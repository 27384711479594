import React from "react";
import { connect } from "react-redux";
import SongLoginPrompt from "./SongLoginPrompt";
import { gradeNumber, artistName } from "./store/songSelectors";
import SaveSong from "./save/SaveSong";
import SaveSongPracticeItem from "./save/SaveSongPracticeItem";

export const SongUserOptions = ({ song, currentUser, token }) => {
  if (currentUser) {
    return (
      <div className="song__opts">
        <SaveSong />
        <SaveSongPracticeItem />
      </div>
    );
  } else {
    return <SongLoginPrompt token={token} />;
  }
};

const GradeBadge = ({ gradeNumber }) => {
  if (gradeNumber) {
    return (
      <span className={`grade-badge grade-badge--grade-${gradeNumber}`}>Grade {gradeNumber}</span>
    );
  } else {
    return null;
  }
};

const SongHeading = ({
  song,
  currentUser,
  gradeNumber,
  token,
  artist,
  toggleMobileContextMenuVisibility,
}) => {
  const { originalArtist, title, artistUrl } = song.data.attributes;
  return (
    <div className="song__heading">
      <div className="song__breadcrumbs">
        <GradeBadge gradeNumber={gradeNumber} />
      </div>
      <div className="song__title">
        <div>
          <h3>
            <a href={artistUrl}>{artist}</a>
          </h3>{" "}
          <h1>{title}</h1>
        </div>
        {currentUser && (
          <button
            className="menu-btn mobile-context-menu-toggle-btn"
            onClick={() => toggleMobileContextMenuVisibility()}
          >
            <i className="icon icon-menu-dots-vertical"></i>
          </button>
        )}
      </div>
      <SongUserOptions song={song} currentUser={currentUser} token={token} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  song: state.entity.song,
  currentUser: state.session.currentUser,
  gradeNumber: gradeNumber(state),
  token: state.entity.token,
  artist: artistName(state),
});

export default connect(mapStateToProps)(SongHeading);
