import React from "react";
import ProductsIndexHeader from "./ProductsIndexHeader";
import OtherWaysToSupport from "./OtherWaysToSupport";
import ProductsListing from "./ProductsListing";
import ProductsTypesLinks from "./ProductsTypesLinks";
import { queryStringValuesToProductTypes, productTypeToQueryString } from "./productTypes";
import { getQueryStringValueAtKey } from "../utilities/query-string";
import { CDN_URL } from "../components/shared/CdnUrl";

// for some reason React.useState breaks here, so i'll have to use a class component
export default class ProductsIndex extends React.Component {
  constructor(props) {
    super(props);

    this.subscribeToHistory();

    this.state = {
      productType: this.getProductTypeFromQueryString(),
    };

    this.originalPushState = history.pushState;
  }

  subscribeToHistory = () => {
    const pushState = history.pushState;

    history.pushState = (...args) => {
      const pushStateReturnVal = pushState.apply(history, args);
      this.applyProductTypeFromQueryString();
      return pushStateReturnVal;
    };

    // navigate properly on click of back button
    window.onpopstate = () => {
      this.applyProductTypeFromQueryString();
    };
  };

  applyProductTypeFromQueryString = () => {
    const newProductType = this.getProductTypeFromQueryString();

    if (newProductType !== this.state.productType) {
      this.setState({ productType: newProductType });
    }
  };

  getProductTypeFromQueryString = () => {
    const currentType = window.location.pathname.split("/").pop(); // getQueryStringValueAtKey("type");

    return queryStringValuesToProductTypes[currentType];
  };

  setProductType = (productType) => {
    this.setState({ productType });
  };

  componentWillUnmount() {
    history.pushState = this.originalPushState;
    window.onpopstate = undefined;
  }

  render() {
    const { productType } = this.state;
    const { user, products, songs, subscriptionReferences, trialAvailable, songsSubscriber } =
      this.props;

    return (
      <div className="products-index">
        <ProductsIndexHeader productType={productType} songsSubscriber={songsSubscriber} />

        {/* <ProductsTypesLinks
          setProductType={this.setProductType}
          productType={productTypeToQueryString(productType)}
        /> */}

        <ProductsListing
          tabsFaqs={this.props.tabsFaqs}
          booksFaqs={this.props.booksFaqs}
          user={user}
          products={products}
          songs={songs}
          subscriptionReferences={subscriptionReferences}
          trialAvailable={trialAvailable}
          productType={productType}
          songsSubscriber={songsSubscriber}
          sheetCount={this.props.sheetCount}
          tabsCount={this.props.tabsCount}
        />
        {productType == null && <OtherWaysToSupport />}
      </div>
    );
  }
}
