import React from "react";
import SongRequestsList from "./SongRequestsList";
import { ViewMoreButton } from "../ViewMoreButton";

const PopularSongRequests = ({
  songRequests,
  loadingMoreResults,
  hasMore,
  fetchSongRequests,
  query,
  setQuery,
  clearQuery,
  updateVoteCount,
}) => {
  return (
    <>
      <div className="query-bar">
        <div className="query-bar__inner">
          <i className="icon icon-search-thin" />
          <input type="text" onChange={setQuery} value={query || ""} />
          <i
            className={"icon icon-cross " + (query?.length ? "" : "disabled")}
            onClick={() => (query?.length ? clearQuery() : null)}
          />
        </div>
      </div>

      {songRequests?.length === 0 && query?.length ? (
        <div className="no-results">No results for current search query</div>
      ) : (
        <div className="infinite-scroll">
          <SongRequestsList songRequests={songRequests} updateVoteCount={updateVoteCount} />
          <ViewMoreButton
            onViewMore={() => fetchSongRequests(false)}
            loadingMoreResults={loadingMoreResults}
            hasMore={hasMore}
          />
        </div>
      )}
    </>
  );
};

export default PopularSongRequests;
