import { inDev } from "../../payments/PaymentConstants";
import "../shared/polyfills/replaceAll.js";

export const CDN_URL = "https://jtgt-static.b-cdn.net";
export const DRAGONFLY_PATH = `system/dragonfly/${APP_ENV}`;
export const DEFAULT_THUMB = `${CDN_URL}/images/default-social.png`;

export function fullUrlInDragonfly(path) {
  return `${CDN_URL}/${DRAGONFLY_PATH}${path.startsWith("/") ? "" : "/"}${path.replaceAll(
    " ",
    "+"
  )}`;
}
