import React from "react";
import { connect } from "react-redux";
import { setFontWeight } from "../../store/songStore";

const FontWeight = ({ fontWeight, setFontWeight }) => {
  return (
    <li className="submenu-item">
      <div className="change-option">
        <div className="change-option-label">
          <span>Font weight</span>
        </div>
        <div
          className={`change-option-action clickable${fontWeight === "bold" ? " bold" : ""}`}
          onClick={() => setFontWeight(fontWeight === "bold" ? null : "bold")}
        >
          Bold
        </div>
      </div>
    </li>
  );
};

const mapStateToProps = (state) => ({
  fontWeight: state.entity.fontWeight,
});

const mapDispatchToProps = (dispatch) => ({
  setFontWeight: (fontWeight) => dispatch(setFontWeight(fontWeight)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FontWeight);
