export const GRADE_GRADIENTS_BY_ID = {
  1: "linear-gradient(146.17deg, rgba(218, 218, 218, 0.6) 0%, #DADADA 0.01%, #FFFFFF 100%)",
  2: "linear-gradient(146.17deg, #FBB040 0%, #F9ED32 100%)",
  3: "linear-gradient(146.17deg, #FBB040 0%, #EF6E36 100%)",
  4: "linear-gradient(146.17deg, #3BB2B8 0%, #42E695 100%)",
  5: "linear-gradient(146.17deg, #6078EA 0%, #17EAD9 100%)",
  6: "linear-gradient(0deg, rgba(68, 84, 108, 0.7), rgba(68, 84, 108, 0.7)), linear-gradient(146.17deg, #6094EA 0%, #F02FC2 100%)",
  7: "linear-gradient(146.17deg, #F54EA2 0%, #EF4136 100%)",
  8: "linear-gradient(146.17deg, #E19E58 0%, #7F351C 100%)",
  9: "linear-gradient(146.17deg, #222427 0%, #503B52 100%)",
  10: "rgb(90 90 90)",
  none: "rgb(90 90 90)",
};
