import React, { useState } from "react";
import Swoosh from "../components/shared/Swoosh";
import { LessonCard } from "./GroupLessonsList";
import { PLAN_TYPES } from "../subscriptions/consts";
import CheckoutForm from "../subscriptions/CheckoutForm";
import { SUBSCRIPTION_TYPES } from "../subscriptions/consts";
import { CREATE_SUBSCRIPTION_URL, CONFIRM_PAYMENT_INTENT_URL } from "../pages/MusicTheoryCourse";
import Login from "../components/auth/Login";

const PRICING_PLAN_TILE_WIDTH = "380px";

const PricingPlanTile = ({
  currentUser,
  plan,
  selectedPricingPlan,
  setSelectedPricingPlan,
  setShowLogin,
}) => {
  if (selectedPricingPlan && selectedPricingPlan !== plan) return null;

  const { price, intervalInMonths, planType } = plan.attributes;
  const title =
    planType === PLAN_TYPES.lifetime ? "Lifetime Access for" : "Subscription Payment for";

  const periodDescription =
    planType === PLAN_TYPES.lifetime
      ? ""
      : intervalInMonths === 1
      ? "/ month"
      : intervalInMonths === 12
      ? "/ year"
      : "/ months";
  return (
    <div
      className={
        "group-paywall__selection clickable " + (selectedPricingPlan === plan ? "active" : "")
      }
      onClick={() => (currentUser ? setSelectedPricingPlan(plan) : setShowLogin(true))}
    >
      <>
        <span className="pricing-option__top">{title}</span>
        <span className="pricing-option__bottom">
          ${price} {periodDescription}
        </span>
      </>
    </div>
  );
};

const Form = ({ selectedPricingPlan, setSelectedPricingPlan, websiteProductId }) => {
  if (!selectedPricingPlan) return null;
  const onPaymentSuccess = () => {
    window.location.reload();
  };

  return (
    <>
      <CheckoutForm
        websiteProductId={websiteProductId}
        selectedPlan={selectedPricingPlan}
        setPaymentSuccess={onPaymentSuccess}
        type={SUBSCRIPTION_TYPES.sales}
        subscriptionUrl={CREATE_SUBSCRIPTION_URL}
        confirmationUrl={CONFIRM_PAYMENT_INTENT_URL}
      />
      <button className="button button--no-background" onClick={() => setSelectedPricingPlan(null)}>
        cancel
      </button>
    </>
  );
};

const Paywall = ({ currentUser, pricingPlans, websiteProductId }) => {
  const [selectedPricingPlan, setSelectedPricingPlan] = useState(null);
  const [showLogin, setShowLogin] = useState(false);

  if (showLogin) {
    return (
      <div className="group-paywall__options">
        <div style={{ minWidth: PRICING_PLAN_TILE_WIDTH }}>
          <Login />
        </div>
      </div>
    );
  }

  return (
    <div className="group-paywall__options">
      <h3 className="white bold">Select an option to continue this course</h3>

      {pricingPlans.data.map((plan, idx) => {
        return (
          <PricingPlanTile
            currentUser={currentUser}
            setShowLogin={setShowLogin}
            plan={plan}
            selectedPricingPlan={selectedPricingPlan}
            setSelectedPricingPlan={setSelectedPricingPlan}
            key={idx}
          />
        );
      })}

      <Form
        currentUser={currentUser}
        selectedPricingPlan={selectedPricingPlan}
        setSelectedPricingPlan={setSelectedPricingPlan}
        websiteProductId={websiteProductId}
      />
    </div>
  );
};

const LessonList = ({ lessons }) => {
  return (
    <div className="tabz container">
      <div className="tabz__options">
        <button className="active">
          <i className="icon icon-stacked-boxes" />
          <span>Lessons</span>
        </button>
      </div>

      <div className="tabz__content tabz__content--Lessons">
        {lessons.map((lesson, idx) => {
          return (
            <LessonCard
              lesson={lesson}
              key={idx}
              completedLessonIds={[]}
              lockedLessonIds={lessons.map((l) => l.attributes.id)}
            />
          );
        })}
      </div>
    </div>
  );
};

const GroupPaywall = ({ currentUser, group, websiteProductId }) => {
  const { title, lessonOrder, lessonTitlesWithIds, pricingPlans } = group.data.attributes;
  const lessons = lessonOrder
    .map((id) => {
      return lessonTitlesWithIds.find((lesson) => lesson.attributes.id === id);
    })
    .filter((lesson) => lesson && lesson != null);

  return (
    <div className="group paywalled-group">
      <Swoosh color="#44546c">
        <div className="video-swoosh__main container">
          <div className="video-swoosh__header">
            <h1 className="header1">{title}</h1>
          </div>

          <div className="paywalled-group__paywall">
            <Paywall
              currentUser={currentUser}
              pricingPlans={pricingPlans}
              websiteProductId={websiteProductId}
            />
          </div>
        </div>
      </Swoosh>

      <LessonList lessons={lessons} />
    </div>
  );
};

export default GroupPaywall;
