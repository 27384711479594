import React from "react";
import ReactPlayer from "react-player";
import { CDN_URL } from "../../components/shared/CdnUrl";
import TeachingTimelineSwoosh from "./TeachingTimelineSwoosh";

export default class Teacher extends React.Component {
  render() {
    return (
      <div className="about__body about__body--teacher">
        <div className="about__body__content">
          <div className="about__body__content__description">
            <h2 className="uppercase header2 text-center">
              Hey, how you doing?
              <br />
              Justin Sandercoe here :)
            </h2>
            <p>
              Sharing my passion for music has always been important to me! JustinGuitar went live
              back in July 2003 to support my private lessons. I moved to free digital lessons on
              YouTube in 2006, before almost anyone else was teaching there. The response was so
              inspiring! It helped me realize my mission: to provide the best guitar education to
              everyone, no matter their circumstances.
            </p>
          </div>

          <div className="about__body__content__video">
            {/* <img src={`${CDN_URL}/redesign/about/with-ziggy.png`} alt="Justin With Ziggy" /> */}
            <div style={{ position: "relative", width: "100%" }}>
              <div className="react-player-wrapper">
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=MZXoy9jXgSE"
                  controls={true}
                  width="100%"
                  allowFullScreen={true}
                  height="100%"
                  className="react-player"
                />
              </div>
            </div>
          </div>
        </div>

        <TeachingTimelineSwoosh />

        <div className="about__body__content">
          <div className="about__body__content__win-system">
            <h2 className="uppercase header2 text-center">the justinguitar method</h2>
            <p>
              Millions of people around the world learn to play with JustinGuitar. I've developed
              practical courses that will help you learn quickly and stay motivated. I believe that
              everyone should have access to high-quality guitar education, and that's why I run the
              site on donations instead of paywalls!
            </p>
            <div className="about__body__content__win-system__description">
              <div>
                <h3>Justin</h3>
                <p className="about__method">
                  I carefully design my courses to help you make the best out of your guitar time!
                  My lessons feel like private lessons and are just as effective. The best part?
                  They're open to everyone.
                </p>
              </div>
              <hr className="about__body__content__win-system__description__hr-border" />
              <div>
                <h3>Students</h3>
                <p className="about__method">
                  Learn to play guitar anywhere, anytime. You'll learn at your own pace and
                  schedule, with a structure that will keep you moving forward. If you dig the
                  lessons and can afford them, I count on your donation to help keep the site free!
                </p>
              </div>
            </div>
            <img
              className="about__body__content__win-system__globe negative-margin-right"
              src={`${CDN_URL}/redesign/about/globe-v2.jpg`}
              alt="Globe"
            />
          </div>

          <div className="about__body__content__press">
            <h2 className="uppercase header2 text-center">in the press</h2>
            <ul>
              {this.props.publications.map((publication, idx) => (
                <li key={idx}>
                  <a href={publication.link}>
                    <img
                      src={`${CDN_URL}/redesign/2020/home-page/${publication.name}.svg`}
                      alt={publication.name}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
