import React, { useState } from "react";
import { connect } from "react-redux";
import ToggleSection from "./toggleSections/ToggleSection";
import OriginalSong from "./toggleSections/OriginalSong";
import { PromoItem } from "../../justins-corner/PromoItem";
import { Box } from "@mui/material";
import { jgDenimTheme } from "../../jg-material/theme/jg-denim-theme";
import { withBootstrapSize } from "../../components/shared/WithBootstrapSize";

class SongInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  render() {
    let { isOpen } = this.state;
    const { notes, originalVideo, spotifyShareUrl } = this.props.song.data.attributes;

    const shouldBeLocatedBelowContent = ["xsDevice", "smDevice", "mdDevice"].includes(
      this.props.bootstrapSize
    );
    const shouldBeHorizontal = ["smDevice", "mdDevice"].includes(this.props.bootstrapSize);

    return (
      <div>
        <div className="song__info">
          <ToggleSection isOpen={isOpen} _this={this} title="About This Lesson" />
          {isOpen && (
            <Box
              display="flex"
              flexDirection={shouldBeLocatedBelowContent ? "column" : "row"}
              justifyContent="space-between"
            >
              <div style={{ width: "100%", maxWidth: "750px" }}>
                <div className="styleized styleized-redesign paragraph-container">
                  <div dangerouslySetInnerHTML={{ __html: notes }} />
                </div>

                <OriginalSong />
              </div>

              <div
                style={{
                  marginTop: shouldBeLocatedBelowContent ? "20px" : 0,
                  marginLeft: shouldBeLocatedBelowContent ? 0 : "20px",
                }}
              >
                <PromoItem orientation={shouldBeHorizontal ? "landscape" : "portrait"} />
              </div>
            </Box>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ entity: { song } }) => ({ song });

export default connect(mapStateToProps)(withBootstrapSize(SongInformation));
