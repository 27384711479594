import React, { Fragment, useState } from "react";
import { GRADES } from "./consts";
import { ArrowRightIcons, StarIcon } from "../../components/shared/Icons";
import { CDN_URL } from "../../components/shared/CdnUrl";

const GRADE_IMAGE_PATH = "/images/pages/store/pmt/grade-";
const GRADE_IMAGE_FORMAT = ".png";

const GradeButtons = ({ activeGrade, setActiveGrade, width }) => {
  if (width > 768) {
    return (
      <div className="grade-buttons">
        {GRADES.map((grade, idx) => (
          <Fragment key={idx}>
            <div
              className={`grade-button${activeGrade.grade === grade.grade ? " active" : ""}`}
              style={{
                backgroundColor: grade.color,
                border: grade.color === "white" ? "1px solid #81ABC1" : "",
                color: grade.color === "white" ? "#1D3446" : "",
              }}
              onClick={() => setActiveGrade(grade)}
            >
              {grade.grade}
            </div>
            {idx < GRADES.length - 1 && <ArrowRightIcons />}
          </Fragment>
        ))}
      </div>
    );
  } else {
    return (
      <div className="grade-buttons">
        <select
          value={activeGrade.grade}
          onChange={(e) => {
            setActiveGrade(GRADES.find((grade) => grade.grade == e.target.value));
          }}
        >
          {GRADES.map((grade, idx) => (
            <option key={idx} value={grade.grade}>
              {`Grade ${grade.grade}`}
            </option>
          ))}
        </select>
      </div>
    );
  }
};

const GradeHighlghts = ({ activeGrade, grades, currentUser }) => {
  const gradeWithCounts = grades.find((grade) => grade.position === activeGrade.grade);

  const moduleCount = gradeWithCounts.moduleCount;
  const lessonCount = gradeWithCounts.lessonCount;

  return (
    <div className="info">
      <h3 className="header2 uppercase">Grade {activeGrade.grade} Highlights & Summary</h3>
      <p style={{ color: activeGrade.color === "white" ? "#1D3446" : activeGrade.color }}>
        {`${moduleCount} Module${moduleCount > 1 ? "s" : ""} · 
        ${lessonCount} Lessons`}
      </p>
      <ul className="learn-icons">
        {activeGrade.highlights.map((highlight, idx) => (
          <li key={idx}>
            <div className="description-icon">
              <StarIcon color={activeGrade.color} />
            </div>
            <p>{highlight}</p>
          </li>
        ))}
      </ul>
      <p dangerouslySetInnerHTML={{ __html: activeGrade.description }}></p>
      <a className="button button--primary" href="/classes/practical-music-theory">
        {currentUser && currentUser.data.attributes.enrollments.includes("PMT")
          ? "start learning"
          : "preview lesson plan"}
      </a>
    </div>
  );
};

const WhatWillYouLearn = ({ grades, width, currentUser }) => {
  const [imagesPreloaded, setImagesPreloaded] = useState(false);
  const [activeGrade, setActiveGrade] = useState(GRADES[0]);
  const gradesToDisplay = grades.filter((grade) => grade.position > 0);

  const setGradeAndPreloadImages = (grade) => {
    setActiveGrade(grade);
    preloadImages();
  };

  const preloadImages = () => {
    if (!imagesPreloaded) {
      const loadImage = (grade) => {
        return new Promise((resolve, reject) => {
          const loadImg = new Image();
          loadImg.src = `${CDN_URL}${GRADE_IMAGE_PATH}${grade.position}${GRADE_IMAGE_FORMAT}`;
          loadImg.onload = () => resolve(loadImg.src);
          loadImg.onerror = (err) => reject(err);
        });
      };
      Promise.all(gradesToDisplay.map((grade) => loadImage(grade))).then(() => {
        setImagesPreloaded(true);
      });
    }
  };

  return (
    <div className="section">
      <h2 className="title">what will you learn?</h2>
      <p className="subtitle">
        {Object.values(gradesToDisplay).reduce((count, grade) => count + grade.lessonCount, 0)}{" "}
        lessons already available - over 11 hours of instruction!
      </p>
      <div className="grade-select">
        <p>Select a Grade</p>
        <GradeButtons
          activeGrade={activeGrade}
          setActiveGrade={setGradeAndPreloadImages}
          width={width}
        />
      </div>

      <div className="what-you-will-learn">
        <img
          src={`${CDN_URL}${GRADE_IMAGE_PATH}${activeGrade.grade}${GRADE_IMAGE_FORMAT}`}
          alt="What you will learn in JustinGuitar PMT"
          className="image"
        />

        <GradeHighlghts
          activeGrade={activeGrade}
          grades={gradesToDisplay}
          currentUser={currentUser}
        />
      </div>
    </div>
  );
};

export default WhatWillYouLearn;
