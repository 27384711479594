import React from "react";
import { JgCarousel } from "../shared/Carousel";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { Box } from "@mui/material";
import { CDN_URL } from "../../../components/shared/CdnUrl";

const images: string[] = [
  "2017_spring053 2.png",
  "DSCF2975 1.png",
  "SELP6121 1.png",
  "2017_spring013 2.png",
  "SELP6398 2.png",
];

export const AcousticJamCarousel = () => {
  return (
    <JgCarousel slickSettings={{ slidesToShow: 1 }}>
      {images.map((imgSrc, idx) => (
        <Box
          key={idx}
          component="img"
          src={`${CDN_URL}/redesign/workshops/acoustic-jam-carousel/${imgSrc}`}
          borderRadius="10px"
          marginBottom={jgDenimTheme.spacing(3)}
        />
      ))}
    </JgCarousel>
  );
};
