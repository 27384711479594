import React from "react";
import Slider from "react-slick";
import { TESTIMONIALS } from "./consts";

const Testimonials = ({ width }) => {
  return (
    <div className="section testimonials">
      <h2 className="title">testimonials</h2>
      <div className="quotes"></div>
      <div className="cards">
        <Slider
          {...{
            dots: true,
            infinite: true,
            slidesToShow: width >= 1200 ? 3 : width >= 992 ? 2 : 1,
            slidesToScroll: 1,
          }}
        >
          {TESTIMONIALS.map((testimonial, idx) => (
            <div key={idx} className="card">
              <div
                className="card-header"
                style={{ backgroundImage: `url(${testimonial.headerImage})` }}
              ></div>
              <div className="card-content">
                <p className="body" dangerouslySetInnerHTML={{ __html: testimonial.body }}></p>
                <p className="data">
                  <strong>
                    {testimonial.name}
                    {testimonial.location ? ", " : ""}
                  </strong>
                  {testimonial.location && <span className="location">{testimonial.location}</span>}
                </p>
                {testimonial.studentSince && (
                  <p className="student-since">{testimonial.studentSince}</p>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
