import React from "react";
import DynamicIframe from "../components/DynamicIframe";

export default class TabbedVideoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ item: nextProps.item });
  }

  render() {
    return (
      <div className="tabbed-video__container">
        <DynamicIframe showDonateAfterVideo={false} link={this.state.item.video} height="400px" />
      </div>
    );
  }
}
