import React from "react";
import { MoneyFormatter } from "../../components/shared/MoneyFormatter";
import LoginPrompt from "../../components/auth/LoginPrompt";
import PaywallForm from "../../payments/PaywallForm";
export default class MultiplePaymentOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPricingOption: null,
    };
  }

  formattedPaymentOptionTitle = (option) => {
    const { price, isSubscription, subscriptionFrequencyInMonths } = option.attributes;
    if (isSubscription) {
      return (
        <>
          <span className="pricing-option__top">Subscription Payment for</span>
          <span className="pricing-option__bottom">
            {MoneyFormatter(price)} / {subscriptionFrequencyInMonths}{" "}
            {subscriptionFrequencyInMonths > 1 ? "Months" : "Month"}
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className="pricing-option__top">Lifetime Access for</span>
          <span className="pricing-option__bottom">{MoneyFormatter(price)}</span>
        </>
      );
    }
  };

  payNow = () => {
    const { paywall, currentUser, token } = this.props;

    if (currentUser) {
      return (
        <>
          <p className="payment-intro bold">Please enter your payment details below.</p>
          <PaywallForm
            currentUser={currentUser}
            paywall={paywall}
            selectedPricingOption={this.state.selectedPricingOption}
          />
          <span className="go-back" onClick={() => this.setState({ selectedPricingOption: null })}>
            <i className="icon icon-angle-left" /> back
          </span>
        </>
      );
    } else {
      return (
        <div className="group-paywall__login">
          <LoginPrompt token={token} loginAction={"make purchases"} />
          <span className="go-back" onClick={() => this.setState({ selectedPricingOption: null })}>
            <i className="icon icon-angle-left" /> back
          </span>
        </div>
      );
    }
  };

  render() {
    const { paywall, pricingOptions, currentUser } = this.props;
    let { selectedPricingOption } = this.state;
    return (
      <div className="group-paywall__options">
        <h3 className="white bold">Select an option to continue this course</h3>
        {pricingOptions.map((option) => {
          return (
            (!selectedPricingOption || selectedPricingOption == option) && (
              <div
                key={option.id}
                className={
                  "group-paywall__selection clickable " +
                  (selectedPricingOption === option ? "active" : "")
                }
                onClick={() => this.setState({ selectedPricingOption: option })}
              >
                {this.formattedPaymentOptionTitle(option)}
              </div>
            )
          );
        })}

        {selectedPricingOption && <div>{this.payNow()}</div>}
      </div>
    );
  }
}
