import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import SheetMusicContainer from "./SheetMusicContainer";
import "../store/songIndex";

export default class SheetMusicStandalonePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const songStore = ReactOnRails.getStore("songStore");

    return (
      <Provider store={songStore}>
        <SheetMusicContainer standalonePage={true} />
      </Provider>
    );
  }
}
