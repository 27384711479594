import { schema } from "normalizr";

export const quiz = new schema.Entity("quizzes");
export const quizQuestion = new schema.Entity("questions");
export const quizQuestionOption = new schema.Entity("options");
export const quizAttempt = new schema.Entity("attempts");
export const userQuizAnswer = new schema.Entity("userQuizAnswers");
export const userQuizAnswerOption = new schema.Entity("userQuizAnswerOptions");
export const lesson = new schema.Entity("lessons");
export const module = new schema.Entity("modules");

export const singleQuizJson = {
  data: quiz,
  included: new schema.Array({ quizQuestion, quizQuestionOption }, (entity) => entity.type),
};

export const singleAttemptJson = {
  data: quizAttempt,
  included: new schema.Array(
    {
      quiz,
      quizQuestion,
      quizQuestionOption,
      userQuizAnswer,
      userQuizAnswerOption,
      lesson,
      group: module,
      module,
    },
    (entity) => entity.type
  ),
};

export const quizPropsJson = {
  quiz: singleQuizJson,
  quizAttempt: singleAttemptJson,
};
