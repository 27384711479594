import ReactOnRails from "react-on-rails";
import { configureStore, createSlice, combineReducers } from "@reduxjs/toolkit";
import { logSheetMusicView } from "./songEffects";
import { ui } from "../../components/shared/ui/uiIndex";
import { session } from "../../components/shared/session/sessionIndex";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import { snakeToCamelCaseObject } from "../../utilities/misc";

const saveSettings = (state) => {
  axiosWithCSRF()
    .post("/advanced_settings", {
      setting_name: "tabs_display",
      setting_value: {
        fontSize: state.fontSize,
        fontWeight: state.fontWeight,
        typeface: state.typeface,
        darkMode: state.darkMode,
        chordHighlighted: state.chordHighlighted,
        selectedChordColor: state.selectedChordColor,
      },
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const initialState = (props) => {
  // These are set in the controller (songs_controller#show -> initialize_redux_store)
  return {
    entity: {
      sheetMusic: props?.sheetMusic || {},
      song: props?.song || {},
      userPerformances: props?.userPerformances || [],
      token: props?.token || "",
      activeTab: "Learn More",
      sheetMusicViewLogged: false,
      transpositionChange: 0,
      fontSize: 14,
      selectedChordColor: "default",
      activeSheetMusicContent: "Chords",
      chordHighlighted: true,
      activeSubMenuTab: null,
      viewAllImages: false,
      activeImage: null,
      standalonePage: false,
      typeface: "OpenSans",
      promoItems: [],
      fontWeight: null,
    },
  };
};

const initialSessionState = (props) => {
  return {
    session: { currentUser: props.currentUser },
  };
};

const songSlice = createSlice({
  name: "song",
  initialState,
  reducers: {
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    changeFontSize: (state, { payload }) => {
      if (payload >= 10 && payload <= 18) {
        state.fontSize = payload;
        saveSettings(state);
      }
    },
    changeActiveSheetMusicContent: (state, { payload }) => {
      if (payload.content === "Tabs") {
        state.activeImage = payload.image;
      } else {
        state.activeImage = null;
        state.viewAllImages = false;
      }
      state.subMenuOpen = false;
      state.activeSubMenuTab = null;
      state.activeSheetMusicContent = payload.content;
    },
    transpose: (state, { payload }) => {
      [-12, 12].includes(state.transpositionChange + payload)
        ? (state.transpositionChange = 0)
        : (state.transpositionChange += payload);
    },
    toggleField: (state, { payload }) => {
      state[payload] = !state[payload];
    },
    closeSubMenu: (state, { payload }) => {
      state["subMenuOpen"] = false;
    },
    setSubMenuTab: (state, { payload }) => {
      state.activeSubMenuTab = payload;
    },
    setSelectedChordColor: (state, { payload }) => {
      if (payload === state.selectedChordColor) {
        state.selectedChordColor = "default";
      } else {
        state.selectedChordColor = payload;
      }
      saveSettings(state);
    },
    setChordHighlighted: (state, { payload }) => {
      state.chordHighlighted = payload;
      saveSettings(state);
    },
    setDarkMode: (state, { payload }) => {
      state.darkMode = payload;
      saveSettings(state);
    },
    setTypeface: (state, { payload }) => {
      state.typeface = payload;
      saveSettings(state);
    },
    setActiveImage: (state, { payload }) => {
      state.activeImage = payload;
      state.activeSheetMusicContent = "Tabs";
    },
    setViewAllImages: (state, { payload }) => {
      state.activeImage = null;
      state.viewAllImages = true;
      state.activeSheetMusicContent = "Tabs";
    },
    setSingleImage: (state, { payload }) => {
      state.activeImage = payload;
      state.viewAllImages = false;
    },
    setFontWeight: (state, { payload }) => {
      state.fontWeight = payload;
      saveSettings(state);
    },
    resetAll: (state, { payload }) => {
      state.fontSize = 14;
      state.fontWeight = null;
      state.typeface = "OpenSans";
      state.darkMode = false;
      state.chordHighlighted = true;
      state.selectedChordColor = "default";
      saveSettings(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logSheetMusicView.fulfilled, (state, { payload }) => {
      state.sheetMusicViewLogged = true;
    });
  },
});

export const {
  setActiveTab,
  changeFontSize,
  changeActiveSheetMusicContent,
  transpose,
  toggleField,
  closeSubMenu,
  setSubMenuTab,
  setSelectedChordColor,
  setActiveImage,
  setViewAllImages,
  setSingleImage,
  setChordHighlighted,
  setDarkMode,
  setTypeface,
  setFontWeight,
  resetAll,
} = songSlice.actions;

const reducer = combineReducers({
  entity: songSlice.reducer,
  ui: ui.reducer,
  session: session.reducer,
});

const createSongStore = (preloadedState) =>
  configureStore({
    reducer,
    preloadedState,
  });

ReactOnRails.registerStore({
  // songStore: (props, railsContext) => createSongStore({ ...initialState, ...props }),
  songStore: (props, railsContext) => {
    let tabs_display = props.session.currentUser
      ? props.session.currentUser.data.attributes.advancedSettings.tabs_display
      : {};
    return createSongStore({
      entity: {
        ...initialState,
        ...props.entity,
        ...snakeToCamelCaseObject(tabs_display || {}),
      },
      ui: {
        ...ui.initialState,
        ...props.ui,
      },
      session: {
        ...initialSessionState,
        ...props.session,
      },
    });
  },
});
