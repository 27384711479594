import React, { useState } from "react";
import { connect } from "react-redux";
import { setActiveTab } from "../store/dashboardStore";
import ContinueLearning from "./ContinueLearning";
import WhatsNew from "./WhatsNew";
import AdvertColumn from "./AdvertColumn";
import AllNewLessons from "./AllNewLessons";
import SnackbarNotification from "../../components/shared/ui/Snackbar";
import { PromoItem } from "../../justins-corner/PromoItem";
import { withBootstrapSize } from "../../components/shared/WithBootstrapSize";
import { withInnerWidth } from "../../components/shared";

const WelcomeBack = ({ user, latestLessons, recentPageviews, setActiveTab, width }) => {
  const [viewAllLessons, setViewAllLessons] = useState(false);
  const { username, slug } = user.data.attributes;
  return (
    <div className="dashboard__content__tabs">
      <div className="tab-container--welcome">
        {!viewAllLessons ? (
          <div className="welcome">
            <div className="welcome__banner">
              <h2>
                Welcome Back, <strong>{username}</strong>.
              </h2>
              <a
                onClick={() => {
                  setActiveTab("practice");
                }}
                className="button button--primary button--inline"
              >
                Start Today's Practice
              </a>
            </div>

            <div className="welcome__row">
              <div className="welcome__col">
                <ContinueLearning user={user} recentPageviews={recentPageviews} />
              </div>
              <div className="welcome__col">
                <WhatsNew
                  latestLessons={latestLessons}
                  setViewAllLessons={() => {
                    setActiveTab("latest");
                  }}
                />
              </div>
              <div className="welcome__col">
                <PromoItem
                  orientation={width < 1200 && width > 576 ? "landscape" : "portrait"}
                  target="dashboard"
                />
              </div>
            </div>
          </div>
        ) : (
          <AllNewLessons setViewAllLessons={setViewAllLessons} />
        )}

        <SnackbarNotification />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.session.currentUser,
  latestLessons: state.entity.dashboard.latestLessons,
  recentPageviews: state.entity.dashboard.recentPageviews,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withInnerWidth(WelcomeBack));
