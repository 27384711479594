import { combineReducers, configureStore } from "@reduxjs/toolkit";
import ReactOnRails from "react-on-rails";
import { normalize } from "normalizr";

import { entitiesReducer, EntitiesState } from "./slices/entities/entities.reducer";
import { entitiesSchema } from "./slices/entities/entities.schema";
import { uiSlice, UiState } from "./slices/ui/ui.slice";
import { workshopsSlice } from "../pages/Workshops/store/workshops.slice";
import { SerializedRailsEntityWithIncludes } from "../../../interfaces";
import { sessionSlice, SessionState } from "./slices/session/session.slice";

export const STORE_NAME = "jgStore";

interface RailsProps {
  entities: SerializedRailsEntityWithIncludes;
  currentEntityId?: string | number;
  session?: { currentUser?: any };
}

ReactOnRails.registerStore({
  [STORE_NAME]: (railsProps: RailsProps) => {
    const { entities } = normalize(railsProps.entities || {}, entitiesSchema);

    const store = configureStore({
      reducer: combineReducers({
        entities: entitiesReducer,
        ui: uiSlice.reducer,
        session: sessionSlice.reducer,
      }),
      preloadedState: {
        ui: { currentEntityId: railsProps.currentEntityId },
        session: {
          ...(railsProps.session || {}),
        },
      },
    });

    // TODO: get state from api, only rely on rails for current user
    store.dispatch(workshopsSlice.actions.setAll(entities.workshops || {}));

    return store;
  },
});

export const getJgStore = () => {
  return ReactOnRails.getStore(STORE_NAME);
};

export interface JgState {
  entities: EntitiesState;
  ui: UiState;
  session: SessionState;
}
