import React from "react";
import PropTypes from "prop-types";

import SearchResultItem from "./SearchResultItem";

const SearchResultsGroup = ({ items, query }) => {
  if (items == null || items.length === 0) {
    return <span className="search__no-results">None found</span>;
  }

  const displayItems = items.slice(0, 4);
  return (
    <div>
      {displayItems.map((i) => (
        <SearchResultItem item={i} query={query} key={i.slug} />
      ))}
      <a href={`/advanced_search?q=${query}`} className="search__more">
        See More
      </a>
    </div>
  );
};

SearchResultsGroup.propTypes = {
  query: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ),
};

export default SearchResultsGroup;
