export interface Faq {
  question: string;
  slug: string;
  html: string;
}

export interface FaqTopic {
  topicName: string;
  questions: Faq[];
}

export interface FaqCategory {
  categoryName: string;
  slug: string;
  icon: string;
  contacts: string[];
  topics: FaqTopic[];
}

export const purchasesAndDonations: FaqCategory = {
  categoryName: "Purchases & Donations",
  slug: "purchases-and-donations",
  icon: "GiftCard",
  contacts: ["hello@justinguitar.com"],
  topics: [
    {
      topicName: "Apps",
      questions: [
        {
          question: "Can you cancel my subscription?",
          slug: "cancel-app-subscription",
          html: "purchases_and_donations/unsubscribe_from_app",
        },
        {
          question: "How do I restore an app?",
          slug: "restore-app",
          html: "purchases_and_donations/restore_app",
        },
        {
          question: "Will your One Minute Changes App be available for Android users?",
          slug: "one-minute-changes-android",
          html: "purchases_and_donations/omc",
        },
      ],
    },
    {
      topicName: "Other",
      questions: [
        {
          question: "How can I update my card details on the website?",
          slug: "how-to-update-card-details",
          html: "purchases_and_donations/update_card",
        },
        {
          question: "Can I buy a JustinGuitar gift card to purchase your Premium Products?",
          slug: "giftcard",
          html: "purchases_and_donations/giftcard",
        },
        {
          question: "Can I get a different sort of subscription to a certain product?",
          slug: "product-options",
          html: "purchases_and_donations/product_options",
        },
        {
          question: "Are JustinGuitar's songbooks available as e-books?",
          slug: "songbook-format",
          html: "purchases_and_donations/songbook_format",
        },
      ],
    },
    {
      topicName: "Donations",
      questions: [
        {
          question: "How can I cancel a PayPal subscription?",
          slug: "cancel-paypal-donation-subscription",
          html: "purchases_and_donations/cancel_paypal_donation",
        },
      ],
    },

    {
      topicName: "Lessons & Songs App",
      questions: [
        {
          question:
            "Will the JustinGuitar Lessons & Songs app ever include Intermediate lessons and songs?",
          slug: "intermediate-songs-on-the-app",
          html: "purchases_and_donations/intermediate_songs",
        },
        {
          question: "I have an problem with the JustinGuitar Lessons & Songs App. Can you help?",
          slug: "lessons-and-songs-app-problem",
          html: "purchases_and_donations/app_problem",
        },
        {
          question:
            "I subscribed to the JustinGuitar Lessons & Songs app, but changed my mind. How can I get a refund?",
          slug: "app-refund",
          html: "purchases_and_donations/app_refund",
        },
        {
          question: "How can I unsubscribe from your app?",
          slug: "unsubscribe-from-app-instructions",
          html: "purchases_and_donations/unsubscribe",
        },
        {
          question:
            "What are the differences between the JustinGuitar Lessons & Songs app, and JustinGuitar Tabs?",
          slug: "difference-between-app-and-products",
          html: "purchases_and_donations/different_products",
        },
        {
          question:
            "How do I bypass the learning path, sync progress or unlock the lessons and the songs in the beginner app?",
          slug: "bypass-learning-path",
          html: "purchases_and_donations/bypass_learning_path",
        },
        {
          question: "Can I use the app in landscape mode?",
          slug: "app-in-landscape-mode",
          html: "purchases_and_donations/landscape_mode",
        },
        {
          question: "I updated from the old version. Is my subscription continuing?",
          slug: "is-my-subscription-continuing",
          html: "purchases_and_donations/subscription_continuing",
        },
        {
          question: "How can I find the Simplified Backing Track in Practice Mode?",
          slug: "simplified-backing-track",
          html: "purchases_and_donations/simplified_backing",
        },
        {
          question:
            "I updated from the old version. Did I lose my learning progress? Where to find it now?",
          slug: "progress-after-updating-app",
          html: "purchases_and_donations/progress_after_update",
        },
      ],
    },
  ],
};

export const websiteIssuesAndFeedback: FaqCategory = {
  categoryName: "Website Issues & Feedback",
  slug: "website-issues-and-feedback",
  icon: "Monitor",
  contacts: ["hello@justinguitar.com"],
  topics: [
    {
      topicName: "Lessons & Songs App",
      questions: [
        {
          question: "Are all the songs from the website in the JustinGuitar Lessons & Songs app?",
          slug: "songs-from-the-app-in-the-site",
          html: "website/app_songs",
        },
        {
          question:
            "Why my JustinGuitar Lessons & Songs App has still not updated on my Android device?",
          slug: "app-not-updated",
          html: "website/app_not_updated",
        },
      ],
    },
    {
      topicName: "Website Features",
      questions: [
        {
          question: "I'm left-handed. Where are the left-handed chord boxes and scale boxes?",
          slug: "left-handed",
          html: "website/left_handed",
        },
        {
          question: "My vision is impaired. How do I use JustinGuitar?",
          slug: "accessibility",
          html: "website/accessibility",
        },
        {
          question: "How can I delete my JustinGuitar.com account?",
          slug: "delete-my-account",
          html: "website/delete_account",
        },
      ],
    },
    {
      topicName: "General",
      questions: [
        {
          question: "Where is your popular Spotify playlist of Blues songs?",
          slug: "blues-playlist-on-spotify",
          html: "website/blues_playlist_on_spotify",
        },
      ],
    },
  ],
};

export const communityForum: FaqCategory = {
  categoryName: "Community Forum",
  slug: "community-forum",
  icon: "Dialog",
  contacts: ["hello@justinguitar.com"],
  topics: [
    {
      topicName: "Lessons",
      questions: [
        {
          question: "How can I request a song lesson?",
          slug: "how-to-request-a-song",
          html: "community/how_to_request_a_song",
        },
        {
          question: "Will you make a lesson on my favorite song?",
          slug: "my-favorite-song",
          html: "community/my_favorite_song",
        },
        {
          question: "Can you remake your older lessons with a better video quality?",
          slug: "better-video-quality",
          html: "community/better_video_quality",
        },
        {
          question: "That song lesson doesn't give a strumming pattern. Can you make one?",
          slug: "add-strum-patter",
          html: "community/add_strum_pattern",
        },
      ],
    },
    {
      topicName: "Gear",
      questions: [
        {
          question: "What kind of guitar are you playing in that video?",
          slug: "what-kind-of-guitar-is-justin-playing",
          html: "community/what_kind_of_guitar",
        },
        {
          question: "What kind of guitar should I buy?",
          slug: "what-kind-of-guitar-should-i-buy",
          html: "community/what_guitar_to_buy",
        },
      ],
    },
    {
      topicName: "About Justin",
      questions: [
        {
          question: "Where do you buy your hats?",
          slug: "where-do-you-get-your-hats",
          html: "community/hats",
        },
        {
          question: "Where are you from/where are you based?",
          slug: "where-is-justin-from",
          html: "community/justin_from",
        },
      ],
    },
    {
      topicName: "General",
      questions: [
        {
          question: "I recently restarted playing the guitar. Where do I start?",
          slug: "where-to-start",
          html: "community/where_to_start",
        },
        {
          question: "Why are the chords different than the original song?",
          slug: "chords-different-from-original-song",
          html: "community/chords_different",
        },
      ],
    },
    {
      topicName: "Lessons & Songs App",
      questions: [
        {
          question: "What's the difference between your beginner app and website? ",
          slug: "difference-between-app-and-website",
          html: "community/difference_between_app_and_website",
        },
        {
          question: "Why is the JustinGuitar Songs & Lessons app not available in my country?",
          slug: "app-availability-in-my-country",
          html: "community/app_availability",
        },
      ],
    },
    {
      topicName: "Learning Path",
      questions: [
        {
          question: "What lesson path should I follow? I'm not sure what to learn!",
          slug: "what-path-should-i-follow",
          html: "community/path_to_follow",
        },
        {
          question: "What's the difference between the lessons and modules?",
          slug: "difference-between-lessons-and-modules",
          html: "community/difference_between_lessons_and_modules",
        },
        {
          question: "How long should I practice between modules?",
          slug: "how-long-should-i-practice-between-modules",
          html: "community/between_modules",
        },
        {
          question: "How do I make my practice more interesting?",
          slug: "interesting-practice",
          html: "community/interesting_practice",
        },
        {
          question: "When will Justin complete Grade 3?",
          slug: "when-will-grade-three-be-done",
          html: "community/grade_three_completion",
        },
        {
          question: "When should I start your Music Theory course?",
          slug: "when-should-i-start-music-theory",
          html: "community/start_pmt",
        },
        {
          question: "I can't seem to play barre chords! What do I do?",
          slug: "stuck-on-barre-chords",
          html: "community/barre_chords",
        },
      ],
    },
    {
      topicName: "Donations",
      questions: [
        {
          question: "Is JustinGuitar free to use?",
          slug: "is-justinguitar-free",
          html: "community/is_justinguitar_free",
        },
      ],
    },
  ],
};

export const tabs: FaqCategory = {
  categoryName: "TABS",
  slug: "tabs",
  icon: "Tabs",
  contacts: ["hello@justinguitar.com"],
  topics: [
    {
      topicName: "TABS Feature",
      questions: [
        {
          question: "Where do I access the TABS feature?",
          slug: "how-to-access-tabs",
          html: "tabs/how_to_access_tabs",
        },
        {
          question: "Are these all Justin’s Transcriptions?",
          slug: "are-these-justins-transcriptions",
          html: "tabs/are_these_justins_transcriptions",
        },
        {
          question: "Are strumming patterns shown?",
          slug: "are-strumming-patterns-shown",
          html: "tabs/are_strumming_patterns_shown",
        },
        {
          question: "Does the feature include solos?",
          slug: "are-solos-included",
          html: "tabs/are_solos_included",
        },
        {
          question: "Can I download and/or print the TABs?",
          slug: "download-tabs",
          html: "tabs/download_tabs",
        },
      ],
    },
    {
      topicName: "TABS Catalogue",
      questions: [
        {
          question: "Which songs have a full tab?",
          slug: "songs-with-full-tab",
          html: "tabs/songs_with_full_tab",
        },
        {
          question: "Where can I see which songs have a full tab and which have chords?",
          slug: "songs-with-full-tab-or-chords",
          html: "tabs/songs_with_full_tab_or_chords",
        },
        {
          question: "Are your songbooks and TABS the same?",
          slug: "are-songbooks-and-tabs-the-same",
          html: "tabs/are_songbooks_and_tabs_the_same",
        },
        {
          question: "Will you be adding more songs?",
          slug: "more-songs",
          html: "tabs/more_songs",
        },
      ],
    },
    {
      topicName: "TABS Pricing & Subscription",
      questions: [
        {
          question: "Why US Dollars?",
          slug: "why-us-dollars",
          html: "tabs/why_us_dollars",
        },
        {
          question: "I can't afford this! Can you help?",
          slug: "cant-afford",
          html: "tabs/cant_afford",
        },
        {
          question: "How can I end my subscription?",
          slug: "end-subscription",
          html: "tabs/end_subscription",
        },
      ],
    },
    {
      topicName: "TABS vs Lessons & Songs App",
      questions: [
        {
          question: "I subscribe to the App, do I get this?",
          slug: "app-subscription",
          html: "tabs/app_subscription",
        },
        {
          question: "What's the difference between TABS and JustinGuitar Lessons & Songs App?",
          slug: "difference-between-tabs-and-justinguitar-lessons-songs-app",
          html: "tabs/difference_between_tabs_and_justinguitar_lessons_songs_app",
        },
      ],
    },
  ],
};

export const approvedTeachers = {
  categoryName: "Approved Teachers",
  slug: "teachers",
  icon: "Teachers",
  contacts: ["hello@justinguitar.com"],
  topics: [
    {
      topicName: "Teachers",
      questions: [
        {
          question: "Criminal Checks",
          slug: "criminal-checks",
          html: "teachers/criminal_checks",
        },
      ],
    },
    {
      topicName: "Lessons",
      questions: [
        {
          question: "Lesson Times",
          slug: "lesson-times",
          html: "teachers/lesson_times",
        },
        {
          question: "Technology",
          slug: "technology",
          html: "teachers/technology",
        },
        {
          question: "Recording Lessons",
          slug: "recording-lessons",
          html: "teachers/recording_lessons",
        },
        {
          question: "Materials",
          slug: "materials",
          html: "teachers/materials",
        },
      ],
    },
    {
      topicName: "Pricing & Cancellations",
      questions: [
        {
          question: "Cancellation Policy",
          slug: "cancelation-policy",
          html: "teachers/cancelation_policy",
        },
        {
          question: "Bulk Discount",
          slug: "bulk-discount",
          html: "teachers/bulk_discount",
        },
      ],
    },
  ],
};

export const workshops = {
  categoryName: "Workshops",
  slug: "workshops",
  icon: "Workshops",
  contacts: ["hello@justinguitar.com"],
  topics: [
    {
      topicName: "Workshops",
      questions: [
        {
          question: "What is the exclusive pre-sale access?",
          slug: "presale",
          html: "workshops/presale",
        },
        {
          question: "What are the criteria to be approved?",
          slug: "approval",
          html: "workshops/approval",
        },
        {
          question: "Can I give feedback after the workshop?",
          slug: "feedback",
          html: "workshops/feedback",
        },
        {
          question: "How can I contact Carlo?",
          slug: "contact-carlo",
          html: "workshops/contact-carlo",
        },
      ],
    },
    {
      topicName: "Accommodations",
      questions: [
        {
          question: "Can I bring a guest?",
          slug: "guests",
          html: "workshops/guests",
        },
        {
          question: "I have dietary requirements",
          slug: "dietary-reqs",
          html: "workshops/dietary-reqs",
        },
        {
          question: "How to book Accommodations & Means?",
          slug: "booking-accommodations",
          html: "workshops/booking-accommodations",
        },
        {
          question: "Is early check-in or late check-out available?",
          slug: "check-in-out",
          html: "workshops/check-in-out",
        },
      ],
    },
    {
      topicName: "Pricing & Cancellations",
      questions: [
        {
          question: "What is the cancellation policy?",
          slug: "cancellation-policy",
          html: "workshops/cancellation-policy",
        },
        {
          question: "I want to request a refund!",
          slug: "refund",
          html: "workshops/refund",
        },
        {
          question: "Are transports to the workshop included in the price?",
          slug: "transportation-included",
          html: "workshops/transportation-included",
        },
      ],
    },
  ],
};

export const childrensSongs = {
  categoryName: "Children’s Songs Ebooks",
  slug: "teachers",
  icon: "ChildrensSongs",
  contacts: ["hello@justinguitar.com"],
  topics: [
    {
      topicName: "General",
      questions: [
        {
          question:
            "I have the Children's Songs for Mums and Dads - Ukulele Edition. Do I need this new book?",
          slug: "contents",
          html: "childrens_songs/contents",
        },
        {
          question:
            "I want a hard copy! How can I buy a printed version of JustinGuitar 101 Children's Songs Songbooks?",
          slug: "hard-copy",
          html: "childrens_songs/hard_copy",
        },
        {
          question: "What's included with my purchase?",
          slug: "what-is-included",
          html: "childrens_songs/what_is_included",
        },
        {
          question: "How do I access my purchase?",
          slug: "how-to-access",
          html: "childrens_songs/how_to_access",
        },
        {
          question: "Which format are the eBooks? What do I need to access them?",
          slug: "format",
          html: "childrens_songs/format",
        },
        {
          question: "What are the 101 Children's Songs presented in these eBooks?",
          slug: "included-songs",
          html: "childrens_songs/included_songs",
        },
        {
          question: "Do these eBooks come with song lesson videos teaching the songs?",
          slug: "lesson-videos",
          html: "childrens_songs/lesson_videos",
        },
      ],
    },
    {
      topicName: "Pricing",
      questions: [
        {
          question: "Can't I get a refund if I don't like my purchase?",
          slug: "refund",
          html: "childrens_songs/refund",
        },
        {
          question:
            "I don't have a credit card but would love to buy one or both songbooks. What should I do?",
          slug: "payment-options",
          html: "childrens_songs/payment_options",
        },

        {
          question:
            "I subscribed to one of JustinGuitar's apps or Premium Courses. Can I get these 101 Children's Songs songbooks for free?",
          slug: "subscription",
          html: "childrens_songs/subscription",
        },
        {
          question:
            "I would love to have this Songbook, but I genuinely can't afford it! Can I have it for free?",
          slug: "cant-afford",
          html: "childrens_songs/cant_afford",
        },
      ],
    },
  ],
};

export const musicTheoryCourse = {
  categoryName: "Music Theory Course",
  slug: "music-theory-course",
  icon: "MusicTheoryCourse",
  contacts: ["hello@justinguitar.com"],
  topics: [
    {
      topicName: "Lessons",
      questions: [
        {
          question: "When should I start learning Music Theory?",
          slug: "when-to-start",
          html: "music_theory/when_to_start",
        },
        {
          question:
            "How's the Music Theory Course structured? Does it relate to the Beginner Course?",
          slug: "course-structure",
          html: "music_theory/course_structure",
        },
        {
          question:
            "Is Music Theory too hard? I'm afraid the lessons will be boring and complicated.",
          slug: "is-theory-hard",
          html: "music_theory/is_theory_hard",
        },
        {
          question:
            "I just don't understand a lesson, exercise, or am feeling stuck. What should I do?",
          slug: "difficult-to-understand",
          html: "music_theory/difficult_to_understand",
        },
        {
          question: "Do you add new lessons regularly?",
          slug: "new-lessons",
          html: "music_theory/new_lessons",
        },
      ],
    },
    {
      topicName: "Course Resources",
      questions: [
        {
          question: "What will I get when enrolling to the Music Theory Course?",
          slug: "what-is-included",
          html: "music_theory/what_is_included",
        },
        {
          question: "I subscribed to the Music Theory Course. How do I find my Downloads?",
          slug: "additional-material",
          html: "music_theory/additional_material",
        },
        {
          question:
            "Where should I ask my questions and reach out to Justin or his Official Guides?",
          slug: "asking-help",
          html: "music_theory/asking_help",
        },
      ],
    },
    {
      topicName: "Pricing & Cancellations",
      questions: [
        {
          question:
            "I've previously subscribed to the 6-month plan or bought the Lifetime access. What now?",
          slug: "previous-plans",
          html: "music_theory/previous_plans",
        },
        {
          question:
            "I have an active subscription to the JustinGuitar Lessons & Songs App. Do I get instant access to the Music Theory Course?",
          slug: "app-subscription",
          html: "music_theory/app_subscription",
        },
        {
          question:
            "What's the difference between the 1-month subscription and the 1-year subscription?",
          slug: "subscription-difference",
          html: "music_theory/subscription_difference",
        },
        {
          question: "Is there any free trial period available for your Music Theory Course?",
          slug: "free-trial",
          html: "music_theory/free_trial",
        },
        {
          question: "Most of JustinGuitar is for free. Why do you charge for this course?",
          slug: "why-not-free",
          html: "music_theory/why_not_free",
        },
        {
          question:
            "I'd love to study Music Theory with you, but I can't afford a subscription. What should I do?",
          slug: "cant-afford",
          html: "music_theory/cant_afford",
        },
        {
          question: "Can I enroll to the full course using PayPal?",
          slug: "paypal",
          html: "music_theory/paypal",
        },
        {
          question: "I subscribed to the monthly plan, but I'd like to upgrade! What should I do?",
          slug: "change-plan",
          html: "music_theory/change_plan",
        },
        {
          question: "Can I cancel at any time?",
          slug: "cancellation",
          html: "music_theory/cancellation",
        },
        {
          question: "How do I cancel my subscription?",
          slug: "how-to-cancel",
          html: "music_theory/how_to_cancel",
        },
        {
          question:
            "I don't like the course, I forgot to cancel it, or I simply changed my mind. Can I get a refund?",
          slug: "refund",
          html: "music_theory/refund",
        },
        {
          question:
            "I'm considering purchasing this Course. Would this give access to anything else, such as the Lessons and Songs app?",
          slug: "access-to-other-paid-resources",
          html: "music_theory/access_to_other_paid_resources",
        },
        {
          question:
            "I paid for this Course, but I can't seem to see the full content! What should I do?",
          slug: "cant-access-the-course",
          html: "music_theory/cant_access_the_course",
        },
        {
          question:
            "Why are prices in American dollars? I'd like to see the prices in my currency!",
          slug: "why-usd",
          html: "music_theory/why_usd",
        },
      ],
    },
  ],
};

export const store = {
  categoryName: "Store",
  slug: "store",
  icon: "Store",
  contacts: ["hello@justinguitar.com"],
  topics: [
    {
      topicName: "General",
      questions: [
        {
          question: "What's the currency I see on the website and on the app stores?",
          slug: "currency",
          html: "store/currency",
        },
        {
          question: "Do you offer a bundle or package that includes apps and website content?",
          slug: "bundles",
          html: "store/bundles",
        },
        {
          question:
            "What should I do if I encounter issues with one of your products or if I need a refund?",
          slug: "issues_and_refund",
          html: "store/issues_and_refund",
        },
        {
          question:
            "I have an active subscription to the JustinGuitar main app, but I can't log in to the site to buy another product. What should I do?",
          slug: "app_account",
          html: "store/app_account",
        },
        {
          question:
            "I'm a JustinGuitar regular donator. Do I get special offers or free access to the paid products at the JustinGuitar store?",
          slug: "donator_access",
          html: "store/donator_access",
        },
      ],
    },
    {
      topicName: "JustinGuitar Lessons & Songs app",
      questions: [
        {
          question:
            "What's the difference between the courses on the JustinGuitar.com website and the JustinGuitar Lessons & Songs app?",
          slug: "difference_between_app_and_website",
          html: "store/difference_between_app_and_website",
        },
        {
          question:
            "Are the songs in the app also available as a song tutorial at JustinGuitar.com?",
          slug: "available_songs",
          html: "store/available_songs",
        },
        {
          question: "How to turn on or remove the vocals in the song player?",
          slug: "toggling_vocals",
          html: "store/toggling_vocals",
        },
        {
          question: "What is the lesson structure in the app? Where do I start?",
          slug: "app_lesson_structure",
          html: "store/app_lesson_structure",
        },
        {
          question:
            "Can I sync my progress between JustinGuitar.com and JustinGuitar Lessons & Songs app?",
          slug: "app_sync",
          html: "store/app_sync",
        },
      ],
    },
    {
      topicName: "Music Theory Course",
      questions: [
        {
          question: "When should I start learning Music Theory?",
          slug: "when_to_start_theory",
          html: "store/when_to_start_theory",
        },
        {
          question:
            "Is Music Theory too hard? I'm afraid the lessons will be boring and complicated.",
          slug: "is_theory_hard",
          html: "store/is_theory_hard",
        },
        {
          question:
            "How's the Music Theory Course structured? Does it relate to the Beginner Course?",
          slug: "music_theory_course_structure",
          html: "store/music_theory_course_structure",
        },
        {
          question:
            "I just don't understand a lesson, exercise, or am feeling stuck. What should I do?",
          slug: "cant_understand",
          html: "store/cant_understand",
        },
        {
          question: "Is there any free trial period available for your Music Theory Course?",
          slug: "music_theory_trial",
          html: "store/music_theory_trial",
        },
      ],
    },
    {
      topicName: "Tabs",
      questions: [
        {
          question: "Is JustinGuitarTabs suitable for all levels?",
          slug: "tabs_levels",
          html: "store/tabs_levels",
        },
        {
          question: "What do you mean by hands-free Tabs and Chords/Lyrics?",
          slug: "hands_free_tabs",
          html: "store/hands_free_tabs",
        },
        {
          question: "Do you add new Tabs regularly?",
          slug: "new_tabs",
          html: "store/new_tabs",
        },
        {
          question: "Do Tabs match Justin's Beginner lessons or Justin's song tutorials?",
          slug: "tabs_match_lessons",
          html: "store/tabs_match_lessons",
        },
        {
          question: "I'm subscribed to JustinGuitarTabs. How do I find the music sheets?",
          slug: "how_to_find_sheets",
          html: "store/how_to_find_sheets",
        },
      ],
    },
    {
      topicName: "Tools App",
      questions: [
        {
          question: "How is the Time Trainer Metronome app different from a regular metronome?",
          slug: "how_is_time_trainer_different",
          html: "store/how_is_time_trainer_different",
        },
        {
          question: "How does the Note Trainer app work?",
          slug: "how_note_trainer_works",
          html: "store/how_note_trainer_works",
        },
        {
          question: "What exactly does the Interval Ear Training do?",
          slug: "interval_ear_training",
          html: "store/interval_ear_training",
        },
        {
          question: "I love your Interval Ear Trainer app! Will it be updated soon?",
          slug: "interval_ear_update",
          html: "store/interval_ear_update",
        },
        {
          question:
            "When will your Blues Lick App be available for Android devices? Is it iOS only?!",
          slug: "blues_licks_on_android",
          html: "store/blues_licks_on_android",
        },
        {
          question: "Is the One Minute Changes App available for Android devices? Will it be?",
          slug: "one_minute_changes_android",
          html: "store/one_minute_changes_android",
        },
        {
          question: "What's the minimum requirement to use the One Minute Changes App?",
          slug: "one_minute_changes_app_compatibility",
          html: "store/one_minute_changes_app_compatibility",
        },
      ],
    },
    {
      topicName: "Books",
      questions: [
        {
          question: "Where can I buy your Songbooks?",
          slug: "where_to_buy_books",
          html: "store/where_to_buy_books",
        },
        {
          question: "Can I buy a PDF version of one of your songbooks?",
          slug: "books_pdf",
          html: "store/books_pdf",
        },
        {
          question:
            "Do the JustinGuitar songbooks follow the same structure as the Beginner Guitar Courses available at JustinGuitar.com?",
          slug: "songbook_structure",
          html: "store/songbook_structure",
        },
        {
          question:
            "I would love to get your songbooks, but I cannot afford them. Can I get it for free?",
          slug: "cant_afford",
          html: "store/cant_afford",
        },
      ],
    },
    {
      topicName: "Children's Ebook",
      questions: [
        {
          question: "Do these eBooks come with song lesson videos teaching the songs?",
          slug: "childrens_ebook_videos",
          html: "store/childrens_ebook_videos",
        },
        {
          question:
            "What's the recommended age for the 101 Children’s Songs on Guitar or Ukulele ebooks?",
          slug: "childrens_ebooks_recommended_age",
          html: "store/childrens_ebooks_recommended_age",
        },
        {
          question:
            "I'm a teacher or have other occupation that allows me to use these songbooks to teach or entertain children! Should I do so?",
          slug: "use_as_teacher",
          html: "store/use_as_teacher",
        },
        {
          question: "How will I download my ebook?",
          slug: "how_to_download_childrens_ebook",
          html: "store/how_to_download_childrens_ebook",
        },
        {
          question: "What's included with my purchase?",
          slug: "whats_included_with_childrens_ebooks",
          html: "store/whats_included_with_childrens_ebooks",
        },
      ],
    },
    {
      topicName: "Merch",
      questions: [
        {
          question:
            "I bought something in your Merch Store, but I don't like it, or it doesn't fit. :( Can I get a refund?",
          slug: "merch_refund",
          html: "store/merch_refund",
        },
        {
          question: "I'm not sure which t-shirt size I should buy. Any recommendation?",
          slug: "sizes",
          html: "store/sizes",
        },
        {
          question: "I placed an order. What now?!",
          slug: "order_process",
          html: "store/order_process",
        },
        {
          question: "My size is not available. What should I do?",
          slug: "size_not_available",
          html: "store/size_not_available",
        },
        {
          question:
            "I saw a design I love but didn't want it as a t-shirt. Could I get it as something else? ",
          slug: "design",
          html: "store/design",
        },
      ],
    },
  ],
};

export const strummingCourse = {
  categoryName: "Strumming Course",
  slug: "strumming-course",
  icon: "Teachers",
  contacts: ["hello@justinguitar.com"],
  topics: [
    {
      topicName: "Course Content",
      questions: [
        {
          question: "How about this?",
          slug: "how-about-this",
          html: "strumming_course/how_about_this",
        },
        {
          question: "How about that?",
          slug: "how-about-that",
          html: "strumming_course/how_about_that",
        },
      ],
    },
    {
      topicName: "Account & Payments",
      questions: [
        {
          question: "How about this?",
          slug: "how-about-this",
          html: "strumming_course/how_about_this",
        },
        {
          question: "How about that?",
          slug: "how-about-that",
          html: "strumming_course/how_about_that",
        },
      ],
    },
    {
      topicName: "Additional Materials",
      questions: [
        {
          question: "How about this?",
          slug: "how-about-this",
          html: "strumming_course/how_about_this",
        },
        {
          question: "How about that?",
          slug: "how-about-that",
          html: "strumming_course/how_about_that",
        },
      ],
    },
  ],
};

export const orderedFaqCategories: FaqCategory[] = [
  purchasesAndDonations,
  websiteIssuesAndFeedback,
  communityForum,
  tabs,
  approvedTeachers,
  workshops,
  childrensSongs,
  musicTheoryCourse,
  strummingCourse,
];
