import React, { useRef } from "react";
import { connect } from "react-redux";
import YoutubeChapters from "../components/shared/video/YoutubeChapters";
import ReactPlayer from "react-player";
import YoutubeVideo from "../components/shared/YoutubeVideo";

const SongVideo = ({ song }) => {
  const { video, hasMaxResImage, youtubeChapters } = song.data.attributes;
  const videoRef = useRef(null);

  const goToChapter = (timestamp) => {
    if (videoRef.current) {
      videoRef.current.seekTo(parseFloat(timestamp));
      videoRef.current.playing = true;
    }
  };

  const videoEmbedUrl = (video) => {
    return video.includes("vimeo")
      ? video
      : `https://www.youtube.com/embed/${video}?modestbranding=1&autohide=1&showinfo=0&rel=0`;
  };

  if (video && video.length > 0)
    return (
      <div className="song__video">
        <div style={{ position: "relative", width: "100%" }}>
          <div className="react-player-wrapper">
            <ReactPlayer
              url={videoEmbedUrl(video[0])}
              controls={true}
              width="100%"
              allowFullScreen={true}
              height="100%"
              ref={videoRef}
              className="react-player"
            />
          </div>

          <div className="youtube-chapters-wrapper">
            {youtubeChapters && (
              <YoutubeChapters youtubeChapters={youtubeChapters} goToChapter={goToChapter} />
            )}
          </div>
        </div>
      </div>
    );
};

const mapStateToProps = ({ entity: { song } }) => ({ song });

export default connect(mapStateToProps)(SongVideo);
