import React from "react";
import axios from "axios";

export default class DeleteNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  deleteNote = () => {
    let { note } = this.props;
    if (confirm("Are you sure you want to delete this note?")) {
      axios
        .delete(`/user_notes/${note.id}`, {
          headers: {
            "X-CSRF-Token": ReactOnRails.authenticityToken(),
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.props.handleDeletedNote(true);
        })
        .catch((error) => {
          this.props.handleDeletedNote(false);
        });
    }
  };

  render() {
    return (
      <button className="practice__button practice__button--red" onClick={this.deleteNote}>
        delete
      </button>
    );
  }
}
