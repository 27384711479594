export const playTone = (frequency) => {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const oscillator = audioContext.createOscillator();
  const gainNode = audioContext.createGain();

  oscillator.type = "sine";
  oscillator.frequency.value = frequency;
  gainNode.gain.value = 0.5;

  oscillator.connect(gainNode);
  gainNode.connect(audioContext.destination);

  oscillator.start();
  setTimeout(() => {
    gainNode.gain.exponentialRampToValueAtTime(0.001, audioContext.currentTime + 1);
    oscillator.stop(audioContext.currentTime + 1);
  }, 100);
};

export const playToneJsTone = (frequency) => {
  const oscillator = new Tone.Oscillator(frequency, "sine");
  const gainNode = new Tone.Gain(0.1);

  oscillator.connect(gainNode);
  gainNode.toDestination();

  oscillator.start();
  setTimeout(() => {
    gainNode.gain.exponentialRampToValueAtTime(0.001, `+1`);
    oscillator.stop(`+1`);
  }, 100);
};

export const encodePattern = (strummingPattern, savedPatternCount) => ({
  id: strummingPattern.id <= 0 ? generateRandomID(12) : strummingPattern.id,
  name: `Your Saved Pattern #${savedPatternCount + 1}`,
  pattern: getEncodedPatternString(strummingPattern),
});

export const getEncodedPatternString = (strummingPattern) => {
  let pattern = "";

  // console.log(strummingPattern);

  for (let i = 0; i < 8; i++) {
    if (strummingPattern[i] === "down") {
      pattern += "1";
    } else if (strummingPattern[i] === "up") {
      pattern += "2";
    } else if (strummingPattern[i] === "hit") {
      pattern += "3";
    } else if (strummingPattern[i] === "mute") {
      pattern += "4";
    } else {
      pattern += "0";
    }

    if ((i + 1) % 2 === 0 && i !== 7) {
      pattern += "-";
    }
  }

  return pattern;
};

export const getDecodedPatternObject = (strummingPattern) => {
  let currentIndex = 0;
  let result = {};

  for (let i = 0; i < strummingPattern.length; i++) {
    const character = strummingPattern[i];

    if (character === "-") continue;

    if (character === "1") {
      result[currentIndex] = "down";
    } else if (character === "2") {
      result[currentIndex] = "up";
    } else if (character === "3") {
      result[currentIndex] = "hit";
    } else if (character === "4") {
      result[currentIndex] = "mute";
    } else {
      result[currentIndex] = null;
    }

    currentIndex++;
  }

  return result;
};

export const decodePattern = (strummingPattern) => {
  let result;

  if (typeof strummingPattern === "object") {
    result = getDecodedPatternObject(strummingPattern.pattern);
    result["id"] = strummingPattern.id;
    result["name"] = strummingPattern.name;
    result["pattern_readable"] = strummingPattern.pattern_readable;
    result["saved"] = strummingPattern.saved || false;
    result["popular"] = strummingPattern.popular || false;
    result["itemable_id"] = strummingPattern.itemable_id || null;
  } else {
    result = getDecodedPatternObject(strummingPattern);
  }

  return result;
};

export const generateRandomID = (length) => {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  let randomID = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomID += characters[randomIndex];
  }

  return randomID;
};

export const replaceLastUrlSegment = (value) => {
  const url = new URL(window.location.href);
  const pathSegments = url.pathname.split("/");
  // console.log(pathSegments);
  if (pathSegments[pathSegments.length - 1] === "strumming-machine") {
    pathSegments.push(value);
  } else {
    pathSegments[pathSegments.length - 1] = value;
  }
  url.pathname = pathSegments.join("/");
  window.history.replaceState(null, "", url.href);
};

export const getInterval = (tempo) => {
  return 60000 / (tempo || 60) / 2;
};
