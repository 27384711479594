import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { clearAlert, openAlert, openDialog, setLoading } from "../../components/shared/ui/uiSlice";
import { withInnerWidth } from "../../components/shared";
import { setActiveTab } from "../store/dashboardStore";
import Profile from "./Profile";
import Password from "./Password";
import Preferences from "./Preferences";
import Receipts from "./Receipts";
import Purchases from "./Purchases";
import Donations from "./Donations/Donations";
import Tabs from "../shared/Tabs";
import Snackbar from "../../components/shared/ui/Snackbar";

const TABS = [
  { name: "Profile", nameShort: "Profile" },
  { name: "Password", nameShort: "Password" },
  { name: "Preferences", nameShort: "Preferences" },
  // { name: "Receipts", nameShort: "Receipts" },
  { name: "Purchases", nameShort: "Purchases" },
  { name: "Donations", nameShort: "Donations" },
];

const AccountTab = (props) => {
  const getTabByName = (name) => {
    const tab = TABS.find((tab) => tab.name.toLowerCase() === name.toLowerCase());

    return tab ? tab : TABS[0];
  };

  const [activeTab, setActiveTab] = useState(
    getTabByName(location.hash.replace("-", " ").slice(1))["name"]
  );

  const [forceRenderCounter, setForceRenderCounter] = useState(0);

  const goToTab = (tabName) => {
    location.hash = `${tabName.replace(" ", "-").toLowerCase()}`;
    setForceRenderCounter(forceRenderCounter + 1);
    setActiveTab(tabName);

    if (tabName == "Donations") {
      setShowDonationSection(false);
      setShowHistorySection(false);
    }
  };

  const [showDonationSection, setShowDonationSection] = useState(false);
  const [showHistorySection, setShowHistorySection] = useState(false);

  const goBack = () => {
    if (activeTab == "Donations" && (showDonationSection || showHistorySection)) {
      setShowDonationSection(false);
      setShowHistorySection(false);
    } else {
      props.setActiveTab("Dashboard");
    }
  };

  const getBreadcrumbName = () => {
    if (activeTab == "Donations" && (showDonationSection || showHistorySection)) {
      return "Donations";
    } else {
      return "Dashboard";
    }
  };

  return (
    <React.Fragment key={forceRenderCounter}>
      <button className="back-btn" onClick={() => goBack()}>
        <i className="icon icon-arrow-left"></i> {getBreadcrumbName()}
      </button>
      <h1 className="dashboard__heading">
        <button className="back-btn" onClick={() => goBack()}>
          <i className="icon icon-arrow-left"></i>
        </button>
        Account
      </h1>
      <div className="dashboard__content__tabs">
        <Tabs tabs={TABS} activeTab={activeTab} goToTab={goToTab} width={props.width} />
        <div className="tab-container account">
          {activeTab == "Password" ? (
            <Password width={props.width} user={props.user} openAlert={props.openAlert} />
          ) : activeTab == "Preferences" ? (
            <Preferences width={props.width} user={props.user} openAlert={props.openAlert} />
          ) : activeTab == "Receipts" ? (
            <Receipts width={props.width} />
          ) : activeTab == "Purchases" ? (
            <Purchases width={props.width} user={props.user} pageParts={props.pageParts} />
          ) : activeTab == "Donations" ? (
            <Donations
              user={props.user}
              width={props.width}
              donation={props.donation}
              showDonationSection={showDonationSection}
              setShowDonationSection={setShowDonationSection}
              showHistorySection={showHistorySection}
              setShowHistorySection={setShowHistorySection}
            />
          ) : (
            <Profile
              user={props.user}
              token={props.token}
              openAlert={props.openAlert}
              openDialog={props.openDialog}
            />
          )}
        </div>
      </div>
      <Snackbar />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.session.currentUser,
  token: state.session.token,
  pageParts: state.pageParts,
  donation: state.entity.donation,
});

const mapDispatchToProps = (dispatch) => ({
  openDialog: (text) => dispatch(openDialog(text)),
  openAlert: (alert) => dispatch(openAlert(alert)),
  clearAlert: () => dispatch(clearAlert()),
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withInnerWidth(AccountTab));
