import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosWithCSRF from "../../../shared/axiosWithCSRF";

import debounce from "lodash.debounce";

import { selectSearchQuery, selectBasicSearchUrl } from "./searchSelectors";
import { groupSearchResults } from "../../../shared/search";

const DEBOUNCE_TIME_MS = 250;

const debouncedFetchSearchResults = debounce((getState, onDone, onError) => {
  const state = getState();
  const query = selectSearchQuery(state);

  if (query.length < 3) {
    // min query length for PgSearch is 3 chars
    return {};
  }

  axiosWithCSRF()
    .get(selectBasicSearchUrl(state))
    .then(({ data: { items } }) => items)
    .then((items) => groupSearchResults(items))
    .then(onDone)
    .catch(onError);
}, DEBOUNCE_TIME_MS);

export const fetchSearchResults = createAsyncThunk(
  "search/fetchResults",
  (_, { getState }) =>
    new Promise((resolve, reject) => debouncedFetchSearchResults(getState, resolve, reject))
);
