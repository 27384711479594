import React from "react";
import { formattedDuration } from "../PracticeTabFunctions";
import TimerControls from "../Play/TimerControls";
import ItemImages from "../Play/ItemImages";
import NoteContainer from "../../UserNotes/NoteContainer";
import MetronomeToggle from "../Play/MetronomeToggle";
import RelatedVideo from "../Play/RelatedVideo";
import PracticeVideoModal from "./PracticeVideoModal";

export default class ActiveItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemOpen: true,
    };
  }

  componentDidMount() {
    let lastSession = this.getMostRecentStat();
  }

  getMostRecentStat = () => {
    let itemId = this.props.item.id;
    let practicedItems = this.props.practiceSessionItems.filter(
      (item) => item.user_practice_item_id === itemId
    );
    if (practicedItems.length > 0) {
      let withStat = practicedItems.filter(
        (item) => item.statistic != null && parseInt(item.statistic) > 0
      );
      if (withStat.length > 0) {
        withStat.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));
        this.setState({ latestStat: withStat[0] });
      }
    }
  };

  latestStat = () => {
    let { latestStat } = this.state;
    let { item } = this.props;
    if (latestStat || item.metronome_speed) {
      return (
        <div className="last-stat">
          <div>
            <div className="last-stat__top">
              <i className="icon icon-stats-dots"></i>
              <div className="last-stat__heading">
                <span>Stats</span>
              </div>
            </div>

            <div className="last-stat__stat">
              {latestStat && (
                <div>
                  <span>
                    The last stat you recorded for this practice item is:{" "}
                    <span className="last-stat__bold">{latestStat.statistic}</span>
                  </span>
                </div>
              )}
              {item.metronome_speed && (
                <div>
                  <span>
                    The last tempo you saved for this practice item is:{" "}
                    <span className="last-stat__bold">{item.metronome_speed} BPM</span>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  itemDescription = () => {
    let { item } = this.props;
    let itemDescription;
    if (item.description && item.description.length > 0) {
      itemDescription = item.description;
    } else {
      itemDescription = "You haven't added a description for this item yet.";
    }

    return (
      <div className="item-desc">
        <div>
          <div className="item-desc__top">
            <i className="icon icon-notification"></i>
            <div className="item-desc__heading">
              <span>Things to Remember</span>
            </div>
          </div>

          <div className="item-desc__desc">
            <span>{itemDescription}</span>
          </div>
        </div>
      </div>
    );
  };

  toggleItem = () => {
    this.setState({ itemOpen: !this.state.itemOpen });
  };

  itemImages = () => {
    let { item } = this.props;
    if (item.images && item.images.length > 0) {
      return <ItemImages images={item.images} />;
    }
  };

  toggleMetronome = () => {
    this.setState({ metronomeOpen: !this.state.metronomeOpen });
  };

  toggleVideo = () => {
    this.setState({ videoOpen: !this.state.videoOpen });
  };

  relatedLink = () => {
    let link = this.props.item.related_link;
    return !link.startsWith("/") && !link.includes("http") ? `/${link}` : link;
  };

  optionsOpen = () => {
    return this.state.metronomeOpen === true || this.state.videoOpen === true;
  };

  flickerBackground = () => {
    this.setState({ flickerBackground: true });
  };

  onTimerEnd = (flickerBackground) => {
    this.setState({
      flickerBackground: flickerBackground,
      metronomeDisabled: true,
    });
  };

  render() {
    let { item, index } = this.props;
    return (
      <div className="practice__outer" key={index}>
        <div className={"routine-timer " + (this.state.flickerBackground ? "flicker-light" : "")}>
          <div className="practicing__list-item practicing__list-item--active">
            <div className="current-item practicing__list-item__inner" key={index}>
              <div className="icon-and-text">
                <span className="items-list__number">{index + 1}</span>
                <span onClick={this.toggleItem}>{item.title}</span>
              </div>

              <span className="practicing__list-duration">
                {formattedDuration(item)}
                <span className="icon icon-clock2"></span>
              </span>
            </div>

            <div className="routine-inner">
              <div className="routine-timer-top">
                <div className="current-practice-item__inner">
                  <TimerControls
                    item={this.props.item}
                    isRunning={this.state.isRunning}
                    setIsRunning={this.setIsRunning}
                    onTimerEnd={this.onTimerEnd}
                    logPractice={this.props.logPractice}
                    videoPlayAlongIsActive={this.props.videoPlayAlongIsActive}
                  />

                  <div className="current-practice-item__right">
                    <div>
                      <NoteContainer
                        item={this.props.item}
                        userId={this.props.user.id}
                        updateNoteInPracticeItem={this.props.updateNoteInPracticeItem}
                      />
                    </div>

                    {this.latestStat()}

                    {this.itemDescription()}
                  </div>
                </div>
              </div>

              {this.props.item.related_link && (
                <p className="related-practice-link">
                  <a href={this.relatedLink()} target="_blank">
                    Related Link
                  </a>
                </p>
              )}

              {this.itemImages()}

              <div
                className={
                  "practice-item__toggle-options " +
                  (this.optionsOpen() ? "practice-item__toggle-options--open" : "")
                }
              >
                <MetronomeToggle
                  saveMetronomeSpeed={this.props.saveMetronomeSpeed}
                  unsavedMetronomeSpeedChange={this.props.unsavedMetronomeSpeedChange}
                  user={this.props.user}
                  item={this.props.item}
                  metronomeOpen={this.state.metronomeOpen}
                  toggleMetronome={this.toggleMetronome}
                  metronomeDisabled={this.state.metronomeDisabled}
                />
                <RelatedVideo
                  item={this.props.item}
                  toggleVideo={this.toggleVideo}
                  videoOpen={this.state.videoOpen}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
