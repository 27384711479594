import React from "react";
import axios from "axios";
import "react-dates/initialize";
import * as moment from "moment";
import DateRangePicker from "react-dates/lib/components/DateRangePicker";
import StatisticsChart from "../StatsTab/StatisticsChart";
import AppConfig from "../../../../../packs/AppConfig.js";
import StatisticsDetails from "./StatisticsDetails";
import { practiceSum, filterItems } from "./StatsFunctions";
import LineChart from "./LineChart";
import AdminIntroduction from "../General/AdminIntroduction";
import PracticeIcons from "../PracticeIcons";
import EditStats from "./EditStats";

export default class StatisticsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let end = moment(this.currentDay());
    let start = moment(this.oneWeekAgo());
    this.setState({
      endDate: end,
      startDate: start,
      filteredEnd: end,
      filteredStart: start,
      statType: "time",
    });
  }

  currentDay() {
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    return now;
  }

  oneWeekAgo() {
    let weekAgo = new Date();
    weekAgo.setDate(weekAgo.getDate() - 6);
    weekAgo.setHours(0, 0, 0, 0);
    return weekAgo;
  }

  formatDate(item) {
    if (window.innerWidth > 576) {
      let d = new Date(item);
      let day = { weekday: "long" };
      var monthYear = { month: "short", day: "numeric" };
      let first = d.toLocaleDateString("en-US", day);
      let second = d.toLocaleDateString("en-US", monthYear);
      return `${first}\n${second}`;
    } else {
      let d = new Date(item);
      let day = { weekday: "short" };
      let formatDay = d.toLocaleDateString("en-US", day);
      return formatDay;
    }
  }

  formatTime(seconds) {
    if (seconds < 60) {
      return seconds === 0 ? "" : `${seconds} seconds`;
    } else if (seconds < 3600) {
      let mins = Math.floor(seconds / 60);
      let secs = seconds - mins * 60;
      return secs === 0 ? `${mins} mins` : `${mins} mins and ${secs} sec`;
    } else {
      let hours = Math.floor(seconds / 3600);
      let mins = Math.floor((seconds - hours * 3600) / 60);
      let hourText = hours === 1 ? "hour" : "hours";
      let minText = mins === 1 ? "min" : "mins";
      return `${hours} ${hourText} ${mins} ${minText}`;
    }
  }

  toggle = (item) => {
    this.setState({ [item]: !this.state[item] });
  };

  editStatsButton = () => {
    return (
      <button className="stats__toggle" onClick={() => this.setStatType("edit")}>
        edit stats <i className="icon icon-pencil"></i>
      </button>
    );
  };

  recordedStatsButton = () => {
    return (
      <button className="stats__toggle" onClick={() => this.setStatType("numbers")}>
        view recorded stats <i className="icon icon-stats-dots"></i>
      </button>
    );
  };

  practiceTimeButton = () => {
    return (
      <button className="stats__toggle" onClick={() => this.setStatType("time")}>
        view practice time <i className="icon icon-clock2"></i>
      </button>
    );
  };

  statTypeSelect = () => {
    if (this.state.statType === "time") {
      return (
        <div>
          {this.recordedStatsButton()}
          {this.editStatsButton()}
        </div>
      );
    } else if (this.state.statType === "numbers") {
      return (
        <div>
          {this.practiceTimeButton()}
          {this.editStatsButton()}
        </div>
      );
    } else if (this.state.statType === "edit") {
      return (
        <div>
          {this.recordedStatsButton()}
          {this.practiceTimeButton()}
        </div>
      );
    }
  };

  setStatType = (statType) => {
    this.setState({ statType });
  };

  filterByDate = () => {
    let { startDate, endDate, filteredStart, filteredEnd, isSubmitting } = this.state;
    if (isSubmitting === true || (startDate === filteredStart && endDate === filteredEnd)) {
      return;
    } else {
      let startString = moment.utc(startDate).toDate().toDateString();
      let endString = moment.utc(endDate).toDate().toDateString();
      this.setState({ isSubmitting: true }, () => {
        axios
          .get(`/users/${this.props.user.id}/user_practice_session_items`, {
            params: {
              start_date: startString,
              end_date: endString,
              authenticity_token: ReactOnRails.authenticityToken(),
            },
          })
          .then((res) => {
            let items = res.data.items;
            this.setState(
              { filteredEnd: endDate, filteredStart: startDate, isSubmitting: null },
              () => {
                this.props.updateFilteredStatistics(items);
              }
            );
          })
          .catch(() => {
            this.handleFilterError();
          });
      });
    }
  };

  handleFilterError = () => {
    this.setState(
      {
        filterError: "There was a problem retrieving your stats. Please try again later.",
        isSubmitting: null,
      },
      () => {
        setTimeout(() => this.setState({ filterError: null }), 3000);
      }
    );
  };

  filterError = () => {
    if (this.state.filterError) {
      return (
        <p className="has-errors" style={{ textAlign: "center", margin: "12px 0" }}>
          {" "}
          {this.state.filterError}
        </p>
      );
    } else {
      return null;
    }
  };

  onlyPrevious(date) {
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    let d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d <= now ? false : true;
  }

  daysToShow = () => {
    if (this.state.filteredStart && this.state.filteredEnd) {
      const MONTHS = AppConfig.data.months;
      let start = new Date(this.state.filteredStart);
      let end = new Date(this.state.filteredEnd);

      return `From ${MONTHS[start.getMonth()]} ${start.getDate()} to ${
        MONTHS[end.getMonth()]
      } ${end.getDate()}: `;
    } else {
      return "Last 7 days: ";
    }
  };

  setTotalTime = (duration) => {
    this.setState({ practiceDuration: duration });
  };

  statsHeader = (total) => {
    let { startDate, endDate, statType } = this.state;
    if (["numbers", "edit"].includes(statType)) {
      return null;
    } else if (startDate && endDate) {
      let totalTime = this.formatTime(total);
      return (
        <div className="stats-header">
          <span className="stats-header__dates">{`From ${this.formatDate(
            startDate
          )} to ${this.formatDate(endDate)}`}</span>
          <span> you practiced </span>
          <span
            className="stopwatch"
            dangerouslySetInnerHTML={{ __html: PracticeIcons.activeStopwatchIcon() }}
          />
          <span className="stats-header__total"> {totalTime === "" ? "None" : totalTime}</span>
        </div>
      );
    }
  };

  dateFilter = () => {
    let prevClass =
      "DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5";
    let nextClass =
      "DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5";
    return (
      <div className="datepicker__container">
        <DateRangePicker
          startDate={this.state.startDate}
          isOutsideRange={(date) => this.onlyPrevious(date)}
          minimumNights={0}
          startDateId="unique_start_date_id"
          endDate={this.state.endDate}
          openDirection={"up"}
          navPrev={
            <span
              className={prevClass}
              dangerouslySetInnerHTML={{ __html: PracticeIcons.arrowWithCircleIcon() }}
              style={{ maxWidth: "35px", width: "auto", display: "inline" }}
            />
          }
          navNext={
            <span
              className={nextClass}
              dangerouslySetInnerHTML={{ __html: PracticeIcons.arrowWithCircleIcon() }}
              style={{
                maxWidth: "35px",
                width: "auto",
                display: "inline",
                transform: "rotate(180deg)",
              }}
            />
          }
          customArrowIcon={<span dangerouslySetInnerHTML={{ __html: PracticeIcons.arrowIcon() }} />}
          numberOfMonths={1}
          endDateId="your_unique_end_date_id"
          onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
          focusedInput={this.state.focusedInput}
          onFocusChange={(focusedInput) => this.setState({ focusedInput })}
        />

        <button
          className="datepicker__filter"
          disabled={this.state.isSubmitting}
          type="button"
          onClick={this.filterByDate}
        >
          <span
            className="filter-icon"
            dangerouslySetInnerHTML={{ __html: PracticeIcons.filterIcon() }}
          />{" "}
          FILTER
        </button>
      </div>
    );
  };

  currentStatChart = () => {
    if (this.state.statType === "time") {
      return (
        <div>
          <StatisticsChart
            items={this.props.items}
            startDate={this.state.filteredStart}
            endDate={this.state.filteredEnd}
            formatTime={this.formatTime}
          />
          <div className="practice-stats-outer">
            <StatisticsDetails
              items={this.props.items}
              startDate={this.state.filteredStart}
              endDate={this.state.endDate}
            />
          </div>
        </div>
      );
    } else if (this.state.statType === "numbers") {
      return (
        <LineChart
          items={this.props.items}
          startDate={this.state.filteredStart}
          endDate={this.state.filteredEnd}
          formatTime={this.formatTime}
        />
      );
    } else if (this.state.statType === "edit") {
      return (
        <EditStats
          items={this.props.items}
          handleUpdatedStatItem={this.props.handleUpdatedStatItem}
          handleDeletedStatItem={this.props.handleDeletedStatItem}
        />
      );
    }
  };

  render() {
    return (
      <div>
        <AdminIntroduction intro={this.props.adminIntroduction} />
        <span className="practice__heading">Your Stats</span>
        {this.statsHeader(practiceSum(this.props.items))}
        {this.statTypeSelect()}
        {this.currentStatChart()}
        {this.filterError()}
        {this.dateFilter()}
      </div>
    );
  }
}
