import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import { PromoItemEntity } from "../interfaces/PromoItem";

const basePath = "/justins-corner";

export const fetchPromoItems = (
  limit: number = 50,
  target: string = "songs_and_lessons",
  promoItemId: number | null = null
) =>
  axiosWithCSRF().get<{ data: PromoItemEntity }>(
    `${basePath}?limit=${limit}&target=${target}${promoItemId ? `&id=${promoItemId}` : ""}`,
    {
      headers: {
        Accept: "application/json;charset=UTF-8",
      },
    }
  );
