import React from "react";
import { connect } from "react-redux";
import "./store/playgroundStore";
import { withBootstrapSize } from "../../components/shared/WithBootstrapSize";
import { createLessonItem, deleteLessonItem } from "../../components/shared/session/sessionEffects";
import IndividualPlaygroundPage from "./IndividualPlaygroundPage";
import PlaygroundCategoriesList from "./PlaygroundCategoriesList";

export const ArrowIcon = () => (
  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.37114e-07 7L14.17 7L10.59 10.59L12 12L18 6L12 -5.24537e-07L10.59 1.41L14.17 5L6.11959e-07 5L4.37114e-07 7Z"
      fill="#FD6A02"
    />
  </svg>
);

const PlaygroundRoot = ({
  bootstrapSize,
  currentUser,
  playground,
  createLessonItem,
  deleteLessonItem,
}) => {
  const categories = playground.data.attributes.groupOrder
    .map((groupId) => {
      return playground.included.find(
        (group) => parseInt(group.id) === parseInt(groupId) && group.type === "group"
      );
    })
    .filter((group) => group);

  const urlPath = window.location.pathname.split("/");
  const categoryPath = urlPath.length === 3 ? urlPath[2] : null;
  const category = categories.find((category) => category.attributes.slug === categoryPath);

  return (
    <div className="playground">
      {category ? (
        <IndividualPlaygroundPage
          parentGroupId={playground.data.id}
          category={category}
          currentUser={currentUser}
          bootstrapSize={bootstrapSize}
          createLessonItem={createLessonItem}
          deleteLessonItem={deleteLessonItem}
        />
      ) : (
        <PlaygroundCategoriesList
          bootstrapSize={bootstrapSize}
          categories={categories}
          currentUser={currentUser}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.currentUser,
    playground: state.entity.playground,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createLessonItem: (lessonId, status) => dispatch(createLessonItem(lessonId, status)),
  deleteLessonItem: (lessonId, status) => dispatch(deleteLessonItem(lessonId, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withBootstrapSize(PlaygroundRoot));
