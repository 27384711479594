import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { InfiniteScroll } from "../../shared/InfiniteScroll";
import {
  fetchSongRequests,
  resultMergeStrategies,
  selectPendingSongRequestsArray,
  selectSongRequestsListingLoading,
  selectSongRequestsMetadata,
  selectSongRequestsQuery,
  slices,
} from "../store";
import SongRequestListing from "./SongRequestListing";

export default function () {
  const songRequests = useSelector(selectPendingSongRequestsArray);
  const loading = useSelector(selectSongRequestsListingLoading);
  const metadata = useSelector(selectSongRequestsMetadata);
  const query = useSelector(selectSongRequestsQuery);
  const dispatch = useDispatch();
  const hasQuery = query != null && query.length > 0;

  const setQuery = (query) => {
    dispatch(slices.ui.actions.setSongRequestsQuery(query));
    dispatch(slices.metadata.actions.setPage({ entity: "songRequests", page: 1 })); // NB: pages are 1-indexed in rails (apparently)
    if (query.length > 2 || query.length === 0) {
      dispatch(
        fetchSongRequests({
          page: metadata.page,
          query,
          mergeStrategy: resultMergeStrategies.replace,
        })
      );
    }
  };

  return (
    <>
      <div className="query-bar">
        <div className="query-bar__inner">
          <i className="icon icon-search-thin" />
          <input type="text" value={query} onChange={({ target }) => setQuery(target.value)} />
          <i
            className={"icon icon-cross " + (hasQuery ? "" : "disabled")}
            onClick={() => (hasQuery ? setQuery("") : null)}
          />
        </div>
      </div>

      {songRequests.length === 0 && hasQuery ? (
        <div className="no-results">No results for current search query</div>
      ) : (
        <InfiniteScroll
          enableInfiniteScroll={false}
          loading={loading}
          metadata={metadata}
          fetchPage={(page) => dispatch(fetchSongRequests({ page, query }))}
        >
          <SongRequestListing songRequests={songRequests} />
        </InfiniteScroll>
      )}
    </>
  );
}
