import React from "react";
import { productSubtypes, productTypes, productsFilteredByType } from "./productTypes";
import ProductList from "./ProductList";

export default function ({ products }) {
  const premiumCourses = productsFilteredByType(products, productTypes.digital);

  return (
    <div className="products-listing products-listing--premium-courses container">
      <h2>Premium Courses</h2>
      <ProductList products={premiumCourses} />
    </div>
  );
}
