import { Components } from "@mui/material";
import { jgDenimColors } from "../../jg-denim-colors";

export const outlinedInput: Components["MuiOutlinedInput"] = {
  styleOverrides: {
    root: {
      borderRadius: "70px",
      backgroundColor: jgDenimColors.white,
      border: "none", // outlining with shadow instead
      boxShadow: "inset 0px 0px 14px rgba(64, 110, 142, 0.3)",
    },
    input: {
      padding: "11px 20px",
      fontSize: "16px",
      lineHeight: "22px",
    },
    multiline: {
      borderRadius: "10px",
      padding: 2,
      minHeight: "100px",
      alignItems: "flex-start",
    },
  },
  defaultProps: {
    fullWidth: true,
  },
};
