import { CDN_URL } from "../shared/CdnUrl";
import { COMMUNITY_URL } from "../shared/Community";

export const headerMenuItems = {
  menu: {
    items: [
      {
        title: "Courses",
        link: "/guitar-lessons",
        dropdown: true,
        sections: [
          {
            title: "By Level",
            subItems: [
              {
                title: "Beginner Grade 1",
                link: "/classes/beginner-guitar-course-grade-one",
                borderColor: "#DADADA",
              },
              {
                title: "Beginner Grade 2",
                link: "/classes/beginner-guitar-course-grade-two",
                borderColor: "#F9ED32",
              },
              {
                title: "Beginner Grade 3",
                link: "/classes/beginner-guitar-course-grade-three",
                borderColor: "#FBB040",
              },
              {
                title: "Intermediate Grade 4",
                link: "/classes/intermediate-guitar-course-grade-four",
                borderColor: "#3CB2A9",
              },
              {
                title: "Intermediate Grade 5",
                link: "/classes/intermediate-guitar-course-grade-five",
                borderColor: "#3A7FD2",
              },
              {
                title: "Intermediate Grade 6",
                link: "/classes/intermediate-guitar-course-grade-six",
                borderColor: "#8A4ECF",
              },
              {
                title: "Advanced Grade 7",
                link: "/classes/advanced-guitar-course-grade-seven",
                borderColor: "#EF4136",
              },
              {
                title: "Advanced Grade 8",
                link: null,
                borderColor: "#8A4223",
              },
              {
                title: "Advanced Grade 9",
                link: null,
                borderColor: "#322634",
              },
            ],
          },
          {
            title: "For Skills",
            subItems: [
              {
                title: `Practical Music Theory <img src="${CDN_URL}/redesign/2020/header/dollar-sign.svg" />`,
                link: "/dynamic_menu_items/pmt",
              },
              {
                title: `Blues Immersion <img src="${CDN_URL}/redesign/2020/header/dollar-sign.svg" />`,
                link: "/dynamic_menu_items/blim",
              },
              {
                title: `Strumming <img src="${CDN_URL}/redesign/2020/header/dollar-sign.svg" />`,
                link: "/dynamic_menu_items/sos",
              },
              {
                title: "Essential Knowledge",
                link: "/classes/knowledge-base",
              },
              { title: "Scales & Modes", link: "/classes/scales-modes" },
              { title: "Chords", link: "/classes/chords" },
              {
                title: "Guitars, Amps & Effects",
                link: "/classes/guitars-amps-effects",
              },
              { title: "Arpeggios", link: "/classes/arpeggios" },
              { title: "Technique", link: "/classes/technique-study" },
              { title: "Ear Training", link: " /classes/ear-training" },
              { title: "Transcribing", link: "/classes/transcribing" },
              // { title: "Songwriting", link: "/modules/songwriting" },
              { title: "Production", link: "/classes/production" },
              { title: "Ukulele", link: "/classes/ukulele" },
            ],
          },
        ],
      },
      {
        title: "Songs",
        dropdown: false,
        link: "/songs",
      },
      {
        title: "Tools",
        dropdown: true,
        subItems: [
          { title: "Strumming Machine", link: "/strumming-machine" },
          { title: "Chord Library", link: "/chords" },
          // { title: "Interval Ear Trainer", link: "/interval-ear-trainer" },
          { title: "Metronome", link: "/metronome" },
          { title: "Tempo Calculator", link: "/tap-tempo-bpm-tool" },
          {
            title: "Blank Papers",
            link: "/modules/print-blank-tab-manuscript",
          },
        ],
      },
      {
        title: "Explore",
        dropdown: true,
        sections: [
          {
            title: "Playground",
            subItems: [
              {
                title: "Quick Tips",
                link: "/playground/quick-tips",
              },
              {
                title: "Rut Busters",
                link: "/playground/rut-busters",
              },
              {
                title: "Food for Thought",
                link: "/playground/food-for-thought",
              },
              {
                title: "Interviews",
                link: "/playground/interviews",
              },
              {
                title: "Tracks & Playlists",
                link: "/playground/tracks-and-playlists",
              },
              {
                title: "View All",
                link: "/playground",
                // mainNavigation: true,
              },
              // { title: "ANSWRD", link: "/modules/guitar-answers" },
              // { title: "Live Streams", link: "/modules/live-stream-sessions" },
              // { title: "Food for Thought", link: "/modules/food-for-thought" },
            ],
          },
          {
            title: "Interact",
            subItems: [
              {
                title: "Community",
                link: COMMUNITY_URL,
                target: "_blank",
              },
              {
                title: "Clubs",
                link: "/clubs",
              },
              {
                title: "Student Performances",
                link: "/student-performances",
              },
              {
                title: "1:1 with Justin",
                link: "/private-one-on-one-lessons",
              },
              {
                title: "Approved Teachers",
                link: "/teachers",
              },
              {
                title: "Workshops & Events",
                link: "/workshops",
              },
              {
                title: "Playin' It Forward",
                link: "/playin-it-forward",
              },
              {
                title: "FAQ",
                link: "/faq",
              },
              {
                title: "Contact",
                link: "/contact",
              },
            ],
          },
          {
            title: "About Us",
            subItems: [
              { title: "About Justin", link: "/about" },
              { title: "The Team", link: "/team" },
              { title: "Donate", link: "/donate", variant: "emphasized" },
            ],
          },
        ],
      },
      {
        title: "Store",
        link: "/store",
        dropdown: true,
        sections: [
          {
            title: "store categories",
            subItems: [
              { title: "Website Products", link: "/store/website-products" },
              { title: "Physical Products", link: "/store/physical-products" },
              { title: "Downloads", link: "/store/downloads" },
              { title: "Apps", link: "/store/apps" },
              { title: "Find What’s Best For Me", link: "/store/find-whats-best-for-me#choose" },
              { title: "View All", link: "/store" },
            ],
          },
        ],
      },
    ],
  },
};

export const sideMenuItems = {
  menu: {
    sections: [
      {
        title: "menu",
        items: [
          ...headerMenuItems.menu.items,
          {
            title: "Playground",
            dropdown: false,
            link: "/playground",
          },
          {
            title: "Strumming Machine",
            dropdown: false,
            link: "/strumming-machine",
          },
        ],
      },
      {
        title: "Popular",
        items: [
          {
            title: "Beginner Course Grade 1",
            link: "/classes/beginner-guitar-course-grade-one",
            borderColor: "#DADADA",
          },
          {
            title: "Beginner Course Grade 2",
            link: "/classes/beginner-guitar-course-grade-two",
            borderColor: "#F9ED32",
          },
          {
            title: "Beginner Course Grade 3",
            link: "/classes/beginner-guitar-course-grade-three",
            borderColor: "#FBB040",
          },
          {
            title: "JustinGuitar Lessons & Songs App",
            link: "/store/lessons-and-songs-app",
            borderColor: "#727E90",
            isNew: true,
          },
          {
            title: "Clubs",
            link: "/clubs",
            borderColor: "#727E90",
          },
        ],
      },
      {
        title: "Waiting List",
        items: [
          {
            title: "Blues Immersion",
            link: "/store/blues-immersion",
            borderColor: "#727E90",
          },
          {
            title: "Teacher Training",
            link: "/teacher-training",
            borderColor: "#727E90",
          },
        ],
      },
      {
        items: [
          { title: "FAQ", link: "/faq" },
          { title: "Donate", link: "/donate" },
        ],
      },
    ],
  },
};
