import React from 'react';
import Slider from 'react-slick'
import styled from '@emotion/styled';

interface SliderGenericProps<T> {
    items: T[];
    dots: boolean;
    hideArrows?: boolean;
    infinite: boolean;
    slidesToShow: number;
    slidesToScroll: number;
    variableWidth: boolean;
    centerMode?: boolean;
    centerPadding?: string;
    renderItem: (item: T, idx: number) => React.ReactElement | null;
}

const DotWidth = 30;
const ArrowPadding = DotWidth;

export function SliderGeneric<T>(props: React.PropsWithChildren<SliderGenericProps<T>>)
{

    const {
        items,
        dots,
        hideArrows,
        infinite,
        slidesToShow,
        slidesToScroll,
        variableWidth,
        centerMode,
        centerPadding,
        renderItem,
    } = props;


    return (
        <SliderContainer itemCount={items?.length || 0} dots={dots} hideArrows={hideArrows} slidesToShow={slidesToShow}>
            <Slider
            {...{
                dots,
                infinite: infinite === false ? false : true,
                slidesToShow: slidesToShow,
                slidesToScroll: slidesToScroll || 1,
                variableWidth: variableWidth === false ? false : true,
                centerMode: !!centerMode, 
                centerPadding: centerPadding || '0',
            }}
            >
                {!!items?.length && items.map((item, idx) => (
                    <ItemContainer slidesToShow={slidesToShow} key={idx}>
                        {renderItem(item, idx)}
                    </ItemContainer>
                ))}
            </Slider>
        </SliderContainer>
    );
}

const SliderContainer = styled('div')<{
    itemCount: number,
    dots: boolean,
    hideArrows?: boolean,
    slidesToShow: number,
}>(
({itemCount, dots, hideArrows, slidesToShow}) => ({
    display: 'block',
    minWidth: 0,
    width: 'calc(100vw - 30px)',
    maxWidth: '1200px',
    overflowX: 'hidden',
    paddingBottom: '60px', // dots
    '.slick-arrow': {
        position: 'absolute',
        bottom: '-42px',
        top: 'auto',
        zIndex: '1',
        display: hideArrows ? 'none !important' : 'block',
    },
    '.slick-prev': {
        left: dots 
            ? `calc(50% - ${itemCount * DotWidth / 2}px - ${ArrowPadding}px) !important`
            : `calc(50% - ${2 * ArrowPadding}px) !important`,
        right: 'auto !important',
    },
    '.slick-next': {
        right: dots
            ? `calc(50% - ${itemCount * DotWidth / 2}px - ${ArrowPadding}px) !important`
            : `calc(50% - ${2 * ArrowPadding}px) !important`,
        left: 'auto !important',
    },
    '.slick-track': {
        display: (slidesToShow === 1) ? 'inline-flex !important' : 'block',
    }
}));

const ItemContainer = styled('div')<{slidesToShow: number}>(({slidesToShow}) => ({
    width: (slidesToShow === 1) ? '100vw !important' : '100%',
    display: (slidesToShow === 1) ? 'inline-flex !important' : 'inline-block',
    justifyContent: 'center',
    minHeight: 0,
}));
