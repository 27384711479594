import React from "react";
import { CSSTransition } from "react-transition-group";

import { CDN_URL } from "../shared/CdnUrl";
import SongGridItemOverlay from "./SongGridItemOverlay";
import { storeSearchAndNavigateToItem } from "../shared/search";

export const SongImg = ({ song }) => (
  <div
    className={`song-image ${song.slug ? "clickable" : ""}`}
    onClick={() => {
      song.slug ? (window.location.href = `/songs/${song.slug}`) : null;
    }}
  >
    <img
      src={
        song.artistImageUid
          ? `${CDN_URL}/system/dragonfly/production/${song.artistImageUid}`
          : `${CDN_URL}/images/all-songs/album-placeholder.png`
      }
      alt={`${song.title} by ${song.artist}`}
    />
  </div>
);

export const SongDetails = ({ song }) => (
  <div
    className={`song-detail ${song.slug ? "clickable" : ""}`}
    onClick={() =>
      song.slug
        ? storeSearchAndNavigateToItem({
            title: song.title,
            slug: `/songs/${song.slug}`,
          })
        : null
    }
  >
    <div>
      <span className={`grade-badge grade-badge--grade-${song.gradeId}`}>
        {song.gradeId == null
          ? "No Grade"
          : song.gradeId === 10
          ? "Grade 0"
          : `Grade ${song.gradeId}`}
      </span>
    </div>
    <div className="song-grid-item__title">{song.title}</div>
    <div className="song-grid-item__artist">{song.artist}</div>
  </div>
);

export const SongsGridItem = ({ song, useOverlay = true }) => {
  const [hovered, setHoveredState] = React.useState(false);
  const setHovered = (hovered) => {
    useOverlay && setHoveredState(hovered);
  };
  return (
    <div
      className="song-grid-item"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="image-container">
        <SongImg song={song} />

        <CSSTransition
          unmountOnExit
          key={song.id}
          in={hovered}
          timeout={200}
          classNames="song-grid-item__overlay"
        >
          <SongGridItemOverlay song={song} songIsSaved={false} />
        </CSSTransition>
      </div>
      <SongDetails song={song} />
    </div>
  );
};

export default SongsGridItem;
