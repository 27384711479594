import { createSelector } from "@reduxjs/toolkit";

export const lessonSelector = (state) => state.entity.lesson;
export const userSelector = (state) => state.session.currentUser;
export const paywallSelector = (state) => state.entity.paywall;

export const groupSelector = createSelector(lessonSelector, (lesson) => {
  return lesson.included.find((i) => i.type === "group");
});

export const note = createSelector(userSelector, lessonSelector, (user, lesson) => {
  if (user) {
    const lessonId = lesson.data.attributes.id;
    return user.included.filter(
      (i) =>
        i.type === "userNote" &&
        i.attributes.noteableType === "Lesson" &&
        i.attributes.noteableId === lessonId
    )[0];
  }
});

export const practiceItems = createSelector(lessonSelector, (lesson) => {
  return lesson.included.filter((i) => i.type === "practiceItem");
});

export const orderedLessonsWithinModule = createSelector(
  lessonSelector,
  groupSelector,
  (lesson, group) => {
    if (group) {
      function itemIndex(id) {
        return group.attributes.lessonOrder.indexOf(id);
      }

      return [...lesson.data.attributes.lessonsWithinModule].sort((a, b) => {
        return itemIndex(a.id) > itemIndex(b.id) ? 1 : itemIndex(a.id) < itemIndex(b.id) ? -1 : 0;
      });
    }
  }
);

export const nextLesson = createSelector(
  lessonSelector,
  orderedLessonsWithinModule,
  (lesson, lessons) => {
    if (lessons) {
      const currentLesson = lessons.find((l) => l.id === parseInt(lesson.data.id));
      const currentIndex = lessons.indexOf(currentLesson);
      if (currentIndex >= 0 && currentIndex + 1 < lessons.length) {
        return lessons[currentIndex + 1];
      }
    }
  }
);

export const gradeNumberSelector = createSelector(lessonSelector, (lesson) => {
  const grade = lesson.included.find((i) => i.type === "grade");
  return grade ? grade.attributes.position : null;
});

export const downloads = createSelector(lessonSelector, (lesson) => {
  return lesson.included.filter((i) => i.type === "downloadable");
});

export const lockedLessonIds = createSelector(
  lessonSelector,
  paywallSelector,
  orderedLessonsWithinModule,
  (lesson, paywall, lessons) => {
    if (lesson.data.attributes.isPreview || paywall) {
      const lessonIds = lessons.map((l) => l.id);
      const numberOfPreviewableChildren = paywall
        ? paywall.data.attributes.paywallableType === "ParentGroup"
          ? 0
          : paywall.data.attributes.numberOfPreviewableChildren
        : lesson.data.attributes.numberOfPreviewableChildren;
      if (numberOfPreviewableChildren === 0) {
        return lessonIds;
      } else {
        return lessonIds.slice(numberOfPreviewableChildren);
      }
    } else {
      return [];
    }
  }
);
