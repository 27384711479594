import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchArtists,
  fetchSongs,
  listingStyles,
  selectArtistMetadata,
  selectArtistsLoading,
  selectCurrentArtist,
  selectListingArtists,
  slices,
} from "./store";
import Swoosh from "../shared/Swoosh";
import ArtistsSearch from "./ArtistsSearch";
import { ArtistFiltersButton, ArtistFiltersList } from "./ArtistFilters";
import { withBootstrapSize } from "../shared/WithBootstrapSize";
import { BootstrapSize, breakpoints } from "../shared/bootstrap-helpers";
import { InfiniteScroll } from "../shared/InfiniteScroll";
import ArtistSearchParamPane from "./ArtistSearchParamPane";
import Loading from "../shared/Loading";
import ArtistsSortBy from "./ArtistsSortBy";
import "../shared/polyfills/replaceAll.js";

const THUMBNAIL_PLACEHOLDER =
  "https://jtgt-static.b-cdn.net/images/all-songs/artist-placeholder.png";

export const ArtistThumbnail = ({ artist }) => {
  const handleClick = () => {
    window.location.href = `/artists/${artist.slug}`;
  };

  return (
    <div className="artist-thumbnail" style={{ cursor: "pointer" }} onClick={handleClick}>
      <img src={artist.thumbnailImageUrl || THUMBNAIL_PLACEHOLDER} alt={artist.name} />
      <div>{artist.name}</div>
    </div>
  );
};

const ArtistListing = () => {
  const artists = useSelector(selectListingArtists);
  const artistsMetadata = useSelector(selectArtistMetadata);
  const artistsLoading = useSelector(selectArtistsLoading);
  const dispatch = useDispatch();
  const fetchPage = (page) => {
    dispatch(slices.metadata.actions.setPage({ entity: "artists", page }));
    dispatch(fetchArtists());
  };

  return (
    <InfiniteScroll
      enableInfiniteScroll={false}
      metadata={artistsMetadata}
      fetchPage={fetchPage}
      loading={artistsLoading}
    >
      {artists.length === 0 ? (
        <div className="no-results">No results match your criteria</div>
      ) : (
        <div className="artists__listing">
          {artists.map((artist) => (
            <ArtistThumbnail artist={artist.attributes} key={artist.id} />
          ))}
        </div>
      )}
    </InfiniteScroll>
  );
};

export default withBootstrapSize(({ width, excludeTopMargin }) => {
  const [showSearchParamsPane, setShowSearchParamsPane] = React.useState(false);
  const metadata = useSelector(selectArtistMetadata);
  const loading = useSelector(selectArtistsLoading);
  const currentArtist = useSelector(selectCurrentArtist);

  return (
    currentArtist == null && (
      <Swoosh color="#F5F7FA" excludeTopMargin={excludeTopMargin}>
        <div className="artists container">
          <h2 className="header2">
            Artists
            {loading ? (
              <span style={{ position: "relative" }}>
                <Loading isInput={true} />
              </span>
            ) : (
              `(${metadata.totalCount})`
            )}
          </h2>
          <div className="artists__main">
            <div className="artists__controls">
              <ArtistsSearch />

              {width > breakpoints[BootstrapSize.lgDevice] ? (
                <>
                  <ArtistsSortBy />
                  <ArtistFiltersList buttonClassModifier="blue" />
                </>
              ) : width > breakpoints[BootstrapSize.smDevice] ? (
                <ArtistFiltersButton buttonClassModifier="blue" />
              ) : (
                <button
                  className="btn--filters button button--blue"
                  onClick={() => setShowSearchParamsPane(true)}
                >
                  <i className="icon icon-sliders" />
                </button>
              )}
            </div>

            {width <= breakpoints[BootstrapSize.lgDevice] &&
              width > breakpoints[BootstrapSize.smDevice] && <ArtistsSortBy />}

            <ArtistListing />

            {showSearchParamsPane && (
              <ArtistSearchParamPane close={() => setShowSearchParamsPane(false)} />
            )}
          </div>
        </div>
      </Swoosh>
    )
  );
});
