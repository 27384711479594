import React from "react";
import { Provider, useSelector } from "react-redux";
import { Box, Container, Grid, ThemeProvider, useMediaQuery } from "@mui/material";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";

import { getJgStore } from "../../../redux/jg-store";
import { WorkshopAbout } from "./WorkshopAbout";
import { WorkshopShowPageHeader } from "./WorkshopShowPageHeader";
import { WorkshopMap } from "./WorkshopMap";
import { RegisterYourInterestButton } from "./RegisterYourInterestButton";
import { WorkshopSchedule } from "./WorkshopSchedule";
import { workshopSelectors } from "../store/workshops.slice";
import { WorkshopTeam } from "./WorkshopTeam";
import { WorkshopPrice } from "./WorkshopPrice";
import { WorkshopsFaqs } from "../shared/WorkshopsFaqs";
import { ElectricJamCarousel } from "./ElectricJamCarousel";
import { AcousticJamCarousel } from "./AcousticJamCarousel";
import { LifestyleCarousel } from "./LifestyleCarousel";
import { FancyQuote } from "./FancyQuote";
import { AcousticJamQuebecCarousel } from "./AcousticJamQuebecCarousel";
import { WorkshopLocation } from "./WorkshopLocation";
import { AcousticElectricClassicsCarousel } from "./AcousticElectricClassicsCarousel";

const _WorkshopShowPage = ({ faqs }) => {
  const workshop = useSelector(workshopSelectors.selectCurrentWorkshop);
  const isMedium = useMediaQuery(jgDenimTheme.breakpoints.down("md"));

  const columnDirection = isMedium ? "column-reverse" : "row";

  if (workshop == null) return null; // todo: 404 page

  return (
    <div>
      <WorkshopShowPageHeader workshop={workshop} />
      <Container>
        <Grid container spacing={2} flexDirection={columnDirection}>
          <Grid item xs={12} md={7} lg={8}>
            <WorkshopAbout workshop={workshop} />
            {workshop.attributes.slug === "2023-Acoustic-Jam-Week" ? (
              <AcousticJamQuebecCarousel />
            ) : workshop.attributes.slug === "2023-Acoustic-Electric-Classics" ? (
              <AcousticElectricClassicsCarousel />
            ) : workshop.attributes.title.toLowerCase().includes("acoustic") ? (
              <AcousticJamCarousel />
            ) : (
              <ElectricJamCarousel />
            )}
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <Box ml={isMedium ? 0 : 4}>
              <RegisterYourInterestButton workshop={workshop} />
              <WorkshopMap workshop={workshop} />
            </Box>
          </Grid>
        </Grid>

        <WorkshopSchedule workshop={workshop} />
        <WorkshopPrice workshop={workshop} />
        <RegisterYourInterestButton workshop={workshop} />
        <WorkshopLocation workshop={workshop} />
        <LifestyleCarousel workshop={workshop} />
        <WorkshopTeam />

        <FancyQuote workshop={workshop} />

        <Box marginTop={4}>
          <WorkshopsFaqs faqs={faqs} />
        </Box>
      </Container>
    </div>
  );
};
// hooks don't work directly inside providers so we have to do it this way
export const WorkshopShowPage = ({ faqs }) => (
  <Provider store={getJgStore()}>
    <ThemeProvider theme={jgDenimTheme}>
      <_WorkshopShowPage faqs={faqs} />
    </ThemeProvider>
  </Provider>
);
