import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { fontFamilies } from "../../../jg-material/theme/typography";

const REASONS = [
  "Track your progress with a free and interactive Dashboard and Practice Assistant.",
  "Join our Community for support from JustinGuitar Guides and Approved Teachers!",
  "Unlock free tools like Strumming Machine and the interactive Songbook!",
];

const WhyRegister = () => {
  return (
    <Container>
      <Grid container mt={2} spacing={2} display="flex" justifyContent="center">
        {REASONS.map((reason, index) => (
          <Grid item sm={4} xs={12} key={index} sx={{ maxWidth: "250px" }}>
            <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center">
              <Grid item xs={"auto"}>
                <CheckCircleOutlineIcon style={{ fontSize: 40, color: "white" }} />
              </Grid>
              <Grid item xs={8}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "white",
                    fontWeight: "500",
                    fontFamily: fontFamilies.openSans,
                  }}
                >
                  {reason}
                </Typography>{" "}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default WhyRegister;
