// todo: put all this in the CDN and DB

import { CDN_URL } from "../../../components/shared/CdnUrl";

export const workshopImageBaseUrl = `${CDN_URL}/redesign/workshops/gallery/`;

export type WorkshopGalleryImages = {
  workshopTitle: string;
  imagePaths: string[];
};

export type WorkshopGalleryImagesByS3Path = Record<
  string,
  {
    workshopTitle: string;
    imagePaths: string[];
  }
>;

export const workshopGalleryImages: WorkshopGalleryImagesByS3Path = {
  "2013-Poggiolo-Electric-Workshop": {
    workshopTitle: "Poggiolo Electric Workshop 2013",
    imagePaths: [
      "2013-PoggioloElectricWorkshop-002.jpg",
      "2013-PoggioloElectricWorkshop-009.jpg",
      "2013-PoggioloElectricWorkshop-014.jpg",
      "2013-PoggioloElectricWorkshop-007.jpg",
      "2013-PoggioloElectricWorkshop-DSF7956.jpg",
      "2013-PoggioloElectricWorkshop-017.jpg",
      "2013-PoggioloElectricWorkshop-018.jpg",
      "2013-PoggioloElectricWorkshop-020.jpg",
      "2013-PoggioloElectricWorkshop-MG_2596.jpg",
      "2013-PoggioloElectricWorkshop-006.jpg",
    ],
  },
  "2013-Villa-Lina-Electric-Workshop": {
    workshopTitle: "Villa Lina Electric Workshop 2013",
    imagePaths: [
      "2013-VillaLinaElectricWorkshop-sept_009.jpg",
      "2013-VillaLinaElectricWorkshop-sept_013.jpg",
      "2013-VillaLinaElectricWorkshop-sept_004.jpg",
      "2013-VillaLinaElectricWorkshop-sept_002.jpg",
      "2013-VillaLinaElectricWorkshop-sept_017.jpg",
      "2013-VillaLinaElectricWorkshop-DSCF0395.jpg",
      "2013-VillaLinaElectricWorkshop-DSCF0515.jpg",
      "2013-VillaLinaElectricWorkshop-sept_010.jpg",
      "2013-VillaLinaElectricWorkshop-sept_006.jpg",
      "2013-VillaLinaElectricWorkshop-sept_003.jpg",
    ],
  },
  "2014-Poggiolo-Electric-Workshop": {
    workshopTitle: "Poggiolo Electric Workshop 2014",
    imagePaths: [
      "2014-PoggioloElectricWorkshop-IMG_9492.jpg",
      "2014-PoggioloElectricWorkshop-09.jpg",
      "2014-PoggioloElectricWorkshop-16.jpg",
      "2014-PoggioloElectricWorkshop-05.jpg",
      "2014-PoggioloElectricWorkshop-26.jpg",
      "2014-PoggioloElectricWorkshop-week_2.jpg",
      "2014-PoggioloElectricWorkshop-18.jpg",
      "2014-PoggioloElectricWorkshop-11.jpg",
      "2014-PoggioloElectricWorkshop-07.jpg",
      "2014-PoggioloElectricWorkshop-01.jpg",
    ],
  },
  "2015-Mike-Dawes-Fingerstyle-Acoustic-Guitar-Workshop": {
    workshopTitle: "Mike Dawes Fingerstyle Acoustic Guitar Workshop 2015",
    imagePaths: [
      "2015-MikeDawesFingerstyleAcousticGuitarWorkshop-33.jpg",
      "2015-MikeDawesFingerstyleAcousticGuitarWorkshop-04.jpg",
      "2015-MikeDawesFingerstyleAcousticGuitarWorkshop-07.jpg",
      "2015-MikeDawesFingerstyleAcousticGuitarWorkshop-34.jpg",
      "2015-MikeDawesFingerstyleAcousticGuitarWorkshop-22.jpg",
      "2015-MikeDawesFingerstyleAcousticGuitarWorkshop-03.jpg",
      "2015-MikeDawesFingerstyleAcousticGuitarWorkshop-11.jpg",
      "2015-MikeDawesFingerstyleAcousticGuitarWorkshop-21.jpg",
    ],
  },
  "2015-Poggiolo-Electric-Workshop": {
    workshopTitle: "Poggiolo Electric Workshop 2015",
    imagePaths: [
      "2015-PoggioloElectricWorkshop-28.jpg",
      "2015-PoggioloElectricWorkshop-07.jpg",
      "2015-PoggioloElectricWorkshop-20.jpg",
      "2015-PoggioloElectricWorkshop-08.jpg",
      "2015-PoggioloElectricWorkshop-18.jpg",
      "2015-PoggioloElectricWorkshop-01.jpg",
      "2015-PoggioloElectricWorkshop-27.jpg",
      "2015-PoggioloElectricWorkshop-30.jpg",
      "2015-PoggioloElectricWorkshop-29.jpg",
      "2015-PoggioloElectricWorkshop-13.jpg",
    ],
  },
  "2016-Poggiolo-Electric-Workshop": {
    workshopTitle: "Poggiolo Electric Workshop 2016",
    imagePaths: [
      "2016-PoggioloElectricWorkshop-088.jpg",
      "2016-PoggioloElectricWorkshop-003.jpg",
      "2016-PoggioloElectricWorkshop-010.jpg",
      "2016-PoggioloElectricWorkshop-054.jpg",
      "2016-PoggioloElectricWorkshop-008.jpg",
      "2016-PoggioloElectricWorkshop-015.jpg",
      "2016-PoggioloElectricWorkshop-042.jpg",
      "2016-PoggioloElectricWorkshop-005.jpg",
      "2016-PoggioloElectricWorkshop-044.jpg",
      "2016-PoggioloElectricWorkshop-022.jpg",
    ],
  },
  "2017-Advancing-Beginners-Acoustic-Guitar-Workshop": {
    workshopTitle: "Advancing Beginners Acoustic Guitar Workshop 2017",
    imagePaths: [
      "2017-Workshop2-2017_spring022.jpg",
      "2017-Workshop2-2017_spring040.jpg",
      "2017-Workshop2-2017_spring041.jpg",
      "2017-Workshop2-2017_spring042.jpg",
      "2017-Workshop2-2017_spring043.jpg",
      "2017-Workshop2-2017_spring044.jpg",
    ],
  },
  "2017-Almost-Intermediate-Acoustic-Guitar-Clinic": {
    workshopTitle: "Almost Intermediate Acoustic Guitar Clinic 2017",
    imagePaths: [
      "2017-Workshop1-2017_spring002.jpg",
      "2017-Workshop1-2017_spring008.jpg",
      "2017-Workshop1-2017_spring009.jpg",
      "2017-Workshop1-2017_spring010.jpg",
      "2017-Workshop1-2017_spring012.jpg",
      "2017-Workshop1-2017_spring013.jpg",
      "2017-Workshop1-2017_spring034.jpg",
      "2017-Workshop1-2017_spring053.jpg",
    ],
  },
  "2017-Rolling-Stones-Jam-Workshop": {
    workshopTitle: "Rolling Stones Jam Workshop 2017",
    imagePaths: [
      "2017-RollingStonesJamWorkshop-2017_sept_013.jpg",
      "2017-RollingStonesJamWorkshop-2017_sept_017.jpg",
      "2017-RollingStonesJamWorkshop-2017_sept_018.jpg",
      "2017-RollingStonesJamWorkshop-2017_sept_026.jpg",
      "2017-RollingStonesJamWorkshop-2017_sept_027.jpg",
      "2017-RollingStonesJamWorkshop-2017_sept_030.jpg",
      "2017-RollingStonesJamWorkshop-2017_sept_032.jpg",
      "2017-RollingStonesJamWorkshop-2017_sept_035.jpg",
      "2017-RollingStonesJamWorkshop-2017_sept_057.jpg",
      "2017-RollingStonesJamWorkshop-2017_sept_067.jpg",
    ],
  },
  "2018-Blues-Standards-Jam-Workshop": {
    workshopTitle: "Blues Standards Jam Workshop 2018",
    imagePaths: [
      "2018-BluesStandardsJamWorkshop-DSCF0893-4.jpg",
      "2018-BluesStandardsJamWorkshop-DSCF1010-4.jpg",
      "2018-BluesStandardsJamWorkshop-DSCF1114-4.jpg",
      "2018-BluesStandardsJamWorkshop-DSCF1135-4.jpg",
      "2018-BluesStandardsJamWorkshop-DSCF1360-4.jpg",
      "2018-BluesStandardsJamWorkshop-DSCF1638-4.jpg",
      "2018-BluesStandardsJamWorkshop-DSCF3694-4.jpg",
      "2018-BluesStandardsJamWorkshop-IMG_6502-4.jpg",
      "2018-BluesStandardsJamWorkshop-Justin Sandercoe Workshop_2018_03.jpg",
      "2018-BluesStandardsJamWorkshop-Justin Sandercoe Workshop_2018_12.jpg",
    ],
  },
  "2018-Campfire-Acoustic-Jamming-Workshop": {
    workshopTitle: "Campfire Acoustic Jamming Workshop 2018",
    imagePaths: [
      "2018-CampfireAcousticJammingWorkshop-DSCF2728.jpg",
      "2018-CampfireAcousticJammingWorkshop-DSCF2744.jpg",
      "2018-CampfireAcousticJammingWorkshop-DSCF2776.jpg",
      "2018-CampfireAcousticJammingWorkshop-DSCF2788.jpg",
      "2018-CampfireAcousticJammingWorkshop-DSCF2803.jpg",
      "2018-CampfireAcousticJammingWorkshop-DSCF2807.jpg",
      "2018-CampfireAcousticJammingWorkshop-DSCF2838.jpg",
      "2018-CampfireAcousticJammingWorkshop-DSCF2975.jpg",
      "2018-CampfireAcousticJammingWorkshop-DSCF3118.jpg",
      "2018-CampfireAcousticJammingWorkshop-DSCF3227.jpg",
      "2018-CampfireAcousticJammingWorkshop-DSCF3233.jpg",
    ],
  },
  "2018-Style-Explorer-Jam-Workshop": {
    workshopTitle: "Style Explorer Jam Workshop 2018",
    imagePaths: [
      "2018-StyleExplorerJamWorkshop-DSCF0050.jpg",
      "2018-StyleExplorerJamWorkshop-DSCF0059.jpg",
      "2018-StyleExplorerJamWorkshop-DSCF0272.jpg",
      "2018-StyleExplorerJamWorkshop-DSCF0324.jpg",
      "2018-StyleExplorerJamWorkshop-DSCF0386.jpg",
      "2018-StyleExplorerJamWorkshop-DSCF0466.jpg",
      "2018-StyleExplorerJamWorkshop-DSCF3048.jpg",
      "2018-StyleExplorerJamWorkshop-DSCF9484.jpg",
      "2018-StyleExplorerJamWorkshop-DSCF9581.jpg",
      "2018-StyleExplorerJamWorkshop-DSCF9790.jpg",
    ],
  },
  "2019-Ariel-Posen-Guitar-Workshop": {
    workshopTitle: "Ariel Posen Guitar Workshop 2019",
    imagePaths: [
      "2019-ArielPosenGuitarWorkshop-DSCF4299.jpg",
      "2019-ArielPosenGuitarWorkshop-DSCF7514.jpg",
      "2019-ArielPosenGuitarWorkshop-DSCF7519.jpg",
      "2019-ArielPosenGuitarWorkshop-DSCF7535.jpg",
      "2019-ArielPosenGuitarWorkshop-DSCF7557.jpg",
      "2019-ArielPosenGuitarWorkshop-DSCF7568.jpg",
      "2019-ArielPosenGuitarWorkshop-DSCF7579.jpg",
      "2019-ArielPosenGuitarWorkshop-DSCF7589.jpg",
      "2019-ArielPosenGuitarWorkshop-DSCF7640.jpg",
      "2019-ArielPosenGuitarWorkshop-DSCF7646.jpg",
    ],
  },
  "2019-Introducing-Electric-Jamming": {
    workshopTitle: "Introducing Electric Jamming 2019",
    imagePaths: [
      "2019-IntroducingElectricJamming-DSCF4084.jpg",
      "2019-IntroducingElectricJamming-DSCF4142.jpg",
      "2019-IntroducingElectricJamming-DSCF4172.jpg",
      "2019-IntroducingElectricJamming-DSCF4428.jpg",
      "2019-IntroducingElectricJamming-DSCF4758.jpg",
      "2019-IntroducingElectricJamming-DSCF7704.jpg",
      "2019-IntroducingElectricJamming-DSCF7903.jpg",
      "2019-IntroducingElectricJamming-DSCF7909.jpg",
      "2019-IntroducingElectricJamming-DSCF8146.jpg",
      "2019-IntroducingElectricJamming-DSCF8163.jpg",
      "2019-IntroducingElectricJamming-IMG_7379.jpg",
    ],
  },
  "2021-Electric-Jam-Week": {
    workshopTitle: "Electric Jam Week 2021",
    imagePaths: [
      "2021-ElectricJamWeek-DSCF8782.jpg",
      "2021-ElectricJamWeek-SELP6561.jpg",
      "2021-ElectricJamWeek-SELP6636.jpg",
      "2021-ElectricJamWeek-SELP6760.jpg",
      "2021-ElectricJamWeek-SELP6768.jpg",
      "2021-ElectricJamWeek-SELP6784.jpg",
      "2021-ElectricJamWeek-SELP6888.jpg",
      "2021-ElectricJamWeek-SELP6916.jpg",
      "2021-ElectricJamWeek-SELP6958.jpg",
      "2021-ElectricJamWeek-SELP7053.jpg",
      "2021-ElectricJamWeek-SELP7103_1.jpg",
    ],
  },
  "2021-The-Acoustic-Jamming-Workshop": {
    workshopTitle: "The Acoustic Jamming Workshop 2021",
    imagePaths: [
      "2021-TheAcousticJammingWorkshop-SELP6121.jpg",
      "2021-TheAcousticJammingWorkshop-SELP6156.jpg",
      "2021-TheAcousticJammingWorkshop-SELP6189.jpg",
      "2021-TheAcousticJammingWorkshop-SELP6200.jpg",
      "2021-TheAcousticJammingWorkshop-SELP6221.jpg",
      "2021-TheAcousticJammingWorkshop-SELP6341.jpg",
      "2021-TheAcousticJammingWorkshop-SELP6370.jpg",
      "2021-TheAcousticJammingWorkshop-SELP6376.jpg",
      "2021-TheAcousticJammingWorkshop-SELP6398.jpg",
      "2021-TheAcousticJammingWorkshop-SELP6424.jpg",
    ],
  },
  "2023-Italy-Workshop-Week-1": {
    workshopTitle: "Italy Workshop 2023 - Week 1",
    imagePaths: [
      "2023-ItalyWeek01-01.jpg",
      "2023-ItalyWeek01-02.jpg",
      "2023-ItalyWeek01-03.jpg",
      "2023-ItalyWeek01-04.jpg",
      "2023-ItalyWeek01-05.jpg",
      "2023-ItalyWeek01-06.jpg",
      "2023-ItalyWeek01-07.jpg",
      "2023-ItalyWeek01-08.jpg",
      "2023-ItalyWeek01-09.jpg",
      "2023-ItalyWeek01-10.jpg",
    ],
  },
  "2023-Italy-Workshop-Week-2": {
    workshopTitle: "Italy Workshop 2023 - Week 2",
    imagePaths: [
      "2023-ItalyWeek02-01.jpg",
      "2023-ItalyWeek02-02.jpg",
      "2023-ItalyWeek02-03.jpg",
      "2023-ItalyWeek02-04.jpg",
      "2023-ItalyWeek02-05.jpg",
      "2023-ItalyWeek02-06.jpg",
      "2023-ItalyWeek02-07.jpg",
      "2023-ItalyWeek02-08.jpg",
      "2023-ItalyWeek02-09.jpg",
      "2023-ItalyWeek02-10.jpg",
    ],
  },
  "2023-Quebec-Acoustic-Jam-Week": {
    workshopTitle: "Quebec Acoustic Jam Week 2023",
    imagePaths: [
      "2023-Acoustic-01.jpg",
      "2023-Acoustic-02.jpg",
      "2023-Acoustic-03.jpg",
      "2023-Acoustic-04.jpg",
      "2023-Acoustic-05.jpg",
      "2023-Acoustic-06.jpg",
      "2023-Acoustic-07.jpg",
      "2023-Acoustic-08.jpg",
      "2023-Acoustic-09.jpg",
      "2023-Acoustic-10.jpg",
    ],
  },
  "2023-UK-Acoustic-Electric-Jam-Week": {
    workshopTitle: "Acoustic & Electric Jam Band Week 2023",
    imagePaths: [
      "uk-wk-cover-sm.jpg",
      "uk-wk-1-sm.jpg",
      "uk-wk-2-sm.jpg",
      "uk-wk-3-sm.jpg",
      "uk-wk-4-sm.jpg",
      "uk-wk-5-sm.jpg",
      "uk-wk-6-sm.jpg",
    ],
  },
};
