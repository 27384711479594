import React from "react";

import { withInnerWidth } from "../../shared";
import { BootstrapSize, breakpoints } from "../../shared/bootstrap-helpers";
import SongRequestsItem from "./SongRequestsItem";
import { useUser } from "../contexts/userContext";

const oneWeekInMilliseconds = 604800000;

const SongRequestsList = ({ songRequests, width, updateVoteCount }) => {
  const user = useUser();

  const voteAtRateLimitThreshold = () => {
    if (!user || !user.userSongVotes || user.userSongVotes.length < 20) return;

    return [...user.userSongVotes].sort(
      (a, b) =>
        new Date(b.attributes.createdAt).getTime() - new Date(a.attributes.createdAt).getTime()
    )[19];
  };

  const rateLimitingVote = voteAtRateLimitThreshold();

  const nextPossibleVoteTime = !!rateLimitingVote
    ? new Date(rateLimitingVote.attributes.createdAt).getTime() + oneWeekInMilliseconds
    : 0;

  return (
    <div className="song-request-listing">
      {width > breakpoints[BootstrapSize.smDevice] && (
        <div className="song-request-listing-headers">
          <div className="ranking">#</div>
          <div className="title">Title</div>
          <div className="artist">Artist</div>
          <div className="vote-count">Votes</div>
          <div className="vote"> </div>
        </div>
      )}

      {songRequests &&
        songRequests.map((songRequest, idx) => (
          <SongRequestsItem
            key={idx}
            songRequest={songRequest}
            ranking={idx + 1}
            updateVoteCount={updateVoteCount}
            nextPossibleVoteTime={nextPossibleVoteTime}
          />
        ))}
    </div>
  );
};

export default withInnerWidth(SongRequestsList);
