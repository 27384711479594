import { BreakpointsOptions } from "@mui/material";

import {
  BootstrapSize,
  breakpoints as bootstrapBreakpoints,
} from "../../components/shared/bootstrap-helpers";

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: bootstrapBreakpoints[BootstrapSize.xsDevice],
    sm: bootstrapBreakpoints[BootstrapSize.smDevice],
    md: bootstrapBreakpoints[BootstrapSize.mdDevice],
    lg: bootstrapBreakpoints[BootstrapSize.lgDevice],
    xl: bootstrapBreakpoints[BootstrapSize.xlDevice],
  },
};
