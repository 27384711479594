let AppConfig = {
  defaultLessonThumbnail: "https://assets.justinguitar.com/images/default_thumbnail.png",
  baseUrl: "www.justinguitar.com",
  filterItems: {
    headerTitle: "Filter the results",
    clearAll: "Clear all",
    clear: "Clear",
    searchPlaceholder: "Search all song lessons",
  },
  songList: {
    noSongsAlert: "No songs could be found. Please change your criteria!",
  },
  lessonList: {
    noLessonsAlert: "No lessons could be found. Please change your criteria!",
  },
  moduleList: {
    noModulesAlert: "No modules could be found. Please change your criteria!",
  },
  months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
};

module.exports = { data: AppConfig };
