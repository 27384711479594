import React from "react";
import { CDN_URL } from "../../components/shared/CdnUrl";
import onClickOutside from "react-onclickoutside";
import { deleteSongbookSong } from "./songbookApi";
import { useDispatch, useSelector } from "react-redux";
import { selectPracticeSongs } from "../store/dashboardSelectors";
import { createUserSongPracticeItem } from "../../components/shared/session/sessionEffects";
import { setActiveTab } from "../store/dashboardStore";
import SongbookDestinationForm from "./SongbookDestinationForm";
import { useDrag } from "react-dnd";
import { DraggableItemTypes } from "./dnd-utils";

export default function ({ song, songbook, ord }) {
  const [menuVisible, setMenuVisible] = React.useState(false);

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: DraggableItemTypes.SONGBOOK_SONG,
    item: {
      song,
      songbook,
      ord,
    },
  }));

  const {
    attributes: { title, originalArtist, artistImageUid, gradeId },
  } = song;
  const coercedGradeId = gradeId == null || gradeId === 10 ? 0 : gradeId;

  return (
    <div className={`songbook-song ${isDragging ? "songbook-song--dragging" : ""}`} ref={dragRef}>
      <div className={`color-grade-${coercedGradeId}`}>{coercedGradeId}</div>
      <img src={`${CDN_URL}/system/dragonfly/production/${artistImageUid}`} alt={title} />
      <div className="artist-and-title">
        <div className="title">{title}</div>
        <div className="artist">{originalArtist}</div>
      </div>
      <div
        className="context-menu-button"
        onClick={(e) => {
          e.stopPropagation();
          setMenuVisible(!menuVisible);
        }}
      >
        <i className="icon icon-menu-dots-vertical" />

        {menuVisible && (
          <SongbookSongContextMenu
            close={() => setMenuVisible(false)}
            songbook={songbook}
            song={song}
          />
        )}
      </div>
    </div>
  );
}

const SongbookSongContextMenu = onClickOutside(
  ({ song, songbook, close }) => {
    const dispatch = useDispatch();
    SongbookSongContextMenu.onClickOutside = (e) => {
      // let menu button handle close if we clicked it
      const clickedMenuButton =
        e.target.className.includes("icon-menu-dots-vertical") ||
        e.target.className.includes("context-menu-button");
      if (!clickedMenuButton) {
        close();
      }
    };

    const userPracticeSongs = useSelector(selectPracticeSongs);

    const userPracticeItemForSong = userPracticeSongs.find(({ attributes: { relatedLink } }) =>
      relatedLink.endsWith(song.attributes.slug)
    );

    const [showMoveToListForm, setShowMoveToListForm] = React.useState(false);

    const [showCopyToListForm, setShowCopyToListForm] = React.useState(false);

    const menuActions = [
      {
        label: "Watch Lesson",
        icon: "play",
        onClick() {
          window.location.href = `/songs/${song.attributes.slug}`;
        },
      },
      {
        label: userPracticeItemForSong == null ? "Add To Practice Items" : "Practice Now",
        icon: "practice-note",
        onClick() {
          if (userPracticeItemForSong == null) {
            dispatch(createUserSongPracticeItem(song.id));
          } else {
            dispatch(setActiveTab("Practice"));
          }
        },
      },
      {
        label: "Move to Different List",
        icon: "redo",
        onClick(e) {
          e.stopPropagation();
          setShowMoveToListForm(true);
        },
      },
      {
        label: "Copy to Different List",
        icon: "copy",
        onClick(e) {
          e.stopPropagation();
          setShowCopyToListForm(true);
        },
      },
      {
        label: "Delete",
        icon: "delete",
        onClick() {
          dispatch(deleteSongbookSong({ songbook, song }));
        },
      },
    ];

    return (
      <div className="songbook-song__context-menu">
        {showMoveToListForm && (
          <SongbookDestinationForm
            songbook={songbook}
            song={song}
            action="move"
            close={(e) => {
              e.stopPropagation();
              setShowMoveToListForm(false);
            }}
          />
        )}
        {showCopyToListForm && (
          <SongbookDestinationForm
            songbook={songbook}
            song={song}
            action="copy"
            close={(e) => {
              e.stopPropagation();
              setShowCopyToListForm(false);
            }}
          />
        )}
        {showMoveToListForm || showCopyToListForm
          ? null
          : menuActions.map(({ label, icon, onClick }) => (
              <div key={label} className="songbook-song__context-menu-option" onClick={onClick}>
                <i className={`icon icon-${icon}`} /> {label}
              </div>
            ))}
      </div>
    );
  },
  {
    handleClickOutside: () => SongbookSongContextMenu.onClickOutside,
  }
);
