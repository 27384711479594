import { createSlice } from "@reduxjs/toolkit";
import "../../../shared/polyfills/replaceAll.js";
import URLSearchParams from "@ungap/url-search-params";

const getUrlFilters = () => {
  const params = new URLSearchParams(location.search);

  return params.has("f") && params.get("f").length
    ? params
        .get("f")
        .replace(/(^,)|(,$)/g, "")
        .split(",")
    : [];
};

const activeFilters = {};

// set active filters from the URL params
getUrlFilters().forEach((key) => {
  key = key
    .replaceAll("_", " ")
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(" ")
    .replaceAll(" Or ", " or ");
  activeFilters[key] = true;
});

export const initialStateFactory = (filters = activeFilters) => filters;

export const filterSliceFactory = (name = "filters") =>
  createSlice({
    name,
    initialState: initialStateFactory(activeFilters),
    reducers: {
      toggleFilter(state, { payload }) {
        const params = new URLSearchParams(location.search);
        const payloadForUrl = payload.replaceAll(" ", "_").toLowerCase();

        let filters = getUrlFilters();

        if (state[payload] === true) {
          filters.splice(filters.indexOf(payloadForUrl), 1);
          delete state[payload];
        } else {
          if (payloadForUrl.length) {
            filters.push(payloadForUrl);
          }
          state[payload] = true;
        }

        params.set("f", filters.join(","));

        window.history.replaceState(
          {},
          "",
          `${location.pathname}?${params}${location.hash ? location.hash : ""}`
        );
      },
      setFilters: (state, { payload }) => {
        return payload.reduce((filters, filter) => ({ ...filters, [filter]: true }), {});
      },
      clearFilters: () => {
        window.history.replaceState(
          {},
          "",
          `${location.pathname}?${location.hash ? location.hash : ""}`
        );
        return initialStateFactory({});
      },
    },
  });
