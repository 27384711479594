import React, { useEffect, useState, useMemo } from "react";
import { debounce } from "lodash";

import { withBootstrapSize } from "../../shared/WithBootstrapSize";
import { BootstrapSize, breakpoints } from "../../shared/bootstrap-helpers";
import Loading from "../../shared/Loading";
import axiosWithCSRF from "../../shared/axiosWithCSRF";
import { ViewMoreButton } from "../ViewMoreButton";
import ListingControls from "./ListingControls";
import { filterOptionsForQueryParams, queryStringToFilterOptions } from "../filters/filters";
import SongsSort from "./SongsSort";
import ListingView from "./ListingView";
import GridView from "./GridView";
import { LIST_STYLES } from "../consts";
import { ListingStyle } from "./ListingStyle";
import ChordsAndTabsFilterButtons from "./ChordsAndTabsFilterButtons";
import SearchParamsPane from "./SearchParamsPane";

const SONGS_URL = "/songs.json";

export const SongsListing = ({ width, filters }) => {
  const [songs, setSongs] = useState([]);
  const [activeFilters, setActiveFilters] = useState({});
  const [showSearchParamsPane, setShowSearchParamsPane] = useState(false);
  const [loadingMoreResults, setLoadingMoreResults] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [listingStyle, setListingStyle] = useState(LIST_STYLES.list);
  const [hasMoreSongs, setHasMoreSongs] = useState(true);
  const [fullCount, setFullCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const hideListingControls = false;

  useEffect(() => {
    fetchSongs();
  }, []);

  useEffect(() => {
    handleFilterChange();
  }, [activeFilters]);

  useEffect(() => {
    handlePrePopulatedFilters();
  }, [filters]);

  useEffect(() => {
    if (!sortBy) return;

    fetchSongs();
  }, [sortBy]);

  function handlePrePopulatedFilters() {
    if (filters.length === 0) return;
    const queryString = new URL(document.location).searchParams;
    const filterMap = queryStringToFilterOptions(queryString, filters);
    const prepopulatedFilters = {};
    Object.values(filterMap)
      .flat()
      .forEach((filter) => {
        prepopulatedFilters[filter] = true;
      });
    setActiveFilters(prepopulatedFilters);
  }

  function fetchSongs(replaceResults = true) {
    setLoadingMoreResults(true);
    const queryString = new URL(document.location).searchParams;
    const offsetParam = replaceResults ? "" : songs.length ? `&offset=${songs.length}` : "";
    const ordering = sortBy ? `&sort_by=${sortBy}` : "";
    const url = `${SONGS_URL}?${queryString}${offsetParam}${ordering}`;
    axiosWithCSRF()
      .get(url)
      .then((response) => {
        if (replaceResults) {
          setSongs(response.data.songs.data);
        } else {
          setSongs((songs) => [...songs, ...response.data.songs.data]);
        }
        setFullCount(response.data.fullCount);
        setHasMoreSongs(response.data.hasMore);
      })
      .finally(() => {
        setInitialLoading(false);
        setLoadingMoreResults(false);
      });
  }

  function handleFilterChange() {
    if (!activeFilters || Object.keys(activeFilters).length === 0) return;

    const filterMap = filterOptionsForQueryParams(activeFilters, filters);
    const existingParams = new URLSearchParams(location.search);
    const filterQuery = new URLSearchParams(filterMap).toString();
    const searchString = existingParams.has("search_term")
      ? `&search_term=${existingParams.get("search_term")}`
      : "";

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${filterQuery}${searchString}`
    );

    fetchSongs();
  }

  function toggleFilter(filter) {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: !prevFilters[filter],
    }));
  }

  const fetchSongsWithSearch = useMemo(
    () =>
      debounce((searchQuery) => {
        currentParams.set("search_term", searchQuery);
        window.history.replaceState({}, "", `${location.pathname}?${currentParams}`);
        fetchSongs();
      }, 300),
    []
  );

  const removeSearchQueryFromParams = () => {
    const hadSearch = currentParams.has("search_term");

    if (hadSearch) {
      currentParams.delete("search_term");

      window.history.replaceState(
        {},
        "",
        `${location.pathname}${currentParams.size > 0 ? "?" : ""}${currentParams}`
      );
      fetchSongs();
    }
  };

  const currentParams = new URLSearchParams(location.search);

  const clearFilters = () => {
    const search = currentParams.get("search_term");
    const queryString = search ? `?search_term=${search}` : "";
    setActiveFilters({});
    window.history.replaceState({}, "", `${location.pathname}${queryString}`);
    fetchSongs();
  };

  useEffect(() => {
    if (searchQuery === "") {
      removeSearchQueryFromParams();
    } else {
      fetchSongsWithSearch(searchQuery);
    }
  }, [searchQuery]);

  return (
    <>
      <h2 className="header2">Songs {`${fullCount || ""}`}</h2>
      {initialLoading ? (
        <span stlye={{ position: "relative" }}>
          <Loading isContent={true} />
        </span>
      ) : (
        <div
          className={`songs-listing songs-listing--${
            listingStyle === LIST_STYLES.list ? "listing" : "grid"
          }`}
        >
          <ListingControls
            activeFilters={activeFilters}
            toggleFilter={toggleFilter}
            width={width}
            setShowSearchParamsPane={setShowSearchParamsPane}
            searchQuery={searchQuery}
            setSearchQuery={(e) => setSearchQuery(e.target.value)}
            clearFilters={clearFilters}
          />

          <div className="songs-listing__body">
            {width > breakpoints[BootstrapSize.smDevice] &&
              width > breakpoints[BootstrapSize.lgDevice] && (
                <div
                  style={{
                    justifyContent: hideListingControls ? "space-between" : "flex-end",
                    paddingBottom: "20px",
                    paddingTop: hideListingControls == null ? 0 : "20px",
                  }}
                  className="songs-listing__main--header"
                >
                  <SongsSort
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    hasQuery={searchQuery.length > 0}
                  />
                  <ListingStyle listingStyle={listingStyle} setListingStyle={setListingStyle} />
                </div>
              )}

            {width <= breakpoints[BootstrapSize.lgDevice] && (
              <ChordsAndTabsFilterButtons
                activeFilters={activeFilters}
                toggleFilter={toggleFilter}
              />
            )}

            <div className="infinite-scroll">
              {listingStyle === LIST_STYLES.list || width <= breakpoints[BootstrapSize.smDevice] ? (
                <ListingView songs={songs} fullCount={fullCount} />
              ) : (
                <GridView songs={songs} width={width} />
              )}
              <ViewMoreButton
                onViewMore={() => fetchSongs(false)}
                loadingMoreResults={loadingMoreResults}
                hasMore={hasMoreSongs}
              />
            </div>
          </div>

          {showSearchParamsPane && (
            <SearchParamsPane
              sortBy={sortBy}
              setSortBy={setSortBy}
              close={() => setShowSearchParamsPane(false)}
              hasQuery={searchQuery.length > 0}
              activeFilters={activeFilters}
              toggleFilter={toggleFilter}
              clearFilters={clearFilters}
            />
          )}
        </div>
      )}
    </>
  );
};

export default withBootstrapSize(SongsListing);
