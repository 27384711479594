import React from "react";
import TalkToAHuman from "./TalkToAHuman";
import { ONBOARDING_STATUSES } from ".";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { withBootstrapSize } from "../../components/shared/WithBootstrapSize";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";

const SKIP_DISCLAIMER =
  "Are you sure you want to skip the onboarding? You won't be able to access it again!";

const OnboardingOptions = ({ currentUser, bootstrapSize, setLoading }) => {
  const { onboardingStatus, slug } = currentUser.data.attributes;

  const updateOnboarding = (status) => {
    return axiosWithCSRF().put("/onboarding", { status: status });
  };

  const redirectToDashboard = () => {
    window.location.href = `/users/${slug}/dashboard`;
  };

  const handleUpdateOnboarding = (status) => {
    setLoading(true);
    if (onboardingStatus === status) {
      redirectToDashboard();
    } else {
      updateOnboarding(status)
        .then(() => {
          redirectToDashboard();
        })
        .finally(() => setLoading(false));
    }
  };

  const handleDismissOnboarding = () => {
    if (confirm(SKIP_DISCLAIMER)) {
      handleUpdateOnboarding(ONBOARDING_STATUSES.dismissedOnboarding);
    }
  };

  const isSmallOrXsDevice = [BootstrapSize.xsDevice, BootstrapSize.smDevice].includes(
    bootstrapSize
  );

  const buttonJustify = isSmallOrXsDevice ? "center" : "flex-start";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isSmallOrXsDevice ? "column" : "row",
        justifyContent: isSmallOrXsDevice ? "center" : "flex-end",
        margin: "20px 0 20px",
      }}
    >
      <TalkToAHuman buttonJustify={buttonJustify} />

      <button
        onClick={() => handleUpdateOnboarding(ONBOARDING_STATUSES.pausedOnboarding)}
        style={{ margin: isSmallOrXsDevice ? "10px 0" : "0 10px", justifyContent: buttonJustify }}
        className="button button--transparent"
      >
        return later
      </button>

      {onboardingStatus === ONBOARDING_STATUSES.pausedOnboarding && (
        <button
          onClick={() => {
            handleDismissOnboarding();
          }}
          style={{ justifyContent: buttonJustify }}
          className="button button--danger"
        >
          skip onboarding
        </button>
      )}
    </div>
  );
};

export default withBootstrapSize(OnboardingOptions);
