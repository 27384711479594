import React from 'react';

export const TestimonialCard = ({ testimonial }) => {
    return (
      <div className="card">
        <div
          className="header"
          style={{
            backgroundImage: `url(${testimonial.headerImage})`,
            ...(testimonial.backgroundPosition
              ? { backgroundPosition: testimonial.backgroundPosition }
              : {}),
          }}
        ></div>
        <div className="content">
          <p className="body">{testimonial.body}</p>
          <p className="data">
            <strong>{testimonial.name}</strong>
            {`${testimonial.location ? `, ${testimonial.location}` : ""}`}
          </p>
          <p className="teacher">{testimonial.teacher}</p>
        </div>
      </div>
    );
  };
