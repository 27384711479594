import React from "react";

export const FacebookIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2934 34.9355V22.9664H27.5974L28.0352 18.8417H24.2934L24.299 16.7773C24.299 15.7015 24.4012 15.1251 25.9463 15.1251H28.0118V11H24.7074C20.7382 11 19.3411 13.0009 19.3411 16.3658V18.8422H16.8669V22.9668H19.3411V34.9355H24.2934Z"
        fill="#1877F2"
      />
    </svg>
  );
};

export const YoutubeIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7996 26.4V19.0667L25.6662 22.7335L19.7996 26.4Z"
        fill="white"
      />
      <g clipPath="url(#clip0_7031_48296)">
        <path
          d="M32.5617 16.67C32.4374 16.202 32.1922 15.7748 31.8509 15.4313C31.5095 15.0877 31.084 14.8399 30.6167 14.7125C28.8967 14.25 22.0217 14.25 22.0217 14.25C22.0217 14.25 15.1467 14.25 13.4267 14.7125C12.9595 14.8399 12.5339 15.0877 12.1926 15.4313C11.8512 15.7748 11.6061 16.202 11.4817 16.67C11.0217 18.3975 11.0217 22 11.0217 22C11.0217 22 11.0217 25.6025 11.4817 27.33C11.6061 27.798 11.8512 28.2252 12.1926 28.5687C12.5339 28.9123 12.9595 29.1601 13.4267 29.2875C15.1467 29.75 22.0217 29.75 22.0217 29.75C22.0217 29.75 28.8967 29.75 30.6167 29.2875C31.084 29.1601 31.5095 28.9123 31.8509 28.5687C32.1922 28.2252 32.4374 27.798 32.5617 27.33C33.0217 25.6025 33.0217 22 33.0217 22C33.0217 22 33.0217 18.3975 32.5617 16.67Z"
          fill="#FF0302"
        />
        <path d="M19.7717 25.2713V18.7288L25.5217 22L19.7717 25.2713Z" fill="#FEFEFE" />
      </g>
      <defs>
        <clipPath id="clip0_7031_48296">
          <rect width="22" height="22" fill="white" transform="translate(11 11)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const TikTokIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="white"
      />
      <path
        d="M26.7451 18.9415C28.1598 19.9564 29.893 20.5536 31.7648 20.5536V16.9387C31.4106 16.9388 31.0572 16.9017 30.7106 16.828V19.6734C28.8389 19.6734 27.106 19.0763 25.6909 18.0615V25.4383C25.6909 29.1286 22.71 32.1199 19.0331 32.1199C17.6611 32.1199 16.3859 31.7037 15.3267 30.9898C16.5357 32.2304 18.2217 33 20.087 33C23.7642 33 26.7453 30.0086 26.7453 26.3182V18.9415H26.7451ZM28.0456 15.2946C27.3225 14.5019 26.8478 13.4775 26.7451 12.3449V11.88H25.7461C25.9976 13.3194 26.8553 14.5492 28.0456 15.2946ZM17.6523 28.158C17.2484 27.6265 17.0301 26.9762 17.0311 26.3076C17.0311 24.6199 18.3945 23.2514 20.0766 23.2514C20.3901 23.2513 20.7018 23.2995 21.0005 23.3946V19.699C20.6514 19.6509 20.299 19.6306 19.9468 19.638V22.5145C19.6478 22.4194 19.336 22.3711 19.0225 22.3714C17.3403 22.3714 15.977 23.7397 15.977 25.4277C15.977 26.6213 16.6585 27.6546 17.6523 28.158Z"
        fill="#FF004F"
      />
      <path
        d="M25.691 18.0614C27.1062 19.0762 28.8391 19.6734 30.7108 19.6734V16.828C29.666 16.6046 28.7411 16.0567 28.0457 15.2946C26.8553 14.5491 25.9977 13.3194 25.7462 11.88H23.1222V26.3181C23.1163 28.0013 21.7551 29.3642 20.0766 29.3642C19.0875 29.3642 18.2087 28.891 17.6522 28.158C16.6584 27.6546 15.9769 26.6212 15.9769 25.4278C15.9769 23.74 17.3403 22.3715 19.0224 22.3715C19.3447 22.3715 19.6554 22.4218 19.9467 22.5146V19.6381C16.3344 19.713 13.4292 22.6751 13.4292 26.3181C13.4292 28.1367 14.1527 29.7853 15.3268 30.9899C16.3861 31.7037 17.6613 32.12 19.0333 32.12C22.7103 32.12 25.6911 29.1285 25.6911 25.4383V18.0614H25.691Z"
        fill="black"
      />
      <path
        d="M30.7107 16.828V16.0586C29.7685 16.06 28.8449 15.7953 28.0456 15.2945C28.7532 16.072 29.6849 16.608 30.7107 16.828ZM25.7461 11.88C25.7222 11.7424 25.7037 11.6039 25.6909 11.4649V11H22.0679V25.4382C22.0621 27.1212 20.701 28.4841 19.0223 28.4841C18.5295 28.4841 18.0642 28.3667 17.6521 28.1581C18.2086 28.891 19.0874 29.3641 20.0765 29.3641C21.7549 29.3641 23.1162 28.0013 23.1221 26.3182V11.88H25.7461ZM19.9468 19.6381V18.8191C19.6441 18.7775 19.3388 18.7567 19.0332 18.7569C15.3559 18.7568 12.375 21.7483 12.375 25.4382C12.375 27.7516 13.5466 29.7905 15.3268 30.9897C14.1526 29.7852 13.4292 28.1366 13.4292 26.3181C13.4292 22.6751 16.3343 19.713 19.9468 19.6381Z"
        fill="#00F2EA"
      />
    </svg>
  );
};
