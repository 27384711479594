import React, { useContext, useState } from "react";
import { ACTION_TYPES, useUser, useUserDispatch } from "../contexts/userContext";
import { LightTooltip } from "../../shared/ui/Tooltips";
import { submitSongRequest } from "../contexts/userActions";
import SongRequestResult from "./SongRequestResult";
import { selectSnackbarAlert } from "../../songs/store";
import { SnackbarContext } from "../SongsIndexContainer";
import { GENERIC_ERROR } from "../consts";

const oneWeekInMilliseconds = 604800000;
const HTTP_CONFLICT = 409;

const SongRequestForm = () => {
  const user = useUser();
  const dispatch = useUserDispatch();
  const { setSnackbar } = useContext(SnackbarContext);

  const [title, setTitle] = useState("");
  const [artist, setArtist] = useState("");
  const [conflicts, setConflicts] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const mostRecentRequest =
    user?.userSongRequests &&
    [...user.userSongRequests].sort(
      (a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
    )[0];

  const nextPossibleVoteTime = !!mostRecentRequest
    ? new Date(mostRecentRequest.attributes.createdAt).getTime() + oneWeekInMilliseconds
    : 0;

  const isRateLimited = nextPossibleVoteTime > Date.now();
  const isDisabled = isRateLimited || title.length === 0 || artist.length === 0;
  const submit = (_event, resubmitting = false) => {
    submitSongRequest(title, artist, resubmitting)
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.SUBMIT_SONG_REQUEST,
          songRequest: response.data.data,
        });
        setConflicts(null);
        setSubmitted(true);
      })
      .catch((error) => {
        if (error?.response?.status === HTTP_CONFLICT) {
          setConflicts(error.response.data);
          setSubmitted(true);
        } else {
          setSnackbar({ message: GENERIC_ERROR, severity: "error" });
        }
      })
      .finally(() => {});
  };

  const clearForm = () => {
    setSubmitted(false);
    setTitle("");
    setArtist("");
  };

  return (
    <div className="new-song-request">
      <h4 className="heading4">submit a song request</h4>
      {submitted ? (
        <SongRequestResult
          clearForm={clearForm}
          conflicts={conflicts}
          resubmit={(e) => submit(e, true)}
        />
      ) : (
        <div className="new-song-request__form">
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title"
          />

          <input
            type="text"
            value={artist}
            onChange={(e) => setArtist(e.target.value)}
            placeholder="Artist"
          />

          <div>
            <LightTooltip
              title={
                isRateLimited
                  ? `You're allowed to submit once per week. You can submit another song on ${new Date(
                      nextPossibleVoteTime + oneWeekInMilliseconds / 7
                    ).toLocaleString("en-US", {
                      weekday: "long",
                      month: "long",
                      day: "numeric",
                    })}`
                  : ""
              }
            >
              <button className="button button--primary" disabled={isDisabled} onClick={submit}>
                Submit Song
              </button>
            </LightTooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default SongRequestForm;
