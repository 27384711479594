import React from "react";

function stripTags(content) {
  let tempDiv = document.createElement("div");
  tempDiv.innerHTML = content;
  return tempDiv.innerText;
}

function labelInSeconds(chapter) {
  const timestamp = chapter.trim().split(" ")[0];
  const timeArray = timestamp && timestamp.split(":");
  if (timeArray.length === 2) {
    const x = parseInt(timeArray[0]) * 60 + parseInt(timeArray[1]);
    return parseInt(timeArray[0]) * 60 + parseInt(timeArray[1]);
  } else if (timeArray.length === 3) {
    return parseInt(timeArray[0]) * 3600 + parseInt(timeArray[1]) * 60 + parseInt(timeArray[2]);
  }
}

const YoutubeChapters = ({ youtubeChapters, goToChapter }) => {
  if (!youtubeChapters || youtubeChapters.length === 0) {
    return null;
  }
  const chaptersArray = youtubeChapters.includes("<br />")
    ? youtubeChapters.trim().split("<br />")
    : youtubeChapters.trim().split("</p>");
  return (
    <select
      className="youtube-chapters"
      defaultValue={""}
      onChange={(e) => {
        if (e.target.value != null) {
          goToChapter(labelInSeconds(e.target.value));
        }
      }}
    >
      <option value="" disabled>
        Select a chapter
      </option>
      {chaptersArray
        .filter((c) => c != "")
        .map((opt) => {
          return (
            <option value={stripTags(opt)} key={opt}>
              {stripTags(opt)}
            </option>
          );
        })}
    </select>
  );
};

export default YoutubeChapters;
