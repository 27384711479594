import React from "react";

const LIGHT_BACKGROUND_PGS = ["BG1"];

const ParentGroupBreadcrumb = ({ reference, swooshBackgroundImg, preTitle }) => {
  return (
    <div
      className={
        "breadcrumb swoosh-breadcrumb pg__breadcrumb breadcrumb--pg " +
        (LIGHT_BACKGROUND_PGS.includes(reference) ? "pg__breadcrumb--dark" : "")
      }
      style={swooshBackgroundImg ? { position: "absolute", top: "20px" } : null}
    >
      <a className="badge" href="/guitar-lessons">
        All Courses
      </a>
      {preTitle && <p className="badge current">{preTitle}</p>}
    </div>
  );
};

export default ParentGroupBreadcrumb;
