import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import React from "react";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../redux/slices/session/session.selectors";
import LoginModal from "../../../components/auth/LoginModal";
import axiosWithCSRF from "../../../components/shared/axiosWithCSRF";

const cardShadow = "0px 0px 14px 0px #44546C40"; // TODO: figure out how to get this in to the MUI theme

const CheckIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 24C0 10.752 10.752 0 24 0C37.248 0 48 10.752 48 24C48 37.248 37.248 48 24 48C10.752 48 0 37.248 0 24ZM19.2016 29.2066L35.0176 13.3906L38.4016 16.7986L19.2016 35.9986L9.60156 26.3986L12.9856 23.0146L19.2016 29.2066Z"
      fill="#6ACF64"
    />
  </svg>
);

export const SignupCard = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [showLogin, setShowLogin] = React.useState(false);
  const [showRegistrationConfirmation, setShowRegistrationConfirmation] = React.useState(false);
  const [showLoginModal, setShowLoginModal] = React.useState(false);

  const registerInterest = () => {
    if (isLoggedIn) {
      axiosWithCSRF()
        .post("/workshops-subscription")
        .then((res) => {
          setShowRegistrationConfirmation(true);
        });
    } else {
      setShowLogin(true);
    }
  };

  return (
    <Card
      sx={{
        borderRadius: "10px",
        boxShadow: cardShadow,
        backgroundColor: "#406E8E",
        textAlign: "center",
        height: {
          xs: "100%",
          // md: "399.29px",
          lg: "325px",
        },
      }}
    >
      <CardContent
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        {showLogin ? (
          <>
            <Typography
              sx={{
                color: "white",
                fontSize: { xs: "18px" },
                lineHeight: "30px",
                fontWeight: 700,
                marginBottom: "40px",
              }}
            >
              To register your interest please log in or create a JustinGuitar account. It’s free.
            </Typography>
            <Box>
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "#FD6A02",
                  "&:hover": {
                    backgroundColor: grey[300],
                  },
                  marginRight: "20px",
                }}
                onClick={() => setShowLoginModal(true)}
              >
                login
              </Button>
              <Button
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.3)",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  },
                  color: "white",
                }}
                onClick={() => (window.location.href = "/users/sign_up")}
              >
                register
              </Button>
            </Box>
            {showLoginModal && (
              <LoginModal
                isOpen={true}
                toggleModal={() => {
                  setShowLoginModal(false);
                }}
              />
            )}
          </>
        ) : showRegistrationConfirmation ? (
          <>
            <CheckIcon />
            <Typography
              sx={{
                color: "white",
                fontSize: { xs: "18px" },
                lineHeight: "30px",
                fontWeight: 700,
                marginTop: "20px",
              }}
            >
              We have successfully registered your interest. You will get an email ahead of upcoming
              JustinGuitar events and workshops.
            </Typography>
          </>
        ) : (
          <>
            <Typography
              sx={{
                textTransform: "uppercase",
                fontWeight: 600,
                marginBottom: "20px",
                color: "#81ABC1",
              }}
              variant="body1"
            >
              more coming soon
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontWeight: 700,
                fontSize: { xs: "18px" },
                lineHeight: "30px",
                marginBottom: "40px",
              }}
            >
              Be the first one to know when new Workshops are available!
            </Typography>
            <Button
              sx={{
                backgroundColor: "white",
                color: "#FD6A02",
                "&:hover": {
                  backgroundColor: grey[300],
                },
              }}
              color="primary"
              onClick={() => registerInterest()}
            >
              Join the Waiting List
            </Button>
          </>
        )}
      </CardContent>
    </Card>
  );
};
