import React from "react";
import BpmIntroduction from "./BpmIntroduction";
import BpmBeatIcons from "./BpmBeatIcons";

export default class BpmTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSession: false,
      beats: 0,
      bpm: "-",
      oldBeats: 0,
      tapNumber: 0,
    };
  }

  startSession = () => {
    this.setState({
      activeSession: true,
      startClock: false,
      beats: 0,
      bpm: 0,
      oldBeats: 0,
      isPaused: false,
      oldBpm: null,
      sessionEnded: false,
    });
  };

  addBeat = () => {
    if (this.state.startClock == true && !this.state.isPaused && this.state.tapNumber < 10) {
      let currentTime = Date.now();
      let currentTap = this.state.tapNumber;
      let currentBpm = this.calculateBpm(currentTime);
      let lastBeat = this.state.latestTime;
      if (currentTime - this.state.latestTime < 3000) {
        this.setState({
          beats: this.state.beats + 1,
          tapNumber: currentTap + 1,
          bpm: currentBpm,
          latestTime: currentTime,
          oldBpm: null,
        });
      } else {
        // here we need to pause the counter and start it again while keeping the original values
        this.setState({ oldBeats: 0, beats: 0, oldBpm: this.state.bpm, isPaused: true }, () =>
          this.addBeat()
        );
      }
    } else {
      let allBeats = this.state.oldBeats + this.state.beats;
      let startTime = Date.now();
      this.setState({
        startClock: true,
        startTime: startTime,
        beats: 1,
        latestTime: startTime,
        isPaused: false,
        tapNumber: 1,
        oldBeats: allBeats,
      });
    }
  };

  calculateBpm = (currentTime) => {
    let startTime = this.state.startTime;
    // get time in seconds and divide it by minutes
    let elapsedTime = (currentTime - startTime) / 1000 / 60;
    let bpm = this.state.beats / elapsedTime;
    return Math.round(bpm);
  };

  endSession = () => {
    this.setState({ startClock: false, sessionEnded: true });
  };

  activeSessionControls = () => {
    return (
      <>
        <button
          autoFocus
          className="button button--primary button--taptempo"
          onClick={this.addBeat}
        >
          <span>
            Click Here Or On Your Space Bar
            <br />
            Along With The Beat
          </span>
        </button>
        <button className="end-session bold uppercase" onClick={this.endSession}>
          End session
        </button>
      </>
    );
  };

  inactiveSessionControls = () => {
    return (
      <button className="button button--primary" onClick={this.startSession}>
        Start Again
      </button>
    );
  };

  render() {
    if (!this.state.activeSession) {
      return (
        <div className="bpm">
          <BpmIntroduction
            toggleActiveSession={() => this.setState({ activeSession: !this.state.activeSession })}
          />
        </div>
      );
    } else {
      return (
        <div className="bpm">
          <div className="bpm__back">
            <span className="clickable" onClick={() => this.setState({ activeSession: false })}>
              <i className="icon icon-angle-left" /> back
            </span>
          </div>
          <div className="bpm__container bpm__tool">
            <h1 className="uppercase bold">Tap Tempo Tool</h1>
            <div className="bpm__stats column">
              <span className="uppercase">Average</span>
              <h2 className="bold uppercase bpm__count">
                {this.state.oldBpm || this.state.bpm} bpm
              </h2>

              {!this.state.sessionEnded && (
                <BpmBeatIcons oldBeats={this.state.oldBeats} beats={this.state.beats} />
              )}
            </div>

            <div className="bpm__controls">
              {this.state.sessionEnded
                ? this.inactiveSessionControls()
                : this.activeSessionControls()}
            </div>
          </div>
        </div>
      );
    }
  }
}
