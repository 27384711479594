import React from "react";
import { connect } from "react-redux";
import ActiveTab from "./ActiveTab";
import { withInnerWidth } from "../components/shared";
import { BootstrapSize, breakpoints } from "../components/shared/bootstrap-helpers";

const DashboardContent = ({ activeTab, width }) => {
  return (
    <div
      className={`dashboard__content dashboard__content--${activeTab.toLowerCase()}`}
      style={{
        marginTop:
          activeTab.toLowerCase() == "dashboard" && width <= breakpoints[BootstrapSize.smDevice]
            ? "-30px"
            : null,
      }}
    >
      <ActiveTab />
    </div>
  );
};

export default connect((state) => ({
  activeTab: state.entity.dashboard.activeTab,
}))(withInnerWidth(DashboardContent));
