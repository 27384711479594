import { Components } from "@mui/material";

export const input: Components["MuiInput"] = {
  styleOverrides: {
    input: {},
  },

  defaultProps: {
    fullWidth: true,
    inputProps: {
      // variant: "outlined",
    },
  },
};
