import { Box, Modal } from "@mui/material";
import React from "react";
import Login from "../../components/auth/Login"; // todo: redo this component in MUI

export interface LoginModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // bgcolor: "background.paper",
  // borderRadius: "20px",
  // boxShadow: 24,
  // p: jgDenimTheme.spacing(4),
};

export const LoginModal = ({ isOpen, handleClose }: LoginModalProps) => {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={style}>
        <Login token={""} />
      </Box>

      {/*  <Typography variant="h3">Log In</Typography>*/}
      {/*  <Button variant="outlined" onClick={handleClose}>*/}
      {/*    Close Modal*/}
      {/*  </Button>*/}
      {/*</Box>*/}
    </Modal>
  );
};
