import React from "react";
import { Typography } from "@mui/material";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";

export const PlaintextAsParagraphs = ({ text }: { text: string }) => (
  <>
    {text.split("\n").map((p, idx) => (
      <Typography
        variant="body1"
        mb={jgDenimTheme.spacing(2)}
        key={idx}
        dangerouslySetInnerHTML={{ __html: p }}
      ></Typography>
    ))}
  </>
);
