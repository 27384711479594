import React from "react";
import ReactOnRails from "react-on-rails";
import { clearUserImage, setUserImage, setUsername } from "../header/Search";

export function withSearchStore(ChildComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.searchStore = ReactOnRails.getStore("searchStore");
    }

    render() {
      return (
        <ChildComponent
          {...this.props}
          clearUserImageFromSearch={() => this.searchStore.dispatch(clearUserImage())}
          setUserImageInSearch={(imageUid) => this.searchStore.dispatch(setUserImage(imageUid))}
          setUsernameInSearch={(username) => this.searchStore.dispatch(setUsername(username))}
        />
      );
    }
  };
}
