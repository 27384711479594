import React from "react";
import { connect } from "react-redux";

import {
  selectCurrentArtist,
  selectQuery,
  selectSortBy,
  sortByLabels,
  sortByOptions,
} from "./store";
import { slices, fetchSongs } from "./store";
import { BootstrapSize, breakpoints } from "../shared/bootstrap-helpers";
import { withInnerWidth } from "../shared";

const SongsSortBy = withInnerWidth(({ width, currentArtist, sortBy, setSortBy, hasQuery }) => {
  const shouldShow = currentArtist == null || width > breakpoints[BootstrapSize.lgDevice];
  return (
    shouldShow && (
      <div className="songs-index__sort-by">
        <span>Sort By: </span>
        <select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
          {Object.entries(sortByLabels).map(([value, label]) => (
            <option
              value={value}
              key={value}
              disabled={value === sortByOptions.relevance && !hasQuery}
            >
              {label}
            </option>
          ))}
        </select>
      </div>
    )
  );
});

export default connect(
  (state) => {
    const query = selectQuery(state);
    return {
      hasQuery: query != null && query.length > 0,
      sortBy: selectSortBy(state),
      currentArtist: selectCurrentArtist(state),
    };
  },
  (dispatch) => ({
    setSortBy: (sortBy) => {
      dispatch(slices.ui.actions.setSortBy(sortBy));
      dispatch(fetchSongs());
    },
  })
)(SongsSortBy);
