import React, { useEffect, useState } from "react";
import ReactOnRails from "react-on-rails";
import { connect, Provider } from "react-redux";
import "./store/musicTheoryCourseStore";
import { PreventScroll, withInnerWidth } from "../../components/shared";
import { musicTheoryCourse } from "../FAQ/data/faqs";
import Category from "../FAQ/Category";
import { Modal } from "@mui/material";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";

import Introduction from "./Introduction";
import Header from "./Header";
import WhatWillYouLearn from "./WhatWillYouLearn";
import MoneybackGuarantee from "./MoneybackGuarantee";
import CourseDifference from "./CourseDifference";
import Testimonials from "./Testimonials";
import TermsAndConditions from "./TermsAndConditions";
import PricingPlans from "./PricingPlans";
import EnrollmentSuccess from "./EnrollmentSuccess";
import { FREE_PLAN } from "./FreePlan";
import Loading from "../../components/shared/Loading";
import { DISCOUNT_TYPES } from "../../subscriptions/consts";

export const CREATE_SUBSCRIPTION_URL = "/payments/sales/subscriptions";
export const CONFIRM_PAYMENT_INTENT_URL = "/payments/sales/payment_intent_confirmations";

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.currentUser,
    enrolledCount: state.entity.enrolledCount,
    faqs: state.entity.faqs,
    gradeData: state.entity.gradeData,
    pricingPlans: state.entity.pricingPlans,
    websiteProductId: state.entity.websiteProductId,
  };
};

const MusicTheorySalesPage = connect(
  mapStateToProps,
  null
)(
  withInnerWidth(
    ({ currentUser, enrolledCount, faqs, gradeData, pricingPlans, websiteProductId, width }) => {
      const [activePlan, setActivePlan] = useState(null);
      const [discount, setDiscount] = useState(null);
      const [loading, setLoading] = useState(false);
      const [enrollmentSuccess, setEnrollmentSuccess] = useState(false);
      const { hasActivePmtSubscription } = currentUser?.data?.attributes || {};
      const [termsModalOpen, setTermsModalOpen] = useState(false);
      const [termsAccepted, setTermsAccepted] = useState(false);

      useEffect(() => {
        let discount = discountForPlan(activePlan, DISCOUNT_TYPES.withinPeriod);

        // within period discount gets priority over upgrade discount. only apply upgrade if no promo is ongoing.
        if (hasActivePmtSubscription && activePlan && !discount) {
          discount = discountForPlan(activePlan, DISCOUNT_TYPES.whenUpgrading);
        }

        setDiscount(discount);
      }, [activePlan]);

      const discountForPlan = (plan, discountType) => {
        if (!plan) return null;

        return pricingPlans.included.find(
          (i) =>
            i.type === "discount" &&
            i.attributes.pricingPlanId === Number(plan.id) &&
            i.attributes.discountType === discountType
        );
      };

      const enroll = () => {
        setLoading(true);
        axiosWithCSRF()
          .post("/users/enroll", { reference: "PMT" })
          .then(() => {
            setTermsModalOpen(false);
            setEnrollmentSuccess(true);
          })
          .finally(() => {
            setLoading(false);
          });
      };

      const createCheckoutSession = () => {
        if (activePlan.id === FREE_PLAN.id) {
          setTermsModalOpen(true);
        } else {
          setLoading(true);
          axiosWithCSRF()
            .post("/payments/sales/checkout_sessions", {
              discount_id: discount?.id,
              pricing_plan_ids: [activePlan.id],
              cancel_url: `${window.location.origin}/store/practical-music-theory`,
            })
            .then((response) => (window.location = response.data.url))
            .catch(() => {
              alert(
                "There was a problem processing your request. Please contact hello@justinguitar.com for assistance."
              );
              setLoading(false);
            });
        }
      };

      function buttonText() {
        if (activePlan?.id === FREE_PLAN.id) {
          return "enroll now";
        } else if (activePlan?.attributes?.planType === "lifetime") {
          return "buy lifetime access";
        } else {
          return "subscribe now";
        }
      }

      return (
        <div className="music-theory-course container">
          <Header
            currentUser={currentUser}
            enrolledCount={enrolledCount}
            enrollmentSuccess={enrollmentSuccess}
          />
          <Introduction />
          <WhatWillYouLearn grades={gradeData} width={width} currentUser={currentUser} />

          <div className="section" id="plans">
            {loading && (
              <PreventScroll shouldPreventScroll={true}>
                <Loading coverViewport={true} />
              </PreventScroll>
            )}
            {enrollmentSuccess !== true && (
              <>
                <PricingPlans
                  activePlan={activePlan}
                  currentUser={currentUser}
                  setActivePlan={setActivePlan}
                  pricingPlans={pricingPlans}
                  buttonText={buttonText}
                  createCheckoutSession={createCheckoutSession}
                />
              </>
            )}
          </div>

          <EnrollmentSuccess displayable={enrollmentSuccess} />

          <MoneybackGuarantee />

          <CourseDifference />

          <Testimonials width={width} />

          <div className="section">
            <div className="faq" id="faq">
              <Category
                faqs={faqs}
                showHeader={false}
                categoryData={musicTheoryCourse}
                useUrlRouting={false}
                showBreadcrumbs={false}
              />
            </div>
          </div>

          <Modal
            open={termsModalOpen}
            onClose={() => setTermsModalOpen(!termsModalOpen)}
            className="terms-modal"
          >
            {/* div needed here, otheriwse: Expected an element that can hold a ref. Did you accidentally use a plain function component for an element instead? */}
            <div>
              <TermsAndConditions
                enroll={enroll}
                setTermsModalOpen={setTermsModalOpen}
                termsModalOpen={termsModalOpen}
                termsAccepted={termsAccepted}
                setTermsAccepted={setTermsAccepted}
              />
            </div>
          </Modal>
        </div>
      );
    }
  )
);

const MusicTheoryCourse = () => {
  const store = ReactOnRails.getStore("musicTheoryCourseStore");

  return (
    <Provider store={store}>
      <MusicTheorySalesPage />
    </Provider>
  );
};

export default MusicTheoryCourse;
