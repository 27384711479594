import { jgDenimColors } from "../../jg-material/theme/jg-denim-colors";

export const PageBreakpoint = "sm";

export const ImageSizes = {
  height: 212,
  width: 300,
  margin: "30px 40px",
  height_sm: 197,
  width_sm: 280,
};

export const GradeButtonColors = {
  1: {
    background: jgDenimColors.grades.grade1,
    text: jgDenimColors.denim.dark,
  },
  2: {
    background: jgDenimColors.grades.grade2,
    text: jgDenimColors.white,
  },
  3: {
    background: jgDenimColors.grades.grade3,
    text: jgDenimColors.white,
  },
  4: {
    background: jgDenimColors.grades.grade4,
    text: jgDenimColors.white,
  },
  5: {
    background: jgDenimColors.grades.grade5,
    text: jgDenimColors.white,
  },
  6: {
    background: jgDenimColors.grades.grade6,
    text: jgDenimColors.white,
  },
  7: {
    background: jgDenimColors.grades.grade7,
    text: jgDenimColors.white,
  },
  8: {
    background: jgDenimColors.grades.grade8,
    text: jgDenimColors.white,
  },
  9: {
    background: jgDenimColors.grades.grade9,
    text: jgDenimColors.white,
  },
};

export const DefaultGradeButtonColors = GradeButtonColors[1];
