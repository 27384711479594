import React, { useState } from "react";
import PropTypes from "prop-types";

import ConditionalWrapper from "../../components/ConditionalWrapper";
import { LongRightArrow } from "../../components/shared/Icons";
import LoginPrompt from "../../components/auth/LoginPrompt";
import Slider from "react-slick";

const SECTIONS = {
  registrationPhase: "Registration Phase",
  lessonPhase: "Lesson Phase",
  feelGoodPhase: "Feel Good Phase",
};

const REGISTRATION_CONTENT = [
  "You <a href='/private-one-on-one-lessons#register'>register your interest</a> for one of the available spots.",
  " We email you a survey to <strong>better understand</strong> your goals and decide if Justin can help you.",
  "Justin reviews the applications <strong>by order of submission</strong>.",
  "Students selected by Justin get <strong>invited to pay and secure their 1:1 lesson</strong>.",
];

const LESSON_CONTENT = [
  "You receive a <strong>pre-lesson questionnaire</strong> to help Justin help you. :)",
  "Justin's team will contact you with <strong>recommendations to prepare you for the lesson</strong>.",
  "You'll <strong>meet Justin on Zoom</strong> in the selected time slot.",
  "You'll receive an email within a week with an <strong>Action Plan & Practice Routine designed by Justin</strong>.",
];

const FEEL_GOOD_CONTENT = [
  "Justin donates all the revenue from these lessons to his <a href='/playin-it-forward' target='_blank'>Playin' It Forward </a> project.",
  "<strong>Playin' it Forward</strong> donates guitars to those in need.",
  "<strong>You and I</strong> help to improve the quality of someone's life by supporting them in making music.",
  "We're <strong>Playin’ It Forward</strong>. :)",
];

const CONTENT = {
  [SECTIONS.registrationPhase]: REGISTRATION_CONTENT,
  [SECTIONS.lessonPhase]: LESSON_CONTENT,
  [SECTIONS.feelGoodPhase]: FEEL_GOOD_CONTENT,
};

const SectionSelector = ({ setHowItWorksSection, howItWorksSection }) => {
  return (
    <div className="how-it-works-buttons">
      {Object.values(SECTIONS).map((section, idx) => (
        <React.Fragment key={idx}>
          <button
            key={section}
            className={howItWorksSection === section ? "active" : ""}
            onClick={() => setHowItWorksSection(section)}
          >
            {section}
          </button>
          {idx + 1 < Object.values(SECTIONS).length && <span className="spacer"></span>}
        </React.Fragment>
      ))}
    </div>
  );
};

const HowItWorks = ({ currentUser, width }) => {
  const [howItWorksSection, setHowItWorksSection] = useState(SECTIONS.registrationPhase);

  return (
    <div className="section how-it-works" id="how-it-works">
      <h2 className="title">How it works</h2>
      <SectionSelector
        setHowItWorksSection={setHowItWorksSection}
        howItWorksSection={howItWorksSection}
      />

      <div className="how-it-works-steps">
        <ConditionalWrapper
          condition={width <= 1200}
          wrapper={(children) => (
            <Slider
              {...{
                dots: true,
                arrows: false,
                infinite: false,
                className: "center",
                centerMode: true,
                slidesToShow: 1,
                slidesToScroll: 1,
              }}
            >
              {children}
            </Slider>
          )}
        >
          {CONTENT[howItWorksSection].map((content, idx) => (
            <div className="how-it-works-step" key={`howItWorks_${idx}`}>
              <div className="content-wrapper">
                <span dangerouslySetInnerHTML={{ __html: content }} />
                {idx + 1 !== CONTENT[howItWorksSection].length && <LongRightArrow />}
              </div>
            </div>
          ))}
        </ConditionalWrapper>
      </div>

      {!currentUser ? (
        <div className="login-prompt">
          <LoginPrompt
            loginParagraph="You must be logged in to register your interest. Not a member yet? Register, it’s free."
            token={ReactOnRails.authenticityToken()}
          />
        </div>
      ) : (
        <button
          className="button button--primary"
          onClick={() => (window.location.href = "/private-one-on-one-lessons#register")}
          disabled={false}
        >
          {false ? "Your registration has been received" : "Register interest"}
        </button>
      )}
    </div>
  );
};

export default HowItWorks;

HowItWorks.propTypes = {
  currentUser: PropTypes.object,
  width: PropTypes.number,
};
