import React from "react";

export interface IEbooksLoginContext {
  isAuthenticated: boolean;
  login: () => void;
}

export const EbooksLoginContext = React.createContext<IEbooksLoginContext>({
  isAuthenticated: false,
  login: () => {},
});
