const questionKey = (question) => `jg-quiz-question-${question.id}`;

export const readStoredAnswerChoices = (question) => {
  // gotta be careful with reading from localstorage
  try {
    const parsed = JSON.parse(localStorage.getItem(questionKey(question))); // parsing string[] or undefined

    if (Array.isArray(parsed)) {
      return parsed.map(Number);
    }

    if (typeof parsed === "number" && !isNaN(parsed)) {
      return [parsed];
    }

    if (typeof parsed === "string" && !isNaN(Number(parsed))) {
      return [Number(parsed)];
    }

    return [];
  } catch {
    return [];
  }
};

export const storeAnswerChoices = (question, optionIds) => {
  try {
    localStorage.setItem(questionKey(question), JSON.stringify(optionIds));
  } catch (e) {
    console.log(e);
    /* no-op */
  }
};
