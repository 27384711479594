import React from "react";
import ReactOnRails from "react-on-rails";
import { Provider } from "react-redux";
import DashboardContent from "./DashboardContent";
import DashboardTabs from "./DashboardTabs";

require("./store/dashboardStore");

export default function () {
  const store = ReactOnRails.getStore("dashboardStore");

  return (
    <Provider store={store}>
      <div className="dashboard">
        <div className="dashboard__inset container">
          <DashboardTabs />
          <DashboardContent />
        </div>
      </div>
    </Provider>
  );
}
