import { createSelector } from "@reduxjs/toolkit";
import { selectEntities } from "../../components/songs/store";

export const entitiesSelector = (state) => state.entities;
export const uiSelector = (state) => state.ui;
export const sessionSelector = (state) => state.session;
export const responseSelector = (state) => state.quizResponse;
export const selectCurrentQuizSlug = createSelector(
  uiSelector,
  ({ currentEntityId }) => currentEntityId
);
export const selectCurrentUser = createSelector(sessionSelector, ({ currentUser }) => currentUser);
export const selectQuizzes = createSelector(entitiesSelector, ({ quizzes }) => quizzes || {});
export const selectQuestions = createSelector(entitiesSelector, ({ questions }) => questions || {});
export const selectOptions = createSelector(entitiesSelector, ({ options }) => options || {});
export const selectLessons = createSelector(entitiesSelector, ({ lessons }) => lessons || {});
export const selectModules = createSelector(entitiesSelector, ({ modules }) => modules || {});
export const selectLessonById = createSelector(selectLessons, (lessons) => (id) => lessons[id]);

export const selectCurrentQuiz = createSelector(
  selectQuizzes,
  selectCurrentQuizSlug,
  (quizzes, slug) => Object.values(quizzes).find((quiz) => quiz.attributes.slug === slug)
);

export const selectCurrentQuizQuestions = createSelector(
  selectCurrentQuiz,
  selectQuestions,
  (quiz, questions) =>
    quiz.relationships.questions.data.map(({ id }) => questions[id]).sort((a, b) => b.ord - a.ord)
);

export const selectQuizQuestionOptionsByQuestion = createSelector(
  selectOptions,
  (options) => (question) =>
    question.relationships.options.data.map(({ id }) => options[id]).sort((a, b) => b.ord - a.ord)
);

export const selectModuleByQuiz = createSelector(selectModules, (modules) => (quiz) => {
  const quizModule = quiz.relationships.quizzable;

  if (quizModule == null || quizModule.data == null) {
    return undefined;
  }

  return modules[quizModule.data.id];
});

export const selectSelectedOptionIdsByQuestionId = createSelector(
  responseSelector,
  (response) => (questionId) => (response[questionId] || []).map(Number)
);

export const selectQuestionOptionActiveByQuestionIdAndOptionId = createSelector(
  selectSelectedOptionIdsByQuestionId,
  (getSelectedQuestionOptions) => (questionId, optionId) => {
    const selectedOptionIds = getSelectedQuestionOptions(questionId);

    if (selectedOptionIds == null) {
      return false;
    }

    if (Array.isArray(selectedOptionIds)) {
      return selectedOptionIds.includes(Number(optionId));
    }

    return selectedOptionIds === optionId;
  }
);

export const selectQuizAttemptsArray = createSelector(entitiesSelector, ({ attempts }) =>
  attempts == null ? [] : Object.values(attempts)
);

export const selectMostRecentQuizAttempt = createSelector(selectQuizAttemptsArray, (attempts) =>
  attempts.length === 0 ? null : attempts[attempts.length - 1]
);

export const selectShowingResults = createSelector(
  selectMostRecentQuizAttempt,
  (attempt) => attempt != null
);

export const selectUserQuizAnswers = createSelector(
  selectEntities,
  ({ userQuizAnswers }) => userQuizAnswers
);

export const selectUserQuizAnswerOptions = createSelector(
  selectEntities,
  ({ userQuizAnswerOptions }) => userQuizAnswerOptions
);

export const selectUserQuizAnswerForQuiz = createSelector(
  selectUserQuizAnswers,
  (userQuizAnswers) => (quiz) => userQuizAnswers[quiz.id]
);

export const selectUserQuizAnswersForMostRecentAttempt = createSelector(
  selectMostRecentQuizAttempt,
  selectUserQuizAnswers,
  (quizAttempt, quizAnswers) => {
    return quizAttempt.relationships.userQuizAnswers.data.map(({ id }) => quizAnswers[id]);
  }
);

export const selectMostRecentUserAnswersForQuestion = createSelector(
  selectUserQuizAnswersForMostRecentAttempt,
  selectQuizQuestionOptionsByQuestion,
  (userAnswers, questionOptionsByQuestion) => (question) => {
    const questionOptions = questionOptionsByQuestion(question);
    const optionIds = new Set(questionOptions.map((o) => Number(o.id)));

    return userAnswers.filter((a) => optionIds.has(Number(a.attributes.quizQuestionOptionId)));
  }
);

// export const selectLessons = createSelector(
//   (state) => {
//     console.log(state.entities);
//     return state.entities.lessons;
//   },
//   ({ lessons }) => lessons
// );
//
// export const selectLessonById = createSelector(selectLessons, (lessons) => (id) => lessons[id]);
