import React from "react";
import ConfettiGenerator from "confetti-js";

const CONFETTI_COLORS = [
  [64, 110, 142],
  [129, 171, 193],
  [255, 225, 204],
  [253, 106, 2],
  [231, 222, 210],
  [189, 163, 138],
];

export default class PaywallSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.confettiCanvas = React.createRef();
  }

  componentDidMount() {
    const confettiSettings = {
      target: this.confettiCanvas.current,
      colors: CONFETTI_COLORS,
      rotate: true,
    };
    var confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
  }

  render() {
    return (
      <div className="paywall__success">
        <h3 className="header3 uppercase">Thanks for your purchase and for supporting the site!</h3>
        <canvas ref={this.confettiCanvas}></canvas>
      </div>
    );
  }
}
