import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { jgDenimColors } from "../../../jg-material/theme/jg-denim-colors";
import { dateAsShortMonthDayNumericYear, getHeaderImage, shortLocation } from "./helpers";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { fontWeights } from "../../../jg-material/theme/typography";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

import { WorkshopEntity } from "../interfaces/workshop.interface";
import { workshopToPurchaseReference } from "../deposit/helpers";

export interface RegisterSidebarProps {
  workshop: WorkshopEntity;
  currentUser?: any;
}

const borderBottom = { borderBottom: `1px solid ${jgDenimColors.denim.light}` };

export const RegisterSidebar = ({ workshop, currentUser = null }: RegisterSidebarProps) => {
  const [expanded, setExpanded] = React.useState(false);
  const isSmall = useMediaQuery(jgDenimTheme.breakpoints.down("sm"));

  const purchaseReference = workshopToPurchaseReference(workshop);
  const userPaidDeposit = (currentUser?.data.attributes.purchasesForDashboard || []).some(
    (purchase) => purchase.data.attributes.purchaseReference === purchaseReference
  );

  return (
    <Box marginRight={isSmall ? 0 : 3}>
      <Box
        width={"100%"}
        component="img"
        src={getHeaderImage(workshop, "small")}
        borderRadius="10px"
        marginBottom={jgDenimTheme.spacing(3)}
      />

      <Box
        sx={borderBottom}
        paddingBottom={jgDenimTheme.spacing(2)}
        marginBottom={jgDenimTheme.spacing(3)}
      >
        <Typography variant="h4" marginBottom={jgDenimTheme.spacing(1)}>
          {workshop.attributes.title}
        </Typography>
        <Typography fontWeight={fontWeights.semiBold}>
          {dateAsShortMonthDayNumericYear(workshop.attributes.arrivalTime)} -{" "}
          {dateAsShortMonthDayNumericYear(workshop.attributes.departureTime)}
        </Typography>

        <Typography>
          {shortLocation(workshop.attributes.addressCity, workshop.attributes.addressCountry)}
        </Typography>
      </Box>

      {isSmall ? (
        <Accordion
          variant="elevation"
          disableGutters={true}
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
        >
          <AccordionSummary sx={{ cursor: "pointer" }}>
            <Typography variant="h8">
              MORE INFO
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SidebarCollapsibleContent workshop={workshop} userPaidDeposit={userPaidDeposit} />
          </AccordionDetails>
        </Accordion>
      ) : (
        <SidebarCollapsibleContent workshop={workshop} userPaidDeposit={userPaidDeposit} />
      )}
    </Box>
  );
};

const SidebarCollapsibleContent = ({
  workshop,
  userPaidDeposit = false,
}: {
  workshop: WorkshopEntity;
  userPaidDeposit: Boolean;
}) => {
  return (
    <>
      {/* <Box
        sx={borderBottom}
        paddingBottom={jgDenimTheme.spacing(2)}
        marginBottom={jgDenimTheme.spacing(3)}
      >
        <Typography variant="h8">PROCESS</Typography>
        <Typography variant="body1">
          By paying this non-refundable deposit, you secure your place in this Workshop and agree to
          our Terms & Conditions. It's your responsibility to book your Accommodation and
          Transporation! You'll receive an email with information on how to do that and the next
          steps, but please don't hesitate to contact laryne@justinguitar.com if you have any
          questions.
        </Typography>
      </Box> */}
      <Box
        sx={borderBottom}
        paddingBottom={jgDenimTheme.spacing(2)}
        marginBottom={jgDenimTheme.spacing(3)}
      >
        <Typography variant="h8">PAYMENT SCHEDULE</Typography>
        <Typography variant="body1">
          {!userPaidDeposit
            ? `By paying this deposit, you secure your place in this Workshop and agree to
          our Terms & Conditions. It's your responsibility to book your Accommodation and
          Transporation! You'll receive an email with information on how to do that and the next
          steps, but please don't hesitate to contact laryne@justinguitar.com if you have any
          questions.`
            : workshop.attributes.slug === "2023-Acoustic-Electric-Classics"
            ? `You must pay for the remaining balance anytime before June 15, 2023. We'll send
          you an email reminder when the due date approaches, but feel free to visit this page again
          when you're ready to pay for the remaining balance!`
            : `You must pay for the remaining balance anytime before May 31, 2023. We'll send
          you an email reminder when the due date approaches, but feel free to visit this page again
          when you're ready to pay for the remaining balance!`}
        </Typography>
      </Box>
    </>
  );
};
