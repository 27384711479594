import { fontFamilies, fontWeights } from "../../typography";

import { Components } from "@mui/material";

import { jgDenimColors } from "../../jg-denim-colors";

export const buttons: Components["MuiButton"] = {
  defaultProps: {
    style: {
      fontSize: "14px",
      fontFamily: fontFamilies.montserrat,
      minHeight: "44px",
      borderRadius: "44px",
      letterSpacing: "1.4px",
      fontWeight: fontWeights.bold,
    },
    color: "secondary",
    variant: "contained",
  },
  variants: [
    {
      props: {
        variant: "contained",
      },
      style: {
        color: "white",
      },
    },
    {
      props: {
        variant: "text",
      },
      style: {
        color: jgDenimColors.accent.orange.main,
      },
    },
  ],
};
