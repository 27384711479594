import React from "react";
import PropTypes from "prop-types";
import Swoosh from "../../components/shared/Swoosh";
import { CDN_URL } from "../../components/shared/CdnUrl";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";

const PRODUCTS_HERO_PATH = `${CDN_URL}/images/products/redesign`;
const LARGE_HEADER_IMAGE = `${PRODUCTS_HERO_PATH}/tabs-hero-lg.jpg`;
const SMALL_HEADER_IMAGE = `${PRODUCTS_HERO_PATH}/tabs-hero-sm.jpg`;

const TITLE = "Tabs";
const SUBTITLE =
  "Get on-screen access to hundreds of verified tabs and chords that perfectly fit my song lessons!";

const DESCRIPTION_FOR_SUBSCRIBER = `<a href="/songs" class="button button--primary">explore songs</a>`;

const DESCRIPTION_FOR_TRIAL = `<a href="#tabs" class="button button--primary">start my free trial</a>`;

const DESCRIPTION_FOR_RESUBSCRIBE = `<a href="#tabs" class="button button--primary">resubscribe now</a>`;

const Header = ({ hasActiveTabsSubscription, trialAvailable }) => {
  const description = () => {
    if (hasActiveTabsSubscription) {
      return DESCRIPTION_FOR_SUBSCRIBER;
    } else if (trialAvailable) {
      return DESCRIPTION_FOR_TRIAL;
    } else {
      return DESCRIPTION_FOR_RESUBSCRIBE;
    }
  };

  return (
    <Swoosh
      imgUrl={{
        [BootstrapSize.smDevice]: LARGE_HEADER_IMAGE,
        [BootstrapSize.xsDevice]: SMALL_HEADER_IMAGE,
      }}
      breadcrumbsCta={{ url: "/store", title: "back to store" }}
    >
      <div className="products-index__header products-index__header--product-type container">
        <h1>{TITLE}</h1>
        <p>{SUBTITLE}</p>

        <div
          dangerouslySetInnerHTML={{
            __html: description(),
          }}
        ></div>
      </div>
    </Swoosh>
  );
};

Header.propTypes = {
  hasActiveTabsSubscription: PropTypes.bool.isRequired,
  trialAvailable: PropTypes.bool.isRequired,
};

export default Header;
