import React from "react";
import Category from "../FAQ/Category";

const SpecialThanks = ({ faqs, width }) => {
  return (
    <>
      <div className="section special-thank-you">
        <h2>🧡 Special Thank You</h2>
        <p>
          To all students who bought or plan to buy a{" "}
          <a href="/private-one-on-one-lessons" target="_blank">
            1:1 with Justin
          </a>
          .{width > 992 ? <br /> : " "}
          Justin dedicates all the revenue generated by those classes to fund this project.
        </p>
      </div>
      <div className="section help-us">
        <h2>No longer need a guitar? Help Us!</h2>
        <p>
          Have a guitar that you no longer use? Don’t let it gather dust! Donate it to us, and we'll
          find it a new home through our Playin’ It Forward Program. Start by filling out the form
          below to see if it fits our program well. :)
        </p>
        <a
          href="https://justinguitar-survey.typeform.com/to/cPg4Mc6W"
          className="button button--primary"
          target="_blank"
        >
          Donate Your Guitar
        </a>
      </div>
      <div className="section banner"></div>
      <div className="faq" id="faq">
        <Category faqs={faqs} />
      </div>
    </>
  );
};

export default SpecialThanks;
