import React from "react";

const ChordsAndTabsFilterButtons = ({ activeFilters, toggleFilter }) => {
  const tabsSelected = activeFilters["JustinGuitar Tabs"];
  const chordsSelected = activeFilters["JustinGuitar Chords"];

  return (
    <div className="songs-features-filters">
      <div className="songs-features-filters__label">Show Songs With:</div>
      <div className="songs-features-filters__buttons flex">
        <button
          className={`button button--${tabsSelected ? "primary" : "beige"}`}
          onClick={() => toggleFilter("JustinGuitar Tabs")}
        >
          Tabs
        </button>

        <button
          className={`button button--${chordsSelected ? "primary" : "beige"}`}
          onClick={() => toggleFilter("JustinGuitar Chords")}
        >
          Chords
        </button>
      </div>
    </div>
  );
};

export default ChordsAndTabsFilterButtons;
