import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from "@mui/material";

import { body1 } from "./body";
import { h1, h1Responsive, h2, h3, h4, h5, h6, h7, h8, heroHeading, heroHeadingResponsive } from "./headings";
import { quote } from "./quote";

export const typography: {
  defaultProps?: ComponentsProps["MuiTypography"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiTypography"];
  variants?: ComponentsVariants["MuiTypography"];
} = {
  variants: [heroHeading, heroHeadingResponsive, h1, h1Responsive, h2, h3, h4, h5, h6, h7, h8, body1, quote],
};
