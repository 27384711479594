import React, { useEffect, useState } from "react";
import { withBootstrapSize } from "../../components/shared/WithBootstrapSize";
import OverviewSwoosh from "./OverviewSwoosh";
import { ArrowDownIcon, ArrowUpIcon } from "../../components/shared/Icons";
import { getUrlSearch } from "./Helpers";

const locationHash = window.location.hash ? window.location.hash.slice(1) : null;

const SearchResults = withBootstrapSize(
  ({
    searchPhrase,
    setSearchPhrase,
    searchResults,
    setSearchResults,
    searchResultsFromQueryByCategory,
    bootstrapSize,
    width,
  }) => {
    const searchResultsQuestions = searchResultsFromQueryByCategory.flatMap(
      (category) => category.questions
    );

    let activeQuestion;
    const [activeCategoryId, setActiveCategoryId] = useState(null);
    const [activeQuestionId, setActiveQuestionId] = useState(
      locationHash &&
        (activeQuestion = searchResultsQuestions.find((child) => child.slug === locationHash))
        ? activeQuestion.id
        : null
    );

    useEffect(() => {
      if (locationHash) {
        setTimeout(() => {
          document.getElementById(locationHash).scrollIntoView();
        }, 0);
      }
    }, []);

    const setActiveCategory = (categoryId) => {
      setActiveCategoryId(categoryId);
    };

    const setActiveQuestion = (categoryId, questionId, questionSlug) => {
      if (activeQuestionId === questionId) {
        setActiveQuestionId(null);
        history.pushState({}, "", window.location.pathname + window.location.search);
      } else {
        setActiveQuestionId(questionId);
        history.pushState({}, "", `${window.location.href.replace(/#.*/, "")}#${questionSlug}`);
      }
    };

    const searchResultsCount = searchResultsFromQueryByCategory
      .flatMap((category) => category.questions.length)
      .reduce((total, count) => total + count);

    return (
      <>
        <OverviewSwoosh
          searchPhrase={searchPhrase}
          setSearchPhrase={setSearchPhrase}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
        />
        <div className="category">
          {/* {showBreadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} headerWidth dark />} */}
          <h2 className="uppercase header2 text-center">{`${searchResultsCount} results for ‘${getUrlSearch()}’`}</h2>
          <div className="content">
            <div className="topics">
              <h3>categories</h3>
              {!["xsDevice", "smDevice", "mdDevice"].includes(bootstrapSize) ? (
                <div className="nav">
                  <button
                    key={0}
                    className={!activeCategoryId ? "active" : null}
                    onClick={() => setActiveCategory(null)}
                  >
                    All categories
                  </button>
                  {searchResultsFromQueryByCategory.map((category, idx) => (
                    <button
                      key={idx}
                      className={activeCategoryId === category.category.id ? "active" : null}
                      onClick={() => setActiveCategory(category.category.id)}
                    >
                      {category.category.title}
                    </button>
                  ))}
                </div>
              ) : (
                <select
                  value={activeCategoryId}
                  onChange={(e) => {
                    setActiveCategory(e.target.value == 0 ? null : parseInt(e.target.value));
                  }}
                >
                  <option key={0} value={0}>
                    All categories
                  </option>
                  {searchResultsFromQueryByCategory.map((category, idx) => (
                    <option key={idx} value={category.category.id}>
                      {category.category.title}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="accordion questions">
              {searchResultsFromQueryByCategory.map((category) =>
                !activeCategoryId || activeCategoryId === category.category.id
                  ? category.questions.map((question, idx) => (
                      <div
                        key={idx}
                        id={width > 992 ? question.slug : null}
                        className={`accordion__content${
                          activeQuestionId == question.id ? " open" : ""
                        }`}
                      >
                        <div
                          className="accordion__content__header"
                          onClick={() =>
                            setActiveQuestion(activeCategoryId, question.id, question.slug)
                          }
                        >
                          <h4>{question.title}</h4>
                          {activeQuestionId == question.id ? <ArrowUpIcon /> : <ArrowDownIcon />}
                        </div>
                        {activeQuestionId == question.id && question.content && (
                          <div
                            className="accordion__content__body"
                            dangerouslySetInnerHTML={{
                              __html: question.content,
                            }}
                          ></div>
                        )}
                      </div>
                    ))
                  : null
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default withBootstrapSize(SearchResults);
