import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosWithCSRF from "../axiosWithCSRF";
import { openAlert, setLoading } from "../ui/uiSlice";
import { SUBSCRIPTION_STATUSES } from "../../../subscriptions/consts";

export const createSongItem = createAsyncThunk(
  "currentUser/createUserItem",
  (userSongItem, { dispatch }) =>
    axiosWithCSRF()
      .post("/user_items/song_item.json", userSongItem)
      .then((res) => {
        dispatch(openAlert({ message: "Saved Song!", severity: "success" }));
        return res.data.userItem.data;
      })
      .catch(() => {
        dispatch(
          openAlert({
            message: "There was a problem saving this song.",
            severity: "error",
          })
        );
      })
);

export const deleteSongItem = createAsyncThunk(
  "currentUser/deleteSongItem",
  (userItem, { dispatch }) =>
    axiosWithCSRF()
      .delete(`/user_items/${userItem.id}.json`)
      .then(() => {
        dispatch(openAlert({ message: "Unsaved Song!", severity: "success" }));
        return userItem;
      })
      .catch(() => {
        dispatch(
          openAlert({ message: "There was a problem unsaving this song.", severity: "error" })
        );
      })
);

export const createChordItem = createAsyncThunk(
  "currentUser/createChordItem",
  (userChordItem, { dispatch }) =>
    axiosWithCSRF()
      .post("/user_items/chord_item.json", userChordItem)
      .then((res) => {
        dispatch(openAlert({ message: "Saved to Dashboard/Favorites!", severity: "success" }));
        return res.data.userItem.data;
      })
      .catch(() => {
        dispatch(
          openAlert({
            message: "There was a problem saving this chord.",
            severity: "error",
          })
        );
      })
);

export const deleteChordItem = createAsyncThunk(
  "currentUser/deleteChordItem",
  (userItem, { dispatch }) =>
    axiosWithCSRF()
      .delete(`/user_items/${userItem.id}.json`)
      .then(() => {
        dispatch(openAlert({ message: "Unsaved Chord!", severity: "success" }));
        return userItem;
      })
      .catch(() => {
        dispatch(
          openAlert({ message: "There was a problem unsaving this chord.", severity: "error" })
        );
      })
);

export const deleteStrummingPatternItem = createAsyncThunk(
  "currentUser/deleteStrummingPatternItem",
  (userItem, { dispatch }) =>
    axiosWithCSRF()
      .delete(`/user_items/${userItem.id}.json`)
      .then(() => {
        dispatch(openAlert({ message: "Unsaved strumming pattern!", severity: "success" }));
        return userItem;
      })
      .catch(() => {
        dispatch(
          openAlert({
            message: "There was a problem unsaving this strumming pattern.",
            severity: "error",
          })
        );
      })
);

export const createStrummingPatternItem = createAsyncThunk(
  "currentUser/createStrummingPatternItem",
  (userStrummingPatternItem, { dispatch }) =>
    axiosWithCSRF()
      .post("/user_items/strumming_pattern_item.json", userStrummingPatternItem)
      .then((res) => {
        dispatch(openAlert({ message: "Saved to Dashboard/Favorites!", severity: "success" }));
        return res.data.userItem.data;
      })
      .catch(() => {
        dispatch(
          openAlert({
            message: "There was a problem saving this strumming pattern.",
            severity: "error",
          })
        );
      })
);

export const renameStrummingPatternItem = createAsyncThunk(
  "currentUser/renameStrummingPatternItem",
  (userStrummingPatternItem, { dispatch }) =>
    axiosWithCSRF()
      .post("/user_items/strumming_pattern_item.json", userStrummingPatternItem)
      .then((res) => {
        dispatch(openAlert({ message: "Saved to Dashboard/Favorites!", severity: "success" }));
        return res.data.userItem.data;
      })
      .catch(() => {
        dispatch(
          openAlert({
            message: "There was a problem saving this strumming pattern.",
            severity: "error",
          })
        );
      })
);

export const createUserSongPracticeItem = createAsyncThunk(
  "currentUser/createUserPracticeItem",
  (songId, { dispatch }) =>
    axiosWithCSRF()
      .post(`/songs/${songId}/user_practice_item.json`)
      .then((res) => {
        dispatch(openAlert({ message: "Saved Practice Item!", severity: "success" }));
        return res.data.serializedPracticeItem.data;
      })
      .catch(() => {
        dispatch(
          openAlert({
            message: "There was a problem saving this practice item.",
            severity: "error",
          })
        );
      })
);

export const deleteUserSongPracticeItem = createAsyncThunk(
  "currentUser/deleteUserPracticeItem",
  (songId, { dispatch }) =>
    axiosWithCSRF()
      .delete(`/songs/${songId}/user_practice_item.json`)
      .then((res) => {
        dispatch(openAlert({ message: "Deleted Practice Item!", severity: "success" }));
        return res.data.serializedPracticeItem.data;
      })
      .catch(() => {
        dispatch(
          openAlert({
            message: "There was a problem deleting this practice item.",
            severity: "error",
          })
        );
      })
);

export const createUserPracticeItemFromLesson = createAsyncThunk(
  "currentUser/createUserPracticeItemFromLesson",
  ({ practiceItemId, addToActiveRoutine, relatedLink }, { dispatch }) => {
    dispatch(setLoading());
    return axiosWithCSRF()
      .post(`/user_practice_items/from_admin_item`, {
        practice_item_id: practiceItemId,
        add_to_active_routine: addToActiveRoutine,
        related_link: `https://www.justinguitar.com/${relatedLink}`,
      })
      .then((res) => {
        dispatch(openAlert({ message: "Saved Practice Item!", severity: "success" }));
        return res.data.userPracticeItem.data;
      })
      .catch(() => {
        dispatch(
          openAlert({
            message: "There was a problem saving this practice item.",
            severity: "error",
          })
        );
      })
      .finally(() => {
        dispatch(setLoading());
      });
  }
);

export const createUserPracticeRoutineFromLesson = createAsyncThunk(
  "currentUser/createUserPracticeRoutineFromLesson",
  ({ practiceRoutineId, setAsActiveRoutine }, { dispatch }) => {
    dispatch(setLoading());
    return axiosWithCSRF()
      .post(`/user_practice_routines/add_admin_generated_routine`, {
        practice_routine_id: practiceRoutineId,
        set_as_active_routine: setAsActiveRoutine,
      })
      .then((res) => {
        dispatch(openAlert({ message: "Saved Practice Routine!", severity: "success" }));
        return res.data.userPracticeRoutine.data;
      })
      .catch(() => {
        dispatch(
          openAlert({
            message: "There was a problem saving this practice routine.",
            severity: "error",
          })
        );
      })
      .finally(() => {
        dispatch(setLoading());
      });
  }
);

export const createLessonItem = createAsyncThunk(
  "currentUser/createLessonItem",
  ({ lessonId, status, alert = true }, { dispatch }) => {
    dispatch(setLoading());
    return axiosWithCSRF()
      .post("/user_items/lesson_item.json", { lesson_id: lessonId, item_status: status })
      .then((res) => {
        if (alert) {
          dispatch(
            openAlert({
              message: `${status === "saved" ? "Saved" : "Completed"} this lesson!`,
              severity: "success",
            })
          );
        }
        return res.data.userItem.data;
      })
      .catch(() => {
        dispatch(
          openAlert({
            message: `There was a problem ${
              status === "saved" ? "saving this lesson" : "marking this lesson as complete"
            }.`,
            severity: "error",
          })
        );
      })
      .finally(() => {
        dispatch(setLoading());
      });
  }
);

export const deleteLessonItem = createAsyncThunk(
  "currentUser/deleteLessonItem",
  ({ lessonId, status }, { dispatch }) => {
    dispatch(setLoading());
    return axiosWithCSRF()
      .delete(`/user_items/delete_lesson_item/${lessonId}?status=${status}`)
      .then((res) => {
        dispatch(
          openAlert({
            message: `Removed this from your ${status} lessons!`,
            severity: "success",
          })
        );
        return { id: lessonId, status: status };
      })
      .catch((error) => {
        dispatch(
          openAlert({
            message: `There was a problem removing this ${status} lessson.`,
            severity: "error",
          })
        );
      })
      .finally(() => {
        dispatch(setLoading());
      });
  }
);

export const deleteAllRecentlyViewed = createAsyncThunk(
  "currentUser/deleteAllRecentlyViewed",
  ({ userId }, { dispatch }) => {
    dispatch(setLoading());
    return axiosWithCSRF()
      .delete(`/recent_pageviews_by_user?user_id=${userId}`)
      .then((res) => {
        dispatch(
          openAlert({
            message: `Deleted your recent views!`,
            severity: "success",
          })
        );
      })
      .catch((error) => {
        dispatch(
          openAlert({
            message: `There was a problem deleting your recent views.`,
            severity: "error",
          })
        );
      })
      .finally(() => {
        dispatch(setLoading());
      });
  }
);

export const cancelSubscription = createAsyncThunk(
  "currentUser/cancelSubscription",
  ({ itemId, typeOf, useNewCancelRoute }, { dispatch }) => {
    dispatch(setLoading());

    if (useNewCancelRoute) {
      return axiosWithCSRF()
        .patch(`/payments/subscriptions/${itemId}`, {
          subscription: {
            status: SUBSCRIPTION_STATUSES.cancelled,
          },
        })
        .then((res) => {
          dispatch(setLoading());
          dispatch(
            openAlert({
              message: "Your subscription has been cancelled!",
              severity: "success",
            })
          );

          window.location.reload();
        })
        .catch(() => {
          dispatch(setLoading());
          dispatch(
            openAlert({
              message:
                "There was a problem cancelling your subscription. Please contact hello@justinguitar.com if the problem persists.",
              severity: "error",
            })
          );
        });
    } else {
      return axiosWithCSRF()
        .post("/cancel-my-subscription", {
          item: {
            id: itemId,
            type_of: typeOf,
          },
        })
        .then((res) => {
          dispatch(setLoading());
          dispatch(
            openAlert({
              message: `Your subscription has been cancelled!`,
              severity: "success",
            })
          );
          return res.data;
        })
        .catch((err) => {
          dispatch(setLoading());
          dispatch(
            openAlert({
              message: `There was a problem cancelling your subscription. Please contact hello@justinguitar.com if the problem persists.`,
              severity: "error",
            })
          );
        });
    }
  }
);

// TODO: use this when sales subscriptions have been moved from Stripe

// export const setSubscriptionCancelled = createAsyncThunk(
//   "currentUser/setSubscriptionCancelled",
//   ({ id, reloadOnSuccess }, { dispatch }) => {
//     dispatch(setLoading());
//     return axiosWithCSRF()
//       .patch(`/payments/subscriptions/${id}`, {
//         subscription: {
//           status: SUBSCRIPTION_STATUSES.cancelled,
//         },
//       })
//       .then((res) => {
//         dispatch(setLoading());
//         dispatch(
//           openAlert({
//             message: "Your subscription has been cancelled!",
//             severity: "success",
//           })
//         );
//         if (reloadOnSuccess) {
//           window.location.reload();
//         } else {
//           return res.data;
//         }
//       })
//       .catch(() => {
//         dispatch(setLoading());
//         dispatch(
//           openAlert({
//             message:
//               "There was a problem cancelling your subscription. Please contact hello@justinguitar.com if the problem persists.",
//             severity: "error",
//           })
//         );
//       });
//   }
// );
