import React, { useEffect } from "react";

const EVENT_KEY = "match_my_sound_view";
const MatchMySound = ({ matchMySoundId }) => {
  if (!matchMySoundId) return null;

  useEffect(() => {
    if (typeof gtag != "undefined") {
      gtag("event", EVENT_KEY, {
        event_category: "match_my_sound",
        event_label: matchMySoundId,
      });
    }
  }, []);

  return (
    <iframe
      id="mms_iframe"
      src={`https://app.matchmysound.com/embed.html?ass_id=${matchMySoundId}`}
      onLoad="mms_resizer(this)"
      width="100%"
      height="600"
      allowFullScreen="allowfullscreen"
      allow="camera;microphone;fullscreen"
    ></iframe>
  );
};

export default MatchMySound;
