import { connect } from "react-redux";

import { fetchSongs, selectFilters, selectHasActiveFilters, slices } from "./store";
import FiltersList from "../shared/FiltersList";
import { filters } from "./filters";

export default connect(
  (state) => ({
    availableFilters: filters,
    activeFilters: selectFilters(state),
    hasActiveFilters: selectHasActiveFilters(state),
    defaultClosed: true,
  }),
  (dispatch) => {
    const { toggleFilter, clearFilters } = slices.ui.filters.actions;
    return {
      closeFilters: () => {
        /* no-op */
      },
      clearFilters: () => {
        dispatch(clearFilters());
        dispatch(fetchSongs());
      },
      toggleFilter: (option) => {
        dispatch(toggleFilter(option));
        dispatch(fetchSongs());
      },
    };
  }
)(FiltersList);
