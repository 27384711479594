// FAQ style select

import { styled } from '@mui/material';
import React, { useCallback, useState } from 'react';


interface ISelectItem {
    id: string;
    title: string;    
}

interface SelectGenericProps<T extends ISelectItem> {
    items: ISelectItem[];
    value?: string;
    onChange?: (item?: T) => void;
}

export function SelectGeneric<T extends ISelectItem>(props: React.PropsWithChildren<SelectGenericProps<T>>) {

    const {
        value,
        onChange,
        items
    } = props;

    const [controlValue, setControlValue] = useState('');

    const eventChange = useCallback((domValue: string) => {
        setControlValue(domValue);
        if (onChange) {
            onChange((items?.find(i => i?.id === domValue) as T));
        }
    }, [onChange, items]);

    return (
        <SelectContainer>
            <select 
                value={value || ''}
                onChange={(event) => eventChange(event?.target?.value)}
            >
                  {items.map((topic, idx) => (
                    <option key={idx} value={topic.id}>
                      {topic.title}
                    </option>
                  ))}
            </select>
        </SelectContainer>
    );
}

const SelectContainer = styled('div')({
    width: '100%',
    paddingBottom: '20px',
    '.nav': {
        display: 'flex',
        flexDirection: 'column',
        borderLeft: '2px solid $lightGrayBackground',

        'button': {
            minHeight: '45px',
            width: '100%',
            background: 'none',
            border: 'none',
            color: '$color-dark-blue',
            fontWeight: 700,
            fontSize: '14px',
            padding: '8px 18px',
            textAlign: 'left',
            // marginBottom: '10px',
            marginLeft: '-2px',

            '&.active': {
                borderLeft: '2px solid $color-bright-orange',
                color: '$color-bright-orange',
                paddingLeft: '16px',
            },

            '&:last-of-type': {
                marginBottom: 0,
            }
        }
    },

    'select': {
        color: '$color-dark-blue',
        padding: '10px 12px',
        boxShadow: '6px 6px 20px rgba(17, 25, 47, 0.14)',
        borderRadius: '10px',
        background: `white
        url("data:image/svg+xml;utf8,<svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10.59 0L6 4.58L1.41 0L0 1.41L6 7.41L12 1.41L10.59 0Z' fill='%231d3446'/></svg>")
        no-repeat`,
        backgroundPosition: 'right 12px top 50%',
        zIndex: 10,
        width: '100%',
        appearance: 'none',
    }
});