import React from "react";
import Loading from "../shared/Loading";

export const ViewMoreButton = ({ onViewMore, loadingMoreResults, hasMore }) => {
  if (!hasMore) return null;

  return (
    <>
      <div className="view-more">
        <div className="view-more__left" />
        {loadingMoreResults ? (
          <Loading isContent={true} />
        ) : (
          <button
            style={{ border: "none", background: "transparent" }}
            onClick={onViewMore}
            className="view-more__label"
            disabled={hasMore === false}
          >
            View More
          </button>
        )}
        <div className="view-more__right" />
      </div>
    </>
  );
};
