// This component is adapted from https://github.com/cwilso/metronome
import React from "react";
import worker from "./MetronomeWorker";
import WebWorker from "./WorkerSetup";
import MetronomeSave from "./MetronomeSave";

const SCHEDULE_AHEAD_TIME = 0.1; // how far ahead to schedule audio (in seconds)
const LOOK_AHEAD = 25.0; // how frequently to call scheduling function (in ms)
const NOTE_LENGTH = 0.05; // length of 'beep' in seconds

export default class Metronome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tempo: 120.0,
      isPlaying: false,
      unlocked: false,
      noteResolution: 2, // 0 == 16th note, 1 == 8th, 2 == quarter
    };

    this.current16thNote; // What note is currently last scheduled?
    this.nextNoteTime = 0.0; // when the next note is due.
    this.last16thNoteDrawn = -1; // the last "box" we drew on the screen
    this.notesInQueue = []; // the notes that have been put into the web audio,
    // and may or may not have played yet. {note, time}
  }

  componentDidMount() {
    let AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioContext = new AudioContext();
    this.requestAnimFrame();
    let scheduleFunction = this.scheduler;
    this.timerWorker = new WebWorker(worker);
    this.timerWorker.onmessage = function (e) {
      if (e.data == "tick") {
        scheduleFunction();
      }
    };
    this.timerWorker.postMessage({ interval: LOOK_AHEAD });
    let { item } = this.props;
    if (item && item.metronome_speed) {
      this.setState({ tempo: parseInt(item.metronome_speed) });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.metronomeDisabled === true) {
      this.timerWorker.postMessage("stop");
      this.setState({ isPlaying: false });
    }
  }

  requestAnimFrame = () => {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function (callback) {
        window.setTimeout(callback, 1000 / 60);
      }
    );
  };

  incrementBpm = () => {
    let { tempo } = this.state;
    if (tempo < 218) {
      this.setState({ tempo: tempo + 1 }, () => {
        if (this.props.unsavedMetronomeSpeedChange) {
          this.props.unsavedMetronomeSpeedChange(tempo);
        }
      });
    }
  };

  decrementBpm = () => {
    let { tempo } = this.state;
    if (tempo > 60) {
      this.setState({ tempo: tempo - 1 }, () => {
        if (this.props.unsavedMetronomeSpeedChange) {
          this.props.unsavedMetronomeSpeedChange(tempo);
        }
      });
    }
  };

  handleScroll = (e) => {
    let tempo = parseInt(e.target.value);
    this.setState({ tempo }, () => {
      if (this.props.unsavedMetronomeSpeedChange) {
        this.props.unsavedMetronomeSpeedChange(tempo);
      }
    });
  };

  nextNote = () => {
    // Advance current note and time by a 16th note...
    let secondsPerBeat = 60.0 / this.state.tempo; // Notice this picks up the CURRENT
    // tempo value to calculate beat length.
    this.nextNoteTime += 0.25 * secondsPerBeat; // Add beat length to last beat time

    this.current16thNote++; // Advance the beat number, wrap to zero
    if (this.current16thNote == 16) {
      this.current16thNote = 0;
    }
  };

  scheduleNote = (beatNumber, time) => {
    // push the note on the queue, even if we're not playing.
    this.notesInQueue.push({ note: beatNumber, time: time });

    if (this.state.noteResolution == 1 && beatNumber % 2) return; // we're not playing non-8th 16th notes
    if (this.state.noteResolution == 2 && beatNumber % 4) return; // we're not playing non-quarter 8th notes

    // create an oscillator
    let osc = this.audioContext.createOscillator();
    osc.connect(this.audioContext.destination);
    if (beatNumber % 16 === 0)
      // beat 0 == high pitch
      osc.frequency.value = 880.0;
    //osc.frequency.value = 440.0
    else if (beatNumber % 4 === 0)
      // quarter notes = medium pitch
      osc.frequency.value = 880.0;
    //osc.frequency.value = 440.0;
    // other 16th notes = low pitch
    else osc.frequency.value = 880.0;
    //osc.frequency.value = 220.0;
    osc.start(time);
    osc.stop(time + NOTE_LENGTH);
  };

  scheduler = () => {
    // while there are notes that will need to play before the next interval,
    // schedule them and advance the pointer.
    while (this.nextNoteTime < this.audioContext.currentTime + SCHEDULE_AHEAD_TIME) {
      this.scheduleNote(this.current16thNote, this.nextNoteTime);
      this.nextNote();
    }
  };

  startMetronome = () => {
    if (!this.state.unlocked) {
      let buffer = this.audioContext.createBuffer(1, 1, 22050);
      let node = this.audioContext.createBufferSource();
      node.buffer = buffer;
      node.start(0);
      this.setState({ unlocked: true }, () => {
        this.play();
      });
    } else {
      this.play();
    }
  };

  play = () => {
    if (!this.state.isPlaying) {
      // start playing
      this.current16thNote = 0;
      this.nextNoteTime = this.audioContext.currentTime;
      this.timerWorker.postMessage("start");
      this.setState({ isPlaying: true });
      return "stop";
    } else {
      this.timerWorker.postMessage("stop");
      this.setState({ isPlaying: false });
      return "play";
    }
  };

  setNoteResolution = (resolutionInteger) => {
    //0 == 16th note, 1 == 8th, 2 == quarter
    this.setState({ noteResolution: resolutionInteger });
  };

  resetToDefaultTempo = () => {
    this.setState({ tempo: 120.0 });
  };

  saveMetronomeSpeed = () => {
    if (this.props.saveMetronomeSpeed) {
      return (
        <MetronomeSave
          item={this.props.item}
          user={this.props.user}
          metronomeSpeed={this.state.tempo}
          resetToDefaultTempo={this.resetToDefaultTempo}
          saveMetronomeSpeed={this.props.saveMetronomeSpeed}
        />
      );
    }
  };

  render() {
    return (
      <div className="metronome">
        <div className="metronome__wrapper">
          <div className="text-center">
            <div className="bpm__info">
              <div className="bpm__info__number">
                {this.state.tempo}
                <span> BPM</span>
              </div>
            </div>
          </div>

          <div className="metronome__controls">
            <div className="m__left">
              <div className="minus-content">
                <div id="minus-bpm" className="minus-bpm" onClick={this.decrementBpm}>
                  <span>-</span>
                </div>
              </div>
            </div>
            <div className="slider">
              <div className="slider__wrapper">
                <input
                  type="range"
                  min={20}
                  max={218}
                  value={this.state.tempo}
                  className="bpm-range"
                  id="bpm-range"
                  onChange={this.handleScroll}
                />
              </div>
            </div>
            <div className="m__right">
              <div className="plus-content">
                <div id="plus-bpm" className="plus-bpm" onClick={this.incrementBpm}>
                  <span>+</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="tempo__container">
          <div className="tempo__option">
            <span className={this.state.noteResolution === 2 ? 'active' : ''}
                  onClick={() => this.setNoteResolution(2)}>1/4th Note</span>
          </div>

          <div className="tempo__option">
            <span className={this.state.noteResolution === 1 ? 'active' : ''}
                  onClick={() => this.setNoteResolution(1)}>8th Note</span>
          </div>

          <div className="tempo__option">
            <span className={this.state.noteResolution === 0 ? 'active' : ''}
                  onClick={() => this.setNoteResolution(0)}>16th Note</span>
          </div>
        </div>
          */}

        <div className="player">
          <div className="player__wrapper">
            <div className="btn-pause" onClick={this.startMetronome}>
              <i
                className={
                  "glyphicon " +
                  (this.state.isPlaying === true ? "glyphicon-pause" : "glyphicon-play")
                }
              ></i>
            </div>
          </div>
        </div>

        {this.saveMetronomeSpeed()}
      </div>
    );
  }
}
