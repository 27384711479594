import React from "react";
import { CDN_URL } from "../../../components/shared/CdnUrl";

const Img = ({ image, title }) => {
  if (!image) {
    return null;
  } else {
    const imageUrl = image.includes(CDN_URL) ? image : `${CDN_URL}${image}`;

    return <img src={imageUrl} alt={title} />;
  }
};

export default Img;
