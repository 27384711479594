import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import LessonTabs from "./LessonTabs";
import Snackbar from "../components/shared/ui/Snackbar";
import MobileContextMenu from "../components/shared/ui/MobileContextMenu";
import { setActiveTab, toggleMobileContextMenuVisibility } from "../components/shared/ui/uiSlice";
import { withInnerWidth } from "../components/shared/WithInnerWidth";
import PromoItems from "../components/shared/PromoItems";
import axiosWithCSRF from "../components/shared/axiosWithCSRF";
import LessonHeroNew from "./LessonHeroNew";
import { USER_ITEM_STATUSES } from "./swoosh/consts";
import { createLessonItem, deleteLessonItem } from "./store/effects/lessonEffects";
import { openAlert } from "../components/shared/ui/uiSlice";
import MobileMenuOptions from "./MobileMenuOptions";
import { LoginModal } from "../jg-material/auth/LoginModal";

const LessonContainer = ({
  lesson,
  currentUser,
  openAlert,
  paywall,
  promoItems,
  toggleMobileContextMenuVisibility,
  width,
  setActiveTab,
}) => {
  const [mobileContextMenuToggleBtn, setMobileContextMenuToggleBtn] = useState(null);
  const [loadingUserItems, setLoadingUserItems] = useState(false);
  const [savedLessonIds, setSavedLessonIds] = useState([]);
  const [completedLessonIds, setCompletedLessonIds] = useState([]);
  const [loginFormOpen, setLoginFormOpen] = useState(false);
  const tabsRef = useRef();

  useEffect(() => {
    if (currentUser) {
      storePageView();
      setLoadingUserItems(true);
      fetchUserItems();
    }

    setMobileContextMenuToggleBtn(document.querySelector(".mobile-context-menu-toggle-btn"));
  }, []);

  function storePageView() {
    axiosWithCSRF().post("/recent_pageviews", {
      recent_pageview: {
        viewable_type: "Lesson",
        viewable_id: lesson.data.id,
      },
    });
  }

  function lessonIdsFromUserItems(userItems, status) {
    return userItems
      .filter((item) => item.attributes.itemStatus === status)
      .flatMap((item) => item.attributes.nestedIds);
  }

  function fetchUserItems() {
    axiosWithCSRF()
      .get(`/user_items/by_lesson?lesson_id=${lesson.data.id}`)
      .then((response) => {
        const { userItems } = response.data;
        if (userItems && userItems.length > 0) {
          setCompletedLessonIds(lessonIdsFromUserItems(userItems, USER_ITEM_STATUSES.completed));
          setSavedLessonIds(lessonIdsFromUserItems(userItems, USER_ITEM_STATUSES.saved));
        }

        setLoadingUserItems(false);
      });
  }

  const userHasCompletedItem = completedLessonIds.includes(parseInt(lesson.data.id));
  const userHasSavedItem = savedLessonIds.includes(parseInt(lesson.data.id));

  function saveItem(itemId, status) {
    createLessonItem(itemId, status)
      .then((response) => {
        const newIds = response.attributes.nestedIds;
        status === USER_ITEM_STATUSES.saved
          ? setSavedLessonIds(newIds)
          : setCompletedLessonIds(newIds);
        openAlert({
          message: `Added this to your ${status} lessons!`,
          severity: "success",
        });
      })
      .catch(() => {
        openAlert({
          message: `There was a problem adding this to your ${status} lessons.`,
          severity: "error",
        });
      });
  }

  function removeItem(itemId, status) {
    deleteLessonItem(itemId, status)
      .then(() => {
        if (status === USER_ITEM_STATUSES.saved) {
          setSavedLessonIds(savedLessonIds.filter((id) => id !== parseInt(itemId)));
        } else if (status === USER_ITEM_STATUSES.completed) {
          setCompletedLessonIds(completedLessonIds.filter((id) => id !== parseInt(itemId)));
        }
        openAlert({
          message: `Removed this from your ${status} lessons!`,
          severity: "success",
        });
      })
      .catch(() => {
        openAlert({
          message: `There was a problem removing this from your ${status} lessons.`,
          severity: "error",
        });
      });
  }

  const toggleFavorite = (itemExists, lessonId) => {
    if (!currentUser) {
      setLoginFormOpen(true);
    } else if (itemExists) {
      removeItem(lessonId, USER_ITEM_STATUSES.saved);
    } else {
      saveItem(lessonId, USER_ITEM_STATUSES.saved);
    }
  };

  const toggleCompleted = (itemExists, lessonId) => {
    if (!currentUser) {
      setLoginFormOpen(true);
    } else if (itemExists) {
      removeItem(lessonId, USER_ITEM_STATUSES.completed);
    } else {
      saveItem(lessonId, USER_ITEM_STATUSES.completed);
    }
  };

  const viewResources = () => {
    setActiveTab("Resources");
    window.location.hash = "resources";
    tabsRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };

  return (
    <div className="lesson">
      <LessonHeroNew
        currentUser={currentUser}
        lesson={lesson}
        paywall={paywall}
        completedLessonIds={completedLessonIds}
        savedLessonIds={savedLessonIds}
        loadingUserItems={loadingUserItems}
        toggleFavorite={toggleFavorite}
        toggleCompleted={toggleCompleted}
        toggleMobileContextMenuVisibility={toggleMobileContextMenuVisibility}
        width={width}
        viewResources={viewResources}
      />

      <div ref={tabsRef}>
        <LessonTabs />
      </div>

      <Snackbar />
      <MobileContextMenu floating={width > 768} anchor={mobileContextMenuToggleBtn}>
        <MobileMenuOptions
          lesson={lesson}
          toggleCompleted={toggleCompleted}
          toggleFavorite={toggleFavorite}
          userHasSavedItem={userHasSavedItem}
          userHasCompletedItem={userHasCompletedItem}
        />
      </MobileContextMenu>
      <LoginModal isOpen={loginFormOpen} handleClose={() => setLoginFormOpen(false)} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  lesson: state.entity.lesson,
  currentUser: state.session.currentUser,
  promoItems: state.entity.promoItems,
  paywall: state.entity.paywall,
  activeTab: state.ui.activeTab,
});

const mapDispatchToProps = (dispatch) => ({
  toggleMobileContextMenuVisibility: () => dispatch(toggleMobileContextMenuVisibility()),
  openAlert: (alert) => dispatch(openAlert(alert)),
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withInnerWidth(LessonContainer));
