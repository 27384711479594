import React from "react";

import Category from "../pages/FAQ/Category";
import { tabs } from "../pages/FAQ/data/faqs";

export const TabsFaqs = ({ additionalContent, faqs }) => (
  <div className="faq">
    <Category
      faqs={faqs}
      showHeader={false}
      categoryData={tabs}
      useUrlRouting={false}
      showBreadcrumbs={false}
      additionalContent={additionalContent}
    />
  </div>
);
