export const sortByOptions = {
  popularity: "popularity",
  relevance: "relevance",
  titleAsc: "titleAsc",
  titleDesc: "titleDesc",
  dateDesc: "dateDesc",
  dateAsc: "dateAsc",
  difficulty: "difficulty",
};

export const sortByLabels = {
  [sortByOptions.popularity]: "Popularity",
  [sortByOptions.titleAsc]: "Title A ⭢ Z",
  [sortByOptions.titleDesc]: "Title Z ⭢ A",
  [sortByOptions.dateDesc]: "Date New ⭢ Old",
  [sortByOptions.dateAsc]: "Date Old ⭢ New",
  [sortByOptions.difficulty]: "Difficulty",
  [sortByOptions.relevance]: "Relevance",
};

export const artistSortByLabels = {
  [sortByOptions.popularity]: sortByLabels[[sortByOptions.popularity]],
  [sortByOptions.relevance]: sortByLabels[[sortByOptions.relevance]],
  [sortByOptions.titleAsc]: "Name A ⭢ Z",
  [sortByOptions.titleDesc]: "Name Z ⭢ A",
};

export const sectionOptions = {
  all: "all",
  latest: "latest",
  index: "index",
  // artists: "artists",
  requests: "requests",
};

export const sectionLabelsByType = {
  [sectionOptions.all]: "All",
  [sectionOptions.latest]: "Latest Releases",
  [sectionOptions.index]: "Songs",
  // [sectionOptions.artists]: "Artists",
  [sectionOptions.requests]: "Requests Board",
};

export const listingStyles = {
  list: "list",
  grid: "grid",
};

export const resultMergeStrategies = {
  append: "append",
  replace: "replace",
};
