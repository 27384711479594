import React, { useState } from "react";
import SignInForm from "./SignInForm";
import AppDisclaimerLink from "../registrations/AppDisclaimerLink";
import AppDisclaimer from "../registrations/AppDisclaimer";

const SECTIONS = {
  form: "form",
  app: "app",
};

const SignIn = ({ sectionHeader, setActivePage }) => {
  const [activeSection, setActiveSection] = useState(SECTIONS.form);
  return (
    <>
      {sectionHeader("Log In")}
      {activeSection === SECTIONS.form && (
        <>
          <SignInForm setActivePage={setActivePage} />
          <AppDisclaimerLink setActiveSection={setActiveSection} />
        </>
      )}

      {activeSection === SECTIONS.app && <AppDisclaimer setActiveSection={setActiveSection} />}
    </>
  );
};

export default SignIn;
