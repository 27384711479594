import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LightTooltip } from "../../shared/ui/Tooltips";

import {
  selectCurrentUserSongRequests,
  selectIsLoggedIn,
  selectTwentiethMostRecentVote,
  userVotedForSongRequest,
} from "../store";
import { createVote, deleteVote } from "../store/effects";
import LoginModal from "../../auth/LoginModal";
import { BootstrapSize, breakpoints } from "../../shared/bootstrap-helpers";
import { withInnerWidth } from "../../shared";

export default function ({ songRequest }) {
  const userVoted = useSelector(userVotedForSongRequest)(songRequest.id);

  return userVoted ? (
    <UnvoteButton songRequest={songRequest} />
  ) : (
    <VoteButton songRequest={songRequest} />
  );
}

const oneWeekInMilliseconds = 604800000;

const VoteButton = withInnerWidth(({ songRequest, width }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const rateLimitingVote = useSelector(selectTwentiethMostRecentVote);
  const nextPossibleVoteTime =
    rateLimitingVote == null
      ? 0
      : new Date(rateLimitingVote.attributes.createdAt).getTime() + oneWeekInMilliseconds;
  const isRateLimited = nextPossibleVoteTime > Date.now();

  const [showModal, setShowModal] = React.useState(false);

  const showText = width > breakpoints[BootstrapSize.smDevice];

  return (
    <>
      <LightTooltip
        title={
          isRateLimited
            ? `You're allowed to vote 20 times within a week. You can vote again on ${new Date(
                nextPossibleVoteTime + oneWeekInMilliseconds / 7
              ).toLocaleString("en-US", {
                weekday: "long",
                month: "long",
                day: "numeric",
              })}`
            : ""
        }
      >
        <div>
          <button
            disabled={isRateLimited}
            onClick={() =>
              isLoggedIn && !isRateLimited ? dispatch(createVote(songRequest)) : setShowModal(true)
            }
            className="button button--no-background vote-button vote-button__vote"
          >
            <i className="icon icon-thumbs-up-hollow" />{" "}
            {showText && <span className="vote-text">Vote</span>}
          </button>
        </div>
      </LightTooltip>
      {showModal && <LoginModal isOpen={showModal} toggleModal={() => setShowModal(false)} />}
    </>
  );
});

const UnvoteButton = ({ songRequest }) => {
  const dispatch = useDispatch();
  const userRequests = useSelector(selectCurrentUserSongRequests);
  const disabled = userRequests.some(
    (userRequest) => Number(userRequest.id) === Number(songRequest.id)
  );
  return (
    <LightTooltip title={disabled ? "Can't remove your vote for your own request!" : ""}>
      <button
        onClick={() => !disabled && dispatch(deleteVote(songRequest))}
        className="button button--no-background vote-button vote-button__unvote"
      >
        <i className="icon icon-thumbs-up-filled" />
      </button>
    </LightTooltip>
  );
};
