import React from "react";
import { fontFamilies } from "../../../jg-material/theme/typography";

export const StripeInput = ({ component: Component, inputRef, ...rest }) => {
  const elementRef = React.useRef();
  React.useImperativeHandle(inputRef, () => ({
    // @ts-ignore
    focus: () => elementRef.current.focus,
  }));

  const stripeInputStyle = {
    base: {
      fontFamily: fontFamilies.openSans,
      fontSize: "16px",
      lineHeight: "22px",
    },
  };

  return (
    <Component
      onReady={(element) => (elementRef.current = element)}
      options={{
        style: stripeInputStyle,
      }}
      {...rest}
    />
  );
};
