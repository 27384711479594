import React from "react";
import { connect } from "react-redux";
import {
  fetchArtists,
  selectArtistFilters,
  selectArtistFiltersOpen,
  selectHasActiveArtistFilters,
  slices,
} from "./store";
import FiltersList from "../shared/FiltersList";
import { filters, filterTypes } from "./filters";
import { withBootstrapSize } from "../shared/WithBootstrapSize";
import { PreventScroll } from "../shared";
import { BootstrapSize, breakpoints } from "../shared/bootstrap-helpers";
import { FiltersOpenCloseButton } from "../shared/FiltersOpenCloseButton";

export const artistFilterTypes = Object.entries(filterTypes)
  .filter(([_, val]) =>
    [filterTypes["Difficulty Level"], filterTypes["Books & Apps"], filterTypes.Genre].includes(val)
  )
  .reduce((accum, [key, val]) => ({ ...accum, [key]: val }), {});
export const artistFilters = filters.filter(({ type }) => artistFilterTypes[type] != null);

const mSTP = (state) => ({
    availableFilters: artistFilters,
    filtersOpen: selectArtistFiltersOpen(state),
    activeFilters: selectArtistFilters(state),
    hasActiveFilters: selectHasActiveArtistFilters(state),
    defaultClosed: true,
    showHeader: true,
  }),
  mDTP = (dispatch) => {
    const { openArtistFilters, closeArtistFilters } = slices.ui.actions;

    const { toggleFilter, clearFilters } = slices.ui.artistFilters.actions;
    return {
      openFilters: () => dispatch(openArtistFilters()),
      closeFilters: () => dispatch(closeArtistFilters()),
      clearFilters: () => {
        dispatch(clearFilters());
        dispatch(fetchArtists());
      },
      toggleFilter: (option) => {
        dispatch(toggleFilter(option));
        dispatch(fetchArtists());
      },
    };
  };

export const ArtistFiltersButton = connect(
  mSTP,
  mDTP
)(
  withBootstrapSize(({ width, bootstrapSize, ...props }) => (
    <PreventScroll
      shouldPreventScroll={() =>
        props.filtersOpen && width < breakpoints[BootstrapSize[bootstrapSize]]
      }
    >
      {props.filtersOpen && <div className="overlay" onClick={props.closeFilters} />}
      <FiltersOpenCloseButton {...props} />
    </PreventScroll>
  ))
);

export const ArtistFiltersList = connect(mSTP, mDTP)(FiltersList);
