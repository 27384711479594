import React from "react";
import { connect } from "react-redux";
import { setChordHighlighted } from "../../store/songStore";

const HighlightChord = ({ chordHighlighted, setChordHighlighted }) => {
  return (
    <li className="submenu-item">
      <div className="change-option">
        <div className="change-option-label">
          <span>Highlight</span>
        </div>
        <div className="change-option-action">
          <label className="switch">
            <input
              type="checkbox"
              defaultChecked={chordHighlighted}
              onChange={(e) => {
                setChordHighlighted(e.target.checked);
              }}
            />
            <div className="slider round">
              <span className="on">ON</span>
              <span className="off">OFF</span>
            </div>
          </label>
        </div>
      </div>
    </li>
  );
};

const mapStateToProps = (state) => ({
  chordHighlighted: state.entity.chordHighlighted,
});

const mapDispatchToProps = (dispatch) => ({
  setChordHighlighted: (isHighlighted) => dispatch(setChordHighlighted(isHighlighted)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HighlightChord);
