import React from "react";
import { Box, Link, Modal, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { workshopSelectors } from "../store/workshops.slice";

export const TermsAndConditionsLink = ({
  inPaymentForm = false,
}: {
  inPaymentForm?: boolean | null;
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const workshop = useSelector(workshopSelectors.selectCurrentWorkshop);

  const terms =
    workshop &&
    (workshop.attributes.slug == "2023-Acoustic-Jam-Week"
      ? rawQuebecTerms
      : workshop.attributes.slug == "2023-Acoustic-Electric-Classics"
      ? rawElectricAcousticTerms
      : rawTerms);

  return (
    <Box>
      <Link
        href="#"
        color="secondary"
        // marginTop={3}
        onClick={() => setModalOpen(true)}
        component="div"
        sx={{ cursor: "pointer" }}
      >
        {inPaymentForm ? "I Accept Booking Terms & Conditions" : "Booking T&Cs"}
      </Link>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80vw",
            height: "100vh",
            overflowY: "scroll",
          }}
        >
          <Paper sx={{ p: 4 }}>
            <Typography variant="h2">TERMS AND CONDITIONS</Typography>

            {terms && terms.split("\n").map((p, idx) => (
              <Typography
                sx={{ mb: 2 }}
                key={idx}
                dangerouslySetInnerHTML={{ __html: p }}
              ></Typography>
            ))}
          </Paper>
        </Box>
      </Modal>
    </Box>
  );
};

const rawTerms = `
The following terms and conditions apply to all companies and or persons or other entities (“you”) who attend a JustinGuitar workshop (“Workshop”) organized by JustinGuitar Ltd (Co. No. 6589840) of 315 Chiswick High Rd, London W4 4HH (“we” or “us”). Please ensure you have read and understand the following:

1. Acceptance of terms and conditions
By completing the registration form for a particular workshop, you agree to the terms and conditions set out below (“Terms”), and they will be incorporated into the contract between us (“Contract”). You will ensure that your employees, partners, agents, contractors, subcontractors, and any other person attending the Workshop on your behalf shall comply with these Terms. The Contract is formed when we send you a booking confirmation.

2. Registration
If you've received an email that confirms there is a place available for you on this Workshop, the payment of this (“Registration Fee”) secures your booking. You'll receive from us a confirmation email and more details on how to get ready for your workshop.

3. Payment
Prices are inclusive of VAT except where expressly stated otherwise. A deposit of £250 must be paid as a (“Registration Fee”). The balance (“Balance”) must be paid by you no later than 40 days before the date of the Workshop. If the Deposit is not received within 7 days of your registration or if the Balance is not paid at least 40 days prior to the date of the Workshop, your registration will be canceled. The payment of any refund will be at our complete discretion. The Deposit must be made via www.justinguitar.com/workshops, following the link you've received by email. The Balance must be made using credit card. We shall provide you with your invoice when such payment must be made. <b>The Registration Fee does not include the cost of flights, travel, and accommodation, for which you are separately liable (see below)</b>.

4. Accommodation
The Workshop is a residential workshop and will take place at the hotel shown on the relevant Workshop webpage (“Hotel”). We have reserved rooms for those attending the Workshop, but it is your responsibility to book and pay for your accommodation for the duration of your stay. Please see links on the relevant Workshop page for hotel costs and availability or contact Carlo by email at roberti.carlo@gmail.com. We are pleased to confirm that the owners of the Hotel have agreed to provide attendees with a special discounted rate which costs include welcome drink on arrival, accommodation for 6 nights, 6 breakfasts, 6 dinners and 5 lunches.

5. Flights and Arrival
You are responsible for booking and paying for flights to and from Italy and for ground transportation to and from the airport and the Hotel. It is your responsibility to ensure that you arrive at the Hotel between 4pm and 6pm on the first day of the Workshop, as the initial meeting is at 6pm, where you will meet your teacher and fellow students. If you wish to arrive a day earlier, please let us know and we will inform the Hotel so that they can reserve an additional night’s accommodation for you (subject to availability, and at your expense). Please allow for plenty of journey time to arrive at the Hotel. If you are late at the workshop or prevented from attending the Workshop due to travel delays or any other circumstances, we shall not be obliged to provide you with a refund or to wait to start the Workshop until you arrive.

6. Refunds
All payments for the Workshop shall be non-refundable except as set out in paragraphs 7 and 8 below. However, if you are unable to attend the Workshop for any reason you may email us at roberti.carlo@gmail.com to provide us with the name of a substitute to attend the Workshop on your behalf and on receipt of this email we shall make such substitution and allow the substitute access to the Workshop. We may at our discretion charge an administration fee of up to £50 for making such substitution.

7. Cancellation
There may be circumstances in which we need to cancel the Workshop. In the unlikely event that we have to cancel the Workshop, we will provide you with a full refund of the amount of the Registration Fee that you have paid to us within 30 days of the date of cancellation.

8. Postponement or change to venue
There may be circumstances in which we need to postpone the Workshop, change any of the teachers or change the venue at which Workshop is to be held and if we do, we shall advise you of this as soon as we possibly can. In such event, you shall have the option of withdrawing from the Workshop and obtaining a full refund of the Registration Fee that you have paid, but please note that should this happen we will not accept any liability in relation to any travel and/or accommodation expenses incurred by you.

9. Liability and Disclaimer – important, you must read this
Our sole liability in relation to any cancellation, postponement or change of venue shall be limited to the Registration Fee paid by you for the Workshop and we shall not be liable under any circumstances for any consequential losses.
    In providing the Workshop, no person is acting as your solicitor or providing you with legal advice. We are providing you with training, course materials and guidance.


10. Your obligations
If you act in any way which in our opinion is likely to cause any harm or nuisance to any person at the Workshop, you will be required to leave the Workshop and we shall not be liable to refund your Registration Fee or any other payment.
    You must comply at all times with the health and safety policy of the venue. You must comply with all requests from us or the Hotel with regard to health and safety and failure to do so will result in you being asked to leave the Workshop (in which case no refund will be provided). You may not bring any equipment or items of a hazardous or dangerous nature to the Workshop.
    You shall not cause any damage to any part of the Hotel (including outside areas, and all inside walls, flooring, fixtures and fittings). You are solely liable for any damage caused by you, your employees, contractors, sub-contractors and agents to any such area of the Hotel and shall fully reimburse us in relation to any damage so caused.
    You must keep your personal belongings with you at all times and we accept no liability for any damage to, loss of or theft of any of your belongings or other items brought to the Workshop by you.

11. Promotional materials and materials at the Workshop
You agree that we may include your details in any promotional materials relating to the Workshop and/or any materials used at the Workshop. We are not liable for errors or omissions contained in such information.
    Unless otherwise stated, the copyright for any such promotional materials and any materials used at the Workshop (including course notes, slides, brochures, articles and case studies) belongs to us and may not be reproduced in any medium without our prior written consent.
    You may use such materials for your own private purposes only and may not reproduce, publish or deal with such materials in any way for any commercial use.
    We reserve the right to change the published programme or materials as we think fit.

12. Photography and filming
We may wish to photograph or film the Workshop and reserve the right to do so for the purposes of promoting future workshops or otherwise. You consent to us (or any other person attending the Workshop) photographing or filming you and any persons attending the Workshop with you. You may not photograph or video the Workshop without our prior consent, but you are welcome to take photographs and to share them.

13. Data protection
We will communicate with you using the contact details provided on the Registration Form for the purposes of the Workshop. This includes addresses, email addresses, professional history and interests where notified. This will be used confidentially and for the purposes of running the Workshop and the performance of our obligations and the exercise of our rights
under these Terms, including the collection of Registration Fees, mailing of course material and notification of other events and products and services that we and our affiliated companies may offer. We shall be entitled to pass on all of your contact details to the Workshop contractors. If you do not wish to receive information about other events or other products or services offered by our affiliated companies and us, please email us at roberti.carlo@gmail.com.

14. Tickets
We shall not send you tickets for the Workshop. We will have your name on the attendee list and if you have paid the Registration Fee you shall be provided with access to the Workshop.

15. Disability, medical conditions and dietary requirements
If you have any disability or medical condition that requires us to make special arrangements for you or any special dietary requirements, please email us at roberti.carlo@gmail.com as soon as possible and in any event at least 2 weeks prior to the date of the Workshop.


16. Limitations of Liability
Whilst every reasonable precaution is taken by us to ensure security and safety at the Workshop, we shall not in any way be liable for any loss or damage suffered by you whatsoever about the Workshop, save that nothing in these Terms shall be deemed to limit the liability of any person for death or personal injury caused by negligence.
    Nothing in these Terms shall limit or exclude our liability for death or personal injury caused by our negligence, fraudulent misrepresentation, or other liability. It is unlawful to exclude or limit liability.
    Our total liability for any matter arising out of the Contract shall be limited to the Registration Fee paid by you for the Workshop, and we shall not in any circumstances be liable to you for any consequential loss whatsoever.

17. Governing law
These Terms shall be governed by English Law, and you hereby submit to the exclusive jurisdiction of the English Courts.`;

const rawQuebecTerms = `
The following terms and conditions apply to all companies and or persons or other entities ("you") who attend a JustinGuitar workshop ("Workshop") organized by JustinGuitar Ltd (Co. No. 6589840) of 315 Chiswick High Rd, London W4 4HH ("we" or "us"). Please ensure you have read and understand the following:

<b>1. Acceptance of Terms & Conditions</b>
By paying your deposit for the Acoustic Jam Week - 2023, you agree to the terms and conditions set out below ("Terms"), and they will be incorporated into the contract between us ("Contract"). You will ensure that your employees, partners, agents, contractors, subcontractors, and any other person attending the Workshop on your behalf shall comply with these Terms. The Contract is formed when you accept to pay for the deposit fee described below.

<b>2. Registration</b>
If you've received an email confirming your place in this Workshop, paying the deposit ("Registration Fee") secures your booking. You'll then receive a confirmation email and more details on how to get ready for your workshop and book your Accommodation.

<b>3. Payment</b>
Workshop prices are inclusive of VAT except where expressly stated otherwise. You must pay a non-refundable deposit of £200 ("Registration Fee"). You must pay the balance of £1400 ("Balance") no later than 45 days before the date of the Workshop. If you do not pay your Deposit within 7 days of our email confirming your place or if you do not pay the Balance at least 45 days before the date of the Workshop, we will cancel your registration. We shall provide you with your invoice when we receive your payment. <b>The Registration Fee does not include the cost of flights, travel, and accommodation, for which you are separately liable (see below)</b>.

<b>4. Accommodation</b>
The Workshop is a residential workshop and will take place at the hotel shown on the relevant Workshop webpage ("Hotel"). We have reserved rooms for those attending the Workshop, but you must book and pay for your accommodation. Rooms are in various inns around the Le Baluchon site. The rooms are available from 4 p.m. on the day of arrival, and you must check out before noon on the day of departure. Le Baluchon will provide a reservation form, and you must make your reservation before April 28, 2023; they will require a credit card number and a non-refundable deposit at the time of reservation. We will not be able to secure your room after that date.
The following cancellation charges will be applied:
<p style="margin: 0 0 0 20px;">- A deposit of $100 per night per room will be billed to your credit card when you make your reservation; This deposit is non-refundable, non-redeemable, and non-transferable.</p>
<p style="margin: 0 0 0 20px;">- In the 30 days before the arrival date, a charge equivalent to the full cost of the stay will be applied.</p>
Please read the FAQ on the relevant Workshop page for hotel costs and availability, or contact Laryne by email at laryne@justinguitar.com. We are pleased to confirm that the owners of the Hotel have agreed to provide attendees with a special discounted rate, including accommodation for 6 nights, 6 breakfasts, 6 dinners, and 5 lunches.

<b>5. Flights and Arrival</b>
You are responsible for booking and paying for flights and ground transportation to and from the Workshop's location. You are responsible for ensuring that you arrive at the Hotel between 4 p.m. and 6 p.m. on the first day of the Workshop, as the initial meeting is at 6 p.m., where you will meet your teacher and fellow students. If you wish to arrive a day earlier, please contact Laryne (laryne@justinguitar.com). Please allow for plenty of journey time to arrive at the Hotel. If you are late at the workshop or prevented from attending the Workshop due to travel delays or any other circumstances, we shall not be obliged to provide you with a refund or to wait to start the Workshop until you arrive.

<b>6. Refunds</b>
You can get a full refund of your deposit fee if you cancel before 60 days of the Workshop starting date. If you cancel from 59 to 20 days before the beginning of the Workshop, we will refund 50% of your deposit. No refund is due for cancellations from the 19th day before the Workshop begins, but please contact laryne@justinguitar.com as soon as you know you will need to cancel. If you pay the Remaining Fee but need to cancel, we will offer you a full refund if we can find someone to take your place.

In the (very rare) case that we have to cancel the Workshop, you are entitled to a full refund. Please note that Accommodation and Transportation to the Workshop are your entire responsibility, even in case of cancellation.

<b>7. Cancellation</b>
There may be circumstances in which we must cancel the Workshop. In the unlikely event that we have to cancel the Workshop, we will provide you with a full refund of the amount of the Registration Fee that you paid us within 30 days of the date of cancellation.

<b>8. Postponement or change to venue</b>
There may be circumstances in which we need to postpone the Workshop, change any of the teachers or change the venue at which we will hold the Workshop. If we do, we shall advise you as soon as possible. In such event, you shall have the option of withdrawing from the Workshop and obtaining a full refund of the Registration Fee that you have paid, but please note that should this happen, we will not accept any liability for any travel and accommodation expenses incurred by you.

<b>9. Liability and Disclaimer - important; you must read this!</b>
Our sole liability to any cancellation, postponement, or change of venue shall be limited to the Registration Fee paid by you for the Workshop, and we shall not be liable under any circumstances for any consequential losses. In providing the Workshop, no person is acting as your solicitor or providing you with legal advice. We are providing you with training, course materials, and guidance.

<b>10. Your obligations</b>
If you act in a way which, in our opinion, is likely to cause any harm or nuisance to any person at the Workshop, you will be required to leave the Workshop, and we shall not be liable to refund your Registration Fee or any other payment.
You must comply at all times with the health and safety policy. You must comply with all requests from the Hotel or us about health and safety, and failure to do so will result in you being asked to leave the Workshop (in which case we will provide no refund). You may not bring any equipment or items of a hazardous nature to the Workshop.
You shall not cause any damage to any part of the Hotel (including outside areas and all inside walls, flooring, fixtures, and fittings). You are solely liable for any damage caused by you, your employees, contractors, sub-contractors, and agents to any such area of the Hotel and shall fully reimburse us for any damage caused.
You must keep your personal belongings with you at all times, and we accept no liability for any damage to, loss of, or theft of any of your belongings or other items brought to the Workshop by you.

<b>11. Promotional materials and materials at the Workshop</b>
You agree that we may include your details in any promotional materials relating to the Workshop and any materials used at the Workshop. We are not liable for errors or omissions contained in such information.
Unless otherwise stated, the copyright for any such promotional materials and any materials used at the Workshop (including course notes, slides, brochures, articles, and case studies) belongs to us and may not be reproduced in any medium without our prior written consent.
You may use such materials for your private purposes only and may not reproduce, publish or deal with such materials in any way for commercial use. We reserve the right to change the published program or materials as we think fit.

<b>12. Photography & Filming</b>
We may wish to photograph or film the Workshop and reserve the right to do so to promote future workshops or otherwise. You consent to us (or any other person attending the Workshop) photographing or filming you and any persons attending the Workshop with you. You may not photograph or video the Workshop without our consent, but you are welcome to take photographs and share them.

<b>13. Data Protection</b>
We will communicate with you using the contact details provided on your Registration. Under these Terms, including the collection of Registration Fees, mailing of course material, and notification of other events and products, and services that our affiliated companies and we may offer. We shall be entitled to pass on all your contact details to the Workshop contractors. If you do not wish to refrain from receiving information about other events or other products or services offered by our affiliated companies and us, please email us at laryne@justinguitar.com.

<b>14. Tickets</b>
We shall not send you tickets for the Workshop. We will have your name on the attendee list, and if you have paid the Registration Fee, we shall provide you access to the Workshop.

<b>15. Disability, Medical Conditions & Dietary Requirements</b>
If you have any disability or medical condition that requires us to make special arrangements for you or any special dietary requirements, please email us at laryne@justinguitar.com as soon as possible and in any event at least 2 weeks before the date of the Workshop.

<b>16. Limitations of Liability</b>
While we take every reasonable precaution to ensure security and safety at the Workshop, we shall not in any way be liable for any loss or damage suffered by you whatsoever about the Workshop, save that nothing in these Terms shall be deemed to limit the liability of any person for death or personal injury caused by negligence.
Nothing in these Terms shall limit or exclude our liability for death or personal injury caused by our negligence, fraudulent misrepresentation, or other liability. It is unlawful to exclude or limit liability.
Our total liability for any matter arising out of the Contract shall be limited to the Registration Fee paid by you for the Workshop, and we shall not in any circumstances be liable to you for any consequential loss whatsoever.

<b>17. Governing law</b>
English Law shall govern these Terms, and you hereby submit to the exclusive jurisdiction of the English Courts.`;

const rawElectricAcousticTerms = `
The following terms and conditions apply to all companies and or persons or other entities ("you") who attend a JustinGuitar workshop ("Workshop") organized by JustinGuitar Ltd (Co. No. 6589840) of 315 Chiswick High Rd, London W4 4HH ("we" or "us"). Please ensure you have read and understand the following:

<b>1. Acceptance of Terms & Conditions</b>
By paying your deposit for the Acoustic & Electric Classics Band Jam Workshop (England 2023), you agree to the terms and conditions set out below ("Terms"), and they will be incorporated into the contract between us ("Contract"). You will ensure that your employees, partners, agents, contractors, subcontractors, and any other person attending the Workshop on your behalf shall comply with these Terms. The Contract is formed when you accept to pay for the deposit fee described below.

<b>2. Registration</b>
If you've received an email confirming your place in this Workshop, paying the deposit ("Registration Fee") secures your booking. You'll then receive a confirmation email and more details on how to get ready for your workshop and book your Accommodation.

<b>3. Payment</b>
Workshop prices are inclusive of VAT except where expressly stated otherwise. You must pay a non-refundable deposit of £400 ("Registration Fee"). You must pay the balance of £2300 ("Balance") no later than 45 days before the date of the Workshop. If you do not pay your Deposit within 7 days of our email confirming your place or if you do not pay the Balance at least 45 days before the date of the Workshop, we will cancel your registration. We shall provide you with your invoice when we receive your payment. <b>The Registration Fee does not include the cost of flights, travel, and accommodation, for which you are separately liable (see below)</b>.

<b>4. Accommodation</b>
The Workshop is a residential workshop and will take place at the hotel shown on the relevant Workshop webpage ("Hotel"). We have reserved rooms for those attending the Workshop, and paying the total of £2700 per student includes your Workshop fee and Accommodations & Meals package. 
<ul>
<li style="margin-left: 20px;">Accommodation and Meals are for a single room/person. If you're bringing an invitee, extra charges will occur, and you must contact <a href="mailto:laryne@justinguitar.com">laryne@justinguitar.com</a> to verify availability and pricing.</li>
</ul>
Please read the FAQ on the relevant Workshop page for hotel costs and availability, or contact Laryne by email at <a href="mailto:laryne@justinguitar.com">laryne@justinguitar.com</a>. We are pleased to confirm that the owners of the Hotel have agreed to provide attendees with a special discounted rate, including accommodation for 6 nights, 6 breakfasts, 6 dinners, and 5 lunches.

<b>5. Flights and Arrival</b>
You are responsible for booking and paying for flights and ground transportation to and from the Workshop's location. You are responsible for ensuring that you arrive at the Hotel between 4 p.m. and 6 p.m. on the first day of the Workshop, as the initial meeting is at 6 p.m., where you will meet your teacher and fellow students. If you wish to arrive a day earlier, please contact Laryne (<a href="mailto:laryne@justinguitar.com">laryne@justinguitar.com</a>). Please allow for plenty of journey time to arrive at the Hotel. If you are late at the workshop or prevented from attending the Workshop due to travel delays or any other circumstances, we shall not be obliged to provide you with a refund or to wait to start the Workshop until you arrive.

<b>6. Refunds</b>
You can get a full refund of your deposit fee if you cancel before 60 days of the Workshop starting date. If you cancel from 59 to 20 days before the beginning of the Workshop, we will refund 50% of your deposit. No refund is due for cancellations from the 19th day before the Workshop begins, but please contact laryne@justinguitar.com as soon as you know you will need to cancel. If you pay the Remaining Fee but need to cancel, we will offer you a full refund if we can find someone to take your place.

In the (very rare) case that we have to cancel the Workshop, you are entitled to a full refund. Please note that Accommodation and Transportation to the Workshop are your entire responsibility, even in case of cancellation.

<b>7. Cancellation</b>
There may be circumstances in which we must cancel the Workshop. In the unlikely event that we have to cancel the Workshop, we will provide you with a full refund of the amount of the Registration Fee that you paid us within 30 days of the date of cancellation.

<b>8. Postponement or change to venue</b>
There may be circumstances in which we need to postpone the Workshop, change any of the teachers or change the venue at which we will hold the Workshop. If we do, we shall advise you as soon as possible. In such event, you shall have the option of withdrawing from the Workshop and obtaining a full refund of the Registration Fee that you have paid, but please note that should this happen, we will not accept any liability for any travel and accommodation expenses incurred by you.

<b>9. Liability and Disclaimer - important; you must read this!</b>
Our sole liability to any cancellation, postponement, or change of venue shall be limited to the Registration Fee paid by you for the Workshop, and we shall not be liable under any circumstances for any consequential losses. In providing the Workshop, no person is acting as your solicitor or providing you with legal advice. We are providing you with training, course materials, and guidance.

<b>10. Your obligations</b>
If you act in a way which, in our opinion, is likely to cause any harm or nuisance to any person at the Workshop, you will be required to leave the Workshop, and we shall not be liable to refund your Registration Fee or any other payment.
You must comply at all times with the health and safety policy. You must comply with all requests from the Hotel or us about health and safety, and failure to do so will result in you being asked to leave the Workshop (in which case we will provide no refund). You may not bring any equipment or items of a hazardous nature to the Workshop.
You shall not cause any damage to any part of the Hotel (including outside areas and all inside walls, flooring, fixtures, and fittings). You are solely liable for any damage caused by you, your employees, contractors, sub-contractors, and agents to any such area of the Hotel and shall fully reimburse us for any damage caused.
You must keep your personal belongings with you at all times, and we accept no liability for any damage to, loss of, or theft of any of your belongings or other items brought to the Workshop by you.

<b>11. Promotional materials and materials at the Workshop</b>
You agree that we may include your details in any promotional materials relating to the Workshop and any materials used at the Workshop. We are not liable for errors or omissions contained in such information.
Unless otherwise stated, the copyright for any such promotional materials and any materials used at the Workshop (including course notes, slides, brochures, articles, and case studies) belongs to us and may not be reproduced in any medium without our prior written consent.
You may use such materials for your private purposes only and may not reproduce, publish or deal with such materials in any way for commercial use. We reserve the right to change the published program or materials as we think fit.

<b>12. Photography & Filming</b>
We may wish to photograph or film the Workshop and reserve the right to do so to promote future workshops or otherwise. You consent to us (or any other person attending the Workshop) photographing or filming you and any persons attending the Workshop with you. You may not photograph or video the Workshop without our consent, but you are welcome to take photographs and share them.

<b>13. Data Protection</b>
We will communicate with you using the contact details provided on your Registration. Under these Terms, including the collection of Registration Fees, mailing of course material, and notification of other events and products, and services that our affiliated companies and we may offer. We shall be entitled to pass on all your contact details to the Workshop contractors. If you do not wish to refrain from receiving information about other events or other products or services offered by our affiliated companies and us, please email us at <a href="mailto:laryne@justinguitar.com">laryne@justinguitar.com</a>.

<b>14. Tickets</b>
We shall not send you tickets for the Workshop. We will have your name on the attendee list, and if you have paid the Registration Fee, we shall provide you access to the Workshop.

<b>15. Disability, Medical Conditions & Dietary Requirements</b>
If you have any disability or medical condition that requires us to make special arrangements for you or any special dietary requirements, please email us at <a href="mailto:laryne@justinguitar.com">laryne@justinguitar.com</a> as soon as possible and in any event at least 2 weeks before the date of the Workshop.

<b>16. Limitations of Liability</b>
While we take every reasonable precaution to ensure security and safety at the Workshop, we shall not in any way be liable for any loss or damage suffered by you whatsoever about the Workshop, save that nothing in these Terms shall be deemed to limit the liability of any person for death or personal injury caused by negligence.
Nothing in these Terms shall limit or exclude our liability for death or personal injury caused by our negligence, fraudulent misrepresentation, or other liability. It is unlawful to exclude or limit liability.
Our total liability for any matter arising out of the Contract shall be limited to the Registration Fee paid by you for the Workshop, and we shall not in any circumstances be liable to you for any consequential loss whatsoever.

<b>17. Governing law</b>
English Law shall govern these Terms, and you hereby submit to the exclusive jurisdiction of the English Courts.`;
