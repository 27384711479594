import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { PromoItemEntity } from "../interfaces/PromoItem";
import { JgState } from "../../redux/jg-store";

export const promoItemsAdaptor = createEntityAdapter<PromoItemEntity>();

const promoItemEntitySelectors = promoItemsAdaptor.getSelectors<JgState>((state) => {
  return state?.entities?.promoItems || {};
});

export const promoItemSelectors = {
  ...promoItemEntitySelectors,
};

const { setAll, setMany } = promoItemsAdaptor;

export const promoItemsSlice = createSlice({
  name: "promoItems",
  initialState: promoItemsAdaptor.getInitialState(),
  reducers: {
    setAll,
    setMany,
  },
});

export type PromoItemsState = ReturnType<typeof promoItemsSlice.reducer>;
