import React from "react";
import { Provider } from "react-redux";
import "./store/lessonIndex";
import LessonContainer from "./LessonContainer";

export default class Lesson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener("click", (event) => this.toggleText(event));
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.toggleText);
  }

  toggleText = (e) => {
    // admin uses '.toggle' class in WYSIWYG content so allow sibling to be
    // displayed/hidden here.
    if (e.target.classList.contains("toggle")) {
      const next = e.target.nextElementSibling;
      if (next.classList.contains("hidden")) {
        next.classList.remove("hidden");
      } else {
        next.classList.add("hidden");
      }
    }
  };

  render() {
    const lessonStore = ReactOnRails.getStore("lessonStore");

    return (
      <Provider store={lessonStore}>
        <LessonContainer />
      </Provider>
    );
  }
}
