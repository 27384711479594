import PropTypes from "prop-types";
import React from "react";

export default class FilterLessonItems extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    filterBy: PropTypes.array.isRequired,
    onFiltersChanged: PropTypes.func.isRequired,
  };

  constructor(props, _railsContext) {
    super(props);

    this.state = {
      items: props.items,
      filterBy: props.filterBy,
      beltCount: props.beltCount,
      itemType: props.itemType,
      filterCount: props.filterCount,
      itemTotal: props.itemTotal,
    };
  }

  // componentWillReceiveProps(nextProps) {
  // 	this.setState({ filterCount: nextProps.filterCount });
  // }

  toggleFilter = (filter, item) => {
    let filterBy = this.state.filterBy;

    let matches = 0;
    let removeItems = false;

    if (item.title && item.grades) {
      for (let a = 0; a < item.grades.length; a++) {
        if (filter.filters.includes(item.grades[a])) {
          matches += 1;
        }
      }
      //if matches == item.grades.length, all of them are selected and need to be removed
      if (matches == item.grades.length) {
        removeItems = true;
      }

      if (removeItems) {
        for (let b = 0; b < item.grades.length; b++) {
          filter.filters.splice(filter.filters.indexOf(item.grades[b]), 1);
        }
      } else {
        for (let c = 0; c < item.grades.length; c++) {
          if (!filter.filters.includes(item.grades[c])) {
            filter.filters.push(item.grades[c]);
          }
        }
      }

      // item is a grade or category, not a level
    } else {
      let toRemove = false;

      if (filter.filters.includes(filter.value(item))) {
        var index = filter.filters.indexOf(filter.value(item));
        if (index >= 0) {
          filter.filters.splice(index, 1);
        }
        //filter.filters.splice(filter.filters.indexOf(filter.value(item), 1));
        toRemove = true;
      }

      if (!toRemove) {
        filter.filters.push(filter.value(item));
      }
    }

    filterBy = this.setFilterToFilterBy(filter, filterBy);
    // Apply filters to items
    this.applyFilters(filterBy);
  };

  // Find and set the filterByItem to filterBy and return the new one.
  setFilterToFilterBy = (filter, filterBy) => {
    for (let i = 0; i < filterBy.length; i++) {
      if (filterBy[i].name === filter.name) {
        filterBy[i] = filter;
      }
    }
    return filterBy;
  };

  // Filter items by filter. Return filtered set.
  filterItems = (items, filter) => {
    let filtered = [];

    // Filter items if filters are present, otherwise
    // just return the original set

    if (filter.filters && filter.filters.length) {
      items.map((item) => {
        if (filter.includeItem(item)) {
          filtered.push(item);
        }
      });
    } else {
      filtered = items;
    }

    return filtered;
  };

  // Apply filters to items.
  applyFilters = (filterBy) => {
    let items = this.state.items; // Initially

    filterBy.map((filter) => {
      items = this.filterItems(items, filter);
    });

    this.setState({ filterBy: filterBy });
    let currentFilterTotal = 0;
    let currentFilters = this.state.filterBy;
    currentFilters.forEach(function (filter) {
      currentFilterTotal += filter.filters.length;
    });

    if (currentFilterTotal == 0) {
      // if there are no filters, pass false so sort doesn't filter by difficulty
      //this.props.onFiltersChanged(items, false);
      this.props.onFiltersChanged(items, true);
    } else {
      this.props.onFiltersChanged(items, true);
    }
  };

  // Reset a given filter
  resetFilter = (filter) => {
    let filterBy = this.state.filterBy;

    filter.filters = [];

    // Update filterBy
    filterBy = this.setFilterToFilterBy(filter, filterBy);

    this.applyFilters(filterBy);
  };

  // Clear all filters
  clearAll = () => {
    let filterBy = this.state.filterBy;

    for (let i = 0; i < filterBy.length; i++) {
      filterBy[i].filters = []; // Reset
    }

    this.applyFilters(filterBy);

    // Clear the search field
    document.getElementById("item-search-field").value = "";
  };

  // Toggle filter list visibility
  toggleList = (filter) => {
    let filterBy = this.state.filterBy;

    // togglelistVisible
    filter.listVisible = !filter.listVisible;

    // Update filterBy
    filterBy = this.setFilterToFilterBy(filter, filterBy);

    this.setState({ filterBy: filterBy });
  };

  itemsCount = (filter) => {
    // if there are grades, we're dealing with a level
    if (filter.grades) {
      let total = this.state.filterCount["levels"][filter.id];
      return total ? total : 0;
    }
    // if there's a reference we're dealing with a Category
    if (filter.reference) {
      let total = this.state.filterCount["categories"][filter.id];
      return total ? total : 0;
    }
    if (filter.belt) {
      let total = this.state.filterCount["belts"][filter.id];
      return total ? total : 0;
    }
  };

  // When search field is manipulated
  searchChanged = (event) => {
    let searchStr = event.target.value.toLowerCase();
    let filterBy = this.state.filterBy;

    // Special case, only one search string at a time
    filterBy[0].filters = [searchStr];

    this.setState({ filterBy: filterBy });
    this.applyFilters(this.state.filterBy);
  };

  render() {
    return (
      <div className="filters">
        <div className="filters__header clearfix">
          <div className="filters__header-title page-subheading">
            Filter {this.state.itemTotal} {this.state.itemType}s
          </div>
          <div className="filters__header-clear" onClick={this.clearAll}>
            Clear all
          </div>
        </div>

        <div className="filters__group">
          <div className="form-group">
            <input
              className=""
              id="item-search-field"
              placeholder={"Search all " + this.state.itemType + "s"}
              onChange={this.searchChanged}
            />
            <button type="submit" className="dynamic-search__submit btn btn--search">
              <span className="glyphicon glyphicon-search"></span>
            </button>
          </div>
        </div>

        {/* First item in the filterBy -array is the special case: search field. Handled elsewhere. */}
        {this.state.filterBy.slice(1, this.state.filterBy.length).map((filter) => (
          <div className="filters__group" key={"filters-group-" + filter.name}>
            <div className="filters__title-row clearfix">
              <div
                className="filters__title page-subheading"
                onClick={() => this.toggleList(filter)}
              >
                <span
                  className={
                    "glyphicon " +
                    (filter.listVisible ? "glyphicon-triangle-top" : "glyphicon-triangle-bottom")
                  }
                ></span>
                <span>{filter.title}</span>
              </div>
              <div className="filters__reset-filter" onClick={() => this.resetFilter(filter)}>
                Clear
              </div>
            </div>
            <div
              className={"filters__list " + (filter.listVisible ? "" : "hidden")}
              id={"filters-list-" + filter.name}
            >
              {filter.filterItems.map((item) => (
                <div key={"filter-item-" + filter.value(item)}>
                  <div
                    className={
                      "filters__item " + (filter.allSelected(filter, item) ? "active" : "")
                    }
                    onClick={() => this.toggleFilter(filter, item)}
                  >
                    <span className="filters__item-check glyphicon glyphicon-ok"></span>
                    <span className="filters__item-title">{filter.value(item)}</span>
                    {this.state.itemType == "lesson" && (
                      <span className="filters__nestedItem-count">{this.itemsCount(item)}</span>
                    )}{" "}
                    {this.state.itemType == "module" && (
                      <span className="filters__nestedItem-count">{this.itemsCount(item)}</span>
                    )}
                  </div>
                  {filter.nestedItems &&
                    filter.nestedItems.map(
                      (nestedItem) =>
                        nestedItem.level_id == item.id && (
                          <div
                            className={
                              "filters__nested__item " +
                              (filter.filters.includes(filter.value(nestedItem)) ? "active" : "")
                            }
                            key={"filter-item-" + nestedItem.belt}
                            onClick={() => this.toggleFilter(filter, nestedItem)}
                          >
                            <span className="filters__nested__item-check glyphicon glyphicon-ok"></span>
                            <span className="filters__nested__item-title">{nestedItem.belt}</span>
                            {this.state.itemType == "lesson" && (
                              <span className="filters__nestedItem-count">
                                {this.itemsCount(nestedItem)}
                              </span>
                            )}{" "}
                            {this.state.itemType == "module" && (
                              <span className="filters__nestedItem-count">
                                {this.itemsCount(nestedItem)}
                              </span>
                            )}
                          </div>
                        )
                    )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
