import React from "react";
import { CDN_URL } from "../../components/shared/CdnUrl";

const VideoPlaceholder = ({
  currentUser,
  image,
  nextModule,
  videoRef,
  quiz,
  quizAttempt,
  toggleLoginModal,
  hasResources,
  viewResources,
}) => {
  function handleQuizClick() {
    if (!currentUser) {
      toggleLoginModal();
    } else {
      window.location.href = `/quizzes/${quiz.slug}${quizAttempt == null ? "" : "/results"}`;
    }
  }

  function handleResourcesClick() {
    if (!currentUser) {
      toggleLoginModal();
    } else {
      viewResources();
    }
  }

  return (
    <div
      ref={videoRef}
      className="flex column video-swoosh__video video-swoosh__fallback-img"
      style={{
        background: `url('${
          image ? image : `${CDN_URL}/redesign/lessons/no-video-placeholder.png`
        }')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        borderRadius: "10px",
      }}
    >
      <div className="placeholder-interactions">
        {!image && !hasResources && (
          <h2 className="uppercase bold">This lesson doesn't have a video</h2>
        )}

        {quiz && nextModule && (
          <button className="button button--white button--inline" onClick={handleQuizClick}>
            Take the Quiz
          </button>
        )}

        {hasResources && (
          <button
            onClick={handleResourcesClick}
            className="button button--primary"
            style={{ boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)" }}
          >
            <span className="download__title">Download</span>
            <i
              className="icon icon-download"
              style={{ marginLeft: "7px", marginBottom: "3px" }}
            ></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default VideoPlaceholder;
