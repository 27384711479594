import React from "react";
import ReactOnRails from "react-on-rails";
import { Provider } from "react-redux";
import "./store/strummingSosStore";
import StrummingSosRoot from "./StrummingSosRoot";

const StrummingSos = () => {
  const store = ReactOnRails.getStore("strummingSosStore");

  return (
    <Provider store={store}>
      <StrummingSosRoot />
    </Provider>
  );
};

export default StrummingSos;
