import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logSheetMusicView } from "../store/songEffects";
import { userHasSongsSubscription, orderedTabImages, artistName } from "../store/songSelectors";
import { setActiveImage } from "../store/songStore";
import SheetMusicContent from "./content/SheetMusicContent";
import { NOTES } from "./Formatter";
import Copyright from "./Copyright";
import SheetMusicHeader from "./menu/SheetMusicHeader";
import SheetMusicPaymentButton from "./SheetMusicPaymentButton";

class SheetMusicContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.logView();
    this.setActiveImageIfNoContent();
  }

  logView = () => {
    const {
      sheetMusic,
      currentUser,
      token,
      sheetMusicViewLogged,
      userHasSongsSubscription,
      standalonePage,
    } = this.props;

    if (
      sheetMusic &&
      currentUser &&
      !sheetMusicViewLogged &&
      userHasSongsSubscription &&
      !standalonePage
    ) {
      this.props.logSheetMusicView({
        song_sheet_view: {
          user_id: currentUser.data.attributes.id,
          song_sheet_id: sheetMusic.data.attributes.id,
        },
        authenticity_token: token,
      });
    }
  };

  setActiveImageIfNoContent = () => {
    const { sheetMusic } = this.props;
    if (sheetMusic) {
      const { orderedTabImages } = this.props;
      const content = sheetMusic.data.attributes.content;
      if ((!content || !content.length > 0) && orderedTabImages && orderedTabImages.length > 0) {
        this.props.setActiveImage(orderedTabImages[0]);
      }
    }
  };

  changeActiveContent = () => {
    if (this.state.activeImage || this.state.viewAllImages) {
      this.setState({ activeImage: null, viewAllImages: false });
    } else {
      this.setState({ activeImage: this.orderedImages()[0] });
    }
  };

  render() {
    const {
      userHasSongsSubscription,
      song,
      fontSize,
      sheetMusic,
      selectedChordColor,
      chordHighlighted,
      typeface,
      darkMode,
      artistName,
      fontWeight,
    } = this.props;
    if (userHasSongsSubscription && sheetMusic) {
      const { title } = song.data.attributes;
      return (
        <div
          className={
            `song-sheet font-size-${fontSize} chord-color-${selectedChordColor} chord-highlighted-${chordHighlighted} font-${typeface}` +
            (darkMode ? " dark-mode" : "") +
            (fontWeight === "bold" ? " bold" : "")
          }
        >
          <div className="styleized title">
            <h2>
              {title} by {artistName}
            </h2>
          </div>
          <SheetMusicHeader />
          <SheetMusicContent />
          <Copyright />
          <p style={{ textAlign: "center", margin: "10px 0" }}>Powered by Sheet Music Direct</p>
        </div>
      );
    } else if (userHasSongsSubscription) {
      return (
        <div>
          <h5>
            There isn't a TAB for this song yet, but we're working on adding TABs for every song on
            the site. Please check back soon!
          </h5>
        </div>
      );
    } else {
      return <SheetMusicPaymentButton reference={song.data.attributes.reference} />;
    }
  }
}

const mapStateToProps = (state) => ({
  song: state.entity.song,
  sheetMusic: state.entity.sheetMusic,
  fontChange: state.entity.fontChange,
  fontSize: state.entity.fontSize,
  selectedChordColor: state.entity.selectedChordColor,
  currentUser: state.session.currentUser,
  sheetMusicViewLogged: state.entity.sheetMusicViewLogged,
  chordHighlighted: state.entity.chordHighlighted,
  typeface: state.entity.typeface,
  userHasSongsSubscription: userHasSongsSubscription(state),
  orderedTabImages: orderedTabImages(state),
  artistName: artistName(state),
  darkMode: state.entity.darkMode,
  fontWeight: state.entity.fontWeight,
});

const mapDispatchToProps = (dispatch) => ({
  logSheetMusicView: (params) => dispatch(logSheetMusicView(params)),
  setActiveImage: (img) => dispatch(setActiveImage(img)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SheetMusicContainer);

SheetMusicContainer.propTypes = {
  user: PropTypes.object,
  sheetMusic: PropTypes.object,
  stripeKey: PropTypes.string,
  setSheetMusicViewLogged: PropTypes.func,
};
