import { createAsyncThunk } from "@reduxjs/toolkit";
import * as slices from "../slices";
import Fuse from "fuse.js";
import { filterOptionToQueryValue, filtersByType, filterTypes } from "../../filters";
import { sortByOptions } from "../shared";
import {
  selectArtists,
  selectChords,
  selectCurrentArtist,
  selectFilters,
  selectGenres,
  selectGrades,
  selectProducts,
  selectQuery,
  selectSongMetadata,
  selectSongsArray,
  selectSortBy,
  selectTags,
} from "../songSelectors";
import debounce from "lodash.debounce";
import "../../../shared/polyfills/replaceAll.js";

export const fetchSongs = createAsyncThunk("songs/querySongs", (_, thunkApi) => {
  thunkApi.dispatch(slices.loading.actions.setListingLoading(true));

  return sortAndFilter(thunkApi);
});

const sortAndFilter = debounce(({ getState, dispatch }) => {
  return new Promise((resolve) => {
    // take this work off the sync stack
    setTimeout(() => {
      const state = getState();
      const allSongs = selectSongsArray(state);
      const { page, perPage } = selectSongMetadata(state);

      const sortedFilteredSongs = applySort(
        applyQuery(applyFilters(applyArtistFilter(allSongs, state), state), state),
        state
      );

      const pagedSongs = sortedFilteredSongs.slice(0, page * perPage);
      const totalCount = sortedFilteredSongs.length;

      dispatch(slices.ui.actions.setListingSongs(pagedSongs));
      dispatch(slices.metadata.actions.setTotalCount({ entity: "songs", totalCount }));
      dispatch(slices.loading.actions.setListingLoading(false));

      resolve();
    }, 10);
  });
}, 300);

const applyQuery = (songs, state) => {
  const query = selectQuery(state);
  return query == null || query.length === 0
    ? songs
    : new Fuse(songs, {
        keys: [
          { name: "attributes.title", weight: 2 },
          { name: "attributes.originalArtist", weight: 2 },
          { name: "attributes.notes", weight: 0.5 },
        ],
        threshold: 0.4,
      })
        .search(query)
        .map(({ item }) => item);
};

const applyFilters = (songs, state) => {
  const activeFilters = Object.keys(selectFilters(state));

  if (activeFilters.length === 0) {
    return songs;
  }

  songs = applyInstrumentFilter(
    songs,
    activeFilters.filter((f) => filtersByType[filterTypes.Instrument].includes(f)),
    state
  );

  songs = applyGradeFilter(
    songs,
    activeFilters.filter((f) => filtersByType[filterTypes["Difficulty Level"]].includes(f)),
    state
  );

  songs = applyChordFilter(
    songs,
    activeFilters.filter((f) => filtersByType[filterTypes.Chords].includes(f)),
    state
  );

  songs = applyTagFilter(
    songs,
    activeFilters.filter((f) => filtersByType[filterTypes.Tags].includes(f)),
    state
  );

  songs = applyProductFilter(
    songs,
    activeFilters.filter((f) => filtersByType[filterTypes["Books & Apps"]].includes(f)),
    state
  );

  songs = appleGenreFilter(
    songs,
    activeFilters.filter((f) => filtersByType[filterTypes.Genre].includes(f)),
    state
  );

  songs = applyFeatureFilter(
    songs,
    activeFilters.filter((f) => filtersByType[filterTypes.Feature].includes(f)),
    state
  );

  songs = applyTimeSignatureFilter(
    songs,
    activeFilters.filter((f) => filtersByType[filterTypes["Time Signature"]].includes(f)),
    state
  );

  return songs;
};

const applyArtistFilter = (songs, state) => {
  const currentArtist = selectCurrentArtist(state);

  if (currentArtist == null) {
    return songs;
  }

  const artistsDictionary = selectArtists(state);

  return songs.filter((song) => {
    const songArtist = artistsDictionary[song.relationships.artist.data.id];

    return songArtist.attributes.name === currentArtist.attributes.name;
  });
};

const applyInstrumentFilter = (songs, instruments) => {
  if (instruments.length === 0) {
    return songs;
  }

  const instrumentsSet = new Set(instruments.map((f) => filterOptionToQueryValue[f]));

  return songs.filter(({ attributes: { playedOn } }) => instrumentsSet.has(playedOn));
};

const applyGradeFilter = (songs, grades, state) => {
  if (grades.length === 0) {
    return songs;
  }

  const gradesSet = new Set(grades.map((f) => filterOptionToQueryValue[f]));

  const stateGrades = selectGrades(state);

  return songs.filter((song) => {
    const gradeRelation = song.relationships.grade;

    if (gradeRelation == null || gradeRelation.data == null) {
      return false;
    }

    const { belt } = stateGrades[gradeRelation.data.id].attributes;

    if (belt == null) {
      return false;
    }

    return gradesSet.has(belt.toLowerCase());
  });
};

const applyChordFilter = (songs, chords, state) => {
  if (chords.length === 0) {
    return songs;
  }

  const chordsSet = new Set(chords.map((f) => filterOptionToQueryValue[f]));
  const allChords = selectChords(state);

  return songs.filter((song) => {
    const chordsRelations = song.relationships.chords;

    if (
      chordsRelations == null ||
      chordsRelations.data == null ||
      chordsRelations.data.length === 0
    ) {
      return false;
    }

    const songChords = new Set(
      chordsRelations.data.map(({ id }) => allChords[id].attributes.title)
    );

    // we want songChords to be a subset of the set of chords selected in the filter
    // eg. I select A, D and E in the filter so i'd want to also see songs with just A and D chords
    return (
      chordsSet.size >= songChords.size && Array.from(songChords).every((c) => chordsSet.has(c))
    );
  });
};

const applyTagFilter = (songs, tags, state) => {
  if (tags.length === 0) {
    return songs;
  }

  const tagsSet = new Set(tags.map((f) => filterOptionToQueryValue[f]));
  const allTags = selectTags(state);

  return songs.filter((song) => {
    const tagsRelations = song.relationships.tags;

    if (tagsRelations == null || tagsRelations.data == null || tagsRelations.length === 0) {
      return false;
    }

    const songTags = new Set(tagsRelations.data.map(({ id }) => allTags[id].attributes.title));

    return Array.from(songTags).some((c) => tagsSet.has(c));
  });
};

const applyProductFilter = (songs, products, state) => {
  if (products.length === 0) {
    return songs;
  }

  const productsSet = new Set(products.map((f) => filterOptionToQueryValue[f]));
  const allProducts = selectProducts(state);

  return songs.filter((song) => {
    const productsRelations = song.relationships.products;

    if (
      productsRelations == null ||
      productsRelations.data == null ||
      productsRelations.length === 0
    ) {
      return false;
    }

    const songProducts = new Set(
      productsRelations.data.map(({ id }) => allProducts[id].attributes.title)
    );

    return Array.from(songProducts).some((c) => productsSet.has(c));
  });
};

const appleGenreFilter = (songs, genres, state) => {
  if (genres.length === 0) {
    return songs;
  }

  const genresSet = new Set(genres.map((f) => filterOptionToQueryValue[f]));

  const stateGenres = selectGenres(state);

  return songs.filter((song) => {
    const genreRelation = song.relationships.genre;

    if (genreRelation == null || genreRelation.data == null) {
      return false;
    }

    const { title } = stateGenres[genreRelation.data.id].attributes;

    return genresSet.has(title.toLowerCase());
  });
};

const applyFeatureFilter = (songs, features) => {
  if (features.length === 0) {
    return songs;
  }

  const featuresSet = new Set(features);

  return songs.filter((s) =>
    featuresSet.has("JustinGuitarCHORDS") && s.attributes.hasChords
      ? true
      : !!(featuresSet.has("JustinGuitarTABS") && s.attributes.hasTabs)
  );
};

const applyTimeSignatureFilter = (songs, timeSignatures) => {
  if (timeSignatures.length === 0) {
    return songs;
  }

  const timeSignaturesSet = new Set(timeSignatures);

  return songs.filter((s) => timeSignaturesSet.has("6/8") && s.attributes.timeSignature == "6/8");
};

const applySort = (songs, state) => {
  const sort = selectSortBy(state);
  switch (sort) {
    case sortByOptions.relevance:
      return songs; // already sorted by Fuse
    case sortByOptions.titleDesc:
      return songs.sort((a, b) => b.attributes.title.localeCompare(a.attributes.title));
    case sortByOptions.titleAsc:
      return songs.sort((a, b) => a.attributes.title.localeCompare(b.attributes.title));
    case sortByOptions.dateAsc:
      return songs.sort(
        (a, b) => new Date(a.attributes.pageReleaseDate) - new Date(b.attributes.pageReleaseDate)
      );
    case sortByOptions.dateDesc:
      return songs.sort(
        (a, b) => new Date(b.attributes.pageReleaseDate) - new Date(a.attributes.pageReleaseDate)
      );
    case sortByOptions.difficulty:
      return songs.sort((a, b) => a.attributes.gradeId - b.attributes.gradeId);
    case sortByOptions.popularity:
    default:
      return songs.sort((a, b) => b.attributes.youtubeViews - a.attributes.youtubeViews);
  }
};
