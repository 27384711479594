import React from "react";
import { useSelector } from "react-redux";
import { CDN_URL } from "../components/shared/CdnUrl";
import { parentGroupBadge } from "../components/shared/BreadcrumbTrail";

import {
  groupSelector,
  firstLessonInGroupSelector,
  lessonsWithinGroupSelector,
  totalVideoDurationSelector,
  userSelector,
  paywallSelector,
} from "./store/groupSelectors";

import VideoSwoosh from "../components/swoosh-templates/VideoSwoosh";
import SinglePaymentOption from "./paywalled/SinglePaymentOption";

const GroupBreadcrumb = () => {
  const group = useSelector(groupSelector);
  const pg = group.included.filter((i) => i.type == "parentGroup")[0];
  return (
    <div className="breadcrumb swoosh-breadcrumb breadcrumb--group">
      <a className="badge" href="/guitar-lessons">
        All Courses
      </a>
      {pg && parentGroupBadge(pg.attributes.reference, pg.attributes.title, pg.attributes.slug)}
    </div>
  );
};
const GroupHeader = () => {
  const title = useSelector(groupSelector).data.attributes.title;
  return (
    <>
      <GroupBreadcrumb />
      <h1 className="white-header">{title}</h1>
    </>
  );
};

const GroupSwooshContent = () => {
  const lessons = useSelector(lessonsWithinGroupSelector);
  const firstLesson = useSelector(firstLessonInGroupSelector) || lessons[0];
  const currentGroup = useSelector(groupSelector);
  const totalVideoDuration = useSelector(totalVideoDurationSelector);
  const currentUser = useSelector(userSelector);
  const paywall = useSelector(paywallSelector);
  const { metaDesc, needsPayment } = currentGroup.data.attributes;
  return (
    <div className="group__start">
      {needsPayment ? (
        <div className="group__pw">
          <SinglePaymentOption />
        </div>
      ) : (
        <>
          <div>
            {metaDesc && <p className="group__overview"> {metaDesc}</p>}

            {lessons.length != 0 && (
              <div className="group__stats">
                <p>
                  <i className="icon icon-play" /> {lessons.length} Lessons{" "}
                </p>
                {totalVideoDuration != "0 mins" && (
                  <p>
                    <i className="icon icon-clock2" /> {totalVideoDuration}
                  </p>
                )}
              </div>
            )}
          </div>

          <div>
            <a
              className="button button--white button--inline"
              href={`/guitar-lessons/${firstLesson.attributes.slug}`}
            >
              Start this Module
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default function () {
  const group = useSelector(groupSelector);
  const { introductionVideo, image, headerImage, title } = group.data.attributes;
  const hasHeaderImage = headerImage && headerImage.length > 0;
  return (
    <VideoSwoosh
      videoUrl={introductionVideo}
      fallbackImg={{
        img: hasHeaderImage
          ? headerImage
          : `${CDN_URL}/images/thumbnails/video-coming-soon-placeholder.png`,
        alt: title,
      }}
      hasHeaderImage={hasHeaderImage}
      hasMaxResImage={true}
      Header={GroupHeader}
      Content={GroupSwooshContent}
    />
  );
}
