export const MONTH_DATA = {
  0: {
    title: "Unit 0: Preparation Lessons - Start Here!",
    subtitle: "Access: Immediately after purchase",
    highlights: [],
    description:
      "Learn to self-assess your technique, start a lick book, and practice muted shuffle exercises. We'll also cover how to record your guitar and encourage you to identify Blues songs you already know. You'll receive recommendations, and we'll set expectations.",
  },
  1: {
    title: "Unit 1: The Birth of the Blues",
    subtitle: "Access: July 1, 2024",
    highlights: [],
    decade: "Before the 1950s",
    description:
      "Make sure you're never boxed into a Pentatonic again. We'll focus on Pattern 1 as a Framework and learn which notes to bend, how to add additional notes, and learn a bunch of essential licks to jam with. Learn a series of cool Bass 6 riffs and my revolutionary new approach to help you work out solos by ear. Our study elements include The Delta Blues and early blues artists (pre-1950).",
  },
  2: {
    title: "Unit 2: The Chicago Masters",
    subtitle: "Access: August 1, 2024",
    highlights: [],
    decade: "1950s",
    description:
      "We'll apply our methodology to the Pattern 2 framework (bend candidates and additional notes) with more licks, which you'll also transcribe! We'll learn to recognize the common 12 Bar Blues forms by ear and learn the different approaches to using a muted shuffle - in any key! We'll also explore vibrato and how to utilize your pet licks effectively! We'll be learning about Chicago Blues and the masters of the 1950's!",
  },
  3: {
    title: "Unit 3: The Electric Kings",
    subtitle: "Access: September 1, 2024",
    highlights: [],
    decade: "1960s",
    description:
      "This unit focuses on the Pattern 3 Framework and associated licks and uses. Notice a pattern here? :) We'll also learn five essential turnarounds, our first melodic sequence, and the techniques of sliding and rolling. We'll also explore Target Tones and Dominant 7th chord use in Blues. The historical exploration is on Memphis Blues and great artists of the 1960s—with a focus on the three kings!",
  },
  4: {
    title: "Unit 4: The British Blues Influences",
    subtitle: "Access: October 1, 2024",
    highlights: [],
    decade: "1970s",
    description:
      "In this unit, we'll dig deeper into the Note Function and move between pattern frameworks. We'll be up in Pattern 4 learning licks, bends, and additional notes, too. We'll explore 9th Chords and more transcribing techniques, meet the Blues masters from the 1970s, and explore the influence of British Blues and rock n roll on Blues music.",
  },
  5: {
    title: "Unit 5: The Big Guns of Texas",
    subtitle: "Access: November 1, 2024",
    highlights: [],
    decade: "1980s",
    description:
      "We're learning our final framework now, Pattern 5! Of course, we'll be learning more licks and additional notes—but also exploring a more horizontal approach! We'll also get a taste of slide guitar, learn some rhythm fills, and play Minor Blues. We'll dig into the history of Texas Blues and the guitar legends of the 80's!",
  },
  6: {
    title: "Unit 6: The Blues Today",
    subtitle: "Access: December 1, 2024",
    highlights: [],
    decade: "Modern Blues",
    description:
      "Time to tie it all together and give you some tasters of things to come! We'll explore using arpeggios instead of scales, learn Chord Specific Licks, and touch on The Major Pentatonic. We'll learn Gospel Slides and a little about Voice Leading. We'll also look at contemporary Blues masters and how  Blues is evolving.",
  },
};

export const HIGHLIGHTS = [
  "Structured and practical lessons with resources like GuitarPro files",
  "Community support at all stages, with a WhatsApp group for announcements",
  "Exclusive printable and digital workbooks so you can learn on the go",
  "Complimentary 6-month access to our Practical Music Theory Course",
];

export const SECTION_TOP_MARGIN = 12;

export const CANCEL_URL = `${window.location.origin}/store/blues-immersion`;

export const HOW_IT_WORKS_SECTIONS = [
  {
    title: "Pre-Course",
    description:
      "Immediately you:<br/> Get access to the preparation lessons area and join the Community",
    size: 3,
  },
  {
    title: "6-Month Blues Immersion Course",
    description:
      "Monthly:<br/> Unlock a new unit with exclusive lessons and practice routine<br/> Receive fun assignments and join live Q&A sessions (optional)",
    size: 6,
  },
  {
    title: "Post-Course",
    description: "Get lifetime access to the course and revisit Q&A recordings whenever you want",
    size: 3,
  },
];

export const LESSONS_HIGHLIGHTS = [
  "<strong>Knowledge:</strong> Understand how Blues works in theory and practice.",
  "<strong>Licks:</strong> Learn the vocabulary you need to improvise all over the neck.",
  "<strong>Technique:</strong> Develop the mechanics to make your Blues feel natural, easy, and fluid.",
  "<strong>Rhythm:</strong> Get solid with your rhythm guitar; we don't take solos all the time! :)",
  "<strong>Jam:</strong> Improvise with confidence and actually ‘say something’.",
  "<strong>Transcribe:</strong> Use my revolutionary approach to learn solos by ear!",
  "<strong>History:</strong> Studying how the blues developed is fun and will make you feel authentic.",
  "<strong>Artist Study:</strong> Learn about the key players in each style and their influences.",
  "<strong>Standards:</strong> We'll dig into essential standard songs you should know to jam!",
  "<strong>Immerse:</strong> Listen lots to playlists for each Unit - IMMERSE yourself in The Blues!",
];
