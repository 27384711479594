import React from "react";

const OpenIcon = ({ _this, isOpen }) => {
  return (
    <svg
      onClick={() => _this.setState({ isOpen: !isOpen })}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 11.8V26.2M11.8 19H26.2M37 19C37 28.9411 28.9411 37 19 37C9.05887 37 1 28.9411 1 19C1 9.05887 9.05887 1 19 1C28.9411 1 37 9.05887 37 19Z"
        stroke="#406e8e"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CloseIcon = ({ _this, isOpen }) => {
  return (
    <svg
      onClick={() => _this.setState({ isOpen: !isOpen })}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8 19H26.2M37 19C37 28.9411 28.9411 37 19 37C9.05887 37 1 28.9411 1 19C1 9.05887 9.05887 1 19 1C28.9411 1 37 9.05887 37 19Z"
        stroke="#406e8e"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ToggleSection = ({ _this, isOpen, title }) => {
  const headerColor = isOpen ? "#44546C" : "#406e8e";
  return (
    <div
      className={"section-toggle__header flex " + (isOpen ? "section-toggle__header--active" : "")}
    >
      <span
        className="clickable"
        style={{ color: headerColor }}
        onClick={() => _this.setState({ isOpen: !isOpen })}
      >
        {title}
      </span>
      <div className="clickable">
        {isOpen ? (
          <CloseIcon _this={_this} isOpen={isOpen} />
        ) : (
          <OpenIcon _this={_this} isOpen={isOpen} />
        )}
      </div>
    </div>
  );
};

export default ToggleSection;
