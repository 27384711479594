import React, { useState } from "react";

const AdminIntroduction = ({ intro }) => {
  const [isOpen, setIsOpen] = useState(false);
  if (!intro || intro.length === 0) {
    return;
  } else if (isOpen) {
    return (
      <div>
        <div
          className="styleized admin__introduction"
          dangerouslySetInnerHTML={{ __html: intro }}
        ></div>
        <button
          className="close-intro button button--inline button--blue"
          onClick={() => setIsOpen(false)}
        >
          close
        </button>
      </div>
    );
  } else {
    return (
      <div className="admin__introduction">
        <i className="icon icon-info clickable" onClick={() => setIsOpen(true)}></i>
      </div>
    );
  }
};

export default AdminIntroduction;
