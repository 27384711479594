import React from "react";
import * as Yup from "yup";

const RegistrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "This name is too short!")
    .max(25, "This name is too long!")
    .required("First name is required."),
  lastName: Yup.string()
    .min(2, "This name is too short!")
    .max(25, "This name is too long!")
    .required("Last name is required."),
  email: Yup.string()
    .email("Please provide a valid email.")
    .required("Email address is required.")
    .test(
      "email",
      "Please provide a valid email.",
      (value) => value == null || !value.includes("+")
    ),
  username: Yup.string()
    .required("Username is required.")
    .matches(/^[aA-zZ0-9]+$/, "Please use letters and numbers only."),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters.")
    .required("Password is required."),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match!")
    .required("Password confirmation is required."),
  termsConsent: Yup.boolean().oneOf([true], "You must accept the terms and conditions."),
});

export default RegistrationSchema;
