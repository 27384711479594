import React, { useEffect } from "react";
import { connect } from "react-redux";
import Dialog from "../../../components/shared/ui/Dialog";
import { openDialog } from "../../../components/shared/ui/uiSlice";
import { cancelSubscription } from "../../../components/shared/session/sessionEffects";
import { trialEnd, formatDate } from "./purchaseUtils";
import Modal from "react-modal";
import SubscriptionRenewal from "./SubscriptionRenewal";
import SubscriptionOptions from "./SubscriptionOptions";
import { PurchaseTilesByReference } from "./PurchaseTilesByReference";
import { CDN_URL } from "../../../components/shared/CdnUrl";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100%",
    width: "90%",
    height: "90%",
    backgroundColor: "transparent",
    border: "none",
    // maxWidth: "600px",
    maxHeight: "600px",
    borderRadius: "20px",
  },
};

function goToPurchaseItem(link) {
  window.location.href = link;
}

const Purchase = ({ purchase, user, openDialog, cancelSubscription, isLoading }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      Modal.setAppElement("body");
    }
  }, []);
  const {
    associatedContent,
    createdAt,
    id,
    isActive,
    isCancelled,
    isSubscription,
    subscriptionPeriodEnd,
    inTrialPeriod,
    purchaseReference,
    actualPurchaseReference,
    price,
    currency,
  } = purchase.data.attributes;

  if (!associatedContent) return null;

  return (
    <div className="clickable">
      <div className="purchase-row">
        <div className="purchase__img-container">
          <img
            className={`purchase__img${associatedContent.isDownload ? " ebook" : ""}`}
            src={
              associatedContent.type == "workshops_deposit"
                ? `${CDN_URL}/images/products/workshops/workshop-deposit-2.jpg`
                : PurchaseTilesByReference[purchaseReference]
            }
            style={{ color: "white" }}
            onClick={() => goToPurchaseItem(associatedContent.link)}
          ></img>
        </div>
        <div className="purchase-card">
          <div
            className="purchase-card__left"
            onClick={() => goToPurchaseItem(associatedContent.link)}
          >
            <span className="bold purchase__title">{associatedContent.title}</span>
            {isSubscription ? (
              <SubscriptionRenewal
                createdAt={createdAt}
                isActive={isActive}
                isCancelled={isCancelled}
                inTrialPeriod={inTrialPeriod}
                subscriptionPeriodEnd={subscriptionPeriodEnd}
                price={price}
                currency={currency}
              />
            ) : (
              <span className="purchase__due">
                {associatedContent.type == "workshops_deposit"
                  ? "Deposit payment"
                  : associatedContent.isDownload
                  ? "This product is a digital download"
                  : "Lifetime access"}
              </span>
            )}
          </div>
          <div className="purchase-card__right">
            {isSubscription && subscriptionPeriodEnd && (
              <>
                <Dialog onConfirm={(data) => cancelSubscription(data)} />
                <SubscriptionOptions
                  isActive={isActive}
                  endDate={formatDate(subscriptionPeriodEnd)}
                  purchaseId={id}
                  purchaseReference={purchaseReference}
                  actualPurchaseReference={actualPurchaseReference}
                  isCancelled={isCancelled}
                  openDialog={openDialog}
                  typeOf="Purchase"
                />
              </>
            )}
            {associatedContent.isDownload &&
              associatedContent.link &&
              (Array.isArray(associatedContent.link) ? (
                associatedContent.link.map((link, idx) => (
                  <div key={idx} className="purchase__actions">
                    <p onClick={() => window.open(link.link, "_blank")} className="teal clickable">
                      Download {link.variant} e-Book
                    </p>
                  </div>
                ))
              ) : (
                <div className="purchase__actions">
                  <p
                    onClick={() => window.open(associatedContent.link, "_blank")}
                    className="teal clickable"
                  >
                    Download e-Book
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  user: state.session.currentUser,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  openDialog: (text) => dispatch(openDialog(text)),
  cancelSubscription: (id) => dispatch(cancelSubscription(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Purchase);
