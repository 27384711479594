import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axiosWithCSRF from "../shared/axiosWithCSRF";
import LoginPrompt from "../auth/LoginPrompt";
import { openAlert } from "../shared/ui/uiSlice";
import Snackbar from "../shared/ui/Snackbar";
import { Box } from "@mui/material";
import { PromoItem } from "../../justins-corner/PromoItem";
import { withBootstrapSize } from "../shared/WithBootstrapSize";
import Loading from "../shared/Loading";

const UserNoteNew = ({ currentUser, noteableId, noteableType, openAlert, bootstrapSize }) => {
  const [noteContent, setNoteContent] = useState("");
  const [note, setNote] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchNote();
  }, []);

  function fetchNote() {
    if (currentUser) {
      setLoading(true);

      axiosWithCSRF()
        .get(`/user_notes/by_itemable?noteable_type=${noteableType}&noteable_id=${noteableId}`)
        .then((response) => {
          const { note } = response.data;
          setNote(note.data);
          setNoteContent(note.data?.attributes?.content);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function handleUpdate() {
    return axiosWithCSRF().patch(`/user_notes/${note.id}`, {
      user_note: { content: noteContent, user_id: currentUser.data.attributes.id },
    });
  }

  function handleCreate() {
    return axiosWithCSRF().post(`/user_notes`, {
      user_note: {
        user_id: currentUser.data.attributes.id,
        noteable_id: noteableId,
        noteable_type: noteableType,
        content: noteContent,
      },
    });
  }

  function onFormSubmit(e) {
    e.preventDefault();
    const method = note?.id ? handleUpdate : handleCreate;

    method()
      .then((response) => {
        const note = response.data.serializedNote;
        setNote(note.data);
        setNoteContent(note.data.attributes.content);
        openAlert({ message: "Saved this note!", severity: "success" });
      })
      .catch(() => {
        openAlert({
          message: "There was a problem saving this note.",
          severity: "error",
        });
      });
  }

  function handleDelete() {
    if (confirm("Are you sure you want to delete this note?")) {
      axiosWithCSRF()
        .delete(`/user_notes/${note.id}`)
        .then(() => {
          setNote(null);
          setNoteContent("");
          openAlert({ message: "Deleted this note!", severity: "success" });
        })
        .catch(() => {
          openAlert({
            message: "There was a problem deleting this note.",
            severity: "error",
          });
        });
    }
  }

  function handleCancel() {
    if (note?.id) {
      setNoteContent(note.attributes.content);
    } else {
      setNoteContent("");
    }
  }

  const isDisabled = (btnType) => {
    if (noteContent?.trim() === note?.attributes?.content?.trim()) {
      return true;
    } else if (btnType === "submit" && noteContent?.trim()?.length === 0) {
      return true;
    } else if (btnType === "cancel" && !note?.id) {
      return true;
    } else {
      return false;
    }
  };

  const shouldBeLocatedBelowContent = ["xsDevice", "smDevice", "mdDevice"].includes(bootstrapSize);
  const shouldBeHorizontal = ["smDevice", "mdDevice"].includes(bootstrapSize);

  return (
    <Box
      display="flex"
      flexDirection={shouldBeLocatedBelowContent ? "column" : "row"}
      justifyContent="space-between"
    >
      <div style={{ width: "100%", maxWidth: "750px" }}>
        {!currentUser ? (
          <LoginPrompt loginAction="save notes" token={ReactOnRails.authenticityToken()} />
        ) : (
          <div className="user-note styleized">
            {loading && <Loading isContent={true} />}
            <form onSubmit={onFormSubmit}>
              <textarea
                type="text"
                value={noteContent}
                placeholder="Begin typing your note here..."
                onChange={(e) => setNoteContent(e.target.value)}
              />
              <div className="flex user-note__btns">
                <div className="flex">
                  <button
                    type="button"
                    onClick={() => handleCancel("")}
                    className="button button--white"
                    disabled={isDisabled("cancel")}
                  >
                    Cancel
                  </button>
                  <input
                    type="submit"
                    className="button button--blue"
                    value="Submit"
                    disabled={isDisabled("submit")}
                  ></input>
                </div>

                {note?.id && (
                  <button type="button" onClick={handleDelete} className="button button--danger">
                    Delete
                  </button>
                )}
              </div>
            </form>
            <Snackbar />
          </div>
        )}
      </div>
      <div
        style={{
          marginTop: shouldBeLocatedBelowContent ? "20px" : 0,
          marginLeft: shouldBeLocatedBelowContent ? 0 : "20px",
        }}
      >
        <PromoItem orientation={shouldBeHorizontal ? "landscape" : "portrait"} />
      </div>
    </Box>
  );
};

UserNoteNew.propTypes = {
  noteableId: PropTypes.number.isRequired,
  noteableType: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
  openAlert: PropTypes.func.isRequired,
  bootstrapSize: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  openAlert: (alert) => dispatch(openAlert(alert)),
});

export default connect(null, mapDispatchToProps)(withBootstrapSize(UserNoteNew));
