import React, { useEffect, useState } from "react";
import { CDN_URL, DRAGONFLY_PATH } from "../../CdnUrl";
import LoginPrompt from "../../../auth/LoginPrompt";
import axiosWithCSRF from "../../axiosWithCSRF";
import PropTypes from "prop-types";
import Loading from "../../Loading";
import {
  TRACKED_ACTION_ACTION_TYPES,
  TRACKED_ACTION_RECORD_TYPES,
  createTrackedAction,
} from "../../../../utilities/trackedActions";

function openFile(downloadId, fileUid) {
  createTrackedAction(
    TRACKED_ACTION_RECORD_TYPES.download,
    downloadId,
    TRACKED_ACTION_ACTION_TYPES.download
  );
  window.open(`${CDN_URL}/${DRAGONFLY_PATH}/${fileUid}`, "_blank");
}

const DownloadsNew = ({ currentUser, lessonId }) => {
  const [downloads, setDownloads] = useState([]);
  const [loading, setLoading] = useState(false);

  function fetchDownloads() {
    setLoading(true);
    axiosWithCSRF()
      .get(`/lessons/downloads?lesson_id=${lessonId}`)
      .then((response) => {
        setDownloads(response.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!currentUser) return null;
    fetchDownloads();
  }, []);

  if (!currentUser) {
    return (
      <LoginPrompt token={ReactOnRails.authenticityToken()} loginAction="download resources" />
    );
  } else {
    return (
      <div className="downloads">
        {loading && <Loading isContent={true} />}
        {downloads.map((download, idx) => {
          return (
            <div
              key={idx}
              className="download"
              onClick={() => openFile(download.id, download.attributes.fileUid)}
            >
              <i className="icon icon-document"></i>
              <span className="download__title">{download.attributes.title}</span>
              <i className="icon icon-download"></i>
            </div>
          );
        })}
      </div>
    );
  }
};

DownloadsNew.propTypes = {
  currentUser: PropTypes.object,
  lessonId: PropTypes.number.isRequired,
};

export default DownloadsNew;
