import { CDN_URL } from "../../components/shared/CdnUrl";

export const ARTISTS = [
    {
      name: "Jim Campilongo",
      slug: "jim-campilongo",
      description: "Guitarist / Producer / Composer / Instructor",
      contacts: ["campy8@yahoo.com", "jimcampilongo.com"],
      nameLogo: `${CDN_URL}/images/pages/teachers/jim-name-logo.png`,
      largeImage: `${CDN_URL}/images/pages/teachers/Jim.png`,
      activeTab: "about",
      showDetails: false,
      content: {
        about: `"Jim Campilongo reveals a range that extends from seductive country-swing to atmospheric jazz and well beyond." -TIME OUT NY.<br><br>Jim Campilongo is a world-renowned American guitarist, producer, composer, and guitar instructor. He's released fourteen critically acclaimed instrumental LP’s and his extensive discography is a diverse musical who’s who including Cake, Burning Spear, Patrick Stewart, Emmylou Harris and Martha Wainwright to name a few. He’s also a member of The Little Willies with Norah Jones.<br><br>Campilongo is a published guitar teacher and is a contributing editor for Guitar Player Magazine. Jim’s monthly column “Vinyl Treasures” has been a reader's favorite for over 6 years!
        `,
        qualifications:
          '<span class="badge">diploma</span>Jim Campilongo studied guitar in San Francisco and started his musical career in the 90s.<span class="badge">experience</span>His innovative approach to blending guitar techniques places him as one the most influential Telecaster players of the new millennium. It has garnered him significant endorsements including Fender releasing a Jim Campilongo signature model Telecaster. Jim has musical expertise that has influenced a generation of guitar playing. He already offers Lesson by Mail, a bestseller at jimcampilongo.com.',
        specialty: `<span class="badge">levels</span>Jim's technical studies are mainly designed for intermediate and advanced players. But, anyone with a desire to explore and learn more about his creative approach to guitar playing.<span class="badge">genres</span>Jim Campilongo specializes in Blues, Rock, Country, Jazz, R&B, and beyond!`,
        lessonDetails:
          '<span class="badge">availability</span><strong>Thursday</strong> - Morning (EST)<br><strong>Saturday</strong>  - Morning (EST)<br>Lessons are <strong>online</strong> only and given in <strong>English</strong>.<span class="badge">price</span><strong>$200</strong>/session',
      },
    },
    {
      name: "Mike Dawes",
      slug: "mike-dawes",
      description: "Guitarist / Composer Instructor",
      contacts: [
        "mikedawes.co.uk",
        { name: "Contact Form", url: "https://mikedawes.co.uk/contact-page" },
      ],
      nameLogo: `${CDN_URL}/images/pages/teachers/mike-name-logo.png`,
      largeImage: `${CDN_URL}/images/pages/teachers/Mike.png`,
      activeTab: "about",
      showDetails: false,
      content: {
        about: `Mike Dawes is an English guitarist known for seamlessly combining the melody, bass, harmony, and percussion with just one guitar. He's been hailed as one of the world's most creative modern fingerstyle guitarists.<br><br>Mike has a strong reputation as an educator - he's a columnist for Guitar magazines, publishes degree-level literature, teaches multiple guitar workshops all over the world…the list goes on! If you're looking to unlock your fingerstyle potential, you'll want to check him out!`,
        qualifications:
          '<span class="badge">diploma</span>“AN INNATE MELODIC INSTINCT AND INCREDIBLE GUITAR PROWESS.” - Guitar World / Acoustic Nation<br>“THE ACOUSTIC TAPPING TITAN” - Total Guitar Magazine/ MusicRadar<span class="badge">experience</span>For two years in a row, Total Guitar Magazine/MusicRadar reader polls names Mike Dawes "Best Acoustic Guitarist in the World". He has performances with Justin Hayward of The Moody Blues and, his own solo album ERA spawned viral hits amassing millions of views on various online platforms.',
        specialty: '<span class="badge">levels</span>Fingerstyle for all levels!',
        lessonDetails:
          '<span class="badge">availability</span>Mike is frequently on tour, please contact him directly for his availabilities.<span class="badge">price</span>Contact him by email for more information about this.',
      },
    },
  ];
  