import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { CDN_URL } from "../../components/shared/CdnUrl";
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";

const swooshImages = {
  [BootstrapSize.smDevice]: `${CDN_URL}/images/pages/ebooks/ebook-swoosh-lg.jpg`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/ebooks/ebook-swoosh-sm.jpg`,
};

export const EbookSwoosh = () => {
  return (
    <Swoosh imgUrl={swooshImages} id="ebook-swoosh">
      <SwooshContainer style={{ height: "400px" }}>
        <Title>
          <Typography variant="hero-heading-responsive">E-books & Resources</Typography>
        </Title>
      </SwooshContainer>
    </Swoosh>
  );
};

const SwooshContainer = styled("div")((props) => ({
  width: "100%",
}));

const Title = styled("div")((props) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingLeft: "130px",
  [props.theme.breakpoints.down("xl")]: {
    paddingLeft: "20px",
  },
}));
