import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosWithCSRF from "../../../shared/axiosWithCSRF";

import * as ui from "../slices/ui";
import * as session from "../slices/session";
import * as loading from "../slices/loading";

export const createSongRequest = createAsyncThunk(
  "songs/createRequest",
  (songRequest, { dispatch, getState }) => {
    const {
      session: { currentUser },
    } = getState();

    if (currentUser == null) {
      dispatch(
        ui.actions.openAlert({
          message: "Please log in before creating a song request",
          severity: "warning",
        })
      );
      return Promise.reject();
    }

    dispatch(loading.actions.setSongRequestFormLoading(true));

    return axiosWithCSRF()
      .post(`/song_requests`, {
        song_request: songRequest,
      })
      .then(
        (res) => {
          dispatch(session.actions.addCurrentUserSongRequest(res.data.data));
          dispatch(
            ui.actions.openAlert({
              message: "Song request successfully created!",
              severity: "success",
            })
          );
          dispatch(ui.actions.setSongRequestConflicts(ui.initialState.songRequestConflicts));
        },
        (err) => {
          if (err.response.status === 409) {
            dispatch(
              ui.actions.setSongRequestConflicts({
                conflicts: err.response.data,
                originalRequest: songRequest,
              })
            );
          } else {
            return err;
          }
        }
      )
      .catch(() => {
        dispatch(
          ui.actions.openAlert({
            message: "An error occurred, please try again later",
            severity: "error",
          })
        );
      })
      .finally(() => dispatch(loading.actions.setSongRequestFormLoading(false)));
  }
);
