import React from "react";
import PropTypes from "prop-types";
import CreateNote from "./CreateNote";
import EditNote from "./EditNote";
import PracticeIcons from "../PracticeIcons";

export default class NoteContainer extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    updateNoteInPracticeItem: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  noteButtonText = () => {
    if (this.props.item.note) {
      return <span>Edit Note</span>;
    } else {
      return (
        <div>
          <span>
            <i className="icon icon-plus"></i> Add note
          </span>
        </div>
      );
    }
  };

  toggleNote = () => {
    this.setState({ noteOpen: !this.state.noteOpen });
  };

  updateNoteInPracticeItem = (note, itemId) => {
    if (note) {
      this.props.updateNoteInPracticeItem(note, itemId);
      this.setState({ noteOpen: false });
    } else {
      this.setState({ errorText: "There was a problem saving this note." });
      setTimeout(() => this.setState({ errorText: null }));
    }
  };

  handleDeletedNote = (noteDeletedSuccessfully) => {
    if (noteDeletedSuccessfully === true) {
      this.props.updateNoteInPracticeItem(null, this.props.item.id);
      this.setState({ noteOpen: false });
    } else {
      this.setState({ errorText: "There was a problem deleting this note." });
      setTimeout(() => this.setState({ errorText: null }));
    }
  };

  noteAction = () => {
    let { item } = this.props;
    if (item.note) {
      return (
        <EditNote
          item={item}
          userId={this.props.userId}
          closeForm={this.toggleNote}
          handleDeletedNote={this.handleDeletedNote}
          updateNoteInPracticeItem={this.updateNoteInPracticeItem}
        />
      );
    } else {
      return (
        <CreateNote
          item={item}
          userId={this.props.userId}
          closeForm={this.toggleNote}
          updateNoteInPracticeItem={this.updateNoteInPracticeItem}
        />
      );
    }
  };

  showNoteContent = () => {
    if (this.props.item.note) {
      return (
        <div>
          <p>{this.props.item.note.content}</p>
        </div>
      );
    }
  };

  noteHeader = () => {
    return (
      <div className="routine-notes__top">
        <span
          className="routine-notes__icon"
          dangerouslySetInnerHTML={{ __html: PracticeIcons.journalIcon() }}
        ></span>
        <div className="routine-notes__heading">
          <span>NOTES</span>
        </div>
      </div>
    );
  };

  render() {
    let { item } = this.props;
    if (item && item.note && !this.state.noteOpen) {
      return (
        <div>
          {this.noteHeader()}
          <div className="routine-notes__existing">
            <span>{item.note.content}</span>
            <span
              className="routine-notes__edit"
              onClick={this.toggleNote}
              dangerouslySetInnerHTML={{
                __html: PracticeIcons.editButtonDisabled(),
              }}
            ></span>
          </div>
        </div>
      );
    } else if (this.state.noteOpen) {
      return (
        <div>
          {this.noteHeader()}
          {this.state.errorText}
          {this.noteAction()}
        </div>
      );
    } else {
      return (
        <div>
          {this.noteHeader()}
          {this.showNoteContent()}
          <div className="practice-buttons__container practice-buttons__container--note">
            <button className="routine-notes__add" onClick={this.toggleNote}>
              {this.noteButtonText()}
            </button>
          </div>
        </div>
      );
    }
  }
}
