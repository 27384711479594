import React from "react";

const IndividualMenuItem = ({ item }) => {
  return (
    <div className="menu-item__container" key={item.link}>
      <div className="menu-item__strip" style={{ background: "black" }} />
      <a
        className="menu-item__link"
        href={item.link}
        rel={item.target ? "noreferrer" : ""}
        target={item.target ? item.target : "_self"}
      >
        {item.title}
        {item.target === "_blank" && (
          <span style={{ marginLeft: "5px" }}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 19H5V5H12V3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V12H19V19ZM14 3V5H17.59L7.76 14.83L9.17 16.24L19 6.41V10H21V3H14Z"
                fill="#44546c"
              />
            </svg>
          </span>
        )}
      </a>
    </div>
  );
};

const Column = ({ item }) => {
  return (
    <>
      <div className="menu-item__section">
        <h5>{item.title}</h5>
        <div className="menu-item__links">
          <div className="menu-item__column">
            {item.subItems.map((i, idx) => (
              <IndividualMenuItem item={i} key={idx} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Column;
