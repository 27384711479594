import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axiosWithCSRF from "./axiosWithCSRF";
import Loading from "./Loading";
import { Provider } from "react-redux";
import Recaptcha from "react-recaptcha";
import loadScript from "./loadScript";
import Snackbar from "./Snackbar";
import data from "../../pages/FAQ/data/faq.json";

const DEFAULT_CONTACTS = ["hello@justinguitar.com"];

const ContactForm = ({ recaptchaKey }) => {
  const store = ReactOnRails.getStore("searchStore");
  const currentUser = store.getState().currentUser;
  let formikRef = useRef(null);
  let recaptchaRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [successfulSubmission, setSuccessfulSubmission] = useState(null);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const getCategory = (slug) => {
    return categories.find((category) => category.slug === slug);
  };

  useEffect(() => {
    const categories = data.map(({ categoryName, slug, contacts }) => ({
      categoryName,
      slug,
      contacts,
    }));
    setCategories(categories);
    loadScript("https://www.google.com/recaptcha/api.js");
  }, []);

  useEffect(() => {
    const categoryParam = new URLSearchParams(document.location.search.substring(1)).get(
      "category"
    );

    formikRef.current.setFieldValue(
      "category",
      getCategory(categoryParam)
        ? categoryParam
        : categoryParam === "other"
        ? categoryParam
        : categories[0]
        ? categories[0].slug
        : ""
    );
  }, [categories]);

  const handleSubmit = (values, setSubmitting) => {
    const category = getCategory(values.category);
    setIsLoading(true);
    axiosWithCSRF()
      .post("/contact", {
        name: values.name.trim(),
        email: values.email.trim(),
        message: values.message.trim(),
        category: values.category,
        contacts: category ? category.contacts : DEFAULT_CONTACTS,
        ["g-recaptcha-response"]: recaptchaToken,
      })
      .then((res) => {
        setMessage(res.data.message);
        setIsLoading(false);
        setSeverity("success");
        setSuccessfulSubmission(true);
      })
      .catch((err) => {
        setSubmitting(false);
        setIsLoading(false);
        recaptchaRef.current.reset();
        setMessage(err.response.data.error);
        setSeverity("error");
      });
  };

  const recaptchaCallback = () => {
    // for some reason verifyCallback is only working if onloadCallback is defined, so define it here and do nothing.
    return;
  };

  const recaptchaVerified = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <Provider store={store}>
      <div className="contact-form" style={{ position: "relative" }}>
        {isLoading && <Loading />}

        {successfulSubmission ? (
          <div className="contact-form__thanks">
            <h2>Thanks for your message!</h2>

            <p>We will review it and get back to you as soon as possible.</p>
          </div>
        ) : (
          <>
            <h2>email justin & team</h2>

            <Formik
              innerRef={formikRef}
              initialValues={{
                category: "",
                name: currentUser
                  ? `${currentUser.data.attributes.firstName} ${currentUser.data.attributes.lastName}`.trim()
                  : "",
                email: currentUser ? currentUser.data.attributes.email : "",
                message: "",
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Name is required."),
                email: Yup.string()
                  .email("Please provide a valid email.")
                  .required("Email address is required."),
                message: Yup.string().required("Message is required."),
              })}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values, setSubmitting);
              }}
            >
              {(props) => (
                <Form>
                  <Field
                    as="select"
                    name="category"
                    placeholder="What do you need help with"
                    autoFocus
                  >
                    {categories.map((category, idx) => (
                      <option key={idx} value={category.slug}>
                        {category.categoryName}
                      </option>
                    ))}
                    <option key={categories.length} value="other">
                      Other
                    </option>
                  </Field>
                  <ErrorMessage name="category" className="error" component="div" />
                  <Field
                    name="name"
                    className={props.errors.name && props.touched.name ? " has-error" : ""}
                    type="text"
                    placeholder="Name"
                  />
                  <ErrorMessage name="name" className="error" component="div" />
                  <Field
                    name="email"
                    className={props.errors.email && props.touched.email ? " has-error" : ""}
                    type="email"
                    placeholder="Email address"
                  />
                  <ErrorMessage name="email" className="error" component="div" />
                  <Field
                    as="textarea"
                    className={props.errors.message && props.touched.message ? " has-error" : ""}
                    name="message"
                    placeholder="Type your message here..."
                  />
                  <ErrorMessage name="message" className="error" component="div" />
                  <div className="recaptcha-container">
                    <Recaptcha
                      render="explicit"
                      ref={recaptchaRef}
                      sitekey={recaptchaKey}
                      onloadCallback={recaptchaCallback}
                      verifyCallback={(token) => recaptchaVerified(token)}
                    />
                  </div>
                  <button
                    disabled={
                      !(props.isValid && props.dirty && !props.isSubmitting && recaptchaToken)
                    }
                  >
                    submit
                  </button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
      <Snackbar message={message} severity={severity} />
    </Provider>
  );
};

export default ContactForm;
