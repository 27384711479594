import React, { useEffect } from "react";
import SiteMapGrade from "./SiteMapGrade";
import { CSSTransition } from "react-transition-group";

export default function ({ isCollapsible, level: { label, gradient, Copy, grades } }) {
  const [isOpen, setOpen] = React.useState(!isCollapsible);
  const showList = !isCollapsible || isOpen;

  useEffect(() => {
    if (label === "Beginner") {
      setOpen(true);
    }
  }, []);

  return (
    <div className="site-map-level">
      <div className="site-map-level__header">
        <h2>{label}</h2>
        {isCollapsible && (
          <i
            onClick={() => setOpen(!isOpen)}
            className={`icon icon-angle-${isOpen ? "up" : "down"}`}
          />
        )}
        {gradient && (
          <div className="site-map-level__header-gradient" style={{ background: gradient }} />
        )}
      </div>

      <CSSTransition in={showList} timeout={200} classNames="verticalGrowInOut">
        {showList ? (
          <div className="verticalGrowInOut">
            <div className="site-map-level__copy">{<Copy />}</div>
            <div className="site-map-level__grades-listing">
              {grades.map((grade) => (
                <SiteMapGrade key={grade.label} grade={grade} isCollapsible={isCollapsible} />
              ))}
            </div>
          </div>
        ) : (
          <div />
        )}
      </CSSTransition>
    </div>
  );
}
