import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import TabsPlanSelect from "./checkout/TabsPlanSelect";
import { withInnerWidth } from "../../components/shared";
import TabsVideo from "../TabsVideo";
import { TabsFaqs } from "../TabsFaqs";
import WhatsIncluded from "./WhatsIncluded";
import LatestSongs from "./LatestSongs";

const SalesPage = ({
  hasActiveTabsSubscription,
  pricingPlans,
  sheetCount,
  songs,
  tabsCount,
  tabsFaqs,
  trialAvailable,
  user,
  width,
}) => {
  const [isMobileView, setIsMobileView] = useState(width <= 576);

  useEffect(() => {
    if (width <= 576 && !isMobileView) {
      setIsMobileView(true);
    }

    if (width > 576 && isMobileView) {
      setIsMobileView(false);
    }
  }, [width]);

  return (
    <div className="products-listing products-listing--tabs container">
      <WhatsIncluded
        width={width}
        isMobileView={isMobileView}
        sheetCount={sheetCount}
        tabsCount={tabsCount}
      />

      {!hasActiveTabsSubscription && (
        <TabsPlanSelect
          pricingPlans={pricingPlans}
          trialAvailable={trialAvailable}
          user={user}
          width={width}
        />
      )}

      <LatestSongs
        hasActiveTabsSubscription={hasActiveTabsSubscription}
        songs={songs}
        width={width}
      />

      <TabsFaqs faqs={tabsFaqs} additionalContent={width > 576 ? <TabsVideo /> : null} />
    </div>
  );
};

SalesPage.propTypes = {
  hasActiveTabsSubscription: PropTypes.bool.isRequired,
  pricingPlans: PropTypes.arrayOf(PropTypes.object).isRequired,
  sheetCount: PropTypes.number.isRequired,
  songs: PropTypes.arrayOf(PropTypes.object).isRequired,
  tabsCount: PropTypes.number.isRequired,
  tabsFaqs: PropTypes.object.isRequired,
  trialAvailable: PropTypes.bool.isRequired,
  user: PropTypes.object,
  width: PropTypes.number.isRequired,
};

export default withInnerWidth(SalesPage);
