import React, { createContext, useContext, useReducer } from "react";

export const UserContext = createContext(null);
const UserDispatchContext = createContext(null);

export const ACTION_TYPES = {
  SAVE_ITEM: "SAVE_SONG_ITEM",
  DELETE_SONG_ITEM: "DELETE_SONG_ITEM",
  SAVE_PRACTICE_ITEM: "SAVE_PRACTICE_ITEM",
  DELETE_PRACTICE_ITEM: "DELETE_PRACTICE_ITEM",
  SET_SONG_PRACTICE_ITEMS: "SET_SONG_PRACTICE_ITEMS",
  SET_SONG_USER_ITEMS: "SET_SONG_USER_ITEMS",
  SET_SONG_VOTES: "SET_SONG_VOTES",
  SUBMIT_VOTE: "SUBMIT_VOTE",
  DELETE_VOTE: "DELETE_VOTE",
  SET_USER_SONG_REQUESTS: "SET_USER_SONG_REQUESTS",
  SUBMIT_SONG_REQUEST: "SUBMIT_SONG_REQUEST",
};

function userReducer(user, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_SONG_PRACTICE_ITEMS: {
      return {
        ...user,
        userPracticeItems: action.userPracticeItems,
      };
    }
    case ACTION_TYPES.SET_SONG_USER_ITEMS: {
      return {
        ...user,
        savedSongItems: action.userItems,
      };
    }
    case ACTION_TYPES.SAVE_PRACTICE_ITEM: {
      return {
        ...user,
        userPracticeItems: [...user.userPracticeItems, action.userPracticeItem],
      };
    }
    case ACTION_TYPES.DELETE_PRACTICE_ITEM: {
      return {
        ...user,
        userPracticeItems: user.userPracticeItems.filter(
          (item) => item.id !== action.userPracticeItemId
        ),
      };
    }
    case ACTION_TYPES.DELETE_SONG_ITEM: {
      return {
        ...user,
        savedSongItems: user.savedSongItems.filter((item) => item.id !== action.userSongItemId),
      };
    }
    case ACTION_TYPES.SAVE_SONG_ITEM: {
      return {
        ...user,
        savedSongItems: [...user.savedSongItems, action.userSongItem],
      };
    }
    case ACTION_TYPES.SET_SONG_VOTES: {
      return {
        ...user,
        userSongVotes: action.userSongVotes,
      };
    }
    case ACTION_TYPES.SUBMIT_VOTE: {
      return {
        ...user,
        userSongVotes: [...user.userSongVotes, action.vote],
      };
    }
    case ACTION_TYPES.DELETE_VOTE: {
      return {
        ...user,
        userSongVotes: user.userSongVotes.filter(
          (vote) => Number(vote.attributes.songRequestId) !== Number(action.songRequestId)
        ),
      };
    }
    case ACTION_TYPES.SET_USER_SONG_REQUESTS: {
      return {
        ...user,
        userSongRequests: action.userSongRequests,
      };
    }
    case ACTION_TYPES.SUBMIT_SONG_REQUEST: {
      return {
        ...user,
        userSongRequests: [...user.userSongRequests, action.songRequest],
      };
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}

export function UserProvider({ currentUser, children }) {
  const [user, dispatch] = useReducer(userReducer, currentUser);

  return (
    <UserContext.Provider value={user}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}

export function useUserDispatch() {
  return useContext(UserDispatchContext);
}
