import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import Swoosh from "../shared/Swoosh";
import LiteYoutube from "../shared/LiteYoutube";
import PlayIcon from "../../group/PlayIcon";

export const VideoEmbed = ({ videoUrl, hasMaxResImage, fullWidth = false }) => {
  if (videoUrl.includes("vimeo")) {
    return (
      <div className="react-player-wrapper" style={fullWidth ? { width: "100%" } : {}}>
        <ReactPlayer
          url={videoUrl}
          controls={true}
          width="100%"
          allowFullScreen={true}
          height="100%"
          className="react-player"
        />
      </div>
    );
  } else {
    return <LiteYoutube url={videoUrl} hasMaxResImage={hasMaxResImage} />;
  }
};

// const FallbackImg = ({ image }) => {
//   if (image.img) {
//     return <img src={image.img} alt={image.alt} />;
//   } else {
//     return <img src={"https://jtgt-static.b-cdn.net/images/default-social.png"} />;
//   }
// };

const VideoSwoosh = ({
  backgroundColor,
  videoUrl,
  hasMaxResImage,
  Header,
  Content,
  fallbackImg,
  hasHeaderImage,
}) => (
  <div className="video-swoosh">
    <Swoosh color={backgroundColor}>
      <div className="video-swoosh__main container">
        <div className="video-swoosh__header">
          <Header />
        </div>
        <div className="flex">
          {videoUrl && videoUrl.length > 0 ? (
            <div className="video-swoosh__video">
              <VideoEmbed videoUrl={videoUrl} hasMaxResImage={hasMaxResImage} />
            </div>
          ) : (
            <div
              className="video-swoosh__fallback-img"
              style={{ background: `url(${fallbackImg.img}) no-repeat` }}
            >
              {!hasHeaderImage ? (
                <div>
                  <PlayIcon />
                  <p>video coming soon</p>
                </div>
              ) : null}
            </div>
          )}

          {Content != null && (
            <div className="video-swoosh__content">
              <Content />
            </div>
          )}
        </div>
      </div>
    </Swoosh>
  </div>
);

VideoSwoosh.defaultProps = {
  backgroundColor: "#44546C",
  hasMaxResImage: true,
};

VideoSwoosh.propTypes = {
  backgroundColor: PropTypes.string,
  videoUrl: PropTypes.string.isRequired,
  hasMaxResImage: PropTypes.bool,
  Header: PropTypes.func.isRequired,
  Content: PropTypes.func,
};

export default VideoSwoosh;
