import React from "react";

export default class TabbedTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.data.title === "The Beginner Song Course App") {
      dataLayer.push({ event: "optimize.activateBeginnerCourseTab" });
    }
  }

  render() {
    return (
      <div>
        <h5 className="header5 bold long-header">{this.props.data.title}</h5>
        <h2 className="header2 header2--white short-header">{this.props.position}</h2>
        {this.props.typeOf == "popular_songs" && (
          <p className="paragraph">{this.props.data.subtitle}</p>
        )}
      </div>
    );
  }
}
