import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import CountdownTimer from "../../components/CountdownTimer";
import { jgDenimColors } from "../../jg-material/theme/jg-denim-colors";
import { CaretDownIcon, CaretUpIcon } from "../../components/shared/Icons";
import { withInnerWidth } from "../../components/shared";
import { BootstrapSize, breakpoints } from "../../components/shared/bootstrap-helpers";

const EnrollmentCountdown = ({ enrollmentCloseTimestamp, enrollmentOpen, width }) => {
  if (!enrollmentOpen) return null;

  const [countdownOpen, setCountdownOpen] = useState(false);

  const isMobile = width < breakpoints[BootstrapSize.smDevice];

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        zIndex: 100,
        py: "5px",
        width: !countdownOpen || isMobile ? "100%" : "auto",
        background: jgDenimColors.denim.dark,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: countdownOpen ? "column" : "row",
        textAlign: "center",
        borderRadius: "0px ",
      }}
    >
      <Typography
        variant={isMobile ? "body1" : "h6"}
        sx={{
          color: "white",
          letterSpacing: 0,
          pt: countdownOpen ? 1 : 0,
          pb: countdownOpen ? 2 : 0,
          mr: countdownOpen ? 0 : 1,
        }}
      >
        Enrollment closes in:
      </Typography>
      <CountdownTimer
        collapsedColor={"white"}
        endDateUnixTimestamp={enrollmentCloseTimestamp}
        expanded={countdownOpen}
        isMobile={isMobile}
      />
      <Box
        sx={{ position: "absolute", top: 10, right: 10 }}
        // onClick={() => setCountdownOpen(!countdownOpen)}
      >
        {/* 
        admin requested to move this for now. let's keep it in case we backtrack...
        {countdownOpen ? (
          <CaretDownIcon fontSize="large" color="white"></CaretDownIcon>
        ) : (
          <CaretUpIcon fontSize="large" color="white"></CaretUpIcon>
        )} */}
      </Box>
    </Box>
  );
};

export default withInnerWidth(EnrollmentCountdown);
