import React from "react";
import { PurchaseTilesByReference } from "./Purchases/PurchaseTilesByReference";

// Todo: eventually generalize this for other enrollments.
const PmtEnrollment = ({ enrollments }) => {
  if (!enrollments || enrollments.length === 0) return null;

  return (
    <div className="clickable">
      <div className="purchase-row">
        <div className="purchase__img-container">
          <img
            className={`purchase__img`}
            src={PurchaseTilesByReference.PMT}
            onClick={() => (window.location.href = "/classes/practical-music-theory")}
          ></img>
        </div>
        <div className="purchase-card">
          <div
            className="purchase-card__left"
            onClick={() => (window.location.href = "/classes/practical-music-theory")}
          >
            <span className="bold purchase__title">Practical Music Theory</span>
            <span className="purchase__due">Enrolled for Free</span>
          </div>
          <div className="purchase-card__right">
            <div className="purchase__actions">
              <p
                onClick={() => (window.location.href = "/store/practical-music-theory#plans")}
                className="teal clickable"
              >
                Upgrade to a Paid Plan
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PmtEnrollment;
