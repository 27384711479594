import React from "react";

export default class Slide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showAppIcons = () => {
    this.setState({ appIconsOpen: true });
    setTimeout(() => this.setState({ appIconsOpen: false }), 3000);
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ appIconsOpen: nextProps.appIconsOpen });
  }

  showBadges() {
    let badges;
    if (this.props.product.app_store_link && this.props.product.google_play_link) {
      badges = (
        <div>
          <a href={this.props.product.app_store_link} target="_blank">
            <img src="https://jtgt-static.b-cdn.net/images/app-store.png"></img>
          </a>
          <a href={this.props.product.google_play_link} target="_blank">
            <img src="https://jtgt-static.b-cdn.net/images/google-play.png"></img>
          </a>
        </div>
      );
    } else if (this.props.product.app_store_link) {
      badges = (
        <a href={this.props.product.app_store_link} target="_blank">
          <img src="https://jtgt-static.b-cdn.net/images/app-store.png"></img>
        </a>
      );
    } else if (this.rpops.google_play_link) {
      badges = (
        <a href={this.props.product.google_play_link} target="_blank">
          <img src="https://jtgt-static.b-cdn.net/images/google-play.png"></img>
        </a>
      );
    }
    return badges;
  }

  showApple() {
    if (this.props.product.app_store_link) {
    }
  }

  showGoogle() {
    if (this.props.product.google_play_link) {
    }
  }

  backgroundImage() {
    if (!this.state.appIconsOpen) {
      return `url(${this.props.product.image_url})`;
    }
  }

  displayItem() {
    if (this.props.productIndex === this.props.currentProductIndex) {
      return "flex";
    } else {
      return "none";
    }
  }

  render() {
    let background = {
      backgroundImage: this.backgroundImage(),
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100%",
      width: "100%",
      display: this.displayItem(),
    };

    let containerDiv = {
      display: this.displayItem(),
    };

    return (
      <div style={containerDiv} className="products">
        {this.props.product.shopify_link && (
          <div className="products__item ">
            <div className="products__image">
              <a href={this.props.product.shopify_link} target="_blank">
                <img src={this.props.product.image_url} alt={this.props.product.title} />{" "}
              </a>
            </div>
          </div>
        )}
        {this.props.product.type_of == "app" && (
          <div className="products__item products__item--app">
            <div
              className={
                "products__image products__image--app " +
                (this.state.appIconsOpen ? "zero-opacity" : "")
              }
              onClick={this.props.openIcons}
            >
              <img src={this.props.product.image_url}></img>
            </div>

            <div className={"right-nav-apps " + (this.state.appIconsOpen ? "" : "hidden")}>
              {this.state.appIconsOpen && this.showBadges()}
            </div>
          </div>
        )}
      </div>
    );
  }
}
