import { createAction, createSlice } from "@reduxjs/toolkit";

import {
  createUserPracticeItem,
  createUserSongItem,
  deleteUserPracticeItem,
  deleteUserSongItem,
} from "../effects/userInteractions";
import { createVote } from "../effects/createVote";
import { deleteVote } from "../effects/deleteVote";
import { fetchCurrentUserSongRequests } from "../effects/fetchCurrentUserSongRequests";

export const initialState = {
  currentUser: null,
  savedSongs: {},
  practiceSongs: {},
};

export const { actions, reducer } = createSlice({
  name: "session",
  initialState,
  reducers: {
    setCurrentUser(state, { payload }) {
      state.currentUser = payload;
    },
    addCurrentUserSongRequest(state, { payload }) {
      state.mostRecentSongRequest = payload;
      state.songRequests.data.push(payload);

      const newVote = {
        ...payload.relationships.votes.data[0],
        attributes: { songRequestId: Number(payload.id) },
      };

      state.songRequestVotes.data.push(newVote); // user always votes for own song requests
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUserSongItem.fulfilled, (state, { payload }) => {
        state.savedSongs[payload.itemable_id] = payload;
      })
      .addCase(deleteUserSongItem.fulfilled, (state, { payload }) => {
        delete state.savedSongs[payload.itemable_id];
      })
      .addCase(createUserPracticeItem.fulfilled, (state, { payload }) => {
        state.practiceSongs = {
          ...state.practiceSongs,
          ...payload,
        };
      })
      .addCase(deleteUserPracticeItem.fulfilled, (state, { payload }) => {
        delete state.practiceSongs[payload];
      })
      .addCase(createVote.fulfilled, (state, { payload }) => {
        state.songRequestVotes.data.push(payload.data);
      })
      .addCase(deleteVote.fulfilled, (state, { payload }) => {
        state.songRequestVotes.data = state.songRequestVotes.data.filter(
          (vote) => vote.attributes.songRequestId !== Number(payload.id)
        );
      })
      .addCase(fetchCurrentUserSongRequests.fulfilled, (state, { payload }) => {
        state.songRequests.data = [...state.songRequests.data, ...payload.data];
        state.songRequests.metadata = {
          page: Number(payload.metadata.page),
          perPage: Number(payload.metadata.perPage),
          totalCount: Number(payload.metadata.totalCount),
        };
      });
  },
});
