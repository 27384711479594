import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";

import { Workshop } from "../interfaces/workshop.interface";
import { JgState } from "../../../redux/jg-store";
import { Entity } from "../../../../../interfaces";
import { selectCurrentEntityId } from "../../../redux/slices/ui/ui.selectors";
import { selectSession } from "../../../redux/slices/session/session.selectors";

export const workshopsAdaptor = createEntityAdapter<Entity<Workshop>>({
  selectId: ({ attributes: { slug } }) => {
    return slug;
  },
  sortComparer: (a, z) =>
    new Date(z.attributes.arrivalTime).getTime() - new Date(a.attributes.arrivalTime).getTime(),
});

const workshopEntitySelectors = workshopsAdaptor.getSelectors<JgState>((state) => {
  return state?.entities?.workshops || {};
});

export const workshopSelectors = {
  ...workshopEntitySelectors, // TODO: select current
  selectCurrentWorkshop: createSelector(
    (state) => state,
    selectCurrentEntityId, // for workshops it's the slug
    (state, slug) => workshopEntitySelectors.selectById(state, slug)
  ),
  selectCurrentUserIsRegistered: createSelector(selectSession, ({ userIsRegisteredForWorkshop }) =>
    Boolean(userIsRegisteredForWorkshop)
  ),
  selectCurrentUserCanPayDeposit: createSelector(selectSession, ({ userCanPayDeposit }) =>
    Boolean(userCanPayDeposit)
  ),
};

const { setAll, setMany } = workshopsAdaptor;

export const workshopsSlice = createSlice({
  name: "workshops",
  initialState: workshopsAdaptor.getInitialState(),
  reducers: {
    setAll,
    setMany,
  },
});

export type WorkshopsState = ReturnType<typeof workshopsSlice.reducer>;
