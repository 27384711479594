import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { workshopImageBaseUrl } from "../gallery/constants";

interface PreviewImage {
  url: string;
  caption: string;
  cols: number;
  rows: number;
}

const bigImgPath = "2023-Italy-Workshop-Week-1/2023-ItalyWeek01-08.jpg";
const smallTopImgPath = "2023-Italy-Workshop-Week-2/2023-ItalyWeek02-06.jpg";
const smallBottomImgPath = "2023-Quebec-Acoustic-Jam-Week/2023-Acoustic-06.jpg";

const bigImg: PreviewImage = {
  url: workshopImageBaseUrl + bigImgPath,
  caption: "Italy Workshop, Week 1 - 2023",
  cols: 2,
  rows: 2,
};

const smallTopImg: PreviewImage = {
  url: workshopImageBaseUrl + smallTopImgPath,
  caption: "Italy Workshop, Week 2 - 2023",
  cols: 1,
  rows: 1,
};
const smallBottomImg: PreviewImage = {
  url: workshopImageBaseUrl + smallBottomImgPath,
  caption: "Quebec Acoustic Jam Week 2023",
  cols: 1,
  rows: 1,
};

export const WorkshopGalleryPreview = () => {
  const isMediumOrSmaller = useMediaQuery(jgDenimTheme.breakpoints.down("md"));
  const rowHeight = isMediumOrSmaller ? 420 : 210;
  return (
    <Box>
      <Typography variant="h4" textAlign="center" padding={jgDenimTheme.spacing(6, 0, 4)}>
        Gallery
      </Typography>
      <ImageList variant="quilted" cols={3} rowHeight={rowHeight} gap={16}>
        {[bigImg, smallTopImg, smallBottomImg].map(({ cols, rows, url, caption }) => (
          <ImageListItem
            cols={isMediumOrSmaller ? 3 : cols}
            rows={isMediumOrSmaller ? 1 : rows}
            sx={{ cursor: "pointer" }}
            onClick={() => (window.location.href = "/workshops/gallery")}
            key={caption}
          >
            <img src={url} loading="lazy" style={{ borderRadius: "10px" }} />
            <ImageListItemBar
              title={caption}
              sx={{
                background: "none",
                textAlign: "right",
                fontWeight: "bold",
                // TODO: bakeshop font family
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};
