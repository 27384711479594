import React from "react";
import PropTypes from "prop-types";
import EmailProviderIcons from "./EmailProviderIcons";

export default class CheckEmailPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="auth">
        <div className="auth__container auth__container--success">
          <i className="icon icon-check"></i>
          <p>{this.props.firstSentence}</p>
          {this.props.hasReminder && <p className="emphasized">ONLY ONE MORE THING:</p>}

          <p>{this.props.secondSentence}</p>
          <div className="auth__emails">
            <div>
              <a href="https://www.gmail.com">
                <span dangerouslySetInnerHTML={{ __html: EmailProviderIcons.gmail() }} />
              </a>
            </div>
            <div>
              <a href="https://login.live.com/">
                <span dangerouslySetInnerHTML={{ __html: EmailProviderIcons.outlook() }} />
              </a>
            </div>
            <div>
              <a href="https://login.yahoo.com/">
                <span dangerouslySetInnerHTML={{ __html: EmailProviderIcons.yahoo() }} />
              </a>
            </div>
            <div>
              <a href="https://login.aol.com/">
                <span dangerouslySetInnerHTML={{ __html: EmailProviderIcons.aol() }} />
              </a>
            </div>
          </div>
          <p className="footnote">Don't forget to check your spam folder!</p>
        </div>
      </div>
    );
  }
}

CheckEmailPrompt.propTypes = {
  firstSentence: PropTypes.string.isRequired,
  secondSentence: PropTypes.string.isRequired,
  hasReminder: PropTypes.bool.isRequired,
};
