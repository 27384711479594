import React from "react";
import { connect } from "react-redux";

import {
  fetchSongs,
  selectFilters,
  selectFiltersOpen,
  selectHasActiveFilters,
  slices,
} from "./store";
import { FiltersOpenCloseButton } from "../shared/FiltersOpenCloseButton";
import { PreventScroll } from "../shared";
import { filters } from "./filters";
import { withBootstrapSize } from "../shared/WithBootstrapSize";
import { BootstrapSize, breakpoints } from "../shared/bootstrap-helpers";

const SongFiltersButton = ({ width, bootstrapSize, ...props }) => (
  <PreventScroll
    shouldPreventScroll={() =>
      props.filtersOpen && width < breakpoints[BootstrapSize[bootstrapSize]]
    }
  >
    {props.filtersOpen && <div className="overlay" onClick={props.closeFilters} />}
    <FiltersOpenCloseButton {...props} availableFilters={filters} />
  </PreventScroll>
);

export default connect(
  (state) => ({
    activeFilters: selectFilters(state),
    filtersOpen: selectFiltersOpen(state),
    hasActiveFilters: selectHasActiveFilters(state),
  }),
  (dispatch) => {
    const { openFilters, closeFilters } = slices.ui.actions;
    const { toggleFilter, clearFilters } = slices.ui.filters.actions;

    return {
      openFilters: () => dispatch(openFilters()),
      closeFilters: () => dispatch(closeFilters()),
      clearFilters: () => {
        dispatch(clearFilters());
        dispatch(fetchSongs());
      },
      toggleFilter: (option) => {
        dispatch(toggleFilter(option));
        dispatch(fetchSongs());
      },
    };
  }
)(withBootstrapSize(SongFiltersButton));
