import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  userNotesWithPracticeExcluded,
  songNotes,
  lessonNotes,
  moduleNotes,
} from "../../components/shared/session/sessionSelectors";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import { clearAlert, openAlert, openDialog } from "../../components/shared/ui/uiSlice";
import { addOrUpdateUserNote, deleteUserNote } from "../../components/shared/session/sessionSlice";
import Dialog from "../../components/shared/ui/Dialog";
import Snackbar from "../../components/shared/ui/Snackbar";
import { setActiveTab } from "../store/dashboardStore";
import UserNote from "./UserNote";
import UserNotes from "./UserNotes";
import TabbedContent from "../shared/TabbedContent";

const TABS = [
  { name: "all", nameShort: "All", selector: "userNotes" },
  { name: "modules", nameShort: "Modules", selector: "moduleNotes" },
  { name: "lessons", nameShort: "Lessons", selector: "lessonNotes" },
  { name: "songs", nameShort: "Songs", selector: "songNotes" },
];

const NotesTab = (props) => {
  const dashboardFiltersRef = useRef(null);

  const getTabByName = (name) => {
    const tab = TABS.find((tab) => tab.name.toLowerCase() === name.toLowerCase());

    return tab ? tab : TABS[0];
  };

  const [activeTab, setActiveTab] = useState(
    getTabByName(location.hash.replace("-", " ").slice(1))["name"]
  );

  const [notes, setNotes] = useState(
    props[getTabByName(location.hash.replace("-", " ").slice(1))["selector"]].sort(
      (a, b) => Date.parse(b.attributes.createdAt) - Date.parse(a.attributes.createdAt)
    )
  );

  const [activeNote, setActiveNote] = useState(null);
  const [editingActiveNote, setEditingActiveNote] = useState(false);

  const searchNotes = (data) => {
    if (!data) {
      setNotes(props[getTabByName(location.hash.replace("-", " ").slice(1))["selector"]]);
    } else {
      setNotes(
        props[getTabByName(location.hash.replace("-", " ").slice(1))["selector"]].filter(
          (note) =>
            note.attributes.noteableTitle.toLowerCase().includes(data.toLowerCase()) ||
            note.attributes.content.toLowerCase().includes(data.toLowerCase())
        )
      );
    }
  };

  const sortNotes = (data) => {
    const [column, direction] = data.split("-");

    if (column === "createdAt") {
      const sorted = props[getTabByName(location.hash.replace("-", " ").slice(1))["selector"]].sort(
        (a, b) =>
          direction === "asc"
            ? Date.parse(a.attributes.createdAt) - Date.parse(b.attributes.createdAt)
            : Date.parse(b.attributes.createdAt) - Date.parse(a.attributes.createdAt)
      );

      setNotes([...sorted]); // create a new array, so the state updates
    }
  };

  const editNote = (note) => {
    window.scrollTo(0, 0);
    setActiveNote(
      props[getTabByName(location.hash.replace("-", " ").slice(1))["selector"]].find(
        (item) => item.id == note.id
      )
    );
    setEditingActiveNote(true);
  };

  const saveNote = (note) => {
    const noteData = {
      user_id: note.attributes.userId,
      noteable_id: note.attributes.noteableId,
      noteable_type: note.attributes.noteableType,
      content: note.attributes.content,
      id: note.id,
    };

    axiosWithCSRF()({
      url: `/user_notes/${note.id}`,
      method: "PATCH",
      data: { user_note: noteData },
    })
      .then((res) => {
        props.addOrUpdateUserNote(res.data.serializedNote.data);
        props.openAlert({ message: "Saved this note!", severity: "success" });
      })
      .catch((err) => {
        props.openAlert({
          message: "There was a problem saving this note.",
          severity: "error",
        });
      })
      .then(() => {
        setEditingActiveNote(false);
      });
  };

  const deleteNote = (note) => {
    axiosWithCSRF()
      .delete(`/user_notes/${note.id}`)
      .then((res) => {
        setEditingActiveNote(false);
        setActiveNote("");
        props.deleteUserNote(note);

        props.openAlert({ message: "Deleted this note!", severity: "success" });
      })
      .catch((err) => {
        props.openAlert({
          message: "There was a problem deleting this note.",
          severity: "error",
        });
      });
  };

  const goToNoteableType = (note) => {
    location.href = note.attributes.noteableUrl;
  };

  const goBack = (where = null) => {
    setNotes(props[getTabByName(location.hash.replace("-", " ").slice(1))["selector"]]);

    if (where) {
      setEditingActiveNote(false);
      setActiveNote(false);

      if (where === "dashboard") {
        props.setActiveTab("Dashboard");
      }
    } else {
      if (editingActiveNote) {
        setEditingActiveNote(false);
      } else if (activeNote) {
        setActiveNote(false);
      } else {
        props.setActiveTab("Dashboard");
      }
    }
  };

  const goToTab = (tabName) => {
    location.hash = `${tabName.replace(" ", "-")}`;
    setActiveTab(tabName);
    dashboardFiltersRef.current && dashboardFiltersRef.current.clearSearch();
    setNotes(props[getTabByName(location.hash.replace("-", " ").slice(1))["selector"]]);
  };

  const getBreadcrumbName = () => {
    if (editingActiveNote || activeNote) {
      return "Notes";
    } else {
      return "Dashboard";
    }
  };

  useEffect(() => {
    setNotes(props[getTabByName(location.hash.replace("-", " ").slice(1))["selector"]]);
  }, [props[getTabByName(location.hash.replace("-", " ").slice(1))["selector"]]]);

  return (
    <div className="dashboard-notes">
      <button className="back-btn" onClick={() => goBack(activeNote ? "notes" : "dashboard")}>
        <i className="icon icon-arrow-left"></i> {getBreadcrumbName()}
      </button>
      <h1 className="dashboard__heading">
        <button className="back-btn" onClick={() => goBack()}>
          <i className="icon icon-arrow-left"></i>
        </button>
        Notes
      </h1>
      {!activeNote ? (
        <UserNotes
          goToTab={goToTab}
          activeTab={activeTab}
          dashboardFiltersRef={dashboardFiltersRef}
          searchNotes={searchNotes}
          sortNotes={sortNotes}
          notes={notes}
          stateNotes={props[getTabByName(location.hash.replace("-", " ").slice(1))["selector"]]}
          editNote={editNote}
          goToNoteableType={goToNoteableType}
          tabs={TABS}
        />
      ) : (
        <UserNote
          activeNote={activeNote}
          setActiveNote={setActiveNote}
          editingActiveNote={editingActiveNote}
          setEditingActiveNote={setEditingActiveNote}
          goToNoteableType={goToNoteableType}
          saveNote={saveNote}
          openDialog={props.openDialog}
        />
      )}
      <Dialog onConfirm={deleteNote} />
      <Snackbar />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userNotes: userNotesWithPracticeExcluded(state),
  songNotes: songNotes(state),
  lessonNotes: lessonNotes(state),
  moduleNotes: moduleNotes(state),
  user: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  openDialog: (text) => dispatch(openDialog(text)),
  openAlert: (alert) => dispatch(openAlert(alert)),
  clearAlert: () => dispatch(clearAlert()),
  addOrUpdateUserNote: (note) => dispatch(addOrUpdateUserNote(note)),
  deleteUserNote: (note) => dispatch(deleteUserNote(note)),
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesTab);
