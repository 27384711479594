import React from "react";
import PropTypes from "prop-types";
import Plan from "./Plan";
import FreePlan from "./FreePlan";
import LoginPrompt from "../../components/auth/LoginPrompt";
import UpgradePlans from "./UpgradePlans";

const SubscriptionButton = ({ activePlan, buttonText, currentUser, createCheckoutSession }) => {
  if (currentUser) {
    return (
      <button
        disabled={!activePlan}
        className="button button--primary"
        onClick={createCheckoutSession}
      >
        {buttonText()}
      </button>
    );
  } else {
    return <LoginPrompt loginAction="continue" token={ReactOnRails.authenticityToken()} />;
  }
};

const PricingPlans = ({
  activePlan,
  currentUser,
  setActivePlan,
  pricingPlans,
  buttonText,
  createCheckoutSession,
}) => {
  const { hasActivePmtSubscription } = currentUser?.data?.attributes || {};

  function orderedPlans() {
    return pricingPlans.data.sort((a, b) => a.attributes.price - b.attributes.price);
  }

  if (hasActivePmtSubscription) {
    return (
      <UpgradePlans
        currentUser={currentUser}
        activePlan={activePlan}
        setActivePlan={setActivePlan}
        pricingPlans={pricingPlans}
        createCheckoutSession={createCheckoutSession}
      />
    );
  }

  return (
    <>
      <h2 className="title">Course plans</h2>

      <div className="plans">
        <FreePlan activePlan={activePlan} setActivePlan={setActivePlan} currentUser={currentUser} />
        {orderedPlans().map((plan) => (
          <Plan
            key={plan.id}
            plan={plan}
            activePlan={activePlan}
            setActivePlan={setActivePlan}
            currentUser={currentUser}
          />
        ))}

        <SubscriptionButton
          activePlan={activePlan}
          buttonText={buttonText}
          currentUser={currentUser}
          createCheckoutSession={createCheckoutSession}
        />
      </div>
    </>
  );
};

PricingPlans.propTypes = {
  activePlan: PropTypes.object,
  currentUser: PropTypes.object,
  setActivePlan: PropTypes.func.isRequired,
  pricingPlans: PropTypes.object.isRequired,
  buttonText: PropTypes.func.isRequired,
};

export default PricingPlans;
