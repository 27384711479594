import ReactOnRails from "react-on-rails";
import { configureStore, createSlice, combineReducers } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { ui } from "../../components/shared/ui/uiIndex";
import { session } from "../../components/shared/session/sessionIndex";

const initialState = (props) => {
  // These are set in the controller (groups_controller#show -> initialize_redux_store)
  return {
    entity: {},
  };
};

const initialSessionState = (props) => {
  return {
    session: {
      currentUser: props.currentUser,
      token: props.token,
    },
  };
};

const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {},
});

const reducer = combineReducers({
  ui: ui.reducer,
  session: session.reducer,
  entity: groupSlice.reducer,
});

const createGroupStore = (preloadedState) => configureStore({ reducer, preloadedState });

ReactOnRails.registerStore({
  groupStore: (props, railsContext) => {
    return createGroupStore({
      entity: {
        ...initialState,
        ...props.entity,
      },
      session: {
        ...initialSessionState,
        ...props.session,
      },
      ui: {
        ...ui.initialState,
        ...props.ui,
      },
    });
  },
});
