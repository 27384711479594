import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { CDN_URL } from "../../components/shared/CdnUrl";

const defaultHeaderImages = {
  [BootstrapSize.smDevice]: `${CDN_URL}/images/pages/teachers/jg-approved-teachers-hero-lg.jpg`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/teachers/jg-approved-teachers-hero-sm.jpg`,
};

const LOGO = `${CDN_URL}/images/pages/teachers/jg-logo.png`;

const TeachersSwoosh = () => {
  return (
    <div className="teachers-swoosh">
      <Swoosh imgUrl={defaultHeaderImages}>
        <div className="teachers-swoosh__content">
          <h1 className="header1">
            <img src={LOGO} alt="JustinGuitar" /> approved teachers
          </h1>
          <p>
            Find excellent teachers that offer one-on-one lessons.
            <br />
            Just in case you need extra help or motivation. :)
          </p>
          <a className="button button--primary" href="#list">
            see the teachers
          </a>
        </div>
      </Swoosh>
    </div>
  );
};

export default TeachersSwoosh;
