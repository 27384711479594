import { Components } from "@mui/material";
import { jgDenimColors } from "../../jg-denim-colors";
import { StepIconComponent } from "./StepIconComponent";
import { activeColor, completedColor, disabledColor } from "./colors";

export const stepLabel: Components["MuiStepLabel"] = {
  styleOverrides: {
    label: {
      color: jgDenimColors.white,

      "&.Mui-active": {
        color: activeColor,
      },
      "&.Mui-completed": {
        color: completedColor,
      },
      "&.Mui-disabled": {
        color: disabledColor,
      },
    },
  },
  defaultProps: {
    StepIconComponent,
  },
};
