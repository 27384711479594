import React from "react";
import AdBlock from "just-detect-adblock";

export default class Ads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      detectedAdBlock: AdBlock.isDetected(),
    };
  }

  componentDidMount() {
    this.updateWidth();
  }

  updateWidth = () => {
    this.setState({ width: window.innerWidth }, () => {
      if (this.fireAdScript() === true) {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    });
  };

  fireAdScript = () => {
    // use this to avoid calling window.adsbygoogle.push twice when there's only 1 <ins> present. if you don't,
    // google will throw error: 'All ins elements in the … with class=adsbygoogle already have ads in them.'
    if (
      (this.props.position == "bottom" && this.state.width > 1092) ||
      (this.props.position == "bottom" && this.state.width < 577 && this.state.width > 0) ||
      (this.props.position == "right" && this.state.width > 576)
    ) {
      return true;
    }
  };

  createAdInstance(adScript) {
    return { __html: adScript };
  }

  detectAdBlock = () => {
    let detectedAbBlock = AdBlock.isDetected();
    this.setState({ detectedAbBlock });
  };

  renderAds = (fallbackImage, adsType, isDonator) => {
    if (this.state.detectedAdBlock) {
      return (
        <div className={"mainos-placeholder  " + (isDonator ? "hidden" : "")}>
          <img src={fallbackImage} />
        </div>
      );
    } else {
      return (
        <div ref="advertContainer" className="mainos-placeholder">
          <div dangerouslySetInnerHTML={this.createAdInstance(adsType)}></div>
        </div>
      );
    }
  };

  render() {
    const { position, isDonator } = this.props;

    if (position == "right" && this.state.width > 576) {
      return (
        <div className="mainos-container--small">
          {this.renderAds(this.props.fallBackImage, this.props.smallAd, isDonator)}
        </div>
      );
    } else if (position == "bottom") {
      if (this.state.width > 1092) {
        return (
          <div className="mainos-container--large">
            <div className="mainos-placeholder">
              <div dangerouslySetInnerHTML={this.createAdInstance(this.props.bottomAd)}></div>
            </div>
          </div>
        );
      } else if (this.state.width < 577 && this.state.width > 0) {
        return (
          <div className="mainos-container--small">
            {this.renderAds(this.props.fallBackImage, this.props.mobileAd, isDonator)}
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
