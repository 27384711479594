import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import ConditionalWrapper from "../../components/ConditionalWrapper";
import { withInnerWidth } from "../../components/shared";
import { CDN_URL } from "../../components/shared/CdnUrl";
import Category from "../FAQ/Category";
import { childrensSongs } from "../FAQ/data/faqs";
import { savedLessonIds } from "../../components/shared/session/sessionSelectors";
import { createLessonItem, deleteLessonItem } from "../../components/shared/session/sessionEffects";
import { VerifiedIcon, MusicNoteIcon, ThumbsIcon, SnowflakeIcon, DialogIcon } from "./Icons.jsx";
import Product from "./Product.jsx";
import LessonCard from "./LessonCard.jsx";
import { stripeLogo } from "../../components/payments/PaymentUtils";
import { StripeProvider, Elements } from "react-stripe-elements";
import { RECAPTCHA_KEY, STRIPE_SALES_PUBLISHABLE_KEY } from "../../payments/PaymentConstants";
import CheckoutForm from "../../components/CheckoutForm";
import LoginPrompt from "../../components/auth/LoginPrompt";

const PRODUCTS = [
  {
    title: "101 Children’s Songs for Parents - Ukulele Edition",
    price: "9.99 USD",
    image: `${CDN_URL}/images/pages/childrens-songs/101-childrens-songs-for-parents-ukulele-edition-2.png`,
    buyBtnTitle: "buy e-book",
    buyBtnClass: "blue",
    amount: "9.99",
    reference: "101childsongsukulele",
    downloadUrl: `${CDN_URL}/products/JustinGuitar-101-Children-Songs-On-Ukulele.pdf`,
  },
  {
    title: "101 Children’s Songs for Parents - Guitar Edition",
    price: "9.99 USD",
    image: `${CDN_URL}/images/pages/childrens-songs/101-childrens-songs-for-parents-guitar-edition-2.png`,
    buyBtnTitle: "buy e-book",
    buyBtnClass: "pink",
    amount: "9.99",
    reference: "101childsongsguitar",
    downloadUrl: `${CDN_URL}/products/JustinGuitar-101-Children-Songs-On-Guitar.pdf`,
  },
  {
    title: "101 Children’s Songs for Parents - Bundle",
    price: "17.99 USD",
    image: `${CDN_URL}/images/pages/childrens-songs/101-childrens-songs-for-parents-bundle-2.png`,
    buyBtnTitle: "buy e-books bundle",
    buyBtnClass: "gradient",
    amount: "17.99",
    reference: "101childsongsbundle",
    downloadUrl: {
      ukulele: `${CDN_URL}/products/JustinGuitar-101-Children-Songs-On-Ukulele.pdf`,
      guitar: `${CDN_URL}/products/JustinGuitar-101-Children-Songs-On-Guitar.pdf`,
    },
  },
];

const ChildrensSongsRoot = ({
  ukuleleLessons,
  guitarLessons,
  faqs,
  currentUser,
  token,
  savedLessonIds,
  createLessonItem,
  deleteLessonItem,
  width,
}) => {
  const [purchasedProductReference, setPurchasedProductReference] = useState(null);

  const getProducts = () => {
    return PRODUCTS.map((product) => {
      product.hasPurchased =
        currentUser &&
        (currentUser.included.find(
          (item) =>
            item.type === "userPurchase" && item.attributes.purchaseReference === product.reference
        ) ||
          product.reference == purchasedProductReference);

      return product;
    });
  };

  const [showGuitarVariant, setShowGuitarVariant] = useState(false);
  const [productToBuy, setProductToBuy] = useState(null);
  const [showPurchaseSuccess, setShowPurchaseSuccess] = useState(false);
  const [products, setProducts] = useState(getProducts());

  useEffect(() => {
    const hash = location.hash.replace("#", "");

    const foundProduct = PRODUCTS.find((product) => product.reference === hash);

    if (foundProduct) {
      buyProduct(foundProduct);
    }
  }, []);

  const toggleBooks = () => {
    setShowGuitarVariant(!showGuitarVariant);
  };

  const backToBooks = () => {
    setProductToBuy(null);
    setShowPurchaseSuccess(false);
    setProducts(getProducts());
  };

  const toggleSave = (event, lesson) => {
    event.stopPropagation();

    if (currentUser) {
      if (savedLessonIds.includes(parseInt(lesson.id))) {
        deleteLessonItem({
          lessonId: lesson.id,
          status: "saved",
        });
      } else {
        createLessonItem({
          lessonId: lesson.id,
          status: "saved",
        });
      }
    }
  };

  const buyProduct = (product) => {
    setProductToBuy(product);
    location.hash = product.reference;
  };

  const onPurchaseSuccess = () => {
    setShowPurchaseSuccess(true);
    setPurchasedProductReference(productToBuy.reference);
  };

  return (
    <div className="childrens-songs container">
      <div className="header">
        <div className="breadcrumb">
          <a href="/store">back to store</a>
        </div>
        <h1>
          <img src={`${CDN_URL}/images/pages/childrens-songs/101.png`} alt="101" />
          children's songs <span>on ukulele & guitar</span>
        </h1>
      </div>
      <div className="section">
        <h2 className="title" id="purchase">
          Buy my children’s song ebooks
        </h2>
        {!productToBuy ? (
          <div className="products">
            <ConditionalWrapper
              condition={width <= 1200}
              wrapper={(children) => (
                <Slider
                  {...{
                    dots: true,
                    infinite: true,
                    slidesToShow: width > 992 ? 2 : 1,
                    slidesToScroll: 1,
                  }}
                >
                  {children}
                </Slider>
              )}
            >
              {products.map((product, idx) => (
                <Product key={idx} product={product} buyProduct={buyProduct} />
              ))}
            </ConditionalWrapper>
          </div>
        ) : showPurchaseSuccess ? (
          <div className="product-purchase payment-success">
            <button className="back-btn" onClick={() => backToBooks()}>
              {"<"} Back to eBooks
            </button>
            <h3>THANK YOU FOR your purchase</h3>
            <p className="info">You can now download 101 Children’s Songs - Ukulele Edition.</p>
            <p className="info">
              To find your receipt visit your dashboard’s <strong>ACCOUNT</strong> then click the
              tab <strong>‘Purchases’</strong>.
            </p>
            <img src={productToBuy.image} alt={productToBuy.title} />
            {productToBuy.reference === "101childsongsbundle" ? (
              <>
                <button
                  className="button button--primary ukulele"
                  onClick={() => window.open(productToBuy.downloadUrl.ukulele, "_blank")}
                >
                  download ukulele e-book
                </button>
                <button
                  className="button button--primary guitar"
                  onClick={() => window.open(productToBuy.downloadUrl.guitar, "_blank")}
                >
                  download guitar e-book
                </button>
              </>
            ) : (
              <button
                className="button button--primary"
                onClick={() => window.open(productToBuy.downloadUrl, "_blank")}
              >
                download e-book
              </button>
            )}
          </div>
        ) : (
          <div className={`product-purchase${currentUser ? " payment" : " login"}`}>
            <button className="back-btn" onClick={() => backToBooks()}>
              {"<"} Back to eBooks
            </button>
            <h3>{productToBuy.title}</h3>
            <p className="price-tag">{productToBuy.price}</p>
            <img src={productToBuy.image} alt={productToBuy.title} />
            {currentUser ? (
              <>
                <p className="info">Pay securely</p>
                <StripeProvider apiKey={STRIPE_SALES_PUBLISHABLE_KEY}>
                  <div className="payments__stripe">
                    <Elements
                      fonts={[
                        {
                          cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:400",
                        },
                      ]}
                    >
                      <CheckoutForm
                        price={productToBuy.amount}
                        currency="USD"
                        reference={productToBuy.reference}
                        recaptchaKey={RECAPTCHA_KEY}
                        purchaseType="sales"
                        subscription={false}
                        userEmail={currentUser ? currentUser.data.attributes.email : null}
                        successCallback={onPurchaseSuccess}
                        info={`By clicking “Agree & Subscribe”, you agree to not sell, rent, lease, distribute, broadcast, or sublicense 101 Children’s Songs on Guitar or Ukulele, the Bundle or any portion of it to any third party.`}
                        splitInputs={true}
                        buttonText="agree & pay"
                      />
                    </Elements>
                  </div>
                </StripeProvider>
                <div className="payments__logo">{stripeLogo()}</div>
              </>
            ) : (
              <>
                <p className="info">First log in to your JustinGuitar account.</p>
                <LoginPrompt token={token} />
              </>
            )}
          </div>
        )}
      </div>
      <div className="section">
        <h2 className="title">why play children's songs?</h2>
        <div className="verified-badges">
          <div>
            <VerifiedIcon />
            Create special memories with loved children!
          </div>
          <div>
            <VerifiedIcon />
            Improve your cognitive functions.
          </div>
          <div>
            <VerifiedIcon />
            Relieve stress and anxiety!
          </div>
        </div>
      </div>
      <div className="section">
        <h2 className="title">take a look inside</h2>
        <div className="slider-toggle slider-toggle--songs">
          <div className="float-left">
            <input
              type="checkbox"
              id="endorsement-toggle"
              checked={showGuitarVariant}
              onChange={() => toggleBooks()}
            />
            <label htmlFor="endorsement-toggle">
              <div
                className={`switch switch--${
                  showGuitarVariant ? "guitar-edition" : "ukulele-edition"
                }`}
                data-checked={"Guitar Edition"}
                data-unchecked={"Ukulele Edition"}
              />
            </label>
          </div>
        </div>
        <div className="what-you-will-learn">
          <div className="image">
            <img
              src={
                showGuitarVariant
                  ? `${CDN_URL}/images/pages/childrens-songs/101-childrens-songs-for-parents-guitar-edition-2.png`
                  : `${CDN_URL}/images/pages/childrens-songs/101-childrens-songs-for-parents-ukulele-edition-2.png`
              }
              alt=""
            />
          </div>
          <div className={`info${showGuitarVariant ? " guitar-edition" : " ukulele-edition"}`}>
            <h3 className="header2 uppercase">what you will learn</h3>
            <ul className="learn-icons">
              <li>
                <div className="description-icon">
                  <MusicNoteIcon />
                </div>
                <p>101 Timeless Children’s Songs</p>
              </li>
              <li>
                <div className="description-icon">
                  <ThumbsIcon />
                </div>
                <p>Easy chords with diagrams</p>
              </li>
              <li>
                <div className="description-icon">
                  <SnowflakeIcon />
                </div>
                <p>Christmas Favorites</p>
              </li>
              <li>
                <div className="description-icon">
                  <DialogIcon />
                </div>
                <p>Exclusive playing tips!</p>
              </li>
            </ul>
            {showGuitarVariant ? (
              <>
                <p>Playing guitar to children is magical and loads of fun!</p>
                <p>
                  They’ll dance, sing, and want to play it too. Learning the songs in this book only
                  requires easy open chords, and even complete beginners can get through them.{" "}
                  <strong className="pink">Use the free lessons below to get you started!</strong>
                </p>
              </>
            ) : (
              <>
                <p>
                  The ukulele itself is a perfect instrument, small, easy to play, portable and
                  there’s no pressure to do anything fancy - <strong>it’s all about fun!</strong>
                </p>
                <p>
                  Learning to play the basic four chords needed to play nearly all the songs in this
                  book is very easy for an adult and the{" "}
                  <strong className="blue">free lessons below will help you get started!</strong>
                </p>
              </>
            )}
            <button
              className="blue"
              onClick={() => {
                if (showGuitarVariant) {
                  buyProduct(PRODUCTS[1]);
                } else {
                  buyProduct(PRODUCTS[0]);
                }

                window.location.href = "#purchase";
              }}
            >
              buy e-book
            </button>
          </div>
        </div>
        <h3 className="title">to help you get started</h3>
        <div className={`lessons${showGuitarVariant ? " guitar-edition" : " ukulele-edition"}`}>
          <ConditionalWrapper
            condition={width <= 1200}
            wrapper={(children) => (
              <Slider
                {...{
                  dots: true,
                  infinite: true,
                  slidesToShow: width > 992 ? 5 : width > 768 ? 3 : width > 576 ? 2 : 1,
                  slidesToScroll: 1,
                }}
              >
                {children}
              </Slider>
            )}
          >
            {showGuitarVariant
              ? guitarLessons.map((lesson, idx) => (
                  <LessonCard
                    key={idx}
                    lesson={lesson}
                    currentUser={currentUser}
                    savedLessonIds={savedLessonIds}
                    toggleSave={toggleSave}
                  />
                ))
              : ukuleleLessons.map((lesson, idx) => (
                  <LessonCard
                    key={idx}
                    lesson={lesson}
                    currentUser={currentUser}
                    savedLessonIds={savedLessonIds}
                    toggleSave={toggleSave}
                  />
                ))}
          </ConditionalWrapper>
        </div>
      </div>
      <div className="section">
        <div className={`review${showGuitarVariant ? " guitar-edition" : " ukulele-edition"}`}>
          <img
            src={
              width <= 768
                ? `${CDN_URL}/images/pages/childrens-songs/cover-sm.png`
                : `${CDN_URL}/images/pages/childrens-songs/cover.png`
            }
            alt="review cover"
          />
          <div className="text">
            <p>
              Instantly recognisable songs for kids. Easy common chords. Great layout and engaging
              pix! Love the song facts.Winner, winner chicken dinner!
            </p>
            <span>MATTY - Review from Amazon</span>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="faq">
          <Category
            faqs={faqs}
            showHeader={false}
            categoryData={childrensSongs}
            useUrlRouting={false}
            showBreadcrumbs={false}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.currentUser,
    token: state.session.token,
    ukuleleLessons: state.entity.ukuleleLessons,
    guitarLessons: state.entity.guitarLessons,
    faqs: state.entity.faqs,
    savedLessonIds: savedLessonIds(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  createLessonItem: (lessonId, status) => dispatch(createLessonItem(lessonId, status)),
  deleteLessonItem: (lessonId, status) => dispatch(deleteLessonItem(lessonId, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withInnerWidth(ChildrensSongsRoot));
