import React from "react";

const T_AND_C = [
  {
    title: "Terms and Conditions",
    items: [
      "This Competition is open to persons aged 18 years or over. Proof of age and \
      identity may be required. Entry to the Competition is free.",
      "In order to enter the Competition users are required to register at justinguitar.com\
       (the “Website”). Only one entry per email address is allowed.",
      "I will use each winner’s, and each entrant’s personal details for the purpose of\
      administering this Competition. I will not use your personal details to contact you about\
      products and services that may be of interest to you.",
    ],
  },
  {
    title: "The Competition",
    items: ["The prize winners will be determined through a random number generator website."],
  },
  {
    title: "Winners",
    items: [
      "Winners will be notified by email to the address associated with their JustinGuitar account.",
      "A full list of winners by username will be available in the results sections of this page.",
    ],
  },
];
const TermsAndConditions = () => {
  return (
    <div className="competition__terms">
      {T_AND_C.map((t) => {
        return (
          <div key={t.title}>
            <strong>{t.title}</strong>
            <ul>
              {t.items.map((item) => {
                return <li key={item}>{item}</li>;
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default TermsAndConditions;
