import React from "react";
import UpdateItem from "../Actions/UpdateItem";
import ArchiveItem from "../Actions/ArchiveItem";
import DisabledItem from "../Actions/DisabledItem";
import CopyItem from "../Actions/CopyItem";

export default class PracticeItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formToShow: null,
      itemIdToEdit: null,
      isSubmitting: false,
    };
  }

  closeForm = () => {
    this.setState({ responseText: null, formToShow: null, itemIdToEdit: null, errorText: null });
  };

  handleUpdatedItem = (item, errorMessage = null) => {
    if (item === null) {
      let error = errorMessage ? errorMessage : "There was a problem updating this item.";
      this.setState({ errorText: error, isSubmitting: false });
      setTimeout(() => this.closeForm(), 5000);
    } else {
      this.props.handleUpdatedItem(item);
      this.setState({ responseText: "Successfully updated item!", isSubmitting: false });
      setTimeout(() => this.closeForm(), 2000);
    }
  };

  handleArchivedItem = (item) => {
    if (item === null) {
      this.setState({ errorText: "There was a problem archiving this item." });
      setTimeout(() => this.closeForm(), 2000);
    } else {
      this.closeForm();
      this.props.handleArchivedItem(item);
    }
  };

  handleCopiedItem = (item) => {
    if (item === null) {
      this.setState({ errorText: "There was a problem copying this item." });
      setTimeout(() => this.closeForm(), 2000);
    } else {
      this.closeForm();
      this.props.handleCopiedItem(item);
    }
  };

  responseMessage = () => {
    if (this.state.responseText) {
      return <p className="success">{this.state.responseText}</p>;
    } else if (this.state.errorText) {
      return <p className="has-errors">{this.state.errorText}</p>;
    }
  };

  toggleForm = (formType, itemId) => {
    let { formToShow, itemIdToEdit } = this.state;
    if (itemIdToEdit === itemId && formToShow === formType) {
      this.setState({ formToShow: null, itemIdToEdit: null, openItemId: null });
    } else {
      this.setState({ formToShow: formType, itemIdToEdit: itemId, openItemId: null });
    }
  };

  setSubmitting = (isSubmitting) => {
    this.setState({ isSubmitting: isSubmitting });
  };

  showForm = (item) => {
    if (item.id === this.state.itemIdToEdit) {
      if (this.state.formToShow === "edit") {
        return (
          <div key={item.id} className="practice-items__form">
            {this.responseMessage()}
            <UpdateItem
              item={item}
              setSubmitting={this.setSubmitting}
              isSubmitting={this.state.isSubmitting}
              handleUpdatedItem={this.handleUpdatedItem}
              closeForm={this.closeForm}
            />
          </div>
        );
      } else if (this.state.formToShow === "archive") {
        return (
          <div key={item.id} className="practice-items__form">
            {this.responseMessage()}
            <ArchiveItem
              item={item}
              handleArchivedItem={this.handleArchivedItem}
              closeForm={this.closeForm}
            />
          </div>
        );
      } else if (this.state.formToShow === "copy") {
        return (
          <div key={item.id} className="practice-items__form">
            {this.responseMessage()}
            <CopyItem
              item={item}
              handleCopiedItem={this.handleCopiedItem}
              closeForm={this.closeForm}
            />
          </div>
        );
      }
    }
  };

  toggleDetails = (itemId) => {
    this.setState({ openItemId: itemId });
  };

  caretToDisplay = (itemId) => {
    if (this.state.openItemId === itemId && this.isEditing(itemId) != true) {
      return <i className="icon icon-minus" onClick={() => this.toggleDetails(null)}></i>;
    } else if (this.isEditing(itemId)) {
      return null;
    } else {
      return <i className="icon icon-plus" onClick={() => this.toggleDetails(itemId)}></i>;
    }
  };

  isEditing = (itemId) => {
    return this.state.itemIdToEdit === itemId ? true : false;
  };

  itemTime = (item) => {
    if (item.duration === 1) {
      return "1 min";
    } else {
      return `${item.duration} mins`;
    }
  };

  itemTitleAndOptions = (item) => {
    if (this.state.formToShow === "edit" && this.isEditing(item.id)) {
      return this.showForm(item);
    } else {
      return (
        <div
          className={
            "routine__item " + (this.isEditing(item.id) ? "practice-items__item--editing" : "")
          }
          key={item.id}
        >
          <div>
            {this.caretToDisplay(item.id)}
            <span className="routine__title"> {`${item.title} (${this.itemTime(item)})`} </span>
          </div>

          <div className="routine__item__options">
            <button
              type="button"
              title="Edit Item"
              alt="Edit Item"
              className="practice__icon practice__icon--edit"
              onClick={() => this.toggleForm("edit", item.id)}
            >
              <img src="https://jtgt-static.b-cdn.net/images/practice-routine/edit-button.svg" />
            </button>
            <button
              type="button"
              title="Create a Copy of This Item"
              alt="Create a Copy of This Item"
              className="practice__icon practice__icon--copy"
              onClick={() => this.toggleForm("copy", item.id)}
            >
              <i className="icon icon-copy"></i>
            </button>
            <button
              type="button"
              title="Archive Item"
              alt="Archive Item"
              className="practice__icon"
              onClick={() => this.toggleForm("archive", item.id)}
            >
              <img src="https://jtgt-static.b-cdn.net/images/practice-routine/delete-button.svg" />
            </button>
          </div>
          {this.showForm(item)}
          {this.disabledItemForm(item)}
        </div>
      );
    }
  };

  disabledItemForm = (item) => {
    if (this.state.openItemId === item.id) {
      return <DisabledItem item={item} showEditIcons={true} />;
    } else {
      return null;
    }
  };

  alphabeticalActiveItems = () => {
    let items = [...this.props.items];
    return items
      .filter((i) => i.status === "active")
      .sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
  };

  render() {
    if (this.props.items) {
      return (
        <div>{this.alphabeticalActiveItems().map((item) => this.itemTitleAndOptions(item))}</div>
      );
    } else {
      return null;
    }
  }
}
