import { CDN_URL } from "../../components/shared/CdnUrl";

export const GRADES = [
  {
    grade: 1,
    color: "white",
    image: "store-music-theory-grade1.png",
    highlights: [
      "The Note Circle",
      "All About Sharps, Flats, and Octaves",
      "The Open String Notes",
    ],
    description:
      "Our Music Theory journey starts easy! You will learn important concepts to get started in a magical adventure unveiling your guitar. If you haven't already, you will memorize the open string note names and how we call them.<br /><br />You will become familiar with the Note Circle and tones, semitones, sharps, and flats. Most importantly, you will understand how this knowledge translates into your guitar fretboard!",
  },
  {
    grade: 2,
    color: "#F4D059",
    image: "store-music-theory-grade2.png",
    highlights: [
      "The Open String Notes",
      "Chords vs. Scales vs. Arpeggios",
      "All About Chord & Scale diagrams",
    ],
    description:
      "Time to learn the difference between chords, scales, and arpeggios. We will also learn how to read chord boxes and fretboard diagrams for scales, discussing some best practices we should all be aware of.<br /><br />Before we move on to Grade 3, we want some ideas to bounce around in your subconscious, so I invite you to a quick exercise. We will play a few chords, try to answer a few questions, and let it all soak; that will help you start exposing the secrets of your guitar!",
  },
  {
    grade: 3,
    color: "#FC9644",
    image: "store-music-theory-grade3.png",
    highlights: ["Major Scale Theory", "Key Signatures", "Power Chord Theory"],
    description:
      "We're almost ready to improvise! But before we do so, let's wrap up our Beginner Grades by learning important fundamentals about the Major Scale. We'll learn the Big Six Essential Notes and dive deeper into Key Signatures.<br /><br />We'll also investigate Power Chords - are they electric or really strong? They're our friends who can rock and are not Major or Minor players! Yes, exciting things are ahead. :)",
  },
  {
    grade: 4,
    color: "#48CFAE",
    image: "store-music-theory-grade4.png",
    highlights: [
      "Solve the Mystery of Improvising!",
      "Chords, Scales, and Keys",
      "Note Study & Circle of 5th",
    ],
    description:
      "Learn how triads work on the guitar, the relationship between chords and scales, and how they come together in keys. You will know how to easily identify chord progressions and use your new skills to play more freely and improvise!<br /><br />Improvising can be intimidating, but we'll keep taking it slowly and breaking it all down. Once it all clicks - it's magical. You'll suddenly be able to improvise in circumstances that you used to find difficult, if not impossible. And without overthinking. :)",
  },
  {
    grade: 5,
    color: "#45AAF2",
    image: "store-music-theory-grade5.png",
    highlights: [
      "Intervals & Ear Development",
      "Pentatonics - and why so many Minors?",
      "Quadads, Harmonic Analysis, Modes",
    ],
    description:
      "Grade 5 will be crucial for your musicianship. It's time to develop your ears so you can hit the right notes in any situation and always sound good. We will learn about Diatonic and Chromatic Intervals and keep studying the Pentatonic Scales.<br /><br />Once you understand how keys work, it's time to look at the note function over the chord you're playing! Are you ready? :) I bet you will be!",
  },
  {
    grade: 6,
    color: "#A65DEA",
    image: "store-music-theory-grade6.png",
    highlights: ["Upper Chord Extensions", "The CAGED System", "Finding Your Chords"],
    description:
      "Howdy guitar friend, we're almost reaching the Advanced lessons! To wrap up our Intermediate Grades, we will understand the theory behind upper chord extensions and other chord alterations. And we'll explore the CAGED System, specifically the E and A Shapes.<br /><br />By the end of our Grade 6, you will know how to find any chords you want and name them - if you wish. These lessons have all you need to develop your guitar chords knowledge, and get even more intimate with your fretboard.",
  },
  {
    grade: 7,
    color: "#FC5C66",
    image: "store-music-theory-grade7.png",
    highlights: ["The CAGED System", "Advanced Chord Grips", "Major Scale Modes"],
    description:
      "You've made it to the Advanced Grade, so get ready to encounter some uncommon chord grips - some are tricky to play. But don't worry about that - I won't say the Advanced lessons are easy, but we're keeping it as practical and inspiring as possible!<br /><br />We'll wrap up our studies by learning to use Major Scale Modes to level up our guitar playing and open even more possibilities. Wow! You will finally have understood everything you're doing with your guitar once and for all. Bravo!",
  },
];

export const TESTIMONIALS = [
  {
    headerImage: `${CDN_URL}/images/pages/music-theory-course/Richard.jpg`,
    location: "",
    studentSince: "JustinGuitar Official Guide & Approved Teacher",
    name: "Richard_close2u",
    body: "“The concept we call <i>music theory</i> could well be named <i>music knowledge</i>! Justin's Practical Music Theory Course is the perfect introduction to a most rewarding pursuit and is a wonderful complement to your hands-on guitar learning with JustinGuitar.”",
  },
  {
    headerImage: `${CDN_URL}/images/pages/music-theory-course/Dave.jpg`,
    location: "US",
    studentSince: "JustinGuitar student since 2020",
    name: "DAVE (Dave999)",
    body: `“If you know a few chords and a little strumming, Theory is a great thing to start. After going through the first 2 grades, I understood things better. So even if you are just beginning, I would strongly recommend starting Music Theory early.”`,
  },
  {
    headerImage: `${CDN_URL}/images/pages/music-theory-course/Adrian.jpg`,
    location: "UK",
    studentSince: "JustinGuitar student since 2020",
    name: "ADRIAN (adi_mrok)",
    body: `“I dare to say this course is the best darn thing out there on the world wide web! It is super well explained and a bargain cost-wise.”`,
  },
  {
    headerImage: `${CDN_URL}/images/pages/music-theory-course/Jesse.jpg`,
    location: "US",
    studentSince: "JustinGuitar Student",
    name: "JESSE (Fncanuk)",
    body: `“I got lucky and found Justin after only a few months of learning guitar. Definitely seems like Justin gave us a very efficient path to understanding music theory and learning guitar.”`,
  },
  {
    headerImage: `${CDN_URL}/images/pages/music-theory-course/Dahn7.jpg`,
    location: "",
    studentSince: "JustinGuitar Student",
    name: "Dahn7",
    body: `“Justin has succeeded in conveying the importance understanding music both theoretically and through your ears while maintaining a simple and accessible approach to them.”`,
  },
  {
    headerImage: `${CDN_URL}/images/pages/music-theory-course/Ross-Edwards.jpeg`,
    location: "",
    studentSince: "JustinGuitar Student since 2006",
    name: "Ross Edwards",
    body: `“I simply can’t imagine playing guitar without this knowledge. By transforming essential musical concepts into ultra-practical exercises, this course opens up the entire fretboard and helps you become a creative, empowered guitarist. Huge thanks!”`,
  },
];
