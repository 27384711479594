import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { BASE_FONTS } from "../components/new-payments/consts";

const withStripeElements = (ChildComponent) => {
  return (props) => {
    return (
      <Elements stripe={props.stripe} fonts={BASE_FONTS}>
        <ChildComponent {...props} />
      </Elements>
    );
  };
};

export default withStripeElements;
