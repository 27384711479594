import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSongbook,
  selectCurrentSongbookId,
  selectSongOptions,
  selectSongOptionsMeta,
  selectOrderedSongsForSongbook,
  selectSongOptionsLoading,
  selectSongOptionsQuery,
} from "../store/dashboardSelectors";
import { InfiniteScroll } from "../../components/shared/InfiniteScroll";
import { CDN_URL } from "../../components/shared/CdnUrl";
import { storeNewSongbookSongs } from "./utils";
import { deleteSongbookSong, querySongOptions } from "./songbookApi";
import { setSongOptionsPage, setSongOptionsQuery } from "../store/dashboardStore";
import Loading from "../../components/shared/Loading";

export default function () {
  const dispatch = useDispatch();
  const songbook = useSelector(selectCurrentSongbook);
  const songbookCurrentSongs = useSelector(selectOrderedSongsForSongbook)(songbook);

  const addSong = (song) =>
    storeNewSongbookSongs(songbook, songbookCurrentSongs, [song.id], dispatch);

  const removeSong = (song) => dispatch(deleteSongbookSong({ songbook, song }));

  return (
    <div className="select-songs">
      <SongOptionListing addSong={addSong} removeSong={removeSong} />
    </div>
  );
}

const FIRST_PAGE = 1;

const SongOptionListing = ({ addSong, removeSong }) => {
  const dispatch = useDispatch();
  const query = useSelector(selectSongOptionsQuery);
  const { page, totalCount } = useSelector(selectSongOptionsMeta);
  const songs = useSelector(selectSongOptions);
  const loading = useSelector(selectSongOptionsLoading);
  const songbookId = useSelector(selectCurrentSongbookId);

  const inputRef = React.useRef();

  const setPage = (newPage) => {
    dispatch(setSongOptionsPage(newPage));
    dispatch(querySongOptions());
  };
  const setQuery = (newQuery) => {
    dispatch(setSongOptionsQuery(newQuery));
    setPage(FIRST_PAGE);
  };
  const clearQuery = () => {
    setQuery("");
  };

  return (
    <InfiniteScroll
      metadata={{
        page,
        perPage: 12,
        totalCount,
      }}
      fetchPage={setPage}
      loading={loading}
      enableInfiniteScroll={true}
    >
      <div className="songbook-song-selector">
        <div className="query-bar">
          <div className="query-bar__inner">
            <i className="icon icon-search-thin" />
            <input
              ref={inputRef}
              type="text"
              value={query}
              onChange={({ target }) => setQuery(target.value)}
            />
            <i className="icon icon-cross" onClick={clearQuery} />
          </div>
        </div>

        {loading && songs.length === 0 ? (
          <Loading />
        ) : (
          songs.map((song) => (
            <SongOption key={song.id} song={song} addSong={addSong} removeSong={removeSong} />
          ))
        )}
      </div>
    </InfiniteScroll>
  );
};

const SongOption = ({ song, addSong, removeSong }) => {
  const {
    attributes: { title, originalArtist, artistImageUid, gradeId, isSelected },
  } = song;
  const coercedGradeId = gradeId == null || gradeId === 10 ? 0 : gradeId;

  return (
    <div className="song-option">
      <div className={`color-grade-${coercedGradeId}`}>{coercedGradeId}</div>
      <img src={`${CDN_URL}/system/dragonfly/production/${artistImageUid}`} alt={title} />
      <div className="artist-and-title">
        <div className="title">{title}</div>
        <div className="artist">{originalArtist}</div>
      </div>
      {isSelected ? (
        <RemoveSongButton song={song} removeSong={removeSong} />
      ) : (
        <AddSongButton song={song} addSong={addSong} />
      )}
    </div>
  );
};

const AddSongButton = ({ song, addSong }) => (
  <div className="clickable song_option__button song_option__button--add">
    <PlusIcon onClick={() => addSong(song)} />
  </div>
);
const RemoveSongButton = ({ song, removeSong }) => (
  <div className="clickable song_option__button song_option__button--remove">
    <MinusIcon onClick={() => removeSong(song)} />
  </div>
);

const PlusIcon = ({ onClick = () => {} }) => (
  <svg
    onClick={onClick}
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 11.8V26.2M11.8 19H26.2M37 19C37 28.9411 28.9411 37 19 37C9.05887 37 1 28.9411 1 19C1 9.05887 9.05887 1 19 1C28.9411 1 37 9.05887 37 19Z"
      stroke="#406e8e"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const MinusIcon = ({ onClick = () => {} }) => (
  <svg
    onClick={onClick}
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8 19H26.2M37 19C37 28.9411 28.9411 37 19 37C9.05887 37 1 28.9411 1 19C1 9.05887 9.05887 1 19 1C28.9411 1 37 9.05887 37 19Z"
      stroke="#406e8e"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
