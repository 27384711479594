import React, { useEffect, useState } from "react";
import SimpleCard from "./SimpleCard";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import { USER_ITEM_STATUSES } from "../../lesson/swoosh/consts";
import { openAlert } from "../../components/shared/ui/uiSlice";
import Snackbar from "../../components/shared/ui/Snackbar";
import Loading from "../../components/shared/Loading";

const IndividualPlaygroundPage = ({
  bootstrapSize,
  parentGroupId,
  category,
  currentUser,
  createLessonItem,
  deleteLessonItem,
}) => {
  const [group, setGroup] = useState(null);
  const [savedLessonIds, setSavedLessonIds] = useState([]);

  function fetchGroup() {
    axiosWithCSRF()
      .get(`/classes/${parentGroupId}/modules/${category.id}`)
      .then((response) => {
        setGroup(response.data.data);
      });
  }

  function fetchUserItems() {
    if (currentUser) {
      axiosWithCSRF()
        .get(`/user_items/by_parent_group?parent_group_id=${parentGroupId}`)
        .then((response) => {
          const { userItems } = response.data;
          const savedIds = userItems
            .filter(
              (item) =>
                item.attributes.itemableType === "Group" &&
                item.attributes.itemStatus === USER_ITEM_STATUSES.saved
            )
            .flatMap((item) => item.attributes.nestedIds);

          setSavedLessonIds(savedIds);
        });
    }
  }

  useEffect(() => {
    fetchGroup();
    fetchUserItems();
  }, []);

  // TODO: this user item stuff needs to be unified (lessons use it too)
  function saveItem(itemId) {
    createLessonItem({ lessonId: itemId, status: USER_ITEM_STATUSES.saved })
      .then(() => {
        const newIds = [...savedLessonIds, parseInt(itemId)];
        setSavedLessonIds(newIds);
        openAlert({
          message: `Added this to your saved lessons!`,
          severity: "success",
        });
      })
      .catch(() => {
        openAlert({
          message: `There was a problem adding this to your saved lessons.`,
          severity: "error",
        });
      });
  }

  function removeItem(itemId) {
    deleteLessonItem({ lessonId: itemId, status: USER_ITEM_STATUSES.saved })
      .then(() => {
        setSavedLessonIds(savedLessonIds.filter((id) => id !== parseInt(itemId)));
        openAlert({
          message: `Removed this from your saved lessons!`,
          severity: "success",
        });
      })
      .catch(() => {
        openAlert({
          message: `There was a problem removing this from your saved lessons.`,
          severity: "error",
        });
      });
  }

  function toggleSave(event, lesson) {
    event.stopPropagation();

    if (currentUser) {
      if (savedLessonIds.includes(parseInt(lesson.id))) {
        removeItem(lesson.id);
      } else {
        saveItem(lesson.id);
      }
    }
  }

  if (!group) return <Loading />;

  const { lessons, headerImage, title, slug } = group.attributes;
  const GROUPS_WITH_NO_TITLE = ["giveaways", "rut-busters"];
  return (
    <>
      <div className="category-header-bg" style={{ backgroundImage: `url(${headerImage})` }}>
        <div className="breadcrumbs">
          <a href={"/playground"}>Playground</a>
          <p className="separator">›</p>
          <p>{title}</p>
        </div>
        {GROUPS_WITH_NO_TITLE.includes(slug) === false && <h1>{title}</h1>}
      </div>
      <div className="playground__body">
        <div className="playground__body__cards">
          {lessons.length &&
            lessons.map((lesson, idx) => (
              <div key={idx} className="card-container category">
                <SimpleCard
                  data={lesson}
                  onNavigate={() => (window.location.href = `/guitar-lessons/${lesson.slug}`)}
                  onToggleSave={toggleSave}
                  hoverPlayOverlay={true}
                  currentUser={currentUser}
                  bootstrapSize={bootstrapSize}
                  isSaved={savedLessonIds.includes(lesson.id)}
                />
              </div>
            ))}
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default IndividualPlaygroundPage;
