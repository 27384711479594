import React from "react";

import Category from "../../FAQ/Category";
import { workshops } from "../../FAQ/data/faqs";

export const WorkshopsFaqs = ({ faqs }) => (
  <div className="faq" id="workshop-faq">
    <Category
      faqs={faqs}
      showHeader={false}
      categoryData={workshops}
      useUrlRouting={false}
      showBreadcrumbs={false}
    />
  </div>
);
