import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import Timeline from "./Timeline";
import { CDN_URL } from "../../components/shared/CdnUrl";

export default class TeachingTimelineSwoosh extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      data: [
        {
          year: "1983",
          videoUrl: "", // not used at the moment, as there are no videos being displayed
          imageUrl: `${CDN_URL}/redesign/about/timeline/JG-Timeline-1983.jpg`,
          title: "",
          description:
            "I started on the guitar pretty early. I was about the age of 9, and I used to play the Ukulele before that!",
        },
        {
          year: "1986",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/JG-Timeline-1986.jpg`,
          title: "",
          description:
            "Here's a pic of my first Rock band - with Luke, the drummer. I was around 12 years old! We loved jamming the Blues. :)",
        },
        {
          year: "1990",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/JG-Timeline-1990.jpg`,
          title: "",
          description:
            "I was a true punk back in the days, even had a Mohawk for a bit. Was all mixed in with the LA Sleaze scene, a very strange combo in Tasmania!",
        },
        {
          year: "1992",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/timeline-placeholder.png`,
          title: "",
          description:
            "Studying music with Alan Cato at Elizabeth College. At the time, I played in funk and rock bands, often five gigs a week! I was also teaching many private students at the Hobart College of Music.",
        },
        {
          year: "1994",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/timeline-placeholder.png`,
          title: "",
          description:
            "I began studying classical guitar at The Tasmanian Conservatorium of Music, and started teaching at the school I'd just left, Elizabeth College. Also started doing sessions and more writing and recording. Was practicing 8 hours a day, plus gigs!",
        },
        {
          year: "1996",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/timeline-placeholder.png`,
          title: "",
          description:
            "I landed a scholarship to The Guitar Institute in London, one of the world’s most prestigious schools. I started teaching there after graduating!",
        },
        {
          year: "1997",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/timeline-placeholder.png`,
          title: "",
          description:
            "Having run out of money I took a gig at Butlins Minehead 'Resort' in the house band. Played 6 nights a week, lots of sight reading and playing in different bands.",
        },
        {
          year: "1998",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/JG-Timeline-1998.jpg`,
          title: "",
          description:
            "I joined The Counterfeit Stones! According to Mick Jagger, that's the most famous Rolling Stones Tribute in England! I played over 1000 concerts with them, all over the world incl. The Albert Hall and the AJAX Arena!",
        },
        {
          year: "2003",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/JG-Timeline-2003.jpg`,
          title: "",
          description:
            "JustinGuitar first went live in July 2003 to promote my private lessons. I used to code it and add content to it when traveling around the world on tours.",
        },
        {
          year: "2005",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/JG-Timeline-2005.jpg`,
          title: "",
          description:
            "I left The Counterfeit Stone to join Katie Melua's band. We've traveled the world playing big theatres and arenas - including the incredible Live Earth concert in 2007. Also recorded at Abbey Road studios and got a platinum album award for  a song of mine she covered called Turn To Tell.",
        },
        {
          year: "2007",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/JG-Timeline-2007.jpg`,
          title: "",
          description:
            "Besides Katie Melua, I've played for Cathy Dennis, Carla Bruni, and many others! I seem to have landed the gig of working with awesome women!",
        },
        {
          year: "2009",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/timeline-placeholder.png`,
          title: "",
          description:
            "I quit touring and started working on the website full-time. It was clear that this was a more exciting adventure then touring as a sideman, which I'd been doing for a long time by this point.",
        },
        {
          year: "2012",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/JG-Timeline-2012.jpg`,
          title: "",
          description:
            "I founded the trip-hop band We Came As Strangers. We've released the albums Recipe For Adventure (2013), Shattered Matter (2014), and then Eyedom (2015). Then we all had kids and stuff and it got too busy!",
        },
        {
          year: "2013",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/JG-Timeline-2013.jpg`,
          title: "",
          description:
            "I had a great time touring to promote the JustinGuitar Beginner Songbook. In this picture, I was at the fantastic Musikmesse in Frankfurt.",
        },
        {
          year: "2015",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/timeline-placeholder.png`,
          title: "",
          description:
            "I started writing the Food For Thought column in the Guitar Techniques magazine. Years later, and I still love doing this gig!",
        },
        {
          year: "2016",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/timeline-placeholder.png`,
          title: "",
          description:
            "My first daughter Vivi was born! Life changed a lot - diapers, kid songs on the Ukulele, and no more long nails. But I made full-time parenting and guitar player work!",
        },
        {
          year: "2020",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/JG-Timeline-2020.jpg`,
          title: "",
          description:
            "The JustinGuitar team started to grow! I used to do most of the backstage work by myself, and now we're a growing team. ",
        },
        {
          year: "2021",
          videoUrl: "",
          imageUrl: `${CDN_URL}/redesign/about/timeline/JG-Timeline-2021-v2.jpeg`,
          title: "",
          description:
            "The website clocked 500,000 registered students! I'm grateful for every single one of them. ",
        },
      ],
    };

    this.arrowPrevious = React.createRef();
    this.arrowNext = React.createRef();
  }

  setActiveYear = (idx) => {
    this.resetArrows();
    this.setArrows(idx);

    this.setState({ activeIndex: parseInt(idx) });
  };

  resetArrows = () => {
    this.arrowPrevious.current.className = this.arrowPrevious.current.className.replace(
      " disabled",
      ""
    );
    this.arrowNext.current.className = this.arrowNext.current.className.replace(" disabled", "");
  };

  setArrows = (activeIndex) => {
    if (activeIndex <= 0) {
      if (!this.arrowPrevious.current.className.includes("disabled")) {
        this.arrowPrevious.current.className += " disabled";
      }
    }

    if (activeIndex >= this.state.data.length - 1) {
      if (!this.arrowNext.current.className.includes("disabled")) {
        this.arrowNext.current.className += " disabled";
      }
    }
  };

  previous = () => {
    this.resetArrows();

    let activeIndex = this.state.activeIndex - 1;
    if (activeIndex < 0) {
      activeIndex = 0;
      this.setArrows(activeIndex);
    }

    this.setState({ activeIndex });
  };

  next = () => {
    this.resetArrows();

    let activeIndex = this.state.activeIndex + 1;
    if (activeIndex > this.state.data.length - 1) {
      activeIndex = this.state.data.length - 1;
      this.setArrows(activeIndex);
    }

    this.setState({ activeIndex });
  };

  render() {
    return (
      <div className="teaching-timeline-swoosh">
        <Swoosh id="about-teaching-timeline" gradient="denim">
          <div className="teaching-timeline-swoosh__content">
            <h2 className="header2 header2--white uppercase text-center">Guitar timeline</h2>
            <div className="teaching-timeline-swoosh__content__info">
              <div className="teaching-timeline-swoosh__content__info__video">
                {/*<div className="aspect-ratio-16-9">*/}
                {/*  {this.state.data[this.state.activeIndex].videoUrl}*/}
                {/*</div>*/}
                <img
                  src={this.state.data[this.state.activeIndex].imageUrl}
                  alt={"JustinGuitar in " + this.state.data[this.state.activeIndex].year}
                />
              </div>
              <div className="teaching-timeline-swoosh__content__info__description">
                {this.state.data[this.state.activeIndex].year ? (
                  <p className="teaching-timeline-swoosh__content__info__description__year">
                    {this.state.data[this.state.activeIndex].year}
                  </p>
                ) : null}
                {this.state.data[this.state.activeIndex].title ? (
                  <p className="teaching-timeline-swoosh__content__info__description__title">
                    {this.state.data[this.state.activeIndex].title}
                  </p>
                ) : null}
                {this.state.data[this.state.activeIndex].description ? (
                  <p className="teaching-timeline-swoosh__content__info__description__description">
                    {this.state.data[this.state.activeIndex].description}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="teaching-timeline-swoosh__content__info__arrows">
              <i
                className="icon icon-angle-left disabled"
                onClick={this.previous}
                ref={this.arrowPrevious}
              ></i>
              <i className="icon icon-angle-right" onClick={this.next} ref={this.arrowNext}></i>
            </div>
            <Timeline
              data={this.state.data}
              activeIndex={this.state.activeIndex}
              setActiveYear={this.setActiveYear}
            />
          </div>
        </Swoosh>
      </div>
    );
  }
}
