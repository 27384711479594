import React from "react";
import CoursesByGrade from "./CoursesByGrade";
import CoursesBySkill from "./CoursesBySkill";
import { withInnerWidth } from "../components/shared";
import MusicTheoryBanner from "./MusicTheoryBanner";
import Swoosh from "../components/shared/Swoosh";
import { BootstrapSize, breakpoints } from "../components/shared/bootstrap-helpers";
import { CDN_URL } from "../components/shared/CdnUrl";

const swooshImages = {
  [BootstrapSize.xsDevice]: `${CDN_URL}/redesign/2020/lessons-page/guitar-sm.png`,
  [BootstrapSize.smDevice]: `${CDN_URL}/redesign/2020/lessons-page/guitar-xl.png`,
};

class LessonsOverview extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { width } = this.props;

    return (
      <div className="lessons guitar-courses">
        <Swoosh imgUrl={swooshImages}>
          <div className="container">
            <h1 className="header1">Guitar courses</h1>
            <p>
              Start learning now! All you have to do is choose a course below, and I’ll guide you
              through effective, fun, and well-structured lessons.
            </p>
          </div>
        </Swoosh>

        <div className="lessons__content">
          <div
            className="guitar-courses__top container"
            style={{ flexDirection: width > breakpoints.mdDevice ? "row" : "column" }}
          >
            <CoursesByGrade width={width} />
            <CoursesBySkill width={width} />
          </div>
        </div>
        <MusicTheoryBanner />
      </div>
    );
  }
}

export default withInnerWidth(LessonsOverview);
