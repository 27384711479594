import React, { useState } from "react";

import LoginModal from "../../auth/LoginModal";

export default function () {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="login-prompt">
      <h4 className="header4 bold">join the community to submit a request or vote for a song</h4>

      <div className="button-group">
        <a className="button button--primary" href="/users/sign_up">
          Join For Free
        </a>

        <a className="button button--no-background" onClick={() => setShowModal(true)}>
          Log In
        </a>
      </div>

      {showModal && <LoginModal isOpen={showModal} toggleModal={() => setShowModal(false)} />}
    </div>
  );
}
