import React from "react";
import { connect } from "react-redux";
import { currentKey } from "../../store/songSelectors";
import { formattedSheetMusic } from "../Formatter";

const LyricsChords = ({ sheetMusic, currentKey, fontSize }) => {
  const { content } = sheetMusic.data.attributes;
  return formattedSheetMusic(content, currentKey, fontSize);
};

const mapStateToProps = (state) => ({
  sheetMusic: state.entity.sheetMusic,
  currentKey: currentKey(state),
  fontSize: state.entity.fontSize,
});

export default connect(mapStateToProps)(LyricsChords);
