import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { MoneyFormatter } from "../../components/shared/MoneyFormatter";

import { STRUMMING_SOS_DISCOUNT_PRICE } from "../../pages/StrummingSos/consts";
const STRUM_REFERENCES = [
  "JustinGuitarStrumSOS1",
  "JustinGuitarStrumSOS2",
  "JustinGuitarStrumSOS3",
];

import LoginPrompt from "../../components/auth/LoginPrompt";
import PaywallForm from "../../payments/PaywallForm";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import Loading from "../../components/shared/Loading";

function formattedPaymentOptionTitle(option) {
  const { price, isSubscription, subscriptionFrequencyInMonths } = option.attributes;
  if (isSubscription) {
    return (
      <>
        <span className="pricing-option__top">Subscription Payment for</span>
        <span className="pricing-option__bottom">
          {MoneyFormatter(price)} / {subscriptionFrequencyInMonths}{" "}
          {subscriptionFrequencyInMonths > 1 ? "Months" : "Month"}
        </span>
      </>
    );
  } else {
    return (
      <>
        <span className="pricing-option__top">Lifetime Access for</span>
        <span className="pricing-option__bottom">{MoneyFormatter(price)}</span>
      </>
    );
  }
}

const SinglePaymentOption = ({ currentUser, paywall, token }) => {
  const pricingOption = paywall.included.filter((i) => i.type == "pricingOption")[0];
  const [price, setPrice] = useState(pricingOption.attributes.price);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser && STRUM_REFERENCES.includes(paywall.data.attributes.reference)) {
      setLoading(true);
      axiosWithCSRF()
        .get(`/users/${currentUser.data.id}/user_purchases`)
        .then((response) => {
          const { purchaseReferences } = response.data.data;
          const hasPurchased = purchaseReferences.some((reference) =>
            STRUM_REFERENCES.includes(reference)
          );
          if (hasPurchased) {
            setPrice(STRUMMING_SOS_DISCOUNT_PRICE);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  if (loading) {
    return <Loading isContent={true} />;
  }

  return (
    <div>
      <h3 className="white">Buy access to this module</h3>
      <div className="group-paywall__selection active">
        {formattedPaymentOptionTitle({
          ...pricingOption,
          attributes: { ...pricingOption.attributes, price },
        })}
      </div>

      {currentUser ? (
        <>
          <p className="payment-intro bold">Please enter your payment details below.</p>
          <PaywallForm
            currentUser={currentUser}
            paywall={paywall}
            selectedPricingOption={{
              ...pricingOption,
              attributes: { ...pricingOption.attributes, price },
            }}
          />
        </>
      ) : (
        <div className="group-paywall__login">
          <LoginPrompt token={token} loginAction={"make purchases"} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  paywall: state.entity.paywall,
  token: state.session.token,
});

export default connect(mapStateToProps)(SinglePaymentOption);
