import React from "react";
import PracticeItems from "./Lists/PracticeItems";
import ArchivedItems from "./Lists/ArchivedItems";
import CreateItem from "./Actions/CreateItem";
import AdminIntroduction from "../General/AdminIntroduction";

export default class PracticeItemsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  practiceItemsList = () => {
    if (this.props.practiceItems) {
      return (
        <PracticeItems
          items={this.props.practiceItems}
          handleUpdatedItem={this.props.handleUpdatedItem}
          handleArchivedItem={this.props.handleArchivedItem}
          handleCopiedItem={this.props.handleCopiedItem}
        />
      );
    }
  };

  archivedItemsList = () => {
    if (this.props.practiceItems) {
      return (
        <ArchivedItems
          items={this.props.practiceItems}
          handleRestoredItem={this.props.handleRestoredItem}
          handleDeletedItem={this.props.handleDeletedItem}
        />
      );
    }
  };

  newItemForm = () => {
    if (this.state.newItemFormOpen === true) {
      return <CreateItem closeForm={this.toggleNewItemForm} handleNewItem={this.handleNewItem} />;
    }
  };

  handleNewItem = (item) => {
    this.toggleNewItemForm();
    this.props.handleNewItem(item);
  };

  toggleNewItemForm = () => {
    this.setState({ newItemFormOpen: !this.state.newItemFormOpen });
  };

  showHeader = (status) => {
    let { practiceItems } = this.props;
    if (practiceItems && practiceItems.filter((i) => i.status === status).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  showForm = (formType, itemId) => {
    this.setState({
      showForm: !this.state.showForm,
      formToShow: formType,
      itemIdToEdit: itemId,
    });
  };

  needsFirstItem = () => {
    let { practiceItems } = this.props;
    if (!practiceItems || practiceItems.length === 0) {
      return (
        <div className="needs-first-item">
          <p>
            Create your first practice item below, then add it to your routine in the 'Routines'
            tab.
          </p>
        </div>
      );
    }
  };

  newPracticeItem = () => {
    return (
      <div>
        <div className="practice-buttons__container">
          {!this.state.newItemFormOpen && (
            <button
              className="practice__button practice__button--save practice__button--new-item"
              onClick={this.toggleNewItemForm}
            >
              <i className="icon icon-plus icon-plus-img" />
              Add New Item
            </button>
          )}
        </div>
        {this.newItemForm()}
      </div>
    );
  };

  closeForm = () => {
    this.setState({
      responseText: null,
      showForm: false,
      formToShow: null,
      itemIdToEdit: null,
      errorText: null,
    });
  };

  render() {
    return (
      <div>
        <AdminIntroduction intro={this.props.adminIntroduction} />
        {this.needsFirstItem()}

        <div className="practice-items__container">
          <div className="practice-items__left">
            <span className="practice__heading">Active Items</span>
            {this.practiceItemsList()}

            {this.newPracticeItem()}
          </div>
          <div className="practice-items__right">
            <span className="practice__heading">Archived Items</span>
            {this.archivedItemsList()}
          </div>
        </div>
      </div>
    );
  }
}
