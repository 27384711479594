import { createSlice } from "@reduxjs/toolkit";
import { storeAnswerChoices } from "../util/localstorage";

export const quizResponseSlice = createSlice({
  name: "quizResponse",
  initialState: {}, // { [questionId]: [...selectedOptionIdsForQuestion]
  reducers: {
    selectQuestionOption(state, { payload: { questionId, optionId, clearOtherOptions = true } }) {
      const currSelections = state[questionId];

      const updatedOptions = clearOtherOptions
        ? [optionId]
        : Array.isArray(currSelections)
        ? Array.from(new Set(currSelections).add(optionId)) // de-dup to be safe
        : [optionId];

      storeAnswerChoices({ id: questionId }, updatedOptions);

      state[questionId] = updatedOptions;
    },

    deselectQuestionOption(state, { payload: { questionId, optionId } }) {
      const currSelections = state[questionId];

      const updatedOptions = [new Set(currSelections).delete(optionId)];

      storeAnswerChoices({ id: questionId }, updatedOptions);

      state[questionId] = updatedOptions;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(logSheetMusicView.fulfilled, (state, { payload }) => {
    //   state.sheetMusicViewLogged = true;
    // });
  },
});
