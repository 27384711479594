import React, { useContext, useEffect, useState } from "react";
import { ACTION_TYPES, useUserDispatch, useUser } from "./contexts/userContext";
import LatestReleases from "./LatestReleases";
import * as bootstrapHelpers from "../shared/bootstrap-helpers";
import SongsListing from "./songs-list/SongsListing";
import Artists from "./artists-list/Artists";
import SongRequests from "./song-requests/SongRequests";
import axiosWithCSRF from "../shared/axiosWithCSRF";
import Header from "./Header";
import SnackbarNotification from "../shared/Snackbar";
import { SnackbarContext } from "./SongsIndexContainer";

const SECTION_BUTTONS = {
  all: "all",
  latestReleases: "latest releases",
  songs: "songs",
  artists: "artists",
  requestsBoard: "requests board",
};

function SectionSelect({ activeSection, setActiveSection }) {
  return (
    <div className="container">
      <ul className="songs-section-buttons songs-section-buttons--pills">
        <li>view</li>
        {Object.values(SECTION_BUTTONS).map((button, idx) => (
          <li key={idx} className={activeSection === button ? "active" : ""}>
            <button onClick={() => setActiveSection(button.toLowerCase())}>{button}</button>
          </li>
        ))}
      </ul>
      <div className="songs-section-buttons songs-section-buttons--select">
        <select
          onChange={(e) => setActiveSection(e.target.value.toLowerCase())}
          value={activeSection}
        >
          {Object.values(SECTION_BUTTONS).map((button, idx) => (
            <option key={idx} value={button}>
              {button.toUpperCase()}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

function SongsIndexPage({ currentUser }) {
  const dispatch = useUserDispatch();
  const user = useUser();
  const { snackbar } = useContext(SnackbarContext);

  const [filters, setFilters] = useState([]);
  const [savedSongs, setSavedSongs] = useState([]);
  const [practiceItems, setPracticeItems] = useState([]);

  useEffect(() => {
    fetchFilters();
    fetchUserItems();
    fetchPracticeItems();
  }, []);

  function fetchFilters() {
    axiosWithCSRF()
      .get("/song_filters.json")
      .then((response) => {
        setFilters(response.data.filters);
      });
  }

  function fetchUserItems() {
    if (!user) return;

    axiosWithCSRF()
      .get("/user_items/songs.json")
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.SET_SONG_USER_ITEMS,
          userItems: response.data.userItems,
        });
      });
  }

  function fetchPracticeItems() {
    if (!user) return;

    axiosWithCSRF()
      .get("/user_practice_items/song_practice_items.json")
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.SET_SONG_PRACTICE_ITEMS,
          userPracticeItems: response.data.userPracticeItems,
        });
      });
  }

  const [activeSection, setActiveSection] = useState(
    window.location.hash ? window.location.hash.substring(1).replace("-", " ") : SECTION_BUTTONS.all
  );

  function isDisplayable(sectionName) {
    return activeSection === SECTION_BUTTONS.all || activeSection === sectionName;
  }

  function changeActiveSection(sectionName) {
    window.location.hash = sectionName.replace(" ", "-").toLowerCase();
    setActiveSection(sectionName);
  }

  function isMdDevice() {
    return (
      window.innerWidth <= bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.lgDevice]
    );
  }

  return (
    <div className="songs-index-page">
      <Header selectedArtist={null} />
      <SectionSelect activeSection={activeSection} setActiveSection={changeActiveSection} />

      <div className="swooosh__content">
        <div
          className="container"
          style={
            activeSection === "songs"
              ? isMdDevice()
                ? { paddingTop: "40px" }
                : { paddingTop: "70px" }
              : null
          }
        >
          {isDisplayable(SECTION_BUTTONS.latestReleases) && (
            <LatestReleases savedSongs={savedSongs} setSavedSongs={setSavedSongs} />
          )}

          {isDisplayable(SECTION_BUTTONS.songs) && (
            <SongsListing filters={filters} savedSongs={savedSongs} setSavedSongs={setSavedSongs} />
          )}
        </div>

        {isDisplayable(SECTION_BUTTONS.artists) && (
          <Artists excludeTopMargin={activeSection === "artists"} filters={filters} />
        )}

        {isDisplayable(SECTION_BUTTONS.requestsBoard) && (
          <SongRequests
            excludeTopMargin={activeSection === "requests board"}
            currentUser={currentUser}
          />
        )}
      </div>

      <SnackbarNotification message={snackbar.message} severity={snackbar.severity} />
    </div>
  );
}

export default SongsIndexPage;
