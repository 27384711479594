import React from "react";
import axiosWithCSRF from "../../shared/axiosWithCSRF";
import ActiveItem from "./Items/ActiveItem";
import InactiveItem from "./Items/InactiveItem";
import FinishedPrompt from "./FinishedPrompt";
const SIXTY_SECONDS = 60;

export default class CurrentRoutineList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  logPractice = (customTime) => {
    let { currentItem, activeRoutine } = this.props;
    let secondsToRecord = customTime ? customTime : currentItem.duration * SIXTY_SECONDS;
    let itemFinished = customTime ? false : true;
    let partiallyFinished = customTime ? true : false;
    this.setState({ isSubmitting: true }, () => {
      axiosWithCSRF()
        .post("/practice_session_items", {
          seconds: secondsToRecord,
          date: new Date(),
          title: currentItem.title,
          user_practice_routine_id: activeRoutine.id,
          description: currentItem.description,
          statistic: this.state.statToRecord,
          statistic_name: currentItem.statistic_name,
          user_practice_item_id: currentItem.id,
          update_user_video_play_along: this.needsPlayAlongUpdate(),
        })
        .then((response) => {
          let item = response.data.practice_session_item;
          let practiceSession = response.data.practice_session;
          let completedSessionItemIds = [...new Set(practiceSession.completed_practice_items)];
          let routineItemIds = this.props.activeRoutine.items.map((i) => i.id);
          let finishedRoutine = routineItemIds.every((item) =>
            completedSessionItemIds.includes(item)
          );
          this.setState({
            loggedItemId: item.id,
            finishedRoutine,
            finishedItem: true,
            isSubmitting: false,
            partiallyFinished,
          });
          this.props.setFinishedItem(true);
          this.props.updateStatsAndPracticeSessions(item, practiceSession);
        })
        .catch((error) => {
          let errorResponse = error.response.data
            ? error.response.data.message
            : "There was a problem logging this session.";
          this.setState({ hasErrors: errorResponse, isSubmitting: false });
          setTimeout(() => this.setState({ hasErrors: null }), 2000);
        });
    });
  };

  needsPlayAlongUpdate = () => {
    return this.props.user.video_play_along_is_active != this.props.videoPlayAlongIsActive;
  };

  itemsList() {
    let ordered = this.props.activeRoutine.items.sort(function (a, b) {
      if (a.position < b.position) {
        return -1;
      }
      if (a.position > b.position) {
        return 1;
      }
      return 0;
    });
    return ordered.map((item, index) => {
      return this.itemInList(item, index);
    });
  }

  unsavedMetronomeSpeedChange = (tempo) => {
    this.setState({ unsavedMetronomeSpeed: tempo });
  };

  itemInList = (item, index) => {
    let { currentItem } = this.props;
    if (currentItem && item.id === currentItem.id) {
      return (
        <ActiveItem
          item={item}
          key={index}
          index={index}
          user={this.props.user}
          unsavedMetronomeSpeedChange={this.unsavedMetronomeSpeedChange}
          saveMetronomeSpeed={this.props.saveMetronomeSpeed}
          updateNoteInPracticeItem={this.props.updateNoteInPracticeItem}
          logPractice={this.logPractice}
          practiceSessionItems={this.props.practiceSessionItems}
          videoPlayAlongIsActive={this.props.videoPlayAlongIsActive}
        />
      );
    } else {
      return (
        <InactiveItem
          item={item}
          key={index}
          index={index}
          makeActive={this.props.makeActive}
          completedItems={this.props.completedItems}
        />
      );
    }
  };

  nextItem = () => {
    let itemPartiallyFinished = this.state.partiallyFinished;
    this.setState({ finishedItem: false, finishedRoutine: false, partiallyFinished: false }, () => {
      this.props.setFinishedItem(false);
      this.props.nextItem(itemPartiallyFinished);
    });
  };

  repeatCurrent = () => {
    this.setState({ finishedItem: false, finishedRoutine: false }, () => {
      this.props.setFinishedItem(false);
    });
  };

  restartRoutine = () => {
    this.setState({ finishedItem: false, finishedRoutine: false }, () => {
      this.props.setFinishedItem(false);
      this.props.restartRoutine();
    });
  };

  render() {
    if (this.state.isSubmitting === true) {
      return (
        <div>
          <img style={{ maxWidth: "150px" }} src="/images/Rolling.gif"></img>
          <h5>Adding time to your statistics.</h5>
        </div>
      );
    } else if (this.state.finishedRoutine === true || this.state.finishedItem === true) {
      return (
        <FinishedPrompt
          userId={this.props.user.id}
          nextItem={this.nextItem}
          finishedRoutine={this.state.finishedRoutine}
          unsavedMetronomeSpeed={this.state.unsavedMetronomeSpeed}
          restartRoutine={this.restartRoutine}
          repeatCurrent={this.repeatCurrent}
          currentItem={this.props.currentItem}
          loggedItemId={this.state.loggedItemId}
          updateStatItem={this.props.updateStatItem}
          updateItemWithChangedBpm={this.props.updateItemWithChangedBpm}
          updateNoteInPracticeItem={this.props.updateNoteInPracticeItem}
        />
      );
    } else {
      return <div>{this.itemsList()}</div>;
    }
  }
}
