import React from "react";
import { CDN_URL } from "../../../components/shared/CdnUrl";

const IMAGE_PREFIX = `${CDN_URL}/images/products/digital/`;

export const PurchaseTilesByReference = {
  PMT: `${IMAGE_PREFIX}Purchases-MusicTheory.jpg`,
  RUBS: `${IMAGE_PREFIX}Purchases-TranscribingBluesSolos.jpg`,
  DMMS: `${IMAGE_PREFIX}Purchases-MasterMajorScales.jpg`,
  DSBG: `${IMAGE_PREFIX}Purchases-SoloBluesGuitar.jpg`,
  DSTR: `${IMAGE_PREFIX}Purchases-StrummingTechniques1-2.jpg`,
  // TABS prices in dev & production
  price_1IaO0YF1dLDLVnhOTRr73EZo: `${IMAGE_PREFIX}Purchases-Tabs.jpg`,
  price_1IaO0XF1dLDLVnhOZQXgQuqc: `${IMAGE_PREFIX}Purchases-Tabs.jpg`,
  price_1IaO01F1dLDLVnhO0eiVApAC: `${IMAGE_PREFIX}Purchases-Tabs.jpg`,
  price_1IaO01F1dLDLVnhOa6aLHAg9: `${IMAGE_PREFIX}Purchases-Tabs.jpg`,
  "101childsongsukulele": `${IMAGE_PREFIX}Purchases-ChildrenSongsUkulele.jpg`,
  "101childsongsguitar": `${IMAGE_PREFIX}Purchases-ChildrenSongsGuitar.jpg`,
  "101childsongsbundle": `${IMAGE_PREFIX}Purchases-ChildrenSongsBundle.jpg`,
  CSMD: `${IMAGE_PREFIX}Purchases-UkuleleParents.jpg`,
  JustinGuitarStrumSOS1: `${IMAGE_PREFIX}Purchases-StrummingFoundations.jpg`,
  JustinGuitarStrumSOS2: `${IMAGE_PREFIX}Purchases-StrummingDynamics.jpg`,
  JustinGuitarStrumSOS3: `${IMAGE_PREFIX}Purchases-FingerStrumming2.jpeg`,
};
