import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axiosWithCSRF from "../shared/axiosWithCSRF";
import LoginPrompt from "../auth/LoginPrompt";
import { openAlert, clearAlert } from "../shared/ui/uiSlice";
import { addOrUpdateUserNote, deleteUserNote } from "../shared/session/sessionSlice";
import Snackbar from "../shared/ui/Snackbar";
import { Box } from "@mui/material";
import { PromoItem } from "../../justins-corner/PromoItem";
import { withBootstrapSize } from "../shared/WithBootstrapSize";

class UserNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noteContent: "",
    };
  }

  componentDidMount() {
    this.initializeNote();
  }

  initializeNote = () => {
    const { note } = this.props;
    let noteContent = "";
    if (note) {
      noteContent = note.attributes.content;
    }
    if (noteContent != this.state.noteContent) {
      this.setState({ noteContent });
    }
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    let noteData = {
      user_id: this.props.user.data.attributes.id,
      noteable_id: this.props.noteableId,
      noteable_type: this.props.noteableType,
      content: this.state.noteContent,
      id: this.props.note ? this.props.note.id : null,
    };

    let url = "/user_notes";
    let method = "POST";
    if (this.props.note) {
      url = `/user_notes/${this.props.note.id}`;
      method = "PATCH";
    }
    axiosWithCSRF({
      "Content-Type": "application/json",
      Accept: "application/json",
    })({
      url: url,
      method: method,
      data: { user_note: noteData },
    })
      .then((res) => {
        this.props.addOrUpdateUserNote(res.data.serializedNote.data);
        this.props.openAlert({ message: "Saved this note!", severity: "success" });
      })
      .catch((err) => {
        this.props.openAlert({
          message: "There was a problem saving this note.",
          severity: "error",
        });
      })
      .then(() => {
        this.setState({ isEditing: false });
      });
  };

  handleDelete = () => {
    if (confirm("Are you sure you want to delete this note?")) {
      axiosWithCSRF()
        .delete(`/user_notes/${this.props.note.id}`, {
          headers: {
            "X-CSRF-Token": ReactOnRails.authenticityToken(),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.setState({ noteContent: "" }, () => {
            this.props.openAlert({ message: "Deleted this note!", severity: "success" });
            this.props.deleteUserNote(this.props.note);
          });
        })
        .catch((error) => {
          this.props.openAlert({
            message: "There was a problem deleting this note.",
            severity: "error",
          });
        })
        .then(() => {
          this.setState({ isEditing: false });
        });
    }
  };

  isDisabled = (btnType) => {
    let content = this.state.noteContent.trim();
    let existingNote = this.props.note ? this.props.note.attributes.content.trim() : null;
    if (content === existingNote) {
      return true;
    } else if (btnType === "submit" && content.length === 0) {
      return true;
    } else if (!existingNote && content.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  toggleLogin = () => {
    this.setState({ loginOpen: !this.state.loginOpen });
  };

  render() {
    const { user, note, token } = this.props;
    let { noteContent } = this.state;

    const shouldBeLocatedBelowContent = ["xsDevice", "smDevice", "mdDevice"].includes(
      this.props.bootstrapSize
    );
    const shouldBeHorizontal = ["smDevice", "mdDevice"].includes(this.props.bootstrapSize);

    return (
      <Box
        display="flex"
        flexDirection={shouldBeLocatedBelowContent ? "column" : "row"}
        justifyContent="space-between"
      >
        <div style={{ width: "100%", maxWidth: "750px" }}>
          {!user ? (
            <LoginPrompt loginAction="save notes" token={token} />
          ) : (
            <div className="user-note styleized">
              <form onSubmit={this.onFormSubmit}>
                <textarea
                  type="text"
                  value={this.state.noteContent}
                  placeholder="Begin typing your note here..."
                  onChange={(e) => this.setState({ noteContent: e.target.value })}
                />
                <div className="flex user-note__btns">
                  <div className="flex">
                    <button
                      type="button"
                      onClick={this.initializeNote}
                      className="button button--white"
                      disabled={this.isDisabled("cancel")}
                    >
                      Cancel
                    </button>
                    <input
                      type="submit"
                      className="button button--blue"
                      value="Submit"
                      disabled={this.isDisabled("submit")}
                    ></input>
                  </div>

                  {note && note.id && (
                    <button
                      type="button"
                      onClick={this.handleDelete}
                      className="button button--danger"
                    >
                      Delete
                    </button>
                  )}
                </div>
              </form>
              <Snackbar />
            </div>
          )}
        </div>
        <div
          style={{
            marginTop: shouldBeLocatedBelowContent ? "20px" : 0,
            marginLeft: shouldBeLocatedBelowContent ? 0 : "20px",
          }}
        >
          <PromoItem orientation={shouldBeHorizontal ? "landscape" : "portrait"} />
        </div>
      </Box>
    );
  }
}

UserNote.propTypes = {
  token: PropTypes.string.isRequired,
  noteableId: PropTypes.number.isRequired,
  noteableType: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  openAlert: (alert) => dispatch(openAlert(alert)),
  clearAlert: () => dispatch(clearAlert()),
  addOrUpdateUserNote: (note) => dispatch(addOrUpdateUserNote(note)),
  deleteUserNote: (note) => dispatch(deleteUserNote(note)),
});

export default connect(null, mapDispatchToProps)(withBootstrapSize(UserNote));
