import React from "react";
import axios from "axios";
import DeleteNote from "./DeleteNote";

export default class EditNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noteContent: "",
    };
  }

  componentDidMount() {
    if (this.props.item && this.props.item.note) {
      this.setState({
        noteContent: this.props.item.note.content,
      });
    }
  }

  handleNoteChange = (e) => {
    this.setState({ noteContent: e.target.value });
  };

  submitNote = () => {
    let { item, userId } = this.props;
    axios
      .patch(`/user_notes/${item.note.id}`, {
        id: item.note.id,
        user_id: userId,
        content: this.state.noteContent,
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((response) => {
        let note = response.data.user_note;
        this.props.updateNoteInPracticeItem(note, item.id);
      })
      .catch((error) => {
        this.setState({ error: "There was a problem saving this note." });
        setTimeout(() => this.setState({ error: null }), 2000);
      });
  };

  render() {
    if (this.props.item.note) {
      return (
        <div className="routine-note__form">
          {this.state.error && <p className="has-errors">{this.state.error}</p>}
          <div className="routine-note__input-container">
            <i className="icon icon-pencil"></i>
            <input value={this.state.noteContent} type="text" onChange={this.handleNoteChange} />
          </div>
          <div className="practice-buttons__container practice-buttons__container--note">
            <button className="practice__button practice__button--save" onClick={this.submitNote}>
              save
            </button>
            <button
              className="practice__button practice__button--cancel"
              onClick={this.props.closeForm}
            >
              cancel
            </button>
            <DeleteNote
              note={this.props.item.note}
              handleDeletedNote={this.props.handleDeletedNote}
            />
          </div>
        </div>
      );
    }
  }
}
