import React, { useEffect, useState } from "react";
import Overview from "./Overview";
import Category from "./Category";
import { withInnerWidth } from "../../components/shared";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import SearchResults from "./SearchResults";

const FAQ = ({ slug, faqs, searchResultsFromQuery }) => {
  const [stateFaqs, setStateFaqs] = useState(null);
  // const [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs);
  const [pageReady, setPageReady] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [searchResultsFromQueryByCategory, setSearchResultsFromQueryByCategory] = useState(null);

  const findFaq = (slug, faqs) => {
    let result = null;

    faqs.some((faq) => (result = faq.slug === slug ? faq : findFaq(slug, faq.children || [])));

    return result;
  };

  const initPage = (slug, stepBack = false) => {
    let foundFaqs = null;

    if (slug) {
      foundFaqs = findFaq(slug, faqs);
    }

    if (searchResultsFromQuery.length) {
      setSearchResultsFromQueryByCategory(
        Object.values(
          searchResultsFromQuery.reduce((group, product) => {
            const { root_faq_id } = product;
            group[root_faq_id] = group[root_faq_id] ?? [];
            group[root_faq_id]["category"] = product.root_faq_data;
            if (group[root_faq_id].questions) {
              group[root_faq_id].questions.push(product);
            } else {
              group[root_faq_id]["questions"] = [product];
            }

            return group;
          }, {})
        )
      );
    }

    setStateFaqs(foundFaqs);
    setPageReady(true);
  };

  useEffect(() => {
    initPage(slug, window.history.state && window.history.state.url != window.location.href);

    window.onpopstate = (e) => {
      initPage(window.location.href.substring(window.location.href.lastIndexOf("/") + 1), true);
    };

    return () => {
      window.onpopstate = undefined;
    };
  }, []);

  useEffect(() => {
    if (searchPhrase) {
      axiosWithCSRF()
        .post("/faq", {
          search_phrase: searchPhrase,
        })
        .then((response) => {
          setSearchResults(response.data.items);
        });
    } else {
      setSearchResults(null);
    }
  }, [searchPhrase]);

  return (
    <div className="faq">
      {pageReady &&
        (stateFaqs ? (
          <Category
            inHelpCenter
            faqs={stateFaqs}
            searchPhrase={searchPhrase}
            setSearchPhrase={setSearchPhrase}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            // breadcrumbs={this.state.breadcrumbs}
          />
        ) : searchResultsFromQueryByCategory ? (
          <SearchResults
            searchPhrase={searchPhrase}
            setSearchPhrase={setSearchPhrase}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            searchResultsFromQueryByCategory={searchResultsFromQueryByCategory}
          />
        ) : (
          <Overview
            searchPhrase={searchPhrase}
            setSearchPhrase={setSearchPhrase}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            faqs={faqs}
          />
        ))}
    </div>
  );
};

export default withInnerWidth(FAQ);
