import React, { useState } from "react";
import { connect } from "react-redux";

import { PromoItem } from "../../justins-corner/PromoItem";
import { withInnerWidth } from "../../components/shared";
import { goToTab } from "./DashboardTabsHome";
import ContinueLearningNew from "./ContinueLearningNew";
import WhatsNewNew from "./WhatsNewNew";
import AllNewLessons from "../WelcomeTab/AllNewLessons";

import { ONBOARDING_STATUSES } from "../../pages/Onboarding";

const WelcomeBack = ({ currentUser, latestLessons, recentPageviews, width }) => {
  const [viewAllLessons, setViewAllLessons] = useState(false);
  const { username, slug, onboardingStatus } = currentUser.data.attributes;
  return (
    <div className="dashboard__content__tabs">
      <div className="tab-container--welcome">
        {!viewAllLessons ? (
          <div className="welcome">
            <div className="welcome__banner">
              <h2>
                Welcome Back, <strong>{username}</strong>.
              </h2>
              <a
                onClick={() => {
                  window.location.href = `/users/${slug}/practice#practice`;
                }}
                className="button button--primary button--inline"
              >
                Start Today's Practice
              </a>

              {onboardingStatus === ONBOARDING_STATUSES.pausedOnboarding && (
                <a
                  onClick={() => (window.location.href = "/onboarding")}
                  className="button button--teal button--inline"
                  style={{ marginLeft: "10px" }}
                >
                  Complete Onboarding
                </a>
              )}
            </div>

            <div className="welcome__row">
              <div className="welcome__col">
                <ContinueLearningNew user={currentUser} recentPageviews={recentPageviews} />
              </div>
              <div className="welcome__col">
                <WhatsNewNew latestLessons={latestLessons} userSlug={slug} />
              </div>
              <div className="welcome__col">
                <PromoItem
                  orientation={width < 1200 && width > 576 ? "landscape" : "portrait"}
                  target="dashboard"
                />
              </div>
            </div>
          </div>
        ) : (
          <AllNewLessons setViewAllLessons={setViewAllLessons} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  latestLessons: state.entity.dashboard.latestLessons,
  recentPageviews: state.entity.dashboard.recentPageviews,
});

export default connect(mapStateToProps)(withInnerWidth(WelcomeBack));
