import ReactOnRails from "react-on-rails";
import { configureStore, createSlice, combineReducers } from "@reduxjs/toolkit";
import { ui } from "../../../components/shared/ui/uiIndex";
import { session } from "../../../components/shared/session/sessionIndex";

const initialState = (props) => {
  return {
    entity: { faqs: [], lesson_counts: {} },
  };
};

const initialSessionState = (props) => {
  return {
    session: { currentUser: props.currentUser },
  };
};

const strummingSosSlice = createSlice({
  name: "strummingSos",
  initialState,
  reducers: {},
});

export const {} = strummingSosSlice.actions;

const reducer = combineReducers({
  entity: strummingSosSlice.reducer,
  ui: ui.reducer,
  session: session.reducer,
});

const createStrummingSosStore = (preloadedState) =>
  configureStore({
    reducer,
    preloadedState,
  });

ReactOnRails.registerStore({
  strummingSosStore: (props, railsContext) => {
    return createStrummingSosStore({
      entity: {
        ...props.entity,
      },
      ui: {
        ...ui.initialState,
        ...props.ui,
      },
      session: {
        ...initialSessionState,
        ...props.session,
      },
    });
  },
});
