import React from "react";
import { connect } from "react-redux";
import { changeActiveSheetMusicContent } from "../../store/songStore";
import { orderedTabImages } from "../../store/songSelectors";

const TabsChordsToggle = ({
  orderedTabImages,
  activeSheetMusicContent,
  changeActiveSheetMusicContent,
}) => {
  const contentToChangeTo = activeSheetMusicContent === "Tabs" ? "Chords" : "Tabs";
  if (orderedTabImages.length > 0) {
    return (
      <div className="slider-toggle slider-toggle--songs">
        <div className="float-left">
          <input
            type="checkbox"
            id="endorsement-toggle"
            checked={activeSheetMusicContent === "Tabs"}
            onChange={() =>
              changeActiveSheetMusicContent({
                content: contentToChangeTo,
                image: orderedTabImages[0],
              })
            }
          />
          <label htmlFor="endorsement-toggle">
            <div
              className={`switch switch--${activeSheetMusicContent}`}
              data-checked={"Tabs"}
              data-unchecked={"Chords"}
            />
          </label>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const mapDispatchToProps = (dispatch) => ({
  changeActiveSheetMusicContent: (content) => dispatch(changeActiveSheetMusicContent(content)),
});

const mapStateToProps = (state) => ({
  activeSheetMusicContent: state.entity.activeSheetMusicContent,
  orderedTabImages: orderedTabImages(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabsChordsToggle);
