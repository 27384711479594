import React from "react";

const MobileMenuOptions = ({
  lesson,
  toggleCompleted,
  toggleFavorite,
  userHasSavedItem,
  userHasCompletedItem,
}) => {
  const { id } = lesson?.data?.attributes || {};
  return (
    <ul>
      <li>
        <button
          className="mobile-context-menu-btn"
          onClick={() => toggleFavorite(userHasSavedItem, id)}
        >
          <i className={`icon ${userHasSavedItem ? "icon-heart" : "icon-heart-outline"}`}></i>
          {`${userHasSavedItem ? "Saved" : "Save"}`} to favorites
        </button>
      </li>
      <li>
        <button
          className="mobile-context-menu-btn"
          onClick={() => toggleCompleted(userHasCompletedItem, id)}
        >
          <i
            className={`icon ${
              userHasCompletedItem ? "icon-check_circle_outline" : "icon-check-circle-outline"
            }`}
          ></i>
          {`${userHasCompletedItem ? "Completed" : "Complete"}`} lesson
        </button>
      </li>
    </ul>
  );
};
export default MobileMenuOptions;
