import React, { useEffect, useState } from "react";
import ReactOnRails from "react-on-rails";
import { connect, Provider } from "react-redux";
import "./store/storeStore";
import Slider from "react-slick";
import { CDN_URL } from "../../components/shared/CdnUrl";
import {
  ArrowRightIcons,
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
  CloseIcon,
  StarIcon,
  VerifiedIcon,
} from "./Icons";
import { grade } from "../../group/store/groupSchema";
import { withInnerWidth } from "../../components/shared";
import { musicTheoryCourse, store } from "../FAQ/data/faqs";
import Category from "../FAQ/Category";
import LoginPrompt from "../../components/auth/LoginPrompt";
import { formatYoutubeDuration, youtubeDurationToSeconds } from "../../utilities/lib";
import { LightTooltip } from "../../components/shared/ui/Tooltips";
import { formatNumber, formatTime, groupBy } from "../../utilities/misc";
import { Box, dividerClasses, Modal, Paper } from "@mui/material";
import StoreSwoosh from "./StoreSwoosh";
import ConditionalWrapper from "../../components/ConditionalWrapper";
import { AppStore, GooglePlay } from "../../banners/icons";

const NAV = [
  { name: "Website Products", slug: "website-products" },
  { name: "Physical Products", slug: "physical-products" },
  { name: "Downloads", slug: "downloads" },
  { name: "Apps", slug: "apps" },
  { name: "Find What’s Best For Me", slug: "find-whats-best-for-me" },
];

const PRODUCTS = {
  websiteProducts: [
    {
      title: "Practical Music Theory",
      labels: [
        { title: "course", variant: 1 },
        { title: "free module 1 & 2", variant: 2 },
      ],
      description: null,
      about:
        "I teach you Music Theory how I wish I learned: using practical examples, understanding the whys, and making music from the very beginning. Music Theory doesn't have to be boring or complicated. And it'll help you connect your skills with your musical imagination! :)",
      price: "USD 8.99/month or USD 49.99/year",
      imageLg: `${CDN_URL}/images/pages/store/store-card-music-theory.png`,
      imageSm: null,
      imageStyleLg: {},
      imageStyleSm: {},
      cardVariant: 1,
      url: "/store/practical-music-theory",
    },
    {
      title: "JustinGuitar Tabs",
      labels: [
        { title: "feature", variant: 2 },
        { title: "free trial", variant: 3 },
      ],
      description: null,
      about: null,
      price: "USD 7.99/month or USD 49.99/year",
      imageLg: `${CDN_URL}/images/pages/store/tabs-lg.png`,
      imageSm: `${CDN_URL}/images/pages/store/tabs-sm.png`,
      imageStyleLg: { backgroundSize: "483px", backgroundPosition: "center top" },
      imageStyleSm: { backgroundSize: "290px", backgroundPosition: "center 40px" },
      cardVariant: 2,
      url: "/store/tabs",
    },
    {
      title: "Transcribing Blues Solos",
      labels: [{ title: "course", variant: 1 }],
      description: null,
      about: null,
      price: "USD 15.00",
      imageLg: `${CDN_URL}/images/pages/store/store-card-transcribing-blues-solos.png`,
      imageSm: null,
      imageStyleLg: {},
      imageStyleSm: {},
      cardVariant: 1,
      url: "/modules/transcribing-blues-solos",
    },
    {
      title: "Solo Blues Guitar",
      labels: [{ title: "course", variant: 1 }],
      description: null,
      about: null,
      price: "USD 9.98",
      imageLg: `${CDN_URL}/images/pages/store/store-card-solo-blues-guitar.png`,
      imageSm: null,
      imageStyleLg: {},
      imageStyleSm: {},
      cardVariant: 1,
      url: "/modules/solo-blues-guitar",
    },
    {
      title: "Strumming SOS",
      labels: [
        { title: "course", variant: 1 },
        { title: "new", variant: 2 },
      ],
      description: null,
      about: null,
      price: "Starting at USD 39.99",
      imageLg: `${CDN_URL}/images/pages/strumming-sos/strumming-sos-hero-sm-2.png`,
      imageSm: null,
      imageStyleLg: {},
      imageStyleSm: {},
      cardVariant: 1,
      url: "/store/strumming-sos",
    },
  ],
  physicalProducts: [
    {
      title: "JustinGuitar Books",
      labels: [
        { title: "songbooks", variant: 1 },
        { title: "technique books", variant: 4 },
      ],
      description: null,
      about: null,
      price: "From USD 5.70",
      imageLg: `${CDN_URL}/images/pages/store/books-lg.png`,
      imageSm: `${CDN_URL}/images/pages/store/books-sm.png`,
      imageStyleLg: { backgroundSize: "600px", backgroundPosition: "center 40px" },
      imageStyleSm: { backgroundSize: "268px", backgroundPosition: "center 30px" },
      cardVariant: 2,
      url: "/store/books",
    },
    {
      title: "JustinGuitar Merch",
      labels: [
        { title: "t-shirts", variant: 5 },
        { title: "mugs", variant: 6 },
        { title: "hats", variant: 7 },
      ],
      description: null,
      about: null,
      price: "From USD 6.99",
      imageLg: `${CDN_URL}/images/pages/store/merch.png`,
      imageSm: null,
      imageStyleLg: { backgroundSize: "220px", backgroundPosition: "center 10px" },
      imageStyleSm: { backgroundSize: "220px", backgroundPosition: "center 10px" },
      cardVariant: 3,
      url: "https://merch.justinguitar.com",
    },
  ],
  digitalDownloads: [
    {
      title: "101 Children’s Songs e-Books",
      labels: null,
      description: "Guitar or Ukulele Edition",
      about: null,
      price: "From USD 9.99",
      imageLg: `${CDN_URL}/images/pages/store/childrens-songbooks.png`,
      imageSm: null,
      imageStyleLg: { backgroundSize: "230px", backgroundPosition: "center 40px" },
      imageStyleSm: { backgroundSize: "230px", backgroundPosition: "center 10px" },
      cardVariant: 4,
      url: "/store/101-childrens-songs",
    },
    {
      title: "Jam Tracks",
      labels: null,
      description: "Playalong tracks to improve your guitar playing",
      about: null,
      price: "From GBP 7.99",
      imageLg: `${CDN_URL}/images/pages/store/jamtracks-lg.png`,
      imageSm: `${CDN_URL}/images/pages/store/jamtracks-sm.png`,
      imageStyleLg: { backgroundSize: "440px", backgroundPosition: "center 40px" },
      imageStyleSm: { backgroundSize: "268px", backgroundPosition: "center 10px" },
      cardVariant: 4,
      url: "https://justinguitarjams.com",
    },
  ],
  apps: [
    {
      image: `url(${CDN_URL}/images/pages/store/time-trainer-app.png)`,
      title: "Time Trainer",
      storeUrl: "/store/time-trainer",
      appStoreUrl: "https://itunes.apple.com/us/app/time-trainer-metronome/id502491350?mt=8",
      playStoreUrl: "https://play.google.com/store/apps/details?id=com.justinguitar.timetrainer",
    },
    {
      image: `url(${CDN_URL}/images/pages/store/note-trainer-app.png)`,
      title: "Note Trainer",
      storeUrl: "/store/note-trainer",
      appStoreUrl: "https://itunes.apple.com/us/app/guitar-note-trainer/id559758702?mt=8",
      playStoreUrl:
        "https://play.google.com/store/apps/details?id=com.justinguitar.guitarnotetrainer",
    },
    {
      image: `url(${CDN_URL}/images/pages/store/ear-trainer-app.png)`,
      title: "Ear Trainer",
      storeUrl: "/store/ear-trainer",
      appStoreUrl: "https://itunes.apple.com/us/app/interval-ear-trainer/id559753482?mt=8",
      playStoreUrl: "https://play.google.com/store/apps/details?id=com.justinguitar.eartrainer",
    },
    {
      image: `url(${CDN_URL}/images/pages/store/blues-licks-app.png)`,
      title: "Blues Licks",
      storeUrl: "/store/blues-licks",
      appStoreUrl: "https://itunes.apple.com/us/app/blues-licks/id1133291883?mt=8",
      playStoreUrl: null,
    },
  ],
};

const WHATS_BEST = [
  {
    productType: "lessonsAndSongsApp",
    productTitle: "JustinGuitar Lessons & Songs App",
    text: "learn guitar with interactive exercises!",
  },
  {
    productType: "websiteProducts",
    productTitle: "Practical Music Theory",
    text: "unleash the fretboard and learn to play freely!",
  },
  {
    productType: "websiteProducts",
    productTitle: "JustinGuitar Tabs",
    text: "learn songs from verified tabs, chords, and lyrics!",
  },
  {
    productType: "digitalDownloads",
    productTitle: "Jam Tracks",
    text: "solo and improvise over real band backing tracks!",
  },
  {
    productType: "apps",
    productTitle: "Ear Trainer",
    text: "recognize notes and chords by ear!",
  },
  {
    productType: "apps",
    productTitle: "Note Trainer",
    text: "learn the notes on the guitar neck",
  },
];

const Nav = ({ activeTab, updateActiveTab, width }) => {
  return (
    <div className="nav">
      {width > 1200 ? (
        NAV.map((navItem, idx) => (
          <NavItem
            key={idx}
            name={navItem.name}
            slug={navItem.slug}
            activeTab={activeTab}
            updateActiveTab={updateActiveTab}
          />
        ))
      ) : (
        <>
          <p className="nav-title">Browse by Product Type</p>
          <select
            value={activeTab}
            onChange={(e) => {
              updateActiveTab(e.target.value);
            }}
          >
            {NAV.map((navItem, idx) => (
              <option key={idx} value={navItem.slug}>
                {navItem.name}
              </option>
            ))}
          </select>
        </>
      )}
    </div>
  );
};

const NavItem = ({ name, slug, activeTab, updateActiveTab }) => {
  return (
    <span
      onClick={() => updateActiveTab(slug)}
      className={activeTab === slug ? "active" : "clickable"}
    >
      {name}
    </span>
  );
};

const ProductCards = ({ products, width }) => {
  return (
    <ConditionalWrapper
      condition={width <= 1200}
      wrapper={(children) => (
        <Slider
          {...{
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          }}
        >
          {children}
        </Slider>
      )}
    >
      {products.map((product, idx) => (
        <ProductCard key={idx} product={product} width={width} />
      ))}
    </ConditionalWrapper>
  );
};

const ProductCard = ({ product, width }) => {
  return (
    <div
      className={`card card-variant-${product.cardVariant}${product.url ? " clickable" : ""}`}
      onClick={() =>
        product.url
          ? product.url.startsWith("http")
            ? window.open(product.url, "_blank")
            : (window.location.href = product.url)
          : null
      }
    >
      <div
        className="card-content"
        style={{
          ...{
            backgroundImage:
              width <= 1200 && product.imageSm
                ? `url(${product.imageSm})`
                : `url(${product.imageLg})`,
          },
          ...(width > 1200 ? product.imageStyleLg : product.imageStyleSm),
        }}
      >
        <h3>{product.title}</h3>
        {product.labels && (
          <div className="labels">
            {product.labels.map((label, idx) => (
              <div key={idx} className={`label label-variant-${label.variant}`}>
                {label.title}
              </div>
            ))}
          </div>
        )}
        {product.description && <div className="description">{product.description}</div>}
        <p className="price">{product.price}</p>
      </div>
    </div>
  );
};

const AppCard = ({ app }) => {
  return (
    <div className="app-card">
      <div
        className="image"
        style={{ backgroundImage: app.image }}
        onClick={() => (window.location.href = app.storeUrl)}
      ></div>
      <div className="details">
        <h3 onClick={() => (window.location.href = app.storeUrl)}>{app.title}</h3>
        <div className="labels">
          <div className="label label-variant-8">app</div>
        </div>
        <div className="app-store">
          {app.appStoreUrl && (
            <a href={app.appStoreUrl}>
              <AppStore />
            </a>
          )}
          {app.playStoreUrl && (
            <a href={app.playStoreUrl}>
              <GooglePlay />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const LessonsAndSongsAppCard = ({ sm = false }) => {
  return (
    <div
      className={`lessons-and-songs-app${sm ? " sm" : ""}`}
      onClick={() => window.open("https://go.onelink.me/3065804798/f5df5605", "_blank").focus()}
    >
      <div className="center">
        <div className="logo"></div>
        <div className="app-store">
          <AppStore />
          <GooglePlay />
        </div>
      </div>
      <div className="details">
        <h3>JustinGuitar Lessons & Songs App</h3>
        <div className="labels">
          <div className="label label-variant-8">app</div>
          <div className="label label-variant-2">free trial</div>
        </div>
        <p className="price">Prices vary depending on local currency, taxes, and sales.</p>
      </div>
    </div>
  );
};

const WebSiteProductsTab = ({ width }) => {
  return <ProductCards products={PRODUCTS.websiteProducts} width={width} />;
};

const PhysicalProductsTab = ({ width }) => {
  return <ProductCards products={PRODUCTS.physicalProducts} width={width} />;
};

const DigitalDownloadsTab = ({ width }) => {
  return <ProductCards products={PRODUCTS.digitalDownloads} width={width} />;
};

const AppsTab = ({ width }) => {
  return (
    <>
      <LessonsAndSongsAppCard sm={width <= 1200} />
      <div className="apps">
        {PRODUCTS.apps.map((app, idx) => (
          <AppCard key={idx} app={app} />
        ))}
      </div>
    </>
  );
};

const FindWhatsBestForMeTab = ({ whatsBestActiveOption, setWhatsBestActiveOption, width }) => {
  const whatsBestItem = WHATS_BEST.find((item) => item.productTitle === whatsBestActiveOption);

  const product =
    whatsBestItem &&
    whatsBestItem.productType != "lessonsAndSongsApp" &&
    PRODUCTS[whatsBestItem.productType].find(
      (product) => product.title === whatsBestItem.productTitle
    );

  return (
    <div className="find-whats-best-for-me" id="choose">
      <div className="options-dropdown">
        <p>I want to </p>
        <select
          value={whatsBestActiveOption}
          onChange={(e) => {
            setWhatsBestActiveOption(e.target.value);
          }}
        >
          {WHATS_BEST.map((whatsBestItem, idx) => (
            <option key={idx} value={whatsBestItem.productTitle}>
              {whatsBestItem.text}
            </option>
          ))}
        </select>
      </div>
      {whatsBestItem.productType === "apps" && product ? (
        <AppCard app={product} />
      ) : ["websiteProducts", "digitalDownloads"].includes(whatsBestItem.productType) && product ? (
        <ProductCard product={product} width={width} />
      ) : (
        <LessonsAndSongsAppCard sm />
      )}
      <p className="about-product">{product.about}</p>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.currentUser,
    token: state.session.token,
    faqs: state.entity.faqs,
  };
};

const mapDispatchToProps = (dispatch) => ({});

const _Store = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withInnerWidth(({ width, currentUser, token, faqs }) => {
    const path = window.location.pathname.split("/").pop();
    const [activeTab, setActiveTab] = useState(
      [
        "website-products",
        "physical-products",
        "downloads",
        "apps",
        "find-whats-best-for-me",
      ].includes(path)
        ? path
        : "website-products"
    );
    const [whatsBestActiveOption, setWhatsBestActiveOption] = useState(WHATS_BEST[0].productTitle);

    const updateActiveTab = (name) => {
      window.history.pushState({}, "", `/store/${name}`);
      setActiveTab(name);
    };

    const getActiveTabFaqs = (id) => faqs.children.find((childFaq) => childFaq.id === id);

    const activeTabFaqs =
      activeTab === "website-products"
        ? getActiveTabFaqs(200)
        : activeTab === "physical-products"
        ? getActiveTabFaqs(405)
        : activeTab === "downloads"
        ? getActiveTabFaqs(301)
        : activeTab === "apps"
        ? getActiveTabFaqs(207)
        : null;

    return (
      <div className="store">
        <StoreSwoosh />
        <div className="container">
          {/*<div className="bf-banner">*/}
          {/*  <h3>Unlock JustinGuitar with our Ultimate Bundle</h3>*/}
          {/*  <p>*/}
          {/*    <strong>*/}
          {/*      Epic Sale - save up to USD 424.96 and get lifetime access all paid courses available*/}
          {/*      at JustinGuitar.com!*/}
          {/*    </strong>{" "}*/}
          {/*    Plus, unlock an exclusive offer to get lifetime access to my Lessons & Songs App and*/}
          {/*    more. :)*/}
          {/*  </p>*/}
          {/*  <div className="price">*/}
          {/*    <div className="old">USD 749.96</div>*/}
          {/*    <div className="new">USD 325</div>*/}
          {/*  </div>*/}
          {/*  <a href="/bundle" className="button button--primary">*/}
          {/*    Shop Now*/}
          {/*  </a>*/}
          {/*</div>*/}
          <div className="tabs">
            <Nav activeTab={activeTab} updateActiveTab={updateActiveTab} width={width} />
            <div className="content">
              {activeTab === "website-products" ? (
                <WebSiteProductsTab width={width} />
              ) : activeTab === "physical-products" ? (
                <PhysicalProductsTab width={width} />
              ) : activeTab === "downloads" ? (
                <DigitalDownloadsTab width={width} />
              ) : activeTab === "apps" ? (
                <AppsTab width={width} />
              ) : activeTab === "find-whats-best-for-me" ? (
                <FindWhatsBestForMeTab
                  whatsBestActiveOption={whatsBestActiveOption}
                  setWhatsBestActiveOption={setWhatsBestActiveOption}
                  width={width}
                />
              ) : (
                <WebSiteProductsTab width={width} />
              )}
            </div>
          </div>
          <div className="workshops" onClick={() => (window.location.href = "/workshops")}>
            <div className="content">
              <h3>JustinGuitar Workshops</h3>
              <div className="labels">
                <div className="label label-variant-2">live event</div>
              </div>
              <p>Price varies depending on location!</p>
            </div>
          </div>
          <div className="jg-friends">
            <div className="justin"></div>
            <h3>JustinGuitar Friends</h3>
            <p>
              My Beginner Guitar Course, and many others, are <strong>FREE</strong>!
              {width > 992 ? <br /> : " "}
              That's possible because of my JustinGuitar Friends.
            </p>
            <a href="/donate" className="button button--primary">
              learn more
            </a>
            <p className="info">
              I will give you most of my products for <strong>FREE</strong> if you genuinely can't
              afford it.
              {width > 992 ? <br /> : " "}
              <a href="mailto:hello@justinguitar.com">Email here</a> and we will get in touch to
              discuss if you qualify for free products.
            </p>
          </div>
          <div className="faq">
            <Category faqs={activeTabFaqs} />
          </div>
        </div>
      </div>
    );
  })
);

const Store = () => {
  const store = ReactOnRails.getStore("storeStore");

  return (
    <Provider store={store}>
      <_Store />
    </Provider>
  );
};

export default Store;
