import React, { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";

import Swoosh from "../../shared/Swoosh";
import PopularSongRequests from "./PopularSongRequests";
import SongRequestsCarousel from "./SongRequestsCarousel";
import SongRequestForm from "./SongRequestForm";
import Loading from "../../shared/Loading";
import axiosWithCSRF from "../../shared/axiosWithCSRF";
import { ACTION_TYPES, useUser, useUserDispatch } from "../contexts/userContext";
import { fetchUserVotes } from "../contexts/userActions";
import JoinPrompt from "./JoinPrompt";
import UserSongRequests from "./UserSongRequests";

export const SONG_REQUESTS_ENDPOINT = "/song_requests.json";

const SongRequests = ({ excludeTopMargin }) => {
  // This state needs to be handled here, rather than in the child components, due to a limitation of the Swoosh component that
  // does not seem to listen for height changes correctly.
  const [popularSongs, setPopularSongs] = useState([]);
  const [hasMorePopularSongs, setHasMorePopularSongs] = useState(true);
  const [loadingPopularSongs, setLoadingPopularSongs] = useState(false);
  const [query, setQuery] = useState(null);
  const [debouncedQuery, setDebouncedQuery] = useState(null);

  const user = useUser();
  const dispatch = useUserDispatch();

  useEffect(() => {
    fetchSongRequests();
    fetchUserSongRequestVotes();
  }, []);

  const fetchUserSongRequestVotes = () => {
    if (!user) return;
    fetchUserVotes().then((response) => {
      dispatch({
        type: ACTION_TYPES.SET_SONG_VOTES,
        userSongVotes: response.data.votes?.data || [],
      });
    });
  };

  useEffect(() => {
    if (debouncedQuery === null || (debouncedQuery.length > 0 && debouncedQuery.length < 3)) return;

    fetchSongRequests();
  }, [debouncedQuery]);

  const debouncedSearch = useMemo(
    () =>
      debounce((e) => {
        setDebouncedQuery(e.target.value);
      }, 500),
    []
  );

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    debouncedSearch(e);
  };

  const clearQuery = () => {
    setQuery("");
    setDebouncedQuery("");
  };

  const updateVoteCount = (songRequestId, amount) => {
    setPopularSongs((songs) =>
      songs.map((song) => {
        if (Number(song.id) === Number(songRequestId)) {
          return {
            ...song,
            attributes: { ...song.attributes, voteCount: song.attributes.voteCount + amount },
          };
        }
        return song;
      })
    );
  };

  function fetchSongRequests(replaceResults = true) {
    setLoadingPopularSongs(true);
    const offset = replaceResults ? 0 : popularSongs.length;
    const searchParams = query ? `&search_term=${query}` : "";
    axiosWithCSRF()
      .get(`${SONG_REQUESTS_ENDPOINT}?scope=popular&offset=${offset}${searchParams}`)
      .then((response) => {
        if (replaceResults) {
          setPopularSongs(response.data.songRequests.data);
        } else {
          setPopularSongs((popularSongs) => [...popularSongs, ...response.data.songRequests.data]);
        }
        setHasMorePopularSongs(response.data.hasMore);
      })
      .finally(() => {
        setLoadingPopularSongs(false);
      });
  }

  if (popularSongs.length === 0) {
    return (
      <div>
        <Loading isContent={true} />
      </div>
    );
  }

  return (
    <Swoosh gradient="nightSky" excludeTopMargin={excludeTopMargin}>
      <div className="song-requests container">
        <h2 className="header2">Top Song Requests For Justin</h2>

        <PopularSongRequests
          query={query}
          setQuery={handleQueryChange}
          songRequests={popularSongs}
          hasMore={hasMorePopularSongs}
          fetchSongRequests={fetchSongRequests}
          loadingMoreResults={loadingPopularSongs}
          clearQuery={clearQuery}
          updateVoteCount={updateVoteCount}
        />
        <SongRequestsCarousel />

        {user ? <SongRequestForm /> : <JoinPrompt />}

        <UserSongRequests />
      </div>
    </Swoosh>
  );
};

export default SongRequests;
