import React from "react";
import axiosWithCSRF from "../components/shared/axiosWithCSRF";
import { HalfStar, Star, Exit, Stars, AppIcon } from "./icons";
import { bannerVersions } from "./bannerVersions";
import SingleLineBanner from "./SingleLineBanner";
import platform from "platform-detect";

export default class AppBanner extends React.Component {
  constructor(props) {
    super(props);
    this.localStorageKey = "jg-app-banner";
    this.lastCloseKey = "closedAt";
    this.numberOfClosesKey = "numberOfCloses";
    this.onelink = "https://justinguitarapp.onelink.me/3065804798/83b0426f";
    this.state = { banner: null };
  }

  componentDidMount() {
    if ((platform.ios || platform.android) && this.isEligibleForDisplay()) {
      const rand = Math.floor(Math.random() * bannerVersions.length);
      const banner = bannerVersions[rand];
      this.setState({ banner }, () => {
        if (banner.url) {
          this.onelink = banner.url;
        }

        axiosWithCSRF()
          .post("/app_banner_views", {
            app_banner_view: {
              identifier: this.state.banner.identifier,
            },
          })
          .then((res) => {
            this.setState({ appBannerViewId: res.data.appBannerViewId });
          });
      });
    }
  }

  seenWithinAMonth(previousExit) {
    if (this.eligibleToShowAgain(previousExit, 30)) {
      try {
        localStorage.removeItem(this.localStorageKey);
        return true;
      } catch {
        return true;
      }
    } else {
      return false;
    }
  }

  isEligibleForDisplay() {
    try {
      let data = JSON.parse(localStorage.getItem(this.localStorageKey));

      let previousExit = data ? data[this.lastCloseKey] : null;
      let closeCount = data ? data[this.numberOfClosesKey] : 0;

      if (parseInt(closeCount) === 3) {
        return this.seenWithinAMonth(previousExit);
      } else if (previousExit) {
        return this.eligibleToShowAgain(previousExit, 1);
      } else {
        return true;
      }
    } catch {
      return true;
    }

    return false;
  }

  eligibleToShowAgain(exitTime, numberOfDays) {
    let now = new Date();
    let eligibleTime = new Date(exitTime);
    eligibleTime.setDate(eligibleTime.getDate() + numberOfDays);
    if (now >= eligibleTime) {
      return true;
    } else {
      return false;
    }
  }

  storeExit() {
    try {
      let data = Object.assign({}, JSON.parse(localStorage.getItem(this.localStorageKey)));
      data[this.lastCloseKey] = new Date();
      data[this.numberOfClosesKey] = (parseInt(data[this.numberOfClosesKey]) || 0) + 1;
      localStorage.setItem(this.localStorageKey, JSON.stringify(data));
    } catch {
      /* no-op */
    }
  }

  logAndGoToOnelink = (e) => {
    const { appBannerViewId } = this.state;
    if (appBannerViewId) {
      axiosWithCSRF().patch(`/app_banner_views/${appBannerViewId}`, {
        app_banner_view: { was_clicked: true },
      });
    }
    this.closeBanner(e);
    window.location.href = this.onelink;
  };

  closeBanner = (e) => {
    e.stopPropagation();
    this.storeExit();
    this.setState({ banner: null });
  };

  render() {
    const { banner } = this.state;
    if (banner) {
      const { layout } = banner;
      return layout === "singleLine" ? (
        <SingleLineBanner
          banner={banner}
          closeBanner={this.closeBanner}
          logAndGoToOnelink={this.logAndGoToOnelink}
        />
      ) : (
        <TwoLineBanner banner={banner} />
      );
    } else {
      return null;
    }
  }
}
