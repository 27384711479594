import { connect } from "react-redux";

import { clearFilters, fetchSearchResults, selectHasActiveFilters, toggleFilter } from "./store";
import FiltersList from "../shared/FiltersList";
import { filters } from "../shared/search";

export default connect(
  (state) => ({
    availableFilters: filters,
    activeFilters: state.filters,
    hasActiveFilters: selectHasActiveFilters(state),
  }),
  (dispatch) => ({
    closeFilters: () => {
      /*no-op*/
    },
    clearFilters: () => {
      dispatch(clearFilters());
      dispatch(fetchSearchResults());
    },
    toggleFilter: (option) => {
      dispatch(toggleFilter(option));
      dispatch(fetchSearchResults());
    },
  })
)(FiltersList);
