import React from "react";
import PropTypes from "prop-types";
import axiosWithCSRF from "../shared/axiosWithCSRF";
import ReactDOM from "react-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Loading from "../shared/Loading";
import CheckEmailPrompt from "./CheckEmailPrompt";
import Recaptcha from "react-recaptcha";
import loadScript from "../shared/loadScript";

const EmailSchema = Yup.object().shape({
  email: Yup.string().email("Please provide a valid email.").required("Email address is required."),
});

export default class PasswordAndConfirmationManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recaptchaToken: "",
    };
    this.recaptchaInstance = React.createRef();
  }

  componentDidMount() {
    loadScript("https://www.google.com/recaptcha/api.js");
  }

  handleSubmit = (values, setErrors) => {
    let url;
    if (this.props.form === "forgotPassword") {
      url = "/users/password";
    } else if (this.props.form === "resendConfirmation") {
      url = "/users/confirmation";
    }
    this.setState({ isSubmitting: true }, () => {
      axiosWithCSRF({ "Content-Type": "application/json", Accept: "application/json" })
        .post(url, {
          user: { email: values.email },
          ["g-recaptcha-response"]: this.state.recaptchaToken,
        })
        .then(() => {
          this.setState({ isSubmitting: false, success: true });
        })
        .catch((error) => {
          let message = "There was a problem sending an email. Please try again later.";
          if (error.response.data && error.response.data.error) {
            message = error.response.data.error;
          }
          this.setState({ isSubmitting: false, error: message }, () => {
            setErrors({ email: message });
            this.recaptchaInstance.current.reset();
          });
        });
    });
  };

  headerContent = () => {
    let messageType = "reset link";
    if (this.props.form === "resendConfirmation") {
      messageType = "confirmation link";
    }
    return <p>Enter your email below and we will send you a {messageType}.</p>;
  };

  recaptchaCallback = () => {
    // for some reason verifyCallback is only working if onloadCallback is defined, so define it here and do nothing.
    return;
  };

  recaptcha = () => {
    // verifyCallback is only working with onLoad
    return (
      <Recaptcha
        render="explicit"
        ref={this.recaptchaInstance}
        sitekey={this.props.recaptchaKey}
        onloadCallback={this.recaptchaCallback}
        verifyCallback={this.recaptchaVerified}
      />
    );
  };

  recaptchaVerified = (recaptchaToken) => {
    this.setState({ recaptchaToken });
  };

  render() {
    if (this.state.success) {
      return (
        <CheckEmailPrompt
          firstSentence={`We've sent a ${
            this.props.form === "resendConfirmation" ? "confirmation" : "password reset"
          } \
            link to the email address you provided!`}
          secondSentence="Here are quick links to common email services."
          hasReminder={false}
        />
      );
    } else {
      return (
        <div className="auth">
          <div className="auth__container forgot-password">
            {this.state.isSubmitting && <Loading />}
            {this.props.form === "forgotPassword" && (
              <h3 className="header3 uppercase login-header">Forgot Your Password?</h3>
            )}
            {this.props.form === "resendConfirmation" && (
              <h3 className="header3 uppercase login-header">Resend Password Confirmation</h3>
            )}

            {this.headerContent()}
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={EmailSchema}
              onSubmit={(values, { setErrors }) => {
                this.handleSubmit(values, setErrors);
              }}
            >
              {(props) => (
                <Form className="auth__form">
                  <div className="auth__input">
                    <Field
                      name="email"
                      type="email"
                      className={
                        (props.values.email.length > 0 ? "populated" : "") +
                        (props.errors.email && props.touched.email ? " has-error" : "")
                      }
                    />

                    {props.touched.email && !props.errors.email && (
                      <i className="auth__response-icon icon icon-check"></i>
                    )}
                    <span className="auth__label">Email</span>
                    {props.errors.email && props.touched.email && (
                      <>
                        <i className="auth__response-icon icon icon-cancel-circle"></i>
                        <div className="error-message">{props.errors.email}</div>
                      </>
                    )}
                  </div>

                  <div className="auth__recaptcha">{this.recaptcha()}</div>

                  <button
                    disabled={
                      props.values.email.length === 0 ||
                      props.errors.email ||
                      this.state.recaptchaToken.length === 0
                    }
                    className="button button--primary auth__btn"
                    type="submit"
                  >
                    Submit
                  </button>
                  <div className="auth__signup">
                    <p>
                      <a className="clickable" href="/users/sign_in">
                        Go to Log In
                      </a>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      );
    }
  }
}
