import { createSelector } from "@reduxjs/toolkit";

export const selectState = (state) => state;
export const selectAlert = createSelector(selectState, ({ ui }) => ui.alert);

export const selectHasAlert = createSelector(
  selectAlert,
  (alert) => alert && alert.message != null && alert.message.length > 0
);

export const selectAlertMessage = createSelector(selectAlert, (alert) => alert && alert.message);
export const selectAlertSeverity = createSelector(selectAlert, (alert) => alert && alert.severity);
