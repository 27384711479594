import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";

import FilterOption from "./FilterOption";
import onClickOutside from "react-onclickoutside";

const FilterGroup = ({ type, options, info, activeFilters, toggleFilter, defaultClosed }) => {
  const [groupOpen, setGroupOpen] = useState(
    Object.keys(activeFilters).some((key) => options.includes(key))
  );
  const handleToggle = (event) => toggleFilter(event.currentTarget.dataset.option);

  return (
    <div className="filters__group">
      <div
        className="filters__group-header"
        onClick={() => setGroupOpen(!groupOpen)}
        style={{ cursor: "pointer" }}
      >
        <span>{type}</span>
        <span>
          <i className={`glyphicon glyphicon-chevron-${groupOpen ? "up" : "down"}`} />
        </span>
      </div>
      <CSSTransition in={groupOpen} timeout={200} classNames="verticalGrowInOut">
        {groupOpen ? (
          <div className="verticalGrowInOut">
            <>
              {info && (
                <div className="filter-group-info">
                  <i className="icon icon-info" />
                  <span>{info}</span>
                </div>
              )}
              {options.map((option) => (
                <FilterOption
                  active={activeFilters[option] === true}
                  option={option}
                  toggleFilter={handleToggle}
                  key={option}
                />
              ))}
            </>
          </div>
        ) : (
          <div />
        )}
      </CSSTransition>
    </div>
  );
};

FilterGroup.propTypes = {
  activeFilters: PropTypes.objectOf(PropTypes.bool).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  toggleFilter: PropTypes.func.isRequired,
};

const FiltersList = ({
  availableFilters,
  hasActiveFilters,
  clearFilters,
  closeFilters,
  activeFilters,
  toggleFilter,
  buttonRef,
  defaultClosed,
}) => {
  FiltersList.handleClickOutside = (e) => {
    if (buttonRef && buttonRef.current && buttonRef.current.contains(e.target)) {
      // user clicked filter button; button will handle closing filters
      return;
    }
    closeFilters();
  };
  return (
    <div className="filters__list">
      <div className="filters__list-header">
        <div>
          <span>
            <i className="icon icon-sliders" />
          </span>
          <span>filters</span>
        </div>
        {hasActiveFilters && (
          <button onClick={clearFilters} className="button button--no-background">
            Clear <i className="icon icon-cross" />
          </button>
        )}
      </div>
      {availableFilters.map(({ type, options, info }) => (
        <FilterGroup
          type={type}
          info={info}
          options={options}
          activeFilters={activeFilters}
          toggleFilter={toggleFilter}
          key={type}
          defaultClosed={defaultClosed}
        />
      ))}
      <div className="filters__action-buttons">
        {hasActiveFilters && (
          <button className="button filter-button filter-button--clear" onClick={clearFilters}>
            CLEAR ALL
          </button>
        )}
        <button className="button filter-button filter-button--close" onClick={closeFilters}>
          DONE
        </button>
      </div>
    </div>
  );
};

FiltersList.propTypes = {
  availableFilters: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  activeFilters: PropTypes.objectOf(PropTypes.bool).isRequired,
  hasActiveFilters: PropTypes.bool.isRequired,
  closeFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  buttonRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }), // react ref
  defaultClosed: PropTypes.bool,
};

export default onClickOutside(FiltersList, {
  handleClickOutside: () => FiltersList.handleClickOutside,
});
