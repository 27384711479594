import React from "react";

const Product = ({ product, buyProduct }) => {
  return (
    <div className="product">
      <div className="background"></div>
      <h3>{product.title}</h3>
      <p>{product.price}</p>
      <img src={product.image} alt={product.title} />
      {!product.hasPurchased ? (
        <button className={product.buyBtnClass} onClick={() => buyProduct(product)}>
          {product.buyBtnTitle}
        </button>
      ) : product.reference === "101childsongsbundle" ? (
        <div className="bundle-buttons">
          <button
            className="button button--primary ukulele"
            onClick={() => window.open(product.downloadUrl.ukulele, "_blank")}
          >
            download ukulele e-book
          </button>
          <button
            className="button button--primary guitar"
            onClick={() => window.open(product.downloadUrl.guitar, "_blank")}
          >
            download guitar e-book
          </button>
        </div>
      ) : (
        <button
          className={`button button--primary ${product.buyBtnClass}`}
          onClick={() => window.open(product.downloadUrl, "_blank")}
        >
          download e-book
        </button>
      )}
    </div>
  );
};

export default Product;
