import React from "react";
import PropTypes from "prop-types";

const PracticeItemToSave = ({
  selectedItem,
  item,
  addItem,
  setSelectedItem,
  relatedLink,
  loading,
}) => {
  return (
    <div className="practice-item" key={item.id}>
      <h5 className="header5 uppercase bold">{item.attributes.title}</h5>
      <p>{item.attributes.description}</p>
      {selectedItem === item ? (
        <div className="practice-item__options">
          <button
            disabled={!!loading}
            className="button button--blue"
            onClick={() => {
              addItem({ practiceItemId: item.id, addToActiveRoutine: true, relatedLink });
            }}
          >
            Add To Your Current Routine
          </button>

          <button
            disabled={!!loading}
            className="button button--dark"
            onClick={() =>
              addItem({
                practiceItemId: item.id,
                addToActiveRoutine: false,
                relatedLink,
              })
            }
          >
            Add To Your Practice Items
          </button>
          <button className="button button--danger" onClick={() => setSelectedItem(null)}>
            cancel
          </button>
        </div>
      ) : (
        <div className="practice-item__options">
          <button className="button button--primary" onClick={() => setSelectedItem(item)}>
            Add To Your Practice Routine
          </button>
        </div>
      )}
    </div>
  );
};

PracticeItemToSave.propTypes = {
  selectedItem: PropTypes.object,
  item: PropTypes.object.isRequired,
  addItem: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default PracticeItemToSave;
