import React from "react";
import { JgCarousel } from "../shared/Carousel";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { Box } from "@mui/material";
import { CDN_URL } from "../../../components/shared/CdnUrl";

const images: string[] = [
  "course-electric-05.png",
  "course-electric-03.png",
  "IMG_6502-4 2.png",
  "course-electric-04 1.png",
  "course-electric-02 1.png",
];

export const ElectricJamCarousel = () => {
  return (
    <JgCarousel slickSettings={{ slidesToShow: 1 }}>
      {images.map((imgSrc) => (
        <Box
          key={imgSrc}
          component="img"
          src={`${CDN_URL}/redesign/workshops/electric-jam-carousel/${imgSrc}`}
          borderRadius="10px"
          marginBottom={jgDenimTheme.spacing(3)}
        />
      ))}
    </JgCarousel>
  );
};
