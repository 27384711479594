import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

import { jgDenimColors } from "../../../jg-material/theme/jg-denim-colors";

export interface JgCarouselProps {
  heading?: string;
  slickSettings?: object;
  children: any; // todo: better typing
}

export const JgCarousel = ({ heading, slickSettings = {}, children }: JgCarouselProps) => {
  const [currIdx, setCurrIdx] = React.useState(0);

  const settings = {
    ...defaultSlickSettings,
    ...slickSettings,
  };
  return (
    <Box>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {heading != null && (
        <Typography variant="h4" textAlign="center" padding={jgDenimTheme.spacing(6, 0, 4)}>
          {heading}
        </Typography>
      )}
      <Box mb={4} pb={4}>
        <Box position="relative" mr={"-5px"} ml={"-5px"}>
          <Slider
            {...settings}
            infinite={true /*https://github.com/kenwheeler/slick/issues/940*/}
            initialSlide={currIdx}
            afterChange={setCurrIdx}
          >
            {children}
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};

const SlickArrow = ({
  direction,
  onClick,
}: {
  direction: "next" | "prev";
  onClick: () => void;
}) => (
  <Box
    position="absolute"
    bottom={"-50px"}
    left={"50%"}
    sx={{
      // transform: `translateX(${direction === "next" ? "50px" : "-50px"})`,
      transform: `translateX(calc(-50% ${direction === "next" ? "+ 50px" : "- 50px"}))`,
    }}
  >
    <Button variant={"text"} size={"small"} onClick={onClick}>
      {direction === "next" ? (
        <ArrowForwardIos sx={{ color: jgDenimColors.accent.orange.main }} />
      ) : (
        <ArrowBackIos sx={{ color: jgDenimColors.accent.orange.main }} />
      )}
    </Button>
  </Box>
);

const NextArrow = (props) => <SlickArrow direction="next" {...props} />;
const PrevArrow = (props) => <SlickArrow direction="prev" {...props} />;

const defaultSlickSettings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};
