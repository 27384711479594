import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import ParentGroupVideo from "./ParentGroupVideo";
import { strippedText } from "./ParentGroupUtils";

const ParentGroupIntro = ({ parentGroup }) => {
  const [introductionOpen, setIntroductionOpen] = useState(false);

  const description = (teaser, fullText) => {
    return teaser ? teaser : strippedText(fullText, 400);
  };

  const {
    data: { attributes: { introductionVideo, introductionText, introductionTeaser } } = ({} = {}),
  } = parentGroup;

  return (
    <div className="inner-offset pg__top">
      <h2 className="uppercase header2 text-center section-header">About This Course</h2>

      <div className="pg__intro">
        <div className="pg__flex">
          {introductionVideo && <ParentGroupVideo url={introductionVideo} />}

          <div
            className={"pg__description " + (introductionVideo ? "pg__description--padded" : "")}
          >
            <p>{description(introductionTeaser, introductionText)}</p>
            <span
              className="uppercase bold description-toggle"
              onClick={() => setIntroductionOpen(!introductionOpen)}
            >
              {introductionOpen ? (
                <>
                  Read Less <i className="icon icon-angle-up" />
                </>
              ) : (
                <>
                  Read More <i className="icon icon-angle-down" />
                </>
              )}
            </span>
          </div>
        </div>

        <CSSTransition
          key="pg-intro"
          in={introductionOpen}
          timeout={200}
          classNames="pg-lessons-list-animation"
        >
          <div>
            {introductionOpen && (
              <div className="pg__longtro">
                <div className="styleized" dangerouslySetInnerHTML={{ __html: introductionText }} />
              </div>
            )}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

export default ParentGroupIntro;
