import React from "react";
import { Provider } from "react-redux";
import { withInnerWidth } from "../shared";
import SearchHeader from "./Search/SearchHeader";
import LargeHeader from "./LargeHeader";
import MediumHeader from "./MediumHeader";
import SmallHeader from "./SmallHeader";

function Header(props) {
  const store = ReactOnRails.getStore("searchStore");
  const { width } = props;
  const isProductBundlesPage = window.location.pathname.includes("/bundle");
  return (
    <Provider store={store}>
      <div className="header container">
        {width > 992 ? (
          // <LazyLoadedComponent>
          <LargeHeader
            logoSize={width >= 1200 ? "large" : "small"}
            isProductBundlesPage={isProductBundlesPage}
          />
        ) : // </LazyLoadedComponent>
        width > 576 ? (
          // <LazyLoadedComponent>
          <MediumHeader isProductBundlesPage={isProductBundlesPage} />
        ) : (
          // </LazyLoadedComponent>
          // <LazyLoadedComponent>
          <SmallHeader isProductBundlesPage={isProductBundlesPage} />
          // </LazyLoadedComponent>
        )}
      </div>
      <SearchHeader />
    </Provider>
  );
}

export default withInnerWidth(Header);
