import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { createSongRequest, selectMostRecentSongRequest } from "../store";
import SongRequestResult from "./SongRequestResult";
import { LightTooltip } from "../../shared/ui/Tooltips";

import * as ui from "../../shared/ui/uiSlice";

const oneWeekInMilliseconds = 604800000;

export default function () {
  const dispatch = useDispatch();
  const mostRecentRequest = useSelector(selectMostRecentSongRequest);
  const [title, setTitle] = React.useState("");
  const [artist, setArtist] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const nextPossibleVoteTime =
    mostRecentRequest == null
      ? 0
      : new Date(mostRecentRequest.attributes.createdAt).getTime() + oneWeekInMilliseconds;
  const isRateLimited = nextPossibleVoteTime > Date.now();
  const isDisabled = isRateLimited || title.length === 0 || artist.length === 0;

  const submit = () => {
    dispatch(createSongRequest({ title, artist }));
    setSubmitted(true);
  };

  const clearForm = () => {
    setSubmitted(false);
    setTitle("");
    setArtist("");
    dispatch(ui.actions.setSongRequestConflicts(ui.initialState.songRequestConflicts));
  };

  return (
    <div className="new-song-request">
      <h4 className="heading4">submit a song request</h4>
      {submitted ? (
        <SongRequestResult clearForm={clearForm} />
      ) : (
        <div className="new-song-request__form">
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title"
          />

          <input
            type="text"
            value={artist}
            onChange={(e) => setArtist(e.target.value)}
            placeholder="Artist"
          />
          <LightTooltip
            title={
              isRateLimited
                ? `You're allowed to submit once per week. You can submit another song on ${new Date(
                    nextPossibleVoteTime + oneWeekInMilliseconds / 7
                  ).toLocaleString("en-US", {
                    weekday: "long",
                    month: "long",
                    day: "numeric",
                  })}`
                : ""
            }
          >
            <div>
              <button className="button button--primary" disabled={isDisabled} onClick={submit}>
                Submit Song
              </button>
            </div>
          </LightTooltip>
        </div>
      )}
    </div>
  );
}
