import React from "react";
import { connect } from "react-redux";
import { setSelectedChordColor } from "../../store/songStore";

const ColorChords = ({ selectedChordColor, setSelectedChordColor }) => {
  return (
    <li className="clickable submenu-item">
      <div className="change-option">
        <div className="change-option-label">Color</div>
        <div className="change-option-action">
          {["default", "yellow", "green", "blue", "purple", "red"].map((c) => {
            return (
              <div className="color-contain" key={c}>
                <span
                  className={
                    `chord-color chord-color--${c} ` +
                    (selectedChordColor === c ? "active-color" : "")
                  }
                  onClick={() => setSelectedChordColor(c)}
                />
                {selectedChordColor === c && (
                  <span onClick={() => setSelectedChordColor(c)} className="checkmark" />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </li>
  );
};

const mapStateToProps = (state) => ({ selectedChordColor: state.entity.selectedChordColor });

const mapDispatchToProps = (dispatch) => ({
  setSubMenuTab: (tab) => dispatch(setSubMenuTab(tab)),
  setSelectedChordColor: (color) => dispatch(setSelectedChordColor(color)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ColorChords);
