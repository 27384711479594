import { schema } from "normalizr";

export const song = new schema.Entity("songs");
export const product = new schema.Entity("products");
export const artist = new schema.Entity("artists");
export const genre = new schema.Entity("genres");
export const tag = new schema.Entity("tags");
export const grade = new schema.Entity("grades");
export const chord = new schema.Entity("chords");
export const songRequest = new schema.Entity("songRequests");

// export const songsJson = {
//   data: [song],
//   included: new schema.Array(
//     { product, artist, genre, tag, grade, chord },
//     (entity) => entity.type
//   ),
// };

export const artistsJson = {
  data: [artist],
  included: new schema.Array({ song, product, genre, tag, grade, chord }, (entity) => entity.type),
};

export const songRequestsJson = {
  pendingRequests: { data: [songRequest] },
  selectedRequests: { data: [songRequest] },
  completedRequests: { data: [songRequest] },
};
