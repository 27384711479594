import React from "react";

const MarkAsCompleteForLessonsWithNoVideo = ({
  lesson,
  toggleCompleted,
  completedLessonIds,
  width,
}) => {
  const isCompleted = completedLessonIds.includes(parseInt(lesson.data.id));

  return (
    <div className="lesson-complete-and-info">
      <button
        className={"lesson-complete-btn " + (isCompleted ? "completed" : "")}
        onClick={() => toggleCompleted(isCompleted, lesson.data.id)}
      >
        <i className={`icon icon-check${isCompleted ? "_circle_outline" : "-circle-outline"}`} />
        {`${
          isCompleted
            ? width <= 992
              ? "Completed"
              : "Lesson completed"
            : width <= 992
            ? "Complete"
            : "Mark lesson as complete"
        }`}
      </button>
    </div>
  );
};

export default MarkAsCompleteForLessonsWithNoVideo;
