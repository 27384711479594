import React from "react";
import axios from "axios";
import ActivateRoutine from "./Actions/ActivateRoutine";
import CreateRoutine from "./Actions/CreateRoutine";
import DeleteRoutine from "./Actions/DeleteRoutine";
import EditRoutine from "./Actions/EditRoutine";
import AdminIntroduction from "../General/AdminIntroduction";
import PracticeIcons from "../PracticeIcons";

export default class RoutineContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    if (this.formTimeout) {
      clearTimeout(this.formTimeout);
    }
  }

  addToInactiveRoutines = (routine) => {
    let inactiveRoutines = [...this.state.inactiveRoutines].concat(routine);
    this.setState({ inactiveRoutines });
    this.closeForm();
  };

  changeActiveRoutine = (activeRoutine) => {
    if (activeRoutine) {
      this.props.changeActiveRoutine(activeRoutine);
      this.closeForm();
    } else {
      this.setState({ errorText: "There was a problem changing your routine." }, () => {
        this.formTimeout = setTimeout(() => this.setState({ errorText: null }));
      });
    }
  };

  changeActiveRoutineWhenUserHasOptions = (routine) => {
    axios
      .patch(`/user_practice_routines/change_active_routine/${routine.id}`, {
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((response) => {
        this.props.changeActiveRoutine(response.data.routine);
      })
      .catch((error) => {
        this.setState({ errorText: "There was a problem changing your routine." }, () => {
          this.formTimeout = setTimeout(() => this.setState({ errorText: null }));
        });
      });
  };

  removeRoutineById = (routineId) => {
    if (routineId) {
      this.props.removeRoutineById(routineId);
    } else {
      this.handleError("There was a problem deleting this routine.");
    }
  };

  updateRoutineItems = (routineId, items, errorMessage = null) => {
    if (routineId && items) {
      this.props.handleChangedItemsWithinRoutine(routineId, items);
      this.setState({ responseText: "Successfully updated your routine!" }, () => {
        this.formTimeout = setTimeout(() => {
          this.closeForm();
        }, 2000);
      });
    } else {
      this.handleError(errorMessage);
    }
  };

  handleNewRoutine = (routine, errorMessage = null) => {
    if (routine) {
      this.props.handleNewRoutine(routine);
    } else {
      this.handleError(errorMessage);
    }
  };

  handleError = (message) => {
    this.setState({ errorText: message }, () => {
      this.formTimeout = setTimeout(() => {
        this.closeForm();
      }, 4000);
    });
  };

  responseText = () => {
    if (this.state.responseText) {
      return <p className="success">{this.state.responseText} </p>;
    } else if (this.state.errorText) {
      return <p className="has-errors">{this.state.errorText}</p>;
    }
  };

  toggleRoutineForm = (action, routineId) => {
    let { routineFormAction, routineIdToChange } = this.state;
    if (routineFormAction === action && routineIdToChange === routineId) {
      this.closeForm();
    } else {
      this.setState({ listOpen: false, routineFormAction: action, routineIdToChange: routineId });
    }
  };

  closeForm = () => {
    this.setState({
      routineFormAction: null,
      routineIdToChange: null,
      listOpen: false,
      responseText: null,
    });
  };

  isBeingEdited = (routineId) => {
    let { routineIdToChange } = this.state;
    return routineIdToChange === routineId ? true : false;
  };

  routineItemList = (routine) => {
    let items = routine.items;
    if (items.length > 0) {
      items.sort((a, b) => (a.position < b.position ? -1 : 1));
      return items.map((item, index) => {
        return (
          <div key={item.id} className="items-list__item">
            <span className="items-list__number">{index + 1}</span>
            <span>
              {item.title} - {item.duration} mins
            </span>
          </div>
        );
      });
    } else {
      return (
        <div className="items-list__item">
          <span>No items!</span>
        </div>
      );
    }
  };

  showRoutineForm = (routine) => {
    let { routineFormAction, routineIdToChange } = this.state;
    if (routineIdToChange === routine.id) {
      switch (routineFormAction) {
        case "Activate":
          return (
            <div>
              {this.responseText()}
              <ActivateRoutine
                closeForm={this.closeForm}
                changeActiveRoutine={this.changeActiveRoutine}
                routineId={routine.id}
              />
            </div>
          );
        case "Edit":
          return (
            <div>
              {this.responseText()}
              <EditRoutine
                closeForm={this.closeForm}
                routine={routine}
                updateRoutineTitle={this.props.updateRoutineTitle}
                practiceItems={this.props.practiceItems}
                updateRoutineItems={this.updateRoutineItems}
              />
            </div>
          );
        case "Delete":
          return (
            <div>
              {this.responseText()}
              <DeleteRoutine
                closeForm={this.closeForm}
                removeRoutineById={this.removeRoutineById}
                routineId={routine.id}
              />
            </div>
          );
        case "List":
          return (
            <div>
              {routineIdToChange === this.props.activeRoutine.id && this.activeRoutineHeading()}
              {this.routineItemList(routine)}
            </div>
          );
      }
    }
  };

  showRoutineTitle = (routine) => {
    let { routineFormAction, routineIdToChange } = this.state;
    if (routineIdToChange === routine.id && routineFormAction === "Edit") {
      return null;
    } else {
      return <span className="routine__title">{routine.title}</span>;
    }
  };

  inactiveListItem = (routine) => {
    return (
      <div
        key={routine.id}
        className={
          "routine__item routine__item--inactive " +
          (this.isBeingEdited(routine.id) ? "routine__item--editing" : "")
        }
      >
        <div>
          {this.showListIcon(routine.id)}
          {this.showRoutineTitle(routine)}
        </div>

        <div className="routine__item__options">
          <button
            type="button"
            title="Edit Routine"
            alt="Edit Routine"
            className="practice__icon practice__icon--edit"
            onClick={() => this.toggleRoutineForm("Edit", routine.id)}
            dangerouslySetInnerHTML={{ __html: PracticeIcons.editButton() }}
          ></button>
          <button
            type="button"
            title="Make This Routine Active"
            alt="Activate Routine"
            className="practice__icon"
            onClick={() => this.toggleRoutineForm("Activate", routine.id)}
            dangerouslySetInnerHTML={{ __html: PracticeIcons.addButton() }}
          ></button>
          <button
            type="button"
            title="Delete Routine"
            alt="Delete Routine"
            className="practice__icon"
            onClick={() => this.toggleRoutineForm("Delete", routine.id)}
            dangerouslySetInnerHTML={{ __html: PracticeIcons.deleteButton() }}
          ></button>
        </div>

        {this.showRoutineForm(routine)}
      </div>
    );
  };

  routineItem = (item) => {
    let { practiceItems } = this.props;
    let practiceItem = practiceItems.filter((p) => p.id === item.id)[0];
    if (practiceItem) {
      return (
        <div key={`routine-item-${practiceItem.id}`} className="active-routine__list-item">
          {this.activeListItem(practiceItem)}
        </div>
      );
    }
  };

  individualItem = (item, routineId) => {
    let practiceItem = this.props.practiceItems.filter(
      (i) => i.id === item.id && i.status === "active"
    )[0];
    if (practiceItem) {
      return (
        <div key={`routine-${routineId}-item-${item.id}`}>
          <span>
            {practiceItem.title} - {practiceItem.duration} mins
          </span>
        </div>
      );
    }
  };

  toggleList = (routineId) => {
    let { routineIdToChange, listOpen } = this.state;
    if (routineIdToChange === routineId && listOpen === true) {
      this.setState({ routineIdToChange: null, listOpen: false });
    } else {
      this.setState({ routineIdToChange: routineId, listOpen: true, routineFormAction: "List" });
    }
  };

  showListIcon = (routineId) => {
    if (
      routineId === this.state.routineIdToChange &&
      this.state.listOpen &&
      this.state.routineFormAction === "List"
    ) {
      return <i className="icon icon-minus" onClick={() => this.toggleList(routineId)}></i>;
    } else if (routineId === this.state.routineIdToChange) {
      return null;
    } else {
      return <i className="icon icon-plus" onClick={() => this.toggleList(routineId)}></i>;
    }
  };

  noRoutineText = () => {
    if (this.props.routines.length === 0) {
      return (
        <div className="admin__introduction">
          <h2>Create your first practice routine.</h2>
          <div className="new-routines">
            <h2>New Routine</h2>
            <CreateRoutine handleNewRoutine={this.handleNewRoutine} errors={this.state.errorText} />
          </div>
        </div>
      );
    } else {
      return (
        <div className="admin__introduction">
          <h5>You don't have an active routine. Choose one below to make active.</h5>
          <div className="practice-items__container">
            <div className="practice-items__left">{this.optionsToSetActiveRoutine()}</div>
          </div>
        </div>
      );
    }
  };

  optionsToSetActiveRoutine = () => {
    let { routines } = this.props;
    return routines.map((r) => {
      return (
        <div key={r.id} className="routine__item routine__item--small">
          <div>
            <span className="routine__title">{r.title}</span>
          </div>
          <div className="routine__item__options">
            <button
              className="practice__icon practice__icon--add"
              onClick={() => this.changeActiveRoutineWhenUserHasOptions(r)}
            >
              <span dangerouslySetInnerHTML={{ __html: PracticeIcons.addButton() }} />
            </button>
          </div>
        </div>
      );
    });
  };

  activeRoutineDisplay = () => {
    let { activeRoutine } = this.props;
    if (this.isBeingEdited(activeRoutine.id)) {
      return <div>{this.showRoutineForm(activeRoutine)}</div>;
    } else {
      return this.activeRoutineHeading();
    }
  };

  activeRoutineHeading = () => {
    let { activeRoutine } = this.props;
    return (
      <div className="routine__item ">
        <div>
          <i
            className={
              "icon " + (this.isBeingEdited(activeRoutine.id) ? "icon-minus" : "icon-plus")
            }
            onClick={() => this.toggleList(activeRoutine.id)}
          ></i>
          <span className="routine__title">{activeRoutine.title}</span>
        </div>

        <div className="routine__item__options">
          <button
            type="button"
            title="Edit Routine"
            alt="Edit Routine"
            className="practice__icon practice__icon--edit"
            onClick={() => this.toggleRoutineForm("Edit", activeRoutine.id)}
            dangerouslySetInnerHTML={{ __html: PracticeIcons.editButton() }}
          ></button>
        </div>
      </div>
    );
  };

  sortedRoutines = (routines) => {
    return routines.sort((a, b) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  render() {
    let { activeRoutine, inactiveRoutines } = this.props;

    if (activeRoutine) {
      return (
        <div>
          <AdminIntroduction intro={this.props.adminIntroduction} />
          <div className="routines__list">
            <div className="routines__list__column">
              <span className="practice__heading"> Current Active Routine</span>

              {this.activeRoutineDisplay()}
            </div>
            <div className="routines__list__column">
              <span className="practice__heading">Inactive Routines</span>
              {this.sortedRoutines(inactiveRoutines).map((routine) =>
                this.inactiveListItem(routine)
              )}
            </div>
          </div>

          <div className="new-routine">
            <span className="practice__heading">New Routine</span>
            <CreateRoutine handleNewRoutine={this.handleNewRoutine} errors={this.state.errorText} />
          </div>
        </div>
      );
    } else {
      return this.noRoutineText();
    }
  }
}
