import React, { useContext } from "react";
import { savedSongId, savedPracticeItemId } from "../songs-list/SongActionsDrawer";
import { ACTION_TYPES, useUser, useUserDispatch } from "../contexts/userContext";
import {
  deleteSongItem,
  saveSongItem,
  deletePracticeItem,
  savePracticeItem,
} from "../contexts/userActions";
import { SnackbarContext } from "../SongsIndexContainer";
import { GENERIC_ERROR } from "../consts";

const SongActionButtons = ({ song }) => {
  const user = useUser();
  const dispatch = useUserDispatch();
  const { setSnackbar } = useContext(SnackbarContext);

  if (!user) return null;

  const savedItemId = savedSongId(user, song);
  const practiceItemId = savedPracticeItemId(user, song);

  const songIsSaved = !!savedItemId;
  const practiceItemIsSaved = !!practiceItemId;

  // todo: move this duplication to one place
  const handleSaveButtonClick = () => {
    if (songIsSaved) {
      deleteSongItem(savedItemId)
        .then(() => {
          dispatch({
            type: ACTION_TYPES.DELETE_SONG_ITEM,
            userSongItemId: savedItemId,
          });
          setSnackbar({ message: "Removed song!", severity: "success" });
        })
        .catch(() => {
          setSnackbar({ message: GENERIC_ERROR, severity: "error" });
        });
    } else {
      saveSongItem(song.id)
        .then((response) => {
          dispatch({
            type: ACTION_TYPES.SAVE_SONG_ITEM,
            userSongItem: response.data.userItem.data,
          });
          setSnackbar({ message: "Saved song!", severity: "success" });
        })
        .catch(() => {
          setSnackbar({ message: GENERIC_ERROR, severity: "error" });
        });
    }
  };

  const handlePracticeAssistantClick = () => {
    if (practiceItemIsSaved) {
      deletePracticeItem(practiceItemId)
        .then(() => {
          dispatch({
            type: ACTION_TYPES.DELETE_PRACTICE_ITEM,
            userPracticeItemId: practiceItemId,
          });
          setSnackbar({ message: "Saved practice item!", severity: "success" });
        })
        .catch(() => {
          setSnackbar({ message: GENERIC_ERROR, severity: "error" });
        });
    } else {
      savePracticeItem(song.id)
        .then((response) => {
          dispatch({
            type: ACTION_TYPES.SAVE_PRACTICE_ITEM,
            userPracticeItem: response.data.userPracticeItem.data,
          });
          setSnackbar({ message: "Removed practice item!", severity: "success" });
        })
        .catch(() => {
          setSnackbar({ message: GENERIC_ERROR, severity: "error" });
        });
    }
  };

  return (
    <div className="song-actions">
      <i
        onClick={handleSaveButtonClick}
        className={`icon icon-heart${songIsSaved ? "" : "-outline"}`}
      />
      <i onClick={handlePracticeAssistantClick} className="icon icon-playlist" />
    </div>
  );
};

export default SongActionButtons;
