import React from "react";
import Swoosh from "../components/shared/Swoosh";
import { breadcrumbTitleFormatter } from "../components/shared/BreadcrumbTrail";
import { CDN_URL } from "../components/shared/CdnUrl";
import { BootstrapSize } from "../components/shared/bootstrap-helpers";

export default function () {
  const defaultHeaderImages = {
    [BootstrapSize.smDevice]: `${CDN_URL}/redesign/lesson-map/hero-lg.png`,
    [BootstrapSize.xsDevice]: `${CDN_URL}/redesign/lesson-map/hero-sm.png`,
  };

  // TODO: add "watch how it works" video button
  return (
    <Swoosh imgUrl={defaultHeaderImages}>
      <div className="site-map-header container">
        <h1 className="header1 uppercase">Lesson Map</h1>
        <p>
          All my guitar lessons are organized by difficulty level and skills. You always have a
          clear path for what to learn next! :)
        </p>
        {/*  TODO: figma has a video button here; what is the video? */}
      </div>
    </Swoosh>
  );
}
