import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Howl } from "howler";
import PracticeVideoModal from "../Items/PracticeVideoModal";
import PracticeIcons from "../../PracticeIcons";
import { CDN_URL } from "../../../shared/CdnUrl";

const DING_SOUND = `${CDN_URL}/files/ding-sound.mp3`;
const SIXTY_SECONDS = 60;

export default class TimerControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoModalOpen: false,
    };
  }

  componentDidMount() {
    let rawDuration = this.props.item.duration * SIXTY_SECONDS;
    this.setState({
      timeLeft: rawDuration,
      formattedTime: this.formatTime(rawDuration),
      percentage: 0,
    });
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.errorInterval) {
      clearInterval(this.errorInterval);
    }
  }

  startItem = (toggleModal) => {
    if (toggleModal && this.props.videoPlayAlongIsActive) {
      this.triggerVideoModal();
    }

    this.setIsRunning(true);
    this.invisibleVideo.play();
    this.interval = setInterval(() => this.countdown(), 1000);
  };

  countdown = () => {
    if (this.state.timeLeft > 1 && this.state.isRunning) {
      let timeLeft = this.state.timeLeft - 1;
      this.setState({
        timeLeft: timeLeft,
        formattedTime: this.formatTime(this.state.timeLeft - 1),
        percentage: this.calculatePercentage(timeLeft),
      });
    } else if (this.state.timeLeft <= 1 && !this.state.videoModalOpen) {
      this.endTimer();
      this.playSound();
      window.scrollTo(0, 0);
      this.props.onTimerEnd(true);
      setTimeout(() => this.props.logPractice(), 1000);
    }
  };

  pauseTimer = () => {
    this.invisibleVideo.pause();
    clearInterval(this.interval);
    this.setIsRunning(false);
  };

  resumeTimer = () => {
    if (this.state.isRunning != true) {
      this.setState({ isRunning: true });
      this.invisibleVideo.play();
      this.interval = setInterval(() => this.countdown(), 1000);
    }
  };

  resetTimer = () => {
    clearInterval(this.interval);
    let rawDuration = this.props.item.duration * SIXTY_SECONDS;

    this.setState(
      {
        timeLeft: rawDuration,
        formattedTime: this.formatTime(rawDuration),
        percentage: 0,
      },
      () => this.setIsRunning(false)
    );
  };

  endTimer() {
    this.invisibleVideo.pause();
    clearInterval(this.interval);
    this.setIsRunning(false);
  }

  playSound = () => {
    let sound = new Howl({ src: [DING_SOUND] });
    sound.play();
  };

  calculatePercentage = (timeLeft) => {
    let itemDuration = this.props.item.duration * SIXTY_SECONDS;
    return ((itemDuration - timeLeft) / itemDuration) * 100;
  };

  formatTime(seconds) {
    if (seconds < 10) {
      return `0:0${seconds}`;
    } else if (seconds < SIXTY_SECONDS) {
      return `0:${seconds}`;
    } else {
      let minutes = Math.floor(seconds / SIXTY_SECONDS);
      let remainingSeconds = seconds - minutes * SIXTY_SECONDS;
      let formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
      return `${minutes}:${formattedSeconds}`;
    }
  }

  formatTime(seconds) {
    if (seconds < 10) {
      return `0:0${seconds}`;
    } else if (seconds < SIXTY_SECONDS) {
      return `0:${seconds}`;
    } else {
      let minutes = Math.floor(seconds / SIXTY_SECONDS);
      let remainingSeconds = seconds - minutes * SIXTY_SECONDS;
      let formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
      return `${minutes}:${formattedSeconds}`;
    }
  }

  itemImages = () => {
    let { item } = this.props;
    if (item.images && item.images.length > 0) {
      return <ItemImages images={item.images} />;
    }
  };

  countdownClock = () => {
    if (this.state.isRunning) {
      return (
        <div>
          <div className="routine-timer__clock routine-timer__clock--running">
            <span
              dangerouslySetInnerHTML={{ __html: PracticeIcons.activeStopwatchIcon() }}
              className="routine-timer__icon"
            />
            <span>{this.state.formattedTime}</span>
          </div>
          <div style={{ display: "inline" }}>
            <button className="countdown-clock" onClick={this.resetTimer} type="button">
              reset
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="routine-timer__clock">
            <span
              dangerouslySetInnerHTML={{ __html: PracticeIcons.inactiveStopwatchIcon() }}
              className={"routine-timer__icon"}
            />
            <span>{this.state.formattedTime}</span>
          </div>
        </div>
      );
    }
  };

  timerButtonToShow = () => {
    if (this.state.isRunning) {
      return (
        <div>
          <span
            onClick={this.pauseTimer}
            dangerouslySetInnerHTML={{ __html: PracticeIcons.pauseButton() }}
            className="clickable routine__pause-button "
          />
        </div>
      );
    } else {
      return (
        <div>
          <span
            onClick={() => this.startItem(true)}
            style={{ display: "inline-block", marginLeft: "15px" }}
            dangerouslySetInnerHTML={{ __html: PracticeIcons.playButton() }}
            className="clickable routine__play-button "
          />
        </div>
      );
    }
  };

  resumeWithoutVideo = () => {
    let { videoPlayAlongIsActive, item } = this.props;
    if (item.has_video_play_along && videoPlayAlongIsActive && !this.state.isRunning) {
      return (
        <button onClick={() => this.startItem(false)} className="practicing__without-video">
          Start Timer Without Video
        </button>
      );
    } else {
      return null;
    }
  };

  markAsCompleteAndLogTime = () => {
    this.endTimer();
    window.scrollTo(0, 0);
    this.props.onTimerEnd(false);
    setTimeout(() => this.props.logPractice(), 500);
  };

  logElapsedTime = () => {
    let elapsedTime = this.props.item.duration * 60 - this.state.timeLeft;
    if (elapsedTime === 0) {
      this.setState({
        buttonError:
          "You haven't started this item yet. If you want to log the full time and mark the item as complete, use the 'Mark as Done' button.",
      });
      this.errorInterval = setInterval(() => this.setState({ buttonError: null }), 7000);
    } else {
      this.endTimer();
      window.scrollTo(0, 0);
      this.props.onTimerEnd(false);
      this.props.logPractice(elapsedTime);
    }
  };

  buttonErrors = () => {
    if (this.state.buttonError) {
      return (
        <p className="has-errors" style={{ margin: "12px 0" }}>
          {this.state.buttonError}
        </p>
      );
    } else {
      return null;
    }
  };

  // VIDEO

  closeVideoModal = () => {
    if (this.state.timeLeft > 1) {
      clearInterval(this.interval);
      this.setState({ videoModalOpen: false, isRunning: false });
    } else {
      this.endTimer();
      this.playSound();
      window.scrollTo(0, 0);
      this.props.onTimerEnd(true);
      setTimeout(() => this.props.logPractice(), 1000);
    }
  };

  setIsRunning = (isRunning) => {
    this.setState({ isRunning: isRunning });
  };

  triggerVideoModal = () => {
    let { item } = this.props;
    if (item.has_video_play_along && item.related_video && item.related_video.length > 0) {
      this.setState({ videoModalOpen: true });
    }
  };

  render() {
    return (
      <div className="current-practice-item__left">
        <PracticeVideoModal
          isOpen={this.state.videoModalOpen}
          closeModal={this.closeVideoModal}
          pauseTimer={this.pauseTimer}
          onVideoPlay={this.resumeTimer}
          video={this.props.item.related_video}
          itemDuration={this.props.item.duration * 60}
        />

        <div className="routine-timer-countdown__container">
          <video
            id="invisible-video"
            loop
            ref={(invisibleVideo) => (this.invisibleVideo = invisibleVideo)}
          >
            <source
              src="https://jtgt-static.b-cdn.net/images/practice-routine/hidden.mov"
              type="video/mp4"
            />
          </video>

          <div className="routine-timer-countdown">
            <CircularProgressbarWithChildren strokeWidth={5} value={this.state.percentage}>
              {this.timerButtonToShow()}
            </CircularProgressbarWithChildren>
            {this.countdownClock()}

            {this.buttonErrors()}
          </div>

          <div className="routine-timer__options">
            {this.resumeWithoutVideo()}
            <button
              className="practicing__without-video practicing__without-video--slim"
              onClick={this.markAsCompleteAndLogTime}
            >
              Mark As Done - Log Full Scheduled Time
            </button>

            {/*
            <button className="practicing__without-video practicing__without-video--slim"
                    onClick={this.logElapsedTime}>Finish Early - Log Elapsed Time Only</button>
          */}
          </div>
        </div>
      </div>
    );
  }
}
