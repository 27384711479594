import React from "react";
import { CheckCircle, CircleOutlined } from "@mui/icons-material";
import { activeColor, completedColor, disabledColor } from "./colors";

export const StepIconComponent = ({ completed, active, error, icon }) => {
  const color = completed ? completedColor : active ? activeColor : disabledColor;
  // TODO: we shouldn't have to use sx here; investigate why color prop doesn't work
  // (probably because color prop indicates an icon variant, so we'd have to apply css color elsewhere in custom theme
  return completed ? <CheckCircle sx={{ color }} /> : <CircleOutlined sx={{ color }} />;
};
