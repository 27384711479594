export const POPULAR_SEARCH_ITEMS = [
  {
    title: "Beginner Course",
    link: "/categories/beginner-guitar-lessons-grade-1",
  },
  { title: "All Songs", link: "/songs" },
  {
    title: "A + D Chords and Songs",
    link: "/modules/lesson-1-all-the-basics-2-chords-and-songs",
  },
  {
    title: "Practical Music Theory",
    link: "/categories/practical-fast-fun-music-theory",
  },
];

export const QUICK_LINKS = [
  { title: "Practical Music Theory", link: "/store/practical-music-theory" },
  { title: "Tuning", link: "/modules/tune-your-guitar" },
  { title: "Chords", link: "/chords" },
];

export const LOCAL_STORAGE_KEY = "justinguitar-searches";

export const searchResultsTypes = {
  group: "group",
  song: "song",
  lesson: "lesson",
  // resource: "resource",
  // product: "product",
};

// these values are based on figma mockup
export const searchResultsTypeToDefaultListLength = {
  [searchResultsTypes.song]: 4,
  [searchResultsTypes.lesson]: 6,
  // [searchResultsTypes.product]: 3,
  // [searchResultsTypes.resource]: 3,
  [searchResultsTypes.group]: 3,
};

export const searchResultsTypeToDisplayName = {
  [searchResultsTypes.group]: "Modules",
  [searchResultsTypes.song]: "Songs",
  [searchResultsTypes.lesson]: "Lessons",
  // [searchResultsTypes.resource]: "Resources",
  // [searchResultsTypes.product]: "Products",
};

export const searchResultsTypeToSlugPrefix = {
  [searchResultsTypes.group]: "modules",
  [searchResultsTypes.resource]: "resources",
  [searchResultsTypes.song]: "songs",
  [searchResultsTypes.product]: "products",
  [searchResultsTypes.lesson]: "guitar-lessons",
};

const PREVIOUS_SEARCHES_CACHE_SIZE = 5;
export const storeSearchAndNavigateToItem = ({ title, slug }) => {
  const rawSearchItems = localStorage.getItem(LOCAL_STORAGE_KEY);
  const previousSearchItems = rawSearchItems == null ? [] : JSON.parse(rawSearchItems);
  const newItem = { title, link: slug };

  const allItems = [newItem, ...previousSearchItems]
    .filter(
      (thisItem, index, arr) =>
        arr.findIndex((otherItem) => thisItem.link === otherItem.link) === index
    )
    .splice(0, PREVIOUS_SEARCHES_CACHE_SIZE - 1);

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(allItems));
  window.location.href = slug;
};
