import React from "react";
import { CDN_URL } from "../../shared/CdnUrl";

const THUMBNAIL_PLACEHOLDER = `${CDN_URL}/images/all-songs/artist-placeholder.png`;

export const ArtistThumbnail = ({ artist }) => {
  const { slug, name, thumbnailImageUrl } = artist;
  return (
    <div
      className="artist-thumbnail"
      style={{ cursor: "pointer" }}
      onClick={() => (window.location.href = `/artists/${slug}`)}
    >
      <img src={thumbnailImageUrl || THUMBNAIL_PLACEHOLDER} alt={name} />
      <div>{name}</div>
    </div>
  );
};
