import React from "react";
import { CDN_URL } from "../CdnUrl";

const QuizTile = ({
  videoRemaining,
  refuseAutoplay,
  markAsCompleteAndGoToNextVideo,
  quiz,
  quizAttempt,
}) => {
  if (videoRemaining && videoRemaining < 10) {
    return (
      <div className="autoplay__tile flex">
        <div className="flex">
          <div>
            <img src={`${CDN_URL}/redesign/2020/quiz/quiz-thumb.png`} />
          </div>
          <div>
            {videoRemaining >= 0 && (
              <span className="autoplay__next">
                Next step in <span className="time-remaining">{videoRemaining}</span>
              </span>
            )}
            <a
              className="autoplay__link"
              onClick={() =>
                markAsCompleteAndGoToNextVideo(
                  `/quizzes/${quiz.slug}${quizAttempt == null ? "" : "/results"}`
                )
              }
            >
              {quiz.title}
            </a>

            <div className="autoplay__options">
              <button
                onClick={refuseAutoplay}
                className="button button--inline button--transparent"
              >
                Cancel
              </button>
              <button
                className="button button--inline button--primary"
                onClick={() =>
                  markAsCompleteAndGoToNextVideo(
                    `/quizzes/${quiz.slug}${quizAttempt == null ? "" : "/results"}`
                  )
                }
              >
                Take the Quiz
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default QuizTile;
