import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectAlertMessage, selectAlertSeverity, selectHasAlert } from "./uiSelectors";
import { clearAlert } from "./uiSlice";

import { Snackbar, SnackbarContent, Portal } from "@mui/material";

const AUTOHIDE_DURATION = 4000;

const SnackbarNotification = ({ message, severity, isOpen, clearAlert }) => {
  const closeButton = <i className="icon icon-cross" onClick={clearAlert} />;

  return (
    <Portal>
      <Snackbar
        classes={{
          root: `snackbar`,
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isOpen}
        onClose={clearAlert}
        autoHideDuration={AUTOHIDE_DURATION}
      >
        <SnackbarContent
          classes={{
            root: `snackbar__content snackbar__content--${severity || "info"}`,
          }}
          message={message}
          action={closeButton}
        />
      </Snackbar>
    </Portal>
  );
};

const mapStateToProps = (state) => ({
  message: selectAlertMessage(state),
  severity: selectAlertSeverity(state),
  isOpen: selectHasAlert(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearAlert: () => dispatch(clearAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarNotification);

SnackbarNotification.propTypes = {
  selectAlertMessage: PropTypes.func,
  selectAlertSeverity: PropTypes.func,
  selectHasAlert: PropTypes.func,
};
