import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick";

import * as bootstrapHelpers from "../shared/bootstrap-helpers";
import { selectCurrentArtist, selectLatestReleasesOrderedSongs } from "./store";
import SongsGridItem, { SongDetails, SongImg } from "./SongsGridItem";
import Stack from "../shared/Stack";
import { withBootstrapSize } from "../shared/WithBootstrapSize";
import SongActionButtons from "./SongActionButtons";

const slickSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.xlDevice],
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.lgDevice],
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.mdDevice],
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.smDevice],
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const LatestReleasesSlick = ({ songs, width }) => (
  <Slider {...slickSettings}>
    {songs.map((song) => (
      <div key={song.id}>
        <SongsGridItem song={song.attributes} key={song.id} />
        {width < bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.mdDevice] && (
          <SongActionButtons song={song.attributes} />
        )}
      </div>
    ))}
  </Slider>
);

export const LatestReleasesStack = ({ songs }) => {
  const [topSong, setTopSong] = React.useState(songs[0].attributes);
  return (
    <>
      <Stack onChange={(i) => setTopSong(songs[i].attributes)}>
        {songs.map((song) => (
          <SongImg song={song.attributes} key={song.id} />
        ))}
      </Stack>
      <SongDetails song={topSong} />
      <SongActionButtons song={topSong} />
    </>
  );
};

const LatestReleases = withBootstrapSize(
  ({ width, bootstrapSize, shouldShow, ...props }) =>
    shouldShow && (
      <div className="latest-releases">
        <h2 className="header2">Latest Releases</h2>
        <LatestReleasesSlick {...props} width={width} />
      </div>
    )
);

export default connect((state) => ({
  songs: selectLatestReleasesOrderedSongs(state),
  shouldShow: selectCurrentArtist(state) == null,
}))(LatestReleases);
