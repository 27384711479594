import * as yup from "yup";
import { EMAIL_REGEX } from "../sessions/consts";

export const GROUPED_FIELDS = [
  {
    type: "text",
    name: "firstName",
    placeholder: "First Name",
    fullWidth: false,
  },
  {
    type: "text",
    name: "lastName",
    placeholder: "Last Name",
    fullWidth: false,
  },
];

export const FORM_FIELDS = [
  {
    type: "email",
    name: "email",
    placeholder: "Email",
    fullWidth: true,
  },
  {
    type: "text",
    name: "username",
    placeholder: "Choose a username",
    errorName: "Username",
    fullWidth: true,
  },
  {
    type: "password",
    name: "password",
    placeholder: "Password",
    fullWidth: true,
  },
];

export const CHECKBOX_FIELDS = [
  {
    name: "termsConsent",
    label: "I consent to JustinGuitar's ",
    required: true,
    link: {
      location: "/terms",
      text: "terms and conditions.",
    },
  },
  {
    name: "emailConsent",
    label: "I want to receive Justin's newsletters and email updates.",
    required: false,
  },
];

const USERNAME_REGEX = /^\w+$/;
const NAME_REGEX = /^[a-zA-Z\s]*$/;

export const REGISTRATION_SCHEMA = yup
  .object({
    firstName: yup
      .string()
      .trim()
      .required()
      .min(2)
      .matches(NAME_REGEX, "First name must only contain letters and spaces"),
    lastName: yup
      .string()
      .trim()
      .required()
      .min(2)
      .matches(NAME_REGEX, "Last name must only contain letters and spaces"),
    email: yup
      .string()
      .trim()
      .required()
      .test("noPlus", "+ character is not allowed", (value) => value.includes("+") === false)
      .matches(EMAIL_REGEX, "Email must be a valid address"),
    password: yup.string().required().min(8),
    username: yup
      .string()
      .trim()
      .required()
      .min(3)
      .matches(USERNAME_REGEX, "Username must only contain letters and numbers"),
    termsConsent: yup
      .boolean()
      .required()
      .oneOf([true], "You must accept the terms to create an account."),
    emailContent: yup.boolean(),
  })
  .required();

export const SECTIONS = {
  app: "app",
  form: "form",
  success: "success",
};

export const EMAIL_PROVIDERS = [
  {
    icon: `
    <svg width="56" height="43" viewBox="0 0 56 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M52.429 42.5362H3.5021C1.60494 42.5362 0.00732422 40.9885 0.00732422 39.0414V3.69423C0.00732422 1.79706 1.55501 0.199448 3.5021 0.199448H52.429C54.3262 0.199448 55.9238 1.74714 55.9238 3.69423V39.0414C55.9238 40.9885 54.3761 42.5362 52.429 42.5362Z" fill="#E3E3E3"/>
      <path opacity="0.1" d="M6.99645 42.5375L27.9152 25.7127L28.065 24.814L6.49719 9.28719L6.44727 41.7886L6.99645 42.5375Z" fill="#231F20"/>
      <path d="M3.5021 42.5364C1.55501 42.5364 0.00732422 40.9887 0.00732422 39.0416V3.64445C0.00732422 1.69736 1.55501 1.34789 3.5021 1.34789C5.4492 1.34789 6.99688 1.74729 6.99688 3.64445V42.5364H3.5021Z" fill="#D54B3D"/>
      <path d="M3.5021 1.84714C5.99838 1.84714 6.49763 2.59602 6.49763 3.64445V42.0371H3.5021C1.85456 42.0371 0.506579 40.6891 0.506579 39.0416V3.64445C0.506579 2.5461 1.00583 1.84714 3.5021 1.84714ZM3.5021 1.34789C1.55501 1.34789 0.00732422 1.74729 0.00732422 3.64445V39.0416C0.00732422 40.9887 1.55501 42.5364 3.5021 42.5364H6.99688V3.64445C6.99688 1.69736 5.4492 1.34789 3.5021 1.34789Z" fill="#D72B27"/>
      <path d="M52.4294 42.5364H48.9346V3.5446C48.9346 1.59751 50.4823 1.34789 52.4294 1.34789C54.3764 1.34789 55.9241 1.59751 55.9241 3.5446V39.0915C55.9241 40.9887 54.3764 42.5364 52.4294 42.5364Z" fill="#D54B3D"/>
      <path d="M52.4294 1.84714C54.676 1.84714 55.4249 2.29647 55.4249 3.5446V39.0915C55.4249 40.739 54.0769 42.087 52.4294 42.087H49.4338V3.5446C49.4338 2.24654 50.1827 1.84714 52.4294 1.84714ZM52.4294 1.34789C50.4823 1.34789 48.9346 1.59751 48.9346 3.5446V42.5863H52.4294C54.3764 42.5863 55.9241 41.0386 55.9241 39.0915V3.5446C55.9241 1.59751 54.3764 1.34789 52.4294 1.34789Z" fill="#D72B27"/>
      <path d="M37.4514 42.5369L0.299805 5.09675L2.25394 5.89163L28.1153 24.5138L55.9238 4.12245V39.092C55.9238 40.9892 54.3761 42.5369 52.429 42.5369H37.4514Z" fill="url(#paint0_linear)"/>
      <path d="M27.9159 25.7118L1.50531 6.54044C-0.0423751 5.39216 -0.491704 3.19544 0.656581 1.64775C1.80487 0.100064 4.05151 -0.249414 5.64912 0.898871L27.9658 17.1246L50.4322 0.699169C51.9799 -0.449115 54.1766 -0.0996374 55.3249 1.49798C56.4732 3.04566 56.1237 5.24238 54.5261 6.39067L27.9159 25.7118Z" fill="#D54B3D"/>
      <path d="M52.429 0.499254C53.3776 0.499254 54.2763 0.948583 54.8754 1.74739C55.8239 3.09538 55.5244 4.94262 54.2263 5.94113L27.9156 25.1125L1.80463 6.14083C0.456642 5.14232 0.107165 3.24515 1.05575 1.94709C1.60493 1.19821 2.55351 0.698956 3.55202 0.698956C4.20105 0.698956 4.85008 0.898658 5.34933 1.29806L27.6161 17.4739L27.9156 17.6736L28.2152 17.4739L50.6317 1.04843C51.1809 0.698956 51.78 0.499254 52.429 0.499254ZM52.429 0C51.7301 0 50.9812 0.199702 50.3821 0.649031L27.9156 17.0745L5.59896 0.848732C4.99986 0.399403 4.25098 0.199702 3.50209 0.199702C2.40373 0.199702 1.30537 0.698956 0.606419 1.64754C-0.491941 3.19523 -0.0426118 5.39195 1.50508 6.54023L27.9156 25.7615L54.476 6.39046C56.0236 5.24217 56.3731 3.09538 55.2748 1.49776C54.5758 0.499254 53.5274 0 52.429 0Z" fill="#D72B27"/>
      <defs>
      <linearGradient id="paint0_linear" x1="0.301968" y1="23.3288" x2="55.9417" y2="23.3288" gradientUnits="userSpaceOnUse">
      <stop stop-opacity="0.1"/>
      <stop offset="1" stop-opacity="0.2"/>
      </linearGradient>
      </defs>
      </svg>`,
    url: "https://www.gmail.com",
  },
  {
    icon: `
      <svg width="76" height="22" viewBox="0 0 76 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 5.13123H4.5215L7.15432 11.867L9.82131 5.13123H14.2237L7.59466 21.0771H3.16433L4.97894 16.8517L0 5.13123Z" fill="#5F01D1"/>
      <path d="M18.81 4.86302C15.4128 4.86302 13.2651 7.9098 13.2651 10.9439C13.2651 14.3582 15.6197 17.0651 18.7455 17.0651C21.0773 17.0651 21.9564 15.6444 21.9564 15.6444V16.7511H25.9001V5.13124H21.9564V6.18759C21.9564 6.18759 20.9756 4.86302 18.81 4.86302ZM19.6488 8.59718C21.2163 8.59718 22.0252 9.8375 22.0252 10.9567C22.0252 12.1618 21.1586 13.3445 19.6488 13.3445C18.3975 13.3445 17.2667 12.3219 17.2667 11.008C17.2667 9.67545 18.1763 8.59718 19.6488 8.59718Z" fill="#5F01D1"/>
      <path d="M27.2578 16.7507V0H31.3826V6.22749C31.3826 6.22749 32.3623 4.86413 34.4143 4.86413C36.9242 4.86413 38.3947 6.73423 38.3947 9.40653V16.7507H34.3001V10.4126C34.3001 9.50821 33.8694 8.63456 32.8936 8.63456C31.9003 8.63456 31.3826 9.52145 31.3826 10.4126V16.7507H27.2578Z" fill="#5F01D1"/>
      <path d="M45.4272 4.86472C41.5368 4.86472 39.2202 7.82302 39.2202 10.9926C39.2202 14.5995 42.0251 17.0654 45.442 17.0654C48.7538 17.0654 51.6518 14.7114 51.6518 11.0531C51.6518 7.05015 48.6175 4.86472 45.4272 4.86472ZM45.4644 8.63169C46.8386 8.63169 47.7895 9.77629 47.7895 10.9969C47.7895 12.0379 46.9035 13.322 45.4644 13.322C44.1458 13.322 43.1562 12.2643 43.1562 10.9855C43.1562 9.75362 43.9789 8.63169 45.4644 8.63169Z" fill="#5F01D1"/>
      <path d="M58.5239 4.86472C54.6335 4.86472 52.3169 7.82302 52.3169 10.9926C52.3169 14.5995 55.1217 17.0654 58.5387 17.0654C61.8505 17.0654 64.7484 14.7114 64.7484 11.0531C64.7484 7.05015 61.7142 4.86472 58.5239 4.86472ZM58.5611 8.63169C59.9353 8.63169 60.8862 9.77629 60.8862 10.9969C60.8862 12.0379 60.0001 13.322 58.5611 13.322C57.2424 13.322 56.2529 12.2643 56.2529 10.9855C56.2529 9.75362 57.0756 8.63169 58.5611 8.63169Z" fill="#5F01D1"/>
      <path d="M68.0027 16.9971C69.5154 16.9971 70.7417 15.7708 70.7417 14.2581C70.7417 12.7454 69.5154 11.5191 68.0027 11.5191C66.49 11.5191 65.2637 12.7454 65.2637 14.2581C65.2637 15.7708 66.49 16.9971 68.0027 16.9971Z" fill="#5F01D1"/>
      <path d="M71.6419 10.5132H66.7109L71.0872 0H75.9998L71.6419 10.5132Z" fill="#5F01D1"/>
      </svg>
      `,
    url: "https://login.yahoo.com/",
  },
  {
    icon: `
      <svg width="68" height="64" viewBox="0 0 68 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M67.9995 33.2094C68.0032 32.6776 67.7285 32.1828 67.2752 31.9047H67.2673L67.2388 31.8889L43.676 17.941C43.5743 17.8723 43.4687 17.8094 43.3597 17.7528C42.45 17.2835 41.3693 17.2835 40.4595 17.7528C40.3505 17.8095 40.2449 17.8723 40.1432 17.941L16.5805 31.8889L16.552 31.9047C15.8319 32.3525 15.6111 33.2994 16.0589 34.0196C16.1909 34.2318 16.3726 34.4085 16.5884 34.5346L40.1511 48.4825C40.2532 48.5506 40.3588 48.6134 40.4674 48.6707C41.3772 49.14 42.4579 49.14 43.3677 48.6707C43.4763 48.6134 43.5819 48.5506 43.684 48.4825L67.2468 34.5346C67.7175 34.2601 68.0049 33.7543 67.9995 33.2094Z" fill="#0A2767"/>
      <path d="M19.3274 23.8965H34.7903V38.0705H19.3274V23.8965ZM64.8368 9.48836V3.00464C64.8739 1.38356 63.5908 0.0388447 61.9698 0H21.8435C20.2224 0.0388447 18.9392 1.38356 18.9764 3.00464V9.48836L42.6973 15.8139L64.8368 9.48836Z" fill="#0364B8"/>
      <path d="M18.9766 9.48898H34.7905V23.7215H18.9766V9.48898Z" fill="#0078D4"/>
      <path d="M50.605 9.48898H34.791V23.7215L50.605 37.9541H64.8375V23.7215L50.605 9.48898Z" fill="#28A8EA"/>
      <path d="M34.791 23.7213H50.6049V37.9538H34.791V23.7213Z" fill="#0078D4"/>
      <path d="M34.791 37.9536H50.6049V52.1861H34.791V37.9536Z" fill="#0364B8"/>
      <path d="M19.3276 38.0698H34.7905V50.955H19.3276V38.0698Z" fill="#14447D"/>
      <path d="M50.605 37.9536H64.8375V52.1861H50.605V37.9536Z" fill="#0078D4"/>
      <path d="M67.2757 34.4485L67.2457 34.4643L43.6829 47.7164C43.5801 47.7796 43.4757 47.8397 43.3666 47.8935C42.9664 48.0841 42.5317 48.1916 42.0888 48.2098L40.8016 47.457C40.6928 47.4024 40.5872 47.3417 40.4853 47.2751L16.6062 33.6467H16.5952L15.814 33.2102V60.037C15.8261 61.8268 17.2865 63.2681 19.0764 63.2567H64.7881C64.815 63.2567 64.8388 63.2441 64.8672 63.2441C65.2453 63.2199 65.618 63.1421 65.9742 63.0132C66.1281 62.948 66.2767 62.8708 66.4185 62.7823C66.5245 62.7222 66.7064 62.591 66.7064 62.591C67.5165 61.9915 67.996 61.0448 67.9999 60.0371V33.2102C67.9994 33.7233 67.7227 34.1965 67.2757 34.4485Z" fill="url(#paint0_linear)"/>
      <path opacity="0.5" d="M66.7347 33.1023V34.747L42.0966 51.7106L16.5887 33.6558C16.5887 33.6471 16.5816 33.64 16.5729 33.64L14.2324 32.2326V31.0465L15.1971 31.0307L17.2371 32.2009L17.2845 32.2167L17.4584 32.3275C17.4584 32.3275 41.4323 46.0065 41.4956 46.0381L42.4128 46.5758C42.4919 46.5441 42.571 46.5125 42.6658 46.4809C42.7133 46.4493 66.4658 33.0865 66.4658 33.0865L66.7347 33.1023Z" fill="#0A2767"/>
      <path d="M67.2757 34.4485L67.2456 34.4659L43.6828 47.7179C43.58 47.7812 43.4757 47.8413 43.3665 47.895C42.4515 48.3421 41.3813 48.3421 40.4663 47.895C40.3579 47.8414 40.2523 47.7822 40.15 47.7179L16.5873 34.4659L16.5588 34.4485C16.1039 34.2018 15.8187 33.7277 15.814 33.2102V60.037C15.8253 61.8265 17.2852 63.268 19.0747 63.2567C19.0747 63.2567 19.0747 63.2567 19.0748 63.2567H64.7391C66.5287 63.2681 67.9886 61.8267 68 60.0372C68 60.0371 68 60.0371 68 60.037V33.2102C67.9994 33.7233 67.7226 34.1965 67.2757 34.4485Z" fill="#1490DF"/>
      <path opacity="0.1" d="M44.0266 47.5208L43.674 47.7185C43.5718 47.7835 43.4662 47.8432 43.3577 47.8972C42.9692 48.0879 42.5467 48.1997 42.1147 48.2261L51.0797 58.8278L66.7181 62.5962C67.1466 62.2725 67.4874 61.8469 67.7096 61.358L44.0266 47.5208Z" fill="black"/>
      <path opacity="0.05" d="M45.6238 46.6219L43.674 47.7178C43.5718 47.7829 43.4662 47.8426 43.3577 47.8965C42.9692 48.0872 42.5467 48.199 42.1147 48.2254L46.3149 59.806L66.7228 62.5908C67.5268 61.9872 68.0001 61.0406 68.0006 60.0353V59.689L45.6238 46.6219Z" fill="black"/>
      <path d="M19.1191 63.2567H64.7344C65.4363 63.2603 66.1209 63.0386 66.6874 62.6242L40.8 47.4602C40.6912 47.4056 40.5856 47.3449 40.4837 47.2783L16.6046 33.6499H16.5936L15.814 33.2102V59.9453C15.8122 61.7724 17.2919 63.255 19.1191 63.2567C19.119 63.2567 19.119 63.2567 19.1191 63.2567Z" fill="#28A8EA"/>
      <path opacity="0.1" d="M37.9535 15.5491V49.2802C37.9507 50.4629 37.2316 51.5259 36.1349 51.9686C35.7952 52.1146 35.4293 52.1899 35.0595 52.19H15.814V14.2318H18.9768V12.6504H35.0596C36.6571 12.6564 37.95 13.9515 37.9535 15.5491Z" fill="black"/>
      <path opacity="0.2" d="M36.3721 17.1311V50.8622C36.3761 51.2442 36.2951 51.6224 36.1349 51.9692C35.6957 53.0517 34.6463 53.7619 33.4781 53.7672H15.814V14.2324H33.4781C33.937 14.2278 34.3892 14.3423 34.7907 14.5645C35.7601 15.0529 36.3717 16.0456 36.3721 17.1311Z" fill="black"/>
      <path opacity="0.2" d="M36.3721 17.1311V47.6995C36.3644 49.2963 35.075 50.5906 33.4782 50.6045H15.814V14.2324H33.4781C33.937 14.2278 34.3892 14.3423 34.7907 14.5645C35.7601 15.0529 36.3717 16.0456 36.3721 17.1311Z" fill="black"/>
      <path opacity="0.2" d="M34.7907 17.131V47.6993C34.789 49.2987 33.4961 50.5965 31.8968 50.6043H15.814V14.2323H31.8967C33.4959 14.2331 34.7915 15.5302 34.7907 17.1294C34.7907 17.1299 34.7907 17.1305 34.7907 17.131Z" fill="black"/>
      <path d="M2.89869 14.2323H31.892C33.4929 14.2323 34.7907 15.5301 34.7907 17.131V46.1242C34.7907 47.7252 33.4929 49.0229 31.892 49.0229H2.89869C1.29777 49.0229 0 47.7251 0 46.1242V17.131C0 15.5301 1.29781 14.2323 2.89869 14.2323Z" fill="url(#paint1_linear)"/>
      <path d="M9.05935 26.3897C9.77379 24.8675 10.9269 23.5937 12.3708 22.7319C13.9698 21.8165 15.7904 21.3601 17.6321 21.413C19.3391 21.376 21.0235 21.8087 22.5012 22.6639C23.8907 23.4925 25.0097 24.7066 25.7225 26.1588C26.4988 27.759 26.8857 29.5201 26.8517 31.2983C26.8893 33.1567 26.4912 34.9981 25.6893 36.675C24.9595 38.1792 23.805 39.4365 22.3684 40.2917C20.8337 41.1731 19.0868 41.6172 17.3174 41.5758C15.574 41.6179 13.8525 41.1803 12.3408 40.3107C10.9393 39.481 9.80624 38.2656 9.07677 36.8094C8.29587 35.2324 7.90437 33.4911 7.93501 31.7316C7.90248 29.889 8.28684 28.0629 9.05935 26.3897ZM12.589 34.9766C12.9699 35.9389 13.6159 36.7735 14.4519 37.3835C15.3034 37.9787 16.3231 38.2857 17.3617 38.2596C18.4677 38.3034 19.5579 37.9858 20.4675 37.355C21.293 36.7469 21.9219 35.9101 22.2766 34.9482C22.6731 33.8739 22.8688 32.7359 22.8538 31.5909C22.8661 30.4349 22.6822 29.2853 22.3098 28.1909C21.981 27.2028 21.3723 26.3315 20.5577 25.6828C19.6708 25.0221 18.5836 24.6871 17.4787 24.734C16.4176 24.7065 15.3751 25.0159 14.5009 25.618C13.6508 26.2306 12.9926 27.0725 12.6032 28.0454C11.7396 30.2754 11.7351 32.7467 12.5906 34.9798L12.589 34.9766Z" fill="white"/>
      <path d="M50.605 9.48898H64.8375V23.7215H50.605V9.48898Z" fill="#50D9FF"/>
      <defs>
      <linearGradient id="paint0_linear" x1="41.907" y1="33.2102" x2="41.907" y2="63.2568" gradientUnits="userSpaceOnUse">
      <stop stop-color="#35B8F1"/>
      <stop offset="1" stop-color="#28A8EA"/>
      </linearGradient>
      <linearGradient id="paint1_linear" x1="6.04384" y1="11.9673" x2="28.7468" y2="51.2879" gradientUnits="userSpaceOnUse">
      <stop stop-color="#1784D9"/>
      <stop offset="0.5" stop-color="#107AD5"/>
      <stop offset="1" stop-color="#0A63C9"/>
      </linearGradient>
      </defs>
      </svg>
      `,
    url: "https://login.live.com/",
  },
  {
    icon: `
      <?xml version="1.0" encoding="UTF-8" standalone="no"?>
      <svg
         xmlns:dc="http://purl.org/dc/elements/1.1/"
         xmlns:cc="http://creativecommons.org/ns#"
         xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
         xmlns:svg="http://www.w3.org/2000/svg"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
         xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
         inkscape:version="1.0 (4035a4f, 2020-05-01)"
         height="29.78661"
         width="74.783829"
         sodipodi:docname="aol-logo-resize.svg"
         id="svg12"
         version="1.1"
         viewBox="0 0 74.783829 29.78661"
         fill-rule="evenodd"
         clip-rule="evenodd">
        <metadata
           id="metadata18">
          <rdf:RDF>
            <cc:Work
               rdf:about="">
              <dc:format>image/svg+xml</dc:format>
              <dc:type
                 rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
              <dc:title></dc:title>
            </cc:Work>
          </rdf:RDF>
        </metadata>
        <defs
           id="defs16" />
        <sodipodi:namedview
           inkscape:current-layer="svg12"
           inkscape:window-maximized="0"
           inkscape:window-y="23"
           inkscape:window-x="0"
           inkscape:cy="-41.90739"
           inkscape:cx="-105.21617"
           inkscape:zoom="1.6725"
           fit-margin-bottom="0"
           fit-margin-right="0"
           fit-margin-left="0"
           fit-margin-top="0"
           showgrid="false"
           id="namedview14"
           inkscape:window-height="789"
           inkscape:window-width="1440"
           inkscape:pageshadow="2"
           inkscape:pageopacity="0"
           guidetolerance="10"
           gridtolerance="10"
           objecttolerance="10"
           borderopacity="1"
           bordercolor="#666666"
           pagecolor="#ffffff" />
        <path
           style="stroke-width:0.207733"
           id="path10"
           d="m 41.17556,6.42456 c -6.71663,0 -11.79777,5.21741 -11.79777,11.68102 0,6.81385 5.29531,11.68103 11.79777,11.68103 6.50245,0 11.77825,-4.86718 11.77825,-11.68103 0,-6.4634 -5.06183,-11.68102 -11.77825,-11.68102 z m 33.60827,19.27283 c 0,2.25826 -1.83013,4.08839 -4.08839,4.08839 -2.25826,0 -4.08839,-1.83013 -4.08839,-4.08839 0,-2.25827 1.83013,-4.08839 4.08839,-4.08839 2.25826,0 4.08839,1.83012 4.08839,4.08839 z M 62.88282,29.00782 H 56.45826 V 0 h 6.42456 z m -33.68015,0 H 21.02589 L 19.66316,24.91943 H 9.5395 L 8.17678,29.00782 H 0 L 11.68103,0 h 6.03525 z m 11.9731,-16.41194 c 2.90078,-0.0195 5.276,2.45291 5.276,5.50949 0,3.03706 -2.37522,5.50949 -5.276,5.50949 -2.90079,0 -5.276,-2.47243 -5.276,-5.50949 0,-3.05658 2.37521,-5.50949 5.276,-5.50949 z m -23.45949,6.28829 -3.01752,-9.73415 -3.01753,9.73415 h 6.03527 z" />
      </svg>
      `,
    url: "https://login.aol.com/",
  },
];
