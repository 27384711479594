import React, { useState } from "react";
import UserPerformancesSwoosh from "./UserPerformancesSwoosh";
import Category from "../FAQ/Category";
import PerformancePlayDialog from "./PerformancePlayDialog";
import PerformanceCard from "./PerformanceCard";
import * as PropTypes from "prop-types";
import LoginPrompt from "../../components/auth/LoginPrompt";

// TODO: class component here, because using functional root component from react_on_rails will throw Invalid hook call
class UserPerformances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      performanceToPlay: null,
    };
  }

  render() {
    const { userPerformances, faqs, currentUser, token } = this.props;

    return (
      <div className="user-performances">
        <UserPerformancesSwoosh />
        <div className="container">
          <div className="section intro">
            <h2 className="title">Performances from the JustinGuitar Community!</h2>
            <p>
              Watch the latest performances from our students! To post a video, choose the song
              lesson you want to play, and submit your recording on the Performance tab below each
              video tutorial. :)
            </p>
            <a href="/songs" className="button button--primary">
              browse song lessons
            </a>
          </div>
          <div className="section performances">
            {currentUser ? (
              <div className="performance-cards">
                {userPerformances.data.map((performance, idx) => (
                  <PerformanceCard
                    key={idx}
                    performance={performance}
                    setPerformanceToPlay={() => this.setState({ performanceToPlay: performance })}
                    currentUser={currentUser}
                  />
                ))}
              </div>
            ) : (
              <div className="login">
                <p>To watch, comment or upload in this feature you need to be logged in!</p>
                <p>Don’t have an account yet? It’s FREE.</p>
                <div className="login-prompt">
                  <LoginPrompt token={token} />
                </div>
              </div>
            )}
          </div>
          <div className="faq" id="faq">
            <Category faqs={faqs} />
          </div>
        </div>
        {this.state.performanceToPlay && (
          <PerformancePlayDialog
            performanceToPlay={this.state.performanceToPlay}
            setPerformanceToPlay={() => this.setState({ performanceToPlay: null })}
          />
        )}
      </div>
    );
  }
}

export default UserPerformances;
