import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { withBootstrapSize } from "../WithBootstrapSize";
import { PromoItem } from "../../../justins-corner/PromoItem";

const Information = ({ informationHtml, bootstrapSize, resource = null }) => {
  const shouldBeLocatedBelowContent = ["xsDevice", "smDevice", "mdDevice"].includes(bootstrapSize);
  const shouldBeHorizontal = ["smDevice", "mdDevice"].includes(bootstrapSize);

  return (
    <Box
      display="flex"
      flexDirection={shouldBeLocatedBelowContent ? "column" : "row"}
      justifyContent="space-between"
    >
      <div style={{ width: "100%", maxWidth: "750px" }}>
        <div className="styleized styleized-redesign paragraph-container">
          {/* {informationHtml} */}
          <div dangerouslySetInnerHTML={{ __html: informationHtml }} />
          {/* <ParsedInformationHtml html={informationHtml} /> */}
        </div>
      </div>
      <div
        style={{
          marginTop: shouldBeLocatedBelowContent ? "20px" : 0,
          marginLeft: shouldBeLocatedBelowContent ? 0 : "20px",
        }}
      >
        {resource?.data?.attributes?.paidContent != true && (
          <PromoItem orientation={shouldBeHorizontal ? "landscape" : "portrait"} />
        )}
      </div>
    </Box>
  );
};

export default withBootstrapSize(Information);

Information.propTypes = {
  informationHtml: PropTypes.string.isRequired,
};
