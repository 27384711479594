import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { CDN_URL } from "../../../components/shared/CdnUrl";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { WorkshopEntity } from "../interfaces";

// TODO: get "Bakeshop" font
export const FancyQuote = ({ workshop }: { workshop: WorkshopEntity }) => {
  // looks terrible when screen is thin, since the height of the img is too small for quote. todo: get vertical version of img?
  const isMdOrLarger = useMediaQuery(jgDenimTheme.breakpoints.up("md"));
  const isSmallOrSmaller = useMediaQuery(jgDenimTheme.breakpoints.down("sm"));
  if (isSmallOrSmaller) return null;

  return (
    <Box position="relative">
      <Box
        position={"relative"}
        sx={{ width: "100%" }}
        component="img"
        src={`${CDN_URL}/redesign/workshops/fancy-quote.png`}
      ></Box>

      <Box
        position={"absolute"}
        left={"50%"}
        top={"50%"}
        width={"100%"}
        p={4}
        sx={{
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <Typography variant={"quote"} sx={{ textAlign: "center" }}>
          {workshop.attributes.slug === "2023-Acoustic-Jam-Week"
            ? `“Justin is one of the best teachers ever. When he performs for us, it's amazing. When he teaches, he simplifies the material so we can learn and enjoy! The same goes for his partner Pete - another great dude and player!”`
            : workshop.attributes.slug === "2023-Acoustic-Electric-Classics"
            ? `"Justin's workshop has really changed my journey as a guitar student. It was by far one of the best experiences I have ever had. Playing guitar for a full week surrounded by other motivated guitar students is unique!”`
            : `“Justin's workshop has really changed my journey as a guitar student. It was by far one of the best experiences I have ever had.”`}
        </Typography>
        <p
          style={{
            fontSize: isMdOrLarger ? "16px" : "14px",
            color: "white",
            margin: "10px 0 0 0",
          }}
        >
          {workshop.attributes.slug === "2023-Acoustic-Electric-Classics" ? "Leo Bego" : "Bob"}
        </p>
      </Box>
    </Box>
  );
};
