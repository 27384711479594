import React from "react";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import SpotifyIcon from "./SpotifyIcon";
import YoutubeIcon from "./YoutubeIcon";

const SpotifyIframe = ({ spotifyShareUrl, isOpen }) => {
  if (isOpen) {
    return (
      <iframe
        allowtransparency="true"
        allow="encrypted-media"
        src={`https://open.spotify.com/embed/track/${spotifyShareUrl}`}
        width="300"
        height="380"
        frameborder="0"
      ></iframe>
    );
  } else {
    return null;
  }
};

const YoutubeIframe = ({ videoId, isOpen }) => {
  if (isOpen) {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          url={`https://www.youtube.com/embed/${videoId}`}
          controls={true}
          className="og-song-player"
          width="100%"
          height="100%"
          allowFullScreen={true}
        />
      </div>
    );
  } else {
    return null;
  }
};

class OriginalSong extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spotifyOpen: false,
      youtubeOpen: false,
    };
  }

  render() {
    const { originalVideo, spotifyShareUrl } = this.props.song.data.attributes;
    if (!originalVideo[0] && !spotifyShareUrl) {
      return null;
    } else {
      return (
        <>
          <div className="song__original">
            <p className="bold">Check out the original</p>
            {spotifyShareUrl && (
              <div
                className="song-original-toggle"
                onClick={() => this.setState({ spotifyOpen: !this.state.spotifyOpen })}
              >
                <SpotifyIcon />
              </div>
            )}

            {originalVideo[0] && (
              <div
                className="song-original-toggle"
                onClick={() => this.setState({ youtubeOpen: !this.state.youtubeOpen })}
              >
                <YoutubeIcon />
              </div>
            )}
          </div>

          <div className="song__iframes">
            <div className="song__spotify">
              <SpotifyIframe spotifyShareUrl={spotifyShareUrl} isOpen={this.state.spotifyOpen} />
            </div>
            <div className="song__yt">
              <YoutubeIframe videoId={originalVideo[0]} isOpen={this.state.youtubeOpen} />
            </div>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = ({ entity: { song } }) => ({ song });
export default connect(mapStateToProps)(OriginalSong);
