import React from "react";
import Podcast from "./Podcast";
import LessonContentCard from "./LessonContentCard";
import UserNote from "./shared/UserNote";
import AddToPracticeRoutine from "./AddToPracticeRoutine";
import FileDownload from "./FileDownload";
import VimeoVideoDownload from "../lesson/VimeoVideoDownload";

const LessonContentTabBody = (props) => {
  const lessonCards = (items, typeOf) => {
    return items.map((item) => {
      return <LessonContentCard item={item} key={item.id} typeOf={typeOf} />;
    });
  };

  const soundTab = () => {
    let hasSound = props.item.getting_the_sound && props.item.getting_the_sound.length > 0;
    let hasInstruments = props.item.instruments && props.item.instruments.length > 0;

    if (hasSound || hasInstruments) {
      return (
        <div role="tabpanel" className="tab-pane lesson__content-sound styleized instrument-cards">
          {hasSound && (
            <div
              className="lesson-sound__container song__details-sound"
              dangerouslySetInnerHTML={{ __html: props.item.getting_the_sound }}
            ></div>
          )}
          {hasInstruments && (
            <div className="card-container">
              {lessonCards(props.item.instruments, "instrument")}
            </div>
          )}
        </div>
      );
    }
  };

  const practiceTab = () => {
    if (
      (props.item.practice_items && props.item.practice_items.length > 0) ||
      (props.item.practice_routines && props.item.practice_routines.length > 0)
    ) {
      return (
        <div role="tabpanel" className="tab-pane lesson__content-practice-item lesson__practice">
          <AddToPracticeRoutine
            userPracticeRoutines={props.currentUser.practice_routines}
            practiceItems={props.item.practice_items}
            practiceRoutines={props.item.practice_routines}
            userPracticeItems={props.currentUser.practice_items}
            user={props.currentUser}
            routineInstructions={props.routine_instructions}
          />
        </div>
      );
    }
  };

  const chordsContent = (items) => {
    let itemsWithImages = items.filter((item) => item.image && item.image.length > 0);
    return itemsWithImages.map((item) => {
      return (
        <div className="chord__item" key={item.id}>
          <img src={item.image} alt={item.title} />
        </div>
      );
    });
  };

  const downloadablesTab = () => {
    return props.downloadables.map((d, index) => {
      return <FileDownload key={index} currentUser={props.currentUser} file={d} />;
    });
  };

  const vimeoDownloadsTab = (item) => {
    return <VimeoVideoDownload item={item} />;
  };

  const bodyContent = () => {
    let innerHtml;
    if (props.itemableType === "Lesson") {
      innerHtml = props.item.body;
    } else if (props.itemableType === "Song") {
      innerHtml = props.item.notes;
    }
    return <div dangerouslySetInnerHTML={{ __html: innerHtml }}></div>;
  };

  return (
    <div className={"tab-content main-tab-content lesson__content-tab song__details-content"}>
      <div
        role="tabpanel"
        className="active tab-pane lesson__content-body song__details-notes styleized"
      >
        {props.item.podcasts && <Podcast podcasts={props.item.podcasts} />}
        {bodyContent()}
      </div>

      {soundTab()}

      {props.item.chords && props.item.chords.length > 0 && (
        <div role="tabpanel" className="tab-pane song__details-chords">
          <div className="chord__container">{chordsContent(props.item.chords)}</div>
        </div>
      )}

      {props.item.strums && props.item.strums.length > 0 && (
        <div role="tabpanel" className="tab-pane song__details-strum">
          <div className="chord__container">{chordsContent(props.item.strums)}</div>
        </div>
      )}

      {props.item.related_songs && props.item.related_songs.length > 0 && (
        <div
          role="tabpanel"
          className="tab-pane lesson__content-related-songs related-song-cards styleized card-container"
        >
          {lessonCards(props.item.related_songs, "song")}
        </div>
      )}
      {props.item.related_lessons && props.item.related_lessons.length > 0 && (
        <div
          role="tabpanel"
          className="tab-pane lesson__content-related-lessons related-lesson-cards styleized card-container"
        >
          {lessonCards(props.item.related_lessons, "lesson")}
        </div>
      )}
      {props.currentUser && (
        <div role="tabpanel" className="tab-pane lesson__practice song__details-user-practice">
          {practiceTab()}
        </div>
      )}

      {props.downloadables && props.downloadables.length > 0 && (
        <div role="tabpanel" className="tab-pane lesson__content-downloads">
          {downloadablesTab()}
        </div>
      )}
      {props.item.vimeo_download_available === true && (
        <div role="tabpanel" className="tab-pane lesson__vimeo-downloads">
          {vimeoDownloadsTab(props.item)}
        </div>
      )}

      {props.currentUser && (
        <div
          role="tabpanel"
          className="tab-pane lesson__content-user-notepad song__details-user-notepad"
        >
          <UserNote
            user={props.currentUser}
            noteableId={props.item.id}
            noteableType={props.itemableType}
          />
        </div>
      )}
    </div>
  );
};

export default LessonContentTabBody;
