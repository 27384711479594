import React from "react";
import Slider from "react-slick";
import TeachersSwoosh from "./TeachersSwoosh";
import { CDN_URL } from "../../components/shared/CdnUrl";
import "../../components/shared/polyfills/replaceAll.js";
import { withInnerWidth } from "../../components/shared/WithInnerWidth";
import { TeachersFaqs } from "./TeachersFaqs";
import { TEACHERS } from "./constants.teachers";
import { ARTISTS } from "./constants.artists";
import { GoalsIcon, OneOnOneIcon, TeachersIcon } from "./icon.components";
import { TeacherCard } from "./TeacherCard";
import { ArtistCard } from "./ArtistCard";
import { TestimonialCard } from "./TestimonialCard";
import { TESTIMONIALS } from "./constants.testimonials";
import { BootstrapSize, breakpoints } from "../../components/shared/bootstrap-helpers";
import { StaticBanner } from "../../components/shared/StaticBanner";
import { Button, ThemeProvider, Typography, useMediaQuery } from "@mui/material";
import { jgDenimTheme } from "../../jg-material/theme/jg-denim-theme";
import { jgDenimColors } from "../../jg-material/theme/jg-denim-colors";

class Teachers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teachers: TEACHERS,
      artists: ARTISTS,
      showAllTeachers: window.location.href.split("/").pop() === "all",
      showTeacher:
        window.location.href.split("/").pop() !== "all" &&
        TEACHERS.find((teacher) => teacher.slug === window.location.href.split("/").pop()),
      showArtist:
        window.location.href.split("/").pop() !== "all" &&
        ARTISTS.find((artist) => artist.slug === window.location.href.split("/").pop()),
    };
  }

  setActiveTab = (teacher, tabName) => {
    const teachers = this.state.teachers;

    teachers[teachers.findIndex((t) => t.name == teacher.name)].activeTab = tabName;

    this.setState({ teachers });
  };

  setActiveArtistTab = (artist, tabName) => {
    const artists = this.state.artists;

    artists[artists.findIndex((t) => t.name == artist.name)].activeTab = tabName;

    this.setState({ artists });
  };

  setShowDetails = (artist, showDetails) => {
    const artists = this.state.artists;

    artists[artists.findIndex((t) => t.name == artist.name)].showDetails = showDetails;

    this.setState({ artists });
  };

  render() {
    const isSMScreen = this.props.width <= breakpoints[BootstrapSize.smDevice];
    return (
      <ThemeProvider theme={jgDenimTheme}>
        <div className="teachers">
          {!this.state.showAllTeachers && !this.state.showTeacher && !this.state.showArtist ? (
            <>
              <TeachersSwoosh />
              <div className="teachers__body">
                <div className="teachers__body__intro">
                  <img
                    src={`${CDN_URL}/images/pages/teachers/JG-ApprovedTeachers-Logo.png`}
                    alt="JustinGuitar Approved Teachers"
                    className="approved-teachers-logo"
                  />
                  <div>
                    <h2 className="uppercase header2">Get one-on-one guitar help!</h2>
                    <p>
                      I get frequent emails from students looking for one-on-one lessons. I only
                      offer a <a href="/private-one-on-one-lessons">few private lessons per year</a>
                      , but I know some excellent teachers who would love to help you out if you
                      want some individual guidance.
                    </p>
                  </div>
                </div>
                <div className="teachers__body__about">
                  <div className="description">
                    <h2>about the program</h2>
                    <div>
                      <h3>
                        <TeachersIcon />
                        Approved Teachers
                      </h3>
                      <p>
                        Learn from a curated group of teachers familiar with my teaching methodology
                        who are as passionate as me about guitar teaching!
                      </p>
                    </div>
                    <div>
                      <h3>
                        <GoalsIcon />
                        Your Lesson, Your Goals
                      </h3>
                      <p>
                        When booking a lesson, you'll fill in an Assessment Quiz so that your
                        teacher can prepare your lessons to meet your individual goals!
                      </p>
                    </div>
                    <div>
                      <h3>
                        <OneOnOneIcon />
                        Flexible One-on-One
                      </h3>
                      <p>
                        Whether you’re a beginner or advanced player, want a one-off session or
                        weekly support, book sessions with the flexibility you're looking for!
                      </p>
                    </div>
                  </div>
                  <div className="photos">
                    <Slider
                      {...{
                        dots: true,
                        infinite: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      }}
                    >
                      {TEACHERS.map((teacher, idx) => (
                        <div className="photo" key={idx}>
                          <div className="bg">
                            <img src={teacher.largeImage} alt={teacher.name} />
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
                <div className="teachers__body__disclaimer" id="list">
                  <p>
                    💡 Please note that JustinGuitar doesn't take any commissions from the Approved
                    Teachers.
                  </p>
                  <p>I just want to support their work, and your learning process!</p>
                </div>
                <div className="teachers__body__list" id="list">
                  <h2>the teachers</h2>
                  <div className="cards">
                    <Slider
                      {...{
                        dots: true,
                        infinite: true,
                        slidesToShow: this.props.width >= 1300 ? 2 : 1,
                        slidesToScroll: 1,
                        variableWidth: true,
                      }}
                    >
                      {TEACHERS.map((teacher, idx) => (
                        <TeacherCard
                          key={idx}
                          teacher={teacher}
                          setActiveTab={this.setActiveTab}
                          width={this.props.width}
                        />
                      ))}
                    </Slider>
                  </div>
                  <button
                    className="button button--primary"
                    onClick={() => (window.location.href = "/teachers/all")}
                  >
                    view all teachers
                  </button>
                </div>
                <StaticBanner
                  imageUrl={`${CDN_URL}/redesign/2023/home-page/male-composer-working-new-song-banner.png`}
                  imageUrlSM={`${CDN_URL}/redesign/2023/home-page/male-composer-working-new-song-banner-sm.png`}
                  // targetUrl={"/clubs"}
                  width={1008}
                  width_sm={320}
                  marginTop={isSMScreen ? 0 : 40}
                  marginBottom={isSMScreen ? 50 : 120}
                >
                  {isSMScreen ? (
                    <>
                      <Typography
                        color={jgDenimColors.white}
                        variant="h6"
                        maxWidth={"280px"}
                        textAlign={"center"}
                        // marginBottom={'20px'}
                      >
                        Join Our Free Live Group Classes on Zoom!
                      </Typography>
                      <Typography
                        color={jgDenimColors.white}
                        variant="body1"
                        // marginBottom={'20px'}
                        maxWidth={"280px"}
                        textAlign={"center"}
                        padding={"0 18px"}
                      >
                        JustinGuitar Approved Teachers and Justin himself give regular free live
                        classes at Clubs—the perfect spot to ask your guitar questions and learn
                        together!
                      </Typography>
                      <Button onClick={() => (window.location.href = "/clubs")}>Learn More</Button>
                    </>
                  ) : (
                    <>
                      <Typography color={jgDenimColors.white} variant="h4">
                        Join Our FREE Group Classes on Zoom!
                      </Typography>
                      <Typography
                        color={jgDenimColors.white}
                        variant="body1"
                        maxWidth={"760px"}
                        padding={"0 32px"}
                        textAlign={"center"}
                      >
                        JustinGuitar Approved Teachers and Justin himself give regular free live
                        classes at Clubs—the perfect spot to ask your guitar questions and learn
                        together!
                      </Typography>
                      <Button onClick={() => (window.location.href = "/clubs")}>
                        Join JustinGuitar Clubs
                      </Button>
                    </>
                  )}
                </StaticBanner>
                <div className="teachers__body__artists">
                  <h2>the artists</h2>
                  <p>
                    Perfect for more advanced players looking to learn from players with a signature
                    tone or inimitable style. The Artists won’t use the JustinGuitar methodology but
                    their own during lessons.
                  </p>
                  <div className="cards">
                    {ARTISTS.map((artist, idx) => (
                      <ArtistCard
                        key={idx}
                        artist={artist}
                        setActiveArtistTab={this.setActiveArtistTab}
                        setShowDetails={this.setShowDetails}
                        width={this.props.width}
                      />
                    ))}
                  </div>
                </div>
                <div className="teachers__body__testimonials">
                  <h2>testimonials</h2>
                  <div className="quotes"></div>
                  <div className="cards">
                    <Slider
                      {...{
                        dots: this.props.width > breakpoints[BootstrapSize.smDevice],
                        infinite: true,
                        slidesToShow:
                          this.props.width >= 1200 ? 3 : this.props.width >= 992 ? 2 : 1,
                        slidesToScroll: 1,
                      }}
                    >
                      {TESTIMONIALS.map((testimonial, idx) => (
                        <TestimonialCard key={idx} testimonial={testimonial} />
                      ))}
                    </Slider>
                  </div>
                </div>
                <div className="teachers__body__faq">
                  <TeachersFaqs faqs={this.props.faqs} />
                </div>
              </div>
            </>
          ) : this.state.showTeacher ? (
            <div className="teachers__body all-teachers container">
              <div className="thumbs">
                <a href="/teachers">Approved Teachers</a>
                {" > "}
                <span>{this.state.showTeacher.name}</span>
              </div>
              <div className="teachers__body__list">
                <h2>{this.state.showTeacher.name}</h2>
                <div className="cards">
                  <TeacherCard
                    teacher={this.state.showTeacher}
                    setActiveTab={this.setActiveTab}
                    width={this.props.width}
                  />
                </div>
              </div>
            </div>
          ) : this.state.showArtist ? (
            <div className="teachers__body all-teachers container">
              <div className="thumbs">
                <a href="/teachers">Approved Teachers</a>
                {" > "}
                <span>{this.state.showArtist.name}</span>
              </div>
              <div className="teachers__body__artists">
                <h2>{this.state.showArtist.name}</h2>
                <div className="cards">
                  <ArtistCard
                    artist={this.state.showArtist}
                    setActiveArtistTab={this.setActiveArtistTab}
                    setShowDetails={this.setShowDetails}
                    width={this.props.width}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="teachers__body all-teachers container">
              <div className="thumbs">
                <a href="/teachers">Approved Teachers</a>
                {" > "}
                <span>All Teachers</span>
              </div>
              <div className="teachers__body__list">
                <h2>all teachers</h2>
                <div className="cards">
                  {TEACHERS.map((teacher, idx) => (
                    <TeacherCard
                      key={idx}
                      teacher={teacher}
                      setActiveTab={this.setActiveTab}
                      width={this.props.width}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

export default withInnerWidth(Teachers);
