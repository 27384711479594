import React from "react";
import PropTypes from "prop-types";
import Carousel from "./Carousel";

const GRADES = [
  {
    key: "GR1",
    subtitle: "Beginner",
    title: "Grade 1",
    description: "Completely new to guitar? Start here! Easy open chords and loads more fun.",
    classes: "tile tile--grade-1",
    url: "/classes/beginner-guitar-course-grade-one",
  },
  {
    key: "GR2",
    subtitle: "Beginner",
    title: "Grade 2",
    description:
      "Don't be afraid of the F chord! :) Get started with cool Rock chords, Blues, and fingerpicking.",
    classes: "tile tile--grade-2",
    url: "/classes/beginner-guitar-course-grade-two",
  },
  {
    key: "GR3",
    subtitle: "Beginner",
    title: "Grade 3",
    description:
      "Consolidate your Beginner skills. Learn how to make the most of your practice time!",
    classes: "tile tile--grade-3",
    url: "/classes/beginner-guitar-course-grade-three",
  },
  {
    key: "GR4",
    subtitle: "Intermediate",
    title: "Grade 4",
    description: "Ready for the E Shape Barre Chords? They're here - with much more.",
    classes: "tile tile--grade-4",
    url: "/classes/intermediate-guitar-course-grade-four",
  },
  {
    key: "GR5",
    subtitle: "Intermediate",
    title: "Grade 5",
    description: "Master Rhythm, the tricky A Shape, and loads of fun with Folk finger style.",
    classes: "tile tile--grade-5",
    url: "/classes/intermediate-guitar-course-grade-five",
  },
  {
    key: "GR6",
    subtitle: "Intermediate",
    title: "Grade 6",
    description:
      "Consolidate your Transcribing skills! Also, The CAGED Shapes, Style modules, and more.",
    classes: "tile tile--grade-6",
    url: "/classes/intermediate-guitar-course-grade-six",
  },
  {
    key: "GR7",
    subtitle: "Advanced",
    title: "Grade 7",
    description: "Master Modes and Rock techniques! Get started with Country and Funk Basics.",
    classes: "tile tile--grade-7",
    url: "/classes/advanced-guitar-course-grade-seven",
  },
  {
    key: "GR8",
    subtitle: "Advanced",
    title: "Grade 8",
    description: `My Advanced Courses aren't available yet, and I still don't know when they will be. Explore my <a href="/teachers">Approved Teachers</a> for Advanced Help!`,
    classes: "tile tile--grade-8",
    url: null,
  },
  {
    key: "GR9",
    subtitle: "Advanced",
    title: "Grade 9",
    description: `My Advanced Courses aren't available yet, and I still don't know when they will be. Explore my <a href="/teachers">Approved Teachers</a> for Advanced Help!`,
    classes: "tile tile--grade-9",
    url: null,
  },
];

const gradeTiles = () => {
  return GRADES.map((g) => {
    return (
      <div className={g["classes"]} key={g["key"]}>
        <span className="level-header uppercase">{g["subtitle"]}</span>
        <span className="grade-header uppercase">{g["title"]}</span>
        <div className={`hover-content${!g["url"] ? " no-url" : ""}`}>
          <p dangerouslySetInnerHTML={{ __html: g["description"] }}></p>
          <div className="bottom-btn">
            {g["url"] && (
              <a href={g["url"]} className="button button--transparent">
                View Course
              </a>
            )}
          </div>
        </div>
      </div>
    );
  });
};

const gradeCarousel = (width) => {
  return <Carousel width={width} items={GRADES} cardType="grade" />;
};

const CoursesByGrade = (props) => (
  <div className="levels-list lessons__left">
    <h3 className="header3 uppercase">Courses By Levels</h3>
    <div className="levels-list__container">
      {props.width > 768 ? gradeTiles() : gradeCarousel(props.width)}
    </div>
  </div>
);

export default CoursesByGrade;

CoursesByGrade.propTypes = {
  width: PropTypes.number.isRequired,
};
