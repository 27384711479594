import React from "react";

const SupportTeamCard = ({ data }) => {
  return (
    <div className="support-team__card">
      <div className="support-team__card__profile">
        <div
          style={{
            backgroundImage: `url('${data.imageUrl}')`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          className={
            "support-team__img " + (data.hasPlaceholder ? "support-team__img--placeholder" : "")
          }
        ></div>
        <h3 className="header3 uppercase">{data.name}</h3>
        <p className="support-team__card__profile__position">{data.position}</p>
      </div>
      <p
        className="support-team__card__description"
        dangerouslySetInnerHTML={{ __html: data.description }}
      ></p>
    </div>
  );
};

export default SupportTeamCard;
