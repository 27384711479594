import React from "react";
import ConditionalWrapper from "../components/ConditionalWrapper";
import { explore } from "./consts";
import { ContentCarousel } from "../components/ContentCarousel";

const SupportYourLearningJourney = ({ width }) => {
  return (
    <div className="explore">
      <h2>HOW WE SUPPORT YOUR LEARNING JOURNEY</h2>
      <div className="explore-cards">
        <ConditionalWrapper
          condition={width <= 992}
          wrapper={(children) => (
            <ContentCarousel settings={{ slidesToShow: 1, dots: true, arrows: false }}>
              {children}
            </ContentCarousel>
          )}
        >
          {explore.map((item, idx) => (
            <div key={idx} className="explore-card">
              <div className="image" style={{ backgroundImage: `url(${item.img})` }}></div>
              <div className="description">
                <h3>{item.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
              </div>
            </div>
          ))}
        </ConditionalWrapper>
      </div>
    </div>
  );
};

export default SupportYourLearningJourney;
