export const colors = [
  "#F98007",
  "#109634",
  "#CC7676",
  "#000AD8",
  "#C71B00",
  "#6D676E",
  "#FFDC00",
  "#59E000",
  "#49A0A0",
  "#1684BF",
  "#7A0202",
  "#5D006D",
  "#00A9FF",
  "#6B4B2B",
  "#FFF847",
  "#FF205B",
  "#8E99CC",
  "#AAFFD6",
  "#FF9647",
  "#002602",
];
