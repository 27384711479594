import { Components } from "@mui/material";

export const formControl: Components["MuiFormControl"] = {
  styleOverrides: {
    root: {},
  },
  defaultProps: {
    fullWidth: true,
  },
};
