import React from "react";
import PropTypes from "prop-types";
import { StripeProvider, Elements } from "react-stripe-elements";
import { setStripeUserId, stripeLogo } from "../components/payments/PaymentUtils";
import CheckoutForm from "../components/CheckoutForm";
import LoginPrompt from "../components/auth/LoginPrompt";

import { STRIPE_TABS_PUBLISHABLE_KEY } from "../payments/PaymentConstants";
import ConfettiGenerator from "confetti-js";
import * as moment from "moment";
import { CDN_URL } from "../components/shared/CdnUrl";
import { withInnerWidth } from "../components/shared";

const CheckboxCheckedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
      fill="#6ACF64"
    />
  </svg>
);

const CheckboxUncheckedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
      fill="#81ABC1"
    />
  </svg>
);

const CONFETTI_COLORS = [
  [64, 110, 142],
  [129, 171, 193],
  [255, 225, 204],
  [253, 106, 2],
  [231, 222, 210],
  [189, 163, 138],
];

const YEARLY_SUBSCRIPTION = {
  amount: "4999",
  frequency: "yearly",
  formattedYearly: "USD 49.99",
  formattedMonthly: "USD 4.16",
};

const MONTHLY_SUBSCRIPTION = {
  amount: "799",
  frequency: "monthly",
  formattedMonthly: "USD 7.99",
};

const tabsSubscriptionParagraph = `Publishing royalties stop me from making this a free website feature. You can only view the Tabs online - the licensing agreement prevents Prints or Downloads.<br>But, I try to keep it really affordable. I recommend the yearly subscription for the best deal!`;

class TabsPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
      showPaywallSuccess: false,
      frequency: null,
      subscribeWord: props.trialAvailable ? "start my 7-day free trial" : "subscribe now",
      nextUrl: window.location.href.includes("?ref=")
        ? `/songs/${window.location.href.split("?ref=")[1]}`
        : "/songs",
      showCheckoutForm: false,
    };
    this.confettiCanvas = React.createRef();
  }

  componentDidMount() {
    this.setSubscriptionOption("yearly");
    // handle async Stripe load
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(STRIPE_TABS_PUBLISHABLE_KEY) });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        this.setState({ stripe: window.Stripe(STRIPE_TABS_PUBLISHABLE_KEY) });
      });
    }
  }

  onPurchaseSuccess = () => {
    this.setState({ showPaywallSuccess: true }, () => {
      const confettiSettings = {
        target: this.confettiCanvas.current,
        colors: CONFETTI_COLORS,
        rotate: true,
      };
      var confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();
      location.hash = "#purchase-success";
    });
  };

  setSubscriptionOption = (frequency) => {
    if (this.state.frequency === frequency) {
      return;
    }
    if (frequency === "yearly") {
      this.setState({
        amount: YEARLY_SUBSCRIPTION["amount"],
        frequency: YEARLY_SUBSCRIPTION["frequency"],
        reference: this.props.subscriptionReferences["yearly"],
      });
    } else {
      this.setState({
        amount: MONTHLY_SUBSCRIPTION["amount"],
        frequency: MONTHLY_SUBSCRIPTION["frequency"],
        reference: this.props.subscriptionReferences["monthly"],
      });
    }
  };

  trialDays = () => {
    return 7;
  };

  render() {
    return (
      <div className="tabs-payment">
        {this.props.user ? (
          this.state.showPaywallSuccess ? (
            <div className="tabs-payment__purchase-success">
              <h2 id="purchase-success">thank you for subscribing!</h2>
              {this.props.trialAvailable ? (
                <p className="congratulations">
                  Congratulations on starting your {this.trialDays()}-day free trial for
                  JustinGuitar Tabs.
                </p>
              ) : null}
              <p className="tabs-location">
                Here is <strong>where to look out for the Tabs icon:</strong>
              </p>
              <img
                src={`${CDN_URL}/images/products/redesign/tabs-layout${
                  this.props.width <= 992 ? "-sm" : ""
                }.png`}
                alt="tabs layout"
                style={{
                  maxWidth: this.props.width <= 992 ? "320px" : "800px",
                  margin: "10px auto",
                }}
              />
              {this.props.trialAvailable ? (
                <p className="charging-info">{`Remember you will be charged after ${moment()
                  .add(this.trialDays(), "days")
                  .format("LL")}.`}</p>
              ) : null}
              <button
                className="button button--primary"
                onClick={() => (window.location.href = `${this.state.nextUrl}?tab=sheet-music`)}
              >
                explore song lessons
              </button>
              <canvas ref={this.confettiCanvas}></canvas>
            </div>
          ) : (
            <>
              <div className="tabs-payment__trial">
                <h2 id="tabs">
                  {this.props.trialAvailable
                    ? this.state.showCheckoutForm
                      ? `start my ${this.trialDays()}-day free trial!`
                      : `get a ${this.trialDays()}-day free trial!`
                    : "subscribe to justinguitar tabs!"}
                </h2>
                <p
                  className="tabs-payment__trial__description"
                  dangerouslySetInnerHTML={{
                    __html: this.props.trialAvailable
                      ? this.state.showCheckoutForm
                        ? `Get ${this.trialDays()}-day free trial then ${
                            this.state.frequency === "yearly"
                              ? YEARLY_SUBSCRIPTION["formattedYearly"] + "/year."
                              : MONTHLY_SUBSCRIPTION["formattedMonthly"] + "/month."
                          } Cancel anytime. Cancel before ${moment()
                            .add(this.trialDays(), "days")
                            .format("LL")} to avoid being charged.`
                        : `Try a ${this.trialDays()} day free trial of JustinGuitar Tabs!`
                      : null,
                  }}
                ></p>
                <p className="tabs-payment__trial__notice">
                  Choose your subscription plan for when your 7-day free trial ends:
                </p>
                <div id="plans" className={`tabs-payment__trial__plan`}>
                  <button
                    className={`${this.state.frequency === "monthly" ? "active" : null}`}
                    onClick={() => this.setSubscriptionOption("monthly")}
                  >
                    {this.state.frequency === "monthly" ? (
                      <CheckboxCheckedIcon />
                    ) : (
                      <CheckboxUncheckedIcon />
                    )}
                    <p>Monthly subscription*</p>
                    <p>{MONTHLY_SUBSCRIPTION["formattedMonthly"]}</p>
                    <p>
                      {MONTHLY_SUBSCRIPTION["formattedMonthly"]} billed monthly.
                      <br />
                      Cancel it anytime.
                    </p>
                  </button>
                  <button
                    className={`${this.state.frequency === "yearly" ? "active" : null}`}
                    onClick={() => this.setSubscriptionOption("yearly")}
                  >
                    {this.state.frequency === "yearly" ? (
                      <CheckboxCheckedIcon />
                    ) : (
                      <CheckboxUncheckedIcon />
                    )}
                    <div className="badges">
                      <div className="badge">best offer</div>
                    </div>
                    <p>Yearly subscription*</p>
                    <p>
                      <span className="actual-price">{YEARLY_SUBSCRIPTION["formattedYearly"]}</span>
                    </p>

                    <p>
                      Billed annually. Equivalent to {YEARLY_SUBSCRIPTION["formattedMonthly"]}{" "}
                      monthly.
                    </p>
                  </button>
                </div>
                {!this.state.showCheckoutForm ? (
                  <>
                    <button
                      className="button button--primary"
                      onClick={() => {
                        this.setState({ showCheckoutForm: true });
                        location.hash = "#plans";
                      }}
                    >
                      {this.props.trialAvailable
                        ? `start my ${this.trialDays()}-day free trial`
                        : "subscribe now"}
                    </button>
                    <p className="tabs-payment__disclaimer">
                      * Please note that <strong>JustinGuitarTABS</strong> and{" "}
                      <strong>JustinGuitar Songs & Lessons App</strong> are two different products.
                      Subscribing to one of them doesn’t give you access to the other. Read the FAQ
                      below for more information, or <a href="#">contact us</a>.
                    </p>
                  </>
                ) : null}
              </div>
              {this.state.showCheckoutForm ? (
                <div className="tabs-payment__payment-form">
                  <p>Pay securely</p>
                  <StripeProvider apiKey={this.props.stripeKey}>
                    <div className="payments__stripe">
                      <Elements
                        fonts={[
                          {
                            cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:400",
                          },
                        ]}
                      >
                        <CheckoutForm
                          price={this.state.amount}
                          currency="USD"
                          reference={this.state.reference}
                          subscription={true}
                          frequency={this.state.frequency}
                          recaptchaKey={this.props.recaptchaKey}
                          stripeCustomerId={this.state.stripeCustomerId}
                          purchaseType="tabs"
                          successCallback={this.onPurchaseSuccess}
                          info={
                            this.props.trialAvailable
                              ? `By clicking "Agree & Subscribe," you are enrolling in a ${
                                  this.state.frequency === "yearly"
                                    ? YEARLY_SUBSCRIPTION["frequency"]
                                    : MONTHLY_SUBSCRIPTION["frequency"]
                                } subscription of ${
                                  this.state.frequency === "yearly"
                                    ? YEARLY_SUBSCRIPTION["formattedYearly"]
                                    : MONTHLY_SUBSCRIPTION["formattedMonthly"]
                                } once your ${this.trialDays()}-day free trial expires. Then, unless you cancel your subscription before your second ${
                                  this.state.frequency === "yearly" ? "year" : "month"
                                }, you'll be charged the regular ${
                                  this.state.frequency === "yearly" ? "yearly" : "monthly"
                                } fee of ${
                                  this.state.frequency === "yearly"
                                    ? YEARLY_SUBSCRIPTION["formattedYearly"]
                                    : MONTHLY_SUBSCRIPTION["formattedMonthly"]
                                }. You can cancel your subscription at any time, effective at the end of the billing period. There are no refunds or credits for partial months or years.`
                              : `By clicking “Agree & Subscribe”, you are enrolling in automatic payments of ${
                                  this.state.frequency === "yearly"
                                    ? YEARLY_SUBSCRIPTION["formattedYearly"] + "/year"
                                    : MONTHLY_SUBSCRIPTION["formattedMonthly"] + "/month"
                                } beginning now that will continue until you cancel. You can cancel at any time, effective at the end of the billing period. There are no refunds or credits for partial months or year.`
                          }
                          splitInputs={true}
                          buttonText="agree & subscribe"
                        />
                      </Elements>
                    </div>
                  </StripeProvider>
                  <div className="payments__logo">{stripeLogo()}</div>
                </div>
              ) : null}
            </>
          )
        ) : (
          <div className="jgtabs__needs-login">
            <h2 id="tabs">start my {this.trialDays()}-day free trial</h2>
            <LoginPrompt token={this.props.token} loginAction="subscribe" />
          </div>
        )}
      </div>
    );
  }
}

TabsPayment.propTypes = {
  stripeKey: PropTypes.string.isRequired,
  recaptchaKey: PropTypes.string.isRequired,
  subscriptionReferences: PropTypes.object.isRequired,
};

export default withInnerWidth(TabsPayment);
