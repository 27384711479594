import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import SectionHeader from "./SectionHeader";
import { unixToFormattedDate } from "../pricingPlans/utils";

const CheckoutSuccessDetails = ({ checkoutSession }) => {
  if (!checkoutSession) return null;

  const { created, amountTotal, email, trialPeriodEnd } = checkoutSession.data.attributes;
  const purchaseDate = unixToFormattedDate(created);

  return (
    <Box>
      <SectionHeader>Order Details</SectionHeader>

      <Grid container sx={{ pb: 3 }}>
        <Grid item xs={12} sm={2} sx={{ p: 1 }}>
          <Typography variant="subtitle1" sx={{ letterSpacing: 0 }}>
            Order Date
          </Typography>
          <Typography variant="h6" sx={{ letterSpacing: 0 }}>
            {purchaseDate}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={2} sx={{ p: 1 }}>
          {trialPeriodEnd ? (
            <>
              <Typography variant="subtitle1" sx={{ letterSpacing: 0 }}>
                Free Trial Ends
              </Typography>
              <Typography variant="h6" sx={{ letterSpacing: 0 }}>
                {unixToFormattedDate(trialPeriodEnd)}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="subtitle1" sx={{ letterSpacing: 0 }}>
                Purchase Amount
              </Typography>
              <Typography variant="h6" sx={{ letterSpacing: 0 }}>
                ${amountTotal / 100}
              </Typography>
            </>
          )}
        </Grid>

        <Grid item xs={12} sm={2} sx={{ p: 1 }}>
          <Typography variant="subtitle1" sx={{ letterSpacing: 0 }}>
            Email
          </Typography>
          <Typography variant="h6" sx={{ letterSpacing: 0 }}>
            {email}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CheckoutSuccessDetails;
