import React from "react";
import {
  createUserPracticeItem,
  createUserSongItem,
  deleteUserPracticeItem,
  deleteUserSongItem,
  selectAppsForSong,
  selectBooksForSong,
  selectCurrentUser,
  selectPracticeSongs,
  selectSavedSongs,
} from "./store";
import { connect } from "react-redux";
import { storeSearchAndNavigateToItem } from "../shared/search";
import LoginModal from "../auth/LoginModal";

export class SongActionsDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promptLogInForSave: false,
      promptLogInForPracticeAssistant: false,
      showingLoginModal: false,
    };
    this.timeout = null;
  }

  componentWillUnmount() {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }
  }

  promptLogIn(type) {
    const TIMEOUT_MS = 10_000;
    const key = `promptLogInFor${type}`;
    this.setState({ [key]: true }, () => {
      this.timeout = setTimeout(() => {
        this.setState({ [key]: false });
      }, TIMEOUT_MS);
    });
  }

  handleSaveButtonClick = (e) => {
    e.stopPropagation();
    const { currentUser, songIsSaved, userSongItem, song, saveSong, unsaveSong } = this.props;

    if (currentUser == null) {
      this.promptLogIn("Save");
    } else if (songIsSaved) {
      unsaveSong(userSongItem);
    } else {
      saveSong(currentUser.data.attributes.id, song.id);
    }
  };

  handlePracticeAssistantClick = (e) => {
    e.stopPropagation();
    const { currentUser, practiceItemIsSaved, createUserPracticeItem, deleteUserPracticeItem } =
      this.props;

    if (currentUser == null) {
      this.promptLogIn("PracticeAssistant");
    } else if (practiceItemIsSaved) {
      deleteUserPracticeItem();
    } else {
      createUserPracticeItem();
    }
  };

  goToBook = (e) => {
    e.preventDefault();
    const { book } = this.props;
    storeSearchAndNavigateToItem(book);
  };

  goToApp = (e) => {
    e.preventDefault();
    window.location.href = "https://justinguitarapp.onelink.me/3065804798/fc941697";
  };

  render() {
    const { songIsSaved, practiceItemIsSaved, book, app } = this.props;
    const { promptLogInForSave, promptLogInForPracticeAssistant } = this.state;
    return (
      <div className="song__actions">
        <div className="song__actions--action" onClick={this.handleSaveButtonClick}>
          <i className={`icon icon-heart${songIsSaved ? "" : "-outline"}`} />
          {promptLogInForSave ? (
            <div style={{ display: "inline" }}>
              you must be{" "}
              <a
                href="#"
                onClick={() => {
                  this.setState({ showingLoginModal: true });
                }}
              >
                Logged In
              </a>
            </div>
          ) : songIsSaved ? (
            "remove"
          ) : (
            "save"
          )}
        </div>

        <div className="song__actions--action" onClick={this.handlePracticeAssistantClick}>
          <i className="icon icon-playlist" />
          {promptLogInForPracticeAssistant ? (
            <div style={{ display: "inline" }}>
              you must be{" "}
              <a
                href="#"
                onClick={() => {
                  this.setState({ showingLoginModal: true });
                }}
              >
                Logged In
              </a>
            </div>
          ) : practiceItemIsSaved ? (
            "remove from practice assistant"
          ) : (
            "practice assistant"
          )}
        </div>
        <div className="song__actions--action-group" style={{ display: "flex", flexWrap: "wrap" }}>
          {app && (
            <span onClick={this.goToApp} className="in-my-app">
              <i className="icon icon-iphone" />
              <span>Play Along With My App!</span>
            </span>
          )}
          {book != null && (
            <span onClick={this.goToBook}>
              <i className="icon icon-book-open" />
              In A Songbook
            </span>
          )}
        </div>
        {this.state.showingLoginModal && (
          <LoginModal
            isOpen={true}
            toggleModal={() => {
              this.setState({ showingLoginModal: false });
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, { song }) => {
  const savedSongs = selectSavedSongs(state);
  const practiceItems = selectPracticeSongs(state);
  const books = selectBooksForSong(state)(song.id);
  const apps = selectAppsForSong(state)(song.id);
  return {
    currentUser: selectCurrentUser(state),
    songIsSaved: savedSongs[song.id] != null,
    practiceItemIsSaved: practiceItems[song.id] != null,
    userSongItem: savedSongs[song.id],
    book: books[0],
    app: apps[0],
  };
};

const mapDispatchToProps = (dispatch, { song }) => ({
  saveSong: (user_id, itemable_id) =>
    dispatch(
      createUserSongItem({ itemable_id, user_id, itemable_type: "Song", item_status: "saved" })
    ),
  unsaveSong: (userItem) => dispatch(deleteUserSongItem(userItem)),
  createUserPracticeItem: () => dispatch(createUserPracticeItem(song.id)),
  deleteUserPracticeItem: () => dispatch(deleteUserPracticeItem(song.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SongActionsDrawer);
