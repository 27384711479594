import PropTypes from "prop-types";
import React from "react";

export default class ModuleListItem extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props, _railsContext) {
    super(props);
    this.state = { data: this.props.data };
  }

  render() {
    let item = this.state.data;

    return (
      <div className={"card all-lessons-card col-lg-12 col-md-12 col-sm-12 col-xs-12"}>
        <a
          className={item.grade ? `border-${item.grade}` : "border-None"}
          href={item.path}
          target="_self"
        >
          <div className="card-thumbnail card-thumbnail--module">
            <img src={item.thumbnail} />
          </div>
          <div className="card-content card-content--module">
            <div className="card-text">
              <div className="card-title">
                <h5 className="card-title-header justin-blue uppercase no-margin">{item.title}</h5>
                <div className="card-description">
                  <p className="pagragraph paragraph--card"> {item.overview} </p>

                  <div className="card-bottom">
                    <p className="paragraph paragraph--medium-gray">
                      {" "}
                      <span className="glyphicon glyphicon-time"></span> {item.lesson_runtime}
                    </p>
                    <p className="paragraph paragraph--medium-gray">
                      {" "}
                      <span className="glyphicon glyphicon-play"></span>{" "}
                      {item.lessons ? item.lessons + " lessons" : "No lessons yet"}
                    </p>
                    <p className="paragraph paragraph--medium-gray">ID: {item.reference} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}
