import React from "react";
import { songSortByLabels, sortByOptions } from "../consts";

import { withInnerWidth } from "../../shared";

const SongsSort = ({ sortBy, setSortBy, hasQuery }) => {
  return (
    <div className="songs-index__sort-by">
      <span>Sort By: </span>
      <select value={sortBy || ""} onChange={(e) => setSortBy(e.target.value)}>
        {Object.entries(songSortByLabels).map(([value, label]) => (
          <option
            value={value}
            key={value}
            disabled={value === sortByOptions.relevance && !hasQuery}
          >
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default withInnerWidth(SongsSort);
