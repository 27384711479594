import React from "react";

export default class Banner extends React.Component {
  constructor(props, _railsContext) {
    super(props);

    const seenBanner = localStorage.getItem("close-justinguitar-banner");
    let visible = true;

    if (seenBanner && seenBanner === "closed") {
      visible = false;
    }

    this.state = { visible: visible };
  }

  closeBanner = () => {
    this.setState({ visible: false });
    localStorage.setItem("close-justinguitar-banner", "closed");
  };

  render() {
    let visible = this.state.visible;

    return (
      visible && (
        <div className="top-banner">
          <div className="top-banner__wrapper">
            <div className="top-banner__content">
              <div className="top-banner__text">
                <p className="no-margin">{this.props.content}</p>
              </div>
              <div className="top-banner__button">
                <button className="top-banner__close" onClick={this.closeBanner}>
                  {"\u00d7"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}
