import React, { useState } from "react";
import { withBootstrapSize } from "../../shared/WithBootstrapSize";
import { BootstrapSize, breakpoints } from "../../shared/bootstrap-helpers";
import SongsFiltersList from "./SongsFiltersList";
import SongsPageSearch from "../filters/SongsPageSearch";
import { filters } from "../filters/filters";
import ChordsAndTabsFilterButtons from "./ChordsAndTabsFilterButtons";
import SongsFilterButton from "./SongsFilterButton";

const ListingControls = ({
  activeFilters,
  clearFilters,
  toggleFilter,
  width,
  searchQuery,
  setSearchQuery,
  setShowSearchParamsPane,
}) => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const hasActiveFilters = Object.values(activeFilters).some((f) => f);
  return (
    <div className="songs-listing__sidebar">
      <SongsPageSearch setQuery={setSearchQuery} />
      {width > breakpoints[BootstrapSize.lgDevice] ? (
        <>
          <ChordsAndTabsFilterButtons toggleFilter={toggleFilter} activeFilters={activeFilters} />
          <SongsFiltersList
            activeFilters={activeFilters}
            toggleFilter={toggleFilter}
            buttonClassModifier="blue"
            clearFilters={clearFilters}
            availableFilters={filters}
          />
        </>
      ) : width > breakpoints[BootstrapSize.smDevice] ? (
        <SongsFilterButton
          availableFilters={filters}
          activeFilters={activeFilters}
          filtersOpen={filtersOpen}
          openFilters={() => setFiltersOpen(true)}
          closeFilters={() => setFiltersOpen(false)}
          clearFilters={clearFilters}
          hasActiveFilters={hasActiveFilters}
          toggleFilter={toggleFilter}
          buttonClassModifier="blue"
          defaultClosed={true}
        />
      ) : (
        <button
          className="btn--filters button button--blue"
          onClick={() => setShowSearchParamsPane(true)}
        >
          <i className="icon icon-sliders" />
        </button>
      )}
    </div>
  );
};
export default ListingControls;
