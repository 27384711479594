import React from "react";
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory/src";
import { filterItems } from "./StatsFunctions";
import { colors } from "./LineColors";
const DATE_FORMAT = "ddd, MMM Do";
import * as moment from "moment";

export default class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.startDate === this.props.startDate &&
      nextProps.endDate === this.props.endDate &&
      nextProps.items === this.props.items
    ) {
      return false;
    } else {
      return true;
    }
  }

  setCoordinates = (items) => {
    let { startDate, endDate } = this.props;
    let datesObj = {};
    if (startDate && endDate) {
      let currentDate = startDate;
      while (currentDate <= endDate) {
        let d = moment(currentDate).format(DATE_FORMAT);
        datesObj[d] = {};
        currentDate = moment(currentDate).add(1, "days");
      }
    }
  };

  itemDates = (items) => {
    let dates = [];
    let { startDate, endDate } = this.props;
    if (startDate && endDate) {
      let currentDate = startDate;
      // do not use time in comparison - only day!
      while (
        moment(currentDate).startOf("day").toDate() <= moment(endDate).startOf("day").toDate()
      ) {
        let d = moment(currentDate).format("LL");
        dates.push(d);
        currentDate = moment(currentDate).add(1, "days");
      }
    }
    return dates;
  };

  noStat(item) {
    if (
      item.statistic === "" ||
      (item.statistic === null && item.statistic_name === "") ||
      item.statistic_name === null
    ) {
      return true;
    }
  }

  groupByItem = (items) => {
    let groupedItems = {};
    items.forEach((item) => {
      if (this.noStat(item)) {
        return;
      }
      if (groupedItems[item.title]) {
        groupedItems[item.title].push(item);
      } else {
        var a = [];
        a.push(item);
        groupedItems[item.title] = a;
      }
    });
    return groupedItems;
  };

  generateRandomColor() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }

  mostRecentValue(allItems, thisItem) {
    if (allItems.data && allItems.data.length > 0) {
      let earlierDates = allItems.data.filter((i) => Date.parse(i["x"]) < Date.parse(thisItem));
      if (earlierDates.length > 0) {
        earlierDates.sort((a, b) => {
          if (Date.parse(a["x"]) > Date.parse(b["x"])) {
            return 1;
          }
          if (Date.parse(a["x"]) < Date.parse(b["x"])) {
            return -1;
          }
          return 0;
        });
        return earlierDates[earlierDates.length - 1]["y"];
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  victoryLineValues = (itemHash, allDates) => {
    if (itemHash) {
      let lineValues = {};
      Object.keys(itemHash).forEach((key, index) => {
        lineValues[key] = {};
        let color = index + 1 > colors.length ? this.generateRandomColor() : colors[index];
        lineValues[key]["color"] = color;
        lineValues[key]["data"] = [];
        itemHash[key].forEach((i) => {
          let statNumber = i.statistic === "" || i.statistic === null ? 0 : parseInt(i.statistic);
          let itemDate = moment(i.date).format("LL");
          let existing = lineValues[key]["data"].filter((i) => i["x"] === itemDate);
          if (existing.length > 0) {
            let maxNumber = statNumber > existing[0]["y"] ? statNumber : existing[0]["y"];
            existing[0]["y"] = maxNumber;
          } else {
            lineValues[key]["data"].push({
              x: itemDate,
              y: statNumber,
            });
          }
        });
      });

      // stats should not go back to 0 on days without practice.
      Object.keys(lineValues).forEach((key) => {
        if (lineValues[key].data.length != allDates.length) {
          let lineDates = lineValues[key].data.filter((i) => i["y"] >= 0).map((j) => j["x"]);
          let missing = allDates.filter(function (obj) {
            return lineDates.indexOf(obj) == -1;
          });
          if (missing.length > 0) {
            missing.forEach((m) => {
              let mostRecent = this.mostRecentValue(lineValues[key], m);
              lineValues[key].data.push({ x: m, y: mostRecent });
            });
          }
        }
        let sorted = lineValues[key].data.sort((a, b) => {
          return new Date(a.x) - new Date(b.x);
        });
        lineValues[key].data = sorted;
      });
      return lineValues;
    }
  };

  yAxisTicks = (allValues) => {
    let totals = allValues.map((v) => v["y"]);
    let maxValue = 100;
    if (totals && totals.length > 0) {
      let maxTotal = Math.max(...totals);
      if (maxTotal > 0) {
        maxValue = maxTotal;
      }
      let secondPoint = Math.round(maxValue * (2 / 3));
      let firstPoint = Math.round(maxValue * (1 / 3));
      return [firstPoint, secondPoint, maxValue];
    } else {
      return [0, 50, 100];
    }
  };

  yAxis = (lineValues) => {
    return (
      <VictoryAxis
        dependentAxis
        style={{
          tickLabels: {
            fontSize: 7,
            fill: "#666666",
            letterSpacing: "0.5px",
            fontWeight: 600,
            fontFamily: "Montserrat, serif",
          },
        }}
        tickValues={this.yAxisTicks(lineValues)}
      />
    );
  };

  xAxis = (allDates) => {
    return (
      <VictoryAxis
        fixLabelOverlap={true}
        offset={20}
        style={{
          tickLabels: {
            fontSize: 7,
            textTransform: "uppercase",
            fill: "#666666",
            letterSpacing: "0.5px",
            fontWeight: 600,
            fontFamily: "Montserrat, serif",
          },
        }}
        tickValues={allDates}
        tickFormat={(t) => moment(t).format("MMM Do")}
      />
    );
  };

  victoryLineWithMoreThanZeroValue = (lineValues, key) => {
    if (lineValues[key].data.reduce((a, b) => a + (b["y"] || 0), 0) > 0) {
      return (
        <VictoryLine
          data={lineValues[key]["data"]}
          key={key}
          style={{
            data: {
              stroke: lineValues[key]["color"],
            },
            labels: {
              fontSize: 6,
              fontWeight: 600,
              fontFamily: "Montserrat, serif",
              fill: lineValues[key]["color"],
            },
          }}
        />
      );
    }
  };

  victoryLines = (lineValues) => {
    if (lineValues) {
      return Object.keys(lineValues).map((key) => {
        return this.victoryLineWithMoreThanZeroValue(lineValues, key);
      });
    }
  };

  colorKey = (values) => {
    return Object.keys(values).map((k) => {
      if (values[k].data.reduce((a, b) => a + (b["y"] || 0), 0) > 0) {
        return (
          <p
            key={k}
            style={{ color: values[k]["color"], fontWeight: "bold", margin: "2px 0" }}
          >{`${k}`}</p>
        );
      }
    });
  };

  render() {
    let { items, startDate, endDate } = this.props;
    if (items) {
      let itemsWithinRange = filterItems(items, startDate, endDate);
      let allDates = this.itemDates(itemsWithinRange);
      let groupedItemHash = this.groupByItem(itemsWithinRange);
      let lineValues = this.victoryLineValues(groupedItemHash, allDates);
      let arrayValues = Object.keys(lineValues).map((k) => lineValues[k]["data"].map((i) => i));
      let allValues = [].concat(...arrayValues);
      return (
        <div>
          <div className="color-key">
            <h3>Practice Items</h3>
            {this.colorKey(lineValues)}
          </div>

          <VictoryChart
            containerComponent={
              <VictoryVoronoiContainer
                labels={(d) => `${d.y}`}
                labelComponent={
                  <VictoryTooltip
                    cornerRadius={2}
                    pointerLength={7}
                    flyoutStyle={{ stroke: "none", fill: "white" }}
                  />
                }
              />
            }
          >
            {this.yAxis(allValues)}
            {this.xAxis(allDates)}
            {this.victoryLines(lineValues)}
          </VictoryChart>
        </div>
      );
    } else {
      return null;
    }
  }
}
