import { createSlice } from "@reduxjs/toolkit";

import { createVote, deleteVote } from "../effects";

export const initialState = {};

export const { actions, reducer } = createSlice({
  name: "entities",
  initialState,
  reducers: {
    addEntities(state, { payload }) {
      Object.entries(payload).forEach(([entityName, newEntities]) => {
        const existingEntities = state[entityName] || {};
        state[entityName] = {
          ...existingEntities,
          ...newEntities,
        };
      });
    },
    replaceEntities(state, { payload }) {
      Object.entries(payload).forEach(([entityName, entities]) => {
        state[entityName] = entities;
      });
    },
    clearEntities(state, { payload }) {
      payload.forEach((entityName) => {
        delete state[entityName];
      });
    },
    clearAll(state) {
      state = initialState;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createVote.fulfilled, (state, { payload }) => {
        const newVote = payload.data;
        const songRequest = state.songRequests[newVote.attributes.songRequestId];

        if (songRequest == null) {
          return state;
        }

        songRequest.attributes.voteCount += 1;
        return state;
      })
      .addCase(deleteVote.fulfilled, (state, { payload: { id } }) => {
        const songRequest = state.songRequests[id];

        if (songRequest == null) {
          return state;
        }

        songRequest.attributes.voteCount -= 1;
        return state;
      }),
});
