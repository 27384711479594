import React from "react";
import { connect } from "react-redux";
import { setTypeface } from "../../store/songStore";

class Typeface extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsOpen: false,
    };
  }

  setTypeface = (typeface) => {
    this.props.setTypeface(typeface);
    this.setState({ optionsOpen: false });
  };

  typefaceOptions = () => {
    const TYPEFACES = ["Arial", "Courier", "OpenSans", "Times", "Verdana"];

    return (
      <div style={{ textAlign: "right", fontSize: "12px" }}>
        {TYPEFACES.map((t) => {
          return (
            <div
              className="clickable typeface-option"
              style={{ fontFamily: t }}
              key={t}
              onClick={() => this.setTypeface(t)}
            >
              {t === "OpenSans" ? "Open Sans" : t}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { typeface } = this.props;
    return (
      <li className="submenu-item">
        <div className="change-option">
          <div className="change-option-label">Typeface</div>
          <div className="change-option-action">
            {this.state.optionsOpen ? (
              this.typefaceOptions()
            ) : (
              <span
                className="clickable"
                style={{ fontFamily: typeface }}
                onClick={() => this.setState({ optionsOpen: true })}
              >
                {typeface === "OpenSans" ? "Open Sans" : typeface}
              </span>
            )}
          </div>
        </div>
      </li>
    );
  }
}

const mapStateToProps = (state) => ({ typeface: state.entity.typeface });

const mapDispatchToProps = (dispatch) => ({
  setTypeface: (typeface) => dispatch(setTypeface(typeface)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Typeface);
