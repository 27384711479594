import React, { useState } from "react";
import { SECTIONS } from "./consts";
import AppDisclaimer from "./AppDisclaimer";
import RegistrationForm from "./RegistrationForm";
import RegistrationSuccess from "./RegistrationSuccess";

export default function ({ sectionHeader, setActivePage }) {
  const [activeSection, setActiveSection] = useState(SECTIONS.form);

  const header = () => {
    switch (activeSection) {
      case SECTIONS.app:
        return "Coming from my app?";
      case SECTIONS.form:
        return "Create an account";
      case SECTIONS.success:
        return "";
    }
  };

  const content = () => {
    switch (activeSection) {
      case SECTIONS.app:
        return <AppDisclaimer setActiveSection={setActiveSection} />;
      case SECTIONS.form:
        return (
          <RegistrationForm setActiveSection={setActiveSection} setActivePage={setActivePage} />
        );
      case SECTIONS.success:
        return <RegistrationSuccess />;
    }
  };

  return (
    <>
      {sectionHeader(header())}
      {content()}
    </>
  );
}
