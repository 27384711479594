import React, { useState, useEffect } from "react";
import { Snackbar, SnackbarContent, Portal } from "@mui/material";

export const AUTOHIDE_DURATION = 4000;

const SnackbarNotification = ({ message, severity, durationMs }) => {
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const closeButton = <i className="icon icon-cross" onClick={() => setSnackbarMessage("")} />;

  useEffect(() => {
    setSnackbarMessage(message);
  }, [message, severity]);

  return (
    <Portal>
      <Snackbar
        classes={{
          root: `snackbar`,
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarMessage.length > 0}
        onClose={() => setSnackbarMessage("")}
        autoHideDuration={durationMs || AUTOHIDE_DURATION}
      >
        <SnackbarContent
          classes={{
            root: `snackbar__content snackbar__content--${severity || "info"}`,
          }}
          message={message}
          action={closeButton}
        />
      </Snackbar>
    </Portal>
  );
};

export default SnackbarNotification;
