import React, { useEffect } from "react";
import ReactOnRails from "react-on-rails";
import { Provider, useDispatch, useSelector } from "react-redux";
import "./store/quizStore";
import {
  responseSelector,
  selectCurrentQuiz,
  selectCurrentQuizQuestions,
  selectLessonById,
  selectModuleByQuiz,
  selectMostRecentQuizAttempt,
  selectMostRecentUserAnswersForQuestion,
  selectQuestionOptionActiveByQuestionIdAndOptionId,
  selectQuizQuestionOptionsByQuestion,
  selectShowingResults,
} from "./store/quizSelectors";
import { quizResponseSlice } from "./store/quizResponseSlice";
import { submitQuiz } from "./store/quizEffects";
import { CDN_URL, DRAGONFLY_PATH, fullUrlInDragonfly } from "../components/shared/CdnUrl";
import { readStoredAnswerChoices, storeAnswerChoices } from "./util/localstorage";

const RadioIcon = ({ active }) => (
  <i className={`icon ${active ? "icon-radio_button_checked" : "icon-radio_button_unchecked"}`} />
);
const CheckboxIcon = ({ active }) => (
  <i className={`icon ${active ? "icon-checkbox-checked" : "icon-checkbox-unchecked"}`} />
);
const BackIcon = () => (
  <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7608 5.25H4.073L8.98217 1.0575L7.73511 0L0.709473 6L7.73511 12L8.97338 10.9425L4.073 6.75H14.7608V5.25Z"
      fill="white"
    />
  </svg>
);

const QuizQuestionOption = ({
  question,
  option,
  onToggle,
  isMultiSelect,
  selected,
  interactable,
}) => {
  const active = interactable
    ? useSelector(selectQuestionOptionActiveByQuestionIdAndOptionId)(question.id, option.id)
    : selected;

  const className = `quiz__question-option ${
    interactable ? "quiz__question-option--interactable" : ""
  }`;

  return (
    <div className={className} key={option.id} onClick={() => onToggle(!active)}>
      {/* <div>optionId: {option.id}</div> */}
      {isMultiSelect ? <CheckboxIcon active={active} /> : <RadioIcon active={active} />}
      <span>{option.attributes.text}</span>
      {option.attributes.imageUid != null && (
        <div className="quiz__question-option-image">
          <img src={fullUrlInDragonfly(option.attributes.imageUid)} alt="" />
        </div>
      )}
    </div>
  );
};

const QuizQuestionOptions = ({ question, interactable, userAnswers = [] }) => {
  const dispatch = useDispatch();
  const options = useSelector(selectQuizQuestionOptionsByQuestion)(question);

  const isMultiSelect =
    question.attributes.kind === "multiple_choice" &&
    question.attributes.multipleChoiceType === "multi_select";

  const toggleOption = (option, activate) => {
    if (activate) {
      dispatch(
        quizResponseSlice.actions.selectQuestionOption({
          optionId: option.id,
          questionId: question.id,
          clearOtherOptions: !isMultiSelect, //
        })
      );
    } else {
      dispatch(
        quizResponseSlice.actions.deselectQuestionOption({
          optionId: option.id,
          questionId: question.id,
        })
      );
    }
  };

  useEffect(() => {
    readStoredAnswerChoices(question).forEach((optionId) => {
      toggleOption({ id: optionId }, true);
    });
  }, []);

  const className = `quiz__question-options quiz__question-options--${
    interactable ? "interactable" : "noninteractable"
  }`;

  const userSelectedOptionIds = new Set(
    userAnswers.map((a) => Number(a.attributes.quizQuestionOptionId))
  );

  return (
    <div className={className}>
      {options.map((option, idx) => (
        <QuizQuestionOption
          question={question}
          option={option}
          idx={idx}
          onToggle={(active) => interactable && toggleOption(option, active)}
          isMultiSelect={isMultiSelect}
          interactable={interactable}
          selected={userSelectedOptionIds.has(Number(option.id))}
          key={option.id}
        />
      ))}
    </div>
  );
};

const QuizQuestion = ({ question, idx }) => {
  const showingResults = useSelector(selectShowingResults);

  const userAnswers = showingResults
    ? useSelector(selectMostRecentUserAnswersForQuestion)(question)
    : undefined;

  return (
    <div className="quiz__question">
      {/* <div>questionId: {question.id}</div> */}

      <div className="quiz__question-prompt">
        {idx + 1}. {question.attributes.prompt}
      </div>

      {question.attributes.imageUid != null && (
        <div className="quiz__question-prompt-image">
          <img src={fullUrlInDragonfly(question.attributes.imageUid)} alt="" />
        </div>
      )}

      <QuizQuestionOptions
        question={question}
        interactable={!showingResults}
        userAnswers={userAnswers == null ? undefined : userAnswers}
      />

      {showingResults && <QuestionResult question={question} userAnswers={userAnswers} />}
    </div>
  );
};

const QuestionResult = ({ userAnswers, question }) => {
  const isCorrect = userAnswers.every((a) => a.attributes.isCorrect);
  const {
    attributes: { correctAnswerFeedback, incorrectAnswerFeedback, hintLessonId },
  } = question;
  const iconClassName = `icon icon-${isCorrect ? "check" : "cross-circle-filled"}`;
  const feedbackText = isCorrect ? correctAnswerFeedback : incorrectAnswerFeedback;
  const hintLesson = useSelector(selectLessonById)(hintLessonId);

  return (
    <div
      className={`quiz__question-result quiz__question-result__${
        isCorrect ? "correct" : "incorrect"
      }`}
    >
      <div className="quiz__question-result-header">
        <i className={iconClassName} />
        <span>{isCorrect ? "Correct" : "Incorrect"}</span>
      </div>
      {feedbackText && <div className="quiz__question-result-feedback">{feedbackText}</div>}
      {!isCorrect && hintLesson != null && (
        <div className="quiz__question-result-lesson-link">
          Review:{" "}
          <a href={`/guitar-lessons/${hintLesson.attributes.slug}`}>
            <i className="icon icon-play-circle-filled" />
            {hintLesson.attributes.title}
          </a>
        </div>
      )}
    </div>
  );
};

const QuizQuestions = () => {
  const quizQuestions = useSelector(selectCurrentQuizQuestions);

  return (
    <div className="quiz__questions">
      {quizQuestions.map((question, idx) => (
        <QuizQuestion question={question} idx={idx} key={question.id} />
      ))}
    </div>
  );
};

const QuizFooter = ({ quiz }) => {
  const selectedOptions = useSelector(responseSelector);
  const dispatch = useDispatch();
  const showingResults = useSelector(selectShowingResults);

  const allQuestionsAnswered =
    Object.values(selectedOptions).filter((val) => val != null).length ===
    quiz.relationships.questions.data.length;

  const enabled = showingResults || allQuestionsAnswered;
  const text = showingResults ? "Try Again" : "Submit";

  const onClick = () => {
    // clear all stored answers on submit/retry
    quiz.relationships.questions.data.forEach((q) => storeAnswerChoices(q, null));

    window.onbeforeunload = null;

    if (showingResults) {
      window.location.href = `/quizzes/${quiz.attributes.slug}`;
    } else {
      dispatch(submitQuiz({ quiz }));
    }
  };

  return (
    <div className="quiz__footer">
      <button className="button button--primary" disabled={!enabled} onClick={onClick}>
        {text}
      </button>
    </div>
  );
};

const ResultsSummary = ({ quiz }) => {
  const showingResults = useSelector(selectShowingResults);
  const attempt = useSelector(selectMostRecentQuizAttempt);
  const module = useSelector(selectModuleByQuiz)(quiz);

  if (!showingResults) {
    return null;
  }

  const { scorePercent } = attempt.attributes,
    { passPercent } = quiz.attributes,
    passed = scorePercent >= passPercent,
    className = `quiz__results-summary quiz__results-summary--${passed ? "passed" : "failed"}`,
    iconClassName = `icon-${passed ? "check" : "cross-circle-filled"}`;

  return (
    <div className={className}>
      <div className="icon-container">
        <i className={`icon ${iconClassName}`}></i>
      </div>
      <div className="header-container">
        <h3 className="header3">
          {passed ? "Congratulations! You passed!" : "You haven’t passed yet!"}
        </h3>
        <div className="subheader">Passing Grade: {quiz.attributes.passPercent}%</div>
      </div>
      <div className="cta-container">
        <button
          className="button button--primary"
          onClick={() => {
            window.location.href = !passed
              ? `/modules/${module.attributes.slug}`
              : module.attributes.nextGroup == null
              ? "/guitar-lessons"
              : `/modules/${module.attributes.nextGroup.slug}`;
          }}
        >
          {passed ? "Keep Learning" : "Review Module"}
        </button>
        {!passed && (
          <button
            className="button button--primary"
            onClick={() => (window.location.href = `/quizzes/${quiz.attributes.slug}`)}
          >
            Retake Quiz
          </button>
        )}
      </div>
      <div className="grade-container">
        <div className="grade-header">Grade</div>
        <div className="grade-score">
          <h4 className="header4">{attempt.attributes.scorePercent}%</h4>
        </div>
      </div>
    </div>
  );
};

const Quiz = () => {
  const quiz = useSelector(selectCurrentQuiz);
  const showingResults = useSelector(selectShowingResults);
  const module = useSelector(selectModuleByQuiz)(quiz);

  useEffect(() => {
    if (!showingResults) {
      window.onbeforeunload = function () {
        return "Changes that you made may not be saved.";
      };
    }
  }, []);

  return (
    <div className="quiz-page">
      <div className="quiz container">
        {quiz.attributes.relatedGroup && (
          <a href={`/modules/${quiz.attributes.relatedGroup.slug}`} className="back-button">
            <BackIcon /> back to lessons
          </a>
        )}
        <div className="intro-text">{quiz.attributes.description}</div>
        <div className="quiz-container">
          <h2 className="quiz__header header2">{quiz.attributes.title}</h2>
          {/* <div className="quiz__description paragraph">{quiz.attributes.description}</div> */}

          <ResultsSummary quiz={quiz} />

          <div className="quiz__body">
            <QuizQuestions />
          </div>

          <QuizFooter quiz={quiz} />
        </div>
      </div>
    </div>
  );
};

export default function () {
  const store = ReactOnRails.getStore("quizStore");

  return (
    <Provider store={store}>
      <Quiz />
    </Provider>
  );
}
