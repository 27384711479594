import React from "react";
import {
  injectStripe,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "react-stripe-elements";
import Recaptcha from "react-recaptcha";
import Loading from "../shared/Loading";
import loadScript from "../shared/loadScript";
import { Tooltip } from "@mui/material";

// this is breaking everything - return after node upgrade
// import "whatwg-fetch"; // polyfill fetch for IE<=11

const DONATION_PURCHASE_TYPE = "donation";

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentSuccess: false,
      hasErrors: false,
      pendingPayment: false,
      cardReady: false,
      cardNumberReady: false,
      cardExpiryReady: false,
      cardCvcReady: false,
      stripeError: null,
      stripeNumberError: null,
      stripeExpiryError: null,
      stripeCvcError: null,
      recaptchaToken: null,
    };
  }

  componentDidMount() {
    if (APP_ENV === "development") {
      this.setState({ recaptchaToken: "dev" });
    } else {
      loadScript("https://www.google.com/recaptcha/api.js");
    }
  }

  formSubmitting = () => {
    if (
      this.props.formSubmitting != null &&
      {}.toString.call(this.props.formSubmitting) === "[object Function]"
    ) {
      this.props.formSubmitting();
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.submitDisabled()) {
      return;
    }

    this.formSubmitting();
    this.setState({ pendingPayment: true });

    const stripeResult = await this.props.stripe.createPaymentMethod("card", CardElement);

    if (stripeResult.error != null) {
      this.setState({
        pendingPayment: false,
        hasErrors: stripeResult.error.message,
      });
    } else {
      const { token } = await this.props.stripe.createToken();
      await this.postPayment(stripeResult.paymentMethod.id, token);
    }
  };

  postPaymentUrl() {
    let { purchaseType } = this.props;
    if (purchaseType === DONATION_PURCHASE_TYPE) {
      return "/payments/donations/donations";
    } else {
      return "/purchases";
    }
  }

  postPayment = (paymentMethodId, cardToken) => {
    const url = this.postPaymentUrl();

    const body = {
      purchase_type: this.props.purchaseType,
      payment_method_id: paymentMethodId,
      card_token: cardToken,
      recaptcha_token: this.state.recaptchaToken,
    };

    this.makeFetchPostRequest(url, body);
  };

  handleServerResponse = async (response) => {
    if (response.error) {
      this.handleError(response.error.message);
    } else if (
      response.status &&
      response.status.toLowerCase &&
      response.status.toLowerCase() === "error"
    ) {
      this.handleError(response.data);
    } else if (response.data.requires_action) {
      await this.handleActionRequired(response);
    } else {
      let isTrial = response.data && response.data.isTrial;
      this.handleSuccess(isTrial, response.data);
    }
  };

  handleActionRequired = async (response) => {
    const handleResponseFn =
      response.data.confirmation_method === "automatic"
        ? this.props.stripe.handleCardPayment
        : response.data.object === "setup_intent"
        ? this.props.stripe.confirmCardSetup
        : this.props.stripe.handleCardAction;

    const result = await handleResponseFn(response.data.payment_intent_client_secret);

    if (result.error) {
      // no need to wait for this response
      await this.failedSetupIntent(response.data.subscription_id);
      this.handleError(result.error.message);
    } else if (result.paymentIntent) {
      // The card action has been handled
      // The PaymentIntent can be confirmed again on the server
      await this.confirmPayment(result.paymentIntent.id, result.customer_id);
    } else if (result.setupIntent && result.setupIntent.status === "succeeded") {
      await this.confirmSetupIntent(result);
    }
  };

  confirmSetupIntent = (intentResult) => {
    const body = {
      setup_intent_id: intentResult.setupIntent.id,
      purchase_reference: this.props.reference,
      customer_id: intentResult.customer_id,
      purchase_type: this.props.purchaseType,
    };

    this.makeFetchPostRequest("/confirm_setup_intent", body);
  };

  failedSetupIntent = (subscription_id) => {
    const body = {
      subscription_id: subscription_id,
      purchase_type: this.props.purchaseType,
    };

    this.makeFetchPostRequest("/failed_setup_intent", body);
  };

  handleSuccess = (isTrial = false, data) => {
    if (this.props.purchaseType === "donation") {
      this.props.donationSuccess(data);
    } else {
      this.setState({
        pendingPayment: false,
        paymentSuccess: true,
        hasErrors: null,
        isTrial: isTrial,
      });
      if (this.props.successCallback) {
        this.props.successCallback();
      } else {
        setTimeout(() => window.location.reload(true), 500);
      }
    }
  };

  makeFetchPostRequest = async (url, body) => {
    const response = await fetch(url, {
      method: "POST",
      credentials: "same-origin",
      headers: this.headers(),
      body: JSON.stringify({
        ...this.body(),
        ...body,
      }),
    }).then((res) => res.json());

    this.handleServerResponse(response);
  };

  headers = () => ({
    "Content-Type": "application/json",
    "X-CSRF-Token": ReactOnRails.authenticityToken(),
  });

  body = () => ({
    amount: this.props.price,
    currency: this.props.currency,
    stripe_customer_id: this.props.stripeCustomerId,
    user_name: this.props.userName,
    user_email: this.props.userEmail,
    is_subscription: this.props.subscription,
    purchase_reference: this.props.reference,
    authenticity_token: ReactOnRails.authenticityToken(),
  });

  confirmPaymentUrl() {
    let { purchaseType } = this.props;

    if (purchaseType === DONATION_PURCHASE_TYPE) {
      return "/payments/donations/payment_intent_confirmations";
    } else {
      return "/confirm_purchase";
    }
  }

  confirmPayment = (paymentIntentId, customerId) => {
    const url = this.confirmPaymentUrl();

    const body = {
      payment_intent_id: paymentIntentId,
      purchase_reference: this.props.reference,
      customer_id: customerId,
      purchase_type: this.props.purchaseType,
    };

    this.makeFetchPostRequest(url, body);
  };

  cardReady = () => {
    return (
      this.state.cardReady ||
      (this.state.cardNumberReady && this.state.cardExpiryReady && this.state.cardCvcReady)
    );
  };

  handleChange = (e) => {
    if (e.error) {
      this.setState({ stripeError: e.error.message });
    } else {
      this.setState({ stripeError: null });
    }

    if (e.complete) {
      this.setState({ cardReady: true });
    }

    if (this.state.hasErrors !== false) {
      this.setState({ hasErrors: false });
    }
  };

  handleNumberChange = (e) => {
    if (e.error) {
      this.setState({ stripeNumberError: e.error.message });
    } else {
      this.setState({ stripeNumberError: null });
    }

    if (e.complete) {
      this.setState({ cardNumberReady: true });
    }

    if (this.state.hasErrors !== false) {
      this.setState({ hasErrors: false });
    }
  };

  handleExpiryChange = (e) => {
    if (e.error) {
      this.setState({ stripeExpiryError: e.error.message });
    } else {
      this.setState({ stripeExpiryError: null });
    }

    if (e.complete) {
      this.setState({ cardExpiryReady: true });
    }

    if (this.state.hasErrors !== false) {
      this.setState({ hasErrors: false });
    }
  };

  handleCvcChange = (e) => {
    if (e.error) {
      this.setState({ stripeCvcError: e.error.message });
    } else {
      this.setState({ stripeCvcError: null });
    }

    if (e.complete) {
      this.setState({ cardCvcReady: true });
    }

    if (this.state.hasErrors !== false) {
      this.setState({ hasErrors: false });
    }
  };

  handleError = (errorMessage) => {
    APP_ENV !== "development" && this.resetRecaptcha();

    this.setState({
      pendingPayment: false,
      hasErrors: errorMessage,
    });

    this.formSubmitting();
  };

  showVerifiedRecaptcha = () => {
    return (
      <div
        style={{
          width: "300px",
          height: "70px",
          background: "#f9f9f9",
          border: "1px solid #d3d3d3",
          color: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Dev Recaptcha Verified
      </div>
    );
  };

  recaptcha = () => {
    return APP_ENV !== "development" ? (
      <Recaptcha
        render="explicit"
        sitekey={this.props.recaptchaKey}
        onloadCallback={() => {
          /*
                      no-op callback. why? due to a silly limitation of the react-recaptcha library,
                      this cb must be definted for verifyCallback to work...
                      https://github.com/appleboy/react-recaptcha/issues/242#issuecomment-540733254
                    */
        }}
        verifyCallback={this.recaptchaVerified}
        ref={(rec) => {
          this.recaptchaInstance = rec;
        }}
      />
    ) : null;
  };

  recaptchaVerified = (recaptchaToken) => {
    this.setState({ recaptchaToken });
  };

  submitDisabled = () => {
    const { price } = this.props;

    if (isNaN(parseFloat(price)) || parseFloat(price) < 1) {
      return true;
    }

    const { recaptchaToken, pendingPayment } = this.state;

    return !(this.cardReady() && recaptchaToken && !pendingPayment);
  };

  buttonText = () => {
    let text = this.props.buttonText
      ? this.props.buttonText
      : this.props.purchaseType === "donation"
      ? `make ${this.props.currency === "USD" ? "USD " : this.props.currency}${this.props.price} ${
          this.props.subscription ? "monthly" : "one-time"
        } donation now`
      : this.props.info
      ? "Agree & Pay"
      : "Pay Now";

    return (
      <span>
        {/*<img*/}
        {/*  className="credit-card-icon"*/}
        {/*  src={`${CDN_URL}/redesign/2020/donate/credit-card.svg`}*/}
        {/*  alt="credit card icon"*/}
        {/*/>*/}
        {text}
      </span>
    );
  };

  resetRecaptcha = () => {
    this.setState({ recaptchaToken: null }, () => {
      this.recaptchaInstance.reset();
    });
  };

  render() {
    let { paymentSuccess, isTrial } = this.state;
    if (this.state.donationSuccess) {
      return <div className="styleized">{this.donationThanks()}</div>;
    } else {
      return (
        <div className="paywall-form__container">
          {isTrial && <h5 className="success-response">Your trial has now started!</h5>}
          {paymentSuccess && !isTrial && (
            <h5 className="success-response">Payment successfully submitted!</h5>
          )}
          {this.state.pendingPayment && <Loading />}
          <form onSubmit={this.handleSubmit}>
            <div className="paywall-form__elements split-inputs">
              <label>
                <CardNumberElement
                  onChange={this.handleNumberChange}
                  style={{
                    base: {
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                    },
                  }}
                />
                {this.state.stripeNumberError && (
                  <h5 className="error-response">{this.state.stripeNumberError}</h5>
                )}
              </label>
              <div className="inline-details" style={{ marginTop: "-15px", marginBottom: "1px" }}>
                <label>
                  <CardExpiryElement
                    onChange={this.handleExpiryChange}
                    style={{
                      base: {
                        fontSize: "16px",
                        fontFamily: "Montserrat",
                      },
                    }}
                  />
                  {this.state.stripeExpiryError && (
                    <h5 className="error-response">{this.state.stripeExpiryError}</h5>
                  )}
                </label>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <CardCvcElement
                    onChange={this.handleCvcChange}
                    style={{
                      base: {
                        fontSize: "16px",
                        fontFamily: "Montserrat",
                      },
                    }}
                  />
                  {this.state.stripeCvcError && (
                    <h5 className="error-response">{this.state.stripeCvcError}</h5>
                  )}
                  <Tooltip
                    arrow
                    enterTouchDelay={0}
                    title="The CVC Number (“Card Validation Code”) on your credit card or debit card is a 3 digit number on VISA®, MasterCard® and Discover® branded credit and debit cards. On your American Express® branded credit or debit card it is a 4 digit numeric code."
                  >
                    <button type="button" className="info" style={{ margin: 0, top: "14px" }}>
                      <i className="icon icon-info"></i>
                    </button>
                  </Tooltip>
                </label>
              </div>
            </div>
            {APP_ENV === "development" ? this.showVerifiedRecaptcha() : this.recaptcha()}
            <div className="payment__button">
              {this.props.info ? <p className="info">{this.props.info}</p> : null}
              <button className="button button--primary" disabled={this.submitDisabled()}>
                {this.buttonText()}
              </button>
            </div>
          </form>
          {this.state.hasErrors && (
            <h5 className="error-response" style={{ textAlign: "center" }}>
              {this.state.hasErrors}
            </h5>
          )}
        </div>
      );
    }
  }
}

export default injectStripe(CheckoutForm);
