import React from "react";
import { compose } from "redux";
import {
  donationTypes,
  donationTabs,
  donationTabsForDashboard,
  currencies,
  currencyTypes,
  RECAPTCHA_KEY,
  STRIPE_DONATION_PUBLISHABLE_KEY,
} from "./consts";
import { withInnerWidth } from "../shared";
import { CDN_URL } from "../shared/CdnUrl";
import CheckoutForm from "./CheckoutForm";
import { Elements, StripeProvider } from "react-stripe-elements";
import {
  currencyDisplay,
  getProducts,
  getPurchases,
  getDonations,
  getStripeUserData,
  tabOptionsFiltered,
} from "./utils";
import { CloseIcon } from "../../pages/Chords/Icons";
import { CaretDownIcon, CaretLeftIcon, CaretRightIcon, CaretUpIcon } from "../shared/Icons";
import ConditionalWrapper from "../ConditionalWrapper";
import Dialog from "../shared/ui/Dialog";
import axiosWithCSRF from "../shared/axiosWithCSRF";
import Loading from "../shared/Loading";
import UpdateCard from "../../subscriptions/UpdateCard";
import { SUBSCRIPTION_TYPES } from "../../subscriptions/consts";

const defaultDonationContextState = {
  currency: currencyTypes.USD,
  currentTab: donationTypes.MONTHLY,
  donationSuccess: false,
};

const DonationContext = React.createContext({
  ...defaultDonationContextState,
});

const withContext = (Component) => (props) =>
  (
    <DonationContext.Consumer>
      {(context) => <Component {...props} {...context} />}
    </DonationContext.Consumer>
  );

const Ziggy = () => (
  <div className="ziggy">
    <img src={`${CDN_URL}/redesign/2020/donate/ziggy-circle.png`} alt="" />
    <p>Ziggy, JustinGuitar’s Chief Happiness Officer, welcomes every donation!</p>
  </div>
);

const DONATION_PATH = "/donate";

const OtherDonation = () => (
  <div>
    <div className="donation__other">
      <h4>PayPal</h4>
      <p>
        If you prefer to donate with PayPal please use this link:&nbsp;
        <a href="https://www.paypal.me/JustinGuitarDonation" target="_blank">
          PayPal.me JustinGuitar Donation.
        </a>
      </p>
    </div>
    <div className="donation__other">
      <h4>Crypto</h4>
      <p>
        Want to donate with cryptocurrency? You can use this link to my{" "}
        <a
          target="_blank"
          href="https://commerce.coinbase.com/checkout/d4adc341-10c9-4978-b5e2-a89b343e96ea"
        >
          Coinbase account.
        </a>
      </p>
    </div>
    <h4>Amazon Associates</h4>
    <div className="amazon-associates donation__other">
      <p>
        You can make regular donations without even noticing by using my Amazon Associates links
        below when you shop!
      </p>
      <div className="amazon-associates--links">
        {/* TODO: get actual links, these are to the wishlist */}
        <div>
          <a href="https://geni.us/jgtr-amazon" target="_blank">
            <img src={`${CDN_URL}/redesign/2020/donate/amazon-us.svg`} alt="" />
          </a>
          <span>(US)</span>
        </div>
        <div>
          <a href="https://geni.us/jgtr-amazon" target="_blank">
            <img src={`${CDN_URL}/redesign/2020/donate/amazon-uk.svg`} alt="" />
          </a>
          <span>(UK)</span>
        </div>
      </div>
    </div>
  </div>
);

const DonationNavigationDropdown = ({
  currentTab,
  onChange,
  embeddedInDashboard,
  availableDonationTypes = [],
  isMonthly = false,
}) => {
  // const tabOptions = tabOptionsFiltered(
  //   embeddedInDashboard ? donationTabsForDashboard : donationTabs,
  //   isMonthly
  // );
  const tabOptions = embeddedInDashboard ? donationTabsForDashboard : donationTabs;

  return (
    <div className="donation__navigation-dropdown">
      <span>How do you want to help?</span>
      <select
        className="form-control form-control--auto-width"
        value={currentTab}
        onChange={(event) => onChange(event.target.value)}
      >
        {Object.entries(tabOptions).map(([value, label]) =>
          availableDonationTypes.length == 0 || availableDonationTypes.includes(value) ? (
            <option key={value} value={value}>
              {label}
              {isMonthly && value === "monthly" ? " (Active)" : ""}
            </option>
          ) : null
        )}
      </select>
    </div>
  );
};

const DonationNavigationTabs = ({
  currentTab,
  onChange,
  embeddedInDashboard,
  availableDonationTypes = [],
  isMonthly = false,
}) => {
  // const tabOptions = tabOptionsFiltered(
  //   embeddedInDashboard ? donationTabsForDashboard : donationTabs,
  //   isMonthly
  // );
  const tabOptions = embeddedInDashboard ? donationTabsForDashboard : donationTabs;

  return (
    <ul
      className="donation__navigation-tabs"
      onClick={({ target }) => onChange(target.dataset.value)}
    >
      {Object.entries(tabOptions).map(([value, label]) =>
        availableDonationTypes.length == 0 || availableDonationTypes.includes(value) ? (
          <li key={value} data-value={value} className={currentTab === value ? "active" : ""}>
            {label}
            {isMonthly && value === "monthly" ? <span className={"badge"}>Active</span> : ""}
          </li>
        ) : null
      )}
    </ul>
  );
};

const DonationNavigation = ({ width, isMonthly, ...props }) => {
  return (
    <DonationContext.Consumer>
      {({ currentTab, updateCurrentTab }) => (
        <div className="donation__navigation">
          {width > 768 ? (
            <DonationNavigationTabs
              {...props}
              currentTab={currentTab}
              onChange={updateCurrentTab}
              isMonthly={isMonthly}
            />
          ) : (
            <DonationNavigationDropdown
              {...props}
              currentTab={currentTab}
              onChange={updateCurrentTab}
              isMonthly={isMonthly}
            />
          )}
        </div>
      )}
    </DonationContext.Consumer>
  );
};

const DonationForm = withContext(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: this.props.name || "",
        email: this.props.email || "",
      };
    }

    handleChange = (event) => {
      this.setState({ [event.target.name]: event.target.value });
    };

    handleFormSubmitting = () => {
      this.setState({ formSubmitting: !this.state.formSubmitting });
    };

    render() {
      const fontArray = [{ cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:400" }];

      return (
        <div>
          <DonationAmountChooser
            amounts={this.props.amounts}
            width={this.props.width}
            currentUser={this.props.currentUser}
          />

          <StripeProvider apiKey={this.props.stripeKey}>
            <div className="donation-form">
              <div className="donate__stripe donation-form-row">
                <div className="donation-details-container">
                  <div id="donation-name">
                    <input
                      onChange={this.handleChange}
                      value={this.state.name}
                      name="name"
                      placeholder="Name"
                    />
                  </div>
                  <div id="donation-email">
                    <input
                      onChange={this.handleChange}
                      value={this.state.email}
                      name="email"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="stripe__element__container">
                  <Elements fonts={fontArray}>
                    <CheckoutForm
                      price={this.props.amount}
                      formSubmitting={this.handleFormSubmitting}
                      donationSuccess={this.props.donationSuccess}
                      subscription={this.props.isSubscription}
                      recaptchaKey={this.props.recaptchaKey}
                      purchaseType="donation"
                      userName={this.state.name}
                      userEmail={this.state.email}
                      currency={this.props.currency}
                    />
                  </Elements>
                </div>
              </div>
              <div className="stripe-secure">
                <i className="icon icon-lock" />
                <span>Your payment is protected</span>
                {/*<span>*/}
                {/*  <img src={`${CDN_URL}/redesign/2020/donate/stripe.svg`} alt="stripe logo" />*/}
                {/*</span>*/}
              </div>
            </div>
          </StripeProvider>
        </div>
      );
    }
  }
);

const DonationCurrencySelector = withContext(({ selectedCurrency, updateCurrency }) => (
  <div className="donation__intro">
    <span>Currency</span>
    <select
      id="currency-select"
      className="form-control form-control--auto-width"
      value={selectedCurrency}
      onChange={(event) => updateCurrency(event.target.value)}
    >
      {Object.entries(currencies).map(([value, label]) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  </div>
));

const MOST_POPULAR_AMOUNT = "25";

const DonationAmountChooser = withContext(
  class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        selectedAmount: props.currentAmount,
      };
    }

    componentDidMount() {
      if (!this.props.currentAmount) {
        this.handleSelection("25");
      }
    }

    handleSelection(selectedAmount) {
      if (selectedAmount != "Other") {
        this.props.updateAmount(selectedAmount);
      }

      this.setState({ selectedAmount });
    }

    render() {
      const { amounts, currency, updateAmount, currentUser } = this.props;
      const { selectedAmount } = this.state;

      return (
        <div className="donation__options">
          {amounts.map((amountOption) => (
            <div
              key={amountOption}
              onClick={() =>
                // currentUser.data.attributes.donationSubscriptions.length === 0 &&
                this.handleSelection(amountOption)
              }
              className={`donation-box ${amountOption == selectedAmount ? "selected-box" : ""} ${
                amountOption == "Other" ? "other-amount" : ""
              }`}
              // ${currentUser.data.attributes.donationSubscriptions.length > 0 ? "disabled" : ""}
            >
              {amountOption === "Other" ? (
                <OtherAmountInput
                  onChange={updateAmount}
                  currency={currency}
                  active={selectedAmount == "Other"}
                />
              ) : (
                <>
                  {currencyDisplay(currency)} {amountOption}
                  {/*{amountOption.includes(currentUser.data.attributes.donationSubscriptions[0].price)*/}
                  {/*  ? " (active)"*/}
                  {/*  : ""}*/}
                </>
              )}
              <div>
                {amountOption === MOST_POPULAR_AMOUNT && this.props.width >= 380 && (
                  <span className="most-popular">Most Popular</span>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    }
  }
);

class OtherAmountInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = { focused: true };

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    // auto-focus if newly active
    if (this.props.active && !prevProps.active) {
      this.setState({ focused: true });
    }

    // if should focus and not already focused
    if (this.props.active && this.state.focused && !prevState.focused) {
      this.inputRef.current.focus();
    }
  }

  handleClick = () => {
    if (!this.state.focused) {
      this.setState({ focused: true });
    }
  };

  handleClickOutside = () => {
    this.setState({ focused: false });
  };

  handleFocus = () => {
    this.props.onChange(this.inputRef.current.value);
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  render() {
    const { currency, onChange } = this.props;
    return (
      <>
        <span>{currencyDisplay(currency)} </span>
        <input
          className={`custom-amount`}
          type="number"
          placeholder="Other amount..."
          ref={this.inputRef}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={({ target: { value } }) => onChange(value)}
          onClick={this.handleClick}
        />
      </>
    );
  }
}

const CashOrCheckDonation = () => (
  <div>
    <h4>Cash or Check</h4>

    <p>
      Cash is super easy, just wrap it in a bit of paper inside the envelope for security. My
      postbox is secure so for small donations it works.
    </p>
    <p>
      Please be aware that the bank fees for me to pay in cheques from anything but GBP are crazy -
      between £12 and £20 per cheque, so if you send in $25 USD by cheque it might only come in as a
      couple of dollars by the time it's in my bank! So if you're sending in less than $100US,
      please just post cash, or use bank transfer.
    </p>
    <p>
      I had a couple of cheques bounce recently, which is embarrassing all round... I'm not going to
      get cross about it of course, but I have to pay all the bank charges, so if you do send a
      cheque, please make sure you have sufficient money in your cheque account! Note that it
      sometimes takes me a month or two to make it to the bank with cheques.
    </p>
    <p>Please make cheque's payable to Justin Guitar Ltd (not to me personally).</p>

    <div>
      JustinGuitar
      <br />
      PO Box 695
      <br />
      FARNHAM GU9 1NB
      <br />
      UK
    </div>
  </div>
);

class BankTransferDonation extends React.Component {
  render() {
    return (
      <div>
        <h4>Direct Bank Transfer</h4>

        <p>
          Direct bank transfer is popular these days. It saves trees, money, and time. You can
          usually send a message in the notes, but please also email me and let me know (subject:
          DONATION.) Bank paperwork is something I generally try to avoid. ;)
        </p>
        <div>
          Account Name: Justin Guitar Ltd <br />
          BIC: HBUKGB4B <br />
          IBAN: GB08HBUK40021331758225
        </div>
        <p>For those in the UK: Sort code 400213 account 31758225</p>
      </div>
    );
  }
}

const DonationDescription = ({ showDonationForm, toggleDonationDescription }) => (
  <ConditionalWrapper
    condition={showDonationForm}
    wrapper={(children) => (
      <div className="row" style={{ display: "flex", justifyContent: "center" }}>
        {children}
      </div>
    )}
  >
    <div
      className="donation-description"
      style={{
        marginTop: showDonationForm ? "0" : "32.84px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <button onClick={() => toggleDonationDescription()} className="close-btn">
        <CloseIcon color={"#1D3446"} />
      </button>
      <p>
        Your donation means I can keep most of JustinGuitar.com free to everyone and afford an
        awesome team to help me with the site, which gives me more time to create content. Your
        donation help in various ways, like creating new lessons and song lessons and building and
        improving site features!
      </p>
      <img src={`${CDN_URL}/images/dashboard/donations-heart.png`} alt="Donations Heart" />
      <p>
        We have around 750,000 registered students, many of whom can't afford paid lessons. Your
        donation makes their learning possible. Remember - dreams are universal, but opportunity is
        not. So I appreciate you sharing your resources to help me teach the world to play!
      </p>
    </div>
  </ConditionalWrapper>
);

const DonationMain = compose(withInnerWidth)(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        ...defaultDonationContextState,
        amount: null,
        stripeKey: this.props.stripeKey,
        recaptchaKey: this.props.recaptchaKey,
        updateCurrentTab: (tab) => {
          this.setState({ currentTab: tab });
          if (
            tab === "monthly" &&
            this.props.currentUser &&
            this.props.currentUser.data.attributes.activeDonationSubscription
          ) {
            this.setState({ showDonationForm: false });
          }
        },
        updateCurrency: (currency) => this.setState({ currency }),
        updateAmount: (amount) => this.setState({ amount }),
        donationSuccess: () => this.setState({ _donationSuccess: true }),
        _donationSuccess: null,
        showPaymentDetails: true,
        showPaymentDetailsUpdate: false,
        showChangeDonationPlan: false,
        showDonationHistory: false,
        showDonationDescription: true,
        showDonationForm: window.location.pathname === DONATION_PATH,
        dialogAction: null,
        lastCardDetails: null,
        isMonthly: false,
        wasMonthly: false,
      };
    }

    componentDidMount() {
      if (this.props.currentUser) {
        const { activeDonationSubscription, hadActiveDonationSubscription, stripeDonationId } =
          this.props.currentUser.data.attributes;
        this.setState(
          {
            isMonthly: activeDonationSubscription, // donations.some((donation) => donation.data.attributes.isSubscription);
            wasMonthly: !activeDonationSubscription && hadActiveDonationSubscription,
          },
          () => {
            const { isMonthly, wasMonthly } = this.state;
            if (activeDonationSubscription && stripeDonationId) {
              this.setCardDetails();
            }
          }
        );
      }

      this.setState({
        currentTab: !this.props.currentUser
          ? donationTypes.MONTHLY
          : this.props.currentUser.data.attributes.activeDonationSubscription &&
            window.location.pathname !== DONATION_PATH
          ? donationTypes.MONTHLY
          : donationTypes.SINGLE,
      });
    }

    setCardDetails = () => {
      const { activeDonationSubscription } = this.props.currentUser.data.attributes;

      this.setState({ cardDataLoading: true, lastCardDetails: null });
      axiosWithCSRF()
        .get("/payments/card", {
          params: { subscription_id: activeDonationSubscription.data.id },
        })
        .then((response) => {
          if (response.data?.card?.data) {
            const { brand, last4, expMonth, expYear } =
              response.data.card.data.attributes.cardDetails;
            this.setState({
              lastCardDetails: {
                brand: brand,
                last4: last4,
                expMonth: expMonth,
                expYear: expYear,
              },
            });
          }
        })
        .finally(() => {
          this.setState({ cardDataLoading: false });
        });
    };

    donationSuccess = () => {
      this.setState({ donationSuccess: true });
    };

    currentTabContent() {
      switch (this.state.currentTab) {
        case donationTypes.BANK_TRANSFER:
          return <BankTransferDonation />;
        case donationTypes.CASH_OR_CHECK:
          return <CashOrCheckDonation />;
        case donationTypes.OTHER:
          return <OtherDonation />;
        case donationTypes.SINGLE:
          return (
            <DonationForm
              isSubscription={false}
              amounts={this.props.singleDonationAmounts || ["25", "50", "100", "Other"]}
              name={this.props.name}
              email={this.props.email}
              currentUser={this.props.currentUser}
              width={this.props.width}
            />
          );
        case donationTypes.MONTHLY:
        default:
          return (
            <DonationForm
              isSubscription={true}
              amounts={this.props.monthlyDonationAmounts || ["10", "25", "50", "100"]}
              name={this.props.name}
              email={this.props.email}
              currentUser={this.props.currentUser}
              width={this.props.width}
            />
          );
      }
    }

    donationThanks() {
      const __html =
        this.state.currentTab === donationTypes.MONTHLY
          ? this.props.donationSubscriptionSuccess
          : this.props.donationSingleSuccess;

      return <div dangerouslySetInnerHTML={{ __html }} />;
    }

    setActiveSection = (activeVariableName) => {
      this.setState({
        showPaymentDetails: activeVariableName === "showPaymentDetails",
        showPaymentDetailsUpdate: activeVariableName === "showPaymentDetailsUpdate",
        showChangeDonationPlan: activeVariableName === "showChangeDonationPlan",
        showDonationHistory: activeVariableName === "showDonationHistory",
      });
    };

    toggleDonationDescription = () => {
      this.setState({ showDonationDescription: !this.state.showDonationDescription });
    };

    changeDonationAmount = () => {
      this.props.openDialog({
        message: `Please confirm that you want to change your donation amount to ${this.state.currency} ${this.state.amount}.`,
        confirmButtonText: "Confirm",
        data: { amount: this.state.amount, currency: this.state.currency },
      });
    };

    confirmDonationAmountChange = (data) => {
      axiosWithCSRF()
        .post("/payments/change_donation_plan", {
          amount: data.amount,
          currency: data.currency,
        })
        .then((response) => {
          this.props.openAlert({
            message: response.data.message,
            severity: response.data.severity,
          });
        });
    };

    cancelDonationSubscription = () => {
      this.props.openDialog({
        message: `Please confirm that you want to cancel your donation subscription.`,
        confirmButtonText: "Confirm",
        data: null,
      });
    };

    confirmDonationSubscriptionCancel = (data) => {
      axiosWithCSRF()
        .post("/payments/cancel_donation_plan", {})
        .then((response) => {
          this.props.openAlert({
            message: response.data.message,
            severity: response.data.severity,
          });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    };

    render() {
      const { _donationSuccess } = this.state;
      const donations =
        this.props.currentUser && this.props.currentUser.data.attributes.userDonations;

      const nonRefundedDonations = donations
        ? donations.filter((donation) => donation.data.attributes.refundReason === null)
        : [];
      const donationsCount = nonRefundedDonations.length;
      const hasDonated = donations && donations.length > 0;
      const donationsByCurrency = donations
        ? donations.reduce((acc, donation) => {
            const { currency } = donation.data.attributes;
            if (!acc[currency]) {
              acc[currency] = [];
            }
            acc[currency].push(donation.data.attributes);
            return acc;
          }, {})
        : {};
      const donationsOutput = [];
      Object.entries(donationsByCurrency).forEach(([currency, donations]) => {
        const count = donations.filter((donation) => donation.refundReason === null).length;
        const sum = donations.reduce(
          (sum, donation) => sum + (donation.refundReason === null ? donation.amount : 0),
          0
        );

        if (count > 0 && sum > 0) {
          donationsOutput.push(
            `You have made <strong>${count} donation(s)</strong> for a total of <strong>${currency} ${sum}</strong>`
          );
        }
      });

      // const donationTotalAmount = donations.reduce(
      //   (acc, donation) => acc + donation.data.attributes.amount,
      //   0
      // );
      // const currency = donations[0] && donations[0].data.attributes.currency;
      // // const lastDonation = {
      // //   price: "£25",
      // //   date: "10/05/2023",
      // // };
      const sortedDonations = donations
        ? [...donations].sort((a, b) => {
            return new Date(b.data.attributes.createdAt) - new Date(a.data.attributes.createdAt);
          })
        : [];
      const lastDonation =
        sortedDonations.length > 0
          ? {
              amount: `${sortedDonations[0].data.attributes.currency} ${sortedDonations[0].data.attributes.amount}`,
              date: new Date(sortedDonations[0].data.attributes.createdAt).toLocaleDateString(
                "en-GB",
                {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                }
              ),
            }
          : null;

      const nextDonation =
        this.props.currentUser && this.props.currentUser.data.attributes.activeDonationSubscription
          ? {
              amount:
                this.props.currentUser.data.attributes.activeDonationSubscription.data.attributes
                  .nextPayment.price,
              currency:
                this.props.currentUser.data.attributes.activeDonationSubscription.data.attributes
                  .nextPayment.currency,
              date: new Date(
                this.props.currentUser.data.attributes.activeDonationSubscription.data.attributes.nextPayment.date
              ).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }),
            }
          : null;

      const lastDonationCancelDate =
        this.props.currentUser &&
        this.props.currentUser.data.attributes.donationSubscriptions.length > 0
          ? new Date(
              this.props.currentUser.data.attributes.donationSubscriptions[0].cancelled_at
            ).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          : null;

      const onUpdateCardDetails = () => {
        this.setActiveSection("showPaymentDetails");
        this.setCardDetails();
      };

      if (this.props.donationsDisabled) {
        return (
          <div className="donate-tabs">
            <p className="disabled-donations-notice">
              We’re currently working to improve your payment experience. We cannot take any
              donations right now, but please check this page again in a couple of hours, and you’ll
              be good to go! :) You can <a href="mailto:laryne@justinguitar.com">contact us</a> if
              you need further assistance.
            </p>
          </div>
        );
      }

      return (
        <div className="donate-tabs">
          <DonationContext.Provider value={this.state}>
            {/*{this.props.currentTab != donationTypes.MONTHLY ||*/}
            {/*this.props.currentUser.data.attributes.donationSubscriptions.length === 0 ? (*/}
            {!this.state.showDonationForm && hasDonated ? (
              <div className="donation-panels">
                <div className="donation-info">
                  <div
                    className={`title${
                      this.state.isMonthly || this.state.wasMonthly ? " monthly" : ""
                    }`}
                  >
                    <p>You {this.state.wasMonthly ? "were" : "are"} a JustinGuitar</p>
                    <div>{(this.state.isMonthly || this.state.wasMonthly) && "MONTHLY "}DONOR</div>
                  </div>
                  {this.state.showPaymentDetails ? (
                    <>
                      <div className="payment-details">
                        <p>{this.state.isMonthly ? "Payment" : "Last Donation"}</p>
                        <p>
                          {this.state.isMonthly
                            ? nextDonation && (
                                <>
                                  Your next donation is for{" "}
                                  <strong>
                                    {nextDonation.currency} {nextDonation.amount}
                                  </strong>{" "}
                                  on <strong>{nextDonation.date}</strong>
                                </>
                              )
                            : lastDonation && (
                                <>
                                  Your last donation was for <strong>{lastDonation.amount}</strong>{" "}
                                  on <strong>{lastDonation.date}</strong>
                                  <br />
                                  {lastDonationCancelDate && (
                                    <span>
                                      You canceled your subscription on{" "}
                                      <strong>{lastDonationCancelDate}</strong>.
                                    </span>
                                  )}
                                </>
                              )}
                        </p>
                        <div className="card-data">
                          {this.state.cardDataLoading && <Loading isContent={true} />}
                          {(this.state.isMonthly || this.state.wasMonthly) &&
                            this.state.lastCardDetails && (
                              <div>
                                {this.state.lastCardDetails.brand} ending in{" "}
                                {this.state.lastCardDetails.last4}
                                <br />
                                <span>
                                  Expires {this.state.lastCardDetails.expMonth}/
                                  {this.state.lastCardDetails.expYear}
                                </span>
                              </div>
                            )}
                          <div>
                            {this.state.isMonthly ? (
                              <button
                                className="donate-tabs-btn"
                                onClick={() => this.setActiveSection("showPaymentDetailsUpdate")}
                              >
                                Update payment details
                              </button>
                            ) : (
                              <button
                                className="donate-tabs-btn orange"
                                onClick={() => {
                                  if (window.location.pathname != DONATION_PATH) {
                                    window.location.href = DONATION_PATH;
                                  } else {
                                    this.setState({ showDonationForm: true });
                                    if (!this.state.showDonationDescription) {
                                      this.setState({ showDonationDescription: true });
                                    }
                                  }
                                }}
                              >
                                Repeat Donation
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="payment-history">
                        <p>History & Milestones 🏅</p>
                        <div>
                          {donationsOutput.map((donation, idx) => (
                            <p key={idx} dangerouslySetInnerHTML={{ __html: donation }}></p>
                          ))}
                        </div>
                        <button
                          className="donate-tabs-btn"
                          onClick={() => this.setActiveSection("showDonationHistory")}
                        >
                          View donation history
                        </button>
                        <p>Thank you so much for contributing to JustinGuitar.</p>
                        <button
                          className="donate-tabs-btn"
                          onClick={() => this.toggleDonationDescription()}
                        >
                          See how your donations help{" "}
                          {this.state.showDonationDescription ? (
                            <CaretLeftIcon />
                          ) : (
                            <CaretRightIcon />
                          )}
                        </button>
                      </div>
                      <div className="options">
                        {this.props.currentUser &&
                          this.props.currentUser.data.attributes.activeDonationSubscription && (
                            <button
                              className="button button--teal"
                              onClick={() => this.setActiveSection("showChangeDonationPlan")}
                            >
                              change plan or cancel
                            </button>
                          )}
                        <button
                          className="button button--primary"
                          onClick={() => {
                            if (window.location.pathname !== DONATION_PATH) {
                              window.location.href = DONATION_PATH;
                            } else {
                              this.setState({ showDonationForm: true });
                              if (!this.state.showDonationDescription) {
                                this.setState({ showDonationDescription: true });
                              }
                              if (
                                this.props.currentUser &&
                                this.props.currentUser.data.attributes.activeDonationSubscription
                              ) {
                                this.setState({ currentTab: donationTypes.SINGLE });
                              }
                            }
                          }}
                        >
                          {this.props.width > 768 ? "make a donation" : "donate"}
                        </button>
                      </div>
                    </>
                  ) : this.state.showPaymentDetailsUpdate ? (
                    <div className="payment-details-update">
                      <button
                        onClick={() => this.setActiveSection("showPaymentDetails")}
                        className="close-btn"
                      >
                        <CloseIcon color={"#1D3446"} />
                      </button>
                      <p>Update Payment Details</p>
                      <UpdateCard
                        subscriptionId={Number(
                          this.props.currentUser?.data?.attributes?.activeDonationSubscription?.data
                            ?.id
                        )}
                        onComplete={() => onUpdateCardDetails()}
                        type={SUBSCRIPTION_TYPES.donation}
                      />
                    </div>
                  ) : this.state.showChangeDonationPlan &&
                    this.props.currentUser &&
                    this.props.currentUser.data.attributes.activeDonationSubscription ? (
                    <div className="donation-plan">
                      <button
                        onClick={() => this.setActiveSection("showPaymentDetails")}
                        className="close-btn"
                      >
                        <CloseIcon color={"#1D3446"} />
                      </button>
                      <p>Change Donor Plan</p>
                      <p>
                        Your current monthly donation is for{" "}
                        <strong>
                          {nextDonation.currency} {nextDonation.amount}
                        </strong>
                      </p>
                      <p>
                        <strong>Change your monthly donation to:</strong>
                      </p>
                      <div className="amount">
                        <DonationAmountChooser
                          amounts={["10", "25", "50", "100"]}
                          width={this.props.width}
                          currentUser={this.props.currentUser}
                          currentAmount={nextDonation.amount}
                        />
                        <DonationCurrencySelector />
                      </div>
                      <div className="donation-plan-buttons">
                        <button
                          className="button button--primary"
                          disabled={!this.state.amount}
                          onClick={() => {
                            this.changeDonationAmount();
                            this.setState({ dialogAction: this.confirmDonationAmountChange });
                          }}
                        >
                          change donation amount
                        </button>
                        <button
                          className="button button--danger"
                          onClick={() => {
                            this.cancelDonationSubscription();
                            this.setState({ dialogAction: this.confirmDonationSubscriptionCancel });
                          }}
                        >
                          cancel monthly donations
                        </button>
                      </div>
                    </div>
                  ) : this.state.showDonationHistory ? (
                    <div className="donation-history">
                      <button
                        onClick={() => this.setActiveSection("showPaymentDetails")}
                        className="close-btn"
                      >
                        <CloseIcon color={"#1D3446"} />
                      </button>
                      <p>History</p>
                      {Object.entries(donationsByCurrency).map(([currency, donations]) => (
                        <table key={currency}>
                          <tbody>
                            {donations.map((donation, idx) => (
                              <tr key={idx}>
                                <td>
                                  {new Date(donation.createdAt).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })}
                                </td>
                                <td>
                                  {donation.isSubscription ? "Monthly" : "One-Off"} Donation
                                  {donation.refundReason && (
                                    <span
                                      style={{
                                        textTransform: "uppercase",
                                        color: "white",
                                        backgroundColor: "#fd6a02",
                                        borderRadius: "4px",
                                        padding: "2px 6px",
                                        fontSize: "11px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      refunded
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {currency}{" "}
                                  {/*{donations.reduce((sum, donation) => sum + donation.amount, 0)}*/}
                                  {donation.amount}
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan={2}>Total Donations:</td>
                              <td>
                                {currency}{" "}
                                {donations.reduce((sum, donation) => sum + donation.amount, 0)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ))}
                      {/*<table>*/}
                      {/*  <tbody>*/}
                      {/*    {donations.map((donation, idx) => (*/}
                      {/*      <tr key={idx}>*/}
                      {/*        <td>*/}
                      {/*          {new Date(donation.data.attributes.createdAt).toLocaleDateString(*/}
                      {/*            "en-GB",*/}
                      {/*            {*/}
                      {/*              day: "2-digit",*/}
                      {/*              month: "2-digit",*/}
                      {/*              year: "numeric",*/}
                      {/*            }*/}
                      {/*          )}*/}
                      {/*        </td>*/}
                      {/*        <td>*/}
                      {/*          {donation.data.attributes.isSubscription ? "Monthly" : "One-Off"}{" "}*/}
                      {/*          Donation*/}
                      {/*        </td>*/}
                      {/*        <td>*/}
                      {/*          {donation.data.attributes.currency}{" "}*/}
                      {/*          {donation.data.attributes.amount}*/}
                      {/*        </td>*/}
                      {/*      </tr>*/}
                      {/*    ))}*/}
                      {/*    <tr>*/}
                      {/*      <td colSpan={2}>Total Donations:</td>*/}
                      {/*      <td>*/}
                      {/*        {donationCurrency} {donationTotalAmount}*/}
                      {/*      </td>*/}
                      {/*    </tr>*/}
                      {/*  </tbody>*/}
                      {/*</table>*/}
                    </div>
                  ) : null}
                </div>
                {this.state.showDonationDescription && (
                  <DonationDescription
                    showDonationForm={this.state.showDonationForm}
                    toggleDonationDescription={this.toggleDonationDescription}
                  />
                )}
              </div>
            ) : (
              <div className="donation">
                <DonationCurrencySelector />
                <DonationNavigation {...this.props} isMonthly={this.state.isMonthly} />
                <div className="donation__option-tabs row">
                  <div
                    className={`donate-tab-content col-sm-12 ${this.props.showZiggy && "col-md-6"}`}
                  >
                    {_donationSuccess &&
                    (this.state.currentTab === donationTypes.MONTHLY ||
                      this.state.currentTab === donationTypes.SINGLE)
                      ? this.donationThanks()
                      : this.currentTabContent()}
                  </div>
                  {this.props.showZiggy && (
                    <div className="hidden-xxs hidden-xs hidden-sm col-md-6">
                      <Ziggy />
                    </div>
                  )}
                </div>
                <button
                  className="donate-tabs-btn"
                  onClick={() => this.toggleDonationDescription()}
                >
                  See how your donations help{" "}
                  {this.state.showDonationDescription ? <CaretUpIcon /> : <CaretDownIcon />}
                </button>
                {this.state.showDonationDescription && (
                  <DonationDescription
                    showDonationForm={this.state.showDonationForm}
                    toggleDonationDescription={this.toggleDonationDescription}
                  />
                )}
              </div>
            )}
            <Dialog onConfirm={(data) => this.state.dialogAction(data)} />
          </DonationContext.Provider>
        </div>
      );
    }
  }
);

class Donation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="jg-payments">
        <div className="donation-component">
          <DonationMain
            name={this.props.currentUser?.data?.attributes?.fullName}
            email={this.props.currentUser?.data?.attributes?.email}
            stripeKey={STRIPE_DONATION_PUBLISHABLE_KEY}
            recaptchaKey={RECAPTCHA_KEY}
            donationSingleSuccess={this.props.donationSingleSuccess}
            donationSubscriptionSuccess={this.props.donationSubscriptionSuccess}
            currentUser={this.props.currentUser}
            availableDonationTypes={this.props.availableDonationTypes}
            showZiggy={this.props.showZiggy}
            width={this.props.width}
            singleDonationAmounts={this.props.singleDonationAmounts}
            monthlyDonationAmounts={this.props.monthlyDonationAmounts}
            openDialog={this.props.openDialog}
            openAlert={this.props.openAlert}
            donationsDisabled={this.props.donationsDisabled}
          />
        </div>
      </div>
    );
  }
}

export default Donation;
