import PropTypes from "prop-types";
import React from "react";

export default class LessonListItem extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props, _railsContext) {
    super(props);
    this.state = {
      data: this.props.data,
    };
  }

  // componentDidMount() {
  // 	if (this.state.data.video) {
  // 		var youtubeId = this.state.data.video.split('/embed/')[1];
  // 		if (youtubeId) {
  // 			this.videoSearch(youtubeId);
  // 		}
  // 	}
  // }
  //

  convertDuration = (iso) => {
    if (iso != "not available") {
      iso = iso.replace(/PT|S/g, "");
      iso = iso.replace("M", ":");
      if (iso.split(":").length > 1 && iso.split(":")[1].length < 2) {
        iso = iso.split(":")[0] + ":0" + iso.split(":")[1];
      }
    }
    return iso;
  };

  render() {
    let item = this.state.data;

    return (
      <div className={"card all-lessons-card col-lg-12 col-md-12 col-sm-12 col-xs-12 "}>
        <a
          className={item.grade ? `border-${item.grade}` : "border-None"}
          href={item.path}
          target="_self"
        >
          <div className="card-thumbnail card-thumbnail--lesson">
            <img src={item.thumbnail["medium"] ? item.thumbnail["medium"] : item.thumbnail} />
          </div>
          <div className="card-content card-content--lesson">
            <div className="card-text">
              <div className="card-title">
                <h5 className="card-title-header justin-blue uppercase no-margin">{item.title}</h5>
                <div className="card-description">
                  <p className="pagragraph paragraph--card"> {item.meta} </p>

                  <div className="card-bottom">
                    <p className="paragraph paragraph--medium-gray">
                      {" "}
                      <span className="glyphicon glyphicon-time"></span>{" "}
                      {item.youtube_duration || "N/A"}
                    </p>
                    <p className="paragraph paragraph--medium-gray">
                      {" "}
                      <span className="glyphicon glyphicon-play"></span>{" "}
                      {item.youtube_views || "N/A"}{" "}
                    </p>
                    <p className="paragraph paragraph--medium-gray">ID: {item.reference} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}
