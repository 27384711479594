import React from "react";
import { connect } from "react-redux";

import {
  sortByOptions,
  slices,
  artistSortByLabels,
  selectArtistQuery,
  selectArtistSortBy,
  fetchArtists,
} from "./store";

const ArtistsSortBy = ({ sortBy, setSortBy, hasQuery }) => (
  <div className="songs-index__sort-by">
    <span>Sort By: </span>
    <select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
      {Object.entries(artistSortByLabels).map(([value, label]) => (
        <option value={value} key={value} disabled={value === sortByOptions.relevance && !hasQuery}>
          {label}
        </option>
      ))}
    </select>
  </div>
);

export default connect(
  (state) => {
    const query = selectArtistQuery(state);
    return { sortBy: selectArtistSortBy(state), hasQuery: query != null && query.length > 0 };
  },
  (dispatch) => ({
    setSortBy: (sortBy) => {
      dispatch(slices.ui.actions.setArtistSortBy(sortBy));
      dispatch(fetchArtists());
    },
  })
)(ArtistsSortBy);
