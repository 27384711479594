import React from "react";
import axiosWithCSRF from "./shared/axiosWithCSRF";

export default class CancelDonation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubscription: null,
      subscriptions: this.props.subscriptions,
    };
  }

  setSubscription = (event) => {
    this.setState({ selectedSubscription: event.target.value });
  };

  formattedPlanName = (subscription) => {
    let currency = subscription.donation_currency;
    let amount = parseInt(subscription.donation_amount);
    return `${amount} ${currency} monthly`;
  };

  subscriptionOptions = () => {
    return this.state.subscriptions.map((s) => {
      return (
        <option key={s.id} value={s.id}>
          {this.formattedPlanName(s)}
        </option>
      );
    });
  };

  formReady = () => {
    let { selectedSubscription } = this.state;
    return selectedSubscription && selectedSubscription.length > 0 ? true : false;
  };

  handleSubscriptionCancel = () => {
    if (confirm("Are you sure you want to cancel this subscription? This cannot be undone.")) {
      this.setState({ isSubmitting: true });
      axiosWithCSRF()
        .post("/cancel-subscription-donation", {
          user_donation_id: this.state.selectedSubscription,
        })
        .then((res) => {
          let subscriptions = [...this.state.subscriptions];
          let updatedList = subscriptions.filter((s) => s.id != this.state.selectedSubscription);
          this.setState(
            {
              successResponse: "You successfully cancelled this subscription.",
              isSubmitting: false,
              subscriptions: updatedList,
              selectedSubscription: null,
            },
            () => {
              setTimeout(() => this.setState({ successResponse: null }), 2000);
            }
          );
        })
        .catch((err) => {
          this.setState({ errorResponse: err.response.data.error.message, isSubmitting: false });
        });
    }
  };

  responseMessage = () => {
    let { successResponse, errorResponse } = this.state;
    if (successResponse) {
      return <h4 className="success-message">{successResponse}</h4>;
    } else if (errorResponse) {
      return (
        <h4 className="error-response">
          There was a problem canceling your subscription: {errorResponse}
        </h4>
      );
    }
  };

  render() {
    let { subscriptions } = this.state;
    if (this.state.isSubmitting) {
      return (
        <div className="pending">
          <img src="/images/Rolling.gif"></img>
          <h4 className="header4 bold justin-blue">canceling your subscription</h4>
        </div>
      );
    } else if (subscriptions && subscriptions.length > 0) {
      return (
        <div>
          {this.responseMessage()}
          <h3 className="header3">Please choose the subscription that you would like to cancel.</h3>
          <select onChange={this.setSubscription}>
            <option></option>
            {this.subscriptionOptions()}
          </select>

          <button
            type="button"
            id="blue-button"
            disabled={this.formReady() === false}
            onClick={this.handleSubscriptionCancel}
          >
            submit
          </button>
        </div>
      );
    } else {
      return (
        <div>
          {this.responseMessage()}
          <h3>You do not have any active subscriptions to cancel.</h3>
        </div>
      );
    }
  }
}
