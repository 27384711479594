import React from "react";
import { useSelector } from "react-redux";

import Swoosh from "../../shared/Swoosh";
import { selectCurrentUser } from "../store";

import LogInPrompt from "./LogInPrompt";
import NewSongRequestForm from "./NewSongRequestForm";
import UserSongRequests from "./UserSongRequests";
import PopularSongRequests from "./PopularSongRequests";
import SongRequestsCarousel from "./SongRequestsCarousel";

export default function ({ excludeTopMargin }) {
  const currentUser = useSelector(selectCurrentUser);
  return (
    <Swoosh gradient="nightSky" excludeTopMargin={excludeTopMargin}>
      <div className="song-requests container">
        <h2 className="header2">Top Song Requests For Justin</h2>

        <PopularSongRequests />

        <SongRequestsCarousel />

        {currentUser == null ? <LogInPrompt /> : <NewSongRequestForm />}

        <UserSongRequests />
      </div>
    </Swoosh>
  );
}
