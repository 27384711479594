import React, { useState } from "react";
import { connect } from "react-redux";
import LoginPrompt from "../../components/auth/LoginPrompt";
import { BackArrowIcon, IconCheck } from "../../components/shared/Icons";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import LiteYoutube from "../../components/shared/LiteYoutube";
import SimpleDialog from "../../components/shared/ui/SimpleDialog";
import PerformanceCard from "../../pages/UserPerformances/PerformanceCard";
import PerformancePlayDialog from "../../pages/UserPerformances/PerformancePlayDialog";
import { getYoutubeId, YT_URL_PATTERN } from "../../pages/UserPerformances/utils";

const PerformanceSubmit = ({
  song,
  setShowPerformanceSubmit,
  performances,
  setPerformances,
  setPerformanceToDelete,
  performanceToEdit = null,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const [videoUrl, setVideoUrl] = useState(
    performanceToEdit ? performanceToEdit.attributes.videoUrl : ""
  );
  const [description, setDescription] = useState(
    performanceToEdit ? performanceToEdit.attributes.description : ""
  );
  const [errors, setErrors] = useState({});
  const [videoSubmitted, setVideoSubmitted] = useState(false);
  const [ownershipConfirmation, setOwnershipConfirmation] = useState(false);

  const validURL = (url) => {
    const match = url.match(YT_URL_PATTERN);

    return match && match[6].length > 0;
  };

  const link = () => {
    if (validURL(videoUrl)) {
      setShowPreview(true);
    } else {
      setErrors({ video_url: "Please enter a valid YouTube URL" });
    }
  };

  const post = () => {
    axiosWithCSRF()
      .post("/student-performances", {
        user_performance: { song_id: song.data.id, video_url: videoUrl, description },
      })
      .then((res) => {
        setShowPreview(false);
        setVideoSubmitted(true);
        setPerformances([res.data.user_performance.data, ...performances]);
      })
      .catch((error) => {
        setErrors(error.response.data);
        // this.setState({ hasErrors: error.response.data.error });
        // setTimeout(() => this.setState({ hasErrors: null }), 3000);
      });
  };

  const update = () => {
    axiosWithCSRF()
      .patch(`/student-performances/${performanceToEdit.id}`, {
        user_performance: { video_url: videoUrl, description },
      })
      .then((res) => {
        setShowPreview(false);
        setVideoSubmitted(true);
        // replace the performance in the performances array
        const idx = performances.findIndex((p) => p.id === performanceToEdit.id);
        const newPerformances = [...performances];
        newPerformances[idx] = res.data.user_performance.data;
        setPerformances(newPerformances);
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  return (
    <div className="performance-submit">
      {showPreview ? (
        <>
          <p className="subtitle">
            <strong>Preview Your Video!</strong>
          </p>
          <div className="video-preview">
            <LiteYoutube url={getYoutubeId(videoUrl)} />
          </div>
          <p className="info">
            <strong>Add a short description</strong> (up to 300 characters){" "}
            <span className="asterisk">*</span>
          </p>
          <textarea
            onChange={(e) => setDescription(e.target.value)}
            maxLength={300}
            value={description}
          ></textarea>
          {errors.message && <p className="error">{errors.message}</p>}

          <div className="confirmation">
            <input
              type="checkbox"
              id="ownershipConfirmation"
              checked={ownershipConfirmation}
              onChange={(e) => setOwnershipConfirmation(e.target.checked)}
            />
            <label htmlFor="ownershipConfirmation">
              I confirm that this video is my property and I have the right to upload it. I also
              certify that all of the information provided is true and accurate.
            </label>
          </div>
          <button
            className="button button--primary"
            disabled={description.length === 0 || !videoUrl || !ownershipConfirmation}
            onClick={() => (performanceToEdit ? update() : post())}
          >
            {performanceToEdit ? "update" : "post"} my performance
          </button>
        </>
      ) : videoSubmitted ? (
        <>
          <p className="subtitle">
            <strong>Thank you! You successfully submitted your video. :)</strong>
          </p>
          <p className="info">
            Remember we need to approve your video before it goes live here. This might take a few
            days; we appreciate your patience!
          </p>
          <div className="icon">
            <IconCheck />
          </div>
          <div className="ctas">
            <button
              className="button button--primary"
              onClick={() => setShowPerformanceSubmit(false)}
            >
              Check Your Performance status
            </button>
          </div>
        </>
      ) : (
        <>
          <p className="info">
            You'll need to upload your video to YouTube to be able to submit it here. If you're
            unsure on how to do that, please{" "}
            <a
              href="https://support.google.com/youtube/answer/57407?hl=en&co=GENIE.Platform%3DDesktop"
              target="_blank"
            >
              read these tips
            </a>
            . When you're ready, all you need to do is paste the link to your video below. Have fun!
          </p>
          <input
            type="text"
            name="video_url"
            placeholder="Paste in https://youtu.be/..."
            onChange={(e) => setVideoUrl(e.target.value)}
            value={videoUrl}
          />
          {errors.video_url && <p className="error">{errors.video_url}</p>}
          <div className="ctas">
            <button
              className="button button--primary"
              onClick={(e) => link(true)}
              disabled={!videoUrl}
            >
              Add Link
            </button>
            {performanceToEdit && (
              <button
                className="button button--danger"
                onClick={(e) => setPerformanceToDelete(performanceToEdit)}
              >
                Delete
              </button>
            )}
          </div>
          <p className="info">
            💡
            <strong>
              Please note that we moderate this Performance tab in order to guarantee a safe
              environment to all JustinGuitar students
            </strong>
            . This means that your video won’t be published here straight away after you upload it.
            First, it needs to be approved by a member of the JustinGuitar team. If you have any
            questions, please <a href="mailto:hello@justinguitar.com">contact us</a>.
          </p>
        </>
      )}
    </div>
  );
};

const PerformanceSubmitCard = ({ setShowPerformanceSubmit }) => {
  return (
    <div className="performance-card submit">
      <button onClick={() => setShowPerformanceSubmit(true)}>Upload my performance</button>
    </div>
  );
};

const Performances = ({ song, userPerformances, currentUser, token }) => {
  const [showPerformanceSubmit, setShowPerformanceSubmit] = useState(false);
  const [performances, setPerformances] = useState(userPerformances.data);
  const [performanceToPlay, setPerformanceToPlay] = useState(null);
  const [performanceToEdit, setPerformanceToEdit] = useState(null);
  const [performanceToDelete, setPerformanceToDelete] = useState(null);

  const edit = (performance) => {
    setPerformanceToEdit(performance);
    setShowPerformanceSubmit(true);
  };

  const deletePerformance = (performance) => {
    axiosWithCSRF()
      .delete(`/student-performances/${performance.id}`)
      .then((res) => {
        setPerformances(performances.filter((p) => p.id !== performance.id));
        setPerformanceToDelete(null);
        setPerformanceToEdit(null);
        setShowPerformanceSubmit(false);
      })
      .catch((error) => {});
  };

  if (!currentUser) {
    return (
      <>
        <p>To watch, comment or upload in this feature you need to be logged in!</p>
        <p>Don’t have an account yet? It’s FREE.</p>
        <div className="login-prompt">
          <LoginPrompt token={token} />
        </div>
      </>
    );
  }

  return (
    <div className="song__performances">
      <>
        {!showPerformanceSubmit ? (
          <>
            <p className="title">Post a Video of You Performing This Song!</p>

            <p className="subtitle">
              Plus, get inspired by watching the JustinGuitar Community performances!
            </p>
            <p className="info">
              📹 Check out Justin's Tips on{" "}
              <a href="https://www.justinguitar.com/guitar-lessons/record-yourself-production-basics-bg-2010">
                How to Record Yourself
              </a>
              !
            </p>
            <div className="performance-cards">
              <PerformanceSubmitCard setShowPerformanceSubmit={setShowPerformanceSubmit} />
              {performances.map((performance, idx) => (
                <PerformanceCard
                  key={idx}
                  performance={performance}
                  setPerformanceToPlay={setPerformanceToPlay}
                  edit={edit}
                  currentUser={currentUser}
                />
              ))}
            </div>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                setShowPerformanceSubmit(false);
                setPerformanceToEdit(null);
              }}
              className="back-btn"
            >
              <BackArrowIcon />
            </button>
            <PerformanceSubmit
              song={song}
              setShowPerformanceSubmit={setShowPerformanceSubmit}
              performances={performances}
              setPerformances={setPerformances}
              setPerformanceToDelete={setPerformanceToDelete}
              performanceToEdit={performanceToEdit}
            />
          </>
        )}
      </>

      {performanceToPlay && (
        <PerformancePlayDialog
          performanceToPlay={performanceToPlay}
          setPerformanceToPlay={setPerformanceToPlay}
        />
      )}
      {performanceToDelete && (
        <SimpleDialog
          title={"Delete my performance"}
          content="Are you sure you want to delete this performance?"
          actionButtons={
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="button button--transparent"
                onClick={() => setPerformanceToDelete(null)}
              >
                cancel
              </button>
              <button
                className="button button--danger"
                onClick={() => deletePerformance(performanceToDelete)}
              >
                Delete
              </button>
            </div>
          }
          closeAction={() => setPerformanceToDelete(null)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  token: state.entity.token,
  song: state.entity.song,
  userPerformances: state.entity.userPerformances,
});

export default connect(mapStateToProps)(Performances);
