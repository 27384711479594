import React from "react";
import { filterItems, formatTime, practiceSum } from "./StatsFunctions";
import ProgressBar from "./ProgressBar";
import ItemStats from "./ItemStats";
import PracticeIcons from "../PracticeIcons";

export default class StatisticsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  hasStatsToShow = () => {
    let { sessionItems } = this.state;
    return sessionItems && sessionItems.length > 0 ? true : false;
  };

  toggleDetails = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  statDetailsHeader = (items) => {
    if (!items || items.length === 0) {
      return (
        <div className="stats-details__toggle">
          <span
            className="icon-stats-details"
            dangerouslySetInnerHTML={{ __html: PracticeIcons.statDetailsIcon() }}
          />
          <span className="stat-details__no-details">No Details</span>
        </div>
      );
    } else {
      let iconClass;
      let action;
      if (this.state.showDetails) {
        iconClass = "icon icon-view-hide";
        action = "Hide";
      } else {
        iconClass = "icon icon-view-show";
        action = "Show";
      }
      return (
        <div className="stats-details__toggle">
          <span
            className="icon-stats-details"
            dangerouslySetInnerHTML={{ __html: PracticeIcons.statDetailsIcon() }}
          />
          <span className="stat-details__button" onClick={this.toggleDetails}>
            <i className={iconClass}></i>
            {`${action} Details`}
          </span>
        </div>
      );
    }
  };

  statDetails = (items) => {
    if (items && items.length > 0 && this.state.showDetails === true) {
      return (
        <div className="stats-details__container">
          <ProgressBar
            textLineOne={"TOTAL"}
            totalProgress={true}
            percentage={"100%"}
            formattedDuration={formatTime(practiceSum(items))}
          />

          <div className="practice-stats-inner">
            <div className="practice-stats-drilldown practice-stats-drilldown--itemly">
              {<ItemStats items={items} formatTime={formatTime} />}
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    let { items, startDate, endDate } = this.props;
    if (items) {
      let itemsWithinRange = filterItems(items, startDate, endDate);
      return (
        <div>
          {this.statDetailsHeader(itemsWithinRange)}
          {this.statDetails(itemsWithinRange)}
        </div>
      );
    } else {
      return null;
    }
  }
}
