import React from "react";
import PlaygroundSwoosh from "./PlaygroundSwoosh";
import SimpleCard from "./SimpleCard";
import AnimatedCard from "./AnimatedCard";

const PlaygroundCategoriesList = ({ bootstrapSize, categories, currentUser }) => {
  return (
    <>
      <PlaygroundSwoosh />
      <div className="playground__body">
        <div className="playground__body__cards">
          {categories.map((category, idx) => (
            <div key={idx} className="card-container categories">
              {["xsDevice", "smDevice", "mdDevice"].includes(bootstrapSize) ? (
                <SimpleCard
                  data={category.attributes}
                  onNavigate={() =>
                    (window.location.href = `/playground/${category.attributes.slug}`)
                  }
                  currentUser={currentUser}
                  bootstrapSize={bootstrapSize}
                />
              ) : (
                <AnimatedCard data={category} />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PlaygroundCategoriesList;
