import React from "react";

const LessonFavorite = ({ currentUser, savedLessonIds, lesson, toggleFavorite }) => {
  const styleWhenNoDuration = lesson.youtubeDuration
    ? null
    : {
        flexBasis: "10%",
        textAlign: "right",
      };

  const userHasSavedItem = savedLessonIds?.includes(lesson.id);

  return (
    <>
      <i
        className={"icon icon-heart" + (userHasSavedItem ? "" : "-outline")}
        style={styleWhenNoDuration}
        onClick={() => toggleFavorite(userHasSavedItem, lesson.id)}
      ></i>
    </>
  );
};

export default LessonFavorite;
