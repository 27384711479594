import { CountryOfResidence } from "./enum/countryOfResidence";
import { ReferralType } from "./enum/referral-type";

import * as yup from "yup";

export interface RegistrationDetails {
  firstName: string;
  lastName: string;
  email: string;
  mobilePhoneNumber: string;
  countryOfResidence: CountryOfResidence;
  isAlum: boolean;
  referralType: ReferralType;
}

export const registrationDetailsInitialState: Partial<RegistrationDetails> = {
  isAlum: false,
};

const phoneNumberRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const registrationDetailsSchema = yup.object({
  firstName: yup.string().min(1).required(),
  lastName: yup.string().min(1).required(),
  email: yup.string().email().required(),
  mobilePhoneNumber: yup.string().matches(phoneNumberRegex).required(),
  countryOfResidence: yup.string().oneOf(Object.keys(CountryOfResidence)).required(),
  isAlum: yup.boolean(),
  referralType: yup.string().oneOf(Object.keys(ReferralType)).required(),
});

export const registrationDetailsFormFieldNames = Object.keys(registrationDetailsSchema.fields);
