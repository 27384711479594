import React from 'react';


  export const TeachersIcon = () => {
    return (
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="10" fill="#406E8E" />
        <path
          d="M24 13L15 17V23C15 28.55 18.84 33.74 24 35C29.16 33.74 33 28.55 33 23V17L24 13ZM22 29L18 25L19.41 23.59L22 26.17L28.59 19.58L30 21L22 29Z"
          fill="white"
        />
      </svg>
    );
  };
  
  export const GoalsIcon = () => {
    return (
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="10" fill="#406E8E" />
        <path
          d="M24 15V25.55C23.41 25.21 22.73 25 22 25C19.79 25 18 26.79 18 29C18 31.21 19.79 33 22 33C24.21 33 26 31.21 26 29V19H30V15H24Z"
          fill="white"
        />
      </svg>
    );
  };
  
  export const OneOnOneIcon = () => {
    return (
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="10" fill="#406E8E" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.67 25.13C30.04 26.06 31 27.32 31 29V32H35V29C35 26.82 31.43 25.53 28.67 25.13Z"
          fill="white"
        />
        <path
          d="M21 24C23.2091 24 25 22.2091 25 20C25 17.7909 23.2091 16 21 16C18.7909 16 17 17.7909 17 20C17 22.2091 18.7909 24 21 24Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27 24C29.21 24 31 22.21 31 20C31 17.79 29.21 16 27 16C26.53 16 26.09 16.1 25.67 16.24C26.5 17.27 27 18.58 27 20C27 21.42 26.5 22.73 25.67 23.76C26.09 23.9 26.53 24 27 24Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 25C18.33 25 13 26.34 13 29V32H29V29C29 26.34 23.67 25 21 25Z"
          fill="white"
        />
      </svg>
    );
  };
  
  export const LocationIcon = () => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z"
          fill="#81ABC1"
        />
      </svg>
    );
  };
  
  export const CloseIcon = () => {
    return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
          fill="white"
        />
      </svg>
    );
  };
