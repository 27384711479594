import React from "react";
import LiteYoutube from "../../components/shared/LiteYoutube";

export default function ({ videoId }) {
  return (
    <div className="container">
      <div className="section welcome">
        <h2 className="title">Congratulations! You have received your new guitar. :)</h2>
        <LiteYoutube url={videoId} hasMaxResImage={true} />
        <p className="welcome-text">
          Welcome to the world of guitar playing! Whether you're a newbie or a seasoned player,
          JustinGuitar is here to guide you through it all. It's not just lessons; it's a community
          of guitar enthusiasts from around the world. If you haven't already, come say hi! :)
        </p>
        <div className="ctas">
          <a
            href="https://community.justinguitar.com/c/community-hub/introduce-yourself/263"
            className="button button--primary"
            target="_blank"
          >
            Introduce Yourself
          </a>
          <a href="/guitar-lessons" className="button button--blue">
            Start Learning
          </a>
        </div>
        <div className="favor-banner">
          <p>Spread the Joy: Help Others Get Free Guitars! :)</p>
          <p>
            Enjoying your new guitar and JustinGuitar learning journey?{" "}
            <a href="https://justinguitar-survey.typeform.com/to/R7z4Xa9s" target="_blank">
              Share your experience
            </a>{" "}
            and support our mission to make music accessible for all with free guitars. 🎸
          </p>
        </div>
      </div>
    </div>
  );
}
