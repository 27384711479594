import React from "react";
import Slick from "react-slick";

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  lazyLoad: true,
};

const imageUrls = [
  "https://jtgt-static.b-cdn.net/system/dragonfly/production/2018/02/03/90d2zogkfl_Melua_Sandercoe_Live_Earth.jpg",
  "https://jtgt-static.b-cdn.net/system/dragonfly/production/2018/02/14/3zv27eirqg_GuitarTechniquesMag.jpg",
  "https://jtgt-static.b-cdn.net/system/dragonfly/production/2018/02/03/n919z1tnz_Melua_Sandercoe_AbbeyRd.jpg",
  "https://jtgt-static.b-cdn.net/system/dragonfly/production/2018/02/14/5hftd76yfj_skydive.jpg",
  "https://jtgt-static.b-cdn.net/system/dragonfly/production/2018/02/03/5ay9s4ybn3_Bruni_Sandercoe_GMTV.jpg",
  "https://jtgt-static.b-cdn.net/system/dragonfly/production/2018/02/20/86baz0s0m2_Weissmies.jpg",
];

const Slideshow = () => (
  <Slick {...settings}>
    {imageUrls.map((url) => (
      <img src={url} alt="" key={url} />
    ))}
  </Slick>
);

export default Slideshow;
