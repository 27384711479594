import { createSelector } from "@reduxjs/toolkit";
import { songbookTabPanel } from "./dashboardStore";
import Fuse from "fuse.js";

export const selectState = (state) => state;

export const selectEntity = createSelector(selectState, ({ entity }) => entity);

export const selectDashboard = createSelector(selectEntity, ({ dashboard }) => dashboard);

export const selectSongbooksDictionary = createSelector(
  selectEntity,
  ({ songbooks }) => songbooks || {}
);

export const selectSongbooksArray = createSelector(selectSongbooksDictionary, (songbooks) =>
  Object.values(songbooks)
);

export const selectSongsDictionary = createSelector(selectEntity, ({ songs }) => songs || {});

export const selectSongsArray = createSelector(selectSongsDictionary, (songs) =>
  Object.values(songs)
);

export const selectSongbookSongsDictionary = createSelector(
  selectEntity,
  ({ songbookSongs }) => songbookSongs
);

export const selectOrderedSongbookSongsForSongbook = createSelector(
  selectSongbookSongsDictionary,
  (songbookSongs) => (songbook) => {
    try {
      return songbook.relationships.songbookSongs.data
        .map(({ id }) => songbookSongs[id])
        .filter((songbookSong) => songbookSong != null)
        .sort((a, b) => b.ord - a.ord);
    } catch {
      return [];
    }
  }
);

export const selectOrderedSongsForSongbook = createSelector(
  selectOrderedSongbookSongsForSongbook,
  selectSongsDictionary,
  (getSongbookSongs, songs) => (songbook) => {
    return getSongbookSongs(songbook)
      .map((songbookSong) => {
        try {
          const songId = songbookSong.relationships.song.data.id;
          return songs[songId];
        } catch {
          return null;
        }
      })
      .filter((song) => song != null);
  }
);

export const selectRenamingSongbookId = createSelector(selectDashboard, (dashboard) =>
  dashboard == null ? null : dashboard.renamingSongbookId
);

export const isRenamingSongbook = createSelector(
  selectRenamingSongbookId,
  (renamingId) => (songbookId) => renamingId != null && Number(renamingId) === Number(songbookId)
);

export const selectSongbookPanel = createSelector(selectDashboard, (dashboard) =>
  dashboard == null || dashboard.songbookTabPanel == null
    ? songbookTabPanel.songbooks
    : dashboard.songbookTabPanel
);

export const selectCurrentSongbookId = createSelector(
  selectDashboard,
  ({ currentSongbookId }) => currentSongbookId
);

export const selectCurrentSongbook = createSelector(
  selectSongbooksDictionary,
  selectCurrentSongbookId,
  (songbooks, id) => (id == null ? null : songbooks[id])
);

export const selectSongOptionsState = createSelector(
  selectEntity,
  ({ songOptions }) => songOptions || {}
);

export const selectSongOptions = createSelector(
  selectSongOptionsState,
  selectSongsDictionary,
  selectCurrentSongbook,
  selectOrderedSongsForSongbook,
  ({ songIds }, songs, currentSongbook, selectOrderedSongsForSongbook) => {
    const songbookSongIds = selectOrderedSongsForSongbook(currentSongbook).map((s) => s.id);
    return songIds
      .map((id) => songs[id])
      .filter((song) => song != null)
      .map((song) => ({
        ...song,
        attributes: {
          ...song.attributes,
          isSelected: songbookSongIds.includes(song.id),
        },
      }));
  }
);

export const selectSongOptionsMeta = createSelector(
  selectSongOptionsState,
  ({ meta }) => meta || {}
);

export const selectSongOptionsLoading = createSelector(
  selectSongOptionsState,
  ({ loading }) => loading
);

export const selectSongOptionsQuery = createSelector(
  selectSongOptionsState,
  ({ query }) => query || ""
);

// doing this in a selector so that the Fuse instance is cached
export const selectSongOptionsFuse = createSelector(
  selectSongsArray,
  (songs) =>
    new Fuse(songs, {
      keys: [
        { name: "attributes.title", weight: 2 },
        { name: "attributes.originalArtist", weight: 2 },
        { name: "attributes.notes", weight: 0.5 },
      ],
      threshold: 0.4,
    })
);

export const selectSessionState = createSelector(selectState, ({ session }) => session || {});

export const selectCurrentUser = createSelector(
  selectSessionState,
  ({ currentUser }) => currentUser
);

export const selectPracticeSongs = createSelector(selectCurrentUser, (currentUser) => {
  if (currentUser == null) {
    return {};
  }

  const userPracticeItems = currentUser?.data?.relationships?.userPracticeItems;

  if (userPracticeItems == null) {
    return {};
  }

  return (currentUser.included || []).filter(
    ({ type, attributes: { relatedLink } }) =>
      type === "userPracticeItem" && relatedLink.startsWith("/song") // hacky but session.currentuser is kinda weird and i don't want to change it in case something else depends on it being structured the way it is now
  );
});
