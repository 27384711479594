import React from "react";

const SiteStats = ({ registeredUserCount, freeLessonsCount, songLessonsCount }) => {
  return (
    <div className="stats">
      <div>
        <p>{registeredUserCount.toLocaleString()}</p>
        <p>Registered Students</p>
      </div>
      <div>
        <p>{freeLessonsCount.toLocaleString()}</p>
        <p>Free Guitar Lessons</p>
      </div>
      <div>
        <p>{songLessonsCount.toLocaleString()}</p>
        <p>Song Tutorials</p>
      </div>
    </div>
  );
};

export default SiteStats;
