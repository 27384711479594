import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import LoginForm from "../LoginForm";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100%",
  },
};

export default class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      Modal.setAppElement("body");
    }
  }

  toggleForm = () => {
    this.setState({ formOpen: !this.state.formOpen });
  };

  render() {
    if (this.state.formOpen != true) {
      return (
        <p>
          You need to be{" "}
          <span className="clickable justin-blue bold" onClick={this.toggleForm}>
            logged in.
          </span>
        </p>
      );
    } else {
      return (
        <Modal
          isOpen={this.state.formOpen}
          onRequestClose={this.toggleForm}
          style={customStyles}
          contentLabel="Log In"
        >
          <button className="close-modal__button" onClick={this.toggleForm}>
            <p>X</p>
          </button>
          <LoginForm />
        </Modal>
      );
    }
  }
}

LoginModal.propTypes = {};
