import React from "react";
import { styled, Tooltip } from "@mui/material";

const LIGHT_COLOR = "white";
const DARK_COLOR = "#4E5B69";

export const LightTooltip = styled(Tooltip)(() => ({
  tooltip: {
    color: DARK_COLOR,
    backgroundColor: LIGHT_COLOR,
    borderRadius: "10px",
    padding: "10px",
    boxShadow: "0px 4px 14px 0px #00000026",
    fontSize: "14px",
  },
  arrow: {
    color: LIGHT_COLOR,
  },
}));

export const DarkTooltip = styled(Tooltip)(() => ({
  tooltip: {
    color: LIGHT_COLOR,
    backgroundColor: DARK_COLOR,
    borderRadius: "10px",
    padding: "10px",
    boxShadow: "0px 4px 14px 0px #00000026",
    fontSize: "14px",
  },
  arrow: {
    color: DARK_COLOR,
  },
}));
