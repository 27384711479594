import { Button, Link, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { SECTIONS } from "./NewRegistration";
import { OrangeLink } from "../../../jg-material/theme/components/styled/Links";

const AppDisclaimer = ({ setActiveSection }) => {
  return (
    <>
      <Typography fontWeight={"bold"}>
        JustinGuitar Songs & Lessons App and JustinGuitar.com are not synced, meaning your progress
        and user account are not shared. Please read this carefully:
      </Typography>

      <List sx={{ listStyleType: "disc" }}>
        <ListItem sx={{ display: "list-item", paddingLeft: "4px" }}>
          <Typography>
            If you wish to use both platforms, you must create separate accounts. While you can use
            the same email address and password, you still need two accounts. I understand this is
            not ideal, but our development teams are working to simplify this process. In the
            meantime, you can still benefit from my content and learn more quickly by combining both
            tools.
          </Typography>
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: "4px" }}>
          <Typography>
            Joining JustinGuitar.com is free, and most content does not have a paywall. However,
            subscribing to my Lessons and Songs App does not grant you access to any Paid Product
            available on JustinGuitar.com
          </Typography>
        </ListItem>
      </List>

      <Typography fontWeight={"bold"}>
        If you have any questions, don't hesitate to contact{" "}
        <OrangeLink href="mailto:hello@justinguitar.com">hello@justinguitar.com</OrangeLink>. A team
        member will happily assist you!
      </Typography>

      <Button
        className="button button--primary"
        sx={{ boxShadow: "none", mt: 3 }}
        onClick={() => setActiveSection(SECTIONS.form)}
      >
        Back
      </Button>
    </>
  );
};

export default AppDisclaimer;
