import React from "react";
import axios from "axios";
import TabItem from "../practice/General/TabItem";
import PlayContainer from "../practice/PracticeTab/PlayContainer";
import StatisticsContainer from "../practice/StatsTab/StatisticsContainer";
import RoutineContainer from "../practice/RoutineTab/RoutineContainer";
import AboutPracticeAssistant from "../practice/AboutTab/AboutPracticeAssistant";
import PracticeConstants from "./PracticeConstants";
import PracticeItemsContainer from "./PracticeItemsTab/PracticeItemsContainer";

export default class PracticeOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      completedItems: [],
      activeTab: "practice",
    };
  }

  componentDidMount() {
    const activeTab = window.location.hash != "" ? window.location.hash.slice(1) : "practice";
    window.location.hash = activeTab;
    this.setState(
      {
        activeTab,
        activeRoutine: this.activeRoutine(this.props.practiceRoutines),
        practiceRoutines: this.props.practiceRoutines,
        practiceItems: this.props.practiceItems || [],
        practiceSessions: this.props.practiceSessions,
        inactiveRoutines: this.inactiveRoutines(this.props.practiceRoutines),
        practiceSessionItems: this.props.practiceSessionItems,
      },
      () => this.setState({ completedItems: this.completedItems() })
    );
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.state.practiceRoutines != nextState.practiceRoutines) {
      this.setState({
        activeRoutine: this.activeRoutine(nextState.practiceRoutines),
        inactiveRoutines: this.inactiveRoutines(nextState.practiceRoutines),
      });
    }
  }

  activeRoutine = (routines) => {
    if (routines) {
      return routines.filter((r) => r.is_active === true)[0];
    }
  };

  inactiveRoutines = (routines) => {
    if (routines) {
      return routines.filter((r) => r.is_active === false);
    }
  };

  completedItems = () => {
    let { practiceSessions } = this.props;
    let { activeRoutine } = this.state;
    if (activeRoutine) {
      let practiceSession = practiceSessions.filter(
        (s) => s.user_practice_routine_id === activeRoutine.id && s.is_finished === false
      )[0];
      return practiceSession ? practiceSession.completed_practice_items : [];
    } else {
      return [];
    }
  };

  resetCompletedItems = () => {
    this.setState({ completedItems: [] });
  };

  formStarted = () => {
    this.setState({ formStarted: true });
  };

  showNew = (newItem) => {
    let { practiceItems } = this.state;
    if (practiceItems && practiceItems.length > 0) {
      this.setState({ practiceItems: [...practiceItems, newItem] });
    } else {
      let items = new Array(newItem);
      this.setState({ practiceItems: items });
    }
  };

  handleNewItem = (item) => {
    let items = [...this.state.practiceItems];
    items.push(item);
    this.setState({ practiceItems: items });
  };

  handleUpdatedItem = (item) => {
    let items = [...this.state.practiceItems];
    let itemToUpdate = items.filter((i) => i.id === item.id)[0];
    if (itemToUpdate) {
      items[items.indexOf(itemToUpdate)] = item;
    }
    this.setState({ practiceItems: items }, this.changeItemWithinPracticeRoutine(item, "update"));
  };

  handleArchivedItem = (item) => {
    let items = [...this.state.practiceItems];
    let itemToUpdate = items.filter((i) => i.id === item.id)[0];
    if (itemToUpdate) {
      itemToUpdate.status = "archived";
    }
    this.setState({ practiceItems: items }, this.changeItemWithinPracticeRoutine(item, "remove"));
  };

  handleRestoredItem = (item) => {
    let items = [...this.state.practiceItems];
    let itemToUpdate = items.filter((i) => i.id === item.id)[0];
    if (itemToUpdate) {
      itemToUpdate.status = "active";
    }
    this.setState({ practiceItems: items });
  };

  handleDeletedItem = (item) => {
    let items = [...this.state.practiceItems];
    let itemToRemove = items.filter((i) => i.id === item.id)[0];
    if (itemToRemove) {
      items.splice(items.indexOf(itemToRemove), 1);
    }
    this.setState({ practiceItems: items });
  };

  changeItemWithinPracticeRoutine = (item, action) => {
    let routines = [...this.state.practiceRoutines];
    routines.map((routine) => {
      let itemToChange = routine.items.filter((i) => i.id === item.id)[0];
      if (itemToChange) {
        if (action === "remove") {
          routine.items.splice(routine.items.indexOf(itemToChange), 1);
        } else if (action === "update") {
          routine.items[routine.items.indexOf(itemToChange)] = item;
        }
      }
    });
    this.setState({ practiceRoutines: routines });
  };

  handleChangedItemsWithinRoutine = (routineId, items) => {
    let practiceRoutines = [...this.state.practiceRoutines];
    let updatedRoutine = practiceRoutines.filter((r) => r.id === routineId)[0];
    updatedRoutine.items = items;
    this.setState({ practiceRoutines });
  };

  handleNewRoutine = (routine) => {
    if (!routine.items) {
      routine.items = [];
    }
    let practiceRoutines = [...this.state.practiceRoutines];
    practiceRoutines.push(routine);
    this.setState({ practiceRoutines });
  };

  removeRoutineById = (routineId) => {
    let practiceRoutines = [...this.state.practiceRoutines];
    let routineToRemove = practiceRoutines.filter((r) => r.id === routineId)[0];
    if (routineToRemove) {
      practiceRoutines.splice(practiceRoutines.indexOf(routineToRemove), 1);
      this.setState({ practiceRoutines });
    }
  };

  removeItem = (itemId) => {
    let currentItems = [...this.state.practiceItems];
    let item = currentItems.filter((item) => item.id === itemId)[0];
    let itemIndex = currentItems.indexOf(item);
    let inactiveItems = [...this.state.inactiveItems];
    inactiveItems.push(item);
    currentItems.splice(itemIndex, 1);
    this.setState({
      practiceItems: currentItems,
      inactiveItems: inactiveItems,
    });
  };

  updateNoteInPracticeItem = (note, itemId) => {
    let practiceItems = [...this.state.practiceItems];
    let changedItem = practiceItems.filter((i) => i.id === itemId)[0];
    if (changedItem) {
      changedItem.note = note;
      let itemInActiveRoutine = this.state.activeRoutine.items.filter((i) => i.id === itemId);

      if (itemInActiveRoutine.length > 0) {
        let activeRoutine = Object.assign({}, this.state.activeRoutine);
        let itemToChange = activeRoutine.items[activeRoutine.items.indexOf(itemInActiveRoutine[0])];
        itemToChange.note = note;
        this.setState({ practiceItems, activeRoutine });
      } else {
        this.setState({ practiceItems });
      }
    }
  };

  saveEditedOrder = (items) => {
    const { activeRoutine } = this.state;
    axios
      .patch(`/user_practice_routines/update_items_order/${activeRoutine.id}`, {
        user_practice_routine_items: items,
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((response) => {
        this.changeItemOrder(response.data.items);
      });
  };

  changeItemOrder = (joinItems) => {
    let originalOrder = [...this.state.activeRoutine.items];

    let revisedOrder = [];
    joinItems.forEach(function (item) {
      let originalItem = originalOrder.filter((i) => i.id === item.user_practice_item_id);
      if (originalItem.length > 0) {
        let revisedItem = originalItem[0];
        revisedItem.position = revisedOrder.length;
        revisedOrder.push(revisedItem);
      }
    });
    let routine = Object.assign({}, this.state.activeRoutine);
    routine.items = revisedOrder;
    this.setState({ activeRoutine: routine });
  };

  hasNoItems = () => {
    if (
      !this.state.practiceItems ||
      (this.state.practiceItems.length < 1 && !this.state.inactiveItems)
    ) {
      return true;
    } else {
      return false;
    }
  };

  updateStatsAndPracticeSessions = (statItem, practiceSession) => {
    let updatedPracticeSessionItems = this.updatePracticeSessionItems(statItem);
    let updatedPracticeSessions = this.updatePracticeSessions(practiceSession);
    this.setState({
      practiceSessionItems: updatedPracticeSessionItems,
      practiceSessions: updatedPracticeSessions,
      completedItems: practiceSession.completed_practice_items,
    });
  };

  updatePracticeSessionItems = (item) => {
    let updatedSessions = [];
    if (this.state.practiceSessionItems) {
      updatedSessions = [...this.state.practiceSessionItems];
    }
    updatedSessions.push(item);
    return updatedSessions;
  };

  updatePracticeSessions = (practiceSession) => {
    let existingSessions = [...this.props.practiceSessions];
    let practiceSessions = existingSessions.filter(
      (s) => s.id === practiceSession.id && s.is_finished === false
    );
    if (practiceSessions.length > 0) {
      let indexToChange = existingSessions.indexOf(practiceSessions[0]);
      existingSessions[indexToChange] = practiceSession;
    } else {
      existingSessions.push(practiceSession);
    }

    return existingSessions;
  };

  handleDeletedStatItem = (itemId) => {
    let practiceSessionItems = [...this.state.practiceSessionItems];
    let itemToRemove = practiceSessionItems.filter((i) => i.id === itemId)[0];
    if (itemToRemove) {
      practiceSessionItems.splice(practiceSessionItems.indexOf(itemToRemove), 1);
      this.setState({ practiceSessionItems });
    }
  };

  updateFinishedPracticeSession = (practiceSession) => {
    let updatedSessions = this.updatePracticeSessions(practiceSession);
    this.setState({ practiceSessions: updatedSessions });
  };

  updateStatItem = (item) => {
    let { practiceSessionItems } = this.state;
    let foundItem = practiceSessionItems.filter((i) => i.id === item.id);
    if (foundItem.length > 0) {
      let updatedSessionItems = [...practiceSessionItems];
      let indexToChange = updatedSessionItems.indexOf(foundItem[0]);
      updatedSessionItems[indexToChange] = item;
      this.setState({ practiceSessionItems: updatedSessionItems });
    }
  };

  updateFilteredStatistics = (practiceSessionItems) => {
    this.setState({ practiceSessionItems });
  };

  allItems = () => {
    let practiceItems = this.state.practiceItems || [];
    let inactiveItems = this.state.inactiveItems || [];
    return practiceItems.concat(inactiveItems);
  };

  setActiveTab = (tabName) => {
    if (this.state.activeTab != tabName) {
      window.location.hash = tabName;
      this.setState({ activeTab: tabName });
    }
  };

  updateActiveRoutine = (activeRoutine) => {
    let routines = [...this.state.practiceRoutines];
    let active = routines.filter((i) => i.is_active === true)[0];
    if (active) {
      routines[routines.indexOf(active)] = activeRoutine;
      this.setState({ activeRoutine, practiceRoutines: routines });
    } else {
      this.setState({ activeRoutine });
    }
  };

  changeActiveRoutine = (newRoutine) => {
    let routines = [...this.state.practiceRoutines];
    let routineToMakeActive = routines.filter((r) => r.id === newRoutine.id)[0];
    let routineToMakeInactive = routines.filter((r) => r.is_active === true)[0];
    if (routineToMakeActive) {
      routines[routines.indexOf(routineToMakeActive)].is_active = true;
    }
    if (routineToMakeInactive) {
      routines[routines.indexOf(routineToMakeInactive)].is_active = false;
    }
    this.setState({ practiceRoutines: routines });
  };

  updateRoutineTitle = (routineId, routineTitle) => {
    if (routineId && routineTitle) {
      let routines = [...this.state.practiceRoutines];
      let routineToEdit = routines.filter((r) => r.id === routineId)[0];
      if (routineToEdit) {
        routineToEdit.title = routineTitle;
        this.setState({ practiceRoutines: routines });
      }
    }
  };

  tabs = () => {
    return PracticeConstants.TAB_ITEMS.map((tab, index) => {
      return (
        <TabItem
          key={tab.tabName}
          active={this.isActiveTab(tab.tabName)}
          tabName={tab.tabName}
          setActiveTab={() => this.setActiveTab(tab.tabName)}
          displayName={tab.displayName}
          mobileIcon={tab.mobileIcon}
        />
      );
    });
  };

  noItemText = () => {
    let { activeRoutine } = this.state;
    if (activeRoutine && activeRoutine.items.length === 0) {
      return `Your current routine, ${activeRoutine.title}, does not have any active items. You can add items in the Routines tab.`;
    } else {
      return "You don't have an active routine. Please set one in the Routines tab.";
    }
  };
  showItemSave = () => {
    this.setState({ activeTab: "practice" });
  };

  isActiveTab = (tabItem) => {
    if (!this.state.activeTab && tabItem == "about") {
      return true;
    } else {
      return this.state.activeTab === tabItem ? true : false;
    }
  };

  render() {
    return (
      <div className="dashboard__content__tabs">
        <div className="tab-nav">{this.tabs()}</div>
        <div className="tab-container">
          <div className="tab-content">
            {this.state.errorMessage && <p className="has-errors">{this.state.errorMessage}</p>}

            <div className={(this.isActiveTab("about") ? "active" : "") + " tab-pane about-tab"}>
              <AboutPracticeAssistant />
            </div>

            <div
              className={(this.isActiveTab("practice") ? "active" : "") + " tab-pane practice-tab"}
            >
              {this.isActiveTab("practice") && this.state.activeRoutine ? (
                <PlayContainer
                  user={this.props.user}
                  practiceItems={this.state.practiceItems}
                  activeRoutine={this.state.activeRoutine}
                  saveEditedOrder={this.saveEditedOrder}
                  completedItems={this.state.completedItems}
                  practiceSessions={this.state.practiceSessions}
                  updateNoteInPracticeItem={this.updateNoteInPracticeItem}
                  saveMetronomeSpeed={this.handleUpdatedItem}
                  updateStatsAndPracticeSessions={this.updateStatsAndPracticeSessions}
                  updateFinishedPracticeSession={this.updateFinishedPracticeSession}
                  updateStatItem={this.updateStatItem}
                  adminIntroduction={this.props.adminIntroductions["practice"]}
                  resetCompletedItems={this.resetCompletedItems}
                  practiceSessionItems={this.state.practiceSessionItems}
                />
              ) : this.isActiveTab("practice") ? (
                <div className="routine__container">
                  <div className="routine__inner ">
                    <div className="routine__column routine__inner--individual-item admin__introduction">
                      <h4>{this.noItemText()}</h4>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div
              className={
                (this.isActiveTab("routines") ? "active" : "") + " tab-pane view-practice-routine"
              }
            >
              {this.state.activeTab === "routines" && (
                <RoutineContainer
                  routines={this.state.practiceRoutines}
                  inactiveRoutines={this.state.inactiveRoutines}
                  activeRoutine={this.state.activeRoutine}
                  practiceItems={this.state.practiceItems}
                  changeActiveRoutine={this.changeActiveRoutine}
                  updateActiveRoutine={this.updateActiveRoutine}
                  removeRoutineById={this.removeRoutineById}
                  handleNewRoutine={this.handleNewRoutine}
                  updateRoutineTitle={this.updateRoutineTitle}
                  adminIntroduction={this.props.adminIntroductions["routines"]}
                  handleChangedItemsWithinRoutine={this.handleChangedItemsWithinRoutine}
                />
              )}
            </div>

            <div
              className={(this.isActiveTab("items") ? "active" : "") + " tab-pane practice-items"}
            >
              <PracticeItemsContainer
                practiceItems={this.state.practiceItems}
                handleUpdatedItem={this.handleUpdatedItem}
                handleArchivedItem={this.handleArchivedItem}
                handleCopiedItem={this.handleNewItem}
                handleRestoredItem={this.handleRestoredItem}
                handleDeletedItem={this.handleDeletedItem}
                handleNewItem={this.handleNewItem}
                adminIntroduction={this.props.adminIntroductions["items"]}
              />
            </div>

            <div
              className={(this.isActiveTab("stats") ? "active" : "") + " tab-pane practice-stats"}
            >
              {this.isActiveTab("stats") && (
                <StatisticsContainer
                  user={this.props.user}
                  handleUpdatedStatItem={this.updateStatItem}
                  updateFilteredStatistics={this.updateFilteredStatistics}
                  handleDeletedStatItem={this.handleDeletedStatItem}
                  items={this.state.practiceSessionItems}
                  adminIntroduction={this.props.adminIntroductions["stats"]}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
