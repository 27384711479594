import React from "react";
import DashboardCardList from "../components/DashboardCardList";

export default class DashboardCardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items,
    };
  }

  onCardDelete = (itemableId) => {
    let currentItems = this.state.items;
    let filtered = currentItems.filter((i) => i.user_item_id != parseInt(itemableId));
    this.setState({ items: filtered });
  };

  componentWillMount() {
    let order = this.props.groupOrder;
    let items = this.props.items;
    let orderedItems = [];
    let unorderedItems = [];
    this.props.items.map((item) => {
      if (order && order.includes(item.group.item_id)) {
        orderedItems.push(item);
      } else {
        unorderedItems.push(item);
      }
    });

    if (order && orderedItems.length > 0) {
      orderedItems = orderedItems.sort(
        (a, b) => order.indexOf(a.group.item_id) > order.indexOf(b.group.item_id)
      );
    }
    if (unorderedItems.length > 0) {
      unorderedItems = unorderedItems.sort((a, b) => a.group.title.localeCompare(b.group.title));
    }
    let allItems = orderedItems.concat(unorderedItems);
    this.setState({ items: allItems });
  }

  render() {
    return (
      <div>
        {this.state.items &&
          this.state.items.map((item) => (
            <DashboardCardList
              key={item.user_item_id}
              item={item}
              status={this.props.status}
              onCardDelete={this.onCardDelete}
            />
          ))}
      </div>
    );
  }
}
