import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { CDN_URL } from "../../components/shared/CdnUrl";
import AppIcons from "../../components/shared/AppIcons";

const defaultHeaderImages = {
  [BootstrapSize.smDevice]: `${CDN_URL}/redesign/app/get-started-lg.png`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/redesign/app/get-started-sm.png`,
};

const BottomSwoosh = ({ onelinkUrl }) => {
  return (
    <div className="bottom-swoosh">
      <Swoosh imgUrl={defaultHeaderImages} id="app-bottom-swoosh">
        <div className="bottom-swoosh__content">
          <h2 className="uppercase bold">get started for free</h2>
          <AppIcons
            black
            onelinkUrl={onelinkUrl}
            analyticsEventPageName="app-page"
            analyticsEventValue="4"
          />
        </div>
      </Swoosh>
    </div>
  );
};

export default BottomSwoosh;
