import React from "react";

const PlayIcon = ({ color = "#b8bec7" }) => {
  return (
    <svg
      width="126"
      height="126"
      viewBox="0 0 126 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 63C14 35.952 35.952 14 63 14C90.048 14 112 35.952 112 63C112 90.048 90.048 112 63 112C35.952 112 14 90.048 14 63ZM53.2021 77.0495C53.2021 80.3456 56.9651 82.2272 59.6021 80.2495L78.3354 66.1995C80.4688 64.5995 80.4688 61.3995 78.3354 59.7995L59.6021 45.7495C56.9651 43.7718 53.2021 45.6533 53.2021 48.9495V77.0495Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="126"
          height="126"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.266667 0 0 0 0 0.329412 0 0 0 0 0.423529 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default PlayIcon;
