import React from "react";
import { BootstrapSize, breakpoints } from "../../components/shared/bootstrap-helpers";

const goToTabDefault = (tabName, setActiveTab) => {
  location.hash = `${tabName.replace(" ", "-")}`;
  setActiveTab(tabName);
};

const Tabs = ({ tabs, activeTab, setActiveTab, goToTab, width, color }) => {
  return (
    <div className="tab-nav">
      {tabs.map((tab, idx) => {
        return (
          <button
            key={idx}
            className={
              activeTab === tab["name"] || (tab.nestedTabs && tab.nestedTabs.includes(activeTab))
                ? "active"
                : ""
            }
            style={color ? { color } : null}
            onClick={() =>
              goToTab ? goToTab(tab["name"]) : goToTabDefault(tab["name"], setActiveTab)
            }
          >
            {width > breakpoints[BootstrapSize.lgDevice] ? tab["name"] : tab["nameShort"]}
            <hr style={color ? { borderTopColor: color } : null} />
          </button>
        );
      })}
    </div>
  );
};

export default Tabs;
