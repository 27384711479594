import { Box, Typography } from "@mui/material";
import React from "react";
import { SECTIONS } from "./NewRegistration";
import { OrangeLink } from "../../../jg-material/theme/components/styled/Links";

const AppDisclaimerLink = ({ setActiveSection }) => {
  return (
    <Box sx={{ marginTop: "10px", textAlign: "center" }}>
      <Typography variant="body1" fontWeight={"bold"}>
        🚨 Coming from my Lessons & Songs App?{" "}
        <OrangeLink onClick={() => setActiveSection(SECTIONS.app)}> Read this!</OrangeLink>
      </Typography>
    </Box>
  );
};

export default AppDisclaimerLink;
