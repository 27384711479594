export enum CountryOfResidence {
  "United States" = "United States",
  "United Kingdom" = "United Kingdom",
  "Canada" = "Canada",
  "Australia" = "Australia",
  "Germany" = "Germany",
  "India" = "India",
  "Netherlands" = "Netherlands",
  "France" = "France",
  "China" = "China",
  "Italy" = "Italy",
  "Spain" = "Spain",
  "New Zealand" = "New Zealand",
  "Sweden" = "Sweden",
  "Brazil" = "Brazil",
  "Ireland" = "Ireland",
  "Poland" = "Poland",
  "Belgium" = "Belgium",
  "Philippines" = "Philippines",
  "Singapore" = "Singapore",
  "Denmark" = "Denmark",
  "Turkey" = "Turkey",
  "Switzerland" = "Switzerland",
  "Finland" = "Finland",
  "Greece" = "Greece",
  "Norway" = "Norway",
  "Indonesia" = "Indonesia",
  "Hong Kong" = "Hong Kong",
  "Austria" = "Austria",
  "Israel" = "Israel",
  "Russia" = "Russia",
  "South Africa" = "South Africa",
  "Portugal" = "Portugal",
  "Mexico" = "Mexico",
  "Malaysia" = "Malaysia",
  "Romania" = "Romania",
  "Czechia" = "Czechia",
  "Japan" = "Japan",
  "Vietnam" = "Vietnam",
  "Hungary" = "Hungary",
  "Thailand" = "Thailand",
  "Argentina" = "Argentina",
  "Croatia" = "Croatia",
  "Serbia" = "Serbia",
  "United Arab Emirates" = "United Arab Emirates",
  "Taiwan" = "Taiwan",
  "Chile" = "Chile",
  "South Korea" = "South Korea",
  "Bangladesh" = "Bangladesh",
  "Nigeria" = "Nigeria",
  "Bulgaria" = "Bulgaria",
  "Others" = "Others",
}
