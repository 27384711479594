import { createSelector } from "@reduxjs/toolkit";

import {
  filters as availableFilters,
  filterOptionToQueryValue,
  filterTypeToQueryValue,
} from "../../../shared/search";

export const selectSearchState = (state) => state;

export const selectSearchQuery = createSelector(selectSearchState, (state) => state.query);

export const selectAdvancedSearchQuery = createSelector(
  selectSearchState,
  (state) => state.advancedQuery
);

export const selectSearchFilters = createSelector(selectSearchState, (state) => state.filters);

export const selectHasActiveFilters = createSelector(
  selectSearchFilters,
  (filters) => filters != null && Object.keys(filters).length > 0
);

export const selectSearchFiltersAsQueryString = createSelector(
  selectSearchFilters,
  (activeFilters) => {
    if (Object.keys(activeFilters).length === 0) {
      return "";
    }

    return availableFilters
      .map(({ type, options }) => {
        const typeQueryStringVal = filterTypeToQueryValue[type];

        return typeQueryStringVal == null
          ? ""
          : options
              .filter((option) => activeFilters[option] != null)
              .map((option) => filterOptionToQueryValue[option])
              .filter((option) => option != null)
              .map((option) => `${typeQueryStringVal}[]=${option}`)
              .join("&");
      })
      .join("&");
  }
);

export const selectVisibleTypeQueryString = createSelector(selectSearchState, ({ visibleType }) =>
  visibleType == null ? "" : `type=${visibleType}`
);

export const selectAdvancedSearchUrl = createSelector(
  selectSearchQuery,
  selectSearchFiltersAsQueryString,
  selectVisibleTypeQueryString,
  (query, filtersQueryString, visibleTypeQueryString) => {
    const url = `/advanced_search?q=${query}`;
    const allFilters = [filtersQueryString, visibleTypeQueryString]
      .filter((str) => str.length > 0)
      .join("&");
    return allFilters.length > 0 ? `${url}&${allFilters}` : url;
  }
);

export const selectBasicSearchUrl = createSelector(
  selectSearchQuery,
  selectSearchFiltersAsQueryString,
  (query, filtersQueryString) => {
    const url = `/search/${query}.json`;
    return filtersQueryString.length ? `${url}?${filtersQueryString}` : url;
  }
);
