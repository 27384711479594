import React from "react";
import { BUTTON_TEXT } from "./Competition";
import CompetitionFormButton from "./CompetitionFormButton";

const STEPS = [
  "Enter your first name",
  "Choose your mobile device’s operating system - iOS or Android.",
  "Tell me in one short sentence why you love playing the guitar.",
];

const HowToEnter = ({ user, handleSubmission, hasSubmitted }) => {
  return (
    <div className="competition__entry">
      <h2 className="header2 uppercase">How To Enter the Draw</h2>

      <p>
        All you need is a <strong>JustinGuitar Account</strong>. If you haven’t already, sign-up
        <a href="/users/sign_up" style={{ color: "#fd6a02" }}>
          {" "}
          here
        </a>{" "}
        - it’s fast & free! Once you are logged in, click the button below and follow these three
        easy steps:
      </p>

      <div className="competition__steps">
        {STEPS.map((step, idx) => {
          return (
            <div className="competition__step" key={idx}>
              <div className="competition__number">{idx + 1}</div>
              <div className="competition__requirement">{step}</div>
            </div>
          );
        })}
      </div>

      <CompetitionFormButton
        handleSubmission={handleSubmission}
        hasSubmitted={hasSubmitted}
        user={user}
        text={BUTTON_TEXT}
      />
    </div>
  );
};

export default HowToEnter;
