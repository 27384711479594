import React from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { DEFAULT_THUMB } from "../../components/shared/CdnUrl";
import { connect } from "react-redux";
import { openDialog } from "../../components/shared/ui/uiSlice";
import Dialog from "../../components/shared/ui/Dialog";
import QuizCard from "./QuizCard";

const LessonsWithinModuleList = ({
  isLocked,
  lessonListOpen,
  lessonOrder,
  lessons,
  title,
  currentUser = null,
  openDialog,
  completedLessonsIds,
  hasPMTEnrollment,
  quiz,
}) => {
  const orderedLessons = () => {
    return [...lessons].sort((a, b) => {
      return lessonOrder.indexOf(parseInt(a.id)) < lessonOrder.indexOf(parseInt(b.id))
        ? -1
        : lessonOrder.indexOf(parseInt(a.id)) > lessonOrder.indexOf(parseInt(b.id))
        ? 1
        : 0;
    });
  };

  const displayThumb = (image, thumbnails, vimeoThumbnail) => {
    if (image?.length > 0) {
      return image;
    } else if (thumbnails && thumbnails["default"]) {
      return thumbnails["default"];
    } else if (vimeoThumbnail?.length > 0) {
      return vimeoThumbnail;
    } else {
      return DEFAULT_THUMB;
    }
  };

  const orderedLessonsList = (isLocked) => {
    return orderedLessons().map((lesson) => {
      const { title, slug, youtubeDuration, thumbnails, image, vimeoThumbnail, id } = lesson;
      return (
        <div className={"pglesson " + (isLocked ? "pglesson--locked" : "")} key={title}>
          <div className="lazyload-wrapper">
            <a href={isLocked ? "#" : `/guitar-lessons/${slug}`}>
              <div
                className="pglesson__thumb"
                style={{
                  background: `url('${displayThumb(image, thumbnails, vimeoThumbnail)}')`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  opacity: isLocked ? "0.5" : "1",
                }}
              ></div>
              {completedLessonsIds && completedLessonsIds.includes(parseInt(id)) && (
                <i className="icon icon icon-check_circle_outline" />
              )}
            </a>
          </div>

          <div className="pglesson__details">
            {isLocked ? (
              <span>
                <i className="icon icon-lock"></i>
                {title}
              </span>
            ) : (
              <a href={`/guitar-lessons/${slug}`}>{title}</a>
            )}

            <span>{youtubeDuration}</span>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <CSSTransition
        key="lessonList"
        in={lessonListOpen}
        timeout={200}
        classNames="pg-lessons-list-animation"
      >
        <div>
          <div className="pgcard__lessons">
            <p>Lessons in {title}</p>

            {orderedLessonsList(isLocked)}

            <QuizCard
              quiz={quiz}
              currentUser={currentUser}
              openDialog={openDialog}
              isLocked={isLocked}
              hasPMTEnrollment={hasPMTEnrollment}
            />
          </div>
        </div>
      </CSSTransition>
      <Dialog onConfirm={() => (window.location.href = "/store/practical-music-theory")} />
    </>
  );
};

LessonsWithinModuleList.propTypes = {
  lessons: PropTypes.array.isRequired,
  isLocked: PropTypes.bool.isRequired,
  lessonListOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  group: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  openDialog: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  openDialog: (text) => dispatch(openDialog(text)),
});

export default connect(null, mapDispatchToProps)(LessonsWithinModuleList);
