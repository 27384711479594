import { fontFamilies, fontWeights } from "../../typography";
import { Interpolation } from "@mui/system";
import { jgDenimColors } from "../../jg-denim-colors";

import { ComponentsPropsList, Theme } from "@mui/material";
import { breakpoints } from '../../breakpoints';

declare module "@mui/material/Typography" {
  export interface TypographyPropsVariantOverrides {
    "hero-heading": true;
    "hero-heading-responsive": true;
    "h1-responsive": true;
    h7: true;
    h8: true;
  }
}

type HeadingOptions = {
  props: Partial<ComponentsPropsList["MuiTypography"]>;
  style: Interpolation<{ theme: Theme }>;
};

export const heroHeading: HeadingOptions = {
  props: { variant: "hero-heading" },
  style: {
    fontFamily: fontFamilies.montserrat,
    fontWeight: fontWeights.bold,
    fontSize: 68,
    color: jgDenimColors.white,
    textTransform: "uppercase",
  },
};

export const heroHeadingResponsive: HeadingOptions = {
  props: { variant: "hero-heading-responsive" },
  style: {
    ...(heroHeading.style as object),
    [`@media (max-width: ${breakpoints?.values?.xl}px)`]: {
      fontSize: 38,
    },
  }
}


export const h1: HeadingOptions = {
  props: { variant: "h1" },
  style: {
    fontSize: 32,
    fontWeight: fontWeights.bold,
    fontFamily: fontFamilies.montserrat,
    textTransform: "uppercase",
    letterSpacing: "10%",
    color: jgDenimColors.denim.dark,
  },
};

export const h1Responsive: HeadingOptions = {
  props: { variant: "h1-responsive" },
  style: {
    ...(h1.style as object),
    [`@media (max-width: ${breakpoints?.values?.lg}px)`]: {
      fontSize: 20,
    }
  }
};

export const h2: HeadingOptions = {
  props: { variant: "h2" },
  style: {
    fontSize: 26,
    fontWeight: fontWeights.bold,
    fontFamily: fontFamilies.montserrat,
    textTransform: "uppercase",
    color: jgDenimColors.denim.dark,
  },
};

export const h3: HeadingOptions = {
  props: { variant: "h3" },
  style: {
    fontSize: 22,
    fontWeight: fontWeights.bold,
    fontFamily: fontFamilies.montserrat,
    textTransform: "uppercase",
    color: jgDenimColors.denim.dark,
  },
};

export const h4: HeadingOptions = {
  props: { variant: "h4" },
  style: {
    fontSize: 22,
    fontWeight: fontWeights.bold,
    fontFamily: fontFamilies.openSans,
    color: jgDenimColors.denim.dark,
    letterSpacing: 0,
  },
};

export const h5: HeadingOptions = {
  props: { variant: "h5" },
  style: {
    fontSize: 18,
    fontWeight: fontWeights.bold,
    fontFamily: fontFamilies.montserrat,
    color: jgDenimColors.denim.dark,
  },
};

export const h6: HeadingOptions = {
  props: { variant: "h6" },
  style: {
    fontSize: 18,
    fontWeight: fontWeights.bold,
    fontFamily: fontFamilies.openSans,
    color: jgDenimColors.denim.dark,
  },
};

export const h7: HeadingOptions = {
  props: { variant: "h7" },
  style: {
    fontSize: 16,
    fontWeight: fontWeights.bold,
    fontFamily: fontFamilies.montserrat,
    textTransform: "uppercase",
    color: jgDenimColors.denim.dark,
  },
};

export const h8: HeadingOptions = {
  props: { variant: "h8" },
  style: {
    fontSize: 16,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamilies.openSans,
    textTransform: "uppercase",
    color: jgDenimColors.denim.dark,
    display: "block",
    marginBottom: "10px",
  },
};
