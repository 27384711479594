import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withInnerWidth } from "../WithInnerWidth";
import { toggleMobileContextMenuVisibility } from "./uiSlice";

const MenuArrow = () => (
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.51305e-06 2.56611C1.58132e-06 1.00425 1.7102 0.0451178 3.0429 0.85955L15.2074 8.29344C16.4836 9.0733 16.4836 10.9267 15.2074 11.7066L3.0429 19.1405C1.71019 19.9549 7.94888e-07 18.9957 8.63159e-07 17.4339L1.51305e-06 2.56611Z"
      fill="white"
    />
  </svg>
);

const MobileContextMenu = ({
  children,
  visible,
  toggleMobileContextMenuVisibility,
  width,
  floating = false,
  anchor = null,
}) => {
  const [floatingPosition, setFloatingPosition] = useState(null);

  useEffect(() => {
    window.addEventListener("click", onPageClick);

    return () => {
      window.removeEventListener("click", onPageClick);
    };
  }, []);

  useEffect(() => {
    if (visible && floating) {
      if (anchor) {
        setFloatingPosition([
          `${anchor.getBoundingClientRect().top - 20}px`,
          `${anchor.getBoundingClientRect().left - 310}px`,
        ]);
        document.body.style.overflow = "hidden";
      } else {
        setFloatingPosition(["50%", "calc(50% - 150px)"]);
      }
    } else {
      setFloatingPosition(null);
      document.body.style.overflow = null;
    }
  }, [visible]);

  useEffect(() => {
    toggleMobileContextMenuVisibility(false);
  }, [width]);

  const onPageClick = (e) => {
    if (
      !e.target.closest(".mobile-context-menu") &&
      !e.target.closest(".mobile-context-menu-toggle-btn")
    ) {
      toggleMobileContextMenuVisibility(false);
      setFloatingPosition(null);
    }

    if (e.target.closest(".mobile-context-menu-btn")) {
      toggleMobileContextMenuVisibility(false);
    }
  };

  return (
    <div
      className={`mobile-context-menu-wrapper ${visible ? "visible" : ""} ${
        floating ? "floating" : ""
      }`}
    >
      <div
        style={
          floatingPosition
            ? { top: `${floatingPosition[0]}`, left: `${floatingPosition[1]}` }
            : null
        }
        className="mobile-context-menu"
      >
        <MenuArrow />
        {children}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  visible: state.ui.mobileContextMenu.visible,
});

const mapDispatchToProps = (dispatch) => ({
  toggleMobileContextMenuVisibility: (visible) =>
    dispatch(toggleMobileContextMenuVisibility(visible)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withInnerWidth(MobileContextMenu));
