import React from "react";
import PropTypes from "prop-types";

import { storeSearchAndNavigateToItem } from "../../shared/search";
import { removeHtmlTagsAndUnescape } from "../../shared/removeHtmlTagsAndUnescape";

export const SearchResultItem = ({ item, query }) => {
  const { title, grade_id } = item;
  const wordMatches = query.toLowerCase().trim().split(" ");
  const regex = new RegExp(wordMatches.join("|"), "gi");
  const updated = title.replace(regex, (matched) => `<span class='bold'>${matched}</span>`);

  return (
    <div className="search-result-item search-result-item--standard">
      <div
        className="search-result-item__title-section"
        onClick={() => storeSearchAndNavigateToItem(item)}
      >
        <span className="title" dangerouslySetInnerHTML={{ __html: updated }} />
        {grade_id != null && (
          <span className={`grade-badge grade-badge--grade-${grade_id}`}>
            {parseInt(grade_id) === 10 ? "All Levels" : `Grade ${grade_id}`}
          </span>
        )}
      </div>
      <div className="search-result-item__subtitle">
        {removeHtmlTagsAndUnescape(item.meta_desc)}
      </div>
    </div>
  );
};

SearchResultItem.propTypes = {
  query: PropTypes.string.isRequired,
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default SearchResultItem;
