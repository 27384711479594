import { PaletteOptions } from "@mui/material";

import { jgDenimColors } from "./jg-denim-colors";

const { denim, action, accent } = jgDenimColors;

export const palette: PaletteOptions = {
  text: {
    primary: denim.dark,
    secondary: denim.main,
    disabled: action.denim.disabled,
  },
  primary: denim,
  secondary: { main: accent.orange.main, dark: accent.orange.hover, light: accent.orange.light },
  success: {
    main: action.success.default,
  },
  error: { main: action.error.default },
};
