import React from "react";

export const fileTypes = {
  blank: "blank",
  gp: "gp",
  gpx: "gpx",
  mid: "mid",
  mp3: "mp3",
  pdf: "pdf",
  tsl: "tsl",
  zip: "zip",
};

export default ({ fileType }) => (
  <div className={`file-icon file-icon--${fileType}`}>
    <div className={`icon icon-doc-${fileType}`}>
      {Array(8)
        .fill(null)
        .map((_, i) => (
          <span key={i} className={`path${i + 1}`} />
        ))}
    </div>
  </div>
);
