import React from "react";
import PropTypes from "prop-types";
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from "./icons";
import { CURRENCY } from "./TabsPlanSelect";

const BestOfferBadge = ({ plan }) => {
  if (plan.attributes.intervalInMonths === 12) {
    return (
      <div className="badges">
        <div className="badge">best offer</div>
      </div>
    );
  }

  return null;
};

const subText = (plan) => {
  if (plan.attributes.intervalInMonths === 12) {
    const monthlyEquivalent = (plan.attributes.price / 12).toFixed(2);
    return `Billed annually. Equivalent to ${CURRENCY}${monthlyEquivalent} monthly.`;
  }

  return "Billed monthly. Cancel it anytime.";
};

const PlanTile = ({ plan, selectedPlan, setSelectedPlan }) => {
  return (
    <button
      className={`${selectedPlan === plan ? "active" : null}`}
      onClick={() => setSelectedPlan(plan)}
    >
      {selectedPlan === plan ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}

      <BestOfferBadge plan={plan} />

      <p>{plan.attributes.intervalInMonths === 1 ? "Monthly" : "Yearly"} Subscription*</p>
      <p>
        <span className="actual-price">
          {CURRENCY}
          {plan.attributes.price}
        </span>
      </p>

      <p>{subText(plan)}</p>
    </button>
  );
};

const PricingPlans = ({ selectedPlan, setSelectedPlan, pricingPlans }) => {
  return (
    <div id="plans" className="tabs-payment__trial__plan">
      {pricingPlans.map((plan) => {
        return (
          <PlanTile
            key={plan.id}
            plan={plan}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
          />
        );
      })}
    </div>
  );
};

PricingPlans.propTypes = {
  selectedPlan: PropTypes.object.isRequired,
  setSelectedPlan: PropTypes.func.isRequired,
  pricingPlans: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PricingPlans;
