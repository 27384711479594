const PracticeConstants = {
  ITEM_STATUS: {
    active: "active",
    in_active: "in_active",
    archived: "archived",
  },
  TAB_ITEMS: [
    { tabName: "practice", displayName: "Practice", mobileIcon: "play3" },
    { tabName: "routines", displayName: "Routines", mobileIcon: "equalizer" },
    { tabName: "items", displayName: "Items", mobileIcon: "plus-circle" },
    { tabName: "stats", displayName: "Stats", mobileIcon: "stats-dots" },
    { tabName: "about", displayName: "About", mobileIcon: "info" },
  ],
};
export default PracticeConstants;
