import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { RECAPTCHA_KEY, CVC_TOOLTIP } from "../components/new-payments/consts";
import { BASE_FORM_STYLE } from "../components/new-payments/styles";
import axiosWithCSRF from "../components/shared/axiosWithCSRF";
import Loading from "../components/shared/Loading";
import withStripe from "./withStripe";
import withStripeElements from "./withStripeElements";
import { INTENT_TYPES } from "./consts";
import Disclaimer from "./Disclaimer";
import { Tooltip } from "@mui/material";

// TODO: use this component in tabs checkout with these urls:
// const SUBSCRIPTION_URL = "/payments/tabs/subscriptions";
// const CONFIRMATION_URL = "/payments/tabs/stripe_intent_confirmations";
const FORM_MIN_WIDTH_SM = "500px";
const FORM_MIN_WIDTH_XS = "350px";

const CheckoutForm = ({
  disclaimerElement,
  selectedPlan,
  setPaymentSuccess,
  websiteProductId,
  subscriptionUrl,
  confirmationUrl,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [cardReady, setCardReady] = useState(false);
  useEffect(() => {
    if (recaptchaToken) {
      handleSubmit();
    }
  }, [recaptchaToken]);

  const recaptchaRef = useRef(null);

  const FALLBACK_ERROR_MESSAGE =
    "There was a problem processing your payment. Please contact hello@justinguitar.com if the problem persists.";

  const executeRecaptcha = (event) => {
    event.preventDefault();
    setLoading(true);
    recaptchaRef.current.execute();
  };

  const handleSubmit = async () => {
    const card = elements.getElement(CardElement);
    stripe.createToken(card).then(function (result) {
      if (result.error) {
        setErrorMessage(result.error.message);
      } else {
        axiosWithCSRF()
          .post(subscriptionUrl, {
            card_token: { id: result.token.id },
            plan: { id: selectedPlan.id },
            website_product: { id: websiteProductId },
            recaptcha_token: recaptchaToken,
          })
          .then((response) => {
            const { nextAction } = response.data.intent.data.attributes;

            if (nextAction) {
              handleConfirmation(response.data.intent);
            } else {
              setLoading(false);
              setPaymentSuccess(true);
            }
          })
          .catch((error) => {
            const errorMessage = error?.response?.data?.error?.message || FALLBACK_ERROR_MESSAGE;
            handleError(errorMessage);
          });
      }
    });
  };

  const handleError = (message) => {
    setLoading(false);
    setErrorMessage(message);
    resetRecaptcha();
  };

  const handleConfirmation = async (intent) => {
    const { clientSecret, intentType } = intent.data.attributes;
    const result =
      intentType === INTENT_TYPES.setupIntent
        ? await stripe.confirmCardSetup(clientSecret)
        : await stripe.confirmCardPayment(clientSecret);

    if (result.error) {
      handleError(result.error.message);
    } else {
      // result will either be { paymentIntent: {...} } or { setupIntent: {...}}
      const intentId = Object.values(result)[0]["id"];

      axiosWithCSRF()
        .post(confirmationUrl, {
          intent_id: intentId,
          intent_type: intentType,
          plan: { id: selectedPlan.id },
          website_product: { id: websiteProductId },
        })
        .then(() => {
          setLoading(false);
          setPaymentSuccess(true);
        })
        .catch((error) => {
          const errorMessage = error?.response?.data?.error?.message || FALLBACK_ERROR_MESSAGE;

          handleError(errorMessage);
        });
    }
  };

  const handleCardChange = (event) => {
    if (event.error) {
      setErrorMessage(event.error.message);
      setCardReady(false);
    } else if (event.complete) {
      setCardReady(true);
    } else {
      setCardReady(false);
      setErrorMessage(null);
    }
  };

  const resetRecaptcha = () => {
    setRecaptchaToken(null);
    const recaptchaInstance = recaptchaRef.current;
    recaptchaInstance.reset();
  };

  return (
    <>
      {loading && <Loading coverViewport />}
      <div className="paywall-form__container payments__v2">
        <form
          onSubmit={executeRecaptcha}
          style={{ minWidth: window.innerWidth >= 576 ? FORM_MIN_WIDTH_SM : FORM_MIN_WIDTH_XS }}
        >
          <div style={{ display: "flex", width: "100%" }}>
            <CardElement
              onChange={handleCardChange}
              options={{ hidePostalCode: true, disableLink: true, style: BASE_FORM_STYLE }}
            />
            <Tooltip arrow enterTouchDelay={0} title={CVC_TOOLTIP}>
              <button
                style={{
                  fontSize: "18px",
                  color: "#406e8e",
                  border: "none",
                  background: "transparent",
                }}
                type="button"
                className="info"
              >
                <i className="icon icon-info"></i>
              </button>
            </Tooltip>
          </div>

          {errorMessage && <p className="error-response">{errorMessage}</p>}

          <ReCAPTCHA
            size="invisible"
            sitekey={RECAPTCHA_KEY}
            onChange={(token) => setRecaptchaToken(token)}
            ref={recaptchaRef}
          />

          <Disclaimer element={disclaimerElement} />

          <button
            className="button button--primary"
            type="submit"
            disabled={!stripe || loading || !cardReady}
            style={{ marginTop: "10px" }}
          >
            Agree and Subscribe
          </button>
        </form>
      </div>
    </>
  );
};

CheckoutForm.propTypes = {
  websiteProductId: PropTypes.number,
  selectedPlan: PropTypes.object.isRequired,
  setPaymentSuccess: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  disclaimerElement: PropTypes.element,
  subscriptionUrl: PropTypes.string.isRequired,
  confirmationUrl: PropTypes.string.isRequired,
};

export default withStripe(withStripeElements(CheckoutForm));
