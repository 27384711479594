import React from "react";
import { productSubtypes, productTypes, productsFilteredByType } from "./productTypes";
import ProductList from "./ProductList";

export default function ({ products }) {
  const jamTracks = productsFilteredByType(products, productTypes.jamTracks);

  const blues = jamTracks.filter(
    (product) => product.data.attributes.subtype === productSubtypes.blues
  );
  const other = jamTracks.filter(
    (product) => product.data.attributes.subtype !== productSubtypes.blues
  );

  return (
    <div className="products-listing products-listing--jam-tracks container">
      <h2>Blues</h2>
      <ProductList products={blues} />

      <h2>Other</h2>
      <ProductList products={other} />
    </div>
  );
}
