import React, { useEffect, useState, useRef } from "react";
import Swoosh from "../components/shared/Swoosh";
import Header from "./swoosh/Header";
import LessonVideoBlock from "./swoosh/LessonVideoBlock";
import LessonsList from "./swoosh/LessonsList";
import { LoginModal } from "../jg-material/auth/LoginModal";
import MarkAsCompleteForLessonsWithNoVideo from "./swoosh/MarkAsCompleteForLessonsWithNoVideo";
import axiosWithCSRF from "../components/shared/axiosWithCSRF";

const LessonHeroNew = ({
  currentUser,
  completedLessonIds,
  savedLessonIds,
  lesson,
  loadingUserItems,
  paywall,
  toggleCompleted,
  toggleFavorite,
  toggleMobileContextMenuVisibility,
  width,
  viewResources,
}) => {
  const [lessonListHeight, setLessonListHeight] = useState("auto");
  const [loginFormOpen, setLoginFormOpen] = useState(false);
  const [quizAttempt, setQuizAttempt] = useState(null);
  const videoRef = useRef();
  const { video, signedInOnly, quiz } = lesson.data.attributes;

  const needsLogin = signedInOnly && !currentUser;

  useEffect(() => {
    watchForHeightChanges();
  }, []);

  useEffect(() => {
    if (videoRef?.current?.clientHeight) {
      setLessonListHeight(videoRef.current.clientHeight);
    }
  }, [videoRef?.current?.clientHeight]);

  useEffect(() => {
    if (currentUser && lesson.data.attributes.quiz) {
      fetchQuizAttempt();
    }
  }, []);

  function fetchQuizAttempt() {
    axiosWithCSRF()
      .get(`/users/${currentUser.data.id}/quiz_attempts?quiz_id=${quiz.id}`)
      .then((response) => {
        setQuizAttempt(response.data.data);
      });
  }

  function watchForHeightChanges() {
    if (typeof ResizeObserver != "undefined") {
      const lessonObserver = new ResizeObserver((entries) => {
        const lessonEntry = entries[0];
        const contentBoxSize = Array.isArray(lessonEntry.contentBoxSize)
          ? lessonEntry.contentBoxSize[0]
          : lessonEntry.contentBoxSize;
        if (videoRef.current && videoRef.current.clientHeight != contentBoxSize) {
          setLessonListHeight(videoRef.current.clientHeight);
        }
      });
      if (videoRef?.current) {
        lessonObserver.observe(videoRef.current);
      }
    }
  }

  return (
    <div className="video-swoosh">
      <Swoosh color="#406e8e">
        <div className="container">
          <div className="video-swoosh__main">
            <Header
              currentUser={currentUser}
              lesson={lesson}
              toggleMobileContextMenuVisibility={toggleMobileContextMenuVisibility}
            />
            <div className="flex align-start">
              <LessonVideoBlock
                currentUser={currentUser}
                lesson={lesson}
                paywall={paywall}
                completedLessonIds={completedLessonIds}
                videoRef={videoRef}
                toggleCompleted={toggleCompleted}
                quizAttempt={quizAttempt}
                toggleLoginModal={() => setLoginFormOpen(!loginFormOpen)}
                viewResources={viewResources}
              />
              <div className="video-swoosh__content">
                <LessonsList
                  currentLesson={lesson}
                  currentUser={currentUser}
                  lessonListHeight={lessonListHeight}
                  loadingUserItems={loadingUserItems}
                  completedLessonIds={completedLessonIds}
                  savedLessonIds={savedLessonIds}
                  toggleFavorite={toggleFavorite}
                  toggleCompleted={toggleCompleted}
                  toggleLoginModal={() => setLoginFormOpen(!loginFormOpen)}
                  quizAttempt={quizAttempt}
                />
              </div>

              {!needsLogin && !paywall && !video && (
                <MarkAsCompleteForLessonsWithNoVideo
                  lesson={lesson}
                  toggleCompleted={toggleCompleted}
                  completedLessonIds={completedLessonIds}
                  width={width}
                />
              )}
            </div>
          </div>
        </div>

        <LoginModal
          isOpen={loginFormOpen}
          token={ReactOnRails.authenticityToken()}
          handleClose={() => setLoginFormOpen(false)}
        />
      </Swoosh>
    </div>
  );
};

export default LessonHeroNew;
