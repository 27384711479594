import React from "react";
import Slider from "react-slick";
import { CDN_URL } from "../components/shared/CdnUrl";

const endorsementTypes = {
  CELEBRITY: "celebs",
  USER: "users",
};

export default class Endorsements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      endorsementType: endorsementTypes.CELEBRITY,
      [`${endorsementTypes.CELEBRITY}Idx`]: 0,
      [`${endorsementTypes.USER}Idx`]: 0,
    };
  }

  handleCheckedChange = (event) => {
    const endorsementType = event.target.checked
      ? endorsementTypes.USER
      : endorsementTypes.CELEBRITY;

    this.setState({ endorsementType });
  };

  get currentIdx() {
    return this.state[`${this.state.endorsementType}Idx`];
  }

  set currentIdx(idx) {
    this.setState({ [`${this.state.endorsementType}Idx`]: idx });
  }

  render() {
    return (
      <div className="endorsements">
        <h2
          className="uppercase"
          style={
            {
              /*marginBottom: "20px"*/
            }
          }
        >
          Some of the greatest RECOMMEND JustinGuitar!
        </h2>
        {/*<h3 style={{ textAlign: "center", margin: "0 auto 50px auto" }}>*/}
        {/*  Some of the greatest guitarists support JustinGuitar!*/}
        {/*</h3>*/}

        <div className="quote-mark" />

        {/*<div className="toggle">*/}
        {/*  <input*/}
        {/*    type="checkbox"*/}
        {/*    id="endorsement-toggle"*/}
        {/*    checked={this.state.endorsementType === endorsementTypes.USER}*/}
        {/*    onChange={this.handleCheckedChange}*/}
        {/*  />*/}
        {/*  <label htmlFor="endorsement-toggle">*/}
        {/*    <div*/}
        {/*      className="switch"*/}
        {/*      data-checked={endorsementTypes.USER}*/}
        {/*      data-unchecked={endorsementTypes.CELEBRITY}*/}
        {/*    />*/}
        {/*  </label>*/}
        {/*</div>*/}

        {this.state.endorsementType === endorsementTypes.CELEBRITY ? (
          <CelebsCarousel
            initialSlide={this.currentIdx}
            setSlide={(idx) => (this.currentIdx = idx)}
          />
        ) : (
          <UsersCarousel
            initialSlide={this.currentIdx}
            setSlide={(idx) => (this.currentIdx = idx)}
          />
        )}
      </div>
    );
  }
}

const CelebsCarousel = ({ initialSlide, setSlide }) => {
  return (
    <Slider {...celebCarouselSettings} initialSlide={initialSlide} afterChange={setSlide}>
      {celebrityEndorsements.map((endorsement) => (
        <CelebrityEndorsement key={endorsement.title} endorsement={endorsement} />
      ))}
    </Slider>
  );
};

const UsersCarousel = ({ initialSlide, setSlide }) => {
  return (
    <Slider {...userCarouselSettings} initialSlide={initialSlide} afterChange={setSlide}>
      {userEndorsements.map((endorsement) => (
        <UserEndorsement key={endorsement.name} endorsement={endorsement} />
      ))}
    </Slider>
  );
};

const CelebrityEndorsement = ({ endorsement: { title, quote, links, imageUrl } }) => (
  <div className="endorsement celebrity-endorsement">
    <div className="image">
      <img src={`${CDN_URL}${imageUrl}`} alt="" />
    </div>

    <div className="body">
      <div className="quote">
        <p>"{quote}"</p>
      </div>

      <div>
        <div className="title">{title}</div>

        {links.map(({ href, text }, index) => (
          <a href={href} target="_blank" key={href} style={index > 0 ? { marginLeft: "10px" } : {}}>
            {text}
          </a>
        ))}
      </div>
    </div>
  </div>
);

const UserEndorsement = ({ endorsement: { name, quote, city, yearJoined, imageUrl } }) => (
  <div className="endorsement user-endorsement">
    <div
      className="user-endorsement__image"
      style={{
        background: `url(${CDN_URL}${imageUrl}) no-repeat top center / cover`,
      }}
    />

    <div className="body">
      <div className="quote user-endorsement__quote">
        <p>"{quote}"</p>
      </div>
      <div className="user-endorsement__name">{name}</div>
    </div>
  </div>
);

const celebCarouselSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1350,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
  ],
};

const userCarouselSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  lazyLoad: true,
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
  ],
};

const celebrityEndorsements = [
  {
    title: "Tommy Emmanuel CGP ",
    subtitle: "Legendary Acoustic Guitarist",
    quote:
      "Justin's lessons are so well taught, he has really changed the lives of millions of people all over the world, keep the good songs coming Justin! Your fan, Tommy Emmanuel cgp.",
    links: [
      {
        href: "http://www.tommyemmanuel.com",
        text: "tommyemmanuel.com",
      },
    ],
    imageUrl: "/redesign/2020/home-page/celebs/tommy-emmanuel.png",
  },
  {
    title: "Mark Knopfler",
    subtitle: "Dire Straits and Solo Artist",
    quote: "If you told me you wanted to get going on guitar I'd recommend Justin Sandercoe.",
    links: [
      {
        href: "http://www.markknopfler.com",
        text: "markknopfler.com",
      },
    ],
    imageUrl: "/redesign/2020/home-page/celebs/mark-knopfler.png",
  },
  {
    title: "Sir Brian May CBE",
    subtitle: "Queen",
    quote:
      "On a day when there's a temptation to go into a dark place, and only see all the bad stuff there is in the world ... greed, cruelty, exploitation, selfishness ... I get days like that pretty often .... it's great to find someone giving out, and giving out good, and operating on an honour basis ... There are so many people who can't afford Guitar lessons .... well, here's a wonderful guy who has set up a whole system of teaching guitar ... Blues, Jazz, Rock, even Songwriting, from the basics, tuning the guitar, etc ... upwards ... If you use his site, it's up to you to determine how much you can contribute ... but this is an amazing site .... he is also very aware of issues in the world which need attention ... a great channel .. Check him out. He's a giver.",
    links: [
      {
        href: "http://www.brianmay.com",
        text: "brianmay.com",
      },
    ],
    imageUrl: "/redesign/2020/home-page/celebs/brian-may.png",
  },
  {
    title: "Dr Martin Taylor MBE",
    subtitle: "Jazz Guitar Legend",
    quote:
      "Justin is one of the most impressive guitar teachers on the planet. Whether he's teaching a complete beginner, or helping a more advanced player reach the next level, he knows exactly how to communicate the knowledge he has to share.",
    links: [
      {
        href: "http://www.martintaylor.com",
        text: "martintaylor.com",
      },
    ],
    imageUrl: "/redesign/2020/home-page/celebs/martin-taylor.png",
  },
  {
    title: "Steve Vai",
    subtitle: "Solo Artist / Frank Zappa / David Lee Roth",
    quote:
      "I have checked out Justin's site and found it to be comprehensive and informative. I have always felt that learning about music and especially music theory applied to the guitar, is helpful in finding your own unique voice on the instrument and expanding your creative horizons. Along with his insight into teaching and his fantastic abilities on the instrument, Justin has created a powerful go-to-place for anyone interested in exploring the instrument to their potential. Just don't hurt yourself.",
    links: [
      {
        href: "http://www.vai.com",
        text: "vai.com",
      },
    ],
    imageUrl: "/redesign/2020/home-page/celebs/steve-vai.png",
  },
  {
    title: "Richard Bennett",
    subtitle: "Mark Knopfler Band / Neil Diamond Band",
    quote:
      "There's an abundance of guitar information out there on the web, some good, some not. I stumbled across Justin Sandercoe's site a year ago and now tell everyone about it. The lessons are conveyed so clearly, concisely and in the most congenial way. The site is laid out logically as well so you can to go straight to your area of interest... beginner, blues, rock, folk, jazz, rhythm, fingerpicking... it's all there and more. Spend ten minutes with Justin and you'll not only play better but feel better too. From novice to know-it-all, everyone will learn something from Sandercoe.",
    links: [
      {
        href: "http://www.richard-bennett.com",
        text: "richard-bennett.com",
      },
    ],
    imageUrl: "/redesign/2020/home-page/celebs/richard-bennett.png",
  },
  {
    title: "Les Wise",
    subtitle: "Teaching Legend (Musicians Institute LA)",
    quote:
      "After teaching guitar and music theory to thousands of students over past three decades, I thought that I had basically 'seen it all' when it comes to guitar instruction. Then I discovered Justin's website, and man was I impressed! Justin's caring spirit, attention to detail, vast knowledge base, and especially his lucid, laidback and nurturing style, allow students to fall in love with the learning process. You see, it's not enough to simply find out how to play a few cool licks or chords. A truly great teacher will make you fall in love with the process of discovery so that you can unlock the best within you. Justin is one of these great teachers, and I highly recommend justinguitar.com to anyone who wants to tap into their best selves.",
    links: [
      {
        href: "http://www.thedeliberatemusician.com",
        text: "thedeliberatemusician.com",
      },
    ],
    imageUrl: "/redesign/2020/home-page/celebs/les-wise.png",
  },
  {
    title: "Jim Campilongo",
    subtitle: "Solo Artist / Norah Jones Band",
    quote:
      "Justin is an instructor with that rare combination that encompasses great playing in conjunction with a thoughtful, likable personality. Justin's instruction is extremely intelligent because he's smart enough to know the 'basics' don't have to be served 'raw' - Justin keenly serves the information covered in chocolate. Justin's site is like a free pass in a candy store!",
    links: [
      {
        href: "http://www.jimcampilongo.com",
        text: "jimcampilongo.com",
      },
    ],
    imageUrl: "/redesign/2020/home-page/celebs/jim-campilongo.png",
  },
  {
    title: "Pierre Bensusan",
    subtitle: "Acoustic DADGAD Legend",
    quote:
      "Lucky you, guitar players from all over the world, to take advantage of the tutorials presented in Justin's comprehensive website! Whatever the style you fancy, Justin is there for you with generous and precise guidance to help you enhance your playing and by doing so, introduce you to so many ways of approaching the guitar and discover new artists along the way. I wish there would have been such a medium and dedicated host around when I started to learn how to play. Bravo Justin, and my gratitude for bringing music to the heart and ears of many!",
    links: [
      {
        href: "http://www.pierrebensusan.com",
        text: "pierrebensusan.com",
      },
    ],
    imageUrl: "/redesign/2020/home-page/celebs/pierre-bensusan.png",
  },
  {
    title: "Steve Wozniak",
    subtitle: "Apple Computers co-founder",
    quote:
      "I have heard how giving you are in so many respects of music schooling and I must say that I am impressed. You remind me of the pure idealism that we had in starting Apple. If I were young, with time, I'd likely offer to join and help you in your endeavours. Keep making people happy, not just in their own learning, but in the example you set for them.",
    links: [
      {
        href: "http://www.woz.org",
        text: "woz.org",
      },
    ],
    imageUrl: "/redesign/2020/home-page/celebs/steve-wozniak.png",
  },
  {
    title: "Mike Dawes",
    subtitle: "The Percussive Acoustic Guitarist",
    quote:
      "There is no better all-round guitar tuition resource out there. Justin's transcriptions are the new standard for accuracy.",
    links: [
      {
        href: "http://www.mikedawes.com",
        text: "mikedawes.com",
      },
    ],
    imageUrl: "/redesign/2020/home-page/celebs/mike-dawes.png",
  },
  {
    title: "Brian Fallon",
    subtitle: "The Gaslight Anthem / Solo Artist",
    quote:
      "When I was looking to expand my guitar playing from strumming chords and playing pentatonic boxes to actually learning what notes are where and why JustinGuitar was where I found the lessons I needed to get me going right away.",
    links: [
      {
        text: "The Gaslight Anthem",
        href: "http://www.thegaslightanthem.com/",
      },
      {
        text: "Brian's Facebook Page",
        href: "https://www.facebook.com/thebrianfallon/",
      },
    ],
    imageUrl: "/redesign/2020/home-page/celebs/brian-fallon.png",
  },
];

const userEndorsements = [
  {
    name: "Ian McKenzie",
    imageUrl: "/system/dragonfly/production/2018/04/23/74gzpqooe_IanMcKenzie.jpg",
    quote:
      "I was lucky enough to meet Justin at the Guitar Institute during a summer school in 2004, and to have some private lessons with him afterwards. He was the teacher who kickstarted my guitar career and persuaded me that I was ready to join a band. That was 14 years ago and many dozens of gigs later. I'm now just finishing a degree in Popular Music Performance. Justin's online lessons are easy to follow and he has a manner about him which makes you believe that you can achieve. Where he demonstrates songs, I have found his versions to be consistently more accurate and easy to follow than those of any other online teacher. On this website you really will find all the skills and information you need to become an excellent musician. Many thanks. Ian",
  },
  {
    name: "LievenDV",
    imageUrl: "/system/dragonfly/production/2017/11/14/6sp24cs3lo_lieven.jpg",
    quote:
      "Justinguitar.com really stands out, thanks to the combination of his addicting free lessons, well-defined method and quality material. There are things to be learned for everybody in between absolute beginner and masterful player, whether it's songs, technique, theory or gear",
  },
  {
    name: "Dahn7",
    imageUrl: "/system/dragonfly/production/2017/11/14/7fp53iclg5_dahn.jpg",
    quote:
      "I would especially like to stress the gentle approach Justin takes with two key aspects that contributed to my development as a musician - music theory and ear training. Justin has succeeded in conveying the importance and profoundness of understanding music both theoretically and through your ears while maintaining a simple and accessible approach to them, all while sticking to what is ultimately the most important motto: 'If it sounds good, it is good'",
  },
  {
    name: "Borodog",
    imageUrl: "/system/dragonfly/production/2017/11/14/5hoq36q53f_borodog.jpg",
    quote:
      "I tried several times over the course of 20 years to learn guitar. I purchased guitars, amps, books, private lessons. Nothing ever stuck, until I found justinguitar.com. The only reason I can play guitar today is because of Justin. His courses are well thought out, easy to understand, easy to follow, and easy to make progress on. I can't think of a single product or service that I've ever used in my life that I could recommend more highly than justinguitar.com",
  },
  {
    name: "Hippocripp",
    imageUrl: "/system/dragonfly/production/2017/11/14/1te2h31gy_hippo.jpg",
    quote:
      "Justin, first off I just wanted to say thanks for all the advice and instruction. You make playing guitar easy and understandable and fun. You explain very well the hows and whys when presenting a new song to learn and walk it through so that anyone can learn. I have played guitar for 20 years now and have learned more from you in the last five years than the previous 15 by myself. You have also been a tremendous help to both my son and my daughter in learning to play as well by making playing fun and interesting. I have a couple of pictures of me and my children all doing what we love. Many Thanks to you Justi",
  },
  {
    name: "KTen",
    imageUrl: "/system/dragonfly/production/2017/11/14/rsmubpc5y_kten.jpg",
    quote:
      "A few years back, I dusted off the ol' Takamine I got in high school to try some 'music therapy' with my disabled son, who was recovering from a massive at-birth stroke. This reignited my long dormant passion to transform myself from a beach strummer to a 'real' musician; however, as a single mom, taking in-person lessons was financially difficult. Then I found Justinguitar! Flash forward to today; my son is almost fully recovered (YAY!), my guitar collection has grown significantly, and I'm starting to play gigs. None of this would have been possible without your guidance and generosity, Justin. Thank you for being part of the journey",
  },
  {
    name: "Tim Mason",
    imageUrl: "/system/dragonfly/production/2017/11/14/tim-mason.jpg",
    quote:
      "Justin Sandercoe has thought long and hard about how to teach people to play the guitar, and how to do this over the internet. He has come up with a well-designed series of courses that will take you from nowhere to proficiency. I tried to learn how to play years ago, using books, and got nowhere. I've been using Justin's site for just over a year and I feel I've made real progress. What's more, Justin offers his lessons for free - a boon for any young player who has the urge to play, but whose pockets are empty. I've seen and used other sites for learners: none of them offer as clearly marked a road as Justin does",
  },
];
