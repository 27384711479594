import React from "react";
import { Box, Breadcrumbs, Container, Link, Typography, useMediaQuery } from "@mui/material";

import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { WorkshopEntity } from "../interfaces";
import { getHeaderImage } from "../shared/helpers";

export const WorkshopShowPageHeader = ({ workshop }: { workshop: WorkshopEntity }) => {
  const isSmall = useMediaQuery(jgDenimTheme.breakpoints.down("sm"));
  const headerImage = getHeaderImage(workshop, isSmall ? "small" : "large");
  return (
    <Container>
      <Box position={"relative"}>
        <Box
          sx={{
            width: "100%",
            // height??
            position: "relative",
            borderRadius: "20px",
            borderTopRightRadius: "60px",
            borderBottomLeftRadius: "60px",
          }}
          component="img"
          src={headerImage}
        />
        {/*  TODO: jg breadcrumb component */}
        <Breadcrumbs
          separator="›"
          color={"white"}
          sx={{
            position: "absolute",
            fontSize: "14px !important",
            top: {
              xs: 10,
              md: 20,
            },
            left: {
              xs: 10,
              md: 20,
            },
          }}
        >
          <Link href={"/workshops"} sx={{ color: "#fff" }}>
            Workshops
          </Link>
          <Typography sx={{ color: "#fff", fontWeight: 700 }}>
            {workshop.attributes.title}
          </Typography>
        </Breadcrumbs>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            width: "100%",
            textAlign: "center",
            margin: "0 auto",
            transform: "translateY(-50%)",
          }}
        >
          <Typography
            variant="hero-heading"
            component="h1"
            sx={{
              fontSize: {
                xs: "30px",
                md: "40px",
              },
            }}
          >
            {workshop.attributes.title}
            {workshop.attributes.slug == "2023-Acoustic-Jam-Week" && (
              <p style={{ fontSize: "26px" }}>Quebec 2023</p>
            )}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
