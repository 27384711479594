import React, { useState } from "react";
import SectionHeader from "./SectionHeader";
import { Avatar, Box, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { jgDenimColors } from "../../jg-material/theme/jg-denim-colors";
import { ArrowRightIcons, StarIcon } from "../../components/shared/Icons";
import { CDN_URL } from "../../components/shared/CdnUrl";
import { MONTH_DATA, SECTION_TOP_MARGIN } from "./consts";
import { fontFamilies, fontWeights } from "../../jg-material/theme/typography";
import { withInnerWidth } from "../../components/shared";
import { BootstrapSize, breakpoints } from "../../components/shared/bootstrap-helpers";

const IMAGE_PATH = `${CDN_URL}/images/pages/store/blues-immersion/v2-blim-unit-`;
const FILE_EXTENSION = ".jpg";

const MonthStepper = ({ currentMonth, setCurrentMonth, width }) => {
  if (width < breakpoints[BootstrapSize.smDevice]) {
    return (
      <Box>
        {/* Figma doesn't match the select i*/}
        <Select
          onChange={(e) => setCurrentMonth(e.target.value)}
          variant={"standard"}
          sx={{
            boxShadow: "0px 0px 6px rgba(64, 110, 142, 0.3)",
            borderRadius: "10px",
            fontFamily: fontFamilies.openSans,
            fontSize: "16px",
            padding: 1,
            "&:before": { display: "none" },
          }}
          defaultValue={1}
        >
          {[0, 1, 2, 3, 4, 5, 6].map((month) => (
            <MenuItem
              key={`month_${month}`}
              value={month}
              sx={{ "& fieldset legend": { border: "none" } }}
            >
              Month {month}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  } else {
    return (
      <Box>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          {[0, 1, 2, 3, 4, 5, 6].map((month) => (
            <React.Fragment key={`month_${month}`}>
              <Avatar
                onClick={() => setCurrentMonth(month)}
                sx={{
                  cursor: "pointer",
                  bgcolor:
                    currentMonth === month ? jgDenimColors.denim.light : jgDenimColors.denim.main,
                }}
              >
                {month}{" "}
              </Avatar>
              {month != 6 && (
                <Box mx={1}>
                  <ArrowRightIcons />
                </Box>
              )}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    );
  }
};

const MonthContent = ({ currentMonth }) => {
  return (
    <Grid container my={5} spacing={2}>
      <Grid item xs={12} sm={6}>
        <img
          style={{ maxWidth: "100%", paddingRight: "10px", marginBottom: "20px" }}
          src={`${IMAGE_PATH}${currentMonth}${FILE_EXTENSION}`}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant={"h4"}>{MONTH_DATA[currentMonth].title}</Typography>
        <Typography
          variant={"h8"}
          sx={{ color: jgDenimColors.denim.light, textTransform: "none", my: 2 }}
        >
          {MONTH_DATA[currentMonth].subtitle}
        </Typography>
        <Box>
          {MONTH_DATA[currentMonth].highlights.map((highlight, idx) => (
            <Box key={idx} display={"flex"} alignItems={"center"} my={1}>
              <StarIcon color={jgDenimColors.grades.grade2} />
              <Typography variant={"body1"} sx={{ ml: 1, fontWeight: fontWeights.semiBold }}>
                {highlight}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box my={2}>
          <Typography variant={"body1"}>{MONTH_DATA[currentMonth].description}</Typography>
        </Box>
        <Box my={2}>
          {MONTH_DATA[currentMonth].decade && (
            <Typography variant="h8" textTransform={"none"} color={jgDenimColors.denim.light}>
              Decade: {MONTH_DATA[currentMonth].decade}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const WhatWillYouLearn = ({ width }) => {
  const [currentMonth, setCurrentMonth] = useState(0);
  return (
    <Box mt={SECTION_TOP_MARGIN}>
      <SectionHeader content="What Will You Learn Over Six Months?" />
      <MonthStepper currentMonth={currentMonth} setCurrentMonth={setCurrentMonth} width={width} />
      <MonthContent currentMonth={currentMonth} />
    </Box>
  );
};

export default withInnerWidth(WhatWillYouLearn);
