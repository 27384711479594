import React from "react";
import { connect } from "react-redux";
import { transpose } from "../../store/songStore";

const TransposeTab = ({ activeSheetMusicContent, transpositionChange, transpose }) => {
  return (
    <li className={`transpose${activeSheetMusicContent === "Tabs" ? " disabled" : ""}`}>
      <span onClick={() => transpose(-1)} className="change-btn change-btn--minus">
        <span>-</span>
      </span>

      <span className="change-option__text transpose">
        {transpositionChange < 0 && (
          <span className="transpose-int transpose-int--minus"> {transpositionChange} </span>
        )}
        Transpose
        {transpositionChange > 0 && (
          <span className="transpose-int transpose-int--plus">+{transpositionChange}</span>
        )}
      </span>

      <span onClick={() => transpose(1)} className="change-btn change-btn--plus">
        <span>+</span>
      </span>
    </li>
  );
};

const mapStateToProps = ({ entity: { activeSheetMusicContent, transpositionChange } }) => ({
  activeSheetMusicContent,
  transpositionChange,
});

const mapDispatchToProps = (dispatch) => ({
  transpose: (amount) => dispatch(transpose(amount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransposeTab);
