import React, { useEffect } from "react";
import { CSSTransition } from "react-transition-group";

export default function ({ isCollapsible, grade: { label, modules, backgroundColor } }) {
  const OPEN_BY_DEFAULT = ["Grade 1", "Grade 2", "Grade 3"];
  const [isOpen, setOpen] = React.useState(!isCollapsible);
  const showList = !isCollapsible || isOpen;
  const headerClassName =
    "site-map-grade__header" + `${label === "Grade 1" ? " site-map-grade__header--dark-text" : ""}`;

  useEffect(() => {
    if (OPEN_BY_DEFAULT.includes(label)) {
      setOpen(true);
    }
  }, []);

  return (
    <div className="site-map-grade">
      <div className={headerClassName} style={{ backgroundColor }}>
        <h2>{label}</h2>
        {isCollapsible && (
          <i
            onClick={() => setOpen(!isOpen)}
            className={`icon icon-angle-${isOpen ? "up" : "down"}`}
          />
        )}
      </div>
      <CSSTransition in={showList} timeout={200} classNames="verticalGrowInOut">
        <div className="verticalGrowInOut">
          {showList ? (
            <div className="site-map-grade__modules">
              {modules && modules.length ? (
                modules.map(({ label, link }) => (
                  <a className="site-map-grade__module" href={link} key={label}>
                    {label}
                  </a>
                ))
              ) : (
                <p>Coming soon...</p>
              )}
            </div>
          ) : null}
        </div>
      </CSSTransition>
    </div>
  );
}
