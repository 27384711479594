import React from "react";
import { connect } from "react-redux";

import { selectCurrentUser } from "./store";
import { storeSearchAndNavigateToItem } from "../shared/search";

import SongActionButtons from "./SongActionButtons";

export const SongGridItemOverlay = ({ currentUser, song }) => {
  return (
    <div
      className="song-grid-item__overlay"
      style={{
        justifyContent: currentUser == null ? "center" : "space-between",
      }}
    >
      <SongActionButtons song={song} />

      <button
        className="button"
        onClick={() =>
          storeSearchAndNavigateToItem({
            title: song.title,
            slug: `/songs/${song.slug}`,
          })
        }
      >
        Go To Song
      </button>
    </div>
  );
};

export default connect((state) => ({
  currentUser: selectCurrentUser(state),
}))(SongGridItemOverlay);
