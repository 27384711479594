import React, { useEffect, useState } from "react";
import BluesImmersionSwoosh from "./BluesImmersionSwoosh";
import { Box, ThemeProvider, Typography } from "@mui/material";
import { jgDenimTheme } from "../../jg-material/theme/jg-denim-theme";
import BluesImmersionIntroduction from "./BluesImmersionIntroduction";
import HowItWorks from "./HowItWorks";
import BuyCourseButton from "./BuyCourseButton";
import WhatWillYouLearn from "./WhatWillYouLearn";
import CourseHighlights from "./CourseHighlights";
import BluesImmersionPricing from "./BluesImmersionPricing";
import MoneybackGuarantee from "../../components/MoneybackGuarantee";
import BluesImmersionFaqs from "./BluesImmersionFaqs";
import ScholarshipDisclaimer from "./ScholarshipDisclaimer";
import Loading from "../../components/shared/Loading";
import { DISCOUNT_TYPES, PLAN_TYPES } from "../../subscriptions/consts";
import { discountForPlan } from "../../subscriptions/utils";
import ClosedForNewEnrollments from "./ClosedForNewEnrollments";
import SectionHeader from "./SectionHeader";
import ExistingSubscription from "./ExistingSubscription";
import EnrollmentCountdown from "./EnrollmentCountdown";
import { LoginModal } from "../../jg-material/auth/LoginModal";
import { jgDenimColors } from "../../jg-material/theme/jg-denim-colors";

const CTA_OPTIONS = {
  freeSpots: {
    message: "Register now!",
    color: "#6acf64",
  },
  almostFull: {
    message: "Almost full! Last chance!",
    color: "#c60000",
  },
  lastSpots: {
    message: "Last spots!",
    color: "#f16335",
  },
  limitedSlots: {
    message: "Limited slots!",
    color: "#fabf22",
  },
};

const BluesImmersion = ({
  currentUser,
  faqs,
  pricingPlans,
  enrollmentCloseTimestamp,
  enrollmentOpen,
  enrollmentCount,
  userHasSubscription,
}) => {
  const lifetimePlan = pricingPlans.data.find(
    (plan) => plan.attributes.planType === PLAN_TYPES.lifetime
  );

  const [activePlan, setActivePlan] = useState(lifetimePlan);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [loginOpen, setLoginOpen] = useState(false);

  useEffect(() => {
    const discount = discountForPlan(activePlan, pricingPlans, DISCOUNT_TYPES.withinPeriod);
    setDiscount(discount);
  }, [activePlan]);

  const callToAction = () => {
    const spotsAvailable = 1000 - enrollmentCount;
    let cta = CTA_OPTIONS.freeSpots;

    if (spotsAvailable < 250) {
      cta = CTA_OPTIONS.almostFull;
    } else if (spotsAvailable < 500) {
      cta = CTA_OPTIONS.lastSpots;
    } else if (spotsAvailable < 750) {
      cta = CTA_OPTIONS.limitedSlots;
    }

    return (
      <Typography variant="h8" sx={{ letterSpacing: 0, textTransform: "none" }} color={cta.color}>
        {cta.message}
      </Typography>
    );
  };

  const buyButton = () => {
    if (!enrollmentOpen || userHasSubscription) return null;

    return (
      <>
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>{callToAction()}</Box>
        <BuyCourseButton
          activePlan={activePlan}
          currentUser={currentUser}
          discount={discount}
          setLoading={setLoading}
          setLoginOpen={setLoginOpen}
        />
      </>
    );
  };

  return (
    <ThemeProvider theme={jgDenimTheme}>
      {loading && <Loading coverViewport={true} />}

      <BluesImmersionSwoosh />

      <EnrollmentCountdown
        enrollmentCloseTimestamp={enrollmentCloseTimestamp}
        enrollmentOpen={enrollmentOpen}
      />

      <Box className="container">
        <BluesImmersionIntroduction />
        <HowItWorks />

        {buyButton()}

        <WhatWillYouLearn />
        <CourseHighlights />

        {enrollmentOpen !== true ? (
          <>
            <SectionHeader content={"Pricing"} />
            <ClosedForNewEnrollments />
          </>
        ) : userHasSubscription ? (
          <ExistingSubscription />
        ) : (
          <>
            <BluesImmersionPricing
              pricingPlans={pricingPlans}
              activePlan={activePlan}
              setActivePlan={setActivePlan}
            />

            {buyButton()}

            <ScholarshipDisclaimer />
          </>
        )}

        <MoneybackGuarantee readMoreLink={"#faqs"} />
        <BluesImmersionFaqs faqs={faqs} />

        <LoginModal isOpen={loginOpen} handleClose={() => setLoginOpen(!loginOpen)} />
      </Box>
    </ThemeProvider>
  );
};

export default (props) => <BluesImmersion {...props} />;
