import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setAllLatestLessons } from "../store/dashboardStore";
import axios from "axios";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import Loading from "../../components/shared/Loading";
import SongAndLessonCard from "../shared/SongAndLessonCard";
import SongTile from "../shared/SongTile";
import TabbedContent from "../shared/TabbedContent";
const TABS = [
  { name: "all", nameShort: "all", selector: "userFavorites" },
  { name: "lessons", nameShort: "lessons", selector: "lessonFavorites" },
  { name: "songs", nameShort: "songs", selector: "songFavorites" },
  { name: "playground", nameShort: "playground", selector: "playgroundFavorites" },
];

const LessonList = ({ allLatestLessons, typeOf }) => {
  return allLatestLessons
    .filter((i) => i.data.attributes.typeOf == typeOf)
    .sort((a, b) =>
      a.data.attributes.pageReleaseDate > b.data.attributes.pageReleaseDate ? -1 : 1
    )
    .map((item, idx) => <SongAndLessonCard key={idx} item={item} />);
};

const AllNewLessons = ({ allLatestLessons, setAllLatestLessons, setViewAllLessons }) => {
  const [activeTab, setActiveTab] = useState("all");
  useEffect(() => {
    const getLessons = axiosWithCSRF().get("/latest/lessons");
    const getSongs = axiosWithCSRF().get("/latest/songs");
    const getPlayground = axiosWithCSRF().get("/latest/playground");
    axios.all([getLessons, getSongs, getPlayground]).then(
      axios.spread(function (lessonsData, songsData, playgroundData) {
        setAllLatestLessons(
          lessonsData.data.items
            .concat(songsData.data.items)
            .concat(playgroundData.data.items)
            .sort((a, b) =>
              a.data.attributes.pageReleaseDate > b.data.attributes.pageReleaseDate ? -1 : 1
            )
        );
      })
    );
  }, []);

  return (
    <TabbedContent
      title={"What's New on JustinGuitar"}
      setActiveTab={setActiveTab}
      activeTab={activeTab}
      tabOptions={TABS}
    >
      {allLatestLessons ? (
        <div className="tab-content tab-content--reduced-padding">
          <div className="cards">
            {activeTab === "all" ? (
              allLatestLessons.map((item, idx) => <SongAndLessonCard key={idx} item={item} />)
            ) : activeTab === "lessons" ? (
              <LessonList allLatestLessons={allLatestLessons} typeOf="Lesson" />
            ) : activeTab === "songs" ? (
              <LessonList allLatestLessons={allLatestLessons} typeOf="Song" />
            ) : activeTab === "playground" ? (
              <LessonList allLatestLessons={allLatestLessons} typeOf="Playground" />
            ) : null}
          </div>
        </div>
      ) : (
        <div className="relative latest-lessons-loading">
          <div style={{ marginTop: "20px" }}>
            <span className="bold">Fetching lessons...</span>
          </div>
          <Loading />
        </div>
      )}
    </TabbedContent>
  );
};

const mapStateToProps = (state, ownProps) => ({
  allLatestLessons: state.entity.dashboard.allLatestLessons,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  setAllLatestLessons: (items) => dispatch(setAllLatestLessons(items)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllNewLessons);
