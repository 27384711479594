import React from "react";
import DynamicIframe from "../../components/DynamicIframe";
import { CDN_URL } from "../../components/shared/CdnUrl";
import NoMoreExcusesSwoosh from "./NoMoreExcusesSwoosh";
import BottomSwoosh from "./BottomSwoosh";
import AppLinksWithDescription from "./AppLinksWithDescription";
import { StrummingIcon } from "./StrummingIcon";
import DescriptionIcon from "./DescriptionIcon";
import AppIcons from "../../components/shared/AppIcons";

const APP_PAGE_ONELINK_URL = "https://go.onelink.me/3065804798/a4d4c6c6";

export default class BeginnerApp extends React.Component {
  render() {
    return (
      <div className="beginner-app">
        <div className="beginner-app-swoosh">
          <div className="beginner-app-swoosh__content">
            <div className="beginner-app-swoosh__content__section">
              <h1 className="header1">
                learn with
                <br />
                justin’s app
              </h1>
              <p>
                Build the guitar skills you need while playing along with your favorite artists.
                From Day 0!
              </p>
              <AppIcons
                analyticsEventPageName="app-page"
                analyticsEventValue="1"
                black
                onelinkUrl={APP_PAGE_ONELINK_URL}
              />
            </div>
            <div className="beginner-app-swoosh__content__section">
              <img src={`${CDN_URL}/redesign/app/app-tile.png`} alt="JustinGuitar" />
              <img
                src={`${CDN_URL}/redesign/app/app-logo.png`}
                alt="JustinGuitar Lessons & Songs App"
              />
              <img
                src={`${CDN_URL}/redesign/app/app-preview-1-v2.png`}
                alt="JustinGuitar Lessons & Songs App Preview"
              />
            </div>
          </div>
        </div>

        <div className="beginner-app__body">
          <h2 className="uppercase header2 beginner-app__body__main-heading text-center">
            Learning to play guitar online was never so easy :)
          </h2>
          <p className="beginner-app__body__description">
            <strong>Still wasting your time searching for the next tutorial to watch?</strong> 😲
          </p>
          <p className="beginner-app__body__description">
            <strong>Look no further! Get The JustinGuitar Lessons & Songs App.</strong>
          </p>
          <p className="beginner-app__body__description">
            The built-in Beginner Guitar Course will teach you all you need to play the guitar with
            confidence.
            <br />
            And you’ll practice your new skills by playing the songs you love!
          </p>
          <div className="beginner-app__body__video">
            <DynamicIframe
              link="https://player.vimeo.com/video/541559599"
              containerHeight="350"
              aspectRatio="1.777"
            />
          </div>
        </div>
        <div className="beginner-app__features-description side-margin-offset-left">
          <img
            src={`${CDN_URL}/redesign/app/app-preview-2-v2.png`}
            alt="JustinGuitar Lessons & Songs App"
          />
          <div className="features__body">
            <h3 className="header3">🎵 Over 1000 Songs! </h3>
            <p>
              Play along with hundreds of backing tracks that sound just like the original
              recording. All songs have easy chords for beginners and a gradual progression of
              difficulty that fits perfectly with the course.
            </p>
          </div>
        </div>
        <div className="beginner-app__features-description side-margin-offset-right">
          <div className="features__body">
            <h3 className="header3">🎸 A Full Course in Your Pocket!</h3>
            <p>
              It feels like private lessons. You’ll always know what to practice next and for how
              long so you know exactly when to move on! Let’s practice smarter, not harder - so you
              improve your musicianship in no time.{" "}
            </p>
          </div>
          <img
            className="negative-margin-right"
            src={`${CDN_URL}/redesign/app/app-preview-3-v2.png`}
            alt="JustinGuitar Lessons & Songs App"
          />
        </div>
        <div className="beginner-app__features-description side-margin-offset-left enjoy-your-journey">
          <img
            src={`${CDN_URL}/redesign/app/app-preview-4-v2.png`}
            alt="JustinGuitar Lessons & Songs App"
          />
          <div className="features__body">
            <h3 className="header3">💙 Enjoy Your Journey!</h3>
            <p>
              Learning guitar is all about playing songs and having real fun, and that's how the APP
              works! Think guitar-eoke. But way better.
            </p>
          </div>
        </div>
        <div className="beginner-app__body">
          <div className="beginner-app__free">
            <AppLinksWithDescription
              onelinkUrl={APP_PAGE_ONELINK_URL}
              description="Try it out for free!"
              analyticsEventValue="2"
            />
          </div>
          <div className="beginner-app__body__description-two">
            <div>
              <img src="https://jtgt-static.b-cdn.net/redesign/app/app-preview-5-v2.png" alt="" />
            </div>
            <div className="beginner-app__copy">
              <h2 className="header2 uppercase">what you will learn</h2>
              <ul className="learn-icons">
                <li>
                  <DescriptionIcon iconClassNames="icon icon-fretboard-chords" size={48} />
                  <p>
                    <em>(Clear & Fast)</em> Chord Changes
                  </p>
                </li>
                <li>
                  <DescriptionIcon iconComponent={<StrummingIcon />} size={48} />
                  <p>
                    Strumming Patterns <em>(That Feel Good!)</em>
                  </p>
                </li>
                <li>
                  <DescriptionIcon iconClassNames="icon icon-pick-filled" size={48} />
                  <p>
                    Picking and Fretting Tricks <em>(That Work!)</em>
                  </p>
                </li>
                <li>
                  <DescriptionIcon iconClassNames="icon icon-music-note-3" size={48} />
                  <p>How to Make Music!</p>
                </li>
              </ul>
              <p>
                <strong>I spent years studying how to optimize your progress.</strong> I even
                learned to play guitar left-handed using my method so I didn’t miss any details. :)
              </p>
              <p>
                JustinGuitar Lessons & Songs will make your journey easier through{" "}
                <strong>interactive exercises</strong> and a <strong>clear pathway!</strong>
              </p>
              <p>
                I broke the course down into modules and organized songs into levels so that you{" "}
                <strong>learn at your own pace.</strong>
              </p>
            </div>
          </div>
          <div className="beginner-app__body__statistics">
            <div>
              <i className="icon icon-star"></i>
              <h3>Best Ranked Guitar App!</h3>
              <p>
                <strong>4.9</strong> Stars on App Store | <strong>4.5</strong> Stars on Google Play
              </p>
            </div>
            <div>
              <i className="icon icon-heart"></i>
              <h3>Trusted Around the World</h3>
              <p>
                Join <strong>over 1.6M students</strong> who learn with the APP!
              </p>
            </div>
          </div>
          <div className="beginner-app__free">
            <AppLinksWithDescription
              description="Get started for free"
              onelinkUrl={APP_PAGE_ONELINK_URL}
              analyticsEventValue="3"
            />
          </div>
        </div>
        <NoMoreExcusesSwoosh />
        <div className="beginner-app__body">
          <div className="beginner-app__body__tips">
            <h2 className="header2 text-center uppercase">
              4 Tips To Help You Learn Even Faster! 🚀
            </h2>
            <div className="app-tips">
              <div className="app-tips__item">
                <DescriptionIcon iconClassNames="icon icon-ear-2" size={80} />
                <p>
                  <strong>Use the built-in tuner</strong> before each practice. Always play with
                  your guitar in-tune so you learn the correct sound of the notes!
                </p>
              </div>
              <div className="app-tips__item">
                <DescriptionIcon iconClassNames="icon icon-alarm-clock" size={80} />
                <p>
                  <strong>Short practice sessions are better than longer ones.</strong> Trust the
                  process and you’ll be playing with confidence in no time!
                </p>
              </div>
              <div className="app-tips__item">
                <DescriptionIcon iconClassNames="icon icon-graph" size={80} />
                <p>
                  <strong>Always keep track of your progress</strong> with the APP! You’ll be
                  surprised at how much you’re improving in a short period. ;)
                </p>
              </div>
              <div className="app-tips__item">
                <DescriptionIcon iconClassNames="icon icon-music-note-2" size={80} />
                <p>
                  <strong>Develop your sense of rhythm!</strong>
                  <br />
                  Strumming is hard, and <strong>playing along with the app</strong> will help you
                  build a solid foundation!
                </p>
              </div>
            </div>
          </div>
        </div>
        <BottomSwoosh onelinkUrl={APP_PAGE_ONELINK_URL} />
      </div>
    );
  }
}
