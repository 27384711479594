import { updateSongbook } from "./songbookApi";

export const storeNewSongbookSongs = (songbook, currentSongs, newSongIds, dispatch) => {
  const existingSongbookSongs = currentSongs.map((song, ord) => ({
    song_id: song.id,
    ord,
  }));
  const maxExistingOrd =
    existingSongbookSongs.length > 0
      ? existingSongbookSongs[existingSongbookSongs.length - 1].ord
      : 0;
  const newSongbookSongs = newSongIds.map((song_id, idx) => ({
    song_id,
    ord: idx + maxExistingOrd + 1,
  }));

  dispatch(
    updateSongbook({
      songbook: {
        ...songbook,
        songbook_songs_attributes: newSongbookSongs, // using rails' .update method with nested_attributes, so we only need the new songs
      },
    })
  );
};

export const reorderSongs = (songbook, song, currentSongs, newOrd, dispatch) => {
  const lowerSongs = currentSongs.filter(
    (songbookSong, idx) => idx <= newOrd && Number(songbookSong.id) !== Number(song.id)
  );
  const higherSongs = currentSongs.filter(
    (songbookSong, idx) => idx > newOrd && Number(songbookSong.id) !== Number(song.id)
  );
  const orderedSongs = [...lowerSongs, song, ...higherSongs].map((s, ord) => ({
    song_id: s.id,
    ord,
  }));

  dispatch(
    updateSongbook({
      songbook: {
        id: songbook.id,
        title: songbook.attributes.title,
        songbook_songs_attributes: orderedSongs,
      },
      replaceSongs: true, // need to delete & replace, not just append
    })
  );
};
