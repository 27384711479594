export const PROMO_END_DATE = "2023-04-24";

const currentDate = new Date();
const promoEndDate = new Date(PROMO_END_DATE);

currentDate.setHours(0, 0, 0, 0);
promoEndDate.setHours(0, 0, 0, 0);

export const IS_STRUMMING_SOS_PROMO = currentDate <= promoEndDate;
export const STRUMMING_SOS_DISCOUNT_PRICE = IS_STRUMMING_SOS_PROMO ? 14.99 : 29.99;
