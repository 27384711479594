import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosWithCSRF from "../../shared/axiosWithCSRF";
import debounce from "lodash.debounce";

import { selectSearchQuery, selectAdvancedSearchUrl, selectVisibleType } from "./searchSelectors";
import { advancedSearchResults } from "./searchSchema";
import { normalize } from "normalizr";
import { searchResultsTypes } from "../../shared/search";
import { appendResults, setLoading, setResults } from "./searchActions";
import { initialState } from "./consts";

export const resultMergeStrategies = {
  append: "append",
  replace: "replace",
};

const DEBOUNCE_TIME_MS = 250;

const makeSearchApiRequest = (url) =>
  axiosWithCSRF()
    .get(url, { headers: { Accept: "application/json" } })
    .then(({ data }) => normalize(data, advancedSearchResults));

const debouncedFetchSearchResults = debounce(
  ({ mergeStrategy = resultMergeStrategies.append, page = 1 } = {}, { getState, dispatch }) => {
    if (mergeStrategy === resultMergeStrategies.replace) {
      dispatch(setResults(initialState.results));
    }

    const state = getState();
    const query = selectSearchQuery(state);

    if (query.length < 3) {
      // min query length for PgSearch is 3 chars
      dispatch(setLoading(false));
      return;
    }

    const url = selectAdvancedSearchUrl(state) + `&page=${page}`;
    const visibleType = selectVisibleType(state);
    const typesToFetch = visibleType == null ? Object.keys(searchResultsTypes) : [visibleType];

    return Promise.all(
      typesToFetch.map((type) => {
        dispatch(setLoading({ entity: type, loading: true }));
        return makeSearchApiRequest(`${url}&type=${type}`).then((results) => {
          dispatch(appendResults(results));
          dispatch(setLoading({ entity: type, loading: false }));
        });
      })
    );
  },
  DEBOUNCE_TIME_MS
);

export const fetchSearchResults = createAsyncThunk(
  "advancedSearch/fetchResults",
  debouncedFetchSearchResults
);

export const createUserSongItem = createAsyncThunk(
  "advancedSearch/createUserSongItem",
  (userSongItem) =>
    axiosWithCSRF()
      .post("/user_items/song_item.json", userSongItem)
      .then((res) => res.data.userItem.data)
);

export const deleteUserSongItem = createAsyncThunk(
  "advancedSearch/deleteUserSongItem",
  (userItem) =>
    axiosWithCSRF()
      .delete(`/user_items/${userItem.id}.json`)
      .then(() => userItem)
);

export const createUserPracticeItem = createAsyncThunk(
  "advancedSearch/createUserPracticeItem",
  (songId) =>
    axiosWithCSRF()
      .post(`/songs/${songId}/user_practice_item.json`)
      .then((res) => res.data)
);

export const deleteUserPracticeItem = createAsyncThunk(
  "advancedSearch/deleteUserPracticeItem",
  (songId) =>
    axiosWithCSRF()
      .delete(`/songs/${songId}/user_practice_item.json`)
      .then(() => songId)
);

export const setQueryParam = (value) => {
  const queryStrKey = "q";
  const regex = new RegExp(`([?;&])${queryStrKey}[^&;]*[;&]?`);
  const queryStr = window.location.search.replace(regex, "$1").replace(/&$/, "");
  const path =
    window.location.pathname +
    (queryStr.length > 2 ? queryStr + "&" : "?") +
    (value && value.length ? `q=${value}` : "");

  window.history.pushState({ path }, "", path);
};
