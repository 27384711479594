import React from "react";

export const YoutubeIconWithWhiteBg = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7996 26.4V19.0667L25.6662 22.7335L19.7996 26.4Z"
        fill="white"
      />
      <g clipPath="url(#clip0_7031_48296)">
        <path
          d="M32.5617 16.67C32.4374 16.202 32.1922 15.7748 31.8509 15.4313C31.5095 15.0877 31.084 14.8399 30.6167 14.7125C28.8967 14.25 22.0217 14.25 22.0217 14.25C22.0217 14.25 15.1467 14.25 13.4267 14.7125C12.9595 14.8399 12.5339 15.0877 12.1926 15.4313C11.8512 15.7748 11.6061 16.202 11.4817 16.67C11.0217 18.3975 11.0217 22 11.0217 22C11.0217 22 11.0217 25.6025 11.4817 27.33C11.6061 27.798 11.8512 28.2252 12.1926 28.5687C12.5339 28.9123 12.9595 29.1601 13.4267 29.2875C15.1467 29.75 22.0217 29.75 22.0217 29.75C22.0217 29.75 28.8967 29.75 30.6167 29.2875C31.084 29.1601 31.5095 28.9123 31.8509 28.5687C32.1922 28.2252 32.4374 27.798 32.5617 27.33C33.0217 25.6025 33.0217 22 33.0217 22C33.0217 22 33.0217 18.3975 32.5617 16.67Z"
          fill="#FF0302"
        />
        <path d="M19.7717 25.2713V18.7288L25.5217 22L19.7717 25.2713Z" fill="#FEFEFE" />
      </g>
      <defs>
        <clipPath id="clip0_7031_48296">
          <rect width="22" height="22" fill="white" transform="translate(11 11)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const YoutubeIcon = () => {
  return (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11445_66588)">
        <path
          d="M66.6465 17.5254C66.2621 16.0787 65.5044 14.7584 64.4494 13.6966C63.3943 12.6348 62.0789 11.8687 60.6347 11.475C55.3184 10.0454 34.0684 10.0454 34.0684 10.0454C34.0684 10.0454 12.8184 10.0454 7.502 11.475C6.05783 11.8687 4.74239 12.6348 3.68734 13.6966C2.63229 14.7584 1.87463 16.0787 1.49018 17.5254C0.0683595 22.865 0.0683594 34 0.0683594 34C0.0683594 34 0.0683595 45.135 1.49018 50.4745C1.87463 51.9212 2.63229 53.2415 3.68734 54.3033C4.74239 55.3651 6.05783 56.1312 7.502 56.525C12.8184 57.9545 34.0684 57.9545 34.0684 57.9545C34.0684 57.9545 55.3184 57.9545 60.6347 56.525C62.0789 56.1312 63.3943 55.3651 64.4494 54.3033C65.5044 53.2415 66.2621 51.9212 66.6465 50.4745C68.0684 45.135 68.0684 34 68.0684 34C68.0684 34 68.0684 22.865 66.6465 17.5254Z"
          fill="#FF0302"
        />
        <path d="M27.1133 44.1112V23.8889L44.886 34.0001L27.1133 44.1112Z" fill="#FEFEFE" />
      </g>
      <defs>
        <clipPath id="clip0_11445_66588">
          <rect width="68" height="68" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ArrowRightIcons = () => (
  <svg width="56" height="24" viewBox="0 0 56 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_501_15)">
      <path
        d="M8.58997 16.59L13.17 12L8.58997 7.41L9.99997 6L16 12L9.99997 18L8.58997 16.59Z"
        fill="#81ABC1"
      />
    </g>
    <g clipPath="url(#clip1_501_15)">
      <path d="M24.59 16.59L29.17 12L24.59 7.41L26 6L32 12L26 18L24.59 16.59Z" fill="#81ABC1" />
    </g>
    <g clipPath="url(#clip2_501_15)">
      <path d="M40.59 16.59L45.17 12L40.59 7.41L42 6L48 12L42 18L40.59 16.59Z" fill="#81ABC1" />
    </g>
    <defs>
      <clipPath id="clip0_501_15">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_501_15">
        <rect width="24" height="24" fill="white" transform="translate(16)" />
      </clipPath>
      <clipPath id="clip2_501_15">
        <rect width="24" height="24" fill="white" transform="translate(32)" />
      </clipPath>
    </defs>
  </svg>
);

export const StarIcon = ({ color }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.5"
      y="0.5"
      width="47"
      height="47"
      rx="9.5"
      fill={color}
      stroke={color === "white" ? "#81ABC1" : ""}
    />
    <g clipPath="url(#clip0_502_19)">
      <path
        d="M24 29.27L30.18 33L28.54 25.97L34 21.24L26.81 20.63L24 14L21.19 20.63L14 21.24L19.46 25.97L17.82 33L24 29.27Z"
        fill={color === "white" ? "#1D3446" : "white"}
      />
    </g>
    <defs>
      <clipPath id="clip0_502_19">
        <rect width="24" height="24" fill="white" transform="translate(12 12)" />
      </clipPath>
    </defs>
  </svg>
);

export const ArrowUpIcon = () => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.41 7.41003L6 2.83003L10.59 7.41003L12 6.00003L6 3.38077e-05L1.23266e-07 6.00003L1.41 7.41003Z"
      fill="#1D3446"
    />
  </svg>
);

export const ArrowDownIcon = () => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.59 0L6 4.58L1.41 0L0 1.41L6 7.41L12 1.41L10.59 0Z" fill="#1D3446" />
  </svg>
);

export const CaretUpIcon = ({ color = "#00B4A5" }) => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.41 7.41016L6 2.83016L10.59 7.41016L12 6.00016L6 0.000156403L0 6.00016L1.41 7.41016Z"
      fill={color}
    />
  </svg>
);

export const CaretDownIcon = ({ color = "#00B4A5" }) => (
  <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2951 0L6.70508 4.58L2.11508 0L0.705078 1.41L6.70508 7.41L12.7051 1.41L11.2951 0Z"
      fill={color}
    />
  </svg>
);

export const CaretRightIcon = () => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M-4.62904e-07 1.41L4.58 6L-6.16331e-08 10.59L1.41 12L7.41 6L1.41 -6.16331e-08L-4.62904e-07 1.41Z"
      fill="#00B4A5"
    />
  </svg>
);

export const CaretLeftIcon = () => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.41016 10.59L2.83016 6L7.41016 1.41L6.00016 0L0.000156403 6L6.00016 12L7.41016 10.59Z"
      fill="#00B4A5"
    />
  </svg>
);

export const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="#1D3446"
    />
  </svg>
);

export const CheckIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18 10C18 14.4183 14.4183 18 10 18C5.58174 18 2.00002 14.4183 2.00002 10C2.00002 5.58172 5.58174 1.99999 10 1.99999C14.4183 1.99999 18 5.58172 18 10ZM14.1178 6.00001L15.465 7.3567L7.82165 15L3.99999 11.1879L5.35668 9.84077L7.82165 12.2962L14.1178 6.00001Z"
      fill="white"
    />
  </svg>
);

export const FilterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 6H12V0H14V2H18V4H14V6ZM0 4V2H10V4H0ZM0 16V14H6V16H0ZM10 18V16H18V14H10V12H8V18H10ZM4 8V6H6V12H4V10H0V8H4ZM18 10V8H8V10H18Z"
      fill="#406E8E"
    />
  </svg>
);

export const LongRightArrow = ({ color = "#81ABC1" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="61" height="16" viewBox="0 0 61 16" fill="none">
    <path
      d="M60.7071 8.70711C61.0976 8.31658 61.0976 7.68342 60.7071 7.29289L54.3432 0.928932C53.9526 0.538408 53.3195 0.538408 52.9289 0.928932C52.5384 1.31946 52.5384 1.95262 52.9289 2.34315L58.5858 8L52.9289 13.6569C52.5384 14.0474 52.5384 14.6805 52.9289 15.0711C53.3195 15.4616 53.9526 15.4616 54.3432 15.0711L60.7071 8.70711ZM0 9H60V7H0V9Z"
      fill={color}
    />
  </svg>
);

export const IconCheck = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM43.2 24C43.2 34.6039 34.6039 43.2 24 43.2C13.3961 43.2 4.8 34.6039 4.8 24C4.8 13.3961 13.3961 4.8 24 4.8C34.6039 4.8 43.2 13.3961 43.2 24ZM33.8828 14.4L37.1159 17.656L18.772 36L9.6 26.851L12.8561 23.6178L18.772 29.5108L33.8828 14.4Z"
      fill="#6ACF64"
    />
  </svg>
);

export const BackArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M8 16L9.41 14.59L3.83 9L16 9L16 7L3.83 7L9.42 1.42L8 -3.49691e-07L-3.49691e-07 8L8 16Z"
      fill="#1D3446"
    />
  </svg>
);
