import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  alert: {
    message: null,
    severity: null,
  },
  activeTab: "Learn More",
  songRequestConflicts: {
    originalRequest: null,
    conflicts: {
      songs: { data: [] },
      songRequests: { data: [] },
    },
  },
  dialog: {
    visible: false,
    message: null,
    data: null,
    cancelButtonText: null,
    confirmButtonText: null,
    swapActions: false,
  },
  mobileContextMenu: {
    visible: false,
  },
  currentEntityId: undefined, // for show pages, eg. quizzes/:id
};

export const { actions, reducer } = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    openAlert: (state, { payload }) => {
      state.alert = payload;
    },
    clearAlert: (state) => {
      state.alert = initialState.alert;
    },
    setSongRequestConflicts: (state, { payload }) => {
      state.songRequestConflicts = payload;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = !state.isLoading;
    },
    openDialog: (state, { payload }) => {
      state.dialog.visible = true;
      state.dialog.message = payload.message;
      state.dialog.secondaryMessage = payload.secondaryMessage;
      state.dialog.cancelButtonText = payload.cancelButtonText;
      state.dialog.confirmButtonText = payload.confirmButtonText;
      state.dialog.swapActions = payload.swapActions;
      state.dialog.data = payload.data;
      state.dialog.input = payload.input;
      state.dialog.onConfirm = payload.onConfirm;
    },
    closeDialog: (state) => {
      state.dialog.visible = false;
      state.dialog.text = "";
    },
    toggleMobileContextMenuVisibility: (state, { payload }) => {
      state.mobileContextMenu.visible =
        payload !== undefined ? payload : !state.mobileContextMenu.visible;
    },
    setCurrentEntityId: (state, { payload }) => (state.currentEntityId = payload),
  },
});

export const {
  openAlert,
  clearAlert,
  setActiveTab,
  setLoading,
  openDialog,
  closeDialog,
  toggleMobileContextMenuVisibility,
  setCurrentEntityId,
} = actions;
