import React, { useEffect } from "react";
import { connect } from "react-redux";
import Dialog from "../../../components/shared/ui/Dialog";
import { openDialog } from "../../../components/shared/ui/uiSlice";
import { cancelSubscription } from "../../../components/shared/session/sessionEffects";
import Modal from "react-modal";
import Renewal from "./Renewal";
import UpdatePaymentMethod from "./UpdatePaymentMethod";
import Cancel from "./Cancel";
import { SUBSCRIPTION_STATUSES, PLAN_TYPES } from "../../../subscriptions/consts";

function goToPurchaseItem(link) {
  window.location.href = link;
}

const Thumbnail = ({ link, thumbnail }) => {
  return (
    <div className="purchase__img-container">
      <img
        className="purchase__img"
        src={thumbnail}
        style={{ color: "white" }}
        onClick={() => goToPurchaseItem(link)}
      ></img>
    </div>
  );
};

const Subscription = ({ subscription, openDialog, cancelSubscription }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      Modal.setAppElement("body");
    }
  }, []);

  const { expiresAt, id, installmentsRemaining, storeLink, productLink, status, title, type } =
    subscription.data.attributes;
  const pricingPlan = subscription.included.find((i) => i.type === "pricingPlan");
  const product = subscription.included.find((i) => i.type === "websiteProduct");
  const { currency, enabled, planType, price } = pricingPlan.attributes;
  const { thumbnail } = product.attributes;

  return (
    <div className="clickable">
      <div className="purchase-row">
        <Thumbnail link={productLink} thumbnail={thumbnail} />
        <div className="purchase-card">
          <div className="purchase-card__left" onClick={() => goToPurchaseItem(productLink)}>
            <span className="bold purchase__title">{title}</span>
            <Renewal
              status={status}
              expiresAt={expiresAt}
              price={price}
              currency={currency}
              planType={planType}
              installmentsRemaining={installmentsRemaining}
            />
          </div>

          <div className="purchase-card__right">
            {planType === PLAN_TYPES.recurring && (
              <>
                <Dialog onConfirm={(data) => cancelSubscription(data)} />

                <div className="purchase__actions">
                  {[SUBSCRIPTION_STATUSES.trial, SUBSCRIPTION_STATUSES.active].includes(status) && (
                    <>
                      <Cancel
                        expiresAt={expiresAt}
                        id={id}
                        openDialog={openDialog}
                        planStillAvailable={!!enabled}
                      />

                      <UpdatePaymentMethod subscriptionId={id} type={type} />
                    </>
                  )}

                  {[SUBSCRIPTION_STATUSES.unpaid, SUBSCRIPTION_STATUSES.cancelled].includes(
                    status
                  ) && (
                    <a href={storeLink} className="teal clickable">
                      Resubscribe
                    </a>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  user: state.session.currentUser,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  openDialog: (text) => dispatch(openDialog(text)),
  cancelSubscription: (id) => dispatch(cancelSubscription(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
