import React, { useState } from "react";
import { VideoEmbed } from "../../components/swoosh-templates";

const Introduction = () => {
  const [expandedIntro, setExpandedIntro] = useState(false);

  return (
    <div className="section">
      <h2 className="title">hands-on!</h2>
      <div className="intro">
        <p>
          <strong>You're entering a Theory course, but a very practical one. :)</strong>
        </p>
        <p>
          Have your guitar with you! We'll be most of the time playing and exploring your fretboard.
          That's how we'll unveil your 6-string friend and understand how it all works!
        </p>
        {expandedIntro && (
          <>
            <p>
              Music Theory is like a magical ingredient that will help you connect everything:
              notes, chords, scales, and how it all comes together in keys - so you can finally play
              freely.
            </p>
            <p>
              You'll learn to find chord progressions to your favorite songs, write your own music,
              and be comfortable improvising in situations you thought were impossible!
            </p>
            <p>
              And here, you'll learn it as I wish I did: using practical examples and making it
              simple. Music Theory doesn't have to be complicated or overwhelming!
            </p>
          </>
        )}
        <button onClick={() => setExpandedIntro(!expandedIntro)}>
          {!expandedIntro ? "Read More..." : "Read Less"}
        </button>
        <div>
          <VideoEmbed videoUrl="https://vimeo.com/803056080/7245d94b5d" />
        </div>
      </div>
    </div>
  );
};
export default Introduction;
