import React from "react";

export default class PlayItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesOpen: false,
    };
  }

  toggleImagesOpen = () => {
    this.setState({ imagesOpen: !this.state.imagesOpen });
  };

  openImages = () => {
    return (
      <div className="toggle-practice-item-image">
        <span className="toggle-practice-item-image__click" onClick={this.toggleImagesOpen}>
          close images <span className="glyphicon glyphicon-chevron-up"></span>
        </span>

        <div className="practice-item-image-container">
          <div className="row">
            {this.props.images.map((image, index) => (
              <div className="col-sm-4 col-xs-6 col-xxs-8" key={index}>
                <img src={image}></img>
                <hr />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  closedImages = () => {
    return (
      <div className="toggle-practice-item-image">
        <span className="toggle-practice-item-image__click" onClick={this.toggleImagesOpen}>
          view images <span className="glyphicon glyphicon-chevron-down"></span>
        </span>
      </div>
    );
  };

  render() {
    if (this.state.imagesOpen) {
      return this.openImages();
    } else {
      return this.closedImages();
    }
  }
}
