import React from "react";
import { formatNumber } from "../../utilities/misc";
import { PMT_REFERENCE } from "../ParentGroupHero";

const PMTButton = ({ reference, hasPMTEnrollment, currentUser, enrolledCount }) => {
  if (reference !== PMT_REFERENCE) return null;

  return (
    <div>
      {!hasPMTEnrollment ? (
        <button
          className="button button--primary"
          disabled={!currentUser}
          onClick={() => (window.location.href = "/store/practical-music-theory")}
        >
          enroll now
        </button>
      ) : (
        <div className="enrolled-badge">already enrolled</div>
      )}
      <p
        style={{
          color: "white",
          marginTop: "20px",
          fontSize: "13px",
          textAlign: "center",
        }}
      >
        <strong>{formatNumber(enrolledCount)}</strong> currently enrolled.
      </p>
    </div>
  );
};

export default PMTButton;
