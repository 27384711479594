import React from "react";
import { isCompleted } from "../lessonPageUtils";

// TODO: pass deleteLessonItem
const LessonIcon = ({ activeLesson, lessonInList, completedLessonIds, toggleCompleted }) => {
  const isActive = parseInt(activeLesson.data.id) === parseInt(lessonInList.id);
  const isComplete = completedLessonIds.includes(parseInt(lessonInList.id));
  const hasVideo = lessonInList.video && lessonInList.video.length > 0;
  const { lockedLessonIds } = activeLesson.data.attributes;

  const isLocked = lockedLessonIds.includes(parseInt(lessonInList.id));

  function goToLesson(slug) {
    window.location.href = `/guitar-lessons/${slug}`;
  }

  if (isLocked) {
    return <i className="icon icon-lock" />;
  } else if (isComplete) {
    return (
      <i
        className={"icon " + (hasVideo ? "icon-check_circle_outline" : "icon-document-complete")}
        onClick={() => toggleCompleted(true, lessonInList.id)}
      />
    );
  } else if (!hasVideo) {
    return <i className="icon icon-document" onClick={() => goToLesson(lessonInList.slug)} />;
  } else if (isActive) {
    return <i className="icon icon-video-pause"></i>;
  } else {
    return <i className="icon icon-play" onClick={() => goToLesson(lessonInList.slug)}></i>;
  }
};

export default LessonIcon;
