import React from "react";
import LazyLoad from "react-lazyload";
import { CDN_URL } from "../components/shared/CdnUrl";

const ParentGroupLiveClasses = ({ parentGroup }) => {
  const DESCRIPTION = `
    I’m trying something new! Each month I will prepare a Class based on your questions and struggles and then go 
    live using Zoom. We might add some fun challenges or more Q&As at the end too. Join me!!!
    `;

  const { hasLiveEvents } = parentGroup.data.attributes;
  if (!hasLiveEvents) return null;

  return (
    <div className="inner-offset pg__top">
      <h2 className="uppercase header2 text-center section-header">
        MONTHLY <span className="highlight">NEW</span> CONTENT!
      </h2>

      <div className="pg__live-classes">
        <div className="pgcard ">
          <div>
            <div className="pgcard__tabs">
              <div className="pgcard__tab">
                <span>ALL LEVELS</span>
              </div>
            </div>
          </div>
          <div
            className="pgcard__inner"
            onClick={() => (window.location.href = "/live-events/music-theory")}
          >
            <div className="pgcard__left">
              <LazyLoad width={250} height={141}>
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    border: "1px solid #E8EAEC",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    width={250}
                    height={141}
                    src={`${CDN_URL}/images/pages/music-theory-course/pmt-live-classes.jpeg`}
                    alt={"title"}
                  />
                </div>
              </LazyLoad>
            </div>
            <div className="pgcard__right">
              <h3 className="uppercase header4">MUSIC THEORY LIVE CLASSES</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: DESCRIPTION,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentGroupLiveClasses;
