import React, { useState } from "react";
import LiteYoutube from "../components/shared/LiteYoutube";

const TabsVideo = () => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className="tabs-video-link">
      <button onClick={() => setShowVideo(!showVideo)}>
        <i className="icon icon-play"></i>watch the tabs video
      </button>
      {showVideo && <LiteYoutube url="cH6sRluPI8U" hasMaxResImage={true} />}
    </div>
  );
};

export default TabsVideo;
