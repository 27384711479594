import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Loading from "../../components/shared/Loading";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";

import { selectSongbooksArray } from "../store/dashboardSelectors";
import Songbook from "./Songbook";

// https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
function isTouchDevice() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

const SongbookList = ({ isLoading }) => {
  const songbooks = useSelector(selectSongbooksArray);

  return (
    <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
      <div className="songbooks">
        {isLoading && <Loading />}
        {songbooks.map((songbook) => (
          <Songbook key={songbook.id} songbook={songbook} />
        ))}
      </div>
    </DndProvider>
  );
};

const mapStateToProps = (state) => ({ isLoading: state.ui.isLoading });

export default connect(mapStateToProps)(SongbookList);
