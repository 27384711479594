import React from "react";

import Category from "../FAQ/Category";
import { approvedTeachers } from "../FAQ/data/faqs";

export const TeacherTrainingFaqs = ({ faqs }) => (
  <div className="faq container">
    <Category
      faqs={faqs}
      showHeader={false}
      categoryData={approvedTeachers}
      useUrlRouting={false}
      showBreadcrumbs={false}
    />
  </div>
);