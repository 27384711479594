import { schema } from "normalizr";

import { WorkshopEntity } from "../interfaces";

export const workshopSchema = new schema.Entity<WorkshopEntity>("workshops", {
  getId: ({ attributes: { slug } }) => slug,
  // processStrategy: (workshop) => ({
  //   ...workshop,
  //   attributes: {
  //     ...workshop.attributes,
  //     arrivalTime: new Date(workshop.attributes.arrivalTime),
  //     departureTime: new Date(workshop.attributes.departureTime),
  //   },
  // }),
});

export const workshopsJson = new schema.Object({
  data: [workshopSchema],
  included: new schema.Array([]),
});
