import React, { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { Tooltip } from "@mui/material";
import { CDN_URL } from "../../shared/CdnUrl";
import { withBootstrapSize } from "../../shared/WithBootstrapSize";

// import { SongActionsDrawer } from "../advanced-search/SongActionsDrawer";
import SongActionsDrawer from "./SongActionsDrawer";

function NoteIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0001 0.703777C18.0001 0.602745 17.9777 0.5029 17.9346 0.411027C17.8915 0.319155 17.8286 0.237408 17.7502 0.171342C17.6718 0.105276 17.5798 0.0564402 17.4803 0.0281525C17.3808 -0.000135155 17.2762 -0.00721145 17.1737 0.00740455L4.96878 1.75068C4.79725 1.77525 4.64052 1.85917 4.52723 1.98712C4.41394 2.11507 4.35166 2.27849 4.35177 2.44752V4.55166C4.35199 4.5918 4.35585 4.63184 4.36332 4.67132C4.35578 4.71142 4.35192 4.75209 4.35177 4.79285V13.4351C3.99786 13.3059 3.62269 13.241 3.24481 13.2436C2.89178 13.2445 2.54082 13.2962 2.2033 13.3971C1.48811 13.6087 0.87351 14.0193 0.470674 14.5533C0.0322221 15.1394 -0.108795 15.8325 0.0851636 16.4547C0.377304 17.3933 1.34469 18 2.54935 18C2.90222 17.9992 3.25303 17.9475 3.59037 17.8466C4.30556 17.6349 4.92065 17.2243 5.323 16.6903C5.64113 16.268 5.80429 15.7879 5.79466 15.3201C5.79466 15.306 5.79466 15.2919 5.79466 15.2774V5.16169L16.5562 3.62347V11.6918C16.2024 11.5623 15.8272 11.4974 15.4493 11.5004C15.0964 11.5012 14.7457 11.5528 14.4082 11.6533C13.6931 11.8654 13.0784 12.276 12.6756 12.8096C12.2352 13.3957 12.0937 14.0892 12.2877 14.7115C12.5803 15.65 13.5477 16.2567 14.7524 16.2567C15.1052 16.2559 15.456 16.2042 15.7934 16.1033C16.5086 15.8912 17.1232 15.4806 17.526 14.947C17.832 14.5527 17.9976 14.0718 17.9977 13.5773C17.9977 13.5627 17.9977 13.5487 17.9977 13.5341V3.05005C17.9974 3.01008 17.9937 2.97021 17.9866 2.93086C17.994 2.89074 17.9977 2.85007 17.9977 2.80932L18.0001 0.703777Z"
        fill="white"
      />
    </svg>
  );
}

function PhoneIcon() {
  return (
    <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895432 0 0 0.895431 0 2V16C0 17.1046 0.895432 18 2 18H10C11.1046 18 12 17.1046 12 16V2C12 0.895431 11.1046 0 10 0H2ZM2 2H10V14H2V2ZM7 16C7 16.5523 6.55228 17 6 17C5.44772 17 5 16.5523 5 16C5 15.4477 5.44772 15 6 15C6.55228 15 7 15.4477 7 16Z"
        fill="white"
      />
    </svg>
  );
}

const APP_PRODUCT_ID = 1;

export function SongsListItem({ song }) {
  const appTile = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { title, artistDisplayName, artistImageUid, slug, gradeId, hasTabs, allProductIds } = song;
  const coercedGradeId = gradeId == null || gradeId === 10 ? 0 : gradeId;
  const userData = null;
  const goToApp = (e) => {
    e.stopPropagation();
    window.location.href = "https://justinguitarapp.onelink.me/3065804798/fc941697";
  };

  const goToTab = (e) => {
    e.stopPropagation();

    if (!userData?.user || userData.user.hasActiveTabsSubscription == false) {
      window.location.href = "/store/tabs";
    } else {
      window.location.href = `/songs/${slug}#tab`;
    }
  };

  const goToSong = (e) => {
    if (appTile && appTile.current && appTile.current.contains(e.target)) {
      goToApp(e);
    } else {
      window.location.href = `/songs/${slug}`;
    }
  };

  const hasApp = allProductIds.includes(APP_PRODUCT_ID);

  return (
    <>
      <div className="listing-item search-result-item search-result-item--song" onClick={goToSong}>
        <div className={`color-grade-${coercedGradeId}`}>{coercedGradeId}</div>
        <img src={`${CDN_URL}/system/dragonfly/production/${artistImageUid}`} alt={title} />
        <div className="artist-and-title">
          <div className="title">{title}</div>
          <div className="artist">{artistDisplayName}</div>
        </div>

        {hasTabs && (
          <Tooltip arrow enterTouchDelay={0} title="Get on-screen chords and tab!">
            <span className="song-icon tabs" onClick={goToTab}>
              <NoteIcon />
            </span>
          </Tooltip>
        )}

        {hasApp && (
          <Tooltip arrow enterTouchDelay={0} title="Play along with my App!">
            <span className="song-icon app" onClick={goToApp}>
              <PhoneIcon />
            </span>
          </Tooltip>
        )}

        <i
          className="icon icon-menu-dots-vertical"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!isOpen);
          }}
        />
      </div>
      <CSSTransition in={isOpen} className="song__actions" timeout={300}>
        <SongActionsDrawer song={song} hasApp={hasApp} />
      </CSSTransition>
    </>
  );
}

export default withBootstrapSize(SongsListItem);
