import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { CDN_URL } from "../../components/shared/CdnUrl";

const defaultHeaderImages = {
  [BootstrapSize.smDevice]: `${CDN_URL}/images/pages/private-one-on-one-lessons/private-one-on-one-lessons-hero-lg.jpg`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/private-one-on-one-lessons/private-one-on-one-lessons-hero-sm.jpg`,
};

const PrivateOneOnOneLessonsSwoosh = () => {
  return (
    <div className="private-one-on-one-lessons-swoosh">
      <Swoosh imgUrl={defaultHeaderImages}>
        <div className="private-one-on-one-lessons-swoosh__content">
          <div className="breadcrumb">
            <a href="/teachers">back to approved teachers</a>
          </div>
          <h1 className={"header1"}>1:1 with Justin</h1>
        </div>
      </Swoosh>
    </div>
  );
};

export default PrivateOneOnOneLessonsSwoosh;
