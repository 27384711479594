import React from "react";
import { connect } from "react-redux";

import { openSearch } from "./Search";
import AuthButtons from "./AuthButtons";

import { headerMenuItems } from "./menuItems";
import onClickOutside from "react-onclickoutside";
import { CDN_URL } from "../shared/CdnUrl";
import ProductsMenu from "./Large/products/ProductsMenu";

export const LOGO = `${CDN_URL}/redesign/2023/logos/justinguitar-logo-hr-dark.svg`;
const LOGO_SMALL = `${CDN_URL}/redesign/2023/logos/justinguitar-logo-hr-dark.svg`;
const LOGO_LIGHT = `${CDN_URL}/redesign/2023/logos/justinguitar-logo-hr.svg`;
const LOGO_SMALL_LIGHT = `${CDN_URL}/redesign/2023/logos/justinguitar-logo-hr.svg`;

export class LargeHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeMenuItem: null };
  }

  handleClickOutside = (evt) => this.setState({ activeMenuItem: null });

  toggleMenuItem = (menuItem) => {
    const activeMenuItem = this.state.activeMenuItem === menuItem ? null : menuItem;
    this.setState({ activeMenuItem });
  };

  activeMenuDropdown = (activeMenuItem) => {
    if (!activeMenuItem) {
      return null;
    }
    switch (activeMenuItem.title) {
      case "Courses":
        return this.courseMenuSections(activeMenuItem);
      case "Tools":
        return this.menuSubItems(activeMenuItem);
      case "Explore":
        return this.playgroundSections(activeMenuItem);
      case "Store":
        return <ProductsMenu />;
    }
  };

  courseMenuColumns = (menuItems) => {
    const numberOfColumns = 3;

    const hasPmtSubscription = this.props.currentUser?.data?.attributes?.hasActivePmtSubscription;
    const hasStrummingSosPurchase =
      this.props.currentUser && this.props.currentUser.data.attributes.hasStrummingSosPurchase;

    const columns = menuItems.reduce((resultArray, item, index) => {
      const groupedIndex = Math.floor(index / (menuItems.length / numberOfColumns));
      if (!resultArray[groupedIndex]) {
        resultArray[groupedIndex] = [];
      }
      resultArray[groupedIndex].push(item);
      return resultArray;
    }, []);

    return (
      <div className="menu-item__group">
        {columns.map((c, index) => {
          return (
            <div key={index} className="menu-item__column">
              {c.map((item, idx) => {
                return (
                  <div className="menu-item__container" key={idx}>
                    <div
                      className="menu-item__strip"
                      style={{
                        background: item.borderColor ? item.borderColor : "black",
                      }}
                    />
                    {item.link ? (
                      <a
                        className="menu-item__link"
                        href={item.link}
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      ></a>
                    ) : (
                      <a className="menu-item__link menu-item__link--disabled">
                        {item.title} <p className="small">(coming 2024+)</p>
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  playgroundSections(activeMenuItem) {
    const columns = [
      [activeMenuItem.sections[0]],
      activeMenuItem.sections.filter((s) => activeMenuItem.sections.indexOf(s) !== 0),
    ];
    return (
      <div className="menu-item__group">
        {columns.map((col, idx) => this.menuColumns(idx, col))}
      </div>
    );
  }

  courseMenuSections = (activeMenuItem) => {
    return (
      <div>
        {activeMenuItem.sections.map((s) => (
          <div className="menu-item__section" key={s.title}>
            <h5>{s.title}</h5>
            <div className="menu-item__links">{this.courseMenuColumns(s.subItems)}</div>
          </div>
        ))}
        <div className="menu-item__section">
          <h5>Not sure where to start?</h5>
          <div className="menu-item__links">
            <div className="menu-item__group">
              <div className="menu-item__column">
                <div className="menu-item__container">
                  <div className="menu-item__strip"></div>
                  <a className="menu-item__link" href="/site-map-and-lesson-structure">
                    Lesson Map
                  </a>
                </div>
              </div>
              <div className="menu-item__column">
                <div className="menu-item__container">
                  <div className="menu-item__strip"></div>
                  <a className="menu-item__link" href="/guitar-lessons">
                    All Courses
                  </a>
                </div>
              </div>
              <div className="menu-item__column">
                <div className="menu-item__container"></div>
              </div>
            </div>
          </div>
        </div>
        <p className="donation">
          🎁 Most of my lessons are free! Please, consider donating and help keep it that way! :){" "}
          <a href="/donate">Learn more.</a>
        </p>
      </div>
    );
  };

  menuColumns = (idx, menuItemsArray, imageWithLink = null) => {
    return (
      <React.Fragment key={idx}>
        {menuItemsArray.map((item, menuItemsIdx) => {
          return (
            <div key={menuItemsIdx} className="menu-item__col">
              <div className="menu-item__section">
                <h5>{item.title}</h5>
                <div className="menu-item__column">
                  {item.subItems.map((i) => {
                    return this.individualMenuItem(i);
                  })}
                </div>
              </div>
            </div>
          );
        })}
        {imageWithLink && (
          <div className="menu-item__col">
            <a href={imageWithLink.link}>
              <img src={imageWithLink.image} />
            </a>
          </div>
        )}
      </React.Fragment>
    );
  };

  individualMenuItem(item) {
    return (
      <div
        className={`menu-item__container${item.mainNavigation ? " main-item" : ""}${
          item.variant === "emphasized" ? " emphasized" : ""
        }`}
        key={item.link}
      >
        <div className="menu-item__strip" style={{ background: "black" }} />
        <a
          className="menu-item__link"
          href={item.link}
          rel={item.target ? "noreferrer" : ""}
          target={item.target ? item.target : "_self"}
        >
          {item.title}
        </a>
      </div>
    );
  }

  menuSubItems = (activeMenuItem) => activeMenuItem.subItems.map((s) => this.individualMenuItem(s));

  closeMenuItemsAndOpenSearch = () => {
    this.setState({ activeMenuItem: null });
    this.props.openSearch();
  };

  render() {
    const { activeMenuItem } = this.state;
    let logo = this.props.logoSize === "large" ? LOGO : LOGO_SMALL;
    if (this.props.isProductBundlesPage) {
      logo = this.props.logoSize === "large" ? LOGO_LIGHT : LOGO_SMALL_LIGHT;
    }

    return (
      <>
        <div className="header__menu">
          <>
            <div className="header__logo">
              <a href="/">
                <img src={logo} alt="JustinGuitar Logo" />
              </a>
            </div>
            {headerMenuItems.menu.items.map((o) => {
              const thisItemIsActive = activeMenuItem === o;
              return (
                <div
                  key={o.title}
                  className={`header__option ${
                    thisItemIsActive
                      ? "header__option--active"
                      : activeMenuItem && !thisItemIsActive
                      ? "header__option--greyed-out"
                      : ""
                  }`}
                >
                  {o.dropdown === false ? (
                    <a className="uppercase" href={o.link}>
                      {o.title}
                    </a>
                  ) : (
                    <div className="menu-item" onClick={() => this.toggleMenuItem(o)}>
                      <span>{o.title}</span>
                      <i className={"icon icon-angle-" + (thisItemIsActive ? "up" : "down")} />
                    </div>
                  )}
                  {activeMenuItem && thisItemIsActive && (
                    <div className={"header__dropdown " + activeMenuItem.title.toLowerCase()}>
                      <div className="header__items">{this.activeMenuDropdown(activeMenuItem)}</div>
                    </div>
                  )}
                </div>
              );
            })}
            <i
              onClick={() => this.closeMenuItemsAndOpenSearch()}
              className="icon icon-search-thin"
            />

            <AuthButtons showUserIcon={true} />
          </>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ searchOpen, currentUser }) => ({ searchOpen, currentUser });

export default connect(mapStateToProps, { openSearch })(onClickOutside(LargeHeader));
