import React from "react";
import { CDN_URL } from "../../components/shared/CdnUrl";
import Albums from "./Albums";
import SpotifyIcon from "./SpotifyIcon";
import ProjectsSwoosh from "./ProjectsSwoosh";
import TemporaryArtistSwoosh from "./TemporaryArtistSwoosh";

const projects = {
  wcas: {
    title: "we came as strangers videos",
    videos: [
      {
        placeholderUrl: `${CDN_URL}/redesign/about/wcas-main.png`,
        youtubeId: "GDDfDfttmcQ",
        title: ["Shattered Matter", "Promo Video"],
      },
      {
        placeholderUrl: `${CDN_URL}/redesign/about/wcas-video-thumb-2.png`,
        youtubeId: "fgboB8OFrrw",
        title: ["Free Fall", "[Music Video]"],
      },
      {
        placeholderUrl: `${CDN_URL}/redesign/about/wcas-video-thumb-3.png`,
        youtubeId: "CV2K7aOSNlk",
        title: ["Free Fall", "[Lyric Video]"],
      },
    ],
  },
  otherArtists: {
    title: "performances with other artists",
    videos: [
      {
        placeholderUrl: `${CDN_URL}/redesign/about/wcas-main.png`,
        youtubeId: "GDDfDfttmcQ",
        title: "pity with the roses [live]",
      },
      {
        placeholderUrl: `${CDN_URL}/redesign/about/wcas-video-thumb-2.png`,
        youtubeId: "fgboB8OFrrw",
        title: "pity with the roses [live]",
      },
      {
        placeholderUrl: `${CDN_URL}/redesign/about/wcas-video-thumb-3.png`,
        youtubeId: "CV2K7aOSNlk",
        title: "pity with the roses [live]",
      },
    ],
  },
};

export default class Musician extends React.Component {
  render() {
    return (
      <div className="about__body about__body--musician">
        <div className="about__body__content">
          <div className="about__body__content__description">
            <h2 className="uppercase header2 text-center">my band project</h2>
            <p>
              My main band project is a Trip Hop band called We Came As Strangers.
              <br />
              We have three albums out. Each one was written and recorded in just 2 weeks with 3 of
              my bestest friends, Ellem on vocals, Tim Harries on bass and Owen Thomas on drums,
              keys and production.
            </p>
            <img
              className="wcas-logo"
              src={`${CDN_URL}/redesign/about/wcas-logo.png`}
              alt="We Came As Strangers"
            />
          </div>

          <Albums />

          <div className="about__body__content__media">
            <a
              href="https://open.spotify.com/artist/56vg5xww6JoTEwMQKru6T0?si=UFkslkMiTnGqi6TfKnvMBQ&dl_branch=1"
              target="_blank"
            >
              <SpotifyIcon />
            </a>
          </div>
        </div>

        <TemporaryArtistSwoosh />

        {/*
          TODO: add this back once we have the videos
            <ProjectsSwoosh data={projects.wcas} additionalClass="projects-swoosh--wcas" />
        */}

        {/*
          TODO: Remove TemporaryArtistSwoosh and use the below, once we have the swoosh content above/below it.
          <div className="about__body__content">
            <div className="about__body__content__other-artists">
              <h2 className="uppercase header2 text-center">playing and writing for other artists</h2>
              <p>
                For many years I worked as a 'sideman' to various artists. So here are some songs and
                video's of me playing with them! During this time I learned a lot about writing and
                the "business" of music.
              </p>
              <div className="about__body__content__other-artists__description">
                <div className="about__body__content__other-artists__description__image">
                  <img src={`${CDN_URL}/redesign/about/katie-melua.png`} alt="Katie Melua" />
                  <h3>
                    Turn To Tell <span>Katie Melua</span>
                  </h3>
                </div>
                <p className="about__body__content__other-artists__description__about">
                  I produced and wrote tracks for Katie Melua and played electric and acoustic guitar
                  in her live band between 2005 and 2008. I wrote her song Turn To Tell which earned
                  me my first platinum disc!!
                  <br />
                  <br />I also taught her guitar from the start of her career.
                </p>
              </div>
              <div className="about__body__content__other-artists__media">
                <a
                  className="clickable"
                  target="_blank"
                  href="https://open.spotify.com/track/5RpRsYDSSOofokn36VjpBV?si=53a48656d7bd4452"
                >
                  listen on spotify
                </a>
              </div>
            </div>
          </div>
        */}

        {/*
          <ProjectsSwoosh
            data={projects.otherArtists}
            additionalClass="projects-swoosh--performances-with-other-artists"
          />

          */}
      </div>
    );
  }
}
