import React from "react";
import { connect } from "react-redux";
import Swoosh from "../components/shared/Swoosh";
import { heroGradient, swooshBackgroundImg } from "./store/parentGroupSelectors";
import { withInnerWidth } from "../components/shared";
import AppSwooshBannerForCourses from "../components/shared/AppSwooshBannerForCourses";
import axiosWithCSRF from "../components/shared/axiosWithCSRF";
import ParentGroupBreadcrumb from "./swoosh/ParentGroupBreadcrumb";
import PMTButton from "./swoosh/PMTButton";

export const PMT_REFERENCE = "PMT";
const APP_BADGE_REFERENCES = ["BG1", "BG2", "BG3"];
const LIGHT_BACKGROUNDS = ["BG1"];
const GRADE_THREE_REFERENCE = "BG3";

const PMT_STYLE = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const GRADIENTS_BY_REFERENCE = {
  BG1: "grade1",
  BG2: "grade2",
  BG3: "grade3",
  IF4: "grade4",
  IF5: "grade5",
  INM: "grade6",
  ADV: "grade7",
};

const Title = ({ preTitle, title, isMainCourseByLevel }) => {
  const displayPreTitle = preTitle && !isMainCourseByLevel;
  return (
    <>
      {displayPreTitle && <h3 className="header3 uppercase">{preTitle}</h3>}
      <h1 className="header1 uppercase">{title}</h1>
    </>
  );
};

const DroppedImage = ({ isMainCourseByLevel, heroRightImage, title }) => {
  if (!isMainCourseByLevel || heroRightImage?.length > 0 !== true) return null;

  return <img className="swooosh-right__img" alt={title} src={heroRightImage} />;
};

const FloatRightImage = ({ isMainCourseByLevel, heroRightImage, title }) => {
  if (isMainCourseByLevel || heroRightImage?.length > 0 !== true) return null;
  return (
    <div className="swooosh-right__container">
      <img className="swooosh-right__img" alt={title} src={heroRightImage} />
    </div>
  );
};

const AppBannerImage = ({ reference }) => {
  if (APP_BADGE_REFERENCES.includes(reference) === false) return null;
  return (
    <AppSwooshBannerForCourses
      ctaUrl={
        reference === GRADE_THREE_REFERENCE
          ? "https://go.onelink.me/3065804798/grade3"
          : `https://justinguitarapp.musopia.net`
      }
    />
  );
};

const ParentGroupHero = ({
  heroGradient,
  parentGroup,
  swooshBackgroundImg,
  width,
  currentUser = null,
  enrolledCount = 0,
  hasPMTEnrollment,
}) => {
  const { data: { attributes: { title, preTitle, reference, heroRightImage } } = ({} = {}) } =
    parentGroup;

  const isMainCourseByLevel = Object.keys(GRADIENTS_BY_REFERENCE).includes(reference);

  return (
    <div
      className={`pg__swoosh${isMainCourseByLevel ? " main-course" : ""}${
        APP_BADGE_REFERENCES.includes(reference) ? " with-app-banner" : ""
      }`}
    >
      <Swoosh
        gradient={heroGradient}
        imgUrl={swooshBackgroundImg}
        hasBreadcrumb={true}
        additionalHeight={reference === PMT_REFERENCE && width <= 576 ? 150 : 0}
      >
        <div className="container">
          <div className="pg__sc">
            <ParentGroupBreadcrumb
              reference={reference}
              swooshBackgroundImg={swooshBackgroundImg}
              preTitle={preTitle}
            />

            <div className="pg__contain">
              <div
                className={
                  "pg__heading " +
                  (LIGHT_BACKGROUNDS.includes(reference) ? "pg__heading--dark" : "")
                }
                style={reference === PMT_REFERENCE ? PMT_STYLE : null}
              >
                <Title
                  preTitle={preTitle}
                  title={title}
                  isMainCourseByLevel={isMainCourseByLevel}
                />

                <DroppedImage
                  title={title}
                  isMainCourseByLevel={isMainCourseByLevel}
                  heroRightImage={heroRightImage}
                />

                <PMTButton
                  reference={reference}
                  hasPMTEnrollment={hasPMTEnrollment}
                  currentUser={currentUser}
                  enrolledCount={enrolledCount}
                />
              </div>

              <FloatRightImage
                title={title}
                heroRightImage={heroRightImage}
                isMainCourseByLevel={isMainCourseByLevel}
              />
            </div>

            <AppBannerImage reference={reference} />
          </div>
        </div>
      </Swoosh>
    </div>
  );
};

const mapStateToProps = (state) => ({
  parentGroup: state.parentGroup.parentGroup,
  heroGradient: heroGradient(state),
  swooshBackgroundImg: swooshBackgroundImg(state),
  enrolledCount: state.parentGroup.enrolledCount,
});

export default connect(mapStateToProps)(withInnerWidth(ParentGroupHero));
