import React from "react";
import PropTypes from "prop-types";
import axiosWithCSRF from "../shared/axiosWithCSRF";
import ReactDOM from "react-dom";
import Loading from "../shared/Loading";
import Recaptcha from "react-recaptcha";
import { Formik, Form, Field } from "formik";
import RegistrationSchema from "./RegistrationSchema";
import UsernameSelection from "./UsernameSelection";
import TermsConsent from "./TermsConsent";
import NewsletterConsent from "./NewsletterConsent";
import loadScript from "../shared/loadScript";

export default class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { recaptchaToken: null, showDisclosure: false };

    this.formRef = React.createRef();
    this.recaptchaInstance = React.createRef();
  }

  componentDidMount() {
    loadScript("https://www.google.com/recaptcha/api.js");
  }

  recaptchaCallback = () => {
    // for some reason verifyCallback is only working if onloadCallback is defined, so define it here and do nothing.
    return;
  };

  recaptcha = (props) => {
    return (
      <Recaptcha
        render="explicit"
        ref={this.recaptchaInstance}
        sitekey={this.props.recaptchaKey}
        onloadCallback={this.recaptchaCallback}
        verifyCallback={(token) => this.recaptchaVerified(token, props)}
      />
    );
  };

  recaptchaVerified = (recaptchaToken, props) => {
    if (props.values.termsConsent != true) {
      props.setFieldTouched("termsConsent");
    }
    this.setState({ recaptchaToken });
  };

  formDisabled = (props) => {
    if (
      props.dirty &&
      this.state.recaptchaToken &&
      Object.keys(props.errors).length === 0 &&
      !this.state.isSubmitting
    ) {
      return false;
    } else {
      return true;
    }
  };

  inputs = (props) => {
    let fields = [
      { name: "firstName", label: "First Name", type: "text" },
      { name: "lastName", label: "Last Name", type: "text" },
      { name: "email", label: "Email", type: "email" },
      { name: "username", label: "Username", type: "username" },
      { name: "password", label: "Password", type: "password" },
      { name: "passwordConfirmation", label: "Password Confirmation", type: "password" },
    ];

    return fields.map((f) => {
      let { errors, touched, values } = props;
      return (
        <div className="auth__input" key={f.name}>
          {f.name === "username" ? (
            <UsernameSelection
              formikProps={props}
              usernameError={this.state.currentUsernameError}
              token={this.props.token}
              removeUsernameError={() => this.setState({ usernameError: false })}
              setUsernameError={() => this.setState({ usernameError: true })}
            />
          ) : (
            <>
              <Field
                name={f.name}
                type={f.type}
                className={
                  (values[f.name].length > 0 ? "populated" : "") +
                  (errors[f.name] && touched[f.name] ? " has-error" : "")
                }
              />

              {touched[f.name] && !errors[f.name] && (
                <i className="auth__response-icon icon icon-check"></i>
              )}
              <span className="auth__label">{f.label}</span>
              {errors[f.name] && touched[f.name] && (
                <>
                  <i className="auth__response-icon icon icon-cancel-circle"></i>
                  <div className="error-message">{errors[f.name]}</div>
                </>
              )}
            </>
          )}
        </div>
      );
    });
  };

  handleSubmit = (values, setFieldValue) => {
    this.setState({ isSubmitting: true }, () => {
      axiosWithCSRF()
        .post("/users", {
          user: {
            first_name: values.firstName.trim(),
            last_name: values.lastName.trim(),
            email: values.email.trim(),
            password: values.password,
            password_confirmation: values.password_confirmation,
            data_consent: values.termsConsent,
            email_consent: values.newsletterConsent,
            username: values.username.trim(),
          },
          ["g-recaptcha-response"]: this.state.recaptchaToken,
          authenticity_token: this.props.token,
        })
        .then((response) => {
          window.location.href = `/successfully-signed-up?user=${values.username.trim()}`;
        })
        .catch((error) => {
          let data = error.response.data;
          if (data.error_fields && data.error_fields.length) {
            data.error_fields.forEach((f) => setFieldValue(f, ""));
          }
          let submitError = data.error || "There was a problem creating your account.";
          this.recaptchaInstance.current.reset();
          this.setState({ submitError, isSubmitting: false }, () => {
            this.formRef.current.scrollIntoView({ behavior: "smooth" });
            setTimeout(() => this.setState({ submitError: null }), 4000);
          });
        });
    });
  };

  toggleDisclosure = () => {
    this.setState({ showDisclosure: !this.state.showDisclosure });
  };

  render() {
    return (
      <div className="auth" ref={this.formRef}>
        <div className="auth__container">
          {!this.state.showDisclosure ? (
            <>
              {this.state.isSubmitting && <Loading />}
              <h3 className="header3 uppercase auth__heading">Register</h3>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  password: "",
                  passwordConfirmation: "",
                  username: "",
                  newsletterConsent: false,
                  termsConsent: false,
                }}
                validationSchema={RegistrationSchema}
                onSubmit={(values, { setFieldValue }) => {
                  this.handleSubmit(values, setFieldValue);
                }}
              >
                {(props) => (
                  <Form className="auth__form">
                    {this.state.submitError && (
                      <p className="auth__top-level-error">{this.state.submitError}</p>
                    )}
                    {this.inputs(props)}

                    <TermsConsent formikProps={props} />
                    <NewsletterConsent />

                    <div className="auth__recaptcha">{this.recaptcha(props)}</div>

                    <button
                      disabled={this.formDisabled(props)}
                      className="button button--primary auth__btn"
                      type="submit"
                    >
                      Create My Account
                    </button>

                    <div className="auth__signup">
                      <p>
                        Already have an account? <a href="/users/sign_in">Log in</a>
                      </p>
                      <p>
                        Coming from my Lessons & Songs App?{" "}
                        <button onClick={() => this.toggleDisclosure()}>Read this</button>
                      </p>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <div className="auth__disclosure">
              <h3 className="header3">Coming from my App?</h3>
              <p>Here are a few things you might want to know:</p>
              <p>
                <strong>JustinGuitar Songs & Lessons App</strong> and{" "}
                <strong>JustinGuitar.com</strong> are not synced yet. This means that your progress
                is not shared between them! You'll also need to create different accounts if you
                wish to use both platforms. You can use the same email address and password if you
                wish, but you still need two accounts.
                <br />
                <br />I know this is not ideal, but our dev teams are working to simplify things.
                Still, you can make the most out of my content and learn faster by combining both
                tools. For example, more advanced students might follow Intermediate and Theory
                lessons on the website but keep using the app for song practice. ;)
                <br />
                <br />
                Joining JustinGuitar.com is free, and most content has no paywall. But please be
                aware that subscribing to my Lessons and Songs App doesn’t give you access to
                Premium Products available on the website.
                <br />
                <br />
                Ready? Register Now!
              </p>
              <div className="controls">
                <button onClick={() => this.toggleDisclosure()}>back to register</button>
                <p>
                  Already have an account? <a href="/users/sign_in">Log in</a>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
