import React from "react";
import { productSubtypes, productTypes, productsFilteredByType } from "./productTypes";
import ProductList from "./ProductList";
import { PromoItem } from "../justins-corner/PromoItem";
import { CDN_URL } from "../components/shared/CdnUrl";
import Category from "../pages/FAQ/Category";

export default function ({ products, booksFaqs }) {
  const books = productsFilteredByType(products, productTypes.book);

  const songBooks = books.filter(
    (product) => product.data.attributes.subtype === productSubtypes.songbook
  );
  const techniqueBooks = books.filter(
    (product) => product.data.attributes.subtype === productSubtypes.technique
  );

  return (
    <div className="products-listing products-listing--books container">
      <h2>Songbooks</h2>
      <ProductList products={songBooks} />

      {/* <div className="childrens-songbook-banner">
        <div>
          <img
            src={`${CDN_URL}/images/pages/childrens-songs/101-childrens-songs-for-parents-bundle-2.png`}
            alt="101 Children’s Songs for Parents"
          />
        </div>
        <div>
          <h3>Children’s Songs eBooks</h3>
          <p>
            Make amazing memories with your children by playing songs they'll love to sing along
            with! 101 Children’s Songs has two editions, one on Guitar and one on Ukulele.
          </p>
          <a href="/products/101-childrens-songs">Learn more</a>
        </div>
      </div> */}

      <h2>Technique Books</h2>
      <ProductList products={techniqueBooks} />

      <div className="faq">
        <Category
          faqs={booksFaqs}
          showHeader={false}
          useUrlRouting={false}
          showBreadcrumbs={false}
        />
      </div>
    </div>
  );
}
