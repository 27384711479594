import React from "react";
import { connect } from "react-redux";

import {
  fetchSearchResults,
  resultMergeStrategies,
  selectMetadataForType,
  setVisibleType,
} from "./store";
import * as PropTypes from "prop-types";
import Loading from "../shared/Loading";
import ResultSectionList from "./ResultSectionList";
import {
  searchResultsTypeToDefaultListLength,
  searchResultsTypeToDisplayName,
} from "../shared/search";

export class ResultSection extends React.Component {
  constructor(props) {
    super(props);
    this.showMoreButtonRef = React.createRef();
    this.header = React.createRef();
    this.handlingScroll = false;
  }

  componentDidMount() {
    const { type, visibleType } = this.props;
    if (type === visibleType) {
      this.fetchNextPage();
      this.initInfiniteScroll();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const wasVisible = prevProps.visibleType === this.props.type;
    const isNowVisible = this.props.type === this.props.visibleType;
    if (!wasVisible && isNowVisible) {
      this.header.current.scrollIntoView({ block: "start", behavior: "smooth" });
      this.initInfiniteScroll();
      this.onScroll();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll);
  }

  fetchNextPage = () => {
    const { metadata, fetchPage } = this.props;
    const page = metadata && metadata.page ? Number(metadata.page) + 1 : 1;
    fetchPage(page);
  };

  shouldShowViewMore() {
    const { visibleType, type, metadata, loading } = this.props;
    if (loading || metadata == null || visibleType != null) {
      return false;
    }

    if (visibleType == null) {
      return searchResultsTypeToDefaultListLength[type] < metadata.totalCount;
    }

    return this.hasMoreResultsToFetch();
  }

  hasMoreResultsToFetch() {
    const { metadata } = this.props;
    if (metadata == null) {
      return false;
    }
    const { totalCount, page = 1, perPage } = metadata;
    return page * perPage < totalCount;
  }

  loadingIndicator() {
    return (
      <span style={{ position: "relative" }}>
        <Loading isInput={true} />
      </span>
    );
  }

  showMoreButtonIsInViewport() {
    const { top } = this.showMoreButtonRef.current.getBoundingClientRect();
    const buffer = 0.8;
    return top * buffer <= (window.innerHeight || document.documentElement.clientHeight);
  }

  initInfiniteScroll() {
    document.addEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    if (!this.handlingScroll) {
      window.requestAnimationFrame(() => {
        if (
          this.hasMoreResultsToFetch() &&
          this.showMoreButtonIsInViewport() &&
          !this.props.loading
        ) {
          this.fetchNextPage();
        }
        this.handlingScroll = false;
      });

      this.handlingScroll = true;
    }
  };

  render() {
    const { metadata, type, loading, visibleType, toggleTypeVisibility } = this.props;
    return (
      <div className={`search-results__section search-results__section--${type}`}>
        <h2 ref={this.header} className="header2">
          {searchResultsTypeToDisplayName[type]}{" "}
          {loading
            ? this.loadingIndicator()
            : metadata && metadata.totalCount
            ? `(${metadata.totalCount})`
            : null}
        </h2>

        <ResultSectionList type={type} />

        {this.shouldShowViewMore() && (
          <div className="view-more" onClick={toggleTypeVisibility}>
            <span>View More</span>
          </div>
        )}
        {type === visibleType && this.hasMoreResultsToFetch() && (
          <div
            className="button button--no-background load-more"
            onClick={this.fetchNextPage}
            ref={this.showMoreButtonRef}
          >
            {loading ? (
              this.loadingIndicator()
            ) : (
              <span>
                show more results <i className="glyphicon glyphicon-arrow-down" />
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}

ResultSection.propTypes = {
  metadata: PropTypes.any,
  type: PropTypes.any,
  visibleType: PropTypes.any,
  toggleTypeVisibility: PropTypes.any,
  loading: PropTypes.bool,
};

export default connect(
  (state, { type }) => ({
    metadata: selectMetadataForType(state)(type),
    visibleType: state.visibleType,
    loading: state.loading[type],
  }),
  (dispatch, { type }) => ({
    toggleTypeVisibility() {
      dispatch(setVisibleType(type));
    },
    fetchPage(page = 1) {
      dispatch(
        fetchSearchResults({
          mergeStrategy: resultMergeStrategies.append,
          page,
        })
      );
    },
  })
)(ResultSection);
