import React from "react";
import PropTypes from "prop-types";

export const FilterOption = ({ option, active, toggleFilter }) => (
  <div
    className={`filters__option ${active && "filters__option--active"}`}
    data-option={option}
    onClick={toggleFilter}
  >
    <i className={`icon ${active ? "icon-checkbox-checked" : "icon-checkbox-unchecked"}`} />
    <span>{option}</span>
  </div>
);

FilterOption.propTypes = {
  option: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  toggleFilter: PropTypes.func.isRequired,
};

export default FilterOption;
