import React, { useCallback } from "react";
import * as moment from "moment";
import { capitalize } from "../shared/utils";
import { withInnerWidth } from "../../components/shared";
import { BootstrapSize, breakpoints } from "../../components/shared/bootstrap-helpers";

const DATE_FORMAT_WITH_TIME = "MMM DD, GGGG • HH:mm";

const UserNote = ({
  activeNote,
  setActiveNote,
  editingActiveNote,
  setEditingActiveNote,
  goToNoteableType,
  saveNote,
  openDialog,
  width,
}) => {
  // Trigger onChange manually, to set textarea size to fit contents
  const textInput = useCallback(
    (node) => {
      if (node !== null) {
        if ("createEvent" in document) {
          const event = new Event("input", { bubbles: true });
          node.dispatchEvent(event);
        }
      }
    },
    [editingActiveNote]
  );

  return (
    <div className="note">
      <p>{activeNote.attributes.noteableLevel}</p>
      <h2>{activeNote.attributes.noteableTitle}</h2>
      <div className="note__container">
        {!editingActiveNote ? (
          <div className="note__content">
            <p>{activeNote.attributes.content}</p>
          </div>
        ) : (
          <div className="note__content note__content--edit">
            <div className="grow-wrap">
              <textarea
                ref={textInput}
                rows="1"
                value={activeNote.attributes.content}
                placeholder="Begin typing your note here..."
                onInput={(e) => (e.target.parentNode.dataset.replicatedValue = e.target.value)}
                onChange={(e) =>
                  setActiveNote({
                    ...activeNote,
                    attributes: {
                      ...activeNote.attributes,
                      content: e.target.value,
                    },
                  })
                }
              ></textarea>
            </div>
          </div>
        )}
        {width <= breakpoints[BootstrapSize.smDevice] && (
          <p className="note__time">
            {moment(activeNote.attributes.createdAt).format(DATE_FORMAT_WITH_TIME)}
          </p>
        )}
        <div className="note__menu">
          <button
            onClick={() => setEditingActiveNote(true)}
            className={editingActiveNote ? "active" : ""}
          >
            <i className="icon icon-pencil"></i>Edit
          </button>
          <button
            onClick={() => {
              openDialog({
                message: "Delete this note?",
                confirmButtonText: "Delete",
                data: activeNote,
              });
            }}
          >
            <i className="icon icon-bin"></i>Delete
          </button>
          <button onClick={() => goToNoteableType(activeNote)}>
            <i className="icon icon-play"></i>Go To{" "}
            {capitalize(activeNote.attributes.noteableType.replace(/group/gi, "module"))}
          </button>
        </div>
      </div>
      {width > breakpoints[BootstrapSize.smDevice] && (
        <p className="note__time">
          {moment(activeNote.attributes.createdAt).format(DATE_FORMAT_WITH_TIME)}
        </p>
      )}
      {editingActiveNote && (
        <button onClick={() => saveNote(activeNote)} className="note__save-btn">
          Save
        </button>
      )}
    </div>
  );
};

export default withInnerWidth(UserNote);
