import React from "react";
import ReactOnRails from "react-on-rails";
import { Provider } from "react-redux";
import GroupHeader from "./GroupHeader";
import GroupTabs from "./GroupTabs";
import GroupNavigation from "./GroupNavigation";
import PromoItems from "../components/shared/PromoItems";

require("./store/groupStore");

export default function () {
  const store = ReactOnRails.getStore("groupStore");

  return (
    <Provider store={store}>
      <div className="group">
        <GroupHeader />
        <GroupTabs />
        <GroupNavigation />
        <PromoItems promoItems={store.getState().entity.promoItems} />
      </div>
    </Provider>
  );
}
