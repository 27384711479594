import React, { useEffect, useState } from "react";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import { connect } from "react-redux";
import { setActiveTab } from "../store/dashboardStore";
import Practice from "../../components/practice/Practice";
import Loading from "../../components/shared/Loading";
import Snackbar from "../../components/shared/ui/Snackbar";
import { openAlert } from "../../components/shared/ui/uiSlice";

const PracticeTab = ({ currentUser, setActiveTab, openAlert }) => {
  const [practiceData, setPracticeData] = useState(null);
  useEffect(() => {
    axiosWithCSRF()
      .get(`/users/${currentUser.data.attributes.slug}/practice_routine_data`)
      .then((res) => {
        setPracticeData(res.data);
      })
      .catch(() => {
        openAlert({
          message:
            "There was a problem loading your practice assistant data. Please try again later.",
          severity: "error",
        });
      });
  }, []);

  return (
    <>
      <button className="back-btn" onClick={() => setActiveTab("Dashboard")}>
        <i className="icon icon-arrow-left"></i> Dashboard
      </button>
      <h1 className="dashboard__heading">
        <button className="back-btn" onClick={() => setActiveTab("Dashboard")}>
          <i className="icon icon-arrow-left"></i>
        </button>
        My Practice Assistant
      </h1>
      <div className="practice">{practiceData ? <Practice {...practiceData} /> : <Loading />}</div>
      <Snackbar />
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
  openAlert: (alert) => dispatch(openAlert(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticeTab);
