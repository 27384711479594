import axiosWithCSRF from "../components/shared/axiosWithCSRF";
import { CHECKOUT_SESSION_URL } from "./consts";

export const createSalesCheckoutSession = (discountId = null, pricingPlanIds, cancelUrl) => {
  return axiosWithCSRF().post(CHECKOUT_SESSION_URL, {
    discount_id: discountId,
    pricing_plan_ids: pricingPlanIds,
    cancel_url: cancelUrl,
  });
};

export const discountForPlan = (plan, pricingPlans, discountType) => {
  if (!plan) return null;

  return pricingPlans.included.find(
    (i) =>
      i.type === "discount" &&
      i.attributes.pricingPlanId === Number(plan.id) &&
      i.attributes.discountType === discountType
  );
};
