import React from "react";
import { formatNumber } from "../../utilities/misc";

const Header = ({ currentUser, enrolledCount, enrollmentSuccess }) => {
  const hasEnrollment = () => {
    if (!currentUser) return false;

    const { enrollments, hasActivePmtSubscription } = currentUser.data.attributes;
    if (enrollments.includes("PMT") || hasActivePmtSubscription || enrollmentSuccess) {
      return true;
    }
    return false;
  };
  return (
    <div className="header">
      <div className="breadcrumb">
        <a href="/store">back to store</a>
      </div>
      <h1>Practical Music Theory</h1>
      {hasEnrollment() ? (
        <div
          className="enrolled-badge"
          onClick={() => (window.location = "/classes/practical-music-theory-course-fast-hands-on")}
        >
          already enrolled
        </div>
      ) : (
        <a className="button button--primary" href="#plans">
          enroll now
        </a>
      )}
      <p>
        <strong>{formatNumber(enrolledCount)}</strong> currently enrolled.
      </p>
    </div>
  );
};

export default Header;
