import React from "react";
import { connect } from "react-redux";
import WelcomeBack from "./WelcomeTab/WelcomeBack";
import FavoritesTab from "./FavoritesTab";
import NotesTab from "./NotesTab";
import AccountTab from "./AccountTab";
import SongbookTab from "./SongbookTab";
import PracticeTab from "./PracticeTab";
import AllNewLessons from "./WelcomeTab/AllNewLessons";
import LearningJourney from "./LearningJourneyTab";
import AppTab from "./AppTab";

const ActiveTab = ({ activeTab }) => {
  switch (activeTab) {
    case "dashboard":
      return <WelcomeBack />;
    case "latest":
      return <AllNewLessons />;
    case "practice":
      return <PracticeTab />;
    case "favorites":
      return <FavoritesTab />;
    case "songbook":
      return <SongbookTab />;
    case "notes":
      return <NotesTab />;
    case "account":
      return <AccountTab />;
    case "journey":
      return <LearningJourney />;
    case "app":
      return <AppTab />;
    default:
      return <WelcomeBack />;
  }
};

export default connect((state) => ({
  activeTab: state.entity.dashboard.activeTab,
}))(ActiveTab);
