import React from "react";
import { CDN_URL } from "../../../components/shared/CdnUrl";
import LoginPrompt from "../../../components/auth/LoginPrompt";
import ReactPlayer from "react-player";

function Welcome({ token }) {
  return (
    <div className="strumming-machine-welcome">
      {/*<img*/}
      {/*  src={`${CDN_URL}/images/components/strumming-machine/strumming-machine-logo.png`}*/}
      {/*  alt="Strumming Machine Logo"*/}
      {/*/>*/}
      <div className="video">
        <ReactPlayer
          url="https://youtu.be/u6mm7zFRxKQ"
          controls={true}
          // width="100%"
          allowFullScreen={true}
          // height="100%"
          // ref={videoRef}
          // className="react-player"
        />
      </div>
      <p>
        Play along a selection of popular strumming patterns curated by Justin or build your own!
      </p>
      <p>
        <img
          src={`${CDN_URL}/images/components/strumming-machine/click-icon.png`}
          alt="Click Icon"
        />{" "}
        <span>
          Log in to access the Strumming Machine. It’s <strong>FREE</strong>!
        </span>
      </p>
      <LoginPrompt token={token} />
    </div>
  );
}

export default Welcome;
