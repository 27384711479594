import ReactOnRails from "react-on-rails";
import { configureStore, createSlice, combineReducers } from "@reduxjs/toolkit";
import { ui } from "../../../components/shared/ui/uiIndex";
import { session } from "../../../components/shared/session/sessionIndex";

const initialState = (props) => {
  return {
    entity: {
      playground: {},
    },
  };
};

const initialSessionState = (props) => {
  return {
    session: { currentUser: props.currentUser },
  };
};

const playgroundSlice = createSlice({
  name: "playground",
  initialState,
  reducers: {},
});

export const {} = playgroundSlice.actions;

const reducer = combineReducers({
  entity: playgroundSlice.reducer,
  ui: ui.reducer,
  session: session.reducer,
});

const createPlaygroundStore = (preloadedState) =>
  configureStore({
    reducer,
    preloadedState,
  });

ReactOnRails.registerStore({
  playgroundStore: (props, railsContext) => {
    return createPlaygroundStore({
      entity: {
        ...props.entity,
      },
      ui: {
        ...ui.initialState,
        ...props.ui,
      },
      session: {
        ...initialSessionState,
        ...props.session,
      },
    });
  },
});
