export const inDev = window.location.href.includes("localhost");
export const inStaging = window.location.href.includes("staging.justinguitar.com");

// staging uses the same recaptcha as production
export const RECAPTCHA_KEY = inDev
  ? "6LcxoHIpAAAAAP412Dh72TlbjBKV7VJs1CdBWiKY"
  : "6Le0n3IpAAAAAE4sDbfnPsflfyLFU-Hyxy4vj5mP";

export const STRIPE_SALES_PUBLISHABLE_KEY =
  inDev || inStaging ? "pk_test_U0qv3vKbEQlsrhitRyVta3ZV" : "pk_live_q38RbNJwSIwuopDalCBVDKYW";

export const STRIPE_DONATION_PUBLISHABLE_KEY =
  inDev || inStaging ? "pk_test_b6XljiClC7k7oDrW8aEyWWaf" : "pk_live_bzGRfVuaUb99OISNIari9jdb";

export const STRIPE_TABS_PUBLISHABLE_KEY =
  inDev || inStaging
    ? "pk_test_51IaJbIF1dLDLVnhOYPVJUiUN0ZWxboTR90EdVJxbWURhRZXNIoLHxqvTfIy8cIoRIxCIH7PGhtJqktCFGEsUBOyu00h8YzPUyL"
    : "pk_live_51IaJbIF1dLDLVnhOeCnrVBlI9y8riy4B39TrQFDqJ1IhdN9AcdbfAZ6xq2VNbZbZvdZ0jWLeeLv2u6l1cefJPZgM007vfhaJ0c";

export const TABS_PURCHASE_REFERENCES = [
  "price_1IaO0YF1dLDLVnhOTRr73EZo",
  "price_1IaO0XF1dLDLVnhOZQXgQuqc",
  "price_1IaO01F1dLDLVnhO0eiVApAC",
  "price_1IaO01F1dLDLVnhOa6aLHAg9",
];

export const DONATION_PURCHASE_REFERENCE = "DONATION";
export const WORKSHOP_DEPOSIT_PURCHASE_REFERENCE = "WORKSHOP_DEPOSIT";
export const WORKSHOP_PURCHASE_TYPE = "workshop-deposit";
