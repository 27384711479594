import React from "react";
import { Elements } from "react-stripe-elements";

import CheckoutForm from "../components/CheckoutForm";

export default class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: this.props.formOpen,
    };
  }

  toggleForm() {
    this.setState({ showForm: !this.state.showForm });
  }

  moneyFormat(amount) {
    return amount.split(".")[1] == "0" ? amount.split(".")[0] : amount;
  }

  render() {
    const fontArray = [{ cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:400" }];

    return (
      <div>
        {this.state.showForm === false && (
          <button
            className="purchase-access header5 uppercase button-spacing"
            onClick={() => this.toggleForm()}
          >
            {" "}
            BUY NOW
          </button>
        )}

        {this.state.showForm === true && (
          <div>
            <div className="stripe-payment__security">
              <h4>Note about security</h4>I have moved to using the super secure Stripe payment
              gateway, no card details are stored on my site and SSL security encryption is used
              when you submit the details on the form (see the green padlockand "Secure" in the top
              of your browser and the https address which mean your link with the server is secure).
              But if you prefer to use PayPal,  please click this 
              <a href="/pmt-paypal">PMT PayPal link</a>.
            </div>

            <div className="stripe-payment__details">
              <h4>To Enter Payment Details</h4>
              <p>
                To enter your card number, just click slightly to the right of the card icon below
                (on the text where it says Card number) and enter your card number, click on the
                month and CCV text and fill them in too. Then hit pay now and you'll be rocking!
              </p>
            </div>

            <div className="stripe-payment__container">
              <h4 className="justin-blue uppercase no-margin header4 bold purchase-confirm-title">
                {this.props.subscription && (
                  <p>
                    Purchase access to {this.props.title} for ${this.moneyFormat(this.props.price)}{" "}
                    {this.props.frequency}
                  </p>
                )}
                {!this.props.subscription && (
                  <p>
                    Purchase lifetime access to {this.props.title} for $
                    {this.moneyFormat(this.props.price)}{" "}
                  </p>
                )}
              </h4>

              <Elements fonts={fontArray}>
                <CheckoutForm
                  price={this.props.price}
                  currency="USD"
                  reference={this.props.reference}
                  subscription={this.props.subscription}
                  frequency={this.props.frequency}
                  recaptchaKey={this.props.recaptchaKey}
                  stripeCustomerId={this.props.stripeCustomerId}
                  purchaseType="sales"
                />
              </Elements>
            </div>
          </div>
        )}
      </div>
    );
  }
}
