import React from "react";
import { connect } from "react-redux";

import { listingStyles, selectCurrentArtist, selectListingStyle, slices } from "./store";

export const SongsListingStyle = ({ selectedStyle, setStyle, shouldShow }) =>
  shouldShow && (
    <div className="listing-style">
      {Object.keys(listingStyles).map((style) => (
        <span
          className={`listing-style__selection ${
            selectedStyle === style ? "listing-style__selection--selected" : ""
          }`}
          onClick={() => setStyle(style)}
          key={style}
        >
          <i className={`icon icon-${style}`} />
        </span>
      ))}
    </div>
  );

export default connect(
  (state) => ({
    selectedStyle: selectListingStyle(state),
    shouldShow: selectCurrentArtist(state) == null,
  }),
  (dispatch) => ({
    setStyle: (style) => dispatch(slices.ui.actions.setListingStyle(style)),
  })
)(SongsListingStyle);
