import React from "react";
import { JgCarousel } from "../shared/Carousel";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { Box } from "@mui/material";
import { CDN_URL } from "../../../components/shared/CdnUrl";
import { WorkshopEntity } from "../interfaces";

const images: object = {
  imagesBaluchon: [
    `${CDN_URL}/redesign/workshops/acoustic-jam-quebec-carousel/baluchon-01.jpg`,
    `${CDN_URL}/redesign/workshops/acoustic-jam-quebec-carousel/baluchon-02.jpg`,
    `${CDN_URL}/redesign/workshops/acoustic-jam-quebec-carousel/baluchon-03.jpg`,
    `${CDN_URL}/redesign/workshops/acoustic-jam-quebec-carousel/baluchon-04.jpg`,
    `${CDN_URL}/redesign/workshops/acoustic-jam-quebec-carousel/baluchon-05.jpg`,
  ],
  imagesFrensham: [
    `${CDN_URL}/redesign/workshops/acoustic-electric-classics-carousel/frensham-01.jpg`,
    `${CDN_URL}/redesign/workshops/acoustic-electric-classics-carousel/frensham-02.jpg`,
    `${CDN_URL}/redesign/workshops/acoustic-electric-classics-carousel/frensham-03.jpg`,
    `${CDN_URL}/redesign/workshops/acoustic-electric-classics-carousel/frensham-04.jpg`,
    `${CDN_URL}/redesign/workshops/acoustic-electric-classics-carousel/frensham-05.jpg`,
  ],
  defaultImages: [
    `${CDN_URL}/redesign/workshops/lifestyle-carousel/hotel-castello-di-montegridolfo-05 1.png`,
    `${CDN_URL}/redesign/workshops/lifestyle-carousel/hotel-castello-di-montegridolfo-03 1.png`,
    `${CDN_URL}/redesign/workshops/lifestyle-carousel/castello-di-montegridolfo-hotel-05 1.png`,
    `${CDN_URL}/redesign/workshops/lifestyle-carousel/hotel-castello-di-montegridolfo-04 1.png`,
    `${CDN_URL}/redesign/workshops/lifestyle-carousel/hotel-castello-di-montegridolfo-02 1.png`,
  ],
};

export const LifestyleCarousel = ({ workshop }: { workshop: WorkshopEntity }) => {
  return (
    <JgCarousel slickSettings={{ slidesToShow: 1 }}>
      {images[
        workshop.attributes.slug == "2023-Acoustic-Jam-Week"
          ? "imagesBaluchon"
          : workshop.attributes.slug == "2023-Acoustic-Electric-Classics"
          ? "imagesFrensham"
          : "defaultImages"
      ].map((imgSrc, idx) => (
        <Box
          key={idx}
          component="img"
          src={imgSrc}
          borderRadius="10px"
          marginBottom={jgDenimTheme.spacing(3)}
        />
      ))}
    </JgCarousel>
  );
};
