import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { SUBSCRIPTION_TYPES } from "./consts";
import {
  STRIPE_TABS_PUBLISHABLE_KEY,
  STRIPE_DONATION_PUBLISHABLE_KEY,
  STRIPE_SALES_PUBLISHABLE_KEY,
} from "../payments/PaymentConstants";

const withStripe = (ChildComponent) => {
  return (props) => {
    const stripePublishableKey = () => {
      switch (props.type) {
        case SUBSCRIPTION_TYPES.tabs:
          return STRIPE_TABS_PUBLISHABLE_KEY;
        case SUBSCRIPTION_TYPES.donation:
          return STRIPE_DONATION_PUBLISHABLE_KEY;
        case SUBSCRIPTION_TYPES.sales:
          return STRIPE_SALES_PUBLISHABLE_KEY;
      }
    };

    const stripePromise = loadStripe(stripePublishableKey());

    return <ChildComponent {...props} stripe={stripePromise} />;
  };
};

export default withStripe;
