import { styled, SxProps } from '@mui/material';
import React from 'react';

interface StaticBannerProps {
    imageUrl: string;
    imageUrlSM: string;
    targetUrl: string;
    width?: number;
    width_sm?: number;
    height?: number;
    height_sm?: number;
    marginTop?: number;
    marginBottom?: number;
}

export const StaticBanner: React.FC<StaticBannerProps> = ({
    imageUrl,
    imageUrlSM,
    targetUrl,
    height,
    height_sm,
    width,
    width_sm,
    marginBottom,
    marginTop,
    children,
}) => {

    const openUrl = (url) => {
        if (url) {
            window.open(url)?.focus();
        }
    }

    return (
        <BannerContainer
            backgroundImage={imageUrl}
            backgroundImage_sm={imageUrlSM}
            height={height}
            height_sm={height_sm}
            width={width}
            width_sm={width_sm}
            marginTop={marginTop}
            marginBottom={marginBottom}
            hasTarget={!!targetUrl}
            onClick={() => targetUrl && openUrl(targetUrl)}
        >
            {children}
        </BannerContainer>
    )    
}

const BannerContainer = styled('div', {
        shouldForwardProp: prop => (
            !prop.toString().startsWith('backgroundImage')
            && !(prop === 'height')
            && !(prop === 'height_sm')
            && !(prop === 'width')
            && !(prop === 'width_sm')
            && !(prop === 'marginTop')
            && !(prop === 'marginBottom')
            && !(prop === 'hasTarget')
        )
    })<{
    backgroundImage: string;
    backgroundImage_sm: string;
    height?: number;
    height_sm?: number;
    width?: number;
    width_sm?: number;
    marginTop?: number;
    marginBottom?: number;
    hasTarget?: boolean;
}>((props) => ({
    maxWidth: '98vw',
    width: props.width ? `${props.width}px` : '100%',
    height: `${props.height || 280}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginTop: `${props.marginTop || 0}px`,
    marginBottom: `${props.marginBottom || 0}px`,
    borderRadius: '8px',
    cursor: props.hasTarget ? 'pointer' : 'inherit',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${props.backgroundImage})`,
    [props.theme.breakpoints.down('sm')]: {
        width: props.width_sm ? `${props.width_sm}px` : 'calc(100% - 20px)',
        height: `${props.height_sm || 280}px`,
        marginLeft: '10px',
        marginRight: '10px',
        backgroundImage: `url(${props.backgroundImage_sm})`,
    }
}));