import React from "react";
import { Box, Paper } from "@mui/material";
import { CloseIcon } from "./Icons";

const TermsAndConditions = ({
  enroll,
  setTermsModalOpen,
  termsModalOpen,
  setTermsAccepted,
  termsAccepted,
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "740px",
        width: "100%",
      }}
    >
      <Paper sx={{ p: "30px", borderRadius: "10px" }}>
        <button className="close-btn" onClick={() => setTermsModalOpen(!termsModalOpen)}>
          <CloseIcon />
        </button>
        <h2>one last step...</h2>

        <div className="checkbox">
          <input
            type="checkbox"
            id="terms-agree"
            onChange={(e) => setTermsAccepted(e.target.checked)}
          />
          <label htmlFor="terms-agree">
            I agree to receive personalized information and updates about the Music Theory Course by
            email. By clicking the button below, you consent for JustinGuitar to use automated
            technology and email to contact you.
          </label>
        </div>
        <button
          className="button button--primary"
          disabled={!termsAccepted}
          onClick={() => enroll()}
        >
          Enroll
        </button>
      </Paper>
    </Box>
  );
};

export default TermsAndConditions;
