import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import ConditionalWrapper from "../../components/ConditionalWrapper";
import { withInnerWidth } from "../../components/shared";
import { CDN_URL } from "../../components/shared/CdnUrl";
import Category from "../FAQ/Category";
import { strummingCourse } from "../FAQ/data/faqs";
import { VerifiedIcon, SettingsIcon, PriceTagIcon } from "./Icons.jsx";
import Product from "./Product.jsx";
import { stripeLogo } from "../../components/payments/PaymentUtils";
import { StripeProvider, Elements } from "react-stripe-elements";
import { RECAPTCHA_KEY, STRIPE_SALES_PUBLISHABLE_KEY } from "../../payments/PaymentConstants";
import { ArrowRightIcons, StarIcon, YoutubeIcon } from "../../components/shared/Icons";
import { VideoEmbed } from "../../components/swoosh-templates";
import { IS_STRUMMING_SOS_PROMO, PROMO_END_DATE, STRUMMING_SOS_DISCOUNT_PRICE } from "./consts";
import * as moment from "moment";
import CheckoutForm from "../../components/CheckoutForm";
import LoginPrompt from "../../components/auth/LoginPrompt";

const BASE_PRICE = 39.99;

const PRODUCTS = [
  {
    title: "Strumming Foundations",
    price: `${BASE_PRICE} USD`,
    image: `${CDN_URL}/images/pages/strumming-sos/store-strumming-foundations-grade1-card.png`,
    summaryImage: "store-strumming-foundations-grade1-summary.png",
    highlights: ["Basic Foundations", "Strumming Theory & Patterns", "Practice Tips & Exercises"],
    description:
      "This course goes from the very beginning and builds a strong strumming foundation that will benefit anyone who struggles with rhythm guitar.<br /><br />Whether you're a beginner or a more experienced player, we'll troubleshoot many of your strumming problems and get ready to become a rockstar rhythm guitarist.",
    lessonCount: null,
    grade: 1,
    amount: BASE_PRICE.toString(),
    reference: "JustinGuitarStrumSOS1",
    url: "/modules/strumming-foundations",
    color: "white",
    hasPurchased: false,
    hasDiscount: false,
  },
  {
    title: "Strumming Dynamics",
    price: `${BASE_PRICE} USD`,
    image: `${CDN_URL}/images/pages/strumming-sos/store-strumming-dynamics-grade2-card.png`,
    summaryImage: "store-strumming-dynamics-grade2-summary.png",
    highlights: [
      "Dynamic Techniques with Fills and Builds",
      "Palm Muting & 8th Note Strumming",
      "6:8 vs. 3:4 Strumming, and much more!",
    ],
    description:
      "Does your strumming sound flat and boring? This course will be a game-changer for you and add a pro touch to your rhythm guitar. :)<br /><br />You'll learn many techniques to bring groove and life to your strumming patterns! You'll know how to transition between different patterns within a song and better how to push your songs with more movement. Have you heard about palm muting? We'll learn that too. :)",
    lessonCount: null,
    grade: 2,
    amount: BASE_PRICE.toString(),
    reference: "JustinGuitarStrumSOS2",
    url: "/modules/strumming-dynamics",
    color: "#f4d059",
    hasPurchased: false,
    hasDiscount: false,
  },
  {
    title: "Finger Strumming",
    price: `${BASE_PRICE} USD`,
    image: `${CDN_URL}/images/pages/strumming-sos/store-finger-strumming-grade3-card.png`,
    summaryImage: "store-strumming-dynamics-grade3-summary-v2.png",
    highlights: [
      "Pick out melodies while strumming",
      "Easily transition between picking and strumming",
      "Great for solo players and singers!",
    ],
    description: `This course IS NOT about finger picking–it's about finger strumming! :) Although I often advise learning to play using a pick, strumming with your fingers adds on a special magic.<br /><br />Sure, there are moments in a band setting where a pick is your go-to companion. But in solo performances or laid-back sessions, finger strumming opens up a spectrum of rich textures. That's the essence of what we'll be exploring in this course!`,
    lessonCount: null,
    grade: 3,
    amount: BASE_PRICE.toString(),
    reference: "JustinGuitarStrumSOS3",
    url: "/modules/finger-strumming",
    color: "#fc9644",
    hasPurchased: false,
    hasDiscount: false,
  },
];

const BUNDLE_PRODUCT = {
  title: "Strumming SOS Bundle",
  price: `99.99 USD`,
  image: `${CDN_URL}/images/pages/strumming-sos/store-strumming-sos-bundle-summary-v2.png`,
  amount: "99.99",
  reference: "JGStrumSOSBundle",
  hasPurchased: false,
  hasDiscount: false,
  url: "/classes/strumming-sos",
};

const StrummingSosRoot = ({ currentUser, token, width, faqs, lesson_counts }) => {
  const [purchasedProductReference, setPurchasedProductReference] = useState(null);

  const getProducts = () => {
    let hasAtLeastOnePurchase = false;

    PRODUCTS.map((product) => {
      product.hasPurchased =
        currentUser &&
        (currentUser.included.find(
          (item) =>
            item.type === "userPurchase" && item.attributes.purchaseReference === product.reference
        ) ||
          (product.reference && product.reference == purchasedProductReference));

      product.lessonCount = product.reference
        ? `${lesson_counts[product.reference]} Lessons`
        : null;

      if (product.hasPurchased) {
        hasAtLeastOnePurchase = true;
      }

      return product;
    });

    if (hasAtLeastOnePurchase) {
      PRODUCTS.map((product) => {
        if (!product.hasPurchased) {
          product.price = `${STRUMMING_SOS_DISCOUNT_PRICE} USD`;
          product.amount = STRUMMING_SOS_DISCOUNT_PRICE.toString();
          product.hasDiscount = true;
        }

        return product;
      });
    }

    return PRODUCTS;
  };

  const [productToBuy, setProductToBuy] = useState(null);
  const [showPurchaseSuccess, setShowPurchaseSuccess] = useState(false);
  const [products, setProducts] = useState(null);
  const [activeGrade, setActiveGrade] = useState(null);

  useEffect(() => {
    setProducts(getProducts());
  }, []);

  useEffect(() => {
    if (products) {
      const hash = location.hash.replace("#", "");

      const foundProduct = products.find((product) => product.reference === hash);

      if (foundProduct) {
        buyProduct(foundProduct);
      }

      if (hash === BUNDLE_PRODUCT.reference && !foundProduct) {
        buyProduct(BUNDLE_PRODUCT);
      }

      setActiveGrade(products[0]);
    }
  }, [products]);

  const backToCourses = () => {
    // setProductToBuy(null);
    // setShowPurchaseSuccess(false);
    // setProducts(getProducts());
    // location.hash = "";
    window.location.href = "/store/strumming-sos";
  };

  const buyProduct = (product) => {
    setProductToBuy(product);
    location.hash = product.reference;
  };

  const onPurchaseSuccess = () => {
    setShowPurchaseSuccess(true);
    setPurchasedProductReference(productToBuy.reference);
  };

  if (!products || !activeGrade) {
    return null;
  }

  const hasAtLeastOnePurchase = products.find((product) => product.hasPurchased);

  return (
    <div className="strumming-sos container">
      <div className="header">
        <div className="breadcrumb">
          <a href="/store">back to store</a>
        </div>
        <h1>Strumming SOS</h1>
      </div>
      <div className="section">
        <div className="intro">
          <h2 className="title">
            Struggling with your rhythm playing and strumming patterns?
            <br />
            You're not alone.
          </h2>
          <p>
            Achieving a good-sounding, relaxed strumming is a challenge to many guitar players! But
            over the past decades, I understood how to help you nail strumming - once and for all.
            And my knowledge is now here, all available to you. :) Join my Strumming SOS Course:
            your pathway to becoming a confident strummer and a rockstar rhythm guitarist!
          </p>
          <VideoEmbed videoUrl="https://vimeo.com/778453306" />
          {/* <div className="yt-placeholder">
            <YoutubeIcon />
          </div> */}
          {/* <p>
            This course contains three modules, each belonging to a Grade.
            <br />
            Grades 2 & 3 will be available soon and sold separately.
          </p> */}
        </div>
      </div>
      {!productToBuy && (
        <div className="section">
          <div className="sos-bundle">
            <div className="description">
              <h2 className="title">Strumming SOS Bundle</h2>
              <p className="grades">
                <span className="grade-1">Grade 1</span>
                <span className="plus">+</span>
                <span className="grade-2">Grade 2</span>
                <span className="plus">+</span>
                <span className="grade-3">Grade 3</span>
              </p>
              <div className="price">
                <span className="old">USD 119.97</span>
                <span className="new">USD 99.99</span>
              </div>
            </div>
            <div className="grade-cards">
              <div className="grade-card grade-1">
                <p>Strumming Foundations</p>
              </div>
              <span className="plus">+</span>
              <div className="grade-card grade-2">
                <p>Strumming Dynamics</p>
              </div>
              <span className="plus">+</span>
              <div className="grade-card grade-3">
                <p>Finger Strumming</p>
              </div>
            </div>
          </div>
          <button
            className="button button--primary"
            onClick={() => buyProduct(BUNDLE_PRODUCT)}
            style={{ margin: "20px auto 0 auto" }}
            disabled={hasAtLeastOnePurchase}
          >
            Buy the bundle
          </button>
          {!productToBuy && hasAtLeastOnePurchase && (
            <p className="bundle-disclaimer">
              💡You cannot buy the bundle because you already have bought individual courses. Please
              get additional grades individually - an automatic USD 10 applies to you!
            </p>
          )}
        </div>
      )}
      <div className="section" id="purchase">
        {!productToBuy || productToBuy.hasPurchased ? (
          <div className="products">
            <ConditionalWrapper
              condition={width <= 1200}
              wrapper={(children) => (
                <Slider
                  {...{
                    dots: true,
                    infinite: true,
                    slidesToShow: width > 992 ? 2 : 1,
                    slidesToScroll: 1,
                  }}
                >
                  {children}
                </Slider>
              )}
            >
              {products.map((product, idx) => (
                <Product
                  key={idx}
                  product={product}
                  buyProduct={buyProduct}
                  basePrice={BASE_PRICE}
                  discountPrice={STRUMMING_SOS_DISCOUNT_PRICE}
                  products={products}
                  currentUser={currentUser}
                />
              ))}
            </ConditionalWrapper>
          </div>
        ) : showPurchaseSuccess ? (
          <div className="product-purchase payment-success">
            <button className="back-btn" onClick={() => backToCourses()}>
              {"<"} Back to Courses
            </button>
            <h3>thank you for your purchase</h3>
            <p className="info">
              Your payment was successful! You have now access to {productToBuy.title}.
              <br />
              To find your receipt visit your dashboard’s {<SettingsIcon />}{" "}
              <strong>ACCOUNT</strong> then click the tab ‘<strong>Purchases</strong>’.
            </p>
            <p className="info">Now let’s get started!</p>
            <button
              className="button button--primary"
              onClick={() => (window.location.href = productToBuy.url)}
            >
              go to course
            </button>
          </div>
        ) : (
          <div className={`product-purchase${currentUser ? " payment" : " login"}`}>
            <button className="back-btn" onClick={() => backToCourses()}>
              {"<"} Back to Courses
            </button>
            <h3>{productToBuy.title}</h3>
            <p className="price-tag">{productToBuy.price}</p>
            <img src={productToBuy.image} alt={productToBuy.title} />
            {currentUser ? (
              <>
                <p className="info">Pay securely</p>
                <StripeProvider apiKey={STRIPE_SALES_PUBLISHABLE_KEY}>
                  <div className="payments__stripe">
                    <Elements
                      fonts={[
                        {
                          cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:400",
                        },
                      ]}
                    >
                      <CheckoutForm
                        price={productToBuy.amount}
                        currency="USD"
                        reference={productToBuy.reference}
                        recaptchaKey={RECAPTCHA_KEY}
                        purchaseType="sales"
                        subscription={false}
                        userEmail={currentUser ? currentUser.data.attributes.email : null}
                        successCallback={onPurchaseSuccess}
                        info={`By clicking “Agree & Pay,” you agree to not sell, rent, lease, distribute, broadcast, or sublicense the Strumming SOS Course content or any portion of it to any third party.`}
                        splitInputs={true}
                        buttonText="agree & pay"
                      />
                    </Elements>
                  </div>
                </StripeProvider>
                <div className="payments__logo">{stripeLogo()}</div>
              </>
            ) : (
              <>
                <p className="info">First log in to your JustinGuitar account.</p>
                <LoginPrompt token={token} />
              </>
            )}
          </div>
        )}
        <div className="products-description">
          <p className="tag-text">
            <PriceTagIcon />
            {IS_STRUMMING_SOS_PROMO
              ? "Limited-Time Offer - Get an extra 50% off to buy the second module!"
              : "Get more than one module and save!"}
          </p>
          <p
            className="description"
            dangerouslySetInnerHTML={{
              __html: IS_STRUMMING_SOS_PROMO
                ? `Pay only USD ${STRUMMING_SOS_DISCOUNT_PRICE} and keep improving your rhythm skills. Offer ends on ${moment(
                    PROMO_END_DATE
                  ).format("dddd, MMMM D, YYYY")}.`
                : `When you buy a module, get <strong>USD 10 OFF</strong> a second module.${
                    !hasAtLeastOnePurchase
                      ? `<br />Or buy all three modules at once through the <strong>Strumming SOS Bundle for $99.99</strong>.`
                      : ""
                  }`,
            }}
          ></p>
        </div>
      </div>
      <div className="section">
        <div className="money-back">
          <h2>30 Day Money Back Guarantee</h2>
          <p>
            If this course doesn't help your strumming, let me know within
            <br />
            30 calendar days of purchase, and I'll give you a full refund!
          </p>
          <a
            href="/faq/store-music-theory-course#i-bought-this-course-but-i-dont-like-it-can-i-get-a-refund"
            target="_blank"
            className="button button--primary"
          >
            read more
          </a>
          <img src={`${CDN_URL}/images/pages/strumming-sos/justin-2.png`} alt="Justin" />
        </div>
      </div>
      <div className="section">
        <h2 className="title">what will you learn?</h2>
        <div className="grade-select">
          <p>Select a Grade</p>
          <div className="grade-buttons">
            {width > 768 ? (
              products.map(
                (grade, idx) =>
                  grade.reference && (
                    <React.Fragment key={idx}>
                      <button
                        className={`grade-button${
                          activeGrade.grade === grade.grade ? " active" : ""
                        }`}
                        style={{
                          backgroundColor: grade.reference ? grade.color : "#B8BEC7",
                          border: grade.color === "white" ? "1px solid #81ABC1" : "",
                          color: grade.color === "white" ? "#1D3446" : "",
                        }}
                        onClick={() => setActiveGrade(grade)}
                        disabled={grade.reference === null}
                      >
                        {grade.grade}
                      </button>
                      {idx < PRODUCTS.length - 1 && PRODUCTS[idx + 1]?.reference && (
                        <ArrowRightIcons />
                      )}
                    </React.Fragment>
                  )
              )
            ) : (
              <select
                value={activeGrade.grade}
                onChange={(e) => {
                  setActiveGrade(products.find((grade) => grade.grade == e.target.value));
                }}
              >
                {products.map((grade, idx) => (
                  <option key={idx} value={grade.grade}>
                    {`Grade ${grade.grade}`}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
        <div className="what-you-will-learn">
          <img
            src={`${CDN_URL}/images/pages/strumming-sos/${activeGrade.summaryImage}`}
            alt={activeGrade.title}
            className="image"
          />
          <div className={`info`}>
            <h3 className="header2 uppercase">Grade {activeGrade.grade} Highlights & Summary</h3>
            <p style={{ color: activeGrade.color === "white" ? "#1D3446" : activeGrade.color }}>
              {activeGrade.lessonCount}
            </p>
            <ul className="learn-icons">
              {activeGrade.highlights.map((highlight, idx) => (
                <li key={idx}>
                  <div className="description-icon">
                    <StarIcon color={activeGrade.color} />
                  </div>
                  <p>{highlight}</p>
                </li>
              ))}
            </ul>
            <p dangerouslySetInnerHTML={{ __html: activeGrade.description }}></p>
            <div className="info-actions">
              {!activeGrade.hasPurchased ? (
                <>
                  <button
                    className="button button--primary"
                    onClick={() => {
                      buyProduct(activeGrade);
                      window.location.href = "#purchase";
                    }}
                  >
                    buy this module
                  </button>
                  <button
                    className="button button--success"
                    onClick={() => (window.location.href = activeGrade.url)}
                  >
                    preview module
                  </button>
                </>
              ) : (
                <button
                  className="button button--blue"
                  onClick={() => (window.location.href = activeGrade.url)}
                >
                  view module
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <h2 className="title">how is this course different?</h2>
        <div className="verified-badges">
          <div>
            <VerifiedIcon />
            Practical lessons designed for online learning
          </div>
          <div>
            <VerifiedIcon />
            Community support at all stages
          </div>
          <div>
            <VerifiedIcon />
            Exclusive digital workbook with practice tips!
          </div>
        </div>
        <div className="strumming-machine-banner">
          {width >= 900 && (
            <img
              src={`${CDN_URL}/images/pages/strumming-sos/strumming-machine-banner.png`}
              alt="Strumming Machine"
            />
          )}
          <p className="title">Free Tool: Strumming Machine</p>
          <p className="description">
            Practice your new skills with my complimentary Strumming Machine! Choose from a
            strumming pattern library or create your own. Free for all JustinGuitar students. :)
          </p>
          <a className="button button--primary" href="/strumming-machine" target="_blank">
            try it now
          </a>
        </div>
      </div>
      <div className="section">
        <div className="faq">
          <Category
            faqs={faqs}
            showHeader={false}
            categoryData={strummingCourse}
            useUrlRouting={false}
            showBreadcrumbs={false}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.currentUser,
    token: state.session.token,
    faqs: state.entity.faqs,
    lesson_counts: state.entity.lesson_counts,
  };
};

export default connect(mapStateToProps)(withInnerWidth(StrummingSosRoot));
