import React from "react";

import { CDN_URL } from "../shared/CdnUrl";
import FileIcon from "../shared/FileIcon";

const ResourceSearchResultItem = ({ resource: { title, fileUid, fileType } }) => (
  <a
    className="search-result-item search-result-item--resource"
    href={`${CDN_URL}/system/dragonfly/production/${fileUid}`}
    download
  >
    <FileIcon fileType={fileType} />
    <div className="title">{title}</div>
    <img src="/redesign/2020/advanced-search/download.svg" alt="download" />
  </a>
);

export default ResourceSearchResultItem;
