import React from "react";

import * as PropTypes from "prop-types";
import Loading from "../shared/Loading";

export class InfiniteScroll extends React.Component {
  constructor(props) {
    super(props);
    this.showMoreButtonRef = React.createRef();
    this.handlingScroll = false;

    this.state = { enabled: false };
  }

  componentDidMount() {
    this.initInfiniteScroll();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const wasEnabled = prevProps.enableInfiniteScroll && prevState.enabled;

    if (!wasEnabled) {
      this.initInfiniteScroll();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll);
  }

  fetchNextPage = () => {
    const shouldLoad = this.hasMoreResultsToFetch() && !this.props.loading;

    if (shouldLoad) {
      const { metadata, fetchPage } = this.props;
      const page = metadata && metadata.page ? Number(metadata.page) + 1 : 1;
      fetchPage(page);
    }
  };

  shouldShowViewMore() {
    if (this.state.enabled) {
      return false;
    }
    const { metadata, loading } = this.props;
    if (loading || metadata == null) {
      return false;
    }
    return this.hasMoreResultsToFetch();
  }

  hasMoreResultsToFetch() {
    const { metadata } = this.props;
    if (metadata == null) {
      return false;
    }
    const { totalCount, page = 1, perPage } = metadata;
    return page * perPage < totalCount;
  }

  loadingIndicator() {
    return (
      <span style={{ position: "relative" }}>
        <Loading isInput={true} />
      </span>
    );
  }

  showMoreButtonIsInViewport() {
    const { top } = this.showMoreButtonRef.current.getBoundingClientRect();
    const buffer = 0.8;
    return top * buffer <= (window.innerHeight || document.documentElement.clientHeight);
  }

  initInfiniteScroll = () => {
    const isEnabled = this.props.enableInfiniteScroll && this.state.enabled;

    if (isEnabled) {
      document.addEventListener("scroll", this.onScroll);
      this.onScroll();
    }
  };

  onScroll = () => {
    if (!this.handlingScroll) {
      window.requestAnimationFrame(() => {
        if (
          this.hasMoreResultsToFetch() &&
          this.showMoreButtonIsInViewport() &&
          !this.props.loading
        ) {
          this.fetchNextPage();
        }
        this.handlingScroll = false;
      });

      this.handlingScroll = true;
    }
  };

  onViewMore = () => {
    this.fetchNextPage();
    this.setState({ enabled: true }, this.initInfiniteScroll);
    this.props.onViewMore();
  };

  render() {
    const { loading, children } = this.props;
    return (
      <div className="infinite-scroll">
        {children}

        {this.shouldShowViewMore() && (
          <div className="view-more" onClick={this.onViewMore}>
            <div className="view-more__left" />
            <div className="view-more__label">View More</div>
            <div className="view-more__right" />
          </div>
        )}

        {this.state.enabled && this.hasMoreResultsToFetch() && (
          <div
            className="button button--no-background load-more"
            onClick={this.fetchNextPage}
            ref={this.showMoreButtonRef}
          >
            {loading ? (
              this.loadingIndicator()
            ) : (
              <span>
                show more results <i className="glyphicon glyphicon-arrow-down" />
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}

InfiniteScroll.defaultProps = {
  enableInfiniteScroll: true,
  onViewMore: () => {
    /*no-op*/
  },
};

InfiniteScroll.propTypes = {
  enableInfiniteScroll: PropTypes.bool,
  fetchPage: PropTypes.func.isRequired,
  metadata: PropTypes.shape({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};
