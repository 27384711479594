import React, { useState } from "react";
import Modal from "react-modal";
import UpdateCard from "../../../subscriptions/UpdateCard";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100%",
    width: "90%",
    height: "50%",
    backgroundColor: "white",
    border: "none",
    maxWidth: "600px",
    maxHeight: "600px",
    borderRadius: "20px",
  },
};

const UpdatePaymentMethod = ({ subscriptionId, type }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <p onClick={() => setModalOpen(true)} className="teal clickable">
        Update payment method
      </p>
      <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} style={customStyles}>
        <UpdateCard
          subscriptionId={Number(subscriptionId)}
          type={type}
          onComplete={() => setModalOpen(!modalOpen)}
        />
      </Modal>
    </>
  );
};

export default UpdatePaymentMethod;
