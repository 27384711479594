import React from "react";
import { withInnerWidth } from "../../shared";
import { BootstrapSize, breakpoints } from "../../shared/bootstrap-helpers";
import VoteButton from "./VoteButton";

const SongRequestsItem = ({
  songRequest,
  ranking,
  width,
  updateVoteCount,
  nextPossibleVoteTime,
}) => {
  const smView = width <= breakpoints[BootstrapSize.smDevice];
  const { title, artist, voteCount } = songRequest.attributes;

  return (
    <div className="song-request-listing-item">
      {ranking && (
        <div className="ranking">
          <h3 className="heading3 ">
            {smView ? null : "#"} {ranking}
          </h3>
        </div>
      )}
      {smView ? (
        <div className="title-and-artist">
          <div className="title">{title}</div>
          <div className="artist">{artist}</div>
        </div>
      ) : (
        <>
          <div className="title">{title}</div>
          <div className="artist">{artist}</div>
        </>
      )}

      {smView ? null : <div className="vote-count">{voteCount}</div>}
      <div className="vote-button">
        <VoteButton
          songRequest={songRequest}
          updateVoteCount={updateVoteCount}
          nextPossibleVoteTime={nextPossibleVoteTime}
        />
      </div>
    </div>
  );
};

export default withInnerWidth(SongRequestsItem);
