import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import "./store/songIndex";
import SongContainer from "./SongContainer";

export default class Song extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const songStore = ReactOnRails.getStore("songStore");

    return (
      <Provider store={songStore}>
        <SongContainer />
      </Provider>
    );
  }
}
