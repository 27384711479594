import PropTypes from "prop-types";
import React from "react";
import SongListItem from "../components/SongListItem";
import LessonListItem from "../components/LessonListItem";
import ModuleListItem from "../components/ModuleListItem";

export default class SortableList extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    limit: PropTypes.number, // Limit the list length
    sortBy: PropTypes.array.isRequired, // sortBy item {title: "Title", name: "name", value: function(item) { return item.some_field }, dir: "ASC" }
    activeIndex: PropTypes.number.isRequired, // Active column (last sorted) index
    type: PropTypes.string.isRequired, // Type of list item
  };

  constructor(props, _railsContext) {
    super(props);

    this.state = {
      items: props.items,
      limit: props.limit || 10000,
      sortBy: props.sortBy,
      activeIndex: props.activeIndex,
      type: props.type,
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      items: newProps.items,
      activeIndex: newProps.activeIndex,
      width: newProps.width,
    });
  }

  // componentWillMount = () => {
  // 	this.setState({width: window.innerWidth})
  // }

  // Sort by field value
  sortBy = (sortItem, index) => {
    if (!sortItem.title.length) return;

    let sortBy = this.state.sortBy; // Handle to sort_by -object

    let sorted = this.state.items.sort(function (a, b) {
      let a_str = sortItem.sortValue(a);
      let b_str = sortItem.sortValue(b);

      //always put null values at the bottom
      if (sortItem.dir == "ASC") {
        if (a_str == null) {
          return +1;
        } else if (b_str == null) {
          return -1;
        }
      }

      if (a_str < b_str) {
        return -1;
      }

      if (a_str > b_str) {
        return 1;
      }

      if (a_str == b_str) {
        // if grade is the same, sort alphabetically
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return +1;
        }
        return 0;
      }
      return 0;
    });

    // In case descending order, reverse
    // and update sorting direction
    if (sortItem.dir === "DESC") {
      sorted = sorted.reverse();

      sortItem.dir = "ASC";
    } else {
      sortItem.dir = "DESC";
    }

    // Set updated sortItem
    sortBy[index] = sortItem;

    this.setState({ items: sorted, sortBy: sortBy, activeIndex: index });
  };

  showAll = (event) => {
    //TODO: Need to think this logic...

    // Show all items, remove limit
    this.setState({ limit: 10000 });

    // Hide the button
    let btn = document.getElementById("show-all-btn");
    btn.className += " hidden";
  };

  render() {
    let type = this.state.type;
    let listItems = this.state.items
      .slice(0, this.state.limit)
      .map((item, index) => <div key={index + item.title}>Should not be here...</div>);

    // Check the type and render correct component based on that
    if (type === "song") {
      listItems = this.state.items
        .slice(0, this.state.limit)
        .map((item, index) => (
          <SongListItem key={index + item.title} data={item} width={this.state.width} />
        ));
    } else if (type === "lesson") {
      listItems = this.state.items
        .slice(0, this.state.limit)
        .map((item, index) => <LessonListItem key={index + item.title} data={item} />);
      listItems = <div className="row">{listItems}</div>;
    } else if (type === "module") {
      listItems = this.state.items
        .slice(0, this.state.limit)
        .map((item, index) => <ModuleListItem key={index + item.title} data={item} />);
      listItems = <div className="row">{listItems}</div>;
    }

    return (
      <div className="sortable-list">
        <div
          className={
            this.state.type == "song"
              ? "sortable-list__table sortable-list__table--song"
              : "sortable-list__table"
          }
        >
          <div className="sortable-list__sort">
            {/* These are the columns that do the sorting */}
            {this.state.sortBy.map((sortItem, index) => (
              <div
                className={
                  "page-subheading no-margin sortable-list__sort-item col " +
                  (index === this.state.activeIndex ? "active" : "")
                }
                key={sortItem.name}
                onClick={() => this.sortBy(sortItem, index)}
              >
                {sortItem.title && sortItem.title.length && (
                  <span>
                    <span>{sortItem.title}</span>
                    <span
                      className={
                        "glyphicon " +
                        (sortItem.dir === "ASC"
                          ? "glyphicon-triangle-top"
                          : "glyphicon-triangle-bottom")
                      }
                    ></span>
                  </span>
                )}
              </div>
            ))}
          </div>
          {this.state.type == "lesson" || this.state.type == "module" ? (
            <div ref="cardItems" className="card-container">
              {" "}
              {listItems}{" "}
            </div>
          ) : (
            listItems
          )}
        </div>

        <div className="content-section text-center">
          <button
            id="show-all-btn"
            role="button"
            className={"btn " + (this.state.items.length <= this.state.limit ? "hidden" : "")}
            onClick={this.showAll}
          >
            <span className="page-subheading">Show more results</span>
            <span className="glyphicon glyphicon-arrow-down page-subheading"></span>
          </button>
        </div>
      </div>
    );
  }
}
