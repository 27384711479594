import { createSlice } from "@reduxjs/toolkit";

import { listingStyles, sectionOptions, sortByOptions } from "../shared";
import * as loading from "./loading";
import { filterSliceFactory, initialStateFactory as filtersInitialStateFactory } from "./filters";
import { nestSlice } from "../../../shared/nestSlice";
import { fetchSongs } from "../effects/fetchSongs";
import { updateQueryString } from "../shared/util";

export const filters = filterSliceFactory("filters");
export const artistFilters = filterSliceFactory("artistFilters");

export const initialState = {
  filtersOpen: false,
  sortBy: sortByOptions.popularity,
  artistSortBy: sortByOptions.popularity,
  query: "",
  songRequestsQuery: "",
  listingStyle: localStorage.getItem("listingStyle") || listingStyles.list,
  loading: loading.initialState,
  filters: filtersInitialStateFactory(),
  artistFilters: filtersInitialStateFactory(),
  artistFiltersOpen: false,
  alert: {
    message: null,
    severity: null,
  },
  artistQuery: "",
  listingSongs: [],
  listingArtists: [],
  songRequestConflicts: {
    originalRequest: null,
    conflicts: {
      songs: { data: [] },
      songRequests: { data: [] },
    },
  },
  orderedIds: {
    pendingSongRequests: [],
    selectedSongRequests: [],
    completedSongRequests: [],
  },
};

export const { actions, reducer } = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setListingSongs(state, { payload }) {
      state.listingSongs = payload;
    },
    setListingArtists(state, { payload }) {
      state.listingArtists = payload;
    },
    openFilters(state) {
      state.filtersOpen = true;
    },
    closeFilters(state) {
      state.filtersOpen = false;
    },
    setFiltersOpen(state, { payload }) {
      state.filtersOpen = payload;
    },
    openArtistFilters(state) {
      state.artistFiltersOpen = true;
    },
    closeArtistFilters(state) {
      state.artistFiltersOpen = false;
    },
    setArtistFiltersOpen(state, { payload }) {
      state.artistFiltersOpen = payload;
    },
    setSortBy(state, { payload }) {
      state.sortBy = payload;
    },
    setArtistSortBy(state, { payload }) {
      state.artistSortBy = payload;
    },
    setQuery(state, { payload }) {
      state.query = payload;
    },
    setSongRequestsQuery(state, { payload }) {
      state.songRequestsQuery = payload;
    },
    setVisibleSection(state, { payload }) {
      state.visibleSection = payload;
    },
    setListingStyle(state, { payload }) {
      localStorage.setItem("listingStyle", payload);
      state.listingStyle = payload;
    },
    openAlert(state, { payload }) {
      // payload = { message: "any message", severity: "error" | "warning" | "info" | "success" }
      state.alert = payload;
    },
    clearAlert(state) {
      state.alert = initialState.alert;
    },
    setCurrentArtistSlug(state, { payload }) {
      updateQueryString("artist", payload);
      state.currentArtistSlug = payload;
    },
    setArtistQuery(state, { payload }) {
      state.artistQuery = payload;
    },
    setSongRequestConflicts(state, { payload }) {
      state.songRequestConflicts = payload;
    },
    setPendingSongRequestOrderedIds(state, { payload }) {
      state.orderedIds.pendingSongRequests = payload;
    },
    appendPendingSongRequestOrderedIds(state, { payload }) {
      state.orderedIds.pendingSongRequests = [...state.orderedIds.pendingSongRequests, ...payload];
    },
    setSelectedSongRequestOrderedIds(state, { payload }) {
      state.orderedIds.selectedSongRequests = payload;
    },
    appendSelectedSongRequestOrderedIds(state, { payload }) {
      state.orderedIds.selectedSongRequests = [
        ...state.orderedIds.selectedSongRequests,
        ...payload,
      ];
    },
    setCompletedSongRequestOrderedIds(state, { payload }) {
      state.orderedIds.completedSongRequests = payload;
    },
    appendCompletedSongRequestOrderedIds(state, { payload }) {
      state.orderedIds.completedSongRequests = [
        ...state.orderedIds.completedSongRequests,
        ...payload,
      ];
    },
  },
  extraReducers: {
    ...nestSlice("filters", filters),
    ...nestSlice("artistFilters", artistFilters),
    ...nestSlice("loading", loading),
  },
});
