import React, { DetailedReactHTMLElement, PropsWithChildren, ReactElement, ReactNode } from "react";
import PropTypes from "prop-types";
import { BootstrapSize, orderedBootstrapSizes } from "./bootstrap-helpers";
import { withBootstrapSize } from "./WithBootstrapSize";

const DEFAULT_IMG_HEIGHT = 900;
const DEFAULT_IMG_WIDTH = 1440;

const radii = {
  [BootstrapSize.xsDevice]: 30,
  [BootstrapSize.smDevice]: 30,
  [BootstrapSize.mdDevice]: 80,
  [BootstrapSize.lgDevice]: 80,
  [BootstrapSize.xlDevice]: 120,
};

export enum SwooshGradient {
  denim = "denim",
  grade1 = "grade1",
  grade2 = "grade2",
  grade3 = "grade3",
  grade4 = "grade4",
  grade5 = "grade5",
  grade6 = "grade6",
  grade7 = "grade7",
  grade8 = "grade8",
  grade9 = "grade9",
  nightSky = "nightSky",
  greyGradient = "greyGradient",
}

interface Gradient {
  left: string;
  right: string;
}

const gradientColors: { [key in SwooshGradient]: Gradient } = {
  [SwooshGradient.denim]: {
    left: "#406E8E",
    right: "#1D3446",
  },
  [SwooshGradient.grade1]: {
    left: "#FFFFFF",
    right: "#DADADA",
  },
  [SwooshGradient.grade2]: {
    left: "#F9ED32",
    right: "#FBB040",
  },
  [SwooshGradient.grade3]: {
    left: "#FBB040",
    right: "#EF6E36",
  },
  [SwooshGradient.grade4]: {
    left: "#42E695",
    right: "#3BB2B8",
  },
  [SwooshGradient.grade5]: {
    left: "#17EAD9",
    right: "#6078EA",
  },
  [SwooshGradient.grade6]: {
    left: "#6094EA",
    right: "#F02FC2",
  },
  [SwooshGradient.grade7]: {
    left: "#F54EA2",
    right: "#EF4136",
  },
  [SwooshGradient.grade8]: {
    left: "#E19E58",
    right: "#7F351C",
  },
  [SwooshGradient.grade9]: {
    left: "#503B52",
    right: "#222427",
  },
  // TODO: are these the colors we want post-denimification?
  [SwooshGradient.nightSky]: {
    left: "#44546C",
    right: "#11192F",
  },
  [SwooshGradient.greyGradient]: {
    left: "#727E90",
    right: "#44546C",
  },
};

interface SwooshBreadcrumb {
  label: string;
  href?: string;
  onClick?: () => void;
}

export interface SwooshProps {
  bootstrapSize: BootstrapSize;
  color?: string;
  gradient?: SwooshGradient;
  imgUrl?:
    | string
    | {
        [BootstrapSize.xsDevice]?: string;
        [BootstrapSize.smDevice]?: string;
        [BootstrapSize.mdDevice]?: string;
        [BootstrapSize.lgDevice]?: string;
        [BootstrapSize.xlDevice]?: string;
      };
  height?: number;
  width?: number;
  excludeTop?: boolean;
  excludeBottom?: boolean;
  id?: string;
  excludeTopMargin?: boolean;
  breadcrumbs: SwooshBreadcrumb[];
}

export interface SwooshState {
  imgWidth?: number;
  imgHeight?: number;
  contentHeight: number;
  initialized: boolean;
}

const initialSwooshState: SwooshState = {
  contentHeight: 0,
  initialized: false,
};

class Swoosh extends React.Component<SwooshProps, SwooshState> {
  readonly state: SwooshState = { ...initialSwooshState };
  private readonly fallbackId = Date.now();
  private readonly contentRef = React.createRef<HTMLDivElement>();
  private observer: ResizeObserver | ReturnType<typeof setInterval>;

  get imgSrc() {
    const { imgUrl, bootstrapSize } = this.props;

    if (imgUrl == null) {
      return null;
    }

    if (typeof imgUrl === "string") {
      return imgUrl;
    }

    if (imgUrl[bootstrapSize] != null) {
      return imgUrl[bootstrapSize];
    }

    const currSizeIdx = orderedBootstrapSizes.findIndex((size) => size === bootstrapSize);

    let i = currSizeIdx;
    while (i < orderedBootstrapSizes.length) {
      const size = orderedBootstrapSizes[i];
      if (typeof imgUrl[size] === "string") {
        return imgUrl[size];
      }
      i += 1;
    }

    i = currSizeIdx;
    while (i >= 0) {
      const size = orderedBootstrapSizes[i];
      if (typeof imgUrl[size] === "string") {
        return imgUrl[size];
      }
      i -= 1;
    }

    throw new Error("No image URLs provided");
  }

  get isImage() {
    return this.props.imgUrl != null;
  }

  get isGradient() {
    return this.props.gradient != null;
  }

  get radius() {
    const { bootstrapSize } = this.props;

    if (bootstrapSize == null) {
      return null;
    }

    return radii[bootstrapSize];
  }

  componentDidMount() {
    if (this.isImage) {
      this.setImageDimensions();
    } else {
      this.setContentDimensions();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.observer == null && !this.isImage) {
      this.watchForHeightChanges();
    }

    if (this.isImage && prevProps.imageUrl == null) {
      this.unobserve();
    }

    if (
      prevProps.bootstrapSize !== this.props.bootstrapSize ||
      prevProps.imgUrl !== this.props.imgUrl
    ) {
      if (this.isImage) {
        this.setImageDimensions();
      } else {
        this.setContentDimensions();
      }
    }
  }

  componentWillUnmount() {
    this.unobserve();
  }

  unobserve() {
    const observerIsResizeObserver = (observer: any): observer is ResizeObserver => {
      return typeof this.observer === "object";
    };

    if (typeof this.observer === "number") {
      clearInterval(this.observer);
    } else if (observerIsResizeObserver(this.observer)) {
      this.observer.unobserve(this.contentRef.current);
    }
  }

  watchForHeightChanges() {
    // detect changes in height (eg. expanding list in swoosh content)
    if (typeof ResizeObserver !== "undefined") {
      this.observer = new ResizeObserver(() => {
        this.setContentDimensions();
      });
      this.observer.observe(this.contentRef.current);
    } else {
      let oldRect = this.contentRef.current.getBoundingClientRect();
      this.observer = setInterval(() => {
        const newRect = this.contentRef.current.getBoundingClientRect();
        const dimensionChanged = Object.entries(newRect).reduce((accum, [key, newVal]) => {
          if (accum) {
            return accum;
          }
          const oldVal = oldRect[key];
          return oldVal !== newVal;
        }, false);

        if (dimensionChanged) {
          this.setContentDimensions();
        }
      }, 500);
    }
  }

  setImageDimensions = () => {
    const src = this.imgSrc;
    const img = new Image();
    const _this = this;

    img.onload = function (this: HTMLImageElement) {
      // using implicit `this` from event handler
      _this.setState({
        imgHeight: this.height,
        imgWidth: this.width,
        initialized: true,
      });
    };
    img.src = src;
  };

  onImageLoad = () => {
    this.setContentDimensions();
  };

  clonedChildren = (children) => {
    // this is necessary to resize swoosh height on page load - otherwise
    // height is calculated before the image loads and does not resize
    return React.Children.map(
      children,
      (child: ReactElement<PropsWithChildren<{ onLoad: () => {} }>>) => {
        if (!React.isValidElement(child)) {
          return child;
        }

        if (child.props.children) {
          child = React.cloneElement(child, {
            children: this.clonedChildren(child.props.children),
          });
        }

        return child.type === "img"
          ? React.cloneElement(child, { onLoad: this.onImageLoad.bind(this) })
          : child;
      }
    );
  };

  calculateHeight = () => {
    const { height } = this.contentRef.current.getBoundingClientRect();
    const { excludeTop, excludeBottom } = this.props;
    const numSwooshes = excludeTop && excludeBottom ? 0 : excludeTop ? 1 : excludeBottom ? 1 : 2;
    return height + this.radius * numSwooshes;
  };

  setContentDimensions = () => {
    this.setState({
      contentHeight: this.calculateHeight(),
      initialized: true,
    });
  };

  get height() {
    const { width, additionalHeight } = this.props;
    const { imgHeight, imgWidth } = this.state;

    if (this.isImage) {
      return Math.ceil((width * imgHeight) / imgWidth) + (additionalHeight || 0);
    }

    return Math.ceil(this.state.contentHeight) + (additionalHeight || 0);
  }

  swooshBackground() {
    const radius = this.radius;
    const height = this.height;
    const { width } = this.props;
    const id = this.props.id || this.fallbackId;
    const patternId = `pattern-${id}`;
    const backgroundId = `background-${id}`;
    const maskId = `mask-${id}`;
    const fill = this.isImage || this.isGradient ? `url(#${patternId})` : this.props.color;

    return (
      <svg style={{ height, width }} className="swoosh-svg">
        <defs>
          {this.isImage
            ? this.imagePattern(patternId)
            : this.isGradient
            ? this.gradient(patternId)
            : null}
        </defs>
        <mask id={maskId} fill="#fff">
          <rect id={backgroundId} width="100%" height="100%" />
          {this.props.excludeTop === false && (
            <>
              <circle className="c-top" cx={radius} cy="0" r={radius} fill="#000" />
              <rect
                className="r-top"
                width={width - radius}
                height={radius}
                x={radius}
                fill="#000"
              />
              <path
                className="top-right"
                d={`
                  M ${width - radius} ${radius}
                  L ${width} ${radius}
                  L ${width} ${2 * radius}
                  Q ${width} ${radius} ${width - radius} ${radius}
                  Z
                `}
                fill="#000"
              />
            </>
          )}
          {this.props.excludeBottom === false && (
            <>
              <circle className="c-bottom" cx={width - radius} cy="100%" r={radius} fill="#000" />
              <rect
                className="r-bottom"
                width={width - radius}
                height={radius + 2}
                y={height - radius}
                fill="#000"
              />
              <path
                className="bottom-left"
                d={`
                  M 0 ${height - 2 * radius}
                  L 0 ${height - radius}
                  L ${radius} ${height - radius}
                  Q 0 ${height - radius} 0 ${height - 2 * radius}
                  Z
                `}
                fill="#000"
              />
            </>
          )}
        </mask>
        <use xlinkHref={`#${backgroundId}`} fill={fill} mask={`url(#${maskId})`} />
      </svg>
    );
  }

  gradient(patternId) {
    const {
      [this.props.gradient]: { left, right },
    } = gradientColors;
    const coordinatesFor146DegAngle = {
      x1: "0%",
      y1: "0%",
      x2: "87%",
      y2: "111%",
    };

    return (
      <linearGradient id={patternId} {...coordinatesFor146DegAngle}>
        <stop offset="0%" style={{ stopColor: left, stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: right, stopOpacity: 1 }} />
      </linearGradient>
    );
  }

  imagePattern(patternId) {
    const { imgHeight, imgWidth } = this.state;

    return (
      this.isImage && (
        <pattern
          id={patternId}
          width="1"
          height="1"
          viewBox={`0 0 ${imgWidth} ${imgHeight}`}
          preserveAspectRatio="xMidYMid slice"
        >
          <image href={this.imgSrc} x="0" y="0" width={imgWidth} height={imgHeight} />
        </pattern>
      )
    );
  }

  breadcrumbs(marginTop) {
    const { breadcrumbs, breadcrumbsCta } = this.props;

    if (breadcrumbsCta) {
      return (
        <div
          className="breadcrumb-cta"
          style={{ position: "absolute", top: `${marginTop}px`, width: "100%", zIndex: 1 }}
        >
          <div className="container">
            <a href="/store">back to store</a>
          </div>
        </div>
      );
    }

    if (breadcrumbs == null || breadcrumbs.length === 0) {
      return;
    }

    const links = breadcrumbs.slice(0, breadcrumbs.length - 1);
    const currPage = breadcrumbs[breadcrumbs.length - 1];

    return (
      <div
        className="breadcrumb swoosh-breadcrumb"
        style={{ position: "absolute", top: `${marginTop}px`, width: "100%", zIndex: 1 }}
      >
        <div className="container">
          {links.map(({ label, href, onClick }, idx) => (
            <React.Fragment key={idx}>
              {href == null ? <span onClick={onClick}>{label}</span> : <a href={href}>{label}</a>}
              <span className="divider"> {">"}</span>
            </React.Fragment>
          ))}
          <span>{currPage.label}</span>
        </div>
      </div>
    );
  }

  render() {
    const isImage = this.isImage;
    if (isImage && !this.state.initialized) {
      // wait for image dimensions
      return <div ref={this.contentRef} />;
    }

    const height = this.height;
    const radius = this.radius;
    const { excludeTop, excludeBottom, excludeTopMargin } = this.props;
    const margin = excludeTop ? 0 : radius;

    return (
      <div
        className="swooosh"
        style={{
          paddingBottom: excludeBottom ? height : height - radius, // allow next sibling to nestle into our swoosh
          margin: !excludeTopMargin ? `-${margin}px 0 0 0` : 0,
          position: "relative",
        }}
      >
        {this.state.initialized && (
          <div
            className="swooosh__background"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              zIndex: -1,
            }}
          >
            {this.swooshBackground()}
          </div>
        )}

        {this.breadcrumbs(margin)}

        <div
          className="swooosh__content"
          style={{
            height: !isImage ? "fit-content" : excludeTop ? "100%" : `calc(100% - ${radius}px)`,
            marginTop: `${margin}px`,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: isImage ? "center" : "stretch",
            justifyContent: "center",
          }}
        >
          <div style={{ height: "-moz-fit-content", width: "100%" }} ref={this.contentRef}>
            {this.clonedChildren(this.props.children)}
          </div>
        </div>
      </div>
    );
  }

  static defaultProps = {
    color: "#1D3446",
    excludeTop: false,
    excludeBottom: false,
  };

  static propTypes = {
    color: PropTypes.string,
    gradient: PropTypes.oneOf(Object.keys(gradientColors)),
    imgUrl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.exact({
        [BootstrapSize.xsDevice]: PropTypes.string,
        [BootstrapSize.smDevice]: PropTypes.string,
        [BootstrapSize.mdDevice]: PropTypes.string,
        [BootstrapSize.lgDevice]: PropTypes.string,
        [BootstrapSize.xlDevice]: PropTypes.string,
      }),
    ]),
    height: PropTypes.number,
    width: PropTypes.number,
    additionalHeight: PropTypes.number,
    excludeTop: PropTypes.bool,
    excludeBottom: PropTypes.bool,
    id: PropTypes.string,
    excludeTopMargin: PropTypes.bool,
    breadcrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        href: PropTypes.string,
        onClick: PropTypes.func,
      })
    ),
  };
}

export default withBootstrapSize(Swoosh);
