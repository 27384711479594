import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { CDN_URL } from "../../components/shared/CdnUrl";

const defaultHeaderImages = {
  club: {
    [BootstrapSize.smDevice]: `${CDN_URL}/images/pages/live-events/jg-live-events-hero-lg-v2.jpeg`,
    [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/live-events/jg-live-events-hero-sm-v2.jpg`,
  },
  pmt: {
    [BootstrapSize.smDevice]: `${CDN_URL}/images/pages/live-events/jg-live-events-music-theory-hero-lg.jpeg`,
    [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/live-events/jg-live-events-music-theory-hero-sm.png`,
  },
  blues: {
    [BootstrapSize.smDevice]: `${CDN_URL}/images/pages/live-events/jg-live-events-blues-hero-lg.jpg`,
    [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/live-events/jg-live-events-blues-hero-sm.jpg`,
  },
};

const LiveEventsSwoosh = ({ title, pageData }) => {
  return (
    <div className="live-events-swoosh">
      <Swoosh imgUrl={defaultHeaderImages[pageData.eventType]}>
        <div className="live-events-swoosh__content ">
          {pageData.eventType === "blues" && (
            <div className="breadcrumb">
              <a href="/clubs">back to all clubs page</a>
            </div>
          )}
          {pageData.eventType === "pmt" && (
            <div className="breadcrumb">
              <a
                href={
                  pageData.pmtSubscriber
                    ? "/classes/practical-music-theory-course-fast-hands-on"
                    : "/store/practical-music-theory"
                }
              >
                back to practical music theory {pageData.pmtSubscriber ? "course" : ""}
              </a>
            </div>
          )}
          <h1 className="header1">{title}</h1>
          {/*<h1 className="header1" style={{ visibility: eventType === "blues" ? "hidden" : "" }}>*/}
          {/*  {title}*/}
          {/*</h1>*/}
        </div>
      </Swoosh>
    </div>
  );
};

export default LiveEventsSwoosh;
