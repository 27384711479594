import React, { Fragment } from "react";
import { EMAIL_PROVIDERS } from "./consts";
import { Box, Grid, Link, Typography } from "@mui/material";
import { jgDenimColors } from "../../../jg-material/theme/jg-denim-colors";
import { CheckCircleOutline } from "@mui/icons-material";
import { OrangeLink } from "../../../jg-material/theme/components/styled/Links";

const SomeoneAlwaysNeedsHelp = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography variant={"body1"} sx={{ fontWeight: "bold", textAlign: "center", my: 2 }}>
        🚨 Didn't receive an email or cannot access your new account?
      </Typography>
      <Typography>
        Please contact{" "}
        <OrangeLink href={"hello@justinguitar.com"}>hello@justinguitar.com</OrangeLink> for
        assistance!
      </Typography>
    </Box>
  );
};
const RegistrationSuccess = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <CheckCircleOutline sx={{ color: jgDenimColors.action.success.default, fontSize: "48px" }} />
      <Typography
        variant={"h8"}
        sx={{
          color: jgDenimColors.action.success.default,
          textTransform: "none",
          mt: 2,
          mb: 4,
          textAlign: "center",
        }}
      >
        Thank you! Your registration was successful.
      </Typography>

      <Typography variant={"h8"} sx={{ fontWeight: "bold" }}>
        ONLY ONE MORE THING:
      </Typography>

      <Typography variant={"body1"} sx={{ textAlign: "center" }}>
        Please check your email and verify your account. Don't forget to check your spam folder if
        you don't see the confirmation email in your inbox.
      </Typography>

      <Typography variant={"body1"} sx={{ textAlign: "center", mt: 2 }}>
        Here are some quick links to access your email:
      </Typography>

      <Grid container sx={{ alignItems: "center", justifyContent: "center" }} spacing={2} mt={2}>
        {EMAIL_PROVIDERS.map((provider, idx) => (
          <Fragment key={`email_${idx}`}>
            <Grid item xs={12} sm={6} md={3} sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  backgroundColor: jgDenimColors.gray.light,
                  borderRadius: "4px",
                  height: "100px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link key={provider.name} href={provider.url}>
                  {" "}
                  <Typography dangerouslySetInnerHTML={{ __html: provider.icon }} />
                </Link>
              </Box>
            </Grid>
          </Fragment>
        ))}
      </Grid>

      <SomeoneAlwaysNeedsHelp />
    </Box>
  );
};

export default RegistrationSuccess;
