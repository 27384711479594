import React from "react";
import PropTypes from "prop-types";
import LessonTile from "../shared/LessonTile";
import SongTile from "../shared/SongTile";
import { goToLesson } from "../shared/utils";

function formattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}

const LessonsList = ({ items }) => {
  return items.map((item) => {
    const { title, pageReleaseDate, slug, groupDisplayTitle, gradeId } = item.attributes;

    return (
      <div key={item.id}>
        <span className="release-date">{pageReleaseDate && formattedDate(pageReleaseDate)}</span>

        <div className="clickable" onClick={() => goToLesson("Lesson", slug)}>
          <LessonTile
            title={title}
            lesson={item}
            gradeId={gradeId}
            groupTitle={groupDisplayTitle}
          />
        </div>
      </div>
    );
  });
};

const SongsList = ({ items }) => {
  return items.map((item) => {
    const { title, pageReleaseDate, slug, artistImageUid, artistDisplayName, gradeId } =
      item.attributes;
    return (
      <div key={item.id}>
        <span className="release-date">{pageReleaseDate && formattedDate(pageReleaseDate)}</span>

        <div key={item.id} className="clickable" onClick={() => goToLesson("Song", slug)}>
          <SongTile
            title={title}
            albumThumb={artistImageUid}
            gradeId={gradeId}
            artistName={artistDisplayName}
          />
        </div>
      </div>
    );
  });
};

const WhatsNewNew = ({ latestLessons, userSlug }) => {
  const [activeLessonType, setActiveLessonType] = React.useState("Lessons");

  return (
    <div className="latest-lessons">
      <p className="welcome__heading">What's New on JustinGuitar?</p>
      <div className="welcome__options">
        <span
          onClick={() => setActiveLessonType("Lessons")}
          className={activeLessonType === "Lessons" ? "active" : "clickable"}
        >
          Lessons
        </span>
        <span
          onClick={() => setActiveLessonType("Songs")}
          className={activeLessonType === "Songs" ? "active" : "clickable"}
        >
          Songs
        </span>
        <span
          onClick={() => setActiveLessonType("Playground")}
          className={activeLessonType === "Playground" ? "active" : "clickable"}
        >
          Playground
        </span>
        <span
          className="uppercase view-all righthand-opt clickable"
          onClick={() => {
            window.location.href = `/users/${userSlug}/latest`;
          }}
        >
          View All
        </span>
      </div>

      {activeLessonType === "Lessons" ? (
        <LessonsList items={latestLessons["lessons"] || []} />
      ) : activeLessonType === "Playground" ? (
        <LessonsList items={latestLessons["playground"] || []} />
      ) : (
        <SongsList items={latestLessons["songs"] || []} />
      )}
    </div>
  );
};

WhatsNewNew.propTypes = {
  latestLessons: PropTypes.object.isRequired,
};

export default WhatsNewNew;
