import React from "react";
import { Paper, Box, Typography, Grid, Link } from "@mui/material";
import SectionHeader from "./SectionHeader";
import {
  humanizedPricingPlanDuration,
  nextPaymentDue,
  unixToFormattedDate,
} from "../pricingPlans/utils";
import { PLAN_TYPES } from "../consts";

const LineItem = ({ checkoutSession, lineItem, pricingPlan }) => {
  const img = lineItem.priceData.productData?.images?.[0];
  const { name } = lineItem.priceData.productData;
  const { linkToProduct } = pricingPlan.attributes;

  const nextPayment = nextPaymentDue(
    checkoutSession.data.attributes.created,
    pricingPlan.attributes
  );

  const { trialPeriodEnd } = checkoutSession.data.attributes;

  const goToProduct = () => {
    if (linkToProduct) {
      window.location.href = linkToProduct;
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        cursor: linkToProduct ? "pointer" : "default",
      }}
      onClick={goToProduct}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={"auto"}>
          <img src={img} style={{ maxWidth: "150px", borderRadius: "4px" }} />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6" sx={{ letterSpacing: 0 }}>
            {name} {humanizedPricingPlanDuration(pricingPlan.attributes)}
          </Typography>

          {trialPeriodEnd ? (
            <Typography variant="body1">
              You will be charged ${lineItem.priceData.unitAmount / 100} after your free trial ends
              on {unixToFormattedDate(trialPeriodEnd)}.
            </Typography>
          ) : (
            nextPayment && (
              <Typography variant="body1">
                Next{" "}
                {pricingPlan.attributes.planType === PLAN_TYPES.installment
                  ? "installment"
                  : "payment"}{" "}
                due on {nextPayment}
              </Typography>
            )
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

const CheckoutSuccessItems = ({ lineItems, pricingPlans, checkoutSession }) => {
  if (!lineItems || lineItems.length === 0) return null;

  const { metadata } = checkoutSession.data.attributes;
  function associatedPricingPlan(lineItem) {
    const { name } = lineItem.priceData.productData;
    const pricingPlanId = metadata[name];
    return pricingPlans.find((pricingPlan) => pricingPlan.id === pricingPlanId);
  }

  return (
    <Box sx={{ py: 3 }}>
      <SectionHeader>Products</SectionHeader>
      {lineItems.map((lineItem, idx) => (
        <LineItem
          checkoutSession={checkoutSession}
          key={`lineItem-${idx}`}
          lineItem={lineItem}
          pricingPlan={associatedPricingPlan(lineItem)}
        />
      ))}
    </Box>
  );
};

export default CheckoutSuccessItems;
