import { Box, Button, IconButton } from "@mui/material";
import React from "react";
import AppIcons from "./shared/AppIcons";
import { CDN_URL } from "./shared/CdnUrl";
import Swoosh from "./shared/Swoosh";
import { ThemeProvider } from "@mui/material";
import { jgDenimTheme } from "../jg-material/theme/jg-denim-theme";
import { withInnerWidth } from "./shared";
import { COMMUNITY_URL } from "./shared/Community";

const YouTubeIcon = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
      fill="#406E8E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.2429 16.5674C32.973 15.5306 32.1778 14.7142 31.1681 14.4371C29.3382 13.9336 21.9999 13.9336 21.9999 13.9336C21.9999 13.9336 14.6617 13.9336 12.8317 14.4371C11.8219 14.7142 11.0267 15.5306 10.7569 16.5674C10.2666 18.4464 10.2666 22.3669 10.2666 22.3669C10.2666 22.3669 10.2666 26.2873 10.7569 28.1665C11.0267 29.2032 11.8219 30.0196 12.8317 30.2968C14.6617 30.8003 21.9999 30.8003 21.9999 30.8003C21.9999 30.8003 29.3382 30.8003 31.1681 30.2968C32.1778 30.0196 32.973 29.2032 33.2429 28.1665C33.7333 26.2873 33.7333 22.3669 33.7333 22.3669C33.7333 22.3669 33.7333 18.4464 33.2429 16.5674"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7998 26.3997V19.0664L25.6665 22.7332L19.7998 26.3997Z"
      fill="#406E8E"
    />
  </svg>
);

const FacebookIcon = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
      fill="#406E8E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.2936 34.9355V22.9664H27.5976L28.0355 18.8417H24.2936L24.2992 16.7773C24.2992 15.7015 24.4014 15.1251 25.9466 15.1251H28.0121V11H24.7076C20.7384 11 19.3413 13.0009 19.3413 16.3658V18.8422H16.8672V22.9668H19.3413V34.9355H24.2936Z"
      fill="white"
    />
  </svg>
);

const InstagramIcon = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
      fill="#406E8E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.001 10.2666C18.8144 10.2666 18.4145 10.2805 17.1629 10.3375C15.9138 10.3947 15.0612 10.5924 14.3151 10.8826C13.5434 11.1823 12.8888 11.5832 12.2366 12.2356C11.5839 12.8878 11.183 13.5424 10.8824 14.3139C10.5915 15.0602 10.3935 15.9131 10.3372 17.1617C10.2813 18.4133 10.2666 18.8134 10.2666 22.0001C10.2666 25.1867 10.2808 25.5854 10.3375 26.8369C10.3949 28.0861 10.5927 28.9387 10.8826 29.6847C11.1825 30.4565 11.5834 31.1111 12.2359 31.7633C12.8878 32.4159 13.5424 32.8178 14.3137 33.1175C15.0602 33.4077 15.9131 33.6054 17.162 33.6626C18.4135 33.7196 18.8132 33.7335 21.9996 33.7335C25.1864 33.7335 25.5851 33.7196 26.8367 33.6626C28.0858 33.6054 28.9394 33.4077 29.686 33.1175C30.4574 32.8178 31.1111 32.4159 31.763 31.7633C32.4157 31.1111 32.8166 30.4565 33.1173 29.685C33.4057 28.9387 33.6037 28.0858 33.6624 26.8372C33.7186 25.5856 33.7333 25.1867 33.7333 22.0001C33.7333 18.8134 33.7186 18.4135 33.6624 17.162C33.6037 15.9128 33.4057 15.0602 33.1173 14.3142C32.8166 13.5424 32.4157 12.8878 31.763 12.2356C31.1104 11.5829 30.4577 11.1821 29.6852 10.8826C28.9372 10.5924 28.0841 10.3947 26.835 10.3375C25.5834 10.2805 25.185 10.2666 21.9974 10.2666L22.001 10.2666Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9487 12.3809C21.1531 12.3805 21.373 12.3806 21.6102 12.3807L22.0013 12.3809C25.1341 12.3809 25.5055 12.3921 26.7426 12.4483C27.8866 12.5006 28.5075 12.6918 28.9211 12.8524C29.4687 13.0651 29.8591 13.3193 30.2695 13.73C30.6802 14.1406 30.9344 14.5318 31.1475 15.0793C31.3081 15.4924 31.4995 16.1133 31.5516 17.2573C31.6078 18.4942 31.6201 18.8658 31.6201 21.9972C31.6201 25.1285 31.6078 25.5001 31.5516 26.737C31.4993 27.881 31.3081 28.5019 31.1475 28.915C30.9349 29.4626 30.6802 29.8525 30.2695 30.2629C29.8588 30.6736 29.4689 30.9278 28.9211 31.1405C28.508 31.3018 27.8866 31.4925 26.7426 31.5448C25.5057 31.601 25.1341 31.6132 22.0013 31.6132C18.8682 31.6132 18.4969 31.601 17.26 31.5448C16.116 31.492 15.4951 31.3008 15.0813 31.1402C14.5337 30.9275 14.1426 30.6733 13.7319 30.2626C13.3212 29.852 13.067 29.4618 12.8539 28.914C12.6933 28.5009 12.5019 27.88 12.4498 26.736C12.3936 25.4991 12.3823 25.1276 12.3823 21.9942C12.3823 18.8609 12.3936 18.4913 12.4498 17.2544C12.5021 16.1104 12.6933 15.4895 12.8539 15.0759C13.0665 14.5283 13.3212 14.1372 13.7319 13.7265C14.1426 13.3159 14.5337 13.0616 15.0813 12.8485C15.4949 12.6872 16.116 12.4965 17.26 12.4439C18.3424 12.395 18.7619 12.3804 20.9487 12.3779V12.3809ZM28.2644 14.3291C27.487 14.3291 26.8564 14.9591 26.8564 15.7367C26.8564 16.514 27.487 17.1447 28.2644 17.1447C29.0417 17.1447 29.6724 16.514 29.6724 15.7367C29.6724 14.9593 29.0417 14.3286 28.2644 14.3286V14.3291ZM15.9754 21.9999C15.9754 18.6723 18.6733 15.9744 22.0008 15.9743C25.3285 15.9743 28.0257 18.6722 28.0257 21.9999C28.0257 25.3275 25.3287 28.0243 22.001 28.0243C18.6734 28.0243 15.9754 25.3275 15.9754 21.9999Z"
      fill="#406E8E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.001 18.0889C24.1609 18.0889 25.9121 19.8398 25.9121 22C25.9121 24.16 24.1609 25.9112 22.001 25.9112C19.8408 25.9112 18.0898 24.16 18.0898 22C18.0898 19.8398 19.8408 18.0889 22.001 18.0889V18.0889Z"
      fill="#406E8E"
    />
  </svg>
);

const TikTokIcon = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" rx="22" fill="#406E8E" />
    <path
      d="M32.9739 15.6793C31.4413 15.6793 29.9715 15.0704 28.8877 13.9867C27.804 12.9029 27.1952 11.433 27.1952 9.90039H23.0666V26.0675C23.0658 26.8046 22.833 27.5228 22.4013 28.1202C21.9696 28.7176 21.3608 29.164 20.6612 29.3961C19.9616 29.6282 19.2067 29.6343 18.5035 29.4134C17.8002 29.1924 17.1844 28.7558 16.7432 28.1653C16.302 27.5749 16.0578 26.8605 16.0453 26.1235C16.0328 25.3865 16.2525 24.6643 16.6734 24.0592C17.0943 23.4541 17.6949 22.9967 18.3902 22.7521C19.0855 22.5074 19.8402 22.4877 20.5473 22.6959V18.6825C19.0446 18.4798 17.5158 18.7405 16.1652 19.4296C14.8145 20.1186 13.7062 21.2034 12.9883 22.5389C12.2704 23.8745 11.9769 25.3973 12.1473 26.904C12.3176 28.4107 12.9435 29.8295 13.9414 30.9711C14.9393 32.1127 16.2618 32.9227 17.7322 33.2929C19.2026 33.6632 20.7509 33.576 22.1705 33.043C23.59 32.5101 24.8132 31.5568 25.6767 30.3104C26.5402 29.064 27.0028 27.5838 27.0029 26.0675L27.1234 17.8956C28.8197 19.1408 30.8699 19.8109 32.9743 19.8078L32.9739 15.6793Z"
      fill="white"
    />
  </svg>
);

const FooterCol = ({ header, link, items }) => {
  return (
    <div key={header} className="footer__col">
      <div className={`col-header${header.toLowerCase() === "more" ? " capitalized" : ""}`}>
        {link ? <a href={link}>{header}</a> : header}
      </div>
      {items.map(({ link, title }) => (
        <div key={link}>
          <a
            href={link}
            target={link.startsWith("http") ? "_blank" : "_self"}
            className={title.toLowerCase() === "view all" ? "strong" : null}
          >
            {title}
          </a>
          {link.startsWith("http") && (
            <span style={{ marginLeft: "5px" }}>
              <svg
                width="10"
                height="10"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 19H5V5H12V3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V12H19V19ZM14 3V5H17.59L7.76 14.83L9.17 16.24L19 6.41V10H21V3H14Z"
                  fill="#fff"
                />
              </svg>
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

const AppCol = () => {
  return (
    <div className="footer__col app">
      <p>lessons & songs app</p>
      <AppIcons black onelinkUrl="https://go.onelink.me/3065804798/f5df5605" />
    </div>
  );
};

const Footer = ({ currentUser, width }) => {
  return (
    <Swoosh excludeBottom>
      <ThemeProvider theme={jgDenimTheme}>
        <footer className="footer">
          <div className="footer__content container">
            <div className="footer__row footer__row--top">
              {width >= 576 ? (
                <>
                  {footerNavSections.map(({ header, link, items }, idx) => (
                    <FooterCol key={idx} header={header} link={link} items={items} />
                  ))}
                  <AppCol />
                </>
              ) : (
                <>
                  <div className="footer__part">
                    {footerNavSectionsSm.col1.map(({ header, link, items }, idx) => (
                      <FooterCol key={idx} header={header} link={link} items={items} />
                    ))}
                  </div>
                  <div className="footer__part">
                    {footerNavSectionsSm.col2.map(({ header, link, items }, idx) => (
                      <FooterCol key={idx} header={header} link={link} items={items} />
                    ))}
                    <AppCol />
                  </div>
                </>
              )}
            </div>
            <div className="footer__row footer__row--bottom">
              <div className="footer__col col col-md-12 col-lg-6">
                <div className="links">
                  <a href="/faq">FAQ</a>
                  <a href="/contact">Contact</a>
                  <a href="/about">About Justin</a>
                  <a href="/privacy-policy">Privacy Policy</a>
                </div>
              </div>
              <div className="footer__col col col-md-12 col-lg-6">
                <div className="buttons">
                  <div className="action-buttons">
                    <div className="auth-buttons">
                      {currentUser == null && (
                        <>
                          <a className="button button--blue" href="/users/sign_in">
                            Log In
                          </a>
                          <a className="button button--primary" href="/users/sign_up">
                            Join
                          </a>
                        </>
                      )}
                    </div>
                    <a href="/donate" className="button button--primary donate-button">
                      Donate
                    </a>
                  </div>
                  <div className="social-buttons">
                    <div className="social-links">
                      <Button target={"_blank"} href="https://www.youtube.com/user/JustinSandercoe">
                        <YouTubeIcon />
                      </Button>
                      <Button
                        target={"_blank"}
                        href="https://www.facebook.com/justinguitarofficial"
                      >
                        <FacebookIcon />
                      </Button>
                      <Button target={"_blank"} href="https://www.instagram.com/justinguitar">
                        <InstagramIcon />
                      </Button>
                      <Button target={"_blank"} href="https://www.tiktok.com/@justinguitarofficial">
                        <TikTokIcon />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer__row footer__row--copyright">
              © JustinGuitar {new Date().getFullYear()}. All rights reserved.
            </div>
          </div>
        </footer>
      </ThemeProvider>
    </Swoosh>
  );
};

const footerNavSections = [
  {
    header: "courses",
    link: "/guitar-lessons",
    items: [
      {
        title: "Beginner Grade 1",
        link: "/classes/beginner-guitar-course-grade-one",
      },
      {
        title: "Beginner Grade 2",
        link: "/classes/beginner-guitar-course-grade-two",
      },
      {
        title: "Beginner Grade 3",
        link: "/classes/beginner-guitar-course-grade-three",
      },
      {
        title: "Intermediate Grade 4",
        link: "/classes/intermediate-guitar-course-grade-four",
      },
      {
        title: "Intermediate Grade 5",
        link: "/classes/intermediate-guitar-course-grade-five",
      },
      {
        title: "Intermediate Grade 6",
        link: "/classes/intermediate-guitar-course-grade-six",
      },
      {
        title: "Advanced Grade 7",
        link: "/classes/advanced-guitar-course-grade-seven",
      },
      {
        title: "View all",
        link: "/guitar-lessons",
      },
    ],
  },
  {
    header: "songs",
    link: "/songs",
    items: [
      {
        title: "Guitar Song Lessons",
        link: "/songs",
      },
      {
        title: "Ukulele Song Lessons",
        link: "/modules/ukulele-songs",
      },
    ],
  },
  {
    header: "playground",
    link: "/playground",
    items: [
      {
        title: "Food for Thought",
        link: "/playground/food-for-thought",
      },
      {
        title: "Rut Busters",
        link: "/playground/rut-busters",
      },
      {
        title: "Quick Tips",
        link: "/playground/quick-tips",
      },
      {
        title: "Interviews",
        link: "/playground/interviews",
      },
      {
        title: "Giveaways",
        link: "/playground/giveaways",
      },
      {
        title: "JustinGuitar News",
        link: "/playground/justinguitar-news",
      },
      {
        title: "View all",
        link: "/playground",
      },
    ],
  },
  {
    header: "store",
    link: "/store",
    items: [
      {
        title: "Find What’s Best For Me",
        link: "/store/find-whats-best-for-me#choose",
      },
      {
        title: "Lessons & Songs App",
        // link: "/store/lessons-and-songs-app",
        link: "https://justinguitarapp.musopia.net",
        target: "_blank",
      },
      { title: "JustinGuitar Tabs", link: "/store/tabs" },
      {
        title: "Practical Music Theory",
        link: "/classes/practical-fast-fun-music-theory",
      },
      {
        title: "Jam Tracks",
        link: "https://justinguitarjams.com",
        target: "_blank",
      },
      { title: "Merch", link: "https://merch.justinguitar.com", target: "_blank" },
      {
        title: "View all",
        link: "/store",
      },
    ],
  },
  {
    header: "more",
    items: [
      {
        title: "Community",
        link: COMMUNITY_URL,
      },
      {
        title: "Clubs",
        link: "/clubs",
      },
      {
        title: "Chord Library",
        link: "/chords",
      },
      {
        title: "Approved Teachers",
        link: "/teachers",
      },
      {
        title: "Workshop & Events",
        link: "/workshops",
      },
      // {
      //   title: "Interval Ear Trainer",
      //   link: "/interval-ear-trainer",
      // },
      {
        title: "Metronome",
        link: "/metronome",
      },
      {
        title: "Tempo Calculator",
        link: "/tap-tempo-bpm-tool",
      },
    ],
  },
];

const footerNavSectionsSm = {
  col1: [
    footerNavSections.find((el) => el.header === "courses"),
    footerNavSections.find((el) => el.header === "songs"),
    footerNavSections.find((el) => el.header === "playground"),
  ],
  col2: [
    footerNavSections.find((el) => el.header === "store"),
    footerNavSections.find((el) => el.header === "more"),
  ],
};

export default withInnerWidth(Footer);
