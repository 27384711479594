import React from "react";
import Fuse from "fuse.js";
import { EmptyChordBoxIcon } from "./Icons";

export const ChordSearchResults = ({ chordsWithoutVariants, searchPhrase }) => {
  const foundChords = new Fuse(chordsWithoutVariants, { keys: ["title"] })
    .search(searchPhrase)
    .map(({ item }) => item);

  return (
    <div className="chords__content__search-results">
      {foundChords.length ? (
        <>
          <h2>{`${foundChords.length} search results found for ‘${searchPhrase}’`}</h2>
          <div className="chords__content__search-results__chords">
            {foundChords.map((chord, idx) => (
              <button key={idx} onClick={() => (window.location.href = `/chords/${chord.slug}`)}>
                {chord.title}
              </button>
            ))}
          </div>
        </>
      ) : (
        <div className="chords__content__search-results__not-found">
          <EmptyChordBoxIcon />
          <p>Sorry we couldn’t find any match for {searchPhrase} :(</p>
          <p>
            Please note this Chord Library is constantly being updated and will start with the most
            common chords.
          </p>
        </div>
      )}
    </div>
  );
};
