import React from "react";

const AccountVerified = () => {
  return (
    <div className="auth__container auth__container--success auth__container--complete">
      <i className="icon icon-check"></i>
      <p>Account verified.</p>
      <p>Your account is now ready to be used.</p>
      <div className="auth__btns">
        <a href="/site-map-and-lesson-structure" className="button button--primary">
          Explore JustinGuitar
        </a>
        <a href="/" className="button button--secondary">
          Go to My Dashboard
        </a>
      </div>
    </div>
  );
};

export default AccountVerified;
