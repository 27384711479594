import React, { useEffect } from "react";
import StrummingPatternDiagramDivision from "./StrummingPatternDiagramDivision";
import { DotsIcon, LinkIcon, PencilIcon, ResetIcon, TrashIcon } from "./Icons";
import { capitalize } from "../../../dashboard/shared/utils";
import { decodePattern, encodePattern, getEncodedPatternString } from "./lib";

function StrummingPatternDiagram({
  savedStrummingPatterns = null,
  selectedStrummingPattern,
  activeDivision = -1,
  onDivisionClick = null,
  onStrummingPatternSelect = null,
  saved = false,
  deleteStrummingPattern = null,
  openAlert = null,
  createStrummingPatternItem = null,
  deleteStrummingPatternItem = null,
  showControls = true,
  openDialog = null,
  savePattern = null,
  copyPattern = null,
  resetPattern = null,
  inLibrary = false,
}) {
  const [menuVisible, setMenuVisible] = React.useState(false);
  const strummingPatternDiagramDivisions = [];

  const savedPattern =
    savedStrummingPatterns &&
    savedStrummingPatterns.length &&
    savedStrummingPatterns.find(
      (strummingPatterns) =>
        strummingPatterns.attributes.data.strumming_pattern.pattern ===
        getEncodedPatternString(selectedStrummingPattern)
    );

  useEffect(() => {
    const onPageClick = (e) => {
      if (!e.target.closest(`.menu`)) {
        setMenuVisible(false);
      }
    };

    document.body.addEventListener("click", onPageClick, true);

    return () => {
      document.body.removeEventListener("click", onPageClick, true);
    };
  });

  for (const [key, value] of Object.entries(selectedStrummingPattern)) {
    // only get values, which keys can be converted to numbers
    if (!isNaN(key)) {
      strummingPatternDiagramDivisions.push({
        noteCount: parseInt(key) + 1,
        strummingDirection: value,
      });
    }
  }

  // console.log("selectedStrummingPattern", selectedStrummingPattern);

  return (
    <>
      {!inLibrary && (
        <div className="save-share-btns">
          <button onClick={() => savePattern(savedPattern, selectedStrummingPattern)}>
            <i className={`icon ${savedPattern ? "icon-heart" : "icon-heart-outline"}`}></i>
            Save{savedPattern ? "d" : ""}
          </button>
          <button onClick={() => copyPattern(selectedStrummingPattern)}>
            <LinkIcon />
            Share
          </button>
          <button onClick={() => resetPattern()}>
            <ResetIcon />
            Reset
          </button>
        </div>
      )}
      <div
        className={`strumming-pattern-diagram${onStrummingPatternSelect ? " selectable" : ""}`}
        onClick={() =>
          onStrummingPatternSelect ? onStrummingPatternSelect(selectedStrummingPattern) : null
        }
      >
        <div className="strumming-pattern-diagram-divisions">
          {strummingPatternDiagramDivisions.map(
            (strummingPatternDiagramDivision, idx) =>
              idx % 2 === 0 && (
                <div key={idx} className="strumming-pattern-diagram-division-pair">
                  <StrummingPatternDiagramDivision
                    key={idx}
                    noteCount={strummingPatternDiagramDivision.noteCount}
                    strummingDirection={strummingPatternDiagramDivision.strummingDirection}
                    active={activeDivision === idx}
                    onDivisionClick={onDivisionClick ? onDivisionClick : null}
                  />
                  {strummingPatternDiagramDivisions[idx + 1] && (
                    <StrummingPatternDiagramDivision
                      key={idx + 1}
                      noteCount={strummingPatternDiagramDivisions[idx + 1].noteCount}
                      strummingDirection={
                        strummingPatternDiagramDivisions[idx + 1].strummingDirection
                      }
                      active={activeDivision === idx + 1}
                      onDivisionClick={onDivisionClick ? onDivisionClick : null}
                    />
                  )}
                </div>
              )
          )}
        </div>
        <div className="strumming-pattern-diagram-name">
          <div>
            {selectedStrummingPattern.id === -1 ? "Custom" : selectedStrummingPattern.name}
            {selectedStrummingPattern.saved && <div className="my-pattern">my pattern</div>}
            {selectedStrummingPattern.name === "Unsaved" && (
              <div
                className="my-pattern"
                onClick={() => {
                  openDialog({
                    message: `Save the pattern?`,
                    confirmButtonText: "Confirm",
                    data: selectedStrummingPattern,
                    input: {
                      type: "text",
                      placeholder: "Pattern Name",
                      value: ["blank", "unsaved"].includes(
                        selectedStrummingPattern.name.toLowerCase()
                      )
                        ? ""
                        : selectedStrummingPattern.name,
                    },
                  });
                }}
              >
                click to save
              </div>
            )}
          </div>
          {showControls && (
            <>
              <div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (savePattern) {
                      savePattern(savedPattern, selectedStrummingPattern);
                    }
                  }}
                >
                  <i
                    className={`icon ${
                      selectedStrummingPattern.saved || saved ? "icon-heart" : "icon-heart-outline"
                    }`}
                  ></i>
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setMenuVisible(true);
                  }}
                >
                  <DotsIcon />
                </button>
              </div>
              <div className="menu" style={{ display: menuVisible ? "flex" : "none" }}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setMenuVisible(false);
                    if (copyPattern) {
                      copyPattern(selectedStrummingPattern);
                    }
                  }}
                >
                  <LinkIcon /> Share
                </button>
                {selectedStrummingPattern.saved && (
                  <>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log(selectedStrummingPattern);
                        setMenuVisible(false);
                        openDialog({
                          message: `Rename the pattern?`,
                          confirmButtonText: "Confirm",
                          data: selectedStrummingPattern,
                          input: {
                            type: "text",
                            placeholder: selectedStrummingPattern.name,
                            value: selectedStrummingPattern.name,
                          },
                        });
                      }}
                    >
                      <PencilIcon /> Rename
                    </button>
                    {/*<button*/}
                    {/*  onClick={(e) => {*/}
                    {/*    e.stopPropagation();*/}
                    {/*    savePattern();*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <TrashIcon /> Delete*/}
                    {/*</button>*/}
                  </>
                )}
                {/*<button onClick={() => console.log("edit")}>*/}
                {/*  <i className="icon icon-pencil"></i> Edit*/}
                {/*</button>*/}
                {/*<button*/}
                {/*  onClick={() => {*/}
                {/*    setMenuVisible(false);*/}
                {/*    // openDialog({*/}
                {/*    //   message: "Delete this note?",*/}
                {/*    //   confirmButtonText: "Delete",*/}
                {/*    //   data: note,*/}
                {/*    // });*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <i className="icon icon-bin"></i>*/}
                {/*  Delete*/}
                {/*</button>*/}
                {/*<button onClick={() => console.log("go to")}>*/}
                {/*  <i className="icon icon-play"></i>Go To{" "}*/}
                {/*</button>*/}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default StrummingPatternDiagram;
