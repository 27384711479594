import { createAsyncThunk } from "@reduxjs/toolkit";
import * as workshopsApi from "./workshops.api";
import { normalize } from "normalizr";
import { workshopSchema } from "./workshop.schema";
import { WorkshopEntity } from "../interfaces";
import { workshopsSlice } from "./workshops.slice";

export const fetchWorkshops = createAsyncThunk(
  "workshops/fetchAll",
  async (params, { dispatch }) => {
    const {
      data: { data },
    } = await workshopsApi.fetchWorkshops();

    const {
      entities: { workshops = {} },
      // result: orderedIds, // not used since we don't rely on api for ordering
    } = normalize<WorkshopEntity>(data, [workshopSchema]);

    dispatch(workshopsSlice.actions.setAll(workshops));
  }
);
