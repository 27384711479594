import React from "react";
import PropTypes from "prop-types";
import axiosWithCSRF from "../shared/axiosWithCSRF";
import Recaptcha from "react-recaptcha";
import ReactDOM from "react-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Loading from "../shared/Loading";
import loadScript from "../shared/loadScript";

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters long."),
  passwordConfirmation: Yup.string()
    .required("Password Confirmation is required.")
    .oneOf([Yup.ref("password"), null], "Passwords don't match."),
});

export default class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recaptchaToken: "",
    };
    this.recaptchaInstance = React.createRef();
  }

  componentDidMount() {
    loadScript("https://www.google.com/recaptcha/api.js");
  }

  handleSubmit = (values) => {
    this.setState({ isSubmitting: true }, () => {
      axiosWithCSRF({ "Content-Type": "application/json", Accept: "application/json" })
        .put("/users/password", {
          user: {
            password: values.password,
            passwordConfirmation: values.passwordConfirmation,
            reset_password_token: this.props.resetPasswordToken,
          },
          ["g-recaptcha-response"]: this.state.recaptchaToken,
        })
        .then(() => {
          // user has been logged in, so take him/her to dashboard
          window.location.href = "/";
        })
        .catch((error) => {
          let responseData = error.response.data;
          let message =
            responseData && responseData.error
              ? responseData.error
              : "There was a problem resetting your password.";
          this.recaptchaInstance.current.reset();
          this.setState({ isSubmitting: false, error: message });
          setTimeout(() => this.setState({ error: null }), 4000);
        });
    });
  };

  inputs = (props) => {
    let fields = [
      { field: "password", label: "Password" },
      { field: "passwordConfirmation", label: "Password Confirmation" },
    ];
    return fields.map((p) => {
      return (
        <div className="auth__input" key={p.field}>
          <Field
            name={p.field}
            type="password"
            className={
              (props.values[p.field].length > 0 ? "populated" : "") +
              (props.errors[p.field] && props.touched[p.field] ? " has-error" : "")
            }
          />

          {props.touched[p.field] && !props.errors[p] && (
            <i className="auth__response-icon icon icon-check"></i>
          )}
          <span className="auth__label">{p.label}</span>
          {props.errors[p.field] && props.touched[p.field] && (
            <>
              <i className="auth__response-icon icon icon-cancel-circle"></i>
              <div className="error-message">{props.errors[p.field]}</div>
            </>
          )}
        </div>
      );
    });
  };

  recaptchaCallback = () => {
    // for some reason verifyCallback is only working if onloadCallback is defined, so define it here and do nothing.
    return;
  };

  recaptchaVerified = (recaptchaToken) => {
    this.setState({ recaptchaToken });
  };

  render() {
    return (
      <div className="auth">
        <div className="auth__container forgot-password">
          {this.state.isSubmitting && <Loading />}
          <h3 className="header3 uppercase login-header">Change your password</h3>
          {this.state.error && <p className="has-error">{this.state.error}</p>}
          <Formik
            initialValues={{
              password: "",
              passwordConfirmation: "",
            }}
            validationSchema={PasswordSchema}
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}
          >
            {(props) => (
              <Form className="auth__form">
                {this.inputs(props)}
                <div className="auth__recaptcha">
                  <Recaptcha
                    render="explicit"
                    ref={this.recaptchaInstance}
                    sitekey={this.props.recaptchaKey}
                    onloadCallback={this.recaptchaCallback}
                    verifyCallback={this.recaptchaVerified}
                  />
                </div>

                <button
                  disabled={
                    props.values.password.length < 8 ||
                    props.values.passwordConfirmation != props.values.password ||
                    this.state.recaptchaToken === ""
                  }
                  className="button button--primary auth__btn"
                  type="submit"
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

PasswordReset.propTypes = {
  resetPasswordToken: PropTypes.string.isRequired,
};
