import React from "react";
import FiltersList from "../shared/FiltersList";
import { filters, filterTypes } from "../filters/filters";
import { withBootstrapSize } from "../shared/WithBootstrapSize";
import { PreventScroll } from "../shared";
import { BootstrapSize, breakpoints } from "../shared/bootstrap-helpers";
import { FiltersOpenCloseButton } from "../shared/FiltersOpenCloseButton";
import SongsFiltersList from "../songs-list/SongsFiltersList";

const artistFilterTypes = Object.entries(filterTypes)
  .filter(([_, val]) =>
    [filterTypes["Difficulty Level"], filterTypes["Books & Apps"], filterTypes.Genre].includes(val)
  )
  .reduce((accum, [key, val]) => ({ ...accum, [key]: val }), {});

export const artistFilters = filters.filter(({ type }) => artistFilterTypes[type] != null);

const ArtistFilters = ({ activeFilters, clearFilters, toggleFilter }) => {
  return (
    <SongsFiltersList
      availableFilters={artistFilters}
      activeFilters={activeFilters}
      clearFilters={clearFilters}
      toggleFilter={toggleFilter}
    />
  );
};

export default ArtistFilters;
