import React from "react";
import DeleteItem from "../Actions/DeleteItem";
import RestoreItem from "../Actions/RestoreItem";
import DisabledItem from "../Actions/DisabledItem";

export default class ArchivedItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formToShow: null,
      itemIdToEdit: null,
    };
  }

  isEditing = (itemId) => {
    return this.state.itemIdToEdit === itemId ? true : false;
  };

  individualItem = (item) => {
    return (
      <div
        key={item.id}
        className={"routine__item " + (this.isEditing(item.id) ? "routine__item--editing" : "")}
      >
        <div>
          {this.caretToDisplay(item.id)}
          <span className="routine__title"> {item.title} </span>
        </div>

        <div className="routine__item__options">
          <button
            type="button"
            title="Restore to Active Items"
            alt="Restore to Active Items"
            className="practice__icon practice__icon--add"
            onClick={() => this.toggleForm("restore", item.id)}
          >
            <img src="https://jtgt-static.b-cdn.net/images/practice-routine/add-button.svg" />
          </button>
          <button
            type="button"
            title="Delete Item"
            alt="Delete Item"
            className="practice__icon"
            onClick={() => this.toggleForm("delete", item.id)}
          >
            <img src="https://jtgt-static.b-cdn.net/images/practice-routine/delete-button.svg" />
          </button>
        </div>

        {this.showForm(item)}
        {this.disabledItemForm(item)}
      </div>
    );
  };

  disabledItemForm = (item) => {
    if (this.state.openItemId === item.id) {
      return <DisabledItem item={item} showEditIcons={false} />;
    } else {
      return null;
    }
  };

  closeForm = () => {
    this.setState({ responseText: null, formToShow: null, itemIdToEdit: null, errorText: null });
  };

  toggleForm = (formType, itemId) => {
    let { formToShow, itemIdToEdit } = this.state;
    if (itemIdToEdit === itemId && formToShow === formType) {
      this.setState({ formToShow: null, itemIdToEdit: null, openItemId: null });
    } else {
      this.setState({ formToShow: formType, itemIdToEdit: itemId, openItemId: null });
    }
  };

  toggleDetails = (itemId) => {
    this.setState({ openItemId: itemId });
  };

  caretToDisplay = (itemId) => {
    if (this.state.openItemId === itemId && this.isEditing(itemId) != true) {
      return <i className="icon icon-minus" onClick={() => this.toggleDetails(null)}></i>;
    } else if (this.isEditing(itemId)) {
      return null;
    } else {
      return <i className="icon icon-plus" onClick={() => this.toggleDetails(itemId)}></i>;
    }
  };

  responseMessage = () => {
    if (this.state.responseText) {
      return <p className="success">{this.state.responseText}</p>;
    } else if (this.state.errorText) {
      return <p className="has-errors">{this.state.errorText}</p>;
    }
  };

  showForm = (item) => {
    if (item.id === this.state.itemIdToEdit) {
      if (this.state.formToShow === "restore") {
        return (
          <div>
            {this.responseMessage()}
            <RestoreItem
              item={item}
              closeForm={this.closeForm}
              handleRestoredItem={this.handleRestoredItem}
            />
          </div>
        );
      } else if (this.state.formToShow === "delete") {
        return (
          <div>
            {this.responseMessage()}
            <DeleteItem
              item={item}
              closeForm={this.closeForm}
              handleDeletedItem={this.handleDeletedItem}
            />
          </div>
        );
      }
    }
  };

  handleDeletedItem = (item) => {
    if (item === null) {
      this.setState({ errorText: `There was a problem deleting this item.` });
      setTimeout(() => this.closeForm(), 2000);
    } else {
      this.props.handleDeletedItem(item);
    }
  };

  handleRestoredItem = (item) => {
    if (item === null) {
      this.setState({ errorText: `There was a problem restoring this item.` });
      setTimeout(() => this.closeForm(), 2000);
    } else {
      this.closeForm();
      this.props.handleRestoredItem(item);
    }
  };

  sortedItems = () => {
    return this.props.items
      .filter((i) => i.status === "archived")
      .sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        } else if (b.title.toLowerCase() < a.title.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
  };

  render() {
    if (this.props.items) {
      return <div>{this.sortedItems().map((item) => this.individualItem(item))}</div>;
    } else {
      return <p>no items!</p>;
    }
  }
}
