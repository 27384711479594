import React from "react";

import { CDN_URL } from "../components/shared/CdnUrl";
import { DarkTooltip } from "../components/shared/ui/Tooltips";
import Dialog from "../components/shared/ui/Dialog";

export function canAccessQuiz(user) {
  if (!user) return false;

  const { enrollments, hasActivePmtSubscription } = user.data.attributes;

  return enrollments.includes("PMT") || hasActivePmtSubscription;
}

export default function ({ group, currentUser, openDialog }) {
  const quiz = (group.included || []).find((entity) => entity.type === "quiz");

  if (quiz == null) {
    return null;
  }

  const linkDestination = `/quizzes/${quiz.attributes.slug}${
    quiz.attributes.attempt == null ? "" : "/results"
  }`;

  const openQuiz = () => {
    canAccessQuiz(currentUser)
      ? (window.location.href = linkDestination)
      : openDialog({
          message: `Please make sure you are logged in and enrolled in the Practical Music Theory course to access this quiz.`,
          confirmButtonText: "enroll",
          data: currentUser,
        });
  };

  return (
    <div className="group-lesson group-lesson__quiz flex clickable" onClick={() => openQuiz()}>
      <div className="group-lesson__quiz-thumb-container">
        <img
          className="group-lesson__quiz-thumb"
          src={`${CDN_URL}/redesign/2020/quiz/quiz-thumb.png`}
          alt=""
        />
      </div>
      <div className="group-lesson__quiz-info">
        <div className="group-lesson__quiz-info-title">{quiz.attributes.title}</div>
        <div className="group-lesson__quiz-info-description">{quiz.attributes.description}</div>
        <div className="group-lesson__quiz-info-pass-percent">
          Passing Grade: {quiz.attributes.passPercent}%
        </div>
      </div>
      <QuizScoreboard quiz={quiz} />
      <Dialog onConfirm={() => (window.location.href = "/store/practical-music-theory")} />
    </div>
  );
}

const QuizScoreboard = ({ quiz }) => {
  const { attempt } = quiz.attributes;

  if (attempt == null) {
    return null;
  }

  const passed = attempt.data.attributes.scorePercent >= quiz.attributes.passPercent;

  return (
    <DarkTooltip title={"Your most recent score for this quiz"}>
      <div
        className={`group-lesson__quiz-scoreboard group-lesson__quiz-scoreboard--${
          passed ? "passed" : "failed"
        }`}
      >
        {attempt.data.attributes.scorePercent}
      </div>
    </DarkTooltip>
  );
};
