import React from "react";
import { withInnerWidth } from "./WithInnerWidth";
import { BootstrapSize, breakpoints } from "./bootstrap-helpers";

const getBootstrapSize = (width) => {
  if (width == null) {
    return null;
  }

  return width < breakpoints[BootstrapSize.smDevice]
    ? BootstrapSize.xsDevice
    : width < breakpoints[BootstrapSize.mdDevice]
    ? BootstrapSize.smDevice
    : width < breakpoints[BootstrapSize.lgDevice]
    ? BootstrapSize.mdDevice
    : width < breakpoints[BootstrapSize.xlDevice]
    ? BootstrapSize.lgDevice
    : BootstrapSize.xlDevice;
};

export const withBootstrapSize = (ChildComponent) =>
  withInnerWidth(({ width, ...props }) => (
    <ChildComponent {...props} width={width} bootstrapSize={getBootstrapSize(width)} />
  ));
