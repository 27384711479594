import { configureStore, createSlice } from "@reduxjs/toolkit";
import ReactOnRails from "react-on-rails";
import { normalize } from "normalizr";

import {
  createUserPracticeItem,
  createUserSongItem,
  deleteUserPracticeItem,
  deleteUserSongItem,
} from "./searchEffects";
import { advancedSearchResults } from "./searchSchema";
import { appendResults, setLoading, setResults, clearResults } from "./searchActions";
import { initialState } from "./consts";
import { mergeEntities, mergeResult } from "./util";

const { actions, reducer } = createSlice({
  name: "search",
  initialState,
  reducers: {
    openSearch: (state) => {
      state.searchOpen = true;
    },
    closeSearch: (state) => {
      state.searchOpen = false;
      state.filtersOpen = false;
    },
    openFilters: (state) => {
      state.filtersOpen = true;
    },
    closeFilters: (state) => {
      state.filtersOpen = false;
    },
    setQuery: (state, { payload }) => {
      state.query = payload;
    },
    toggleFilter(state, { payload }) {
      // NB: JS Set is not supported in redux store because it's not serializable
      // consider adding immutable.js as a dependency
      if (state.filters[payload] === true) {
        delete state.filters[payload];
      } else {
        state.filters[payload] = true;
      }

      state.results = initialState.results;
    },
    setFilters: (state, { payload }) => {
      state.filters = payload.reduce((filters, filter) => ({ ...filters, [filter]: true }), {});
      state.results = initialState.results;
    },
    clearFilters: (state) => {
      state.filters = {};
      state.results = initialState.results;
    },
    setCurrentUser: (state, { payload }) => {
      state.session.currentUser = payload;
    },
    setVisibleType: (state, { payload }) => {
      state.visibleType = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setLoading, (state, { payload: { entity, loading } }) => {
        state.loading[entity] = loading;
      })
      .addCase(createUserSongItem.fulfilled, (state, { payload }) => {
        state.currentUser.included = [...state.currentUser.included, payload];
      })
      .addCase(deleteUserSongItem.fulfilled, (state, { payload }) => {
        const deletedItem = state.currentUser.included.filter(
          (i) => i.id === payload.id && i.type === "userItem"
        )[0];
        state.currentUser.included.splice(state.currentUser.included.indexOf(deletedItem), 1);
      })
      .addCase(createUserPracticeItem.fulfilled, (state, { payload }) => {
        state.currentUser.included = [
          ...state.currentUser.included,
          payload.serializedPracticeItem.data,
        ];
      })
      .addCase(deleteUserPracticeItem.fulfilled, (state, { payload }) => {
        const deletedItem = state.currentUser.included.filter(
          (i) => i.attributes.practiceItemItemableId === payload && i.type === "userPracticeItem"
        )[0];
        state.currentUser.included.splice(state.currentUser.included.indexOf(deletedItem), 1);
      })
      .addCase(setResults, (state, { payload }) => {
        state.results = payload;
      })
      .addCase(appendResults, (state, { payload }) => {
        state.results = {
          entities: mergeEntities(state.results.entities, payload.entities),
          result: mergeResult(state.results.result, payload.result),
        };
      })
      .addCase(clearResults, (state, { payload }) => {
        state.results = initialState.results;
      });
  },
});

export const {
  openSearch,
  closeSearch,
  openFilters,
  closeFilters,
  toggleFilter,
  setFilters,
  clearFilters,
  setQuery,
  setCurrentUser,
  setVisibleType,
} = actions;

const createSearchStore = (preloadedState) =>
  configureStore({
    reducer,
    preloadedState,
  });

ReactOnRails.registerStore({
  advancedSearchStore: (props, railsContext) => {
    const normalizedResults = normalize(props.results, advancedSearchResults);

    return createSearchStore({ ...initialState, ...props, results: normalizedResults });
  },
});
