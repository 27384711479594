import React, { useState } from "react";
import Loading from "../components/shared/Loading";
import CreateDiscourseComment from "./CreateDiscourseComment";
import DiscourseEmbed from "./DiscourseEmbed";
import CreateDiscourseTopic from "./CreateDiscourseTopic";
import DiscourseDisclaimer from "./DiscourseDisclaimer";
import LoginPrompt from "../components/auth/LoginPrompt";
import { COMMUNITY_URL } from "../components/shared/Community";

const Discourse = ({ objectId, objectType, topicId, currentUser }) => {
  const [embeddedTopicId, setEmbeddedTopicId] = useState(topicId);
  const [showCommenting, setShowCommenting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const DISCOURSE_URL = `${COMMUNITY_URL}/session/sso?return_path=${COMMUNITY_URL}/t/${embeddedTopicId}`;

  return (
    <div className="discourse" style={{ position: "relative" }}>
      {isLoading && <Loading />}

      <div className="discourse__main">
        {!currentUser ? (
          <LoginPrompt
            loginAction="read and leave comments"
            token={ReactOnRails.authenticityToken()}
          />
        ) : showCommenting ? (
          <CreateDiscourseComment
            topicId={embeddedTopicId}
            currentUser={currentUser}
            setShowCommenting={setShowCommenting}
            setIsLoading={setIsLoading}
          />
        ) : embeddedTopicId ? (
          <DiscourseEmbed
            setShowCommenting={setShowCommenting}
            topicId={embeddedTopicId}
            discussionUrl={DISCOURSE_URL}
          />
        ) : null}
      </div>
      {currentUser && <DiscourseDisclaimer discussionUrl={DISCOURSE_URL} />}
    </div>
  );
};

export default Discourse;
