import React from "react";
import axiosWithCSRF from "../../shared/axiosWithCSRF";
import CurrentRoutineList from "./CurrentRoutineList";
import AdminIntroduction from "../General/AdminIntroduction";
import PracticeIcons from "../PracticeIcons";

export default class PlayContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { activeRoutine, practiceItems, user } = this.props;
    let routineItems;
    if (activeRoutine && activeRoutine.items) {
      routineItems = [...activeRoutine.items];
      let items = [];
      routineItems.sort(function (a, b) {
        return a.position - b.position;
      });
      this.setState(
        {
          activeRoutine: activeRoutine,
          items: routineItems,
          videoPlayAlongIsActive: user.video_play_along_is_active,
        },
        () => {
          this.setState({ currentItem: this.getCurrentItem() });
        }
      );
    }
  }

  getCurrentItem = () => {
    let { practiceSessions, activeRoutine, completedItems } = this.props;
    let currentItem;
    if (completedItems && completedItems.length > 0) {
      let items = this.state.items ? this.state.items : activeRoutine.items;
      for (let item of items) {
        if (completedItems.includes(item.id) === false) {
          currentItem = item;
          break;
        }
      }

      return currentItem
        ? currentItem
        : this.isFinished(activeRoutine, practiceSessions, completedItems);
    } else {
      let firstItem = activeRoutine.items.filter((i) => i.position === 0)[0];
      currentItem = firstItem ? firstItem : activeRoutine.items[0];
      return currentItem;
    }
  };

  isFinished = (activeRoutine, practiceSessions, completedItems) => {
    let uniqueComplete = [...new Set(completedItems)];
    uniqueComplete.sort((a, b) => a - b);
    let routineItemIds = activeRoutine.items.map((i) => i.id).sort((a, b) => a - b);

    if (JSON.stringify(uniqueComplete) === JSON.stringify(routineItemIds)) {
      this.setState({ sessionIsFinished: true });
    } else {
      return activeRoutine.items[0];
    }
  };

  makeActive = (item) => {
    let currentOrder = [...this.state.items];
    let itemIndex = currentOrder.indexOf(item);
    if (itemIndex != 0) {
      currentOrder.splice(itemIndex, 1);
      currentOrder.unshift(item);
    }
    this.setState({
      currentItem: item,
      items: currentOrder,
      activeItemOpen: true,
    });
  };

  saveEditedOrder = () => {
    this.props.saveEditedOrder(this.state.items);
  };

  sortedItems = (items) => {
    let sorted = items.sort((a, b) => {
      if (a.position < b.position) {
        return -1;
      }
      if (a.position > b.position) {
        return 1;
      }
      return 0;
    });
    return sorted;
  };

  itemsReorderedForNext = (items, currentIndex) => {
    let beginning = items.slice(currentIndex + 1);
    let ending = items.slice(0, currentIndex + 1);
    return beginning.concat(ending);
  };

  nextItem = (partiallyFinished) => {
    let items = [...this.sortedItems(this.state.items)];
    let completedItems = this.props.completedItems || [];
    let currentIndex = items.indexOf(this.state.currentItem) || 0;
    completedItems = [...new Set(completedItems)];

    if (currentIndex != items.length - 1) {
      items = this.itemsReorderedForNext(items, currentIndex);
    }
    if (partiallyFinished) {
      // if item isn't the last one and it was only partially done, remove it to show a different item
      if (
        completedItems.includes(this.state.currentItem.id) === false &&
        completedItems.length != items.length - 1
      ) {
        items.splice(items.indexOf(this.state.currentItem), 1);
      }
    }

    let nextItem = items.find((item) => {
      return completedItems.includes(item.id) === false;
    });

    if (this.state.currentItem != nextItem) {
      this.setState({ currentItem: nextItem });
    }
  };

  restartFinishedAdjustedRoutine = () => {
    let { practiceSessions, activeRoutine } = this.props;
    let thisSession = practiceSessions.filter(
      (s) => s.user_practice_routine_id === activeRoutine.id && s.is_finished === false
    );
    if (thisSession.length > 0) {
      axiosWithCSRF()
        .patch(`/practice_sessions/${thisSession[0].id}`)
        .then((res) => {
          this.props.updateFinishedPracticeSession(res.data.practice_session);
          this.restartFinishedRoutine();
        })
        .catch((res) => {
          this.restartFinishedRoutine();
        });
    }
  };

  restartFinishedRoutine = () => {
    let { items } = this.state;
    let nextItem;
    let firstItem = items.filter((i) => i.position === 0);
    if (firstItem.length > 0) {
      nextItem = firstItem[0];
    } else {
      items.sort((a, b) => {
        if (a.position < b.position) {
          return -1;
        }
        if (a.position > b.position) {
          return 1;
        }
        return 0;
      });
      nextItem = items[0];
    }
    this.props.resetCompletedItems();
    this.setState({ currentItem: nextItem, sessionIsFinished: false });
  };

  resetRoutine = () => {
    if (confirm("Are you sure you want to restart this routine?")) {
      this.restartFinishedAdjustedRoutine();
    }
  };

  totalTime = () => {
    if (this.state.items) {
      let duration = 0;
      this.state.items.forEach((item) => (duration += item.duration));
      return (
        <div className="practicing__routine-duration">
          <span className="icon icon-clock2"></span>
          <span>TOTAL {duration} MINS.</span>
        </div>
      );
    }
  };

  videoPlayAlongIcon = () => {
    if (this.state.videoPlayAlongIsActive) {
      return (
        <span
          dangerouslySetInnerHTML={{ __html: PracticeIcons.checkedCheckboxIcon() }}
          onClick={this.toggleVideoPlayAlong}
        />
      );
    } else {
      return (
        <span
          dangerouslySetInnerHTML={{ __html: PracticeIcons.uncheckedCheckboxIcon() }}
          onClick={this.toggleVideoPlayAlong}
        />
      );
    }
  };

  toggleVideoPlayAlong = () => {
    this.setState({ videoPlayAlongIsActive: !this.state.videoPlayAlongIsActive });
  };

  activeRoutineItemsList = () => {
    let listItems = [];
    this.state.items
      .filter((i) => i.status === "active")
      .map((item, index) => {
        listItems.push(this.itemInList(item, index));
      });
    return listItems;
  };

  setFinishedItem = (itemIsFinished) => {
    this.setState({ finishedItem: itemIsFinished });
  };

  saveMetronomeSpeed = (currentItem) => {
    this.setState({ currentItem }, () => {
      this.props.saveMetronomeSpeed(currentItem);
    });
  };

  render() {
    if (this.state.sessionIsFinished) {
      return (
        <div>
          <p>
            You've finished all of the practice items in this routine. Would you like to restart it?
          </p>
          <div className="practice-buttons__container">
            <button
              type="button"
              style={{ marginTop: "36px" }}
              className="practice__button practice__button--save"
              onClick={this.restartFinishedAdjustedRoutine}
            >
              restart routine
            </button>
          </div>
        </div>
      );
    } else if (this.state.currentItem) {
      return (
        <div className="practicing">
          <AdminIntroduction intro={this.props.adminIntroduction} />

          <div className="practicing__inner ">
            <div className="practicing__top">
              <div>
                <span className="practice__heading">
                  {this.state.activeRoutine ? this.state.activeRoutine.title : ""}
                </span>
                {this.totalTime()}
              </div>
              <div className="practicing__right">
                {this.state.finishedItem != true && (
                  <div>
                    <button className="practicing__reset" onClick={this.resetRoutine}>
                      reset routine
                    </button>
                    <div className="practicing__playalong">
                      <p>Always Play With Video Assistant: </p>
                      {this.videoPlayAlongIcon()}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="practicing__list">
              <CurrentRoutineList
                activeRoutine={this.props.activeRoutine}
                currentItem={this.state.currentItem}
                setFinishedItem={this.setFinishedItem}
                makeActive={this.makeActive}
                updateStatsAndPracticeSessions={this.props.updateStatsAndPracticeSessions}
                saveMetronomeSpeed={this.saveMetronomeSpeed}
                nextItem={this.nextItem}
                repeatItem={this.repeatItem}
                restartRoutine={this.restartFinishedRoutine}
                updateStatItem={this.props.updateStatItem}
                updateNoteInPracticeItem={this.props.updateNoteInPracticeItem}
                updateItemWithChangedBpm={this.saveMetronomeSpeed}
                videoPlayAlongIsActive={this.state.videoPlayAlongIsActive}
                user={this.props.user}
                practiceSessionItems={this.props.practiceSessionItems}
                completedItems={this.props.completedItems}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.activeRoutine && this.state.activeRoutine.items.length === 0) {
      return (
        <div>
          <p className="bold">To get started, please add practice items to your routine. </p>
          <p>
            You can add a new item using the 'Practice Items' tab above. After that, add your item
            to this routine in the 'Routines' tab.{" "}
          </p>
        </div>
      );
    } else {
      return null;
    }
  }
}
