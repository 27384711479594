import React from "react";
import SimpleDialog from "../../components/shared/ui/SimpleDialog";

const ConfirmationDialog = ({ showConfirmDialog, setShowConfirmDialog }) => {
  if (!showConfirmDialog) return null;

  return (
    <SimpleDialog
      title="Thank you!"
      content={
        <div style={{ textAlign: "center" }}>
          You will receive an email containing all the necessary information for attending this Live
          Event. Please check your Spam folder if you don't receive the email within a few minutes.
        </div>
      }
      actionButtons={
        <>
          <button className="button button--primary" onClick={() => setShowConfirmDialog(false)}>
            ok
          </button>
        </>
      }
      closeAction={() => setShowConfirmDialog(false)}
    />
  );
};

export default ConfirmationDialog;
