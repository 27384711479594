import React from "react";
import Modal from "react-modal";
import ReactPlayer from "react-player";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100%",
    width: "90%",
    height: "90%",
    backgroundColor: "black",
  },
};

export default class PracticeVideoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: 0,
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      Modal.setAppElement("body");
    }
  }

  videoLink = () => {
    let { video } = this.props;
    if (video.includes("vimeo")) {
      return video;
    } else {
      return `https://www.youtube.com/embed/${video}?start=${this.state.startTime}&modestbranding=1&autohide=1&showinfo=0`;
    }
  };

  handleVideoProgress = (info) => {
    this.setState({ playedSeconds: info.playedSeconds });
  };

  updateAndClose = () => {
    this.setState({ startTime: this.state.playedSeconds }, () => this.props.closeModal());
  };

  render() {
    if (this.props.isOpen) {
      return (
        <div>
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.updateAndClose}
            style={customStyles}
            contentLabel="Practice Item"
          >
            <ReactPlayer
              url={this.videoLink()}
              playing
              controls={true}
              onPlay={this.props.onVideoPlay}
              onProgress={this.handleVideoProgress}
              onEnded={this.props.closeModal}
              onPause={this.props.pauseTimer}
              width="100%"
              height="100%"
            />
          </Modal>
        </div>
      );
    } else {
      return null;
    }
  }
}
