import React from "react";
import { connect } from "react-redux";
import { createUserPracticeItemFromLesson } from "../../session/sessionEffects";
import { userPracticeItems } from "../../session/sessionSelectors";
import PracticeItemToSave from "./PracticeItemToSave";
import PracticeRoutineToSave from "./PracticeRoutineToSave";
import LoginPrompt from "../../../auth/LoginPrompt";
import Loading from "../../Loading";
const PracticeItemAlreadySaved = ({ item }) => {
  return (
    <div>
      <h5 className="header5 uppercase bold">{item.attributes.title}</h5>
      <p>{item.attributes.description}</p>
      <button className="button disabled" disabled={true}>
        saved this practice item
      </button>
    </div>
  );
};
class PracticeTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  practiceItemAlreadySaved = (itemId) => {
    let { userPracticeItems } = this.props;
    if (
      userPracticeItems &&
      userPracticeItems.find((i) => i.attributes.practiceItemId === parseInt(itemId))
    ) {
      return true;
    }
  };

  render() {
    const {
      practiceItems,
      practiceRoutines,
      createUserPracticeItemFromLesson,
      user,
      userPracticeItems,
      userPracticeRoutines,
      isLoading,
      relatedLink,
    } = this.props;
    let { selectedPracticeItem } = this.state;
    if (!user) {
      return <LoginPrompt loginAction={"save practice items."} />;
    } else {
      return (
        <div>
          <div className="practice-items">
            {practiceItems.map((i) => {
              return this.practiceItemAlreadySaved(i.id) ? (
                <PracticeItemAlreadySaved key={i.id} item={i} />
              ) : (
                <div style={{ position: "relative" }}>
                  {isLoading && <Loading />}
                  <PracticeItemToSave
                    key={i.id}
                    selectedItem={selectedPracticeItem}
                    item={i}
                    relatedLink={relatedLink}
                    addItem={createUserPracticeItemFromLesson}
                    setSelectedItem={(item) => this.setState({ selectedPracticeItem: item })}
                  />
                </div>
              );
            })}

            {practiceRoutines.map((r) => {
              return (
                <PracticeRoutineToSave
                  key={r}
                  isLoading={isLoading}
                  routine={r}
                  userPracticeItems={userPracticeRoutines}
                />
              );
            })}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.currentUser,
  userPracticeItems: userPracticeItems(state),
  isLoading: state.ui.isLoading,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  createUserPracticeItemFromLesson: (itemId, addToRoutine) =>
    dispatch(createUserPracticeItemFromLesson(itemId, addToRoutine)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticeTab);
