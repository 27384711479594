import React, { useState } from "react";
import * as moment from "moment";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import Loading from "../../components/shared/Loading";
import { CheckIcon } from "../../components/shared/Icons";
import { Typography } from "@mui/material";

const Slots = ({ slots, currentUser, userApplications }) => {
  const [appliedIds, setAppliedIds] = useState(userApplications || []);
  const [loading, setLoading] = useState(false);

  const register = (slot) => {
    setLoading(true);
    axiosWithCSRF()
      .post("/private_one_on_one_lesson_applications", {
        private_one_on_one_lesson_id: slot.id,
      })
      .then((response) => {
        setAppliedIds([...appliedIds, { id: response.data.item.id, slotId: slot.id }]);
      })
      .catch(() => {
        alert("There was an error processing your request. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const cancel = (slot) => {
    setLoading(true);
    const existingApplication = appliedIds.find((i) => Number(i.slotId) === Number(slot.id));

    axiosWithCSRF()
      .delete(`/private_one_on_one_lesson_applications/${existingApplication.id}`)
      .then(() => {
        setAppliedIds(appliedIds.filter((i) => i.id !== existingApplication.id));
      })
      .catch(() => {
        alert("There was an error processing your request. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function formattedTime(time) {
    return moment(time).format("h:mm A");
  }

  function slotAppliedFor(slot) {
    if (!appliedIds) return false;

    return appliedIds.map((i) => Number(i.slotId)).includes(Number(slot.id));
  }

  function sortedSlots() {
    return slots.sort((a, b) => {
      if (a.attributes.date < b.attributes.date) return -1;
      if (a.attributes.date > b.attributes.date) return 1;
      return 0;
    });
  }

  return (
    <>
      <div className="section slots" id="register">
        {loading && <Loading coverViewport />}
        {sortedSlots().map((slot, idx) => {
          const {
            price,
            date,
            approved,
            paid,
            time,
            flexibleTime,
            flexiblePeriod,
            selectedUserId,
          } = slot.attributes;
          return (
            <div key={slot.id} className="slot">
              {slotAppliedFor(slot) && (
                <div className="attending">
                  <CheckIcon />{" "}
                  {selectedUserId === currentUser?.id && paid ? "Confirmed" : "Interest submitted"}
                </div>
              )}
              <div className="price">USD {price}</div>
              <div className="title">{`SLOT #${idx + 1}`}</div>
              <div className="date">{moment(date, "YYYY-MM-DD").format("MMMM DD, YYYY")}</div>
              {flexibleTime ? (
                <div className="time">{flexiblePeriod} (GMT)</div>
              ) : (
                <div className="time">{formattedTime(time)}</div>
              )}

              {paid && selectedUserId === currentUser?.id ? (
                <div className="paid-notice">
                  <Typography variant="body1" sx={{ color: "white", fontSize: "14px" }}>
                    If you would like to cancel this lesson, please contact hello@justinguitar.com
                  </Typography>
                </div>
              ) : (
                <button
                  className="button button--primary"
                  disabled={
                    !currentUser ||
                    loading ||
                    paid ||
                    approved ||
                    (appliedIds?.length > 0 && !slotAppliedFor(slot)) // don't disable button if users have applied for slot so they can cancel
                  }
                  onClick={() => (slotAppliedFor(slot) ? cancel(slot) : register(slot))}
                >
                  {paid
                    ? "Sold"
                    : slotAppliedFor(slot)
                    ? "Cancel"
                    : approved
                    ? "Reserved"
                    : "Register my interest"}
                </button>
              )}
            </div>
          );
        })}
      </div>

      <button
        className="button button--primary faq-cta"
        onClick={() => (window.location.href = "/private-one-on-one-lessons#faq")}
      >
        Have Questions? Read Our Faq!
      </button>
    </>
  );
};

export default Slots;
