import React, { useState } from "react";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import * as Yup from "yup";
import Dialog from "../../components/shared/ui/Dialog";
import FormInput from "./FormInput";
import {
  clearUserImage as clearUserImageFromSession,
  updateUserAttributes,
} from "../../components/shared/session/sessionSlice";
import { connect } from "react-redux";
import { withSearchStore } from "../../components/shared/WithSearchStore";
import ImageInput from "./ImageInput";
import debounce from "lodash.debounce";

let lastUsernameValue = undefined;
let lastEmailValue = undefined;

const Profile = ({
  user,
  token,
  openAlert,
  openDialog,
  clearUserImageFromSession,
  clearUserImageFromSearch,
  setUserImageInSearch,
  setUsernameInSearch,
  updateUserAttributes,
}) => {
  const [deletingUser, setDeletingUser] = useState(false);
  const [imageRemoved, setImageRemoved] = useState(false);

  const deleteProfileImage = () => {
    axiosWithCSRF()
      .delete(`/delete_profile_image`, {
        data: {
          user: { remove_image: true },
        },
      })
      .then((res) => {
        openAlert({ message: "Image deleted successfully!", severity: "success" });
        clearUserImageFromSession();
        clearUserImageFromSearch();
        setImageRemoved(true);
      })
      .catch((err) => {
        openAlert({ message: "Error deleting image!", severity: "error" });
      });
  };

  const deleteUser = (data) => {
    axiosWithCSRF()
      .delete("/delete_user", {
        data: {
          user: {
            current_password: data.inputValue,
          },
        },
      })
      .then((res) => {
        openAlert({ message: res.data.message, severity: "success" });

        setTimeout(() => {
          window.location.href = "https://www.justinguitar.com/";
        }, 4000);
      })
      .catch((err) => {
        openAlert({ message: err.response.data.error.message, severity: "error" });
      });
  };

  const submitForm = (data, setSubmitting, setErrors, setEditing) => {
    axiosWithCSRF()
      .patch(`/users/${user.data.attributes.slug}/update_profile`, {
        user: data,
      })
      .then((res) => {
        const responseText =
          res.data.hasOwnProperty("response_text") && res.data.response_text.length > 0
            ? res.data.response_text
            : null;

        openAlert({
          message: responseText ? responseText : "Your profile was updated successfully!",
          severity: "success",
        });

        if (res.data.hasOwnProperty("username")) {
          setUsernameInSearch(res.data.username);
        }

        if (res.data.hasOwnProperty("image_uid")) {
          setUserImageInSearch(res.data.image_uid);
          setImageRemoved(false);
          updateUserAttributes(Object.assign(data, { imageUid: res.data.image_uid }));
        } else {
          updateUserAttributes(data);
        }

        setEditing(false);
      })
      .catch((err) => {
        const errors = err.response.data.error.hasOwnProperty("errors")
          ? err.response.data.error.errors
          : null;

        openAlert({ message: "Profile update failed!", severity: "error" });
        setErrors(errors);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  function checkUsernameAvailability(value) {
    return new Promise((resolve) => {
      if (this.parent.username != lastUsernameValue) {
        lastUsernameValue = this.parent.username;
        axiosWithCSRF()
          .post("/users/check_username_availability", {
            username: value,
          })
          .then((response) => {
            if (response.data.existing === true) {
              resolve(false);
            } else {
              resolve(true);
            }
          });
      } else {
        resolve(true);
      }
    });
  }

  function checkEmailAvailability(value) {
    return new Promise((resolve) => {
      if (this.parent.email != lastEmailValue) {
        lastEmailValue = this.parent.email;
        axiosWithCSRF()
          .post("/users/check_email_availability", {
            email: value,
          })
          .then((response) => {
            if (response.data.existing === true) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
      } else {
        resolve(true);
      }
    });
  }

  return (
    <div className="tab-content">
      <div className="account__profile">
        <div className="section">
          <div className="profile-img">
            <ImageInput
              user={user}
              openDialog={openDialog}
              imageRemoved={imageRemoved}
              submitForm={submitForm}
            />
          </div>
          <FormInput
            label="First Name"
            submitForm={submitForm}
            user={user}
            initialValues={{
              first_name: user.data.attributes.firstName,
            }}
            validationSchema={Yup.object().shape({
              first_name: Yup.string()
                .min(2, "This name is too short!")
                .max(25, "This name is too long!")
                .required("First name is required."),
            })}
          />
          <FormInput
            label="Last Name"
            submitForm={submitForm}
            user={user}
            initialValues={{
              last_name: user.data.attributes.lastName,
            }}
            validationSchema={Yup.object().shape({
              last_name: Yup.string()
                .min(2, "This name is too short!")
                .max(25, "This name is too long!")
                .required("Last name is required."),
            })}
          />
          <FormInput
            label="Username"
            confirm
            submitForm={submitForm}
            user={user}
            initialValues={{
              username: user.data.attributes.username,
              username_password_confirm: "",
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string()
                .min(2, "This name is too short!")
                .max(25, "This name is too long!")
                .required("Username is required.")
                .matches(/^[aA-zZ0-9]+$/, "Please use letters and numbers only.")
                .test("unique", "Username is taken.", debounce(checkUsernameAvailability, 250)),
              username_password_confirm: Yup.string().required(
                "Password confirmation is required."
              ),
            })}
          />
          <FormInput
            label="Email address"
            confirm
            submitForm={submitForm}
            user={user}
            initialValues={{
              email: user.data.attributes.email,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Please provide a valid email.")
                .required("Email address is required.")
                .test("unique", "Email is taken.", debounce(checkEmailAvailability, 250)),
              email_password_confirm: Yup.string().required("Password confirmation is required."),
            })}
          />
          <p className="section-info">
            It is possible to{" "}
            <button
              type="button"
              onClick={() => {
                setDeletingUser(true);
                openDialog({
                  message: `Deleting your JustinGuitar account doesn't unsubscribe you from paid courses or features! Please make sure you don't have an active subscription to the site before deleting your account. If you're using my app and wish to unsubscribe, do so on your mobile device. <a href="/faq/unsubscribe-from-app-instructions">Read more</a>.<br><br>Please enter your current password to delete your account.`,
                  confirmButtonText: "Confirm",
                  data: user,
                  input: {
                    type: "password",
                    placeholder: "current password",
                  },
                });
              }}
            >
              delete your account
            </button>
            , but it’s irreversible.
          </p>
        </div>
        <Dialog onConfirm={deletingUser ? deleteUser : deleteProfileImage} />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  clearUserImageFromSession: () => dispatch(clearUserImageFromSession()),
  updateUserAttributes: (values) => dispatch(updateUserAttributes(values)),
});

export default connect(null, mapDispatchToProps)(withSearchStore(Profile));
