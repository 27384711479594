import React, { useRef } from "react";
import { connect } from "react-redux";
import { otherSongsByArtist } from "../../store/songSelectors";
import ToggleSection from "./ToggleSection";
import { CDN_URL } from "../../../components/shared/CdnUrl";
import { withMobileOnlySwoosh } from "../../../components/shared/WithMobileOnlySwoosh";
import { SWOOSH_BACKGROUND } from "./consts";

export const SongCard = ({ song, showDelete, onDeleteSongItem }) => {
  const { artistImageUid, slug, title, originalArtist } = song;
  const gradeId = song.gradeId ? song.gradeId : 0;
  const buttonRef = useRef();
  return (
    <div
      onClick={(event) => {
        if (
          !buttonRef ||
          !buttonRef.current ||
          buttonRef.current.contains(event.target) === false
        ) {
          window.location.href = `/songs/${slug}`;
        }
      }}
      className="listing-item search-result-item search-result-item--song"
    >
      <div className={`color-grade-${gradeId}`}>{gradeId}</div>
      <img src={`${CDN_URL}/system/dragonfly/production/${artistImageUid}`} alt={title} />
      <div className="artist-and-title">
        <div className="title">{title}</div>
        <div className="artist">{originalArtist}</div>
      </div>

      {showDelete && (
        <button ref={buttonRef} onClick={onDeleteSongItem}>
          <i className="icon icon-heart"></i>
        </button>
      )}
    </div>
  );
};

class RelatedSongs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    let { isOpen } = this.state;
    let { song, relatedSongs } = this.props;
    if (relatedSongs.length === 0) {
      return null;
    } else {
      return (
        <div
          className={"section-toggle__section " + (isOpen ? "section-toggle__section--active" : "")}
        >
          <ToggleSection
            _this={this}
            title={`Other Songs by ${song.data.attributes.originalArtist}`}
            isOpen={isOpen}
          />

          {isOpen && (
            <>
              {relatedSongs.map((song) => {
                return <SongCard key={song.title} song={song} showDelete={false} />;
              })}
            </>
          )}
        </div>
      );
    }
  }
}

// const wrappedWithSwoosh = withMobileOnlySwoosh(RelatedSongs, SWOOSH_BACKGROUND);

const mapStateToProps = (state, { width }) => ({
  song: state.entity.song,
  relatedSongs: otherSongsByArtist(state),
  width: width,
});

export default connect(mapStateToProps)(RelatedSongs);
