export const SUBSCRIPTION_TYPES = {
  donation: "DonationSubscription",
  tabs: "TabsSubscription",
  sales: "SalesSubscription",
};

export const DISCOUNT_TYPES = {
  whenUpgrading: "when_upgrading",
  withinPeriod: "within_period",
};

export const SUBSCRIPTION_STATUSES = Object.freeze({
  trial: 1,
  active: 2,
  cancelled: 3,
  unpaid: 4,
  expired: 5,
});

export const SUBSCRIPTION_STATUS_KEYS = Object.freeze({
  active: "active",
  cancelled: "cancelled",
  trial: "trial",
  unpaid: "unpaid",
});

export const INTENT_TYPES = {
  setupIntent: "setup_intent",
  paymentIntent: "payment_intent",
};

export const PLAN_TYPES = {
  recurring: "recurring",
  lifetime: "lifetime",
  installment: "installment",
  temporaryAccess: "temporary_access",
};

export const CURRENCY_SIGN = {
  usd: "$",
  gbp: "£",
  aus: "AUS $",
  eur: "€",
  cad: "CAD $",
};

export const CHECKOUT_SESSION_URL = "/payments/checkout_sessions";
