import React, { useEffect } from "react";
import {
  isRenamingSongbook,
  selectOrderedSongbookSongsForSongbook,
  selectOrderedSongsForSongbook,
} from "../store/dashboardSelectors";
import { useDispatch, useSelector } from "react-redux";
import SongbookSong from "./SongbookSong";
import onClickOutside from "react-onclickoutside";
import {
  setCurrentSongbookId,
  setRenamingSongbookId,
  setSongbookTabPanel,
  songbookTabPanel,
} from "../store/dashboardStore";
import { createSongbook, deleteSongbook, updateSongbook } from "./songbookApi";
import { openDialog } from "../../components/shared/ui/uiSlice";
import { useDrop } from "react-dnd";
import { DraggableItemTypes } from "./dnd-utils";
import { reorderSongs } from "./utils";

export default function ({ songbook }) {
  const songs = useSelector(selectOrderedSongsForSongbook)(songbook);
  const isRenaming = useSelector(isRenamingSongbook)(songbook.id);
  const dispatch = useDispatch();

  return (
    <div className="songbook">
      <div className="songbook__header">
        {isRenaming ? (
          <RenameSongbookForm songbook={songbook} />
        ) : (
          <h5 className="header5">{songbook.attributes.title}</h5>
        )}
        <SongbookActionsMenu songbook={songbook} />
      </div>

      {Array.isArray(songs) && (
        <>
          {songs.map((song, ord) => (
            <>
              {ord == 0 && <SongDropZone ord={-1} songbook={songbook} key={0} />}
              <SongbookSong key={song.id} song={song} songbook={songbook} ord={ord} />
              <SongDropZone ord={ord} songbook={songbook} key={song.id * -1} />
            </>
          ))}
        </>
      )}

      <div
        className="add-song"
        onClick={() => {
          dispatch(setCurrentSongbookId(songbook.id));
          dispatch(setSongbookTabPanel(songbookTabPanel.songs));
        }}
      >
        <span>
          <i className="icon icon-plus" />
          Add Song
        </span>
      </div>
    </div>
  );
}

const SongDropZone = ({ ord, songbook }) => {
  const dispatch = useDispatch();
  const getSongbookSongItems = useSelector(selectOrderedSongbookSongsForSongbook);
  const getCurrentSongs = useSelector(selectOrderedSongsForSongbook);

  const [{ isOver, canDrop }, dropRef] = useDrop(
    () => ({
      accept: DraggableItemTypes.SONGBOOK_SONG,
      drop(item) {
        reorderSongs(songbook, item.song, getCurrentSongs(songbook), ord, dispatch);
      },
      canDrop(item) {
        return item.songbook.id === songbook.id && ord !== item.ord && ord != item.ord - 1;
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: Boolean(monitor.canDrop()),
      }),
    }),
    []
  );

  return (
    <div
      className={`song-drop-zone ${canDrop ? "song-drop-zone--droppable" : ""} ${
        isOver && canDrop ? "song-drop-zone--hover" : ""
      }`}
      ref={dropRef}
    />
  );
};

const SongbookActionsMenu = ({ songbook }) => {
  const [menuActive, setMenuActive] = React.useState(false);
  return (
    <div
      className="songbook-actions-menu"
      onClick={(e) => {
        e.stopPropagation();
        setMenuActive(!menuActive);
      }}
    >
      <i className="icon icon-menu-dots-vertical" />
      {menuActive && <SongbookActionsList songbook={songbook} close={() => setMenuActive(false)} />}
    </div>
  );
};

const RenameSongbookForm = onClickOutside(
  ({ songbook }) => {
    const [newTitle, setNewTitle] = React.useState(songbook.attributes.title);
    const dispatch = useDispatch();
    const inputRef = React.useRef();

    useEffect(() => {
      inputRef.current.focus();
    }, []);

    const closeAndSave = () => {
      dispatch(
        updateSongbook({
          songbook: {
            ...songbook,
            ...songbook.attributes,
            title: inputRef.current.value, // need to use ref to avoid issues with useState returning new object references each re-render
          },
        })
      );
      dispatch(setRenamingSongbookId(null)); // close form
    };
    RenameSongbookForm.handleClickOutside = closeAndSave;

    return (
      <div className="rename-songbook-form">
        <input
          type="text"
          ref={inputRef}
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && closeAndSave()}
        />
      </div>
    );
  },
  {
    handleClickOutside: () => {
      return RenameSongbookForm.handleClickOutside;
    },
  }
);

const SongbookActionsList = onClickOutside(
  ({ songbook, close }) => {
    const dispatch = useDispatch();

    const songbookSongs = useSelector(selectOrderedSongsForSongbook)(songbook);

    SongbookActionsList.onClickOutside = (e) => {
      // let menu button handle close if we clicked it
      const clickedMenuButton =
        e.target.className.includes("icon-menu-dots-vertical") ||
        e.target.className.includes("songbook-actions-menu");
      if (!clickedMenuButton) {
        close();
      }
    };

    const menuActions = [
      {
        label: "Add Song",
        icon: "record",
        onClick() {
          dispatch(setCurrentSongbookId(songbook.id));
          dispatch(setSongbookTabPanel(songbookTabPanel.songs));
        },
      },
      {
        label: "Rename List",
        icon: "pencil-right",
        onClick() {
          dispatch(setRenamingSongbookId(songbook.id));
        },
      },
      {
        label: "Delete List",
        icon: "delete",
        onClick() {
          dispatch(
            openDialog({
              message: "Are you sure you want to delete this list? This action can not be reversed",
              confirmButtonText: "Yes, delete this list",
              onConfirm: () => dispatch(deleteSongbook(songbook)),
            })
          );
        },
      },
      {
        label: "Duplicate List",
        icon: "copy",
        onClick() {
          const { title } = songbook.attributes;
          const songbook_songs_attributes = songbookSongs.map(({ id }, ord) => ({
            song_id: id,
            ord,
          }));

          dispatch(createSongbook({ title: `${title} (copy)`, songbook_songs_attributes }));
        },
      },
    ];

    return (
      <div className="songbook-actions-list">
        {menuActions.map((action) => {
          return (
            <div
              key={action.label}
              className="songbook-actions-list__option"
              onClick={action.onClick}
            >
              <i className={`icon icon-${action.icon}`} />
              <span>{action.label}</span>
            </div>
          );
        })}
      </div>
    );
  },
  {
    handleClickOutside: () => SongbookActionsList.onClickOutside,
  }
);
