import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axiosWithCSRF from "../components/shared/axiosWithCSRF";
import Snackbar from "../components/shared/ui/Snackbar";
import { openAlert } from "../components/shared/ui/uiSlice";
import {
  RECAPTCHA_KEY,
  STRIPE_DONATION_PUBLISHABLE_KEY,
  STRIPE_SALES_PUBLISHABLE_KEY,
  STRIPE_TABS_PUBLISHABLE_KEY,
  TABS_PURCHASE_REFERENCES,
} from "./PaymentConstants";
import { Elements, CardElement, StripeProvider, injectStripe } from "react-stripe-elements";
import Loading from "../components/shared/Loading";

const CardForm = ({ purchaseReference, stripe, toggleModal, openAlert }) => {
  const [stripeError, setStripeError] = useState(null);
  const [cardElement, setCardElement] = useState(null);
  const [cardReady, setCardReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  function handleChange(e) {
    if (e.error) {
      setStripeError(e.error.message);
    } else {
      setStripeError(null);
      setCardElement(e);
    }

    if (e.complete) {
      setCardReady(true);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    const stripeResult = await stripe.createPaymentMethod("card", CardElement);

    if (stripeResult.error != null) {
      setStripeError(stripeResult.error.message);
      setIsLoading(false);
    } else {
      const { token } = await stripe.createToken();
      const cardToken = await (stripeResult.paymentMethod.id, token);
      axiosWithCSRF()
        .post("/payments/stripe_update_payment_method", {
          card_token: cardToken,
          purchase_reference: purchaseReference,
        })
        .then(() => {
          setIsLoading(false);
          toggleModal(false);
          openAlert({ message: "Your card has been updated!", severity: "success" });
        })
        .catch(() => {
          setIsLoading(false);
          toggleModal(false);
          openAlert({ message: "There was a problem updating your card.", severity: "error" });
        });
    }
  }

  return (
    <>
      {isLoading && <Loading coverViewport />}

      <form onSubmit={handleSubmit}>
        <label style={{ width: "100%" }}>
          <CardElement
            onChange={handleChange}
            style={{
              base: {
                fontSize: "16px",
                lineHeight: "26px",
                fontFamily: "Montserrat",
              },
            }}
          />
        </label>

        {stripeError && <h5 className="error-response">{stripeError}</h5>}

        <div className="payment__button">
          <button className="button button--primary" disabled={!cardReady} type="submit">
            Update my card
          </button>
        </div>
      </form>
    </>
  );
};

const WrappedCardForm = injectStripe(CardForm);

const UpdateCardDetails = ({ userPurchase, purchaseReference, toggleModal, openAlert }) => {
  const fontArray = [{ cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:400" }];
  function stripeKeyByPurchaseReference(purchaseReference) {
    if (TABS_PURCHASE_REFERENCES.includes(purchaseReference)) {
      return STRIPE_TABS_PUBLISHABLE_KEY;
    } else if (purchaseReference === "DONATION") {
      return STRIPE_DONATION_PUBLISHABLE_KEY;
    } else {
      return STRIPE_SALES_PUBLISHABLE_KEY;
    }
    // TODO: make this work with donations too ->
    // } else { return STRIPE_DONATION_PUBLISHABLE_KEY_KEY; }
  }

  return (
    <div className="update-card" style={{ position: "relative" }}>
      <p className="bold instructions">Please enter your new card details below.</p>
      {/* TODO: SET API KEY BY PRODUCT */}
      <StripeProvider apiKey={stripeKeyByPurchaseReference(purchaseReference)}>
        <div className="paywall-form">
          <div className="paywall-form__outer">
            <div className="stripe__element__container">
              <Elements fonts={fontArray}>
                <WrappedCardForm
                  purchaseReference={purchaseReference}
                  openAlert={openAlert}
                  toggleModal={toggleModal}
                />
              </Elements>
            </div>
          </div>
        </div>
      </StripeProvider>
      <Snackbar />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  openAlert: (alert) => dispatch(openAlert(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCardDetails);

UpdateCardDetails.propTypes = {
  purchaseReference: PropTypes.string.isRequired,
};
