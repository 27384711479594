import React from "react";
import { BootstrapSize } from "../shared/bootstrap-helpers";
import { CDN_URL } from "../shared/CdnUrl";
import Swoosh from "../shared/Swoosh";

const MIN_SWOOOSH_HEIGHT = 300;

const defaultHeaderImages = {
  [BootstrapSize.lgDevice]: `${CDN_URL}/images/all-songs/all-songs-banner-xl-v3.png`,
  [BootstrapSize.mdDevice]: `${CDN_URL}/images/all-songs/all-songs-banner-md.jpg`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/images/all-songs/all-songs-banner-xs-v2.jpg`,
};

export const ArtistHeader = ({
  artist: {
    attributes: { name, bannerImageUrl },
  },
}) => (
  <div className="artist-banner container">
    <img
      src={bannerImageUrl ? bannerImageUrl : defaultHeaderImages[BootstrapSize.lgDevice]}
      alt={name}
    />
    <div className="artist-banner__breadcrumbs">
      <a href="/songs">All Songs</a>
      <i className="icon icon-angle-right" />
      <span>All Songs By {name}</span>
    </div>
  </div>
);

export const StandardHeader = () => (
  <div className="songs__hero" style={{ minHeight: `${MIN_SWOOOSH_HEIGHT}px` }}>
    <Swoosh imgUrl={defaultHeaderImages}>
      <div className="container">
        <h1 className="header1">Songs</h1>
        <p>
          Want chords/lyrics + tabs to follow along with my song tutorials? Check out JustinGuitar
          Tabs!
        </p>
        <a className="button button--inline button--primary" href="/store/tabs">
          View Tabs
        </a>
      </div>
    </Swoosh>
  </div>
);

const Header = ({ selectedArtist }) => {
  return selectedArtist == null || selectedArtist.attributes.bannerImageUrl == null ? (
    <StandardHeader />
  ) : (
    <ArtistHeader artist={selectedArtist} />
  );
};

export default Header;
