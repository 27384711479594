import React, { lazy, useEffect, useState } from "react";
import { CDN_URL } from "../components/shared/CdnUrl";

const songImageMap = {
  423: {
    imageName: "HeartOfGold2.png",
    url: "https://go.onelink.me/3065804798/heartofgold",
  },
  717: {
    imageName: "TakeMeHomeCountryRoads2.png",
    url: "https://go.onelink.me/3065804798/takemehomecountryroads",
  },
  109: {
    imageName: "Wonderwall2.png",
    url: "https://go.onelink.me/3065804798/wonderwall",
  },
  619: {
    imageName: "GivePeaceAChance2.png",
    url: "https://go.onelink.me/3065804798/GivePeaceAChance",
  },
  41: {
    imageName: "HeyJoe2.png",
    url: "https://go.onelink.me/3065804798/HeyJoe",
  },
  84: {
    imageName: "HouseOfTheRisingSun2.png",
    url: "https://go.onelink.me/3065804798/HouseOfTheRisingSun",
  },
  469: {
    imageName: "Perfect2.png",
    url: "https://go.onelink.me/3065804798/perfect",
  },
  42: {
    imageName: "MadWorld2.png",
    url: "https://go.onelink.me/3065804798/MadWorld",
  },
  602: {
    imageName: "EleanorRigby2.png",
    url: "https://go.onelink.me/3065804798/EleanorRigby",
  },
  124: {
    imageName: "HaveYouEverSeenTheRain2.png",
    url: "https://go.onelink.me/3065804798/HaveYouEverSeenTheRain",
  },
};

export const SongBanner = ({ song, currentUser }) => {
  const songId = song.data.attributes.id;
  const [isClosed, setIsClosed] = useState(
    localStorage.getItem(`songBannerClosed-${songId}`) === "1"
  );
  const banner = songImageMap[songId];

  const closeBanner = () => {
    localStorage.setItem(`songBannerClosed-${songId}`, "1");
    setIsClosed(true);
  };

  const setHeaderMenuStyle = (zIndex, position) => {
    const headerMenu = document.querySelector(".page");
    if (headerMenu) {
      headerMenu.style.zIndex = zIndex;
      headerMenu.style.position = position;
    }
  };

  useEffect(() => {
    if (isClosed) {
      localStorage.setItem(`songBannerClosed-${songId}`, "1");
    }

    if (!currentUser && banner && !isClosed) {
      setHeaderMenuStyle("1000", "relative");
    } else {
      setHeaderMenuStyle("auto", "static");
    }

    return () => {
      setHeaderMenuStyle("auto", "static");
    };
  }, [currentUser, banner, isClosed]);

  if (!currentUser && banner && !isClosed) {
    return (
      <div className="song-banner">
        <button onClick={closeBanner}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
              fill="white"
            />
          </svg>
        </button>
        <a href={banner.url} target="_blank">
          <img src={`${CDN_URL}/images/banner-resources/song-banners/${banner.imageName}`} alt="" />
        </a>
      </div>
    );
  }

  return null;
};
