import React, { useCallback, useContext, useState } from "react";
import { IEbookItem } from "./ebooks.interface";
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CardTypeMap,
  SxProps,
  Typography,
  styled,
} from "@mui/material";
import { jgDenimColors } from "../../jg-material/theme/jg-denim-colors";
import { EbooksLoginContext } from "./EbookLoginContext";
import {
  DefaultGradeButtonColors,
  GradeButtonColors,
  ImageSizes,
  PageBreakpoint,
} from "./EbookItem.constants";

interface EbookItemProps extends Partial<IEbookItem & CardTypeMap> {
  subscribeUrl?: string;
  sx?: SxProps;
}

export const EbookItem: React.FC<EbookItemProps> = (props) => {
  const { image, accessibleByCurrentUser, id, url, title, contentType, grade, subscribeUrl } =
    props;

  const [isHover, setHover] = useState(false);

  const eventMouseEnter = useCallback(() => setHover(true), []);
  const eventMouseLeave = useCallback(() => setHover(false), []);

  const eventDownloadButtonClick = useCallback((url: string | undefined) => {
    if (url) {
      window.open(url)?.focus();
    }
  }, []);

  const eventSubscribeButtonClick = useCallback((url: string | undefined) => {
    if (url) {
      window.location.href = url;
    }
  }, []);

  const getGradeColors = (grade: number | undefined) => {
    let colors = DefaultGradeButtonColors;

    if (grade && GradeButtonColors[grade]) {
      colors = GradeButtonColors[grade];
    }

    return colors;
  };

  const { isAuthenticated, login } = useContext(EbooksLoginContext);

  return (
    <EbookCard onMouseEnter={eventMouseEnter} onMouseLeave={eventMouseLeave} sx={props.sx}>
      {image ? <EbookMedia image={image} /> : <ImagePlaceholder />}
      <EbookContent>
        <Typography variant="h5" style={{ textAlign: "center" }}>
          {title}
        </Typography>
        <Typography variant="body1" style={{ textAlign: "center" }}>
          {contentType}
        </Typography>
      </EbookContent>
      <EbookActions>
        <EbookButton
          style={{
            backgroundColor: getGradeColors(grade).background,
            color: getGradeColors(grade).text,
          }}
        >
          {grade ? `Grade ${grade}` : "All grades"}
        </EbookButton>
      </EbookActions>
      <EbookBackdrop open={isAuthenticated && !accessibleByCurrentUser && isHover}>
        <Typography variant="body1" color={"white"} textAlign={"center"} marginBottom={"20px"}>
          You must be subscribed to download this asset.
        </Typography>

        {!!subscribeUrl && (
          <Button onClick={() => eventSubscribeButtonClick(subscribeUrl)}>subscribe</Button>
        )}
      </EbookBackdrop>
      <EbookBackdrop open={!isAuthenticated && isHover}>
        <Typography variant="body1" color={"white"} textAlign={"center"} marginBottom={"20px"}>
          You need to log in to download the content
        </Typography>

        <OverlayActions>
          <Button onClick={() => (window.location.href = "/users/sign_up")}>Register</Button>
          <LoginButton className="button button--blue" onClick={() => login()}>
            Log In
          </LoginButton>
        </OverlayActions>
      </EbookBackdrop>
      <EbookBackdrop open={!!(isAuthenticated && accessibleByCurrentUser && isHover)}>
        <OverlayActions>
          <Button onClick={() => eventDownloadButtonClick(url)}>
            <DownloadIcon className="icon icon-download"></DownloadIcon>
            Download
          </Button>
        </OverlayActions>
      </EbookBackdrop>
    </EbookCard>
  );
};

const EbookMedia = styled(CardMedia)(({ theme }) => ({
  height: ImageSizes.height,
  width: ImageSizes.width,
  margin: ImageSizes.margin,
  boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "10px",
  [theme.breakpoints.down(PageBreakpoint)]: {
    height: ImageSizes.height_sm,
    width: ImageSizes.width_sm,
  },
}));

const ImagePlaceholder = styled("div")(({ theme }) => ({
  height: ImageSizes.height,
  width: ImageSizes.width,
  margin: ImageSizes.margin,
  [theme.breakpoints.down(PageBreakpoint)]: {
    height: ImageSizes.height_sm,
    width: ImageSizes.width_sm,
  },
}));

const EbookCard = styled(Card)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: 360,
  width: 360,
  backgroundColor: jgDenimColors.gray.light,
  borderRadius: 6,
  [theme.breakpoints.down(PageBreakpoint)]: {
    height: 340,
    width: 320,
  },
}));

const EbookContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const EbookActions = styled(CardActions)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "flex-start",
  paddingTop: "0px",
  paddingBottom: "20px",
}));

const EbookBackdrop = styled(Backdrop)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: jgDenimColors.denim.dark + "cc",
}));

const OverlayActions = styled("div")({
  flexDirection: "row",
  width: "100%",
  display: "inline-flex",
  justifyContent: "space-evenly",
  alignItems: "center",
});

const EbookButton = styled("div")({
  borderRadius: "4px",
  padding: "0 6px",
  height: "22px",
  display: "inline-flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",

  color: "#FFF",
  fontFamily: "Open Sans",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  filter: "drop-shadow(1px 0px 1px #ccc)",

  ":hover": {
    filter: "drop-shadow(2px 1px 2px #ccc) brightness(1.03)",
  },
});

const LoginButton = styled("a")({
  boxShadow:
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
  ":hover": {
    filter: "brightness(1.1)",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
});

const DownloadIcon = styled("i")({
  marginRight: "10px",
});
