import PropTypes from "prop-types";
import React from "react";

export default class SearchItems extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    searchBy: PropTypes.object.isRequired, // {fields: ["title", "original_artist"], searchStr: null}
    onSearchChange: PropTypes.func.isRequired,
  };

  constructor(props, _railsContext) {
    super(props);

    this.state = { items: props.items, searchBy: props.searchBy };
  }

  searchBy = (event) => {
    let searchStr = event.target.value.toLowerCase();

    let searchFields = this.state.searchBy.fields;
    let filtered = [];

    this.state.items.map((item) => {
      if (item) {
        let valid = false; // Assume item is unvalid

        searchFields.map((field) => {
          let value = item[field];

          // Iterate through item searchBy -fields and see if the searchStr
          // does partially match, then we can include it
          if (item[field].toLowerCase().indexOf(searchStr) !== -1) {
            valid = true;
          }
        });

        if (valid) {
          filtered.push(item);
        }
      }
    });

    let searchBy = { fields: searchFields, searchStr: searchStr };
    this.setState({ searchBy: searchBy });

    // Callback for SongList
    this.props.onSearchChange(filtered, searchBy);
  };

  render() {
    return (
      <div className="form-group">
        <input
          className="form-control"
          placeholder="SearchHeader all song lessons"
          onChange={this.searchBy}
        />
      </div>
    );
  }
}
