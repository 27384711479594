import React from "react";
import PropTypes from "prop-types";

const DescriptionIcon = ({ iconClassNames, iconComponent }) => {
  return (
    <div className="description-icon">
      <div>
        {iconClassNames ? <i className={iconClassNames}></i> : null}
        {iconComponent ? iconComponent : null}
      </div>
    </div>
  );
};

const requiredEitherNotBothPropsCheck = (props, propName, componentName) => {
  if (!props.iconClassNames && !props.iconComponent) {
    return new Error(
      `One of 'iconClassNames' or 'iconComponent' is required by '${componentName}' component.`
    );
  }
};

DescriptionIcon.propTypes = {
  iconClassNames: requiredEitherNotBothPropsCheck,
  iconComponent: requiredEitherNotBothPropsCheck,
  size: PropTypes.number,
};

export default DescriptionIcon;
