import { Box, Grid, Paper, Typography } from "@mui/material";

import React, { useState, useEffect } from "react";
import { jgDenimColors } from "../jg-material/theme/jg-denim-colors";
import { withInnerWidth } from "./shared";
import { BootstrapSize, breakpoints } from "./shared/bootstrap-helpers";

// we should make this reusable. just improving some styles here for now since there's no design available...
const Card = ({ title, body }) => {
  return (
    <Paper
      elevation={2}
      sx={{
        minHeight: "80px",
        minWidth: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#2d4a6133",
      }}
    >
      <Typography variant="h6" color={"white"}>
        {title}
      </Typography>
      <Typography variant="body1" color={"white"}>
        {body}
      </Typography>
    </Paper>
  );
};

const CountdownTimer = ({ collapsedColor, endDateUnixTimestamp, expanded, isMobile }) => {
  const calculateTimeLeft = () => {
    // unix timestamp to milliseconds
    const difference = +new Date(endDateUnixTimestamp * 1000) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  if (!timeLeft) return null;

  if (expanded) {
    return (
      <Box>
        <Grid container spacing={1}>
          <Grid item>
            <Card title={timeLeft.days} body="days" />
          </Grid>
          <Grid item>
            <Card title={timeLeft.hours} body="hours" />
          </Grid>
          <Grid item>
            <Card title={timeLeft.minutes} body="minutes" />
          </Grid>
          <Grid item>
            <Card title={timeLeft.seconds} body="seconds" />
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return (
      <Box>
        <Typography
          variant={isMobile ? "body1" : "h6"}
          color={collapsedColor || jgDenimColors.denim.dark}
          letterSpacing={0}
        >
          {timeLeft.days} days {timeLeft.hours} hours {timeLeft.minutes} minutes {timeLeft.seconds}{" "}
          seconds
        </Typography>
      </Box>
    );
  }
};

export default CountdownTimer;
