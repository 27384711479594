import React, { useEffect, useState } from "react";
import * as moment from "moment";
import { openDialog } from "../../components/shared/ui/uiSlice";
import { connect } from "react-redux";
import { capitalize } from "../shared/utils";

const DATE_FORMAT = "MMM DD, GGGG";
const DASHBOARD_NOTE_MENU_CLASS = "dashboard__note-card__menu";

const NoteCard = ({ note, onEdit, openDialog, goToNoteableType }) => {
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    const onPageClick = (e) => {
      if (!e.target.closest(`.${DASHBOARD_NOTE_MENU_CLASS}`)) {
        setMenuVisible(false);
      }
    };

    document.body.addEventListener("click", onPageClick, true);

    return () => {
      document.body.removeEventListener("click", onPageClick, true);
    };
  });

  return (
    <div className="dashboard__note-card">
      <div className="dashboard__note-card__header">
        <p>{note.attributes.noteableLevel}</p>
        <h3>{note.attributes.noteableTitle}</h3>
        <button
          className="dashboard__note-card__header__menu-button"
          onClick={() => {
            setMenuVisible(!menuVisible);
          }}
        >
          <i className="icon icon-menu-dots-vertical"></i>
        </button>
      </div>
      <div className="dashboard__note-card__content">
        <p>{note.attributes.content}</p>
        <span>{moment(note.attributes.createdAt).format(DATE_FORMAT)}</span>
      </div>
      <div className={DASHBOARD_NOTE_MENU_CLASS} style={{ display: menuVisible ? "flex" : "none" }}>
        <button onClick={() => onEdit(note)}>
          <i className="icon icon-pencil"></i> Edit
        </button>
        <button
          onClick={() => {
            setMenuVisible(false);
            openDialog({
              message: "Delete this note?",
              confirmButtonText: "Delete",
              data: note,
            });
          }}
        >
          <i className="icon icon-bin"></i>
          Delete
        </button>
        <button onClick={() => goToNoteableType(note)}>
          <i className="icon icon-play"></i>Go To{" "}
          {capitalize(note.attributes.noteableType.replace(/group/gi, "module"))}
        </button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openDialog: (text) => dispatch(openDialog(text)),
});

export default connect(null, mapDispatchToProps)(NoteCard);
