import React from "react";
import SimpleDialog from "../../components/shared/ui/SimpleDialog";
import LiteYoutube from "../../components/shared/LiteYoutube";
import { getYoutubeId } from "./utils";

const PerformancePlayDialog = ({
  performanceToPlay: {
    attributes: { description, thumbnails, userAttributes, videoUrl },
  },
  setPerformanceToPlay,
}) => {
  return (
    <SimpleDialog
      title={`View ${userAttributes.username}'s performance`}
      content={
        <>
          <LiteYoutube
            url={getYoutubeId(videoUrl)}
            thumbnailUrl={thumbnails && thumbnails.standard}
          />
          {description && <p style={{ margin: "20px auto 0 auto" }}>{description}</p>}
        </>
      }
      closeAction={() => setPerformanceToPlay(null)}
    />
  );
};

export default PerformancePlayDialog;
