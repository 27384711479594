import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import * as slices from "../slices";
import { normalize } from "normalizr";
import { songRequestsJson } from "../songSchema";
import debounce from "lodash.debounce";
import { selectSongRequestsMetadata } from "../songSelectors";
import { resultMergeStrategies } from "../shared";

const _fetchSongRequests = (
  { page, query, mergeStrategy = resultMergeStrategies.append },
  { dispatch, getState }
) => {
  dispatch(slices.loading.actions.setSongRequestsListingLoading(true));

  const metadata = selectSongRequestsMetadata(getState()),
    queryString = `page=${page}&per_page=${metadata.perPage}&query=${query}`;

  return axios
    .get(`/song-request-search.json?${queryString}`)
    .then(({ data }) => {
      const { entities, result } = normalize(
        {
          pendingRequests: data.pendingRequests,
          completedRequests: data.completedRequests,
          selectedRequests: data.selectedRequests,
        },
        songRequestsJson
      );
      const isEmpty = Object.entries(entities).length === 0;
      if (mergeStrategy === resultMergeStrategies.append) {
        dispatch(slices.entities.actions.addEntities(entities));
        dispatch(slices.ui.actions.appendPendingSongRequestOrderedIds(result.pendingRequests.data));
        // );
      } else {
        // when empty, normalize gives us {} instead of { songRequests: {} } - so when mapping through
        // Object.entries in replaceEntites, results aren't emptied
        const entityObject = isEmpty ? { songRequests: {} } : entities;
        dispatch(slices.entities.actions.replaceEntities(entityObject));
        dispatch(slices.ui.actions.setPendingSongRequestOrderedIds(result.pendingRequests.data));
        dispatch(slices.ui.actions.setSelectedSongRequestOrderedIds(result.selectedRequests.data));
        dispatch(
          slices.ui.actions.setCompletedSongRequestOrderedIds(result.completedRequests.data)
        );
      }
      dispatch(slices.metadata.actions.setPage({ entity: "songRequests", page }));
      dispatch(
        slices.metadata.actions.setTotalCount({
          entity: "songRequests",
          totalCount: result.pendingRequests.metadata.totalCount,
        })
      );
    })
    .catch((e) => {
      dispatch(slices.ui.actions.openAlert({ message: "An Error Occurred" }));
    })
    .finally(() => dispatch(slices.loading.actions.setSongRequestsListingLoading(false)));
};

const debouncedFetchSongRequests = debounce(_fetchSongRequests, 250);

export const fetchSongRequests = createAsyncThunk(
  "songs/fetchSongRequests",
  debouncedFetchSongRequests
);
