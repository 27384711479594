import { Box, Typography } from "@mui/material";
import React from "react";
import { VideoEmbed } from "../../components/swoosh-templates";

const VIMEO_VIDEO_URL = "https://vimeo.com/952646524";

const BluesImmersionIntroduction = () => {
  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} my={6}>
      <Typography variant={"h4"} mb={3}>
        Do you love Blues Guitar, and are tired of aimless practice?
      </Typography>

      <Typography variant={"h8"} textTransform={"none"} mb={5}>
        Dive into guitar freedom with <strong>Blues Immersion</strong>—a 6-month program developed
        by Justin Sandercoe for those hungry to improvise and make music effortlessly. Master
        essential skills and jam confidently with your friends or in a band. Ready for the ultimate
        Blues journey? Join us!
      </Typography>

      <VideoEmbed fullWidth={true} videoUrl={VIMEO_VIDEO_URL} />
    </Box>
  );
};

export default BluesImmersionIntroduction;
