// productTypes values are from DB, we'll use this enum to reference them
export const productTypes = {
  dvd: "DVD",
  app: "app",
  jamTracks: "Jam Tracks",
  merch: "Merch",
  book: "Book",
  digital: "Digital",
  tabs: "Tabs",
};

export const productSubtypes = {
  songbook: "songbook",
  technique: "technique",
  blues: "blues",
  teeShirt: "tee shirt",
  hoodie: "hoodie",
  mug: "mug",
  bottle: "bottle",
  hat: "hat",
};

export const productTypesQueryStringValues = {
  dvd: "dvds",
  app: "apps",
  jamTracks: "jam-tracks",
  merch: "merch",
  book: "books",
  digital: "digital",
  tabs: "tabs",
};

export const queryStringValuesToProductTypes = {
  [productTypesQueryStringValues.dvd]: productTypes.dvd,
  [productTypesQueryStringValues.app]: productTypes.app,
  [productTypesQueryStringValues.jamTracks]: productTypes.jamTracks,
  [productTypesQueryStringValues.merch]: productTypes.merch,
  [productTypesQueryStringValues.book]: productTypes.book,
  [productTypesQueryStringValues.digital]: productTypes.digital,
  [productTypesQueryStringValues.tabs]: productTypes.tabs,
};

export function productTypeToQueryString(typeOf) {
  return productTypesQueryStringValues[
    Object.keys(productTypes).find((key) => productTypes[key] === typeOf)
  ];
}

export function productsFilteredByType(products, typeOf, subtypes = []) {
  subtypes = Array.isArray(subtypes) ? subtypes : [subtypes];

  return products.filter((product) =>
    !subtypes.length
      ? product.data.attributes.typeOf === typeOf
      : product.data.attributes.typeOf === typeOf &&
        subtypes.includes(product.data.attributes.subtype)
  );
}
