import React, { useState } from "react";
import { LightTooltip } from "../../shared/ui/Tooltips";

import LoginModal from "../../auth/LoginModal";
import { BootstrapSize, breakpoints } from "../../shared/bootstrap-helpers";
import { withInnerWidth } from "../../shared";
import { ACTION_TYPES, useUser, useUserDispatch } from "../contexts/userContext";
import { deleteVote, submitVote } from "../contexts/userActions";

export default function ({ songRequest, updateVoteCount, nextPossibleVoteTime }) {
  const user = useUser();
  const userVoted =
    user?.userSongVotes &&
    user.userSongVotes.some(
      (vote) => Number(vote.attributes.songRequestId) === Number(songRequest.id)
    );

  const belongsToUser = Number(songRequest.attributes.userId) === Number(user?.data?.id);

  return userVoted || belongsToUser ? (
    <UnvoteButton songRequest={songRequest} user={user} updateVoteCount={updateVoteCount} />
  ) : (
    <VoteButton
      songRequest={songRequest}
      user={user}
      updateVoteCount={updateVoteCount}
      nextPossibleVoteTime={nextPossibleVoteTime}
    />
  );
}

const oneWeekInMilliseconds = 604800000;

const VoteButton = withInnerWidth(
  ({ songRequest, width, user, updateVoteCount, nextPossibleVoteTime }) => {
    const dispatch = useUserDispatch();

    const isRateLimited = nextPossibleVoteTime && nextPossibleVoteTime > Date.now();

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const showText = width > breakpoints[BootstrapSize.smDevice];

    const handleCreateVote = () => {
      setLoading(true);
      submitVote(songRequest.id)
        .then((response) => {
          dispatch({
            type: ACTION_TYPES.SUBMIT_VOTE,
            vote: response.data.data,
          });
          updateVoteCount(songRequest.id, 1);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <>
        <LightTooltip
          title={
            isRateLimited
              ? `You're allowed to vote 20 times within a week. You can vote again on ${new Date(
                  nextPossibleVoteTime + oneWeekInMilliseconds / 7
                ).toLocaleString("en-US", {
                  weekday: "long",
                  month: "long",
                  day: "numeric",
                })}`
              : ""
          }
        >
          <div>
            <button
              disabled={isRateLimited || loading}
              onClick={() => (user && !isRateLimited ? handleCreateVote() : setShowModal(true))}
              className="button button--no-background vote-button vote-button__vote"
            >
              <i className="icon icon-thumbs-up-hollow" />{" "}
              {showText && <span className="vote-text">Vote</span>}
            </button>
          </div>
        </LightTooltip>
        {showModal && <LoginModal isOpen={showModal} toggleModal={() => setShowModal(false)} />}
      </>
    );
  }
);

const UnvoteButton = ({ songRequest, user, updateVoteCount }) => {
  const dispatch = useUserDispatch();

  const handleDeleteVote = () => {
    deleteVote(songRequest.id).then(() => {
      dispatch({
        type: ACTION_TYPES.DELETE_VOTE,
        songRequestId: songRequest.id,
      });
      updateVoteCount(songRequest.id, -1);
    });
  };

  const disabled = Number(songRequest.attributes.userId) === Number(user.data.id);

  return (
    <LightTooltip title={disabled ? "Can't remove your vote for your own request!" : ""}>
      <button
        onClick={() => !disabled && handleDeleteVote()}
        className="button button--no-background vote-button vote-button__unvote"
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      >
        <i className="icon icon-thumbs-up-filled" />
      </button>
    </LightTooltip>
  );
};
