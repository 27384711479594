import React from "react";
import { Field } from "formik";

const NewsletterConsent = () => {
  return (
    <div className="auth__consent">
      <div className="auth__checkbox">
        <Field id="newsletterConsent" name="newsletterConsent" type="checkbox" />
        <label htmlFor="newsletterConsent">I want to receive Justin’s monthly newsletter.</label>
      </div>
    </div>
  );
};

export default NewsletterConsent;
