import React from "react";
import ReactOnRails from "react-on-rails";
import { Provider } from "react-redux";
import "./store/playgroundStore";
import PlaygroundRoot from "./PlaygroundRoot";

const Playground = () => {
  const store = ReactOnRails.getStore("playgroundStore");

  return (
    <Provider store={store}>
      <PlaygroundRoot />
    </Provider>
  );
};

export default Playground;
