import React from "react";
import TabbedTab from "../components/TabbedTab";

export default class TabbedTabContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      selected: this.props.data[0],
      activeTab: this.props.activeTab,
      typeOf: this.props.typeOf,
    };
  }

  updateSelected = (item) => {
    this.props.triggerSelected(item);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeTab) {
      this.setState({ activeTab: nextProps.activeTab });
    }
  }

  render() {
    return (
      <div className="tabbed-navigation-list">
        <div className="short-header-container">
          <h2 className="header2 short-header-intro uppercase">Intro Videos</h2>
          {this.state.data.map((item, index) => (
            <div
              key={item.title}
              onClick={() => this.updateSelected(item)}
              className={
                item.title == this.state.activeTab.title
                  ? "tabbed-navigation-item tabbed-navigation-item--active"
                  : "tabbed-navigation-item"
              }
            >
              <TabbedTab data={item} position={index + 1} typeOf={this.state.typeOf} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
