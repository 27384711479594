import React from "react";
import debounce from "lodash.debounce";

export default class DashboardFilters extends React.Component {
  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
  }

  search = (e) => {
    this.props.onSearch(e.target.value);
  };

  clearSearch = () => {
    this.searchInput.current.value = "";
  };

  render() {
    return (
      <div className="dashboard__filters">
        <i className="icon icon-search-thin"></i>
        <input
          ref={this.searchInput}
          type="text"
          placeholder={this.props.searchPlaceholder}
          onChange={debounce(this.search, 250)}
        />
        <div>
          <span>Sort by: </span>
          <select name="sort_by" onChange={(e) => this.props.onSort(e.target.value)}>
            {this.props.sortBy.map((sortByItem, idx) => {
              return (
                <option key={idx} value={`${sortByItem.column}-${sortByItem.direction}`}>
                  {sortByItem.title}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }
}
