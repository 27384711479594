import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Loading from "../../components/shared/Loading";
import _ from "lodash";

const FormInput = ({ label, confirm, submitForm, user, initialValues, validationSchema }) => {
  const [editing, setEditing] = useState(false);
  const fieldName = Object.keys(initialValues)[0];

  const formDisabled = (props) => {
    if (props.dirty && Object.keys(props.errors).length === 0 && !props.isSubmitting) {
      return false;
    } else {
      return true;
    }
  };

  const toggleVisibility = (e) => {
    const input = e.currentTarget.parentElement.querySelector("input");

    if (input.type === "password") {
      e.currentTarget.classList.remove("icon-view-show");
      e.currentTarget.classList.add("icon-view-hide");
      input.type = "text";
    } else {
      e.currentTarget.classList.remove("icon-view-hide");
      e.currentTarget.classList.add("icon-view-show");
      input.type = "password";
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        const data = {};

        data[fieldName] = values[fieldName].trim();

        if (confirm) {
          data[`${fieldName}_password_confirm`] = values[`${fieldName}_password_confirm`];
        }

        submitForm(data, setSubmitting, setErrors, setEditing);
      }}
    >
      {(props) => (
        <Form>
          {props.isSubmitting && <Loading />}
          <div className="form-input">
            <span className="label">{label}</span>
            {!editing ? (
              <>
                <p>{props.values[fieldName]}</p>
                <button onClick={() => setEditing(true)} className="edit">
                  edit
                </button>
              </>
            ) : (
              <>
                <Field name={fieldName} type="text" />
                <ErrorMessage name={fieldName} component="div" className="error" />
              </>
            )}
          </div>
          {editing && (
            <>
              {confirm && (
                <div className="form-input">
                  <span className="label">current password</span>
                  <Field
                    name={`${fieldName}_password_confirm`}
                    type="password"
                    autoComplete="off"
                  />
                  <i className="icon icon-view-show" onClick={(e) => toggleVisibility(e)}></i>
                  <ErrorMessage
                    name={`${fieldName}_password_confirm`}
                    component="div"
                    className="error"
                  />
                </div>
              )}
              <div className="form-controls">
                <button type="submit" disabled={formDisabled(props)} className="save">
                  save
                </button>
                <button
                  onClick={() => {
                    setEditing(false);
                    const values = [];
                    values[fieldName] = user.data.attributes[_.camelCase(fieldName)];
                    props.resetForm({ values: { ...initialValues, ...values } });
                  }}
                  className="cancel"
                >
                  cancel
                </button>
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default FormInput;
