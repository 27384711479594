import React from "react";

function LibraryToggle({
  showLibrary,
  setShowLibrary,
  showLessons,
  setShowLessons,
  showInstructions,
  setShowInstructions,
  width,
}) {
  return (
    // <div className="slider-toggle slider-toggle--songs">
    //   <div className="float-left">
    //     <input
    //       type="checkbox"
    //       id="library-toggle"
    //       checked={showLibrary}
    //       onChange={() => setShowLibrary(!showLibrary)}
    //     />
    //     <label htmlFor="library-toggle">
    //       <div
    //         className={`switch switch--${showLibrary ? "library" : "blank"}`}
    //         data-checked={"Library"}
    //         data-unchecked={"Blank"}
    //       />
    //     </label>
    //   </div>
    // </div>
    <div className="switch-toggle">
      <button
        className={`${!showLibrary && !showLessons && !showInstructions ? "active" : ""}`}
        onClick={() => {
          setShowLibrary(false);
          setShowLessons(false);
          setShowInstructions(false);
        }}
      >
        Player
      </button>
      <button
        className={`${showLibrary ? "active" : ""}`}
        onClick={() => {
          setShowLibrary(true);
          setShowLessons(false);
          setShowInstructions(false);
        }}
      >
        Library
      </button>
      <button
        className={`${showLessons ? "active" : ""}`}
        onClick={() => {
          setShowLibrary(false);
          setShowLessons(true);
          setShowInstructions(false);
        }}
      >
        Lessons
      </button>
      <button
        className={`${showInstructions ? "active" : ""}`}
        onClick={() => {
          setShowLibrary(false);
          setShowLessons(false);
          setShowInstructions(true);
        }}
      >
        Instructions
      </button>
    </div>
  );
}

export default LibraryToggle;
