import { createSelector } from "@reduxjs/toolkit";

import {
  filterOptionToQueryValue,
  filters as availableFilters,
  filterTypeToQueryValue,
} from "../../shared/search";

export const selectSearchState = (state) => state;

export const selectSearchQuery = createSelector(selectSearchState, (state) => state.query);

export const selectSearchFilters = createSelector(selectSearchState, (state) => state.filters);

export const selectHasActiveFilters = createSelector(
  selectSearchFilters,
  (filters) => filters != null && Object.keys(filters).length > 0
);

export const selectSearchFiltersAsQueryString = createSelector(
  selectSearchFilters,
  (activeFilters) => {
    if (Object.keys(activeFilters).length === 0) {
      return "";
    }

    return availableFilters
      .map(({ type, options }) => {
        const typeQueryStringVal = filterTypeToQueryValue[type];

        return typeQueryStringVal == null
          ? ""
          : options
              .filter((option) => activeFilters[option] != null)
              .map((option) => filterOptionToQueryValue[option])
              .filter((option) => option != null)
              .map((option) => `${typeQueryStringVal}[]=${option}`)
              .join("&");
      })
      .join("&");
  }
);

export const selectVisibleType = createSelector(
  selectSearchState,
  ({ visibleType }) => visibleType
);

export const selectVisibleTypeQueryString = createSelector(selectVisibleType, (visibleType) =>
  visibleType == null ? "" : `type=${visibleType}`
);

export const selectAdvancedSearchUrl = createSelector(
  selectSearchQuery,
  selectSearchFiltersAsQueryString,
  (query, filtersQueryString) => {
    const url = `/advanced_search?q=${query}`;
    const allFilters = [filtersQueryString].filter((str) => str.length > 0).join("&");
    return allFilters.length > 0 ? `${url}&${allFilters}` : url;
  }
);

export const selectBasicSearchUrl = createSelector(
  selectSearchQuery,
  selectSearchFiltersAsQueryString,
  (query, filtersQueryString) => {
    const url = `/search/${query}.json`;
    return filtersQueryString.length ? `${url}?${filtersQueryString}` : url;
  }
);

export const selectCurrentUser = createSelector(
  selectSearchState,
  ({ currentUser }) => currentUser
);

export const selectItems = createSelector(selectCurrentUser, (currentUser) =>
  currentUser == null ? [] : currentUser.included.filter((i) => i.type === "userItem")
);

export const selectPracticeItems = createSelector(selectCurrentUser, (currentUser) =>
  currentUser == null ? [] : currentUser.included.filter((i) => i.type === "userPracticeItem")
);

export const selectSavedSongs = createSelector(selectItems, (items) =>
  items
    .filter(
      (item) => item.attributes.itemableType === "Song" && item.attributes.itemStatus === "saved"
    )
    .reduce(
      (dictionary, item) => ({
        ...dictionary,
        [item.attributes.itemableId]: item,
      }),
      {}
    )
);

export const selectPracticeSongs = createSelector(selectPracticeItems, (practiceItems) =>
  practiceItems
    .filter(
      (item) =>
        item.attributes.practiceItemType === "Song" && item.attributes.practiceItemItemableId
    )
    .reduce(
      (dictionary, item) => ({
        ...dictionary,
        [item.attributes.practiceItemItemableId]: item,
      }),
      {}
    )
);

export const selectResults = createSelector(selectSearchState, ({ results }) => results);

export const selectResultsEntities = createSelector(selectResults, ({ entities }) => entities);

export const selectResultOrderingAndMeta = createSelector(selectResults, ({ result }) => result);

export const selectLessonDictionary = createSelector(selectResultsEntities, ({ lesson }) => lesson);

export const selectOrderedLessons = createSelector(
  selectLessonDictionary,
  selectResultOrderingAndMeta,
  (lessons, ordering) => ordering.lesson.data.map((id) => lessons[id])
);

export const selectSongDictionary = createSelector(selectResultsEntities, ({ song }) => song);

export const selectOrderedSongs = createSelector(
  selectSongDictionary,
  selectResultOrderingAndMeta,
  (songs, ordering) => ordering.song.data.map((id) => songs[id])
);

export const selectGroupDictionary = createSelector(selectResultsEntities, ({ group }) => group);

export const selectOrderedGroups = createSelector(
  selectGroupDictionary,
  selectResultOrderingAndMeta,
  (groups, ordering) => ordering.group.data.map((id) => groups[id])
);

export const selectProductDictionary = createSelector(
  selectResultsEntities,
  ({ product }) => product
);

export const selectOrderedProducts = createSelector(
  selectProductDictionary,
  selectResultOrderingAndMeta,
  (products, ordering) => ordering.product.data.map((id) => products[id])
);

export const selectResourceDictionary = createSelector(
  selectResultsEntities,
  ({ resource }) => resource
);

export const selectOrderedResources = createSelector(
  selectResourceDictionary,
  selectResultOrderingAndMeta,
  (resources, ordering) => ordering.resource.data.map((id) => resources[id])
);

export const selectOrderedResultsForType = createSelector(
  selectResultsEntities,
  selectResultOrderingAndMeta,
  (entities, orderingAndMeta) => (type) => {
    if (entities[type] == null || orderingAndMeta[type] == null) {
      return [];
    }
    return orderingAndMeta[type].data.map((id) => entities[type][id]);
  }
);

export const selectMetadataForType = createSelector(
  selectResultOrderingAndMeta,
  (orderingAndMeta) => (type) => orderingAndMeta[type] == null ? {} : orderingAndMeta[type].metadata
);

export const selectProductsForSong = createSelector(
  selectSongDictionary,
  selectProductDictionary,
  (songs, products) => (songId) => {
    const song = songs[songId];
    if (song == null) {
      return [];
    }

    return (song.relationships.products.data || [])
      .map(({ id }) => products[id])
      .filter((product) => product != null);
  }
);

export const selectBooksForSong = createSelector(
  selectProductsForSong,
  (productSelector) => (songId) =>
    productSelector(songId)
      .map(({ attributes }) => attributes)
      .filter(({ typeOf }) => typeOf === "Book")
);

export const selectAppsForSong = createSelector(
  selectProductsForSong,
  (productSelector) => (songId) =>
    productSelector(songId)
      .map(({ attributes }) => attributes)
      .filter(({ typeOf }) => typeOf === "app")
);
