import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import SortableList from "../components/SortableList";
import FilterItems from "../components/FilterItems";
import AppConfig from "../../../packs/AppConfig.js";
import UserItemDummyButton from "../components/UserItemDummyButton";

export default class SongList extends React.Component {
  static propTypes = {
    songs: PropTypes.object.isRequired,
  };

  constructor(props, _railsContext) {
    super(props);

    this.state = {
      songs: this.songByParams(),
      songCount: props.songs["songs"].length,
      filterCount: props.songs["songs_count"],
      selectedArtist: props.selectedArtist,
      // These values are passed to SortableList
      sortBy: [
        {
          title: "",
          name: "album_cover",
          value: function (item) {
            return null;
          },
          dir: "ASC",
        },
        {
          title: "Title",
          name: "title",
          value: function (item) {
            return item.title || "?";
          },
          sortValue: function (item) {
            return item.title || null;
          },
          dir: "ASC",
        },
        {
          title: "Artist",
          name: "original_artist",
          value: function (item) {
            return item.original_artist || "?";
          },
          sortValue: function (item) {
            return item.original_artist || null;
          },
          dir: "ASC",
        },

        {
          title: "Grade",
          name: "grade",
          value: function (item) {
            if (item.grade && item.grade.belt) {
              let gradeMap = {};

              props.songs["grades"].map((grade) => {
                gradeMap[grade.belt] = grade.position;
              });

              return gradeMap[item.grade.belt];
            } else {
              return -1;
            }
            return (item.grade && item.grade.belt) || "?";
          },
          sortValue: function (item) {
            if (item.grade && item.grade.belt) {
              let gradeMap = {};

              props.songs["grades"].map((grade) => {
                // if we're dealing with Spectrum (value = 0), treat it same as item without grade
                if (grade.position && grade.position != 0) {
                  gradeMap[grade.belt] = grade.position;
                } else {
                  gradeMap[grade.belt] = null;
                }
              });

              return gradeMap[item.grade.belt];
            } else {
              return null;
            }

            return (item.grade && item.grade.belt) || null;
          },
          dir: "ASC",
        },
        {
          title: "Date",
          name: "date",
          value: function (item) {
            return item.release_date[0] || "?";
          },
          sortValue: function (item) {
            if (item.release_date[1]) {
              return Date.parse(item.release_date[1]);
            } else if (item.created_at) {
              return Date.parse(item.created_at);
            } else {
              return null;
            }
          },
          dir: "ASC",
        },
      ],
      // These are passed to FilterItems component
      filterBy: [
        {
          title: "Search",
          name: "search",
          searchStr: null,
          filters: [],
          includeItem: function (item) {
            let searchFields = ["title", "original_artist", "reference"];
            let include = false;

            searchFields.map((field) => {
              let value = item[field];

              // Partial match is enough
              if (value.toLowerCase().indexOf(this.filters[0]) !== -1) {
                include = true;
              }
            });

            return include;
          },
        },
        {
          title: "Difficulty",
          subtitle: "Select songs by difficulty",
          name: "level",
          filterItems: props.songs["levels"],
          filters: [],
          listVisible: true,
          value: function (filterItem) {
            if (filterItem.title && filterItem.title.length) {
              return filterItem.title;
            } else if (filterItem.belt && filterItem.belt.length) {
              return filterItem.belt;
            }
          },
          includeItem: function (item, filter) {
            let itemValue = (item.grade && item.grade.belt) || null;
            let include = false;
            if (this.filters.length) {
              if (itemValue) {
                include = this.filters.includes(itemValue);
              }
            } else {
              include = true;
            }

            return include;
          },
        },
        {
          title: "Easy Chords",
          subtitle: "Select songs by chords you know",
          name: "chords",
          filterItems: props.songs["chords"],
          filters: [],
          listVisible: false,
          value: function (filterItem) {
            return filterItem.title;
          },
          includeItem: function (item, filter) {
            let itemValues = item.chords.map((val) => {
              return val.title;
            });
            let include = false;

            // If we have filters to match...
            if (this.filters.length) {
              //... and we have chords
              if (itemValues && itemValues.length) {
                // if filter chords contain all chords in the song
                include = itemValues.filter((value) => !this.filters.includes(value)).length === 0;
              }
              // ... or just include
            } else {
              include = true;
            }

            return include;
          },
        },
        {
          title: "Tags",
          subtitle: "Select songs by common filters",
          name: "tags",
          filterItems: props.songs["tags"],
          filters: [],
          listVisible: false,
          value: function (filterItem) {
            return filterItem.title;
          },
          includeItem: function (item, filter) {
            let itemValues = item.tags;
            let include = false;

            // If we have filters to match...
            if (this.filters.length) {
              //... and we have tags
              if (itemValues && itemValues.length) {
                itemValues.map((val) => {
                  // match each tag against filters
                  if (this.filters.includes(val.title)) {
                    include = true;
                  }
                });
              }
              // ... or just include
            } else {
              include = true;
            }

            return include;
          },
        },
        {
          title: "Book and App",
          subtitle: "Songs in Justin’s products",
          name: "appears",
          filterItems: props.songs["appears_products"],
          filters: [],
          listVisible: false,
          value: function (filterItem) {
            return filterItem.title;
          },
          includeItem: function (item, filter) {
            let include = false;
            if (item.appears_in_products && item.appears_in_products.length > 0) {
              let filters = this.filters;

              item.appears_in_products.forEach(function (i) {
                if (i && filters.includes(i.title)) {
                  include = true;
                }
              });
            }
            return include;
          },
        },
        {
          title: "Genre",
          subtitle: "Select songs by style of music ",
          name: "genre",
          filterItems: props.songs["genres"],
          filters: [],
          listVisible: false,
          value: function (filterItem) {
            return filterItem.title;
          },
          includeItem: function (item) {
            let itemValue = (item.genre && item.genre.title) || null;
            let include = false;

            // If we have filters, check if this item matches any of them
            if (this.filters.length) {
              if (itemValue) {
                include = this.filters.includes(itemValue);
              }
              // ... or just include by default
            } else {
              include = true;
            }

            return include;
          },
        },
      ],
      initialLevel: [],
    };
  }

  componentDidMount() {
    // Justin wants filters open by default on bigger screens - if the user is
    // on smaller screen, close them here.
    this.updateWindowWidth();
    window.addEventListener("resize", this.updateWindowWidth);
    let songs = [...this.state.songs];
    let filterBy = [...this.state.filterBy];

    if (window.innerWidth && window.innerWidth < 850) {
      filterBy.forEach(function (i) {
        if (i["listVisible"]) {
          i["listVisible"] = false;
        }
      });
    }
    this.setState({ filterBy });
  }

  sortedSongs(collection) {
    collection.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
    return collection;
  }

  songByParams() {
    if (this.props.selectedArtist) {
      let songs = [...this.props.songs["songs"]];
      const dashes = /-/g;
      let artist = this.props.selectedArtist.replace(dashes, " ");
      songs = songs.filter((s) => s.original_artist.toLowerCase() === artist.toLowerCase());

      if (songs.length > 0) {
        songs = this.sortedSongs(songs);
      }
      return songs.length > 0 ? songs : this.props.songs["songs"];
    } else if (this.props.selectedDifficulty) {
      let songs = [...this.props.songs["songs"]];
      let difficulty = this.props.selectedDifficulty;
      songs = songs.filter(
        (s) => s.level && s.level.slug.toLowerCase() === difficulty.toLowerCase()
      );
      if (songs.length > 0) {
        songs = this.sortedSongs(songs);
      }
      return songs.length > 0 ? songs : this.props.songs["songs"];
    } else {
      return this.props.songs["songs"];
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  }

  updateWindowWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  onItemsFiltered = (items, searchBy) => {
    let currentFilters = this.state.filterCount;

    // set all filter counts to 0
    for (var k in currentFilters) {
      for (var key in currentFilters[k]) {
        currentFilters[k][key] = 0;
      }
    }

    // repopulate filter count values based on filtered item values
    for (var i = 0; i < items.length; i++) {
      if (items[i].genre) {
        currentFilters["genres"][items[i].genre.id] += 1;
      }
      if (items[i].tags && items[i].tags.length > 0) {
        items[i].tags.forEach(function (tag) {
          currentFilters["tags"][tag.id] += 1;
        });
      }
      if (items[i].grade) {
        currentFilters["grades"][items[i].grade.id] += 1;
      }
      if (items[i].level) {
        currentFilters["levels"][items[i].level.id] += 1;
      }
      if (items[i].appears_in_products && items[i].appears_in_products.length > 0) {
        items[i].appears_in_products.forEach(function (ap) {
          currentFilters["appears_in"][ap.id] += 1;
        });
      }
      if (items[i].chords && items[i].chords.length > 0) {
        items[i].chords.forEach(function (chord) {
          currentFilters["chords"][chord.id] += 1;
        });
      }
    }

    searchBy = searchBy || this.state.searchBy;
    this.setState({
      songs: items,
      activeIndex: 0,
      searchBy: searchBy,
      filterCount: currentFilters,
      selectedArtist: null,
    });

    if (window.innerWidth < 769) {
      const sortedList = ReactDOM.findDOMNode(this.refs.scrollTo);
      sortedList.scrollIntoView();
      window.scrollBy(0, -75);
    }
  };

  getGenres = (sngs) => {
    sngs.map((song) => {
      <li> {song.genre}</li>;
    });
  };

  clearSelectedArtist = () => {
    let url = window.location.href.split("?")[0];
    window.history.replaceState({}, "All Songs", url);
    this.setState({ songs: this.props.songs["songs"], selectedArtist: null });
  };

  resetSongList = () => {
    if (this.state.selectedArtist && this.state.songs != this.props.songs["songs"]) {
      return (
        <div className="songs__clear-artist">
          <span className="page-subheading" onClick={this.clearSelectedArtist}>
            Clear Selected Artist
          </span>
        </div>
      );
    }
  };

  showDisqus = (current_user) => {
    return (
      <div className="disqus_section__container">
        <div className="disqus_discussion">
          <div className="main-tab-options">
            <ul className="nav nav-tabs" role="tablist">
              <li role="presentation" className="active header5 bold uppercase">
                <a href=".disqus__href" aria-controls="home" role="tab" data-toggle="tab">
                  Discussion
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="tab-content disqus_comments">
          <div role="tabpanel" className="active disqus-container tab-pane disqus__href">
            {current_user ? (
              <div id="disqus_thread"></div>
            ) : (
              <UserItemDummyButton clickedText={true} extraText={"to view and leave comments."} />
            )}
          </div>
        </div>
      </div>
    );
  };

  ShowSortableList = () => {
    if (this.state.songs.length > 0) {
      return (
        <div>
          <SortableList
            items={this.state.songs}
            sortBy={this.state.sortBy}
            width={this.state.width}
            filterCount={this.state.filterCount}
            activeIndex={0}
            limit={20}
            type="song"
          />

          {this.resetSongList()}
        </div>
      );
    } else {
      return (
        <div className="song-list__none alert alert-info">
          <p>{AppConfig.data.songList.noSongsAlert}</p>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="song-list container-fluid">
        <div className="row">
          <div className="col-xs-12 col-sm-4 col-md-3">
            <div className="song-list__filters">
              <FilterItems
                items={this.state.songs}
                allSongs={this.props.songs["songs"]}
                filterCount={this.state.filterCount}
                filterBy={this.state.filterBy}
                onFiltersChanged={this.onItemsFiltered}
                songCount={this.state.songCount}
                initialLevel={this.state.initialLevel}
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-8 col-md-9" ref="scrollTo">
            {this.ShowSortableList()}
            {this.showDisqus(this.props.current_user)}
          </div>
        </div>
      </div>
    );
  }
}
