import React from "react";
import Modal from "react-modal";

import DynamicIframe from "../components/DynamicIframe";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100%",
    width: "90%",
    backgroundColor: "transparent",
    border: "none",
    padding: "none",
  },
};

export default class WelcomeVideoButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showVideo: false };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      Modal.setAppElement("body");
    }
  }

  handleOpen = () => {
    this.setState({ showVideo: true });
  };

  handleClose = () => {
    this.setState({ showVideo: false });
  };

  render() {
    return (
      <div>
        <button className="welcome-video-button button button--primary" onClick={this.handleOpen}>
          <span className="play-icon">
            <i className="icon icon-play" />
          </span>
          welcome video
        </button>

        <Modal
          isOpen={this.state.showVideo}
          onRequestClose={this.handleClose}
          shouldCloseOnOverlayClick={true}
          style={customStyles}
        >
          <DynamicIframe
            showDonateAfterVideo={false}
            link="https://player.vimeo.com/video/276210649"
            containerHeight="350"
            aspectRatio="1.777"
          />
        </Modal>
      </div>
    );
  }
}
