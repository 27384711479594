import React from "react";
import { connect } from "react-redux";
import { instruments } from "../../store/songSelectors";
import ToggleSection from "./ToggleSection";
import Img from "./Img";
import { withMobileOnlySwoosh } from "../../../components/shared/WithMobileOnlySwoosh";
import { SWOOSH_BACKGROUND } from "./consts";

class GettingTheSound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    let { isOpen } = this.state;
    const { instruments, song } = this.props;
    const soundIntro = song.data.attributes.gettingTheSound;
    if (instruments.length === 0 && (!soundIntro || soundIntro.length === 0)) {
      return null;
    } else {
      return (
        <div
          className={
            "section-toggle__section section-toggle__chords " +
            (isOpen ? "section-toggle__section--active" : "")
          }
        >
          <ToggleSection _this={this} title="Getting the Sound" isOpen={isOpen} />

          {isOpen && (
            <>
              {soundIntro && soundIntro.length > 0 && (
                <div dangerouslySetInnerHTML={{ __html: soundIntro }} />
              )}

              <div className="flex">
                {instruments.map((instrument) => {
                  return (
                    <div key={instrument.attributes.id} className="song__instrument">
                      <Img
                        title={instrument.attributes.title}
                        image={`/system/dragonfly/production/${instrument.attributes.imageUid}`}
                      />
                      <p className="bold">{instrument.attributes.title}</p>
                      <div
                        dangerouslySetInnerHTML={{ __html: instrument.attributes.description }}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      );
    }
  }
}

// const wrappedWithSwoosh = withMobileOnlySwoosh(GettingTheSound, SWOOSH_BACKGROUND);

const mapStateToProps = (state, { width }) => ({
  song: state.entity.song,
  instruments: instruments(state),
  width: width,
});

export default connect(mapStateToProps)(GettingTheSound);
