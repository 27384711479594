import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { CDN_URL } from "../../components/shared/CdnUrl";

const defaultHeaderImages = {
  [BootstrapSize.smDevice]: `${CDN_URL}/images/pages/playin-it-forward/playin-it-forward-hero-lg.jpg`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/playin-it-forward/playin-it-forward-hero-sm.png`,
};
const welcomeHeaderImages = {
  [BootstrapSize.smDevice]: `${CDN_URL}/images/pages/playin-it-forward/playin-it-forward-welcome-hero-lg.jpeg`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/playin-it-forward/playin-it-forward-welcome-hero-sm.jpeg`,
};

const PlayinItForwardSwoosh = ({ showApplicationForm, applicationReceived, hasReceivedGuitar }) => {
  return (
    <div className="playin-it-forward-swoosh">
      <Swoosh imgUrl={hasReceivedGuitar ? welcomeHeaderImages : defaultHeaderImages}>
        <div className="playin-it-forward-swoosh__content">
          {(showApplicationForm || applicationReceived) && (
            <div className="breadcrumb">
              <a href="/playin-it-forward">back to playin' it forward</a>
            </div>
          )}
          {!hasReceivedGuitar ? (
            <h1 className={showApplicationForm || applicationReceived ? "header2" : "header1"}>
              Playin' it Forward
            </h1>
          ) : (
            <h1 className="header2 welcome-header">
              Playin' it Forward
              <span>Welcome :)</span>
            </h1>
          )}
          {(showApplicationForm || applicationReceived) && (
            <h2 className="header1">application form</h2>
          )}
        </div>
      </Swoosh>
    </div>
  );
};

export default PlayinItForwardSwoosh;
