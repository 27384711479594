import React from "react";
import { connect } from "react-redux";
import { getDownloadLinks } from "./store/effects/getDownloadLinks";
import Loading from "../components/shared/Loading";
import LoginPrompt from "../components/auth/LoginPrompt";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const DownloadsList = ({ downloadLinks }) => {
  let [...links] = downloadLinks;
  let originalSize = links.filter((l) => l.quality === "source")[0];
  if (originalSize) {
    links.splice(links.indexOf(originalSize), 1);
  }
  links.sort((a, b) => {
    return a.size < b.size ? -1 : a.size > b.size ? 1 : 0;
  });
  return links.map((l, index) => {
    return (
      <div className="vimeo-downloads__option" key={index}>
        <span className="vimeo-downloads__quality">
          {l["quality"].toUpperCase()} {l["height"]}p
        </span>
        <a className="button button--blue button--inline" href={l["link"]}>
          download
        </a>
        <span className="vimeo-downloads__size"> ({formatBytes(l["size"])})</span>
      </div>
    );
  });
};

const VimeoVideoDownload = ({
  downloadLinks,
  isLoading,
  lessonId,
  getDownloadLinks,
  currentUser,
}) => {
  if (!currentUser) {
    return <LoginPrompt loginAction="download videos" token={ReactOnRails.authenticityToken()} />;
  } else if (isLoading) {
    return (
      <div style={{ position: "relative", minHeight: "200px" }}>
        <Loading />
      </div>
    );
  } else if (downloadLinks) {
    return <DownloadsList downloadLinks={downloadLinks} />;
  } else {
    return (
      <button
        className="button button--primary button--inline"
        onClick={() => getDownloadLinks(lessonId)}
      >
        Get Download Links
      </button>
    );
  }
};

const mapStateToProps = (state, ownProps) => ({
  downloadLinks: state.entity.downloadLinks,
  isLoading: state.ui.isLoading,
  lessonId: ownProps.lessonId,
  currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  getDownloadLinks: (lessonId, videoId) => dispatch(getDownloadLinks(lessonId, videoId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VimeoVideoDownload);
