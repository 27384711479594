import React from "react";
import {
  fetchSearchResults,
  resultMergeStrategies,
  setQuery,
  setQueryParam,
  setResults,
} from "./store";
import { connect } from "react-redux";
import Swoosh from "../shared/Swoosh";
import { initialState } from "./store/consts";

const QueryBar = ({ query, setQuery, clearQuery }) => {
  const inputRef = React.createRef();
  return (
    <Swoosh>
      <div className="query-bar container" onClick={() => inputRef.current.focus()}>
        <div className="query-bar__inner">
          <i className="icon icon-search-thin" />
          <input
            ref={inputRef}
            type="text"
            value={query}
            onChange={({ target }) => setQuery(target.value)}
          />
          <i className="icon icon-cross" onClick={clearQuery} />
        </div>
      </div>
    </Swoosh>
  );
};

export default connect(
  ({ query }) => ({ query }),
  (dispatch) => ({
    setQuery: (query) => {
      dispatch(setQuery(query));
      setQueryParam(query);
      dispatch(fetchSearchResults({ mergeStrategy: resultMergeStrategies.replace }));
    },
    clearQuery: () => {
      dispatch(setQuery(""));
      dispatch(setResults(initialState.results));
    },
  })
)(QueryBar);
