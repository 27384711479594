import React from "react";
import NewRoutine from "../NewRoutine";
//import PracticeRoutineContainer from '../../practice/PracticeRoutineContainer';

export default class NewOrExistingRoutine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    if (!this.props.practiceRoutines) {
      this.setState({
        getStarted: true,
      });
    } else {
      this.setState({
        routineId: this.props.practiceRoutines[0].id,
      });
    }
  }

  routineCreated = (routineId) => {
    this.setState({ getStarted: false, routineId: routineId });
  };

  render() {
    if (this.state.getStarted) {
      return (
        <NewRoutine
          user={this.props.user}
          routineCreated={this.routineCreated}
          practiceIntro={this.props.practiceIntro}
        />
      );
    } else {
      return (
        <PracticeRoutineContainer
          user={this.props.user}
          routineId={this.state.routineId}
          practiceRoutine={this.props.practiceRoutine}
          practiceItems={this.props.practiceItems}
          practiceSessions={this.props.practiceSessions}
          aboutPracticeRoutine={this.props.aboutPracticeRoutine}
        />
      );
    }
  }
}
