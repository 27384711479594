import React from "react";
import { connect } from "react-redux";

import SearchHeader, { openSearch } from "./Search";
import Menu from "./Menu";
import AuthButtons from "./AuthButtons";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CDN_URL } from "../shared/CdnUrl";

const STACKED_LOGO_SMALL = `${CDN_URL}/redesign/2023/logos/justinguitar-logo-hr-sm-dark.svg`;
const STACKED_LOGO_SMALL_LIGHT = `${CDN_URL}/redesign/2023/logos/justinguitar-logo-hr-sm.svg`;
const ANIMATION_TIMEOUT_MS = 200;

export const SmallHeader = ({ openSearch, searchOpen, isProductBundlesPage }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  let logo = STACKED_LOGO_SMALL;
  if (isProductBundlesPage) {
    logo = STACKED_LOGO_SMALL_LIGHT;
  }

  return (
    <div className="header__menu">
      <div className="header__left">
        <i className="icon icon-bars clickable" onClick={() => setMenuOpen(true)} />
        <i className="icon icon-search-thin clickable" onClick={() => openSearch()} />
      </div>
      <div className="header__center header__logo">
        <a href="/">
          <img src={logo} alt="JustinGuitar Logo" />
        </a>
      </div>
      <div className="header__right">
        <AuthButtons showJoin={false} showName={false} />
      </div>
      {menuOpen && <Menu close={() => setMenuOpen(false)} />}
    </div>
  );
};

export default connect(({ searchOpen }) => ({ searchOpen }), { openSearch })(SmallHeader);
