import React from "react";
import { SliderButton } from "@typeform/embed-react";
const FORM_ID = "othzMxCi";

const CtaButton = ({ text }) => {
  return (
    <SliderButton className="button button--primary button--inline" id={FORM_ID}>
      {text ? text : "click here"}
    </SliderButton>
  );
};

export default CtaButton;
