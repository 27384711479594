import React from "react";
import PropTypes from "prop-types";

const TabItem = ({ active, tabLink, displayName, mobileIcon, setActiveTab }) => {
  return (
    <button
      className={`mobile-top-row ` + (active === true ? "active" : "")}
      onClick={() => setActiveTab(tabLink)}
    >
      <>
        <span className={`submenu--mobile-only icon icon-${mobileIcon}`}></span>
        <span className="submenu--desktop-only">{displayName}</span>
        {active && <hr />}
      </>
    </button>
  );
};

export default TabItem;

TabItem.propTypes = {
  tabName: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  mobileIcon: PropTypes.string.isRequired,
};
