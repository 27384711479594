export function formatDate(d) {
  let dateToFormat = new Date(d);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return dateToFormat.toLocaleDateString("en-US", options);
}

export function trialEnd(d) {
  let dateToFormat = new Date(d);
  dateToFormat.setDate(dateToFormat.getDate() + 7);
  return formatDate(dateToFormat);
}

export function accessHasExpired(expiresAt) {
  let expirationDate = new Date(expiresAt);
  let today = new Date();
  return expirationDate < today;
}
