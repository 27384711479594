import React from "react";
import { connect } from "react-redux";
import { orderedTabImages } from "../../store/songSelectors";
import { setActiveImage, setViewAllImages } from "../../store/songStore";
import TabImage from "./TabImage";

const TabSingleImage = ({ orderedTabImages, activeImage, setActiveImage, setViewAllImages }) => {
  return (
    <div>
      <div className="song-sheet__images">
        <div className="song-sheet__options">
          {orderedTabImages.map((image, index) => {
            return (
              <span
                key={image.attributes.id}
                onClick={() => setActiveImage(image)}
                className={"clickable image-number " + (activeImage == image ? "active" : "")}
              >
                {index + 1}
              </span>
            );
          })}
        </div>
      </div>
      <div className="song-sheet__container song-sheet__tabs">
        <div className="song-sheet__cover"></div>
        <TabImage image={activeImage.attributes.imageLink} />
      </div>

      {orderedTabImages.length > 1 && (
        <div className="song-sheet__images">
          <div className="song-sheet__options">
            <span className="button button--blue button--inline" onClick={() => setViewAllImages()}>
              view all
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeImage: state.entity.activeImage,
  orderedTabImages: orderedTabImages(state),
});

const mapDispatchToProps = (dispatch) => ({
  setActiveImage: (image) => dispatch(setActiveImage(image)),
  setViewAllImages: () => dispatch(setViewAllImages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabSingleImage);
