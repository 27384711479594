import React from "react";
import PropTypes from "prop-types";
import DynamicIframe from "./DynamicIframe";

export default class HomepageTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.data[0],
    };
  }

  componentDidMount() {
    if (this.props.optimizeEvent === true && dataLayer) {
      dataLayer.push({ event: "optimize.activate" });
    }
  }

  componentDidUpdate() {
    dataLayer.push({ event: "optimize.activate" });
  }

  setActiveTab = (activeTab) => {
    this.setState({ activeTab });
  };

  tabContent = () => {
    return this.props.data.map((item, index) => {
      return (
        <div
          key={item.title}
          onClick={() => this.setActiveTab(item)}
          className={
            "tabbed-navigation-item " +
            (item.title == this.state.activeTab.title ? "tabbed-navigation-item--active" : "")
          }
          id={`tabbed-navigation-item-${index}`}
        >
          <div>
            {this.tabHeading(item, index)}

            <h2 className="header2 header2--white short-header">{index + 1}</h2>
          </div>
        </div>
      );
    });
  };

  tabHeading = (item, index) => {
    if (item.title === "The Beginner Song Course App") {
      return (
        <div>
          <h5 id={`tab-header-${index}`} className={"header5 bold long-header tabbed__sm-header"}>
            {item.title}
          </h5>
          {this.appButtons()}
        </div>
      );
    } else {
      return (
        <h5 id={`tab-header-${index}`} className={"header5 bold long-header"}>
          {item.title}
        </h5>
      );
    }
  };

  appButtons = () => {
    let appleLink = "https://go.onelink.me/app/fe2e385a";
    let androidLink = "https://go.onelink.me/app/5fbcf632";
    if (window.innerWidth <= 576) {
      appleLink = "https://go.onelink.me/3065804798/35a32b8f";
      androidLink = "https://go.onelink.me/3065804798/35a32b8f";
    }
    return (
      <div className="tabbed__app-btns">
        <div className="tabbed__app-link">
          <a href={androidLink} className="no-styling">
            <img
              alt="JustinGuitar Lessons & Songs App on Google Play"
              src="https://jtgt-static.b-cdn.net/images/google-play.png"
            ></img>
          </a>
        </div>
        <div className="tabbed__app-link">
          <a href={appleLink} className="no-styling">
            <img
              alt="JustinGuitar Lessons & Songs App on the App Store"
              src="https://jtgt-static.b-cdn.net/images/app-store.png"
            ></img>
          </a>
        </div>
      </div>
    );
  };

  tabButton = () => {
    let { activeTab } = this.state;
    if (activeTab.link && activeTab.link_text) {
      return (
        <div className="tabbed-text--button">
          <a className="header5 header5--orange button-spacing uppercase" href={activeTab.link}>
            {activeTab.link_text}
          </a>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="tabbed">
        <div className="tabbed__container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 tabbed-navigation tabbed-navigation--large-device">
              <div className="tabbed-navigation-list">
                <div className="short-header-container" id="short-header-container">
                  <h2 className="header2 short-header-intro uppercase">Intro Videos</h2>
                  {this.tabContent()}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 tabbed-video">
              <div className="tabbed-video__container">
                <DynamicIframe
                  showDonateAfterVideo={false}
                  link={this.state.activeTab.video}
                  containerHeight="350"
                  aspectRatio="1.777"
                />
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 top-aligned tabbed-text__container">
              <div className="tabbed-text">
                <div className="tabbed-text--title">
                  <h2 className="header2 bold no-margin">{this.state.activeTab.title}</h2>
                </div>
                <div className="tabbed-text--textbox">
                  <div
                    id={`tabbed-text-content-${this.props.data.indexOf(this.state.activeTab)}`}
                    className="paragraph tabbed-text__inner"
                    dangerouslySetInnerHTML={{ __html: this.state.activeTab.body }}
                  ></div>
                  <div className="paragraph tabbed-text__bg-course">
                    <p>
                      Give me 60 seconds of your time I'll tell you all about the site and what
                      you'll find here! :)
                    </p>
                    <p>
                      Way over 1000 free lessons and songs and a LOT more content to be released
                      soon.
                    </p>
                    <p>
                      Join the community (FREE) and download the{" "}
                      <strong>beginner song course app</strong>.
                    </p>
                  </div>
                  {this.appButtons()}
                </div>

                {this.tabButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomepageTop.propTypes = {};
