import React from "react";

const LessonContentTabOptions = (props) => {
  const hasSound = () => {
    let sound = props.item.getting_the_sound;
    let instruments = props.item.instruments;
    return (sound && sound.length > 0) || (instruments && instruments.length > 0);
  };

  const hasStrums = () => {
    return props.item.strums && props.item.strums.length > 0;
  };

  const hasChords = () => {
    return props.item.chords && props.item.chords.length > 0;
  };

  const bodyTitle = () => {
    if (props.itemableType === "Lesson") {
      return "LESSON";
    } else if (props.itemableType === "Song") {
      return "NOTES";
    }
  };

  const hasDownloadables = () => {
    return props.downloadables && props.downloadables.length > 0;
  };

  const hasVimeoDownload = () => {
    return props.item.vimeo_download_available === true;
  };

  const hasSheetMusic = () => {
    return props.item.sheet_music ? true : false;
  };

  const practiceTab = () => {
    if (
      (props.currentUser && props.item.practice_items && props.item.practice_items.length != 0) ||
      (props.currentUser &&
        props.item.practice_routines &&
        props.item.practice_routines.length != 0)
    ) {
      return (
        <li role="presentation" className="header5 bold uppercase">
          <a href=".lesson__practice" aria-controls="profile" role="tab" data-toggle="tab">
            PRACTICE
          </a>
        </li>
      );
    }
  };

  return (
    <div className="lesson__content-top main-tab-options">
      <ul className="nav nav-tabs lesson__content-nav" role="tablist">
        <li role="presentation" className="active header5  uppercase">
          <a href=".lesson__content-body" aria-controls="home" role="tab" data-toggle="tab">
            {" "}
            {bodyTitle()}
          </a>
        </li>
        {hasSound() === true && (
          <li role="presentation" className="header5 uppercase">
            <a href=".lesson__content-sound" aria-controls="profile" role="tab" data-toggle="tab">
              GETTING THE SOUND
            </a>
          </li>
        )}
        {hasSheetMusic() === true && (
          <li role="presentation" className="header5 bold">
            <a href=".sheet-music" aria-controls="profile" role="tab" data-toggle="tab">
              SHEET MUSIC
            </a>
          </li>
        )}

        {hasChords() === true && (
          <li role="presentation" className="header5 bold">
            <a href=".song__details-chords" aria-controls="profile" role="tab" data-toggle="tab">
              CHORDS
            </a>
          </li>
        )}
        {hasStrums() === true && (
          <li role="presentation" className="header5 bold">
            <a href=".song__details-strum" aria-controls="profile" role="tab" data-toggle="tab">
              STRUMMING
            </a>
          </li>
        )}
        {hasDownloadables() === true && (
          <li role="presentation" className="header5 bold">
            <a
              href=".lesson__content-downloads"
              aria-controls="downloads"
              role="tab"
              data-toggle="tab"
            >
              DOWNLOADS
            </a>
          </li>
        )}
        {hasVimeoDownload() === true && (
          <li role="presentation" className="header5 bold">
            <a
              href=".lesson__vimeo-downloads"
              aria-controls="downloads"
              role="tab"
              data-toggle="tab"
            >
              VIDEO DOWNLOADS
            </a>
          </li>
        )}
        {props.item.related_songs && props.item.related_songs.length > 0 && (
          <li role="presentation" className="header5 uppercase">
            <a
              href=".lesson__content-related-songs"
              aria-controls="profile"
              role="tab"
              data-toggle="tab"
            >
              RELATED SONGS
            </a>
          </li>
        )}
        {props.item.related_lessons && props.item.related_lessons.length > 0 && (
          <li role="presentation" className="header5 uppercase">
            <a
              href=".lesson__content-related-lessons"
              aria-controls="profile"
              role="tab"
              data-toggle="tab"
            >
              RELATED LESSONS
            </a>
          </li>
        )}

        {practiceTab()}

        {props.currentUser && (
          <li role="presentation" className="header5 bold uppercase">
            <a
              href=".lesson__content-user-notepad"
              aria-controls="profile"
              role="tab"
              data-toggle="tab"
            >
              NOTEPAD
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

export default LessonContentTabOptions;
