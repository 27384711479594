import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { workshopSelectors } from "../store/workshops.slice";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { RegisterFormPaper } from "../shared/RegisterFormPaper";
import { TermsAndConditionsLink } from "../register/TermsAndConditionsLink";
import { getHeaderImage } from "../shared/helpers";

export const DepositComplete = () => {
  const workshop = useSelector(workshopSelectors.selectCurrentWorkshop);
  const marginBottom = jgDenimTheme.spacing(3);

  return (
    <RegisterFormPaper sx={{ width: "100%" }}>
      <Box display="flex" flexDirection="column" alignItems={"center"}>
        <Typography variant="h4" textAlign="center" marginBottom={marginBottom}>
          <div>Thank you for booking your place for</div>
          <div>{workshop?.attributes.title}!</div>
        </Typography>

        <Typography variant="body1" marginBottom={marginBottom}>
          I look forward to seeing you there!
        </Typography>

        <Box
          height={300}
          component="img"
          src={getHeaderImage(workshop!, "large")}
          borderRadius="10px"
          marginBottom={jgDenimTheme.spacing(3)}
        />

        <Box marginBottom={marginBottom}>
          <Button variant="contained" color="secondary" href={"/workshops"}>
            back to workshops & events
          </Button>
        </Box>

        <TermsAndConditionsLink />
      </Box>
    </RegisterFormPaper>
  );
};
