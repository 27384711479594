import React from "react";
import { connect } from "react-redux";
import { savedSongPracticeItem } from "../../components/shared/session/sessionSelectors";
import {
  createUserSongPracticeItem,
  deleteUserSongPracticeItem,
} from "../../components/shared/session/sessionEffects";

const SaveSongPracticeItem = (props) => {
  return (
    <div
      className={"song__action-btn clickable " + (props.savedItem ? "song__action-btn--saved" : "")}
      onClick={
        props.savedItem
          ? () => props.deleteItem(props.song.data.id)
          : () => props.saveItem(props.song.data.id)
      }
    >
      <i className={"icon " + (props.savedItem ? "icon-library" : "icon-playlist")} />
      {`${props.savedItem ? "Added" : "Add"}`} to practice items
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.session.currentUser,
  song: state.entity.song,
  savedItem: savedSongPracticeItem(state),
});

const mapDispatchToProps = (dispatch) => ({
  saveItem: (item) => dispatch(createUserSongPracticeItem(item)),
  deleteItem: (item) => dispatch(deleteUserSongPracticeItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveSongPracticeItem);
