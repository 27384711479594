import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { workshopSelectors } from "../store/workshops.slice";
import { WorkshopCard } from "./WorkshopCard";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { fetchWorkshops } from "../store/workshops.effects";
import { SignupCard } from "./SignupCard";
import axiosWithCSRF from "../../../components/shared/axiosWithCSRF";

const WorkshopsListing = ({ currentUser }) => {
  const [viewAll, setViewAll] = React.useState(false);
  const dispatch = useDispatch();
  const workshops = useSelector(workshopSelectors.selectAll);
  const [showSignupCard, setShowSignupCard] = React.useState(false);
  const [visibleWorkshopsLimit, setVisibleWorkshopsLimit] = React.useState(6);

  useEffect(() => {
    dispatch(fetchWorkshops());
  }, []);

  const visibleWorkshops = viewAll ? workshops : workshops.slice(0, visibleWorkshopsLimit);

  React.useEffect(() => {
    const subscriptionStatus = sessionStorage.getItem("justin-guitar-workshops");

    if (currentUser && subscriptionStatus != "false" && subscriptionStatus != "true") {
      axiosWithCSRF()
        .get("/subscription-data")
        .then((res) => {
          const isSubscribed =
            res.data.subscription_status.status === "subscribed" &&
            res.data.subscription_status.tags.find(
              (item) => item.name === "Workshops Waiting List"
            ) != null;

          sessionStorage.setItem("justin-guitar-workshops", isSubscribed.toString());

          setShowSignupCard(!isSubscribed);
          setVisibleWorkshopsLimit(!isSubscribed ? 5 : 6);
        });
    } else if (currentUser && subscriptionStatus === "true") {
      setShowSignupCard(false);
      setVisibleWorkshopsLimit(6);
    } else {
      setShowSignupCard(true);
      setVisibleWorkshopsLimit(5);
    }
  }, []);

  return (
    <Box mb={jgDenimTheme.spacing(4)}>
      <Typography variant="h4" padding={jgDenimTheme.spacing(6, 0, 4)}>
        Join Justin's Live Workshops!
      </Typography>
      <Grid
        mb={jgDenimTheme.spacing(4)}
        container
        spacing={2}
        justifyContent="center"
        alignItems="stretch"
      >
        <>
          {showSignupCard && (
            <Grid item key={0} xs={12} sm={6} lg={4}>
              <SignupCard />
            </Grid>
          )}

          {visibleWorkshops.map((workshop) => (
            <Grid item key={workshop.id} xs={12} sm={6} lg={4}>
              <WorkshopCard workshop={workshop} />
            </Grid>
          ))}
        </>
      </Grid>

      <Box display={"flex"} justifyContent={"center"} m={2}>
        <Button onClick={() => setViewAll(!viewAll)}>{viewAll ? "View Less" : "View All"}</Button>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(WorkshopsListing);
