// brightenOrDarken("#123abc", 0.2) // brighten 20%
// brightenOrDarken("#123abc", -0.2) // darken 20%
// https://www.sitepoint.com/javascript-generate-lighter-darker-color/
export function brightenOrDarken(hex: string, lum: number) {
  const isHex = hex.match(/^#(?:[0-9a-fA-F]{3}){1,2}$/) != null;

  if (!isHex) {
    return hex;
  }

  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
}
