import React from "react";

export const CloseIcon = ({ color = "#BDA38A" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
        fill={color}
      />
    </svg>
  );
};

export const EmptyChordBoxIcon = () => {
  return (
    <svg width="48" height="54" viewBox="0 0 48 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="36" width="3" height="54" rx="1.5" fill="#1D3446" />
      <rect x="27" width="3" height="54" rx="1.5" fill="#1D3446" />
      <rect x="18" width="3" height="54" rx="1.5" fill="#1D3446" />
      <rect x="9" width="3" height="54" rx="1.5" fill="#1D3446" />
      <rect width="3" height="54" rx="1.5" fill="#1D3446" />
      <rect x="45" width="3" height="54" rx="1.5" fill="#1D3446" />
      <rect width="48" height="6" fill="#1D3446" />
      <rect y="15" width="48" height="3" fill="#1D3446" />
      <rect y="27" width="48" height="3" fill="#1D3446" />
      <rect y="39" width="48" height="3" fill="#1D3446" />
    </svg>
  );
};
