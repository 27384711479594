import * as yup from "yup";
import { TransportMethod } from "./enum/transport-method";

export interface YourStayDetails {
  withPartner: string;
  specialRequests: string;
  transportType: TransportMethod;
  arrivalTime?: string;
}

export const yourStayDetailsInitialState: Partial<YourStayDetails> = {};

export const yourStayDetailsSchema = yup.object({
  withPartner: yup.string().min(1).required(),
  specialRequests: yup.string().min(1).required(),
  transportType: yup.string().oneOf(Object.keys(TransportMethod)).required(),
  arrivalTime: yup.string().min(1),
});

export const yourStayDetailsFormFieldNames = Object.keys(yourStayDetailsSchema.fields);
