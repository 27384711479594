import React from "react";
import { CDN_URL } from "../shared/CdnUrl";
import { storeSearchAndNavigateToItem } from "../shared/search";

export const ProductSearchResultItem = ({ product: { title, imageUid, slug } }) => (
  <div
    className="search-result-item search-result-item--product"
    onClick={() => storeSearchAndNavigateToItem({ title, slug })}
  >
    <img src={`${CDN_URL}/system/dragonfly/production/${imageUid}`} alt={title} />
    <div>{title}</div>
  </div>
);

export default ProductSearchResultItem;
