import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withStripe from "./withStripe";
import withStripeElements from "./withStripeElements";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { BASE_FORM_STYLE } from "../components/new-payments/styles";
import Loading from "../components/shared/Loading";
import axiosWithCSRF from "../components/shared/axiosWithCSRF";
import { openAlert } from "../components/shared/ui/uiSlice";

const UpdateCard = ({ subscriptionId, onComplete, openAlert }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [cardReady, setCardReady] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const card = elements.getElement(CardElement);

    stripe.createToken(card).then(function (result) {
      if (result.error) {
        setErrorMessage(result.error.message);
      } else {
        axiosWithCSRF()
          .patch("/payments/card", {
            subscription_id: subscriptionId,
            card_id: result.token.id,
          })
          .then(() => {
            setLoading(false);
            openAlert({ message: "Your card has been updated!", severity: "success" });
          })
          .catch((error) => {
            setLoading(false);
            openAlert({
              message: `There was a problem updating your card. ${error.response.data.message}`,
              severity: "error",
            });
          })
          .finally(() => {
            setLoading(false);
            onComplete();
          });
      }
    });
  };

  const handleCardChange = (event) => {
    if (event.error) {
      setErrorMessage(event.error.message);
      setCardReady(false);
    } else if (event.complete) {
      setCardReady(true);
    } else {
      setCardReady(false);
      setErrorMessage(null);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {loading && <Loading coverViewport />}

      <CardElement
        onChange={handleCardChange}
        options={{ hidePostalCode: true, disableLink: true, style: BASE_FORM_STYLE }}
      />

      {/* Let's move these styles somewhere else to unify them. Adding them here for now override the ones in css files...  */}

      {errorMessage && (
        <p style={{ color: "red", fontSize: "14px", margin: "10px 0" }}>{errorMessage}</p>
      )}

      <button
        className="button button--primary"
        type="submit"
        style={{ margin: "10px 0" }}
        disabled={!stripe || loading || !cardReady}
      >
        Save
      </button>
    </form>
  );
};

UpdateCard.propTypes = {
  subscriptionId: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = (_, ownProps) => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  openAlert: (alert) => dispatch(openAlert(alert)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStripe(withStripeElements(UpdateCard)));
