import { Box, Button, Typography } from "@mui/material";
import React from "react";

const ExistingSubscription = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography mb={3} variant="h4">
        You have access to Blues Immersion!
      </Typography>
      <Button
        className="button button--primary"
        sx={{ boxShadow: "none" }}
        href="/classes/blues-immersion"
      >
        Go to Course
      </Button>
    </Box>
  );
};

export default ExistingSubscription;
