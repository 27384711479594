import React from "react";
import { connect } from "react-redux";
import { setDarkMode } from "../../store/songStore";

const DarkMode = ({ darkMode, setDarkMode }) => {
  return (
    <li className="submenu-item">
      <div className="change-option">
        <div className="change-option-label">
          <span>Dark Mode</span>
        </div>
        <div className="change-option-action">
          <label className="switch">
            <input
              type="checkbox"
              defaultChecked={darkMode}
              onChange={(e) => {
                setDarkMode(e.target.checked);
              }}
            />
            <div className="slider round">
              <span className="on">ON</span>
              <span className="off">OFF</span>
            </div>
          </label>
        </div>
      </div>
    </li>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.entity.darkMode,
});

const mapDispatchToProps = (dispatch) => ({
  setDarkMode: (isOn) => dispatch(setDarkMode(isOn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DarkMode);
