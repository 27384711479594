import React from "react";

const PlayinItForwardBanner = () => {
  return (
    <>
      <div className="section banner"></div>
      <div className="banner-description">
        <h3>We're Playin' It Forward!</h3>
        <p>
          Enrolling in these lessons means you're not only chasing your guitar aspirations but also
          making a positive impact! Justin will dedicate all his profit with these lessons to{" "}
          <strong>Playin’ It Forward</strong> - a program that provides{" "}
          <strong>free guitars</strong> to those who are in need. The funds will help Justin cover
          the cost of the initial logistics and the first electric guitars we will give away.
        </p>
      </div>
      <a className="button button--primary pif-cta" href="/playin-it-forward" target="_blank">
        Playin' It Forward
      </a>
    </>
  );
};

export default PlayinItForwardBanner;
