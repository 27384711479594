import { Components } from "@mui/material";

export const accordion: Components["MuiAccordion"] = {
  styleOverrides: {
    root: {
      "&::before": {
        display: "none",
      },
    },
  },
};
