import React from "react";
import { connect } from "react-redux";

import ResultSection from "./ResultSection";
import { searchResultsTypes } from "../shared/search";

const resultsTypes = [
  searchResultsTypes.group,
  searchResultsTypes.lesson,
  searchResultsTypes.song,
  // searchResultsTypes.resource,
  // searchResultsTypes.product,
];

const SearchResults = ({ hasQuery, visibleType }) => (
  <div className="search-results">
    {hasQuery ? (
      resultsTypes.map(
        (type) =>
          (visibleType == null || visibleType === type) && <ResultSection type={type} key={type} />
      )
    ) : (
      <div style={{ paddingBottom: "100px", paddingTop: "50px" }}>
        Type in the search bar above to begin searching
      </div>
    )}
  </div>
);

export default connect(({ visibleType, query }) => ({
  visibleType,
  hasQuery: query && query.length,
}))(SearchResults);
