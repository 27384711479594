import React from "react";
import { withBootstrapSize } from "../shared/WithBootstrapSize";
import { BootstrapSize, breakpoints } from "./bootstrap-helpers";
import PropTypes from "prop-types";

const OptionDropdown = ({ options, defaultSelection, selected, onToggle }) => (
  <div className="collapsing-option-list--dropdown">
    <select value={selected || defaultSelection} onChange={(event) => onToggle(event.target.value)}>
      {options.map(({ value, label }) => {
        return (
          <option key={label} value={value}>
            {label}
          </option>
        );
      })}
    </select>
    <i className="icon icon-angle-down" />
  </div>
);

const OptionList = ({ options, defaultSelection, selected, onToggle }) => (
  <div className="collapsing-option-list">
    {options.map(({ value, label }) => {
      const currVal = selected || defaultSelection;
      const modifier = currVal === value ? "primary" : "secondary";
      return (
        <button
          key={label}
          className={`button button--${modifier}`}
          onClick={() => onToggle(value)}
        >
          {label}
        </button>
      );
    })}
  </div>
);

const CollapsingOptionList = withBootstrapSize(({ width, ...props }) =>
  width < breakpoints[BootstrapSize.lgDevice] ? (
    <OptionDropdown {...props} />
  ) : (
    <OptionList {...props} />
  )
);

CollapsingOptionList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  defaultSelection: PropTypes.string,
  selected: PropTypes.string,
  onToggle: PropTypes.func,
};

export default CollapsingOptionList;
