import React from "react";

export default class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  lineTwo = () => {
    if (this.props.textLineTwo) {
      return <p className="text-line-two">{this.props.textLineTwo}</p>;
    }
  };

  render() {
    return (
      <div
        className={
          "progress__container " + (this.props.totalProgress ? "progress__container--top" : "")
        }
      >
        <div className="text-column">
          <p className="text-line-one">{this.props.textLineOne} </p>
          {this.lineTwo()}
        </div>
        <div className="progress-column">
          {this.props.formattedDuration === "0 seconds" && <h3 className="no-margin">None</h3>}
          {this.props.formattedDuration != "0 seconds" && (
            <div>
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow={this.props.percentage}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: this.props.percentage }}
              >
                <span className="sr-only">{this.props.percentage}</span>
              </div>
              <span className="duration-text">
                <i className="icon icon icon-clock2"></i> {this.props.formattedDuration}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
