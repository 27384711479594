import React, { useState } from "react";
import { countries } from "../../utilities/countries";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import Loading from "../../components/shared/Loading";

const REFERRAL_SOURCES = [
  "JustinGuitar Newsletter (or email)",
  "JustinGuitar website",
  "Social media",
  "YouTube",
  "Suggested by a friend/family",
  "Online Forum",
  "Internet search",
  "Mentioned on a different website",
  "Other",
];

const ApplicationForm = ({ currentUser, setApplicationReceived }) => {
  const [firstName, setFirstName] = useState(currentUser.first_name);
  const [lastName, setLastName] = useState(currentUser.last_name);
  const [email, _setEmail] = useState(currentUser.email);
  const [country, setCountry] = useState("");
  const [description, setDescription] = useState("");
  const [referralSource, setReferralSource] = useState("");
  const [guitarType, setGuitarType] = useState(null);
  const [consent, setConsent] = useState(false);
  const [loading, setLoading] = useState(false);

  const [checkedItems, setCheckedItems] = useState({
    trust1: false,
    trust2: false,
    trust3: false,
  });

  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  const apply = () => {
    setLoading(true);

    axiosWithCSRF()
      .post("/playin-it-forward/apply", {
        first_name: firstName,
        last_name: lastName,
        email: email,
        country: country,
        description: description,
        referral_source: referralSource,
        guitar_type: guitarType,
      })
      .then(() => {
        setApplicationReceived(true);
      })
      .catch(() => {
        setLoading(false);
        alert("There was an error with this submission. Please try again.");
      });
  };

  const completedForm =
    !!firstName &&
    !!lastName &&
    !!email &&
    !!country &&
    !!description &&
    !!referralSource &&
    !!guitarType &&
    !!consent &&
    !!checkedItems.trust1 &&
    !!checkedItems.trust2 &&
    !!checkedItems.trust3;

  return (
    <div className="container">
      {loading && <Loading coverViewport />}
      <div className="section application-form">
        <h2>Fill in this form to apply for a free guitar!</h2>
        <div className="application-form-container">
          <p>Your contact details</p>
          <div className="input-row">
            <input
              type="text"
              placeholder="First Name"
              defaultValue={currentUser?.first_name}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Last Name"
              defaultValue={currentUser?.last_name}
              onChange={(e) => setLastName(e.target.value)}
            />
            <input type="text" placeholder="Email" defaultValue={currentUser?.email} disabled />

            <select defaultValue={""} onChange={(e) => setCountry(e.target.value)}>
              <option value={""} disabled>
                Select your country
              </option>
              {countries
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((country) => (
                  <option key={country.code} value={country.name}>
                    {country.name}
                  </option>
                ))}
            </select>
          </div>
          <p>Tell us a bit about you... and why do you want a guitar?</p>
          <div className="input-row">
            <textarea onChange={(e) => setDescription(e.target.value)} />
          </div>
          <p>How did you hear about this program?</p>
          <div className="input-row">
            <select defaultValue={""} onChange={(e) => setReferralSource(e.target.value)}>
              <option value={""} disabled>
                Select an option
              </option>
              {REFERRAL_SOURCES.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </select>
          </div>
          <p>
            Any guitar preference? <span>Please select one</span>
          </p>
          <div className="input-col">
            <div className="radio-group">
              <input
                type="radio"
                name="guitar-type"
                id="electric"
                value="electric"
                onChange={(e) => setGuitarType(e.target.value)}
                checked={guitarType === "electric"}
              />
              <label htmlFor="electric">Electric Guitar (Second-hand but refurbished)</label>
            </div>
            <div className="radio-group">
              <input
                type="radio"
                name="guitar-type"
                id="acoustic"
                value="acoustic"
                onChange={(e) => setGuitarType(e.target.value)}
                checked={guitarType === "acoustic"}
              />
              <label htmlFor="acoustic">Acoustic Guitar (New from Andertons)</label>
            </div>
            <div className="radio-group">
              <input
                type="radio"
                name="guitar-type"
                id="any"
                value="any"
                onChange={(e) => setGuitarType(e.target.value)}
                checked={guitarType === "any"}
              />
              <label htmlFor="any">I’ll be happy with any guitar. :)</label>
            </div>
          </div>
          <p>
            Remember this program relies on trust. <span>Please tick all</span>
          </p>
          <div className="input-col">
            <div className="checkbox-group">
              <input
                type="checkbox"
                name="trust1"
                id="trust-1"
                checked={checkedItems.trust1}
                onChange={handleChange}
              />
              <label htmlFor="trust-1">I confirm I cannot afford to buy a guitar.</label>
            </div>
            <div className="checkbox-group">
              <input
                type="checkbox"
                name="trust2"
                id="trust-2"
                checked={checkedItems.trust2}
                onChange={handleChange}
              />
              <label htmlFor="trust-2">
                If I realize that playing the guitar is not for me and end up not using it, I will
                contact JustinGuitar. A member of Justin's team will arrange for the guitar to be
                passed on to a new owner.
              </label>
            </div>
            <div className="checkbox-group">
              <input
                type="checkbox"
                name="trust3"
                id="trust-3"
                checked={checkedItems.trust3}
                onChange={handleChange}
              />
              <label htmlFor="trust-3">I won’t resell this guitar.</label>
            </div>
          </div>
          <p>By applying...</p>
          <div className="input-col">
            <div className="checkbox-group">
              <input
                type="checkbox"
                name="consent"
                id="consent"
                checked={consent}
                onChange={(e) => setConsent(e.target.checked)}
              />
              <label htmlFor="consent">
                I consent to Justin Guitar’s{" "}
                <a href="/terms" target="_blank">
                  Terms & Conditions
                </a>{" "}
                and to receive Justin's newsletters and email updates.
              </label>
            </div>
          </div>
          <div className="apply-btn">
            <button
              className="button button--primary apply-btn"
              onClick={apply}
              disabled={!completedForm || loading}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationForm;
