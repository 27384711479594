import React from "react";
import { connect } from "react-redux";
import { orderedLessonsWithinModule, lockedLessonIds } from "./store/lessonSelectors";
import { createLessonItem, deleteLessonItem } from "../components/shared/session/sessionEffects";
import { savedLessonIds, completedLessonIds } from "../components/shared/session/sessionSelectors";
import { openDialog } from "../components/shared/ui/uiSlice";
import Loading from "../components/shared/Loading";
import GradeBadge from "../components/shared/GradeBadge";
import LoginModal from "../components/auth/LoginModal";
import { PauseIcon } from "./PauseIcon";
import { quizAttempt } from "../quiz/store/quizSchema";
import Dialog from "../components/shared/ui/Dialog";
import { canAccessQuiz } from "../group/GroupQuizLink";

class LessonsWithinModuleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxHeight: "auto",
      loginFormOpen: false,
    };
    this.currentItemRef = React.createRef();
    this.containerRef = React.createRef();
    this.lastItemRef = React.createRef();
  }

  componentDidMount() {
    if (this.currentItemRef.current && this.containerRef.current) {
      this.scrollItemIntoView();
    }
    this.setOverflow();
    this.observeScroll();
  }

  scrollItemIntoView = () => {
    // -30 in order to show the previous item
    let toScroll = this.currentItemRef.current.offsetTop - 30;
    setTimeout(() => {
      this.containerRef.current.scroll({
        top: toScroll,
        behavior: "smooth",
      });
    }, 200);
  };

  setOverflow = () => {
    if (
      this.containerRef.current &&
      this.containerRef.current.clientHeight > this.props.maxHeight
    ) {
      this.setState({ hasOverflow: true });
    }
  };

  observeScroll = () => {
    if (
      typeof IntersectionObserver != "undefined" &&
      this.lastItemRef &&
      this.lastItemRef.current
    ) {
      const callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.setState({ hasOverflow: false });
          } else if (!this.state.hasOverflow) {
            this.setState({ hasOverflow: true });
          }
        });
      };
      const options = { threshold: 0.5 };
      const observer = new IntersectionObserver(callback, options);
      observer.observe(this.lastItemRef.current);
    }
  };

  maxHeight = (videoHeight) => {
    if (videoHeight) {
      return parseInt(videoHeight);
    } else {
      return "auto";
    }
  };

  goToLesson = (slug) => {
    window.location.href = `/guitar-lessons/${slug}`;
  };

  goToQuiz = (slug, quizAttempt, user) => {
    canAccessQuiz(user)
      ? (window.location.href = `/quizzes/${slug}${quizAttempt == null ? "" : "/results"}`)
      : this.props.openDialog({
          message: `Please make sure you are logged in and enrolled in the Practical Music Theory course to access this quiz.`,
          confirmButtonText: "enroll",
          data: user,
        });
  };

  render() {
    const {
      orderedLessons,
      lesson,
      gradeNumber,
      group,
      maxHeight,
      savedLessonIds,
      completedLessonIds,
      isLoading,
      lockedLessonIds,
      user,
      quiz = null,
      quizAttempt = null,
    } = this.props;

    return (
      <div>
        <div
          className={"lesson-list " + (this.state.hasOverflow ? "lesson-list--with-overflow" : "")}
          ref={this.containerRef}
          style={{
            maxHeight: this.props.maxHeight || "auto",
            overflowY: "auto",
            position: "relative",
          }}
        >
          {isLoading && <Loading />}
          <div className="lesson-list__container">
            {orderedLessons.map((l) => {
              const isLocked = lockedLessonIds.includes(parseInt(l.id));
              const isActive = parseInt(lesson.data.id) === l.id;
              const userHasSavedItem = savedLessonIds && savedLessonIds.includes(parseInt(l.id));
              const userHasCompletedItem =
                completedLessonIds && completedLessonIds.includes(parseInt(l.id));
              const lessonHasVideo = l.video && l.video.length > 0 ? true : false;
              const lastItem = orderedLessons.indexOf(l) === orderedLessons.length - 1;
              return (
                <div
                  className={"clickable lesson__list-item " + (isActive ? "active" : "")}
                  key={l.id}
                  ref={
                    isActive && !lastItem ? this.currentItemRef : lastItem ? this.lastItemRef : null
                  }
                >
                  {isLocked ? (
                    <i className="icon icon-lock" />
                  ) : userHasCompletedItem ? (
                    <i
                      className={
                        "icon " +
                        (lessonHasVideo ? "icon-check_circle_outline" : "icon-document-complete")
                      }
                      onClick={() =>
                        this.props.deleteLessonItem({
                          lessonId: l.id,
                          status: "completed",
                        })
                      }
                    />
                  ) : !lessonHasVideo ? (
                    <i className="icon icon-document" onClick={() => this.goToLesson(l.slug)} />
                  ) : isActive ? (
                    <i className="icon icon-video-pause"></i>
                  ) : (
                    <i className="icon icon-play" onClick={() => this.goToLesson(l.slug)}></i>
                  )}

                  <span className="list-item-title" onClick={() => this.goToLesson(l.slug)}>
                    {l.title}
                  </span>

                  <i
                    className={"icon icon-heart" + (userHasSavedItem ? "" : "-outline")}
                    style={
                      !l.youtubeDuration
                        ? {
                            flexBasis: "10%",
                            textAlign: "right",
                          }
                        : null
                    }
                    onClick={() => {
                      this.props.user && userHasSavedItem
                        ? this.props.deleteLessonItem({
                            lessonId: l.id,
                            status: "saved",
                          })
                        : this.props.user
                        ? this.props.createLessonItem({
                            lessonId: l.id,
                            status: "saved",
                          })
                        : this.setState({ loginFormOpen: true });
                    }}
                  ></i>
                  {l.youtubeDuration ? (
                    <span className="list-item-duration">{l.youtubeDuration}</span>
                  ) : null}
                </div>
              );
            })}
            {quiz && (
              <div className="clickable lesson__list-item" key={-1}>
                {quizAttempt && quizAttempt.score >= quiz.pass_percent ? (
                  <i
                    className="icon icon-document-complete"
                    onClick={() => this.goToQuiz(quiz.slug, quizAttempt, user)}
                  />
                ) : (
                  <i
                    className="icon icon-document"
                    onClick={() => this.goToQuiz(quiz.slug, quizAttempt, user)}
                  />
                )}
                <span
                  className="list-item-title quiz"
                  onClick={() => this.goToQuiz(quiz.slug, quizAttempt, user)}
                >
                  {quiz.title}
                </span>
              </div>
            )}
          </div>
        </div>

        <LoginModal
          isOpen={this.state.loginFormOpen}
          token={this.props.token}
          toggleModal={() => this.setState({ loginFormOpen: !this.state.loginFormOpen })}
        />

        <Dialog onConfirm={() => (window.location.href = "/store/practical-music-theory")} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lesson: state.entity.lesson,
  orderedLessons: orderedLessonsWithinModule(state),
  lockedLessonIds: lockedLessonIds(state),
  isLoading: state.ui.isLoading,
  user: state.session.currentUser,
  token: state.session.token,
});

const mapDispatchToProps = (dispatch) => ({
  createLessonItem: (lessonId, status) => dispatch(createLessonItem(lessonId, status)),
  deleteLessonItem: (lessonItemId, status) => dispatch(deleteLessonItem(lessonItemId, status)),
  openDialog: (text) => dispatch(openDialog(text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LessonsWithinModuleList);
