import React from "react";
import LessonBreadcrumb from "../LessonBreadcrumb";
import PropTypes from "prop-types";

const MobileContextButton = ({ currentUser, toggleMobileContextMenuVisibility }) => {
  if (!currentUser) return null;

  return (
    <button
      className="menu-btn mobile-context-menu-toggle-btn"
      onClick={() => toggleMobileContextMenuVisibility()}
    >
      <i className="icon icon-menu-dots-vertical"></i>
    </button>
  );
};

const Header = ({ currentUser, lesson, toggleMobileContextMenuVisibility }) => {
  const group = lesson.included.find((i) => i.type === "group");
  const gradeNumber = lesson.included.find((i) => i.type === "grade")?.attributes?.position;

  return (
    <div className="video-swoosh__header">
      <div>
        <div className="video-swoosh__breadcrumbs">
          <LessonBreadcrumb group={group} lesson={lesson} gradeNumber={gradeNumber} />
        </div>
        <div className="video-swoosh__title">
          <h1 className="white-header">{lesson.data.attributes.title}</h1>
          <MobileContextButton
            currentUser={currentUser}
            toggleMobileContextMenuVisibility={toggleMobileContextMenuVisibility}
          />
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  currentUser: PropTypes.object,
  lesson: PropTypes.object,
  toggleMobileContextMenuVisibility: PropTypes.func,
};

export default Header;
