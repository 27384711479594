import React from "react";

const ReceivedApplication = () => {
  return (
    <div className="container">
      <div className="section application-received">
        <h2>Thank you! We have received your application. :)</h2>
        <p>
          We receive a large number of applications, so please be patient while we review them. Once
          it's your turn, we will contact you to provide an update and request your shipping
          information.
        </p>
        <p>
          Please note that we reserve the right to ask additional questions about your application,
          reject applications without further explanations, or keep an application in progress as
          long as necessary.
        </p>
        <p>
          You can withdraw your application at any time. If you have any questions,{" "}
          <a href="/faq" target="_blank">
            please read our FAQ
          </a>
          . And while you wait for a response, why not explore some fun videos and articles to learn
          more about JustinGuitar? :)
        </p>
        <button
          className="button button--primary"
          onClick={() => (window.location.href = "/guitar-lessons")}
        >
          View All Courses
        </button>
      </div>
    </div>
  );
};

export default ReceivedApplication;
