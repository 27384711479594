import React from "react";
import {
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Provider, useSelector } from "react-redux";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { getJgStore } from "../../../redux/jg-store";
import { RegisterSidebar } from "../shared/RegisterSidebar";
import { RegistrationStep } from "../register/WorkshopRegisterPage";
import { workshopSelectors } from "../store/workshops.slice";
import { DepositComplete } from "./DepositComplete";
import { PayDeposit } from "./PayDeposit";
import { selectCurrentUser } from "../../../redux/slices/session/session.selectors";
import { workshopToPurchaseReference, workshopToRemainingFeeReference } from "./helpers";

const _WorkshopDepositPage = () => {
  const currentUser = useSelector(selectCurrentUser);
  const workshop = useSelector(workshopSelectors.selectCurrentWorkshop);

  if (workshop == null) return null;

  // todo: let's do better on the backend linking purchases to other entities
  const purchaseReference = workshopToPurchaseReference(workshop);
  const userPaidDeposit = (currentUser?.data.attributes.purchasesForDashboard || []).some(
    (purchase) => purchase.data.attributes.purchaseReference === purchaseReference
  );

  const remainingFeeReference = workshopToRemainingFeeReference(workshop);
  const userPaidRemainingFee = (currentUser?.data.attributes.purchasesForDashboard || []).some(
    (purchase) => purchase.data.attributes.purchaseReference === remainingFeeReference
  );

  const [activeStep, setActiveStep] = React.useState(
    !userPaidDeposit ? 0 : !userPaidRemainingFee ? 1 : 2
  );
  const isSmall = useMediaQuery(jgDenimTheme.breakpoints.down("sm"));

  return (
    <Container>
      {activeStep !== 2 && (
        <Stepper
          activeStep={1}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Step>
            <StepLabel>
              <Typography variant="h6" color="inherit">
                {activeStep == 0 ? "Deposit" : "Remaining fee"}
              </Typography>
            </StepLabel>
          </Step>
        </Stepper>
      )}

      <Grid
        container
        marginTop={jgDenimTheme.spacing(2)}
        marginBottom={8}
        flexDirection={isSmall ? "column" : "row"}
      >
        {activeStep === 2 ? (
          <DepositComplete />
        ) : activeStep === 1 ? (
          <>
            <Grid item xs={12} sm={4} display={activeStep < 2 ? "unset" : "none"}>
              <RegisterSidebar workshop={workshop} currentUser={currentUser} />
            </Grid>
            <Grid item xs={12} sm={activeStep < 2 ? 8 : 12}>
              <PayDeposit
                onComplete={() => setActiveStep(2)}
                currentUser={currentUser}
                activeStep={activeStep}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={4} display={activeStep < 2 ? "unset" : "none"}>
              <RegisterSidebar workshop={workshop} currentUser={currentUser} />
            </Grid>
            <Grid item xs={12} sm={activeStep < 2 ? 8 : 12}>
              <PayDeposit
                onComplete={() => setActiveStep(2)}
                currentUser={currentUser}
                activeStep={activeStep}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export const WorkshopDepositPage = () => (
  <Provider store={getJgStore()}>
    <ThemeProvider theme={jgDenimTheme}>
      <_WorkshopDepositPage />
    </ThemeProvider>
  </Provider>
);
