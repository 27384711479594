import React from "react";

import { PreventScroll } from "../../shared";
import { filters } from "../filters/filters";
import { withBootstrapSize } from "../../shared/WithBootstrapSize";
import { BootstrapSize, breakpoints } from "../../shared/bootstrap-helpers";
import FiltersOpenCloseButton from "../filters/FiltersOpenCloseButton";

const SongsFiltersButton = ({ width, bootstrapSize, ...props }) => (
  <PreventScroll
    shouldPreventScroll={() =>
      props.filtersOpen && width < breakpoints[BootstrapSize[bootstrapSize]]
    }
  >
    {props.filtersOpen && <div className="overlay" onClick={props.closeFilters} />}
    <FiltersOpenCloseButton {...props} availableFilters={filters} />
  </PreventScroll>
);

export default withBootstrapSize(SongsFiltersButton);
