import React, { useState, useRef } from "react";
import debounce from "lodash.debounce";
import { CloseIcon } from "./Icons";
import { ChordSearchResults } from "./ChordSearchResults";
import { ChordContentGroup } from "./ChordContentGroup";
import "../../components/shared/polyfills/replaceAll.js";

const Library = ({
  data,
  setData,
  chordGroupsByRootNote,
  chordGroupsByCategory,
  chordsWithoutVariants,
  bootstrapSize,
}) => {
  const searchRef = useRef(null);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [showNotification, setShowNotification] = useState(
    !localStorage.getItem("hide-chord-notification")
  );

  const closeNotification = () => {
    localStorage.setItem("hide-chord-notification", "1");
    setShowNotification(false);
  };

  const search = (e) => {
    setSearchPhrase(e.target.value);
  };

  const clearSearch = () => {
    searchRef.current.value = "";
    setSearchPhrase("");
  };

  const setFilter = (name) => {
    setData({
      activeType: data.activeType,
      activeChord: null,
      activeSection: name.toLowerCase().replaceAll(" ", "-"),
    });

    window.location.hash = name.toLowerCase().replaceAll(" ", "-");
  };

  const chordGroups = data.activeType === "notes" ? chordGroupsByRootNote : chordGroupsByCategory;

  return (
    <div className="library">
      <div className="chords__header">
        <h1 className="header1">chord library</h1>
        <div className="chords__header__search">
          <i className="icon icon-search-thin"></i>
          <input
            type="text"
            placeholder="Search chords"
            ref={searchRef}
            onChange={debounce(search, 250)}
            maxLength={10}
            autoFocus
          />
          {searchPhrase && <i className="icon icon-cross" onClick={() => clearSearch()}></i>}
        </div>
      </div>
      {showNotification && (
        <div className="chords__notification">
          Please note this Chord Library is constantly updated and will start with the most common
          chords.
          <button onClick={() => closeNotification()}>
            <CloseIcon />
          </button>
        </div>
      )}
      <div className="chords__body">
        <div className="chords__nav">
          <h3>filter chords by</h3>
          <div className="chords__nav__type">
            <button
              onClick={() => {
                setData({
                  activeType: "notes",
                  activeChord: null,
                  activeSection: chordGroupsByRootNote[0].name.toLowerCase().replaceAll(" ", "-"),
                });
                window.location.hash = chordGroupsByRootNote[0].name
                  .toLowerCase()
                  .replace(/\s/g, " ");
              }}
              className={data.activeType === "notes" ? "active" : ""}
            >
              notes
            </button>
            <button
              onClick={() => {
                setData({
                  activeType: "categories",
                  activeChord: null,
                  activeSection: chordGroupsByCategory[0].name.toLowerCase().replaceAll(" ", "-"),
                });
                window.location.hash = chordGroupsByCategory[0].name
                  .toLowerCase()
                  .replace(/\s/g, " ");
              }}
              className={data.activeType === "categories" ? "active" : ""}
            >
              categories
            </button>
          </div>
          {!["xsDevice", "smDevice", "mdDevice"].includes(bootstrapSize) ? (
            <div className="chords__nav__groups">
              {chordGroups.map((chordGroup, idx) => (
                <button
                  key={idx}
                  className={
                    data.activeSection === chordGroup.name.toLowerCase().replaceAll(" ", "-")
                      ? "active"
                      : ""
                  }
                  onClick={() => setFilter(chordGroup.name)}
                >
                  {`${chordGroup.name} chords`}
                </button>
              ))}
            </div>
          ) : (
            <select value={data.activeSection} onChange={(e) => setFilter(e.target.value)}>
              {chordGroups.map((chordGroup, idx) => (
                <option key={idx} value={chordGroup.name.toLowerCase()}>
                  {`${chordGroup.name} chords`}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="chords__content">
          {!searchPhrase ? (
            !["xsDevice", "smDevice", "mdDevice"].includes(bootstrapSize) ? (
              chordGroups.map((chordGroup, idx) => (
                <ChordContentGroup key={idx} chordGroup={chordGroup} />
              ))
            ) : (
              <ChordContentGroup
                chordGroup={chordGroups.find(
                  (chordGroup) =>
                    chordGroup.name.toLowerCase().replaceAll(" ", "-") === data.activeSection
                )}
              />
            )
          ) : (
            <ChordSearchResults
              chordsWithoutVariants={chordsWithoutVariants}
              searchPhrase={searchPhrase}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Library;
