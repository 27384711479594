import React, { useState } from "react";
import { CDN_URL } from "../../shared/CdnUrl";
import RegistrationForm from "./RegistrationForm";
import WhyRegister from "./WhyRegister";
import { Box, Container, ThemeProvider, Typography } from "@mui/material";
import { LOGO } from "../../header/LargeHeader";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import AppDisclaimer from "./AppDisclaimer";
import RegistrationSuccess from "./RegistrationSuccess";

const BACKGROUND_IMG = `url(${CDN_URL}/redesign/2024/sign-up-background.jpg)`;

export const SECTIONS = {
  app: "app",
  form: "form",
  success: "success",
};

const SectionContent = ({ activeSection, setActiveSection }) => {
  switch (activeSection) {
    case SECTIONS.app:
      return <AppDisclaimer setActiveSection={setActiveSection} />;
    case SECTIONS.form:
      return <RegistrationForm setActiveSection={setActiveSection} />;
    case SECTIONS.success:
      return <RegistrationSuccess />;
  }
};

const SectionHeader = ({ activeSection }) => {
  const header = (activeSection) => {
    switch (activeSection) {
      case SECTIONS.app:
        return "Coming from my app?";
      case SECTIONS.form:
        return "Create an account";
      case SECTIONS.success:
        return "";
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img style={{ maxWidth: "150px" }} src={LOGO} alt="JustinGuitar" />
      </Box>
      <Typography
        component="h1"
        variant="h8"
        sx={{
          mt: 3,
          mb: 2,
          fontSize: "22px",
          textTransform: "none",
          letterSpacing: 0,
          fontWeight: "bold",
        }}
      >
        {header(activeSection)}
      </Typography>
    </>
  );
};

const NewRegistration = () => {
  const [activeSection, setActiveSection] = useState(SECTIONS.form);

  return (
    <ThemeProvider theme={jgDenimTheme}>
      <div
        style={{
          backgroundImage: BACKGROUND_IMG,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100vw",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "3rem 1rem",
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{ background: "white", borderRadius: "20px" }}
        >
          <Box
            my={4}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SectionHeader activeSection={activeSection} />
            <SectionContent activeSection={activeSection} setActiveSection={setActiveSection} />
          </Box>
        </Container>
        <WhyRegister />
      </div>
    </ThemeProvider>
  );
};

export default (props) => <NewRegistration {...props} />;
