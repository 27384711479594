import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { CDN_URL } from "../../components/shared/CdnUrl";

const defaultHeaderImages = {
  teacher: {
    [BootstrapSize.smDevice]: `${CDN_URL}/redesign/about/about-teacher-lg.jpg`,
    [BootstrapSize.xsDevice]: `${CDN_URL}/redesign/about/about-teacher-sm.png`,
  },
  musician: {
    [BootstrapSize.smDevice]: `${CDN_URL}/redesign/about/about-musician-lg.jpg`,
    [BootstrapSize.xsDevice]: `${CDN_URL}/redesign/about/about-musician-sm.png`,
  },
};

const AboutSwoosh = ({ changePage, currentPage }) => {
  return (
    <div className="about-swoosh">
      <Swoosh imgUrl={defaultHeaderImages[currentPage]} id="about">
        <div className="about-swoosh__content">
          <div className="about-swoosh__content__description">
            <h1 className="header1">about me</h1>
            <p>
              I've spent decades with a guitar in my hands - teaching, touring, recording, and
              everything in between! My mission is to make music and teach the world to play.
            </p>
          </div>
          <div className="about-swoosh__content__nav">
            <div className="about-swoosh__content__nav__pills">
              <button
                onClick={() => changePage("teacher")}
                className={
                  "about-swoosh__content__nav__pills__button" +
                  (currentPage === "teacher"
                    ? " about-swoosh__content__nav__pills__button--active-teacher"
                    : "")
                }
              >
                teacher
              </button>
              <button
                onClick={() => changePage("musician")}
                className={
                  "about-swoosh__content__nav__pills__button" +
                  (currentPage === "musician"
                    ? " about-swoosh__content__nav__pills__button--active-musician"
                    : "")
                }
              >
                musician
              </button>
            </div>
          </div>
        </div>
      </Swoosh>
    </div>
  );
};

export default AboutSwoosh;
