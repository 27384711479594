import React from "react";

const QuizLink = ({ quiz, quizAttempt, currentUser, toggleLoginModal }) => {
  if (!quiz) return null;

  function goToQuiz() {
    if (!currentUser) {
      toggleLoginModal();
    } else {
      window.location.href = `/quizzes/${quiz.slug}${quizAttempt == null ? "" : "/results"}`;
    }
  }

  const icon =
    quizAttempt && quizAttempt.attributes?.scorePercent >= quiz.passPercent
      ? "document-complete"
      : "document";

  return (
    <div className="clickable lesson__list-item" key={`quiz-${quiz.id}`}>
      <i className={`icon icon-${icon}`} onClick={goToQuiz} />

      <span className="list-item-title quiz" onClick={goToQuiz}>
        {quiz.title}
      </span>
    </div>
  );
};

export default QuizLink;
