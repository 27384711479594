import React from "react";
import axiosWithCSRF from "../../../shared/axiosWithCSRF";

export default class CopyItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  copyItem = (item) => {
    axiosWithCSRF()
      .post(`/user_practice_items/copy_item/${item.id}`)
      .then((res) => {
        this.props.handleCopiedItem(res.data.item);
      })
      .catch((error) => {
        this.props.handleCopiedItem(null);
      });
  };

  render() {
    let { item } = this.props;
    return (
      <div className="practice-buttons__container">
        <button
          className="practice__button practice__button--save"
          onClick={() => this.copyItem(item)}
        >
          copy item
        </button>
        <button
          className="practice__button practice__button--cancel"
          onClick={this.props.closeForm}
        >
          cancel
        </button>
      </div>
    );
  }
}
