import React from "react";
import { connect } from "react-redux";
import { note } from "../store/songSelectors";
import UserNote from "../../components/notes/UserNote";

const SongNote = ({ currentUser, note, song, token }) => {
  return (
    <UserNote
      user={currentUser}
      note={note}
      noteableType={"Song"}
      noteableId={song.data.attributes.id}
      token={token}
    />
  );
};

const mapStateToProps = (state) => ({
  note: note(state),
  song: state.entity.song,
  currentUser: state.session.currentUser,
  token: state.session.token,
});

export default connect(mapStateToProps)(SongNote);
