import React from "react";
import { connect } from "react-redux";
import { setActiveTab } from "../store/songIndex";
import SongInformation from "./SongInformation";
import SheetMusicContainer from "../sheetmusic/SheetMusicContainer";
import SongNote from "./SongNote";
import Performances from "./Performances";
import RelatedSongs from "./toggleSections/RelatedSongs";
import Chords from "./toggleSections/Chords";
import GettingTheSound from "./toggleSections/GettingTheSound";
import { withInnerWidth } from "../../components/shared/WithInnerWidth";
import Discourse from "../../discourse/Discourse";

const TABS = [
  { name: "Learn More", icon: "book-open", mobileName: "Learn" },
  { name: "Tab", icon: "music-note" },
  { name: "Performances", icon: "performances", mobileIcon: "performances-mobile" },
  { name: "Discussion", icon: "bubble", mobileName: "Discussion" },
  { name: "Notes", icon: "pencil" },
];

const TabOptions = ({ activeTab, setActiveTab, width }) => {
  return (
    <div className="tabz__options">
      {TABS.map((tab) => {
        return (
          <button
            className={activeTab === tab.name ? "active" : ""}
            key={tab.name}
            onClick={() => {
              setActiveTab(tab.name);
              window.location.hash = `#${tab.name.toLowerCase().replace(" ", "-")}`;
            }}
          >
            <i
              className={`icon icon-${
                width <= 576 && tab.mobileIcon && activeTab === tab.name ? tab.mobileIcon : tab.icon
              }`}
            />
            {width <= 576 && tab.mobileName ? (
              <span>{tab.mobileName}</span>
            ) : (
              <span>{tab.name}</span>
            )}
          </button>
        );
      })}
    </div>
  );
};

const TabContent = ({ activeTab, song, currentUser }) => {
  switch (activeTab) {
    case "Learn More":
      return <SongInformation />;
    case "Tab":
      return <SheetMusicContainer />;
    case "Performances":
      return <Performances />;
    case "Discussion":
      return (
        <Discourse
          topicId={song.data.attributes.discourseTopicId}
          currentUser={currentUser}
          objectType={"Song"}
          objectId={song.data.id}
        />
      );
    case "Notes":
      return <SongNote />;
    default:
      return <SongInformation />;
  }
};

const TabExtras = ({ activeTab, width }) => {
  if (activeTab === "Learn More") {
    return (
      <>
        <Chords width={width} />
        <RelatedSongs width={width} />
        <GettingTheSound width={width} />
      </>
    );
  } else {
    return null;
  }
};

const TabContainer = ({ song, activeTab, setActiveTab, width, currentUser }) => {
  return (
    <div className="tabz">
      <TabOptions activeTab={activeTab} setActiveTab={setActiveTab} width={width} />
      <div className={`tabz__content tabz__content--${activeTab}`}>
        <TabContent activeTab={activeTab} song={song} currentUser={currentUser} />
      </div>

      <div className="tabz__extra">
        <TabExtras activeTab={activeTab} width={width} />
      </div>
    </div>
  );
};

const TabsWithWidth = withInnerWidth(TabContainer);

const mapStateToProps = (state, ownProps) => ({
  activeTab: state.entity.activeTab,
  song: state.entity.song,
  width: ownProps.width,
  currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabsWithWidth);
