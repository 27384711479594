import React from "react";
import PropTypes from "prop-types";
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from "./Icons";
import { PLAN_TYPES } from "../../subscriptions/consts";
import { Box } from "@mui/material";

const LIFETIME_DESCRIPTION = `<p><strong>💡 Why do we recommend the Lifetime Access?</strong></p>
<p>
  Music Theory is a long-lasting adventure. Choosing the Lifetime Access is a
  commitment that pays off in the long run! With the vast and comprehensive content
  of the course, the Lifetime option provides a timeless resource for guitar players
  dedicated to mastering their fretboard. Learning how music works is not just a
  one-month sprint; it's a lifelong journey of growth and skill development!
</p>
<p>
  By opting for Lifetime Access, you get uninterrupted access to the Practical Music
  Theory Course and all its perks, including Justin's Live Classes for as long as he
  runs them! Lifetime Access also allows you to learn, practice, and evolve at your
  own pace without worrying about recurring monthly payments. :) You'll also support JustinGuitar.com and initiatives like <a href="/playin-it-forward" target="_blank">Playin' it Forward</a>, so we thank you in advance. :)
</p>`;

const PlanBadge = ({ plan, upgrade }) => {
  const { intervalInMonths, planType, withinPeriodDiscount } = plan.attributes;

  function badgeContent() {
    if (upgrade && !withinPeriodDiscount) {
      return `Upgrade to ${planType === "lifetime" ? "Lifetime" : "Yearly"}`;
    } else if (planType === "lifetime") {
      return "recommended";
    } else if (intervalInMonths === 1) {
      return "monthly";
    } else if (intervalInMonths === 12) {
      return "yearly";
    }
  }

  return <div className="badge">{badgeContent()}</div>;
};

export const PlanDetails = ({ plan, upgrade, existing = false }) => {
  const { price, planType, intervalInMonths, upgradeDiscount, withinPeriodDiscount } =
    plan.attributes;
  const effectivePrice = withinPeriodDiscount
    ? withinPeriodDiscount
    : upgrade
    ? upgradeDiscount
    : price;

  function planTitle() {
    if (planType === "lifetime") {
      return "Lifetime Access";
    } else if (intervalInMonths === 1) {
      return "Monthly Subscription";
    } else if (intervalInMonths === 12) {
      return "Yearly Subscription";
    }
  }

  function planDescription() {
    const priceToDisplay = existing ? price : effectivePrice;
    if (planType === PLAN_TYPES.lifetime) {
      return `${upgrade ? "" : LIFETIME_DESCRIPTION}<p>USD ${priceToDisplay} billed once.</p>`;
    } else if (intervalInMonths === 1) {
      return `$${priceToDisplay} billed monthly.<br />Cancel anytime.`;
    } else if (intervalInMonths === 12 && upgrade) {
      return `Discount applied for the first year only. Afterwards, you will be billed at the regular price. Cancel anytime.`;
    } else if (intervalInMonths === 12) {
      return `Billed annually.<br />Equivalent to $${(priceToDisplay / 12).toFixed(2)} monthly`;
    }
  }

  function displayPrice() {
    if (existing) {
      return <p className="plan-price">${price}</p>;
    } else if (withinPeriodDiscount || upgrade) {
      return (
        <div
          className="plan-discount"
          style={withinPeriodDiscount ? { flexDirection: "column" } : {}}
        >
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <p className="strikethrough">${price}</p>
            {withinPeriodDiscount && <p className="limited-discount">Limited-Time Only!</p>}
          </Box>
          <p className="plan-price">${effectivePrice}</p>
        </div>
      );
    } else {
      return <p className="plan-price">${effectivePrice}</p>;
    }
  }

  return (
    <>
      <p className="plan-title">{planTitle()}</p>
      {displayPrice()}
      <p
        className="plan-description"
        dangerouslySetInnerHTML={{
          __html: planDescription(),
        }}
      ></p>
    </>
  );
};

const Plan = ({ activePlan, setActivePlan, currentUser, plan, upgrade }) => {
  const { id } = plan;
  const { planType, withinPeriodDiscount } = plan.attributes;

  return (
    <button
      className={`plan ${activePlan?.id === id ? "active" : ""} ${planType} ${
        upgrade ? "upgrade" : ""
      } ${withinPeriodDiscount && planType != PLAN_TYPES.lifetime ? "plan--with-discount" : ""}`}
      onClick={() => (currentUser ? setActivePlan(plan) : null)}
      style={{ cursor: !activePlan ? "default" : null }}
    >
      {activePlan?.id === id ? <CheckboxCheckedIcon /> : currentUser && <CheckboxUncheckedIcon />}

      <PlanBadge plan={plan} upgrade={upgrade} />
      <PlanDetails plan={plan} upgrade={upgrade} />
    </button>
  );
};

Plan.propTypes = {
  activePlan: PropTypes.object,
  upgrade: PropTypes.bool,
  setActivePlan: PropTypes.func,
  currentUser: PropTypes.object,
  plan: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      planType: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Plan;
