import React from "react";

function icons(oldBeats, beats) {
  let str = "";
  let totalBeats = oldBeats + beats;
  if (totalBeats >= 16) {
    totalBeats = totalBeats % 16;
  }
  for (var i = 0; i < totalBeats; i++) {
    str += '<span class="icon icon-circle"></span>';
  }
  return { __html: str };
}

const BpmBeatIcons = ({ oldBeats, beats }) => {
  return (
    <div className="beat-icons">
      <div
        className="header2 justin-blue beat-icons__container"
        dangerouslySetInnerHTML={icons(oldBeats, beats)}
      ></div>
    </div>
  );
};

export default BpmBeatIcons;
