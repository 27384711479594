import React from "react";
import PropTypes from "prop-types";

import { searchResultsTypeToSlugPrefix, storeSearchAndNavigateToItem } from "../shared/search";
import { removeHtmlTagsAndUnescape } from "../shared/removeHtmlTagsAndUnescape";

export const SearchResultItem = ({ item, query, type }) => {
  const { title, gradeId } = item;
  const wordMatches = query.toLowerCase().trim().split(" ");
  const regex = new RegExp(wordMatches.join("|"), "gi");
  const updated = title.replace(regex, (matched) => `<span class='bold'>${matched}</span>`);
  const subtitle = item.meta_desc
    ? removeHtmlTagsAndUnescape(item.meta_desc)
    : item.body
    ? removeHtmlTagsAndUnescape(item.body)
    : null;

  return (
    <div className="search-result-item search-result-item--standard">
      <div
        className="search-result-item__title-section"
        onClick={() =>
          storeSearchAndNavigateToItem({
            title: item.title,
            slug: `${searchResultsTypeToSlugPrefix[type]}/${item.slug}`,
          })
        }
      >
        <span className="title" dangerouslySetInnerHTML={{ __html: updated }} />
        {gradeId != null && (
          <span className={`grade-badge grade-badge--grade-${gradeId}`}>
            {parseInt(gradeId) === 10 ? "All Levels" : `Grade ${gradeId}`}
          </span>
        )}
      </div>
      <div className="search-result-item__subtitle">{subtitle}</div>
    </div>
  );
};

SearchResultItem.propTypes = {
  query: PropTypes.string.isRequired,
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default SearchResultItem;
