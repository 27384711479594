import React from "react";
import { VerifiedIcon } from "./Icons";

const CourseDifference = () => {
  return (
    <div className="section">
      <h2 className="title">how is this course different?</h2>
      <div className="verified-badges">
        <div>
          <VerifiedIcon />
          Progress at your own pace with bingeable lessons and a clear learning path!
        </div>
        <div>
          <VerifiedIcon />
          Test yourself with Interactive Quizzes so you know when to move on!
        </div>
        <div>
          <VerifiedIcon />
          Get Community Support, additional resources, exclusive tips, and much more!
        </div>
      </div>
      <div className="zoom-classes">
        <p>But that’s not all, you’ll also be invited to...</p>
        <div className="zoom-classes-banner">
          <div className="zoom-classes-new"></div>
          <div className="justin-zoom-classes"></div>
          <p>Exclusive Live Classes on Zoom!</p>
          <p>
            Join Justin once a month for an exclusive live class based on your questions. Every
            second Tuesday of the month from 20:00 to 21:00 (London Time.)
          </p>
          <p
            onClick={() => {
              window.location.href = "/live-events/music-theory";
            }}
          >
            Read more. :)
          </p>
        </div>
      </div>
    </div>
  );
};
export default CourseDifference;
