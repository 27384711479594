import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import * as bootstrapHelpers from "../shared/bootstrap-helpers";
import SongsGridItem, { SongDetails, SongImg } from "./songs-grid/SongsGridItem";
import Stack from "../shared/Stack";
import { withBootstrapSize } from "../shared/WithBootstrapSize";
import axiosWithCSRF from "../shared/axiosWithCSRF";
import Loading from "../shared/Loading";
import SongActionButtons from "./songs-grid/SongActionButtons";

const slickSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.xlDevice],
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.lgDevice],
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.mdDevice],
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.smDevice],
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const LatestReleasesSlick = ({ songs, width }) => (
  <Slider {...slickSettings}>
    {songs.map((song) => (
      <div key={song.id}>
        <SongsGridItem song={song.attributes} key={song.id} />
        {width < bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.mdDevice] && (
          <SongActionButtons song={song.attributes} mobileView={true} />
        )}
      </div>
    ))}
  </Slider>
);

export const LatestReleasesStack = ({ songs }) => {
  const [topSong, setTopSong] = useState(songs[0].attributes);
  return (
    <>
      <Stack onChange={(i) => setTopSong(songs[i].attributes)}>
        {songs.map((song) => (
          <SongImg song={song.attributes} key={song.id} />
        ))}
      </Stack>
      <SongDetails song={topSong} />
      <SongActionButtons song={topSong} />
    </>
  );
};

const LatestReleases = ({ width }) => {
  const [songs, setSongs] = useState(null);

  useEffect(() => {
    axiosWithCSRF()
      .get("/latest-songs.json")
      .then((response) => {
        setSongs(response.data.songs.data);
      });
  }, []);

  return (
    <div className="latest-releases">
      <h2 className="header2">Latest Releases</h2>
      {songs ? (
        <LatestReleasesSlick songs={songs} width={width} />
      ) : (
        <span style={{ position: "relative" }}>
          <Loading isContent={true} />
        </span>
      )}
    </div>
  );
};

export default withBootstrapSize(LatestReleases);
