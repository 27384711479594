import React from "react";
import { ArrowIcon } from "./PlaygroundRoot";
import { PLACEHOLDER_THUMB } from "../../utilities/thumb";

const AnimatedCard = ({ data }) => {
  const { title, image, label, lessonOrder, slug } = data.attributes;
  return (
    <div className="card-animated" onClick={() => (window.location.href = `/playground/${slug}`)}>
      <div
        className="background"
        style={{ backgroundImage: `url(${image?.length ? image : PLACEHOLDER_THUMB})` }}
      ></div>
      <div className="details">
        <h2>{title}</h2>
        <div>
          <p>
            {lessonOrder.length} {label?.length ? label : "Lessons"}
          </p>
          <button>
            explore <ArrowIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AnimatedCard;
