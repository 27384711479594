import { Components } from "@mui/material";

const fullHeight = "translate(20px, 14px) scale(1)";
const shrink = "translate(14px, -5px) scale(0.75)";

// need to change label placement since we changed input font size, line height, and padding
export const inputLabel: Components["MuiInputLabel"] = {
  styleOverrides: {
    root: {
      transform: fullHeight,
    },
    shrink: {
      transform: shrink,
    },
  },
  defaultProps: {},
};
