import React from "react";

import { currencyTypes, donationTypes } from "./consts";

export const defaultDonationContextState = {
  currency: currencyTypes.USD,
  currentTab: donationTypes.MONTHLY,
  donationSuccess: false,
};

export const DonationContext = React.createContext({
  ...defaultDonationContextState,
});

export const withContext = (Component) => (props) =>
  (
    <DonationContext.Consumer>
      {(context) => <Component {...props} {...context} />}
    </DonationContext.Consumer>
  );
