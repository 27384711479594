import React from "react";
import SupportTeamSwoosh from "./SupportTeamSwoosh";
import { CDN_URL } from "../../components/shared/CdnUrl";
import SupportTeamCard from "./SupportTeamCard";

const IMG_PATH = `${CDN_URL}/images/support-team`;

const supportTeamCards = [
  {
    imageUrl: `${IMG_PATH}/Laryne.png`,
    name: "Laryne",
    position: "COO",
    description:
      "Laryne started learning guitar during the first lockdown and kept emailing me until I gave her a gig. :) She’s now full-time and helps me out with all my social media, website stuff, and organizing everything. Invaluable!",
  },
  {
    imageUrl: `${IMG_PATH}/Ben.png`,
    name: "Ben",
    position: "Software Engineer",
    description:
      "Ben was my first full-time employee and the lead developer for the website from 2018 to early 2022. He took a break and returned to the team in late 2023—coding the site and helping us improve our projects and make sense of our development ideas!",
  },
  {
    imageUrl: `${IMG_PATH}/Barbara.png`,
    name: "Barbara",
    position: "Designer",
    description:
      "Barbara was the lead designer for the 2020 website revamp, but I managed to snaffle her away from her agency gig and got her full-time. She’s why stuff looks awesome!",
  },
  {
    imageUrl: `${IMG_PATH}/Fanny.png`,
    name: "Fanny",
    position: "Student Support Specialist",
    description:
      "Fanny rocks as our go-to person for student questions! She's super friendly and quick to respond—and just like us, she loves music and guitar! Fan ensures our customer service is top-notch and that you're having a blast in our community-based activities, like Clubs!",
  },
  {
    imageUrl: `${IMG_PATH}/Lieven.png`,
    name: "Lieven",
    position: "Community Guides",
    description:
      "Lieven started as a forum moderator back in 2006, and he’s still around! :) He helps me out with the JustinGuitar Community and is one of the Official Guides that answer comments around! Check out his rock band Point Fifty!",
  },
  {
    imageUrl: `${IMG_PATH}/Richard.png`,
    name: "Richard",
    position: "Community Guides",
    description:
      "Richard is another long-standing forum moderator who is now an Official Guide and helps me make sure all students make the best out of my courses. He’s also an ex-classroom teacher teaching Math and Guitar!",
  },
  {
    imageUrl: `${IMG_PATH}/David.png`,
    name: "David",
    position: "Community Guides",
    description:
      "David joined JustinGuitar as a student in 2016, and he says he's achieved more on his guitar journey than in his wildest dreams! He became a Guide in 2022 and has been incredible helping students in the Community.",
  },
  {
    imageUrl: `${IMG_PATH}/danica.png`,
    name: "Danica",
    position: "Student Support Assistant",
    description:
      "Danica's got your back when it comes to emails! If you're reaching out to JustinGuitar, chances are she'll be the one you'll chat with first. She does her best responding to your requests and ensuring you get the support you need.",
  },
  {
    imageUrl: `${IMG_PATH}/Adam.png`,
    name: "Adam",
    position: "Studio Assistant",
    description:
      "Adam helps me out in the real world at the studio! He’s been around for a couple of years now and helps out with production stuff, recording when I have guests, some video editing rough cuts, and backing tracks!",
  },
  {
    imageUrl: `${IMG_PATH}/Paula-v2.png`,
    name: "Paula",
    position: "app support",
    description:
      "Paula is part of the Musopia team, the people behind the JustinGuitar Lessons & Songs app tech! :) She helps me ensure that all students have their best experience when learning with my app.",
  },
  {
    imageUrl: `${IMG_PATH}/Adam-Leafcutter.png`,
    name: "Adam",
    position: "App support",
    description:
      "Adam takes care of most of my apps, including the Time Trainer Metronome, Note Trainer, Blues Licks and more! He works at Leafcutter Studios and helps me ensure the apps are the best they can be.",
  },
  {
    imageUrl: `${IMG_PATH}/Jon-v2.png`,
    name: "Jon",
    position: "Music Engraver",
    description:
      "Jon is a Notation Expert and helps me a lot with the JustinGuitar Tabs. He writes the chord charts and tabs for my song lessons so you can have accurate music to follow along with my tutorials!",
  },
  {
    imageUrl: `${IMG_PATH}/Jeffery.png`,
    name: "Jeff",
    position: "Editor",
    description:
      "Jeffrey works as an editor for sheet music giant Hal Leonard - based in Milwaukee, WI. He just finished editing my first book for HL and did a great job.",
  },
  {
    imageUrl: `${IMG_PATH}/Cas.png`,
    name: "Cass",
    position: "UX Researcher",
    description:
      "Cassandra was the User Experience Researcher responsible for the latest website redesign. She talked to many students to make sure we’re delivering the best experience possible here! She’s a passionate vegan cat-lady. :)",
  },
  {
    imageUrl: `${IMG_PATH}/Pete-v2.png`,
    name: "Pete",
    position: "Workshop Teacher",
    description:
      "Pete is my co-teacher for many of my workshops. We first met when he was teaching me at The Guitar Institute in ‘96 and we played in The Counterfeit Stones for many years! Great player and awesome teacher!",
  },
  {
    imageUrl: `${IMG_PATH}/Dave-v2.png`,
    name: "Dave",
    position: "Music Producer",
    description:
      "I’ve been making Jam Tracks with Dave for many years! He’s a world class bassist, but also an incredible drummer, guitarist and producer! And he’s a maniac. :)",
  },
];

export default class SupportTeam extends React.Component {
  render() {
    return (
      <div className="support-team">
        <SupportTeamSwoosh />
        <h2 className="uppercase header2 text-center">Meet the Team</h2>
        <div className="support-team__intro">
          <p>
            Over the last couple of years, JustinGuitar has grown from me trying to do and control
            almost everything myself to having an incredible team of people worldwide! They help me
            run and maintain the ecosystem, which means I have more time to work on the courses. I
            appreciate their support so much! :)
          </p>
        </div>
        <div className="support-team__body">
          {supportTeamCards.map((card, idx) => (
            <SupportTeamCard key={idx} data={card} />
          ))}
        </div>
      </div>
    );
  }
}
