import axiosWithCSRF from "../../shared/axiosWithCSRF";

export const savePracticeItem = (songId) =>
  axiosWithCSRF().post(`/user_practice_items/song_practice_items.json`, {
    user_practice_item: {
      song_id: songId,
    },
  });

export const deletePracticeItem = (userPracticeItemId) =>
  axiosWithCSRF().delete(`/user_practice_items/song_practice_items/${userPracticeItemId}.json`);

export const deleteSongItem = (userSongItemId) =>
  axiosWithCSRF().delete(`/user_items/songs/${userSongItemId}.json`);

export const saveSongItem = (songId) =>
  axiosWithCSRF().post(`/user_items/songs.json`, {
    user_item: {
      itemable_id: songId,
    },
  });

export const fetchUserVotes = () => axiosWithCSRF().get("/song_request_votes.json");

export const submitVote = (songRequestId) =>
  axiosWithCSRF().post(`/song_requests/${songRequestId}/vote.json`);

export const deleteVote = (songRequestId) =>
  axiosWithCSRF().delete(`/song_requests/${songRequestId}/vote.json`);

export const fetchSongRequests = (offset) => {
  return axiosWithCSRF().get(
    `/song_requests.json?scope=for_user&ordering_column=created_at&offset=${offset}`
  );
};

export const submitSongRequest = (title, artist, force) =>
  axiosWithCSRF().post(`/song_requests`, {
    song_request: {
      title: title,
      artist: artist,
      force: force,
    },
  });
