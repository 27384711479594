import React from "react";
import { Box, Button } from "@mui/material";
import { jgDenimColors } from "../../../jg-material/theme/jg-denim-colors";
import { TermsAndConditionsLink } from "./TermsAndConditionsLink";

export interface RegisterFooterProps {
  canGoPrev: boolean;
  canGoNext: boolean;
  onPrev: () => void;
  onNext: () => void;
}

export const RegisterFooter = ({ canGoNext, canGoPrev, onNext, onPrev }: RegisterFooterProps) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Button
        sx={{ color: jgDenimColors.accent.orange.main }}
        disabled={!canGoPrev}
        onClick={onPrev}
        variant="contained"
      >
        Prev Step
      </Button>
      <Box display="flex" flexDirection={"column"} alignItems="flex-end">
        <Button variant="contained" color="secondary" disabled={!canGoNext} onClick={onNext}>
          Next Step
        </Button>
        <TermsAndConditionsLink />
      </Box>
    </Box>
  );
};
