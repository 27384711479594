import PropTypes from "prop-types";
import React from "react";
import UserItemButton from "../components/UserItemButton";
import UserItemDummyButton from "../components/UserItemDummyButton";
import axiosWithCSRF from "./shared/axiosWithCSRF";

export default class UserItemContainer extends React.Component {
  constructor(props, _railsContext) {
    super(props);

    this.state = {
      item: props.item,
      currentUser: props.currentUser,
      itemableType: props.itemableType,
      userItems: props.userItems,
      saved: this.itemExists(props.userItems, "saved"),
      completed: this.itemExists(props.userItems, "completed"),
      progress: this.itemExists(props.userItems, "progress"),
      buttonDisabled: false,
      hasErrors: null,
    };

    this.onItemChange = this.onItemChange.bind(this);
  }

  itemExists = (items, status) => {
    let foundItem;
    if (!this.props.currentUser) {
      return false;
    } else {
      if (this.props.itemableType === "Lesson" && this.props.item.group_id) {
        foundItem = items.filter(
          (i) =>
            i.itemable_id === this.props.item.group_id &&
            i.itemable_type === "Group" &&
            i.item_status === status &&
            i.nested_ids.includes(this.props.item.id)
        );
      } else if (this.props.itemableType === "Group") {
        foundItem = items.filter(
          (i) =>
            i.itemable_id === this.props.item.id &&
            i.itemable_type === "Group" &&
            i.item_status === status &&
            i.nested_ids.length === this.props.item.released_lessons_count
        );
      } else {
        foundItem = items.filter(
          (i) =>
            i.itemable_id === this.props.item.id &&
            i.itemable_type === this.props.itemableType &&
            i.item_status === status
        );
      }

      if (foundItem && foundItem.length > 0) {
        return foundItem[0]["id"];
      } else {
        return false;
      }
    }
  };

  checkRightNavItems = (completed) => {
    if (completed) {
      setTimeout(function () {
        let circles = document.getElementsByClassName("nav-item-icon");
        for (var i = 0; i < circles.length; i++) {
          circles[i].classList.remove("icon-circle");
          circles[i].classList.add("icon-check");
        }

        let navLinks = document.getElementsByClassName("right-nav__link");
        for (var i = 0; i < navLinks.length; i++) {
          navLinks[i].classList.add("right-nav__link--completed");
        }
      }, 2000);
    } else {
      setTimeout(function () {
        let circles = document.getElementsByClassName("nav-item-icon");
        for (var i = 0; i < circles.length; i++) {
          circles[i].classList.remove("icon-check");
          circles[i].classList.add("icon-circle");
        }

        let navLinks = document.getElementsByClassName("right-nav__link");
        for (var i = 0; i < navLinks.length; i++) {
          navLinks[i].classList.remove("right-nav__link--completed");
        }
      }, 2000);
    }
  };

  onItemChange = (itemStatus) => {
    if (this.state[itemStatus] === false) {
      axiosWithCSRF()
        .post("/user_items", {
          user_id: this.state.currentUser["id"],
          itemable_id: this.state.item["id"],
          itemable_type: this.state.itemableType,
          item_status: itemStatus,
        })
        .then((response) => {
          // if item has moved from progress -> complete or vice versa
          if (response.data.update_sibling) {
            this.setState({
              [itemStatus]: response.data.item_id,
              [response.data.update_sibling]: false,
            });
          } else {
            this.setState({ [itemStatus]: response.data.item_id });
            // if on a module page, change the styling of the righthand column items (from rails partial, not react component)
          }

          if (this.state.itemableType === "Group" && itemStatus == "completed") {
            this.checkRightNavItems(true);
          } else if (this.state.itemableType === "Group" && itemStatus == "progress") {
            this.checkRightNavItems(false);
          }
        })
        .catch((error) => {
          let errorResponse =
            error.response.data.message || "There was a problem saving this item.";
          this.setState({ hasErrors: errorResponse });
          setTimeout(() => this.setState({ hasErrors: null }), 2000);
        });
    } else if (this.state[itemStatus]) {
      //  let itemStatus = this.state.saved && "saved" || this.state.progress && "progress" || this.state.completed && "completed"

      axiosWithCSRF()
        .delete(`/user_items/${this.state[itemStatus]}`, {
          data: {
            user_id: this.state.currentUser["id"],
            item_id: this.state.item.id,
            item_type: this.state.itemableType,
            group_id: this.state.item.group_id,
            item_status: itemStatus,
          },
        })
        .then((response) => {
          this.setState({ [itemStatus]: false });
          // if on a module page, change the styling of the righthand column items (from rails partial, not react component)
          if (this.state.itemableType === "Group" && itemStatus == "completed") {
            setTimeout(function () {
              let checks = document.getElementsByClassName("nav-item-icon");
              for (let i = 0; i < checks.length; i++) {
                checks[i].classList.remove("icon-check");
                checks[i].classList.add("icon-circle");
              }

              let nav = document.getElementsByClassName("right-nav__link");
              for (let i = 0; i < nav.length; i++) {
                nav[i].classList.remove("right-nav__link--completed");
              }
            }, 2000);
          }
        })
        .catch((error) => {
          let errorResponse =
            error.response.data.message || "There was a problem deleting this item.";
          this.setState({ hasErrors: errorResponse });
          setTimeout(() => this.setState({ hasErrors: null }), 2000);
        });
    }
  };

  currentUserPresent(status) {
    if (this.state.currentUser) {
      return (
        <UserItemButton
          itemStatus={status}
          item={this.state.item}
          changeItem={this.onItemChange}
          itemPresent={this.state[status]}
          itemableType={this.props.itemableType}
        />
      );
    } else {
      return (
        <UserItemDummyButton
          itemStatus={status}
          clickedText={false}
          updateUser={this.updateUser}
        ></UserItemDummyButton>
      );
    }
  }

  render() {
    return (
      <div>
        <div className="user-items">
          <div className="header5 user-items--saved">{this.currentUserPresent("saved")}</div>

          <div className="header5 user-items--completed">
            {this.currentUserPresent("completed")}
          </div>

          {this.props.nextItem && (
            <div className="user-items--next">
              <a className="user-items-btn header5" href={this.props.nextItem}>
                <span>
                  Next <span className="md-and-bigger-only">Module</span>
                </span>
                <i className="icon icon-arrow-right"></i>
              </a>
            </div>
          )}
        </div>
        {this.state.hasErrors && (
          <div className="user-items--error">
            <p>{this.state.hasErrors}</p>
          </div>
        )}
      </div>
    );
  }
}
