import React from "react";
import { CDN_URL } from "../../components/shared/CdnUrl";
import { PromoItem } from "../../justins-corner/PromoItem";

class PromoItemPreview extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="promo-item-preview">
        <PromoItem orientation={"portrait"} promoItemId={this.props.promoItemId} />

        <PromoItem orientation={"landscape"} promoItemId={this.props.promoItemId} />
      </div>
    );
  }
}

export default PromoItemPreview;
