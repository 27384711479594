import React from "react";
import { connect } from "react-redux";

import { fetchArtists, selectArtistQuery, slices, sortByOptions } from "./store";

export const ArtistsSearch = ({ query, setQuery }) => {
  const inputRef = React.createRef();
  return (
    <div className="artist-search" onClick={() => inputRef.current.focus()}>
      <div className="artist-search__inner">
        <i className="icon icon-search-thin" />
        <input
          ref={inputRef}
          type="text"
          value={query}
          onChange={({ target }) => setQuery(target.value)}
        />
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    query: selectArtistQuery(state),
  }),
  (dispatch) => ({
    setQuery: (query) => {
      dispatch(slices.ui.actions.setArtistQuery(query));

      if (query.length === 1) {
        // if new query sort by relevance (pg_search ranking)
        // only set it on new query so that we don't erase post-query user sort selection
        dispatch(slices.ui.actions.setArtistSortBy(sortByOptions.relevance));
      }
      if (query.length === 0) {
        // search cleared, go back to default
        dispatch(slices.ui.actions.setArtistSortBy(sortByOptions.popularity));
      }

      dispatch(fetchArtists());
    },
  })
)(ArtistsSearch);
