import React from "react";
import { connect } from "react-redux";
import TabSingleImage from "./TabSingleImage";
import TabAllImages from "./TabAllImages";
import LyricsChords from "./LyricsChords";

const SheetMusicContent = ({ activeImage, viewAllImages }) => {
  if (activeImage) {
    return <TabSingleImage />;
  } else if (viewAllImages) {
    return <TabAllImages />;
  } else {
    return <LyricsChords />;
  }
};

const mapStateToProps = ({ entity: { activeImage, viewAllImages } }) => ({
  activeImage,
  viewAllImages,
});

export default connect(mapStateToProps)(SheetMusicContent);
