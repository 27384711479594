import React from "react";
import LessonsAndSongsImg from "./LessonsAndSongsImg";
import { CDN_URL } from "../components/shared/CdnUrl";
import { HalfStar } from "../banners/icons";
import { Star } from "@mui/icons-material";

const LessonsAndSongsApp = ({ width }) => {
  return (
    <div className="container">
      <div className="lessons-and-songs-app">
        <div className="content">
          <h2>
            <LessonsAndSongsImg />
          </h2>
          {width <= 992 && (
            <img
              src={`${CDN_URL}/redesign/2023/home-page/lessons-songs-app-2.png`}
              alt="JustinGuitar Lessons & Songs App"
            />
          )}
          <p>Learn Anywhere, Anytime</p>
          <ul>
            <li>Learn through your favorite songs from day 1</li>
            <li>Play-along tracks hand-picked for each stage</li>
            <li>Bite-sized lessons so you learn at your pace</li>
            <li>Easy-to-use guitar tuner</li>
            <li>Structure learning path and interactive exercises</li>
          </ul>
          <div className="app-store-cta">
            <a href="https://go.onelink.me/3065804798/main" className="button button--primary">
              download
            </a>
            <div className="ratings">
              <div className="google-play-store">
                <Star />
                <Star />
                <Star />
                <Star />
                <HalfStar /> 4.7 • Google Play
              </div>
              <div className="apple-app-store">
                <Star />
                <Star />
                <Star />
                <Star />
                <Star /> 4.9 • App Store
              </div>
            </div>
          </div>
        </div>
        {width > 992 && (
          <img
            src={`${CDN_URL}/redesign/2023/home-page/lessons-songs-app-2.png`}
            alt="JustinGuitar Lessons & Songs App"
          />
        )}
      </div>
    </div>
  );
};

export default LessonsAndSongsApp;
