import React from "react";
import { CDN_URL } from "../../components/shared/CdnUrl";
import HowToEnter from "./HowToEnter";
import CompetitionFormButton from "./CompetitionFormButton";
import { BUTTON_TEXT } from "./Competition";

const AboutTheApp = ({ user, handleSubmission, hasSubmitted }) => {
  return (
    <div className="competition__about">
      <strong>ABOUT THE APP</strong>

      <p>
        My JustinGuitar Lessons & Songs App lets you play along with your favorite songs while
        practicing the new guitar skills we’re learning together!
      </p>
      <p>
        The app is the perfect boost for learning the guitar in a more effective and fun way! And if
        you struggle with strumming patterns and chord changes, the app can be a great extra hand
        too. :)
      </p>
      <p>
        Don’t miss the opportunity to win a free subscription! Or, if you don’t want to count on
        your luck, grab a 7-day trial now. The free trial is only available once per App Store or
        Google Play account. To redeem your trial, just download the app in your app store.
      </p>
      <div className="competition__buttons">
        <CompetitionFormButton
          user={user}
          text={BUTTON_TEXT}
          handleSubmission={handleSubmission}
          hasSubmitted={hasSubmitted}
        />
        <a
          href="https://go.onelink.me/3065804798/f826f02a"
          className="button button--inline button--teal"
        >
          Try the app for free
        </a>
      </div>
    </div>
  );
};

const BottomContent = () => {
  return (
    <div className="competition__bottom">
      <p>
        This competition closes on Tuesday 7 September 2021 at 1 PM Eastern Daylight Time (EDT).
      </p>
      <p>I’ll announce the ten winners at 3 PM Eastern Daylight Time (EDT).</p>
      <p>
        If you’re one of them - I’ll get in touch through your valid JustinGuitar Account email.
      </p>
      <p>One entry per email address.</p>

      <p>
        <strong>
          🚨 The App is not available in all countries. Please check in your local Google Play Store
          or App Store to verify availability.
        </strong>
      </p>
    </div>
  );
};

const CompetitionContent = ({ user, handleSubmission, hasSubmitted }) => {
  return (
    <div className="competition__content">
      <div className="competition__copy">
        <h2>Hey, how you doing? :) Justin Sandercoe here.</h2>

        <p>
          I want to celebrate the global launch of the updated{" "}
          <strong>JustinGuitar Lessons & Songs App</strong> by doing something special! I’m giving
          away ten subscriptions to the new app, and you can be one of the lucky winners. :)
        </p>
      </div>

      <div className="competition__section">
        <div>
          <img
            src={`${CDN_URL}/images/pages/app-competition/phone-with-text.png`}
            alt="JustinGuitar Lessons & Songs App"
          />
        </div>
        <AboutTheApp handleSubmission={handleSubmission} hasSubmitted={hasSubmitted} user={user} />
      </div>

      <HowToEnter user={user} handleSubmission={handleSubmission} hasSubmitted={hasSubmitted} />
      <BottomContent />
    </div>
  );
};

export default CompetitionContent;
