import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

export const SquareTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    border: "1px solid rgb(129 171 193 / 50%)",
    boxShadow: "none",
    outline: "none",
  },
  "& .Mui-focused": {
    boxShadow: "none",
    border: "none",
    outline: "none",
  },
  "&.Mui-filled .MuiOutlinedInput-root": {
    boxShadow: "none",
    border: "none",
    outline: "none",
  },
  "& legend": {
    boxShadow: "none",
    border: "none",
    outline: "none",
  },
}));
