import React from "react";
import { Field } from "formik";

const TermsConsent = ({ formikProps }) => {
  return (
    <div className="auth__consent">
      <div className="auth__checkbox">
        <Field
          name="termsConsent"
          id="termsConsent"
          type="checkbox"
          className={
            formikProps.errors.termsConsent && formikProps.touched.termsConsent ? " has-error" : ""
          }
        />
        <label htmlFor="termsConsent">
          I consent to JustinGuitar’s <a href="/privacy-policy">Terms & Conditions</a>.
        </label>
      </div>
      {formikProps.errors.termsConsent && formikProps.touched.termsConsent && (
        <span className="has-error">{formikProps.errors.termsConsent}</span>
      )}
    </div>
  );
};

export default TermsConsent;
