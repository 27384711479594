import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { jgDenimColors } from "../../jg-material/theme/jg-denim-colors";
import { fontWeights } from "../../jg-material/theme/typography";

const ScholarshipDisclaimer = () => {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="body1">
        I will give you most of my products for{" "}
        <Typography
          variant="span"
          sx={{ color: jgDenimColors.action.success.default, fontWeight: fontWeights.semiBold }}
        >
          FREE
        </Typography>{" "}
        if you genuinely can't afford it. <br />
        <Link
          sx={{
            color: jgDenimColors.accent.orange.main,
            textDecoration: "none",
            fontWeight: fontWeights.semiBold,
          }}
          href="mailto:hello@justinguitar.com"
        >
          Email here
        </Link>{" "}
        and we will get in touch to discuss if you qualify for a FREE Blues Immersion scholarship :)
      </Typography>
    </Box>
  );
};

export default ScholarshipDisclaimer;
