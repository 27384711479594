import React from "react";
import { productTypes, productsFilteredByType, productSubtypes } from "./productTypes";
import ProductList from "./ProductList";

export default function ({ products }) {
  const teeShirts = productsFilteredByType(products, productTypes.merch, productSubtypes.teeShirt);
  const merch = productsFilteredByType(products, productTypes.merch, [
    productSubtypes.hoodie,
    productSubtypes.mug,
    productSubtypes.bottle,
    productSubtypes.hat,
  ]);

  return (
    <div className="products-listing products-listing--jam-tracks container">
      <h2>Tee Shirts</h2>
      <ProductList products={teeShirts} />
      {merch.length > 0 && (
        <>
          <h2>Merch</h2>
          <ProductList products={merch} />
        </>
      )}
    </div>
  );
}
