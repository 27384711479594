import React from "react";
import { VerifiedIcon } from "../MusicTheoryCourse/Icons";
import { Box, Grid, Typography } from "@mui/material";
import { HIGHLIGHTS, SECTION_TOP_MARGIN } from "./consts";
import { fontFamilies } from "../../jg-material/theme/typography";

const CourseHighlights = () => {
  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} my={SECTION_TOP_MARGIN}>
      <Grid container spacing={3}>
        {HIGHLIGHTS.map((highlight, idx) => (
          <Grid
            item
            key={idx}
            xs={12}
            sm={6}
            md={3}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <VerifiedIcon />
            {/* there's no variant to match what's in figma... */}
            <Typography
              variant={"h5"}
              sx={{
                m: 1,
                textTransform: "none",
                textAlign: "center",
                letterSpacing: 0,
                fontFamily: fontFamilies.openSans,
              }}
            >
              {highlight}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CourseHighlights;
