import React from "react";
import { connect } from "react-redux";
import LazyLoad from "react-lazyload";
import { orderedLessons, completedLessonIds } from "./store/groupSelectors";
import { DEFAULT_THUMB } from "../components/shared/CdnUrl";
import GroupQuizLink from "./GroupQuizLink";
import { openDialog } from "../components/shared/ui/uiSlice";

const CardThumbnail = ({ lesson, completedLessonIds, isLocked }) => {
  const { thumbnails, vimeoThumbnail, image, title } = lesson.attributes;
  let thumb = DEFAULT_THUMB;

  if (image && image.length > 0) {
    thumb = image;
  } else if (thumbnails && Object.keys(thumbnails).length > 0) {
    thumb = thumbnails["maxres"] ? thumbnails["maxres"] : thumbnails["default"];
  } else if (vimeoThumbnail) {
    thumb = vimeoThumbnail;
  }

  return (
    <div style={{ position: "relative" }}>
      <LazyLoad width={150} height={84}>
        {completedLessonIds && completedLessonIds.includes(parseInt(lesson.id)) && (
          <i className="icon icon-check_circle_outline" />
        )}
        {isLocked && <i className="icon icon-lock" />}
        <img src={thumb} alt={title} width={150} height={84} />
      </LazyLoad>
    </div>
  );
};

function goToLesson(slug, isLocked) {
  if (!isLocked) {
    window.location.href = `/guitar-lessons/${slug}`;
  }
}

function sanitizedContent(content) {
  const doc = new DOMParser().parseFromString(content, "text/html");
  const textContent = doc.body.textContent || "";
  return textContent.length > 170 ? `${textContent.substring(0, 170)}...` : textContent;
}

export const LessonCard = ({ lesson, completedLessonIds, needsSubscription, lockedLessonIds }) => {
  const { slug, title, metaDesc, body, id } = lesson.attributes;
  const isLocked = !lockedLessonIds || lockedLessonIds.includes(id);
  return (
    <div
      className={"group-lesson flex clickable " + (isLocked ? "locked-lesson-card" : "")}
      onClick={() => goToLesson(slug, isLocked)}
    >
      <div className="group-lesson__img">
        <CardThumbnail
          lesson={lesson}
          completedLessonIds={completedLessonIds}
          isLocked={isLocked}
        />
      </div>
      <div className="group-lesson__text">
        <h3 className="header5">{title}</h3>
        <p>{metaDesc && metaDesc.length > 0 ? metaDesc : body ? sanitizedContent(body) : ""}</p>
        {completedLessonIds && completedLessonIds.includes(parseInt(lesson.id)) && (
          <div className="site-progress">
            <span className="lesson-completed flex">
              Your Progress: 100% <progress className="site-progress" value="100" />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const GroupLessonsList = ({ lessons, completedLessonIds, group, currentUser, openDialog }) => {
  return (
    <div className="group-lessons">
      {lessons.map((l) => {
        return (
          <LessonCard
            key={l.id}
            lesson={l}
            completedLessonIds={completedLessonIds}
            lockedLessonIds={group.data.attributes.lockedLessonIds}
          />
        );
      })}
      <GroupQuizLink group={group} currentUser={currentUser} openDialog={openDialog} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  lessons: orderedLessons(state),
  completedLessonIds: completedLessonIds(state),
  group: state.entity.group,
  currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  openDialog: (text) => dispatch(openDialog(text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupLessonsList);
