import { Box, ThemeProvider, styled } from "@mui/material";
import React, { useCallback, useState } from "react";
import { jgDenimTheme } from "../../jg-material/theme/jg-denim-theme";

import { EbookSwoosh } from "./EbookSwoosh";
import { EbookSection } from "./EbookSection";
import { EbookFaqs } from "./EbookFaqs";
import { EbooksLoginContext, IEbooksLoginContext } from "./EbookLoginContext";
import SignInModal from "../../components/users/sessions/SignInModal";
import { EbookFeatured } from "./EbookFeatured";

const Ebooks = ({ currentUser, faqs, featuredEbook, sections }) => {
  const [showLogin, setShowLogin] = useState(false);

  const login = useCallback(() => {
    setShowLogin(true);
  }, []);

  const contextProviderLogin: IEbooksLoginContext = {
    isAuthenticated: !!currentUser,
    login,
  };

  return (
    <EbooksLoginContext.Provider value={contextProviderLogin}>
      <ThemeProvider theme={jgDenimTheme}>
        <div>
          <EbookSwoosh />
          <Box className="container">
            {!!featuredEbook && <EbookFeatured {...featuredEbook?.data} />}
            <EbookContainer>
              {sections.map((section, idx) => (
                <EbookSection {...section} hasPadding={!section?.subsections?.length} key={idx} />
              ))}
            </EbookContainer>
            <PadBottom />
            <EbookFaqs faqs={faqs} />
          </Box>
        </div>
        <SignInModal isOpen={showLogin} setIsOpen={() => setShowLogin(!showLogin)} />
      </ThemeProvider>
    </EbooksLoginContext.Provider>
  );
};

const EbookContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const PadBottom = styled("div")(({ theme }) => ({
  height: "140px",
  [theme.breakpoints.down("md")]: {
    height: "70px",
  },
}));

export default (props) => <Ebooks {...props} />;
