import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  userFavorites,
  songFavorites,
  chordFavorites,
  lessonFavorites,
  // strummingPatternFavorites,
} from "../../components/shared/session/sessionSelectors";
import { clearAlert, openAlert, openDialog, setLoading } from "../../components/shared/ui/uiSlice";
import DashboardFilters from "../shared/DashboardFilters";
import FavoriteCard from "./FavoriteCard";
import { withInnerWidth } from "../../components/shared";
import { setActiveTab } from "../store/dashboardStore";
import {
  deleteLessonItem,
  deleteSongItem,
  deleteChordItem,
} from "../../components/shared/session/sessionEffects";
import TabbedContent from "../shared/TabbedContent";
import Snackbar from "../../components/shared/ui/Snackbar";
import Dialog from "../../components/shared/ui/Dialog";
import { CDN_URL } from "../../components/shared/CdnUrl";

const TABS = [
  { name: "all", nameShort: "All", selector: "userFavorites" },
  { name: "lessons", nameShort: "Lessons", selector: "lessonFavorites" },
  { name: "songs", nameShort: "Songs", selector: "songFavorites" },
  { name: "chords", nameShort: "Chords", selector: "chordFavorites" },
  // { name: "strumming patterns", nameShort: "Patterns", selector: "strummingPatternFavorites" },
];

const SORT_BY = [
  { title: "Newest", column: "createdAt", direction: "desc" },
  { title: "Oldest", column: "createdAt", direction: "asc" },
];

const FavoritesTab = (props) => {
  const dashboardFiltersRef = useRef(null);

  const getTabByName = (name) => {
    const tab = TABS.find((tab) => tab.name.toLowerCase() === name.toLowerCase());

    return tab ? tab : TABS[0];
  };

  const [activeTab, setActiveTab] = useState(
    getTabByName(location.hash.replace("-", " ").slice(1))["name"]
  );

  const activeTabData = props[getTabByName(location.hash.replace("-", " ").slice(1))["selector"]];
  const [favorites, setFavorites] = useState(
    activeTabData.sort(
      (a, b) => Date.parse(b.attributes.createdAt) - Date.parse(a.attributes.createdAt)
    )
  );

  const goToTab = (tabName) => {
    location.hash = `${tabName.replace(" ", "-")}`;
    setActiveTab(tabName);
    setFavorites(activeTabData);
  };

  const searchFavorites = (data) => {
    if (!data) {
      setFavorites(activeTabData);
    } else {
      setFavorites(
        activeTabData.filter((favorite) =>
          favorite.attributes.itemableTitle.toLowerCase().includes(data.toLowerCase())
        )
      );
    }
  };

  const sortFavorites = (data) => {
    const [column, direction] = data.split("-");

    if (column === "createdAt") {
      const sorted = activeTabData.sort((a, b) =>
        direction === "asc"
          ? Date.parse(a.attributes.createdAt) - Date.parse(b.attributes.createdAt)
          : Date.parse(b.attributes.createdAt) - Date.parse(a.attributes.createdAt)
      );

      setFavorites([...sorted]); // create a new array, so the state updates
    }
  };

  const deleteUserFavorite = (favorite) => {
    if (favorite.attributes.itemableType == "Song") {
      props.deleteSongItem(favorite);
    }

    if (favorite.attributes.itemableType == "Chord") {
      props.deleteChordItem(favorite);
    }

    if (favorite.attributes.itemableType == "Lesson") {
      props.deleteLessonItem({
        itemableId: favorite.id,
        lessonId: favorite.attributes.lessonId,
        status: favorite.attributes.itemStatus,
        type: favorite.type,
      });
    }
  };

  useEffect(() => {
    setFavorites(activeTabData);
  }, [activeTabData]);

  return (
    <div className="dashboard-favorites">
      <TabbedContent
        title={"Favorites"}
        setActiveTab={(tab) => {
          setActiveTab(tab);
          dashboardFiltersRef.current && dashboardFiltersRef.current.clearSearch();
        }}
        activeTab={activeTab}
        tabOptions={TABS}
      >
        <>
          {activeTabData && activeTabData.length ? (
            <>
              <DashboardFilters
                ref={dashboardFiltersRef}
                searchPlaceholder="Search your favorites..."
                sortBy={SORT_BY}
                onSearch={searchFavorites}
                onSort={sortFavorites}
              />
              <div className="tab-content">
                <p className="info">
                  {activeTab} ({activeTabData.length})
                </p>
                {favorites.length ? (
                  <div className="cards">
                    {favorites.map((favorite, idx) => (
                      <FavoriteCard
                        key={idx}
                        favorite={favorite}
                        user={props.user}
                        openAlert={props.openAlert}
                      />
                    ))}
                  </div>
                ) : (
                  <p>No favorites found</p>
                )}
              </div>
            </>
          ) : !activeTabData.length && activeTab !== "strumming patterns" ? (
            <p className="text-center">You haven't saved any favorites yet</p>
          ) : (
            <div className="empty-info strumming-patterns">
              <p className="text-center">
                Your Strumming Patterns are empty. Visit the Strumming Machine and start building
                your favorites!
              </p>
              <button
                className="button button--primary"
                onClick={() => (window.location.href = "/strumming-machine")}
              >
                Strumming Machine
              </button>
              <img
                src={`${CDN_URL}/images/components/strumming-machine/dashboard-strumming-machine.png`}
                alt="Strumming Machine"
              />
            </div>
          )}
        </>
      </TabbedContent>
      <Dialog onConfirm={deleteUserFavorite} />
      <Snackbar />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userFavorites: userFavorites(state),
  songFavorites: songFavorites(state),
  chordFavorites: chordFavorites(state),
  lessonFavorites: lessonFavorites(state),
  // strummingPatternFavorites: strummingPatternFavorites(state),
  user: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  openDialog: (text) => dispatch(openDialog(text)),
  openAlert: (alert) => dispatch(openAlert(alert)),
  clearAlert: () => dispatch(clearAlert()),
  deleteSongItem: (favorite) => dispatch(deleteSongItem(favorite)),
  deleteChordItem: (favorite) => dispatch(deleteChordItem(favorite)),
  deleteLessonItem: (favorite) => dispatch(deleteLessonItem(favorite)),
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withInnerWidth(FavoritesTab));
