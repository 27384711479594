//
// wrapper around axios to include Rails CSRF token
// adapted from: https://github.com/rails/webpacker/issues/1015#issuecomment-443268352

import axios from "axios";

function axiosWithCSRF(customArgs = {}) {
  const token = document.querySelector('[name="csrf-token"]') || { content: "no-csrf-token" };
  const common = {
    common: {
      "X-CSRF-Token": token.content,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  return axios.create({
    headers: Object.assign(customArgs, common),
  });
}

export default axiosWithCSRF;
