import React from "react";
import { connect } from "react-redux";
import TabContainer from "../components/shared/tabs/TabContainer";
import { note } from "./store/groupSelectors";

const GroupTabs = ({ group, activeTab, session, note }) => {
  const { introductionText, reference, title, slug, id, discourseTopicId } = group.data.attributes;
  return (
    <TabContainer
      activeTab={activeTab}
      informationHtml={introductionText}
      token={session.token}
      reference={reference}
      title={title}
      currentUser={session.currentUser}
      objectType="Group"
      objectId={id}
      relativePath={`/modules/${slug}`}
      note={note}
      topicId={discourseTopicId}
    />
  );
};

const mapStateToProps = (state) => ({
  group: state.entity.group,
  activeTab: state.ui.activeTab,
  session: state.session,
  note: note(state),
});

export default connect(mapStateToProps)(GroupTabs);
