import axiosWithCSRFTyped from "../../../components/shared/axiosWithCSRF-typed";
import {
  PaymentIntent,
  PaymentIntentResult,
  PaymentMethod,
  SetupIntent,
  SetupIntentResult,
  Stripe,
  Token,
} from "@stripe/stripe-js";
import { WORKSHOP_PURCHASE_TYPE } from "../../../payments/PaymentConstants";
import { WorkshopEntity } from "../interfaces";

interface WorkshopPayment {
  amount: number;
  currency: string;
  cardToken: Token;
  isSubscription: boolean;
  paymentMethodId: PaymentMethod["id"];
  purchaseType: string;
  purchaseReference: string;
  authenticityToken: string;
  recaptchaToken: string;
}

interface PaymentResponse {
  error?: {
    message: string;
  };
  data: {
    status: number;
    error?: {
      message: string;
    };
    requires_action?: boolean;
    confirmation_method?: string; // looks like we only care about "automatic"
    object?: string; // looks like we only care about "setup_intent
    isTrial?: boolean;
    payment_intent_client_secret: string;
  };
  setupIntent?: {
    status: string; // looks like we only care about "succeeded"
  };
}

export const createDeposit = async (
  paymentMethod: PaymentMethod,
  cardToken: Token,
  recaptchaToken: string,
  stripe: Stripe,
  purchaseReference: string,
  workshop: WorkshopEntity | undefined,
  userPaidDeposit: Boolean
) => {
  const amount =
    workshop?.attributes.slug === "2023-Acoustic-Electric-Classics"
      ? userPaidDeposit
        ? 2300
        : 400
      : workshop?.attributes.slug === "2023-Acoustic-Jam-Week"
      ? userPaidDeposit
        ? 1200
        : 200
      : userPaidDeposit
      ? 1200
      : 250;

  const response = await axiosWithCSRFTyped().post<WorkshopPayment, PaymentResponse>("/purchases", {
    amount,
    currency: "gbp",
    cardToken,
    isSubscription: false,
    paymentMethodId: paymentMethod.id,
    purchaseType: "workshop_deposit",
    purchaseReference,
    recaptchaToken,
  });

  if (response.error != null) {
    throw new Error(response.error.message);
  }

  if (response.data.error != null) {
    throw new Error(response.data.error.message);
  }

  if (response.data.requires_action) {
    return handleActionRequired(response, stripe, purchaseReference);
  }

  // data object has another data object in it
  if (response.data.data.requires_action) {
    return handleActionRequired(response.data, stripe, purchaseReference);
  }
};

const handleActionRequired = async (
  response: PaymentResponse,
  stripe: Stripe,
  purchaseReference: string
) => {
  const handleResponseFn =
    response.data.confirmation_method === "automatic"
      ? stripe.handleCardAction
      : response.data.object === "setup_intent"
      ? stripe.confirmCardSetup
      : stripe.handleCardAction;

  const result: PaymentIntentResult | SetupIntentResult = await handleResponseFn(
    response.data.payment_intent_client_secret
  );

  if (result.error) {
    throw new Error(result.error.message);
  }
  if (resultIsPaymentIntentResult(result)) {
    return confirmPayment(result.paymentIntent, purchaseReference);
  } else if (resultIsSetupIntentResult(result) && result.setupIntent.status === "succeeded") {
    return confirmSetupIntent(result.setupIntent, purchaseReference);
  }
};

const resultIsPaymentIntentResult = (result: any): result is PaymentIntentResult => {
  return result.paymentIntent != null;
};
const resultIsSetupIntentResult = (result: any): result is SetupIntentResult => {
  return result.setupIntent != null;
};

const confirmPayment = (paymentIntent: PaymentIntent, purchaseReference: string) => {
  return axiosWithCSRFTyped().post("/confirm_purchase", {
    payment_intent_id: paymentIntent.id,
    purchase_reference: purchaseReference,
    // customer_id: customerId, // i don't think this is used on the back end
    purchase_type: WORKSHOP_PURCHASE_TYPE,
  });
};

const confirmSetupIntent = (setupIntent: SetupIntent, purchaseReference: string) => {
  return axiosWithCSRFTyped().post("/confirm_setup_intent", {
    setupIntentId: setupIntent.id,
    purchaseReference: purchaseReference,
    // customer_id: intentResult.customer_id, // i don't think this is used
    purchaseType: WORKSHOP_PURCHASE_TYPE,
  });
};
