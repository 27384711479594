import React from "react";

export default class DisabledItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  editIcon = () => {
    if (this.props.showEditIcons === true) {
      return <span className="icon icon-pencil-outline"></span>;
    } else {
      return null;
    }
  };

  render() {
    const { item } = this.props;
    return (
      <div className="practice-items__form">
        <div className="practice-form__container">
          <div className="practice-form__item practice-form__item--disabled">
            <div className="practice-form__inner">
              {this.editIcon()}
              <input disabled value={item.title}></input>
            </div>
            <span className="routine__form-field__label">Title</span>
          </div>

          <div className="practice-form__item practice-form__item--disabled">
            <div className="practice-form__inner">
              {this.editIcon()}
              <input disabled value={item.description}></input>
            </div>
            <span className="routine__form-field__label">Description</span>
          </div>

          <div className="practice-form__item practice-form__item--disabled">
            <div className="practice-form__inner">
              {this.editIcon()}
              <input disabled type="number" value={item.duration}></input>
            </div>
            <span className="routine__form-field__label">Duration</span>
          </div>

          <div className="practice-form__item practice-form__item--disabled">
            <div className="practice-form__inner">
              {this.editIcon()}
              <input disabled value={item.related_link}></input>
            </div>
            <span className="routine__form-field__label">Related Link</span>
          </div>

          <div className="practice-form__item practice-form__item--disabled">
            <div className="practice-form__inner">
              {this.editIcon()}
              <input disabled value={item.statistic_name}></input>
            </div>
            <span className="routine__form-field__label">Statistic Name</span>
          </div>
        </div>
      </div>
    );
  }
}
