import React from "react";
import PrivateOneOnOneLessonsSwoosh from "./PrivateOneOnOneLessonsSwoosh";
import { withInnerWidth } from "../../components/shared";
import Category from "../FAQ/Category";
import "../../components/shared/polyfills/replaceAll.js";
import Intro from "./Intro";
import HowItWorks from "./HowItWorks";
import WhatYouGet from "./WhatYouGet";
import PlayinItForwardBanner from "./PlayinItForwardBanner";
import Slots from "./Slots";

const PrivateOneOnOneLessons = ({ faqs, currentUser, slots = [], width, userApplications }) => {
  return (
    <div className="private-one-on-one-lessons">
      <PrivateOneOnOneLessonsSwoosh />
      <div className="container">
        <Intro />
        <HowItWorks width={width} currentUser={currentUser} />
        <WhatYouGet currentUser={currentUser} />

        <Slots slots={slots.data} currentUser={currentUser} userApplications={userApplications} />

        <PlayinItForwardBanner />

        <div className="faq" id="faq">
          <Category faqs={faqs} />
        </div>
      </div>
    </div>
  );
};

export default withInnerWidth(PrivateOneOnOneLessons);
