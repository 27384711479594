import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "../components/CheckoutForm";
import PaywallSuccess from "./PaywallSuccess";

import {
  RECAPTCHA_KEY,
  STRIPE_DONATION_PUBLISHABLE_KEY,
  STRIPE_SALES_PUBLISHABLE_KEY,
} from "./PaymentConstants";

export default class PaywallForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuccess: false,
    };
  }

  successfulPurchase = () => {
    if (!this.props.hideSuccess) {
      this.setState({ showSuccess: true }, () => {
        setTimeout(() => window.location.reload(), 1500);
      });
    }

    if (this.props.successCallback) {
      this.props.successCallback();
    }
  };

  render() {
    const { attributes: { price, isSubscription } = {} } = this.props.selectedPricingOption;
    const { currentUser } = this.props;
    const { data: { attributes: { reference } } = {} } = this.props.paywall;

    const fontArray = [{ cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:400" }];
    return (
      <StripeProvider apiKey={STRIPE_SALES_PUBLISHABLE_KEY}>
        <div className="paywall-form">
          {!this.props.hideSuccess && this.state.showSuccess ? (
            <PaywallSuccess />
          ) : (
            <div className="paywall-form__outer">
              <div className="stripe__element__container">
                <Elements fonts={fontArray}>
                  <CheckoutForm
                    price={price}
                    reference={reference}
                    subscription={isSubscription}
                    recaptchaKey={RECAPTCHA_KEY}
                    purchaseType="sales"
                    userEmail={currentUser ? currentUser.data.attributes.email : null}
                    currency={"USD"}
                    successCallback={this.successfulPurchase}
                    splitInputs={this.props.splitInputs}
                  />
                </Elements>
              </div>

              {/* Eventually we'll need a 'Summary' component here:
                https://www.figma.com/file/RvERGPDmcfhFu3qQxtbBfq/%5BFINAL-UI%5D-Justin-Guitar-Website-Redesign-2020?node-id=116%3A5746
              */}
            </div>
          )}
        </div>
      </StripeProvider>
    );
  }
}

PaywallForm.propTypes = {};
