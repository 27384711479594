import React from "react";

import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  InputLabel,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { CountryOfResidence, ReferralType, RegistrationDetails } from "../interfaces";
import { UseFormik } from "../interfaces/formik-helpers";
import { RegisterFormPaper } from "../shared/RegisterFormPaper";

export const DetailsForm = ({ formik }: { formik: UseFormik<Partial<RegistrationDetails>> }) => {
  return (
    <>
      <RegisterFormPaper>
        <Typography variant="h4" sx={{ mb: jgDenimTheme.spacing(2) }}>
          Your Details
        </Typography>

        <Box>
          <TextField
            label="First Name"
            fullWidth={true}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            name={"firstName"}
            id={"firstName"}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            onBlur={formik.handleBlur}
            margin="dense"
          />
          <TextField
            label="Last Name"
            fullWidth={true}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            name={"lastName"}
            id={"lastName"}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            onBlur={formik.handleBlur}
            margin="dense"
          />
          <TextField
            label="Email Address"
            fullWidth={true}
            value={formik.values.email}
            onChange={formik.handleChange}
            name={"email"}
            id={"email"}
            error={formik.touched.email && Boolean(formik.errors.email)}
            onBlur={formik.handleBlur}
            margin="dense"
          />

          <TextField
            label="Mobile Phone"
            fullWidth={true}
            value={formik.values.mobilePhoneNumber}
            onChange={formik.handleChange}
            name={"mobilePhoneNumber"}
            id={"mobilePhoneNumber"}
            error={formik.touched.mobilePhoneNumber && Boolean(formik.errors.mobilePhoneNumber)}
            onBlur={formik.handleBlur}
            margin="dense"
          />

          <FormControl
            margin="dense"
            fullWidth={true}
            error={formik.touched.countryOfResidence && Boolean(formik.errors.countryOfResidence)}
          >
            <InputLabel id="countryOfResidence">Where will you be traveling from?</InputLabel>
            <Select
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              labelId="countryOfResidence"
              value={formik.values.countryOfResidence}
              name={"countryOfResidence"}
              id={"countryOfResidence"}
              label="Where will you be traveling from?"
            >
              {Object.entries(CountryOfResidence).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </RegisterFormPaper>

      <RegisterFormPaper>
        <Typography variant="h4">JustinGuitar Workshops</Typography>

        <FormControlLabel
          name={"isAlum"}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          control={
            <Checkbox
              checked={formik.values.isAlum}
              value={Boolean(formik.values.isAlum)} // browser default is "on" :(
              color="success"
            />
          }
          label="I have participated in other JustinGuitar Workshops"
        />

        <Typography variant="body1" marginBottom={1}>
          How did you hear about our guitar workshops?
        </Typography>

        <FormControl
          fullWidth={true}
          error={formik.touched.referralType && Boolean(formik.errors.referralType)}
        >
          <InputLabel id="referralType">How did you hear about this workshop?</InputLabel>
          <Select
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            labelId="referralType"
            value={formik.values.referralType}
            name={"referralType"}
            id={"referralType"}
            label="How did you hear about this workshop?"
            margin="dense"
          >
            {Object.entries(ReferralType).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </RegisterFormPaper>
    </>
  );
};
