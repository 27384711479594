import React, { useState, useRef } from "react";
import LazyLoad from "react-lazyload";
import { DEFAULT_THUMB } from "../../components/shared/CdnUrl";
import { strippedText, formattedSeconds } from "../ParentGroupUtils";
import LessonsWithinModuleList from "./LessonsWithinModuleList";
import { GRADE_GRADIENTS_BY_ID } from "../../components/shared/GradeGradientsById";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import Loading from "../../components/shared/Loading";

const Tabs = ({ grade, tabContent }) => {
  return (
    <div>
      <div className="pgcard__tabs">
        <GradeTab grade={grade} />
        <SecondaryTab tabContent={tabContent} />
      </div>
    </div>
  );
};

const GradeTab = ({ grade }) => {
  if (!grade) return null;

  // Grade 10 is 'spectrum', or 'All Levels'
  const gradeId = grade?.data?.id;
  let gradeTitle = ["10", null].includes(gradeId) ? "All Levels" : `Grade ${gradeId}`;

  return (
    <div
      className={"pgcard__tab " + `pgcard__tab--grade${gradeId}`}
      style={{ background: GRADE_GRADIENTS_BY_ID[gradeId] }}
    >
      <span>{gradeTitle} </span>
    </div>
  );
};

const SecondaryTab = ({ tabContent }) => {
  if (!tabContent || tabContent.length === 0) {
    return null;
  } else {
    return (
      <div className="pgcard__tab">
        <span>{tabContent}</span>
      </div>
    );
  }
};

const Thumb = ({ title, completionPercentage, image, isLocked }) => {
  return (
    <div className="pgcard__left">
      {isLocked && <i className="icon icon-lock"></i>}
      <LazyLoad width={250} height={141}>
        <div
          style={{
            position: "relative",
            display: "inline-block",
            border: "1px solid #E8EAEC",
            borderRadius: "10px",
          }}
        >
          <img width={250} height={141} src={image || DEFAULT_THUMB} alt={title} />
          {completionPercentage === 1 && <i className="icon icon-check_circle_outline" />}
        </div>
      </LazyLoad>
    </div>
  );
};

const CompletionPercentage = ({ completionPercentage }) => {
  if (!completionPercentage) return null;

  return (
    <div className="site-progress">
      <span>Your progress: {(completionPercentage * 100).toFixed(0)}%</span>
      <progress max="100" value={(completionPercentage * 100).toFixed(0)}></progress>
    </div>
  );
};

const CardBody = ({
  title,
  introductionText,
  completionPercentage,
  lessonCount,
  videoDuration,
  lessonListOpen,
  setLessonListOpen,
  toggleRef,
  setLessons,
  setQuiz,
  groupId,
  parentGroupId,
  lessons,
  setLoadingLessons,
  lessonCountByModule,
}) => {
  function handleLessonListOpening() {
    if (!lessonListOpen && lessons.length === 0) {
      setLoadingLessons(true);
      axiosWithCSRF()
        .get(`/classes/${parentGroupId}/modules/${groupId}`)
        .then((response) => {
          setLessons(response.data.data.attributes.lessons);
          setQuiz(response.data.data.attributes.quiz);
          setLessonListOpen(true);
        })
        .finally(() => {
          setLoadingLessons(false);
        });
    } else {
      setLessonListOpen(!lessonListOpen);
      setLoadingLessons(false);
    }
  }

  return (
    <div className="pgcard__right">
      <h3 className="uppercase header4">{title}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: strippedText(introductionText, 120),
        }}
      />

      <CompletionPercentage completionPercentage={completionPercentage} />

      <div className="pgcard__bottom" ref={toggleRef}>
        <div className="pgcard__info">
          <div className="pgcard__stats">
            <LessonCount lessonCount={lessonCountByModule(groupId)} />
            <Duration duration={formattedSeconds(videoDuration)} />
          </div>

          {lessonCount > 0 && (
            <div>
              <span onClick={handleLessonListOpening} className="uppercase bold pgcard__preview">
                Preview Lessons
                <i className={"icon icon-angle-" + (lessonListOpen ? "up" : "down")}></i>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const LessonCount = ({ lessonCount }) => {
  return (
    <div>
      <i className="icon icon-stacked-boxes"></i>
      <span>
        {lessonCount} Lesson{lessonCount === 1 ? "" : "s"}
      </span>
    </div>
  );
};

const Duration = ({ duration }) => {
  if (duration === "00:00") return null;

  return (
    <>
      <div className="pgcard__divider"></div>
      <div>
        <i className="icon icon-clock2"></i>
        <span>{duration} </span>
      </div>
    </>
  );
};

const LoadingLessonsPlaceholder = ({ title, lessonCount }) => {
  const CARD_HEIGHT = 65;
  const lessonHeight = CARD_HEIGHT * lessonCount;

  return (
    <div className="pgcard__lessons">
      <p>{title}</p>
      <div style={{ minHeight: lessonHeight + "px" }}></div>
      <Loading />
    </div>
  );
};

const ModuleCard = ({
  group,
  completionPercentage,
  completedLessonIds,
  isLocked,
  currentUser,
  hasPMTEnrollment,
  parentGroupId,
  lessonCountByModule,
}) => {
  const [lessonListOpen, setLessonListOpen] = useState(false);
  const toggleRef = useRef(null);
  const [lessons, setLessons] = useState([]);
  const [quiz, setQuiz] = useState(null);

  const {
    attributes: {
      id,
      title,
      image,
      introductionText,
      totalVideoDuration,
      slug,
      lessonOrder,
      metaDesc,
      lessonCount,
      tabHeaderForClassPage,
    } = {},
  } = group;

  const [loadingLessons, setLoadingLessons] = useState(false);

  function navigateToModule(event) {
    if (!isLocked && !toggleRef.current.contains(event.target)) {
      window.location.href = `/modules/${slug}`;
    }
  }

  return (
    <div className={"pgcard " + (isLocked ? "pgcard--locked" : "")} key={id}>
      <Tabs grade={group.relationships.grade} tabContent={tabHeaderForClassPage} />
      <div className="pgcard__inner" onClick={(event) => navigateToModule(event)}>
        <Thumb
          title={title}
          completionPercentage={completionPercentage}
          image={image}
          isLocked={isLocked}
        />
        <CardBody
          title={title}
          groupId={id}
          introductionText={metaDesc?.length > 0 ? metaDesc : introductionText}
          completionPercentage={completionPercentage}
          lessonCount={lessonCount}
          videoDuration={totalVideoDuration}
          lessonListOpen={lessonListOpen}
          setLessonListOpen={setLessonListOpen}
          toggleRef={toggleRef}
          setLessons={setLessons}
          lessons={lessons}
          parentGroupId={parentGroupId}
          setLoadingLessons={setLoadingLessons}
          setQuiz={setQuiz}
          lessonCountByModule={lessonCountByModule}
        />
      </div>

      {loadingLessons && <LoadingLessonsPlaceholder title={title} lessonCount={lessonCount} />}

      {lessonListOpen && lessons.length > 0 && (
        <LessonsWithinModuleList
          title={title}
          completedLessonsIds={completedLessonIds}
          lessonListOpen={lessonListOpen}
          lessonOrder={lessonOrder}
          lessons={lessons}
          isLocked={isLocked}
          group={group}
          currentUser={currentUser}
          hasPMTEnrollment={hasPMTEnrollment}
          quiz={quiz}
        />
      )}
    </div>
  );
};

export default ModuleCard;
