import React, { useEffect, useState } from "react";
import LiveEventsSwoosh from "./LiveEventsSwoosh";
import { withInnerWidth } from "../../components/shared";
import { CaretDownIcon, CaretUpIcon, CheckIcon, FilterIcon } from "../../components/shared/Icons";
import * as moment from "moment";
import Category from "../FAQ/Category";
import LoginPrompt from "../../components/auth/LoginPrompt";
import "../../components/shared/polyfills/replaceAll.js";
import LiteYoutube from "../../components/shared/LiteYoutube";
import DonateBanner from "./DonateBanner";
import ConfirmationDialog from "./ConfirmationDialog";
import SelectedEventDialog from "./SelectedEventDialog";

export const EVENT_TYPES = {
  club: "club",
  pmt: "pmt",
  blues: "blues",
};

const LiveEvents = ({ pageData, currentUser, token, width }) => {
  const [eventsToShow, setEventsToShow] = useState(6);
  const [selectedLiveEvent, setSelectedLiveEvent] = useState(null);
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [showConfirmDialog, setShowConfirmDialog] = useState(pageData.showConfirmDialog);

  // Filter and Sort states
  const [referenceFilter, setReferenceFilter] = useState("all");
  const [dateFilter, setDateFilter] = useState("all");
  const [teacherFilter, setTeacherFilter] = useState("all");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    if (width > 992) {
      setFiltersVisible(true);
    } else {
      setFiltersVisible(false);
    }
  }, [width]);

  const filteredEvents = pageData.liveEvents
    .filter((event) => {
      if (referenceFilter !== "all" && event.reference !== referenceFilter) return false;
      if (teacherFilter !== "all" && event.teacher_name !== teacherFilter) return false;

      if (dateFilter === "today" && !moment(event.scheduled_date).isSame(moment(), "day"))
        return false;
      if (dateFilter === "this week" && !moment(event.scheduled_date).isSame(moment(), "week"))
        return false;

      return true;
    })
    .sort((a, b) => {
      // Move ended events to the end
      if (a.ended && !b.ended) return 1;
      if (!a.ended && b.ended) return -1;

      // If both are either ended or not, then sort by date
      if (sortOrder === "asc") {
        return new Date(a.scheduled_date) - new Date(b.scheduled_date);
      } else {
        return new Date(b.scheduled_date) - new Date(a.scheduled_date);
      }
    });

  const resetFilters = () => {
    setReferenceFilter("all");
    setDateFilter("all");
    setTeacherFilter("all");
    // setSortOrder("asc");
  };

  const viewAll = () => {
    setEventsToShow(pageData.liveEvents.length);
  };

  const watchLiveEvent = (liveEvent) => {
    window.location.href = liveEvent.video_url;
  };

  const goToSurvey = (liveEvent) => {
    window.location.href = liveEvent.survey_url;
  };

  const ctaButtons = (liveEvent) => {
    if (pageData.eventType === "pmt" && !pageData.pmtSubscriber) {
      return (
        <div className="cta-buttons">
          <a
            href="/store/practical-music-theory#plans"
            className="button button--primary"
            target="_blank"
          >
            enroll
          </a>
        </div>
      );
    }

    return (
      <div className="cta-buttons">
        {!liveEvent.ended &&
          liveEvent.attending &&
          currentUser &&
          liveEvent.starting_today &&
          liveEvent.url && (
            <button className={`button button--primary join`} onClick={() => goToEvent(liveEvent)}>
              Join Live
            </button>
          )}
        {!liveEvent.ended &&
          liveEvent.attending &&
          currentUser &&
          liveEvent.starting_in_seven_days &&
          !liveEvent.starting_today &&
          liveEvent.survey_url && (
            <button
              className={`button button--primary survey`}
              onClick={() => goToSurvey(liveEvent)}
            >
              Survey
            </button>
          )}
        {!liveEvent.ended && currentUser && liveEvent.attending && (
          <button
            className="button button--primary cancel"
            onClick={() => setSelectedLiveEvent(liveEvent)}
          >
            Cancel
          </button>
        )}
        {!liveEvent.ended &&
          currentUser &&
          !liveEvent.attending &&
          parseInt(liveEvent.attendee_limit) - parseInt(liveEvent.attendees.length || 0) > 0 && (
            <button
              className="button button--primary attend"
              onClick={() => setSelectedLiveEvent(liveEvent)}
            >
              Register
            </button>
          )}
        {liveEvent.ended && currentUser && liveEvent.video_url && (
          <button
            className={`button button--dark`}
            onClick={() => watchLiveEvent(liveEvent)}
            style={{ fontSize: 12 }}
          >
            See Archive
          </button>
        )}
      </div>
    );
  };

  const ATTENDEE_COPY = {
    freeSpots: {
      message: "Register now!",
      color: "#6acf64",
    },
    almostFull: {
      message: "Almost full!",
      color: "#c60000",
    },
    lastSpots: {
      message: "Last spots!",
      color: "#f16335",
    },
    limitedSlots: {
      message: "Limited slots!",
      color: "#fabf22",
    },
  };

  const attendees = (liveEvent) => {
    const totalSpots = parseInt(liveEvent.attendee_limit);
    const spotsTaken = parseInt(liveEvent.attendees.length || 0);
    const spotsLeft = totalSpots - spotsTaken;

    if (liveEvent.ended || liveEvent.attending) return null;

    let attendeeCopy = ATTENDEE_COPY.freeSpots;

    if (spotsLeft <= 250) {
      attendeeCopy = ATTENDEE_COPY.almostFull;
    } else if (spotsLeft > 250 && spotsLeft <= 500) {
      attendeeCopy = ATTENDEE_COPY.lastSpots;
    } else if (spotsLeft > 500 && spotsLeft <= 750) {
      attendeeCopy = ATTENDEE_COPY.limitedSlots;
    }

    return (
      <div className={`attendees`}>
        {spotsLeft > 0 ? (
          <span style={{ color: attendeeCopy.color }}>{attendeeCopy.message}</span>
        ) : (
          "No spots left"
        )}
      </div>
    );
  };

  const teacher = (liveEvent) => (
    <div className="teacher">
      Teacher: <span className="name">{liveEvent.teacher_name}</span>
      {width > 992 && liveEvent.teacher_description && (
        <span className="description"> - {liveEvent.teacher_description}</span>
      )}
    </div>
  );

  const attend = (liveEvent) => {
    window.location.href = `/clubs/${liveEvent.id}/attend`;
  };

  const unattend = (liveEvent) => {
    window.location.href = `/clubs/${liveEvent.id}/unattend`;
  };

  const goToEvent = (liveEvent) => {
    window.location.href = liveEvent.url;
  };

  const getUnique = (events, key) => {
    const seen = new Set();
    return events
      .filter((event) => {
        return event[key] && !seen.has(event[key]) && seen.add(event[key]);
      })
      .map((event) => event[key]);
  };

  const hasEventsToday = pageData.liveEvents.some((event) =>
    moment(event.scheduled_date).isSame(moment(), "day")
  );

  const hasEventsThisWeek = pageData.liveEvents.some((event) =>
    moment(event.scheduled_date).isSame(moment(), "week")
  );

  const thumbClick = (liveEvent) => {
    if (pageData.eventType === "club" && liveEvent.reference === "Blues-with-Justin") {
      window.location.href = "/live-events/blues-with-justin";
    }
  };

  const happeningNow = (liveEvent) => {
    const start = moment(liveEvent.scheduled_date);
    return start.isSame(new Date(), "hour");
  };

  const eventDate = (liveEvent) => {
    return (
      moment(liveEvent.scheduled_date).format(
        `ddd, MMM DD YYYY · HH:mm [UTC]${
          moment(liveEvent.scheduled_date).format("Z").startsWith("-") ? "" : "+"
        }${parseInt(moment(liveEvent.scheduled_date).format("Z").split(":")[0], 10)}`
      ) + " (Your Local Time)"
    );
  };

  const uniqueReferences = getUnique(pageData.liveEvents, "reference");

  const uniqueTeachers = getUnique(pageData.liveEvents, "teacher_name");

  return (
    <div className="live-events">
      <ConfirmationDialog
        showConfirmDialog={showConfirmDialog}
        setShowConfirmDialog={setShowConfirmDialog}
      />

      <SelectedEventDialog
        selectedLiveEvent={selectedLiveEvent}
        setSelectedLiveEvent={setSelectedLiveEvent}
        attend={attend}
        unattend={unattend}
      />

      <LiveEventsSwoosh title={pageData.title} pageData={pageData} />

      <div className="container">
        <div className="section intro">
          <h2 className="title">{pageData.introTitle}</h2>
          <p>{pageData.introDescription}</p>
          {pageData.eventType === "club" && (
            <a href="/clubs#list" className="button button--primary">
              Browse {pageData.typeName}
            </a>
          )}
          {pageData.eventType === "pmt" && !pageData.pmtSubscriber && (
            <a
              href="/store/practical-music-theory"
              className="button button--primary"
              target="_blank"
            >
              Subscribe & Start Learning
            </a>
          )}
          {pageData.eventType === "pmt" && pageData.pmtSubscriber && (
            <a href="/live-events/music-theory#faq" className="button button--success">
              Questions? Read FAQ!
            </a>
          )}
          {pageData.eventType === "blues" && pageData.faqs && (
            <a href="/live-events/blues-with-justin#faq" className="button button--primary">
              Read More
            </a>
          )}

          {pageData.introVideoId && (
            <LiteYoutube url={pageData.introVideoId} hasMaxResImage={true} />
          )}
        </div>

        <div className="section live-event-list" id="list">
          <h2 className="title">{pageData.liveEventListTitle}</h2>
          <p className="notice">
            Please note this schedule may change without notice, and an event might be canceled or
            rescheduled in case of a last-minute emergency.
          </p>
          {!currentUser && (
            <div className="login-prompt">
              <LoginPrompt
                loginParagraph={
                  pageData.eventType === "pmt"
                    ? `Log in to Join a Music Theory Live Classes (available for Practical Music Theory subscribers only)`
                    : `Log in to join a Club for free!`
                }
                token={token}
              />
            </div>
          )}
          {pageData.liveEvents.length > 0 && (
            <div className="filters">
              <button className="filter-toggle" onClick={() => setFiltersVisible(!filtersVisible)}>
                <FilterIcon /> {filtersVisible ? "close" : "open"} filters
              </button>
              {filtersVisible && (
                <>
                  {pageData.eventType !== "blues" &&
                    pageData.eventType !== "pmt" &&
                    uniqueReferences.length > 1 && (
                      <select
                        value={referenceFilter}
                        onChange={(e) => setReferenceFilter(e.target.value)}
                      >
                        <option value="all">Any Event</option>
                        {uniqueReferences.map((ref, idx) => (
                          <option key={idx} value={ref}>
                            {ref.replaceAll("-", " ")}
                          </option>
                        ))}
                      </select>
                    )}
                  {(hasEventsToday || hasEventsThisWeek) && (
                    <select value={dateFilter} onChange={(e) => setDateFilter(e.target.value)}>
                      <option value="all">All Dates</option>
                      {hasEventsToday && <option value="today">Today</option>}
                      {hasEventsThisWeek && <option value="this week">This Week</option>}
                    </select>
                  )}
                  {pageData.eventType !== "blues" &&
                    pageData.eventType !== "pmt" &&
                    uniqueTeachers.length > 1 && (
                      <select
                        value={teacherFilter}
                        onChange={(e) => setTeacherFilter(e.target.value)}
                      >
                        <option value="all">All Teachers</option>
                        {uniqueTeachers.map((ref, idx) => (
                          <option key={idx} value={ref}>
                            {ref}
                          </option>
                        ))}
                      </select>
                    )}
                  <button
                    className="filter-btn"
                    onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
                  >
                    Sort By Date
                    {sortOrder === "asc" ? (
                      <CaretUpIcon color="#406e8e" />
                    ) : (
                      <CaretDownIcon color="#406e8e" />
                    )}
                  </button>
                  {(hasEventsToday ||
                    hasEventsThisWeek ||
                    uniqueReferences.length > 1 ||
                    uniqueTeachers.length > 1) && (
                    <button className="reset-btn" onClick={resetFilters}>
                      Reset Filters
                    </button>
                  )}
                </>
              )}
            </div>
          )}
          <div className="live-event-cards">
            {filteredEvents.length ? (
              filteredEvents.slice(0, eventsToShow).map((liveEvent, idx) => (
                <div key={idx} className="live-event-card">
                  <div
                    className={`thumb ${liveEvent.reference.toLowerCase()} ${
                      pageData.eventType === "club" && liveEvent.reference === "Blues-with-Justin"
                        ? "clickable"
                        : ""
                    }`}
                    onClick={() => thumbClick(liveEvent)}
                  >
                    {liveEvent.attending && (
                      <div className="attending">
                        <CheckIcon /> {liveEvent.ended ? "you were attending" : "you are attending"}
                      </div>
                    )}

                    {happeningNow(liveEvent) && <div className="happening-now">· Live</div>}

                    <h3>{liveEvent.title}</h3>

                    {liveEvent.ended && <div className="ended-overlay"></div>}
                  </div>
                  <div className="content">
                    <div className="top-info">
                      <div className={`date${liveEvent.ended ? " ended" : ""}`}>
                        {liveEvent.ended ? "This event is over" : eventDate(liveEvent)}
                      </div>
                      {width > 992 && attendees(liveEvent)}
                    </div>
                    <div className="middle">
                      <div className="description">
                        {liveEvent.subtitle && <h3>{liveEvent.subtitle}</h3>}
                        <p>{liveEvent.description}</p>
                      </div>
                      {width > 992 && ctaButtons(liveEvent)}
                    </div>
                    {width <= 992 && teacher(liveEvent)}
                    {width <= 992 && ctaButtons(liveEvent)}
                    <div className="bottom-info">
                      {width > 992 && teacher(liveEvent)}
                      {width <= 992 && attendees(liveEvent)}
                      {liveEvent.level != null && (
                        <span className={`grade-badge grade-badge--grade-${liveEvent.level}`}>
                          {parseInt(liveEvent.level) === 10 || parseInt(liveEvent.level) === 0
                            ? "All Levels"
                            : `Grade ${liveEvent.level}+`}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>Currently there are no events.</p>
            )}
          </div>
          {pageData.liveEvents.length > 6 && eventsToShow < pageData.liveEvents.length && (
            <button className="button button--primary" onClick={() => viewAll()}>
              View All
            </button>
          )}

          <DonateBanner eventType={pageData.eventType} width={width} />
        </div>
        <div className="faq" id="faq">
          <Category faqs={pageData.faqs} />
        </div>
      </div>
    </div>
  );
};

export default withInnerWidth(LiveEvents);
