import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import LoginPrompt from "../../../components/auth/LoginPrompt";
import { withInnerWidth } from "../../../components/shared";
import PricingOverview from "./PricingOverview";
import PricingPlans from "./PricingPlans";
import CallToActionButton from "./CallToActionButton";
import axiosWithCSRF from "../../../components/shared/axiosWithCSRF";
import Loading from "../../../components/shared/Loading";

// TODO: we can make this dynamic based on the pricing plan
export const TABS_TRIAL_DAYS = 7;
export const CURRENCY = "$";
export const ONERROR_PLAN_PARAM_NAME = "plan";

const TabsPlanSelect = ({ pricingPlans, trialAvailable, user, width }) => {
  const [selectedPlan, setSelectedPlan] = useState(
    pricingPlans.find((plan) => plan.attributes.intervalInMonths === 12)
  );
  const [loading, setLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);

  const planParam = urlParams.get(ONERROR_PLAN_PARAM_NAME);

  useEffect(() => {
    if (planParam) {
      const planParamNumeric = isNaN(planParam) ? null : parseInt(planParam);
      if (planParamNumeric || planParamNumeric === 0) {
        const plan = pricingPlans.find((plan) => parseInt(plan.id) === planParamNumeric);
        if (plan) {
          setSelectedPlan(plan);
        }
      }
    }
  }, [planParam]);

  const eventSubscribe = useCallback(() => {
    setLoading(true);
    axiosWithCSRF()
      .post("/payments/tabs/checkout_sessions", {
        pricing_plan_id: selectedPlan.id,
        cancel_url: `${window.location.origin}/store/tabs?${ONERROR_PLAN_PARAM_NAME}=${selectedPlan.id}#plans`,
      })
      .then((response) => (window.location = response.data.url))
      .catch(() => {
        setErrorMessage(
          "There was a problem processing your request. Please contact hello@justinguitar.com for assistance."
        );
        setLoading(false);
      });
  }, [selectedPlan]);

  if (!user) {
    return (
      <div className="tabs-payment">
        <div className="jgtabs__needs-login">
          <h2 id="tabs">start my {TABS_TRIAL_DAYS}-day free trial</h2>
          <LoginPrompt token={ReactOnRails.authenticityToken()} loginAction="subscribe" />
        </div>
      </div>
    );
  } else {
    return (
      <div className="tabs-payment">
        <div className="tabs-payment__trial">
          <PricingOverview trialAvailable={trialAvailable} />

          <PricingPlans
            pricingPlans={pricingPlans}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
          />

          {loading && <Loading coverViewport />}

          <CallToActionButton
            eventClick={() => eventSubscribe()}
            trialAvailable={trialAvailable}
            disabled={loading}
          />
        </div>
      </div>
    );
  }
};

TabsPlanSelect.propTypes = {
  pricingPlans: PropTypes.arrayOf(PropTypes.object).isRequired,
  trialAvailable: PropTypes.bool.isRequired,
  user: PropTypes.object,
  width: PropTypes.number.isRequired,
};

export default withInnerWidth(TabsPlanSelect);
