import axiosWithCSRF from "../components/shared/axiosWithCSRF";

export const createTrackedAction = (recordType, recordId, actionType) => {
  return axiosWithCSRF().post("/tracked_actions", {
    tracked_action: {
      trackable_type: recordType,
      trackable_id: recordId,
      action_type: actionType,
    },
  });
};

export const TRACKED_ACTION_ACTION_TYPES = {
  click: "click",
  download: "download",
};

export const TRACKED_ACTION_RECORD_TYPES = {
  download: "Downloadable",
};
