import { schema } from "normalizr";

const lesson = new schema.Entity("lesson");
const song = new schema.Entity("song");
const group = new schema.Entity("group");
const product = new schema.Entity("product");
const resource = new schema.Entity("resource");

const searchableEntities = { lesson, song, group, product, resource };

const serializedCollection = (entity) =>
  new schema.Object({
    data: new schema.Array(entity),
    included: new schema.Array(searchableEntities, (entity) => entity.type),
  });

export const advancedSearchResults = new schema.Object(
  Object.entries(searchableEntities).reduce(
    (accum, [entityName, entity]) => ({
      ...accum,
      [entityName]: serializedCollection(entity),
    }),
    {}
  )
);
