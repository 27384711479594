import React from "react";
import ReactOnRails from "react-on-rails";
import { Provider } from "react-redux";
import { CDN_URL } from "../components/shared/CdnUrl";

import { SongsIndexArtistHeader } from "../components/songs/SongsIndexHeader";
import SongsListing from "../components/songs/SongsListing";
import SongSnackbar from "../components/songs/SongSnackbar";
import * as bootstrapHelpers from "../components/shared/bootstrap-helpers";

require("../components/songs/store/songStore");

class ArtistPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isMdDevice() {
    return (
      window.innerWidth <= bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.lgDevice]
    );
  }

  render() {
    const store = ReactOnRails.getStore("songsIndexStore");

    return (
      <div className="songs-index-page">
        <Provider store={store}>
          <SongsIndexArtistHeader artist={this.props.artist} />

          <div className="swooosh__content">
            <div className="container" style={{ paddingTop: "40px" }}>
              <div className="clear-artist">
                <a href="/songs">
                  <span>← All Songs</span>
                </a>
              </div>

              <SongsListing hideListingControls={true} artist={this.props.artist} />
              {/* TODO: add img to cdn and change here */}
              <a className="tabs-cta" href="/tabs">
                <img alt="JustinGuitar Tabs" src={`${CDN_URL}/images/products/tabs-cta.png`} />
              </a>
            </div>
          </div>
        </Provider>
      </div>
    );
  }
}

export default ArtistPage;
