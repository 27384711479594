// for some reason Js files can't import axiosWithCSRF when it's a .ts file,
// so we'll just have two files for now, and look in to this import issue later

// wrapper around axios to include Rails CSRF token
// adapted from: https://github.com/rails/webpacker/issues/1015#issuecomment-443268352

import axios from "axios";

export function axiosWithCSRF(customArgs: Record<string, any> = {}) {
  const { content }: HTMLMetaElement =
    document.querySelector('[name="csrf-token"]') ||
    ({ content: "no-csrf-token" } as HTMLMetaElement);

  return axios.create({
    headers: {
      ...customArgs,
      common: {
        "X-CSRF-Token": content,
        "Content-Type": "application/json",
      },
    },
  });
}

export default axiosWithCSRF;
