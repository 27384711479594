import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LessonTile from "../shared/LessonTile";
import SongTile from "../shared/SongTile";
import { goToLesson } from "../shared/utils";
import { deleteAllRecentlyViewed } from "../../components/shared/session/sessionEffects";
import Dialog from "../../components/shared/ui/Dialog";
import { openDialog } from "../../components/shared/ui/uiSlice";

const ContinueLearningList = ({ recentPageviews }) => {
  if (recentPageviews.length > 0) {
    return recentPageviews.map((item, idx) => {
      const { title, gradeId, groupDisplayTitle, artistDisplayName, albumThumb, slug } =
        item.attributes;
      return (
        <div
          key={idx}
          className="clickable continue-learning__item"
          onClick={() => goToLesson(item.attributes.viewableType, slug)}
        >
          {item.attributes.viewableType === "Lesson" ? (
            <LessonTile title={title} gradeId={gradeId} groupTitle={groupDisplayTitle} />
          ) : (
            <SongTile
              title={title}
              gradeId={gradeId}
              artistName={artistDisplayName}
              albumThumb={albumThumb}
            />
          )}
        </div>
      );
    });
  } else {
    return (
      <span className="no-items">
        You don't have any recently viewed lessons or songs yet. This section will automatically
        display your most recently viewed lessons and songs.
      </span>
    );
  }
};

const ContinueLearningNew = ({ user, recentPageviews, deleteAllRecentlyViewed, openDialog }) => {
  const [activeLessonType, setActiveLessonType] = React.useState("Lesson");
  return (
    <div className="continue-learning">
      <p className="welcome__heading welcome__heading--white">Resume</p>
      <div className="continue-learning__list">
        <ContinueLearningList recentPageviews={recentPageviews} />
      </div>
      <Dialog onConfirm={() => deleteAllRecentlyViewed({ userId: user.data.id })} />
    </div>
  );
};

const mapStateToProps = (ownProps) => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  deleteAllRecentlyViewed: (userId) => dispatch(deleteAllRecentlyViewed(userId)),
  openDialog: (data) => dispatch(openDialog(data)),
});

ContinueLearningNew.propTypes = {
  user: PropTypes.object.isRequired,
  recentPageviews: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContinueLearningNew);
