export const filterItems = (items, startDate, endDate) => {
  let beginning = new Date(Date.parse(startDate)).setHours(0, 0, 0, 0);
  let end = new Date(Date.parse(endDate)).setHours(0, 0, 0, 0);

  let filtered = [];
  if (items) {
    filtered = items.filter(
      (item) =>
        new Date(item.date).setHours(0, 0, 0, 0) <= end &&
        new Date(item.date).setHours(0, 0, 0, 0) >= beginning
    );
  }
  return filtered;
};

export const formatTime = (seconds) => {
  if (seconds < 60) {
    return seconds === 0 ? "" : `${seconds} seconds`;
  } else if (seconds < 3600) {
    let mins = Math.floor(seconds / 60);
    let secs = seconds - mins * 60;
    return secs === 0 ? `${mins} mins` : `${mins} mins and ${secs} sec`;
  } else {
    let hours = Math.floor(seconds / 3600);
    let mins = (seconds - hours * 3600) / 60;
    let hourText = hours === 1 ? "hour" : "hours";
    let minText = mins === 1 ? "min" : "mins";
    return `${hours} ${hourText} ${mins} ${minText}`;
  }
};

export const practiceSum = (items) => {
  let sum = 0;
  if (items && items.length > 0) {
    items.forEach(function (item) {
      sum += parseInt(item.seconds);
    });
    return sum;
  } else {
    return 0;
  }
};
