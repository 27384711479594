import React from "react";
import PropTypes from "prop-types";
import axiosWithCSRF from "../shared/axiosWithCSRF";
import ReactDOM from "react-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PasswordAndConfirmationManagement from "./PasswordAndConfirmationManagement";
import Loading from "../shared/Loading";
import { Errors } from "./Errors";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Please provide a valid email.").required("Email address is required."),
  password: Yup.string().required("Password is required."),
});

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      showResendLink: false,
    };
  }

  componentDidMount() {
    const { error } = this.props;
    if (error && Errors[error]) {
      this.setState({ error: Errors[error] });
    }
  }

  handleSubmit = (vals, resetForm) => {
    this.setState({ isSubmitting: true }, () => {
      axiosWithCSRF({
        "Content-Type": "application/json",
        Accept: "application/json",
      })
        .post("/users/sign_in", {
          user: {
            email: vals.email,
            password: vals.password,
            remember_me: vals.rememberMe,
          },
        })
        .then((response) => {
          if (this.props.discourseSignIn) {
            window.location.href = `/discourse-sso?sso=${this.props.sso}&sig=${this.props.sig}`;
          } else {
            window.location.reload(true);
          }
        })
        .catch((error) => {
          let responseError = error.response.data.error;
          let showResendLink = responseError === "You must confirm your email before logging in";
          this.setState({
            error: responseError,
            isSubmitting: false,
            showResendLink,
          });
          this.emailInput.focus();
          //resetForm();
          setTimeout(() => this.setState({ error: null }), 4000);
        });
    });
  };

  resendConfirmation = () => {
    if (this.state.showResendLink) {
      return (
        <div className="auth__signup" style={{ marginTop: "0" }}>
          <p>
            <a href="/users/confirmation/new">Resend Email Confirmation</a>
          </p>
        </div>
      );
    }
  };

  submitIsDisabled = (values) => {
    if (values.email.length === 0 || values.password.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <div className="auth">
        <div className="auth__container">
          {this.state.isSubmitting && <Loading />}
          <h3 className="header3 uppercase login-header">Log In</h3>

          <Formik
            initialValues={{
              email: "",
              password: "",
              rememberMe: "",
            }}
            validationSchema={LoginSchema}
            onSubmit={(values, { resetForm }) => {
              this.handleSubmit(values, resetForm);
            }}
          >
            {(props) => (
              <Form className="auth__form">
                {this.state.error && <p className="has-error">{this.state.error}</p>}
                {this.resendConfirmation()}
                <div className="auth__input">
                  <Field
                    name="email"
                    type="email"
                    autoFocus
                    innerRef={(input) => {
                      this.emailInput = input;
                    }}
                    className={
                      (props.values.email.length > 0 ? "populated" : "") +
                      (props.errors.email && props.touched.email ? " has-error" : "")
                    }
                  />

                  {props.touched.email && !props.errors.email && (
                    <i className="auth__response-icon icon icon-check"></i>
                  )}
                  <span className="auth__label">Email</span>
                  {props.errors.email && props.touched.email && (
                    <>
                      <i className="auth__response-icon icon icon-cancel-circle"></i>
                      <div className="error-message">{props.errors.email}</div>
                    </>
                  )}
                </div>
                <div className="auth__input">
                  <Field
                    name="password"
                    type="password"
                    className={
                      (props.values.password.length > 0 ? "populated" : "") +
                      (props.errors.password && props.touched.password ? " has-error" : "")
                    }
                  />

                  {props.touched.password && !props.errors.password && (
                    <i className="auth__response-icon icon icon-check"></i>
                  )}
                  <span className="auth__label">Password</span>
                  {props.errors.password && props.touched.password && (
                    <>
                      <i className="auth__response-icon icon icon-cancel-circle"></i>
                      <div className="error-message">{props.errors.password}</div>
                    </>
                  )}
                </div>

                <div className="auth__side-by-side">
                  <div>
                    <input type="checkbox" id="rememberMe"></input>
                    <label htmlFor="rememberMe">Remember Me</label>
                  </div>

                  <a href="/users/password/new">Forgot your password?</a>
                </div>

                <button
                  disabled={this.submitIsDisabled(props.values)}
                  className="button button--primary auth__btn"
                  type="submit"
                >
                  Log In
                </button>
                <div className="auth__signup">
                  <p>
                    Don't have an account? <a href="/users/sign_up">Register.</a>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  token: PropTypes.string.isRequired,
};
