import React from "react";
import { connect } from "react-redux";

import Menu from "./Menu";
import SearchHeader, { openSearch } from "./Search";
import AuthButtons from "./AuthButtons";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CDN_URL } from "../shared/CdnUrl";

const STACKED_LOGO_LARGE = `${CDN_URL}/redesign/2023/logos/justinguitar-logo-hr-dark.svg`;
const STACKED_LOGO_LARGE_LIGHT = `${CDN_URL}/redesign/2023/logos/justinguitar-logo-hr.svg`;
const ANIMATION_TIMEOUT_MS = 200;

export function MediumHeader({ openSearch, searchOpen, isProductBundlesPage }) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  let logo = STACKED_LOGO_LARGE;
  if (isProductBundlesPage) {
    logo = STACKED_LOGO_LARGE_LIGHT;
  }

  return (
    <>
      <div className="header__menu">
        <div className="header__left">
          <div className="menu-button clickable" onClick={() => setMenuOpen(!menuOpen)}>
            <span className="uppercase">Menu</span>
            <i className="icon icon-angle-down" />
          </div>
          <div className="clickable" onClick={() => openSearch()}>
            <i className="icon icon-search-thin" />
          </div>
        </div>
        <div className="header__center header__logo">
          <a href="/">
            <img src={logo} alt="JustinGuitar Logo" />
          </a>
        </div>
        <div className="header__right">
          <AuthButtons />
        </div>
      </div>

      {menuOpen && <Menu close={() => setMenuOpen(false)} />}
    </>
  );
}

export default connect(({ searchOpen }) => ({ searchOpen }), { openSearch })(MediumHeader);
