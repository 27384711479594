import React from "react";

import { POPULAR_SEARCH_ITEMS, QUICK_LINKS, LOCAL_STORAGE_KEY } from "../../shared/search";

export default class StaticLinks extends React.Component {
  constructor(props) {
    super(props);

    this.state = { previousSearches: [], confirmClear: false };
  }

  componentDidMount() {
    this.setPreviousSearchResults();
  }

  setPreviousSearchResults = () => {
    const previousSearches = this.retrievePreviousSearchItems();
    this.setState({ previousSearches });
  };

  retrievePreviousSearchItems() {
    const searchItems = localStorage.getItem(LOCAL_STORAGE_KEY);
    return searchItems ? JSON.parse(searchItems) : [];
  }

  clearPreviousSearches = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    this.setState({ previousSearches: [] });
  };

  clearButton = () => {
    const { previousSearches, confirmClear } = this.state;
    if (previousSearches && confirmClear) {
      return <span onClick={this.clearPreviousSearches}>clear</span>;
    } else if (previousSearches) {
      return <i onClick={() => this.setState({ confirmClear: true })} />;
    }
  };

  render() {
    const { previousSearches } = this.state;

    const hasPreviousSearches = Array.isArray(previousSearches) && previousSearches.length;

    return (
      <>
        {hasPreviousSearches ? (
          <Links
            title={"Your Previous Searches"}
            Button={this.clearButton()}
            links={previousSearches}
          />
        ) : (
          <Links title={"Quick Links"} Button={this.clearButton()} links={QUICK_LINKS} />
        )}
        <Links title={"Popular Right Now"} links={POPULAR_SEARCH_ITEMS} button={null} />
      </>
    );
  }
}

const Links = ({ title, links, Button }) => (
  <div className="search__recommendations">
    <div className="recommendation__header">
      <p>{title}</p>
      {Button}
    </div>

    <div className="search__options">
      {links.map((l) => (
        <a className="search__recommendation" href={l.link} key={l.title}>
          {l.title}
        </a>
      ))}
    </div>
  </div>
);
