import React from "react";
import { connect } from "react-redux";

import SearchResultsGroup from "./SearchResultsGroup";
import NoResults from "./NoResults";
import { searchResultsTypes, searchResultsTypeToDisplayName } from "../../shared/search";

const SearchResults = ({ query, results, loading }) => {
  const hasQuery = query && query.length >= 2 && !loading;

  if (!hasQuery) {
    return null;
  }

  const hasResults = Object.keys(results).length > 0;

  if (!hasResults) {
    return <NoResults query={query} />;
  }

  return (
    <div className="search__results">
      {Object.keys(searchResultsTypes)
        .map((type) => ({
          key: type,
          title: searchResultsTypeToDisplayName[type],
        }))
        .map(({ title, key }) => (
          <div className="search__section" key={key}>
            <span className="search__heading">{title}</span>
            <SearchResultsGroup items={results[key]} query={query} />
          </div>
        ))}
    </div>
  );
};

export default connect(({ query, results, loading }) => ({
  query,
  results,
  loading,
}))(SearchResults);
