import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  Grid,
  Box,
  Checkbox,
  Button,
  FormControlLabel,
} from "@mui/material";
import { CheckCircleOutline, Visibility, VisibilityOff, Cancel } from "@mui/icons-material";
import {
  GROUPED_FIELDS,
  FORM_FIELDS,
  CHECKBOX_FIELDS,
  REGISTRATION_SCHEMA,
  SECTIONS,
} from "./consts";
import { RECAPTCHA_KEY } from "../../new-payments/consts";
import ReCAPTCHA from "react-google-recaptcha";
import SignInLink from "./SignInLink";
import axiosWithCSRF from "../../shared/axiosWithCSRF";
import Loading from "../../shared/Loading";
import AppDisclaimerLink from "./AppDisclaimerLink";
import { SquareTextField } from "../../../jg-material/theme/components/styled/SquareTextField";
import InvisibleRecaptcha from "../../InvisibleRecaptcha";

const RegistrationForm = ({ setActiveSection, setActivePage }) => {
  const {
    handleSubmit,
    control,
    setError,
    getFieldState,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(REGISTRATION_SCHEMA),
    mode: "onTouched",
  });

  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [executeRecaptcha, setExecuteRecaptcha] = useState(false);
  const [resetRecaptcha, setResetRecaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(null);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (recaptchaToken) {
      postForm(formData);
    }
  }, [recaptchaToken]);

  const errorFormatter = (field, message) => {
    return message.replace(field.name, field.errorName || field.placeholder).trim();
  };

  const onSubmit = (data) => {
    setFormData(data);
    setLoading(true);
    setExecuteRecaptcha(true);
  };

  const postForm = (data) => {
    setResetRecaptcha(false);
    axiosWithCSRF()
      .post("/users", {
        user: {
          first_name: data.firstName.trim(),
          last_name: data.lastName.trim(),
          email: data.email.trim(),
          password: data.password,
          password_confirmation: data.password,
          data_consent: data.termsConsent,
          email_consent: data.emailConsent,
          username: data.username.trim(),
        },
        recaptcha_token: recaptchaToken,
      })
      .then(() => {
        setActiveSection(SECTIONS.success);
      })
      .catch((error) => {
        setResetRecaptcha(true);
        setExecuteRecaptcha(false);
        setRecaptchaToken(null);
        const errors = error?.response?.data?.errors;
        if (errors) {
          error.response.data.errors.forEach((error) => {
            setError(error.source.parameter, {
              type: "custom",
              message: error.title,
            });
          });
        } else {
          setError("root.serverError", {
            type: `${error.response.status}`,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const inputAdornment = (formField) => {
    const currentInputState = getFieldState(formField.name);

    if (formField.type === "password") {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowPassword((show) => !show)}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      );
    } else if (
      !!errors[formField.name]?.message === false &&
      currentInputState.isTouched === true
    ) {
      return (
        <InputAdornment position="end">
          <CheckCircleOutline color="success" />
        </InputAdornment>
      );
    } else if (!!errors[formField.name]?.message) {
      return (
        <InputAdornment position="end">
          <Cancel color="error" />
        </InputAdornment>
      );
    } else {
      return "";
    }
  };

  const inputField = (formField) => {
    return (
      <>
        <Controller
          name={formField.name}
          control={control}
          render={({ field }) => {
            return (
              <SquareTextField
                {...field}
                margin="normal"
                size="medium"
                className={field?.value?.length > 0 ? "Mui-filled" : ""}
                required
                fullWidth
                label={formField.placeholder}
                type={formField.type === "password" && showPassword ? "text" : formField.type}
                error={errors[formField.name] ? true : false}
                helperText={
                  errors[formField.name] &&
                  errorFormatter(formField, errors[formField.name].message)
                }
                InputProps={{
                  endAdornment: inputAdornment(formField),
                }}
              />
            );
          }}
        />
      </>
    );
  };

  const checkBoxField = (formField) => {
    return (
      <Controller
        name={formField.name}
        control={control}
        render={({ field }) => (
          <FormControl error={!!errors[formField.name]}>
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  value={formField.name}
                  color="primary"
                  required={formField.required}
                />
              }
              label={
                <span>
                  {formField.label}
                  {formField.link && (
                    <Link href={formField.link.location} target="_blank">
                      {formField.link.text}
                    </Link>
                  )}
                  {formField.required && "*"}
                </span>
              }
            />
            {errors[formField.name] && (
              <FormHelperText sx={{ margin: "-10px 0px 10px", fontSize: "12px" }}>
                {errors[formField.name].message}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {errors.root?.serverError && (
          <FormHelperText sx={{ fontSize: "12px" }} error={true}>
            There was a problem submitting your request. Please try again later.
          </FormHelperText>
        )}

        {loading && <Loading />}
        <Box>
          <Grid container spacing={1} justify="space-between">
            {GROUPED_FIELDS.map((groupedField) => (
              <Grid item xs={6} key={groupedField.name}>
                {inputField(groupedField)}
              </Grid>
            ))}
          </Grid>

          {FORM_FIELDS.map((formField) => (
            <React.Fragment key={formField.name}>{inputField(formField)}</React.Fragment>
          ))}

          {CHECKBOX_FIELDS.map((formField) => (
            <React.Fragment key={formField.name}>{checkBoxField(formField)}</React.Fragment>
          ))}

          <Button
            className="button button--primary"
            type="submit"
            disabled={!isValid || loading}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, boxShadow: "none" }}
          >
            Sign Up
          </Button>

          <SignInLink setActivePage={setActivePage} />

          <AppDisclaimerLink setActiveSection={setActiveSection} />
        </Box>
      </form>

      <InvisibleRecaptcha
        shouldExecute={executeRecaptcha}
        shouldReset={resetRecaptcha}
        onSuccess={(token) => setRecaptchaToken(token)}
      />
    </>
  );
};

export default RegistrationForm;
