import { createSelector } from "@reduxjs/toolkit";
import {
  filterOptionToQueryValue,
  filters,
  filters as availableFilters,
  filtersByType,
  filterTypes,
  filterTypeToQueryValue,
} from "../filters";
import { sortByOptions } from "./shared";
import "../../shared/polyfills/replaceAll.js";

import Fuse from "fuse.js";

export const selectSongState = (state) => state;
export const selectEntities = createSelector(selectSongState, ({ entities }) => entities);
export const selectUi = createSelector(selectSongState, ({ ui }) => ui);
export const selectSession = createSelector(selectSongState, ({ session }) => session);
export const selectMetadata = createSelector(selectSongState, ({ metadata }) => metadata);

// UI
export const selectSortBy = createSelector(selectUi, (ui) => ui.sortBy);
export const selectArtistSortBy = createSelector(selectUi, (ui) => ui.artistSortBy);
export const selectFiltersOpen = createSelector(selectUi, (ui) => ui.filtersOpen);
export const selectArtistFiltersOpen = createSelector(selectUi, (ui) => ui.artistFiltersOpen);
export const selectQuery = createSelector(selectUi, (ui) => ui.query);
export const selectListingStyle = createSelector(selectUi, (ui) => ui.listingStyle);
export const selectSnackbarAlert = createSelector(selectUi, (ui) => ui.alert);
export const selectHasAlert = createSelector(
  selectSnackbarAlert,
  ({ message }) => message != null && message.length > 0
);
export const selectAlertMessage = createSelector(selectSnackbarAlert, ({ message }) => message);
export const selectAlertSeverity = createSelector(selectSnackbarAlert, ({ severity }) => severity);
export const selectCurrentArtistSlug = createSelector(selectUi, (ui) => ui.currentArtistSlug);
export const selectSongRequestConflicts = createSelector(selectUi, (ui) => ui.songRequestConflicts);
export const selectOrderedIds = createSelector(selectUi, (ui) => ui.orderedIds);

// UI->Loading
export const selectLoading = createSelector(selectUi, (ui) => ui.loading);
export const selectListingLoading = createSelector(selectLoading, (loading) => loading.listing);
export const selectLatestReleasesLoading = createSelector(
  selectLoading,
  (loading) => loading.latestReleases
);
export const selectArtistsLoading = createSelector(selectLoading, (loading) => loading.artists);
export const selectCurrentUserSongRequestsListingLoading = createSelector(
  selectLoading,
  (loading) => loading.currentUserSongRequestsListing
);
export const selectSongRequestFormLoading = createSelector(
  selectLoading,
  (loading) => loading.songRequestFormLoading
);

// UI->Filters
export const selectFilters = createSelector(selectUi, (ui) => ui.filters);
export const selectArtistFilters = createSelector(selectUi, (ui) => ui.artistFilters);
export const selectHasActiveFilters = createSelector(
  selectFilters,
  (filters) => Object.keys(filters).length > 0
);

export const selectHasActiveArtistFilters = createSelector(
  selectArtistFilters,
  (filters) => Object.keys(filters).length > 0
);

// Session
export const selectCurrentUser = createSelector(selectSession, (session) => session.currentUser);
export const selectIsLoggedIn = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser != null
);
export const selectSavedSongs = createSelector(selectSession, ({ savedSongs }) => savedSongs || {});
export const selectPracticeSongs = createSelector(
  selectSession,
  ({ practiceSongs }) => practiceSongs || {}
);

// entities
export const selectSongsDictionary = createSelector(selectEntities, (entities) => {
  return entities.songs || {};
});
export const selectSongsArray = createSelector(selectSongsDictionary, (songs) =>
  Object.values(songs)
);
export const selectLatestReleasesOrderedSongs = createSelector(selectSongsDictionary, (songs) =>
  Object.values(songs)
    .sort((a, b) => new Date(b.attributes.pageReleaseDate) - new Date(a.attributes.pageReleaseDate))
    .slice(0, 15)
);
export const selectProductsDictionary = createSelector(selectEntities, (entities) => {
  return entities.products || {};
});
export const selectProductsForSong = createSelector(
  selectSongsDictionary,
  selectProductsDictionary,
  (songs, products) => (songId) => {
    const song = songs[songId];

    if (song == null) {
      return [];
    }

    return (song.relationships.products.data || [])
      .map(({ id }) => products[id])
      .filter((product) => product != null);
  }
);

export const selectAppsForSong = createSelector(
  selectProductsForSong,
  (productSelector) => (songId) => {
    const products = productSelector(songId).map(({ attributes }) => attributes);
    return products.filter(({ typeOf }) => typeOf === "app");
  }
);

// metadata
export const selectSongMetadata = createSelector(selectMetadata, (metadata) => metadata.songs);
export const selectSongCount = createSelector(selectMetadata, (metadata) => metadata.songCount);

//artists
export const selectArtists = createSelector(selectEntities, ({ artists }) => artists || {});
export const selectArtistsArray = createSelector(selectArtists, (artists) =>
  Object.values(artists)
);
export const selectListingArtists = createSelector(selectUi, (ui) => ui.listingArtists);
export const selectArtistMetadata = createSelector(selectMetadata, (metadata) => metadata.artists);
export const selectArtistQuery = createSelector(selectUi, (ui) => ui.artistQuery);

export const selectListingSongs = createSelector(selectUi, (ui) => ui.listingSongs);
export const selectGrades = createSelector(selectEntities, (entities) => entities.grades);
export const selectChords = createSelector(selectEntities, (entities) => entities.chords);
export const selectTags = createSelector(selectEntities, (entities) => entities.tags);
export const selectProducts = createSelector(selectEntities, (entities) => entities.products);
export const selectGenres = createSelector(selectEntities, (entities) => entities.genres);

export const selectSongsByArtist = createSelector(
  selectSongsDictionary,
  (songs) => (artist) =>
    artist.relationships.songs.data.map(({ id }) => songs[id]).filter((song) => song != null)
);

export const selectCurrentArtist = createSelector(
  selectArtistsArray,
  selectCurrentArtistSlug,
  (artists, artistSlug) =>
    artistSlug == null
      ? undefined
      : artists.find(
          ({ attributes: { name } }) => artistSlug === name.toLowerCase().replaceAll(" ", "-")
        )
);

// song requests
export const selectSongRequestsDictionary = createSelector(
  selectEntities,
  (entities) => entities.songRequests || {}
);
export const selectPendingSongRequestOrderedIds = createSelector(
  selectOrderedIds,
  (orderedIds) => orderedIds.pendingSongRequests || []
);
export const selectSelectedSongRequestOrderedIds = createSelector(
  selectOrderedIds,
  (orderedIds) => orderedIds.selectedSongRequests || []
);
export const selectCompletedSongRequestOrderedIds = createSelector(
  selectOrderedIds,
  (orderedIds) => orderedIds.completedSongRequests || []
);
const orderFromDictionary = (dictionary, orderedIds) =>
  orderedIds.map((id) => dictionary[id]).filter((req) => req != null);
export const selectPendingSongRequestsArray = createSelector(
  selectSongRequestsDictionary,
  selectPendingSongRequestOrderedIds,
  orderFromDictionary
);
export const selectSelectedSongRequests = createSelector(
  selectSongRequestsDictionary,
  selectSelectedSongRequestOrderedIds,
  orderFromDictionary
);
export const selectCompletedSongRequests = createSelector(
  selectSongRequestsDictionary,
  selectCompletedSongRequestOrderedIds,
  orderFromDictionary
);
export const selectSongRequestsMetadata = createSelector(
  selectMetadata,
  (metadata) => metadata.songRequests
);
export const selectSongRequestsQuery = createSelector(
  selectUi,
  ({ songRequestsQuery }) => songRequestsQuery
);
export const selectSongRequestsListingLoading = createSelector(
  selectLoading,
  (loading) => loading.songRequestsListing
);
export const selectCurrentUserSongRequests = createSelector(selectSession, ({ songRequests }) =>
  songRequests == null || songRequests.data == null ? [] : songRequests.data
);
export const selectCurrentUserSongRequestsMetadata = createSelector(
  selectSession,
  ({ songRequests }) =>
    songRequests == null || songRequests.metadata == null
      ? { totalCount: 0 }
      : songRequests.metadata
);
export const selectCurrentUsersSongRequestVotes = createSelector(
  selectSession,
  ({ songRequestVotes }) =>
    songRequestVotes == null || songRequestVotes.data == null ? [] : songRequestVotes.data
);
export const userVotedForSongRequest = createSelector(
  selectCurrentUsersSongRequestVotes,
  (votes) => (requestId) =>
    votes.some((vote) => vote.attributes.songRequestId === Number(requestId))
);
export const selectMostRecentSongRequest = createSelector(
  selectSession,
  ({ mostRecentSongRequest }) => mostRecentSongRequest.data
);
export const selectTwentiethMostRecentVote = createSelector(
  selectCurrentUsersSongRequestVotes,
  (votes) => {
    const sorted =
      votes && votes.length >= 20
        ? votes
            .slice()
            .sort(
              (a, b) =>
                new Date(b.attributes.createdAt).getTime() -
                new Date(a.attributes.createdAt).getTime()
            )
        : [];

    return sorted[19] || null;
  }
);
