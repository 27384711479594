import React from "react";
import { BootstrapSize } from "../components/shared/bootstrap-helpers";
import Swoosh from "../components/shared/Swoosh";
import { CDN_URL } from "../components/shared/CdnUrl";
import SiteLogin from "../components/auth/Login";
import { COMMUNITY_URL } from "../components/shared/Community";

const Login = ({ user, sso, sig }) => {
  if (user) {
    return (
      <div>
        <h1>You're already logged in.</h1>
        <p>Looking for the forum? </p>
        <a className="button button--primary button--inline" href={COMMUNITY_URL}>
          Visit Forum
        </a>
      </div>
    );
  } else {
    return (
      <div>
        <Swoosh>
          <div className="container">
            <h1 className="header1 uppercase" style={{ color: "white" }}>
              Forum Log In
            </h1>
          </div>
        </Swoosh>
        <div style={{ marginTop: "40px" }}>
          <div className="container">
            <SiteLogin discourseSignIn={true} sso={sso} sig={sig} />
          </div>
        </div>
      </div>
    );
  }
};

export default Login;
