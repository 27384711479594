import React from "react";
import { Typography } from "@mui/material";

import Swoosh from "../../components/shared/Swoosh";
import { CDN_URL } from "../../components/shared/CdnUrl";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";

const defaultHeaderImages = {
  [BootstrapSize.smDevice]: `${CDN_URL}/images/pages/store/store-hero-lg.jpeg`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/store/store-hero-sm.jpeg`,
};

const CheckoutSuccessSwoosh = () => {
  return (
    <Swoosh imgUrl={defaultHeaderImages}>
      <div className="store-swoosh__content container">
        <Typography variant="h1" sx={{ letterSpacing: 0, color: "white" }}>
          Thank you :)
        </Typography>{" "}
        <Typography variant="h5" sx={{ color: "white" }}>
          We processed your payment.
        </Typography>
      </div>
    </Swoosh>
  );
};

export default CheckoutSuccessSwoosh;
