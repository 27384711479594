export const currentItem = (practiceSessions, activeRoutine, items) => {
  let sessionInProgress = practiceSessions.filter(
    (s) => s.user_practice_routine_id === activeRoutine.id && s.is_finished === false
  );
  let currentItem;
  if (sessionInProgress.length > 0) {
    let completedItems = sessionInProgress[0].completed_practice_items;
    for (let item of items) {
      if (completedItems.includes(item.id) === false) {
        currentItem = item;
        break;
      }
    }
    return currentItem ? currentItem : items[0];
  } else {
    return items[0];
  }
};

export const getFinishedItems = (practiceSessions, activeRoutine) => {
  let routineInProgress = practiceSessions.filter(
    (s) => s.user_practice_routine_id === activeRoutine.id && s.is_finished === false
  );
  return routineInProgress.length > 0 ? routineInProgress[0].completed_practice_items : [];
};

export const itemInFinished = (practiceSessions, activeRoutine, item) => {
  return this.getFinishedItems(practiceSessions, activeRoutine).includes(item.id);
};

export const formattedDuration = (item) => {
  let unit = item.duration <= 1 ? "min" : "mins";
  return `${item.duration} ${unit}`;
};
