import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { CDN_URL } from "../../components/shared/CdnUrl";

const defaultHeaderImages = {
  [BootstrapSize.smDevice]: `${CDN_URL}/images/support-team/team-hero-v2-lg.jpg`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/images/support-team/team-hero-v2-sm.jpg`,
};

const SupportTeamSwoosh = () => {
  return (
    <div className="support-team-swoosh">
      <Swoosh imgUrl={defaultHeaderImages} id="support-team">
        <div className="support-team-swoosh__content">
          <h1 className="header1">the team</h1>
        </div>
      </Swoosh>
    </div>
  );
};

export default SupportTeamSwoosh;
