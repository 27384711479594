import React, { useEffect, useState } from "react";
import PlayinItForwardSwoosh from "./PlayinItForwardSwoosh";
import { withInnerWidth } from "../../components/shared";
import "../../components/shared/polyfills/replaceAll.js";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";

import Welcome from "./Welcome";
import Intro from "./Intro";
import AboutTheGuitars from "./AboutTheGuitars";
import HowToApply from "./HowToApply";
import Supporters from "./Supporters";
import SpecialThanks from "./SpecialThanks";
import ApplicationForm from "./ApplicationForm";
import ReceivedApplication from "./ReceivedApplication";
import GuitarsGivenAway from "./GuitarsGivenAway";
import { ThemeProvider } from "@mui/material";
import { jgDenimTheme } from "../../jg-material/theme/jg-denim-theme";

export const PAGES = {
  application: "application",
};

const PlayinItForward = ({
  faqs,
  hasReceivedGuitar,
  videoId,
  alreadyApplied,
  currentUser,
  width,
}) => {
  const [showApplicationForm, setShowApplicationForm] = useState(false);
  const [applicationReceived, setApplicationReceived] = useState(false);

  useEffect(() => {
    const page = new URLSearchParams(window.location.search).get("page");
    if (page === PAGES.application && eligibleToApply) {
      setShowApplicationForm(true);
    } else if (page === PAGES.application) {
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  useEffect(() => {
    if (showApplicationForm || applicationReceived) {
      window.scrollTo(0, 0);
    }
  }, [showApplicationForm, applicationReceived]);

  const eligibleToApply = currentUser && !alreadyApplied;

  return (
    <ThemeProvider theme={jgDenimTheme}>
      <div className="playin-it-forward">
        <PlayinItForwardSwoosh
          showApplicationForm={showApplicationForm}
          applicationReceived={applicationReceived}
          hasReceivedGuitar={hasReceivedGuitar}
        />

        {hasReceivedGuitar ? (
          <Welcome videoId={videoId} />
        ) : applicationReceived || alreadyApplied ? (
          <ReceivedApplication />
        ) : showApplicationForm ? (
          <ApplicationForm
            currentUser={currentUser}
            setApplicationReceived={setApplicationReceived}
          />
        ) : (
          <div className="container">
            <Intro videoId={videoId} />
            <AboutTheGuitars />
            <HowToApply currentUser={currentUser} />
            <GuitarsGivenAway />
            <Supporters />
            <SpecialThanks width={width} faqs={faqs} />
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default withInnerWidth(PlayinItForward);
