import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Container, Modal, ThemeProvider, Typography } from "@mui/material";
import SignInForm from "./SignInForm";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  p: 4,
  background: "white",
  width: "90%",
  height: "90%",
  borderRadius: "20px",
  maxWidth: "500px",
  maxHeight: "500px",
};

const SignInModal = ({ isOpen, setIsOpen }) => {
  return (
    <ThemeProvider theme={jgDenimTheme}>
      <Modal
        open={isOpen}
        onClose={setIsOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <Container>
            <Typography
              id="modal-modal-title"
              component="h1"
              variant="h8"
              sx={{
                mt: 3,
                mb: 2,
                fontSize: "22px",
                textTransform: "none",
                letterSpacing: 0,
                fontWeight: "bold",
              }}
            >
              Log In
            </Typography>

            <Box>
              <SignInForm />
            </Box>
          </Container>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

SignInModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default SignInModal;
