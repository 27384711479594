import React, { useState } from "react";
import StrummingPatternDiagram from "./StrummingPatternDiagram";
import { CaretUpIcon, FiltersIcon, Play2Icon } from "../../../components/shared/Icons";
import { CaretDownIcon } from "../../../components/shared/Icons";

function Library({
  setShowLibrary,
  setSelectedStrummingPattern,
  savedStrummingPatterns,
  strummingPatterns,
  openAlert = null,
  createStrummingPatternItem = null,
  deleteStrummingPatternItem = null,
  openDialog = null,
  savePattern = null,
  copyPattern = copyPattern,
  width,
}) {
  const [sectionsOpen, setSectionsOpen] = useState({ favorite: true, preset: true, popular: true });

  const onStrummingPatternSelect = (strummingPattern) => {
    setShowLibrary(false);
    setSelectedStrummingPattern(strummingPattern);
    window.scrollTo(0, 0);
  };

  const toggleSection = (section) => {
    setSectionsOpen({ ...sectionsOpen, [section]: !sectionsOpen[section] });
  };

  const favoritePatterns = strummingPatterns.filter((pattern) => pattern.saved === true);
  const presetPatterns = strummingPatterns.filter((pattern) => pattern.saved === false);
  const popularPatterns = strummingPatterns.filter((pattern) => pattern.popular === true);

  return (
    <div className="strumming-machine-library">
      {/*<div className="sidebar">*/}
      {/*  <div className="filters">*/}
      {/*    <div className="header">*/}
      {/*      <FiltersIcon /> filters*/}
      {/*    </div>*/}
      {/*    <div className="select">*/}
      {/*      patterns <CaretDownIcon color="#4e5b69" />*/}
      {/*    </div>*/}
      {/*    <div className="footer">*/}
      {/*      <button>clear all</button>*/}
      {/*      <button>done</button>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="intro">*/}
      {/*    <Play2Icon /> watch intro video*/}
      {/*  </div>*/}
      {/*  <div className="community">*/}
      {/*    💬 Have questions or feedback?*/}
      {/*    <br />*/}
      {/*    Join the <a href="#">Strumming Machine Discussion</a> on the Community or{" "}*/}
      {/*    <a href="#">contact my team</a>! :)*/}
      {/*  </div>*/}
      {/*  <div className="donation">*/}
      {/*    🎁 Strumming Machine is free thanks to your support! If you enjoy this tool and can afford*/}
      {/*    to contribute, please <a href="/donate">make a donation</a>!*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="strumming-patterns">
        {[
          { name: "favorite", patterns: favoritePatterns },
          { name: "popular", patterns: popularPatterns },
          { name: "preset", patterns: presetPatterns },
        ].map((section) => (
          <div className="section" key={section.name}>
            <div className="header" onClick={() => toggleSection(section.name)}>
              <p>
                {section.name.charAt(0).toUpperCase() + section.name.slice(1)} patterns (
                {section.patterns.length})
              </p>
              {sectionsOpen[section.name] ? (
                <CaretUpIcon color="white" />
              ) : (
                <CaretDownIcon color="white" />
              )}
            </div>
            {sectionsOpen[section.name] && (
              <div className="patterns">
                {section.patterns.map((strummingPattern, idx) => (
                  <StrummingPatternDiagram
                    key={idx}
                    selectedStrummingPattern={strummingPattern}
                    onStrummingPatternSelect={onStrummingPatternSelect}
                    openAlert={openAlert}
                    createStrummingPatternItem={createStrummingPatternItem}
                    deleteStrummingPatternItem={deleteStrummingPatternItem}
                    savedStrummingPatterns={savedStrummingPatterns}
                    openDialog={openDialog}
                    savePattern={savePattern}
                    copyPattern={copyPattern}
                    inLibrary={true}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Library;
