import React from "react";
import Bubble from "./bubble";
import TeacherTrainingContent from "./TeacherTrainingContent";
import CtaButton from "./CtaButton";
import { CDN_URL } from "../../components/shared/CdnUrl";
import { TeacherTrainingFaqs } from "./TeacherTrainingFaqs";

export default class TeacherTraining extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="teacher-training">
        <div className="container">
          <div className="teacher-training__hero">
            <Bubble />
            <h1 className="header1 uppercase">
              Teacher <span>Training</span>
            </h1>
            <p>
              JustinGuitar Teacher Training will explore all I've learned since building
              JustinGuitar.com from scratch - and much more.
            </p>

            <img src={`${CDN_URL}/images/pages/teachers-training/coming-soon.png`} />
            <div className="teacher-training__btn">
              <CtaButton text="Join The Waitlist" />
            </div>
          </div>

          <TeacherTrainingContent />
        </div>
        <div className="teacher-training__body__faq">
          <TeacherTrainingFaqs faqs={this.props.faqs || []} />
        </div>
      </div>
    );
  }
}
