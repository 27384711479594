import React from "react";
import NewPayments from "../../../components/new-payments";

const Donations = ({ user, donation }) => {
  return (
    <div className="tab-content donations">
      <div className="account__donations account__purchases">
        <div className="donation-section">
          <NewPayments
            currentUser={user}
            donationSingleSuccess={donation.singleSuccess}
            donationSubscriptionSuccess={donation.subscriptionSuccess}
            donationCardData={donation.donationCardData}
            showZiggy={false}
            singleDonationAmounts={["5", "10", "15", "25", "50", "Other"]}
            donationsDisabled={donation.donationsDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default Donations;
