import { Box, Container } from "@mui/material";
import React, { useRef, useEffect } from "react";
import Slider from "react-slick";

export const ContentCarousel = ({
  children,
  settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    initialSlide: 2,
    ...props.settings,
  },
}) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, [children]);

  return (
    <Container className="content-carousel">
      <Box position="relative">
        <Slider ref={sliderRef} {...settings}>
          {children}
        </Slider>
      </Box>
    </Container>
  );
};
