import React from "react";
import LiteYoutube from "../../components/shared/LiteYoutube";

const Intro = ({ videoId }) => (
  <div className="section intro">
    <h2 className="title">Everyone should be able to learn a musical instrument.</h2>
    <p>
      I've always hoped that I might be able to improve the quality of people's lives by helping
      them make music. I believe that learning an instrument can help us out of a funk and amplify
      our happiness, which is why I keep nearly all of{" "}
      <a href="https://www.justinguitar.com">JustinGuitar.com</a> free. But sometimes, those who
      could use that kind of help the most can't even afford a guitar to get started… And that's
      where <strong>Playin’ It Forward</strong> comes in!
    </p>
    <LiteYoutube url={videoId} hasMaxResImage={true} />
  </div>
);

export default Intro;
