import React from "react";
import ReactOnRails from "react-on-rails";
import { Provider } from "react-redux";

import SongsIndexHeader from "./SongsIndexHeader";
import SongsListing from "./SongsListing";
import LatestReleases from "./LatestReleases";
import SongSnackbar from "./SongSnackbar";
import Artists from "./Artists";
import SongRequests from "./song-requests/SongRequests";
import * as bootstrapHelpers from "../shared/bootstrap-helpers";

require("./store/songStore");

const sectionButtons = ["all", "latest releases", "songs", "artists", "requests board"];

class SongsIndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: window.location.hash
        ? window.location.hash.substring(1).replace("-", " ")
        : "all",
    };
  }

  isActiveSection(sectionName) {
    return this.state.activeSection === "all" || this.state.activeSection === sectionName;
  }

  setActiveSection(sectionName) {
    window.location.hash = sectionName.replace(" ", "-").toLowerCase();
    this.setState({ activeSection: sectionName.toLowerCase() });
  }

  isMdDevice() {
    return (
      window.innerWidth <= bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.lgDevice]
    );
  }

  render() {
    const store = ReactOnRails.getStore("songsIndexStore");

    return (
      <div className="songs-index-page">
        <Provider store={store}>
          <SongsIndexHeader />

          <div className="container">
            <ul className="songs-section-buttons songs-section-buttons--pills">
              <li>view</li>
              {sectionButtons.map((button, idx) => (
                <li key={idx} className={this.state.activeSection === button ? "active" : ""}>
                  <button onClick={() => this.setActiveSection(button.toLowerCase())}>
                    {button}
                  </button>
                </li>
              ))}
            </ul>
            <div className="songs-section-buttons songs-section-buttons--select">
              <select
                onChange={(e) => this.setActiveSection(e.target.value.toLowerCase())}
                value={this.state.activeSection}
              >
                {sectionButtons.map((button, idx) => (
                  <option key={idx} value={button}>
                    {button.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="swooosh__content">
            <div
              className="container"
              style={
                this.state.activeSection === "songs"
                  ? !this.isMdDevice()
                    ? { paddingTop: "70px" }
                    : { paddingTop: "40px" }
                  : null
              }
            >
              {this.isActiveSection("latest releases") ? <LatestReleases /> : null}
              {this.isActiveSection("songs") ? <SongsListing /> : null}
            </div>
          </div>

          {this.isActiveSection("artists") ? (
            <Artists excludeTopMargin={this.state.activeSection === "artists"} />
          ) : null}
          {this.isActiveSection("requests board") ? (
            <>
              <SongRequests excludeTopMargin={this.state.activeSection === "requests board"} />
              <SongSnackbar />
            </>
          ) : null}
        </Provider>
      </div>
    );
  }
}

export default SongsIndexPage;
