import React, { memo } from "react";
import { Widget } from "@typeform/embed-react";
import { TYPEFORM_HEIGHT } from ".";

const TYPEFORM_ID = "WJfbg6oH";

const TypeformEmbed = ({ currentUser, onSubmit }) => {
  const { firstName, lastName, email } = currentUser.data.attributes;

  return (
    <Widget
      id={TYPEFORM_ID}
      hidden={{ email: email, name: `${firstName} ${lastName}` }}
      style={{ width: "100%", height: TYPEFORM_HEIGHT, margin: "40px 0 20px" }}
      onSubmit={onSubmit}
      className="onboarding-form"
      inlineOnMobile
    />
  );
};

export default memo(TypeformEmbed);
