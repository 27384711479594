import React from "react";
import { CDN_URL } from "../../components/shared/CdnUrl";

const Supporters = () => {
  return (
    <div className="section supporters">
      <p>We are Playin’ It Forward also thanks to the support of...</p>
      <div className="logos">
        <a href="https://www.andertons.co.uk" target="_blank">
          <div className="andertons-logo"></div>
        </a>
        <a href="https://www.daddario.com" target="_blank">
          <img
            src={`${CDN_URL}/images/pages/playin-it-forward/daddario-logo.svg`}
            alt="D'Addario"
          />
        </a>
        <a href="https://www.justinguitar.com/donate" target="_blank">
          <img
            src={`${CDN_URL}/images/pages/playin-it-forward/justinguitar-friends-logo.svg`}
            alt="JustinGuitar Friends"
          />
        </a>
      </div>
    </div>
  );
};

export default Supporters;
