import React from "react";
import { IEbookSection } from "./ebooks.interface";
import { Box, SxProps, Typography, styled } from "@mui/material";
import { EbookItemGrid } from "./EbookItemGrid";
import { EbookSubSection } from "./EbookSubSection";

interface EbookSectionProps extends Partial<IEbookSection> {
  hasPadding?: boolean;
  sx?: SxProps;
}

export const EbookSection: React.FC<EbookSectionProps> = (props) => {
  const { title, items, subsections, hasPadding } = props;

  return (
    <SectionBox sx={props.sx}>
      <Typography
        variant="h1-responsive"
        sx={{ paddingBottom: hasPadding ? "80px" : "0" }}
        component={"div"}
      >
        {title}
      </Typography>

      {!!items?.length && <EbookItemGrid items={items} />}

      {!!subsections?.length &&
        subsections.map((subsection, idx) => (
          <EbookSubSection {...subsection} key={idx} sx={{ marginTop: "80px" }} />
        ))}
    </SectionBox>
  );
};

const SectionBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "80px",
}));
