import { WorkshopEntity } from "../interfaces";
import "../../../components/shared/polyfills/replaceAll.js";

export function workshopToPurchaseReference(workshop: WorkshopEntity): string {
  return workshop.attributes.slug.slice(0, 22); // stripe caps purchase_reference at 22 chars
}

export function workshopToRemainingFeeReference(workshop: WorkshopEntity): string {
  return (workshop.attributes.slug as any).replaceAll("-", "").slice(0, 19) + "Fee"; // stripe caps purchase_reference at 22 chars
}
