import React from "react";
import axiosWithCSRF from "./shared/axiosWithCSRF";

export default class AddPracticeRoutineToDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      savedIds: [],
    };
  }

  componentWillMount() {
    let { userPracticeRoutines, userPracticeItems, routine } = this.props;
    let selectedIds = {};
    let savedIds = [];
    let itemIds = routine.practice_items.map((i) => i.id);
    itemIds.forEach((i) => {
      if (userPracticeItems.filter((userItem) => userItem.practice_item_id === i).length > 0) {
        savedIds.push(i);
      } else {
        selectedIds[i] = true;
      }
    });
    let formDisabled = savedIds.length === routine.practice_items.length ? true : false;

    this.setState({ selectedIds, savedIds, formDisabled });
  }

  togglePrompt = () => {
    this.setState({ togglePrompt: !this.state.togglePrompt });
  };

  saveRoutine = () => {
    axiosWithCSRF()
      .post("/user_practice_routines/add_admin_generated_routine", {
        admin_created_routine_id: this.props.routine.id,
      })
      .then((response) => {
        let selectedIds = {};
        let savedIds = [];
        this.props.routine.practice_items.forEach((item) => {
          selectedIds[item.id] = true;
          savedIds.push(item.id);
        });
        this.setState({ formDisabled: true, selectedIds, savedIds });
      })
      .catch((error) => {
        this.setState({ hasErrors: error.response.data.error });
        setTimeout(() => this.setState({ hasErrors: null }), 3000);
      });
  };

  addRoutineItems = () => {
    let itemIds = [];
    Object.keys(this.state.selectedIds).forEach((k) => {
      if (this.state.selectedIds[k] === true) {
        itemIds.push(k);
      }
    });

    if (itemIds.length > 0) {
      this.itemIds = itemIds;
      axiosWithCSRF()
        .post("/user_practice_routines/add_admin_generated_items", {
          admin_created_item_ids: itemIds,
        })
        .then((response) => {
          let savedIds = [...this.state.savedIds];
          this.itemIds.map((i) => savedIds.push(parseInt(i)));

          let formDisabled = false;
          if (savedIds.length === this.props.routine.practice_items.length) {
            formDisabled = true;
          }
          this.setState({
            savedIds,
            formDisabled,
            successText: "Items have been added to your routine!",
          });
          setTimeout(() => this.setState({ successText: null }), 2000);
        })
        .catch((error) => {
          this.setState({ hasErrors: error.response.data.message });
          setTimeout(() => this.setState({ hasErrors: null }), 2000);
        });
    } else {
      this.setState({ hasErrors: "You must select at least one item to add" });
      setTimeout(() => this.setState({ hasErrors: null }), 2000);
    }
  };

  handleCheckboxChange = (itemId) => {
    let selectedIds = Object.assign({}, this.state.selectedIds);
    selectedIds[itemId] === true ? (selectedIds[itemId] = false) : (selectedIds[itemId] = true);
    this.setState({ selectedIds });
  };

  isSaved = (itemId) => {
    return this.state.savedIds.includes(itemId);
  };

  itemIsChecked = (item) => {
    return this.state.selectedIds[item.id] || this.state.savedIds.includes(item.id);
  };

  itemCheckbox = (item) => {
    return (
      <div className={this.isSaved(item.id) ? "saved-item" : ""}>
        <input
          type="checkbox"
          id={`item-${item.id}`}
          value={this.state.selectedIds[item.id]}
          checked={this.itemIsChecked(item)}
          disabled={this.state.formDisabled || this.isSaved(item.id)}
          onChange={() => this.handleCheckboxChange(item.id)}
        ></input>
        <label htmlFor={`item-${item.id}`}>{item.title}</label>
        {this.isSaved(item.id) && <span className="saved-item">saved</span>}
      </div>
    );
  };

  buttonToShow = (routineIds) => {
    if (this.state.formDisabled) {
      return (
        <div>
          <button
            disabled
            className="standard-blue-button standard-blue-button--disabled uppercase"
          >
            SAVED
          </button>
        </div>
      );
    } else if (!this.state.togglePrompt) {
      if (this.props.userPracticeRoutines.length > 0) {
        return (
          <div className="routine-button-selection">
            <button className="add-to-routine__button" onClick={this.togglePrompt}>
              Save this routine
            </button>
          </div>
        );
      } else {
        // here the user does not have a routine yet, so we need to handle differently
        return (
          <div className="routine-button-selection">
            <button className="add-to-routine__button" onClick={this.saveRoutine}>
              Save this Routine
            </button>
          </div>
        );
      }
    } else {
      return (
        <div className="routine-button-selection">
          <button className="add-to-routine__button" onClick={this.saveRoutine}>
            Replace Your Current Routine With All
          </button>
          <button className="add-to-routine__button" onClick={this.addRoutineItems}>
            Add Selected to Your Current Routine
          </button>
          <button className="cancel-routine__button" onClick={this.togglePrompt}>
            Cancel
          </button>
        </div>
      );
    }
  };

  handleResponse = () => {
    if (this.state.hasErrors) {
      return <p className="has-errors">{this.state.hasErrors}</p>;
    } else if (this.state.successText) {
      return <p className="success">{this.state.successText}</p>;
    }
  };

  render() {
    const routine = this.props.routine;

    return (
      <div
        className={
          "practice-item-save__container addable-routine " +
          (this.state.formDisabled ? "addable-routine--disabled" : "")
        }
      >
        {this.handleResponse()}

        <h2>{routine.title}</h2>
        <ul className="addable-routine__list">
          {routine.practice_items.map((item) => (
            <div key={item.id}>{this.itemCheckbox(item)}</div>
          ))}
        </ul>
        <div className="routine-button-selection">{this.buttonToShow()}</div>
      </div>
    );
  }
}
