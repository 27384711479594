import ReactOnRails from "react-on-rails";
import { configureStore, createSlice, combineReducers } from "@reduxjs/toolkit";
import { ui } from "../../components/shared/ui/uiIndex";
import { session } from "../../components/shared/session/sessionIndex";

const initialState = (props) => {
  return {
    entity: {
      lesson: props?.lesson || {},
      promoItems: [],
    },
  };
};

const initialSessionState = (props) => {
  return {
    session: { currentUser: props.currentUser },
  };
};

const lessonSlice = createSlice({
  name: "lesson",
  initialState,
  reducers: {
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    setDownloadLinks: (state, { payload }) => {
      state.downloadLinks = payload;
    },
  },
});

export const { setActiveTab, setDownloadLinks } = lessonSlice.actions;

const reducer = combineReducers({
  entity: lessonSlice.reducer,
  ui: ui.reducer,
  session: session.reducer,
});

const createLessonStore = (preloadedState) =>
  configureStore({
    reducer,
    preloadedState,
  });

ReactOnRails.registerStore({
  lessonStore: (props, railsContext) => {
    return createLessonStore({
      entity: {
        // ...initialState,
        ...props.entity,
      },
      ui: {
        ...ui.initialState,
        ...props.ui,
      },
      session: {
        ...initialSessionState,
        ...props.session,
      },
    });
  },
});
