import React from "react";

export const StrummingIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="34.0527"
        y="15.3027"
        width="1.5"
        height="26.5136"
        rx="0.75"
        transform="rotate(45 34.0527 15.3027)"
        fill="white"
      />
      <rect
        x="29.1035"
        y="10.3545"
        width="1.5"
        height="26.5136"
        rx="0.75"
        transform="rotate(45 29.1035 10.3545)"
        fill="white"
      />
      <rect
        x="24.1543"
        y="5.4043"
        width="1.5"
        height="26.5136"
        rx="0.75"
        transform="rotate(45 24.1543 5.4043)"
        fill="white"
      />
      <path
        d="M6.53732 18.9737C4.86312 17.2995 4.35573 15.2955 4.63749 13.2992C4.92375 11.271 6.03358 9.23122 7.63219 7.63261C9.2308 6.034 11.2706 4.92417 13.2988 4.63791C15.295 4.35615 17.2991 4.86354 18.9733 6.53773C19.7103 7.27481 20.5029 8.55058 21.2377 10.1679C21.9638 11.766 22.5998 13.622 23.0552 15.4536C23.5119 17.2905 23.7772 19.0635 23.7844 20.501C23.788 21.2199 23.7267 21.8238 23.6088 22.296C23.4888 22.7766 23.3282 23.0493 23.1885 23.189C23.0489 23.3286 22.7761 23.4892 22.2956 23.6092C21.8234 23.7271 21.2195 23.7884 20.5005 23.7848C19.0631 23.7776 17.29 23.5123 15.4532 23.0556C13.6216 22.6002 11.7655 21.9642 10.1675 21.2381C8.55016 20.5033 7.27439 19.7108 6.53732 18.9737Z"
        fill="white"
        stroke="#406E8E"
        strokeWidth="2"
      />
    </svg>
  );
};
