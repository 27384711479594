import React, { useEffect, useState } from "react";
import { CloseIcon } from "../Icons";
import { Portal } from "@mui/material";

const SimpleDialog = ({ title, content, actionButtons, closeAction }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeAction();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [closeAction]);

  const handleContainerClick = () => {
    closeAction();
  };

  const handleDialogClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Portal>
      <div className="simple-dialog-container" onClick={handleContainerClick}>
        <div className="simple-dialog" onClick={handleDialogClick}>
          <button className="close-btn" onClick={() => closeAction()}>
            <CloseIcon />
          </button>
          <h2 className="title">{title}</h2>
          <div className="content">{content}</div>
          {actionButtons && <div className="action-buttons">{actionButtons}</div>}
        </div>
      </div>
    </Portal>
  );
};

export default SimpleDialog;
