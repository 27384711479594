import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "../../components/shared/ui/Snackbar";
import { createSongbook, fetchSongbooks, fetchSongOptions } from "./songbookApi";
import { selectSongbookPanel } from "../store/dashboardSelectors";
import Dialog from "../../components/shared/ui/Dialog";
import { setActiveTab, setSongbookTabPanel, songbookTabPanel } from "../store/dashboardStore";
import SongSelectionForm from "./SongSelectionForm";
import SongbookList from "./SongbookList";

export default function () {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchSongbooks());
    setTimeout(() => {
      dispatch(fetchSongOptions());
    }, 100);
  }, []);

  const songbookPanelConfigs = {
    [songbookTabPanel.songbooks]: {
      header: "Songbooks",
      subheader: "Organize song lessons into categories that suit your guitar learning journey!",
      backButton: {
        label: " Dashboard",
        onClick() {
          dispatch(setActiveTab("Dashboard"));
        },
      },
      Content: SongbookList,
    },
    [songbookTabPanel.songs]: {
      header: "songs",
      subheader: "",
      backButton: {
        label: " Songbook",
        onClick() {
          dispatch(setSongbookTabPanel(songbookTabPanel.songbooks));
        },
      },
      Content: SongSelectionForm,
    },
  };

  const currentPanel = useSelector(selectSongbookPanel);
  const panelOptions = songbookPanelConfigs[currentPanel];

  return (
    <>
      <button className="back-btn" onClick={panelOptions.backButton.onClick}>
        <i className="icon icon-arrow-left" />
        {panelOptions.backButton.label}
      </button>

      <div className="dashboard__heading dashboard__heading--songbooks">
        <button className="back-btn" onClick={panelOptions.backButton.onClick}>
          <i className="icon icon-arrow-left" />
        </button>

        <div>{panelOptions.header}</div>

        {currentPanel === songbookTabPanel.songbooks && <NewSongbookButton />}
      </div>

      {panelOptions.subheader && (
        <div className="dashboard__subheading">
          <CollapsibleSubheader />
          {currentPanel === songbookTabPanel.songbooks && <NewSongbookButton />}
        </div>
      )}

      <panelOptions.Content />

      <Snackbar />
      <Dialog />
    </>
  );
}

const CollapsibleSubheader = () => {
  const [isCollapsed, setCollapsed] = React.useState(true);

  return (
    <div
      className="dashboard__subheader--collapsible clickable"
      onClick={() => setCollapsed(!isCollapsed)}
    >
      <span>Welcome to your Songbook! Here, you can organize your song lessons into </span>

      {isCollapsed ? (
        <span>
          <strong>. . .</strong>
        </span>
      ) : (
        <span>
          custom lists that suit your learning journey. I recommend that all guitar players work on{" "}
          <a href="https://www.justinguitar.com/guitar-lessons/3-types-of-songs-you-should-practice-to-play-guitar-better-pc-001">
            3 types of songs at any time:
          </a>{" "}
          <strong>Campfire | Developers | Dreamers</strong>. You can also keep track of songs you
          play live or setlists you will perform. Customize your Songbook as you wish and create as
          many lists as you want!
        </span>
      )}
    </div>
  );
};

const NewSongbookButton = () => {
  const dispatch = useDispatch();

  return (
    <div className="new-songbook-button">
      <button
        className="button button--primary"
        onClick={() => {
          dispatch(createSongbook());
        }}
      >
        <i className="icon icon-plus" />
        Add List
      </button>
    </div>
  );
};
