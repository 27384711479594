import { createSlice } from "@reduxjs/toolkit";

import {
  createUserSongPracticeItem,
  createSongItem,
  deleteUserSongPracticeItem,
  deleteSongItem,
  createChordItem,
  deleteChordItem,
  createUserPracticeItemFromLesson,
  createUserPracticeRoutineFromLesson,
  createLessonItem,
  deleteLessonItem,
  cancelSubscription,
  deleteStrummingPatternItem,
  createStrummingPatternItem,
  renameStrummingPatternItem,
} from "./sessionEffects";

import _ from "lodash";

export const initialState = {
  currentUser: null,
};

export const { actions, reducer } = createSlice({
  name: "session",
  initialState,
  reducers: {
    addOrUpdateUserNote: (state, { payload }) => {
      const existing = state.currentUser.included.filter(
        (i) => i.type === "userNote" && i.id === payload.id
      )[0];
      if (existing) {
        state.currentUser.included[state.currentUser.included.indexOf(existing)] = payload;
      } else {
        state.currentUser.included.push(payload);
      }
    },
    deleteUserNote: (state, { payload }) => {
      const deletedItem = state.currentUser.included.filter(
        (i) => i.id === payload.id && i.type == payload.type
      )[0];
      state.currentUser.included.splice(state.currentUser.included.indexOf(deletedItem), 1);
    },
    clearUserImage: (state, { payload }) => {
      state.currentUser.data.attributes.imageUid = null;
    },
    updateUserAttributes: (state, { payload }) => {
      const disallowed = ["password", "image"];
      const attributes = Object.keys(payload)
        .filter((key) => !disallowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = payload[key];
          return obj;
        }, {});
      const attributesToCamelCase = _.mapKeys(attributes, (v, k) => _.camelCase(k));
      state.currentUser.data.attributes = {
        ...state.currentUser.data.attributes,
        ...attributesToCamelCase,
      };
    },
    updateUserDonations: (state, { payload }) => {
      state.currentUser.included.push(payload);
    },
    setUserSetting: (state, { payload }) => {
      const settingName = Object.keys(payload)[0];
      const settingValue = Object.values(payload)[0];

      if (settingValue === true) {
        let settings = state.currentUser.data.attributes.settings;
        settings.push(settingName);
        settings = settings.filter((v, i, a) => a.indexOf(v) === i); // keep only unique settings

        state.currentUser.data.attributes.settings = settings;
      } else {
        state.currentUser.data.attributes.settings =
          state.currentUser.data.attributes.settings.filter((v) => v !== settingName);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSongItem.fulfilled, (state, { payload }) => {
        state.currentUser.included = [...state.currentUser.included, payload];
      })
      .addCase(deleteSongItem.fulfilled, (state, { payload }) => {
        const deletedItem = state.currentUser.included.filter(
          (i) => i.id === payload.id && i.type === "userItem"
        )[0];
        state.currentUser.included.splice(state.currentUser.included.indexOf(deletedItem), 1);
      })
      .addCase(createChordItem.fulfilled, (state, { payload }) => {
        state.currentUser.included = [...state.currentUser.included, payload];
      })
      .addCase(deleteChordItem.fulfilled, (state, { payload }) => {
        const deletedItem = state.currentUser.included.filter(
          (i) => i.id === payload.id && i.type === "userItem"
        )[0];
        state.currentUser.included.splice(state.currentUser.included.indexOf(deletedItem), 1);
      })
      .addCase(createStrummingPatternItem.fulfilled, (state, { payload }) => {
        state.currentUser.included = [...state.currentUser.included, payload];
      })
      .addCase(renameStrummingPatternItem.fulfilled, (state, { payload }) => {
        state.currentUser.included = state.currentUser.included.map((item) => {
          if (item.id === payload.id) {
            return payload;
          }
          return item;
        });
      })
      .addCase(deleteStrummingPatternItem.fulfilled, (state, { payload }) => {
        const deletedItem = state.currentUser.included.filter(
          (i) => i.id === payload.id && i.type === "userItem"
        )[0];
        state.currentUser.included.splice(state.currentUser.included.indexOf(deletedItem), 1);
      })
      .addCase(createUserSongPracticeItem.fulfilled, (state, { payload }) => {
        state.currentUser.included = [...state.currentUser.included, payload];
      })
      .addCase(deleteUserSongPracticeItem.fulfilled, (state, { payload }) => {
        const deletedItem = state.currentUser.included.filter(
          (i) => i.id === payload.id && i.type === "userPracticeItem"
        )[0];
        state.currentUser.included.splice(state.currentUser.included.indexOf(deletedItem), 1);
      })
      .addCase(createUserPracticeItemFromLesson.fulfilled, (state, { payload }) => {
        if (payload) {
          state.currentUser.included = [...state.currentUser.included, payload];
        }
      })
      .addCase(createUserPracticeRoutineFromLesson.fulfilled, (state, { payload }) => {
        if (payload) {
          state.currentUser.included = [...state.currentUser.included, payload];
        }
      })
      .addCase(cancelSubscription.fulfilled, (state, { payload }) => {
        if (payload) {
          let userToUpdate = Object.assign({}, state.currentUser);
          if (payload.purchasesForDashboard) {
            userToUpdate.data.attributes.purchasesForDashboard = payload.purchasesForDashboard;
          }
          if (payload.updatedDonationId) {
            let item = userToUpdate.included.filter(
              (i) => parseInt(i.id) == payload.updatedDonationId
            )[0];
            let newItem = Object.assign({}, item);
            newItem.attributes.isActive = false;
            newItem.attributes.isCancelled = true;
            userToUpdate.included.splice(userToUpdate.included.indexOf(item), 1);
            userToUpdate.included.push(newItem);
          }
          state.currentUser = userToUpdate;
        }
      })
      .addCase(createLessonItem.fulfilled, (state, { payload }) => {
        let existing = state.currentUser?.included?.filter(
          (i) => i.id === payload.id && i.type === "userItem"
        )[0];
        if (existing) {
          let updatedArray = [...state.currentUser.included];
          updatedArray.splice(state.currentUser.included.indexOf(existing), 1);
          state.currentUser.included = [...updatedArray, payload];
        } else if (state.currentUser?.included) {
          state.currentUser.included = [...state.currentUser.included, payload];
        }
      })
      .addCase(deleteLessonItem.fulfilled, (state, { payload }) => {
        let item = state.currentUser?.included?.filter(
          (i) =>
            i.type === "userItem" &&
            i.attributes.itemStatus === payload.status &&
            i.attributes.nestedIds.includes(payload.id)
        )[0];

        if (item) {
          item.attributes.nestedIds.splice(item.attributes.nestedIds.indexOf(payload.id), 1);

          item.attributes.lessonsWithinModule.splice(
            item.attributes.nestedIds.indexOf(
              state.currentUser.included.filter((i) => i.id === payload.id)[0]
            ),
            1
          );
        }
      });
  },
});

export const {
  addOrUpdateUserNote,
  deleteUserNote,
  clearUserImage,
  updateUserAttributes,
  updateUserDonations,
  setUserSetting,
} = actions;
