import React from "react";
import axiosWithCSRF from "../../../shared/axiosWithCSRF";

export default class RestoreItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  restoreItem = (item) => {
    axiosWithCSRF()
      .patch(`/user_practice_items/restore_archived_item/${item.id}`)
      .then((res) => {
        this.props.handleRestoredItem(res.data.user_practice_item);
      })
      .catch((error) => {
        this.props.handleRestoredItem(null);
      });
  };

  render() {
    let { item } = this.props;
    return (
      <div className="practice-buttons__container practice-buttons__container--with-margin">
        <button
          className="practice__button practice__button--green"
          onClick={() => this.restoreItem(item)}
        >
          restore item
        </button>
        <button
          className="practice__button practice__button--cancel"
          onClick={this.props.closeForm}
        >
          cancel
        </button>
      </div>
    );
  }
}
