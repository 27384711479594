export const productsMenuItems = {
  title: "Store",
  link: "/store",
  dropdown: true,
  sections: [
    {
      title: "store categories",
      subItems: [
        { title: "Website Products", link: "/store/website-products" },
        { title: "Physical Products", link: "/store/physical-products" },
        { title: "Downloads", link: "/store/downloads" },
        { title: "Apps", link: "/store/apps" },
        { title: "Find What’s Best For Me", link: "/store/find-whats-best-for-me#choose" },
        { title: "View All", link: "/store" },
      ],
    },
    {
      title: "popular products",
      subItems: [
        {
          title: "Lessons & Songs App",
          // link: "/store/lessons-and-songs-app",
          link: "https://justinguitarapp.musopia.net",
          target: "_blank",
        },
        {
          title: "Strumming SOS",
          link: "/store/strumming-sos",
        },
        { title: "JustinGuitar Tabs", link: "/store/tabs" },
        {
          title: "Practical Music Theory",
          link: "/store/practical-music-theory",
        },
        {
          title: "Blues Immersion",
          link: "/store/blues-immersion",
        },
        {
          title: "Jam Tracks",
          link: "https://justinguitarjams.com",
          target: "_blank",
        },
        { title: "Merch", link: "https://merch.justinguitar.com", target: "_blank" },
      ],
    },
    {
      title: "waiting list",
      subItems: [
        {
          title: "Blues Immersion",
          link: "/store/blues-immersion",
        },
        {
          title: "Teacher Training",
          link: "/teacher-training",
        },
      ],
    },
  ],
};
