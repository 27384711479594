export const filterTypes = {
  Instrument: "Instrument",
  ["Difficulty Level"]: "Difficulty Level",
  Genre: "Genre",
};

export const filters = [
  {
    type: "Instrument",
    options: ["Guitar", "Ukulele"],
  },
  {
    type: "Difficulty Level",
    options: [
      "Beginner Grade 1",
      "Beginner Grade 2",
      "Beginner Grade 3",
      "Intermediate Grade 4",
      "Intermediate Grade 5",
      "Intermediate Grade 6",
      "Advanced Grade 7",
      "Advanced Grade 8",
      "Advanced Grade 9",
    ],
  },
  {
    type: "Genre",
    options: [
      "Alternative",
      "Blues",
      "Christmas",
      "Country",
      "Folk",
      "Funk",
      "Grunge",
      "Indie",
      "Jazz",
      "Metal",
      "Pop",
      "Punk",
      "Reggae",
      "Rock",
      "Rock 'n' Roll",
      "Soul",
    ],
  },
];

export const filterTypeToQueryValue = {
  [filterTypes.Genre]: "genre",
  [filterTypes.Instrument]: "instrument",
  [filterTypes["Difficulty Level"]]: "grade",
};

export const filterOptionToQueryValue = {
  Guitar: "guitar",
  Ukulele: "ukulele",
  ["Beginner Grade 1"]: "white",
  ["Beginner Grade 2"]: "yellow",
  ["Beginner Grade 3"]: "orange",
  ["Intermediate Grade 4"]: "green",
  ["Intermediate Grade 5"]: "blue",
  ["Intermediate Grade 6"]: "purple",
  ["Advanced Grade 7"]: "red",
  ["Advanced Grade 8"]: "brown",
  ["Advanced Grade 9"]: "",
  Alternative: "alternative",
  Blues: "blues",
  Christmas: "christmas",
  Country: "country",
  Folk: "folk",
  Funk: "funk",
  Grunge: "grunge",
  Indie: "indie",
  Jazz: "jazz",
  Metal: "metal",
  Pop: "pop",
  Punk: "punk",
  Reggae: "reggae",
  Rock: "rock",
  ["Rock 'n' Roll"]: "rock 'n' roll",
  Soul: "soul",
};
