export const ACOUSTIC_GUITARS_GIVEN_AWAY = [
  "8 guitars donated to South London School | UK",
  "Simran T. | UK",
  "Byron L. | UK",
  "Alyssa A. | UK",
  "Nigel E. | UK",
  "Sean P. | UK",
  "Alex M. | UK",
  "Catriona B. | UK",
  "Liam B. | UK",
  "Dave B. | UK",
  "Eve S. | UK",
  "Chris B. | UK",
  "Marc B. | UK",
  "Andrew T. | UK",
  "Terry S. | UK",
  "William B. | UK",
  "Leo V. | UK",
  "Nigel C. | UK",
  "Rana S. | Sweden",
];

export const ELECTRIC_GUITARS_GIVEN_AWAY = [
  "Duncan T. | UK",
  "Chris B. | UK",
  "Mr J S. | UK",
  "George H. | UK",
  "Rob Cook. | UK",
  "Liam B, | UK",
  "Bharatshreshtha S. | UK",
  "Andy T.| UK",
  "Christopher G.| UK",
  "Stefan M. | UK",
  "Fiona M. | UK",
  "Felix B. | Germany",
  "Kamila M. | Poland",
  "Aleksandra S | Poland",
  "Aleksandra G. | Poland",
  "August T. | Estonia",
];
