import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { WorkshopEntity } from "../interfaces";
import { dateAsFullDayMonthYear, dateAsShortMonthDayNumericYear } from "../shared/helpers";
import { TermsAndConditionsLink } from "../register/TermsAndConditionsLink";
import { CDN_URL } from "../../../components/shared/CdnUrl";

export const WorkshopMap = ({ workshop }: { workshop: WorkshopEntity }) => {
  return (
    <Box>
      <Typography variant="h4" textAlign="left" mb={jgDenimTheme.spacing(2)}>
        Location
      </Typography>
      <Box
        width={"100%"}
        component="img"
        src={
          workshop.attributes.mapImgUrl
            ? `${CDN_URL}/redesign/workshops/${workshop.attributes.mapImgUrl}`
            : `${CDN_URL}/redesign/workshops/castello.png`
        }
        borderRadius={"10px"}
        mb={jgDenimTheme.spacing(2)}
      />
      <Box mb={jgDenimTheme.spacing(2)}>
        <Typography variant="body1" fontWeight="bold">
          <Link color="secondary" href={workshop.attributes.accommodationUrl} target={"_blank"}>
            {workshop.attributes.addressTitle}
          </Link>
        </Typography>
        <Typography variant="body1">{workshop.attributes.addressStreet}</Typography>
        <Typography variant="body1">{workshop.attributes.addressCity}</Typography>
        <Typography variant="body1">{workshop.attributes.addressCountry}</Typography>
      </Box>
      <Typography
        variant="h4"
        textAlign="left"
        mb={jgDenimTheme.spacing(2)}
        mt={jgDenimTheme.spacing(4)}
      >
        Date & Time
      </Typography>
      <Box mb={jgDenimTheme.spacing(2)}>
        <Typography variant="body1" fontWeight="bold">
          {dateAsShortMonthDayNumericYear(workshop.attributes.arrivalTime)} -{" "}
          {dateAsShortMonthDayNumericYear(workshop.attributes.departureTime)}
        </Typography>
        <Typography variant="body1">
          Arrive {dateAsFullDayMonthYear(workshop.attributes.arrivalTime)},{" "}
          {workshop.attributes.slug === "2023-Acoustic-Electric-Classics" ? "15:00" : "16:00"}
        </Typography>
        <Typography variant="body1">
          Depart {dateAsFullDayMonthYear(workshop.attributes.departureTime)},{" "}
          {workshop.attributes.slug === "2023-Acoustic-Electric-Classics"
            ? "10:00"
            : "after breakfast"}
        </Typography>
        {workshop.attributes.slug === "2023-Acoustic-Electric-Classics" ? (
          <Typography variant="body1" fontStyle={"italic"}>
            Classes run Monday to Friday, from 10:00 to 18:00
          </Typography>
        ) : null}
        {workshop.attributes.slug === "2023-Acoustic-Electric-Classics" ? (
          <p style={{ marginTop: "30px" }}>
            🧐{" "}
            <span
              style={{
                fontWeight: "700",
                fontSize: "16px",
                fontStyle: "italic",
              }}
            >
              Read the <a href="#workshop-faq">FAQ</a> below to learn more about the Terms & Conditions, Payment Plan, and
              others!
            </span>
          </p>
        ) : null}
      </Box>
      {workshop.attributes.slug === "2023-Acoustic-Electric-Classics" ? null : (
        <TermsAndConditionsLink />
      )}
    </Box>
  );
};
