import React from "react";
import axios from "axios";
import UserItemDummyButton from "../components/UserItemDummyButton";

export default class FileDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = () => {
    this.setState({ pending: true });

    const path = `/downloadables/${this.props.file["id"]}`;
    if (this.props.currentUser) {
      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.setAttribute("type", "hidden");
      link.href = path;
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.setState({ pending: false });
      this.trackingFileDownload(this.props.file.title);
    } else {
      this.setState({ needsLogin: true });
    }
  };

  trackingFileDownload = (title) => {
    if ("ga" in window) {
      let tracker = ga.getAll()[0];
      if (tracker) {
        tracker.send("event", "FileDownload", "download", title);
      }
    }
  };

  iconToShow = () => {
    switch (this.props.file["file_type"]) {
      case "PDF":
        return "icon-file-pdf";
      case "GPX":
        return "icon-gpx-graphics";
      case "TSL":
        return "icon-katana";
      case "ZIP":
        return "icon-file-zip";
      case "MP3":
        return "icon-mp3";
      case "MIDI":
        return "icon-midi";
      default:
        return "icon-box-add";
    }
  };

  render() {
    return (
      <div className="file__container">
        {this.state.clickResponse && (
          <div className="download-response">
            <p className={this.state.downloadSuccess ? "download-success" : "download-failure"}>
              {this.state.clickResponse}
            </p>
          </div>
        )}
        {this.state.needsLogin && (
          <div className="needs-login">
            <UserItemDummyButton clickedText={true} extraText={"to download files"} />
          </div>
        )}
        <button
          className="file__download uppercase"
          onClick={this.handleClick}
          disabled={this.state.pending}
        >
          <span className={"icon " + this.iconToShow()}></span>{" "}
          <p>Download {this.props.file["title"]} </p>
        </button>
      </div>
    );
  }
}
