import React, { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { withBootstrapSize } from "../../shared/WithBootstrapSize";
import Swoosh from "../../shared/Swoosh";
import axiosWithCSRF from "../../shared/axiosWithCSRF";
import SongsPageSearch from "../filters/SongsPageSearch";
import ArtistFilters from "./ArtistFilters";
import ArtistListing from "./ArtistListing";
import { filterOptionsForQueryParams } from "../filters/filters";
import ArtistSort from "./ArtistSort";
import Loading from "../../shared/Loading";
import { BootstrapSize, breakpoints } from "../../shared/bootstrap-helpers";
import ArtistSearchParamsPane from "./ArtistsSearchParamsPane";
import { ArtistFiltersButton } from "./ArtistFilterButton";

const ARTISTS_URL = `/artists.json`;

const Artists = ({ currentArtist, width, excludeTopMargin, filters }) => {
  const [showSearchParamsPane, setShowSearchParamsPane] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [artists, setArtists] = useState([]);
  const [loadingMoreResults, setLoadingMoreResults] = useState(false);
  const [hasMoreArtists, setHasMoreArtists] = useState(true);
  const [searchQuery, setSearchQuery] = useState(null);
  const [fullCount, setFullCount] = useState(0);
  const [activeFilters, setActiveFilters] = useState({});
  const [filterQueryString, setFilterQueryString] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [filtersOpen, setFiltersOpen] = useState(false);

  if (currentArtist) return null;

  useEffect(() => {
    fetchArtists();
  }, []);

  useEffect(() => {
    if (searchQuery === null || (searchQuery.length > 0 && searchQuery.length < 3)) return;
    fetchArtists();
  }, [searchQuery]);

  useEffect(() => {
    if (sortBy === null) return;
    fetchArtists();
  }, [sortBy]);

  useEffect(() => {
    if (filterQueryString === null) return;
    fetchArtists();
  }, [filterQueryString]);

  const debouncedQuery = useMemo(
    () =>
      debounce((e) => {
        setSearchQuery(e.target.value);
      }, 500),
    []
  );

  const fetchArtists = (replaceResults = true) => {
    setLoadingMoreResults(true);
    const paramsObject = { offset: replaceResults ? 0 : artists.length };
    if (searchQuery) {
      paramsObject.search_term = searchQuery;
    }
    const searchParams = new URLSearchParams(paramsObject).toString();

    const filterParams = filterQueryString?.length ? `&${filterQueryString}` : "";
    const sortByParams = sortBy?.length ? `&sort_by=${sortBy}` : "";
    const url = `${ARTISTS_URL}?${searchParams}${filterParams}${sortByParams}`;

    axiosWithCSRF()
      .get(url)
      .then((response) => {
        if (replaceResults) {
          setArtists(response.data.artists.data);
        } else {
          setArtists((artists) => [...artists, ...response.data.artists.data]);
        }
        setHasMoreArtists(response.data.hasMore);
        setFullCount(response.data.fullCount);
      })
      .finally(() => {
        setLoadingMoreResults(false);
        if (initialLoading) setInitialLoading(false);
      });
  };

  const clearFilters = () => {
    setActiveFilters({});
    setFilterQueryString("");
  };

  const toggleFilter = (filterName) => {
    const newActiveFilters = { ...activeFilters };
    newActiveFilters[filterName] = newActiveFilters[filterName] ? false : true;
    setActiveFilters(newActiveFilters);

    const filterMap = filterOptionsForQueryParams(newActiveFilters, filters);
    let filterString = new URLSearchParams(filterMap).toString();
    setFilterQueryString(filterString);
  };

  if (initialLoading) {
    return (
      <div>
        <Loading isContent={true} />
      </div>
    );
  }

  return (
    <Swoosh color="#F5F7FA" excludeTopMargin={excludeTopMargin}>
      <div className="artists container">
        <h2 className="header2">Artists {`${isNaN(fullCount) ? "" : fullCount}`}</h2>
        <div className="artists__main">
          <div className="artists__controls">
            <SongsPageSearch setQuery={debouncedQuery} className="artist-search" />

            {width > breakpoints[BootstrapSize.lgDevice] ? (
              <ArtistFilters
                activeFilters={activeFilters}
                toggleFilter={toggleFilter}
                clearFilters={clearFilters}
              />
            ) : width > breakpoints[BootstrapSize.smDevice] ? (
              <ArtistFiltersButton
                activeFilters={activeFilters}
                filtersOpen={filtersOpen}
                openFilters={() => setFiltersOpen(true)}
                closeFilters={() => setFiltersOpen(false)}
                clearFilters={clearFilters}
                hasActiveFilters={Object.values(activeFilters).some((f) => f)}
                toggleFilter={toggleFilter}
                buttonClassModifier="blue"
                defaultClosed={true}
              />
            ) : (
              <button
                className="btn--filters button button--blue"
                onClick={() => setShowSearchParamsPane(true)}
              >
                <i className="icon icon-sliders" />
              </button>
            )}
          </div>

          <div style={{ width: "100%" }}>
            <ArtistSort sortBy={sortBy} setSortBy={setSortBy} hasQuery={searchQuery?.length > 0} />
            <ArtistListing
              artists={artists}
              hasMoreArtists={hasMoreArtists}
              loadingMoreResults={loadingMoreResults}
              fetchArtists={fetchArtists}
            />
          </div>

          {showSearchParamsPane && (
            <ArtistSearchParamsPane
              close={() => setShowSearchParamsPane(false)}
              sortBy={sortBy}
              setSortBy={setSortBy}
              hasQuery={searchQuery?.length > 0}
              activeFilters={activeFilters}
              toggleFilter={toggleFilter}
              clearFilters={clearFilters}
            />
          )}
        </div>
      </div>
    </Swoosh>
  );
};

export default withBootstrapSize(Artists);
