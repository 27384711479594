import React from "react";
import SectionHeader from "./SectionHeader";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { SECTION_TOP_MARGIN } from "./consts";
import { jgDenimColors } from "../../jg-material/theme/jg-denim-colors";
import { fontWeights } from "../../jg-material/theme/typography";
import { CheckboxCheckedIcon } from "../MusicTheoryCourse/Icons";
import { CheckboxUncheckedIcon } from "../../components/new-payments/Icons";
import { PLAN_TYPES } from "../../subscriptions/consts";
import { withInnerWidth } from "../../components/shared";
import { BootstrapSize, breakpoints } from "../../components/shared/bootstrap-helpers";

const PRICING_PLAN_CARD_STYLE = {
  backgroundColor: jgDenimColors.gray.light,
};

const ACTIVE_PLAN_CARD_STYLE = {
  border: `2px solid ${jgDenimColors.action.success.default}`,
  backgroundColor: "white",
};

const BluesImmersionPricing = ({ activePlan, setActivePlan, pricingPlans, width }) => {
  const bottomContent = (plan) => {
    if (plan.attributes.planType === PLAN_TYPES.lifetime) {
      return `$${effectivePrice(plan)} billed once.`;
    } else {
      return `${plan.attributes.numberOfInstallments} installments of $${plan.attributes.price} per month.`;
    }
  };

  const effectivePrice = (plan) => {
    if (plan.attributes.withinPeriodDiscount) {
      return plan.attributes.withinPeriodDiscount.replace(".0", "");
    } else {
      return plan.attributes.price;
    }
  };

  const displayPrice = (plan) => {
    if (!plan) return;

    const { price, withinPeriodDiscount } = plan.attributes;
    if (withinPeriodDiscount) {
      return (
        <>
          <Typography variant="span" style={{ textDecoration: "line-through" }} mr={1}>
            ${price}
          </Typography>
          <Typography variant="span">${effectivePrice(plan)}</Typography>
        </>
      );
    } else {
      return <Typography variant="span">${price}</Typography>;
    }
  };

  return (
    <Box
      mt={SECTION_TOP_MARGIN}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      textAlign={"center"}
    >
      <SectionHeader content={"Pricing"} />

      <Grid container spacing={2} mb={2} sx={{ justifyContent: "center" }}>
        {pricingPlans.data.map((plan, idx) => {
          return (
            <Grid item key={`plan_${idx}`}>
              <Box
                key={plan.id}
                mt={width < breakpoints[BootstrapSize.smDevice] ? 2 : 4}
                style={activePlan === plan ? ACTIVE_PLAN_CARD_STYLE : PRICING_PLAN_CARD_STYLE}
                onClick={() => setActivePlan(plan)}
                sx={{
                  borderRadius: "10px",
                  height: 200,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  position: "relative",
                  cursor: "pointer",
                  width: "300px",
                }}
              >
                <Box sx={{ position: "absolute", left: 15, top: 15 }}>
                  {activePlan === plan ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}
                </Box>

                <Typography variant={"h6"} sx={{ letterSpacing: 0 }} fontWeight={fontWeights.bold}>
                  {plan.attributes.planType === PLAN_TYPES.lifetime
                    ? "One time payment"
                    : "Six monthly installments"}
                </Typography>

                <Typography variant={"h4"} fontWeight={fontWeights.bold}>
                  {displayPrice(plan)}
                </Typography>

                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                  <Typography
                    sx={{ maxWidth: "75%" }}
                    variant={"body1"}
                    mt={2}
                    color={jgDenimColors.denim.light}
                    fontWeight={fontWeights.semiBold}
                  >
                    {bottomContent(plan)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default withInnerWidth(BluesImmersionPricing);
