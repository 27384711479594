import React from "react";
import { CDN_URL } from "../components/shared/CdnUrl";

const DiscourseDisclaimer = ({ discussionUrl }) => {
  return (
    <div className="discourse__disclaimer">
      <h4 className="uppercase">More Discussion Features On</h4>
      <img src={`${CDN_URL}/forum/community-logo-v2.svg`} alt="JustinGuitar Community" />
      <p>This Discussion tab is limited to reading and commenting using plain text.</p>
      <p>
        To access all the JustinGuitar Community features and benefits (like replying to a specific
        comment, liking a comment or using rich text) please reply to this topic in the Community
        forum itself.
      </p>
      {/*<p>*/}
      {/*  This Discussion tab is <strong>temporarily</strong> limited to reading comments.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Please reply to this topic directly in the JustinGuitar Community by clicking on "View Full*/}
      {/*  Discussion" below or the "Leave a Comment" button. That also allows you to access all*/}
      {/*  Discussion features and benefits! Thank you.*/}
      {/*</p>*/}
      <a className="uppercase" href={discussionUrl} target="_blank">
        View Full Discussion
      </a>
    </div>
  );
};

export default DiscourseDisclaimer;
