import React, { useState } from "react";

import { ArtistFiltersList } from "./ArtistFilters";
import {
  resultMergeStrategies,
  selectArtistQuery,
  selectArtistSortBy,
  selectSortBy,
  slices,
  sortByLabels,
  sortByOptions,
  artistSortByLabels,
  fetchArtists,
} from "./store";
import Swoosh from "../shared/Swoosh";
import { connect } from "react-redux";
import { PreventScroll } from "../shared";

const panes = {
  query: "query",
  filters: "filters",
  sorts: "sorts",
};

export const SortsHeader = ({ setPane }) => (
  <>
    <i onClick={() => setPane(panes.filters)} className="icon icon-arrow-left" />
    <span>SORT BY</span>
  </>
);

export const SortsPane = connect(
  (state) => {
    const query = selectArtistQuery(state);
    return { sortBy: selectArtistSortBy(state), hasQuery: query != null && query.length > 0 };
  },
  (dispatch) => ({
    setSortBy: (sortBy) => {
      dispatch(slices.ui.actions.setArtistSortBy(sortBy));
      dispatch(fetchArtists());
    },
  })
)(({ sortBy, setSortBy, hasQuery }) => (
  <div>
    {Object.entries(artistSortByLabels).map(([value, label]) => {
      const checked = sortBy === value;
      const disabled = value === sortByOptions.relevance && !hasQuery;
      return (
        <div
          className={`sorts-option ${checked && "sorts-option--checked"} ${
            disabled && "sorts-option--disabled"
          }`}
          onClick={() => !disabled && setSortBy(value)}
          key={value}
        >
          <span>{label}</span>
          <i className={`icon icon-radio-button-${checked ? "checked" : "unchecked"}`} />
        </div>
      );
    })}
  </div>
));

export const FiltersHeader = ({ close }) => (
  <>
    <i onClick={close} className="icon icon-cross" />
    <span>FILTERS</span>
  </>
);

export const FiltersPane = connect((state) => ({ sortBy: selectSortBy(state) }))(
  ({ setPane, close, sortBy }) => (
    <>
      <div className="search-pane__sorts-link" onClick={() => setPane(panes.sorts)}>
        <div className="label">Sort By</div>
        <div className="selection">{sortByLabels[sortBy]}</div>
        <i className="icon icon-angle-right" />
      </div>
      <ArtistFiltersList />
    </>
  )
);

export const ArtistSearchParamsPane = ({ initialPane = panes.filters, close }) => {
  const [currPane, setPane] = useState(initialPane);

  return (
    <PreventScroll>
      <div className="search-pane">
        <div className="search-pane__header">
          {currPane === panes.filters ? (
            <FiltersHeader setPane={setPane} close={close} />
          ) : currPane === panes.sorts ? (
            <SortsHeader setPane={setPane} close={close} />
          ) : null}
        </div>

        <Swoosh color="white" excludeBottom>
          <div className="search-pane__body">
            {currPane === panes.filters ? (
              <FiltersPane setPane={setPane} close={close} />
            ) : currPane === panes.sorts ? (
              <SortsPane setPane={setPane} close={close} />
            ) : null}
          </div>
        </Swoosh>
      </div>
    </PreventScroll>
  );
};

export default ArtistSearchParamsPane;
