import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { CDN_URL } from "../../components/shared/CdnUrl";
import { savedChordsSelector } from "./store/chordsSelectors";
import { createChordItem, deleteChordItem } from "../../components/shared/session/sessionEffects";
import LoginModal from "../../components/auth/LoginModal";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import { DarkTooltip } from "../../components/shared/ui/Tooltips";
import "../../components/shared/polyfills/replaceAll.js";
const PLACEHOLDER_THUMB = `${CDN_URL}/images/thumbnails/video-coming-soon-placeholder.png`;

let leftHandedChordBoxEventTracked = false;

const Chord = ({
  data,
  setData,
  currentUser,
  token,
  lessons,
  savedChords,
  createChordItem,
  deleteChordItem,
  chordSections,
  getChordSectionTitle,
  chordVariants,
  bootstrapSize,
}) => {
  const [showLeftHandedChordBox, setShowLeftHandedChordBox] = useState(
    currentUser &&
      currentUser.data.attributes.settings.includes("left-hand") &&
      data.activeChord.image_left_hand
  );
  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const getRelatedLessonData = (activeChord) => {
    return {
      lesson1: lessons.find(
        (lesson) => lesson.slug === activeChord.related_lesson_one.split("/").pop()
      ),
      lesson2: lessons.find(
        (lesson) => lesson.slug === activeChord.related_lesson_two.split("/").pop()
      ),
    };
  };

  const relatedLessonData = data.activeChord ? getRelatedLessonData(data.activeChord) : null;

  const variants = chordVariants
    .filter((chordVariant) => chordVariant.title === data.activeChord.title)
    .sort((a, b) => {
      const nameA = a.search_title.toLowerCase();
      const nameB = b.search_title.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

  const savedChord =
    savedChords.length &&
    savedChords.find((chord) => chord.attributes.itemableId === data.activeChord.id);

  const toggleSaved = () => {
    if (currentUser) {
      if (savedChord) {
        deleteChordItem(savedChord);
      } else {
        createChordItem({
          user_item: {
            user_id: currentUser.data.id,
            itemable_type: "Chord",
            itemable_id: data.activeChord.id,
            item_status: "saved",
          },
        });
      }
    } else {
      setLoginModalOpen(true);
    }
  };

  const setFilter = (name) => {
    const activeSectionName = parseNameForUrl(name);

    setData({
      activeType: data.activeType,
      activeChord: data.activeChord,
      activeSection: activeSectionName,
    });

    window.location.hash = activeSectionName;
  };

  const parseNameForUrl = (name) => {
    return name.toLowerCase().replaceAll(" ", "-").replaceAll("-&-", "-").replaceAll(",", "");
  };

  const toggleLeftHandedChordBox = () => {
    setShowLeftHandedChordBox(!showLeftHandedChordBox);

    if (
      (!currentUser || !currentUser.data.attributes.settings.includes("left-hand")) &&
      !leftHandedChordBoxEventTracked
    ) {
      leftHandedChordBoxEventTracked = true;

      axiosWithCSRF().post("/left_hand_chord_box_views", {
        chord: data.activeChord.slug,
        left: true,
      });
    }
  };

  const isMdDevice = ["xsDevice", "smDevice", "mdDevice"].includes(bootstrapSize);

  useEffect(() => {
    if (!currentUser || !currentUser.data.attributes.settings.includes("left-hand")) {
      axiosWithCSRF().post("/left_hand_chord_box_views", {
        chord: data.activeChord.slug,
      });
    }
  }, []);

  const backgroundImage =
    data.activeChord.header_image_desktop && data.activeChord.header_image_mobile
      ? isMdDevice
        ? `url(${CDN_URL}/images/pages/chords/header-bg/${data.activeChord.header_image_mobile})`
        : `url(${CDN_URL}/images/pages/chords/header-bg/${data.activeChord.header_image_desktop})`
      : null;

  return (
    <div className="chord">
      <div className="chords__header" style={{ backgroundImage }}>
        <h1 className="header1">{data.activeChord.title}</h1>
        <p dangerouslySetInnerHTML={{ __html: data.activeChord.summary }}></p>
        <button className="back-btn" onClick={() => (window.location.href = "/chords")}>
          <i className="icon icon-arrow-left" />
          all chords
        </button>
      </div>
      <div className="chords__body">
        <div className="chords__nav">
          <h3 className="toc">table of contents</h3>
          {!isMdDevice && (
            <div className="chords__nav__groups">
              {chordSections.map((chordSection, idx) => (
                <button
                  key={idx}
                  className={data.activeSection === parseNameForUrl(chordSection) ? "active" : ""}
                  onClick={() => setFilter(chordSection)}
                >
                  {chordSection}
                </button>
              ))}
            </div>
          )}
        </div>
        <div className="chords__content">
          {variants.length > 0 && (
            <div className="chords__content__variations-nav">
              <p>Select chord variation:</p>
              <div>
                {variants.map((chordVariant, idx) => (
                  <button
                    key={idx}
                    onClick={() => (window.location.href = `/chords/${chordVariant.slug}`)}
                    className={data.activeChord.id === chordVariant.id ? "active" : ""}
                  >
                    {chordVariant.variant_title
                      ? chordVariant.variant_title
                      : idx === 0
                      ? "Standard"
                      : `Variation #${idx}`}
                  </button>
                ))}
              </div>
            </div>
          )}
          <div className="accordion questions">
            {chordSections.map((chordSection, idx) => (
              <div
                key={idx}
                className={`accordion__content open`}
                id={parseNameForUrl(chordSection)}
              >
                <div className="accordion__content__header" onClick={() => setFilter(chordSection)}>
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: getChordSectionTitle(idx, data.activeChord),
                    }}
                  ></h4>
                  {isMdDevice &&
                    (data.activeSection === parseNameForUrl(chordSection) ||
                    (window.location.hash.length === 0 && (idx === 0 || idx === 1)) ? (
                      <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.41 7.41003L6 2.83003L10.59 7.41003L12 6.00003L6 3.38077e-05L1.23266e-07 6.00003L1.41 7.41003Z"
                          fill="#1D3446"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.59 0L6 4.58L1.41 0L0 1.41L6 7.41L12 1.41L10.59 0Z"
                          fill="#1D3446"
                        />
                      </svg>
                    ))}
                </div>
                <div className="accordion__content__body chords__content__chord-description-container">
                  {idx === 0 &&
                    (!isMdDevice ||
                      data.activeSection === parseNameForUrl(chordSection) ||
                      window.location.hash.length === 0) && (
                      <div style={{ position: "relative", width: "100%" }}>
                        <div className="react-player-wrapper">
                          <ReactPlayer
                            url={data.activeChord.main_lesson}
                            controls={true}
                            width="100%"
                            allowFullScreen={true}
                            height="100%"
                            className="react-player"
                          />
                        </div>
                      </div>
                    )}
                  {idx === 1 &&
                    (!isMdDevice ||
                      data.activeSection === parseNameForUrl(chordSection) ||
                      window.location.hash.length === 0) && (
                      <div className="chord-box">
                        <div>
                          <button className="save-to-favorites" onClick={() => toggleSaved()}>
                            <i
                              className={`icon ${savedChord ? "icon-heart" : "icon-heart-outline"}`}
                            ></i>
                          </button>
                          <img
                            src={`${CDN_URL}/images/pages/chords/chord-diagrams/${data.activeChord.image_right_hand}`}
                            alt={`${data.activeChord.title} chord box`}
                            style={{
                              display: !showLeftHandedChordBox ? "inline" : "none",
                            }}
                          />
                          {data.activeChord.image_left_hand && (
                            <>
                              <img
                                src={`${CDN_URL}/images/pages/chords/chord-diagrams/${data.activeChord.image_left_hand}`}
                                alt={`${data.activeChord.title} chord box`}
                                style={{
                                  display: showLeftHandedChordBox ? "inline" : "none",
                                }}
                              />
                              <div className="chord-box__nav">
                                <div className="slider-toggle slider-toggle--songs">
                                  <div className="float-left">
                                    <input
                                      type="checkbox"
                                      id="endorsement-toggle"
                                      checked={showLeftHandedChordBox}
                                      onChange={() => toggleLeftHandedChordBox()}
                                    />
                                    <label htmlFor="endorsement-toggle">
                                      <div
                                        className={`switch switch--${
                                          showLeftHandedChordBox ? "left-hand" : "right-hand"
                                        }`}
                                        data-checked={"Left Hand"}
                                        data-unchecked={"Right Hand"}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <DarkTooltip
                                  arrow
                                  enterTouchDelay={0}
                                  placement={"bottom"}
                                  title="If you want to see only left-hand diagrams in your favorites, please visit the preferences area in your dashboard under ‘Account > Preferences’"
                                >
                                  <button type="button" className="info">
                                    <i className="icon icon-info"></i>
                                  </button>
                                </DarkTooltip>
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `${data.activeChord.description}`,
                          }}
                        ></div>
                      </div>
                    )}
                  {idx === 2 &&
                    (!isMdDevice || data.activeSection === parseNameForUrl(chordSection)) && (
                      <div className="lesson-list">
                        {relatedLessonData.lesson1 && (
                          <div>
                            <div>
                              {Object.keys(relatedLessonData.lesson1.thumbnails).length > 0 ? (
                                <img
                                  src={relatedLessonData.lesson1.thumbnails.medium}
                                  alt={relatedLessonData.lesson1.title}
                                />
                              ) : (
                                <img
                                  src={PLACEHOLDER_THUMB}
                                  alt={relatedLessonData.lesson1.title}
                                />
                              )}
                              <a href={data.activeChord.related_lesson_one}>
                                {relatedLessonData.lesson1.title}
                              </a>
                            </div>
                            <span>{relatedLessonData.lesson1.youtube_duration}</span>
                          </div>
                        )}
                        {relatedLessonData.lesson2 && (
                          <div>
                            <div>
                              {Object.keys(relatedLessonData.lesson2.thumbnails).length > 0 ? (
                                <img
                                  src={relatedLessonData.lesson2.thumbnails.medium}
                                  alt={relatedLessonData.lesson2.title}
                                />
                              ) : (
                                <img
                                  src={PLACEHOLDER_THUMB}
                                  alt={relatedLessonData.lesson2.title}
                                />
                              )}
                              <a href={data.activeChord.related_lesson_two}>
                                {relatedLessonData.lesson2.title}
                              </a>
                            </div>
                            <span>{relatedLessonData.lesson2.youtube_duration}</span>
                          </div>
                        )}
                      </div>
                    )}
                  {idx === 3 &&
                    (!isMdDevice || data.activeSection === parseNameForUrl(chordSection)) && (
                      <div className="how-to-practice">
                        <p>
                          I have two types of exercises I recommend when learning how to play the{" "}
                          {data.activeChord.title} chord:
                        </p>
                        <div className="practice-thumbs">
                          <img
                            src={`${CDN_URL}/images/pages/chords/lesson-thumb-1.png`}
                            alt="Chord Perfect Practice"
                          />
                          <a href="/guitar-lessons/one-minute-changes-exercise-b1-110">
                            <img
                              src={`${CDN_URL}/images/pages/chords/lesson-thumb-2.png`}
                              alt="One Minute Changes"
                            />
                          </a>
                        </div>
                        <p>
                          Chord Perfect Practice helps you identify and solve fingering problems you
                          might have when learning a new chord. The focus is chord accuracy! You'll
                          firstly strum the {data.activeChord.title} chord, but then play its
                          individual strings and make sure they sound clean. If they don't, identify
                          the problem and correct it!
                        </p>
                        <p>
                          <a href="/guitar-lessons/one-minute-changes-exercise-b1-110">
                            One Minute Changes
                          </a>{" "}
                          is an exercise about speed when switching between chords. Pick two chords
                          and see how many times you can switch back and forth between them in one
                          minute. Your goal is to reach around 60 chord changes per minute. Use My
                          Practice Assistant to keep track of your progress!
                        </p>
                        <div className="practice-thumbs">
                          <a href="/songs">
                            <img
                              src={`${CDN_URL}/images/pages/chords/lesson-thumb-3.png`}
                              alt="Song Practice"
                            />
                          </a>
                          <a href="/guitar-lessons/3-types-of-songs-you-should-practice-to-play-guitar-better-pc-001">
                            <img
                              src={`${CDN_URL}/images/pages/chords/lesson-thumb-4.png`}
                              alt="3 Types of Songs to Practice"
                            />
                          </a>
                        </div>
                        <p>
                          But don't forget: the big secret is to have fun! Learn the chords you need
                          to play the songs you love. That's the best way! You can choose from over{" "}
                          <a href="/songs">600 song lessons here</a>. Just filter for the{" "}
                          {data.activeChord.title} chord, and off you go! Not sure about it? Learn
                          more about{" "}
                          <a href="/guitar-lessons/3-types-of-songs-you-should-practice-to-play-guitar-better-pc-001">
                            the songs you should practice (to play better guitar!)
                          </a>
                          .
                        </p>
                      </div>
                    )}
                  {idx === 4 &&
                    (!isMdDevice || data.activeSection === parseNameForUrl(chordSection)) && (
                      <p dangerouslySetInnerHTML={{ __html: data.activeChord.theory }}></p>
                    )}
                  {idx === 5 &&
                    (!isMdDevice || data.activeSection === parseNameForUrl(chordSection)) &&
                    data.activeChord.commonProgression && (
                      <div className="chord-progression">
                        {data.activeChord.commonProgression
                          .replaceAll(" ", "")
                          .split(",")
                          .map((chord, idx) => (
                            <div
                              key={idx}
                              onClick={() =>
                                (window.location.href = `/chords/${chord
                                  .replace("m", "min")
                                  .toLowerCase()}`)
                              }
                            >
                              {chord}
                            </div>
                          ))}
                      </div>
                    )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <LoginModal
        token={token}
        isOpen={loginModalOpen}
        toggleModal={() => setLoginModalOpen(!loginModalOpen)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  token: state.session.token,
  lessons: state.entity.lessons,
  savedChords: savedChordsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  createChordItem: (chordId, status) => dispatch(createChordItem(chordId, status)),
  deleteChordItem: (chordId, status) => dispatch(deleteChordItem(chordId, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chord);
