import React from "react";
import OverviewSwoosh from "./OverviewSwoosh";
import { withBootstrapSize } from "../../components/shared/WithBootstrapSize";
import { CDN_URL } from "../../components/shared/CdnUrl";

const Topic = ({ faq }) => {
  return (
    <div className="topic">
      <h3>{faq.title}</h3>
      <ul>
        {faq.children.map((childFaq, idx) => (
          <li key={idx}>
            <button onClick={() => (window.location.href = `/faq/${childFaq.slug}`)}>
              {childFaq.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Overview = ({ searchPhrase, setSearchPhrase, searchResults, setSearchResults, faqs }) => {
  return (
    <>
      <OverviewSwoosh
        searchPhrase={searchPhrase}
        setSearchPhrase={setSearchPhrase}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
      />
      <div className="container">
        <div className="overview">
          {faqs.map((faq, idx) => (
            <Topic faq={faq} key={idx} />
          ))}
        </div>
        <div className="get-in-touch">
          <h2>Still need help?</h2>
          <p>
            If you have already browsed our Frequently Asked Questions but still need assistance,
            please contact my team.
            <br />
            They will be happy to help you!
          </p>
          <a href="/contact" className="button button--primary">
            get in touch
          </a>
          <img src={`${CDN_URL}/images/pages/faq/justin.png`} alt="Justin" />
        </div>
      </div>
    </>
  );
};

export default withBootstrapSize(Overview);
