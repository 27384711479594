import axiosWithCSRF from "../../../components/shared/axiosWithCSRF";

export function createLessonItem(lessonId, status) {
  return axiosWithCSRF()
    .post("/user_items/lesson_item.json", { lesson_id: lessonId, item_status: status })
    .then((res) => {
      return res.data.userItem.data;
    });
}

export function deleteLessonItem(lessonId, status) {
  return axiosWithCSRF()
    .delete(`/user_items/delete_lesson_item/${lessonId}?status=${status}`)
    .then(() => {
      return { id: lessonId, status: status };
    });
}
