import { Interpolation } from "@mui/system";
import { jgDenimColors } from "../../jg-denim-colors";

import { ComponentsPropsList, Theme } from '@mui/material';

export const body1: {
  props: Partial<ComponentsPropsList["MuiTypography"]>;
  style: Interpolation<{ theme: Theme }>;
} = {
  props: {
    variant: "body1",
  },
  style: ({ theme }) => ({
    lineHeight: "22px",
    color: jgDenimColors.denim.dark,

    // there's gotta be a better way to overwrite font size for all sizes
    fontSize: "14px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
    },
  }),
};
