import React from "react";
import onClickOutside from "react-onclickoutside";
import { connect } from "react-redux";
import axiosWithCSRF from "../shared/axiosWithCSRF";
import { CDN_URL } from "../shared/CdnUrl";

export const CDN_PREFIX = `${CDN_URL}/system/dragonfly/production/`;

const userOptions = (currentUser) => {
  if (!currentUser) {
    return { items: [] };
  } else {
    return {
      items: [
        {
          title: "Dashboard",
          link: `/users/${currentUser.data.attributes.slug}/dashboard`,
          dataMethod: "get",
        },
        {
          title: "Log Out BADGE",
          link: "/users/sign_out",
          dataMethod: "delete",
        },
      ],
    };
  }
};

const userImage = (currentUser, imgPath = null, imgBlob = null) => {
  if (imgBlob) {
    return (
      <div
        className="initial"
        style={{
          backgroundImage: `url(${URL.createObjectURL(imgBlob)})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>
    );
  } else if (imgPath) {
    return (
      <div
        className="initial"
        style={{
          backgroundImage: `url('${CDN_PREFIX}${imgPath}')`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>
    );
  } else {
    return <DefaultImage />;
  }
};

export const DefaultImage = ({ width, height }) => {
  return (
    <div className="initial">
      <svg
        width={width ? width : "36"}
        height={height ? height : "36"}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" fill="transparent" />
        <circle cx="18" cy="18" r="18" fill="url(#paint0_linear)" />
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="36"
          height="36"
        >
          <circle cx="18" cy="18" r="18" fill="#F5F7FA" />
        </mask>
        <g mask="url(#mask0)">
          <path
            d="M24.8622 20.9836C23.1124 22.6816 20.6763 23.747 18 23.747C15.3237 23.747 12.9219 22.6816 11.1378 20.9836C7.29492 23.2809 3.72656 26.4771 5.44212 29.8066C8.73599 36.1991 18 35.9993 18 35.9993C18 35.9993 27.264 36.1991 30.5579 29.8066C32.2734 26.4771 28.7051 23.2809 24.8622 20.9836Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.6027 6.45188C24.1532 3.65418 21.1819 3.00022 18.0024 3C18.0013 3 18.0006 3 18.0002 3C17.9993 3 17.999 3 17.9981 3C14.8182 3.00022 11.8471 3.65418 10.3972 6.45188C8.94741 9.25112 11.883 14.3245 12.5547 15.514C13.2265 16.7026 16.2292 21.4549 17.9979 21.4615C17.9986 21.4615 17.999 21.4615 17.9999 21.4615C18.0004 21.4615 18.0011 21.4615 18.0022 21.4615C19.7709 21.4549 22.7736 16.7026 23.4452 15.5142C24.1173 14.3243 27.0527 9.25112 25.6027 6.45188Z"
            fill="white"
          />
          <path
            d="M16.2122 17.5822V16.5296C16.2122 16.1787 16.4992 15.8917 16.8501 15.8917H18.4768C18.8277 15.8917 19.1147 16.1787 19.1147 16.5296V17.5822C19.1147 17.933 18.8277 18.2201 18.4768 18.2201H16.8501C16.4992 18.2201 16.2122 17.933 16.2122 17.5822ZM21.6983 11.2667C21.2837 11.8727 20.7096 12.3512 20.0716 12.7021C19.6889 12.9572 19.4656 13.2124 19.338 13.4676C19.2423 13.6589 19.1785 13.9141 19.1466 14.2012C19.1147 14.4244 18.9234 14.6158 18.7001 14.6158H16.6906C16.4355 14.6158 16.2122 14.3925 16.2441 14.1374C16.3079 13.5632 16.4355 13.1486 16.6587 12.8296C16.9458 12.415 17.4561 11.9684 18.1897 11.4262C18.5725 11.171 18.8915 10.884 19.1147 10.5331C19.338 10.1822 19.4337 9.7676 19.4337 9.28915C19.4337 8.81071 19.3061 8.42796 19.0509 8.14089C18.7958 7.85383 18.413 7.69434 17.9346 7.69434C17.5518 7.69434 17.2329 7.82193 16.9777 8.0452C16.8182 8.20468 16.6906 8.39606 16.6268 8.61934C16.563 8.9064 16.276 9.09778 15.957 9.09778L14.107 9.06588C13.8838 9.06588 13.6924 8.87451 13.6924 8.61934C13.7562 7.63055 14.1389 6.86504 14.8088 6.3547C15.5743 5.74868 16.6268 5.46161 17.9027 5.46161C19.2742 5.46161 20.3587 5.81247 21.1242 6.48229C21.9216 7.184 22.3044 8.10899 22.3044 9.28915C22.3363 10.0228 22.1449 10.6607 21.6983 11.2667Z"
            fill="#FD6A02"
          />
          <circle cx="18" cy="18" r="17" stroke="url(#paint1_linear)" strokeWidth="2" />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="0"
            y1="0"
            x2="36"
            y2="36"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#406E8E" />
            <stop offset="1" stopColor="#1D3446" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="36"
            y1="36"
            x2="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1D3446" />
            <stop offset="1" stopColor="#406E8E" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
export const CurrentUserBadge = ({
  currentUser,
  size,
  showName,
  showOptions = false,
  clickable = true,
  imgPath = null,
  imgBlob = null,
}) => {
  const [optionsOpen, setOptionsOpen] = React.useState();
  CurrentUserBadge.handleClickOutside = () => setOptionsOpen(false);
  const logout = () => {
    axiosWithCSRF()
      .delete("/users/sign_out")
      .finally(() => {
        location.href = "/";
      });
  };
  return (
    <div
      className={"current-user-badge " + (currentUser ? "clickable" : "")}
      onClick={() => setOptionsOpen(!optionsOpen)}
    >
      {showName && currentUser && (
        <span className="username">{currentUser.data.attributes.username}</span>
      )}
      <div className={`current-user-icon current-user-icon--${size}`}>
        <div className="outline">{userImage(currentUser, imgPath, imgBlob)}</div>
      </div>
      {optionsOpen && currentUser && (
        <div className="current-user-options">
          <a href={`/users/${currentUser.data.attributes.slug}/dashboard`}>Dashboard</a>
          <button onClick={() => logout()}>Log Out</button>
        </div>
      )}
    </div>
  );
};

export default connect(({ currentUser }) => ({ currentUser }))(
  onClickOutside(CurrentUserBadge, {
    handleClickOutside: () => CurrentUserBadge.handleClickOutside,
  })
);
