import { Components } from "@mui/material";
import { jgDenimColors } from "../../jg-denim-colors";

export const stepper: Components["MuiStepper"] = {
  styleOverrides: {
    root: {
      color: "white",
      backgroundColor: jgDenimColors.denim.dark,
      borderRadius: "10px",
      padding: 40,
    },
  },
};
