import React from "react";
import { CDN_URL } from "../../components/shared/CdnUrl";
import { YoutubeIcon, TikTokIcon, FacebookIcon } from "./Icons";

const CardList = ({ items, itemType }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: "#406E8E",
        width: "100%",
        alignItems: "center",
        borderRadius: "0 0 10px",
        marginTop: "-10px",
        padding: "10px",
      }}
    >
      {items.map((item) => (
        <a
          href={`${itemType == "lesson" ? "/guitar-lessons/" : "/songs/"}${
            item.data.attributes.slug
          }`}
        >
          <CardImage item={item} />
        </a>
      ))}
    </div>
  );
};

const ImageTile = ({ item }) => {
  return (
    <div>
      <a href={item.url} style={{ display: "block", margin: "10px 0 10px" }}>
        <img style={{ maxWidth: "100%" }} src={item.image} />
      </a>
    </div>
  );
};

const CardImage = ({ item }) => {
  const { image, thumbnails, vimeoThumbnail } = item.data.attributes;
  let imgSrc = `${CDN_URL}/images/default-social.png`;
  if (image && image.length > 0) {
    imgSrc = image;
  } else if (Object.keys(thumbnails).length > 0) {
    imgSrc = thumbnails["maxres"] ? thumbnails["maxres"] : thumbnails["high"];
  } else if (vimeoThumbnail && vimeoThumbnail.length > 0) {
    imgSrc = vimeoThumbnail;
  }
  return (
    <img
      style={{ maxWidth: "100%", width: "100%", borderRadius: "10px", margin: "10px 0" }}
      src={imgSrc}
    />
  );
};

// functional component breaking here for some reason, so use class component.
class Links extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      songsOpen: false,
      lessonsOpen: false,
    };
  }
  render() {
    const { links, banners } = this.props;
    const { songsOpen, lessonsOpen } = this.state;
    return (
      <div className="links">
        <div className="links__logo">
          <img src={`${CDN_URL}/redesign/2020/logos/logo-white.svg`} alt="JustinGuitar Logo" />
        </div>
        <div className="links__list">
          <a className="button button--primary button--inline" href="https://www.justinguitar.com">
            Website
          </a>
          <a
            className="button button--primary button--inline"
            href="https://www.justinguitar.com/guitar-lessons"
          >
            Guitar Courses
          </a>

          <span
            className={`button button--${lessonsOpen ? "blue" : "primary"}`}
            onClick={() => this.setState({ lessonsOpen: !lessonsOpen })}
          >
            Latest Lessons <i className={`icon icon-angle-${lessonsOpen ? "up" : "down"}`} />
          </span>

          {lessonsOpen && <CardList itemType={"lesson"} items={this.props.latestLessons} />}

          <span
            className={`button button--${songsOpen ? "blue" : "primary"}`}
            onClick={() => this.setState({ songsOpen: !songsOpen })}
          >
            Latest Songs <i className={`icon icon-angle-${songsOpen ? "up" : "down"}`} />
          </span>
          {songsOpen && <CardList itemType={"song"} items={this.props.latestSongs} />}

          <a
            className="button button--primary button--inline"
            href="https://www.justinguitar.com/products"
          >
            Products
          </a>

          {links.map((link, idx) => (
            <a href={link.url} className="button button--primary button--inline" key={idx}>
              {link.text}
            </a>
          ))}

          {banners.map((banner, idx) => (
            <ImageTile item={banner} key={idx} />
          ))}
        </div>
        <div className="links__social">
          <div className="some-links some-links--tight footer-links">
            <a href="https://www.youtube.com/user/JustinSandercoe" target="_blank">
              <YoutubeIcon />
              <span>
                Youtube <br />
                Main
              </span>
            </a>
            <a href="https://www.youtube.com/user/JustinSandercoeSongs" target="_blank">
              <YoutubeIcon />
              <span>
                Youtube <br />
                Songs
              </span>
            </a>
            <a href="https://www.facebook.com/justinguitarofficial/" target="_blank">
              <FacebookIcon />
              <span>Facebook</span>
            </a>

            <a href="https://www.tiktok.com/@justinguitarofficial" target="_blank">
              <TikTokIcon />
              <span>TikTok</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Links;
