import React from "react";
import PropTypes from "prop-types";
import { CDN_URL } from "../components/shared/CdnUrl";
import "../components/shared/polyfills/flat.js";

export default class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: this.props.items[0],
    };
  }

  currentIndex = () => {
    let { items } = this.props;
    return items.indexOf(this.state.activeItem);
  };

  nextItem = (currentIndex) => {
    if (currentIndex + 1 === this.props.items.length) {
      return this.props.items[0];
    } else {
      return this.props.items[currentIndex + 1];
    }
  };

  previousItem = (currentIndex) => {
    if (currentIndex === 0) {
      return this.props.items[this.props.items.length - 1];
    } else {
      return this.props.items[currentIndex - 1];
    }
  };

  isMobile = () => {
    return this.props.width <= 576;
  };

  card = (item, side) => {
    if (this.props.cardType === "grade") {
      return this.gradeCard(item, side);
    } else if (this.props.cardType === "skill") {
      return this.skillCard(item);
    }
    return this.standardCard(item, side);
  };

  gradeCard = (item, side) => {
    return (
      <div
        key={`grade-${item.key}`}
        onClick={() => this.setActiveCard(item, side)}
        className={`carousel__card carousel__card--grade carousel__card--${item.title.replace(
          " ",
          "-"
        )} carousel__card--${side}`}
      >
        <div style={{ textAlign: "center" }}>
          <span className="subtitle">{item.subtitle}</span>
          <h3>{item.title}</h3>
          {side === "center" && (
            <div className="carousel__grade-description">
              <div className="carousel__divider"></div>
              <p>{item.description}</p>
              <div className="carousel__link">
                {item.url && (
                  <a href={item.url} className="button button--transparent">
                    View Course
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  skillCard = () => {
    if (this.isMobile()) {
      return (
        <div className="carousel__stacked">
          {this.props.items.flat().map((i, index) => {
            return (
              <div className="guitar-course__skill" key={`skill-${index}`}>
                <i className=""></i>
                <a className="uppercase" href={i.url}>
                  <i className={`icon icon-${i["icon"]}`}></i>
                  <span>{i.title}</span>
                </a>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="carousel__skills">
          <div className="carousel__skill-list">
            {this.state.activeItem.map((i, index) => {
              return (
                <div className="guitar-course__skill" key={index}>
                  <i className=""></i>
                  <a className="uppercase" href={i.url}>
                    <i className={`icon icon-${i["icon"]}`}></i>
                    <span>{i.title}</span>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  cardWithImageStyle = (item) => {
    if (item.image) {
      return {
        background: `url('${CDN_URL}${item.image}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      };
    } else {
      return {};
    }
  };

  setActiveCard = (item, side) => {
    if (side != "center") {
      this.setState({ activeItem: item });
    }
  };

  standardCard = (item, side) => {
    return (
      <div
        style={this.cardWithImageStyle(item)}
        key={item.key}
        className={`carousel__card carousel__card--standard carousel__card--${side}`}
        onClick={() => this.setActiveCard(item, side)}
      >
        <div style={{ textAlign: "center" }}>
          <h3 className="uppercase">{item.title}</h3>
          {side === "center" && (
            <div className="carousel__fixed-bottom">
              <a href={item.url} className="button button--transparent">
                View Course
              </a>
            </div>
          )}
        </div>
      </div>
    );
  };

  cards = () => {
    if (this.props.cardType === "skill") {
      return this.skillCard();
    } else {
      let currentIndex = this.currentIndex();
      let items = [
        { item: this.previousItem(currentIndex), side: "left" },
        { item: this.state.activeItem, side: "center" },
        { item: this.nextItem(currentIndex), side: "right" },
      ];
      return (
        <div className="carousel__cards">
          <div
            className={
              "stacked-deck " +
              `stacked-deck--${this.props.cardType}` +
              ` stacked-deck--${this.state.activeItem.title.replace(" ", "-")}`
            }
          >
            <div className="stacked-deck__card"></div>
            <div className="stacked-deck__card"></div>
            <div className="stacked-deck__card"></div>
          </div>
          {items.map((i) => {
            return this.card(i.item, i.side);
          })}
        </div>
      );
    }
  };

  previous = () => {
    let previousItem = this.previousItem(this.currentIndex());
    this.setState({ activeItem: previousItem });
  };

  next = () => {
    let nextItem = this.nextItem(this.currentIndex());
    this.setState({ activeItem: nextItem });
  };

  isActiveDot = (item) => {
    if (Array.isArray(this.state.activeItem)) {
      return item[0]["title"] === this.state.activeItem[0]["title"];
    } else {
      return item === this.state.activeItem;
    }
  };

  carouselDots = () => {
    if (this.props.width > 576) {
      return (
        <div className="carousel__dots">
          {this.props.items.map((item, index) => {
            return (
              <div
                key={`dot-${index}`}
                onClick={() => this.setState({ activeItem: item })}
                className={
                  "carousel__dot " + (this.isActiveDot(item) ? "carousel__dot--active" : "")
                }
              ></div>
            );
          })}
        </div>
      );
    } else if (this.props.cardType != "skill") {
      return (
        <div className="carousel__numbers">
          <p>
            <span style={{ fontWeight: "bold" }}>
              {this.props.items.indexOf(this.state.activeItem) + 1}
            </span>{" "}
            of {this.props.items.length}
          </p>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="carousel">
        <div className="carousel__container">
          <i className="icon icon-angle-left" onClick={this.previous}></i>
          {this.cards()}
          <i className="icon icon-angle-right" onClick={this.next}></i>
        </div>
        <div className="carousel__count">{this.carouselDots()}</div>
      </div>
    );
  }
}

Carousel.propTypes = {
  items: PropTypes.array.isRequired,
  cardType: PropTypes.string,
};
