import React from "react";
import Swoosh from "../components/shared/Swoosh";
import { CDN_URL } from "../components/shared/CdnUrl";
import { BootstrapSize } from "../components/shared/bootstrap-helpers";
import { productTypes } from "./productTypes";
import { setQueryStringKeyValuePair } from "../utilities/query-string";
import { product } from "../components/songs/store/songSchema";
import { isBFPromoDate } from "./utils";

const PRODUCTS_HERO_PATH = `${CDN_URL}/images/products/redesign`;
const DEFAULT_SWOOSH_IMAGES = {
  lg: `${PRODUCTS_HERO_PATH}/products-hero-lg.png`,
  sm: `${PRODUCTS_HERO_PATH}/products-hero-v2-sm.png`,
};

export const productTypeHeaderInfo = {
  [productTypes.book]: {
    title: "Books",
    images: {
      lg: `${PRODUCTS_HERO_PATH}/books-hero-lg.jpg`,
      sm: `${PRODUCTS_HERO_PATH}/books-hero-sm.jpg`,
    },
  },
  [productTypes.app]: {
    title: "Apps",
    subtitle:
      "Here you'll find my mega-popular Beginner Song Course App and all my other awesome 'tool' apps, almost all are available for Apple and Android devices!",
    images: {
      lg: `${PRODUCTS_HERO_PATH}/apps-hero-lg.jpg`,
      sm: `${PRODUCTS_HERO_PATH}/apps-hero-sm.jpg`,
    },
  },
  [productTypes.jamTracks]: {
    title: "Jam Tracks",
    images: {
      lg: `${PRODUCTS_HERO_PATH}/jam-tracks-hero-lg.jpg`,
      sm: `${PRODUCTS_HERO_PATH}/jam-tracks-hero-sm.jpg`,
    },
  },
  [productTypes.digital]: {
    title: "Premium Courses",
    images: {
      lg: `${PRODUCTS_HERO_PATH}/premium-courses-hero-lg.jpg`,
      sm: `${PRODUCTS_HERO_PATH}/premium-courses-hero-sm.jpg`,
    },
  },
  [productTypes.merch]: {
    title: "Merch",
    images: {
      lg: `${PRODUCTS_HERO_PATH}/clothing-hero-lg.jpg`,
      sm: `${PRODUCTS_HERO_PATH}/clothing-hero-sm.jpg`,
    },
  },
  [productTypes.tabs]: {
    title: "Tabs",
    subtitle:
      "Get on-screen access to hundreds of verified tabs and chords that perfectly fit my song lessons!",
    extraHtml: {
      subscriber: `<a href="/songs" class="button button--primary">explore songs</a>`,
      nonSubscriber: `<a href="#tabs" class="button button--primary">start my ${
        isBFPromoDate() ? 7 : 7
      }-day free trial</a>`,
    },
    images: {
      lg: `${PRODUCTS_HERO_PATH}/tabs-hero-lg.jpg`,
      sm: `${PRODUCTS_HERO_PATH}/tabs-hero-sm.jpg`,
    },
  },
};

const ProductTypeHeader = ({ productType, songsSubscriber }) => {
  const { title, subtitle, extraHtml, images } = productTypeHeaderInfo[productType];
  return (
    <Swoosh
      imgUrl={{
        [BootstrapSize.smDevice]: images["lg"] || DEFAULT_SWOOSH_IMAGES["lg"],
        [BootstrapSize.xsDevice]: images["sm"] || DEFAULT_SWOOSH_IMAGES["sm"],
      }}
      // breadcrumbs={[
      //   {
      //     label: "Products",
      //     onClick: () => setQueryStringKeyValuePair("type", null),
      //   },
      //   { label: title },
      // ]}
      breadcrumbsCta={{ url: "/store", title: "back to store" }}
    >
      <div className="products-index__header products-index__header--product-type container">
        <h1>{title}</h1>
        {subtitle ? <p>{subtitle}</p> : null}
        {extraHtml && (
          <div
            dangerouslySetInnerHTML={{
              __html:
                productType == "Tabs"
                  ? songsSubscriber
                    ? extraHtml.subscriber
                    : extraHtml.nonSubscriber
                  : extraHtml,
            }}
          ></div>
        )}
      </div>
    </Swoosh>
  );
};

export default function ({ productType, songsSubscriber }) {
  if (productType != null) {
    return <ProductTypeHeader productType={productType} songsSubscriber={songsSubscriber} />;
  }

  return (
    <>
      <Swoosh
        imgUrl={{
          [BootstrapSize.smDevice]: DEFAULT_SWOOSH_IMAGES["lg"],
          [BootstrapSize.xsDevice]: DEFAULT_SWOOSH_IMAGES["sm"],
        }}
      >
        <div className="products-index__header container">
          <h1>Products</h1>
          <p>Here you'll find some premium products to take your guitar journey even further!</p>
        </div>
      </Swoosh>
    </>
  );
}
