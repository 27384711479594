import React from "react";
import { Exit, Stars, Download, AppStore, GooglePlay } from "./icons";
import * as icons from "./icons";
import { NUMBER_OF_REVIEWS_IOS, NUMBER_OF_REVIEWS_ANDROID } from "./consts";
import platform from "platform-detect";
import { withInnerWidth } from "../components/shared";

const MAX_ALLOWED_WIDTH = 440;

const CenterContentAndRightButtons = ({
  fullWidthSubtitle,
  subtitle,
  showRating,
  showDownloadText,
  buttonType,
  buttonText,
  logAndGoToOnelink,
  width,
}) => {
  return (
    <div className="app-banner__center-content-and-right-buttons">
      <div className="app-banner__center">
        {!fullWidthSubtitle && subtitle && <p className="app-banner__subtitle">{subtitle}</p>}

        {showRating && (
          <div className="app-banner__rating">
            <Stars />
            <span>{NUMBER_OF_REVIEWS_IOS}</span>
          </div>
        )}

        {showDownloadText && width < MAX_ALLOWED_WIDTH && (
          <p className="app-banner__download-text">download</p>
        )}
      </div>

      {buttonType === "text" ? (
        <div
          className="app-banner__download-button"
          style={{ padding: "10px 14px", borderRadius: "30px" }}
        >
          {buttonText}
        </div>
      ) : buttonType === "icon" ? (
        <div
          className="app-banner__download-button"
          style={{ padding: "6px 13px", borderRadius: "10px" }}
        >
          <Download />
        </div>
      ) : buttonType === "store" ? (
        platform.ios ? (
          <AppStore />
        ) : platform.android ? (
          <GooglePlay />
        ) : null
      ) : null}

      {buttonType === null && width >= MAX_ALLOWED_WIDTH && showDownloadText && (
        <p className="app-banner__download-text">download</p>
      )}
    </div>
  );
};

const SingleLineBanner = ({ banner, closeBanner, logAndGoToOnelink, width }) => {
  const {
    identifier,
    layout,
    theme,
    background,
    subtitle,
    fullWidthSubtitle,
    showRating,
    showReviewCount,
    showDownloadText,
    buttonText,
    buttonType,
    leftImageName,
    // appIcon,
    // textColor,
    // buttonColor,
  } = banner;

  const allowFullWidthSubtitle = fullWidthSubtitle && width < MAX_ALLOWED_WIDTH;

  return (
    <div
      className="app-banner"
      style={{ background: background, color: theme === "light" ? "#1D3446" : "white" }}
      onClick={logAndGoToOnelink}
    >
      <div className="app-banner__container">
        <div className="app-banner__close">
          <Exit color={theme === "light" ? "#81ABC1" : "white"} closeBanner={closeBanner} />
        </div>

        <div className="app-banner__left-image">
          {leftImageName && React.createElement(icons[leftImageName])}
        </div>

        {allowFullWidthSubtitle ? (
          <>
            <div className="app-banner__full-width-subtitle-container">
              <p className="app-banner__subtitle">{subtitle}</p>
              <CenterContentAndRightButtons
                fullWidthSubtitle={true}
                subtitle={subtitle}
                showRating={showRating}
                showDownloadText={showDownloadText}
                buttonType={buttonType}
                buttonText={buttonText}
                width={width}
              />
            </div>
          </>
        ) : (
          <>
            <CenterContentAndRightButtons
              fullWidthSubtitle={false}
              subtitle={subtitle}
              showRating={showRating}
              showDownloadText={showDownloadText}
              buttonType={buttonType}
              buttonText={buttonText}
              width={width}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default withInnerWidth(SingleLineBanner);
