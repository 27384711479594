import React from "react";
import axiosWithCSRF from "./shared/axiosWithCSRF";

export default class CancelSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubscriptionReference: null,
      isSubmitting: false,
    };
  }

  readyForSubmit = () => {
    let { selectedSubscriptionReference } = this.state;
    return selectedSubscriptionReference && selectedSubscriptionReference.length > 0 ? true : false;
  };

  formattedReference(reference) {
    let { sheetMusicReferences } = this.props;
    if (sheetMusicReferences && sheetMusicReferences.includes(reference)) {
      return "Sheet Music";
    } else {
      return reference;
    }
  }

  cancelOptions = () => {
    let { items } = this.props;

    if (items && items.length > 0) {
      return items.map((item) => {
        return (
          <option key={item.id} value={item.purchase_reference}>
            {this.formattedReference(item.purchase_reference)}
          </option>
        );
      });
    }
  };

  setItemToCancel = (event) => {
    this.setState({ selectedSubscriptionReference: event.target.value });
  };

  handleSubscriptionCancel = () => {
    if (confirm("Are you sure you want to cancel this subscription? This cannot be undone.")) {
      this.setState({ isSubmitting: true });
      let item = this.props.items.filter(
        (i) => i.purchase_reference === this.state.selectedSubscriptionReference
      )[0];
      axiosWithCSRF()
        .post("/cancel-subscription-purchase", {
          item: item,
          authenticity_token: ReactOnRails.authenticityToken(),
        })
        .then((res) => {
          this.setState({
            successResponse: "You successfully cancelled this subscription.",
            isSubmitting: false,
          });
        })
        .catch((err) => {
          this.setState({
            errorResponse: err.response.data.error.message,
            isSubmitting: false,
          });
        });
    }
  };

  formattedEndDate = () => {
    let { selectedSubscriptionReference } = this.state;
    let item = this.props.items.filter(
      (i) => i.purchase_reference === selectedSubscriptionReference
    )[0];
    if (item) {
      let dateFormat = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let endDate = new Date(item.subscription_period_end);
      let formattedDate = endDate.toLocaleDateString("en-US", dateFormat);
      return ` You will no longer have access to ${this.formattedReference(
        item.purchase_reference
      )} after ${formattedDate}`;
    }
  };

  responseMessage = () => {
    let { successResponse, errorResponse } = this.state;
    if (successResponse) {
      return (
        <h4 className="success-message">
          {successResponse} {this.formattedEndDate()}
        </h4>
      );
    } else if (errorResponse) {
      return (
        <h4 className="error-response">
          There was a problem canceling your subscription: {errorResponse}
        </h4>
      );
    }
  };

  render() {
    let { items } = this.props;
    if (!items || items.length === 0) {
      return (
        <div>
          <h3>You do not have any active subscriptions to cancel.</h3>
        </div>
      );
    } else if (this.state.isSubmitting) {
      return (
        <div className="pending">
          <img src="/images/Rolling.gif"></img>
          <h4 className="header4 bold justin-blue">canceling your subscription</h4>
        </div>
      );
    } else {
      return (
        <div>
          {this.responseMessage()}
          {!this.state.successResponse && (
            <div>
              <h3 className="header3">
                Please choose the subscription that you would like to cancel.
              </h3>
              <select className="cancel-subscription__options" onChange={this.setItemToCancel}>
                <option></option>
                {this.cancelOptions()}
              </select>

              <button
                type="button"
                id="blue-button"
                onClick={this.handleSubscriptionCancel}
                disabled={this.readyForSubmit() === false}
              >
                submit
              </button>
            </div>
          )}
        </div>
      );
    }
  }
}
