import React from "react";
import { getLessonThumb } from "../../utilities/thumb";

const LessonCard = ({ lesson, currentUser, savedLessonIds, toggleSave }) => {
  const backgroundImage = getLessonThumb(lesson.data.attributes);

  return (
    <div className="card">
      <div className="thumb" style={{ backgroundImage: `url(${backgroundImage})` }}>
        {currentUser && (
          <button onClick={(e) => toggleSave(e, lesson.data.attributes)}>
            {currentUser && savedLessonIds.includes(lesson.data.attributes.id) ? (
              <i className="icon icon-heart"></i>
            ) : (
              <span className="glyphicon glyphicon-heart-empty"></span>
            )}
          </button>
        )}
      </div>
      <a href={`/guitar-lessons/${lesson.data.attributes.slug}`}>{lesson.data.attributes.title}</a>
      <div className="info">
        {lesson.data.attributes.youtubeDuration &&
          lesson.data.attributes.youtubeDuration.length > 0 && (
            <p className="info__duration">
              <i className="icon icon-clock2"></i>
              {lesson.data.attributes.youtubeDuration}
            </p>
          )}
      </div>
    </div>
  );
};

export default LessonCard;
