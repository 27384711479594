import React from "react";
import PropTypes from "prop-types";
import Carousel from "./Carousel";

const SKILLS = [
  { key: "S1", title: "Essential Knowledge", url: "/categories/knowledge-base", icon: "bulb" },
  { key: "S2", title: "Scales & Modes", url: "/categories/scales-modes", icon: "scales" },
  { key: "S3", title: "Chords", url: "/categories/chords", icon: "fretboard-chords" },
  { key: "S4", title: "Arpeggios", url: "/categories/arpeggios", icon: "music-note" },
  {
    key: "S5",
    title: "Guitars, Amps & Effects",
    url: "/categories/guitars-amps-effects",
    icon: "amp",
  },
  {
    key: "S6",
    title: "Practical Music Theory",
    url: "/store/practical-music-theory",
    icon: "theory-note",
  },
  {
    key: "S12",
    title: "Blues Immersion",
    url: "/store/blues-immersion",
    icon: "record",
  },
  { key: "S7", title: "Technique", url: "/categories/technique-study", icon: "pick" },
  { key: "S8", title: "Ear Training", url: "/categories/ear-training", icon: "ear" },
  { key: "S9", title: "Transcribing", url: "/categories/transcribing", icon: "transcribe" },
  { key: "S10", title: "Songwriting", url: "/modules/songwriting", icon: "songwriting" },
  { key: "S11", title: "Production", url: "/categories/production", icon: "production" },
];

const SKILLS_FOR_CAROUSEL = () => {
  let chunkedArray = [];
  for (let i = 0; i < SKILLS.length; i += 4) {
    chunkedArray.push(SKILLS.slice(i, i + 4));
  }
  return chunkedArray;
};

const skillTiles = () => {
  return SKILLS.map((s) => {
    return (
      <div className="guitar-course__skill" key={s["key"]}>
        <a className="uppercase" href={s["url"]}>
          <i className={`icon icon-${s["icon"]}`}></i>
          <span>{s["title"]}</span>
        </a>
      </div>
    );
  });
};

const skillCarousel = (width) => {
  return <Carousel width={width} items={SKILLS_FOR_CAROUSEL()} cardType={"skill"} />;
};

const CoursesBySkill = (props) => {
  return (
    <div className="guitar-courses__skills lessons__right">
      {props.width < 576 && (
        <>
          <div className="swoosh swoosh--top-left"></div>
          <div className="swoosh swoosh--bottom-right"></div>
        </>
      )}
      <h3 className="header3 uppercase">Skills</h3>
      {props.width > 768 ? skillTiles() : skillCarousel(props.width)}
    </div>
  );
};

export default CoursesBySkill;

CoursesBySkill.propTypes = {
  width: PropTypes.number.isRequired,
};
