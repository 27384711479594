import React from "react";
import axios from "axios";

export default class CreateRoutine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routineTitle: "",
    };
  }

  toggleForm = () => {
    this.setState({ formOpen: !this.state.formOpen, routineTitle: "" });
  };

  inputDisabled = () => {
    let { routineTitle } = this.state;
    let { errors } = this.props;
    if (errors) {
      return true;
    }
    return routineTitle && routineTitle.length > 2 ? false : true;
  };

  handleTitleChange = (event) => {
    this.setState({ routineTitle: event.target.value });
  };

  submitNewRoutine = () => {
    axios
      .post("/user_practice_routines", {
        user_practice_routine: {
          title: this.state.routineTitle,
          is_active: false,
        },
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((response) => {
        this.props.handleNewRoutine(response.data.routine);
        this.toggleForm();
      })
      .catch((err) => {
        let error = "There was a problem creating your routine.";
        if (err.response.data && err.response.data.message) {
          error = err.response.data.message;
        }
        this.props.handleNewRoutine(null, error);
      });
  };

  render() {
    if (this.state.formOpen) {
      return (
        <div className="new-routine__form">
          {this.props.errors && (
            <p className="has-errors" style={{ margin: "10px 0" }}>
              {this.props.errors}
            </p>
          )}
          <div className="new-routine__title">
            <div className="new-routine__title__inner">
              <span className="icon icon-pencil-outline"></span>
              <input
                type="text"
                value={this.state.routineTitle}
                onChange={this.handleTitleChange}
              ></input>
            </div>

            <span className="routine__form-field__label">Title</span>
          </div>
          <div className="practice-buttons__container practice-buttons__container--new-routine">
            <button
              className="practice__button practice__button--save"
              onClick={this.submitNewRoutine}
              disabled={this.inputDisabled()}
            >
              submit
            </button>
            <button className="practice__button practice__button--cancel" onClick={this.toggleForm}>
              cancel
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <button
          className="practice__button practice__button--save practice__button--new-routine"
          onClick={this.toggleForm}
        >
          <i className="icon icon-plus"></i>
          <span>add new routine</span>
        </button>
      );
    }
  }
}
