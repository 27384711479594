import React from "react";
import DynamicIframe from "../../../DynamicIframe";

export default class RelatedVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  fullPathToVideo = () => {
    let video = this.props.item.related_video;
    if (video && video.includes("vimeo")) {
      return video;
    } else if (video) {
      return `https://www.youtube.com/embed/${video}`;
    }
  };

  render() {
    if (this.props.item.related_video && this.props.videoOpen) {
      return (
        <div className="practice-item__video">
          <i className="icon icon-film"></i>
          <span className="toggle-practice-video" onClick={this.props.toggleVideo}>
            <i className="icon icon-view-hide"></i> hide video
          </span>
          <DynamicIframe
            showDonateAfterVideo={true}
            link={this.fullPathToVideo()}
            containerHeight="200"
            aspectRatio="1.777"
          />
        </div>
      );
    } else if (this.props.item.related_video) {
      return (
        <div className="practice-item__video">
          <i className="icon icon-film"></i>
          <span className="toggle-practice-video" onClick={this.props.toggleVideo}>
            <i className="icon icon-view-show"></i> show video
          </span>
        </div>
      );
    } else {
      return null;
    }
  }
}
