import React, { useEffect, useState } from "react";
import TabbedContent from "../shared/TabbedContent";
import { connect } from "react-redux";
import { userCompletedGroups } from "../../components/shared/session/sessionSelectors";
import { CaretIcon } from "../shared/CaretIcon";
import { without, cloneDeep } from "lodash";
import { createLessonItem, deleteLessonItem } from "../../components/shared/session/sessionEffects";
import Loading from "../../components/shared/Loading";

const PAID_COURSE_SLUGS = [
  "blues-immersion-class-1",
  "practical-music-theory-course-fast-hands-on",
];

const TABS = [
  { name: "in progress", nameShort: "in progress" },
  { name: "completed", nameShort: "completed" },
];

const LearningJourneyTab = ({
  userCompletedGroups,
  createLessonItem,
  deleteLessonItem,
  isLoading,
}) => {
  const getTabByName = (name) => {
    const tab = TABS.find((tab) => tab.name.toLowerCase() === name.toLowerCase());

    return tab ? tab : TABS[0];
  };

  const [activeTab, setActiveTab] = useState(
    getTabByName(location.hash.replace("-", " ").slice(1))["name"]
  );

  const [groupsInProgress, setGroupsInProgress] = useState([]);
  const [groupsCompleted, setGroupsCompleted] = useState([]);
  const [openGroups, setOpenGroups] = useState([]);
  const [openModules, setOpenModules] = useState([]);

  useEffect(() => {
    setGroupsInProgress(getGroups(true));
    setGroupsCompleted(getGroups(false));
  }, [userCompletedGroups]);

  const getGroups = (inProgress = true) => {
    const groups = cloneDeep(userCompletedGroups).filter((group) =>
      inProgress
        ? group.attributes.allLessonsWithinModule.length >
          group.attributes.lessonsWithinModule.length
        : group.attributes.allLessonsWithinModule.length ===
          group.attributes.lessonsWithinModule.length
    );

    const mergedGroups = [];

    groups.forEach((group) => {
      /* There are some cases where a module might not belong to a class! */

      if (group && group.attributes.parentGroup) {
        if (!mergedGroups.hasOwnProperty(group.attributes.parentGroup.id)) {
          mergedGroups[group.attributes.parentGroup.id] = group.attributes.parentGroup;
          mergedGroups[group.attributes.parentGroup.id]["modules"] = [];
        }

        const module = {
          id: group.attributes.itemableId,
          title: group.attributes.groupTitle,
          url: group.attributes.itemableUrl,
          lessons: group.attributes.allLessonsWithinModule,
          completedLessonIds: group.attributes.nestedIds,
        };
        module["percentsCompleted"] = Math.ceil(
          (group.attributes.lessonsWithinModule.length /
            group.attributes.allLessonsWithinModule.length) *
            100
        );
        module[
          "completedCount"
        ] = `${group.attributes.lessonsWithinModule.length}/${group.attributes.allLessonsWithinModule.length}`;
        mergedGroups[group.attributes.parentGroup.id]["modules"].push(module);
        mergedGroups[group.attributes.parentGroup.id]["modules"].sort((el1, el2) => {
          return (
            mergedGroups[group.attributes.parentGroup.id].group_order.indexOf(el1.id) -
            mergedGroups[group.attributes.parentGroup.id].group_order.indexOf(el2.id)
          );
        });
      }
    });

    // Reset array keys, add additional data and calculate percents for the group
    let result = [];
    mergedGroups.forEach((group) => {
      group["percentsCompleted"] = Math.ceil(
        (group.modules.reduce((acc, curr) => {
          return acc + curr.percentsCompleted;
        }, 0) /
          (group.moduleCount * 100)) *
          100
      );

      // TODO: this needs to be changed. We can't rely on pre_title to distinguish between bg/int/adv. courses and others

      if (group.pre_title && PAID_COURSE_SLUGS.includes(group.slug) === false) {
        group["title"] = `grade ${group.grade}`;
        group["grade"] = group.grade;
      }
      result.push(group);
    });

    result.sort((el1, el2) => {
      return el2.grade - el1.grade && (el2.pre_title || "").length - (el1.pre_title || "").length;
    });

    return result;
  };

  const toggleGroup = (event, group) => {
    event.stopPropagation();
    const idx = openGroups.indexOf(group.id);
    if (idx > -1) {
      const arr = [...openGroups];
      arr.splice(idx, 1);
      setOpenGroups(arr);
      // also collapse open modules under the closed group
      setOpenModules(without(openModules, ...group.modules.map((module) => module.id)));
    } else {
      setOpenGroups(openGroups.concat(group.id));
    }
  };

  const toggleModule = (event, module) => {
    event.stopPropagation();
    const idx = openModules.indexOf(module.id);
    if (idx > -1) {
      const arr = [...openModules];
      arr.splice(idx, 1);
      setOpenModules(arr);
    } else {
      setOpenModules(openModules.concat(module.id));
    }
  };

  const toggleCompleted = (event, module, lesson) => {
    event.stopPropagation();
    !module.completedLessonIds.includes(lesson.id)
      ? createLessonItem({
          lessonId: lesson.id,
          status: "completed",
        })
      : deleteLessonItem({
          lessonId: lesson.id,
          status: "completed",
        });
  };

  const collection = activeTab === "in progress" ? groupsInProgress : groupsCompleted;

  return (
    <>
      <div className="dashboard-learning-journey">
        <TabbedContent
          title="My Learning Journey"
          setActiveTab={(tab) => {
            setActiveTab(tab);
          }}
          activeTab={activeTab}
          tabOptions={TABS}
        >
          <div className="tab-content">
            {collection.length === 0 ? (
              <div>
                <p>You don't have any {activeTab} lessons yet.</p>
              </div>
            ) : (
              collection.map((group, idx) => (
                <div className="item-container" key={idx}>
                  <div
                    className={`item tile ${
                      group.pre_title ? `tile--grade-${group.grade}` : `tile--grade-0`
                    }`}
                    style={group.pre_title ? {} : { color: "white" }}
                    onClick={() => (window.location.href = `/classes/${group.slug}`)}
                  >
                    <span>{group.title}</span>
                    <button onClick={(e) => toggleGroup(e, group)}>
                      <CaretIcon
                        rotate={openGroups.includes(group.id)}
                        color={group.pre_title ? "#1d3446" : "white"}
                      />
                    </button>
                  </div>
                  {openGroups.includes(group.id) && (
                    <ul className="groups">
                      {group.modules.map((module, idx) => (
                        <li key={idx}>
                          <div className="item" onClick={() => (window.location.href = module.url)}>
                            {module.title}
                            <button onClick={(e) => toggleModule(e, module)}>
                              <CaretIcon rotate={openModules.includes(module.id)} />
                            </button>
                            <div className="item__progress">
                              <p>{module.completedCount}</p>
                            </div>
                          </div>
                          {openModules.includes(module.id) && (
                            <ul className="lessons">
                              {module.lessons.map((lesson, idx) => (
                                <li key={idx} onClick={() => (window.location.href = lesson.url)}>
                                  {lesson.title}
                                  <i
                                    className={`icon icon-check_circle_outline${
                                      !module.completedLessonIds.includes(lesson.id)
                                        ? " icon-check_circle_outline--uncompleted"
                                        : ""
                                    }`}
                                    onClick={(e) => toggleCompleted(e, module, lesson)}
                                  ></i>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))
            )}
          </div>
        </TabbedContent>
        {isLoading ? <Loading /> : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  userCompletedGroups: userCompletedGroups(state),
  isLoading: state.ui.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  createLessonItem: (lessonId, status) => dispatch(createLessonItem(lessonId, status)),
  deleteLessonItem: (lessonId, status) => dispatch(deleteLessonItem(lessonId, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LearningJourneyTab);
