import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosWithCSRF from "../../../shared/axiosWithCSRF";

import * as slices from "../slices";
import {
  selectCurrentUserSongRequestsMetadata,
  selectSongRequestsMetadata,
} from "../songSelectors";
import { normalize } from "normalizr";
import { songRequestsJson } from "../songSchema";

export const fetchCurrentUserSongRequests = createAsyncThunk(
  "songs/fetchCurrentUserSongRequests",
  (arg, { dispatch, getState }) => {
    dispatch(slices.loading.actions.setCurrentUserSongRequestsListingLoading(true));

    const metadata = selectCurrentUserSongRequestsMetadata(getState()),
      nextPage = Number(metadata.page) + 1,
      queryString = `page=${nextPage}&per_page=${metadata.perPage}&current_user`;

    return axiosWithCSRF()
      .get(`/song_requests.json?${queryString}`)
      .then(({ data }) => data)
      .catch(() => {
        dispatch(slices.ui.actions.openAlert({ message: "An Error Occurred" }));
      })
      .finally(() =>
        dispatch(slices.loading.actions.setCurrentUserSongRequestsListingLoading(false))
      );
  }
);
