import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { WorkshopEntity } from "../interfaces";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export const WorkshopAbout = ({ workshop }: { workshop: WorkshopEntity }) => {
  const [expanded, setExpanded] = React.useState(false);
  const paragraphs = workshop.attributes.about.split("\n");
  const paragraphsSliced = paragraphs.slice(0, expanded ? undefined : 1);
  return (
    <Box mr={jgDenimTheme.spacing(2)}>
      <Typography variant="h4" textAlign="left" margin={jgDenimTheme.spacing(6, 0, 4, 0)}>
        About This Workshop
      </Typography>
      {paragraphsSliced.map((p, idx, arr) => {
        const nextLine = arr[idx + 1];
        return (
          <Typography
            variant="body1"
            mb={jgDenimTheme.spacing(nextLine != null && nextLine.startsWith("-") ? 0 : 2)} // don't put spaces in song list (hacky solution for now)
            key={idx}
            dangerouslySetInnerHTML={{ __html: p }}
          ></Typography>
        );
      })}
      {paragraphs.length > 1 && (
        <Box mb={2}>
          <Button
            variant={"text"}
            size={"small"}
            sx={{ textTransform: "unset" }}
            onClick={() => setExpanded(!expanded)}
          >
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <span>{expanded ? "Read Less" : "Read More"}</span>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </Box>
          </Button>
        </Box>
      )}
    </Box>
  );
};
