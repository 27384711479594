import React from "react";
import { formattedDuration } from "../PracticeTabFunctions";

export default class InactiveItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  leftDisplayIcon = (item, index) => {
    let { completedItems } = this.props;
    if (completedItems && completedItems.includes(item.id)) {
      return <span className="icon icon-check"></span>;
    } else {
      return <span className="items-list__number">{index + 1}</span>;
    }
  };

  render() {
    let { index, item } = this.props;
    return (
      <div className="practice__outer">
        <div className="practicing__list-item practicing__list-item--inactive">
          <span className="inactive-item inactive-item--clickable">
            <div className="practicing__list-item__inner">
              <div className="icon-and-text">
                {this.leftDisplayIcon(item, index)}
                <span className="clickable" onClick={() => this.props.makeActive(item)}>
                  {item.title}
                </span>
              </div>

              <span className="practicing__list-duration">
                {formattedDuration(item)}
                <span className="icon icon-clock2"></span>
              </span>
            </div>
          </span>
        </div>
      </div>
    );
  }
}
