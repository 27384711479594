import React from "react";
import { formatDate } from "../Purchases/purchaseUtils";

const Cancel = ({ id, expiresAt, openDialog, planStillAvailable }) => {
  const unavailablePlanMessage = `
    <br><br>
    <span style="color: #fd6a02;">
      You're subscribed to a payment plan that no longer exists! If you cancel your subscription now, you cannot subscribe again to this same price. Are you sure you want to cancel your subscription?
    </span>
  `;

  const secondaryMessage = () => {
    if (expiresAt) {
      return `You will still have access until ${formatDate(expiresAt)}. ${
        planStillAvailable ? "" : unavailablePlanMessage
      }`;
    } else {
      return null;
    }
  };

  return (
    <p
      className="orange clickable"
      onClick={() => {
        openDialog({
          message: "Are you sure you want to cancel this subscription?",
          secondaryMessage: secondaryMessage(),
          cancelButtonText: "No, keep my subscription",
          confirmButtonText: "Yes, cancel my subscription",
          data: { itemId: id, useNewCancelRoute: true },
        });
      }}
    >
      Cancel subscription
    </p>
  );
};

export default Cancel;
