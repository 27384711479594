import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

const ActivateRoutine = (props) => {
  const submitForm = () => {
    axios
      .patch(`/user_practice_routines/change_active_routine/${props.routineId}`, {
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((response) => {
        props.changeActiveRoutine(response.data.routine);
      })
      .catch((error) => {
        props.changeActiveRoutine(null);
      });
  };

  return (
    <div className="practice-buttons__container practice-buttons__container--with-margin">
      <button className="practice__button practice__button--green" onClick={submitForm}>
        make active routine
      </button>
      <button className="practice__button practice__button--cancel" onClick={props.closeForm}>
        cancel
      </button>
    </div>
  );
};

ActivateRoutine.propTypes = {
  routineId: PropTypes.string.isRequired,
  changeActiveRoutine: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default ActivateRoutine;
