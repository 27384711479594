import { FontFamily, FontWeight } from "./interfaces";
import { TypographyOptions } from "@mui/material";

export const fontFamilies: Record<FontFamily, string> = {
  [FontFamily.montserrat]: '"Montserrat", sans-serif',
  [FontFamily.openSans]: '"Open Sans", sans-serif',
};

export const fontWeights: Record<FontWeight, number> = {
  [FontWeight.default]: 400,
  [FontWeight.semiBold]: 600,
  [FontWeight.bold]: 700,
};

export const typography: TypographyOptions = {
  fontFamily: fontFamilies.openSans,
  fontSize: 16,
};
