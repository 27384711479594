import React, { useEffect, useState } from "react";
import Loading from "../../Loading";
import { openAlert } from "../../ui/uiSlice";
import axiosWithCSRF from "../../axiosWithCSRF";
import { connect } from "react-redux";

const PracticeRoutineItem = ({ item }) => {
  const { duration, title } = item.attributes;
  return (
    <div className="item-within-routine">
      <span>{title}</span>
      <span>{duration} min</span>
    </div>
  );
};

const AlreadySaved = () => {
  return (
    <div className="practice-item__options">
      <button className="button disabled" disabled>
        saved this practice routine
      </button>
    </div>
  );
};

const SaveRoutine = ({ loading, setLoading, routineId, setUserRoutine, openAlert }) => {
  const [optionsOpen, setOptionsOpen] = useState(false);

  function addRoutine(setAsActive) {
    setLoading(true);
    axiosWithCSRF()
      .post(`/user_practice_routines/add_admin_generated_routine`, {
        practice_routine_id: routineId,
        set_as_active_routine: setAsActive,
      })
      .then((response) => {
        openAlert({ message: "Saved Practice Routine!", severity: "success" });
        setUserRoutine(response.data);
      })
      .catch(() => {
        openAlert({
          message: "There was a problem saving this practice routine.",
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div>
      <div className="practice-item__options">
        {optionsOpen ? (
          <div>
            <button
              disabled={loading}
              className="button button--blue"
              onClick={() => addRoutine(true)}
            >
              Set as your active routine
            </button>
            <button
              disabled={loading}
              className="button button--dark"
              onClick={() => addRoutine(false)}
            >
              Add to your saved routines
            </button>
            <button onClick={() => setOptionsOpen(false)} className="button button--danger">
              Cancel
            </button>
          </div>
        ) : (
          <button
            disabled={loading}
            onClick={() => setOptionsOpen(true)}
            className="button button--primary"
          >
            Save this routine
          </button>
        )}
      </div>
    </div>
  );
};

const PracticeRoutineToSaveNew = ({ loading, setLoading, routine, openAlert }) => {
  const [userRoutine, setUserRoutine] = useState(null);

  useEffect(() => {
    if (loading != true) setLoading(true);

    axiosWithCSRF()
      .get(`/user_practice_routines/by_practice_routine?practice_routine_id=${routine.id}`)
      .then((response) => {
        setUserRoutine(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const { practiceItems, title } = routine.attributes;

  return (
    <div
      className={"practice-routine " + (userRoutine ? "practice-routine--saved" : "")}
      style={{ position: "relative" }}
    >
      <h4 className="header4 uppercase bold">{title}</h4>
      {practiceItems.map((item) => {
        return <PracticeRoutineItem key={item.id} item={item} />;
      })}

      {userRoutine ? (
        <AlreadySaved />
      ) : (
        <SaveRoutine
          routineId={routine.id}
          setUserRoutine={setUserRoutine}
          setLoading={setLoading}
          loading={loading}
          openAlert={openAlert}
        />
      )}
    </div>
  );
};

export default connect(null, { openAlert })(PracticeRoutineToSaveNew);

// export default PracticeRoutineToSaveNew;
