export const mergeEntities = (storeEntities, newEntities) => {
  return Object.entries(storeEntities).reduce(
    (accumulatedEntities, [currType, currTypeStoreEntities = {}]) => {
      const newEntitiesForType = newEntities[currType] || {};

      return {
        ...accumulatedEntities,
        [currType]: {
          ...currTypeStoreEntities,
          ...newEntitiesForType,
        },
      };
    },
    {}
  );
};

export const mergeResult = (storeResult, newResult) => {
  return Object.entries(storeResult).reduce(
    (accumulatedResult, [currType, currTypeStoreResult = {}]) => {
      const defaultFields = { metadata: null, included: [], data: [] };
      currTypeStoreResult = {
        ...defaultFields,
        ...currTypeStoreResult,
      };
      const newResultForType = {
        ...defaultFields,
        ...(newResult[currType] || {}),
      };

      return {
        ...accumulatedResult,
        [currType]: {
          metadata: newResultForType.metadata || currTypeStoreResult.metadata,
          included: [...currTypeStoreResult.included, ...newResultForType.included],
          data: [...currTypeStoreResult.data, ...newResultForType.data],
        },
      };
    },
    {}
  );
};
