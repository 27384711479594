import React from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import SalesPage from "./SalesPage";

const TabsShow = ({
  faqs,
  hasActiveTabsSubscription,
  pricingPlans,
  sheetCount,
  songs,
  tabsCount,
  trialAvailable,
  user,
}) => {
  return (
    <div className="products-index">
      <Header
        hasActiveTabsSubscription={!!hasActiveTabsSubscription}
        trialAvailable={!!trialAvailable}
      />

      <SalesPage
        pricingPlans={pricingPlans}
        sheetCount={sheetCount}
        songs={songs}
        hasActiveTabsSubscription={!!hasActiveTabsSubscription}
        subscriptionReferences={[]}
        tabsCount={tabsCount}
        tabsFaqs={faqs}
        trialAvailable={!!trialAvailable}
        user={user}
      />
    </div>
  );
};

TabsShow.propTypes = {
  faqs: PropTypes.array.isRequired,
  hasActiveTabsSubscription: PropTypes.bool.isRequired,
  pricingPlans: PropTypes.arrayOf(PropTypes.object).isRequired,
  sheetCount: PropTypes.number.isRequired,
  songs: PropTypes.arrayOf(PropTypes.object).isRequired,
  tabsCount: PropTypes.number.isRequired,
  trialAvailable: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default TabsShow;
