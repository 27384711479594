import React from "react";
import { connect } from "react-redux";

import { slices, selectFilters, fetchSongs } from "./store";

export function ChordsAndTabsFilterButtons({ tabsSelected, chordsSelected, toggleFilter }) {
  return (
    <div className="songs-features-filters">
      <div className="songs-features-filters__label">Show Songs With:</div>
      <div className="songs-features-filters__buttons flex">
        <button
          className={`button button--${tabsSelected ? "primary" : "beige"}`}
          onClick={() => toggleFilter("JustinGuitarTABS")}
        >
          Tabs
        </button>

        <button
          className={`button button--${chordsSelected ? "primary" : "beige"}`}
          onClick={() => toggleFilter("JustinGuitarCHORDS")}
        >
          Chords
        </button>
      </div>
    </div>
  );
}

export default connect(
  (state) => {
    const filters = selectFilters(state);
    return {
      tabsSelected: filters.JustinGuitarTABS != null,
      chordsSelected: filters.JustinGuitarCHORDS != null,
    };
  },
  (dispatch) => ({
    toggleFilter: (filter) => {
      dispatch(slices.ui.filters.actions.toggleFilter(filter));
      dispatch(fetchSongs());
    },
  })
)(ChordsAndTabsFilterButtons);
