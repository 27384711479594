import React from "react";
import axios from "axios";
import ReactDOM from "react-dom";

export default class DeleteUserItem extends React.Component {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      itemId: this.props.itemId,
      hasErrors: null,
      itemType: this.props.itemType,
      lessonId: this.props.lessonId,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    if (confirm("Are you sure you want to delete this item?")) {
      let link = "";
      if (this.props.groupId) {
        link = `/user_items/lesson/${this.props.lessonId}?item=${this.props.itemId}`;
      } else {
        link = `/user_items/${this.props.itemId}`;
      }
      axios
        .delete(link, {
          headers: {
            "X-CSRF-Token": ReactOnRails.authenticityToken(),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.props.deletedItem(`${this.state.lessonId}`, `${this.props.itemId}`);
        })
        .catch((error) => {
          this.setState({ hasErrors: error.response.data.message });
          setTimeout(() => this.setState({ hasErrors: null }), 2000);
        });
    }
  };

  render() {
    var errorStyle = { color: "red" };
    return (
      <form onSubmit={this.handleSubmit} className="user-item-removal__form">
        {this.state.hasErrors && <p style={errorStyle}>{this.state.hasErrors}</p>}

        {!this.state.hasErrors && (
          <button type="submit" className="user-item-removal__button">
            <span className="removable-icon icon icon-bin"></span>
          </button>
        )}
      </form>
    );
  }
}
