import React from "react";

type ScheduleFormatterProps = {
  text: string;
};

export const ScheduleFormatter: React.FC<ScheduleFormatterProps> = ({ text }) => {
  const splitText = text.split("\n");
  let result = "";
  let isList = false;

  splitText.forEach((line, index) => {
    if (line.startsWith("-")) {
      line = line.replace("- ", "");
      if (!isList) {
        result += "<ul style='margin-left: 40px;'>";
        isList = true;
      }
      const timeMatch = line.match(/\b\d+:\d+\b/);
      if (timeMatch) {
        const restOfLine = line.replace(timeMatch[0], "").trim();
        const boldTime = `<b style="font-weight: 600; margin-right: 20px;">${timeMatch[0]}</b>`;
        result += `<li>${boldTime}<span style="font-weight: 700;">${restOfLine}</span></li>`;
      } else {
        result += `<li>${line}</li>`;
      }
    } else {
      if (isList) {
        result += "</ul>";
        isList = false;
      }
      const timeMatch = line.match(/\b\d+:\d+\b/);
      if (timeMatch) {
        const restOfLine = line.replace(timeMatch[0], "").trim();
        const boldTime = `<b style="font-weight: 600; margin-right: 20px;">${timeMatch[0]}</b>`;
        result += `<p>${boldTime}<span style="font-weight: 700;">${restOfLine}</span></p>`;
      } else {
        result += `<p style="font-weight: 700;">${line}</p>`;
      }
    }

    if (index === splitText.length - 1 && isList) {
      result += "</ul>";
    }
  });

  return <div dangerouslySetInnerHTML={{ __html: result }} style={{ fontSize: "16px" }} />;
};
