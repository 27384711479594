import React from "react";
import Slider from "react-slick";
import {
  selectCompletedSongRequests,
  selectSelectedSongRequests,
  selectSongsDictionary,
} from "../store";
import { connect } from "react-redux";
import SongsGridItem from "../SongsGridItem";
import * as bootstrapHelpers from "../../shared/bootstrap-helpers";

const requestTypes = {
  SELECTED: "Coming Soon",
  LESSON: "Live",
};

const slickSettings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.xlDevice],
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.lgDevice],
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.mdDevice],
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.smDevice],
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export class SongRequestsCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requestType: requestTypes.LESSON,
      [`${requestTypes.SELECTED}Idx`]: 0,
      [`${requestTypes.LESSON}Idx`]: 0,
    };
  }

  handleCheckedChange = (event) => {
    const requestType = event.target.checked ? requestTypes.SELECTED : requestTypes.LESSON;

    this.setState({ requestType });
  };

  get requests() {
    const requests =
      this.state.requestType === requestTypes.SELECTED
        ? this.props.selectedRequests
        : this.props.completedRequests;

    return Array.isArray(requests) ? requests : [];
  }

  get currentIdx() {
    return this.state[`${this.state.requestType}Idx`];
  }

  set currentIdx(idx) {
    this.setState({ [`${this.state.requestType}Idx`]: idx });
  }

  render() {
    const header =
      this.state.requestType === requestTypes.SELECTED
        ? "These top requests will be lessons soon"
        : "These top requests became lessons thanks to you";

    const requests = this.requests;

    return (
      <div className="song-requests-carousel">
        <h4 className="header4 uppercase">{header}</h4>

        <div className="toggle">
          <input
            type="checkbox"
            id="song-request-carousel__toggle"
            checked={this.state.requestType === requestTypes.SELECTED}
            onChange={this.handleCheckedChange}
          />
          <label htmlFor="song-request-carousel__toggle">
            <div
              className="switch"
              data-checked={requestTypes.SELECTED}
              data-unchecked={requestTypes.LESSON}
            />
          </label>
        </div>

        {this.requests.length > 0 ? (
          <Slider
            {...slickSettings}
            infinite={requests.length > 4 /*https://github.com/kenwheeler/slick/issues/940*/}
            initialSlide={this.currentIdx}
            afterChange={(idx) => (this.currentIdx = idx)}
          >
            {requests.map((request) => (
              <RequestCarouselItem key={request.id} request={request} songs={this.props.songs} />
            ))}
          </Slider>
        ) : (
          <p className="text-center song-request-carousel__placeholder">None right now!</p>
        )}
      </div>
    );
  }
}

const RequestCarouselItem = ({ request, songs }) => {
  let songId;
  try {
    // don't want to bother checking for the existence of each of these properties, throwing it in a `try` instead
    songId = request.relationships.song.data.id;
  } catch {
    /* no-op */
  }

  const song = songs[songId];
  return (
    <div className="song-request-carousel-item">
      <SongsGridItem
        key={request.id}
        song={song == null ? request.attributes : song.attributes}
        useOverlay={false}
      />

      <p>
        <span>Submitted by: </span>
        <span className="username">{request.attributes.submittedBy || "JustinGuitar Team"}</span>
      </p>
    </div>
  );
};

export default connect((state) => ({
  songs: selectSongsDictionary(state),
  selectedRequests: selectSelectedSongRequests(state),
  completedRequests: selectCompletedSongRequests(state),
}))(SongRequestsCarousel);
