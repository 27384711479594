import React from "react";
import { connect } from "react-redux";

import { fetchSongs, selectCurrentArtist, selectQuery, slices, sortByOptions } from "./store";

export const SongsSearch = ({ query, setQuery, shouldShow }) => {
  const inputRef = React.createRef();

  return (
    shouldShow && (
      <div className="song-search" onClick={() => inputRef.current.focus()}>
        <div className="song-search__inner">
          <i className="icon icon-search-thin" />
          <input
            ref={inputRef}
            type="text"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
          />
        </div>
      </div>
    )
  );
};

export default connect(
  (state) => ({
    query: selectQuery(state),
    shouldShow: selectCurrentArtist(state) == null,
  }),
  (dispatch) => ({
    setQuery(query) {
      dispatch(slices.ui.actions.setQuery(query));
      if (query.length === 1) {
        // if new query sort by relevance (pg_search ranking)
        // only set it on new query so that we don't erase post-query user sort selection
        dispatch(slices.ui.actions.setSortBy(sortByOptions.relevance));
      }
      if (query.length === 0) {
        // search cleared, go back to default
        dispatch(slices.ui.actions.setSortBy(sortByOptions.popularity));
      }
      dispatch(fetchSongs());
    },
  })
)(SongsSearch);
