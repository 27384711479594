import React from "react";
import { trialEnd, formatDate } from "./purchaseUtils";

const SubscriptionRenewal = ({
  isActive,
  isCancelled,
  subscriptionPeriodEnd,
  inTrialPeriod,
  createdAt,
  price = null,
  currency = null,
}) => {
  if (!isActive || isCancelled) {
    return (
      <span className="purchase__due">
        Your subscription has been cancelled. You will lose access after{" "}
        {formatDate(subscriptionPeriodEnd)}.
      </span>
    );
  } else if (inTrialPeriod) {
    return <span className="purchase__due">Your trial will end on {trialEnd(createdAt)} </span>;
  } else if (subscriptionPeriodEnd) {
    return (
      <span className="purchase__due">
        Next payment{price && currency ? ` of ${price} ${currency} ` : " "}due on{" "}
        {formatDate(subscriptionPeriodEnd)}
      </span>
    );
  } else {
    return <span className="purchase__due">This was a gift. Your access is ongoing.</span>;
  }
};

export default SubscriptionRenewal;
