import React from "react";
import { connect } from "react-redux";

import CurrentUserBadge from "./CurrentUserBadge";

export const AuthButtons = ({
  currentUser,
  showUserIcon = true,
  userIconSize = "small",
  showName = true,
  showJoin = true,
  showLogIn = true,
  showLogOut = !showUserIcon,
  orientation = "horizontal",
  primary = true,
}) => (
  <div
    className="header__auth-container"
    style={{
      display: "flex",
      flexDirection: orientation === "vertical" ? "column" : "row",
    }}
  >
    {currentUser == null ? (
      <>
        {showJoin && (
          <a
            className={`button ${primary ? "button--primary" : "button--no-background"}`}
            href="/users/sign_up"
          >
            Join
          </a>
        )}

        {showLogIn && (
          <a className="button button--no-background button--login" href="/users/sign_in">
            Log in
          </a>
        )}
      </>
    ) : (
      <div>
        {showUserIcon && (
          <CurrentUserBadge
            imgPath={currentUser.data.attributes.imageUid}
            size={userIconSize}
            showName={showName}
          />
        )}
        {showLogOut && (
          <a className="button button--no-background" href="/users/sign_out" data-method="delete">
            Log Out
          </a>
        )}
      </div>
    )}
  </div>
);

export default connect(({ currentUser }) => ({
  currentUser,
}))(AuthButtons);
