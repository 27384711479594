import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

export default class CreateNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noteContent: "",
    };
  }

  submitNote = () => {
    axios
      .post("/user_notes", {
        noteable_id: this.props.item.id,
        user_id: this.props.userId,
        noteable_type: "UserPracticeItem",
        content: this.state.noteContent,
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((response) => {
        this.props.updateNoteInPracticeItem(response.data.user_note, this.props.item.id);
      })
      .catch((error) => {
        this.props.updateNoteInPracticeItem(null);
      });
  };

  handleNoteChange = (event) => {
    this.setState({ noteContent: event.target.value });
  };

  render() {
    return (
      <div className="routine-note__form">
        <div className="routine-note__input-container">
          <i className="icon icon-pencil"></i>
          <input
            value={this.state.noteContent}
            onChange={this.handleNoteChange}
            placeholder="Start typing your note here"
          />
        </div>

        <div className="practice-buttons__container practice-buttons__container--note">
          <button
            onClick={this.submitNote}
            disabled={!this.state.noteContent || this.state.noteContent.length < 1}
            className="practice__button practice__button--save"
          >
            Submit
          </button>
          <button
            type="button"
            className="practice__button practice__button--cancel"
            onClick={this.props.closeForm}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

CreateNote.propTypes = {
  userId: PropTypes.number.isRequired,
};
