import React from "react";

import SongRequestListingItem from "./SongRequestListingItem";
import { withInnerWidth } from "../../shared";
import { BootstrapSize, breakpoints } from "../../shared/bootstrap-helpers";

export default withInnerWidth(({ songRequests, width }) => {
  return (
    <div className="song-request-listing">
      {width > breakpoints[BootstrapSize.smDevice] && (
        <div className="song-request-listing-headers">
          <div className="ranking">#</div>
          <div className="title">Title</div>
          <div className="artist">Artist</div>
          <div className="vote-count">Votes</div>
          <div className="vote"> </div>
        </div>
      )}

      {songRequests.map((songRequest, i) => (
        <SongRequestListingItem key={songRequest.id} songRequest={songRequest} ranking={i + 1} />
      ))}
    </div>
  );
});
