import { CDN_URL } from "../../components/shared/CdnUrl";

export const TEACHERS = [
    {
      name: "Jed Wardley",
      slug: "jed-wardley",
      location: "LONDON, UK",
      contacts: ["+44 (0) 7879 224418", "jedwardley@gmail.com", "jediwardley.com"],
      largeImage: `${CDN_URL}/images/pages/teachers/Jed2.jpeg`,
      smallImage: `${CDN_URL}/images/pages/teachers/Jed-Square4.jpeg`,
      activeTab: "about",
      content: {
        about:
          "I’ve been friends with Jed for over 20 years, and we worked together for over half that time. He’s a great player, knows my lessons really well, and would be a great fit for any beginner or intermediate player!<br><br>Jed also attended The Guitar Institute in London and achieved a diploma with merit in 2001. He's taught thousands of guitarists, and it's especially great if you dig Rock, Classic Rock, Blues, Pop, and want to feel ready to perform!",
        qualifications:
          '<span class="badge">diploma</span>Diploma in Music Performance - Guitar<br>Guitar Institute London - 2001<span class="badge">experience</span>Jed has been playing guitar for over 35 years and has been teaching guitar for nearly as long. He loves teaching and takes great pride in working closely with students to achieve their goals. He enjoys one-on-one teaching interactions and makes them as enjoyable as possible.',
        specialty:
          '<span class="badge">levels</span>Jed teaches Beginner to Intermediate levels, teenagers to adults.<span class="badge">genres</span>Jed specializes in teaching Blues, Classic Rock, and acoustic guitar. His influences include Eric Clapton, David Gilmour, Stevie Ray Vaughan, and players of similar styles.',
        lessonDetails:
          '<span class="badge">availability</span><strong>Mon - Friday</strong>: 10:00 to 14:00 and 18:00 to 22:00 (GMT)<br><i>Please contact Jed directly for availability between 14:00 and 18:00 (GMT) and Saturdays.</i><br>Lessons are <strong>online</strong> only and given in <strong>English</strong>.<span class="badge">price</span><strong>£40</strong> for 55-minute lesson<br>(<strong>10% discount</strong> for bookings of 5 or more)',
      },
    },
    {
      name: "Jon Gingell",
      slug: "jon-gingell",
      location: "LONDON, UK",
      contacts: ["+44 (0) 7859 023994", "jongingell@mac.com"],
      largeImage: `${CDN_URL}/images/pages/teachers/Jon.jpg`,
      smallImage: `${CDN_URL}/images/pages/teachers/Jon-Square.png`,
      activeTab: "about",
      content: {
        about:
          "Jon is a professional guitarist and teaches at universities. He's been doing tabs on the site for a few years and has great attention to detail. Jon loves to dig into the nuances and stylistic details in guitar solos of great players like Jimmy Page, Hendrix, Peter Green, and many more.<br><br>Jon's a great suit to intermediate and above guitarists, especially if into Jazz, Modern Blues, and Rock! Do you need help with string bending and vibrato? Talk to Jon!",
        qualifications:
          '<span class="badge">diploma</span>BA (Hons), University of Southampton<span class="badge">experience</span>Jon teaches absolute beginners up to conservatoire Jazz players! He welcomes guitar students who want a ‘check up’ for fresh ideas or more regular lessons to set goals and maintain good progress.<br><br>As a theater guitarist, he has to play appropriately in many different styles. He loves to explore ways to capture a good sound for songs!',
        specialty:
          '<span class="badge">levels</span>Jon teaches Beginner, Intermediate and Advanced levels.<span class="badge">genres</span>Jon specializes in teaching Jazz, Modern Blues, and Rock, but teaches all different styles! With his help, you can develop your strumming technique, nail that guitar solo and improve your overall timing! His interest in rhythm can help you get your strumming to sit nicely on the guitar.',
        lessonDetails:
          '<span class="badge">availability</span><strong>Thurday</strong>: 10:00 - 19:00 (GMT)<br><strong>Friday</strong>: 10:00 - 17:00 (GMT)<br>Lessons are <strong>online</strong> only and given in <strong>English</strong>.<span class="badge">price</span><strong>£35</strong> for a 55-minute lesson<br>(<strong>10% discount</strong> for bookings of 5 or more)',
      },
    },
    {
      name: "Lieven De Vleeschouwer",
      slug: "lieven-de-vleeschouwer",
      location: "Putte, Belgium",
      contacts: ["lieven@justinguitar.com"],
      largeImage: `${CDN_URL}/images/pages/teachers/Lieven.jpg`,
      smallImage: `${CDN_URL}/images/pages/teachers/Lieven-Square.png`,
      activeTab: "about",
      content: {
        about:
          "Lieven has been a moderator on the forum for as long as it’s existed - over 15 years! If you want help with motivation, direction, and big picture stuff, then Lieven is your man! He’s a great player, songwriter, and singer with tons of experience.<br><br>Lieven knows that many beginners quit playing because they lose motivation, get stuck, or fail to grasp basic concepts. Others have dreams of playing live in a band or solo but don't know how to get there. And some just feel intimidated by all of it! So, if you want help with consistency and a plan to reach your guitar goals, book a session with him!",
        qualifications:
          '<span class="badge">diploma</span>Official Justin Guitar Guide | BA Information Management<span class="badge">experience</span>Lieven has been a member of the JustinGuitar community since 2006 and Community administrator for almost as long. Helping many beginners overcome their struggles ever since - you might have seen him answering comments in my content over the years! ;)<br><br>As a JustinGuitar Official Guide, he’s involved in various tasks and projects in-house. He also sings and plays guitar in both a solo project and a modern Heavy Metal band.',
        specialty:
          '<span class="badge">levels</span>Lieven teaches beginner guitar players and singing basics.<span class="badge">specialty</span>Lieven specializes in teaching beginners, targeting your guitar goals, and how to navigate JustinGuitar lessons. His guidance and feedback will improve your playing, get you out of that guitar rut, and help you succeed in your learning path! He also has tips to gain confidence performing live and solving issues with your band.',
        lessonDetails:
          '<span class="badge">availability</span>Contact Lieven to match calendars and time zones!<br>Lessons are <strong>online</strong> only and given in <strong>English</strong> (fluent) or in <strong>Dutch</strong> (mother tongue).<span class="badge">price</span><strong>£35 / €40</strong> for a 55-minute lesson<br>(<strong>10% discount</strong> for bookings of 5 or more)',
      },
    },
    {
      name: "Richard Coles",
      slug: "richard-coles",
      location: "Stockton-on-Tees, UK",
      contacts: ["+44 (0) 7508 808979", "richard@justinguitar.com"],
      largeImage: `${CDN_URL}/images/pages/teachers/Richard.jpg`,
      smallImage: `${CDN_URL}/images/pages/teachers/Richard-Square.png`,
      activeTab: "about",
      content: {
        about:
          "Richard is a proper teacher - he teaches math and music! He is excellent at breaking down seemingly complex topics. He’s been on my team for many years and knows JustinGuitar inside and out. Great for beginners of all ages!<br><br>Can you play a few songs but want to get beyond simple 3-chord basics? Are you a bedroom strummer wishing to play at an open-mic? Do you want to learn guitar with an emphasis on playing? Richard can help you with this and more!",
        qualifications:
          '<span class="badge">diploma</span>Post-Graduate Certificate in Education<br>Diploma Certificates in Teaching<br>RSL Level 2 Certificate in Popular Music Performance (Guitar)<span class="badge">experience</span>Richard has 30 years as a professional school, college, and one-on-one teacher! He has played guitar for over 40 years - sometimes sporadically due to work commitments. Still, his focus is on teaching and playing the guitar as a hobby!',
        specialty:
          '<span class="badge">levels</span>Richard’s focus is on beginners and guitarists with basic skills who want to improve their playing.<span class="badge">genres</span>Richard builds his lessons around you and the music you enjoy! Together, you’ll understand and learn the skills you need, so you nail the songs you want! He uses simple tabs and chord sheets to help. Though not essential if you just want to play for fun, a bit of music theory can go alongside your practical learning. If you want to explore theory further, Richard’s got that covered too!',
        lessonDetails:
          '<span class="badge">availability</span><strong>Weekday</strong>: 10:00 – 19:00 (GMT)<br>Lessons are <strong>online</strong> only and given in <strong>English</strong>.<span class="badge">price</span><strong>£35</strong> for a 50-minute lesson<br>(<strong>10% discount</strong> for bookings of 5 or more)',
      },
    },
    {
      name: "Lee Mead-Batten",
      slug: "lee-mead-batten",
      location: "Cardiff, UK",
      contacts: ["+44 (0) 7977 201510", "leeandguitar.com", "lee@leeandguitar.com"],
      largeImage: `${CDN_URL}/images/pages/teachers/Lee.jpeg`,
      smallImage: `${CDN_URL}/images/pages/teachers/Lee-Square.png`,
      activeTab: "about",
      content: {
        about:
          "Lee is a guitarist based in Cardiff, South Wales, UK. His guitar journey started back in 1997, and he's been teaching guitar since 2004. Lee and I go way back! In 2006, Lee furthered his guitar playing and teaching skills through one-on-one lessons with me, and now he's ready to share those skills with you!<br><br>Lee has close to 20 years of teaching experience, and teaches a wide range of different music styles to all levels.",
        qualifications:
          '<span class="badge">diploma</span>BSc Honors in Music Technology<span class="badge">experience</span>With almost 20 years of teaching experience, Lee teaches people of all ages and abilities in a wide variety of settings - including in person and online lessons.',
        specialty:
          '<span class="badge">levels</span>Lee teaches up to Grade 8 standard!<span class="badge">genres</span>Lee teaches many styles that tailor lessons to your individual needs and musical interests. Lee bases his teaching approach on joy and simplifying the learning process. As an ex-student of JustinGuitar, he also believes in teaching the “whys” and the “whats” of learning, helping you become more self-sufficient on your guitar journey.',
        lessonDetails:
          '<span class="badge">availability</span><strong>Mondays</strong> - 10:00 - 15:00 (GMT)<br><strong>Wednesdays</strong> - 10:00 - 15:00 (GMT)<br><strong>Thursdays</strong> - 10:00 - 15:00 (GMT)<br><strong>Fridays</strong> - 10:00 - 15:00 (GMT)<br>Lessons are <strong>online or in-person</strong> only and given in <strong>English</strong>.<span class="badge">price</span><strong>£40</strong> per 55-minute lesson and <strong>£22</strong> per 30-minute lesson.<br>(<strong>10% discount</strong> for bookings of 5 or more)',
      },
    },
  ];
