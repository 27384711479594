import React from "react";
import axiosWithCSRF from "../../../shared/axiosWithCSRF";

export default class CreateItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      duration: "",
      description: "",
      metronome: "",
      statistic: "",
      link: "",
      pending: false,
    };
  }

  handleChange = (type, e) => {
    if (e.target.type === "checkbox") {
      this.setState({ [type]: e.target.checked });
    } else {
      this.setState({ [type]: e.target.value });
    }
  };

  submitForm = () => {
    this.setState({ pending: true });
    setTimeout(() => {
      let hasStat = this.state.statistic ? true : false;
      axiosWithCSRF()
        .post("/user_practice_items", {
          user_practice_item: {
            title: this.state.title,
            duration: this.state.duration,
            description: this.state.description,
            metronome: this.state.metronome,
            statistic_name: this.state.statistic,
            statistic: hasStat,
            related_link: this.state.link,
          },
        })
        .then((response) => {
          this.setState({ pending: false });
          this.props.handleNewItem(response.data.item);
        })
        .catch((err) => {
          let error = "There was a problem saving this item.";
          if (err.response.data && err.response.data.message) {
            error = err.response.data.message;
          }
          this.setState({ pending: false, errorText: error });
          setTimeout(() => this.setState({ errorText: null }), 5000);
        });
    }, 1000);
  };

  disableForm = () => {
    let { title, duration, description } = this.state;
    if (title.length > 0 && duration.length > 0 && this.state.pending === false) {
      return false;
    } else {
      return "disabled";
    }
  };

  errorMessage = () => {
    if (this.state.errorText) {
      return <p className="has-errors">{this.state.errorText}</p>;
    }
  };

  render() {
    return (
      <div
        className={
          "practice-items__form " + (this.state.pending ? "npractice-items__form--pending" : "")
        }
      >
        <span className="practice__heading">New Item</span>
        {this.errorMessage()}

        <div className="practice-form__container">
          <div className="practice-form__item">
            <div className="practice-form__inner">
              <span className="icon icon-pencil-outline"></span>
              <input
                value={this.state.title}
                onChange={(e) => this.handleChange("title", e)}
              ></input>
            </div>
            <span className="routine__form-field__label">Title*</span>
          </div>

          <div className="practice-form__item">
            <div className="practice-form__container">
              <div className="practice-form__inner">
                <span className="icon icon-pencil-outline"></span>
                <input
                  value={this.state.description}
                  onChange={(e) => this.handleChange("description", e)}
                ></input>
              </div>
              <span className="routine__form-field__label">Description</span>
            </div>
          </div>

          <div className="practice-form__item">
            <div className="practice-form__container">
              <div className="practice-form__inner">
                <span className="icon icon-pencil-outline"></span>
                <input
                  type="number"
                  value={this.state.duration}
                  onChange={(e) => this.handleChange("duration", e)}
                ></input>
              </div>
              <span className="routine__form-field__label">Duration* (Number only)</span>
            </div>
          </div>

          <div className="practice-form__item">
            <div className="practice-form__container">
              <div className="practice-form__inner">
                <span className="icon icon-pencil-outline"></span>
                <input
                  value={this.state.link}
                  onChange={(e) => this.handleChange("link", e)}
                ></input>
              </div>
              <span className="routine__form-field__label">Related Link</span>
            </div>
          </div>

          <div className="practice-form__item">
            <div className="practice-form__container">
              <div className="practice-form__inner">
                <span className="icon icon-pencil-outline"></span>
                <input
                  value={this.state.statistic}
                  onChange={(e) => this.handleChange("statistic", e)}
                ></input>
              </div>
              <span className="routine__form-field__label">Statistic Name</span>
            </div>
          </div>
        </div>

        <div className="practice-buttons__container practice-buttons__container--margin-vertical">
          <button
            disabled={this.disableForm()}
            className="practice__button practice__button--save"
            onClick={this.submitForm}
          >
            save
          </button>
          <button
            className="practice__button practice__button--cancel"
            onClick={this.props.closeForm}
          >
            cancel
          </button>
        </div>
      </div>
    );
  }
}
