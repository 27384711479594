import React from "react";
import Swoosh from "../components/shared/Swoosh";

export default function () {
  return (
    <Swoosh color="#406e8e">
      >
      <div className="donate-uses">
        <h2>how is your donation used?</h2>
        <div className="donate-cards">
          <div className="donate-cards__inner">
            <div className="donate-card">
              <div className="icon icon-people" />
              <div className="card-header">Admin & Tech Support</div>
              <div className="card-copy">
                Money means I can afford assistants to take over admin tasks, which gives me more
                time to make new courses & song lessons!
              </div>
            </div>

            <div className="donate-card">
              <div className="icon icon-phone-gear" />
              <div className="card-header">Better, Faster Website</div>
              <div className="card-copy">
                With your donation, I can afford talented developers who are always improving and
                adding amazing new features to JustinGuitar.
              </div>
            </div>

            <div className="donate-card">
              <div className="icon icon-very-smiley" />
              <div className="card-header">Keeping It Free</div>
              <div className="card-copy">
                I rely on support from people who can afford to donate to keep 99% of the website
                free. Dreams are universal - opportunities are not.
              </div>
            </div>
          </div>
        </div>
      </div>
    </Swoosh>
  );
}
