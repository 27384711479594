import React from "react";

const MusicTheoryBanner = () => {
  const goToPMT = () => {
    window.location.href = "/store/practical-music-theory";
  };

  return (
    <div className="container lessons-music-theory-banner">
      <div className="music-theory-course" style={{ cursor: "pointer" }} onClick={goToPMT}>
        <div className="header">
          <h1>Practical Music Theory</h1>
          <p>
            Bring your guitar! We'll explore your fretboard, demystifying Music Theory with
            practical examples. Learn your fretboard, compose, and improvise effortlessly. No more
            confusion—just simple, enjoyable learning!
          </p>
        </div>
      </div>
    </div>
  );
};

export default MusicTheoryBanner;
