import React from "react";

const boldWeight = 700;

const whiteBelt = "white";
const yellowBelt = "#F4D059";
const orangeBelt = "#FC9644";
const greenBelt = "#48CFAE";
const blueBelt = "#45AAF2";
const purpleBelt = "#A65DEA";
const redBelt = "#FC5C66";
const brownBelt = "#7A3E26";
const blackBelt = "#222F3F";
const noBelt = "#81ABC1";

export const siteMapGrades = {
  grade1: {
    label: "Grade 1",
    backgroundColor: whiteBelt,
    modules: [
      {
        label: "0 - Before You Begin: Guitar Basics",
        link: "/classes/beginner-guitar-course-grade-one",
      },
      {
        label: "1 - A & D Chords: Play Your First Song",
        link: "/modules/lesson-1-all-the-basics-2-chords-and-songs",
      },
      {
        label: "2 - Rhythm & Chord Changes + Your First Riff",
        link: "/modules/module-2-the-e-gunn",
      },
      {
        label: "3 - Capo, Minor Chords, & Up Strums",
        link: "/modules/beginner-guitar-lesson-3",
      },
      {
        label: "4 - Metronome, Stretches, & The Pattern",
        link: "/modules/beginner-guitar-lesson-4",
      },
      {
        label: "5 - Basic Theory & Strumming Development",
        link: "/modules/beginner-guitar-lesson-5",
      },
      {
        label: "6 - 6:8 Time, Fast Changes, & Alternate Picking",
        link: "/modules/beginner-guitar-lesson-6",
      },
      {
        label: "7 - Air Changes, Dynamics & Consolidation",
        link: "/modules/beginner-guitar-lesson-7",
      },
      {
        label: "Nitsuj - Grade 1 Practice Left Handed",
        link: "/modules/nitsuj-grade-1-practice",
      },
    ],
  },
  grade2: {
    label: "Grade 2",
    backgroundColor: yellowBelt,
    modules: [
      {
        label: "8 - Your First Scale, Pick Tricks, & 8th Note Strumming",
        link: "/modules/lesson-8-stuck-3-4-wish",
      },
      {
        label: "9 - The F Chord Journey & Pinky Workout",
        link: "/modules/lesson-9-f-in-this-chili-riff",
      },
      {
        label: "10 - Alternate Picking, Hammer-Ons, and Improvisation",
        link: "/modules/lesson-10-weak-g-bamba",
      },
      {
        label: "11 - Fingerstyle & Songwriting First Steps",
        link: "/modules/lesson-11-sus-fingerstyle",
      },
      {
        label: "12 - Rock Power Chords & Techniques for Beginners",
        link: "/modules/lesson-12-power-chords",
      },
      {
        label: "13 - Blues Guitar & Easy Improvisation",
        link: "/modules/lesson-13-the-blues",
      },
      {
        label: "14 - Beginners: Get a PRO Upgrade",
        link: "/modules/lesson-14-slash-hit-consolidate",
      },
      {
        label: "Nitsuj - Grade 2 Practice Left Handed",
        link: "/modules/nitsuj-grade-2-practice",
      },
    ],
  },
  grade3: {
    label: "Grade 3",
    backgroundColor: orangeBelt,
    modules: [
      {
        label: "15 - Practice Better & Finger Gym Exercise",
        link: "/modules/practice-exploring-gym",
      },
      {
        label: "16 - Playing Melodies & Moving Chords Up",
        link: "/modules/chord-melody-pomodoro",
      },
      {
        label: "17 - Finger Strums, Triads, & The Musician in You",
        link: "/modules/finger-style-c-shapes",
      },
      {
        label: "18 - Blues, Chips, & Open Tuning",
        link: "/modules/chips-blues-open-tuning",
      },
      {
        label: "19 - Master Song Practice & Learn to Sing",
        link: "/modules/master-finger-style-songs-practice",
      },
      {
        label: "20 - Chords in Keys Made Easy",
        link: "/modules/the-songwriting-effect",
      },
      {
        label: "21 - Double Strumming, Vibrato, & Rut Busters",
        link: "/modules/picked-fingerstyle-chord-hammers",
      },
      {
        label: "22 - Beginner Consolidation & Easy Barre Chords",
        link: "/modules/beginner-consolidation",
      },
    ],
  },
  grade4: {
    label: "Grade 4",
    backgroundColor: greenBelt,
    modules: [
      {
        label: "Grade 4 pathway options",
        link: "/modules/grade-4-pathway-options",
      },
      {
        label: "E shape barre chords",
        link: "/modules/e-shape-barre-chords",
      },
      {
        label: "Major scale maestro",
        link: "/modules/major-scale-maestro",
      },
      {
        label: "Rhythm maestro",
        link: "/modules/rhythm-maestro",
      },
      {
        label: "Intermediate foundation 1",
        link: "/modules/INT1/",
      },
      {
        label: "Intermediate foundation 2",
        link: "/modules/INT2/",
      },
      {
        label: "Blues guitar studies",
        link: "/modules/blues-guitar-studies",
      },
    ],
  },
  grade5: {
    label: "Grade 5",
    backgroundColor: blueBelt,
    modules: [
      {
        label: "Intermediate foundation 3",
        link: "/modules/INT3/",
      },
      {
        label: "Intermediate foundation 4",
        link: "/modules/INT4/",
      },
      {
        label: "Intermediate foundation 5",
        link: "/modules/INT5/",
      },
      {
        label: "Essential blues lead guitar",
        link: "/modules/BLG1/",
      },
      {
        label: "Folk fingerstyle",
        link: "/modules/FOLK/",
      },
      {
        label: "Transcribing blues solos",
        link: "/modules/TRA1/",
      },
    ],
  },
  grade6: {
    label: "Grade 6",
    backgroundColor: purpleBelt,
    modules: [
      {
        label: "Blues solos all over",
        link: "/modules/blues-solos-all-over",
      },
      {
        label: "Colouring the blues",
        link: "/modules/colouring-the-blues",
      },
      {
        label: "Essential blues rhythm",
        link: "/modules/essential-blues-rhythm",
      },
      {
        label: "Blues rhythm fun",
        link: "/modules/blues-rhythm-fun",
      },
      {
        label: "Funk course",
        link: "/modules/FUNK/",
      },
      {
        label: "Jazz introduction & basics",
        link: "/modules/jazz-introduction-basics",
      },
      {
        label: "Jazz standards",
        link: "/modules/jazz-standards/",
      },
      {
        label: "Jazz chord extensions & alterations",
        link: "/modules/jazz-chord-extensions-alterations",
      },
    ],
  },
  grade7: {
    label: "Grade 7",
    backgroundColor: redBelt,
    modules: [
      {
        label: "Rhythm fills: Mayfield & Hendrix style",
        link: "/modules/rhythm-fills-mayfield-hendrix-style",
      },
      {
        label: "Making the blues changes",
        link: "/modules/making-the-blues-changes",
      },
      {
        label: "Jazz up your blues",
        link: "/modules/jazz-up-your-blues",
      },
      {
        label: "Jazz chord tricks",
        link: "/modules/jazz-chord-tricks",
      },
      {
        label: "Jazz harmony & concepts",
        link: "/modules/jazz-harmony-concepts",
      },
      {
        label: "Jazz walking bass",
        link: "/modules/jazz-walking-bass",
      },
      {
        label: "Jazz lead 1",
        link: "/modules/jazz-lead-1",
      },
    ],
  },
  grade8: {
    label: "Grade 8",
    backgroundColor: brownBelt,
    modules: [],
  },
  grade9: {
    label: "Grade 9",
    backgroundColor: blackBelt,
    modules: [],
  },
  essentials: {
    label: "Essentials",
    backgroundColor: noBelt,
    modules: [
      { label: "Tune your guitar", link: "/modules/tune-your-guitar" },
      { label: "Altered tunings", link: "/modules/altered-tunings" },
      { label: "Guitar maintenance 1", link: "/modules/guitar-maintenance-1" },
      { label: "Print blank tab & manuscript", link: "/modules/print-blank-tab-manuscript" },
    ],
  },
  chords: {
    label: "Chords",
    backgroundColor: noBelt,
    modules: [
      { label: "Chords for beginners", link: "/modules/chords-for-beginners" },
      { label: "Caged system", link: "/modules/caged-system" },
      { label: "Triad chords", link: "/modules/triad-chords" },
      {
        label: "Chords: Hendrix style (Thumb over)",
        link: "/modules/chords-hendrix-style-thumb-over",
      },
      { label: "Chord adventures", link: "/modules/chord-adventures" },
    ],
  },
  earTraining: {
    label: "Ear Training",
    backgroundColor: noBelt,
    modules: [
      { label: "Ear training - Introduction", link: "/modules/ear-training-introduction" },
      { label: "Ear training - Reference", link: "/modules/ear-training-reference" },
      { label: "Ear training - Grade 1", link: "/modules/ear-training-grade-1" },
      { label: "Ear training - Grade 2", link: "/modules/ear-training-grade-2" },
      { label: "Ear training - Grade 3", link: "/modules/ear-training-grade-3" },
    ],
  },
  scalesAndModes: {
    label: "Scales Etc.",
    backgroundColor: noBelt,
    modules: [
      { label: "Scale info for beginners", link: "/modules/scale-info-for-beginners" },
      { label: "Scale info for intermediate", link: "/modules/scale-info-for-intermediates" },
      { label: "Minor pentatonic scale", link: "/modules/minor-pentatonic-scale" },
      { label: "Major pentatonic scale", link: "/modules/major-pentatonic-scale" },
      { label: "Major scale", link: "/modules/major-scale" },
      { label: "Major scale 3NPS", link: "/modules/major-scale-3nps" },
      { label: "Harmonic minor scale", link: "/modules/harmonic-minor-scale" },
      { label: "Melodic minor scale", link: "/modules/melodic-minor-scale" },
      { label: "Melodic minor scale modes", link: "/modules/melodic-minor-scale-modes" },
      { label: "Symmetrical scales", link: "/modules/symmetrical-scales" },
      { label: "Melodic sequences for scales", link: "/modules/melodic-sequences-for-scales" },
      { label: "Arpeggios & beyond", link: "/modules/arpeggios-beyond" },
    ],
  },
  technique: {
    label: "Technique",
    backgroundColor: noBelt,
    modules: [
      { label: "Technique: General", link: "/modules/TQGN/" },
      { label: "Technique: Fretting hand", link: "/modules/TQFH/" },
      { label: "Technique: Picking", link: "/modules/TQPK/" },
      { label: "Technique: Fingerstyle", link: "/modules/TQFI/" },
      { label: "Technique: Time feel", link: "/modules/TQTF/" },
      { label: "Rock techniques", link: "/modules/TROK/" },
      { label: "Harmonics", link: "/modules/THAR/" },
      { label: "Legato primer", link: "/modules/legato-primer" },
      { label: "Singing lessons", link: "/modules/singing-lessons" },
    ],
  },
  guitarsAmpsAndEffects: {
    label: "Guitar Gear",
    backgroundColor: noBelt,
    modules: [
      {
        label: "Beginner guitar buying guides",
        link: "/modules/beginner-guitar-buying-guides",
      },
      { label: "Set up your electric guitar", link: "/modules/GSCC" },
      { label: "All about guitar effects", link: "/modules/GFXA" },
      { label: "Boss katana", link: "/modules/KATA" },
      { label: "Guitar effects reviews", link: "/modules/GFXR/" },
      { label: "Justin’s guitars", link: "/modules/JUSG/" },
      { label: "All about guitar amplifiers", link: "/modules/all-about-guitar-amplifiers" },
      { label: "Guitar accessories", link: "/modules/GACS/" },
    ],
  },
  transcribing: {
    label: "Transcribing",
    backgroundColor: noBelt,
    modules: [
      { label: "Transcribing 1", link: "/modules/TRA1" },
      { label: "Transcribing 2", link: "/modules/TRA2" },
      { label: "Transcribing 3", link: "/modules/TRA3" },
    ],
  },
  production: {
    label: "Production",
    backgroundColor: noBelt,
    modules: [
      { label: "Production basics with Adam G", link: "/modules/production-basics-with-adam-g" },
      { label: "Recording techniques 1", link: "/modules/recording-techniques-1" },
      { label: "Getting into garage band", link: "/modules/getting-into-garage-band" },
      { label: "Songwriting", link: "/modules/songwriting" },
    ],
  },
  theory: {
    label: "Theory",
    backgroundColor: noBelt,
    alert: () => (
      <div className="theory-alert">
        <i className="icon icon-lock"></i> There is a charge for some of these modules
      </div>
    ),
    modules: [
      { label: "Notes, sharps & flats & octaves!", link: "/modules/notes-sharps-flats-octaves" },
      {
        label: "Neck notes, tab, chord & scale diagrams",
        link: "/modules/neck-notes-tab-chord-scale-diagrams",
      },
      {
        label: "Major scale theory & key signatures",
        link: "/modules/major-scale-theory-key-signatures",
      },
      { label: "Triad chord theory & practice", link: "/modules/triad-chord-theory-practice" },
      {
        label: "Notes on the fretboard & cycle of 5TH",
        link: "/modules/notes-on-the-fretboard-cycle-of-5ths",
      },
      { label: "Diatonic & chromatic intervals", link: "/modules/diatonic-chromatic-intervals" },
      {
        label: "QUADAD chord theory (4 notes chords)",
        link: "/modules/quadad-chord-theory-4-note-chords",
      },
      {
        label: "Scale: pentatonics & why so many minors?",
        link: "/modules/scale-pentatonics-why-so-many-minors",
      },
      { label: "Harmonic analysis, what and how!", link: "/modules/harmonic-analysis-what-how" },
      {
        label: "Upper chord extensions (9,11 & 13)",
        link: "/modules/upper-chord-extensions-9-11-13",
      },
      { label: "Caged: Exploring E and A shapes", link: "/modules/caged-exploring-e-a-shapes" },
      { label: "Caged: Exploring D, C & G shapes", link: "/modules/caged-exploring-d-c-g-shapes" },
      { label: "Major scale modes", link: "/modules/major-scale-modes" },
    ],
  },
  // playground: {
  //   label: "Playground",
  //   backgroundColor: noBelt,
  //   modules: [
  //     {
  //       label: "Chord shape explorer",
  //       link: "/modules/CEXP",
  //     },
  //     {
  //       label: "Effective practice",
  //       link: "/modules/PRAC/",
  //     },
  //     {
  //       label: "Master rock power chords",
  //       link: "/modules/BCPC/",
  //     },
  //   ],
  // },
};

export const siteMapLevels = {
  beginner: {
    label: "Beginner",
    Copy: () => (
      <div className="level-copy">
        <p>
          The <span style={{ fontWeight: boldWeight }}>Grade 1 (White)</span> is super easy and for
          people who have never played before. You'll learn the 8 essential beginner chords, easy
          8th note strumming, chord changes and loads of easy songs too.
        </p>
        <p>
          Then you graduate to <span style={{ fontWeight: boldWeight }}>Grade 2 (Yellow)</span>{" "}
          where you’ll learn some more chords, alternative fingerings for chords, strumming and
          more! You'll also get a taste of fingerstyle, Blues and Rock Power Chords.
        </p>
        <p>
          The last 3 topics will be covered in more detail in a module each in{" "}
          <span style={{ fontWeight: boldWeight }}>Grade 3 (Orange)</span>! You will also have the
          Chord Explorer module to explore. After Grade 3, you'll be an Intermediate Guitar Player.
        </p>
      </div>
    ),
    gradient: "linear-gradient(90deg, #FFFFFF 0%, #F4D059 50%, #FC9644 100%)",
    grades: [siteMapGrades.grade1, siteMapGrades.grade2, siteMapGrades.grade3],
  },
  intermediate: {
    label: "Intermediate",
    Copy: () => (
      <div className="level-copy">
        <p>
          In <span style={{ fontWeight: boldWeight }}>Grade 4 (Green)</span>, you'll explore
          Foundations 1 and 2 and you will find there are many songs that use these new skills.
        </p>
        <p>
          In the <span style={{ fontWeight: boldWeight }}>Grade 5 (Blue)</span> we continue on with
          Foundation Modules 3 and 4 which as well as learning to play A Shape Barre chords, we
          explore improvising, scale practice, technique development and more.
        </p>
        <p>
          By the end of <span style={{ fontWeight: boldWeight }}>Grade 6 (Purple)</span> you should
          be confident playing E and A Shape barre chords but also be familiar with The CAGED system
          and manipulation of chords and also Major Scale Improvisation.
        </p>
      </div>
    ),
    gradient: "linear-gradient(90deg, #48CFAE 0%, #45AAF2 50%, #A65DEA 100%)",
    grades: [siteMapGrades.grade4, siteMapGrades.grade5, siteMapGrades.grade6],
  },
  advanced: {
    label: "Advanced",
    Copy: () => (
      <div className="level-copy">
        <p>
          <span className="bold">Grade 7 (Red)</span> we get into how to handle key changes, and
          jazz is the perfect vehicle for that stuff, but it's fun in blues too!
        </p>
        <p>
          <span className="bold">Grade 8 & 9</span> are empty right now - I'm trying to cook up all
          the foundation stuff now - and have some super fun and exciting stuff planned for those
          grades soon!
        </p>
      </div>
    ),
    gradient: "linear-gradient(90deg, #48CFAE 0%, #45AAF2 50%, #A65DEA 100%)",
    grades: [siteMapGrades.grade7, siteMapGrades.grade8, siteMapGrades.grade9],
  },
  allLevels: {
    label: "All Levels",
    Copy: () => (
      <div className="level-copy">
        <p>
          Looking for lessons on specific skills or techniques? Here you'll find helpful modules for
          nearly any stage of your guitar journey. They're perfect if you're looking to dig a little
          deeper on a certain topic!
        </p>
        <p>
          I highly recommend that you explore different courses. You don't need to follow them in
          any particular order, so choose the ones that inspire you the most. They’ll take your
          playing to the next level!
        </p>
      </div>
    ),
    gradient: null,
    grades: [
      siteMapGrades.theory,
      siteMapGrades.chords,
      siteMapGrades.scalesAndModes,
      siteMapGrades.essentials,
      siteMapGrades.earTraining,
      siteMapGrades.technique,
      siteMapGrades.guitarsAmpsAndEffects,
      siteMapGrades.transcribing,
      siteMapGrades.production,
    ],
  },
};

export const orderedLevels = [
  siteMapLevels.beginner,
  siteMapLevels.intermediate,
  siteMapLevels.advanced,
  siteMapLevels.allLevels,
];
