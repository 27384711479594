import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSongRequest,
  selectSongRequestConflicts,
  selectSongRequestFormLoading,
} from "../store";
import SongRequestListingItem from "./SongRequestListingItem";
import { SongListingItem } from "../SongsListingItem";
import Loading from "../../shared/Loading";

export default function ({ clearForm }) {
  const { conflicts, originalRequest } = useSelector(selectSongRequestConflicts);
  const loading = useSelector(selectSongRequestFormLoading);
  const dispatch = useDispatch();
  const resubmit = () =>
    dispatch(
      createSongRequest({
        ...originalRequest,
        force: true,
      })
    );

  const conflictingRequests = conflicts.songRequests.data;
  const conflictingSongs = conflicts.songs.data;

  if (loading) {
    return <Loading />;
  }

  return conflictingRequests.length || conflictingSongs.length ? (
    <SongRequestConflicts
      conflictingRequests={conflictingRequests}
      conflictingSongs={conflictingSongs}
      resubmit={resubmit}
      clearForm={clearForm}
    />
  ) : (
    <RequestSubmittedNotice />
  );
}

const RequestSubmittedNotice = () => (
  <div className={"song-request-submitted"}>
    <i className={"icon icon-check_circle_outline"} />
    <p>Song submitted!</p>
    <p>Each song needs to be manually approved to avoid offensive or inappropriate requests.</p>
    <p>
      There will be a short delay between your request and when the song appears in the request
      board.
    </p>
  </div>
);

const SongRequestConflicts = ({ conflictingSongs, conflictingRequests, resubmit, clearForm }) => {
  return (
    <div className="song-request-conflicts">
      {conflictingRequests.length > 0 && (
        <div className="song-request-conflicts__conflicting-requests">
          <p>The song you're looking for may have already been requested:</p>

          <div className="song-request-conflicts__conflicting-requests-list">
            {conflictingRequests.map((request) => (
              <SongRequestListingItem songRequest={request} key={request.id} />
            ))}
          </div>

          <ConfirmResubmission resubmit={resubmit} />

          <p>
            <a className="clickable" onClick={clearForm}>
              <i className="icon icon-triangle-left" />
              Back to Song Request Form
            </a>
          </p>
        </div>
      )}

      {conflictingSongs.length > 0 && (
        <div className="song-request-conflicts__conflicting-requests">
          <p>The song lesson you're requesting may already exist:</p>
          <div className="song-request-conflicts__conflicting-requests-list">
            {conflictingSongs.map((song) => (
              <SongListingItem song={song.attributes} key={song.id} />
            ))}
          </div>

          <ConfirmResubmission resubmit={resubmit} />

          <p>
            <a className="clickable" onClick={clearForm}>
              <i className="icon icon-triangle-left" />
              Back to Song Request Form
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

const ConfirmResubmission = ({ resubmit }) => {
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const conflictNotice = "Don't see the song you wanted to request?";
  const confirmationNotice =
    "Are you sure that this song isn't listed above? Duplicates will not be considered.";

  return (
    <div className="song-request-conflicts__force text-center">
      {isConfirmed ? (
        <div>
          <p>{confirmationNotice}</p>
          <p>
            <span className="button button--inline button--primary" onClick={resubmit}>
              Submit song request
            </span>
          </p>
        </div>
      ) : (
        <div>
          <p>
            {conflictNotice}{" "}
            <a className="clickable" onClick={() => setIsConfirmed(true)}>
              Click Here
            </a>
          </p>
        </div>
      )}
    </div>
  );
};
