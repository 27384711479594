import React from "react";
import PropTypes from "prop-types";

const LessonTile = ({ title, gradeId, groupTitle }) => {
  const coercedGrade = parseInt(gradeId) === 10 ? 0 : gradeId;
  return (
    <div className="dashboard-tile">
      {gradeId ? (
        <div className={`tile tile--grade-${coercedGrade}`}>{coercedGrade}</div>
      ) : (
        <div className="tile tile--grade-placeholder"></div>
      )}
      <div className="dashboard-tile__inner">
        <div className="dashboard-tile__title">
          {title.length > 30 ? `${title.substring(0, 27)}...` : title}
        </div>
        {groupTitle && <span className="dashboard-tile__sub">{groupTitle}</span>}
      </div>
    </div>
  );
};

LessonTile.propTypes = {
  title: PropTypes.string.isRequired,
  grade: PropTypes.string,
  groupTitle: PropTypes.string,
};

export default LessonTile;
