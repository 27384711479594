import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { CDN_URL } from "../../components/shared/CdnUrl";

const defaultHeaderImages = {
  [BootstrapSize.smDevice]: `${CDN_URL}/redesign/app/no-more-excuses-lg.png`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/redesign/app/no-more-excuses-sm.png`,
};

const NoMoreExcusesSwoosh = () => {
  return (
    <div className="no-more-excuses-swoosh">
      <Swoosh imgUrl={defaultHeaderImages} id="app-no-more-excuses">
        <div className="no-more-excuses-swoosh__content">
          <h2 className="header2 header2--white uppercase">🚨 No MORE Excuses!</h2>
          <p>
            Dedicate 10 minutes per day and follow my step-by-step instructions, and I know you’ll
            learn to play guitar.{" "}
            <strong>
              All you have to do is follow your daily practice routines in each module.
            </strong>
          </p>
        </div>
      </Swoosh>
    </div>
  );
};

export default NoMoreExcusesSwoosh;
