import React from "react";
import { connect } from "react-redux";

const GroupNavButton = ({ group, direction }) => {
  return (
    <div
      className={`group__nav-item ${direction}-group clickable`}
      onClick={() => (window.location.href = `/modules/${group.slug}`)}
    >
      <h5 className="bold uppercase">
        {direction === "previous" && <i className="icon icon-caret-left" />}
        {direction} Module
        {direction === "next" && <i className="icon icon-caret-right" />}
      </h5>
      <span>{group.title}</span>
    </div>
  );
};

function justify(next, previous) {
  if (next && previous) {
    return "space-between";
  } else if (next) {
    return "flex-end";
  } else {
    return "flex-start";
  }
}

const GroupNavigation = ({ group }) => {
  const { nextGroup, previousGroup } = group.data.attributes;
  if (previousGroup || nextGroup) {
    return (
      <div className="container">
        <div
          className="group__nav flex"
          style={{ justifyContent: justify(nextGroup, previousGroup) }}
        >
          {previousGroup && <GroupNavButton group={previousGroup} direction="previous" />}
          {nextGroup && <GroupNavButton group={nextGroup} direction="next" />}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  group: state.entity.group,
});
export default connect(mapStateToProps)(GroupNavigation);
