import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { CDN_URL } from "../../components/shared/CdnUrl";

const defaultHeaderImages = {
  [BootstrapSize.smDevice]: `${CDN_URL}/images/pages/contact/contact-hero-lg-v2.jpg`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/contact/contact-hero-sm-v2.jpg`,
};

const ContactSwoosh = () => {
  return (
    <div className="contact-swoosh">
      <Swoosh imgUrl={defaultHeaderImages}>
        <div className="contact-swoosh__content container">
          <div className="contact__intro">
            <h1 className="header1">contact</h1>
            <p>
              I try my best to reply to all emails, but please be aware that I have thousands of
              students, so it gets pretty busy! Please check the FAQ before emailing me. Thank you
              :)
            </p>
          </div>
        </div>
      </Swoosh>
    </div>
  );
};

export default ContactSwoosh;
