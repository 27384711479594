import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import SongsGridItem from "../songs-grid/SongsGridItem";
import { carouselSettings } from "./carouselSettings";
import axiosWithCSRF from "../../shared/axiosWithCSRF";
import { SONG_REQUESTS_ENDPOINT } from "./SongRequests";
import Loading from "../../shared/Loading";
import SongRequestsCarouselItem from "./SongRequestCarouselItem";

const requestTypes = {
  COMING_SOON: "Coming Soon",
  LIVE: "Live",
};

const SongRequestsCarousel = () => {
  const [requestType, setRequestType] = useState(requestTypes.LIVE);
  const [upcomingIdx, setUpcomingIdx] = useState(0);
  const [releasedIdx, setReleasedIdx] = useState(0);
  const [releasedSongs, setReleasedSongs] = useState([]);
  const [upcomingSongs, setUpcomingSongs] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchReleasedRequests();
  }, []);

  function fetchReleasedRequests() {
    setLoading(true);
    axiosWithCSRF()
      .get(`${SONG_REQUESTS_ENDPOINT}?scope=released&limit=25`)
      .then((response) => {
        setReleasedSongs(response.data.songRequests.data);
      })
      .finally(() => setLoading(false));
  }

  function fetchUpcomingRequests() {
    setLoading(true);
    axiosWithCSRF()
      .get(`${SONG_REQUESTS_ENDPOINT}?scope=upcoming&limit=25`)
      .then((response) => {
        setUpcomingSongs(response.data.songRequests.data);
      })
      .finally(() => setLoading(false));
  }

  const handleCheckedChange = (event) => {
    const newRequestType = event.target.checked ? requestTypes.COMING_SOON : requestTypes.LIVE;

    if (newRequestType === requestTypes.COMING_SOON && !upcomingSongs) {
      fetchUpcomingRequests();
    }
    setRequestType(newRequestType);
  };

  const requests = requestType === requestTypes.COMING_SOON ? upcomingSongs : releasedSongs;

  const currentIdx = requestType === requestTypes.COMING_SOON ? upcomingIdx : releasedIdx;
  const setCurrentIdx = requestType === requestTypes.COMING_SOON ? setUpcomingIdx : setReleasedIdx;

  const header =
    requestType === requestTypes.COMING_SOON
      ? "These top requests will be lessons soon"
      : "These top requests became lessons thanks to you";

  return (
    <div className="song-requests-carousel">
      <h4 className="header4 uppercase">{header}</h4>
      {loading && <Loading isContent={true} />}
      <div className="toggle">
        <input
          type="checkbox"
          id="song-request-carousel__toggle"
          checked={requestType === requestTypes.COMING_SOON}
          onChange={handleCheckedChange}
        />
        <label htmlFor="song-request-carousel__toggle">
          <div
            className="switch"
            data-checked={requestTypes.COMING_SOON}
            data-unchecked={requestTypes.LIVE}
          />
        </label>
      </div>

      {requests?.length > 0 ? (
        <Slider
          {...carouselSettings}
          infinite={requests.length > 4 /*https://github.com/kenwheeler/slick/issues/940*/}
          initialSlide={currentIdx}
          afterChange={(idx) => setCurrentIdx(idx)}
        >
          {requests.map((request, idx) => (
            <SongRequestsCarouselItem key={idx} songRequest={request} />
          ))}
        </Slider>
      ) : (
        <p className="text-center song-request-carousel__placeholder">None right now!</p>
      )}
    </div>
  );
};

export default SongRequestsCarousel;
