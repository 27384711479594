import React, { useEffect, useState } from "react";
import { ACTION_TYPES, useUser, useUserDispatch } from "../contexts/userContext";
import SongRequestsList from "./SongRequestsList";
import { fetchSongRequests } from "../contexts/userActions";
import { ViewMoreButton } from "../ViewMoreButton";

const UserSongRequests = () => {
  const user = useUser();
  const dispatch = useUserDispatch();

  if (!user) return null;

  const [hasMore, setHasMore] = useState(true);
  const [loadingMoreResults, setLoadingMoreResults] = useState(false);

  useEffect(() => {
    if (!user) return;

    fetchUserSongRequests();
  }, []);

  const fetchUserSongRequests = (replaceResults = true) => {
    setLoadingMoreResults(true);
    const offset = replaceResults ? 0 : user.userSongRequests.length;
    fetchSongRequests(offset)
      .then((response) => {
        const userSongRequests = replaceResults
          ? response.data.songRequests.data
          : [...user.userSongRequests, ...response.data.songRequests.data];

        dispatch({
          type: ACTION_TYPES.SET_USER_SONG_REQUESTS,
          userSongRequests: userSongRequests,
        });

        setHasMore(response.data.hasMore);
      })
      .finally(() => {
        setLoadingMoreResults(false);
      });
  };

  return (
    <div className="user-song-requests">
      <h4 className="heading4">Your Song Requests</h4>
      <div className="infinite-scroll">
        <SongRequestsList songRequests={user?.userSongRequests} />

        <ViewMoreButton
          onViewMore={() => fetchUserSongRequests(false)}
          loadingMoreResults={loadingMoreResults}
          hasMore={hasMore}
        />
      </div>
    </div>
  );
};

export default UserSongRequests;
