import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { CDN_URL } from "../../components/shared/CdnUrl";

const defaultHeaderImages = {
  [BootstrapSize.smDevice]: `${CDN_URL}/images/pages/store/store-hero-lg.jpeg`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/store/store-hero-sm.jpeg`,
};

const StoreSwoosh = () => {
  return (
    <div className="store-swoosh">
      <Swoosh imgUrl={defaultHeaderImages}>
        <div className="store-swoosh__content container">
          <h1 className="header1">Store</h1>
          <p>
            Explore all my courses, apps, books, songbooks, and other resources to take your guitar
            journey even further!
          </p>
        </div>
      </Swoosh>
    </div>
  );
};

export default StoreSwoosh;
