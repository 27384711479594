import React from "react";
import { connect } from "react-redux";

import SongSearchResultItem from "./SongSearchResultItem";
import ProductSearchResultItem from "./ProductSearchResultItem";
import SearchResultItem from "./SearchResultItem";
import {
  searchResultsTypes,
  searchResultsTypeToDefaultListLength,
  searchResultsTypeToDisplayName,
} from "../shared/search";
import ResourceSearchResultItem from "./ResourceSearchResultItem";
import { selectOrderedResultsForType, selectVisibleType } from "./store";

export const ResultSectionList = ({ loading, results, type, query }) => (
  <div className={`search-results__section-list search-results__section-list--${type}`}>
    {results == null || results.length === 0 ? (
      <div className="empty-results">
        {loading
          ? null
          : `There weren't any ${searchResultsTypeToDisplayName[type].toLowerCase()} matching your
        search.`}
      </div>
    ) : (
      results.map(({ attributes }) =>
        type === searchResultsTypes.song ? (
          <SongSearchResultItem key={attributes.slug} song={attributes} />
        ) : type === searchResultsTypes.product ? (
          <ProductSearchResultItem key={attributes.slug} product={attributes} />
        ) : type === searchResultsTypes.resource ? (
          <ResourceSearchResultItem key={attributes.fileUid} resource={attributes} />
        ) : (
          <SearchResultItem key={attributes.slug} type={type} query={query} item={attributes} />
        )
      )
    )}
  </div>
);

export default connect((state, { type }) => {
  const orderedResults = selectOrderedResultsForType(state)(type);
  const visibleType = selectVisibleType(state);
  return {
    results:
      visibleType == null
        ? orderedResults.slice(0, searchResultsTypeToDefaultListLength[type])
        : orderedResults,
    query: state.query,
    loading: state.loading[type],
  };
})(ResultSectionList);
