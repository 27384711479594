import React from "react";
import TabbedTabContainer from "../components/TabbedTabContainer";
import TabbedTextContainer from "../components/TabbedTextContainer";
import DynamicIframe from "../components/DynamicIframe";

export default class Tabbed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      selectedItem: this.props.data[0],
      typeOf: this.props.typeOf,
    };
  }

  updateSelectedItem = (item) => {
    this.setState({ selectedItem: item });
  };

  render() {
    return (
      <div className="tabbed">
        <div className="tabbed__container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 tabbed-navigation tabbed-navigation--large-device">
              <TabbedTabContainer
                data={this.state.data}
                activeTab={this.state.selectedItem}
                triggerSelected={(item) => this.updateSelectedItem(item)}
                typeOf={this.state.typeOf}
              />
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 tabbed-video">
              <div className="tabbed-video__container">
                <DynamicIframe
                  showDonateAfterVideo={false}
                  link={this.state.selectedItem.video}
                  containerHeight="350"
                  aspectRatio="1.777"
                />
              </div>
              {/* Change the order of the tabs for popular songs on smaller devices */}
              {this.state.typeOf == "popular_songs" && (
                <div className="tabbed-text tabbed-video__container--small-device">
                  <TabbedTextContainer item={this.state.selectedItem} typeOf={this.state.typeOf} />
                </div>
              )}
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 top-aligned tabbed-text__container">
              <div className="tabbed-text">
                <TabbedTextContainer item={this.state.selectedItem} typeOf={this.state.typeOf} />
              </div>
            </div>

            {this.state.typeOf == "popular_songs" && (
              <div className="col-sm-12 col-xs-12 tabbed-navigation tabbed-navigation--small-device">
                {/* Change the order of the tabs for popular songs on smaller devices */}
                <TabbedTabContainer
                  data={this.state.data}
                  activeTab={this.state.selectedItem}
                  triggerSelected={(item) => this.updateSelectedItem(item)}
                  typeOf={this.state.typeOf}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
