import React from "react";
import { CDN_URL } from "../../shared/CdnUrl";

const DonateTile = () => (
  <div className="menu-item__section">
    <a href="/store">
      <img src={`${CDN_URL}/images/pages/store/view-all.png`} />
    </a>
  </div>
);

export default DonateTile;
