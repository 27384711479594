import React from "react";
import Donation from "./Donation";
import { withInnerWidth } from "../shared";
import { openAlert, openDialog } from "../shared/ui/uiSlice";
import { connect } from "react-redux";

class NewPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Donation
          currentUser={this.props.currentUser || null}
          donationSingleSuccess={this.props.donationSingleSuccess}
          donationSubscriptionSuccess={this.props.donationSubscriptionSuccess}
          availableDonationTypes={this.props.availableDonationTypes || []}
          showZiggy={typeof this.props.showZiggy === "undefined" || this.props.showZiggy}
          width={this.props.width}
          singleDonationAmounts={this.props.singleDonationAmounts || null}
          monthlyDonationAmounts={this.props.monthlyDonationAmounts || null}
          openDialog={this.props.openDialog}
          openAlert={this.props.openAlert}
          donationsDisabled={this.props.donationsDisabled}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openDialog: (text) => dispatch(openDialog(text)),
  openAlert: (alert) => dispatch(openAlert(alert)),
});

export default connect(null, mapDispatchToProps)(withInnerWidth(NewPayments));
