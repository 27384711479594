import React from "react";
import { connect } from "react-redux";
import { userPracticeRoutines } from "../../session/sessionSelectors";
import { createUserPracticeRoutineFromLesson } from "../../session/sessionEffects";
import Loading from "../../Loading";

const PracticeRoutineItem = ({ item }) => {
  const { duration, title } = item.attributes;
  return (
    <div className="item-within-routine">
      <span>{title}</span>
      <span>{duration} min</span>
    </div>
  );
};

class PracticeRoutineToSave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  userHasAlreadySavedRoutine = (userPracticeRoutines) => {
    return userPracticeRoutines.find(
      (i) => parseInt(i.attributes.practiceRoutineId) === parseInt(this.props.routine.data.id)
    );
  };

  render() {
    const { routine, userPracticeRoutines, createUserPracticeRoutineFromLesson, isLoading } =
      this.props;
    let savedRoutine = this.userHasAlreadySavedRoutine(userPracticeRoutines);
    return (
      <div
        className={"practice-routine " + (savedRoutine ? "practice-routine--saved" : "")}
        style={{ position: "relative" }}
      >
        <h4 className="header4 uppercase bold">{routine.data.attributes.title}</h4>
        {routine.included.map((item) => {
          return <PracticeRoutineItem key={item.id} item={item} />;
        })}

        {savedRoutine ? (
          <div className="practice-item__options">
            <button className="button disabled" disabled>
              saved this practice routine
            </button>
          </div>
        ) : (
          <div>
            {isLoading && <Loading />}
            <div className="practice-item__options">
              {this.state.optionsOpen ? (
                <div>
                  <button
                    className="button button--blue"
                    onClick={() =>
                      createUserPracticeRoutineFromLesson({
                        practiceRoutineId: routine.data.id,
                        setAsActiveRoutine: true,
                      })
                    }
                  >
                    Set as your active routine
                  </button>
                  <button
                    className="button button--dark"
                    onClick={() =>
                      createUserPracticeRoutineFromLesson({
                        practiceRoutineId: routine.data.id,
                        setAsActiveRoutine: false,
                      })
                    }
                  >
                    Add to your saved routines
                  </button>
                  <button
                    onClick={() => this.setState({ optionsOpen: false })}
                    className="button button--danger"
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => this.setState({ optionsOpen: true })}
                  className="button button--primary"
                >
                  Save this routine
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userPracticeRoutines: userPracticeRoutines(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  createUserPracticeRoutineFromLesson: (practiceRoutineId, setAsActiveRoutine) =>
    dispatch(createUserPracticeRoutineFromLesson(practiceRoutineId, setAsActiveRoutine)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticeRoutineToSave);
