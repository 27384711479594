import { styled } from "@mui/material/styles";
import { Link } from "@mui/material";
import { jgDenimColors } from "../../jg-denim-colors";

export const OrangeLink = styled(Link)({
  color: jgDenimColors.accent.orange.main,
  fontWeight: "bold",
  textDecoration: "none",
  cursor: "pointer",
});
