import React from "react";
import SongsListItem from "./SongsListItem";
import NoResults from "./NoResults";

const ListingView = ({ songs }) =>
  songs == null || songs.length === 0 ? (
    <NoResults />
  ) : (
    <div>
      {songs.map((song) => (
        <SongsListItem song={song.attributes} key={song.id} />
      ))}
    </div>
  );

export default ListingView;
