import React, { useState, useEffect } from "react";
import ReactOnRails from "react-on-rails";
import { Provider } from "react-redux";
import "./store/chordsStore";
import { withBootstrapSize } from "../../components/shared/WithBootstrapSize";
import Snackbar from "../../components/shared/ui/Snackbar";
import Library from "./Library";
import Chord from "./Chord";
import chordData from "./chords.json";
import "../../components/shared/polyfills/replaceAll.js";

let nav, content, footer;
const FIXED_NAV_TOP_GAP = 10;

const Chords = ({ bootstrapSize }) => {
  const store = ReactOnRails.getStore("chordsStore");

  const chords = store.getState().entity.chords;

  const chordsWithoutVariants = chords.filter(
    (chord) =>
      !chord.search_title.toLowerCase().includes(`variation`) ||
      chord.search_title.toLowerCase().includes(`variation #0`)
  );

  const chordVariants = chords.filter((chord) =>
    chord.search_title.toLowerCase().includes(`variation`)
  );

  const chordRootNotes = chords
    .map((chord) => chord.root_note)
    .filter((chord, idx, self) => self.indexOf(chord) === idx)
    .sort();

  const chordCategories = chords
    .reduce(
      (items, chord) =>
        chord.categories ? [...items, ...chord.categories.replace(`, `, `,`).split(`,`)] : items,
      []
    )
    .filter((chord, idx, self) => self.indexOf(chord) === idx)
    .sort();

  const chordGroupsByRootNote = chordRootNotes.map((root_note) => {
    return {
      name: root_note,
      chords: chordsWithoutVariants.filter((chord) => chord.root_note === root_note),
    };
  });

  const chordGroupsByCategory = chordCategories.map((category) => {
    return {
      name: category,
      chords: chordsWithoutVariants.filter((chord) =>
        chord.categories ? chord.categories.includes(category) : false
      ),
    };
  });

  const getActiveChord = () => {
    const slug = window.location.pathname.split(`/`).pop();

    return slug !== `chords` ? chords.find((chord) => chord.slug === slug) : null;
  };

  const getActiveType = () => {
    const locationHash = window.location.hash.slice(1).replaceAll(`-`, ` `);

    if (locationHash.length) {
      if (
        chordGroupsByRootNote.find(
          (chordGroupByRootNote) => chordGroupByRootNote.name.toLowerCase() === locationHash
        )
      ) {
        return `notes`;
      }

      if (
        chordGroupsByCategory.find(
          (chordGroupByRootNote) => chordGroupByRootNote.name.toLowerCase() === locationHash
        )
      ) {
        return `categories`;
      }
    }

    return `notes`;
  };

  const getChordSectionTitle = (chordSectionIdx, chord) => {
    if (chordSectionIdx === 0) {
      return chord.page_title.replace(" (Variation #0)", "");
    }

    if (chordSectionIdx === 1) {
      return `${chord.title} Chord - Box diagram`;
    }

    if (chordSectionIdx === 2) {
      return `Learn More from these Related Lessons!`;
    }

    if (chordSectionIdx === 3) {
      return `How to Practice The ${chord.title} Chord?`;
    }

    if (chordSectionIdx === 4) {
      return `${chord.title} Chord name, abbreviation and theory`;
    }

    if (chordSectionIdx === 5) {
      return `Common chord progression with the ${chord.title} Chord`;
    }

    return chordSections[chordSectionIdx];
  };

  const chordSections = [
    `How to play video`,
    `Chord box diagram`,
    `Related lesson links`,
    `Chord practice`,
    `Name, abbreviation & theory`,
    // `Common Chord Progressions`, // show chord progressions on v2
  ];

  const [data, setData] = useState({
    activeType: getActiveType(),
    activeChord: getActiveChord(),
    activeSection:
      window.location.hash.slice(1) || (getActiveChord() === null ? "a" : "how-to-play-video"),
  });

  useEffect(() => {
    nav = document.querySelector(".chords__nav");
    content = document.querySelector(".chords__content");
    footer = document.querySelector("[id*=Footer-react-component]");

    window.addEventListener("scroll", isSticky);

    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    if (["xsDevice", "smDevice", "mdDevice"].includes(bootstrapSize)) {
      return;
    }

    if (content.getBoundingClientRect().top <= FIXED_NAV_TOP_GAP) {
      nav.classList.add("fixed");
      content.style.marginLeft = `${nav.offsetWidth + 20}px`;

      if (footer.getBoundingClientRect().top < nav.offsetHeight + FIXED_NAV_TOP_GAP) {
        nav.style.top = `${(nav.offsetHeight - footer.getBoundingClientRect().top) * -1}px`;
      } else {
        nav.style.top = `${FIXED_NAV_TOP_GAP}px`;
      }
    } else {
      nav.classList.remove("fixed");
      content.style.marginLeft = "";
    }
  };

  return (
    <Provider store={store}>
      <div className="chords container">
        {!data.activeChord ? (
          <Library
            data={data}
            setData={setData}
            chordGroupsByRootNote={chordGroupsByRootNote}
            chordGroupsByCategory={chordGroupsByCategory}
            chordsWithoutVariants={chordsWithoutVariants}
            bootstrapSize={bootstrapSize}
          />
        ) : (
          <Chord
            data={data}
            setData={setData}
            chordSections={chordSections}
            getChordSectionTitle={getChordSectionTitle}
            chordVariants={chordVariants}
            bootstrapSize={bootstrapSize}
          />
        )}
        <Snackbar />
      </div>
    </Provider>
  );
};

export default withBootstrapSize(Chords);
