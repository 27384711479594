import React from "react";

export default class ProductAppButtons extends React.Component {
  constructor(props) {
    super(props);
  }

  link = (platform) => {
    let { product } = this.props;
    if (product.genius_link && product.genius_link.length > 0) {
      return product.genius_link;
    } else if (platform === "App Store") {
      return product.app_store_link;
    } else if (platform === "Play Store") {
      return product.google_play_link;
    }
  };

  render() {
    return (
      <div className="app-image__container">
        {this.props.product.app_store_link && (
          <div className="apple-badge">
            <a href={this.link("App Store")} target="_blank">
              <img src={this.props.product.app_store_image}></img>
            </a>
          </div>
        )}
        {this.props.product.google_play_link && (
          <div className="google-badge">
            <a href={this.link("Play Store")} target="_blank">
              <img src={this.props.product.google_play_image}></img>
            </a>
          </div>
        )}
      </div>
    );
  }
}
