export const currencyTypes = {
  USD: "USD",
  GBP: "GBP",
  AUD: "AUD",
  EUR: "EUR",
  CAD: "CAD",
};

export const currencies = {
  [currencyTypes.USD]: "USD $",
  [currencyTypes.GBP]: "GBP £",
  [currencyTypes.AUD]: "AUS $",
  [currencyTypes.EUR]: "EUR €",
  [currencyTypes.CAD]: "CAD $",
};

export const donationTypes = {
  MONTHLY: "monthly",
  SINGLE: "single",
  BANK_TRANSFER: "bankTransfer",
  CASH_OR_CHECK: "cashOrCheck",
  OTHER: "other",
};

export const donationTabs = {
  [donationTypes.MONTHLY]: "Monthly",
  [donationTypes.SINGLE]: "One-Time",
  [donationTypes.BANK_TRANSFER]: "Direct Bank Transfer",
  [donationTypes.CASH_OR_CHECK]: "Cash or Cheque",
  [donationTypes.OTHER]: "Other",
};

export const donationTabsForDashboard = {
  [donationTypes.MONTHLY]: "Monthly",
  [donationTypes.SINGLE]: "One-Time",
};
