import { styled } from "@mui/material";
import React from "react";
import { IEbookFeatured } from "./ebooks.interface";

export const EbookFeatured: React.FC<IEbookFeatured> = ({ attributes, id, type }) => {
  const openUrl = (url) => {
    if (url) {
      window.open(url)?.focus();
    }
  };

  return (
    <FeaturedContainer
      backgroundImage={attributes?.desktopBackgroundImage}
      backgroundImage_sm={attributes?.mobileBackgroundImage}
      onClick={() => openUrl(attributes?.fileUrl)}
    ></FeaturedContainer>
  );
};

const FeaturedContainer = styled("div", {
  shouldForwardProp: (prop) => !prop.toString().startsWith("backgroundImage"),
})<{
  backgroundImage: string;
  backgroundImage_sm: string;
}>((props) => ({
  width: "100%",
  height: "325px",
  marginTop: "80px",
  borderRadius: "8px",
  cursor: "pointer",
  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundImage: `url(${props.backgroundImage})`,
  [props.theme.breakpoints.down("sm")]: {
    width: "calc(100% - 20px)",
    height: "480px",
    marginTop: "20px",
    marginLeft: "10px",
    marginRight: "10px",
    backgroundImage: `url(${props.backgroundImage_sm})`,
  },
}));
