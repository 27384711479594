import { Typography } from "@mui/material";
import React from "react";

const SectionHeader = ({ content }) => (
  <Typography sx={{ textAlign: "center" }} variant={"h3"} mb={5}>
    {content}
  </Typography>
);

export default SectionHeader;
