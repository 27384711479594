import React from "react";
import NoResults from "./NoResults";
import SongsGridItem from "../songs-grid/SongsGridItem";
import SongActionButtons from "../../songs/SongActionButtons";
import * as bootstrapHelpers from "../../shared/bootstrap-helpers";

function GridView({ songs, width }) {
  if (songs == null || songs.length === 0) {
    return <NoResults />;
  }
  return (
    <div className="song-grid">
      {songs.map((song) => (
        <div key={song.id}>
          <SongsGridItem song={song.attributes} key={song.id} />
          {width < bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.mdDevice] && (
            <SongActionButtons song={song.attributes} />
          )}
        </div>
      ))}
    </div>
  );
}

export default GridView;
