import { createSelector } from "@reduxjs/toolkit";
import { JgState } from "../../jg-store";
import { SessionState } from "./session.slice";

export const selectSession = (state: JgState): SessionState => state.session || {};

export const selectCurrentUser = createSelector(selectSession, ({ currentUser }) => currentUser);

export const selectIsLoggedIn = createSelector(selectCurrentUser, (user) => user != null);

export const isLoggedOut = createSelector(selectIsLoggedIn, (loggedIn) => !loggedIn);
