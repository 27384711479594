import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import MultiplePaymentOptions from "./MultiplePaymentOptions";
import { LessonCard } from "../GroupLessonsList";
import SinglePaymentOption from "./SinglePaymentOption";

export const GroupPaywall = (props) => {
  const pricingOptions = props.paywall.included.filter((i) => i.type === "pricingOption");
  if (pricingOptions.length > 1) {
    return <MultiplePaymentOptions {...props} pricingOptions={pricingOptions} />;
  } else {
    return <SinglePaymentOption {...props} pricingOptions={pricingOptions} />;
  }
};

function sortedLessons(lessonOrder, lessons) {
  let orderedLessons = lessonOrder.map((lessonId) => {
    return lessons.filter((l) => l.attributes.id === lessonId)[0];
  });
  return orderedLessons.filter((l) => l && l != null);
}

const GroupPaywalledOnParentGroup = ({ paywalledGroup, currentUser, token }) => {
  const { title, paywall, lessonOrder, lessonTitlesWithIds } = paywalledGroup.data.attributes;
  const lessons = sortedLessons(lessonOrder, lessonTitlesWithIds);

  return (
    <div className="group paywalled-group">
      <Swoosh color="#44546c">
        <div className="paywalled-group__swoosh-content">
          <h1 className="header1">{title}</h1>
          <div className="paywalled-group__paywall">
            <GroupPaywall paywall={paywall} currentUser={currentUser} token={token} />
          </div>
        </div>
      </Swoosh>

      <div className="tabz container">
        <div className="tabz__options">
          <button className="active">
            <i className="icon icon-stacked-boxes" />
            <span>Lessons</span>
          </button>
        </div>

        <div className="tabz__content tabz__content--Lessons">
          {lessons.map((lesson) => {
            return (
              <LessonCard
                lesson={lesson}
                completedLessonIds={[]}
                lockedLessonIds={lessons.map((l) => l.attributes.id)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GroupPaywalledOnParentGroup;
