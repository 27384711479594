import React from "react";

export default class UserItemButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      itemStatus: props.itemStatus,
      itemPresent: props.itemPresent,
      buttonDisabled: false,
    };
  }

  saveItemText = (present) => {
    if (present) {
      return (
        <div>
          <span className="icon icon-heart"></span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="glyphicon glyphicon-heart-empty"></span>
          <p className="header5 save-item__button"> Save for Later</p>
        </div>
      );
    }
  };

  progressItemText = (present) => {
    if (present) {
      return (
        <div>
          <span className="glyphicon glyphicon-time glyphicon-time--yellow"></span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="glyphicon glyphicon-time"></span>
          <p className="header5 save-item__button"> In Progress</p>
        </div>
      );
    }
  };

  completedItemText = (present) => {
    if (present) {
      return (
        <div>
          <span className="icon icon-check icon-check--green"></span>
        </div>
      );
    } else {
      let text = this.props.itemableType === "Group" ? "Module Complete" : "Mark as Complete";
      return (
        <div>
          <span className="icon icon-check"></span>
          <p className="header5 save-item__button"> {text} </p>
        </div>
      );
    }
  };

  handleItemSubmit(status) {
    this.props.changeItem(status);
    this.setState({ buttonDisabled: true });
    setTimeout(() => this.setState({ buttonDisabled: false }), 500);
    if ("ga" in window) {
      let tracker = ga.getAll()[0];
      if (tracker) {
        tracker.send(
          "event",
          "UserItemButton",
          `${this.props.itemStatus}`,
          `${this.props.item.reference}-${this.props.item.title}`
        );
      }
    }
  }

  render() {
    return (
      <button
        onClick={() => this.handleItemSubmit(this.props.itemStatus)}
        disabled={this.state.buttonDisabled}
      >
        {this.state.itemStatus === "saved" && this.saveItemText(this.props.itemPresent)}

        {this.state.itemStatus === "progress" && this.progressItemText(this.props.itemPresent)}

        {this.state.itemStatus === "completed" && this.completedItemText(this.props.itemPresent)}
      </button>
    );
  }
}
