import React from "react";
import DeleteUserItem from "../components/DeleteUserItem";

export default class DashboardCardItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lesson: this.props.lesson,
      status: this.props.status,
    };
  }

  onItemDelete = (lessonId, itemableId) => {
    this.props.deletedItem(lessonId, itemableId);
  };

  render() {
    return (
      <div>
        {this.state.status == "completed" && <span className="icon icon-check"></span>}
        {this.state.status == "progress" && <span className="glyphicon glyphicon-time"></span>}
        {this.state.status == "saved" && <span className="glyphicon glyphicon-heart-empty"></span>}
        <a href={this.state.lesson.path}>{this.state.lesson.title}</a>
        <DeleteUserItem
          itemId={this.state.lesson.user_item_id}
          groupId={this.state.lesson.group_id}
          lessonId={this.state.lesson.lesson_id}
          deletedItem={this.onItemDelete}
        />
      </div>
    );
  }
}
