import React, { useEffect, useState } from "react";
import axiosWithCSRF from "./axiosWithCSRF";
import "../../components/shared/polyfills/replaceAll.js";

const buttonVariants = ["Start Your Free Trial", "Download Free", "Download"];
const randomButtonVariant = buttonVariants[Math.floor(Math.random() * buttonVariants.length)];

const AppSwooshBannerForCourses = ({ ctaUrl = null }) => {
  const [bannerId, setBannerId] = useState(null);

  useEffect(() => {
    axiosWithCSRF()
      .post("/app_banner_views", {
        app_banner_view: {
          identifier: randomButtonVariant.toLowerCase().replaceAll(" ", "-"),
        },
      })
      .then((res) => {
        setBannerId(res.data.appBannerViewId);
      });
  }, []);

  const ctaClick = () => {
    if (bannerId) {
      axiosWithCSRF()
        .patch(`/app_banner_views/${bannerId}`, {
          app_banner_view: { was_clicked: true },
        })
        .then((res) => {
          window.open(ctaUrl ? ctaUrl : `https://justinguitarapp.musopia.net`, "_blank");
        });
    }
  };

  return (
    <div className={`app-swoosh-banner-for-courses`}>
      <div className="jg-app-icon"></div>
      <p>
        Learn anywhere, anytime with my <strong>Lessons & Songs App</strong>.
      </p>
      <a onClick={() => ctaClick()} className="button button--primary">
        {randomButtonVariant}
      </a>
    </div>
  );
};

export default AppSwooshBannerForCourses;
