import React from "react";
import { connect } from "react-redux";
import { toggleField } from "../../store/songStore";

const SubMenuTab = ({ activeSheetMusicContent, toggleField }) => {
  return (
    <li
      className={`clickable more-opts${activeSheetMusicContent === "Tabs" ? " disabled" : ""}`}
      onClick={() => toggleField("subMenuOpen")}
    ></li>
  );
};

const mapStateToProps = (state) => ({
  activeSheetMusicContent: state.entity.activeSheetMusicContent,
});

const mapDispatchToProps = (dispatch) => ({
  toggleField: (field) => dispatch(toggleField(field)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubMenuTab);
