import React from "react";
import PropTypes from "prop-types";
import { formatDate, accessHasExpired } from "../Purchases/purchaseUtils";
import { SUBSCRIPTION_STATUSES } from "../Purchases/consts";
import { CURRENCY_SIGN, PLAN_TYPES, SUBSCRIPTION_STATUS_KEYS } from "../../../subscriptions/consts";
import { Box } from "@mui/material";

const Renewal = ({ status, price, currency, expiresAt, installmentsRemaining, planType }) => {
  const currentStatus = SUBSCRIPTION_STATUSES[status];
  const formattedExpiration = formatDate(expiresAt);
  let renewalMessage = "";

  if (planType === PLAN_TYPES.lifetime) {
    renewalMessage = "Lifetime access";
  } else if (planType === PLAN_TYPES.installment) {
    renewalMessage = `Lifetime access. ${installmentsRemaining} installments of ${CURRENCY_SIGN[currency]}${price} remaining. Next payment on ${formattedExpiration}`;
  } else if (planType === PLAN_TYPES.temporaryAccess) {
    renewalMessage = `You have access until ${formattedExpiration}`;
  } else if (!!expiresAt === false) {
    // invalid data from legacy subscription that was imported to the new system.
    renewalMessage = "Your access is ongoing";
  } else if (currentStatus === SUBSCRIPTION_STATUS_KEYS.trial) {
    renewalMessage = `Your trial will end on ${formattedExpiration}`;
  } else if (currentStatus === SUBSCRIPTION_STATUS_KEYS.cancelled) {
    renewalMessage = `Your subscription has been cancelled.`;
    let expirationMessage = accessHasExpired(expiresAt)
      ? `Your access expired on ${formattedExpiration}.`
      : `You will lose access after ${formattedExpiration}.`;
    renewalMessage = `${renewalMessage} ${expirationMessage}`;
  } else if (currentStatus === SUBSCRIPTION_STATUS_KEYS.unpaid) {
    renewalMessage = `Your subscription is unpaid. Your access ended on ${formattedExpiration}.`;
  } else {
    renewalMessage = `Next payment of ${
      CURRENCY_SIGN[currency] || currency
    }${price} due on ${formattedExpiration}`;
  }

  return <Box className="purchase__due">{renewalMessage}</Box>;
};

Renewal.propTypes = {
  status: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  expiresAt: PropTypes.string,
  planType: PropTypes.string,
  installmentsRemaining: PropTypes.number,
};

export default Renewal;
