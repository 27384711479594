import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Login from "./Login";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // maxWidth: "100%",
    width: "90%",
    height: "90%",
    backgroundColor: "transparent",
    border: "none",
    maxWidth: "600px",
    maxHeight: "600px",
    borderRadius: "20px",
  },
};

export default class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      Modal.setAppElement("body");
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        style={customStyles}
        onRequestClose={this.props.toggleModal}
        contentLabel="Log In"
      >
        <Login token={this.props.token} />
      </Modal>
    );
  }
}

LoginModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
