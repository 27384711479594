import React from "react";
import DashboardFilters from "../shared/DashboardFilters";
import NoteCard from "./NoteCard";
import { withInnerWidth } from "../../components/shared";
import Tabs from "../shared/Tabs";

const SORT_BY = [
  {
    title: "Most Recent",
    column: "createdAt",
    direction: "desc",
  },
  { title: "Oldest", column: "createdAt", direction: "asc" },
];

const UserNote = ({
  goToTab,
  activeTab,
  dashboardFiltersRef,
  searchNotes,
  sortNotes,
  notes,
  stateNotes,
  editNote,
  goToNoteableType,
  tabs,
  width,
}) => {
  return (
    <div className="dashboard__content__tabs">
      <Tabs tabs={tabs} activeTab={activeTab} goToTab={goToTab} width={width} />
      <div className="tab-container">
        {stateNotes && stateNotes.length ? (
          <>
            <DashboardFilters
              ref={dashboardFiltersRef}
              searchPlaceholder="Search your notes..."
              sortBy={SORT_BY}
              onSearch={searchNotes}
              onSort={sortNotes}
            />
            <div className="tab-content">
              <p className="info">
                {activeTab} ({stateNotes.length})
              </p>
              {notes.length ? (
                <div className="cards">
                  {notes.map((note, idx) => (
                    <NoteCard
                      key={idx}
                      note={note}
                      onEdit={editNote}
                      goToNoteableType={goToNoteableType}
                    />
                  ))}
                </div>
              ) : (
                <p>No notes found</p>
              )}
            </div>
          </>
        ) : (
          <p className="text-center">
            You haven't saved any{" "}
            {activeTab === "all" ? "" : activeTab.substring(0, activeTab.length - 1)} notes yet
          </p>
        )}
      </div>
    </div>
  );
};

export default withInnerWidth(UserNote);
