import { schema } from "normalizr";

export const songbook = new schema.Entity("songbooks");
export const song = new schema.Entity("songs");
export const artist = new schema.Entity("artists");
export const songbookSong = new schema.Entity("songbookSongs");

export const songbookIndexJson = {
  data: [songbook],
  included: new schema.Array({ song, artist, songbookSong }, (entity) => entity.type),
};

export const songbookJson = {
  data: songbook,
  included: new schema.Array({ song, artist, songbookSong }, (entity) => entity.type),
};

export const songOptionsJson = {
  data: [song],
  included: new schema.Array({ artist }, (entity) => entity.type),
};
