import React from "react";

export default function () {
  return (
    <div className="other-ways container">
      <div className="other-ways__header">
        <h2>Other Ways You Can Support JustinGuitar</h2>
        <p>
          Enjoying the lessons? Here are a few easy ways to support JustinGuitar if you dig my work!
        </p>
      </div>
      <div className="other-ways__content">
        <div className="other-ways__column other-ways__column--left">
          <div className="other-ways__card other-ways__card--donate">
            <h4 className="uppercase">Donations</h4>
            <p>
              I don’t charge for my lessons because I know that not everyone can afford them. By
              donating, you help provide the very best guitar education to people from all walks of
              life, all over the world.{" "}
              <span style={{ fontWeight: 700 }}>
                If you have enjoyed the site and can afford to contribute, please donate. :) Thank
                you!
              </span>
            </p>
            <a
              href="/donate"
              className="button button--white button--inline"
              style={{ width: "fit-content" }}
            >
              Donate
            </a>
          </div>

          <div className="other-ways__card other-ways__card--free">
            <div>
              <i className="icon icon-heart" />
            </div>
            <div>
              I will give you most of my products for <span className="emph--free">FREE</span> if
              you genuinely can't afford it. Email{" "}
              <span className="emph--orange">
                <a href="mailto:assist@justinguitar.com">hello@justinguitar.com</a>
              </span>{" "}
              and someone will get in touch with you!
            </div>
          </div>
        </div>

        <div className="other-ways__column other-ways__column--right">
          <div className="other-ways__card other-ways__card--social">
            <div className="social-left">
              <h5>YouTube & Social Media</h5>
              <p>
                Subscribe to my
                <a href="https://www.youtube.com/user/JustinSandercoe">
                  {" "}
                  YouTube Main channel
                </a> and{" "}
                <a href="https://www.youtube.com/user/JustinSandercoeSongs">
                  Youtube Songs channel
                </a>{" "}
                and/or follow me on social media.
              </p>
            </div>

            <div className="social-right">
              <a href="//instagram.com/justinguitar">
                <i className="icon icon-instagram" />
              </a>
              <a href="//youtube.com/user/JustinSandercoe">
                <i className="icon icon-youtube" />
              </a>
              <a href="//twitter.com/justinsandercoe">
                <i className="icon icon-twitter" />
              </a>
              <a href="//facebook.com/justinguitarofficial">
                <i className="icon icon-facebook" />
              </a>
            </div>
          </div>
          <div className="other-ways__card">
            <h5>Spread the Word</h5>
            <p>
              Tell your friends, your local music store, your guitar teacher, people on other guitar
              forums. If you're a journalist, a musician, or an influencer, let your community know
              that a guitar teacher is giving some free courses over here!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
