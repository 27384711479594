export const bannerVersions = [
  // {
  //   identifier: "9",
  //   layout: "singleLine",
  //   theme: "dark",
  //   background: "linear-gradient(90deg, #406E8E 0%, #1D3446 100%)",
  //   subtitle: "Free trial on my App",
  //   fullWidthSubtitle: false,
  //   showRating: true,
  //   showReviewCount: true,
  //   showDownloadText: false,
  //   buttonText: "Download",
  //   buttonType: "text",
  //   leftImageName: "AppLogo1",
  // },
  // {
  //   identifier: "10",
  //   layout: "singleLine",
  //   theme: "dark",
  //   background: "linear-gradient(90deg, #406E8E 0%, #1D3446 100%)",
  //   subtitle: "FREE trial on my App!",
  //   fullWidthSubtitle: false,
  //   showRating: true,
  //   showReviewCount: true,
  //   showDownloadText: false,
  //   buttonText: "Download",
  //   buttonType: "store",
  //   leftImageName: "AppLogo1",
  // },
  // {
  //   identifier: "11",
  //   layout: "singleLine",
  //   theme: "dark",
  //   background: "linear-gradient(90deg, #406E8E 0%, #1D3446 100%)",
  //   subtitle: "Free trial on my App",
  //   fullWidthSubtitle: false,
  //   showRating: true,
  //   showReviewCount: true,
  //   showDownloadText: false,
  //   buttonText: "Download",
  //   buttonType: "text",
  //   leftImageName: "AppLogo2",
  // },
  // {
  //   identifier: "12",
  //   layout: "singleLine",
  //   theme: "dark",
  //   background: "linear-gradient(90deg, #406E8E 0%, #1D3446 100%)",
  //   subtitle: "Practice 1,000+ hit songs with backing tracks!",
  //   fullWidthSubtitle: false,
  //   showRating: true,
  //   showReviewCount: true,
  //   showDownloadText: false,
  //   buttonText: "Download",
  //   buttonType: "icon",
  //   leftImageName: "AppLogo2",
  // },
  // {
  //   identifier: "13",
  //   layout: "singleLine",
  //   theme: "dark",
  //   background: "linear-gradient(90deg, #406E8E 0%, #1D3446 100%)",
  //   subtitle: "Try my App for FREE!",
  //   fullWidthSubtitle: false,
  //   showRating: true,
  //   showReviewCount: true,
  //   showDownloadText: false,
  //   buttonText: "Download",
  //   buttonType: "icon",
  //   leftImageName: "Justin1",
  // },
  {
    identifier: "14",
    layout: "singleLine",
    theme: "dark",
    background: "linear-gradient(90deg, #406E8E 0%, #1D3446 100%)",
    subtitle: "Try my App for FREE!",
    fullWidthSubtitle: false,
    showRating: true,
    showReviewCount: true,
    showDownloadText: false,
    buttonText: "Download",
    buttonType: "text",
    leftImageName: "Justin1",
  },
  // {
  //   identifier: "15",
  //   layout: "singleLine",
  //   theme: "dark",
  //   background: "linear-gradient(90deg, #406E8E 0%, #1D3446 100%)",
  //   subtitle: "Try my App for FREE today!",
  //   fullWidthSubtitle: true,
  //   showRating: true,
  //   showReviewCount: true,
  //   showDownloadText: false,
  //   buttonText: "Download",
  //   buttonType: "store",
  //   leftImageName: "Justin1",
  // },
  // {
  //   identifier: "16",
  //   layout: "singleLine",
  //   theme: "dark",
  //   background: "radial-gradient(44.44% 160% at 50% 50%, #406E8E 0%, #1D3446 100%)",
  //   subtitle: "Try my App for FREE today!",
  //   fullWidthSubtitle: false,
  //   showRating: true,
  //   showReviewCount: true,
  //   showDownloadText: true,
  //   buttonText: "Download",
  //   buttonType: null,
  //   leftImageName: "Justin2",
  // },
  {
    identifier: "17",
    layout: "singleLine",
    theme: "dark",
    background: "linear-gradient(90deg, #406E8E 0%, #1D3446 100%)",
    subtitle: "Try my App!",
    fullWidthSubtitle: false,
    showRating: true,
    showReviewCount: true,
    showDownloadText: false,
    buttonText: "Free Trial",
    buttonType: "text",
    leftImageName: "Justin1",
    // url: "https://www.justinguitarapp.com?utm_source=justin_website&utm_campaign=web_payments&utm_content=smart_banner",
    url: "https://justinguitarapp.onelink.me/3065804798/83b0426f",
  },
];
