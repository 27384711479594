import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import * as PropTypes from "prop-types";

import {
  listingStyles,
  selectListingLoading,
  selectListingStyle,
  selectSongMetadata,
  selectListingSongs,
  slices,
  fetchSongs,
  selectSongCount,
  selectFilters,
} from "./store";
import { withBootstrapSize } from "../shared/WithBootstrapSize";
import SongListingItem from "./SongsListingItem";
import SongsGridItem from "./SongsGridItem";
import SongsSearch from "./SongsSearch";
import SongsFiltersList from "./SongsFiltersList";
import SongsFiltersButton from "./SongsFiltersButton";
import SongsSortBy from "./SongsSortBy";
import SongsListingStyle from "./SongsListingStyle";
import { InfiniteScroll } from "../shared/InfiniteScroll";
import { BootstrapSize, breakpoints } from "../shared/bootstrap-helpers";
import SearchParamsPane from "./SearchParamsPane";
import * as bootstrapHelpers from "../shared/bootstrap-helpers";
import SongActionButtons from "./SongActionButtons";
import Loading from "../shared/Loading";
import ChordsAndTabsFilterButtons from "./ChordsAndTabsFilterButtons";

const ListingControls = ({ width, setShowSearchParamsPane, artist }) => {
  const shouldShow = !artist || width > breakpoints[BootstrapSize.lgDevice];
  return (
    shouldShow && (
      <div className="songs-listing__sidebar">
        <SongsSearch />
        {width > breakpoints[BootstrapSize.lgDevice] ? (
          <>
            <ChordsAndTabsFilterButtons />
            <SongsFiltersList buttonClassModifier="blue" />
          </>
        ) : width > breakpoints[BootstrapSize.smDevice] ? (
          <>
            <SongsFiltersButton buttonClassModifier="blue" />
          </>
        ) : (
          <button
            className="btn--filters button button--blue"
            onClick={() => setShowSearchParamsPane(true)}
          >
            <i className="icon icon-sliders" />
          </button>
        )}
      </div>
    )
  );
};

const NoResults = () => <div className="no-results">No results match your criteria</div>;

const ListingView = ({ songs }) =>
  songs == null || songs.length === 0 ? (
    <NoResults />
  ) : (
    <div>
      {songs.map((song) => (
        <SongListingItem song={song.attributes} key={song.id} />
      ))}
    </div>
  );

const GridView = ({ songs, width }) =>
  songs == null || songs.length === 0 ? (
    <NoResults />
  ) : (
    <div className="song-grid">
      {songs.map((song) => (
        <div key={song.id}>
          <SongsGridItem song={song.attributes} key={song.id} />
          {width < bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.mdDevice] && (
            <SongActionButtons song={song.attributes} />
          )}
        </div>
      ))}
    </div>
  );

export const SongsListing = withBootstrapSize(
  ({
    songs,
    songsMetadata,
    listingStyle,
    loading,
    fetchPage,
    width,
    artist,
    hideListingControls,
    songCount,
    activeFilters,
  }) => {
    const [showSearchParamsPane, setShowSearchParamsPane] = useState(false);
    return (
      <>
        <h2 className="header2">
          {!artist ? "Songs " : `Songs by ${artist.attributes.name} `}
          {loading ? (
            <span style={{ position: "relative" }}>
              <Loading isInput={true} />
            </span>
          ) : (
            `(${
              artist || Object.keys(activeFilters).length ? songsMetadata.totalCount : songCount
            })`
          )}
        </h2>

        <div
          className={`songs-listing songs-listing--${
            listingStyle === listingStyles.list ? "listing" : "grid"
          }`}
        >
          {!hideListingControls && (
            <ListingControls
              width={width}
              setShowSearchParamsPane={setShowSearchParamsPane}
              artist={artist}
            />
          )}
          <div className="songs-listing__body">
            {width > breakpoints[BootstrapSize.smDevice] &&
              (!artist || width > breakpoints[BootstrapSize.lgDevice]) && (
                <div
                  style={{
                    justifyContent: hideListingControls ? "space-between" : "flex-end",
                    paddingBottom: "20px",
                    paddingTop: hideListingControls == null ? 0 : "20px",
                  }}
                  className="songs-listing__main--header"
                >
                  <SongsSortBy />
                  <SongsListingStyle />
                </div>
              )}

            {width <= breakpoints[BootstrapSize.lgDevice] && !hideListingControls && (
              <ChordsAndTabsFilterButtons />
            )}

            <InfiniteScroll
              metadata={songsMetadata}
              fetchPage={fetchPage}
              loading={loading}
              enableInfiniteScroll={false}
            >
              {listingStyle === listingStyles.list ||
              width <= breakpoints[BootstrapSize.smDevice] ? (
                <ListingView songs={songs} />
              ) : (
                <GridView songs={songs} width={width} />
              )}
            </InfiniteScroll>
          </div>

          {showSearchParamsPane && (
            <SearchParamsPane close={() => setShowSearchParamsPane(false)} />
          )}
        </div>
      </>
    );
  }
);

SongsListing.propTypes = {
  songs: PropTypes.any,
  listingStyle: PropTypes.any,
  width: PropTypes.any,
  bootstrapSize: PropTypes.any,
};

export default connect(
  (state) => ({
    songsMetadata: selectSongMetadata(state),
    listingStyle: selectListingStyle(state),
    songs: selectListingSongs(state),
    loading: selectListingLoading(state),
    songCount: selectSongCount(state),
    activeFilters: selectFilters(state),
  }),
  (dispatch) => ({
    fetchPage: (page) => {
      dispatch(slices.metadata.actions.setPage({ entity: "songs", page }));
      dispatch(fetchSongs());
    },
  })
)(SongsListing);
