import { connect } from "react-redux";
import onClickOutside from "react-onclickoutside";

import {
  clearFilters,
  closeFilters,
  fetchSearchResults,
  openFilters,
  selectHasActiveFilters,
  toggleFilter,
} from "./store";
import { FiltersOpenCloseButton } from "../../shared/FiltersOpenCloseButton";
import { filters } from "../../shared/search";

export default connect(
  (state) => ({
    availableFilters: filters,
    activeFilters: state.filters,
    filtersOpen: state.filtersOpen,
    hasActiveFilters: selectHasActiveFilters(state),
  }),
  (dispatch) => ({
    openFilters: () => dispatch(openFilters()),
    closeFilters: () => dispatch(closeFilters()),
    clearFilters: () => {
      dispatch(clearFilters());
      dispatch(fetchSearchResults());
    },
    toggleFilter: (option) => {
      dispatch(toggleFilter(option));
      dispatch(fetchSearchResults());
    },
  })
)(FiltersOpenCloseButton);
