import React from "react";
import axiosWithCSRF from "./axiosWithCSRF";
import LoginModal from "../shared/LoginModal";

export default class UserNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noteContent: "",
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user) {
      let notes = user.user_notes || [];
      let currentNote = notes.filter(
        (note) =>
          note.noteable_id === this.props.noteableId &&
          note.noteable_type === this.props.noteableType
      );
      if (currentNote.length > 0) {
        this.setState({ noteContent: currentNote[0].content, noteId: currentNote[0].id });
      }
    }
  }

  handleChange = (event) => {
    this.setState({ noteContent: event.target.value });
  };

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  handleResponse = (text, hasError) => {
    this.setState({ requestResponse: text });
    setTimeout(() => this.setState({ requestResponse: null, hasErrors: hasError }), 2000);
  };

  displayNoBlankContentError = () => {
    this.setState({ requestResponse: "Note cannot be blank!", hasErrors: true });
    setTimeout(() => this.setState({ requestResponse: null, hasErrors: false }), 2000);
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    if (!this.state.noteContent || this.state.noteContent.trim().length == 0) {
      this.displayNoBlankContentError();
    } else {
      let noteData = {
        user_id: this.props.user.id,
        noteable_id: this.props.noteableId,
        noteable_type: this.props.noteableType,
        content: this.state.noteContent,
        id: this.state.noteId,
      };

      let url = "/user_notes";
      let method = "POST";
      if (this.state.noteId) {
        url = `/user_notes/${this.state.noteId}`;
        method = "PATCH";
      }
      axiosWithCSRF()({
        url: url,
        method: method,
        data: {
          user_note: noteData,
        },
      })
        .then((response) => {
          this.setState({ showForm: false, noteId: response.data.user_note.id });
        })
        .catch((error) => {
          this.handleResponse(error.response.data.message, true);
        });
    }
  };

  handleDelete = () => {
    if (confirm("Are you sure you want to delete this note?")) {
      axios
        .delete(`/user_notes/${this.state.noteId}`, {
          headers: {
            "X-CSRF-Token": ReactOnRails.authenticityToken(),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.setState({ noteId: null, noteContent: "", showForm: false });
        })
        .catch((error) => {
          this.handleResponse(error.response.data.message, true);
        });
    }
  };

  render() {
    const { user } = this.props;
    if (!user) {
      return (
        <div>
          <LoginModal />
        </div>
      );
    } else if (this.state.showForm) {
      return (
        <div className="user-note styleized">
          {this.state.requestResponse && (
            <p className={this.state.hasErrors ? "note-error" : "note-success"}>
              {this.state.requestResponse}
            </p>
          )}
          <form onSubmit={this.onFormSubmit}>
            <textarea type="text" value={this.state.noteContent} onChange={this.handleChange} />
            <div className=" user-note__btns">
              <button
                type="button"
                onClick={this.toggleForm}
                className="button button--white button--inline"
              >
                CANCEL
              </button>
              <input
                type="submit"
                value="SUBMIT"
                className="button button--blue button--inline"
              ></input>
              {this.state.noteId && (
                <button
                  type="button"
                  onClick={this.handleDelete}
                  className="button button--danger button--inline"
                >
                  DELETE
                </button>
              )}
            </div>
          </form>
        </div>
      );
    } else {
      return (
        <div className="user-note stylized">
          {this.state.noteId ? (
            <div>
              <p className="user-note__content">{this.state.noteContent}</p>
              <button
                type="button"
                onClick={this.toggleForm}
                className="uppercase button button--white"
              >
                Edit your note
              </button>
            </div>
          ) : (
            <button
              type="button"
              onClick={this.toggleForm}
              className="uppercase button button--blue"
            >
              Click here to add your own notes
            </button>
          )}
        </div>
      );
    }
  }
}
