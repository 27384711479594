import * as bootstrapHelpers from "../../shared/bootstrap-helpers";

export const carouselSettings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.xlDevice],
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.lgDevice],
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.mdDevice],
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: bootstrapHelpers.breakpoints[bootstrapHelpers.BootstrapSize.smDevice],
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
