import {
  Box,
  Button,
  ButtonProps,
  Grid,
  lighten,
  MenuItem,
  Paper,
  Select,
  SelectProps,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { PlaintextAsParagraphs } from "./PlaintextAsParagraphs";
import { jgDenimColors } from "../../../jg-material/theme/jg-denim-colors";
import { fontWeights } from "../../../jg-material/theme/typography";
import { WorkshopEntity } from "../interfaces";
import { ScheduleFormatter } from "./ScheduleFormatter";

enum DayOfWeek {
  sunday = "Sunday",
  monday = "Monday",
  tuesday = "Tuesday",
  wednesday = "Wednesday",
  thursday = "Thursday",
  friday = "Friday",
  saturday = "Saturday",
}

const orderedDaysOfWeek = [
  DayOfWeek.sunday,
  DayOfWeek.monday,
  DayOfWeek.tuesday,
  DayOfWeek.wednesday,
  DayOfWeek.thursday,
  DayOfWeek.friday,
  DayOfWeek.saturday,
];

type WorkshopOrderedSchedule = Array<{
  day: DayOfWeek;
  schedule: string;
}>;

const useWorkshopOrderedSchedule = (workshop: WorkshopEntity): WorkshopOrderedSchedule => {
  const workshopStartDay = orderedDaysOfWeek[new Date(workshop.attributes.arrivalTime).getDay()];

  return orderedDaysOfWeek
    .slice(orderedDaysOfWeek.indexOf(workshopStartDay))
    .concat(orderedDaysOfWeek.slice(0, orderedDaysOfWeek.indexOf(workshopStartDay)))
    .map((day) => ({
      day,
      schedule: workshop.attributes[`schedule${day}`],
    }))
    .filter(({ schedule }) => schedule != null) as Array<{
    day: DayOfWeek;
    schedule: string; // convince typescript that schedule is not null (we filtered out the null ones
  }>;
};

export const WorkshopSchedule = ({ workshop }: { workshop: WorkshopEntity }) => {
  const workshopOrderedSchedule = useWorkshopOrderedSchedule(workshop);

  if (workshopOrderedSchedule.length === null && workshop.attributes.schedule == null) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h4" textAlign="left" margin={jgDenimTheme.spacing(2, 0, 4, 0)}>
        What We'll Learn
      </Typography>
      {workshop.attributes.schedule && (
        <Box style={{ marginBottom: "80px" }}>
          <PlaintextSchedule schedule={workshop.attributes.schedule} />
        </Box>
      )}
      {workshopOrderedSchedule.length > 0 && (
        <>
          <Typography variant="h4" textAlign="left" margin={jgDenimTheme.spacing(2, 0, 4, 0)}>
            Schedule
          </Typography>
          <DailySchedule workshopOrderedSchedule={workshopOrderedSchedule} workshop={workshop} />
        </>
      )}
      <p style={{ marginTop: "30px", fontSize: "16px", color: "#1D3446", fontStyle: "italic" }}>
        🚨 Please note the schedule above is subject to changes without notice!
      </p>
    </Box>
  );
};

const PlaintextSchedule = ({ schedule }: { schedule: string }) =>
  PlaintextAsParagraphs({ text: schedule });

interface ScheduleDayButtonProps extends ButtonProps {
  isCurrentDay: boolean;
}

const ScheduleDayButton = styled(Button, {
  shouldForwardProp: (propName) => propName !== "isCurrentDay",
})<ScheduleDayButtonProps>(({ theme, isCurrentDay }) => ({
  width: "100%",
  marginBottom: theme.spacing(1),
  boxShadow: "none",
  "&:last-of-type": {
    marginBottom: 0,
  },
  "&:hover": {
    backgroundColor: lighten(jgDenimColors.denim.dark, 0.1),
  },
  backgroundColor: jgDenimColors.denim.dark,
  borderRadius: "10px !important",
  "&.Mui-disabled": {
    backgroundColor: jgDenimColors.denim.light,
  },
}));

const DailySchedule = ({
  workshopOrderedSchedule,
  workshop,
}: {
  workshopOrderedSchedule: WorkshopOrderedSchedule;
  workshop: WorkshopEntity;
}) => {
  const [selectedDayIdx, setSelectedDayIdx] = React.useState<number>(0);
  const isMedium = useMediaQuery(jgDenimTheme.breakpoints.down("md"));
  const currScheduleDay = workshopOrderedSchedule[selectedDayIdx];

  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <DaySelect
          collapsed={isMedium}
          workshopOrderedSchedule={workshopOrderedSchedule}
          selectedDayIdx={selectedDayIdx}
          setSelectedDayIdx={setSelectedDayIdx}
        />
      </Grid>

      <Grid item xs={12} md={9} minHeight={"100%"}>
        <Paper
          sx={{
            backgroundColor: jgDenimColors.gray.light,
            p: jgDenimTheme.spacing(2),
            height: "100%",
            width: "100%",
            ml: isMedium ? 0 : 1,
          }}
        >
          {workshop.attributes.slug === "2023-Acoustic-Electric-Classics" ? (
            <ScheduleFormatter text={currScheduleDay.schedule} />
          ) : (
            <PlaintextAsParagraphs text={currScheduleDay.schedule} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

const DaySelect = ({
  workshopOrderedSchedule,
  selectedDayIdx,
  setSelectedDayIdx,
  collapsed,
}: {
  workshopOrderedSchedule: WorkshopOrderedSchedule;
  selectedDayIdx: number;
  setSelectedDayIdx: (idx: number) => void;
  collapsed: boolean;
}) =>
  collapsed ? (
    <DaySelectDropdown
      variant="standard"
      value={selectedDayIdx}
      onChange={(e) => setSelectedDayIdx(e.target.value as number)}
    >
      {workshopOrderedSchedule.map(({ day, schedule }, idx) => (
        <MenuItem value={idx} key={idx}>
          {day}
        </MenuItem>
      ))}
    </DaySelectDropdown>
  ) : (
    <>
      {workshopOrderedSchedule.map(({ day, schedule }, idx) => {
        const isCurrentDay = idx === selectedDayIdx;
        return (
          <ScheduleDayButton
            isCurrentDay={isCurrentDay}
            disabled={isCurrentDay}
            onClick={() => !isCurrentDay && setSelectedDayIdx(idx)}
            key={idx}
          >
            <Typography
              color="white"
              fontWeight="bold"
              textTransform="uppercase"
              textAlign="center"
            >
              {day}
            </Typography>
          </ScheduleDayButton>
        );
      })}
    </>
  );

const DaySelectDropdown = styled(Select)<SelectProps>(({ theme }) => ({
  backgroundColor: jgDenimColors.denim.light,
  borderRadius: "10px",
  color: "white",
  textTransform: "uppercase",
  fontSize: "16px",
  padding: "10px",
  fontWeight: fontWeights.semiBold,
  marginBottom: theme.spacing(1),
  "&:before,&:after": {
    display: "none", // kill bottom border thing
  },
}));
