import React from "react";
import { Step, StepLabel, Stepper, Typography, useMediaQuery } from "@mui/material";
import { workshopRegistrationSteps } from "./steps-constants";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";

export const WorkshopRegistrationStepper = ({ activeStep }: { activeStep: number }) => {
  const isMedium = useMediaQuery(jgDenimTheme.breakpoints.between("sm", "md"));
  const isSmall = useMediaQuery(jgDenimTheme.breakpoints.down("sm"));

  // show last step completed since it's not really a step, it's an info page saying you're done
  const displayedActiveStep =
    activeStep === workshopRegistrationSteps.length - 1 ? activeStep + 1 : activeStep;

  return (
    <Stepper
      orientation={isSmall ? "vertical" : "horizontal"}
      activeStep={displayedActiveStep}
      alternativeLabel={isMedium}
    >
      {workshopRegistrationSteps.map((step, idx) => (
        <Step key={idx}>
          <StepLabel>
            <Typography variant="h6" color="inherit">
              {step.label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
