import React from "react";
import HomeHero from "./HomeHero";
import Swoosh from "../components/shared/Swoosh";
import RelatedLessonsSwoosh from "./RelatedLessonsSwoosh";
import { withBootstrapSize } from "../components/shared/WithBootstrapSize";
import { defaultHeaderImages, studentTestimonials, relatedLessons } from "./consts";
import Endorsements from "./Endorsements";
import StudentTestimonialsSwoosh from "./StudentTestimonialsSwoosh";
import LazyLoad from "react-lazyload";
import SupportYourLearningJourney from "./SupportYourLearningJourney";
import AboutJustin from "./AboutJustin";
import LessonsAndSongsApp from "./LessonsAndSongsApp";
import FeaturedIn from "./FeaturedIn";
import JoinForFree from "./JoinForFree";
import SiteStats from "./SiteStats";
import NewContent from "./NewContent";

const DEFAULT_LAZY_LOAD_HEIGHT = 300;

const Home = ({
  width,
  registeredUserCount,
  freeLessonsCount,
  songLessonsCount,
  apiStats,
  lastFiveLessons,
}) => {
  return (
    <div className="new-home">
      <div className="home-hero-container">
        <HomeHero />
      </div>
      <div className="home-body">
        <div className="container">
          <FeaturedIn width={width} />

          <JoinForFree />

          <SiteStats
            registeredUserCount={registeredUserCount}
            freeLessonsCount={freeLessonsCount}
            songLessonsCount={songLessonsCount}
          />

          <LazyLoad height={DEFAULT_LAZY_LOAD_HEIGHT}>
            <NewContent width={width} lastFiveLessons={lastFiveLessons} apiStats={apiStats} />
          </LazyLoad>
        </div>

        <LazyLoad height={DEFAULT_LAZY_LOAD_HEIGHT}>
          <div className="container">
            <Endorsements />
          </div>
        </LazyLoad>

        <LazyLoad height={DEFAULT_LAZY_LOAD_HEIGHT}>
          <RelatedLessonsSwoosh width={width} relatedLessons={relatedLessons} />
        </LazyLoad>

        <LazyLoad height={DEFAULT_LAZY_LOAD_HEIGHT}>
          <LessonsAndSongsApp width={width} />
        </LazyLoad>

        <LazyLoad height={DEFAULT_LAZY_LOAD_HEIGHT}>
          <StudentTestimonialsSwoosh studentTestimonials={studentTestimonials} />
        </LazyLoad>

        <LazyLoad height={DEFAULT_LAZY_LOAD_HEIGHT}>
          <AboutJustin width={width} />
        </LazyLoad>

        <LazyLoad height={DEFAULT_LAZY_LOAD_HEIGHT}>
          <SupportYourLearningJourney width={width} />
        </LazyLoad>
      </div>

      <LazyLoad height={DEFAULT_LAZY_LOAD_HEIGHT}>
        <div className="bottom-swoosh">
          <Swoosh imgUrl={defaultHeaderImages} additionalHeight={70}>
            <div className="bottom-swoosh-content container">
              <h2>ready to learn?</h2>
              <a href="/users/sign_up" className="button button--primary">
                join justin guitar for free!
              </a>
            </div>
          </Swoosh>
        </div>
      </LazyLoad>
    </div>
  );
};

export default withBootstrapSize(Home);
