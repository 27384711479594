import { createSelector } from "@reduxjs/toolkit";
import { NOTES } from "../sheetmusic/Formatter";

export const songSelector = (state) => state.entity.song;
export const userSelector = (state) => state.session.currentUser;
export const sheetMusicSelector = (state) => state.entity.sheetMusic;
const transpositionChange = (state) => state.entity.transpositionChange;

export const gradeNumber = createSelector(songSelector, (songSelector) => {
  const grade = songSelector.included.filter((i) => i.type === "grade")[0];
  return grade ? grade.attributes.position : null;
});

export const artistName = createSelector(songSelector, (song) => {
  // moving over from orgiginal_artist column to associated artist model
  const includedArtist = song.included.filter((i) => i.type === "artist")[0];
  if (includedArtist) {
    return includedArtist.attributes.name;
  } else {
    return song.data.attributes.originalArtist;
  }
});

export const otherSongsByArtist = createSelector(songSelector, (song) => {
  return [...song.data.attributes.songsBySameArtist].sort((a, b) =>
    a.title < b.title ? -1 : a.title > b.title ? 1 : 0
  );
});

export const chords = createSelector(songSelector, (song) => {
  return song.included
    .filter((i) => i.type === "chord")
    .sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0));
});

export const instruments = createSelector(songSelector, (song) => {
  return song.included.filter((i) => i.type === "instrument");
});

export const userHasSongsSubscription = createSelector(userSelector, (userSelector) => {
  return userSelector && userSelector.data.attributes.hasActiveTabsSubscription === true;
});

export const orderedTabImages = createSelector(sheetMusicSelector, (sheetMusicSelector) => {
  return (
    sheetMusicSelector &&
    sheetMusicSelector.included
      .filter((s) => s.type === "songSheetImage")
      .sort((a, b) =>
        a.attributes.position < b.attributes.position
          ? -1
          : a.attributes.position > b.attributes.position
          ? 1
          : 0
      )
  );
});

export const hasChords = createSelector(sheetMusicSelector, (sheetMusicSelector) => {
  return (
    sheetMusicSelector &&
    sheetMusicSelector.data.attributes.content &&
    sheetMusicSelector.data.attributes.content.length > 0
  );
});

export const currentKey = createSelector(
  sheetMusicSelector,
  transpositionChange,
  (sheetMusicSelector, transpositionChange) => {
    if (sheetMusicSelector) {
      const songKey = sheetMusicSelector.data.attributes.defaultKey;
      let nextIndex = NOTES.indexOf(songKey) + transpositionChange;
      if (nextIndex >= NOTES.length) {
        nextIndex = nextIndex - NOTES.length;
      } else if (nextIndex < 0) {
        nextIndex = NOTES.length + nextIndex;
      }

      return NOTES[nextIndex];
    }
  }
);

export const note = createSelector(userSelector, songSelector, (userSelector, songSelector) => {
  if (userSelector) {
    const songId = songSelector.data.attributes.id;
    return userSelector.included.filter(
      (i) =>
        i.type === "userNote" &&
        i.attributes.noteableType === "Song" &&
        i.attributes.noteableId === songId
    )[0];
  }
});
