import React from "react";
import { CourseIcon, PlayCircleIcon } from "./Icons";

// List items in array of objects
const lessons = [
  {
    href: "/guitar-lessons/bars-strumming-on-1-b1-111",
    title: "Bars & Strumming On 1",
    Icon: PlayCircleIcon,
  },
  {
    href: "/guitar-lessons/how-to-strum-the-correct-strings-b1-112",
    title: "How To Strum The Correct Strings",
    Icon: PlayCircleIcon,
  },
  {
    href: "/guitar-lessons/strumming-on-the-beat-b1-205",
    title: "Strumming On The Beat",
    Icon: PlayCircleIcon,
  },
  { href: "/guitar-lessons/counting-ands-b1-305", title: "Counting Ands", Icon: PlayCircleIcon },
  {
    href: "/guitar-lessons/all-about-up-strums-b1-306",
    title: "All About Up Strums",
    Icon: PlayCircleIcon,
  },
  {
    href: "/guitar-lessons/strumming-patterns-with-ups-b1-307",
    title: "Strumming Patterns With Ups",
    Icon: PlayCircleIcon,
  },
  {
    href: "/guitar-lessons/the-strumming-pattern-b1-404",
    title: "THE Strumming Pattern",
    Icon: PlayCircleIcon,
  },
  {
    href: "/guitar-lessons/guitar-strumming-tips-b1-502",
    title: "Guitar Strumming Tips",
    Icon: PlayCircleIcon,
  },
  {
    href: "/guitar-lessons/exploring-strumming-b1-503",
    title: "Exploring Strumming",
    Icon: PlayCircleIcon,
  },
  {
    href: "/guitar-lessons/about-time-signatures-b1-603",
    title: "About Time Signatures",
    Icon: PlayCircleIcon,
  },
  {
    href: "/guitar-lessons/a-6-8-strumming-pattern-b1-604",
    title: "A 6:8 Strumming Pattern",
    Icon: PlayCircleIcon,
  },
  {
    href: "/guitar-lessons/feel-good-strumming-b1-705",
    title: "Feel Good Strumming",
    Icon: PlayCircleIcon,
  },
  {
    href: "/guitar-lessons/dynamic-improvements-b1-706",
    title: "Dynamic Improvements",
    Icon: PlayCircleIcon,
  },
  {
    href: "/classes/beginner-guitar-course-grade-one",
    title: "View All Beginner Course",
    Icon: CourseIcon,
  },
];

function Lessons({ width }) {
  const splitIndex = Math.ceil(lessons.length / 2);
  const firstHalfLessons = lessons.slice(0, splitIndex);
  const secondHalfLessons = lessons.slice(splitIndex);

  return (
    <div className="strumming-machine-lessons">
      <p>
        💡 Get my in-depth{" "}
        <a href="/store/strumming-sos" target="_blank">
          Strumming SOS Course
        </a>{" "}
        and become a confident rhythm guitar player!
      </p>
      <div className="lessons">
        <div
          className="strumming-sos"
          onClick={() => window.open("/store/strumming-sos", "_blank")}
        >
          <div className="badges">
            <div className="badge g1">grade 1</div>
            <div className="badge g2">grade 2</div>
            <div className="badge g3">grade 3</div>
          </div>
          <div className="other">
            <p className="title">Strumming SOS Course</p>
            <p className="price">
              From <span className="sum">$39.99</span>{" "}
              <span className="small">(30-day money back guarantee)</span>
            </p>
          </div>
        </div>
        <div className="list">
          <p>
            Or get started for <span>free</span> with strumming lessons from my Beginner Guitar
            Course:
          </p>
          <div>
            {width > 1200 ? (
              <>
                <ul>
                  {firstHalfLessons.map((lesson) => (
                    <li key={lesson.href}>
                      <a href={lesson.href} target="_blank">
                        <lesson.Icon />
                        {lesson.title}
                      </a>
                    </li>
                  ))}
                </ul>
                <ul>
                  {secondHalfLessons.map((lesson) => (
                    <li key={lesson.href}>
                      <a href={lesson.href} target="_blank">
                        <lesson.Icon />
                        {lesson.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <ul>
                {lessons.map((lesson) => (
                  <li key={lesson.href}>
                    <a href={lesson.href} target="_blank">
                      <lesson.Icon />
                      {lesson.title}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Lessons;
