import React, { useEffect, useRef, useState } from "react";
import LessonIcon from "./LessonIcon";
import Loading from "../../components/shared/Loading";
import LessonFavorite from "./LessonFavorite";
import QuizLink from "./QuizLink";
import Dialog from "../../components/shared/ui/Dialog";

const LessonsList = ({
  currentLesson,
  currentUser,
  lessonListHeight,
  loadingUserItems,
  completedLessonIds,
  savedLessonIds,
  toggleFavorite,
  toggleCompleted,
  quizAttempt,
  toggleLoginModal,
}) => {
  const containerRef = useRef(null);
  const currentItemRef = useRef(null);
  const lastItemRef = useRef(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  const { lessonsWithinModule, quiz } = currentLesson.data.attributes;

  useEffect(() => {
    scrollToCurrentItem();
  }, []);

  useEffect(() => {
    if (typeof IntersectionObserver != "undefined" && lastItemRef?.current) {
      function scrollCallback(entries) {
        if (entries[0].isIntersecting) {
          setHasOverflow(false);
        } else if (!hasOverflow) {
          setHasOverflow(true);
        }
      }

      const observer = new IntersectionObserver(scrollCallback, {
        threshold: 1,
        root: containerRef.current,
      });
      observer.observe(lastItemRef.current);
    }
  }, [lastItemRef?.current, loadingUserItems]);

  useEffect(() => {
    if (containerRef?.current?.clientHeight > lessonListHeight) {
      setHasOverflow(true);
    }
  }, [lessonListHeight]);

  function scrollToCurrentItem() {
    if (!currentItemRef.current) return;
    // -30 in order to show the previous item too
    let toScroll = currentItemRef.current.offsetTop - 30;
    setTimeout(() => {
      containerRef.current.scroll({
        top: toScroll,
        behavior: "smooth",
      });
    }, 200);
  }

  function goToLesson(slug) {
    window.location.href = `/guitar-lessons/${slug}`;
  }

  const lastItem = lessonsWithinModule.slice(-1)[0];
  const currentLessonId = parseInt(currentLesson.data.id);

  return (
    <div>
      <div
        className={"lesson-list " + (hasOverflow ? "lesson-list--with-overflow" : "")}
        ref={containerRef}
        style={{
          maxHeight: lessonListHeight || "auto",
          overflowY: "auto",
          position: "relative",
        }}
      >
        <div className="lesson-list__container">
          {loadingUserItems ? (
            <Loading />
          ) : (
            <>
              {lessonsWithinModule.map((lesson, idx) => {
                const isActive = currentLessonId === lesson.id;
                return (
                  <div
                    className={"clickable lesson__list-item " + (isActive ? "active" : "")}
                    key={idx}
                    ref={lastItem === lesson ? lastItemRef : isActive ? currentItemRef : null}
                  >
                    <LessonIcon
                      activeLesson={currentLesson}
                      lessonInList={lesson}
                      currentUser={currentUser}
                      savedLessonIds={savedLessonIds}
                      completedLessonIds={completedLessonIds}
                      toggleCompleted={toggleCompleted}
                    />

                    <span className="list-item-title" onClick={() => goToLesson(lesson.slug)}>
                      {lesson.title}
                    </span>

                    <LessonFavorite
                      currentUser={currentUser}
                      lesson={lesson}
                      savedLessonIds={savedLessonIds}
                      toggleFavorite={toggleFavorite}
                    />

                    {lesson.youtubeDuration && (
                      <span className="list-item-duration">{lesson.youtubeDuration}</span>
                    )}
                  </div>
                );
              })}

              <Dialog onConfirm={() => (window.location.href = "/store/practical-music-theory")} />
            </>
          )}
          <QuizLink
            quiz={quiz}
            quizAttempt={quizAttempt}
            toggleLoginModal={toggleLoginModal}
            currentUser={currentUser}
          />
        </div>
      </div>
    </div>
  );
};

export default LessonsList;
