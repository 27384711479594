import React from "react";
import LoginPrompt from "../../components/auth/LoginPrompt";
import { PAGES } from ".";

const HowToApply = ({ currentUser }) => {
  const showApplicationForm = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", PAGES.application);
    window.location.search = searchParams.toString();
  };

  return (
    <div className="section how-to-apply">
      <h2 className="title">How to apply</h2>
      <div className="content">
        <div className="bg"></div>
        <div className="description">
          <div className="text">
            <h3>Eligibility</h3>
            <p>
              To apply for a free guitar, start by filling out our form. We won't require any proof
              of financial need, but we want to hear your story and why playing the guitar is
              important to you (or means a lot to you). Please note that applicants must be at least
              18 years old.
            </p>
            <p>
              Once we have assessed your application, our team will contact you to gather more
              details and ensure you're the best fit for the guitar. When selected, we'll arrange
              your shipping information and delivery details. If you want to learn more, please{" "}
              <a href="/playin-it-forward#faq">read our FAQ</a>
            </p>
            <p>
              <strong>
                🚨 JustinGuitar Playin' It Forward relies on trust. If you can afford to buy a
                guitar or already have one, please do not apply.
              </strong>
            </p>

            {currentUser ? (
              <button className="button button--primary" onClick={showApplicationForm}>
                Apply for a free guitar
              </button>
            ) : (
              <div className="login-prompt">
                <LoginPrompt
                  loginParagraph="You must be logged in to apply. Not a member yet? Register, it’s free."
                  token={ReactOnRails.authenticityToken()}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToApply;
