import React, { useEffect, useState } from "react";
import PracticeItemToSave from "../practice/PracticeItemToSave";
import LoginPrompt from "../../../auth/LoginPrompt";
import Loading from "../../Loading";
import axiosWithCSRF from "../../axiosWithCSRF";
import PracticeRoutineToSaveNew from "./PracticeRoutineToSaveNew";
import { openAlert } from "../../ui/uiSlice";
import { connect } from "react-redux";

const PracticeItemAlreadySaved = ({ item }) => {
  return (
    <div>
      <h5 className="header5 uppercase bold">{item.attributes.title}</h5>
      <p>{item.attributes.description}</p>
      <button className="button disabled" disabled={true}>
        saved this practice item
      </button>
    </div>
  );
};

const PracticeTabNew = ({ currentUser, object, objectType }) => {
  const [loading, setLoading] = useState(false);
  const [practiceItems, setPracticeItems] = useState(null);
  const [practiceRoutines, setPracticeRoutines] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [savedItemIds, setSavedItemIds] = useState([]);

  useEffect(() => {
    if (!currentUser) return;

    fetchPracticeItems();
    fetchUserPracticeItems();
  }, []);

  function fetchPracticeItems() {
    setLoading(true);
    axiosWithCSRF()
      .get(
        `/practice_items_and_routines/by_relatable?relatable_id=${object.data.id}&relatable_type=${objectType}`
      )
      .then((response) => {
        setPracticeItems(response.data.practiceItems);
        setPracticeRoutines(response.data.practiceRoutines);
        setLoading(false);
      });
  }

  function fetchUserPracticeItems() {
    const relatedPracticeItems = object.data?.relationships?.practiceItems?.data;

    if (!relatedPracticeItems || relatedPracticeItems.length === 0) return;
    const itemIds = relatedPracticeItems.map((item) => item.id).join(",");

    axiosWithCSRF()
      .get(`/user_practice_items/by_practice_items?practice_item_ids=${itemIds}`)
      .then((response) => {
        const savedIds = response.data.map((item) => item.attributes.practiceItemId);
        setSavedItemIds(savedIds);
      });
  }

  function saveUserPracticeItem(params) {
    const { practiceItemId, addToActiveRoutine, relatedLink } = params;
    setLoading(true);
    axiosWithCSRF()
      .post(`/user_practice_items/from_admin_item`, {
        practice_item_id: practiceItemId,
        add_to_active_routine: addToActiveRoutine,
        related_link: relatedLink,
      })
      .then(() => {
        setSavedItemIds([...savedItemIds, parseInt(practiceItemId)]);
        openAlert({ message: "Practice item saved!", severity: "success" });
      })
      .catch(() => {
        openAlert({ message: "There was a problem saving this practice item", severity: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (!currentUser) {
    return <LoginPrompt loginAction={"save practice items."} />;
  }

  return (
    <div>
      <div className="practice-items">
        {loading && <Loading isContent={true} />}
        {practiceItems &&
          practiceItems.data.map((item) => {
            return savedItemIds.includes(parseInt(item.id)) ? (
              <PracticeItemAlreadySaved key={item.id} item={item} />
            ) : (
              <div style={{ position: "relative" }} key={item.id}>
                <PracticeItemToSave
                  selectedItem={selectedItem}
                  item={item}
                  relatedLink={`https://www.justinguitar.com/${window.location.pathname}`}
                  addItem={saveUserPracticeItem}
                  setSelectedItem={setSelectedItem}
                  loading={loading}
                />
              </div>
            );
          })}

        {practiceRoutines &&
          practiceRoutines.data.map((routine, idx) => {
            return (
              <PracticeRoutineToSaveNew
                key={idx}
                loading={loading}
                setLoading={setLoading}
                routine={routine}
              />
            );
          })}
      </div>
    </div>
  );
};

export default connect(null, { openAlert })(PracticeTabNew);
