import React from "react";

export function breadcrumbTitleFormatter(title) {
  if (title.length > 15) {
    return `${title.slice(0, 12)}...`;
  } else {
    return title;
  }
}

export const PARENT_GROUP_BADGES = {
  BG1: "grade 1",
  BG2: "grade 2",
  BG3: "grade 3",
  IF4: "grade 4",
  IF5: "grade 5",
  INM: "grade 6",
  ADV: "grade 7",
  PMT: "practical music theory",
  GTR: "gear",
  KNO: "knowledge",
  SCA: "scales",
  TEC: "technique",
};

export function parentGroupBadge(reference, title, slug) {
  const abrreviatedTitle = PARENT_GROUP_BADGES[reference];
  return (
    <a
      className={
        abrreviatedTitle && abrreviatedTitle.includes("grade")
          ? `badge grade-badge grade-badge--${abrreviatedTitle.replace(" ", "-")}`
          : "badge"
      }
      href={`/${slug.toLowerCase() === "playground" ? "playground" : `classes/${slug}`}`}
    >
      <span>{abrreviatedTitle || breadcrumbTitleFormatter(title)}</span>
    </a>
  );
}
