import React from "react";
import { PriceTagIcon } from "./Icons";

const Product = ({
  product,
  buyProduct,
  basePrice,
  discountPrice,
  products,
  currentUser = null,
}) => {
  const showPriceTag = () => {
    if (!product.reference) {
      return false;
    }

    if (!product.hasPurchased) {
      if (!products[0].hasPurchased && !products[1].hasPurchased) {
        if (product.reference == "JustinGuitarStrumSOS1") {
          return false;
        } else if (product.reference == "JustinGuitarStrumSOS2") {
          return true;
        } else if (product.reference == "JustinGuitarStrumSOS3") {
          return true;
        }
      }

      return true;
    }

    return false;
  };

  return (
    <div className="product">
      <div className="background" style={{ backgroundImage: `url(${product.image})` }}></div>
      <h3>{product.title}</h3>
      <p className={`grade grade-${product.grade}`}>{`Grade ${product.grade}`}</p>
      {!product.hasPurchased && (
        <div className="price-container">
          <p className="price">
            {product.hasDiscount && <span className="discount-price">{basePrice}</span>}
            {product.price}
          </p>
        </div>
      )}
      {!product.reference ? (
        <button className="button button--primary" disabled>
          module coming soon
        </button>
      ) : !product.hasPurchased ? (
        <button className="button button--primary" onClick={() => buyProduct(product)}>
          buy this module
        </button>
      ) : (
        <button
          className="button button--blue"
          onClick={() => (window.location.href = product.url)}
        >
          view module
        </button>
      )}
      {showPriceTag() && (
        <div className="price-tag">
          <p className="tag-text">
            <PriceTagIcon />
            {`USD ${(basePrice - discountPrice).toFixed(0)} off`}
          </p>
        </div>
      )}
    </div>
  );
};

export default Product;
