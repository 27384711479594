export enum BootstrapSize {
  xsDevice = "xsDevice",
  smDevice = "smDevice",
  mdDevice = "mdDevice",
  lgDevice = "lgDevice",
  xlDevice = "xlDevice",
}

export const orderedBootstrapSizes: BootstrapSize[] = [
  BootstrapSize.xsDevice,
  BootstrapSize.smDevice,
  BootstrapSize.mdDevice,
  BootstrapSize.lgDevice,
  BootstrapSize.xlDevice,
];

export const breakpoints: { [key in BootstrapSize]: number } = {
  [BootstrapSize.xsDevice]: 0,
  [BootstrapSize.smDevice]: 576,
  [BootstrapSize.mdDevice]: 768,
  [BootstrapSize.lgDevice]: 992,
  [BootstrapSize.xlDevice]: 1200,
};
