import React, { useContext, useState } from "react";

import { LoginModal } from "../../../jg-material/auth/LoginModal";
import { ACTION_TYPES, useUser, useUserDispatch } from "../contexts/userContext";
import {
  deletePracticeItem,
  deleteSongItem,
  savePracticeItem,
  saveSongItem,
} from "../contexts/userActions";
import Loading from "../../shared/Loading";
import { SnackbarContext } from "../SongsIndexContainer";
import { GENERIC_ERROR } from "../consts";

export function savedSongId(user, song) {
  return user?.savedSongItems?.find((userItem) => userItem.attributes.itemableId === song.id)?.id;
}

export function savedPracticeItemId(user, song) {
  return user?.userPracticeItems?.find(
    (userPracticeItem) => userPracticeItem.attributes.songId === song.id
  )?.id;
}

function SongActionsDrawer({ song, hasApp }) {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useUserDispatch();
  const { setSnackbar } = useContext(SnackbarContext);

  const user = useUser();

  const songIsSaved = savedSongId(user, song);
  const practiceItemIsSaved = savedPracticeItemId(user, song);

  const handleSaveButtonClick = () => {
    if (!user) {
      setShowLoginModal(true);
      return;
    }

    setLoading(true);

    if (songIsSaved) {
      deleteSongItem(songIsSaved)
        .then(() => {
          dispatch({
            type: ACTION_TYPES.DELETE_SONG_ITEM,
            userSongItemId: songIsSaved,
          });
          setSnackbar({ message: "Removed song!", severity: "success" });
        })
        .catch(() => {
          setSnackbar({ message: GENERIC_ERROR, severity: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      saveSongItem(song.id)
        .then((response) => {
          dispatch({
            type: ACTION_TYPES.SAVE_SONG_ITEM,
            userSongItem: response.data.userItem.data,
          });
          setSnackbar({ message: "Saved song!", severity: "success" });
        })
        .catch(() => {
          setSnackbar({ message: GENERIC_ERROR, severity: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handlePracticeAssistantClick = () => {
    if (!user) {
      setShowLoginModal(true);
      return;
    }

    setLoading(true);

    if (practiceItemIsSaved) {
      deletePracticeItem(practiceItemIsSaved)
        .then(() => {
          dispatch({
            type: ACTION_TYPES.DELETE_PRACTICE_ITEM,
            userPracticeItemId: practiceItemIsSaved,
          });
          setSnackbar({ message: "Removed practice item!", severity: "success" });
        })
        .catch(() => {
          setSnackbar({ message: GENERIC_ERROR, severity: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      savePracticeItem(song.id)
        .then((response) => {
          dispatch({
            type: ACTION_TYPES.SAVE_PRACTICE_ITEM,
            userPracticeItem: response.data.userPracticeItem.data,
          });
          setSnackbar({ message: "Saved practice item!", severity: "success" });
        })
        .catch(() => {
          setSnackbar({ message: GENERIC_ERROR, severity: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const goToBook = (e) => {
    e.preventDefault();
  };

  const goToApp = () => {
    window.location.href = "https://justinguitarapp.onelink.me/3065804798/fc941697";
  };

  const book = null;

  return (
    <div className="song__actions">
      {loading ? (
        <Loading isContent />
      ) : (
        <>
          <div className="song__actions--action clickable" onClick={handleSaveButtonClick}>
            <i className={`icon icon-heart${songIsSaved ? "" : "-outline"}`} />
            {songIsSaved ? "remove" : "save"}
          </div>

          <div className="song__actions--action clickable" onClick={handlePracticeAssistantClick}>
            <i className="icon icon-playlist" />
            <div style={{ display: "inline" }}>
              {practiceItemIsSaved ? "remove from practice assistant" : "practice assistant"}
            </div>
          </div>
          <div
            className="song__actions--action-group"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            {hasApp && (
              <span onClick={goToApp} className="in-my-app">
                <i className="icon icon-iphone" />
                <span>Play Along With My App!</span>
              </span>
            )}
          </div>
        </>
      )}

      <LoginModal isOpen={showLoginModal} handleClose={() => setShowLoginModal(false)} />
    </div>
  );
}

export default SongActionsDrawer;
