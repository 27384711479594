import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LessonTile from "../shared/LessonTile";
import SongTile from "../shared/SongTile";
import { goToLesson } from "../shared/utils";
import { deleteAllRecentlyViewed } from "../../components/shared/session/sessionEffects";
import Dialog from "../../components/shared/ui/Dialog";
import { openDialog } from "../../components/shared/ui/uiSlice";

const ContinueLearningList = ({ recentPageviews }) => {
  const items = recentPageviews.slice(-6);
  if (items.length > 0) {
    return items
      .sort((a, b) => (a.data.attributes.createdAt > b.data.attributes.createdAt ? -1 : 1))
      .map((i) => {
        const { title, gradeId, groupTitle, artistName, albumThumb, slug } = i.data.attributes;
        return (
          <div
            key={i.data.id}
            className="clickable continue-learning__item"
            onClick={() => goToLesson(i.data.attributes.viewableType, slug)}
          >
            {i.data.attributes.viewableType === "Lesson" ? (
              <LessonTile title={title} gradeId={gradeId} groupTitle={groupTitle} />
            ) : (
              <SongTile
                title={title}
                gradeId={gradeId}
                artistName={artistName}
                albumThumb={albumThumb}
              />
            )}
          </div>
        );
      });
  } else {
    return (
      <span className="no-items">
        You don't have any recently viewed lessons or songs yet. This section will automatically
        display your most recently viewed lessons and songs.
      </span>
    );
  }
};

const ContinueLearning = ({ user, recentPageviews, deleteAllRecentlyViewed, openDialog }) => {
  const [activeLessonType, setActiveLessonType] = React.useState("Lesson");
  return (
    <div className="continue-learning">
      <p className="welcome__heading welcome__heading--white">Resume</p>
      <div className="continue-learning__list">
        <ContinueLearningList recentPageviews={recentPageviews} />
      </div>
      <Dialog onConfirm={() => deleteAllRecentlyViewed({ userId: user.data.id })} />
    </div>
  );
};

const mapStateToProps = (ownProps) => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  deleteAllRecentlyViewed: (userId) => dispatch(deleteAllRecentlyViewed(userId)),
  openDialog: (data) => dispatch(openDialog(data)),
});

ContinueLearning.propTypes = {
  user: PropTypes.object.isRequired,
  recentPageviews: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContinueLearning);
