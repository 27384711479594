import React from "react";
import { CSSTransition } from "react-transition-group";

import { CDN_URL } from "../shared/CdnUrl";
import {
  searchResultsTypes,
  searchResultsTypeToSlugPrefix,
  storeSearchAndNavigateToItem,
} from "../shared/search";
import SongActionsDrawer from "./SongActionsDrawer";
import { withBootstrapSize } from "../shared/WithBootstrapSize";

export const SongSearchResultItem = ({ song }) => {
  const [isOpen, setOpen] = React.useState(false);
  const { title, artist, artistImageUid, slug, gradeId } = song;
  return (
    <>
      <div
        className="search-result-item search-result-item--song"
        onClick={() =>
          storeSearchAndNavigateToItem({
            title: title,
            slug: `${searchResultsTypeToSlugPrefix[searchResultsTypes.song]}/${slug}`,
          })
        }
      >
        <div className={`color-grade-${gradeId}`}>{gradeId}</div>
        <img src={`${CDN_URL}/system/dragonfly/production/${artistImageUid}`} alt={title} />
        <div className="artist-and-title">
          <div className="title">{title}</div>
          <div className="artist">{artist}</div>
        </div>

        <i
          className="icon icon-menu-dots-vertical"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!isOpen);
          }}
        />
      </div>

      <CSSTransition in={isOpen} className="song__actions" timeout={300}>
        <SongActionsDrawer song={song} />
      </CSSTransition>
    </>
  );
};

export default withBootstrapSize(SongSearchResultItem);
