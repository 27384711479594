import React from "react";
import PropTypes from "prop-types";
import LoginPrompt from "../components/auth/LoginPrompt";

const ProductButton = ({ currentUser, storeSlug }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "60px 0",
      }}
    >
      <button
        className="button button--primary"
        disabled={!currentUser}
        onClick={() => (window.location.href = `/store/${storeSlug}`)}
      >
        subscribe to unlock full course
      </button>

      {!currentUser && (
        <div style={{ marginTop: "40px" }}>
          <LoginPrompt loginAction="continue" token={ReactOnRails.authenticityToken()} />
        </div>
      )}
    </div>
  );
};

ProductButton.propTypes = {
  currentUser: PropTypes.object,
  storeSlug: PropTypes.string.isRequired,
};

export default ProductButton;
