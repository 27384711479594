import React from "react";

const LessonContentCard = (props) => (
  <div
    className={`card card--${props.typeOf} col-lg-12 col-md-12 col-sm-12 col-xs-12`}
    id={props.item.user_item_id}
  >
    <a
      className={props.item.belt ? `border-${props.item.belt}` : "border-None"}
      href={props.item.path}
    >
      <div className={`card-thumbnail card-thumbnail--${props.typeOf} card-thumbnail--tabbed`}>
        {props.item.thumbnail_source && <img src={props.item.thumbnail_source} />}
      </div>
      <div className={`card-content card-content--${props.typeOf}`}>
        <div className="card-text">
          <div className="card-title">
            <h5 className="card-title-header justin-blue uppercase no-margin">
              {props.item.title}
            </h5>
            <div className="card-description">
              <p className="pagragraph paragraph--card">{props.item.overview} </p>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
);

export default LessonContentCard;
