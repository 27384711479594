import React from "react";
import { CDN_URL } from "../../../components/shared/CdnUrl";
import { YouTubeIcon } from "./Icons";
import { VideoEmbed } from "../../../components/swoosh-templates";
import LiteYoutube from "../../../components/shared/LiteYoutube";
import ReactPlayer from "react-player";
import YoutubeVideo from "../../../components/shared/YoutubeVideo";

function Instructions({}) {
  return (
    <div className="strumming-machine-instructions">
      <p>Not sure how to use the Strumming Machine? Watch the video below:</p>
      {/*<div className="video">*/}
      {/*  <YouTubeIcon />*/}
      {/*</div>*/}
      {/*<VideoEmbed videoUrl="https://youtu.be/u6mm7zFRxKQ" hasMaxResImage={false} />*/}
      {/* <LiteYoutube url="https://youtu.be/u6mm7zFRxKQ" hasMaxResImage={false} />*/}
      {/*<YoutubeVideo videoId="u6mm7zFRxKQ" />*/}
      <div className="video">
        <ReactPlayer
          url="https://youtu.be/u6mm7zFRxKQ"
          controls={true}
          // width="100%"
          allowFullScreen={true}
          // height="100%"
          // ref={videoRef}
          // className="react-player"
        />
      </div>
      <div className="community">
        💬 Have questions or feedback? Join the{" "}
        <a
          href="https://community.justinguitar.com/t/new-free-tool-strumming-machine/217903"
          target="_blank"
        >
          Strumming Machine Discussion
        </a>{" "}
        on the Community or{" "}
        <a href="mailto:hello@justinguitar.com" target="_blank">
          contact my team
        </a>
        ! :)
      </div>
      <div className="donate">
        <img src={`${CDN_URL}/images/components/strumming-machine/heart.png`} alt="Heart" />
        <p>
          🎁 Strumming Machine is free thanks to your support! If you enjoy this tool and can afford
          to contribute, <a href="/donate">please make a donation</a>!
        </p>
      </div>
    </div>
  );
}

export default Instructions;
