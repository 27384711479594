import { SxProps, styled, useMediaQuery } from "@mui/material";
import React from "react";
import { IEbookItem } from "./ebooks.interface";
import { EbookItem } from "./EbookItem";
import { breakpoints } from "../../jg-material/theme/breakpoints";
import { SliderGeneric } from "../../components/shared/ui/SliderGeneric";

interface EbookItemGridProps extends React.HTMLAttributes<HTMLDivElement> {
  items: IEbookItem[];
  sectionButtonUrl?: string;
  sx?: SxProps;
}

export const EbookItemGrid: React.FC<EbookItemGridProps> = ({
  items,
  sectionButtonUrl,
  ...htmlProps
}) => {
  const isSMScreen = useMediaQuery(`(max-width:${breakpoints.values?.sm}px)`);
  const isMDScreen = useMediaQuery(`(min-width:${breakpoints.values?.md}px)`);
  const isLGScreen = useMediaQuery(`(min-width:${breakpoints.values?.lg}px)`);
  const isXLScreen = useMediaQuery(`(min-width:${breakpoints.values?.xl}px)`);
  if (!isXLScreen && !(isLGScreen && items?.length < 3)) {

    return (
      <div {...htmlProps}>
        <SliderGeneric
          {...{
            dots: items?.length < 10,
            infinite: true,
            slidesToShow: isLGScreen ? 2 : 1,
            slidesToScroll: 1,
            variableWidth: true,
            centerMode: true,
            items,
          }}
          renderItem={(ebook, idx) => (
            <SlickPadding>
              <EbookItem
                key={idx}
                {...ebook}
                subscribeUrl={sectionButtonUrl}
                sx={{ marginBottom: "30px" }}
              />
            </SlickPadding>
          )}
        />
      </div>
    );
  }

  return (
    <ItemWrapper {...htmlProps}>
      {items?.map((item, idx) => (
        <EbookItem
          {...item}
          subscribeUrl={sectionButtonUrl}
          key={idx}
          sx={{ margin: "10px" }}
        />
      ))}
    </ItemWrapper>
  );
};

const ItemWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "start",
  justifyContent: "center",
}));

const SlickPadding = styled('div')({
  paddingLeft: '10px',
  paddingRight: '10px',
  boxSizing: 'border-box',
})
