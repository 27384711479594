import React from "react";
import { CDN_URL } from "../../components/shared/CdnUrl";
import AlbumCarousel from "./AlbumCarousel";
import { compose } from "redux";
import { withHeroMargin, withInnerWidth } from "../../components/shared";

const albumData = [
  {
    title: "Eyedom",
    subtitle: "2015 • Album",
    imageSrc: `${CDN_URL}/redesign/about/wcas-album-1.png`,
  },
  {
    title: "Shattered Matter",
    subtitle: "2014 • Album",
    imageSrc: `${CDN_URL}/redesign/about/wcas-album-2.png`,
  },
  {
    title: "Recipe For Adventure",
    subtitle: "2013 • Album",
    imageSrc: `${CDN_URL}/redesign/about/wcas-album-3.png`,
  },
];

const albums = albumData.map((album, idx) => {
  return (
    <div className="about__body__content__albums__album" key={idx}>
      <img src={album["imageSrc"]} alt={`${album["title"]} - ${album["subtitle"]}`} />
      <h3>{album["title"]}</h3>
      <p>{album["subtitle"]}</p>
    </div>
  );
});

const albumTiles = () => {
  return albums;
};

const Albums = ({ width }) => (
  <div className="about__body__content__albums">
    {width > 768 ? albumTiles() : <AlbumCarousel albums={albumData} />}
  </div>
);

export default compose(withInnerWidth, withHeroMargin)(Albums);
