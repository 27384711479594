import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { closeDialog } from "./uiSlice";
import { Portal } from "@mui/material";

const Dialog = ({ onConfirm, dialog, closeDialog }) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const onPageClick = (e) => {
      if (!e.target.closest(".dialog")) {
        closeDialog();
      }
    };

    const cleanup = () => {
      document.body.removeEventListener("click", onPageClick, true);
    };

    if (dialog.visible) {
      document.body.addEventListener("click", onPageClick, true);
    } else {
      cleanup();
    }

    return cleanup;
  }, [dialog.visible]);

  useEffect(() => {
    if (dialog.input?.value) {
      setInputValue(dialog.input.value);
    } else {
      setInputValue(dialog.input && dialog.input.type == "password" ? "" : inputValue);
    }
  }, [dialog.visible]);

  const cancelAction = () => {
    closeDialog();
  };

  const confirmAction = () => {
    // from own props
    typeof onConfirm === "function" &&
      onConfirm(
        inputValue && isNullOrEmptyObject(dialog.data) ? inputValue : { ...dialog.data, inputValue }
      );
    // from store
    typeof dialog.onConfirm === "function" &&
      dialog.onConfirm(
        inputValue && isNullOrEmptyObject(dialog.data) ? inputValue : { ...dialog.data, inputValue }
      );
    closeDialog();
  };

  const isNullOrEmptyObject = (value) => {
    if (value === null) {
      return true;
    }

    if (typeof value === "object" && Object.keys(value).length === 0) {
      return true;
    }

    return false;
  };

  const cancelButtonText = dialog.cancelButtonText || "Cancel";
  const confirmButtonText = dialog.confirmButtonText || "Confirm";

  return dialog.visible ? (
    <Portal>
      <div className="dialog">
        <p dangerouslySetInnerHTML={{ __html: dialog.message }}></p>
        {dialog.secondaryMessage && (
          <p
            style={{ fontSize: "0.8em" }}
            dangerouslySetInnerHTML={{ __html: dialog.secondaryMessage }}
          ></p>
        )}
        {!dialog.input || (
          <input
            name="dialog_input"
            type={dialog.input.type}
            placeholder={dialog.input.placeholder}
            value={inputValue}
            onChange={(e) => {
              e.stopPropagation();
              setInputValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.code === "Space") {
                e.stopPropagation();
              }
            }}
            autoComplete={(dialog.input.type != "password").toString()}
          />
        )}
        <div>
          <button onClick={() => (!dialog.swapActions ? cancelAction() : confirmAction())}>
            {!dialog.swapActions ? cancelButtonText : confirmButtonText}
          </button>
          <button
            className="primary"
            onClick={() => (!dialog.swapActions ? confirmAction() : cancelAction())}
          >
            {!dialog.swapActions ? confirmButtonText : cancelButtonText}
          </button>
        </div>
      </div>
    </Portal>
  ) : null;
};

const mapStateToProps = (state, ownProps) => ({
  dialog: state.ui.dialog,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  closeDialog: () => dispatch(closeDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
