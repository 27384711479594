export function goToLesson(itemType, slug) {
  const prefix = itemType === "Lesson" ? "guitar-lessons" : "songs";
  window.location.href = `/${prefix}/${slug}`;
}

export function capitalize(str) {
  const lower = str.toLowerCase();

  return str.charAt(0).toUpperCase() + lower.slice(1);
}
