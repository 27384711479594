import React from "react";
import LoginModal from "../components/auth/LoginModal";

const ClickIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="path-1-outside-1"
        maskUnits="userSpaceOnUse"
        x="13.9437"
        y="0"
        width="3"
        height="11"
        fill="black"
      >
        <rect fill="white" x="13.9437" width="3" height="11" />
        <path d="M15.7315 10.6206C16.1684 10.6206 16.5193 10.2693 16.5193 9.83203V1.78857C16.5193 1.35127 16.1684 1 15.7315 1C15.2946 1 14.9437 1.35127 14.9437 1.78857V9.83203C14.9437 10.2657 15.2946 10.6206 15.7315 10.6206Z" />
      </mask>
      <path
        d="M15.7315 10.6206C16.1684 10.6206 16.5193 10.2693 16.5193 9.83203V1.78857C16.5193 1.35127 16.1684 1 15.7315 1C15.2946 1 14.9437 1.35127 14.9437 1.78857V9.83203C14.9437 10.2657 15.2946 10.6206 15.7315 10.6206Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M15.7315 10.6206C16.1684 10.6206 16.5193 10.2693 16.5193 9.83203V1.78857C16.5193 1.35127 16.1684 1 15.7315 1C15.2946 1 14.9437 1.35127 14.9437 1.78857V9.83203C14.9437 10.2657 15.2946 10.6206 15.7315 10.6206Z"
        stroke="white"
        strokeWidth="0.4"
        mask="url(#path-1-outside-1)"
      />
      <mask
        id="path-2-outside-2"
        maskUnits="userSpaceOnUse"
        x="0"
        y="13.9614"
        width="11"
        height="3"
        fill="black"
      >
        <rect fill="white" y="13.9614" width="11" height="3" />
        <path d="M10.6115 15.75C10.6115 15.3127 10.2606 14.9614 9.8237 14.9614H1.78783C1.35094 14.9614 1 15.3127 1 15.75C1 16.1873 1.35094 16.5386 1.78783 16.5386H9.8237C10.257 16.5386 10.6115 16.1837 10.6115 15.75Z" />
      </mask>
      <path
        d="M10.6115 15.75C10.6115 15.3127 10.2606 14.9614 9.8237 14.9614H1.78783C1.35094 14.9614 1 15.3127 1 15.75C1 16.1873 1.35094 16.5386 1.78783 16.5386H9.8237C10.257 16.5386 10.6115 16.1837 10.6115 15.75Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M10.6115 15.75C10.6115 15.3127 10.2606 14.9614 9.8237 14.9614H1.78783C1.35094 14.9614 1 15.3127 1 15.75C1 16.1873 1.35094 16.5386 1.78783 16.5386H9.8237C10.257 16.5386 10.6115 16.1837 10.6115 15.75Z"
        stroke="white"
        strokeWidth="0.4"
        mask="url(#path-2-outside-2)"
      />
      <mask
        id="path-3-outside-3"
        maskUnits="userSpaceOnUse"
        x="18.0387"
        y="4.0918"
        width="9"
        height="9"
        fill="black"
      >
        <rect fill="white" x="18.0387" y="4.0918" width="9" height="9" />
        <path d="M19.8247 12.3556C20.0253 12.3556 20.2294 12.2804 20.3834 12.1262L26.0665 6.43775C26.3745 6.12949 26.3745 5.63125 26.0665 5.32299C25.7585 5.01473 25.2608 5.01473 24.9528 5.32299L19.2697 11.0115C18.9617 11.3197 18.9617 11.818 19.2697 12.1262C19.4201 12.2804 19.6242 12.3556 19.8247 12.3556Z" />
      </mask>
      <path
        d="M19.8247 12.3556C20.0253 12.3556 20.2294 12.2804 20.3834 12.1262L26.0665 6.43775C26.3745 6.12949 26.3745 5.63125 26.0665 5.32299C25.7585 5.01473 25.2608 5.01473 24.9528 5.32299L19.2697 11.0115C18.9617 11.3197 18.9617 11.818 19.2697 12.1262C19.4201 12.2804 19.6242 12.3556 19.8247 12.3556Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M19.8247 12.3556C20.0253 12.3556 20.2294 12.2804 20.3834 12.1262L26.0665 6.43775C26.3745 6.12949 26.3745 5.63125 26.0665 5.32299C25.7585 5.01473 25.2608 5.01473 24.9528 5.32299L19.2697 11.0115C18.9617 11.3197 18.9617 11.818 19.2697 12.1262C19.4201 12.2804 19.6242 12.3556 19.8247 12.3556Z"
        stroke="white"
        strokeWidth="0.4"
        mask="url(#path-3-outside-3)"
      />
      <mask
        id="path-4-outside-4"
        maskUnits="userSpaceOnUse"
        x="4.0899"
        y="4.0918"
        width="9"
        height="9"
        fill="black"
      >
        <rect fill="white" x="4.0899" y="4.0918" width="9" height="9" />
        <path d="M6.43459 5.32299C6.12662 5.01473 5.62885 5.01473 5.32088 5.32299C5.01291 5.63125 5.01291 6.12949 5.32088 6.43775L11.004 12.1262C11.158 12.2804 11.3585 12.3556 11.5627 12.3556C11.7632 12.3556 11.9673 12.2804 12.1213 12.1262C12.4293 11.818 12.4293 11.3197 12.1213 11.0115L6.43459 5.32299Z" />
      </mask>
      <path
        d="M6.43459 5.32299C6.12662 5.01473 5.62885 5.01473 5.32088 5.32299C5.01291 5.63125 5.01291 6.12949 5.32088 6.43775L11.004 12.1262C11.158 12.2804 11.3585 12.3556 11.5627 12.3556C11.7632 12.3556 11.9673 12.2804 12.1213 12.1262C12.4293 11.818 12.4293 11.3197 12.1213 11.0115L6.43459 5.32299Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M6.43459 5.32299C6.12662 5.01473 5.62885 5.01473 5.32088 5.32299C5.01291 5.63125 5.01291 6.12949 5.32088 6.43775L11.004 12.1262C11.158 12.2804 11.3585 12.3556 11.5627 12.3556C11.7632 12.3556 11.9673 12.2804 12.1213 12.1262C12.4293 11.818 12.4293 11.3197 12.1213 11.0115L6.43459 5.32299Z"
        stroke="white"
        strokeWidth="0.4"
        mask="url(#path-4-outside-4)"
      />
      <mask
        id="path-5-outside-5"
        maskUnits="userSpaceOnUse"
        x="4.0899"
        y="18.0205"
        width="9"
        height="9"
        fill="black"
      >
        <rect fill="white" x="4.0899" y="18.0205" width="9" height="9" />
        <path d="M11.004 19.2522L5.32088 24.9371C5.01291 25.2453 5.01291 25.7436 5.32088 26.0518C5.47487 26.2059 5.67541 26.2812 5.87953 26.2812C6.08006 26.2812 6.28418 26.2059 6.43817 26.0518L12.1213 20.3633C12.4293 20.0551 12.4293 19.5568 12.1213 19.2486C11.8097 18.9439 11.312 18.9439 11.004 19.2522Z" />
      </mask>
      <path
        d="M11.004 19.2522L5.32088 24.9371C5.01291 25.2453 5.01291 25.7436 5.32088 26.0518C5.47487 26.2059 5.67541 26.2812 5.87953 26.2812C6.08006 26.2812 6.28418 26.2059 6.43817 26.0518L12.1213 20.3633C12.4293 20.0551 12.4293 19.5568 12.1213 19.2486C11.8097 18.9439 11.312 18.9439 11.004 19.2522Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M11.004 19.2522L5.32088 24.9371C5.01291 25.2453 5.01291 25.7436 5.32088 26.0518C5.47487 26.2059 5.67541 26.2812 5.87953 26.2812C6.08006 26.2812 6.28418 26.2059 6.43817 26.0518L12.1213 20.3633C12.4293 20.0551 12.4293 19.5568 12.1213 19.2486C11.8097 18.9439 11.312 18.9439 11.004 19.2522Z"
        stroke="white"
        strokeWidth="0.4"
        mask="url(#path-5-outside-5)"
      />
      <mask
        id="path-6-outside-6"
        maskUnits="userSpaceOnUse"
        x="13.9641"
        y="13.9404"
        width="26"
        height="26"
        fill="black"
      >
        <rect fill="white" x="13.9641" y="13.9404" width="26" height="26" />
        <path d="M38.7724 32.5322L31.485 25.2415L36.7491 21.994C37.0177 21.8291 37.1609 21.5208 37.1144 21.209C37.0678 20.8971 36.8458 20.6426 36.5414 20.5602L15.9575 14.9685C15.6854 14.8932 15.3953 14.9721 15.1948 15.1728C14.9942 15.3735 14.919 15.6639 14.9906 15.9363L20.5807 36.5432C20.663 36.8478 20.9173 37.0737 21.2288 37.1167C21.5404 37.1633 21.8483 37.0163 22.0131 36.7511L25.2575 31.482L32.5414 38.7727C32.6954 38.9268 32.8959 39.0021 33.1 39.0021C33.3041 39.0021 33.5047 38.9268 33.6587 38.7727L38.776 33.6505C39.0768 33.3387 39.0768 32.8404 38.7724 32.5322ZM33.0964 37.0987L25.6622 29.6575C25.5153 29.5105 25.3112 29.4281 25.1035 29.4281C25.0713 29.4281 25.0426 29.4281 25.0104 29.4317C24.7705 29.4603 24.5592 29.5965 24.4303 29.8009L21.6227 34.371L16.8707 16.8503L34.3749 21.6069L29.809 24.4206C29.6049 24.5461 29.4688 24.7611 29.4402 25.0013C29.4115 25.2415 29.4939 25.4816 29.6658 25.6501L37.1 33.0914L33.0964 37.0987Z" />
      </mask>
      <path
        d="M38.7724 32.5322L31.485 25.2415L36.7491 21.994C37.0177 21.8291 37.1609 21.5208 37.1144 21.209C37.0678 20.8971 36.8458 20.6426 36.5414 20.5602L15.9575 14.9685C15.6854 14.8932 15.3953 14.9721 15.1948 15.1728C14.9942 15.3735 14.919 15.6639 14.9906 15.9363L20.5807 36.5432C20.663 36.8478 20.9173 37.0737 21.2288 37.1167C21.5404 37.1633 21.8483 37.0163 22.0131 36.7511L25.2575 31.482L32.5414 38.7727C32.6954 38.9268 32.8959 39.0021 33.1 39.0021C33.3041 39.0021 33.5047 38.9268 33.6587 38.7727L38.776 33.6505C39.0768 33.3387 39.0768 32.8404 38.7724 32.5322ZM33.0964 37.0987L25.6622 29.6575C25.5153 29.5105 25.3112 29.4281 25.1035 29.4281C25.0713 29.4281 25.0426 29.4281 25.0104 29.4317C24.7705 29.4603 24.5592 29.5965 24.4303 29.8009L21.6227 34.371L16.8707 16.8503L34.3749 21.6069L29.809 24.4206C29.6049 24.5461 29.4688 24.7611 29.4402 25.0013C29.4115 25.2415 29.4939 25.4816 29.6658 25.6501L37.1 33.0914L33.0964 37.0987Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M38.7724 32.5322L31.485 25.2415L36.7491 21.994C37.0177 21.8291 37.1609 21.5208 37.1144 21.209C37.0678 20.8971 36.8458 20.6426 36.5414 20.5602L15.9575 14.9685C15.6854 14.8932 15.3953 14.9721 15.1948 15.1728C14.9942 15.3735 14.919 15.6639 14.9906 15.9363L20.5807 36.5432C20.663 36.8478 20.9173 37.0737 21.2288 37.1167C21.5404 37.1633 21.8483 37.0163 22.0131 36.7511L25.2575 31.482L32.5414 38.7727C32.6954 38.9268 32.8959 39.0021 33.1 39.0021C33.3041 39.0021 33.5047 38.9268 33.6587 38.7727L38.776 33.6505C39.0768 33.3387 39.0768 32.8404 38.7724 32.5322ZM33.0964 37.0987L25.6622 29.6575C25.5153 29.5105 25.3112 29.4281 25.1035 29.4281C25.0713 29.4281 25.0426 29.4281 25.0104 29.4317C24.7705 29.4603 24.5592 29.5965 24.4303 29.8009L21.6227 34.371L16.8707 16.8503L34.3749 21.6069L29.809 24.4206C29.6049 24.5461 29.4688 24.7611 29.4402 25.0013C29.4115 25.2415 29.4939 25.4816 29.6658 25.6501L37.1 33.0914L33.0964 37.0987Z"
        stroke="white"
        strokeWidth="0.4"
        mask="url(#path-6-outside-6)"
      />
    </svg>
  );
};

class SongLoginPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginOpen: false,
    };
  }
  render() {
    return (
      <div className="song-prompt">
        <ClickIcon />
        <span className="song-prompt__primary">Log In</span>
        <span className="song-prompt__secondary">for the best experience.</span>
        <p className="song-prompt__p">
          I recommend logging in so you can save this song and add it to your practice routine.
        </p>
        <div>
          <a href="/users/sign_up" className="button button--white button--inline">
            Register
          </a>
          <a
            onClick={() => this.setState({ loginOpen: !this.state.loginOpen })}
            className="button button--blue button--inline"
          >
            Log In
          </a>
        </div>
        <LoginModal
          token={this.props.token}
          isOpen={this.state.loginOpen}
          toggleModal={() => this.setState({ loginOpen: !this.state.loginOpen })}
        />
      </div>
    );
  }
}

export default SongLoginPrompt;
