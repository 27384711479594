import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  ChipProps,
  Grid,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import { ArrowCircleRightRounded } from "@mui/icons-material";

import { fontWeights } from "../../../jg-material/theme/typography";
import { jgDenimColors } from "../../../jg-material/theme/jg-denim-colors";
import { dateAsShortMonthNumericYear, getHeaderImage, shortLocation } from "../shared/helpers";
import { WorkshopEntity } from "../interfaces";

export interface WorkshopCardProps {
  workshop: WorkshopEntity;
}

const cardShadow = "0px 0px 14px 0px #44546C40"; // TODO: figure out how to get this in to the MUI theme

export const WorkshopCard = ({ workshop }: WorkshopCardProps) => {
  const arrivalDate = new Date(workshop.attributes.arrivalTime);
  const isExpired = arrivalDate.getTime() - Date.now() < 0;

  return (
    <Card
      sx={{
        borderRadius: "10px",
        boxShadow: cardShadow,
      }}
    >
      <CardActionArea
        onClick={() => (window.location.href = `/workshops/${workshop.attributes.slug}`)}
        disabled={isExpired}
      >
        <WorkshopCardImg workshop={workshop} />
        <CardContent>
          <Typography sx={{ mb: 1 }} variant="body1">
            {dateAsShortMonthNumericYear(workshop.attributes.arrivalTime)}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              textAlign: "left",
              marginBottom: 1,
              height: 40 /* always take up two lines of text */,
            }}
          >
            {workshop.attributes.slug == "2023-Acoustic-Electric-Classics"
              ? "Acoustic & Electric Band Jam Workshop"
              : workshop.attributes.title}
          </Typography>
          <Grid container justifyContent="space-between">
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1">
                {shortLocation(workshop.attributes.addressCity, workshop.attributes.addressCountry)}
              </Typography>
            </Grid>

            {/* always take up button space for card height consistency */}
            <Grid item height={25}>
              {!isExpired && <ArrowCircleRightRounded fontSize={"large"} color="primary" />}
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const WorkshopCardImg = ({ workshop }: WorkshopCardProps) => {
  const arrivalDate = new Date(workshop.attributes.arrivalTime);
  const isExpired = arrivalDate.getTime() - Date.now() < 0;
  return (
    <Box sx={{ position: "relative" }}>
      <CardMedia
        component="img"
        image={getHeaderImage(workshop, "small")}
        width="340px"
        sx={{
          position: "relative",
          minHeight: "190px", // take up space while img is loading
        }}
      />
      {isExpired && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            bgcolor: "rgba(78, 91, 105, 0.6)",
          }}
        />
      )}
      <Box sx={{ position: "absolute", top: 10, left: 10 }}>
        <WorkshopChips workshop={workshop} />
      </Box>
    </Box>
  );
};

enum WorkshopChipType {
  past = "past",
  upcoming = "upcoming",
  presale = "presale",
  soldOut = "soldOut",
}

const WorkshopChip = styled(Chip, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "type",
})<
  ChipProps & {
    type: WorkshopChipType;
  }
>(({ type }) => ({
  height: 15,
  borderBottomLeftRadius: 5,
  borderBottomRightRadius: 5,
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  textTransform: "uppercase",
  fontWeight: fontWeights.bold,
  color: "white",
  backgroundColor:
    type === WorkshopChipType.past
      ? jgDenimColors.gray.dark
      : type === WorkshopChipType.upcoming
      ? jgDenimColors.accent.teal.main
      : WorkshopChipType.presale
      ? jgDenimColors.accent.orange.main
      : WorkshopChipType.soldOut
      ? jgDenimColors.action.error.default
      : jgDenimColors.gray.dark,
}));

const WorkshopChips = ({ workshop }: WorkshopCardProps) => {
  const arrivalDate = new Date(workshop.attributes.arrivalTime);
  const isExpired = arrivalDate.getTime() - Date.now() < 0;
  const { isPresale, isSoldOut } = workshop.attributes;

  return (
    <Stack direction="row" spacing={1}>
      {isExpired ? (
        <WorkshopChip label="Past" type={WorkshopChipType.past} />
      ) : (
        <WorkshopChip label="Upcoming" type={WorkshopChipType.upcoming} />
      )}

      {isPresale && !isExpired && (
        <WorkshopChip label="Exclusive Pre-Sale" type={WorkshopChipType.presale} />
      )}
      {isSoldOut && !isExpired && <WorkshopChip label="Sold Out" type={WorkshopChipType.soldOut} />}
    </Stack>
  );
};
