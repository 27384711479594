import { Components } from "@mui/material";
import { SelectIconComponent } from "./SelectIconComponent";

export const select: Components["MuiSelect"] = {
  styleOverrides: {},
  defaultProps: {
    fullWidth: true,
    IconComponent: SelectIconComponent,
  },
};
