import React from "react";
import ConditionalWrapper from "../components/ConditionalWrapper";
import { ContentCarousel } from "../components/ContentCarousel";
import { publications } from "./consts";

const FeaturedIn = ({ width }) => {
  return (
    <div className="websites-featured-in">
      <p>
        JustinGuitar is <strong>trusted by millions of students worldwide</strong> and was featured
        in
      </p>
      <div>
        <ConditionalWrapper
          condition={width <= 992}
          wrapper={(children) => (
            <ContentCarousel
              settings={{
                slidesToShow: width > 768 ? 3 : 2,
                dots: true,
                arrows: false,
                autoplay: true,
              }}
            >
              {children}
            </ContentCarousel>
          )}
        >
          {publications.map((publication, idx) => (
            <a key={idx} href={publication.link} target="_blank">
              {publication.logo}
            </a>
          ))}
        </ConditionalWrapper>
      </div>
    </div>
  );
};

export default FeaturedIn;
