import { EMAIL_REGEX } from "../sessions/consts";
import * as yup from "yup";

export const REQUEST_PASSWORD_RESET_SCHEMA = yup.object({
  email: yup
    .string()
    .required()
    .test("noPlus", "+ character is not allowed", (value) => value.includes("+") === false)
    .matches(EMAIL_REGEX, "Please provide a valid email address"),
});

export const PASSWORD_FIELDS = [
  { name: "password", placeholder: "Password" },
  { name: "passwordConfirmation", placeholder: "Password Confirmation" },
];

export const PASSWORD_CHANGE_SCHEMA = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(30, "Password must be at most 30 characters"),
  passwordConfirmation: yup
    .string()
    .required("Confirm password")
    .test("password-match", "Passwords don't match", function (passwordConfirmation, context) {
      if (!passwordConfirmation) return false;

      return passwordConfirmation === context.parent.password;
    }),
});
