import React from "react";
import { CDN_URL } from "./shared/CdnUrl";

export default class AllProductsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeProducts: [],
    };
  }

  componentWillMount() {
    if (this.props.path.includes("?type=")) {
      let typeOf = this.typeMatcher(this.props.path.split("?type=")[1]);
      let filteredProducts = this.props.products.filter((product) => product.type_of === typeOf);
      filteredProducts.sort(this.sortByPosition());
      this.setState({ activeProducts: filteredProducts, activeTab: typeOf });
    } else {
      let products = [...this.props.products];
      products.sort(this.sortByPosition());
      this.setState({ activeProducts: products, activeTab: "All" });
    }
  }

  sortByPosition() {
    return function (a, b) {
      if (a.position === b.position) {
        return 0;
      } else if (a.position === null || a.position === "") {
        return 1;
      } else if (b.position === null || b.position === "") {
        return -1;
      } else {
        return a.position < b.position ? -1 : 1;
      }
    };
  }

  typeMatcher(typeOf) {
    if (typeOf === "books") {
      return "Book";
    } else if (typeOf === "videos") {
      return "DVD";
    } else if (typeOf === "merch") {
      return "Merch";
    } else if (typeOf === "apps") {
      return "app";
    } else if (typeOf === "digital") {
      return "Digital";
    } else if (typeOf === "jam-tracks") {
      return "Jam Tracks";
    } else {
      return typeOf;
    }
  }

  changeProducts = (typeOf) => {
    let filteredProducts = this.props.products.filter(
      (product) => product.type_of === this.typeMatcher(typeOf)
    );
    filteredProducts.sort(this.sortByPosition());
    let parsedType = this.typeMatcher(typeOf);
    this.setState({ activeProducts: filteredProducts, activeTab: parsedType });
    history.replaceState(
      "Products on the Official JustinGuitar store",
      `JustinGuitar Products | ${typeOf}`,
      `/products?type=${typeOf}`
    );
  };

  allProducts = () => {
    let activeProducts = [...this.props.products];
    activeProducts.sort(this.sortByPosition());
    this.setState({ activeProducts, activeTab: "All" });
    history.replaceState(
      "Products on the Official JustinGuitar store",
      `JustinGuitar Products`,
      `/products`
    );
  };

  imageToDisplay(product) {
    if (product.type_of === "app") {
      return product.app_display_image;
    } else if (
      product.type_of === "Digital" &&
      product.products_page_image &&
      product.products_page_image.length > 0
    ) {
      return product.products_page_image;
    } else if (product.cdn_image && product.cdn_image.length > 0) {
      return `${CDN_URL}${product.cdn_image}`;
    } else if (product.image && product.image.length > 0) {
      return product.image;
    } else {
      return product.shopify_image_link;
    }
  }

  linkToDisplay(product) {
    if (product.type_of === "Digital") {
      return product.link;
    } else if (product.genius_link && product.genius_link.length > 0) {
      return product.genius_link;
    } else if (product.shopify_link && product.shopify_link.length > 0) {
      return product.shopify_link;
    } else {
      return `/products/${product.slug}`;
    }
  }

  productContent = () => {
    return this.state.activeProducts.map((product) => {
      return (
        <div className="product-item" key={`${product.type_of}-${product.id}`}>
          <a className="product-item--image" href={this.linkToDisplay(product)} target="_blank">
            <img src={this.imageToDisplay(product)}></img>
          </a>
          <a className="product-item--title" href={this.linkToDisplay(product)} target="_blank">
            <p className="product-title">{product.title}</p>
          </a>
        </div>
      );
    });
  };

  render() {
    return (
      <div className="products-index">
        <div className="main-tab-options">
          <ul className="nav nav-tabs lesson__content-nav" role="tablist">
            <li
              role="presentation"
              className={"header5 uppercase " + (this.state.activeTab === "All" ? "active" : "")}
            >
              <a onClick={() => this.allProducts()}>ALL</a>
            </li>
            <li
              role="presentation"
              className={
                "header5 uppercase " + (this.state.activeTab === "Digital" ? "active" : "")
              }
            >
              <a onClick={() => this.changeProducts("digital")}>DIGITAL</a>
            </li>
            <li
              role="presentation"
              className={"header5 uppercase " + (this.state.activeTab === "app" ? "active" : "")}
            >
              <a onClick={() => this.changeProducts("apps")}>APPS</a>
            </li>
            <li
              role="presentation"
              className={"header5 uppercase " + (this.state.activeTab === "Merch" ? "active" : "")}
            >
              <a onClick={() => this.changeProducts("merch")}>MERCH</a>
            </li>
            <li
              role="presentation"
              className={"header5 uppercase " + (this.state.activeTab === "Book" ? "active" : "")}
            >
              <a onClick={() => this.changeProducts("books")}>BOOKS</a>
            </li>
            <li
              role="presentation"
              className={
                "header5 uppercase " + (this.state.activeTab === "Jam Tracks" ? "active" : "")
              }
            >
              <a onClick={() => this.changeProducts("jam-tracks")}>JAM TRACKS</a>
            </li>
          </ul>
        </div>

        <div className="products-outer">
          <div className="products-container">{this.productContent()}</div>
        </div>
      </div>
    );
  }
}
