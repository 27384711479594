export enum FontFamily {
  montserrat = "montserrat",
  openSans = "openSans",
}

export enum FontWeight {
  default = "default",
  bold = "bold",
  semiBold = "semiBold",
}
