import React from "react";

export default class Timeline extends React.Component {
  render() {
    return (
      <div className="teaching-timeline-swoosh__content__timeline">
        <div className="teaching-timeline-swoosh__content__timeline__slider">
          <input
            type="range"
            min="0"
            max={this.props.data.length - 1}
            value={this.props.activeIndex}
            step="1"
            onChange={(e) => this.props.setActiveYear(e.target.value)}
            list="timeline-values"
            className="teaching-timeline-swoosh__content__timeline__slider__range"
          />
          <div
            id="timeline-values"
            className="teaching-timeline-swoosh__content__timeline__slider__values"
          >
            {this.props.data.map((item, i) => (
              <span
                className="teaching-timeline-swoosh__content__timeline__slider__values__value clickable"
                style={{
                  opacity: this.props.activeIndex === i ? "1" : "0.5",
                }}
                key={i}
                onClick={(e) => this.props.setActiveYear(i)}
              >
                {item.year.slice(-2)}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
