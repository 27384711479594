import React, { Fragment } from "react";
import Slider from "react-slick";

import { Box, Grid, Typography } from "@mui/material";
import { jgDenimColors } from "../../jg-material/theme/jg-denim-colors";
import { fontFamilies, fontWeights } from "../../jg-material/theme/typography";
import SectionHeader from "./SectionHeader";
import { HOW_IT_WORKS_SECTIONS, LESSONS_HIGHLIGHTS, SECTION_TOP_MARGIN } from "./consts";
import { LongRightArrow } from "../../components/shared/Icons";
import { withInnerWidth } from "../../components/shared";
import { BootstrapSize, breakpoints } from "../../components/shared/bootstrap-helpers";

const CARD_STYLE = {
  borderRadius: "10px",
  padding: 3,
};

const SectionCard = ({ title, description, withNextItem }) => {
  return (
    <Box
      position={"relative"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      height={"100%"}
    >
      <Box
        sx={{
          p: 1,
          mb: 2,
          backgroundColor: jgDenimColors.denim.main,
          color: "white",
          borderRadius: CARD_STYLE["borderRadius"],
          fontWeight: fontWeights.semiBold,
          width: "100%",
          textAlign: "center",
        }}
      >
        {title}
      </Box>
      <Box
        sx={{
          backgroundColor: jgDenimColors.gray.light,
          borderRadius: CARD_STYLE["borderRadius"],
          p: CARD_STYLE["padding"],
          width: "100%",
          fontWeight: fontWeights.bold,
          minHeight: "150px",
          fontFamily: fontFamilies.openSans,
          height: "100%",
        }}
        textAlign={"center"}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />

      {withNextItem && (
        <Box sx={{ position: "absolute", top: "50%", right: "-45px", zIndex: 1 }}>
          <LongRightArrow />
        </Box>
      )}
    </Box>
  );
};

const MobileSlider = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
  };

  return (
    <Box position="relative" pb={8} mr={"-5px"} ml={"-5px"}>
      <Slider {...settings}>
        {HOW_IT_WORKS_SECTIONS.map((section, index) => (
          <Box key={section.title}>
            <Box display={"flex"} alignItems={"center"} position={"relative"}>
              <LongRightArrow />
              <SectionCard
                title={section.title}
                description={section.description}
                withNextItem={false}
              />
              <LongRightArrow />
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

const HowItWorks = ({ width }) => {
  return (
    <Box mt={SECTION_TOP_MARGIN}>
      <SectionHeader content={"How It Works"} />

      {width < breakpoints[BootstrapSize.smDevice] ? (
        <MobileSlider />
      ) : (
        <Grid container spacing={2} alignContent={"stretch"}>
          {HOW_IT_WORKS_SECTIONS.map((section, index) => (
            <Grid key={`sections_${index}`} item xs={section.size}>
              <SectionCard
                title={section.title}
                description={section.description}
                withNextItem={index + 1 < HOW_IT_WORKS_SECTIONS.length}
              />
            </Grid>
          ))}
        </Grid>
      )}

      <Box
        textAlign={"center"}
        sx={{
          mt: 4,
          p: CARD_STYLE["padding"],
          backgroundColor: jgDenimColors.gray.light,
          borderRadius: CARD_STYLE["borderRadius"],
        }}
      >
        <Typography sx={{ fontFamily: fontFamilies.openSans, fontWeight: fontWeights.bold, mb: 2 }}>
          Each monthly module includes a lesson about:
        </Typography>
        {LESSONS_HIGHLIGHTS.map((lesson, index) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            key={`lessons_${index}`}
          >
            <Typography mr={1} variant={"body2"}>
              ✔️
            </Typography>
            <Typography
              key={index}
              variant={"body1"}
              mb={"2px"}
              dangerouslySetInnerHTML={{
                __html: lesson,
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default withInnerWidth(HowItWorks);
