import React from "react";
import { Portal } from "@mui/material";

const CloseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_13185_77464)">
      <path
        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_13185_77464">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const CaretDownIcon = () => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z" fill="white" />
  </svg>
);

const CaretUpIcon = () => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.59 7.40991L6 2.82991L1.41 7.40991L1.23266e-07 5.99991L6 -8.82626e-05L12 5.99991L10.59 7.40991Z"
      fill="white"
    />
  </svg>
);

const TRUNCATED_TERMS = `
   <p>JustinGuitar.com uses cookies to enhance your browsing experience. By continuing to use this site, 
   you consent to the use of cookies. For more detailed information, please review the full 
   <a href="/terms" target="_blank">Terms &amp; Conditions</a>.</p>
`;

const FULL_TERMS = `
  <p>This site uses essential cookies to ensure the best possible experience, such as remembering your preferences and 
  enabling core site functionality. These cookies are necessary for the website to function properly. Additionally, we use 
  cookies for statistical analysis to help us improve our services. These cookies provide anonymous information about how you 
  interact with the website, allowing us to understand and enhance your user experience.</p>
  <p>Rest assured that we do not use cookies for advertising purposes, nor do we have third-party advertisers on this website. 
  We do not use cookies to access your computer or collect personally identifiable information without your consent. We treat your 
  information with the utmost care. We do not give, sell, or pass it on to any third parties unless you consent to such sharing.</p>
`;

class CookieConsent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isExpanded: false,
    };
  }

  componentDidMount() {
    const consent =
      document.cookie
        ?.split("; ")
        ?.find((row) => row.startsWith("cookie-consent-status="))
        ?.split("=")[1] || false;

    const status = localStorage.getItem("cookie-consent-status");

    if (status === "closed" || consent) {
      this.setState({ isOpen: false });
    } else {
      this.setState({ isOpen: true });
    }
  }

  componentWillUnmount() {
    this.setState({ isOpen: false });
  }

  handleClose = () => {
    localStorage.setItem("cookie-consent-status", "closed");
    this.setState({ isOpen: false });
  };

  handleExpand = () => {
    this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
  };

  handleAccept = () => {
    localStorage.setItem("cookie-consent-status", "closed");
    document.cookie = "cookie-consent-status=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
    this.setState({ isOpen: false });
  };

  render() {
    if (!this.state.isOpen) {
      return null;
    }

    return (
      <Portal>
        <div className="cookie-consent-overlay">
          <div className={`cookie-consent ${this.state.isExpanded ? "expanded" : ""}`}>
            <div className="text-content">
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: this.state.isExpanded
                    ? `${TRUNCATED_TERMS} ${FULL_TERMS}`
                    : TRUNCATED_TERMS,
                }}
              ></div>
              {this.state.isExpanded ? (
                <button onClick={this.handleExpand} className="text-expand-button">
                  Read less <CaretUpIcon />
                </button>
              ) : (
                <button onClick={this.handleExpand} className="text-expand-button">
                  Read more <CaretDownIcon />
                </button>
              )}
            </div>
            <button onClick={this.handleAccept} className="button button--primary">
              Accept All
            </button>
            <button onClick={this.handleClose} className="close-button">
              <CloseIcon />
            </button>
          </div>
        </div>
      </Portal>
    );
  }
}

export default CookieConsent;
