import { createTheme, responsiveFontSizes } from "@mui/material";

import { breakpoints } from "./breakpoints";
import { palette } from "./palette";
import { components } from "./components";
import { typography } from "./typography";

export const jgDenimTheme = responsiveFontSizes(
  createTheme({
    palette,
    breakpoints,
    typography,
    components,
  })
);
