import React from "react";
import ProgressBar from "../StatsTab/ProgressBar";
import { practiceSum } from "./StatsFunctions";

export default class ItemStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  itemsByTitle = (items) => {
    let itemsHash = {};
    items.map((item) => {
      if (item && !itemsHash[item.title]) {
        itemsHash[item.title] = {
          seconds: item.seconds,
          formattedDuration: this.props.formatTime(item.seconds),
        };
      } else {
        itemsHash[item.title]["seconds"] += item.seconds;
        itemsHash[item.title]["formattedDuration"] = this.props.formatTime(
          itemsHash[item.title]["seconds"]
        );
      }
    });

    let itemsToSort = [];

    for (let i in itemsHash) {
      itemsToSort.push([i, itemsHash[i]]);
    }

    itemsToSort.sort(function (a, b) {
      return b[1].seconds - a[1].seconds;
    });

    let sortedItems = {};
    itemsToSort.map((item) => {
      sortedItems[item[0]] = item[1];
    });
    return sortedItems;
  };

  practicePercentage = (seconds) => {
    let practiceTotal = practiceSum(this.props.items);
    if (practiceTotal) {
      let total = parseInt(practiceTotal);
      return `${(seconds / total) * 100}%`;
    }
  };

  render() {
    let itemsByTitle = this.itemsByTitle(this.props.items);

    if (itemsByTitle && Object.keys(itemsByTitle).length > 0) {
      return (
        <div>
          <ul>
            {Object.keys(itemsByTitle).map((k) => (
              <li key={k}>
                <ProgressBar
                  textLineOne={k}
                  percentage={this.practicePercentage(itemsByTitle[k]["seconds"])}
                  formattedDuration={itemsByTitle[k]["formattedDuration"]}
                />
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="styleized">
          <h3>No stats available.</h3>
        </div>
      );
    }
  }
}
