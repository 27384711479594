import React, { useState, useRef } from "react";
import * as Yup from "yup";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Loading from "../../components/shared/Loading";

const PASSWORD_RESET_DELAY_SECONDS = 60;

const Password = ({ user, token, openAlert }) => {
  const [editing, setEditing] = useState(false);
  const [allowPasswordReset, setAllowPasswordReset] = useState(true);
  const [seconds, setSeconds] = useState(PASSWORD_RESET_DELAY_SECONDS);

  const formDisabled = (props) => {
    if (props.dirty && Object.keys(props.errors).length === 0 && !props.isSubmitting) {
      return false;
    } else {
      return true;
    }
  };

  const submitForm = (data, setSubmitting, setErrors, resetForm) => {
    axiosWithCSRF()
      .patch(`/users/${user.data.attributes.slug}/update_profile`, {
        user: data,
      })
      .then((res) => {
        const responseText =
          res.data.hasOwnProperty("response_text") && res.data.response_text.length > 0
            ? res.data.response_text
            : null;

        openAlert({
          message: responseText ? responseText : "Your password was updated successfully!",
          severity: "success",
        });

        resetForm();
        setEditing(false);
      })
      .catch((err) => {
        const errors = err.response.data.error.hasOwnProperty("errors")
          ? err.response.data.error.errors
          : null;

        openAlert({ message: "Password update failed!", severity: "error" });
        setErrors(errors);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const submitPasswordReset = () => {
    if (allowPasswordReset) {
      axiosWithCSRF()
        .post("/reset_password", {
          user: { email: user.data.attributes.email },
        })
        .then((res) => {
          openAlert({
            message: "We've sent a password reset link to your email address!",
            severity: "success",
          });
        })
        .catch((err) => {
          openAlert({
            message:
              "There was a problem sending a password reset link to your email address. Please try again later.",
            severity: "error",
          });
        })
        .finally(() => {
          setAllowPasswordReset(false);
          let secondsCount = seconds;
          let interval = setInterval(() => {
            secondsCount--;
            setSeconds(secondsCount);
            if (secondsCount <= 0) {
              clearInterval(interval);
              setSeconds(PASSWORD_RESET_DELAY_SECONDS);
              setAllowPasswordReset(true);
            }
          }, 1000);
        });
    }
  };

  const toggleVisibility = (e) => {
    const input = e.currentTarget.parentElement.querySelector("input");

    if (input.type === "password") {
      e.currentTarget.classList.remove("icon-view-show");
      e.currentTarget.classList.add("icon-view-hide");
      input.type = "text";
    } else {
      e.currentTarget.classList.remove("icon-view-hide");
      e.currentTarget.classList.add("icon-view-show");
      input.type = "password";
    }
  };

  return (
    <div className="account__password">
      <div className="section">
        <Formik
          initialValues={{
            current_password: "",
            new_password: "",
            new_password_confirm: "",
          }}
          validationSchema={Yup.object().shape({
            current_password: Yup.string().required("Current password is required."),
            new_password: Yup.string()
              .min(8, "Password must be at least 8 characters.")
              .required("New password is required."),
            new_password_confirm: Yup.string()
              .oneOf([Yup.ref("new_password"), null], "Passwords don't match!")
              .required("New password confirmation is required."),
          })}
          onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
            submitForm(values, setSubmitting, setErrors, resetForm);
          }}
        >
          {(props) => (
            <Form>
              {props.isSubmitting && <Loading />}
              <div className="form-input">
                <span className="label">Password</span>
                {!editing ? (
                  <>
                    <button onClick={() => setEditing(true)} className="edit">
                      edit
                    </button>
                  </>
                ) : (
                  <>
                    <Field
                      name="current_password"
                      type="password"
                      autoComplete="off"
                      placeholder="Current password"
                    />
                    <i className="icon icon-view-show" onClick={(e) => toggleVisibility(e)}></i>
                    <ErrorMessage name="current_password" component="div" className="error" />
                    <button
                      type="button"
                      className="password-reset"
                      onClick={() => submitPasswordReset()}
                      disabled={!allowPasswordReset}
                    >
                      {!allowPasswordReset
                        ? `Send again in ${seconds} seconds`
                        : "Forgot your password?"}
                    </button>
                  </>
                )}
              </div>
              {editing && (
                <>
                  <div className="form-input">
                    <Field
                      name="new_password"
                      type="password"
                      autoComplete="off"
                      placeholder="New password"
                    />
                    <i className="icon icon-view-show" onClick={(e) => toggleVisibility(e)}></i>
                    <ErrorMessage name="new_password" component="div" className="error" />
                  </div>
                  <div className="form-input">
                    <Field
                      name="new_password_confirm"
                      type="password"
                      autoComplete="off"
                      placeholder="Confirm password"
                    />
                    <i className="icon icon-view-show" onClick={(e) => toggleVisibility(e)}></i>
                    <ErrorMessage name="new_password_confirm" component="div" className="error" />
                  </div>
                  <div className="form-controls">
                    <button type="submit" disabled={formDisabled(props)} className="save">
                      save
                    </button>
                    <button
                      onClick={() => {
                        setEditing(false);
                        props.resetForm();
                      }}
                      className="cancel"
                    >
                      cancel
                    </button>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Password;
