import React from "react";
import { connect } from "react-redux";
import { selectCurrentUser, selectPracticeSongs, selectSavedSongs } from "./store";
import {
  createUserPracticeItem,
  createUserSongItem,
  deleteUserPracticeItem,
  deleteUserSongItem,
} from "./store/effects/userInteractions";

export const SongActionButtons = ({
  currentUser,
  songIsSaved,
  userSongItem,
  song,
  saveSong,
  unsaveSong,
  practiceItemIsSaved,
  createUserPracticeItem,
  deleteUserPracticeItem,
}) => {
  const handleSaveButtonClick = (e) => {
    e.stopPropagation();
    songIsSaved ? unsaveSong(userSongItem) : saveSong(currentUser.data.attributes.id, song.id);
  };

  const handlePracticeAssistantClick = (e) => {
    e.stopPropagation();
    practiceItemIsSaved ? deleteUserPracticeItem() : createUserPracticeItem();
  };

  return currentUser != null ? (
    <div className="song-actions">
      <i
        onClick={handleSaveButtonClick}
        className={`icon icon-heart${songIsSaved ? "" : "-outline"}`}
      />
      <i onClick={handlePracticeAssistantClick} className="icon icon-playlist" />
    </div>
  ) : null;
};

export default connect(
  (state, { song }) => {
    const savedSongs = selectSavedSongs(state);
    const practiceItems = selectPracticeSongs(state);

    return {
      currentUser: selectCurrentUser(state),
      songIsSaved: savedSongs[song.id] != null,
      practiceItemIsSaved: practiceItems[song.id] != null,
      userSongItem: savedSongs[song.id],
    };
  },
  (dispatch, { song }) => ({
    saveSong: (user_id, itemable_id) =>
      dispatch(
        createUserSongItem({ itemable_id, user_id, itemable_type: "Song", item_status: "saved" })
      ),
    unsaveSong: (userItem) => dispatch(deleteUserSongItem(userItem)),
    createUserPracticeItem: () => dispatch(createUserPracticeItem(song.id)),
    deleteUserPracticeItem: () => dispatch(deleteUserPracticeItem(song.id)),
  })
)(SongActionButtons);
