import React, { useEffect, useState } from "react";
import Loading from "../components/shared/Loading";
import LoginPrompt from "../components/auth/LoginPrompt";
import { COMMUNITY_URL } from "../components/shared/Community";

const DiscourseEmbed = ({ setShowCommenting, topicId, discussionUrl }) => {
  //  // function refreshIframe() {
  //   let s = document.getElementById("discourseScript");
  //   let iframe = document.getElementById("discourse-embed-frame");
  //   s.remove();
  //   iframe.remove();
  //   embedDiscourse(embeddedTopicId);

  useEffect(() => {
    window.DiscourseEmbed = {
      discourseUrl: `${COMMUNITY_URL}/`,
      topicId: topicId,
    };

    const d = document.createElement("script");
    d.type = "text/javascript";
    d.id = "discourseScript";
    d.async = true;
    d.src = window.DiscourseEmbed.discourseUrl + "javascripts/embed.js";
    (
      document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]
    ).appendChild(d);
  }, []);
  // }

  return (
    <div>
      <div className="discourse__contained">
        <div id="discourse-comments"></div>
      </div>
      <div className="discourse__options">
        {/*<button*/}
        {/*  onClick={() => window.open(discussionUrl, "_blank")}*/}
        {/*  className="button button--primary button--inline"*/}
        {/*>*/}
        {/*  Leave a Comment*/}
        {/*</button>*/}
        <button
          onClick={() => setShowCommenting(true)}
          className="button button--primary button--inline"
        >
          Leave a Comment
        </button>
        <button
          onClick={() => window.open(discussionUrl, "_blank")}
          className="button button--discourse-secondary button--inline"
        >
          View Full Discussion
        </button>
      </div>
    </div>
  );
};

export default DiscourseEmbed;
