import React from "react";
import { Box, Chip, ChipProps, Grid, styled, Typography, useMediaQuery } from "@mui/material";
import { fontWeights } from "../../../jg-material/theme/typography";
import { jgDenimColors } from "../../../jg-material/theme/jg-denim-colors";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { CDN_URL } from "../../../components/shared/CdnUrl";
import { useSelector } from "react-redux";
import { workshopSelectors } from "../store/workshops.slice";

interface WorkshopTeamMember {
  name: string;
  position: string;
  blurb: string;
  headshotUrl: string;
}

const workshopTeamMembers: WorkshopTeamMember[] = [
  {
    name: "Justin Sandercoe",
    position: "Teacher",
    blurb:
      "I love teaching online, but there are clear benefits to the in-person experience. So, I've managed to keep running Workshops for the past few decades, and it's awesome!",
    headshotUrl: `${CDN_URL}/redesign/workshops/Justin-v2.png`,
  },
  {
    name: "Pete Whittard",
    position: "Co-Teacher",
    blurb:
      "Pete was one of my teachers at The Guitar Institute back in the 90s. He now joins me as a co-teacher to share all his guitar knowledge and enthusiasm with you!",
    headshotUrl: "https://jtgt-static.b-cdn.net/images/support-team/Pete-v2.png",
  },
  {
    name: "Carlo Roberti",
    position: "Workshop Coordinator",
    blurb:
      "Carlo takes care of all my Italian workshops since 2012! He'll assist you with registration, gear, hotels - all the logistics. Sometimes he joins us for a jam, and we love it!",
    headshotUrl: "https://jtgt-static.b-cdn.net/images/support-team/Carlo.png",
  },
  {
    name: "Adam Rowley",
    position: "Videographer",
    blurb:
      "Adam is my Studio Assistant and will join us to document our Workshop on video. :) He'll capture our incredible moments and have a great souvenir of our time!",
    headshotUrl: `${CDN_URL}/redesign/workshops/Adam.png`,
  },
];

const quebecWorkshopTeamMembers: WorkshopTeamMember[] = [
  {
    name: "Justin Sandercoe",
    position: "Teacher",
    blurb:
      "I love teaching online, but there are clear benefits to the in-person experience. So, I've managed to keep running Workshops for the past few decades, and it's awesome!",
    headshotUrl: `${CDN_URL}/redesign/workshops/Justin-v2.png`,
  },
  {
    name: "Pete Whittard",
    position: "Co-Teacher",
    blurb:
      "Pete was one of my teachers at The Guitar Institute back in the 90s. He now joins me as a co-teacher to share all his guitar knowledge and enthusiasm with you!",
    headshotUrl: "https://jtgt-static.b-cdn.net/images/support-team/Pete-v2.png",
  },
  {
    name: "Laryne",
    position: "Ops Manager",
    blurb:
      "Laryne lives not far from our Workshop site, so she'll assist us with registration, gear, hotels - all the logistics. Contact her for all your Workshop needs.",
    headshotUrl: "https://jtgt-static.b-cdn.net/images/support-team/Laryne.png",
  },
];

const acousticElectricClassicsWorkshopTeamMembers: WorkshopTeamMember[] = [
  {
    name: "Justin Sandercoe",
    position: "Teacher",
    blurb:
      "I love teaching online, but there are clear benefits to the in-person experience. So, I've managed to keep running Workshops for the past few decades, and it's awesome!",
    headshotUrl: `${CDN_URL}/redesign/workshops/Justin-v2.png`,
  },
  {
    name: "Pete Whittard",
    position: "Co-Teacher",
    blurb:
      "Pete was one of my teachers at The Guitar Institute back in the 90s. He now joins me as a co-teacher to share all his guitar knowledge and enthusiasm with you!",
    headshotUrl: "https://jtgt-static.b-cdn.net/images/support-team/Pete-v2.png",
  },
  {
    name: "Dave Marks & Special Guest",
    position: "Bass & Drums",
    blurb:
      "We'll have two incredible musicians joining our band! My friend Dave Marks will be on the bass guitar and/or drums, and a local guest musician will add the extra groove we love.",
    headshotUrl: `${CDN_URL}/redesign/workshops/Dave-v3.png`,
  },
  {
    name: "Laryne",
    position: "Ops Manager",
    blurb:
      "Laryne will be joining us directly from Canada to help coordinate the event! You can count on her to answer all your Workshop related questions before, during, and after the event. :)",
    headshotUrl: "https://jtgt-static.b-cdn.net/images/support-team/Laryne.png",
  },
];

const workshopBand: WorkshopTeamMember = {
  name: "Onorino and Gianni",
  position: "Bass & Drums",
  blurb:
    "We use two excellent local musicians for bass and drums, Onorino and Gianni have played together for decades and they have an incredible groove and are super responsive, perfect for our workshops!",
  headshotUrl: `${CDN_URL}/redesign/workshops/Others.png`,
};

const headshotSize = "100px";
const WorkshopTeamMember = ({
  teamMember: { name, position, blurb, headshotUrl },
}: {
  teamMember: WorkshopTeamMember;
}) => {
  const isSmall = useMediaQuery(jgDenimTheme.breakpoints.down("sm"));
  return (
    <Grid container alignItems="center" flexDirection={isSmall ? "column" : "row"} mb={4}>
      <Grid item xs={12} sm={3} md={2}>
        <div
          style={{
            backgroundColor: "rgb(243, 246, 248)", // todo: put this color in palette
            borderRadius: "50%",
            height: headshotSize,
            width: headshotSize,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${headshotUrl})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              height: headshotSize,
              width: headshotSize,
            }}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={9} md={10}>
        <Typography variant="h6" m={jgDenimTheme.spacing(1, 0)}>
          {name}
        </Typography>
        <WorkshopTeamChip label={position} />
        <Typography variant="body1" mb={0} mt={1}>
          {blurb}
        </Typography>
      </Grid>
    </Grid>
  );
};

const WorkshopTeamChip = styled(Chip)<ChipProps>(() => ({
  height: 15,
  borderBottomLeftRadius: 5,
  borderBottomRightRadius: 5,
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  textTransform: "uppercase",
  fontWeight: fontWeights.bold,
  color: "white",
  backgroundColor: jgDenimColors.gray.dark,
}));

export const WorkshopTeam = () => {
  const workshop = useSelector(workshopSelectors.selectCurrentWorkshop);
  return (
    <Box margin={jgDenimTheme.spacing(4, 0, 2)}>
      <Typography variant="h4" mb={2}>
        Workshop Team
      </Typography>
      {workshop &&
        (workshop.attributes.slug === "2023-Acoustic-Jam-Week"
          ? quebecWorkshopTeamMembers.map((teamMember, idx) => (
              <WorkshopTeamMember teamMember={teamMember} key={idx} />
            ))
          : workshop.attributes.slug === "2023-Acoustic-Electric-Classics"
          ? acousticElectricClassicsWorkshopTeamMembers.map((teamMember, idx) => (
              <WorkshopTeamMember teamMember={teamMember} key={idx} />
            ))
          : workshopTeamMembers.map((teamMember, idx) => (
              <WorkshopTeamMember teamMember={teamMember} key={idx} />
            )))}
      {workshop != null &&
      workshop.attributes.title.toLowerCase().includes("electric") &&
      workshop.attributes.slug !== "2023-Acoustic-Electric-Classics" ? (
        <WorkshopTeamMember teamMember={workshopBand} />
      ) : null}
    </Box>
  );
};
