import React from "react";
import { compose } from "redux";
import { donationTypes } from "./consts";
import { withInnerWidth } from "../components/shared";
import { defaultDonationContextState, DonationContext } from "./DonationContext";
import Swoosh from "../components/shared/Swoosh";
import { BootstrapSize } from "../components/shared/bootstrap-helpers";
import { CDN_URL } from "../components/shared/CdnUrl";
import NewPayments from "../components/new-payments";
import ReactOnRails from "react-on-rails";
import "./store/donationStore";
import { Provider } from "react-redux";
import Snackbar from "../components/shared/ui/Snackbar";

class Donation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultDonationContextState,
      amount: null,
      stripeKey: this.props.stripeKey,
      recaptchaKey: this.props.recaptchaKey,
      updateCurrentTab: (tab) => this.setState({ currentTab: tab }),
      updateCurrency: (currency) => this.setState({ currency }),
      updateAmount: (amount) => this.setState({ amount }),
      donationSuccess: () => this.setState({ _donationSuccess: true }),
      _donationSuccess: null,
    };
  }

  donationSuccess = () => {
    this.setState({ donationSuccess: true });
  };

  donationThanks() {
    const __html =
      this.state.currentTab === donationTypes.MONTHLY
        ? this.props.donationSubscriptionSuccess
        : this.props.donationSingleSuccess;

    return <div dangerouslySetInnerHTML={{ __html }} />;
  }

  render() {
    const { _donationSuccess } = this.state;

    const swooshImages = {
      [BootstrapSize.xsDevice]: `${CDN_URL}/redesign/donate/hero-v4-sm.jpg`,
      [BootstrapSize.smDevice]: `${CDN_URL}/redesign/donate/hero-v3-lg.jpg`,
    };

    const store = ReactOnRails.getStore("donationStore");

    return (
      <>
        <Swoosh imgUrl={swooshImages}>
          <div className="donate-hero">
            <div className="donate-hero-content hero-content container">
              <h1>donations</h1>
              <p>
                I don’t charge for my lessons because I know that not everyone can afford them. By
                donating, you help provide the very best guitar education to people from all walks
                of life, all over the world.
              </p>
            </div>
          </div>
        </Swoosh>

        <div className="container" style={{ marginTop: "120px", marginBottom: "120px" }}>
          {!this.props.donationsDisabled && (
            <h2 style={{ marginBottom: "60px" }}>you can donate in many ways</h2>
          )}

          <Provider store={store}>
            <NewPayments
              currentUser={this.props.currentUser}
              donationSingleSuccess={this.props.donationSingleSuccess}
              donationSubscriptionSuccess={this.props.donationSubscriptionSuccess}
              donationsDisabled={this.props.donationsDisabled}
            />
            <Snackbar />
          </Provider>
        </div>
      </>
    );
  }
}

export default compose(withInnerWidth)(Donation);
