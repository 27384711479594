import React from "react";
import Swoosh from "../components/shared/Swoosh";
import { BootstrapSize } from "../components/shared/bootstrap-helpers";
import { CDN_URL } from "../components/shared/CdnUrl";
import { withInnerWidth } from "../components/shared";
import axiosWithCSRF from "../components/shared/axiosWithCSRF";
import "../components/shared/polyfills/replaceAll.js";

const buttonVariants = [
  { lessonsCTAText: "Learn Here For Free!", appButtonText: "Beginners: Learn With My App!" },
  { lessonsCTAText: "Choose Your Course!", appButtonText: "Learn With my Beginner App!" },
  { lessonsCTAText: "Learn to Play Here!", appButtonText: "Download My Beginner App!" },
];
const randomButtonVariant = buttonVariants[Math.floor(Math.random() * buttonVariants.length)];

class HomeHero extends React.Component {
  constructor(props) {
    super(props);
    this.state = { headerHeight: 0, lessonsBtnId: null, appBtnId: null };
  }

  componentDidMount() {
    this.setHeaderHeight();
  }

  componentDidUpdate({ width: prevWidth }) {
    if (this.props.width !== prevWidth) {
      this.setHeaderHeight();
    }
  }

  setHeaderHeight = () => {
    const header = document.querySelector(".header__menu");
    if (header == null) {
      window.setTimeout(this.setHeaderHeight, 50);
    } else {
      this.setState({ headerHeight: header.offsetHeight });
    }
  };

  render() {
    const { headerHeight } = this.state;
    return (
      <div style={{ marginTop: `-${headerHeight}px` }}>
        <Swoosh
          excludeTop
          imgUrl={{
            [BootstrapSize.xsDevice]: `${CDN_URL}/redesign/2020/home-page/hero-small-v7.jpeg`,
            [BootstrapSize.xlDevice]: `${CDN_URL}/redesign/2020/home-page/hero-v7.jpeg`,
          }}
        >
          <div className="home-hero container" id="home-hero" style={{ top: `-${headerHeight}px` }}>
            <div className="home-hero-content">
              <div className="home-hero-header">
                <h1 id="home__heading">Learn How To Play Guitar</h1>

                <div className="subheading d-none">
                  Songs. Techniques. Rhythm. Theory. Chords. Practice Tools. From Beginner to
                  Advanced!
                </div>

                <div className="ctas">
                  <a className="button button--primary" href="/guitar-lessons">
                    {randomButtonVariant.lessonsCTAText}
                  </a>
                  <span> or </span>
                  <a className="button button--teal" href="https://go.onelink.me/3065804798/hero">
                    {randomButtonVariant.appButtonText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Swoosh>
      </div>
    );
  }
}

export default withInnerWidth(HomeHero);
