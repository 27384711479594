import { ThemeColors } from "./interfaces";

export const jgDenimColors: ThemeColors = {
  denim: {
    main: "#406e8e",
    light: "#81ABC1",
    dark: "#1D3446",
  },
  gray: {
    main: "#A6ADB4",
    light: "#F5F8F9",
    dark: "#4E5B69",
  },
  brown: {
    dark: "#694B32", // brown
    main: "#BDA38A", // latte
    light: "#E7DED2", // sand
  },
  action: {
    success: {
      default: "#6ACF64",
    },
    error: {
      default: "#EA424D",
    },
    denim: {
      default: "#44546C",
      disabled: "#B8BEC7",
      hover: "#44546C", // TODO: find hover color in live app
    },
    orange: {
      default: "#FD6A02",
      disabled: "#B8BEC7",
      hover: "#FD6A02", // TODO: find hover color in live app
    },
  },
  accent: {
    orange: {
      main: "#FD6A02",
      hover: "#fd8835",
      light: "#FFE1CC",
    },
    teal: {
      main: "#00B4A5",
    },
  },
  grades: {
    grade1: "#fff",
    grade2: "#f4d059",
    grade3: "#FC9644",
    grade4: "#48CFAE",
    grade5: "#45AAF2",
    grade6: "#A65DEA",
    grade7: "#FC5C66",
    grade8: "#7a3e26",
    grade9: "#222F3F",
  },
  white: "#fff",
  black: "#000",
};
