import React from "react";
import axiosWithCSRF from "../../../shared/axiosWithCSRF";

export default class MetronomeSave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  componentWillUnmount() {
    if (this.successTimeout) {
      clearTimeout(this.successTimeout);
    } else if (this.errorTimeout) {
      clearTimeout(this.errorTimeout);
    }
  }

  saveMetronomeSpeed = (returnToDefault) => {
    this.setState({ isSubmitting: true }, () => {
      let metronomeSpeed = returnToDefault === true ? null : this.props.metronomeSpeed;
      axiosWithCSRF()
        .patch(`/user_practice_items/metronome_speed/${this.props.item.id}`, {
          metronome_speed: metronomeSpeed,
        })
        .then((res) => {
          let saveSuccess =
            returnToDefault === true
              ? "Restored to default tempo."
              : "Saved your tempo for this practice item!";
          this.setState({ saveSuccess }, () => {
            this.successTimeout = setTimeout(
              () => this.setState({ saveSuccess: null, isSubmitting: false }),
              2000
            );
            if (returnToDefault) {
              this.props.resetToDefaultTempo();
            }
            this.props.saveMetronomeSpeed(res.data.item);
          });
        })
        .catch((res) => {
          this.setState({ saveError: res.response.data.message }, () => {
            this.errorTimeout = setTimeout(
              () => this.setState({ saveError: null, isSubmitting: false }),
              2000
            );
          });
        });
    });
  };

  saveResponse = () => {
    if (this.state.saveError) {
      return <p className="has-errors">{this.state.saveError}</p>;
    } else if (this.state.saveSuccess) {
      return <p className="success">{this.state.saveSuccess}</p>;
    }
  };

  submitIsDisabled = (speed, clearSpeed) => {
    let { item } = this.props;
    if (clearSpeed) {
      return item.metronome_speed === null || speed === 120 || this.state.isSubmitting;
    } else {
      return speed === 120 || speed === item.metronome_speed || this.state.isSubmitting;
    }
  };

  render() {
    return (
      <div className="save-metronome-speed">
        {this.saveResponse()}
        <div className="save-metronome-speed__container">
          <button
            onClick={() => this.saveMetronomeSpeed(false)}
            disabled={this.submitIsDisabled(this.props.metronomeSpeed, false)}
          >
            save tempo
          </button>

          <button
            onClick={() => this.saveMetronomeSpeed(true)}
            disabled={this.submitIsDisabled(this.props.metronomeSpeed, true)}
          >
            clear saved tempo
          </button>
        </div>
      </div>
    );
  }
}
