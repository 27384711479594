import ReactOnRails from "react-on-rails";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import thunk from "redux-thunk";

import * as songSchema from "./songSchema";

import { entities, ui, session, metadata } from "./slices";
import { getArtistNameFromQueryString } from "./shared";
import { fetchSongs } from "./effects/fetchSongs";
import { fetchArtists } from "./effects";

const initialState = {
  ui: ui.initialState,
  session: session.initialState,
  entities: entities.initialState,
  metadata: metadata.initialState,
};

const reducer = combineReducers({
  ui: ui.reducer,
  session: session.reducer,
  entities: entities.reducer,
  metadata: metadata.reducer,
});

const createSongStore = (preloadedState) => {
  const store = configureStore({
    reducer,
    preloadedState,
    middleware: [thunk],
  });

  // apply filters, etc.
  store.dispatch(fetchSongs());
  store.dispatch(fetchArtists());

  return store;
};

ReactOnRails.registerStore({
  songsIndexStore: ({ artists, songRequests, session, currentArtist, songCount }, railsContext) => {
    const { entities } = normalize(artists, songSchema.artistsJson);
    const normalizedSongRequests = songRequests
      ? normalize(songRequests, songSchema.songRequestsJson)
      : null;
    const orderedIds = normalizedSongRequests
      ? {
          pendingSongRequests: normalizedSongRequests.result.pendingRequests.data,
          selectedSongRequests: normalizedSongRequests.result.selectedRequests.data,
          completedSongRequests: normalizedSongRequests.result.completedRequests.data,
        }
      : { pendingSongRequests: [], selectedSongRequests: [], completedSongRequests: [] };

    return createSongStore({
      ui: {
        ...initialState.ui,
        currentArtistSlug: currentArtist,
        orderedIds: orderedIds,
      },
      session: { ...initialState.session, ...session },
      entities: {
        ...initialState.entities,
        ...entities,
        ...(normalizedSongRequests ? normalizedSongRequests.entities : []),
      },
      metadata: {
        songs: {
          page: 1,
          // do not paginate songs on artists#show
          perPage: normalizedSongRequests
            ? 10
            : entities.songs
            ? Object.keys(entities.songs).length
            : 10,
          totalCount: entities.songs ? entities.songs.length : 0,
        },
        artists: {
          page: 1,
          perPage: 8,
          totalCount: entities.artists.length,
        },
        songRequests: {
          ...(normalizedSongRequests ? normalizedSongRequests.result.pendingRequests.metadata : []),
        },
        songCount: songCount,
      },
    });
  },
});
