import React from "react";
import { EVENT_TYPES } from ".";

const DonateBanner = ({ eventType, width }) => {
  if (eventType != EVENT_TYPES.club) return null;

  const justinImg = <div className="justin-container"></div>;

  return (
    <div className="jg-banner">
      {width > 992 && justinImg}
      <h3>Hey, do you enjoy JustinGuitar Clubs?</h3>
      <p>
        I proudly compensate our <a href="/teachers">Approved Teachers</a> for hosting weekly live
        events at no cost for JustinGuitar.com students. Learning together is better and should be
        accessible to everyone! If you can, please consider making a donation to support my work and
        help me keep JustinGuitar Clubs free for all!
      </p>
      <a href="/donate" className="button button--primary">
        Donate
      </a>
      {width <= 992 && justinImg}
    </div>
  );
};

export default DonateBanner;
