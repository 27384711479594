import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";

import FilterOption from "./FilterOption";

const FilterGroup = ({ type, options, info, activeFilters, toggleFilter }) => {
  const [groupOpen, setGroupOpen] = useState(
    Object.keys(activeFilters).some((key) => options.includes(key))
  );

  useEffect(() => {
    setGroupOpen(Object.keys(activeFilters).some((key) => options.includes(key)));
  }, [activeFilters]);

  const handleToggle = (event) => toggleFilter(event.currentTarget.dataset.option);

  return (
    <div className="filters__group">
      <div
        className="filters__group-header"
        onClick={() => setGroupOpen(!groupOpen)}
        style={{ cursor: "pointer" }}
      >
        <span>{type}</span>
        <span>
          <i className={`glyphicon glyphicon-chevron-${groupOpen ? "up" : "down"}`} />
        </span>
      </div>
      <CSSTransition in={groupOpen} timeout={200} classNames="verticalGrowInOut">
        {groupOpen ? (
          <div className="verticalGrowInOut">
            <>
              {info && (
                <div className="filter-group-info">
                  <i className="icon icon-info" />
                  <span>{info}</span>
                </div>
              )}
              {options.map((option) => (
                <FilterOption
                  active={activeFilters[option] === true}
                  option={option}
                  toggleFilter={handleToggle}
                  key={option}
                />
              ))}
            </>
          </div>
        ) : (
          <div />
        )}
      </CSSTransition>
    </div>
  );
};

export default FilterGroup;

FilterGroup.propTypes = {
  activeFilters: PropTypes.objectOf(PropTypes.bool).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  toggleFilter: PropTypes.func.isRequired,
};
