import React, { Fragment, useEffect, useState } from "react";

import ModuleCard from "./ModuleCard";
import ProductButton from "../ProductButton";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import { USER_ITEM_STATUSES } from "../../lesson/swoosh/consts";

const ModulesList = ({ parentGroup, lessonCountByModule, currentUser, hasPMTEnrollment }) => {
  const { lockedModuleIds, groupOrder } = parentGroup.data.attributes;
  const [userItems, setUserItems] = useState([]);

  useEffect(() => {
    if (!currentUser) return;
    axiosWithCSRF()
      .get(`/user_items/by_parent_group?parent_group_id=${parentGroup.data.id}`)
      .then((response) => {
        const { userItems } = response.data;
        setUserItems(userItems);
      });
  }, []);

  function includedModule(id) {
    return parentGroup.included.find((item) => parseInt(item.id) === id && item.type === "group");
  }

  function completedLessonsByModule(moduleId) {
    if (!currentUser || userItems.length === 0) return null;

    const items = userItems.filter(
      (item) =>
        item.attributes.itemableId === moduleId &&
        item.attributes.itemableType === "Group" &&
        item.attributes.itemStatus === USER_ITEM_STATUSES.completed
    );

    return [...new Set(items.flatMap((item) => item.attributes.nestedIds))];
  }

  function completionsByModule(moduleId) {
    if (!currentUser || userItems.length === 0) return null;

    const lessonCount = lessonCountByModule(moduleId);
    const lessonIds = completedLessonsByModule(moduleId);

    return {
      completionPercentage: lessonIds.length / lessonCount,
      completedLessonIds: lessonIds,
    };
  }

  const firstLockedModuleId = [...lockedModuleIds]
    .sort((a, b) => groupOrder.indexOf(a) - groupOrder.indexOf(b))
    .shift();

  const storeSlug = parentGroup.included.find((item) => item.type === "websiteProduct")?.attributes
    ?.storeSlug;

  return (
    <div className="pg__outline">
      <h2 className="uppercase header2 section-header">Course Outline</h2>
      {groupOrder.map((moduleId) => {
        const module = includedModule(moduleId);
        if (!module) return;

        const completionInfo = completionsByModule(moduleId);

        return (
          <Fragment key={moduleId}>
            {firstLockedModuleId === parseInt(module.id) && (
              <ProductButton currentUser={currentUser} storeSlug={storeSlug} />
            )}
            <ModuleCard
              group={module}
              completionPercentage={completionInfo?.completionPercentage}
              completedLessonIds={completionInfo?.completedLessonIds}
              isLocked={lockedModuleIds.includes(parseInt(moduleId))}
              currentUser={currentUser}
              hasPMTEnrollment={hasPMTEnrollment}
              parentGroupId={parentGroup.data.id}
              lessonCountByModule={lessonCountByModule}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default ModulesList;
