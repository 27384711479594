import { Box, Button, Typography } from "@mui/material";
import React from "react";

const TYPEFORM_URL = "https://justinguitar-survey.typeform.com/to/I8cwPSVQ";

const ClosedForNewEnrollments = () => {
  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
      <Typography variant="h4">Blues Immersion - Class 1 is now closed for enrollments.</Typography>
      <Typography variant="h8" my={3} textTransform={"none"}>
        Don’t miss out next time! Join the waitlist to be the first to know when new classes open.
      </Typography>
      <Button
        href={TYPEFORM_URL}
        sx={{ boxShadow: "none" }}
        target="_blank"
        className="button button--primary"
      >
        Join the waitlist
      </Button>
    </Box>
  );
};

export default ClosedForNewEnrollments;
