import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axiosWithCSRF from "../components/shared/axiosWithCSRF";

import SongSwoosh from "./SongSwoosh";
import TabContainer from "./tabs/TabContainer";
import SongProducts from "./SongProducts";
import Snackbar from "../components/shared/ui/Snackbar";
import MobileContextMenu from "../components/shared/ui/MobileContextMenu";
import { toggleMobileContextMenuVisibility } from "../components/shared/ui/uiSlice";
import {
  savedSongItem,
  savedSongPracticeItem,
} from "../components/shared/session/sessionSelectors";
import {
  createSongItem,
  deleteSongItem,
  createUserSongPracticeItem,
  deleteUserSongPracticeItem,
} from "../components/shared/session/sessionEffects";
import { withInnerWidth } from "../components/shared/WithInnerWidth";
import { PromoItem } from "../justins-corner/PromoItem.tsx";
import { setActiveTab } from "./store/songIndex";
import { SongBanner } from "./SongBanner";

const SongContainer = ({
  song,
  savedSongItem,
  createSongItem,
  deleteSongItem,
  savedSongPracticeItem,
  createUserSongPracticeItem,
  deleteUserSongPracticeItem,
  darkMode,
  user,
  toggleMobileContextMenuVisibility,
  width,
  promoItems,
  setActiveTab,
}) => {
  const [mobileContextMenuToggleBtn, setMobileContextMenuToggleBtn] = useState(null);

  useEffect(() => {
    if (user) {
      axiosWithCSRF().post("/recent_pageviews", {
        recent_pageview: {
          viewable_type: "Song",
          viewable_id: song.data.id,
        },
      });
    }

    setMobileContextMenuToggleBtn(document.querySelector(".mobile-context-menu-toggle-btn"));

    const urlHash = location.hash ? location.hash.replace("#", "") : null;

    if (urlHash === "discussion") {
      setActiveTab("Discussion");
    }
    if (urlHash === "notes") {
      setActiveTab("Notes");
    }
    if (urlHash === "tab") {
      setActiveTab("Tab");
    }
    if (urlHash === "performances") {
      setActiveTab("Performances");
    }
  }, []);

  const toggleFavorite = () => {
    if (savedSongItem) {
      deleteSongItem(savedSongItem);
    } else {
      createSongItem({
        user_item: {
          user_id: user.data.id,
          itemable_type: "Song",
          itemable_id: song.data.id,
          item_status: "saved",
        },
      });
    }
  };

  const togglePracticeItem = () => {
    if (savedSongPracticeItem) {
      deleteUserSongPracticeItem(song.data.id);
    } else {
      createUserSongPracticeItem(song.data.id);
    }
  };

  if (song) {
    return (
      <div className={"song " + (darkMode ? "dark-mode" : "")}>
        <SongBanner song={song} currentUser={user} />
        <SongSwoosh toggleMobileContextMenuVisibility={toggleMobileContextMenuVisibility} />
        <div className="container">
          <SongProducts song={song} />
          <TabContainer />
          <Snackbar />
        </div>
        <MobileContextMenu floating={width > 768} anchor={mobileContextMenuToggleBtn}>
          <ul>
            <li>
              <button className="mobile-context-menu-btn" onClick={() => toggleFavorite()}>
                <i className={`icon ${savedSongItem ? "icon-heart" : "icon-heart-outline"}`}></i>
                {`${savedSongItem ? "Saved" : "Save"}`} to favorites
              </button>
            </li>
            <li>
              <button className="mobile-context-menu-btn" onClick={() => togglePracticeItem()}>
                <i
                  className={"icon " + (savedSongPracticeItem ? "icon-library" : "icon-playlist")}
                />
                {`${savedSongPracticeItem ? "Added" : "Add"}`} to practice items
              </button>
            </li>
          </ul>
        </MobileContextMenu>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  song: state.entity.song,
  savedSongItem: savedSongItem(state),
  savedSongPracticeItem: savedSongPracticeItem(state),
  darkMode: state.entity.darkMode,
  user: state.session.currentUser,
  promoItems: state.entity.promoItems,
});

const mapDispatchToProps = (dispatch) => ({
  toggleMobileContextMenuVisibility: () => dispatch(toggleMobileContextMenuVisibility()),
  createSongItem: (item) => dispatch(createSongItem(item)),
  deleteSongItem: (item) => dispatch(deleteSongItem(item)),
  createUserSongPracticeItem: (item) => dispatch(createUserSongPracticeItem(item)),
  deleteUserSongPracticeItem: (item) => dispatch(deleteUserSongPracticeItem(item)),
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withInnerWidth(SongContainer));
