import PropTypes from "prop-types";
import React from "react";
import SortableList from "../components/SortableList";
import SearchItems from "../components/SearchItems";
import FilterLessonItems from "../components/FilterLessonItems";
import AppConfig from "../../../packs/AppConfig.js";
import ReactDOM from "react-dom";

export default class LessonList extends React.Component {
  static propTypes = {
    lessons: PropTypes.array,
    modules: PropTypes.array,
    difficulty: PropTypes.array.isRequired,
    category: PropTypes.array.isRequired,
    grades: PropTypes.array.isRequired,
  };

  constructor(props, _railsContext) {
    super(props);

    this.state = {
      // These values are passed to SortableList
      sortBy: [
        {
          title: "Title",
          name: "title",
          // value: function(item) {
          // 	return item.title || '?';
          // },
          sortValue: function (item) {
            return item.title || "";
          },
          dir: "ASC",
        },
        {
          title: "Difficulty",
          name: "difficulty",
          // value: function(item) {
          // 	if (item.position) {
          // 		return item.position
          // 	} else {
          // 	return '?'};
          // },
          sortValue: function (item) {
            if (item.position && item.position > 0) {
              return item.position;
            } else {
              return null;
            }
          },
          dir: "ASC",
        },
        {
          title: "Date",
          name: "date",
          // value: function(item) {
          // 	if (item.date) {
          // 		return item.date
          // 	} else {
          // 	return '?'};
          // },
          sortValue: function (item) {
            if (item.release_date && item.release_date[1]) {
              return Date.parse(item.release_date[1]);
            } else if (item.created_at) {
              return Date.parse(item.created_at);
            } else {
              return null;
            }
          },
          dir: "ASC",
        },
      ],
      // These are passed to FilterItems component
      filterBy: [
        {
          title: "Search",
          name: "search",
          searchStr: null,
          filters: [],
          includeItem: function (item) {
            let searchField = ["title"];
            let include = false;
            let value = item[searchField];
            // Partial match is enough
            if (value.toLowerCase().indexOf(this.filters[0]) !== -1) {
              include = true;
            }
            return include;
          },
        },
        {
          title: "Difficulty",
          name: "difficulty",
          filterItems: props.difficulty,
          nestedItems: props.grades,
          filters: [],
          listVisible: true,
          value: function (filterItem) {
            if (filterItem.title) {
              return filterItem.title;
            } else if (filterItem.belt) {
              return filterItem.belt;
            }
          },
          allSelected: function (filter, item) {
            let allFound = true;
            for (let i = 0; i < item.grades.length; i++) {
              if (!filter.filters.includes(item.grades[i])) {
                allFound = false;
              }
            }
            return allFound;
          },
          count: function (item) {
            if (item.title) {
              return props.beltCount[item.title];
            } else if (item.belt) {
              return props.beltCount[item.belt];
            }
          },
          lessonCount: function (item) {
            // if item has a title, it's a Level and not a grade
            if (item.title && item.grades) {
              return props.lessonFiltersCount["levels"][item.id]
                ? props.lessonFiltersCount["levels"][item.id]
                : 0;
            } else if (item.belt) {
              return props.lessonFiltersCount["belts"][item.id]
                ? props.lessonFiltersCount["belts"][item.id]
                : 0;
            }
          },
          moduleCount: function (item) {
            if (item.title && item.grades) {
              return props.moduleFiltersCount["levels"][item.id]
                ? props.moduleFiltersCount["levels"][item.id]
                : 0;
            } else if (item.belt) {
              return props.moduleFiltersCount["belts"][item.id]
                ? props.moduleFiltersCount["belts"][item.id]
                : 0;
            }
          },
          includeItem: function (item) {
            let itemValue = item.grade || null;

            let include = false;

            if (this.filters.length) {
              if (itemValue) {
                include = this.filters.includes(itemValue);
              }
            } else {
              include = true;
            }

            return include;
          },
        },
        {
          title: "Category",
          name: "category",
          filterItems: props.category,
          filters: [],
          isDifficulty: false,
          listVisible: true,
          value: function (filterItem) {
            if (filterItem.title) {
              return filterItem.title;
            }
          },
          allSelected: function (filter, item) {
            if (!item.grades && filter.filters.includes(item.title)) {
              return true;
            }
          },
          lessonCount: function (item) {
            return props.lessonFiltersCount["categories"][item.id]
              ? props.lessonFiltersCount["categories"][item.id]
              : 0;
          },
          moduleCount: function (item) {
            return props.moduleFiltersCount["categories"][item.id]
              ? props.moduleFiltersCount["categories"][item.id]
              : 0;
          },
          includeItem: function (item) {
            let itemValue = item.category || null;

            let include = false;

            if (this.filters.length) {
              if (itemValue) {
                include = this.filters.includes(itemValue);
              }
            } else {
              include = true;
            }

            return include;
          },
        },
      ],
    };
  }

  componentDidMount() {
    // Justin wants filters open by default on bigger screens - if the user is
    // on smaller screen, close them here.
    if (window.innerWidth && window.innerWidth < 850) {
      let filterItems = this.state.filterBy;
      filterItems.forEach(function (i) {
        if (i["listVisible"]) {
          i["listVisible"] = false;
        }
      });
      this.setState({
        filterBy: filterItems,
        lessons: this.props.lessons,
        modules: this.props.modules,
        lessonFiltersCount: this.props.lessonFiltersCount,
        moduleFiltersCount: this.props.moduleFiltersCount,
        itemTotal: this.props.itemTotal,
      });
    } else {
      this.setState({
        lessons: this.props.lessons,
        modules: this.props.modules,
        lessonFiltersCount: this.props.lessonFiltersCount,
        moduleFiltersCount: this.props.moduleFiltersCount,
        itemTotal: this.props.itemTotal,
      });
    }
  }

  onItemsFiltered = (items, filtersHere, searchBy) => {
    let currentFilters = null;

    if (this.props.lessonsActive) {
      currentFilters = this.state.lessonFiltersCount;
    } else if (this.props.modulesActive) {
      currentFilters = this.state.moduleFiltersCount;
    }
    // set all filter counts to 0
    for (var k in currentFilters) {
      for (var key in currentFilters[k]) {
        currentFilters[k][key] = 0;
      }
    }

    for (var i = 0; i < items.length; i++) {
      if (items[i].filters["grade_id"]) {
        currentFilters["belts"][items[i].filters["grade_id"]] += 1;
      }
      if (items[i].filters["parent_group_id"]) {
        currentFilters["categories"][items[i].filters["parent_group_id"]] += 1;
      }
      if (items[i].filters["level_id"]) {
        currentFilters["levels"][items[i].filters["level_id"]] += 1;
      }
    }

    searchBy = searchBy || this.state.searchBy;

    // if we're dealing with lessons, sort by date with most recent first
    if (this.state.lessons && this.state.lessons.length > 0) {
      items.sort(function (a, b) {
        if (a.page_release_date < b.page_release_date) {
          return 1;
        }
        if (a.page_release_date > b.page_release_date) {
          return -1;
        }
        return 0;
      });
      // if we're dealing with modules, sort by difficulty first then alphabetically
    } else {
      items.sort(function (a, b) {
        // if there is no position or it's 'Spectrum', which has a value of 0, put it at the end of the list.
        if (a.position == null || a.position == 0) {
          return +1;
        } else if (b.position == null || b.position == 0) {
          return -1;
        }

        if (a.position < b.position) {
          return -1;
        }
        if (a.position > b.position) {
          return 1;
        }
        if (a.position == b.position) {
          // if grade is the same, sort alphabetically
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return +1;
          }
          return 0;
        }
      });
    }

    if (this.props.lessonsActive) {
      this.setState({
        lessons: items,
        activeIndex: 0,
        searchBy: searchBy,
        filterCount: currentFilters,
      });
    } else if (this.props.modulesActive) {
      this.setState({
        modules: items,
        activeIndex: 0,
        searchBy: searchBy,
        filterCount: currentFilters,
      });
    }

    if (window.innerWidth < 769) {
      const sortedList = ReactDOM.findDOMNode(this.refs.scrollTo);
      sortedList.scrollIntoView();
      window.scrollBy(0, -75);
    }
  };

  render() {
    return (
      <div className="lesson-list container-fluid">
        <div className="row">
          <div className="col-xs-12 col-sm-4 col-md-3 lesson-list__container">
            <div className="lesson-list__filters">
              {this.state.lessons && this.props.lessonsActive && (
                <FilterLessonItems
                  itemType="lesson"
                  itemTotal={this.state.itemTotal}
                  filterCount={this.state.lessonFiltersCount}
                  items={this.state.lessons}
                  lessonCount={this.state.lessonFiltersCount}
                  filterBy={this.state.filterBy}
                  onFiltersChanged={this.onItemsFiltered}
                />
              )}{" "}
              {this.state.modules && this.props.modulesActive && (
                <FilterLessonItems
                  itemType="module"
                  itemTotal={this.state.itemTotal}
                  filterCount={this.state.moduleFiltersCount}
                  items={this.state.modules}
                  moduleCount={this.state.moduleFiltersCount}
                  filterBy={this.state.filterBy}
                  onFiltersChanged={this.onItemsFiltered}
                />
              )}
            </div>
          </div>
          <div className="col-xs-12 col-sm-8 col-md-9" ref="scrollTo">
            {this.state.lessons && this.props.lessonsActive && this.state.lessons.length > 0 && (
              <SortableList
                items={this.state.lessons}
                sortBy={this.state.sortBy}
                activeIndex={0}
                limit={9}
                type="lesson"
              />
            )}{" "}
            {this.state.modules && this.props.modulesActive && this.state.modules.length > 0 && (
              <SortableList
                items={this.state.modules}
                sortBy={this.state.sortBy}
                activeIndex={0}
                limit={9}
                type="module"
              />
            )}{" "}
            {this.state.lessons && this.state.lessons.length < 1 && (
              <div className="lesson-list__none alert alert-info">
                <p>{AppConfig.data.lessonList.noLessonsAlert}</p>
              </div>
            )}{" "}
            {this.state.modules && this.state.modules.length < 1 && (
              <div className="module-list__none alert alert-info">
                <p>{AppConfig.data.moduleList.noModulesAlert}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
