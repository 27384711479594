import React, { useEffect, useState } from "react";
// import Breadcrumbs from "./Breadcrumbs";
import { withBootstrapSize } from "../../components/shared/WithBootstrapSize";
import OverviewSwoosh from "./OverviewSwoosh";
import { ArrowDownIcon, ArrowUpIcon } from "../../components/shared/Icons";

const locationPath = window.location.pathname.replace(/^\/+|\/+$/g, "");
const locationPathArr = locationPath.split("/");
const locationHash = window.location.hash ? window.location.hash.slice(1) : null;

const Category = withBootstrapSize(
  ({
    faqs,
    bootstrapSize,
    width,
    additionalContent = null,
    inHelpCenter = false,
    searchPhrase = null,
    setSearchPhrase = null,
    searchResults = null,
    setSearchResults = null,
    initialActiveTopicId = null,
    openQuestion = null,
    // breadcrumbs,
    // showBreadcrumbs = true,
  }) => {
    if (!faqs || faqs.length === 0) {
      return null;
    }

    let activeTopic;
    let activeQuestion;

    const initializeActiveTopic = () => {
      return locationPathArr.length >= 3 &&
        (activeTopic = faqs.children.find((child) => child.slug === locationPathArr[2]))
        ? activeTopic.id
        : initialActiveTopicId &&
          (activeTopic = faqs.children.find((child) => child.id === initialActiveTopicId))
        ? activeTopic.id
        : faqs.children[0].id;
    };

    const initializeActiveQuestion = () => {
      return activeTopic &&
        locationHash &&
        (activeQuestion = activeTopic.children.find((child) => child.slug === locationHash))
        ? activeQuestion.id
        : null;
    };

    const [activeTopicId, setActiveTopicId] = useState(null);
    const [activeQuestionId, setActiveQuestionId] = useState(null);

    useEffect(() => {
      if (locationHash && inHelpCenter) {
        setTimeout(() => {
          document.getElementById(locationHash).scrollIntoView();
        }, 0);
      }
    }, []);

    useEffect(() => {
      setActiveTopicId(initializeActiveTopic());
      setActiveQuestionId(initializeActiveQuestion());
    }, [faqs, initialActiveTopicId]);

    const setActiveTopic = (id) => {
      if (activeTopicId != id) {
        setActiveQuestionId(null);
      }

      setActiveTopicId(id);

      if (inHelpCenter) {
        locationPathArr[2] = faqs.children.find((faq) => faq.id === id)?.slug || "";
        window.history.replaceState({}, "", `/${locationPathArr.join("/")}`);
      }
    };

    const setActiveQuestion = (activeTopicId, questionId, questionSlug = null) => {
      if (activeQuestionId === questionId) {
        setActiveQuestionId(null);

        if (inHelpCenter) {
          history.pushState({}, "", window.location.pathname);
        }
      } else {
        setActiveTopicId(activeTopicId);
        setActiveQuestionId(questionId);

        if (inHelpCenter && questionSlug) {
          history.pushState({}, "", `#${questionSlug}`);
        }
      }
    };

    // if (openQuestion) {
    //   setActiveQuestion(openQuestion.activeTopicId, openQuestion.questionId);
    // }

    useEffect(() => {
      if (openQuestion) {
        setActiveTopic(openQuestion.activeTopicId);
        setActiveQuestion(openQuestion.activeTopicId, openQuestion.questionId);
      }
    }, [openQuestion]);

    const question = faqs.children.find((faq) => faq.id == activeTopicId);

    return (
      <>
        {inHelpCenter && (
          <OverviewSwoosh
            searchPhrase={searchPhrase}
            setSearchPhrase={setSearchPhrase}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
          />
        )}
        <div className={`${inHelpCenter ? "container " : ""}category`}>
          {/* {showBreadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} headerWidth dark />} */}
          <h2 className="uppercase header2 text-center">{faqs.title} FAQS</h2>
          {additionalContent}
          <div className="content">
            <div className="topics">
              <h3>categories</h3>
              {!["xsDevice", "smDevice", "mdDevice"].includes(bootstrapSize) ? (
                <div className="nav">
                  {faqs.children.map((topic, idx) => (
                    <button
                      key={idx}
                      className={topic.id === activeTopicId ? "active" : null}
                      onClick={() => setActiveTopic(topic.id)}
                    >
                      {topic.title}
                    </button>
                  ))}
                </div>
              ) : (
                <select
                  value={activeTopicId || ""}
                  onChange={(e) => {
                    setActiveTopic(parseInt(e.target.value));
                  }}
                >
                  {faqs.children.map((topic, idx) => (
                    <option key={idx} value={topic.id}>
                      {topic.title}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="accordion questions">
              {question && question.content && (
                <>
                  <h3
                    className="accordion__content__title"
                    id={width > 992 ? question.slug : null}
                    style={width > 992 ? { marginTop: "-115px", paddingTop: "115px" } : null}
                  >
                    {question.title}
                  </h3>
                  <div
                    className="accordion__content__body"
                    dangerouslySetInnerHTML={{
                      __html: question.content,
                    }}
                  ></div>
                </>
              )}
              {question &&
                !question.content &&
                question.children &&
                question.children.map((childQuestion, idx) => (
                  <div
                    key={idx}
                    id={width > 992 ? childQuestion.slug : null}
                    className={`accordion__content${
                      activeQuestionId == childQuestion.id ? " open" : ""
                    }`}
                  >
                    <div
                      className="accordion__content__header"
                      onClick={() =>
                        setActiveQuestion(activeTopicId, childQuestion.id, childQuestion.slug)
                      }
                      data-id={childQuestion.id}
                    >
                      <h4>{childQuestion.title}</h4>
                      {activeQuestionId == childQuestion.id ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </div>
                    {activeQuestionId == childQuestion.id && childQuestion.content && (
                      <div
                        className="accordion__content__body"
                        dangerouslySetInnerHTML={{
                          __html: childQuestion.content,
                        }}
                      ></div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default withBootstrapSize(Category);
