import React from "react";
import UserItemDummyButton from "../components/UserItemDummyButton";
import AddPracticeItemToDashboard from "../components/AddPracticeItemToDashboard";
import AddPracticeRoutineToDashboard from "../components/AddPracticeRoutineToDashboard";

export default class AddToPracticeRoutine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showSave(existingIds, thisId) {
    return existingIds.includes(thisId) ? false : true;
  }

  render() {
    let userPracticeItems = this.props.userPracticeItems || [];
    const itemIds = userPracticeItems.map((item) => item.practice_item_id);
    const adminPracticeItems = this.props.practiceItems || [];
    const adminPracticeRoutines = this.props.practiceRoutines || [];
    let { userPracticeRoutines } = this.props;

    if (!this.props.user) {
      return (
        <div>
          <UserItemDummyButton clickedText={true} extraText={"to save practice items"} />
        </div>
      );
    } else {
      return (
        <div className="practice-item-save styleized">
          {adminPracticeItems.map((item) => (
            <div key={item.id} className="practice-item-save__container">
              <AddPracticeItemToDashboard
                item={item}
                userPracticeRoutines={this.props.userPracticeRoutines}
                showSave={this.showSave(itemIds, item.id)}
                userNotes={this.props.userNotes}
              />
            </div>
          ))}

          {adminPracticeRoutines.map((item) => (
            <AddPracticeRoutineToDashboard
              key={item.id}
              routine={item}
              userPracticeRoutines={this.props.userPracticeRoutines}
              practiceItems={adminPracticeItems}
              userPracticeItems={userPracticeItems}
            />
          ))}
        </div>
      );
    }
  }
}
