import React from "react";
import { productsMenuItems } from "./productsMenuItems";
import Column from "../Column";
import DonateTile from "../DonateTile";

const ColumnPlaceholder = () => <div className="menu-item__column" />;

const ProductsMenu = () => {
  return (
    <div>
      {/* <div className="menu-item__group menu-item__group--top">
        <div className="menu-item__column">
          <a className="menu-item__link" href="/products">
            All Products
          </a>
        </div>
        <ColumnPlaceholder />
        <ColumnPlaceholder />
      </div> */}
      <div className="store__row">
        {productsMenuItems.sections.map((item, idx) => (
          <Column item={item} key={idx} />
        ))}
        <DonateTile />
      </div>
      {/*<p className="donation">*/}
      {/*  🚨 <a href="/bundle">SPECIAL OFFER AVAILABLE!</a> Get the JustinGuitar Ultimate Bundle and*/}
      {/*  save over USD 400.*/}
      {/*</p>*/}
    </div>
  );
};

export default ProductsMenu;
