import { Components } from "@mui/material";

import { buttons } from "./buttons";
import { typography } from "./typography";
import { stepper } from "./stepper";
import { paper } from "./paper";
import { stepLabel } from "./step-label";
import { input } from "./input";
import { outlinedInput } from "./outlined-input";
import { inputLabel } from "./input-label";
import { textField } from "./text-field";
import { select } from "./select";
import { menu } from "./menu";
import { formControl } from "./form-control";
import { accordion } from "./accordion";
import { inputBase } from "./input-base";

export const components: Components = {
  MuiButton: buttons,
  MuiTypography: typography,
  MuiStepper: stepper,
  MuiStepLabel: stepLabel,
  MuiPaper: paper,
  MuiInput: input,
  MuiOutlinedInput: outlinedInput,
  MuiInputLabel: inputLabel,
  MuiTextField: textField,
  MuiSelect: select,
  MuiMenu: menu,
  MuiFormControl: formControl,
  MuiAccordion: accordion,
  MuiInputBase: inputBase,
};
