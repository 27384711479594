import React from "react";
import { CDN_URL } from "../../shared/CdnUrl";

const SongRequestsCarouselItem = ({ songRequest }) => {
  const { songSlug, albumImage, artist, title, submittedBy, gradeId } = songRequest.attributes;

  function goToSong() {
    window.location.href = `/songs/${songSlug}`;
  }

  return (
    <div className="song-grid-item song-request-carousel-item">
      <div className="image-container">
        <div className={`song-image clickable`} onClick={goToSong}>
          <img
            src={
              albumImage
                ? `${CDN_URL}/system/dragonfly/production/${albumImage}`
                : `${CDN_URL}/images/all-songs/album-placeholder.png`
            }
            alt={`${title} by ${artist}`}
          />
        </div>
      </div>
      <div className="song-detail clickable" onClick={goToSong}>
        <div>
          <span className={`grade-badge grade-badge--grade-${gradeId}`}>
            {gradeId == null ? "No Grade" : gradeId === 10 ? "Grade 0" : `Grade ${gradeId}`}
          </span>
        </div>
        <div className="song-grid-item__title">{title}</div>
        <div className="song-grid-item__artist">{artist}</div>
      </div>
      <p>
        <span>Submitted by: </span>
        <span className="username">{submittedBy || "JustinGuitar Team"}</span>
      </p>
    </div>
  );
};

export default SongRequestsCarouselItem;
