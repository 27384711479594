import React, { useState, useEffect, useRef } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Loading from "../../components/shared/Loading";
import CurrentUserBadge from "../../components/header/CurrentUserBadge";
import * as Yup from "yup";

const CameraIcon = () => (
  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 3V0H5V3H8V5H5V8H3V5H0V3H3ZM6 9V6H9V3H16L17.83 5H21C22.1 5 23 5.9 23 7V19C23 20.1 22.1 21 21 21H5C3.9 21 3 20.1 3 19V9H6ZM13 18C15.76 18 18 15.76 18 13C18 10.24 15.76 8 13 8C10.24 8 8 10.24 8 13C8 15.76 10.24 18 13 18ZM9.8 13C9.8 14.77 11.23 16.2 13 16.2C14.77 16.2 16.2 14.77 16.2 13C16.2 11.23 14.77 9.8 13 9.8C11.23 9.8 9.8 11.23 9.8 13Z"
      fill="#FD6A02"
    />
  </svg>
);

const ImageInput = ({ user, openDialog, submitForm, imageRemoved }) => {
  let formikRef = useRef(null);
  const [editing, setEditing] = useState(false);
  const [uploadedImage, setUploadedImage] = useState("");

  const formDisabled = (props) => {
    if (props.dirty && Object.keys(props.errors).length === 0 && !props.isSubmitting) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    formikRef.setFieldValue("image", uploadedImage);
    formikRef.setFieldTouched("image");
  }, [uploadedImage]);

  useEffect(() => {
    if (imageRemoved === true) {
      setUploadedImage("");
      setEditing(false);
    }
  }, [imageRemoved]);

  return (
    <Formik
      innerRef={(ref) => (formikRef = ref)}
      initialValues={{
        image: "",
      }}
      validationSchema={Yup.object().shape({
        image:
          uploadedImage &&
          Yup.mixed()
            .test("fileSize", "File too large", () => {
              return uploadedImage && uploadedImage.size <= 500 * 1024;
            })
            .test(
              "fileFormat",
              "Unsupported image format",
              () => uploadedImage && ["image/jpeg", "image/png"].includes(uploadedImage.type)
            ),
      })}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        const data = {};

        if (uploadedImage) {
          const reader = new FileReader();
          reader.readAsDataURL(uploadedImage);

          reader.onload = () => {
            data["image"] = {
              b64: reader.result,
              name: uploadedImage.name,
              type: uploadedImage.type,
              size: uploadedImage.size,
            };

            submitForm(data, setSubmitting, setErrors, setEditing);
          };
        }
      }}
    >
      {(props) => (
        <Form style={{ flexDirection: "row", alignItems: "center", position: "relative" }}>
          {props.isSubmitting && <Loading />}
          <div className="form-input">
            <span className="label">profile picture</span>
            <div
              className="current-user-badge-wrapper"
              onClick={() => {
                if (editing) {
                  document.getElementById("image").click();
                }
              }}
            >
              <CurrentUserBadge
                size="large"
                clickable={false}
                imgPath={user.data.attributes.imageUid}
                imgBlob={uploadedImage}
              />
              {editing && !props.dirty && (
                <div className="camera-overlay">
                  <CameraIcon />
                </div>
              )}
            </div>
            {!editing ? (
              <button onClick={() => setEditing(true)} className="edit">
                edit
              </button>
            ) : (
              <>
                <Field
                  name="image"
                  id="image"
                  type="file"
                  accept=".png,.jpeg"
                  style={{ display: "none" }}
                  value=""
                  onChange={(e) => {
                    setUploadedImage(e.target.files[0]);
                  }}
                />
                <ErrorMessage name="image" component="div" className="error" />
              </>
            )}
          </div>
          {editing && (
            <>
              <div className="form-controls">
                <button type="submit" disabled={formDisabled(props)} className="save">
                  save
                </button>
                <button
                  onClick={() => {
                    setEditing(false);
                    props.resetForm();
                  }}
                  className="cancel"
                >
                  cancel
                </button>
              </div>
              {user.data.attributes.imageUid && (
                <button
                  type="button"
                  className="remove-image"
                  onClick={() => {
                    openDialog({
                      message: "Delete profile picture?",
                      confirmButtonText: "Delete",
                      data: user,
                    });
                  }}
                >
                  remove picture
                </button>
              )}
              {/*<p>Maximum upload file size: 500 KB.</p>*/}
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ImageInput;
