import React from "react";
import { connect, dispatch } from "react-redux";
import TabContainer from "../components/shared/tabs/TabContainer";
import { setActiveTab } from "../components/shared/ui/uiSlice";
import { withInnerWidth } from "../components/shared/WithInnerWidth";

class LessonTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener("resize", this.preventLessonTabOnLgDevices);

    const urlHash = location.hash ? location.hash.replace("#", "") : null;

    if (urlHash === "discussion") {
      this.props.setActiveTab("Discussion");
    }
    if (urlHash === "notes") {
      this.props.setActiveTab("Notes");
    }
    if (urlHash === "resources") {
      this.props.setActiveTab("Resources");
    }
    if (urlHash === "practice") {
      this.props.setActiveTab("Practice");
    }
  }

  preventLessonTabOnLgDevices = () => {
    let { width, activeTab, setActiveTab } = this.props;
    // Lessons tab is only visible on tablet and smaller. if that tab is selected and window expands,
    // switch back to default tab.
    if (width > 768 && activeTab === "Lessons") {
      setActiveTab("Learn More");
    }
  };

  render() {
    const { lesson, activeTab, session, needsLogin, token, paywall } = this.props;
    const {
      body,
      reference,
      title,
      slug,
      id,
      vimeoDownloadAvailable,
      discourseTopicId,
      groupReference,
      giveawayTag,
      giveawayTerms,
      matchMySoundId,
    } = lesson.data.attributes;

    const isGiveaway = giveawayTag && giveawayTerms;

    return (
      <TabContainer
        activeTab={activeTab}
        informationHtml={body}
        reference={reference}
        title={title}
        currentUser={session.currentUser}
        objectType="Lesson"
        objectId={id}
        relativePath={`/guitar-lessons/${slug}`}
        needsLogin={needsLogin}
        needsPayment={paywall != null}
        token={token}
        vimeoDownloadAvailable={vimeoDownloadAvailable}
        topicId={discourseTopicId}
        isGiveaway={isGiveaway}
        object={lesson}
        matchMySoundId={matchMySoundId}
      />
    );
  }
}

const LessonTabsWithWidth = withInnerWidth(LessonTabs);

const mapStateToProps = (state) => ({
  lesson: state.entity.lesson,
  activeTab: state.ui.activeTab,
  session: state.session,
  needsLogin: state.session.needsLogin,
  token: state.session.token,
  paywall: state.entity.paywall,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LessonTabsWithWidth);
