import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { LightTooltip } from "../../../components/shared/ui/Tooltips";

const Play = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 16.5V7.5L16 12L10 16.5Z"
        fill="#6ACF64"
      />
    </svg>
  );
};

const Stop = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16H16V8H8V16ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
        fill="#EA424D"
      />
    </svg>
  );
};

const SpeedGauge1 = () => {
  return (
    <svg
      width="47"
      height="26"
      viewBox="0 0 47 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: "24px" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.90803 4.90771C6.13028 7.07163 3.88372 9.84166 2.33986 13.0063C0.796008 16.1709 -0.00430824 19.6465 1.74421e-05 23.1677H10.0637C10.0633 20.9655 10.618 18.7988 11.6765 16.8678C12.735 14.9367 14.2632 13.3037 16.1198 12.1194L8.90803 4.90771Z"
        fill="#6ACF64"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.3333 23.1677C46.3359 19.6468 45.5349 16.1718 43.9912 13.0074C42.4474 9.84303 40.2018 7.07277 37.4253 4.90771L30.2061 12.1194C32.0641 13.3021 33.5934 14.9349 34.6521 16.8663C35.7108 18.7977 36.2646 20.9651 36.2622 23.1677H46.3333Z"
        fill="#1D3446"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6267 4.3026C32.6964 1.50395 27.9916 0 23.1667 0C18.3418 0 13.637 1.50395 9.70673 4.3026L17.0028 11.6061C18.8997 10.5982 21.0149 10.0711 23.163 10.0711C25.311 10.0711 27.4263 10.5982 29.3232 11.6061L36.6267 4.3026Z"
        fill="#1D3446"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6598 20.9996L14 17.7318L21.6599 24.4635C21.6598 24.4636 21.6598 24.4636 21.6598 24.4636C21.66 24.4637 21.6601 24.4638 21.6603 24.4639C22.6168 25.0158 23.8397 24.688 24.3919 23.7316C24.9441 22.7751 24.6165 21.5522 23.6603 20.9998C23.6601 20.9997 23.66 20.9996 23.6598 20.9995C23.6598 20.9995 23.6598 20.9995 23.6598 20.9996Z"
        fill="#1D3446"
      />
    </svg>
  );
};

const SpeedGauge2 = () => {
  return (
    <svg
      width="47"
      height="25"
      viewBox="0 0 47 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: "24px" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.90803 4.90771C6.13028 7.07163 3.88372 9.84166 2.33986 13.0063C0.796008 16.1709 -0.00430824 19.6465 1.74421e-05 23.1677H10.0637C10.0633 20.9655 10.618 18.7988 11.6765 16.8678C12.735 14.9367 14.2632 13.3037 16.1198 12.1194L8.90803 4.90771Z"
        fill="#6ACF64"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.3333 23.1677C46.3359 19.6468 45.5349 16.1718 43.9912 13.0074C42.4474 9.84303 40.2018 7.07277 37.4253 4.90771L30.2061 12.1194C32.0641 13.3021 33.5934 14.9349 34.6521 16.8663C35.7108 18.7977 36.2646 20.9651 36.2622 23.1677H46.3333Z"
        fill="#1D3446"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6267 4.3026C32.6964 1.50395 27.9916 0 23.1667 0C18.3418 0 13.637 1.50395 9.70673 4.3026L17.0028 11.6061C18.8997 10.5982 21.0149 10.0711 23.163 10.0711C25.311 10.0711 27.4263 10.5982 29.3232 11.6061L36.6267 4.3026Z"
        fill="#6ACF64"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9999 22.9995L23 13L21.0001 22.9995C21 22.9995 21 22.9995 21 22.9995C21 22.9997 21 22.9998 21 23C21.0003 24.1043 21.8956 24.9995 23 24.9995C24.1044 24.9995 24.9997 24.1043 25 23C25 22.9998 25 22.9997 25 22.9995C25 22.9995 25 22.9995 24.9999 22.9995Z"
        fill="#1D3446"
      />
    </svg>
  );
};

const SpeedGauge3 = () => {
  return (
    <svg
      width="47"
      height="26"
      viewBox="0 0 47 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: "24px" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.90803 4.90771C6.13028 7.07163 3.88372 9.84166 2.33986 13.0063C0.796008 16.1709 -0.00430824 19.6465 1.74421e-05 23.1677H10.0637C10.0633 20.9655 10.618 18.7988 11.6765 16.8678C12.735 14.9367 14.2632 13.3037 16.1198 12.1194L8.90803 4.90771Z"
        fill="#6ACF64"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.3333 23.1677C46.3359 19.6468 45.5349 16.1718 43.9912 13.0074C42.4474 9.84303 40.2018 7.07277 37.4253 4.90771L30.2061 12.1194C32.0642 13.3021 33.5934 14.9349 34.6521 16.8663C35.7108 18.7977 36.2646 20.9651 36.2622 23.1677H46.3333Z"
        fill="#6ACF64"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6267 4.3026C32.6964 1.50395 27.9916 0 23.1667 0C18.3418 0 13.637 1.50395 9.70673 4.3026L17.0028 11.6061C18.8997 10.5982 21.0149 10.0711 23.163 10.0711C25.311 10.0711 27.4263 10.5982 29.3232 11.6061L36.6267 4.3026Z"
        fill="#6ACF64"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7307 24.4638L32.3906 17.7321L22.7308 20.9998C22.7308 20.9998 22.7308 20.9998 22.7308 20.9998C22.7307 20.9998 22.7305 20.9999 22.7304 21C21.7741 21.5524 21.4465 22.7754 21.9987 23.7318C22.5509 24.6883 23.7738 25.016 24.7304 24.4641C24.7305 24.464 24.7307 24.4639 24.7308 24.4639C24.7308 24.4639 24.7308 24.4638 24.7307 24.4638Z"
        fill="#1D3446"
      />
    </svg>
  );
};

const AutoscrollTab = ({ activeSheetMusicContent }) => {
  const [autoscroll, setAutoscroll] = useState({
    interval: null,
    speed: null,
  });

  const hasSubpixelScrollSupport = () => {
    const initialScollY = window.scrollY;

    window.scrollBy(0, 0.5);
    window.scrollBy(0, 0.5);

    return initialScollY < window.scrollY;
  };

  const startAutoscroll = (speed = null) => {
    if (autoscroll.interval) {
      clearInterval(autoscroll.interval);
    }

    let interval = null;
    speed = speed || autoscroll.speed;

    if (hasSubpixelScrollSupport()) {
      if (speed === 1) {
        interval = setInterval(() => window.scrollBy(0, 0.5), 50);
      }
      if (speed === 2) {
        interval = setInterval(() => window.scrollBy(0, 0.5), 30);
      }
      if (speed === 3) {
        interval = setInterval(() => window.scrollBy(0, 0.5), 15);
      }

      setAutoscroll({
        interval,
        speed,
      });
    } else {
      if (speed === 1) {
        interval = setInterval(() => window.scrollBy(0, 1), 100);
      }
      if (speed === 2) {
        interval = setInterval(() => window.scrollBy(0, 1), 60);
      }
      if (speed === 3) {
        interval = setInterval(() => window.scrollBy(0, 1), 30);
      }

      setAutoscroll({
        interval,
        speed,
      });
    }
  };

  const stopAutoscroll = () => {
    if (autoscroll.interval) {
      clearInterval(autoscroll.interval);
    }

    setAutoscroll({
      interval: null,
      speed: autoscroll.speed,
    });
  };

  const toggleAutoscroll = () => {
    if (!autoscroll.interval) {
      startAutoscroll();
    } else {
      stopAutoscroll();
    }
  };

  const decreaseAutoscrollSpeed = () => {
    if (autoscroll.interval && autoscroll.speed > 1) {
      startAutoscroll(autoscroll.speed - 1);
      localStorage.setItem("autoscrollSpeed", autoscroll.speed - 1);
    }
  };

  const increaseAutoscrollSpeed = () => {
    if (autoscroll.interval && autoscroll.speed < 3) {
      startAutoscroll(autoscroll.speed + 1);
      localStorage.setItem("autoscrollSpeed", autoscroll.speed + 1);
    }
  };

  useEffect(() => {
    if (localStorage.autoscrollSpeed) {
      setAutoscroll({
        interval: null,
        speed: parseInt(localStorage.autoscrollSpeed),
      });
    } else {
      setAutoscroll({
        interval: null,
        speed: 2,
      });
    }
  }, []);

  useEffect(() => {
    const onKeyDown = (e) => {
      switch (e.code.toLowerCase()) {
        case "minus":
          decreaseAutoscrollSpeed();
          break;
        case "plus":
          increaseAutoscrollSpeed();
          break;
        case "equal":
          increaseAutoscrollSpeed();
          break;
        case "escape":
          stopAutoscroll();
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [autoscroll]);

  useEffect(() => {
    if (activeSheetMusicContent === "Tabs") {
      stopAutoscroll();
    }
  }, [activeSheetMusicContent]);

  return (
    <li
      className={`autoscroll${!autoscroll.interval ? " off" : " on"}${
        activeSheetMusicContent === "Tabs" ? " disabled" : ""
      }`}
    >
      {autoscroll.interval ? (
        <button onClick={() => toggleAutoscroll()}>
          <Stop />
        </button>
      ) : (
        <button onClick={() => toggleAutoscroll()}>
          <Play />
        </button>
      )}

      {!autoscroll.interval ? (
        <span className="change-option__text">Autoscroll</span>
      ) : (
        <>
          <span
            onClick={() => decreaseAutoscrollSpeed()}
            onKeyDown={(e) => e.code.toLowerCase() === "minus" && decreaseAutoscrollSpeed()}
            className={`change-btn change-btn--minus${autoscroll.speed === 1 ? " disabled" : ""}`}
          >
            <span>-</span>
          </span>
          {autoscroll.speed === 3 ? (
            <SpeedGauge3 />
          ) : autoscroll.speed === 2 ? (
            <SpeedGauge2 />
          ) : (
            <SpeedGauge1 />
          )}
          <span
            onClick={() => increaseAutoscrollSpeed()}
            onKeyDown={(e) =>
              e.code.toLowerCase() === "plus" ||
              (e.code.toLowerCase() === "equal" && decreaseAutoscrollSpeed())
            }
            className={`change-btn change-btn--plus${autoscroll.speed === 3 ? " disabled" : ""}`}
          >
            <span>+</span>
          </span>
        </>
      )}

      <LightTooltip
        arrow
        enterTouchDelay={0}
        placement="bottom"
        title={
          <React.Fragment>
            <div className="shortcuts-tooltip">
              <p>shortcuts</p>
              <div>
                <span>stop</span>
                <span>esc</span>
              </div>
              <div>
                <span>change speed</span>
                <div>
                  <span>-</span>
                  <span>+</span>
                </div>
              </div>
            </div>
          </React.Fragment>
        }
      >
        <i className="icon icon-info" />
      </LightTooltip>
    </li>
  );
};

const mapStateToProps = ({ entity: { activeSheetMusicContent } }) => ({
  activeSheetMusicContent,
});

export default connect(mapStateToProps)(AutoscrollTab);
