import React, { useEffect, useState } from "react";
import { DarkTooltip } from "../../components/shared/ui/Tooltips";
import { BootstrapSize, breakpoints } from "../../components/shared/bootstrap-helpers";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import { userSettingEnabled } from "../../components/shared/session/sessionSelectors";
import { connect } from "react-redux";
import { setUserSetting } from "../../components/shared/session/sessionSlice";

const EnvelopeIcon = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 6.99L2 2H18ZM10 9L2 4V14H18V4L10 9Z"
      fill="#44546C"
    />
  </svg>
);

const AutoplayIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00015 9H6.00015V6.70849C5.06826 7.76513 4.42987 9.05168 4.15386 10.4393C3.84518 11.9911 4.00361 13.5997 4.60911 15.0615C5.21461 16.5233 6.23999 17.7727 7.55558 18.6518C8.87118 19.5308 10.4179 20 12.0001 20C13.5824 20 15.1291 19.5308 16.4447 18.6518C17.7603 17.7727 18.7857 16.5233 19.3912 15.0615C19.9967 13.5997 20.1551 11.9911 19.8464 10.4393C19.5377 8.88743 18.7758 7.46197 17.657 6.34314L16.9499 5.63604L18.3641 4.22182L19.0712 4.92893C20.4697 6.32746 21.4221 8.10929 21.808 10.0491C22.1938 11.9889 21.9958 13.9996 21.2389 15.8268C20.4821 17.6541 19.2003 19.2159 17.5558 20.3147C15.9114 21.4135 13.978 22 12.0001 22C10.0223 22 8.08893 21.4135 6.44444 20.3147C4.79995 19.2159 3.51823 17.6541 2.76135 15.8268C2.00447 13.9996 1.80644 11.9889 2.19229 10.0491C2.57164 8.14199 3.4986 6.38759 4.85872 5L2.00015 5L2.00015 3H6.00015H8.00015V5L8.00015 9ZM16.0001 11.5L10.0001 16V7L16.0001 11.5Z"
      fill="#1D3446"
    />
  </svg>
);

const AutocompleteIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 9H6V6.70849C5.06812 7.76513 4.42973 9.05168 4.15372 10.4393C3.84504 11.9911 4.00347 13.5997 4.60897 15.0615C5.21447 16.5233 6.23985 17.7727 7.55544 18.6518C8.87103 19.5308 10.4178 20 12 20C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34314L16.9498 5.63604L18.364 4.22182L19.0711 4.92893C20.4696 6.32746 21.422 8.10929 21.8079 10.0491C22.1937 11.9889 21.9957 13.9996 21.2388 15.8268C20.4819 17.6541 19.2002 19.2159 17.5557 20.3147C15.9112 21.4135 13.9778 22 12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51808 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.5715 8.14199 3.49846 6.38759 4.85858 5L2 5L2 3H6H8V5L8 9ZM17.5 9.5L16 8L10.51 13.5L7.5 10.5L6 12L10.51 16.5L17.5 9.5Z"
      fill="#1D3446"
    />
  </svg>
);

const LeftHandIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 14.72L8.76 22.9257C9.4 23.6114 10.3029 24 11.2514 24L18.1429 24C20.04 24 21.5714 22.4686 21.5714 20.5714L21.5714 13.4286L21.5714 11.4286L21.5714 4.85714C21.5714 4.06857 20.9314 3.42857 20.1429 3.42857C19.3543 3.42857 18.7143 4.06857 18.7143 4.85714L18.7143 11.4286L17.5714 11.4286L17.5714 2.57143C17.5714 1.78286 16.9314 1.14286 16.1429 1.14286C15.3543 1.14286 14.7143 1.78286 14.7143 2.57143L14.7143 11.4286L13.5714 11.4286L13.5714 1.42857C13.5714 0.639998 12.9314 -1.59442e-06 12.1429 -1.55995e-06C11.3543 -1.52548e-06 10.7143 0.639999 10.7143 1.42857L10.7143 11.4286L9.57143 11.4286L9.57143 3.71428C9.57143 2.92571 8.93143 2.28571 8.14286 2.28571C7.35429 2.28571 6.71429 2.92571 6.71429 3.71428L6.71429 15.0171C5.44571 14.2857 3.76571 13.3257 3.73143 13.3143C3.54857 13.2114 3.35429 13.1543 3.12571 13.1543C2.82857 13.1543 2.55429 13.2571 2.32571 13.4514C2.28 13.4629 1 14.72 1 14.72Z"
      fill="#1D3446"
    />
  </svg>
);

const Preferences = ({ width, openAlert, userSettingEnabled, setUserSetting }) => {
  const [newsletterSubscriptionActive, setNewsletterSubscriptionActive] = useState("0");

  useEffect(() => {
    let subscriptionStatus = sessionStorage.getItem("justin-guitar-newsletter");

    if (subscriptionStatus === "true") {
      subscriptionStatus = "1";
    }
    if (subscriptionStatus === "false") {
      subscriptionStatus = "0";
    }

    if (subscriptionStatus === null) {
      axiosWithCSRF()
        .get("/subscription")
        .then((response) => {
          sessionStorage.setItem(
            "justin-guitar-newsletter",
            response.data.subscription_status === "subscribed" ? "1" : "0"
          );
          setNewsletterSubscriptionActive(
            response.data.subscription_status === "subscribed" ? "1" : "0"
          );
        });
    } else {
      setNewsletterSubscriptionActive(subscriptionStatus == "1" ? "1" : "0");
    }
  }, []);

  const toggleNewsletterSubscription = () => {
    axiosWithCSRF()
      .post("/subscription", {
        subscribed: newsletterSubscriptionActive == "1" ? false : true,
      })
      .then((response) => {
        openAlert({ message: response.data.message, severity: "success" });
        sessionStorage.setItem(
          "justin-guitar-newsletter",
          newsletterSubscriptionActive == "1" ? "0" : "1"
        );
        setNewsletterSubscriptionActive(newsletterSubscriptionActive == "1" ? "0" : "1");
      })
      .catch((error) => {
        if (
          error.response.data.message &&
          error.response.data.message.includes("is already a list member")
        ) {
          sessionStorage.setItem("justin-guitar-newsletter", "1");
          setNewsletterSubscriptionActive("1");
        }
        openAlert({
          message: "An error occurred. Please try later.",
          severity: "error",
        });
      });
  };

  const toggleAutoplay = () => {
    axiosWithCSRF()
      .post("/settings", {
        setting_name: "autoplay",
        setting_value: !userSettingEnabled("autoplay"),
      })
      .then((res) => {
        setUserSetting({ autoplay: !userSettingEnabled("autoplay") });
        openAlert({ message: res.data.message, severity: "success" });
      })
      .catch((err) => {
        openAlert({
          message: "There was an error saving your settings.",
          severity: "error",
        });
      });
  };

  const toggleAutocomplete = () => {
    axiosWithCSRF()
      .post("/settings", {
        setting_name: "autocomplete",
        setting_value: !userSettingEnabled("autocomplete"),
      })
      .then((res) => {
        setUserSetting({ autocomplete: !userSettingEnabled("autocomplete") });
        openAlert({ message: res.data.message, severity: "success" });
      })
      .catch((err) => {
        openAlert({
          message: "An error occurred. Please try later.",
          severity: "error",
        });
      });
  };

  const toggleLeftHand = () => {
    axiosWithCSRF()
      .post("/settings", {
        setting_name: "left-hand",
        setting_value: !userSettingEnabled("left-hand"),
      })
      .then((res) => {
        setUserSetting({ "left-hand": !userSettingEnabled("left-hand") });
        openAlert({ message: res.data.message, severity: "success" });
      })
      .catch((err) => {
        openAlert({
          message: "An error occurred. Please try later.",
          severity: "error",
        });
      });
  };

  return (
    <div className="account__privacy">
      <div>
        <div className="form-toggle">
          <span>
            <EnvelopeIcon /> Monthly Newsletter Subscription
          </span>
          <button
            className={`toggle ${newsletterSubscriptionActive === "1" ? "active" : ""}`}
            onClick={() => {
              toggleNewsletterSubscription();
            }}
          ></button>
          <DarkTooltip
            arrow
            enterTouchDelay={0}
            placement={width <= breakpoints[BootstrapSize.mdDevice] ? "bottom" : "right"}
            title="I will not give, sell or pass on your details to anyone unless you expressly consent that I can do so (except in the very limited circumstances as set out in the Privacy Policy below). Easy :)"
          >
            <button type="button" className="info">
              <i className="icon icon-info"></i>
            </button>
          </DarkTooltip>
        </div>
        <div className="form-toggle">
          <span>
            <AutoplayIcon /> Lesson Autoplay
          </span>
          <button
            className={`toggle ${userSettingEnabled("autoplay") ? "active" : ""}`}
            onClick={() => {
              toggleAutoplay();
            }}
          ></button>
        </div>
        <div className="form-toggle">
          <span>
            <AutocompleteIcon /> Automatically Mark Lessons as Complete
          </span>
          <button
            className={`toggle ${userSettingEnabled("autocomplete") ? "active" : ""}`}
            onClick={() => {
              toggleAutocomplete();
            }}
          ></button>
        </div>
        <div className="form-toggle">
          <span>
            <LeftHandIcon /> Prefer Left-hand Chord Box
          </span>
          <button
            className={`toggle ${userSettingEnabled("left-hand") ? "active" : ""}`}
            onClick={() => {
              toggleLeftHand();
            }}
          ></button>
        </div>
      </div>
      <div>
        <h3>Privacy Policy</h3>
        <p>
          Review JustinGuitar’s <a href="/privacy-policy">Terms & Conditions and Privacy Policy</a>.
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userSettingEnabled: (settingName) => userSettingEnabled(state, settingName),
});

const mapDispatchToProps = (dispatch) => ({
  setUserSetting: (data) => dispatch(setUserSetting(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
