import React, { useCallback } from "react";
import { IEbookSubSection } from "./ebooks.interface";
import { Box, Button, SxProps, Typography, styled, useMediaQuery } from "@mui/material";
import { jgDenimColors } from "../../jg-material/theme/jg-denim-colors";
import { EbookItemGrid } from "./EbookItemGrid";
import { breakpoints } from "../../jg-material/theme/breakpoints";
import { SelectGeneric } from "../../components/shared/ui/SelectGeneric";

interface EbookSubSectionProps extends Partial<IEbookSubSection> {
  sx?: SxProps;
}

export const EbookSubSection: React.FC<EbookSubSectionProps> = (props) => {
  const { items, title, sectionButtonUrl, sectionButtonText } = props;

  const [filter, setFilter] = React.useState("");

  const buttonClick = useCallback((url: string | undefined) => {
    if (url) {
      window.location.href = url;
    }
  }, []);

  const isXLScreen = useMediaQuery(`(min-width:${breakpoints.values?.xl}px)`);

  return (
    <SubSectionBox sx={props.sx}>
      <Typography variant="h6">{title}</Typography>
      <Divider />
      {!isXLScreen && false && (
        <SelectContainer>
          <SelectLabel variant="h8">Filter by</SelectLabel>
          <SelectGeneric
            items={[]}
            value={filter}
            onChange={(filter) => setFilter(filter?.id || "")}
          />
        </SelectContainer>
      )}
      <EbookItemGrid
        items={items || []}
        sectionButtonUrl={sectionButtonUrl}
        style={{ paddingTop: isXLScreen ? "50px" : "20px" }}
      />
      {!!sectionButtonUrl && (
        <ActionRow>
          <Button onClick={() => buttonClick(sectionButtonUrl)}>{sectionButtonText}</Button>
        </ActionRow>
      )}
    </SubSectionBox>
  );
};

const SubSectionBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const Divider = styled("div")(({ theme }) => ({
  width: "62vw", //899, // ###percentage
  maxWidth: "900px",
  height: 2,
  backgroundColor: jgDenimColors.action.denim.disabled,
  marginTop: 10,
  [theme.breakpoints.down("lg")]: {
    width: 300,
  },
}));

const ActionRow = styled("div")({
  paddingTop: "50px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const SelectLabel = styled(Typography)({
  color: jgDenimColors.denim.light,
  paddingTop: "20px",
  textTransform: "none",
});

const SelectContainer = styled("div")({
  width: "320px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});
