import PropTypes from "prop-types";
import React from "react";

export default class SongListItem extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props, _railsContext) {
    super(props);
    this.state = { data: this.props.data };
  }

  render() {
    let item = this.state.data;

    if (this.props.width <= 576) {
      return (
        <a href={item.path} className={"song-list-item"}>
          <div className="col__container col__container--image">
            <div className="col">
              <img src={item.artist_image} alt={item.original_artist + " - " + item.title} />
            </div>
          </div>

          <div className="col__container col__container--song">
            <div className="col">
              <span className="paragraph">{item.title}</span>
            </div>
            <div className="col">
              <p className="paragraph">{item.original_artist}</p>
            </div>
          </div>

          <div className="col__container col__container--release">
            <div className="col">
              <p className="paragraph">{(item.grade && item.grade.belt) || "none"}</p>
            </div>
            <div className="col">
              <p className="paragraph">{item.release_date[2] || "none"}</p>
            </div>
          </div>
        </a>
      );
    } else {
      return (
        <a href={item.path} className={"song-list-item"}>
          <div className="col">
            <img src={item.artist_image} alt={item.original_artist + " - " + item.title} />
          </div>

          <div className="col">
            <span className="paragraph">{item.title}</span>
          </div>
          <div className="col">
            <p className="paragraph">{item.original_artist}</p>
          </div>

          <div className="col">
            <p className="paragraph">{(item.grade && item.grade.belt) || "none"}</p>
          </div>
          <div className="col">
            <p className="paragraph">{item.release_date[0] || "none"}</p>
          </div>
        </a>
      );
    }
  }
}
