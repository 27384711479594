import React from "react";
import { connect } from "react-redux";

class Copyright extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  copyrightToggle = () => {
    return (
      <span onClick={() => this.setState({ open: !this.state.open })}>
        Copyright Information{" "}
        <i className={"icon icon-angle-" + (this.state.open ? "up" : "down")}></i>
      </span>
    );
  };

  render() {
    const { sheetMusic } = this.props;
    if (!sheetMusic.copyright_info || sheetMusic.copyright_info.length === 0) {
      return null;
    } else if (this.state.open) {
      return (
        <div className="song-sheet__copyright clickable">
          <div dangerouslySetInnerHTML={{ __html: sheetMusic.copyright_info }} />
          {this.copyrightToggle()}
        </div>
      );
    } else {
      return <div className="song-sheet__copyright clickable">{this.copyrightToggle()}</div>;
    }
  }
}

const mapStateToProps = ({ entity: { sheetMusic } }) => ({ sheetMusic });

export default connect(mapStateToProps)(Copyright);
