import React from "react";
import SiteMapHeader from "./SiteMapHeader";
import { orderedLevels } from "./site-map-content";
import SiteMapLevel from "./SiteMapLevel";
import { BootstrapSize, breakpoints } from "../components/shared/bootstrap-helpers";
import { withBootstrapSize } from "../components/shared/WithBootstrapSize";

export default withBootstrapSize(({ width }) => {
  const isCollapsible = width <= breakpoints[BootstrapSize.mdDevice];

  return (
    <div className="site-map">
      <SiteMapHeader />

      <div className="site-map-levels container">
        {orderedLevels.map((level) => (
          <SiteMapLevel key={level.label} level={level} isCollapsible={isCollapsible} />
        ))}
      </div>
    </div>
  );
});
