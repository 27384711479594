import React, { Fragment, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FORM_FIELDS, SIGN_IN_SCHEMA } from "./consts";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { SquareTextField } from "../../../jg-material/theme/components/styled/SquareTextField";
import Loading from "../../shared/Loading";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { OrangeLink } from "../../../jg-material/theme/components/styled/Links";
import { PAGES } from ".";
import Checkbox from "@mui/material/Checkbox";
import { fontFamilies } from "../../../jg-material/theme/typography";
import axiosWithCSRF from "../../shared/axiosWithCSRF";

const CreateAnAccount = ({ setActivePage }) => {
  return (
    <Typography sx={{ textAlign: "center" }}>
      New to JustinGuitar?{" "}
      <OrangeLink sx={{ fontWeight: "bold" }} onClick={() => setActivePage(PAGES.registration)}>
        Create a Free Account
      </OrangeLink>
    </Typography>
  );
};
const SignInForm = ({ setActivePage }) => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(SIGN_IN_SCHEMA),
    mode: "onTouched",
  });

  const onSubmit = (data) => {
    setLoading(true);
    axiosWithCSRF()
      .post("/users/sign_in", {
        user: {
          email: data.email,
          password: data.password,
          remember_me: data.rememberMe,
        },
      })
      .then(() => {
        window.location.reload(true);
      })
      .catch((error) => {
        setError("root.general", {
          type: "401",
          message:
            error?.response?.data?.error || "There was a problem signing you in. Please try again.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const rememberMe = () => {
    const fieldName = "rememberMe";
    return (
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => (
          <FormControl error={!!errors[fieldName]}>
            <FormControlLabel
              sx={{ mb: 0 }}
              control={
                <Checkbox
                  {...field}
                  value={fieldName}
                  sx={{ p: "9px 5px 9px 9px" }}
                  color="primary"
                />
              }
              label={<span>Remember Me</span>}
            />
            {errors[fieldName] && (
              <FormHelperText sx={{ margin: "-10px 0px 10px", fontSize: "12px" }}>
                {errors[fieldName].message}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    );
  };

  const inputField = (formField) => {
    return (
      <Fragment key={formField.name}>
        <Controller
          name={formField.name}
          control={control}
          render={({ field }) => {
            return (
              <SquareTextField
                {...field}
                margin="normal"
                size="medium"
                className={field?.value?.length > 0 ? "Mui-filled" : ""}
                required
                fullWidth
                label={formField.placeholder}
                type={formField.type === "password" && showPassword ? "text" : formField.type}
                error={errors[formField.name] ? true : false}
                helperText={errors[formField.name]?.message}
                InputProps={{
                  endAdornment: formField.type === "password" && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            );
          }}
        />
      </Fragment>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {errors.root?.general && (
        <FormHelperText sx={{ fontSize: "12px" }} error={true}>
          {errors.root.general.message}
        </FormHelperText>
      )}

      {loading && <Loading />}

      {FORM_FIELDS.map((formField) => inputField(formField))}

      <Box display="flex" justifyContent={"space-between"} mb={3}>
        <Grid container alignItems={"center"}>
          <Grid item xs={6}>
            {rememberMe()}
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "end" }}>
            <OrangeLink
              onClick={() => setActivePage(PAGES.requestPasswordReset)}
              sx={{ fontWeight: "500", fontFamily: fontFamilies.openSans }}
            >
              Forgot your password?
            </OrangeLink>
          </Grid>
        </Grid>
      </Box>

      <Button
        fullWidth
        type="submit"
        className="button button--primary"
        variant="contained"
        sx={{ mt: 3, mb: 2, boxShadow: "none" }}
        disabled={!isValid || loading}
      >
        Log In
      </Button>

      <CreateAnAccount setActivePage={setActivePage} />
    </form>
  );
};

export default SignInForm;
