import React, { useRef, useEffect } from "react";
import Swoosh from "../../components/shared/Swoosh";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { CDN_URL } from "../../components/shared/CdnUrl";
import debounce from "lodash.debounce";
import { getUrlSearch } from "./Helpers";

const defaultHeaderImages = {
  [BootstrapSize.lgDevice]: `${CDN_URL}/images/pages/faq/faq-hero-lg-v3.jpg`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/faq/faq-hero-sm-v3.jpg`,
};

const OverviewSwoosh = ({
  searchPhrase,
  setSearchPhrase,
  searchResults,
  setSearchResults,
  showBreadcrumbs = true,
}) => {
  const searchRef = useRef(null);
  const searchResultsRef = useRef(null);

  const search = (e) => {
    if (e.target.value.length >= 3) {
      setSearchPhrase(e.target.value);
    }
  };

  const clearSearch = () => {
    searchRef.current.value = "";
    setSearchPhrase("");
  };

  useEffect(() => {
    if (searchResultsRef.current) {
      if (searchResults) {
        searchResultsRef.current.style.display = "block";
        searchResultsRef.current.style.bottom = `-${searchResultsRef.current.offsetHeight + 12}px`;
      } else {
        searchResultsRef.current.style.display = "none";
      }
    }
  }, [searchResults]);

  return (
    <div className="overview-swoosh">
      <Swoosh imgUrl={defaultHeaderImages}>
        <div className="overview-swoosh__content container">
          {showBreadcrumbs && (
            <div className="breadcrumb">
              <a href="/faq">Back to FAQ</a>
            </div>
          )}
          <h1 className="header1">FAQ</h1>
          <div className="faq-search">
            <i className="icon icon-search-thin"></i>
            <input
              className="search-field"
              type="text"
              placeholder="Search our frequently asked questions"
              ref={searchRef}
              onChange={debounce(search, 250)}
              maxLength={100}
              autoFocus
              defaultValue={getUrlSearch()}
              onKeyDown={(e) =>
                e.key.toLowerCase() === "enter"
                  ? (window.location.href = `/faq?s=${encodeURIComponent(searchPhrase)}`)
                  : null
              }
            />
            {searchPhrase && <i className="icon icon-cross" onClick={() => clearSearch()}></i>}
            <div className="search-results" ref={searchResultsRef}>
              {searchResults &&
                searchResults.map((item, idx) => (
                  <div
                    key={idx}
                    className="search-results__item"
                    onClick={() => (window.location.href = item.url)}
                  >
                    <p className="search-results__item__title">{item.title}</p>
                    <p className="search-results__item__breadcrumbs">{item.breadcrumbs}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Swoosh>
    </div>
  );
};

export default OverviewSwoosh;
