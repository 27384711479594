import React from "react";

function createMarkup(rawContent) {
  return { __html: rawContent };
}

export default class TabbedTextContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item,
      typeOf: this.props.typeOf,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.item) {
      this.setState({ item: nextProps.item });
    }
  }

  render() {
    return (
      <div>
        <div className="tabbed-text--title">
          <h2 className="header2 bold no-margin">{this.state.item.title}</h2>
          {this.state.typeOf == "popular_songs" && (
            <h4 className="header4">{this.state.item.subtitle}</h4>
          )}
        </div>
        <div className="tabbed-text--textbox">
          <p className="paragraph" dangerouslySetInnerHTML={createMarkup(this.state.item.body)}></p>
        </div>
        <div className="tabbed-text--button">
          {this.state.item.link && this.state.item.link_text && (
            <a
              className="header5 header5--orange button-spacing uppercase"
              href={this.state.item.link}
            >
              {this.state.item.link_text}
            </a>
          )}
        </div>
      </div>
    );
  }
}
