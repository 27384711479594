import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  songs: {},
  artists: {},
  songRequests: {},
  songCount: 0,
};

export const { actions, reducer } = createSlice({
  name: "metadata",
  initialState,
  reducers: {
    setMetadata(state, { payload }) {
      Object.entries(payload).forEach(([entity, metadata]) => {
        state[entity] = metadata;
      });
    },
    setPage(state, { payload: { page, entity } }) {
      state[entity].page = page;
    },
    setPerPage(state, { payload: { perPage, entity } }) {
      state[entity].perPage = perPage;
      // reset to first page on perPage change, since old page will be invalid (it's based off of old perPage)
      state[entity].page = 1;
    },
    setTotalCount(state, { payload: { totalCount, entity } }) {
      state[entity].totalCount = totalCount;
    },
  },
});
