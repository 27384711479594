import React from "react";
import DashboardCardItem from "../components/DashboardCardItem";
import AppConfig from "../../../packs/AppConfig.js";

export default class DashboardCardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item,
      toggleOpen: true,
    };
  }

  onDeletedItem = (lessonId, itemableId) => {
    let currentItem = this.state.item;
    let currentLessons = this.state.item.lessons;
    let individualLessons = this.state.item.individual_lessons;

    if (currentLessons) {
      let filteredLessons = currentLessons.filter((l) => l.lesson_id != parseInt(lessonId));
      currentItem.lessons = filteredLessons;
      this.setState({ item: currentItem });

      if (currentItem.lessons.length === 0) {
        this.props.onCardDelete(itemableId);
      }
    } else if (individualLessons) {
      let filteredLessons = individualLessons.filter((l) => l.lesson_id != parseInt(lessonId));
      if (filteredLessons.length > 0) {
        currentItem.individual_lessons = filteredLessons;
      } else {
        currentItem.individual_lessons = null;
      }
      this.setState({ item: currentItem });
    }
  };

  onToggle = () => {
    this.setState({ toggleOpen: !this.state.toggleOpen });
  };

  render() {
    const thumbnailDefault = AppConfig.data.defaultLessonThumbnail;
    const currentItem = this.props.item;
    const toggleClosed = { paddingBottom: "0px" };
    return (
      <div>
        {/* if currentItem does not have a group, it's an individual lesson */}
        {currentItem.group && (
          <div
            className="group-card"
            key={currentItem.user_item_id}
            style={!this.state.toggleOpen ? toggleClosed : null}
          >
            <div className="group-card__image">
              {this.state.toggleOpen && (
                <a href={currentItem.group.path}>
                  <img
                    src={
                      currentItem.group.thumbnail_source
                        ? currentItem.group.thumbnail_source
                        : thumbnailDefault
                    }
                  ></img>
                </a>
              )}
            </div>

            <div className="mobile-only group-card-toggle">
              <h3>
                <span
                  className={"glyphicon glyphicon-chevron-up"}
                  onClick={this.onToggle}
                  style={{ display: this.state.toggleOpen === true ? "" : "none" }}
                ></span>
              </h3>
            </div>

            <div className="group-card__body">
              {this.state.toggleOpen && (
                <div>
                  <h3 className="header3">
                    <a href={currentItem.group.path}>{currentItem.group.title}</a>
                    <span className="glyphicon glyphicon-chevron-up" onClick={this.onToggle}></span>
                  </h3>
                  <ul>
                    {currentItem.lessons &&
                      currentItem.lessons.length > 0 &&
                      currentItem.lessons.map((lesson) => (
                        <li key={lesson.lesson_id}>
                          <DashboardCardItem
                            deletedItem={this.onDeletedItem}
                            key={lesson.lesson_id}
                            status={this.props.status}
                            lesson={lesson}
                          />
                        </li>
                      ))}
                  </ul>
                </div>
              )}

              {!this.state.toggleOpen && (
                <div>
                  <h3 className="header3">
                    <a href={currentItem.group.path}>{currentItem.group.title}</a>
                    <span
                      className="glyphicon glyphicon-chevron-down"
                      onClick={this.onToggle}
                    ></span>
                  </h3>
                </div>
              )}
            </div>

            <div className="mobile-only group-card-toggle">
              <h3>
                <span
                  className="glyphicon glyphicon-chevron-down"
                  onClick={this.onToggle}
                  style={{ display: this.state.toggleOpen === true ? "none" : "" }}
                ></span>
              </h3>
            </div>
          </div>
        )}

        {currentItem.individual_lessons && (
          <div className="group-card" key="individual_lesson">
            <div className="group-card__image">
              {this.state.toggleOpen && <img src={thumbnailDefault}></img>}
            </div>
            <div className="group-card__body">
              {this.state.toggleOpen && (
                <div>
                  <h3 className="header3">
                    Individual Lessons
                    <span className="glyphicon glyphicon-chevron-up" onClick={this.onToggle}></span>
                  </h3>
                  <ul>
                    {currentItem.individual_lessons &&
                      currentItem.individual_lessons.length > 0 &&
                      currentItem.individual_lessons.map((lesson) => (
                        <li key={lesson.lesson_id}>
                          <DashboardCardItem
                            deletedItem={this.onDeletedItem}
                            key={lesson.lesson_id}
                            status={this.props.status}
                            lesson={lesson}
                          />
                        </li>
                      ))}
                  </ul>
                </div>
              )}

              {!this.state.toggleOpen && (
                <div>
                  <h3 className="header3">
                    Individual Lessons
                    <span
                      className="glyphicon glyphicon-chevron-down"
                      onClick={this.onToggle}
                    ></span>
                  </h3>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
