import { CDN_URL, DEFAULT_THUMB } from "../components/shared/CdnUrl";

export const PLACEHOLDER_THUMB = `${CDN_URL}/images/thumbnails/video-coming-soon-placeholder.png`;

export const getModuleThumb = (item) => {
  return item.image && item.image.length > 0
    ? item.image
    : item.vimeoThumbnail && item.vimeoThumbnail.length > 0
    ? item.vimeoThumbnail
    : item.thumbnails && item.thumbnails.medium && item.thumbnails.medium.length > 0
    ? item.thumbnails.medium
    : PLACEHOLDER_THUMB;
};

export const getLessonThumb = (item) => {
  let thumb = DEFAULT_THUMB;

  if (item.image && item.image.length > 0) {
    thumb = item.image;
  } else if (item.thumbnails && Object.keys(item.thumbnails).length > 0) {
    thumb = item.thumbnails["maxres"] ? item.thumbnails["maxres"] : item.thumbnails["default"];
  } else if (item.vimeoThumbnail) {
    thumb = item.vimeoThumbnail;
  }

  return thumb;
};
