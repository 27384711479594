import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { OrangeLink } from "../../../jg-material/theme/components/styled/Links";
import SignInModal from "../sessions/SignInModal";
import { PAGES } from "../sessions";

const SignInLink = ({ setActivePage }) => {
  const [loginOpen, setLoginOpen] = useState(false);

  return (
    <Grid container display="flex" justifyContent="center">
      <Grid item display="flex" justifyContent="center">
        <Typography variant="body1">
          Already have an account?{" "}
          <OrangeLink
            onClick={() => {
              setActivePage(PAGES.signIn);
            }}
          >
            Log In
          </OrangeLink>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SignInLink;
