import React from "react";
import Swoosh from "../components/shared/Swoosh";
import { truncate } from "./consts";

const LessonIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_13241_77216)">
      <path
        d="M10 16.5L16 12L10 7.5V16.5ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
        fill="#FD6A02"
      />
    </g>
    <defs>
      <clipPath id="clip0_13241_77216">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ModuleIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_13354_76996)">
      <path
        d="M4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6ZM20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM12 14.5V5.5L18 10L12 14.5Z"
        fill="#FD6A02"
      />
    </g>
    <defs>
      <clipPath id="clip0_13354_76996">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PaidItemIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM9.128 12.872V14.4H6.992V12.856C5.624 12.568 4.464 11.688 4.376 10.136H5.944C6.024 10.976 6.6 11.632 8.064 11.632C9.632 11.632 9.984 10.848 9.984 10.36C9.984 9.696 9.632 9.072 7.848 8.648C5.864 8.168 4.504 7.352 4.504 5.712C4.504 4.336 5.616 3.44 6.992 3.144V1.6H9.128V3.16C10.616 3.52 11.36 4.648 11.408 5.872H9.84C9.8 4.984 9.328 4.376 8.064 4.376C6.864 4.376 6.144 4.92 6.144 5.688C6.144 6.36 6.664 6.8 8.28 7.216C9.896 7.632 11.624 8.328 11.624 10.344C11.616 11.808 10.52 12.608 9.128 12.872Z"
      fill="#B8BEC7"
    />
  </svg>
);

const ChordLibraryIcon = () => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="13.334" width="1.11111" height="20" rx="0.555556" fill="#FD6A02" />
    <rect x="10" width="1.11111" height="20" rx="0.555556" fill="#FD6A02" />
    <rect x="6.66602" width="1.11111" height="20" rx="0.555556" fill="#FD6A02" />
    <rect x="3.33398" width="1.11111" height="20" rx="0.555556" fill="#FD6A02" />
    <rect width="1.11111" height="20" rx="0.555556" fill="#FD6A02" />
    <rect x="16.666" width="1.11111" height="20" rx="0.555556" fill="#FD6A02" />
    <rect width="17.7778" height="2.22222" fill="#FD6A02" />
    <rect y="5.55556" width="17.7778" height="1.11111" fill="#FD6A02" />
    <rect y="10" width="17.7778" height="1.11111" fill="#FD6A02" />
    <rect y="14.4444" width="17.7778" height="1.11111" fill="#FD6A02" />
  </svg>
);

const ForumIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_13241_77491)">
      <path
        d="M21 6H19V15H6V17C6 17.55 6.45 18 7 18H18L22 22V7C22 6.45 21.55 6 21 6ZM17 12V3C17 2.45 16.55 2 16 2H3C2.45 2 2 2.45 2 3V17L6 13H16C16.55 13 17 12.55 17 12Z"
        fill="#FD6A02"
      />
    </g>
    <defs>
      <clipPath id="clip0_13241_77491">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Lesson = ({ lesson, numberToWord }) => (
  <li>
    {lesson.type === "lesson" ? (
      <LessonIcon />
    ) : lesson.type === "course" ? (
      <ModuleIcon />
    ) : lesson.type === "community" ? (
      <ForumIcon />
    ) : lesson.type === "chord_library" ? (
      <ChordLibraryIcon />
    ) : (
      <LessonIcon />
    )}
    <a href={lesson.url}>{truncate(lesson.title, 50)}</a>
    {lesson.isPaid ? (
      <PaidItemIcon />
    ) : lesson.grade ? (
      <span className={`grade ${numberToWord(lesson.grade)}`}>
        {lesson.grade !== "community" && lesson.grade !== "playground" ? "Grade " : ""}
        {lesson.grade}
      </span>
    ) : (
      ""
    )}
  </li>
);

export default class RelatedLessonsSwoosh extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRelatedLesson: this.props.relatedLessons[0],
    };
  }

  numberToWord = (number) => {
    const words = ["one", "two", "three", "four", "five"];

    return words[parseInt(number) - 1] || number;
  };

  render() {
    return (
      <div className="related-lessons-swoosh">
        <Swoosh color="#F5F8F9">
          <div className="related-lessons-swoosh-content container">
            <h2>overcome your guitar struggles</h2>
            <div className="content">
              {this.props.width > 576 ? (
                <div className="lesson-list-accordion">
                  {this.props.relatedLessons.map((lessonListItem, idx) => (
                    <div
                      key={idx}
                      className={`lesson-list-accordion-item${
                        this.state.activeRelatedLesson === lessonListItem ? " open" : ""
                      }`}
                      onClick={() => this.setState({ activeRelatedLesson: lessonListItem })}
                    >
                      {this.state.activeRelatedLesson === lessonListItem ? (
                        <>
                          <p className="title">{this.state.activeRelatedLesson.title}</p>
                          <p className="description">
                            {this.state.activeRelatedLesson.description}
                          </p>
                          <ul className="lesson-list">
                            {this.state.activeRelatedLesson.lessons.map((lesson, idx) => (
                              <Lesson key={idx} lesson={lesson} numberToWord={this.numberToWord} />
                            ))}
                          </ul>
                        </>
                      ) : (
                        <>{truncate(lessonListItem.title, 50)}</>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <select
                  name="lesson_list_select"
                  onChange={(e) =>
                    this.setState({
                      activeRelatedLesson: this.props.relatedLessons.find(
                        (lessonListItem) => lessonListItem.title === e.target.value
                      ),
                    })
                  }
                >
                  {this.props.relatedLessons.map((lessonListItem, idx) => (
                    <option key={idx} value={lessonListItem.title}>
                      {truncate(lessonListItem.title)}
                    </option>
                  ))}
                </select>
              )}
              <div
                className="image"
                style={{
                  backgroundColor: "dimgray",
                  backgroundImage: `url(${this.state.activeRelatedLesson.associatedImg})`,
                }}
              ></div>
              {this.props.width <= 576 && (
                <div className="related-lesson-list-item">
                  <p className="description">{this.state.activeRelatedLesson.description}</p>
                  <ul className="lesson-list">
                    {this.state.activeRelatedLesson.lessons.map((lesson, idx) => (
                      <Lesson key={idx} lesson={lesson} numberToWord={this.numberToWord} />
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <a href="/users/sign_up" className="button button--primary">
              Join JustinGuitar for Free!
            </a>
          </div>
        </Swoosh>
      </div>
    );
  }
}
