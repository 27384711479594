import { createSlice } from "@reduxjs/toolkit";

// TODO
export interface UiState {
  currentEntityId?: string | number;
}

export const initialUiState: UiState = {};

export const uiSlice = createSlice({
  name: "ui",
  initialState: initialUiState,
  reducers: {
    setCurrentEntityId: (state, { payload }) => {
      state.currentEntityId = payload;
    },
  },
});
