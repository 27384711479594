import React, { createContext, useEffect, useState } from "react";
import { UserProvider } from "./contexts/userContext";
import SongsIndexPage from "./SongsIndexPage";
import { AUTOHIDE_DURATION } from "../shared/Snackbar";

// we need a solution for this across the site if we move away from redux.
export const SnackbarContext = createContext();

const SongsIndexContainer = ({ currentUser }) => {
  const [snackbar, setSnackbar] = useState({ message: "", severity: "" });

  useEffect(() => {
    if (snackbar.message !== "") {
      setTimeout(() => {
        setSnackbar({ message: "", severity: "" });
      }, AUTOHIDE_DURATION);
    }
  }, [snackbar]);

  return (
    <>
      <UserProvider currentUser={currentUser}>
        <SnackbarContext.Provider value={{ snackbar, setSnackbar }}>
          <SongsIndexPage currentUser={currentUser} />
        </SnackbarContext.Provider>
      </UserProvider>
    </>
  );
};

// For now, we get the currentUser as props from backend.
export default (props) => <SongsIndexContainer {...props} />;
