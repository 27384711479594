import React from "react";
import { PreventScroll } from "../../shared";
// import { filters } from "../filters/filters";
import { withBootstrapSize } from "../../shared/WithBootstrapSize";
import { BootstrapSize, breakpoints } from "../../shared/bootstrap-helpers";
import FiltersOpenCloseButton from "../filters/FiltersOpenCloseButton";
import { artistFilters } from "./ArtistFilters";

export const ArtistFiltersButton = ({ width, bootstrapSize, ...props }) => {
  return (
    <PreventScroll
      shouldPreventScroll={() =>
        props.filtersOpen && width < breakpoints[BootstrapSize[bootstrapSize]]
      }
    >
      {props.filtersOpen && <div className="overlay" onClick={props.closeFilters} />}
      <FiltersOpenCloseButton {...props} availableFilters={artistFilters} />
    </PreventScroll>
  );
};

export default withBootstrapSize(ArtistFiltersButton);
