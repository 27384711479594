import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { CDN_URL } from "../../components/shared/CdnUrl";

const defaultHeaderImages = {
  [BootstrapSize.smDevice]: `${CDN_URL}/images/pages/user-performances/user-performances-hero-lg.png`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/user-performances/user-performances-hero-sm.png`,
};

const UserPerformancesSwoosh = () => {
  return (
    <div className="user-performances-swoosh">
      <Swoosh imgUrl={defaultHeaderImages}>
        <div className="user-performances-swoosh__content">
          <h1 className="header1">Student Performances</h1>
        </div>
      </Swoosh>
    </div>
  );
};

export default UserPerformancesSwoosh;
