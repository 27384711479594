import { PromoItemEntity } from "./interfaces/PromoItem";
import { PromoItemProps } from "./PromoItem";
import { useEffect, useState } from "react";
import { fetchPromoItems } from "./store/promo-items.api";
import { normalize } from "normalizr";
import { promoItemSchema } from "./store/promo-item.schema";

export const usePromoItem = (
  target: PromoItemProps["target"],
  promoItemId = null
): PromoItemEntity | undefined => {
  const [promoItem, setPromoItem] = useState<PromoItemEntity | undefined>();
  useEffect(() => {
    fetchPromoItems(1, target, promoItemId).then(({ data: { data } }) => {
      const {
        entities: { promoItems = {} },
      } = normalize<PromoItemEntity>(data, [promoItemSchema]);

      setPromoItem(Object.values(promoItems)[0]);
    });
  }, []);

  return promoItem;
};
