import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setActiveTab } from "../ui/uiSlice";
import Information from "./Information";
import UserNote from "../../notes/UserNote";
import GroupLessonsList from "../../../group/GroupLessonsList";
import LessonsWithinModuleList from "../../../lesson/LessonsWithinModuleList";
import { withInnerWidth } from "../WithInnerWidth";
import Discourse from "../../../discourse/Discourse";
import PracticeTab from "./practice/PracticeTab";
import LoginPrompt from "../../auth/LoginPrompt";
import VimeoVideoDownload from "../../../lesson/VimeoVideoDownload";
import UserNoteNew from "../../notes/UserNoteNew";
import PracticeTabNew from "./refactored/PracticeTabNew";
import DownloadsNew from "./refactored/DownloadsNew";
import MatchMySound from "./refactored/MatchMySound";

const OBJECT_TYPES = {
  lesson: "Lesson",
};

const InfoIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.1 4.5H9.9V6.3H8.1V4.5Z" fill="#406E8E" />
      <path d="M8.1 8.1H9.9V13.5H8.1V8.1Z" fill="#406E8E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9C0 4.032 4.03198 0 9 0C13.968 0 18 4.032 18 9C18 13.968 13.968 18 9 18C4.03198 18 0 13.968 0 9Z"
        fill="#406E8E"
      />
      <rect x="8.1001" y="8.1001" width="1.8" height="5.4" fill="white" />
      <rect x="8.1001" y="4.5" width="1.8" height="1.8" fill="white" />
    </svg>
  );
};

const TABS = [
  { name: "Learn More", icon: "book-open", mobileName: "Learn" },
  { name: "Discussion", icon: "bubble", mobileName: "Discussion" },
  { name: "Notes", icon: "pencil" },
];

const TabButton = ({ tab, activeTab, setActiveTab, width, isGiveaway = false }) => {
  return (
    <button
      className={activeTab === tab.name ? "active" : ""}
      onClick={() => {
        setActiveTab(tab.name);
        window.location.hash = `#${tab.name.toLowerCase().replace(" ", "-")}`;
      }}
    >
      {tab.name === "Learn More" && isGiveaway ? (
        <InfoIcon />
      ) : (
        <i className={`icon icon-${tab.icon}`} />
      )}
      {((width <= 576 && activeTab === tab.name) || width > 576) && (
        <span>
          {tab.name === "Learn More" && isGiveaway
            ? "Information"
            : width <= 576 && tab.mobileName
            ? tab.mobileName
            : tab.name}
        </span>
      )}
    </button>
  );
};

const TabOptions = (props) => {
  if (props.objectType === OBJECT_TYPES.lesson) {
    const { relationships } = props.object.data;
    const showPractice =
      relationships.practiceItems.data.length > 0 || relationships.practiceRoutines.data.length > 0;

    const showResources = relationships.downloadables.data.length > 0;

    // TODO: after refactor we can unify these. or we can split them up
    return (
      <div className="tabz__options">
        {TABS.map((tab) => {
          return <TabButton {...props} tab={tab} key={tab.name} />;
        })}

        {props.matchMySoundId && (
          <TabButton {...props} tab={{ name: "Play", icon: "play-circle-filled" }} />
        )}

        {showResources && <TabButton {...props} tab={{ name: "Resources", icon: "download" }} />}
        {showPractice && <TabButton {...props} tab={{ name: "Practice", icon: "playlist" }} />}

        <span className="lesson-tab">
          <TabButton {...props} tab={{ name: "Lessons", icon: "stacked-boxes" }} key="Lessons" />
        </span>

        {props.vimeoDownloadAvailable && (
          <TabButton {...props} tab={{ name: "Downloads", icon: "box-download" }} />
        )}
      </div>
    );
  }
  return (
    <div className="tabz__options">
      {props.objectType === "Group" && (
        <TabButton {...props} tab={{ name: "Lessons", icon: "stacked-boxes" }} key="Lessons" />
      )}
      {TABS.map((tab) => {
        return <TabButton {...props} tab={tab} key={tab.name} />;
      })}

      {props.downloads && props.downloads.length > 0 && (
        <TabButton {...props} tab={{ name: "Resources", icon: "download" }} />
      )}
      {((props.practiceItems && props.practiceItems.length > 0) ||
        (props.practiceRoutines && props.practiceRoutines.length > 0)) && (
        <TabButton {...props} tab={{ name: "Practice", icon: "playlist" }} />
      )}

      {props.vimeoDownloadAvailable && (
        <TabButton {...props} tab={{ name: "Downloads", icon: "box-download" }} />
      )}
    </div>
  );
};

const TabContent = (props) => {
  switch (props.activeTab) {
    case "Learn More":
      return <Information informationHtml={props.informationHtml} resource={props.object} />;
    case "Discussion":
      return (
        <Discourse
          relativePath={props.relativePath}
          topicId={props.topicId}
          currentUser={props.currentUser}
          token={props.token}
          objectType={props.objectType}
          objectId={props.objectId}
        />
      );
    case "Practice":
      // TODO: move all tabs to New component
      if (props.objectType === OBJECT_TYPES.lesson) {
        return (
          <PracticeTabNew
            object={props.object}
            objectType={props.objectType}
            currentUser={props.currentUser}
          />
        );
      }
      return (
        <PracticeTab
          practiceItems={props.practiceItems}
          relatedLink={props.relativePath}
          practiceRoutines={props.practiceRoutines}
        />
      );
    case "Notes":
      // TODO: move all tabs to New component

      if (props.objectType === "Lesson") {
        return (
          <UserNoteNew
            currentUser={props.currentUser}
            noteableId={props.objectId}
            noteableType={props.objectType}
          />
        );
      } else {
        return (
          <UserNote
            user={props.currentUser}
            note={props.note}
            noteableType={props.objectType}
            noteableId={props.objectId}
            token={props.token}
          />
        );
      }
    case "Lessons":
      return (
        <>
          {props.objectType === "Group" && <GroupLessonsList />}
          {props.objectType === "Lesson" && <LessonsWithinModuleList />}
        </>
      );
    case "Resources":
      return <DownloadsNew lessonId={props.objectId} currentUser={props.currentUser} />;
    case "Downloads":
      return <VimeoVideoDownload lessonId={props.objectId} />;
    case "Play":
      return <MatchMySound matchMySoundId={props.matchMySoundId} />;
    default:
      return <Information informationHtml={props.informationHtml} />;
  }
};

const TabContainer = (props) => {
  const { activeTab, width, setActiveTab, needsLogin, token, needsPayment } = props;
  return (
    <div className="tabz container">
      <TabOptions {...props} />
      <div className={`tabz__content tabz__content--${activeTab}`}>
        {needsLogin ? (
          <LoginPrompt token={token} loginAction={"access this lesson"} />
        ) : needsPayment ? (
          <h3 className="needs-payment">Please purchase access to this course to continue.</h3>
        ) : (
          <TabContent {...props} />
        )}
      </div>
    </div>
  );
};

const TabsWithWidth = withInnerWidth(TabContainer);

const mapDispatchToProps = (dispatch) => ({
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
});

export default connect(null, mapDispatchToProps)(TabsWithWidth);

TabContainer.propTypes = {
  informationHtml: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  objectId: PropTypes.number.isRequired,
  note: PropTypes.object,
};
