import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { CDN_URL } from "../../components/shared/CdnUrl";
import { Box, Typography } from "@mui/material";

const HEADER_IMAGES = {
  [BootstrapSize.smDevice]: `${CDN_URL}/images/pages/store/blues-immersion/blim-hero-lg.jpg`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/store/blues-immersion/banner-sm.jpg`,
};

const BluesImmersionSwoosh = () => {
  return (
    <Box>
      <Swoosh imgUrl={HEADER_IMAGES}>
        <Box className="blues-immersion-swoosh__content container">
          <Typography variant="h1" color={"white"}>
            Blues Immersion
          </Typography>
        </Box>
      </Swoosh>
    </Box>
  );
};

export default BluesImmersionSwoosh;
