export const nestSlice = (stateKey, slice) =>
  Object.values(slice.actions).reduce(
    (accum, actionCreator) => ({
      ...accum,
      [actionCreator.type]: (state, action) => {
        state[stateKey] = slice.reducer(state[stateKey], action);
      },
    }),
    {}
  );
