import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";

import FiltersList from "./FiltersList";
import { PreventScroll } from "./PreventScroll";

const ANIMATION_TIMEOUT_MS = 200;

export const FiltersOpenCloseButton = ({
  availableFilters,
  activeFilters,
  filtersOpen,
  openFilters,
  closeFilters,
  clearFilters,
  hasActiveFilters,
  toggleFilter,
  buttonClassModifier,
  defaultClosed,
}) => {
  const buttonRef = React.useRef();
  return (
    <div className={"filters " + (filtersOpen ? "filters--open" : "")}>
      <button
        ref={buttonRef}
        className={`btn--filters button button--${buttonClassModifier || "primary"}`}
        onClick={() => (filtersOpen ? closeFilters() : openFilters())}
      >
        <i className="icon icon-sliders" />
        <span>filters</span>
      </button>

      <TransitionGroup component={null}>
        {filtersOpen ? (
          <CSSTransition classNames="filters__list" timeout={ANIMATION_TIMEOUT_MS}>
            <PreventScroll>
              <FiltersList
                availableFilters={availableFilters}
                activeFilters={activeFilters}
                clearFilters={clearFilters}
                closeFilters={closeFilters}
                toggleFilter={toggleFilter}
                hasActiveFilters={hasActiveFilters}
                buttonRef={buttonRef}
                defaultClosed={defaultClosed}
              />
            </PreventScroll>
          </CSSTransition>
        ) : null}
      </TransitionGroup>
    </div>
  );
};

FiltersOpenCloseButton.propTypes = {
  availableFilters: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  activeFilters: PropTypes.objectOf(PropTypes.bool).isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  hasActiveFilters: PropTypes.bool.isRequired,
  openFilters: PropTypes.func.isRequired,
  closeFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func.isRequired,
};
