import { Interpolation } from "@mui/system";
import { jgDenimColors } from "../../jg-denim-colors";
import { TypographyProps, Theme } from "@mui/material";

// todo: why doesn't adding this override work properly?
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    quote: true;
  }
}

export const quote: {
  props: Partial<TypographyProps>;
  style: Interpolation<{ theme: Theme }>;
} = {
  props: {
    // @ts-ignore
    variant: "quote",
  },
  style: ({ theme }) => ({
    textAlign: "center",
    color: jgDenimColors.white,
    // TODO: get "Bakeshop" font
    fontFamily: "Bakeshop, Sans-serif",
    lineHeight: "1.4",
    fontSize: "16px",
    fontStyle: "italic",
    [theme.breakpoints.up("xs")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "24px",
    },
  }),
};
