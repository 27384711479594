import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import { CDN_URL } from "../../components/shared/CdnUrl";

const TemporaryArtistSwoosh = () => {
  return (
    <div className="artist-temp-styles-to-remove">
      <Swoosh color="#44546c">
        <div className="about__body__content">
          <div className="about__body__content__other-artists">
            <h2 className="uppercase header2 text-center">playing and writing for other artists</h2>
            <p className="white">
              For many years I worked as a sideman to various artists. During this time I learned a
              lot about writing and the "business" of music.
            </p>
            <div className="about__body__content__other-artists__description">
              <div className="about__body__content__other-artists__description__image">
                <img src={`${CDN_URL}/redesign/about/katie-melua-b-sides.jpg`} alt="Katie Melua" />
                <h3>
                  Turn To Tell <span>Katie Melua</span>
                </h3>
              </div>
              <p className="about__body__content__other-artists__description__about">
                I produced and wrote tracks for Katie Melua and played electric and acoustic guitar
                in her live band between 2005 and 2008. I wrote her song Turn To Tell which earned
                me my first platinum disc!
                <br />
                <br />
                I've also played for Cathy Dennis, Carla Bruni, and many others!
              </p>
            </div>
            <div className="about__body__content__other-artists__media">
              <a
                className="clickable"
                target="_blank"
                href="https://open.spotify.com/track/5RpRsYDSSOofokn36VjpBV?si=53a48656d7bd4452"
              >
                listen on spotify
              </a>
            </div>
          </div>
        </div>
      </Swoosh>
    </div>
  );
};

export default TemporaryArtistSwoosh;
