import React from "react";

const Intro = () => {
  return (
    <div className="section intro">
      <h2 className="title">An exclusive chance for a good cause! Read more. :)</h2>
      <p>
        Tackle your guitar hurdles with a 1:1 lesson with Justin Sandercoe, followed by a
        personalized Action Plan and Practice Routine Suggestion. Plus, your purchase supports the
        JustinGuitar{" "}
        <a href="/playin-it-forward" target="_blank">
          Playin' It Forward
        </a>{" "}
        initiative, providing free guitars to those in need.
      </p>
    </div>
  );
};

export default Intro;
