import axiosWithCSRF from "../../../components/shared/axiosWithCSRF";
import { dispatch } from "react-redux";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setDownloadLinks } from "../lessonStore";
import { openAlert, setLoading } from "../../../components/shared/ui/uiSlice";

export const getDownloadLinks = createAsyncThunk(
  "lesson/getDownloadLinks",
  (lessonId, { dispatch }) => {
    dispatch(setLoading());
    return axiosWithCSRF()
      .get("/vimeo_download_links", {
        params: {
          lesson_id: lessonId,
        },
      })
      .then((result) => {
        dispatch(setLoading());
        dispatch(setDownloadLinks(result.data.links));
      })
      .catch((error) => {
        dispatch(setLoading());
        dispatch(
          openAlert({ message: "There was a problem downloading this video.", severity: "error" })
        );
      });
  }
);
