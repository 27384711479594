export function strippedText(fullText, maxLength) {
  // content is admin-generated HTML from a WYSIWYG editor, so strip tags here.
  let doc = new DOMParser().parseFromString(fullText, "text/html");
  let content =
    doc && doc.body ? doc.body.textContent : "A description for this class is coming soon!";
  return content.length > maxLength ? `${content.substr(0, maxLength)}...` : content;
}

export function formattedSeconds(seconds) {
  let formatted = new Date(seconds * 1000).toISOString().substr(11, 8);
  return formatted.split(":")[0] === "00" ? formatted.split(":").splice(1, 2).join(":") : formatted;
}
