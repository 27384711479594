import React from "react";
import { CDN_URL } from "../../components/shared/CdnUrl";

const PLACEHOLDER_THUMB = `${CDN_URL}/images/thumbnails/video-coming-soon-placeholder.png`;

const SongAndLessonCard = ({ item, grade }) => {
  const { image, thumbnails, title, slug, vimeoThumbnail, youtubeDuration, fullUrl } =
    item.data.attributes;

  const backgroundImage =
    vimeoThumbnail && vimeoThumbnail.length > 0
      ? vimeoThumbnail
      : thumbnails && thumbnails.medium
      ? thumbnails.medium
      : image
      ? image
      : PLACEHOLDER_THUMB;

  return (
    <div className="dashboard__favorite-card">
      <div
        onClick={() => (window.location.href = fullUrl)}
        className="favorite-thumb clickable"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <a href={fullUrl}>{title}</a>
      <div className="favorite-info">
        {youtubeDuration && youtubeDuration.length > 0 && (
          <p className="favorite-info__duration">
            <i className="icon icon-clock2"></i>
            {youtubeDuration}
          </p>
        )}
        {(grade === 0 || grade) && (
          <p className="favorite-info__grade">{grade === 0 ? "all grades" : `Grade ${grade}`}</p>
        )}
      </div>
    </div>
  );
};

export default SongAndLessonCard;
