import React from "react";
import * as moment from "moment";
import { VictoryChart, VictoryBar, VictoryAxis, VictoryTooltip, VictoryLabel } from "victory/src";
import { filterItems } from "./StatsFunctions";
const DATE_FORMAT = "ddd, MMM Do";

export default class StatisticsChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setXaxisValues = () => {
    let { startDate, endDate } = this.props;
    if (startDate && endDate) {
      let currentDate = startDate;
      let datesObj = {};
      while (currentDate <= endDate) {
        let d = moment(currentDate).format(DATE_FORMAT);
        datesObj[d] = { seconds: 0 };
        currentDate = moment(currentDate).add(1, "days");
      }
      return datesObj;
    }
  };

  itemsWithinRange = () => {
    let { items } = this.props;
    let beginning = new Date(Date.parse(this.props.startDate)).setHours(0, 0, 0, 0);
    let end = new Date(Date.parse(this.props.endDate)).setHours(0, 0, 0, 0);

    let filtered = [];
    if (items) {
      filtered = items.filter(
        (item) =>
          new Date(item.date).setHours(0, 0, 0, 0) <= end &&
          new Date(item.date).setHours(0, 0, 0, 0) >= beginning
      );
    }
    return filtered;
  };

  transformItems = (items) => {
    let days = this.setXaxisValues();
    if (days && items) {
      items.map((item) => {
        let dateFormatted = moment(item.date).format(DATE_FORMAT);

        if (days[dateFormatted]) {
          let updatedSeconds = (days[dateFormatted]["seconds"] += item.seconds);
          days[dateFormatted]["seconds"] = updatedSeconds;
        } else {
          days[dateFormatted] = { seconds: item.seconds };
        }
      });
      return days;
    }
  };

  formatYTick = (t) => {
    let sec_num = parseInt(t, 10);
    let hours = Math.floor(t / 3600);
    let minutes = Math.floor(t / 60) % 60;
    let seconds = sec_num % 60;

    if (hours > 0) {
      return [`${hours} hr`, `${minutes} min`];
      return;
    } else if (minutes > 0) {
      return `${minutes} min`;
    } else if (seconds > 0) {
      return `${seconds} sec`;
    }
  };

  getClosestMinute = (secs) => {
    let first = secs - (secs % 60);
    let second = secs + 60 - (secs % 60);
    let closest;
    if (secs < 60) {
      closest = 0;
    } else if (secs - first > second - secs) {
      closest = second;
    } else {
      closest = first;
    }
    return closest;
  };

  yAxisTicks = (values) => {
    let seconds = values.map((v) => v["y"]);
    let maxValue = Math.max(...seconds);
    let thirdPoint = this.getClosestMinute(maxValue);
    let secondPoint = this.getClosestMinute(maxValue * 0.66);
    let firstPoint = this.getClosestMinute(maxValue * 0.33);
    return [firstPoint, secondPoint, thirdPoint];
  };

  barLabel = () => {
    let { startDate, endDate } = this.props;
    let numberOfDays = endDate.diff(startDate, "days");
    if (numberOfDays > 12) {
      return <VictoryTooltip />;
    } else {
      return <VictoryLabel />;
    }
  };

  showChart = (data, x_values) => {
    if (data.length > 0) {
      return (
        <VictoryChart domainPadding={20}>
          <VictoryAxis
            dependentAxis
            style={{
              tickLabels: {
                fontSize: 7,
                fill: "#666666",
                letterSpacing: "0.5px",
                fontWeight: 600,
                fontFamily: "Montserrat, serif",
              },
            }}
            tickValues={this.yAxisTicks(data)}
            tickFormat={(t) => this.formatYTick(t)}
          />
          <VictoryAxis
            style={{
              tickLabels: {
                fontSize: 7,
                textTransform: "uppercase",
                fill: "#666666",
                letterSpacing: "0.5px",
                fontWeight: 600,
                fontFamily: "Montserrat, serif",
              },
            }}
            fixLabelOverlap={true}
            tickFormat={(t) =>
              t.split(",").map((i) => {
                return i;
              })
            }
          />
          <VictoryBar
            alignment="middle"
            labelComponent={this.barLabel()}
            data={data}
            labels={(d) => `${this.props.formatTime(d.y)}`}
            style={{
              data: {
                fill: "#406E8E",
              },
              labels: {
                fontSize: 6,
                fontWeight: 600,
                fontFamily: "Montserrat, serif",
                fill: "#666666",
                textTransform: "uppercase",
              },
            }}
          />
        </VictoryChart>
      );
    } else {
      return (
        <div>
          <h4>No practice stats to display!</h4>
        </div>
      );
    }
  };

  render() {
    let { items, startDate, endDate } = this.props;
    if (items) {
      let data = [];
      let itemsWithinRange = filterItems(items, startDate, endDate);
      let transformedItems = this.transformItems(itemsWithinRange);
      let x_values = Object.keys(transformedItems);
      let y_values = transformedItems;

      x_values.forEach((category, index) => {
        data.push({
          x: category,
          y: y_values[category]["seconds"],
        });
      });

      return <div className="statistics-chart__container">{this.showChart(data, x_values)}</div>;
    } else {
      return null;
    }
  }
}
