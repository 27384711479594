import React from "react";
import { Provider } from "react-redux";
import { Container, ThemeProvider, Typography } from "@mui/material";

import Swoosh from "../../../components/shared/Swoosh";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import WorkshopsListing from "./WorkshopsListing";

import { getJgStore } from "../../../redux/jg-store";
import { WhatStudentsSay } from "./WhatStudentsSay";
import { WorkshopGalleryPreview } from "./WorkshopGalleryPreview";
import { CDN_URL } from "../../../components/shared/CdnUrl";
import { BootstrapSize } from "../../../components/shared/bootstrap-helpers";
import { WorkshopsFaqs } from "../shared/WorkshopsFaqs";

const smallHero = `${CDN_URL}/redesign/workshops/JG-Workshops-Hero-Mobile.jpg`;
const largeHero = `${CDN_URL}/redesign/workshops/JG-Workshops-Hero.jpg`;

export const WorkshopsIndexPage = ({ faqs }) => {
  return (
    <Provider store={getJgStore()}>
      <ThemeProvider theme={jgDenimTheme}>
        <div>
          <Swoosh
            imgUrl={{
              [BootstrapSize.xsDevice]: smallHero,
              [BootstrapSize.smDevice]: largeHero,
              [BootstrapSize.mdDevice]: largeHero,
              [BootstrapSize.lgDevice]: largeHero,
              [BootstrapSize.xlDevice]: largeHero,
            }}
            breadcrumbsCta={{ url: "/store", title: "back to store" }}
          >
            <Container>
              <Typography variant="h1" color="white">
                Workshops & Events
              </Typography>
            </Container>
          </Swoosh>

          <Container>
            <WorkshopsListing />
            <WorkshopGalleryPreview />
            <WhatStudentsSay />
            <WorkshopsFaqs faqs={faqs} />
          </Container>
        </div>
      </ThemeProvider>
    </Provider>
  );
};
