import React, { useState } from "react";
import { aboutJustin } from "./consts";

const AboutJustin = ({ width }) => {
  const [activeAboutJustin, setActiveAboutJustin] = useState(aboutJustin[0]);

  return (
    <div className="container">
      <div className="about-justin">
        <h2>ABOUT JUSTIN</h2>
        <div className="about-justin-nav-container">
          <span>I am a</span>
          {width > 576 ? (
            <div className="about-justin-nav">
              {aboutJustin.map((item, idx) => (
                <button
                  key={idx}
                  className={activeAboutJustin === item ? "active" : ""}
                  onClick={() => setActiveAboutJustin(item)}
                >
                  {item.title}
                </button>
              ))}
            </div>
          ) : (
            <select
              value={activeAboutJustin.title}
              onChange={(e) =>
                setActiveAboutJustin(aboutJustin.find((item) => item.title === e.target.value))
              }
            >
              {aboutJustin.map((item, idx) => (
                <option key={idx} value={item.title}>
                  {item.title}
                </option>
              ))}
            </select>
          )}
        </div>
        <img src={activeAboutJustin.img} alt={`About Justin - ${activeAboutJustin.title}`} />
        <div
          className="content-text"
          dangerouslySetInnerHTML={{ __html: activeAboutJustin.description }}
        ></div>
        <div className="years">
          {activeAboutJustin.stats.map((stat, idx) => (
            <div key={idx}>
              <p>{stat.value}</p>
              <p>{stat.title}</p>
            </div>
          ))}
        </div>
        <a href="/about" className="button button--primary">
          learn more
        </a>
      </div>
    </div>
  );
};

export default AboutJustin;
