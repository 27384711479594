import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Slider from "react-slick";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { CDN_URL } from "../../../components/shared/CdnUrl";

import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

import { jgDenimColors } from "../../../jg-material/theme/jg-denim-colors";

export const WhatStudentsSay = () => (
  <Box>
    <link
      rel="stylesheet"
      type="text/css"
      charSet="UTF-8"
      href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
    />
    <link
      rel="stylesheet"
      type="text/css"
      href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
    />
    <Typography variant="h4" textAlign="center" padding={jgDenimTheme.spacing(6, 0, 4)}>
      What Students Say
    </Typography>
    <Box mb={8} pb={4}>
      <Carousel />
    </Box>
  </Box>
);

const SlickArrow = ({
  direction,
  onClick,
}: {
  direction: "next" | "prev";
  onClick: () => void;
}) => (
  <Box
    position="absolute"
    bottom={"-50px"}
    left={"50%"}
    sx={{
      // transform: `translateX(${direction === "next" ? "50px" : "-50px"})`,
      transform: `translateX(calc(-50% ${direction === "next" ? "+ 50px" : "- 50px"}))`,
    }}
  >
    <Button variant={"text"} size={"small"} onClick={onClick}>
      {direction === "next" ? (
        <ArrowForwardIos sx={{ color: jgDenimColors.accent.orange.main }} />
      ) : (
        <ArrowBackIos sx={{ color: jgDenimColors.accent.orange.main }} />
      )}
    </Button>
  </Box>
);

const NextArrow = (props) => <SlickArrow direction="next" {...props} />;
const PrevArrow = (props) => <SlickArrow direction="prev" {...props} />;

const slickSettings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

interface Endorsement {
  quote: string;
  student: string;
  location: string;
  photoUrl: string;
}

const endorsements: Endorsement[] = [
  {
    quote:
      "I had an incredible experience at Justin's weeklong guitar workshop. The supportive community he has created gave me the confidence to play with others and face my fear of singing in front of an audience. Despite my doubts going into the week, Justin's kind and protective environment for growth helped me push myself and embrace the vulnerability of performance. It was an unforgettable journey of learning and self-discovery. Thank you, Justin and team!",
    student: "David",
    location: "USA",
    photoUrl: `${CDN_URL}/redesign/workshops/david-sm-2024.jpeg`,
  },
  {
    quote:
      "The Acoustic Jam Week in Quebec was truly amazing! I have received loads of personal feedback from Justin and Peter to help me improve my technique. I really enjoyed the daily jamming sessions with other players! The atmosphere was very friendly and inclusive for everyone. I encourage everybody to attend one of Justin's workshops!",
    student: "Marie-Andree",
    location: "Canada",
    photoUrl: `${CDN_URL}/redesign/workshops/ma-sm-2024.jpeg`,
  },
  {
    quote:
      "I have done five of Justin's workshops over the years! The Acoustic Jam Week in Quebec was my first 'acoustic' - I'm a Classic Rocker and Blues kinda guy - and I was BLOWN AWAY! This Workshop stretched my guitar thinking and playing. When I got home and picked up my Tele, plugged in, and cranked it to ELEVEN, I never played so well. I looked 'outside the box' and took a chance! Justin and Pete are incredible teachers, and it was a blast to hang out with at all the four-course meals in the evenings! I cannot wait for the next one!",
    student: "Bob",
    location: "USA",
    photoUrl: `${CDN_URL}/redesign/workshops/bob-sm-2024.jpeg`,
  },
  {
    quote:
      "The Tuscany workshop was amazing. Playing lots of guitar, making new friends and just having a good time in a wonderful region - a perfect combination. Justin is a really nice guy and a great teacher who sped up my learning process significantly. If you have the chance to attend one of Justin’s workshops don’t miss it!",
    student: "Simone Musahl",
    location: "Germany",
    photoUrl: `${CDN_URL}/redesign/workshops/simone.jpg`,
  },
  {
    quote:
      "Justin’s workshop has really changed my journey as a guitar student. It was by far one of the best experiences I have ever had. Playing guitar for a full week surrounded by other motivated guitar students is unique and of course having Justin teaching you live is incredible. I think I learnt much more in this one week than what I learnt in the past 6 months. Furthermore, all the people in the class and Justin are still in touch and remain friends.",
    student: "Leo Bego",
    location: "Venezuela",
    photoUrl: `${CDN_URL}/redesign/workshops/leonel.jpg`,
  },
  {
    quote:
      "The week in Tuscany was incredibly great! Justin is an excellent teacher, the whole workshop, the food, the place, all the people around there where perfect! One week ‘in the bubble’ only music, a lot of fun and many eye-opening moments! Thank you Justin!",
    student: "Juergen Hengsberger",
    location: "Switzerland",
    photoUrl: `${CDN_URL}/redesign/workshops/Jurgen.jpg`,
  },
  {
    quote:
      "Justin’s Tuscany workshop is so good, so inspiring, that it’s become a yearly event for my wife and I. Fantastic people, amazing food, and then there’s the guitar stuff! If you’re like me and love playing the guitar there is no better place to be.",
    student: "Richard Williams",
    location: "USA",
    photoUrl: `${CDN_URL}/redesign/workshops/Richard.jpg`,
  },
  {
    quote:
      "It was amazing to attend Justin's Electric Jam Workshop. By creating a fun and inclusive environment, he had us all playing, singing, and having a great time. The backup band made even an average guitar player like me sound like a Rock Star!",
    student: "Mark Senecal",
    location: "New Jersey, USA",
    photoUrl: `${CDN_URL}/redesign/workshops/Mark.jpeg`,
  },
];

const Carousel = () => {
  const [currIdx, setCurrIdx] = React.useState(0);

  const isLgOrSmaller = useMediaQuery(jgDenimTheme.breakpoints.down("lg"));
  const isMdOrSmaller = useMediaQuery(jgDenimTheme.breakpoints.down("md"));

  const settings = {
    ...slickSettings,
    slidesToShow: isMdOrSmaller ? 1 : isLgOrSmaller ? 2 : 3,
  };

  return (
    <Box position="relative" pb={8} mr={"-5px"} ml={"-5px"}>
      <Slider
        {...settings}
        infinite={true /*https://github.com/kenwheeler/slick/issues/940*/}
        initialSlide={currIdx}
        afterChange={setCurrIdx}
      >
        {endorsements.map((endorsement, idx) => (
          <CarouselCard key={idx} endorsement={endorsement} />
        ))}
      </Slider>
    </Box>
  );
};

const CarouselCard = ({ endorsement }: { endorsement: Endorsement }) => (
  //  height will depend on image height and longest student quote
  <div style={{ margin: "0 auto", marginBottom: 10 }}>
    <Card elevation={3} sx={{ m: jgDenimTheme.spacing(1) }}>
      <CardMedia component="img" image={endorsement.photoUrl} />
      {/* TODO: this calc() may have to change when image sizes change; is there a better way to do this?  */}
      <Box
        height="calc(100% - 115px)"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <CardContent>
          <Typography variant="body1">"{endorsement.quote}"</Typography>
        </CardContent>

        <CardContent>
          <Typography variant="body1" fontWeight="bold">
            {endorsement.student}
          </Typography>
          <Typography variant="subtitle1">{endorsement.location}</Typography>
        </CardContent>
      </Box>
    </Card>
  </div>
);
