import React from "react";
import { breadcrumbTitleFormatter, parentGroupBadge } from "../components/shared/BreadcrumbTrail";

const ParentGroupBreadcrumb = ({ parentGroupBreadcrumb }) => {
  if (parentGroupBreadcrumb) {
    return parentGroupBadge(
      parentGroupBreadcrumb.reference,
      parentGroupBreadcrumb.title,
      parentGroupBreadcrumb.slug
    );
  } else {
    return null;
  }
};

const LessonBreadcrumb = ({ group, lesson, gradeNumber }) => {
  const { parentGroupBreadcrumb } = lesson.data.attributes;
  return (
    <div className="breadcrumb swoosh-breadcrumb breadcrumb--white">
      <a className="badge" href="/guitar-lessons">
        All Courses
      </a>
      <ParentGroupBreadcrumb parentGroupBreadcrumb={parentGroupBreadcrumb} />
      {group && (
        <>
          <a
            className={
              "badge " + (gradeNumber ? `grade-badge grade-badge--grade-${gradeNumber}` : "")
            }
            href={`/${
              parentGroupBreadcrumb.slug.toLowerCase() === "playground" ? "playground" : "modules"
            }/${group.attributes.slug}`}
          >
            <span>
              {group.attributes.tabHeaderForClassPage ||
                breadcrumbTitleFormatter(group.attributes.title)}
            </span>
          </a>
          <span className="lesson__steps">{group.relationships.lessons.data.length} Lessons</span>
        </>
      )}
    </div>
  );
};

export default LessonBreadcrumb;
