import React, { useRef } from "react";

export const SongsPageSearch = ({ shouldShow, query, setQuery, className = "song-search" }) => {
  const inputRef = useRef();

  if (shouldShow) return null;

  return (
    <div className={className} onClick={() => inputRef.current?.focus()}>
      <div className="song-search__inner">
        <i className="icon icon-search-thin" />
        <input ref={inputRef} type="text" onChange={setQuery} />
      </div>
    </div>
  );
};

export default SongsPageSearch;
