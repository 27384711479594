import React from "react";
import PropTypes from "prop-types";

import { TABS_TRIAL_DAYS } from "./TabsPlanSelect";

const PricingOverview = ({ trialAvailable }) => {
  if (!trialAvailable) {
    return <h2 id="tabs">subscribe to JustinGuitar Tabs!</h2>;
  }

  return (
    <>
      <h2 id="tabs">Start my {`${TABS_TRIAL_DAYS}-day free trial!`}</h2>

      <p className="tabs-payment__trial__description">
        {`Try a ${TABS_TRIAL_DAYS} day free trial of JustinGuitar Tabs!`}
      </p>

      <p className="tabs-payment__trial__notice">
        Choose your subscription plan for when your {TABS_TRIAL_DAYS}-day free trial ends:
      </p>
    </>
  );
};

PricingOverview.propTypes = {
  trialAvailable: PropTypes.bool.isRequired,
};

export default PricingOverview;
