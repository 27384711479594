import React from "react";
import { connect } from "react-redux";
import { chords } from "../../store/songSelectors";
import Img from "./Img";
import ToggleSection from "./ToggleSection";
import { withMobileOnlySwoosh } from "../../../components/shared/WithMobileOnlySwoosh";
import { SWOOSH_BACKGROUND } from "./consts";
import { CDN_URL } from "../../../components/shared/CdnUrl";

class Chords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    let { isOpen } = this.state;
    let { chords, currentUser } = this.props;

    if (chords.length === 0) {
      return null;
    } else {
      return (
        <div
          className={
            "section-toggle__section section-toggle__chords " +
            (isOpen ? "section-toggle__section--active" : "")
          }
        >
          <ToggleSection _this={this} title="Chord Grips" isOpen={isOpen} />
          {isOpen && (
            <div className="flex song__chords-list">
              {chords.map((chord) => {
                return (
                  <div key={chord.attributes.id}>
                    <Img
                      image={`${CDN_URL}/images/pages/chords/chord-diagrams/${
                        currentUser &&
                        currentUser.data.attributes.settings.includes("left-hand") &&
                        chord.attributes.imageLeftHand
                          ? chord.attributes.imageLeftHand
                          : chord.attributes.imageRightHand
                      }`}
                      title={chord.attributes.title}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    }
  }
}

// const wrappedWithSwoosh = withMobileOnlySwoosh(Chords, SWOOSH_BACKGROUND);

const mapStateToProps = (state, { width }) => ({
  chords: chords(state),
  width: width,
});

export default connect(mapStateToProps)(Chords);
