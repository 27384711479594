import React from "react";
import LessonList from "../components/LessonList";

export default class LessonListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lessonsActive: false,
      modulesActive: true,
    };
  }

  showModules = (e) => {
    e.preventDefault();
    this.setState({ modulesActive: true, lessonsActive: false });
  };

  showLessons = (e) => {
    e.preventDefault();
    this.setState({ modulesActive: false, lessonsActive: true });
  };

  render() {
    return (
      <div>
        <ul className="nav nav-tabs lessons-index-tabs" role="tablist">
          <li className={"header3 spaced bold " + (this.state.modulesActive ? "active" : "")}>
            <a onClick={this.showModules}>MODULES</a>
          </li>
          <li className={"header3 spaced bold " + (this.state.lessonsActive ? "active" : "")}>
            <a onClick={this.showLessons}>LESSONS</a>
          </li>
        </ul>

        <div className="tab-content">
          <div role="tabpanel" className="tab-pane paragraph active">
            {this.state.modulesActive && (
              <LessonList
                lessonsActive={false}
                modulesActive={true}
                modules={this.props.modules["modules"]}
                difficulty={this.props.lessons["levels"]}
                grades={this.props.lessons["grades"]}
                moduleFiltersCount={this.props.modules["module_count"]}
                category={this.props.lessons["categories"]}
                itemTotal={this.props.modules["modules"].length}
              />
            )}

            {this.state.lessonsActive && (
              <LessonList
                lessonsActive={true}
                modulesActive={false}
                lessons={this.props.lessons["lessons"]}
                difficulty={this.props.lessons["levels"]}
                grades={this.props.lessons["grades"]}
                lessonFiltersCount={this.props.lessons["lesson_count"]}
                category={this.props.lessons["categories"]}
                itemTotal={this.props.lessons["lessons"].length}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
