import React from "react";
import Slider from "react-slick";
import { CDN_URL } from "./CdnUrl";
import { withInnerWidth } from "./WithInnerWidth";

const carouselSettings = (promoItems) => {
  return {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
};

const PromoItem = ({ imgSrc, title, blank, link = null }) => {
  if (imgSrc) {
    return (
      <div
        className={`promo-item ${link ? "promo-item__link" : ""}`}
        onClick={() =>
          link
            ? blank
              ? window.open(link, "_blank").focus()
              : (window.location.href = link)
            : null
        }
      >
        <img src={imgSrc.includes("://") ? imgSrc : `${CDN_URL}/${imgSrc}`} alt={title} />
      </div>
    );
  } else {
    return null;
  }
};

const PromoItems = ({ promoItems, width }) => {
  return null; // temporarily disable
  // return (
  //   promoItems.length > 0 && (
  //     <div className="promo-items">
  //       <Slider {...carouselSettings(promoItems)}>
  //         {promoItems.map(
  //           (
  //             {
  //               data: {
  //                 attributes: { image, mobileImage, title, targetBlank, link },
  //               },
  //             },
  //             idx
  //           ) => (
  //             <PromoItem
  //               key={idx}
  //               imgSrc={width > 576 ? image : mobileImage}
  //               title={title}
  //               blank={targetBlank}
  //               link={link}
  //             />
  //           )
  //         )}
  //       </Slider>
  //     </div>
  //   )
  // );
};

export default withInnerWidth(PromoItems);
