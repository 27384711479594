import React from "react";
import { StripeProvider } from "react-stripe-elements";
import Checkout from "../components/Checkout";
import PropTypes from "prop-types";

export default class StripeContainer extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    frequency: PropTypes.string.isRequired,
    subscription: PropTypes.bool.isRequired,
    formOpen: PropTypes.bool.isRequired,
  };

  constructor(props, _rails) {
    super(props);
    this.state = {
      stripe: null,
    };
  }

  componentDidMount() {
    // handle async Stripe load
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(this.props.apiKey) });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        this.setState({ stripe: window.Stripe(this.props.apiKey) });
      });
    }
  }

  render() {
    return (
      <StripeProvider apiKey={this.props.apiKey}>
        <Checkout
          title={this.props.title}
          recaptchaKey={this.props.recaptchaKey}
          price={this.props.price}
          reference={this.props.reference}
          frequency={this.props.frequency}
          subscription={this.props.subscription}
          formOpen={this.props.formOpen}
          stripeCustomerId={this.props.stripeCustomerId}
        />
      </StripeProvider>
    );
  }
}
