import React from "react";
import LoginPrompt from "../../components/auth/LoginPrompt";
import { Star } from "@mui/icons-material";

const INCLUDED = [
  "A <strong>pre-lesson questionnaire</strong> to help Justin tailor your coaching session.",
  "<strong>50 minutes of undivided attention from Justin</strong> in a Zoom meeting.",
  "An <strong>Action Plan & Practice Routine</strong> outlining Justin's recommended steps.",
];
const WhatYouGet = ({ currentUser }) => {
  return (
    <div className="section what-you-will-get">
      <h2 className="title">What you will get?</h2>
      <div className="content">
        <div className="bg"></div>
        <div className="description">
          <div className="text">
            <h3>Included in your 1:1 with Justin Lesson Pack:</h3>
            <ul>
              {INCLUDED.map((item, idx) => (
                <li key={idx}>
                  <Star />
                  <p dangerouslySetInnerHTML={{ __html: item }} />
                </li>
              ))}
            </ul>

            <h3 className="cost">What's the Cost?</h3>
            <p className="cost">
              Each 1:1 with Justin Lesson costs <strong>USD 699</strong>; all funds go to{" "}
              <a href="/playin-it-forward" target="_blank">
                Playin’ It Forward
              </a>
              .<br />
              Are you ready to commit?
            </p>

            {!currentUser ? (
              <div className="login-prompt">
                <LoginPrompt
                  loginParagraph="You must be logged in to register your interest. Not a member yet? Register, it’s free."
                  token={ReactOnRails.authenticityToken()}
                />
              </div>
            ) : (
              <div className="ctas">
                <button
                  className="button button--primary"
                  onClick={() => (window.location.href = "/private-one-on-one-lessons#register")}
                  disabled={false}
                >
                  {false ? "Your registration has been received" : "Register interest"}
                </button>
                <button
                  className="button button--teal"
                  onClick={() => (window.location.href = "/private-one-on-one-lessons#faq")}
                >
                  Read FAQ
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatYouGet;
