import { configureStore, createSlice } from "@reduxjs/toolkit";
import ReactOnRails from "react-on-rails";

import { fetchSearchResults } from "./searchEffects";

const initialState = {
  loading: false,
  searchOpen: false,
  filtersOpen: false,
  filters: {}, // todo: immutable.js set?
  query: "",
  advancedQuery: "",
  results: [],
  advancedResults: {},
  currentUser: null,
  visibleType: null,
};

const { actions, reducer } = createSlice({
  name: "search",
  initialState,
  reducers: {
    openSearch: (state) => {
      state.searchOpen = true;
    },
    closeSearch: (state) => {
      state.searchOpen = false;
      state.filtersOpen = false;
    },
    openFilters: (state) => {
      state.filtersOpen = true;
    },
    closeFilters: (state) => {
      state.filtersOpen = false;
    },
    setQuery: (state, { payload }) => {
      state.query = payload;
    },
    setAdvancedQuery: (state, { payload }) => {
      state.advancedQuery = payload;
    },
    toggleFilter(state, { payload }) {
      // NB: JS Set is not supported in redux store because it's not serializable
      // consider adding immutable.js as a dependency
      if (state.filters[payload] === true) {
        delete state.filters[payload];
      } else {
        state.filters[payload] = true;
      }
    },
    setFilters: (state, { payload }) => {
      state.filters = payload.reduce((filters, filter) => ({ ...filters, [filter]: true }), {});
    },
    clearFilters: (state) => {
      state.filters = {};
    },
    setResults: (state, { payload }) => {
      state.results = payload;
    },
    setAdvancedResults: (state, { payload }) => {
      state.advancedResults = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setCurrentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    setVisibleType: (state, { payload }) => {
      state.visibleType = payload;
    },
    clearUserImage: (state, { payload }) => {
      state.currentUser = {
        ...state.currentUser,
        data: { attributes: { ...state.currentUser.data.attributes, imageUid: null } },
      };
    },
    setUserImage: (state, { payload }) => {
      state.currentUser = {
        ...state.currentUser,
        data: { attributes: { ...state.currentUser.data.attributes, imageUid: payload } },
      };
    },
    setUsername: (state, { payload }) => {
      state.currentUser = {
        ...state.currentUser,
        data: { attributes: { ...state.currentUser.data.attributes, username: payload } },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchResults.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSearchResults.fulfilled, (state, { payload }) => {
        state.results = payload;
        state.loading = false;
      });
  },
});

export const {
  openSearch,
  closeSearch,
  openFilters,
  closeFilters,
  toggleFilter,
  setFilters,
  clearFilters,
  setQuery,
  setAdvancedQuery,
  setResults,
  setAdvancedResults,
  setLoading,
  setCurrentUser,
  setVisibleType,
  clearUserImage,
  setUserImage,
  setUsername,
} = actions;

const createSearchStore = (preloadedState) =>
  configureStore({
    reducer,
    preloadedState,
  });

ReactOnRails.registerStore({
  searchStore: (props, railsContext) => createSearchStore({ ...initialState, ...props }),
});
