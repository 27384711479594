import React from "react";

export default class Podcast extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="podcast__container row">
        {this.props.podcasts.map((podcast) => (
          <div key={podcast.id} className="col-md-12">
            <label>{podcast.title}</label>
            <br />
            <audio id="podcast__player" controls src={podcast.file_name}>
              Your browser does not support the <code>audio</code> element.
            </audio>
          </div>
        ))}
      </div>
    );
  }
}
