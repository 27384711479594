import React from "react";
import { connect } from "react-redux";

const TABS = [
  { name: "Dashboard", icon: "home", nestedTabs: ["latest"] },
  { name: "Practice", icon: "practice-note" },
  { name: "Favorites", icon: "heart" },
  { name: "Songbook", icon: "record" },
  { name: "Notes", icon: "pencil-right" },
  { name: "Journey", icon: "hat" },
  { name: "App", icon: "phone" },
  { name: "Account", icon: "gears" },
];

export function goToTab(currentUser, tabName) {
  const { slug } = currentUser.data.attributes;
  window.location.href = `/users/${slug}/${tabName.toLowerCase()}`;
}

const DashboardTabsHome = ({ currentUser }) => {
  return (
    <div className="dashboard__tabs" style={{ display: "flex" }}>
      {TABS.map((tab, idx) => {
        return (
          <button
            key={idx}
            className={"tab " + (tab["name"] === "Dashboard" ? "active" : "")}
            onClick={() => {
              goToTab(currentUser, tab["name"]);
            }}
          >
            <i className={`icon icon-${tab["icon"]}`}></i>
            {tab["name"]}
          </button>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(DashboardTabsHome);
