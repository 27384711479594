import React, { useEffect, useState } from "react";
import { CDN_URL } from "../../components/shared/CdnUrl";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import LazyLoad from "react-lazyload";
import { SUBSCRIPTION_STATUSES } from "../../subscriptions/consts";

const PMT_PRODUCT_NAME = "Practical Music Theory";

const QuizCard = ({ quiz, currentUser, openDialog, isLocked, hasPMTEnrollment }) => {
  if (!quiz) return null;
  const [quizAttempt, setQuizAttempt] = useState(null);
  const [hasActivePmtSubscription, setHasActivePmtSubscription] = useState(false);

  function fetchQuizAttempt() {
    axiosWithCSRF()
      .get(`/users/${currentUser.data.id}/quiz_attempts?quiz_id=${quiz.id}`)
      .then((response) => {
        setQuizAttempt(response.data.data);
      });
  }

  function fetchSubscriptions() {
    axiosWithCSRF()
      .get(`/users/${currentUser.data.id}/subscriptions`)
      .then((response) => {
        const { subscriptions } = response.data.data;
        const activePmtSubscription = subscriptions.data.some(
          (subscription) =>
            subscription.attributes.productName === PMT_PRODUCT_NAME &&
            subscription.attributes.status === SUBSCRIPTION_STATUSES.active
        );
        setHasActivePmtSubscription(activePmtSubscription);
      });
  }

  useEffect(() => {
    if (currentUser) {
      fetchQuizAttempt();
      fetchSubscriptions();
    }
  }, []);

  const { slug, title, passPercent } = quiz;

  const canAccessQuiz = hasPMTEnrollment || hasActivePmtSubscription;

  const goToQuiz = (slug, quizAttempt, user) => {
    canAccessQuiz
      ? (window.location.href = `/quizzes/${slug}${quizAttempt == null ? "" : "/results"}`)
      : openDialog({
          message: `Please make sure you are logged in and enrolled in the Practical Music Theory course to access this quiz.`,
          confirmButtonText: "enroll",
          data: user,
        });
  };

  return (
    <div className="pglesson">
      <div onClick={() => (isLocked ? null : goToQuiz(slug, quizAttempt, currentUser))}>
        <LazyLoad>
          <div
            className="pglesson__thumb"
            style={{
              background: `url('${CDN_URL}/redesign/2020/quiz/quiz-thumb.png')`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              opacity: isLocked ? "0.5" : "1",
            }}
          ></div>
          {quizAttempt && quizAttempt.attributes.scorePercent >= passPercent ? (
            <i className="icon icon icon-check_circle_outline" />
          ) : null}
        </LazyLoad>
      </div>
      <div className="pglesson__details">
        {isLocked ? (
          <span>
            <i className="icon icon-lock"></i>
            {title}
          </span>
        ) : (
          <div onClick={() => goToQuiz(slug, quizAttempt, currentUser)} className="quiz-title">
            {title}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizCard;
