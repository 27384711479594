import React from "react";
import ReactPlayer from "react-player";
import LiteYoutube from "../components/shared/LiteYoutube";

const ParentGroupVideo = ({ url }) => {
  return (
    <div className="pg__video">
      <LiteYoutube url={url} hasMaxResImage={true} />
    </div>
  );
};

export default ParentGroupVideo;
