import { createAsyncThunk } from "@reduxjs/toolkit";
import * as slices from "../slices";
import {
  selectArtistFilters,
  selectArtistMetadata,
  selectArtistQuery,
  selectArtistsArray,
  selectArtistSortBy,
  selectGenres,
  selectGrades,
  selectProducts,
  selectSongsByArtist,
} from "../songSelectors";
import Fuse from "fuse.js";
import { filterOptionToQueryValue, filtersByType, filterTypes } from "../../filters";
import { sortByOptions } from "../shared";
import debounce from "lodash.debounce";

export const fetchArtists = createAsyncThunk("songs/querySongs", (_, thunkApi) => {
  thunkApi.dispatch(slices.loading.actions.setArtistsLoading(true));

  return sortAndFilter(thunkApi);
});
const sortAndFilter = debounce(({ getState, dispatch }) => {
  return new Promise((resolve) => {
    // take this work off the sync stack
    setTimeout(() => {
      const state = getState();
      const allArtists = selectArtistsArray(state);

      const { page, perPage } = selectArtistMetadata(state);

      const sortedFilteredArtists = applySort(
        applyQuery(applyFilters(allArtists, state), state),
        state
      );
      const pagedArtists = sortedFilteredArtists.slice(0, page * perPage);
      const totalCount = sortedFilteredArtists.length;

      dispatch(slices.ui.actions.setListingArtists(pagedArtists));
      dispatch(slices.metadata.actions.setTotalCount({ entity: "artists", totalCount }));
      dispatch(slices.loading.actions.setArtistsLoading(false));

      resolve();
    }, 10);
  });
}, 300);

const applyQuery = (artists, state) => {
  const query = selectArtistQuery(state);
  return query == null || query.length === 0
    ? artists
    : new Fuse(artists, {
        keys: ["attributes.name"],
        threshold: 0.3,
      })
        .search(query)
        .map(({ item }) => item);
};

const applyFilters = (artists, state) => {
  const activeFilters = Object.keys(selectArtistFilters(state));

  if (activeFilters.length === 0) {
    return artists;
  }

  let filteredSongs = applyGradeFilter(
    artists,
    activeFilters.filter((f) => filtersByType[filterTypes["Difficulty Level"]].includes(f)),
    state
  );

  filteredSongs = applyProductFilter(
    filteredSongs,
    activeFilters.filter((f) => filtersByType[filterTypes["Books & Apps"]].includes(f)),
    state
  );

  filteredSongs = applyGenreFilter(
    filteredSongs,
    activeFilters.filter((f) => filtersByType[filterTypes.Genre].includes(f)),
    state
  );

  return filteredSongs;
};

const applyGradeFilter = (artists, grades, state) => {
  if (grades.length === 0) {
    return artists;
  }

  const gradesSet = new Set(grades.map((f) => filterOptionToQueryValue[f]));

  const stateGrades = selectGrades(state);

  return artists.filter((artist) => {
    const songs = selectSongsByArtist(state)(artist);
    return songs.some((song) => {
      const gradeRelation = song.relationships.grade;

      if (gradeRelation == null || gradeRelation.data == null) {
        return false;
      }

      const { belt } = stateGrades[gradeRelation.data.id].attributes;

      if (belt == null) {
        return false;
      }

      return gradesSet.has(belt.toLowerCase());
    });
  });
};

const applyProductFilter = (artists, products, state) => {
  if (products.length === 0) {
    return artists;
  }

  const productsSet = new Set(products.map((f) => filterOptionToQueryValue[f]));
  const allProducts = selectProducts(state);

  return artists.filter((artist) => {
    const songs = selectSongsByArtist(state)(artist);

    return songs.some((song) => {
      const productsRelations = song.relationships.products;

      if (
        productsRelations == null ||
        productsRelations.data == null ||
        productsRelations.length === 0
      ) {
        return false;
      }

      const songProducts = new Set(
        productsRelations.data.map(({ id }) => allProducts[id].attributes.title)
      );

      return Array.from(songProducts).some((c) => productsSet.has(c));
    });
  });
};

const applyGenreFilter = (artists, genres, state) => {
  if (genres.length === 0) {
    return artists;
  }

  const genresSet = new Set(genres.map((f) => filterOptionToQueryValue[f]));

  const stateGenres = selectGenres(state);

  return artists.filter((artist) => {
    const songs = selectSongsByArtist(state)(artist);

    return songs.some((song) => {
      const genreRelation = song.relationships.genre;

      if (genreRelation == null || genreRelation.data == null) {
        return false;
      }

      const { title } = stateGenres[genreRelation.data.id].attributes;

      return genresSet.has(title.toLowerCase());
    });
  });
};

const applySort = (artists, state) => {
  const sort = selectArtistSortBy(state);
  switch (sort) {
    case sortByOptions.relevance:
      return artists; // already sorted by Fuse
    case sortByOptions.titleDesc:
      return artists.sort((a, b) => b.attributes.name.localeCompare(a.attributes.name));
    case sortByOptions.titleAsc:
      return artists.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name));
    case sortByOptions.popularity:
    default:
      // popularity is calculated by the server as an average of artist's song youtube views
      return artists.sort((a, b) => b.attributes.popularity - a.attributes.popularity);
  }
};
