import React from "react";
import axiosWithCSRF from "./shared/axiosWithCSRF";
import StripeContainer from "../components/StripeContainer";

export default class StripeOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: false,
      isSubscription: null,
      itemPrice: null,
      stripeCustomerId: null,
    };
  }

  componentDidMount() {
    this.setStripeUserId();
  }

  setStripeUserId = () => {
    // get stripe customer id here rather than server side, so we don't need to do a lot of rescuing and retrying
    axiosWithCSRF()
      .get("/stripe_sales_id", {
        params: {
          user_id: this.props.userId,
        },
      })
      .then((response) => {
        this.setState({ stripeCustomerId: response.data.stripe_sales_id });
      });
  };

  handleOptionSelect = (optionType) => {
    if (optionType == "subscription") {
      let displaySubPrice =
        this.props.price.split(".")[1] == "0" ? parseInt(this.props.price) : this.props.price;
      this.setState({
        optionSelected: true,
        isSubscription: true,
        itemPrice: displaySubPrice.toString(),
        frequency: this.props.frequency,
      });
    } else if (optionType == "lifetime") {
      let displayLifetimePrice =
        this.props.lifetime_price.split(".")[1] == "0"
          ? parseInt(this.props.lifetime_price)
          : this.props.lifetime_price;
      this.setState({
        optionSelected: true,
        isSubscription: false,
        itemPrice: displayLifetimePrice.toString(),
        frequency: "once",
      });
    }
  };

  moneyFormat = (amount) => {
    // if integer is .0, remove it from display price. same below.
    return amount.split(".")[1] == "0" ? parseInt(amount) : amount;
  };

  render() {
    return (
      <div className="stripe-options">
        <h3 className="header3 justin-blue bold no-margin uppercase">Purchase Options:</h3>
        <div className="stripe-options__container">
          <button
            className={
              "stripe-options__option stripe-options__option--subscription " +
              (this.state.isSubscription == true ? "active-option" : "")
            }
            onClick={() => this.handleOptionSelect("subscription")}
          >
            Subscription payment {this.props.frequency} for USD {this.moneyFormat(this.props.price)}
          </button>
          <button
            className={
              "stripe-options__option stripe-options__option--lifetime " +
              (this.state.isSubscription == false ? "active-option" : "")
            }
            onClick={() => this.handleOptionSelect("lifetime")}
          >
            Lifetime access for USD {this.moneyFormat(this.props.lifetime_price)}
          </button>
        </div>

        {this.state.optionSelected && (
          <StripeContainer
            apiKey={this.props.apiKey}
            price={this.state.itemPrice}
            title={this.props.title}
            reference={this.props.reference}
            subscription={this.state.isSubscription}
            frequency={this.state.frequency}
            formOpen={true}
            recaptchaKey={this.props.recaptchaKey}
            stripeSalesId={this.state.stripeSalesId}
          />
        )}
      </div>
    );
  }
}
