import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { LoginModal } from "../../../jg-material/auth/LoginModal";
import { useSelector } from "react-redux";
import {
  selectIsLoggedIn,
  selectCurrentUser,
} from "../../../redux/slices/session/session.selectors";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { WorkshopEntity } from "../interfaces";
import { workshopSelectors } from "../store/workshops.slice";
import { workshopToPurchaseReference, workshopToRemainingFeeReference } from "../deposit/helpers";

export const RegisterYourInterestButton = ({ workshop }: { workshop: WorkshopEntity }) => {
  const [showLoginPrompt, setShowLoginPrompt] = React.useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const currentUser = useSelector(selectCurrentUser);
  const userIsRegistered = useSelector(workshopSelectors.selectCurrentUserIsRegistered);
  const userCanPayDeposit = useSelector(workshopSelectors.selectCurrentUserCanPayDeposit);

  const handleClick = () => {
    if (!isLoggedIn) {
      setShowLoginPrompt(true);
    } else if (userCanPayDeposit) {
      window.location.href = `/workshops/${workshop.attributes.slug}/deposit`;
    } else {
      window.location.href = `/workshops/${workshop.attributes.slug}/register`;
    }
  };

  // never disable button if user needs to pay
  // disable when sold out or user is registered but not approved
  const isDisabled =
    (!userCanPayDeposit && workshop.attributes.isSoldOut) ||
    (userIsRegistered && !userCanPayDeposit);

  const purchaseReference = workshopToPurchaseReference(workshop);
  const userPaidDeposit = (currentUser?.data.attributes.purchasesForDashboard || []).some(
    (purchase) => purchase.data.attributes.purchaseReference === purchaseReference
  );

  const remainingFeeReference = workshopToRemainingFeeReference(workshop);
  const userPaidRemainingFee = (currentUser?.data.attributes.purchasesForDashboard || []).some(
    (purchase) => purchase.data.attributes.purchaseReference === remainingFeeReference
  );

  return (
    <Box m={jgDenimTheme.spacing(6, 0, 4, 0)}>
      {userPaidDeposit && userPaidRemainingFee ? (
        <p>
          You're all set up! If you have any questions, please get in touch with{" "}
          <a href="mailto:laryne@justinguitar.com">laryne@justinguitar.com</a>.<br />
          <br />
          See you soon!
        </p>
      ) : (
        <>
          <Button
            onClick={handleClick}
            disabled={isDisabled}
            sx={{ letterSpacing: "unset !important" }}
          >
            {userIsRegistered && userCanPayDeposit
              ? !userPaidDeposit
                ? "Click to Pay Deposit"
                : "Click to Pay Remaining Fee"
              : workshop.attributes.isSoldOut
              ? "Sold Out!"
              : userIsRegistered
              ? "Interest Submitted!"
              : !isLoggedIn
              ? "Login to Register"
              : "Register Your Interest"}
          </Button>
          {/*{workshop.attributes.slug == "2023-Acoustic-Electric-Classics" &&*/}
          {/*  !isDisabled &&*/}
          {/*  isLoggedIn &&*/}
          {/*  !userCanPayDeposit && (*/}
          {/*    <Typography sx={{ marginTop: "30px" }}>*/}
          {/*      💡{" "}*/}
          {/*      <span style={{ fontStyle: "italic" }}>*/}
          {/*        <strong>*/}
          {/*          Please contact{" "}*/}
          {/*          <a href="mailto:laryne@justinguitar.com">laryne@justinguitar.com</a> if you'd*/}
          {/*          like to attend this event.*/}
          {/*        </strong>{" "}*/}
          {/*        Please note that alumni have priority on booking; read the{" "}*/}
          {/*        <a href="#workshop-faq">FAQ</a> to learn more about Booking Priority.*/}
          {/*      </span>*/}
          {/*    </Typography>*/}
          {/*  )}*/}
          {workshop.attributes.slug == "2023-Acoustic-Electric-Classics" &&
            isDisabled &&
            workshop.attributes.isSoldOut && (
              <Typography sx={{ marginTop: "30px" }}>
                💡{" "}
                <span style={{ fontStyle: "italic" }}>
                  <strong>
                    Please contact{" "}
                    <a href="mailto:laryne@justinguitar.com">laryne@justinguitar.com</a> if you'd
                    like to enter the Waiting List for this specific event.
                  </strong>{" "}
                  Please read the <a href="#workshop-faq">FAQ</a> to learn more about Booking
                  Priority & Waiting List.
                </span>
              </Typography>
            )}
          {/*{!isDisabled &&*/}
          {/*  workshop.attributes.slug == "2023-Acoustic-Jam-Week" &&*/}
          {/*  (!userPaidDeposit || !userPaidRemainingFee) && (*/}
          {/*    <Typography*/}
          {/*      sx={{ marginTop: "10px" }}*/}
          {/*      dangerouslySetInnerHTML={{*/}
          {/*        __html: !userPaidDeposit*/}
          {/*          ? "You have until <strong>March 10, 2023</strong>, to pay your deposit and guarantee your spot in this Workshop!"*/}
          {/*          : "You have until <strong>May 31, 2023</strong>, to pay the balance of your Workshop fee. We'll email a reminder, so you don't miss that out!",*/}
          {/*      }}*/}
          {/*    ></Typography>*/}
          {/*  )}*/}
          {isLoggedIn &&
            userIsRegistered &&
            !isDisabled &&
            (!userPaidDeposit || !userPaidRemainingFee) && (
              <Typography sx={{ marginTop: "30px" }}>
                {workshop.attributes.slug === "2023-Acoustic-Jam-Week" ? (
                  <>
                    {!userPaidDeposit ? (
                      <>
                        You have until <strong>March 10, 2023</strong>, to pay your deposit and
                        guarantee your spot in this Workshop!
                      </>
                    ) : (
                      <>
                        You must pay your remaining £2300 balance before{" "}
                        <strong>May 31, 2023</strong>, to secure your Workshop & Accommodation spot.
                      </>
                    )}
                  </>
                ) : workshop.attributes.slug === "2023-Acoustic-Electric-Classics" ? (
                  <>
                    {!userPaidDeposit ? (
                      <>
                        💡 You must pay a non-refundable <strong>£400</strong> deposit before{" "}
                        <strong>August 8, 2023</strong>, to secure your Workshop & Accommodation
                        spot.
                      </>
                    ) : (
                      <>
                        💡 You must pay your remaining <strong>£2300</strong> balance before{" "}
                        <strong>August 15, 2023</strong>, to secure your Workshop & Accommodation
                        spot.
                      </>
                    )}
                  </>
                ) : null}
              </Typography>
            )}
          <LoginModal isOpen={showLoginPrompt} handleClose={() => setShowLoginPrompt(false)} />
        </>
      )}
    </Box>
  );
};
