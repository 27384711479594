import React from "react";
import PropTypes from "prop-types";
import { Portal } from "@mui/material";
export default class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Portal disablePortal={!this.props.coverViewport}>
        <div
          className={`loading__cover loading__cover--${
            this.props.isInput
              ? "input"
              : this.props.isContent
              ? "content"
              : this.props.coverViewport
              ? "viewport"
              : "form"
          }`}
        >
          <div className="loading__spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </Portal>
    );
  }
}

Loading.propTypes = {
  isInput: PropTypes.bool,
  isContent: PropTypes.bool,
  coverViewport: PropTypes.bool,
};
