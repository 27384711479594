import { Box } from "@mui/material";
import React from "react";
import { SECTION_TOP_MARGIN } from "./consts";
import Category from "../FAQ/Category";

const BluesImmersionFaqs = ({ faqs }) => {
  return (
    <Box mt={SECTION_TOP_MARGIN} display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Box className="faq" id="faqs" sx={{ width: "100%" }}>
        <Category
          faqs={faqs}
          showHeader={false}
          categoryData={null}
          useUrlRouting={false}
          showBreadcrumbs={false}
        />
      </Box>
    </Box>
  );
};

export default BluesImmersionFaqs;
