import React from "react";
import PropTypes from "prop-types";
import { TABS_TRIAL_DAYS } from "./TabsPlanSelect";

const CallToActionButton = ({ eventClick, trialAvailable, visible, disabled }) => {
  if (visible === false) {
    return null;
  }

  return (
    <>
      <button
        className="button button--primary"
        disabled={!!disabled}
        onClick={() => {
          if (!disabled) {
            eventClick();
          }
        }}
      >
        {trialAvailable ? `start my ${TABS_TRIAL_DAYS}-day free trial` : "subscribe now"}
      </button>
      <p className="tabs-payment__disclaimer">
        * Please note that <strong>JustinGuitarTABS</strong> and{" "}
        <strong>JustinGuitar Songs & Lessons App</strong> are two different products. Subscribing to
        one of them doesn’t give you access to the other. Read the FAQ below for more information,
        or <a href="#">contact us</a>.
      </p>
    </>
  );
};

CallToActionButton.propTypes = {
  eventClick: PropTypes.func.isRequired,
  trialAvailable: PropTypes.bool.isRequired,
};

export default CallToActionButton;
