import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentUserSongRequests,
  selectCurrentUserSongRequestsListingLoading,
  selectCurrentUserSongRequestsMetadata,
  selectIsLoggedIn,
} from "../store";
import SongRequestListing from "./SongRequestListing";
import { InfiniteScroll } from "../../shared/InfiniteScroll";
import { fetchCurrentUserSongRequests } from "../store/effects/fetchCurrentUserSongRequests";

export default function () {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userSongRequests = useSelector(selectCurrentUserSongRequests);
  const loading = useSelector(selectCurrentUserSongRequestsListingLoading);
  const metadata = useSelector(selectCurrentUserSongRequestsMetadata);
  const dispatch = useDispatch();

  if (!isLoggedIn || userSongRequests == null || userSongRequests.length === 0) {
    return null;
  }

  return (
    <div className="user-song-requests">
      <h4 className="heading4">Your Song Requests</h4>
      <InfiniteScroll
        enableInfiniteScroll={false}
        loading={loading}
        metadata={metadata}
        fetchPage={(page) => dispatch(fetchCurrentUserSongRequests(page))}
      >
        <SongRequestListing songRequests={userSongRequests} voteDisabled={true} />
      </InfiniteScroll>
    </div>
  );
}
