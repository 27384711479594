import React from "react";
import axiosWithCSRF from "../../../shared/axiosWithCSRF";

export default class ArchiveItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  archiveItem = (item) => {
    axiosWithCSRF()
      .patch(`/user_practice_items/archive_item/${item.id}`)
      .then((res) => {
        this.props.handleArchivedItem(res.data.user_practice_item);
      })
      .catch((error) => {
        this.props.handleArchivedItem(null);
      });
  };

  render() {
    let { item } = this.props;
    return (
      <div className="practice-buttons__container">
        <button
          className="practice__button practice__button--red"
          onClick={() => this.archiveItem(item)}
        >
          archive item
        </button>
        <button
          className="practice__button practice__button--cancel"
          onClick={this.props.closeForm}
        >
          cancel
        </button>
      </div>
    );
  }
}
