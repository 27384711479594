import React from "react";

const JoinForFree = () => {
  return (
    <div className="join-cta">
      <a href="/users/sign_up" className="button button--primary">
        Join & Learn for Free!
      </a>
    </div>
  );
};

export default JoinForFree;
