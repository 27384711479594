import React, { useEffect, useState } from "react";
import { Box, Container, ThemeProvider, Typography } from "@mui/material";
import { LOGO } from "../../header/LargeHeader";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import Registration from "../registrations/index";
import {
  SESSION_CONTAINER_STYLE,
  SESSION_CONTAINER_MOBILE_STYLE,
  BASE_CONTAINER_STYLE,
  MOBILE_CONTAINER_STYLE,
} from "./consts";
import WhyRegister from "../registrations/WhyRegister";
import SignIn from "./SignIn";
import RequestPasswordReset from "../passwords/RequestPasswordReset";
import ChangePassword from "../passwords/ChangePassword";
import { withInnerWidth } from "../../shared";
import { BootstrapSize, breakpoints } from "../../shared/bootstrap-helpers";

export const PAGES = {
  registration: "/users/sign_up",
  signIn: "/users/sign_in",
  requestPasswordReset: "/users/password/new",
  changePassword: "/users/password/edit",
};

const PAGES_WITH_RECAPTCHA = [PAGES.registration, PAGES.requestPasswordReset];

const sectionHeader = (content) => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img
          onClick={() => (window.location.href = "/")}
          style={{ maxWidth: "150px", cursor: "pointer" }}
          src={LOGO}
          alt="JustinGuitar"
        />
      </Box>
      <Typography
        component="h1"
        variant="h8"
        sx={{
          mt: 3,
          mb: 2,
          fontSize: "22px",
          textTransform: "none",
          letterSpacing: 0,
          fontWeight: "bold",
        }}
      >
        {content}
      </Typography>
    </>
  );
};

const FooterContent = ({ activePage }) => {
  if (activePage === PAGES.registration) {
    return <WhyRegister />;
  } else {
    return null;
  }
};

const UserSession = ({ width }) => {
  const defaultPageFromCurrentUrl = () => {
    return PAGES[
      Object.keys(PAGES).find((key) => {
        return PAGES[key] === window.location.pathname;
      })
    ];
  };
  const [activePage, setActivePage] = useState(defaultPageFromCurrentUrl());

  useEffect(() => {
    if (activePage !== window.location.pathname) {
      window.history.pushState({ page: activePage }, "", activePage);
    }
  }, [activePage]);

  const content = () => {
    switch (activePage) {
      case PAGES.registration:
        return <Registration sectionHeader={sectionHeader} setActivePage={setActivePage} />;
      case PAGES.signIn:
        return <SignIn sectionHeader={sectionHeader} setActivePage={setActivePage} />;
      case PAGES.requestPasswordReset:
        return <RequestPasswordReset sectionHeader={sectionHeader} setActivePage={setActivePage} />;
      case PAGES.changePassword:
        return <ChangePassword sectionHeader={sectionHeader} setActivePage={setActivePage} />;
    }
  };

  return (
    <ThemeProvider theme={jgDenimTheme}>
      <Box
        sx={{
          ...SESSION_CONTAINER_STYLE,
          ...(width < breakpoints[BootstrapSize.smDevice] && SESSION_CONTAINER_MOBILE_STYLE),
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            ...BASE_CONTAINER_STYLE,
            ...(width < breakpoints[BootstrapSize.smDevice] && MOBILE_CONTAINER_STYLE),
            ...(PAGES_WITH_RECAPTCHA.includes(activePage) && { paddingBottom: "60px" }), //clear recaptcha
          }}
        >
          <Box
            my={4}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {content()}
          </Box>
        </Container>

        <FooterContent activePage={activePage} />
      </Box>
    </ThemeProvider>
  );
};

const WrappedUserSession = withInnerWidth(UserSession);

export default (props) => <WrappedUserSession {...props} />;
