import React from "react";
import PropTypes from "prop-types";

export class PreventScroll extends React.Component {
  componentDidMount() {
    if (this.shouldPreventScroll()) {
      this.preventScroll();
      this.preventing = true;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const shouldPrevent = this.shouldPreventScroll();
    if (shouldPrevent && !this.preventing) {
      this.preventScroll();
    } else if (this.preventing && !shouldPrevent) {
      this.restoreScroll();
    }
  }

  componentWillUnmount() {
    if (this.preventing) {
      this.restoreScroll();
    }
  }

  preventScroll() {
    const top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";
    document.body.style.top = top;
  }

  restoreScroll() {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }

  shouldPreventScroll() {
    if (typeof this.props.shouldPreventScroll === "boolean") {
      return this.props.shouldPreventScroll;
    }

    if (typeof this.props.shouldPreventScroll === "function") {
      return this.props.shouldPreventScroll();
    }

    return true;
  }

  render() {
    return this.props.children;
  }
}

PreventScroll.defaultProps = {
  shouldPreventScroll: true,
};

PreventScroll.propTypes = {
  children: PropTypes.node.isRequired,
  shouldPreventScroll: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
};

export function preventScroll(ChildComponent, { shouldPreventScroll = true } = {}) {
  return class extends React.Component {
    render() {
      return (
        <PreventScroll shouldPreventScroll={shouldPreventScroll}>
          <ChildComponent {...this.props} />
        </PreventScroll>
      );
    }
  };
}
