import ReactOnRails from "react-on-rails";
import { configureStore, createSlice, combineReducers } from "@reduxjs/toolkit";
import { ui } from "../../components/shared/ui/uiIndex";
import { session } from "../../components/shared/session/sessionIndex";
import { deleteAllRecentlyViewed } from "../../components/shared/session/sessionEffects";
import { nestSlice } from "../../components/shared/nestSlice";

export const songbookTabPanel = {
  songbooks: "songbooks",
  songs: "songs",
};

const initialSongOptionsState = {
  songIds: [],
  meta: {
    page: 1,
    perPage: 12,
    totalCount: 0,
  },
  query: "",
  loading: false,
};

const songOptionsSlice = createSlice({
  name: "songOptions",
  initialState: initialSongOptionsState,
  reducers: {
    setSongOptions(state, { payload }) {
      state.songIds = payload;
    },
    setSongOptionsPage(state, { payload }) {
      state.meta.page = payload;
    },
    setSongOptionsTotalCount(state, { payload }) {
      state.meta.totalCount = payload;
    },
    setSongOptionsLoading(state, { payload }) {
      state.loading = payload;
    },
    setSongOptionsQuery(state, { payload }) {
      state.query = payload;
    },
  },
});

const initialState = (props) => {
  return {
    dashboard: {
      activeTab: props?.entity?.dashboard?.activeTab || "",
      renamingSongbookId: null,
      songbookTabPanel: songbookTabPanel.songbooks,
      currentSongbookId: null,
      chords: [],
    },
    songbooks: {},
    songs: {},
    songbookSongs: {},
    artists: {},
    songOptions: initialSongOptionsState,
  };
};

const initialSessionState = (props) => {
  return {
    session: { currentUser: props.currentUser },
  };
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState(),
  reducers: {
    // dashboard-specific
    setActiveTab: (state, { payload }) => {
      state.dashboard.activeTab = payload;
      const pathName = window.location.pathname;
      // if user is at root of dashboard, the payload needs to be appended; if not, it needs to replace what's after the last /
      const newLocation =
        pathName.split("/")[pathName.split("/").length - 2] === "users"
          ? `${pathName}/${payload}`
          : pathName.replace(
              /\/[^\/]*$/,
              payload.toLowerCase() === "dashboard" ? "" : `/${payload.toLowerCase()}`
            );
      window.history.pushState({}, "", newLocation);
    },
    setAllLatestLessons: (state, { payload }) => {
      state.dashboard.allLatestLessons = payload;
    },
    setRenamingSongbookId: (state, { payload }) => {
      state.dashboard.renamingSongbookId = payload;
    },
    setSongbookTabPanel: (state, { payload }) => {
      state.dashboard.songbookTabPanel = payload;
    },
    setCurrentSongbookId: (state, { payload }) => {
      state.dashboard.currentSongbookId = payload;
    },
    // entities
    setSongbooks: (state, { payload }) => {
      state.songbooks = payload;
    },
    appendSongbooks: (state, { payload }) => {
      state.songbooks = {
        ...state.songbooks,
        ...payload,
      };
    },
    setSongbook: (state, { payload }) => {
      state.songbooks[payload.id] = payload;
    },
    removeSongbook: (state, { payload }) => {
      delete state.songbooks[payload.id];
    },
    setSongs: (state, { payload }) => {
      state.songs = payload;
    },
    appendSongs: (state, { payload }) => {
      state.songs = {
        ...state.songs,
        ...payload,
      };
    },
    setSongbookSongs: (state, { payload }) => {
      state.songbookSongs = payload;
    },
    appendSongbookSongs: (state, { payload }) => {
      state.songbookSongs = {
        ...state.songbookSongs,
        ...payload,
      };
    },
    replaceSongbookSongsForSongbook: (state, { payload: songbookSongs }) => {
      const songbookSongsArr = Object.values(songbookSongs);

      if (songbookSongsArr.length === 0) {
        return;
      }

      const songbookIds = new Set(songbookSongsArr.map((s) => Number(s.attributes.songbookId)));

      const idsToDelete = Object.entries(state.songbookSongs)
        .filter(([_, songbookSong]) => songbookIds.has(Number(songbookSong.attributes.songbookId)))
        .map(([id]) => id);

      idsToDelete.forEach((id) => {
        // new list of songbook songs might be shorter than existing, so delete everything existing from state and replace with new list from action payload
        delete state.songbookSongs[id];
      });

      state.songbookSongs = {
        ...state.songbookSongs,
        ...songbookSongs,
      };
    },
    setArtists: (state, { payload }) => {
      state.artists = payload;
    },
    appendArtists: (state, { payload }) => {
      state.artists = {
        ...state.artists,
        ...payload,
      };
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(deleteAllRecentlyViewed.fulfilled, (state) => {
  //     state.dashboard.recentPageviews = [];
  //   });
  // },
  extraReducers: {
    ...nestSlice("songOptions", songOptionsSlice),
    [deleteAllRecentlyViewed.fulfilled.type]: (state) => {
      state.dashboard.recentPageviews = [];
    },
  },
});

export const {
  setActiveTab,
  setAllLatestLessons,
  setArtists,
  setSongbooks,
  setSongs,
  setSongbookSongs,
  setRenamingSongbookId,
  setSongbook,
  removeSongbook,
  setSongbookTabPanel,
  setCurrentSongbookId,
  appendArtists,
  appendSongbookSongs,
  replaceSongbookSongsForSongbook,
  appendSongs,
  appendSongbooks,
} = dashboardSlice.actions;

export const {
  setSongOptionsTotalCount,
  setSongOptionsLoading,
  setSongOptionsPage,
  setSongOptions,
  setSongOptionsQuery,
} = songOptionsSlice.actions;

const reducer = combineReducers({
  entity: dashboardSlice.reducer,
  ui: ui.reducer,
  session: session.reducer,
});

const createDashboardStore = (preloadedState) =>
  configureStore({
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
        serializableCheck: {
          // // Ignore these action types
          // ignoredActions: ["ui/openDialog", "ui/closeDialog"],
          // // Ignore these field paths in all actions
          ignoredActionPaths: [
            "payload.onConfirm", // onConfirm is a non-serializable function
          ],
          // // Ignore these paths in the state
          ignoredPaths: [
            "ui.dialog.onConfirm", // onConfirm is a non-serializable function
          ],
        },
      }),
  });

ReactOnRails.registerStore({
  dashboardStore: (props, railsContext) => {
    const { dashboard: initialStateDashboard, ...initialStateEntity } = initialState(props);
    const { dashboard: propsDashboard, ...propsEntity } = props.entity;
    return createDashboardStore({
      entity: {
        dashboard: {
          ...initialStateDashboard,
          ...propsDashboard,
        },
        ...initialStateEntity,
        ...propsEntity,
      },
      ui: {
        ...ui.initialState,
        ...props.ui,
      },
      session: {
        ...initialSessionState,
        ...props.session,
      },
    });
  },
});
