import React from "react";
import PropTypes from "prop-types";
import { CDN_URL } from "../../components/shared/CdnUrl";

const DRAGONFLY_PATH = "/system/dragonfly/production/";

const SongTile = ({ title, gradeId, artistName, albumThumb }) => {
  return (
    <div className="dashboard-tile dashboard-tile--song">
      {gradeId && <div className={`tile tile--grade-${gradeId}`}>{gradeId}</div>}
      <div className="dashboard-tile__inner">
        {albumThumb && (
          <div className="dashboard-tile__thumb">
            <img
              src={`${CDN_URL}${DRAGONFLY_PATH}${albumThumb}`}
              alt={`${title} by ${artistName}`}
            />
          </div>
        )}
        <div>
          <div className="dashboard-tile__title">
            {title.length > 30 ? `${title.substring(0, 27)}...` : title}
          </div>
          {artistName && <span className="dashboard-tile__sub">{artistName}</span>}
        </div>
      </div>
    </div>
  );
};

SongTile.propTypes = {
  title: PropTypes.string.isRequired,
  artistName: PropTypes.string,
  gradeId: PropTypes.number,
  albumThumb: PropTypes.string,
};

export default SongTile;
