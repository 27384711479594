import React from "react";
import axiosWithCSRF from "../../../shared/axiosWithCSRF";

export default class UpdateItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  componentWillMount() {
    let { item } = this.props;
    this.setState({
      itemTitle: item.title || "",
      itemDuration: item.duration || "",
      itemDescription: item.description || "",
      itemMetronome: item.metronome || "",
      itemStatistic: item.statistic_name || "",
      itemLink: item.related_link || "",
    });
  }

  handleChange = (type, e) => {
    if (e.target.type === "checkbox") {
      this.setState({ [type]: e.target.checked });
    } else {
      this.setState({ [type]: e.target.value });
    }
  };

  formDisabled = () => {
    let { itemTitle, itemDuration } = this.state;
    if (
      this.props.isSubmitting === false &&
      itemTitle &&
      itemTitle.length > 0 &&
      itemDuration &&
      itemDuration.toLocaleString().length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  submitForm = () => {
    this.props.setSubmitting(true);
    axiosWithCSRF()
      .patch(`/user_practice_items/${this.props.item.id}`, {
        user_practice_item: {
          title: this.state.itemTitle,
          duration: this.state.itemDuration,
          metronome: this.state.itemMetronome,
          statistic_name: this.state.itemStatistic,
          description: this.state.itemDescription,
          related_link: this.state.itemLink,
        },
      })
      .then((response) => {
        this.props.handleUpdatedItem(response.data.practice_item);
      })
      .catch((err) => {
        let error = "There was a problem saving this item.";
        if (err.response.data && err.response.data.message) {
          error = err.response.data.message;
        }
        this.props.handleUpdatedItem(null, error);
      });
  };

  render() {
    return (
      <div className="practice-form__container">
        <div className="practice-form__item">
          <div className="practice-form__inner">
            <span className="icon icon-pencil-outline"></span>
            <input
              value={this.state.itemTitle}
              onChange={(e) => this.handleChange("itemTitle", e)}
            ></input>
          </div>
          <span className="routine__form-field__label">Title*</span>
        </div>

        <div className="practice-form__item">
          <div className="practice-form__inner">
            <span className="icon icon-pencil-outline"></span>
            <input
              value={this.state.itemDescription}
              onChange={(e) => this.handleChange("itemDescription", e)}
            ></input>
          </div>
          <span className="routine__form-field__label">Description</span>
        </div>

        <div className="practice-form__item">
          <div className="practice-form__inner">
            <span className="icon icon-pencil-outline"></span>
            <input
              type="number"
              value={this.state.itemDuration}
              onChange={(e) => this.handleChange("itemDuration", e)}
            ></input>
          </div>
          <span className="routine__form-field__label">Duration*</span>
        </div>

        <div className="practice-form__item">
          <div className="practice-form__inner">
            <span className="icon icon-pencil-outline"></span>
            <input
              value={this.state.itemLink}
              onChange={(e) => this.handleChange("itemLink", e)}
            ></input>
          </div>
          <span className="routine__form-field__label">Related Link</span>
        </div>

        <div className="practice-form__item">
          <div className="practice-form__inner">
            <span className="icon icon-pencil-outline"></span>
            <input
              value={this.state.itemStatistic}
              onChange={(e) => this.handleChange("itemStatistic", e)}
            ></input>
          </div>
          <span className="routine__form-field__label">Statistic Name</span>
        </div>

        <div className="practice-buttons__container practice-buttons__container--margin-vertical">
          <button
            disabled={this.formDisabled()}
            className="practice__button practice__button--save"
            onClick={this.submitForm}
          >
            save
          </button>
          <button
            className="practice__button practice__button--cancel"
            onClick={this.props.closeForm}
          >
            cancel
          </button>
        </div>
      </div>
    );
  }
}
