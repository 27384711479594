import { Box, Modal, Typography } from "@mui/material";
import React, { useState } from "react";

const CALENDLY_LINK = "https://calendly.com/justin-guitar/onboarding-call-with-fanny-1-clone-2";

const TalkToAHuman = ({ buttonJustify }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "740px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

  return (
    <>
      <button
        className="button button--white"
        style={{ justifyContent: buttonJustify }}
        onClick={() => setModalOpen(true)}
      >
        Book Support Call
      </button>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)} className="onboarding-modal">
        <Box sx={style}>
          <Typography variant="h5" mb={2}>
            👋 Need an extra hand to kickstart your guitar journey with us? Book a personalized
            video call with Fanny, our Student Success Specialist, and tailor your guitar learning
            experience to perfection!{" "}
          </Typography>

          <a
            style={{ display: "inline-flex" }}
            className="button button--primary"
            href={CALENDLY_LINK}
            target="_blank"
          >
            Book Call
          </a>
        </Box>
      </Modal>
    </>
  );
};

export default TalkToAHuman;
