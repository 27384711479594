import React from "react";
import PropTypes from "prop-types";
import { Box, Link, Typography } from "@mui/material";

import { CDN_URL } from "./shared/CdnUrl";
import { withInnerWidth } from "./shared";
import { BootstrapSize, breakpoints } from "./shared/bootstrap-helpers";

const IMG_HEIGHT = 250;

const IMG_STYLE = {
  position: "absolute",
  bottom: 0,
  left: -30,
  maxHeight: IMG_HEIGHT,
};

const BOX_STYLE = {
  background: "radial-gradient(56.03% 156.07% at 50% 50%, #fc9644 0%, #fd6a02 100%)",
  borderRadius: "10px",
  position: "relative",
  minHeight: IMG_STYLE.maxHeight + 20,
  maxWidth: 780,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const MOBILE_BOX = {
  minHeight: BOX_STYLE.minHeight + IMG_HEIGHT,
  justifyContent: "space-between",
  paddingBottom: 0,
};

const MOBILE_IMG = {
  position: "relative",
  // bottom: 0,
  maxHeight: IMG_HEIGHT,
  marginTop: 1,
};

const MoneybackGuarantee = ({ readMoreLink, width }) => {
  const isMobile = width < breakpoints[BootstrapSize.smDevice];

  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} mt={5}>
      <Box sx={isMobile ? Object.assign({}, BOX_STYLE, MOBILE_BOX) : BOX_STYLE} p={3}>
        <Typography variant={"h4"} sx={{ color: "white" }}>
          30 Day Money Back Guarantee
        </Typography>
        <Typography
          variant={"body1"}
          my={3}
          style={{ color: "white", maxWidth: isMobile ? "75%" : "50%" }}
        >
          We'll refund you completely if you change your mind or don't enjoy this course. No
          questions asked.
        </Typography>
        <Link href={readMoreLink} className="button button--dark" sx={{ color: "white" }}>
          read more
        </Link>
        <img
          style={isMobile ? MOBILE_IMG : IMG_STYLE}
          src={`${CDN_URL}/images/pages/strumming-sos/justin-2.png`}
          alt="Justin"
        />
      </Box>
    </Box>
  );
};

export default withInnerWidth(MoneybackGuarantee);

MoneybackGuarantee.propTypes = {
  readMoreLink: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};
