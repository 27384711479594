import React from "react";
import axiosWithCSRF from "../../shared/axiosWithCSRF";
import NoteContainer from "../UserNotes/NoteContainer";
import PracticeIcons from "../PracticeIcons";

export default class FinishedPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statToRecord: "",
      saveTempo: false,
    };
  }

  logStatisticOrBpm = (callback) => {
    let metronomeSpeed = this.state.saveTempo === true ? this.props.unsavedMetronomeSpeed : null;
    let statToRecord = this.state.statToRecord.length > 0 ? this.state.statToRecord : null;
    axiosWithCSRF()
      .post("/add_stat_or_bpm_to_practice_session_item", {
        practice_session_item_id: this.props.loggedItemId,
        user_practice_item_id: this.props.currentItem.id,
        metronome_speed: metronomeSpeed,
        statistic: statToRecord,
      })
      .then((res) => {
        if (statToRecord) {
          this.props.updateStatItem(res.data.item);
        }
        if (metronomeSpeed) {
          this.props.updateItemWithChangedBpm(res.data.practice_item);
        }
        callback();
      });
  };

  handleStatChange = (event) => {
    this.setState({ statToRecord: event.target.value });
  };

  statPrompt = () => {
    let { currentItem } = this.props;
    if (currentItem.statistic_name && currentItem.statistic_name != "") {
      return (
        <div className="practice-items__form practice-items__form--log-session">
          <div className="practice-form__item">
            <div className="practice-form__container">
              <div className="practice-form__inner">
                <span className="icon icon-pencil-outline"></span>
                <input
                  type="number"
                  min="0"
                  value={this.state.statToRecord}
                  onChange={this.handleStatChange}
                ></input>
              </div>
              <span className="routine__form-field__label">{`Record Statistic - ${this.props.currentItem.statistic_name}`}</span>
            </div>
          </div>
        </div>
      );
    }
  };

  nextItem = () => {
    this.logPracticeInformation(this.props.nextItem);
  };

  repeatCurrent = () => {
    this.logPracticeInformation(this.props.repeatCurrent);
  };

  logPracticeInformation = (callback) => {
    if (this.state.statToRecord.length > 0 || this.state.saveTempo === true) {
      this.logStatisticOrBpm(callback);
    } else {
      callback();
    }
  };

  restartRoutine = () => {
    if (this.state.statToRecord.length > 0 || this.state.saveTempo === true) {
      this.logStatisticOrBpm();
    }
    this.props.restartRoutine();
  };

  finishedPrompt = () => {
    return (
      <div className="finished__prompt">
        <h5>Great job! You finished your practice routine!</h5>
        <div className="finished__inner">
          <div className="finished__left">
            {this.statPrompt()}
            {this.saveTempoPrompt()}

            <div className="practice-buttons__container practice-buttons__container--col">
              <button
                type="button"
                style={{ marginTop: "24px", width: "100%" }}
                className="practice__button practice__button--orange"
                onClick={this.restartRoutine}
              >
                finished for today
              </button>

              <button
                type="button"
                style={{ marginTop: "12px", width: "100%" }}
                className="practice__button practice__button--save"
                onClick={this.restartRoutine}
              >
                start routine again
              </button>
            </div>
          </div>

          <div className="finished__right">{this.noteForm()}</div>
        </div>
      </div>
    );
  };

  noteForm = () => {
    return (
      <NoteContainer
        item={this.props.currentItem}
        userId={this.props.userId}
        updateNoteInPracticeItem={this.props.updateNoteInPracticeItem}
      />
    );
  };

  toggleSaveTempo = () => {
    this.setState({ saveTempo: !this.state.saveTempo });
  };

  saveTempoPrompt = () => {
    let { unsavedMetronomeSpeed, currentItem } = this.props;
    if (
      unsavedMetronomeSpeed &&
      unsavedMetronomeSpeed != currentItem.metronome_speed &&
      unsavedMetronomeSpeed != 120
    ) {
      return (
        <div className="log-bpm">
          {this.state.saveTempo && (
            <span
              dangerouslySetInnerHTML={{ __html: PracticeIcons.checkedCheckboxIcon() }}
              onClick={this.toggleSaveTempo}
              className="log-bpm__img"
            />
          )}
          {!this.state.saveTempo && (
            <span
              dangerouslySetInnerHTML={{ __html: PracticeIcons.uncheckedCheckboxIcon() }}
              onClick={this.toggleSaveTempo}
              className="log-bpm__img"
            />
          )}
          <span className="log-bpm__label" onClick={this.toggleSaveTempo}>
            Save {unsavedMetronomeSpeed} BPM metronome tempo?
          </span>
        </div>
      );
    }
  };

  nextPrompt = () => {
    return (
      <div className="finished__prompt">
        <h5>Nice Work!</h5>
        <div className="finished__inner">
          <div className="finished__left">
            {this.statPrompt()}
            {this.saveTempoPrompt()}

            <div className="practice-buttons__container practice-buttons__container--margin-vertical">
              <button className="practice__button practice__button--save" onClick={this.nextItem}>
                Next Item
              </button>
              <button
                className="practice__button practice__button--orange"
                onClick={this.repeatCurrent}
              >
                Repeat Item
              </button>
            </div>
          </div>

          <div className="finished__right">{this.noteForm()}</div>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.finishedRoutine === true) {
      return this.finishedPrompt();
    } else {
      return this.nextPrompt();
    }
  }
}
