import React from "react";
import { Typography } from "@mui/material";

const SectionHeader = ({ children }) => {
  return (
    <Typography variant="h5" sx={{ letterSpacing: 0, mt: 3, mb: 2 }}>
      {children}
    </Typography>
  );
};

export default SectionHeader;
