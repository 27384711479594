import React from "react";
import { connect } from "react-redux";
import { selectAlertMessage, selectAlertSeverity, selectHasAlert } from "./store";
import { ui } from "./store/slices";

import { Snackbar, SnackbarContent, Portal } from "@mui/material";

export default connect(
  (state) => ({
    message: selectAlertMessage(state),
    severity: selectAlertSeverity(state),
    isOpen: selectHasAlert(state),
  }),
  (dispatch) => ({
    clearAlert: () => dispatch(ui.actions.clearAlert()),
  })
)(({ message, severity, isOpen, clearAlert }) => {
  const closeButton = <i className="icon icon-cross" onClick={clearAlert} />;

  return (
    <Portal>
      <Snackbar
        classes={{
          root: `snackbar`,
        }}
        open={isOpen}
        onClose={clearAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <SnackbarContent
          classes={{
            root: `snackbar__content snackbar__content--${severity || "info"}`,
          }}
          message={message}
          action={closeButton}
        />
      </Snackbar>
    </Portal>
  );
});
