import React, { useState, useRef, useEffect } from "react";
import { CDN_URL } from "../components/shared/CdnUrl";
import TopicCards from "../components/TopicCards";
import data from "../pages/FAQ/data/faq.json";
import { RECAPTCHA_KEY, STRIPE_TABS_PUBLISHABLE_KEY } from "../payments/PaymentConstants";
import TabsPayment from "./TabsPayment";
import { withInnerWidth } from "../components/shared";
import TabsVideo from "./TabsVideo";
import LatestReleases from "../components/songs/LatestReleases";
import SongsGridItem from "./SongsGridItem";
import { TabsFaqs } from "./TabsFaqs";

const includedItems = [
  {
    title: "chord & lyrics",
    image: "tabs-features-chords.jpeg",
    mobileImage: "tabs-features-chords-mobile.jpeg",
  },
  {
    title: "tabs & lyrics",
    image: "tabs-features-tabs.jpeg",
    mobileImage: "tabs-features-tabs-mobile.jpeg",
  },
  {
    title: "autoscroll",
    image: "tabs-features-autoscroll-3.jpg",
    mobileImage: "tabs-features-autoscroll-mobile-3.jpg",
  },
  {
    title: "display settings",
    image: "tabs-features-display.jpeg",
    mobileImage: "tabs-features-display-mobile.jpeg",
  },
  {
    title: "transposer",
    image: "tabs-features-transpose.jpeg",
    mobileImage: "tabs-features-transpose-mobile.jpeg",
  },
  {
    title: "full screen mode",
    image: "tabs-features-newwindow.jpeg",
    mobileImage: null,
  },
];

const VerifiedIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46 24L41.12 18.42L41.8 11.04L34.58 9.4L30.8 3L24 5.92L17.2 3L13.42 9.38L6.2 11L6.88 18.4L2 24L6.88 29.58L6.2 36.98L13.42 38.62L17.2 45L24 42.06L30.8 44.98L34.58 38.6L41.8 36.96L41.12 29.58L46 24ZM20.18 33.44L12.58 25.82L15.54 22.86L20.18 27.52L31.88 15.78L34.84 18.74L20.18 33.44Z"
      fill="#1D3446"
    />
  </svg>
);

export default withInnerWidth(
  ({
    user,
    songs,
    subscriptionReferences,
    trialAvailable,
    songsSubscriber,
    width,
    tabsFaqs,
    sheetCount,
    tabsCount,
  }) => {
    const tabsCategory = data.find((category) => category.slug === "tabs");

    const [activeIncludedItem, setActiveIncludedItem] = useState(includedItems[0]);
    const [isMobileView, setIsMobileView] = useState(width <= 576);

    const setPage = (page) => {
      window.location.href = `/faq/${page.questionData.slug}`;
    };

    useEffect(() => {
      if (isMobileView && activeIncludedItem.title.toLowerCase() === "full screen mode") {
        setActiveIncludedItem(includedItems[includedItems.length - 2]);
      }
    }, [isMobileView]);

    useEffect(() => {
      if (width <= 576 && !isMobileView) {
        setIsMobileView(true);
      }

      if (width > 576 && isMobileView) {
        setIsMobileView(false);
      }
    }, [width]);

    const songDisplayCount =
      width >= 1200 ? 5 : width >= 992 ? 4 : width >= 768 ? 3 : width >= 576 ? 2 : 1;

    const includedItemsCount = isMobileView ? includedItems.length - 1 : includedItems.length;

    return (
      <div className="products-listing products-listing--tabs container">
        <h2>what’s included in JustinGuitar Tabs?</h2>
        <div className="pill-btn-container">
          {includedItems.slice(0, includedItemsCount).map((includedItem, idx) => (
            <button
              key={idx}
              className={`pill-btn${
                activeIncludedItem.title === includedItem.title ? " active" : ""
              }`}
              onClick={() => setActiveIncludedItem(includedItem)}
            >
              {includedItem.title}
            </button>
          ))}
        </div>
        <img
          className="included-item-image"
          src={`${CDN_URL}/images/products/redesign/features/${
            width <= 576 ? activeIncludedItem.mobileImage : activeIncludedItem.image
          }`}
          alt={activeIncludedItem.title}
        />
        {width <= 576 ? <TabsVideo /> : null}
        <div className="verified-badges">
          <div>
            <VerifiedIcon />
            Chord & Lyric Sheets for
            <br />
            {sheetCount} Song Lessons
          </div>
          <div>
            <VerifiedIcon />
            Detailed Tabs for
            <br />
            {tabsCount} Song Lessons
          </div>
          <div>
            <VerifiedIcon />
            New Content
            <br />
            Added Weekly!
          </div>
        </div>
        <img
          src={
            width <= 576
              ? `${CDN_URL}/images/products/redesign/approved-by-justin-sm.png`
              : `${CDN_URL}/images/products/redesign/approved-by-justin-2.png`
          }
          alt="Approved by Justin"
          className="approved-by-justin"
        />
        {!songsSubscriber && (
          <TabsPayment
            stripeKey={STRIPE_TABS_PUBLISHABLE_KEY}
            recaptchaKey={RECAPTCHA_KEY}
            subscriptionReferences={subscriptionReferences}
            user={user}
            trialAvailable={trialAvailable}
            token={ReactOnRails.authenticityToken()}
          />
        )}
        <h2 style={{ paddingTop: songsSubscriber ? (width > 576 ? "90px" : "80px") : null }}>
          latest releases
        </h2>
        <div className="latest-releases">
          <div className="latest-releases__song-grid">
            {songs.slice(0, songDisplayCount).map((song, idx) => (
              <SongsGridItem key={idx} song={song} />
            ))}
          </div>
          <div className="latest-releases__nav">
            <a href="/songs" className="button button--primary">
              search all songs
            </a>
          </div>
        </div>
        <TabsFaqs faqs={tabsFaqs} additionalContent={width > 576 ? <TabsVideo /> : null} />
      </div>
    );
  }
);
