import { PLAN_TYPES } from "../consts";
import * as moment from "moment";

const CHECKOUT_DATE_FORMAT = "MMMM Do YYYY";

export function unixToFormattedDate(unixDate) {
  return moment.unix(unixDate).format(CHECKOUT_DATE_FORMAT);
}

export function humanizedPricingPlanDuration(pricingPlan) {
  if (pricingPlan.planType === PLAN_TYPES.lifetime) {
    return "Lifetime Access";
  } else if (pricingPlan.planType === PLAN_TYPES.installment) {
    return "Installment Plan";
  } else if (pricingPlan.intervalInMonths === 1) {
    return "Monthly Subscription";
  } else if (pricingPlan.intervalInMonths === 12) {
    return "Yearly Subscription";
  } else if (pricingPlan.intervalInMonths === 6) {
    return "6-Month Subscription";
  }
}

export function nextPaymentDue(createdAt, pricingPlan) {
  if (pricingPlan.planType === PLAN_TYPES.lifetime) return null;

  const nextPaymentDate = moment.unix(createdAt);
  nextPaymentDate.add(pricingPlan.intervalInMonths, "M");

  return moment(nextPaymentDate).format(CHECKOUT_DATE_FORMAT);
}
