import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ParentGroupHero from "./ParentGroupHero";
import ParentGroupIntro from "./ParentGroupIntro";
import ParentGroupLiveClasses from "./ParentGroupLiveClasses";
import ModulesList from "./modules/ModulesList";
import axiosWithCSRF from "../components/shared/axiosWithCSRF";
import { ParentGroupFAQ } from "./ParentGroupFAQ";

export const PMT_REFERENCE = "PMT";

const ParentGroupContainer = ({ parentGroup, releasedLessonIds, currentUser, faqs }) => {
  if (!parentGroup) return null;

  const [hasPMTEnrollment, setHasPMTEnrollment] = useState(false);

  const { reference } = parentGroup.data.attributes;

  useEffect(() => {
    if (reference === PMT_REFERENCE && currentUser) {
      axiosWithCSRF()
        .get(`/users/${currentUser.data.id}/enrollments`)
        .then((response) => {
          const { enrollments, activeSubscriptions } = response.data.data;
          const isSubscribed =
            enrollments.includes(PMT_REFERENCE) || activeSubscriptions.includes(PMT_REFERENCE);
          setHasPMTEnrollment(isSubscribed);
        });
    }
  }, []);

  function lessonCountByModule(moduleId) {
    const group = parentGroup.included.find(
      (item) => parseInt(moduleId) === parseInt(item.id) && item.type === "group"
    );

    const { lessonOrder } = group.attributes;
    return lessonOrder.filter((lessonId) => releasedLessonIds.includes(lessonId)).length;
  }

  return (
    <div className="pg">
      <ParentGroupHero currentUser={currentUser} hasPMTEnrollment={hasPMTEnrollment} />
      <div className="container">
        <ParentGroupIntro parentGroup={parentGroup} />
        <ParentGroupLiveClasses parentGroup={parentGroup} />
        <div className="inner-offset">
          <ModulesList
            parentGroup={parentGroup}
            currentUser={currentUser}
            lessonCountByModule={lessonCountByModule}
            hasPMTEnrollment={hasPMTEnrollment}
          />
          <div style={{ margin: "50px 0" }} />
        </div>
      </div>
      {!!faqs && (
        <div className="faq-padding">
          <div className="faq" id="faq">
            <ParentGroupFAQ faqs={faqs} />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  parentGroup: state.parentGroup.parentGroup,
  releasedLessonIds: state.parentGroup.releasedLessonIds,
  currentUser: state.parentGroup.session.currentUser,
  faqs: state.parentGroup.faqs,
});

export default connect(mapStateToProps)(ParentGroupContainer);
