import { WorkshopRegistration } from "../interfaces/workshop-registration.interface";

import axiosWithCSRF from "../../../components/shared/axiosWithCSRF-typed";
import { Workshop, WorkshopEntity } from "../interfaces";

const basePath = "/workshops";

const basePathWithSlug = (slug: Workshop["slug"]) => `${basePath}/${slug}`;

export const createWorkshopRegistration = (
  registration: WorkshopRegistration,
  workshop: WorkshopEntity
) => {
  return axiosWithCSRF().post<WorkshopRegistration & { id: number }>(
    `${basePathWithSlug(workshop.attributes.slug)}/register`,
    {
      registration,
    }
  );
};

export const fetchWorkshops = () =>
  // rails seems to require .json extension (shouldn't the Accept header be sufficient?)
  axiosWithCSRF().get<{ data: WorkshopEntity[] }>(`${basePath}.json`, {
    headers: {
      Accept: "application/json;charset=UTF-8",
    },
  });
