export const initialState = {
  loading: {
    song: false,
    lesson: false,
    group: false,
    product: false,
    resource: false,
  },
  searchOpen: false,
  filtersOpen: false,
  filters: {},
  query: "",
  results: {
    entities: { song: {}, lesson: {}, group: {}, product: {}, resource: {} },
    result: {
      song: { data: [], metadata: {} },
      lesson: { data: [], metadata: {} },
      group: { data: [], metadata: {} },
      product: { data: [], metadata: {} },
      resource: { data: [], metadata: {} },
    },
  },
  currentUser: null,
  visibleType: null,
};
