import React from "react";
import PropTypes from "prop-types";
import { DefaultImage, CDN_PREFIX } from "../../components/header/CurrentUserBadge";

const CompetitionWinners = ({ winners }) => {
  return (
    <div className="competition__content">
      <div className="competition__copy">
        <h2>The Competition is Now Closed!</h2>

        <p>The lucky winners are:</p>

        <div className="competition__winners">
          {winners.map((winner) => {
            return (
              <div className="competition__user">
                {winner.image_uid ? (
                  <div
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "50%",
                      background: `url(${CDN_PREFIX}${winner.image_uid})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                ) : (
                  <DefaultImage width="70" height="70" />
                )}
                <p>{winner.username}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CompetitionWinners;
