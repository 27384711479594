export const data = [
  {
    id: 1,
    title: "On the 1",
    0: "down",
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
  },
  {
    id: 2,
    title: "On the 1 & 3",
    0: "down",
    1: null,
    2: null,
    3: null,
    4: "down",
    5: null,
    6: null,
    7: null,
  },
  {
    id: 3,
    title: "On the Beat",
    0: "down",
    1: null,
    2: "down",
    3: null,
    4: "down",
    5: null,
    6: "down",
    7: null,
  },
  {
    id: 4,
    title: "Beats Plus 2 Up",
    0: "down",
    1: null,
    2: "down",
    3: "up",
    4: "down",
    5: null,
    6: "down",
    7: null,
  },
];

export const strummingPatternData = [
  {
    id: 1,
    title: "On the 1",
    0: "down",
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
  },
  {
    id: 2,
    title: "One and Three",
    0: "down",
    1: null,
    2: null,
    3: null,
    4: "down",
    5: null,
    6: null,
    7: null,
  },
  {
    id: 3,
    title: "On The Beats",
    0: "down",
    1: null,
    2: "down",
    3: null,
    4: "down",
    5: null,
    6: "down",
    7: null,
  },
  {
    id: 4,
    title: "And After 1",
    0: "up",
    1: "down",
    2: null,
    3: "down",
    4: null,
    5: "down",
    6: null,
    7: null,
  },
  {
    id: 5,
    title: "And After Two",
    0: "down",
    1: "up",
    2: "down",
    3: null,
    4: "down",
    5: null,
    6: "down",
    7: null,
  },
  {
    id: 6,
    title: "And After Three",
    0: "down",
    1: "down",
    2: "down",
    3: "up",
    4: "down",
    5: null,
    6: "down",
    7: null,
  },
  {
    id: 7,
    title: "And After Four",
    0: "down",
    1: "down",
    2: "down",
    3: null,
    4: "down",
    5: null,
    6: null,
    7: "up",
  },
  {
    id: 8,
    title: "Eight At The Start",
    0: "up",
    1: "down",
    2: null,
    3: "up",
    4: "down",
    5: null,
    6: "down",
    7: null,
  },
  {
    id: 9,
    title: "Eight In The Middle",
    0: "down",
    1: null,
    2: "down",
    3: "up",
    4: null,
    5: "down",
    6: "up",
    7: "down",
  },
  {
    id: 10,
    title: "Eight At The End",
    0: "down",
    1: null,
    2: "down",
    3: null,
    4: "down",
    5: "up",
    6: "down",
    7: "up",
  },
  {
    id: 11,
    title: "Top And Tail Eights",
    0: "up",
    1: "down",
    2: null,
    3: "down",
    4: null,
    5: null,
    6: "down",
    7: "up",
  },
  {
    id: 12,
    title: "?",
    0: "up",
    1: null,
    2: "down",
    3: null,
    4: "down",
    5: "up",
    6: null,
    7: "up",
  },
  {
    id: 13,
    title: "?",
    0: "down",
    1: null,
    2: "down",
    3: "up",
    4: null,
    5: "down",
    6: null,
    7: "up",
  },
  {
    id: 14,
    title: "All Eights",
    0: "up",
    1: "down",
    2: "up",
    3: "down",
    4: "up",
    5: "down",
    6: "up",
    7: "down",
  },
  {
    id: 15,
    title: "Old Faithful",
    0: "down",
    1: null,
    2: "down",
    3: "up",
    4: "down",
    5: "up",
    6: null,
    7: "down",
  },
  {
    id: 16,
    title: "Old Faithful Plus",
    0: "down",
    1: null,
    2: "down",
    3: "up",
    4: "down",
    5: "up",
    6: null,
    7: "up",
  },
  {
    id: 17,
    title: "Missing Backbeats",
    0: "up",
    1: "down",
    2: null,
    3: null,
    4: "up",
    5: null,
    6: "up",
    7: null,
  },
  {
    id: 18,
    title: "One And Ups",
    0: "up",
    1: "down",
    2: null,
    3: null,
    4: "up",
    5: null,
    6: null,
    7: null,
  },
  {
    id: 19,
    title: "Faithless Two",
    0: "down",
    1: null,
    2: null,
    3: "up",
    4: null,
    5: "up",
    6: null,
    7: "up",
  },
  {
    id: 20,
    title: "Stand Sparse",
    0: "down",
    1: null,
    2: null,
    3: "up",
    4: null,
    5: null,
    6: null,
    7: null,
  },
  {
    id: 21,
    title: "Eights With Backbeat",
    0: "up",
    1: "down",
    2: "cross",
    3: "up",
    4: "down",
    5: "up",
    6: "cross",
    7: "up",
  },
  {
    id: 22,
    title: "Backbeat Faithful",
    0: "down",
    1: null,
    2: "cross",
    3: "up",
    4: null,
    5: "up",
    6: "cross",
    7: null,
  },
];

export const blankStrummingPattern = {
  0: null,
  1: null,
  2: null,
  3: null,
  4: null,
  5: null,
  6: null,
  7: null,
  id: 0,
  pattern_readable: "________",
  name: "Blank",
};

export const timeSignatureOptions = [
  { value: "1", label: "4:4" },
  { value: "0", label: "More Coming Soon!" },
  // { value: "2", label: "6:8" },
  // { value: "3", label: "3:4" },
];

export const tempoOptions = [
  // { value: "40", label: "40 BPM" },
  { value: "60", label: "60 BPM" },
  { value: "80", label: "80 BPM" },
  { value: "100", label: "100 BPM" },
  { value: "120", label: "120 BPM" },
  { value: "140", label: "140 BPM" },
  // { value: "160", label: "160 BPM" },
  // { value: "180", label: "180 BPM" },
  // { value: "200", label: "200 BPM" },
  // { value: "220", label: "220 BPM" },
  // { value: "240", label: "240 BPM" },
  // { value: "260", label: "260 BPM" },
  // { value: "280", label: "280 BPM" },
  // { value: "300", label: "300 BPM" },
];

export const soundOptions = [
  { value: "acoustic", label: "Acoustic Guitar" },
  { value: "electric", label: "Electric Guitar" },
  { value: "0", label: "More Coming Soon!" },
  // { value: "piano", label: "Piano" },
  // { value: "drums", label: "Drums" },
];

export const chordOptions = [
  { value: "a", label: "A" },
  { value: "am", label: "Am" },
  { value: "c", label: "C" },
  { value: "d", label: "D" },
  { value: "dm", label: "Dm" },
  { value: "e", label: "E" },
  { value: "em", label: "Em" },
  { value: "f", label: "F" },
  { value: "g", label: "G" },
];

// export const soundFiles = {
//   A1: "/audio/acoustic/Acoustic-A-Down.mp3",
//   A2: "/audio/acoustic/Acoustic-A-Up.mp3",
// };
//
// const soundFiles = {
//   "Acoustic-A-Down": "Acoustic-A-Down.mp3",
//   "Acoustic-A-Up": "Acoustic-A-Up.mp3",
//   "Electric-A-Down": "Electric-A-Down.mp3",
//   "Electric-A-Up": "Electric-A-Up.mp3",
//   "Electric-Am-Down": "Electric-Am-Down.mp3",
//   "Electric-Am-Up": "Electric-Am-Up.mp3",
//   "Electric-C-Down": "Electric-C-Down.mp3",
//   "Electric-C-Up": "Electric-C-Up.mp3",
//   "Electric-D-Down": "Electric-D-Down.mp3",
//   "Electric-D-Up": "Electric-D-Up.mp3",
//   "Electric-Dm-Down": "Electric-Dm-Down.mp3",
//   "Electric-Dm-Up": "Electric-Dm-Up.mp3",
//   "Electric-E-Down": "Electric-E-Down.mp3",
//   "Electric-E-Up": "Electric-E-Up.mp3",
//   "Electric-Em-Down": "Electric-Em-Down.mp3",
//   "Electric-Em-Up": "Electric-Em-Up.mp3",
//   "Electric-F-Down": "Electric-F-Down.mp3",
//   "Electric-F-Up": "Electric-F-Up.mp3",
//   "Electric-G-Down": "Electric-G-Down.mp3",
//   "Electric-G-Up": "Electric-G-Up.mp3",
//   "Electric-HIT": "Electric-HIT.mp3",
//   "Electric-Muted-Down": "Electric-Muted-Down.mp3",
//   "Electric-Muted-Up": "Electric-Muted-Up.mp3",
// };

export const soundFiles = {
  acoustic: {
    a: {
      A1: "/audio/acoustic/Acoustic-A-Down.mp3",
      A2: "/audio/acoustic/Acoustic-A-Up.mp3",
    },
    am: {
      A1: "/audio/acoustic/Acoustic-Am-Down.mp3",
      A2: "/audio/acoustic/Acoustic-Am-Up.mp3",
    },
    dm: {
      A1: "/audio/acoustic/Acoustic-Dm-Down.mp3",
      A2: "/audio/acoustic/Acoustic-Dm-Up.mp3",
    },
    e: {
      A1: "/audio/acoustic/Acoustic-E-Down.mp3",
      A2: "/audio/acoustic/Acoustic-E-Up.mp3",
    },
    g: {
      A1: "/audio/acoustic/Acoustic-G-Down.mp3",
      A2: "/audio/acoustic/Acoustic-G-Up.mp3",
    },
    em: {
      A1: "/audio/acoustic/Acoustic-Em-Down.mp3",
      A2: "/audio/acoustic/Acoustic-Em-Up.mp3",
    },
    f: {
      A1: "/audio/acoustic/Acoustic-F-Down.mp3",
      A2: "/audio/acoustic/Acoustic-F-Up.mp3",
    },
    d: {
      A1: "/audio/acoustic/Acoustic-D-Down.mp3",
      A2: "/audio/acoustic/Acoustic-D-Up.mp3",
    },
    c: {
      A1: "/audio/acoustic/Acoustic-C-Down.mp3",
      A2: "/audio/acoustic/Acoustic-C-Up.mp3",
    },
    muted: {
      A1: "/audio/acoustic/Acoustic-Muted-Down.mp3",
      A2: "/audio/acoustic/Acoustic-Muted-Up.mp3",
    },
    hit: "/audio/acoustic/Acoustic-HIT.mp3",
  },
  electric: {
    a: {
      A1: "/audio/electric/Electric-A-Down.mp3",
      A2: "/audio/electric/Electric-A-Up.mp3",
    },
    am: {
      A1: "/audio/electric/Electric-Am-Down.mp3",
      A2: "/audio/electric/Electric-Am-Up.mp3",
    },
    c: {
      A1: "/audio/electric/Electric-C-Down.mp3",
      A2: "/audio/electric/Electric-C-Up.mp3",
    },
    d: {
      A1: "/audio/electric/Electric-D-Down.mp3",
      A2: "/audio/electric/Electric-D-Up.mp3",
    },
    dm: {
      A1: "/audio/electric/Electric-Dm-Down.mp3",
      A2: "/audio/electric/Electric-Dm-Up.mp3",
    },
    e: {
      A1: "/audio/electric/Electric-E-Down.mp3",
      A2: "/audio/electric/Electric-E-Up.mp3",
    },
    em: {
      A1: "/audio/electric/Electric-Em-Down.mp3",
      A2: "/audio/electric/Electric-Em-Up.mp3",
    },
    f: {
      A1: "/audio/electric/Electric-F-Down.mp3",
      A2: "/audio/electric/Electric-F-Up.mp3",
    },
    g: {
      A1: "/audio/electric/Electric-G-Down.mp3",
      A2: "/audio/electric/Electric-G-Up.mp3",
    },
    muted: {
      A1: "/audio/electric/Electric-Muted-Down.mp3",
      A2: "/audio/electric/Electric-Muted-Up.mp3",
    },
    hit: "/audio/electric/Electric-HIT.mp3",
  },
  human: {
    1: "/audio/count/Count-1.mp3",
    2: "/audio/count/Count-2.mp3",
    3: "/audio/count/Count-3.mp3",
    4: "/audio/count/Count-4.mp3",
    5: "/audio/count/Count-5.mp3",
    6: "/audio/count/Count-6.mp3",
    and: "/audio/count/Count-and.mp3",
  },
};
