import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

const DeleteRoutine = (props) => {
  const deleteRoutine = () => {
    if (confirm("Are you sure? This action cannot be undone.")) {
      axios
        .delete(`/user_practice_routines/${props.routineId}`, {
          headers: {
            "X-CSRF-Token": ReactOnRails.authenticityToken(),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          props.removeRoutineById(props.routineId);
        })
        .catch((error) => {
          props.removeRoutineById(null);
        });
    }
  };

  return (
    <div className="practice-buttons__container practice-buttons__container--with-margin">
      <button className="practice__button practice__button--red" onClick={deleteRoutine}>
        delete routine
      </button>
      <button className="practice__button practice__button--cancel" onClick={props.closeForm}>
        cancel
      </button>
    </div>
  );
};

DeleteRoutine.PropTypes = {
  routineId: PropTypes.string.isRequired,
  removeRoutineById: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default DeleteRoutine;
