import React, { useState } from "react";
import { timeSignatureOptions, tempoOptions, soundOptions, chordOptions } from "./consts";
import { MinusIcon, PlusIcon } from "./Icons";

function Settings({
  timeSignature,
  setTimeSignature,
  tempo,
  setTempo,
  sound,
  setSound,
  metronome,
  setMetronome,
  soundMute,
  setSoundMute,
  chord,
  setChord,
  voiceCount,
  setVoiceCount,
}) {
  const handleBpmChange = (event) => {
    const newBpm = parseInt(event.target.value);
    setTempo(newBpm);
  };

  const decreaseBpm = () => {
    const bpm = parseInt(tempo) - 1;
    setTempo(bpm < 60 ? 60 : bpm);
  };

  const increaseBpm = () => {
    const bpm = parseInt(tempo) + 1;
    setTempo(bpm > 140 ? 140 : bpm);
  };

  return (
    <div className="settings">
      <p>settings</p>
      <p>
        Build your strumming pattern by clicking on the Up strums or Down strums (arrows) you want.
        Then press play!
      </p>
      <div className="select-options">
        <select
          id="time-select"
          value={timeSignature}
          onChange={(e) => (e.target.value == 1 ? setTimeSignature(e.target.value) : null)}
          className="form-control"
        >
          <option value="">Time signature</option>
          {timeSignatureOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {/*<select*/}
        {/*  id="tempo-select"*/}
        {/*  value={tempo}*/}
        {/*  onChange={(e) => setTempo(e.target.value)}*/}
        {/*  className="form-control"*/}
        {/*>*/}
        {/*  <option value="">Tempo</option>*/}
        {/*  {tempoOptions.map((option) => (*/}
        {/*    <option key={option.value} value={option.value}>*/}
        {/*      {option.label}*/}
        {/*    </option>*/}
        {/*  ))}*/}
        {/*</select>*/}
        <select
          id="sound-select"
          value={sound}
          onChange={(e) =>
            e.target.value == "acoustic" || e.target.value == "electric"
              ? setSound(e.target.value)
              : null
          }
          className="form-control"
        >
          <option value="">Sound</option>
          {soundOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <select
          id="chord-select"
          value={chord}
          onChange={(e) => (e.target.value != "" ? setChord(e.target.value) : null)}
          className="form-control"
        >
          <option value="">Chord</option>
          {chordOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group bpm-select">
        <div>
          <button onClick={() => decreaseBpm()}>
            <MinusIcon />
          </button>
          <p>
            Tempo: <span>{tempo}</span> bpm
          </p>
          <button onClick={() => increaseBpm()}>
            <PlusIcon />
          </button>
        </div>
        <div>
          <input
            type="range"
            className="form-control-range"
            id="bpmSlider"
            min={tempoOptions[0].value}
            max={tempoOptions[tempoOptions.length - 1].value}
            step={1}
            value={tempo}
            onChange={handleBpmChange}
          />
        </div>
      </div>
      <div className="toggles">
        <div className="form-toggle">
          <span>Strumming Sound</span>
          <button
            className={`toggle ${!soundMute ? "active" : ""}`}
            onClick={() => setSoundMute(!soundMute)}
          ></button>
        </div>
        <div className="form-toggle">
          <span>Voice Count</span>
          <button
            className={`toggle ${voiceCount ? "active" : ""}`}
            onClick={() => setVoiceCount(!voiceCount)}
          ></button>
        </div>
        <div className="form-toggle">
          <span>Metronome</span>
          <button
            className={`toggle ${metronome ? "active" : ""}`}
            onClick={() => setMetronome(!metronome)}
          ></button>
        </div>
      </div>
    </div>
  );
}

export default Settings;
