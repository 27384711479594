import React, { useState } from "react";
import { withInnerWidth } from "../../components/shared";
import Category from "../FAQ/Category";
import "../../components/shared/polyfills/replaceAll.js";
import { Elements, StripeProvider } from "react-stripe-elements";
import { RECAPTCHA_KEY, STRIPE_SALES_PUBLISHABLE_KEY } from "../../payments/PaymentConstants";
import { stripeLogo } from "../../components/payments/PaymentUtils";
import CheckoutForm from "../../components/CheckoutForm";
import LoginPrompt from "../../components/auth/LoginPrompt";

const PlusIcon = () => (
  <svg width="96" height="94" viewBox="0 0 96 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.1367 93.2V0.799994H60.9367V93.2H35.1367ZM0.536719 59.2V34.8H95.7367V59.2H0.536719Z"
      fill="white"
    />
  </svg>
);

const BundledProducts = () => (
  <>
    <ul>
      <li>
        <a href="/store/practical-music-theory" target="_blank">
          Practical Music Theory
        </a>
      </li>
      <li>
        <a href="/store/strumming-sos" target="_blank">
          SOS Strumming
        </a>
      </li>
      <li>
        <a href="/modules/transcribing-blues-solos" target="_blank">
          Transcribing Blues Solos
        </a>
      </li>
      <li>
        <a href="/modules/solo-blues-guitar" target="_blank">
          Solo Blues Guitar
        </a>
      </li>
    </ul>
    <p>
      Download your 2x 101 Children's songs ebooks by clicking{" "}
      <a href="/store/101-childrens-songs" target="_blank">
        this link
      </a>
      .
    </p>
    <p>
      Here is your 20% discount code for the{" "}
      <a href="https://merch.justinguitar.com" target="_blank">
        Merch store
      </a>
      : <span>HAPPYBF23</span>
    </p>
    <p>
      Here is your 20% discount code for the{" "}
      <a href="https://justinguitarjams.com" target="_blank">
        Jam Tracks store
      </a>
      : <span>HAPPYBF23</span>
    </p>
    <p>
      Here is your 20% discount code for the{" "}
      <a href="https://justinguitarapp.com" target="_blank">
        JustinGuitar Lessons & Songs App
      </a>
      : <span>APPBF23</span>
    </p>
  </>
);

const ProductBundles = ({ pageData, currentUser, token, width }) => {
  // console.log(pageData);
  // console.log(currentUser);

  const hasPurchased = currentUser?.advanced_settings.jg_ultimate_bundle_2023 == true;

  const [showPaymentForm, setShowPaymentForm] = useState(
    location.hash === "#payment" && !hasPurchased
  );
  const [showPurchaseSuccess, setShowPurchaseSuccess] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [openQuestion, setOpenQuestion] = useState(null);

  const productToBuy = {
    title: "JustinGuitar The Ultimate Bundle",
    description: "4 Premium Courses · 2 e-Books · Bonus Discount Codes",
    price: "USD 325",
    amount: 325,
    reference: "JGUltimateBundle2023",
  };

  const justin = () => <div className="justin-container"></div>;

  const purchase = () => {
    setShowPaymentForm(true);
    location.hash = "#payment";
  };

  const cancelPurchase = () => {
    setShowPaymentForm(false);
    setShowPurchaseSuccess(false);
    location.hash = "";
  };

  const onPurchaseSuccess = () => {
    setShowPaymentForm(false);
    setShowPurchaseSuccess(true);
    location.hash = "";
  };

  const headerHeight = () => {
    const header = document.querySelector(".header");
    const notification = document.querySelector(".notification");
    let height = 0;

    if (header) {
      height += header.offsetHeight;
    }

    if (notification) {
      height += notification.offsetHeight;
    }

    return height;
  };

  return (
    <div
      className="product-bundles"
      style={{ marginTop: `-${headerHeight()}px`, paddingTop: `${headerHeight()}px` }}
    >
      <div className="container">
        <div className="section intro">
          <h1 className="title">
            JustinGuitar
            <br />
            <span>The Ultimate Bundle</span>
          </h1>
          <p>
            We have never done this before! This season, treat yourself to the most insane
            JustinGuitar Ultimate Bundle including 4 premium courses (Practical Music Theory,
            Strumming SOS, Transcribing Blues Solos and Solo Blues Guitar), hours of professional
            backing tracks with JustinGuitarJams, and lots more!
          </p>
        </div>
        {!showPaymentForm && !showPurchaseSuccess && !hasPurchased && (
          <div className="section content bundle-price">
            <h2 className="title">Black Friday 2023</h2>
            <p className="description">
              Offer valid until November 30, 2023, midnight (UTC), or while capacity lasts. Read our
              FAQ for details.
            </p>
            <div className="price">
              <div className="previous">
                <p>Worth</p>
                <p>USD 749.96</p>
              </div>
              <div className="current">
                <p>Pay Only</p>
                <p>USD 325</p>
              </div>
            </div>
            <p className="saving">You're saving at least USD 424.96!</p>
          </div>
        )}
        {showPaymentForm && (
          <div className="section payment">
            <div className={`product-purchase${currentUser ? " payment" : " login"}`}>
              <button className="back-btn" onClick={() => cancelPurchase()}>
                {"<"} Back to Inside the Bundle
              </button>
              <h3>{productToBuy.title}</h3>
              {/*<p className="description">{productToBuy.description}</p>*/}
              <p className="price-tag">
                {productToBuy.price} <span>Value USD 749.96</span>
              </p>
              {currentUser ? (
                <>
                  <p className="info">Pay securely</p>
                  <StripeProvider apiKey={STRIPE_SALES_PUBLISHABLE_KEY}>
                    <div className="payments__stripe">
                      <Elements
                        fonts={[
                          {
                            cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:400",
                          },
                        ]}
                      >
                        <CheckoutForm
                          price={productToBuy.amount}
                          currency="USD"
                          reference={productToBuy.reference}
                          recaptchaKey={RECAPTCHA_KEY}
                          purchaseType="sales"
                          subscription={false}
                          userEmail={currentUser ? currentUser.email : null}
                          successCallback={onPurchaseSuccess}
                          info={`By clicking “Agree & Pay,” you agree to not sell, rent, lease, distribute, broadcast, or sublicense any portion of the content inside the JustinGuitar Ultimate Bundle. You also agree to our Terms & Conditions and to join our mailing list, so we can email you the promo codes and extra downloads. You can unsubscribe at anytime.`}
                          splitInputs={true}
                          buttonText="Agree & Pay"
                          acceptTerms={acceptTerms}
                        />
                      </Elements>
                    </div>
                  </StripeProvider>
                  <div className="payments__logo">{stripeLogo()}</div>
                  {/*<div className="checkbox-group">*/}
                  {/*  <input*/}
                  {/*    type="checkbox"*/}
                  {/*    name="consent"*/}
                  {/*    id="consent"*/}
                  {/*    checked={acceptTerms}*/}
                  {/*    onChange={(e) => setAcceptTerms(e.target.checked)}*/}
                  {/*  />*/}
                  {/*  <label htmlFor="consent">Accept T&C and receive emails from Justin.</label>*/}
                  {/*</div>*/}
                </>
              ) : (
                <>
                  <p className="info">First log in to your JustinGuitar account.</p>
                  <LoginPrompt token={token} />
                </>
              )}
            </div>
          </div>
        )}
        {showPurchaseSuccess && (
          <div className="section payment">
            <div className="product-purchase payment-success">
              <button className="back-btn" onClick={() => cancelPurchase()}>
                {"<"} Back to Inside the Bundle
              </button>
              <h3>Start Learning Now!</h3>
              <p>Your payment was successful! You have now access to these courses:</p>
              <BundledProducts />
              <p>These codes will be sent to you by email as well :)</p>
              <p>Happy learning!!!!</p>
            </div>
          </div>
        )}
        {hasPurchased && (
          <div className="section content has-purchased">
            <h2 className="title">Thank you for your purchase!</h2>
            <p>Start learning now! You have Lifetime Access to these courses:</p>
            <div>
              <p>
                <a href="/store/practical-music-theory" target="_blank">
                  ✅ Practical Music Theory
                </a>
              </p>
              <p>
                <a href="/store/strumming-sos" target="_blank">
                  ✅ Strumming SOS
                </a>
              </p>
              <p>
                <a href="/modules/transcribing-blues-solos" target="_blank">
                  ✅ Transcribing Blues Guitar
                </a>
              </p>
              <p>
                <a href="/modules/solo-blues-guitar" target="_blank">
                  ✅ Solo Blues Guitar
                </a>
              </p>
              <p>
                ➕ Download your{" "}
                <a href="/store/101-childrens-songs" target="_blank">
                  101 Children's Song eBooks
                </a>
              </p>
            </div>
            <div>
              <p>
                💡 Click{" "}
                <a href="#" target="_blank">
                  HERE
                </a>{" "}
                to access the instructions to access the JustinGuitarJams backing tracks, and the
                promo codes to the Merch Store and the Lessons & Songs App. If you have questions or
                need assistance, <a href="mailto:hello@justinguitar.com">contact us</a>.
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="ctas">
        {!showPaymentForm && !showPurchaseSuccess && !hasPurchased && (
          <button className="button button--primary" onClick={() => purchase()}>
            Buy Bundle
          </button>
        )}
        <a className="button button--teal" href="/bundle#faq">
          Read Faq
        </a>
      </div>
      <div className="section content whats-inside">
        <h2 className="title">🎊 What’s Inside The Bundle 🎊</h2>
        <p className="description">Lifetime Access to All Courses available at JustinGuitar.com!</p>
        <div className="products">
          <div className="product">
            <div className="name">
              <a href="/store/practical-music-theory" target="_blank">
                ☑️ Practical Music Theory Course
              </a>
            </div>
            <div className="price">USD 299.99 value</div>
          </div>
          <div className="product">
            <div className="name">
              <a href="/store/strumming-sos" target="_blank">
                ☑️ Strumming SOS
              </a>
              , <em>including the brand new Finger Strumming Course!</em>
            </div>
            <div className="price">USD 99.99 value</div>
          </div>
          <div className="product">
            <div className="name">
              <a href="/modules/transcribing-blues-solos" target="_blank">
                ☑️ Transcribing Blues Solos
              </a>
            </div>
            <div className="price">USD 15 value</div>
          </div>
          <div className="product">
            <div className="name">
              <a href="/modules/solo-blues-guitar" target="_blank">
                ☑️ Solo Blues Guitar
              </a>
            </div>
            <div className="price">USD 9.98 value</div>
          </div>
        </div>
        <PlusIcon />
      </div>
      <div className="section content extra-download">
        <h2 className="title">🔥 Downloads! 🔥</h2>
        <p className="description">Yep - there is more! Download:</p>
        <div className="products">
          <div className="product">
            <div className="name">
              ☑️{" "}
              <a href="https://justinguitarjams.com" target="_blank">
                JustinGuitarJams
              </a>{" "}
              - Our entire backing tracks collection!
            </div>
            <div className="price">USD 208 value</div>
          </div>
          <div className="product">
            <div className="name">
              <a href="/store/101-childrens-songs" target="_blank">
                ☑️ 101 Children's Songs on Guitar & Ukulele
              </a>{" "}
              <em>(e-Books Bundle)</em>
            </div>
            <div className="price">USD 17.99 value</div>
          </div>
        </div>
        <PlusIcon />
      </div>
      <div className="section content special-promo-codes">
        <h2 className="title">🎁 More Discounts! 🎁</h2>
        <p className="description">Unlock exclusive promo codes and save:</p>
        <div className="products">
          <div className="product">
            <div className="name">
              ☑️ <strong>40% off</strong> to buy a Lifetime Access Gift Card to the{" "}
              <a href="https://justinguitarapp.com" target="_blank">
                Lessons & Songs App
              </a>
              ! <span className="note">*</span>
            </div>
            <div className="price">Save USD 100</div>
          </div>
          <div className="product">
            <div className="name">
              ☑️ <strong>75% off</strong> one order in our{" "}
              <a href="https://merch.justinguitar.com" target="_blank">
                Merch Store
              </a>
              !! <span className="note">*</span>
            </div>
            <div className="price">Save up to USD 100</div>
          </div>
        </div>
        <p className="note">
          * Please note that redeeming these offers is optional. Using the promo codes requires the
          payment of additional fees. Conditions may apply.{" "}
          <button
            onClick={() => {
              setOpenQuestion({ activeTopicId: 393, questionId: 573 });
              window.location.href = "/bundle#faq";
            }}
          >
            Read more.
          </button>
        </p>
      </div>
      <div className="ctas">
        {!showPaymentForm && !showPurchaseSuccess && !hasPurchased && (
          <button className="button button--primary" onClick={() => purchase()}>
            Buy Bundle
          </button>
        )}
        <a className="button button--teal" href="/store" target="_blank">
          Shop Individual Products
        </a>
      </div>
      <div className="section">
        <div className="jg-banner">
          {width > 992 && justin()}
          <h3>30-Day Money Back Guarantee!</h3>
          <p>
            If this <strong>JustinGuitar Ultimate Bundle</strong> doesn't bring you complete joy,
            reach out within 30 days for a full refund - no questions asked!
          </p>
          <a href="mailto:hello@justinguitar.com" className="button button--teal">
            Read More
          </a>
          {width <= 992 && justin()}
        </div>
      </div>
      <div className="section">
        <div className="faq" id="faq">
          <Category faqs={pageData.faqs} openQuestion={openQuestion} />
        </div>
      </div>
    </div>
  );
};

export default withInnerWidth(ProductBundles);
