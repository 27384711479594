import React from "react";
import { connect } from "react-redux";

import {
  clearFilters,
  closeFilters,
  fetchSearchResults,
  openFilters,
  resultMergeStrategies,
  selectHasActiveFilters,
  setResults,
  toggleFilter,
} from "./store";
import { FiltersOpenCloseButton } from "../shared/FiltersOpenCloseButton";
import { PreventScroll } from "../shared";
import { filters } from "../shared/search";

const AdvancedSearchFilters = (props) => (
  <PreventScroll shouldPreventScroll={props.filtersOpen}>
    {props.filtersOpen && <div className="overlay" onClick={props.closeFilters} />}
    <FiltersOpenCloseButton {...props} availableFilters={filters} />
  </PreventScroll>
);

export default connect(
  (state) => ({
    activeFilters: state.filters,
    filtersOpen: state.filtersOpen,
    hasActiveFilters: selectHasActiveFilters(state),
  }),
  (dispatch) => ({
    openFilters: () => dispatch(openFilters()),
    closeFilters: () => dispatch(closeFilters()),
    clearFilters: () => {
      dispatch(clearFilters());
      dispatch(fetchSearchResults({ mergeStrategy: resultMergeStrategies.replace }));
    },
    toggleFilter: (option) => {
      dispatch(toggleFilter(option));
      dispatch(fetchSearchResults({ mergeStrategy: resultMergeStrategies.replace }));
    },
  })
)(AdvancedSearchFilters);
