import { useFormik } from "formik";
import {
  RegistrationDetails,
  registrationDetailsInitialState,
  registrationDetailsSchema,
  Workshop,
  YourStayDetails,
  yourStayDetailsInitialState,
  yourStayDetailsSchema,
} from "../interfaces";

type RegistrationFormState = Partial<
  RegistrationDetails &
    YourStayDetails & {
      workshopId: Workshop["id"];
    }
>;

const initialRegistrationFormState: Partial<RegistrationFormState> = {
  ...registrationDetailsInitialState,
  ...yourStayDetailsInitialState,
};

export const useWorkshopRegistrationFormik = (initialValues: RegistrationFormState) => {
  return useFormik<RegistrationFormState>({
    initialValues: {
      ...initialRegistrationFormState,
      ...initialValues,
    },
    validationSchema: registrationDetailsSchema.concat(yourStayDetailsSchema),
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (f) => {
      // not used
      console.log(f);
    },
  });
};
