import React from "react";
import Swoosh from "../components/shared/Swoosh";
import BooksListing from "./BooksListing";
import ProductList from "./ProductList";
import { productTypes } from "./productTypes";
import AppsListing from "./AppsListing";
import JamTracksListing from "./JamTracksListing";
import MerchListing from "./MerchListing";
import PremiumCoursesListing from "./PremiumCoursesListing";
import TabsListing from "./TabsListing";

export default function ({
  user,
  products,
  songs,
  subscriptionReferences,
  trialAvailable,
  productType,
  songsSubscriber,
  tabsFaqs,
  sheetCount,
  tabsCount,
  booksFaqs,
}) {
  const [showAll, setShowAll] = React.useState(false);

  switch (productType) {
    case productTypes.app:
      return <AppsListing products={products} />;
    case productTypes.book:
      return <BooksListing products={products} booksFaqs={booksFaqs} />;
    case productTypes.jamTracks:
      return <JamTracksListing products={products} />;
    case productTypes.merch:
      return <MerchListing products={products} />;
    case productTypes.digital:
      return <PremiumCoursesListing products={products} />;
    case productTypes.tabs:
      return (
        <TabsListing
          tabsFaqs={tabsFaqs}
          user={user}
          songs={songs}
          subscriptionReferences={subscriptionReferences}
          trialAvailable={trialAvailable}
          songsSubscriber={songsSubscriber}
          sheetCount={sheetCount}
          tabsCount={tabsCount}
        />
      );
    default:
      const productsToShow = showAll ? products : products.slice(0, 15);
      return (
        <Swoosh color="#F5F7FA">
          <div className="products-listing container">
            <div className="products-listing__header">
              <h2>All Justin Guitar Products</h2>
              <p>
                Thanks for shopping at the JustinGuitar Store! :) When you buy a product here, you
                help me keep most of my guitar lessons free. Together, we're teaching the world to
                play.
              </p>
            </div>
            <div className="products-listing__content">
              <ProductList products={productsToShow} />
              {showAll || (
                <button
                  className="button button--blue"
                  style={{ width: "fit-content" }}
                  onClick={() => setShowAll(true)}
                >
                  View All Products
                </button>
              )}
            </div>
          </div>
        </Swoosh>
      );
  }
}
