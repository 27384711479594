import React from "react";
import { Provider } from "react-redux";
import "./store/parentGroupIndex";
import ParentGroupContainer from "./ParentGroupContainer";

export default class ParentGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const parentGroupStore = ReactOnRails.getStore("parentGroupStore");

    return (
      <Provider store={parentGroupStore}>
        <ParentGroupContainer />
      </Provider>
    );
  }
}
