import React, { useState } from "react";
import DynamicIframe from "../../DynamicIframe";
import { withInnerWidth } from "../../shared";

const AboutPracticeAssistant = ({ width }) => {
  const [videoOpen, setVideoOpen] = React.useState();
  return (
    <div className="about-practice-routine__container styleized admin__introduction">
      <div>
        <button
          className="button button--inline button--blue intro-btn"
          onClick={() => setVideoOpen(!videoOpen)}
        >
          {width > 500 ? (
            (videoOpen ? "Close " : "Open ") + "Introduction Video"
          ) : videoOpen ? (
            <>
              <i className="icon icon-view-hide"></i> Introduction
            </>
          ) : (
            <>
              <i className="icon icon-view-show"></i> Introduction
            </>
          )}
        </button>
        {videoOpen && (
          <DynamicIframe
            showDonateAfterVideo={false}
            link="https://www.youtube.com/embed/ljgHimyKpDk"
            containerHeight="350"
            aspectRatio="1.777"
          />
        )}
      </div>

      <p style={{ fontSize: "18px" }}>
        <strong>Welcome to the Justin Guitar Practice Assistant! :)</strong>
      </p>

      <p>
        Here you'll be able to construct an amazing practice routine, edit a template routine to
        suit your own needs or get suggested routines from various courses (like each lesson of the
        2020 Beginner Course!). It's going to take me a while to get practice items for all lessons,
        but you should be able to make your own pretty easily.
        <a href="https://justinguitarcommunity.com/index.php?topic=45977.0" target="_blank">
          {" "}
          If you got some feedback or suggestions, please leave it here!
        </a>
      </p>

      <p className="teal">
        <strong>Practice Items</strong>
      </p>

      <p>
        Before you make a routine, you'll need to make a practice item to put in it. You can specify
        the item name, the duration for practice and also a statistic to chart (like metronome speed
        or number of chord changes).
      </p>

      <p className="teal">
        <strong>Practice Routines</strong>
      </p>

      <p>
        Once you have some items you can structure them into a Routine, change the order of them -
        and make a number of different routines if you want to. Note that 'archiving items' removes
        them from the routines - so don't archive items unless you're done practicing them!
      </p>

      <p>Just make a new routine and then choose the items you want in it!</p>

      <p className="teal">
        <strong>Practice</strong>
      </p>

      <p>
        Once you got a routine you can start practice! Just click the start arrow and off you go.
        Some explainers on the buttons and things on this page:
      </p>

      <ul>
        <li>Reset Routine: as it says, resets everything so you can start over.</li>
        <li>
          Always Play With Video Assistant: This only works with Justin's&nbsp;built-in Practice
          Items, and will give you a full screen video to practice with!
        </li>
        <li>
          Notes: Use the pencil button to enter some notes and things you want to remember - these
          are saved with the practice item for future practice sessions.
        </li>
        <li>
          Start Timer Without Video: If you selected 'Always Play With Video Assistant' at the top,
          this allows you to use the timer without it, if you get sick of looking at my ugly mug!
        </li>
        <li>
          Mark As Done - Log Full Scheduled Time - if you wanted to use a different timer or did
          you&nbsp; practice at work - this allows you to still log your practice time. But no
          cheating ok!?!
        </li>
      </ul>

      <p className="teal">
        <strong>Stats</strong>
      </p>

      <p>
        Our stats are basic for now but we're working on more fun things for this area - right now
        it'll rack time spent practising and also the stats for items (chord changes or metronome
        speeds, that kind of thing).
      </p>

      <p className="teal">
        <strong>Metronome</strong>
      </p>

      <p>
        You'll also find a metronome you can use when wanted, easy to use basic metronome with
        subdivisions!
      </p>

      <p className="teal">
        <strong>Pricing</strong>
      </p>

      <p>
        The Practice Assistant has taken me many hours and many thousands of pounds in development
        over the last year. If you would like me to continue to develop more amazing tools for
        you&nbsp;(and all guitarists around the world)&nbsp;to enjoy, then{" "}
        <a href="https://www.justinguitar.com/donate">please consider making a donation!</a>
        &nbsp;The site could not continue to keep almost everything free without the support of
        awesome people like yourself supporting things they enjoy. Thank you :) J.
      </p>

      <p>&nbsp;</p>
    </div>
  );
};

export default withInnerWidth(AboutPracticeAssistant);
