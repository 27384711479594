import React from "react";
import PropTypes from "prop-types";
import LessonTile from "../shared/LessonTile";
import SongTile from "../shared/SongTile";
import { goToLesson } from "../shared/utils";

function formattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}

function findRelationshipByType(item, itemType) {
  return item.included.find((i) => i.type === itemType);
}

const LessonsList = ({ items }) => {
  return items.map((item) => {
    const { title, pageReleaseDate, slug } = item.data.attributes;
    const grade = findRelationshipByType(item, "grade");
    const group = findRelationshipByType(item, "group");
    return (
      <div key={item.data.id}>
        <span className="release-date">{pageReleaseDate && formattedDate(pageReleaseDate)}</span>

        <div className="clickable" onClick={() => goToLesson("Lesson", slug)}>
          <LessonTile
            title={title}
            lesson={item}
            gradeId={grade && grade.id}
            groupTitle={group ? group.attributes.title : null}
          />
        </div>
      </div>
    );
  });
};

const SongsList = ({ items }) => {
  return items.map((item) => {
    const { title, pageReleaseDate, slug, artistImageUid } = item.data.attributes;
    const grade = findRelationshipByType(item, "grade");
    const artist = findRelationshipByType(item, "artist");
    return (
      <div key={item.data.id}>
        <span className="release-date">{pageReleaseDate && formattedDate(pageReleaseDate)}</span>

        <div key={item.data.id} className="clickable" onClick={() => goToLesson("Song", slug)}>
          <SongTile
            title={title}
            albumThumb={artistImageUid}
            gradeId={grade && grade.attributes && grade.attributes.id}
            artistName={artist ? artist.attributes.name : null}
          />
        </div>
      </div>
    );
  });
};

const WhatsNew = ({ latestLessons, setViewAllLessons }) => {
  const [activeLessonType, setActiveLessonType] = React.useState("Lessons");

  return (
    <div className="latest-lessons">
      <p className="welcome__heading">What's New on JustinGuitar?</p>
      <div className="welcome__options">
        <span
          onClick={() => setActiveLessonType("Lessons")}
          className={activeLessonType === "Lessons" ? "active" : "clickable"}
        >
          Lessons
        </span>
        <span
          onClick={() => setActiveLessonType("Songs")}
          className={activeLessonType === "Songs" ? "active" : "clickable"}
        >
          Songs
        </span>
        <span
          onClick={() => setActiveLessonType("Playground")}
          className={activeLessonType === "Playground" ? "active" : "clickable"}
        >
          Playground
        </span>
        <span
          className="uppercase view-all righthand-opt clickable"
          onClick={() => {
            setViewAllLessons(true);
          }}
        >
          View All
        </span>
      </div>

      {activeLessonType === "Lessons" ? (
        <LessonsList items={latestLessons["lessons"] || []} />
      ) : activeLessonType === "Playground" ? (
        <LessonsList items={latestLessons["playground"] || []} />
      ) : (
        <SongsList items={latestLessons["songs"] || []} />
      )}
    </div>
  );
};

WhatsNew.propTypes = {
  latestLessons: PropTypes.object.isRequired,
};

export default WhatsNew;
