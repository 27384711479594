import React from "react";
import { Provider, useSelector } from "react-redux";
import { Box, Container, Grid, ThemeProvider, useMediaQuery } from "@mui/material";

import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";

import { getJgStore } from "../../../redux/jg-store";
import { WorkshopRegistrationStepper } from "./WorkshopRegistrationStepper";
import { workshopSelectors } from "../store/workshops.slice";
import { DetailsForm } from "./DetailsForm";
import { RegisterSidebar } from "../shared/RegisterSidebar";
import { YourStayForm } from "./YourStayForm";
import { registrationDetailsFormFieldNames, yourStayDetailsFormFieldNames } from "../interfaces";
import { RegisterFooter } from "./RegisterFooter";
import { useWorkshopRegistrationFormik } from "./use-workshop-registration-formik";
import { RegistrationComplete } from "./RegistrationComplete";
import { createWorkshopRegistration } from "../store/workshops.api";
import { WorkshopRegistration } from "../interfaces/workshop-registration.interface";

export enum RegistrationStep {
  details = 0,
  yourStay = 1,
  complete = 2,
}

const orderedSteps = [
  RegistrationStep.details,
  RegistrationStep.yourStay,
  RegistrationStep.complete,
];

const nextStep = (step: RegistrationStep): RegistrationStep =>
  orderedSteps[orderedSteps.indexOf(step) + 1] || RegistrationStep.complete;

const prevStep = (step: RegistrationStep): RegistrationStep =>
  orderedSteps[orderedSteps.indexOf(step) - 1] || RegistrationStep.details;

const _WorkshopRegisterPage = () => {
  const isSmall = useMediaQuery(jgDenimTheme.breakpoints.down("sm"));
  const userIsRegistered = useSelector(workshopSelectors.selectCurrentUserIsRegistered);
  const [activeStep, setActiveStep] = React.useState<number>(
    userIsRegistered ? RegistrationStep.complete : RegistrationStep.details
  );
  const workshop = useSelector(workshopSelectors.selectCurrentWorkshop);
  const formik = useWorkshopRegistrationFormik({});

  if (workshop == null) return null; // todo: 404

  const activeStepFormFields =
      activeStep === 0 ? registrationDetailsFormFieldNames : yourStayDetailsFormFieldNames,
    activeStepIsValid = activeStepFormFields.every((key) => !Boolean(formik.errors[key]));

  const advance = () => {
    if (activeStep === RegistrationStep.yourStay) {
      createWorkshopRegistration(formik.values as WorkshopRegistration, workshop).then(() => {
        setActiveStep(RegistrationStep.complete);
      });
    } else {
      setActiveStep(nextStep(activeStep));
    }
  };

  return (
    <Container>
      <WorkshopRegistrationStepper activeStep={activeStep} />

      <Grid
        container
        marginTop={jgDenimTheme.spacing(2)}
        marginBottom={8}
        flexDirection={isSmall ? "column" : "row"}
      >
        {activeStep === RegistrationStep.complete ? (
          <RegistrationComplete />
        ) : (
          <>
            <Grid
              item
              xs={12}
              sm={4}
              display={activeStep === RegistrationStep.complete ? "none" : "unset"}
            >
              <RegisterSidebar workshop={workshop} />
            </Grid>
            <Grid item xs={12} sm={activeStep === RegistrationStep.complete ? 12 : 8}>
              <Box>
                {activeStep === RegistrationStep.details ? (
                  <DetailsForm formik={formik} />
                ) : activeStep === RegistrationStep.yourStay ? (
                  <YourStayForm formik={formik} />
                ) : (
                  <div />
                )}
              </Box>

              <RegisterFooter
                canGoPrev={activeStep > RegistrationStep.details}
                canGoNext={activeStep < RegistrationStep.complete && activeStepIsValid}
                onPrev={() => setActiveStep(prevStep(activeStep))}
                onNext={advance}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};
// hooks don't work directly inside providers so we have to do it this way
export const WorkshopRegisterPage = () => (
  <Provider store={getJgStore()}>
    <ThemeProvider theme={jgDenimTheme}>
      <_WorkshopRegisterPage />
    </ThemeProvider>
  </Provider>
);
