import React from "react";
import { Box, Typography, Link } from "@mui/material";
import SectionHeader from "./SectionHeader";

const CheckoutSuccessMessage = ({ currentUser }) => {
  const { firstName } = currentUser.data.attributes;
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        mb: 5,
      }}
    >
      <SectionHeader>Hey, how you doing, {firstName} :)</SectionHeader>
      <Typography variant="body1">
        Thank you for your purchase. We hope it enhances your guitar journey! Please don't hesitate
        to <Link href="mailto:hello@justinguitar.com">contact us</Link> if you have any questions.
      </Typography>
    </Box>
  );
};

export default CheckoutSuccessMessage;
