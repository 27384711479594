import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import UpdateCardDetails from "../../../payments/UpdateCardDetails";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100%",
    width: "90%",
    height: "50%",
    backgroundColor: "white",
    border: "none",
    maxWidth: "600px",
    maxHeight: "600px",
    borderRadius: "20px",
  },
};

const SubscriptionOptions = ({
  isActive,
  isCancelled,
  openDialog,
  typeOf,
  purchaseId,
  endDate,
  purchaseReference,
  actualPurchaseReference = null,
}) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      Modal.setAppElement("body");
    }
  }, []);

  const [modalOpen, setModalOpen] = useState(false);

  const isOldPMTSubscription = actualPurchaseReference == "PMT";
  const oldPMTPlanMessage = isOldPMTSubscription
    ? `<br><br><span style="color: #fd6a02;">You're subscribed to a payment plan that no longer exists! If you cancel your subscription now, you cannot subscribe again to this same price. Are you sure you want to cancel your subscription?</span>`
    : "";

  return (
    <div>
      {isActive && !isCancelled && (
        <div className="purchase__actions">
          <p onClick={() => setModalOpen(true)} className="teal clickable">
            Update payment method
          </p>
          <p
            className="orange clickable"
            onClick={() => {
              openDialog({
                message: "Are you sure you want to cancel this subscription?",
                secondaryMessage: endDate
                  ? `You will still have access until ${endDate}.${oldPMTPlanMessage}`
                  : null,
                cancelButtonText: "No, keep my subscription",
                confirmButtonText: "Yes, cancel my subscription",
                swapActions: isOldPMTSubscription,
                data: { itemId: purchaseId, typeOf: typeOf },
              });
            }}
          >
            Cancel subscription
          </p>
          <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} style={customStyles}>
            <UpdateCardDetails purchaseReference={purchaseReference} toggleModal={setModalOpen} />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default SubscriptionOptions;
