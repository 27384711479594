import React from "react";
import Metronome from "./Metronome";
import PracticeIcons from "../../PracticeIcons";

export default class MetronomeToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.metronomeOpen) {
      return (
        <div className="routine__metronome">
          <span
            className="metronome-icon"
            dangerouslySetInnerHTML={{ __html: PracticeIcons.metronomeIcon() }}
          />
          <span className="toggle-metronome__click" onClick={this.props.toggleMetronome}>
            <i className="icon icon-view-hide"></i> hide metronome
          </span>
          <Metronome
            saveMetronomeSpeed={this.props.saveMetronomeSpeed}
            unsavedMetronomeSpeedChange={this.props.unsavedMetronomeSpeedChange}
            user={this.props.user}
            item={this.props.item}
            metronomeDisabled={this.props.metronomeDisabled}
          />
        </div>
      );
    } else {
      return (
        <div className="routine__metronome">
          <span
            className="metronome-icon"
            dangerouslySetInnerHTML={{ __html: PracticeIcons.metronomeIcon() }}
          />
          <span className="toggle-metronome__click" onClick={this.props.toggleMetronome}>
            <i className="icon icon-view-show"></i> show metronome
          </span>
        </div>
      );
    }
  }
}
