import React, { useEffect, useState, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";

const NOTIFICATION_EVENTS = {
  impression: "notification_impression",
  click: "notification_click",
};

const Close = ({ color }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
        fill={color}
      />
    </svg>
  );
};

const Notification = ({ notifications }) => {
  const [notification, setNotification] = useState(null);
  const notificationCloseRef = useRef(null);

  useEffect(() => {
    // Why do we allow there to be more than one active notification?
    if (!notifications?.length) return;

    const lastNotification = notifications.slice(-1).pop();

    let hideNotifications = null;

    try {
      hideNotifications = JSON.parse(localStorage.getItem("hide-notifications"));
    } catch (e) {
      localStorage.removeItem("hide-notifications");
    }

    if (hideNotifications && hideNotifications.notificationId === lastNotification.id) {
      setNotification(null);
    } else {
      localStorage.removeItem("hide-notifications");
      setNotification(lastNotification);
    }
  }, []);

  useEffect(() => {
    if (!notification) return;

    logEvent(NOTIFICATION_EVENTS.impression);
  }, [notification]);

  const closeNotification = () => {
    localStorage.setItem(
      "hide-notifications",
      JSON.stringify({
        notificationId: notification.id,
      })
    );

    setNotification(null);
  };

  const handleNotificationClick = (e) => {
    if (notificationCloseRef && notificationCloseRef.current.contains(e.target)) return;

    logEvent(NOTIFICATION_EVENTS.click);
    closeNotification();
    window.location.href = notification.url;
  };

  const logEvent = (notificationName) => {
    if (typeof gtag === "undefined") return;

    gtag("event", notificationName, {
      notification_id: notification.id,
      notification_content: notification.content.slice(0, 20),
    });
  };

  const needsDarkText = ["#fff"].includes(notification?.color);

  if (!notification) return null;

  return (
    <div
      className={`notification${needsDarkText ? " dark-text" : ""}`}
      style={{ backgroundColor: notification.color }}
      onClick={(e) => handleNotificationClick(e)}
    >
      <p>{notification.content}</p>

      <button onClick={closeNotification} type="button" ref={notificationCloseRef}>
        <Close color={needsDarkText ? "#44546c" : "white"} />
      </button>
    </div>
  );
};

export default (props) => (
  <ErrorBoundary fallback={<div></div>}>
    <Notification {...props} />
  </ErrorBoundary>
);
