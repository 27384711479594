import React, { useState, useEffect } from "react";
import { withBootstrapSize } from "../../components/shared/WithBootstrapSize";
import "../../components/shared/polyfills/replaceAll.js";
import { CDN_URL } from "../../components/shared/CdnUrl";

const TermsAndConditions = ({ terms, width }) => {
  return (
    <div className="terms-and-conditions container">
      <h1>Terms & Conditions</h1>
      {/*<div className="toc"></div>*/}
      <div className="text-content" dangerouslySetInnerHTML={{ __html: terms }}></div>
    </div>
  );
};

export default withBootstrapSize(TermsAndConditions);
