import React from "react";
import { connect } from "react-redux";
import { CSSTransition } from "react-transition-group";

import AuthButtons from "./AuthButtons";
import { sideMenuItems } from "./menuItems";
import { preventScroll } from "../shared";

import CurrentUserBadge from "./CurrentUserBadge";

const ANIMATION_DURATION_MS = 500;

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  componentDidMount() {
    this.setState({ open: true });
  }

  close = () => {
    this.setState({ open: false }, () => {
      setTimeout(() => {
        this.props.close();
      }, ANIMATION_DURATION_MS); // wait for animation to complete
    });
  };

  render() {
    const { open } = this.state;
    const { currentUser } = this.props;
    return (
      <>
        <CSSTransition in={open} timeout={ANIMATION_DURATION_MS} classNames="header__side-menu">
          <div className="header__side-menu">
            <div
              className={`menu__section ${
                currentUser == null
                  ? "menu__section--auth-logged-out"
                  : "menu__section--auth-logged-in"
              }`}
            >
              {currentUser == null ? (
                <AuthButtons orientation="vertical" primary={false} />
              ) : (
                <a href={`/users/${currentUser.data.attributes.slug}`}>
                  <div className="menu__section--greeting">
                    <CurrentUserBadge imgPath={currentUser.data.attributes.imageUid} />
                    <div className="user-info">
                      <div>Hi, {currentUser.data.attributes.username}</div>
                      <div className="uppercase spaced">Dashboard</div>
                    </div>
                    <div>
                      <i className="icon icon-arrow-right" />
                    </div>
                  </div>
                </a>
              )}
            </div>

            {sideMenuItems.menu.sections.map((section, idx) => (
              <div
                key={section.title || idx}
                className={`menu__section menu__section--${
                  section.title ? section.title.toLowerCase().replace(/ +/g, "-") : ""
                }`}
              >
                {section.title && <div className="menu__section-title">{section.title}</div>}
                {section.items.map(
                  (item) =>
                    item.link && (
                      <div className="menu-item__container" key={item.title}>
                        {item.borderColor && (
                          <div
                            className="menu-item__strip"
                            style={{ background: item.borderColor }}
                          />
                        )}

                        <a className="menu-item__link" href={item.link}>
                          {item.title}
                          {item.link.startsWith("http") ? (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19 19H5V5H12V3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V12H19V19ZM14 3V5H17.59L7.76 14.83L9.17 16.24L19 6.41V10H21V3H14Z"
                                fill="#fff"
                              />
                            </svg>
                          ) : null}
                        </a>
                      </div>
                    )
                )}
              </div>
            ))}
          </div>
        </CSSTransition>

        <CSSTransition in={open} timeout={ANIMATION_DURATION_MS} classNames="fadeInOut">
          <div className="menu-overlay" onClick={this.close} />
        </CSSTransition>

        <CSSTransition
          in={open}
          appear={true}
          timeout={ANIMATION_DURATION_MS}
          classNames="growInShrinkOut"
        >
          <div className="menu-overlay__close-button clickable" onClick={this.close}>
            <i className="icon icon-cross" />
          </div>
        </CSSTransition>
      </>
    );
  }
}

export default connect(({ currentUser }) => ({ currentUser }))(preventScroll(Menu));
