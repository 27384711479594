import axiosWithCSRF from "../shared/axiosWithCSRF";
import { currencyTypes } from "./consts";

export const currencyDisplay = (currency) => {
  switch (currency) {
    case currencyTypes.USD:
      return "USD";
    case currencyTypes.GBP:
      return "GBP";
    case currencyTypes.AUD:
      return "AUD";
    case currencyTypes.EUR:
      return "EUR";
    case currencyTypes.CAD:
      return "CAD";
  }
};

export const moneyFormat = (amount, currency = "USD") => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });
  return formatter.format(amount);
};

// Use this function, because of these multiple stores.
// When we have one single site-wide store, we can add these functions as selectors there.
export const hasPurchased = (product, purchases, currentUser) => {
  return (
    currentUser &&
    purchases.some(
      (purchase) =>
        purchase.user_id == currentUser.data.attributes.id &&
        purchase.purchase_reference == product.reference
    )
  );
};

export const getProductResources = (product) => {
  if (product.resources) {
    if (Array.isArray(product.resources)) {
      return product.resources[0];
    } else {
      return product.resources;
    }
  }

  return null;
};

export const getFirstProduct = (products) => {
  return products.length > 0 ? products[0] : null;
};

export const isBFPromoDate = (
  promoStart = new Date(2022, 10, 24),
  promoEnd = new Date(2022, 10, 28)
) => {
  let now = new Date();
  promoStart.setHours(0, 0, 0, 0);
  promoEnd.setHours(23, 59, 59, 999);

  return now >= promoStart && now <= promoEnd;
};

export const trialDays = () => {
  return isBFPromoDate() ? 7 : 7;
};

export const getProducts = () => axiosWithCSRF().get("/payments/products");
export const getPurchases = () => axiosWithCSRF().get("/payments/purchases");
export const getDonations = () => axiosWithCSRF().get("/payments/donations");
export const getStripeUserData = (userId) =>
  axiosWithCSRF().get(`/payments/stripe_user_data/${userId}`);

export const tabOptionsFiltered = (tabOptions, isMonthly) =>
  Object.keys(tabOptions)
    .filter((key) => (isMonthly && key !== "monthly") || !isMonthly)
    .reduce((obj, key) => {
      obj[key] = tabOptions[key];
      return obj;
    }, {});
