import React, { useState } from "react";
import PropTypes from "prop-types";
import SimpleDialog from "../../components/shared/ui/SimpleDialog";

const SelectedEventDialog = ({ selectedLiveEvent, setSelectedLiveEvent, attend, unattend }) => {
  if (!selectedLiveEvent) return null;

  const [termsConsent, setTermsConsent] = useState(false);
  const [recordingConsent, setRecordingConsent] = useState(false);

  return (
    <SimpleDialog
      title={
        selectedLiveEvent.attending
          ? "Are you sure you want to give up your spot on this Live Event?"
          : "By attending this Live Event..."
      }
      content={
        selectedLiveEvent.attending ? (
          <div style={{ textAlign: "center" }}>
            Please cancel your spot if you won't be able to attend the Live Event. Thank you!
          </div>
        ) : (
          <>
            <div style={{ textAlign: "left" }}>
              <input type="checkbox" onClick={(e) => setTermsConsent(e.target.checked)} />
              <span>
                I consent to Justin Guitar’s <a href="/terms">Terms & Conditions</a> and to receive
                Justin's newsletters and email updates.
              </span>
            </div>
            <div style={{ textAlign: "left" }}>
              <input type="checkbox" onClick={(e) => setRecordingConsent(e.target.checked)} />
              <span>
                I agree to have my image recorded during the Live Meetings and that Justin Guitar
                Ltd owns that material. I acknowledge that the recording may be used for promotional
                and commercial reasons in perpetuity. I am aware I can mute my audio and turn off my
                camera during the meeting - and if I do so, my image and audio will not be recorded.
              </span>
            </div>
          </>
        )
      }
      actionButtons={
        <>
          {selectedLiveEvent.attending ? (
            <button className="button button--primary" onClick={() => unattend(selectedLiveEvent)}>
              cancel my spot
            </button>
          ) : (
            <button
              className="button button--primary"
              onClick={() => attend(selectedLiveEvent)}
              disabled={!termsConsent || !recordingConsent}
            >
              attend
            </button>
          )}
        </>
      }
      closeAction={() => setSelectedLiveEvent(null)}
    />
  );
};

export default SelectedEventDialog;

SelectedEventDialog.propTypes = {
  selectedLiveEvent: PropTypes.object,
  setSelectedLiveEvent: PropTypes.func.isRequired,
  attend: PropTypes.func.isRequired,
  unattend: PropTypes.func.isRequired,
};
