import React from "react";
import NewRoutine from "./NewRoutine";
import PracticeOptions from "./PracticeOptions";

export default class Practice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      practiceRoutines: this.props.practiceRoutines,
    };
  }

  componentDidMount() {
    if (!this.props.practiceRoutines || this.props.practiceRoutines.length === 0) {
      this.setState({ needsNewRoutine: true });
    }
  }

  routineCreated = (routine) => {
    let routines = [...this.state.practiceRoutines];
    routine.items = [];
    routines.push(routine);
    this.setState({ needsNewRoutine: false, practiceRoutines: routines });
  };

  render() {
    if (this.state.needsNewRoutine) {
      return (
        <NewRoutine
          user={this.props.user}
          routineCreated={this.routineCreated}
          practiceIntro={this.props.practiceIntro}
        />
      );
    } else {
      return (
        <PracticeOptions
          user={this.props.user}
          practiceRoutines={this.state.practiceRoutines}
          practiceItems={this.props.practiceItems}
          practiceSessions={this.props.practiceSessions}
          practiceSessionItems={this.props.practiceSessionItems}
          adminIntroductions={this.props.routineIntros}
        />
      );
    }
  }
}
