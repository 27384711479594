import React from "react";
import axiosWithCSRF from "./shared/axiosWithCSRF";

export default class AddPracticeItemToDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      songItemDuration: 5,
    };
  }

  componentDidMount() {
    let { userNotes, item } = this.props;
    let userNoteContent;
    if (userNotes) {
      let songNote = userNotes.filter(
        (note) => note.noteable_id === item.itemable_id && note.noteable_type === item.itemable_type
      );
      if (songNote.length > 0) {
        userNoteContent = songNote[0].content;
      }
    }
    this.setState({ showSave: this.props.showSave, userNoteContent });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ showSave: nextProps.showSave });
  }

  addToRoutine = (item, addToRoutine) => {
    let routineId = this.props.practiceRoutineId;
    let itemDuration =
      item.item_type.title === "Song" ? this.state.songItemDuration : item.duration;
    axiosWithCSRF()
      .post("/user_practice_items", {
        user_practice_item: {
          user_practice_routine_id: routineId,
          title: item.title,
          duration: itemDuration,
          description: item.description,
          has_video_play_along: item.has_video_play_along,
          metronome: item.metronome,
          statistic: item.statistic,
          statistic_name: item.statistic_name,
          related_link: item.related_link,
          practice_item_id: item.id,
          related_video: item.related_video,
          image: item.images,
          practice_note_content: this.state.currentUserNoteContent,
        },
        add_to_active_routine: addToRoutine,
      })
      .then((response) => {
        this.setState({ showSave: false });
      })
      .catch((error) => {
        this.setState({ hasErrors: error.response.data.message });
        setTimeout(() => this.setState({ hasErrors: null }), 3000);
      });
  };

  saveFirstPracticeItem = (item) => {
    axiosWithCSRF()
      .post("/user_practice_items/first_item", {
        item: item,
      })
      .then((res) => {
        this.setState({ showSave: false });
      })
      .catch((error) => {
        this.setState({ hasErrors: error.response.data.error });
        setTimeout(() => this.setState({ hasErrors: null }), 3000);
      });
  };

  togglePrompt = () => {
    this.setState({ optionsOpen: !this.state.optionsOpen });
  };

  handleDurationChange = (e) => {
    this.setState({ songItemDuration: e.target.value });
  };

  songDurationInput = () => {
    const { item } = this.props;
    if (item.item_type.title === "Song") {
      return (
        <div className="song-item-duration">
          <label>Minutes</label>
          <input
            className="justin-blue-input"
            type="number"
            onChange={this.handleDurationChange}
            value={this.state.songItemDuration}
          ></input>
        </div>
      );
    }
  };

  buttonToShow = (item) => {
    if (this.state.showSave === false) {
      return (
        <button disabled className="standard-blue-button standard-blue-button--disabled uppercase">
          saved
        </button>
      );
    } else if (!this.state.optionsOpen) {
      if (this.props.userPracticeRoutines.length > 0) {
        return (
          <button onClick={this.togglePrompt} className="add-to-routine__button">
            Add to your routine
          </button>
        );
      } else {
        return (
          <button
            className="add-to-routine__button"
            onClick={() => this.saveFirstPracticeItem(item)}
          >
            Add To Your First Routine
          </button>
        );
      }
    } else {
      return (
        <div>
          {this.songDurationInput()}
          <button onClick={() => this.addToRoutine(item, true)} className="add-to-routine__button">
            add to your active routine
          </button>
          <button onClick={() => this.addToRoutine(item, false)} className="add-to-routine__button">
            save to your practice items
          </button>
          <button onClick={this.togglePrompt} className="cancel-routine__button">
            Cancel
          </button>
        </div>
      );
    }
  };

  render() {
    const item = this.props.item;

    return (
      <div
        className={
          "saveable-practice-item " +
          (this.state.showSave === false ? "saveable-practice-item--disabled" : "")
        }
      >
        {this.state.hasErrors && <p className="has-errors">{this.state.hasErrors}</p>}
        <h2>{item.title}</h2>
        <p>{item.description}</p>
        <div className="routine-button-selection">{this.buttonToShow(item)}</div>
      </div>
    );
  }
}
