import React from "react";
import { DarkTooltip } from "../../components/shared/ui/Tooltips";
import PlayIcon from "../../group/PlayIcon";
import { ArrowIcon } from "./PlaygroundRoot";
import { getModuleThumb } from "../../utilities/thumb";
import { dateAsShortMonthNumericYear } from "../Workshops/shared/helpers";

const SimpleCard = ({
  data,
  currentUser,
  onNavigate,
  bootstrapSize,
  onToggleSave = null,
  hoverPlayOverlay = false,
  isSaved,
}) => {
  function description() {
    const releaseDate =
      data?.pageReleaseDate?.length > 0 ? dateAsShortMonthNumericYear(data.pageReleaseDate) : "";
    const lessonLength = data?.youtubeDuration?.length > 0 ? data.youtubeDuration : "";
    const separator = releaseDate.length && lessonLength.length ? " • " : "";
    if (releaseDate.length > 0 || lessonLength.length > 0) {
      return `${releaseDate}${separator}${lessonLength}`;
    } else if (data?.lessonOrder) {
      return `${data.lessonOrder.length} ${data.label ? data.label : "Lessons"}`;
    } else {
      return "";
    }
  }

  return (
    <div className="card-simple" onClick={() => onNavigate(data)}>
      <div className={`background${hoverPlayOverlay ? " play-overlay" : ""}`}>
        <div className="image" style={{ backgroundImage: `url(${getModuleThumb(data)})` }}></div>
        {currentUser && onToggleSave && (
          <button onClick={(e) => onToggleSave(e, data)}>
            {isSaved ? (
              <i className="icon icon-heart"></i>
            ) : (
              <span className="glyphicon glyphicon-heart-empty"></span>
            )}
          </button>
        )}
        {hoverPlayOverlay &&
          (data.video && data.video.length > 0 ? (
            <PlayIcon color="white" />
          ) : (
            <i className="icon icon-document"></i>
          ))}
      </div>
      <div className="details">
        <h2>
          {data.title.length > 27 ||
          ["xsDevice", "smDevice", "mdDevice"].includes(bootstrapSize) ? (
            <DarkTooltip arrow enterTouchDelay={0} placement={"bottom"} title={data.title}>
              <span>{`${data.title.slice(0, 24)}...`}</span>
            </DarkTooltip>
          ) : (
            data.title
          )}
        </h2>
        <div>
          <p> {description()}</p>
          <button>
            <ArrowIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SimpleCard;
