import React from "react";
import { CDN_URL } from "../../components/shared/CdnUrl";

const MoneybackGuarantee = () => {
  return (
    <div className="section">
      <div className="money-back">
        <h2>30 Day Money Back Guarantee</h2>
        <p>
          We'll refund you completely if you change your mind or don't enjoy this course. No
          questions asked.
        </p>
        <a href="/store/practical-music-theory#faq" className="button button--primary">
          read more
        </a>
        <img src={`${CDN_URL}/images/pages/strumming-sos/justin-2.png`} alt="Justin" />
      </div>
    </div>
  );
};

export default MoneybackGuarantee;
