import React from "react";

export const Star = () => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99992 10.5134L11.1199 13L10.0266 8.31337L13.6666 5.16004L8.87325 4.75337L6.99992 0.333374L5.12659 4.75337L0.333252 5.16004L3.97325 8.31337L2.87992 13L6.99992 10.5134Z"
        fill="url(#paint0_linear_6904_12)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6904_12"
          x1="0.333252"
          y1="0.333374"
          x2="9.91878"
          y2="15.3866"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9ED32" />
          <stop offset="1" stopColor="#FBB040" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const HalfStar = () => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6666 5.16004L8.87325 4.74671L6.99992 0.333374L5.12659 4.75337L0.333252 5.16004L3.97325 8.31337L2.87992 13L6.99992 10.5134L11.1199 13L10.0333 8.31337L13.6666 5.16004ZM6.99992 9.26671V3.06671L8.13992 5.76004L11.0599 6.01337L8.84659 7.93337L9.51325 10.7867L6.99992 9.26671Z"
        fill="url(#paint0_linear_6904_32)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6904_32"
          x1="0.333252"
          y1="0.333374"
          x2="9.91878"
          y2="15.3866"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9ED32" />
          <stop offset="1" stopColor="#FBB040" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Exit = ({ color, closeBanner }) => {
  return (
    <svg
      className="clickable"
      onClick={closeBanner}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
        fill={color || "white"}
      />
    </svg>
  );
};

export const Stars = () => {
  return (
    <div style={{ display: "flex" }}>
      <Star />
      <Star />
      <Star />
      <Star />
      <HalfStar />
    </div>
  );
};

export const AppIcon = () => {
  return (
    <img
      style={{ height: "60px", width: "60px" }}
      src="https://jtgt-static.b-cdn.net/redesign/app/app-tile.png"
      alt="JustinGuitar Lessons & Songs App"
    />
  );
};

export const Download = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z" fill="white" />
    </svg>
  );
};

export const AppLogo1 = () => {
  return (
    <img
      style={{ height: "95px", width: "60px" }}
      src="https://jtgt-static.b-cdn.net/images/banner-resources/app-logo-1.png"
      alt="JustinGuitar Lessons & Songs App"
    />
  );
};

export const AppLogo2 = () => {
  return (
    <img
      style={{ height: "96px", width: "92px" }}
      src="https://jtgt-static.b-cdn.net/images/banner-resources/app-logo-2.png"
      alt="JustinGuitar Lessons & Songs App"
    />
  );
};

export const Justin1 = () => {
  return (
    <img
      style={{ height: "96px", width: "111px", marginRight: "-10px" }}
      src="https://jtgt-static.b-cdn.net/images/banner-resources/justin-1.png"
      alt="JustinGuitar Lessons & Songs App"
    />
  );
};

export const Justin2 = () => {
  return (
    <img
      style={{ height: "96px", width: "108px" }}
      src="https://jtgt-static.b-cdn.net/images/banner-resources/justin-2.png"
      alt="JustinGuitar Lessons & Songs App"
    />
  );
};

export const GooglePlay = () => {
  return (
    <img
      className="store-button"
      style={{ height: "auto", width: "130px", marginLeft: "10px" }}
      src="https://jtgt-static.b-cdn.net/images/banners/google-play-badge.svg"
      alt="JustinGuitar Lessons & Songs App"
    />
  );
};

export const AppStore = () => {
  return (
    <img
      className="store-button"
      style={{ height: "auto", width: "130px", marginLeft: "10px" }}
      src="https://jtgt-static.b-cdn.net/images/banners/apple-store-badge.svg"
      alt="JustinGuitar Lessons & Songs App"
    />
  );
};
