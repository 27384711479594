import React from "react";
import { useUser } from "../contexts/userContext";
import SongActionButtons from "./SongActionButtons";

export const SongGridItemOverlay = ({ song }) => {
  const user = useUser();

  return (
    <div
      className="song-grid-item__overlay"
      style={{
        justifyContent: user == null ? "center" : "space-between",
      }}
    >
      <SongActionButtons song={song} />

      <button className="button" onClick={() => (window.location.href = `/songs/${song.slug}`)}>
        Go To Song
      </button>
    </div>
  );
};

export default SongGridItemOverlay;
