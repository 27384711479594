import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { REQUEST_PASSWORD_RESET_SCHEMA } from "./consts";
import { SquareTextField } from "../../../jg-material/theme/components/styled/SquareTextField";
import { Button, Container, FormHelperText, Link, Typography } from "@mui/material";
import Loading from "../../shared/Loading";
import { PAGES } from "../sessions";
import { OrangeLink } from "../../../jg-material/theme/components/styled/Links";
import axiosWithCSRF from "../../shared/axiosWithCSRF";
import InvisibleRecaptcha from "../../InvisibleRecaptcha";

const RequestPasswordReset = ({ sectionHeader, setActivePage }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [executeRecaptcha, setExecuteRecaptcha] = useState(false);
  const [resetRecaptcha, setResetRecaptcha] = useState(false);
  const [formData, setFormData] = useState(null);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(REQUEST_PASSWORD_RESET_SCHEMA),
    mode: "onTouched",
  });

  const FORM_FIELD = "email";

  useEffect(() => {
    if (recaptchaToken) {
      postForm();
    }
  }, [recaptchaToken]);

  const onSubmit = (data) => {
    setFormData(data);
    setLoading(true);
    setExecuteRecaptcha(true);
  };

  const postForm = () => {
    setResetRecaptcha(false);
    axiosWithCSRF()
      .post("/users/password", {
        user: { email: formData.email },
        recaptcha_token: recaptchaToken,
      })
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setResetRecaptcha(true);
        setExecuteRecaptcha(false);
        setRecaptchaToken(null);
        setError("root.general", {
          type: "manual",
          message: "There was a problem sending a reset email. Please try again later.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {sectionHeader("Forgot Your Password?")}

      {success ? (
        <>
          <Typography>
            If this email address is in our database, we will send you an email with instructions
            for resetting your password. Please be sure to check your spam folder if you don't
            receive it.
          </Typography>

          <Typography mt={1}>
            If you still have trouble logging in, don't hesitate to contact{" "}
            <Link href="mailto:hello@justinguitar.com">hello@justinguitar.com</Link>.
          </Typography>

          <OrangeLink
            onClick={() => setActivePage(PAGES.signIn)}
            sx={{ textAlign: "center", mt: 2 }}
          >
            Back to Log In
          </OrangeLink>
        </>
      ) : (
        <>
          <Typography mb={2}>
            Don't worry, it happens to the best of us. Enter your email below and we'll send you a
            reset link.
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            {errors.root?.general && (
              <FormHelperText sx={{ fontSize: "12px" }} error={true}>
                {errors.root.general.message}
              </FormHelperText>
            )}

            {loading && <Loading />}

            <>
              <Controller
                name={FORM_FIELD}
                control={control}
                render={({ field }) => {
                  return (
                    <SquareTextField
                      {...field}
                      margin="normal"
                      size="medium"
                      className={field?.value?.length > 0 ? "Mui-filled" : ""}
                      required
                      fullWidth
                      label={"Email"}
                      error={errors[FORM_FIELD] ? true : false}
                      helperText={errors[FORM_FIELD]?.message}
                    />
                  );
                }}
              />
            </>

            <Button
              fullWidth
              type="submit"
              className="button button--primary"
              variant="contained"
              sx={{ mt: 3, mb: 2, boxShadow: "none" }}
              disabled={!isValid || loading}
            >
              Submit
            </Button>

            <Container>
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                <OrangeLink
                  onClick={() => {
                    setActivePage(PAGES.signIn);
                  }}
                >
                  Back to Log In
                </OrangeLink>
              </Typography>
            </Container>

            <InvisibleRecaptcha
              shouldExecute={executeRecaptcha}
              shouldReset={resetRecaptcha}
              onSuccess={(token) => setRecaptchaToken(token)}
            />
          </form>
        </>
      )}
    </>
  );
};

export default RequestPasswordReset;
