import React from "react";
import VoteButton from "./VoteButton";
import { withInnerWidth } from "../../shared";
import { BootstrapSize, breakpoints } from "../../shared/bootstrap-helpers";

export default withInnerWidth(({ songRequest, ranking, width }) => {
  const smView = width <= breakpoints[BootstrapSize.smDevice];
  return (
    <div className="song-request-listing-item">
      {ranking && (
        <div className="ranking">
          <h3 className="heading3 ">
            {smView ? null : "#"} {ranking}
          </h3>
        </div>
      )}
      {smView ? (
        <div className="title-and-artist">
          <div className="title">{songRequest.attributes.title}</div>
          <div className="artist">{songRequest.attributes.artist}</div>
        </div>
      ) : (
        <>
          <div className="title">{songRequest.attributes.title}</div>
          <div className="artist">{songRequest.attributes.artist}</div>
        </>
      )}

      {smView ? null : <div className="vote-count">{songRequest.attributes.voteCount}</div>}
      <div className="vote-button">
        <VoteButton songRequest={songRequest} />
      </div>
    </div>
  );
});
