import React from "react";
import AppIcons from "../../components/shared/AppIcons";

const AppLinksWithDescription = ({ description, onelinkUrl, analyticsEventValue }) => {
  return (
    <div className="beginner-app__body__app-links-with-description">
      <h3 className="uppercase bold">{description}</h3>
      <AppIcons
        black
        onelinkUrl={onelinkUrl}
        analyticsEventPageName="app-page"
        analyticsEventValue={analyticsEventValue}
      />
    </div>
  );
};

export default AppLinksWithDescription;
