import React, { useState } from "react";

import ArtistFilters from "./ArtistFilters";

import Swoosh from "../../shared/Swoosh";
import { PreventScroll } from "../../shared";
import { filters } from "../filters/filters";
import { artistSortByLabels, sortByOptions } from "../consts";

const panes = {
  query: "query",
  filters: "filters",
  sorts: "sorts",
};

export const SortsHeader = ({ setPane }) => (
  <>
    <i onClick={() => setPane(panes.filters)} className="icon icon-arrow-left" />
    <span>SORT BY</span>
  </>
);

export const SortsPane = ({ sortBy, setSortBy, hasQuery }) => (
  <div>
    {Object.entries(artistSortByLabels).map(([value, label]) => {
      const checked = sortBy === value;
      const disabled = value === sortByOptions.relevance && !hasQuery;
      return (
        <div
          className={`sorts-option ${checked && "sorts-option--checked"} ${
            disabled && "sorts-option--disabled"
          }`}
          onClick={() => !disabled && setSortBy(value)}
          key={value}
        >
          <span>{label}</span>
          <i className={`icon icon-radio-button-${checked ? "checked" : "unchecked"}`} />
        </div>
      );
    })}
  </div>
);

export const FiltersHeader = ({ close }) => (
  <>
    <i onClick={close} className="icon icon-cross" />
    <span>FILTERS</span>
  </>
);

export const FiltersPane = ({ setPane, sortBy, activeFilters, toggleFilter, clearFilters }) => (
  <>
    <div className="search-pane__sorts-link" onClick={() => setPane(panes.sorts)}>
      <div className="label">Sort By</div>
      <div className="selection">{artistSortByLabels[sortBy]}</div>
      <i className="icon icon-angle-right" />
    </div>
    <ArtistFilters
      activeFilters={activeFilters}
      toggleFilter={toggleFilter}
      buttonClassModifier="blue"
      clearFilters={clearFilters}
      availableFilters={filters}
    />
  </>
);

export const ArtistSearchParamsPane = ({
  initialPane = panes.filters,
  close,
  sortBy,
  setSortBy,
  hasQuery,
  activeFilters,
  toggleFilter,
  clearFilters,
}) => {
  const [currentPane, setPane] = useState(initialPane);

  return (
    <PreventScroll>
      <div className="search-pane">
        <div className="search-pane__header">
          {currentPane === panes.filters ? (
            <FiltersHeader setPane={setPane} close={close} />
          ) : currentPane === panes.sorts ? (
            <SortsHeader setPane={setPane} close={close} />
          ) : null}
        </div>

        <Swoosh color="white" excludeBottom>
          <div className="search-pane__body">
            {currentPane === panes.filters ? (
              <FiltersPane
                sortBy={sortBy}
                setSortBy={setSortBy}
                setPane={setPane}
                activeFilters={activeFilters}
                toggleFilter={toggleFilter}
                clearFilters={clearFilters}
              />
            ) : currentPane === panes.sorts ? (
              <SortsPane
                hasQuery={hasQuery}
                sortBy={sortBy}
                setSortBy={setSortBy}
                setPane={setPane}
                close={close}
              />
            ) : null}
          </div>
        </Swoosh>
      </div>
    </PreventScroll>
  );
};

export default ArtistSearchParamsPane;
