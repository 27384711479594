import React from "react";

import { productTypes, productsFilteredByType } from "./productTypes";
import ProductList from "./ProductList";
import { withInnerWidth } from "../components/shared";
import { BootstrapSize, breakpoints } from "../components/shared/bootstrap-helpers";
import AppIcons from "../components/shared/AppIcons";
import { CDN_URL } from "../components/shared/CdnUrl";

export default withInnerWidth(({ products, width }) => {
  const apps = productsFilteredByType(products, productTypes.app);
  const beginnerApp = apps.filter(
    (product) => product.data.attributes.title === "Beginner Guitar App"
  );
  const otherApps = apps.filter(
    (product) => product.data.attributes.title !== "Beginner Guitar App"
  );

  const beginnerAppScreenShotsSrc =
    width > breakpoints[BootstrapSize.mdDevice]
      ? "redesign/2020/products/phones.png"
      : "redesign/2020/products/phones-sm.png";

  return (
    <div className="products-listing products-listing--books container">
      <h2>Lessons & Songs App</h2>
      <div className="beginner-guitar-app-card">
        <div className="image-container">
          <img
            src={beginnerAppScreenShotsSrc}
            alt="screenshots of the JustinGuitar Lessons & Songs App"
          />
        </div>
        <div className="beginner-guitar-app-card__copy">
          <h3>This karaoke style play along app is the perfect companion to my Beginner Course</h3>
          <p>
            - Over 50 engaging lessons and over 1000 hit songs
            <br />
            - Self-assessment system to track your improvement
            <br />
            - Speed adjustment and easy-to-use guitar tuner
            <br />
            ... and more!!!
            <br />
          </p>
          <div className="app-store-button-container">
            <AppIcons />
            <img
              src={`${CDN_URL}/images/products/qr-codes/qr-code-other-webpage-placement.png`}
              alt="app qr code"
              className="app-qr-code"
            />
          </div>
        </div>
      </div>

      <h2>JustinGuitar Tool apps</h2>
      <ProductList products={otherApps} />
    </div>
  );
});
