import React, { useState } from "react";
import PropTypes from "prop-types";

import onClickOutside from "react-onclickoutside";
import FilterGroup from "./FilterGroup";

const SongsFiltersList = ({
  availableFilters,
  clearFilters,
  closeFilters,
  activeFilters,
  toggleFilter,
  buttonRef,
  defaultClosed,
}) => {
  SongsFiltersList.handleClickOutside = (e) => {
    if (buttonRef && buttonRef.current && buttonRef.current.contains(e.target)) {
      // user clicked filter button; button will handle closing filters
      return;
    }
  };

  const hasActiveFilters = Object.values(activeFilters).some((filter) => filter);

  return (
    <div className="filters__list">
      <div className="filters__list-header">
        <div>
          <span>
            <i className="icon icon-sliders" />
          </span>
          <span>filters</span>
        </div>
        {hasActiveFilters && (
          <button onClick={clearFilters} className="button button--no-background">
            Clear <i className="icon icon-cross" />
          </button>
        )}
      </div>
      {availableFilters.map(({ type, options, info }) => (
        <FilterGroup
          type={type}
          info={info}
          options={options}
          activeFilters={activeFilters}
          toggleFilter={toggleFilter}
          key={type}
          defaultClosed={defaultClosed}
        />
      ))}
      <div className="filters__action-buttons">
        {hasActiveFilters && (
          <button className="button filter-button filter-button--clear" onClick={clearFilters}>
            CLEAR ALL
          </button>
        )}
        {/* <button className="button filter-button filter-button--close" onClick={() => noop}>
          DONE
        </button> */}
      </div>
    </div>
  );
};

export default onClickOutside(SongsFiltersList, {
  handleClickOutside: () => SongsFiltersList.handleClickOutside,
});
