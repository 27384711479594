import React from "react";
import PropTypes from "prop-types";
import LoginModal from "./LoginModal";

export default class LoginPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggleIsOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { loginAction, loginParagraph } = this.props;
    return (
      <div>
        {loginAction && <p>You must be logged in to {loginAction}.</p>}
        {loginParagraph && <p>{loginParagraph}</p>}
        <div className="prompt-btns">
          <a className="button button--primary" href="/users/sign_up">
            Register
          </a>

          <a className="button button--blue" onClick={this.toggleIsOpen}>
            Log In
          </a>
        </div>
        <LoginModal
          token={this.props.token}
          isOpen={this.state.isOpen}
          toggleModal={this.toggleIsOpen}
        />
      </div>
    );
  }
}

LoginPrompt.propTypes = {
  loginAction: PropTypes.string,
  token: PropTypes.string.isRequired,
};
