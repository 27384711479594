import { combineReducers } from "@reduxjs/toolkit";

import { workshopsSlice } from "../../../pages/Workshops/store/workshops.slice";
import { promoItemsSlice } from "../../../justins-corner/store/promo-items.slice";

export const entitiesReducer = combineReducers({
  workshops: workshopsSlice.reducer,
  promoItems: promoItemsSlice.reducer,
});

export type EntitiesState = ReturnType<typeof entitiesReducer>;
