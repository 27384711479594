import * as yup from "yup";
import { CDN_URL } from "../../shared/CdnUrl";

// source: https://github.com/jquense/yup/issues/507
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const SIGN_IN_SCHEMA = yup
  .object({
    email: yup
      .string()
      .required("Please enter your email")
      .matches(EMAIL_REGEX, "Please provide a valid email address"),
    password: yup.string().required("Please enter your password"),
  })
  .required();

export const FORM_FIELDS = [
  {
    type: "email",
    name: "email",
    placeholder: "Email",
    fullWidth: true,
  },
  {
    type: "password",
    name: "password",
    placeholder: "Password",
    fullWidth: true,
  },
];
const BACKGROUND_IMG = `url(${CDN_URL}/redesign/2024/sign-up-background.jpg)`;

export const SESSION_CONTAINER_STYLE = {
  backgroundImage: BACKGROUND_IMG,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100vw",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "3rem 1rem",
};

export const SESSION_CONTAINER_MOBILE_STYLE = {
  padding: "0 0 3rem 0",
  width: "100%",
  justifyContent: "flex-start",
};

export const BASE_CONTAINER_STYLE = {
  background: "white",
  borderRadius: "20px",
  minHeight: "50vh",
};

export const MOBILE_CONTAINER_STYLE = {
  maxWidth: "100% !important",
  borderRadius: "0 0 20px 20px",
  minHeight: "auto",
};
