import React from "react";
import Swoosh from "../../components/shared/Swoosh";
import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
import { CDN_URL } from "../../components/shared/CdnUrl";

const defaultHeaderImages = {
  [BootstrapSize.smDevice]: `${CDN_URL}/images/pages/playground/playground-hero-lg.jpg`,
  [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/playground/playground-hero-sm.jpg`,
};

const PlaygroundSwoosh = () => {
  return (
    <div className="playground-swoosh">
      <Swoosh imgUrl={defaultHeaderImages}>
        <div className="breadcrumbs">
          <a href={"/playground"}>Explore</a>
          <p className="separator">›</p>
          <p>Playground</p>
        </div>
        <div className="playground-swoosh__content">
          <h1 className="header1">playground</h1>
          <p>
            Grab a cup! This extra content will inspire you even if you don't have your guitar in
            your hands. Enjoy! :)
          </p>
        </div>
      </Swoosh>
    </div>
  );
};

export default PlaygroundSwoosh;
