import React from "react";
import {
  Box,
  Breadcrumbs,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Link,
  Modal,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { workshopGalleryImages, workshopImageBaseUrl } from "./constants";
import { WorkshopImagesCarousel } from "./WorkshopImagesCarousel";

const _WorkshopGalleryPage = () => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedWorkshop, setSelectedWorkshop] = React.useState<string | null>(null);
  const isSmallOrSmaller = useMediaQuery(jgDenimTheme.breakpoints.down("sm"));
  const isBetweenLargeAndSmall = useMediaQuery(jgDenimTheme.breakpoints.between("sm", "lg"));

  const openWorkshopGalleryModal = (workshopName) => {
    setSelectedWorkshop(workshopName);
    setModalOpen(true);
  };

  return (
    <Container>
      <Breadcrumbs separator="›" sx={{ mb: jgDenimTheme.spacing(3) }}>
        <Link href={"/workshops"}>Workshops</Link>
        <Typography sx={{ fontWeight: 700 }}>Gallery</Typography>
      </Breadcrumbs>

      <Typography
        variant="h2"
        mb={jgDenimTheme.spacing(4)}
        textTransform="uppercase"
        textAlign="center"
      >
        workshops gallery
      </Typography>

      <Grid
        container
        spacing={jgDenimTheme.spacing(2)}
        columns={isSmallOrSmaller ? 1 : isBetweenLargeAndSmall ? 2 : 3}
        mb={jgDenimTheme.spacing(4)}
      >
        {Object.entries(workshopGalleryImages)
          .reverse()
          .map(([pathPrefix, { workshopTitle, imagePaths }]) => (
            <Grid item key={pathPrefix} xs={1} mb={3}>
              <Card>
                <CardActionArea onClick={() => openWorkshopGalleryModal(pathPrefix)}>
                  <CardMedia
                    component="img"
                    image={`${workshopImageBaseUrl}${pathPrefix}/${imagePaths[0]}`}
                    sx={{ borderRadius: "10px" }}
                  />
                  <CardContent>
                    <Typography variant="h6">{workshopTitle}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
      </Grid>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100vw",
          }}
        >
          <WorkshopImagesCarousel workshop={selectedWorkshop} />
        </Box>
      </Modal>
    </Container>
  );
};

export const WorkshopGalleryPage = () => (
  <ThemeProvider theme={jgDenimTheme}>
    <_WorkshopGalleryPage />
  </ThemeProvider>
);
