import React, { useEffect, useState } from "react";
import { CDN_URL } from "../../components/shared/CdnUrl";
import TabsVideo from "../TabsVideo";

const includedItems = [
  {
    title: "chord & lyrics",
    image: "tabs-features-chords.jpeg",
    mobileImage: "tabs-features-chords-mobile.jpeg",
  },
  {
    title: "tabs & lyrics",
    image: "tabs-features-tabs.jpeg",
    mobileImage: "tabs-features-tabs-mobile.jpeg",
  },
  {
    title: "autoscroll",
    image: "tabs-features-autoscroll-3.jpg",
    mobileImage: "tabs-features-autoscroll-mobile-3.jpg",
  },
  {
    title: "display settings",
    image: "tabs-features-display.jpeg",
    mobileImage: "tabs-features-display-mobile.jpeg",
  },
  {
    title: "transposer",
    image: "tabs-features-transpose.jpeg",
    mobileImage: "tabs-features-transpose-mobile.jpeg",
  },
  {
    title: "full screen mode",
    image: "tabs-features-newwindow.jpeg",
    mobileImage: null,
  },
];

const VerifiedIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46 24L41.12 18.42L41.8 11.04L34.58 9.4L30.8 3L24 5.92L17.2 3L13.42 9.38L6.2 11L6.88 18.4L2 24L6.88 29.58L6.2 36.98L13.42 38.62L17.2 45L24 42.06L30.8 44.98L34.58 38.6L41.8 36.96L41.12 29.58L46 24ZM20.18 33.44L12.58 25.82L15.54 22.86L20.18 27.52L31.88 15.78L34.84 18.74L20.18 33.44Z"
      fill="#1D3446"
    />
  </svg>
);

const WhatsIncluded = ({ isMobileView, sheetCount, tabsCount, width }) => {
  const [activeIncludedItem, setActiveIncludedItem] = useState(includedItems[0]);
  const includedItemsCount = isMobileView ? includedItems.length - 1 : includedItems.length;

  useEffect(() => {
    if (isMobileView && activeIncludedItem.title.toLowerCase() === "full screen mode") {
      setActiveIncludedItem(includedItems[includedItems.length - 2]);
    }
  }, [isMobileView]);
  return (
    <>
      <h2>what’s included in JustinGuitar Tabs?</h2>
      <div className="pill-btn-container">
        {includedItems.slice(0, includedItemsCount).map((includedItem, idx) => (
          <button
            key={idx}
            className={`pill-btn${
              activeIncludedItem.title === includedItem.title ? " active" : ""
            }`}
            onClick={() => setActiveIncludedItem(includedItem)}
          >
            {includedItem.title}
          </button>
        ))}
      </div>
      <img
        className="included-item-image"
        src={`${CDN_URL}/images/products/redesign/features/${
          width <= 576 ? activeIncludedItem.mobileImage : activeIncludedItem.image
        }`}
        alt={activeIncludedItem.title}
      />
      {width <= 576 ? <TabsVideo /> : null}
      <div className="verified-badges">
        <div>
          <VerifiedIcon />
          Chord & Lyric Sheets for
          <br />
          {sheetCount} Song Lessons
        </div>
        <div>
          <VerifiedIcon />
          Detailed Tabs for
          <br />
          {tabsCount} Song Lessons
        </div>
        <div>
          <VerifiedIcon />
          New Content
          <br />
          Added Weekly!
        </div>
      </div>
      <img
        src={
          width <= 576
            ? `${CDN_URL}/images/products/redesign/approved-by-justin-sm.png`
            : `${CDN_URL}/images/products/redesign/approved-by-justin-2.png`
        }
        alt="Approved by Justin"
        className="approved-by-justin"
      />
    </>
  );
};

export default WhatsIncluded;
