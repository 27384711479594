import React from "react";
import Swoosh from "../components/shared/Swoosh";
import { QuotesIcon } from "./consts";

export default class StudentTestimonialsSwoosh extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStudentTestimonial: this.props.studentTestimonials[0],
    };
  }

  render() {
    return (
      <div className="guitarist-testimonials-swoosh">
        <Swoosh gradient="denim">
          <div className="guitarist-testimonials-swoosh-content container">
            <div className="guitarist-testimonials-swoosh-info">
              <div className="guitarist-testimonials-swoosh-info-upper">
                <h2>Students success stories</h2>
                <p>{this.state.activeStudentTestimonial.description}</p>
                <QuotesIcon />
              </div>
              <div className="guitarist-testimonials-swoosh-info-lower">
                <span>
                  <strong>{this.state.activeStudentTestimonial.name}</strong>
                </span>
              </div>
            </div>
          </div>
        </Swoosh>
        <div className="guitarist-select">
          {this.props.studentTestimonials.map((testimonial, idx) => (
            <div
              key={idx}
              className={`guitarist ${
                this.state.activeStudentTestimonial.name === testimonial.name ? "active" : ""
              }`}
              onClick={() => this.setState({ activeStudentTestimonial: testimonial })}
              style={{ backgroundImage: `url(${testimonial.profileImg})` }}
            >
              <span className="name">{testimonial.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
