import React from "react";
import { LightTooltip } from "../../components/shared/ui/Tooltips";
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from "./Icons";

export const FREE_PLAN = {
  id: "free",
  price: "free",
  title: "Enroll for",
  info: "grades 1 & 2",
};

const FreePlanToolTip = () => {
  return (
    <LightTooltip
      arrow
      enterTouchDelay={0}
      placement="bottom"
      title="You can enroll and follow Grades 1 and 2 of the Music Theory Course free of charge. By enrolling for free, you get access to Interactive Quizzes, Community support, and more!"
    >
      <i className="icon icon-info"></i>
    </LightTooltip>
  );
};

const FreePlan = ({ activePlan, setActivePlan, currentUser }) => {
  if (currentUser?.data?.attributes?.isPmtEnrolled === true) return null;

  return (
    <button
      className={`plan ${activePlan?.id === FREE_PLAN.id ? "active" : ""} free`}
      onClick={() => (currentUser ? setActivePlan(FREE_PLAN) : null)}
      style={{ cursor: !activePlan ? "default" : null }}
    >
      {activePlan?.id === FREE_PLAN.id ? (
        <CheckboxCheckedIcon />
      ) : (
        currentUser && <CheckboxUncheckedIcon />
      )}

      <div className="badge">{FREE_PLAN.info}</div>
      <FreePlanToolTip />
      <p className="plan-title">Enroll for</p>
      <p className="plan-price">free</p>
      <p className="plan-description">
        <strong>No credit card required.</strong>
        Unlock interactive quizzes and more!
      </p>
    </button>
  );
};

export default FreePlan;
