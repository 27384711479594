import React from "react";
import { BootstrapSize } from "../components/shared/bootstrap-helpers";
import Swoosh from "../components/shared/Swoosh";
import { CDN_URL } from "../components/shared/CdnUrl";

export default function () {
  return (
    <div className="join">
      <Swoosh
        imgUrl={{
          [BootstrapSize.xsDevice]: `${CDN_URL}/redesign/2020/home-page/cta-small.jpg`,
          [BootstrapSize.xlDevice]: `${CDN_URL}/redesign/2020/home-page/cta.jpg`,
        }}
      >
        <div className="join-cta">
          <h2>Stop Dreaming and Do It.</h2>
          <a className="button button--primary" href="/users/sign_up">
            Join JustinGuitar
          </a>
        </div>
      </Swoosh>
    </div>
  );
}
