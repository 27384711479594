import React from "react";
import { ArrowDownEmpty, ArrowDownFilled, ArrowUpEmpty, ArrowUpFilled } from "./Icons";

function StrummingPatternDiagramDivision({
  noteCount,
  strummingDirection,
  active = false,
  onDivisionClick = null,
}) {
  const getDivisionName = (noteCount) => {
    return noteCount % 2 === 0 ? "+" : Math.ceil(noteCount / 2);
  };

  return (
    <div
      className={`strumming-pattern-diagram-division ${active ? "active" : ""}`}
      onClick={() =>
        onDivisionClick
          ? onDivisionClick(
              noteCount - 1,
              strummingDirection || (noteCount % 2 === 0 ? "up" : "down")
            )
          : null
      }
    >
      <div>
        {strummingDirection === "down" && <ArrowDownFilled />}
        {strummingDirection === "up" && <ArrowUpFilled />}
        {strummingDirection === "hit" && (
          <span
            style={{
              fontWeight: "bold",
              width: "21px",
              height: "64px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            X
          </span>
        )}
        {strummingDirection === "mute" && (
          <span
            style={{
              fontWeight: "bold",
              width: "21px",
              height: "64px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            M
          </span>
        )}
        {!strummingDirection && (noteCount % 2 === 0 ? <ArrowUpEmpty /> : <ArrowDownEmpty />)}
      </div>
      <div className={!strummingDirection ? "inactive" : ""}>{getDivisionName(noteCount)}</div>
    </div>
  );
}

export default StrummingPatternDiagramDivision;
