import React from "react";
import axiosWithCSRF from "./shared/axiosWithCSRF";

export default class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      hasErrors: false,
      loginSuccess: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    let data = JSON.stringify({
      user: {
        email: this.state.email,
        password: this.state.password,
      },
      authenticity_token: ReactOnRails.authenticityToken(),
    });
    axiosWithCSRF()
      .post("/users/sign_in", data)
      .then((response) => {
        this.setState({ loginSuccess: true });
        setTimeout(() => window.location.reload(true), 500);
      })
      .catch((error) => {
        this.setState({
          hasErrors: error.response.data,
          email: "",
          password: "",
        });
        setTimeout(() => this.setState({ hasErrors: false }), 2000);
      });
  }

  render() {
    let { hasErrors } = this.state;
    return (
      <div className="user-login-modal">
        {this.state.loginSuccess && (
          <div className="user-login-modal--success">
            <h3 className="header3">Successfully Logged In.</h3>
          </div>
        )}

        <h2 className="header2 justin-blue">LOG IN</h2>

        {hasErrors && (
          <div className="user-login-modal--errors">
            <h3 className="header3">
              {hasErrors.error ? hasErrors.error : "There was a problem signing you in."}{" "}
            </h3>
          </div>
        )}
        <form onSubmit={this.handleSubmit}>
          <label>
            Email:
            <input type="email" value={this.state.email} onChange={this.handleEmailChange} />
          </label>

          <label>
            Password:
            <input
              type="password"
              value={this.state.password}
              onChange={this.handlePasswordChange}
            />
          </label>

          <input type="submit" value="Log In" />
        </form>

        <div className="user-login__options">
          <a href="/login-help">Having trouble logging in?</a>
        </div>

        <div className="create-account-link user-login__options">
          <a
            className="btn btn-block btn-social btn-signup header5 button-spacing"
            href="/users/sign_up"
          >
            <span className="glyphicon glyphicon-envelope"></span> CREATE AN ACCOUNT
          </a>
          <a href="/users/password/new">Facebook and Google Login</a>
          <a href="/users/password/new">Forgot your password?</a>
        </div>
      </div>
    );
  }
}
