import { WorkshopEntity } from "../interfaces";
import { CDN_URL } from "../../../components/shared/CdnUrl";

export const dateAsShortMonthNumericYear = (date?: Date | string): string => {
  if (date == null) return "";

  if (typeof date === "string") date = new Date(date);

  return date.toLocaleString("default", { month: "long", year: "numeric" });
};

export const dateAsShortMonthDayNumericYear = (date?: Date | string): string => {
  if (date == null) return "";

  if (typeof date === "string") date = new Date(date);

  return date.toLocaleString("default", { day: "numeric", month: "short", year: "numeric" });
};

export const dateAsFullDayMonthYear = (date?: Date | string): string => {
  if (date == null) return "";

  if (typeof date === "string") date = new Date(date);

  return date.toLocaleString("default", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

export const dateAsFullDateTime = (date?: Date | string): string => {
  if (date == null) return "";

  if (typeof date === "string") date = new Date(date);

  return date.toLocaleString("default", {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

export const shortLocation = (
  locationCity: string | undefined,
  locationCountry: string | undefined
): string => {
  return [locationCity, locationCountry].filter((s) => s != null && s.length > 0).join(" • ");
};

const acousticSmallHeaderImg = `${CDN_URL}/redesign/workshops/JG-2022WorkshopAcoustic-SmallHero.jpg`;
const acousticLargeHeaderImg = `${CDN_URL}/redesign/workshops/JG-2022WorkshopAcoustic-Hero.jpg`;
const electricSmallHeaderImg = `${CDN_URL}/redesign/workshops/JG-2022WorkshopElectric-SmallHero.jpg`;
const electricLargeHeaderImg = `${CDN_URL}/redesign/workshops/JG-2022WorkshopElectric-Hero.jpg`;

export const getHeaderImage = (
  workshop: WorkshopEntity,
  size: "large" | "small" = "large"
): string => {
  if (size === "small" && workshop.attributes.smallBannerImgUrl != null) {
    return `${CDN_URL}/redesign/workshops/${workshop.attributes.smallBannerImgUrl}`;
  }

  if (size === "large" && workshop.attributes.largeBannerImgUrl != null) {
    return `${CDN_URL}/redesign/workshops/${workshop.attributes.largeBannerImgUrl}`;
  }

  console.log(size);
  console.log(workshop.attributes);

  if (workshop.attributes.title.toLowerCase().includes("acoustic")) {
    return size === "small" ? acousticSmallHeaderImg : acousticLargeHeaderImg;
  } else {
    return size === "small" ? electricSmallHeaderImg : electricLargeHeaderImg;
  }
};
