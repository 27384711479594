import React from "react";
import { withInnerWidth } from "../../components/shared";
import { BootstrapSize, breakpoints } from "../../components/shared/bootstrap-helpers";
import WelcomeBack from "./WelcomeBack";

const DashboardContentHome = ({ width }) => {
  return (
    <div
      className="dashboard__content dashboard__content--dashboard"
      style={{
        marginTop: width <= breakpoints[BootstrapSize.smDevice] ? "-30px" : null,
      }}
    >
      <WelcomeBack />
    </div>
  );
};

export default withInnerWidth(DashboardContentHome);
