import React, { useState, useEffect, useRef } from "react";
import { LinkIcon, PauseIcon, PlayIcon, ResetIcon } from "./Icons";
import { blankStrummingPattern, soundFiles } from "./consts.js";
import StrummingPatternDiagram from "./StrummingPatternDiagram";
import Settings from "./Settings";
import Library from "./Library";
import LibraryToggle from "./LibraryToggle";
import {
  decodePattern,
  encodePattern,
  getEncodedPatternString,
  getInterval,
  playTone,
  replaceLastUrlSegment,
} from "./lib";
import { connect } from "react-redux";
import Welcome from "./Welcome";
import Lessons from "./Lessons";
import {
  createStrummingPatternItem,
  deleteStrummingPatternItem,
  renameStrummingPatternItem,
} from "../../../components/shared/session/sessionEffects";
import { openAlert, openDialog } from "../../../components/shared/ui/uiSlice";
import Snackbar from "../../../components/shared/ui/Snackbar";
import Dialog from "../../../components/shared/ui/Dialog";
import Instructions from "./Instructions";
import { savedStrummingPatternsSelector } from "../store/strummingMachineSelectors";
import { withBootstrapSize } from "../../../components/shared/WithBootstrapSize";
import { CDN_URL } from "../../../components/shared/CdnUrl";

let loop = null;
let loopTimeoutId;
let voiceLoopTimeoutId;

function StrummingMachineApp({
  strummingPatternData,
  currentUser,
  token,
  createStrummingPatternItem,
  deleteStrummingPatternItem,
  renameStrummingPatternItem,
  openAlert,
  openDialog,
  savedStrummingPatterns,
  width,
}) {
  const [strummingPatterns, setStrummingPatterns] = useState([]);
  const [selectedStrummingPattern, setSelectedStrummingPattern] = useState(blankStrummingPattern);
  const [timeSignature, setTimeSignature] = useState("1");
  const [tempo, setTempo] = useState("100");
  const [sound, setSound] = useState("acoustic");
  const [chord, setChord] = useState("a");
  const [metronome, setMetronome] = useState(false);
  const [soundMute, setSoundMute] = useState(false);
  const [voiceCount, setVoiceCount] = useState(false);
  const [activeDivision, setActiveDivision] = useState(-1);
  // const [activeVoiceDivision, setActiveVoiceDivision] = useState(-1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showLibrary, setShowLibrary] = useState(false);
  const [showLessons, setShowLessons] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [useDifferentStrums, setUseDifferentStrums] = useState(false);
  const [cutSound, setCutSound] = useState(false);
  const [savedPattern, setSavedPattern] = useState(null);
  const [savedPatternCount, setSavedPatternCount] = useState(0);
  const [sampler, setSampler] = useState(null);
  const [samplerLoaded, setSamplerLoaded] = useState(false);

  useEffect(() => {
    initSampler();
    initStrummingPatternData();
    initSelectedPattern();

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      // todo: add cleanup for sampler?
      // newSynth.dispose();
      stopStrummingPattern();
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const prevDeps = useRef({
    selectedStrummingPattern,
    timeSignature,
    tempo,
    sound,
    chord,
    useDifferentStrums,
    cutSound,
    metronome,
    soundMute,
    voiceCount,
  });

  useEffect(() => {
    if (sampler && selectedStrummingPattern) {
      const changedDeps = Object.entries({
        selectedStrummingPattern,
        timeSignature,
        tempo,
        sound,
        chord,
        useDifferentStrums,
        cutSound,
        metronome,
        soundMute,
        voiceCount,
      }).reduce((acc, [key, value]) => {
        if (prevDeps.current[key] !== value) {
          acc.push(key);
        }
        return acc;
      }, []);

      prevDeps.current = {
        selectedStrummingPattern,
        timeSignature,
        tempo,
        sound,
        chord,
        useDifferentStrums,
        cutSound,
        metronome,
        soundMute,
        voiceCount,
      };

      stopStrummingPattern();
      initSampler(soundFiles[sound][chord]);
    }
  }, [
    selectedStrummingPattern,
    timeSignature,
    tempo,
    sound,
    chord,
    useDifferentStrums,
    cutSound,
    metronome,
    soundMute,
    voiceCount,
  ]);

  useEffect(() => {
    const encodedPattern = getEncodedPatternString(selectedStrummingPattern);
    replaceLastUrlSegment(encodedPattern);
    initStrummingPatternData(); // maybe not needed
  }, [selectedStrummingPattern]);

  useEffect(() => {
    initStrummingPatternData();
    initSelectedPattern();
  }, [savedStrummingPatterns]);

  const handleKeyPress = (event) => {
    if (event.code === "Space") {
      event.preventDefault();
      document.querySelector(".play-button").click();
    }
  };

  const initSelectedPattern = () => {
    const pathSegments = window.location.pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];
    let newPattern = { ...blankStrummingPattern };
    let blank = true;

    if (lastSegment && lastSegment.length === 11) {
      const lastSegmentDecoded = decodePattern(lastSegment);

      for (let i = 0; i <= 7; i++) {
        if (lastSegmentDecoded[i] !== null) {
          blank = false;
        }
        newPattern[i] = lastSegmentDecoded[i];
      }
    }

    const saved =
      savedStrummingPatterns.length &&
      savedStrummingPatterns.find(
        (strummingPatterns) =>
          strummingPatterns.attributes.data.strumming_pattern.pattern ===
          getEncodedPatternString(newPattern)
      );

    setSavedPattern(saved);

    if (!blank && !saved) {
      newPattern.name = "Unsaved";
    }

    if (saved) {
      newPattern = decodePattern({
        ...saved.attributes.data.strumming_pattern,
        saved: true,
        popular: saved.popular,
        itemable_id: saved.itemable_id,
      });
    }

    if (selectedStrummingPattern.saved && savedStrummingPatterns.length === 0) {
      setSelectedStrummingPattern(blankStrummingPattern);
    }

    if (
      getEncodedPatternString(newPattern) !== getEncodedPatternString(selectedStrummingPattern) ||
      newPattern.saved
    ) {
      setSelectedStrummingPattern(newPattern);
    }
  };

  const initStrummingPatternData = () => {
    let data = strummingPatternData.map((strummingPattern) => {
      const popular = ["10-00-00-00", "10-10-10-10", "10-12-02-10"].includes(
        strummingPattern.pattern
      );

      return decodePattern({ ...strummingPattern, saved: false, popular });
    });

    data = [
      ...data,
      ...savedStrummingPatterns.map((strummingPattern) => {
        return decodePattern({
          ...strummingPattern.attributes.data.strumming_pattern,
          saved: true,
          popular: false,
          itemable_id: strummingPattern.itemable_id,
        });
      }),
    ];

    data.sort((a, b) => {
      if (a.saved && !b.saved) {
        return -1; // a comes before b
      } else if (!a.saved && b.saved) {
        return 1; // b comes before a
      } else {
        return 0; // no sorting needed
      }
    });

    setSavedPatternCount(savedStrummingPatterns.length);
    setStrummingPatterns(data);
  };

  const playMetronome = (divisionIdx) => {
    if (divisionIdx % 2 === 0) {
      setTimeout(() => {
        if (divisionIdx === 0) {
          playTone(880);
        } else {
          playTone(440);
        }
      }, 100);
    }
  };

  const playVoice = (divisionIdx) => {
    const division = selectedStrummingPattern[divisionIdx.toString()];

    if (division !== null) {
      if (divisionIdx % 2 !== 0) {
        sampler.triggerAttack("C5");
      }
      if (divisionIdx === 0) {
        sampler.triggerAttack("C1");
      }
      if (divisionIdx === 2) {
        sampler.triggerAttack("C2");
      }
      if (divisionIdx === 4) {
        sampler.triggerAttack("C3");
      }
      if (divisionIdx === 6) {
        sampler.triggerAttack("C4");
      }
    }
  };

  const playStrum = (divisionIdx) => {
    const division = selectedStrummingPattern[divisionIdx.toString()];
    const note = "A" + (divisionIdx + 1);

    // if (note == "A1" || note == "A5") {
    //   gainNode.gain.set({ value: 0.2 });
    // } else {
    //   gainNode.gain.set({ value: 0.5 });
    // }

    if (!soundMute) {
      if (useDifferentStrums) {
        if (division !== null) {
          if (division === "mute") {
            sampler.releaseAll(Tone.now() + 0.01);
            // console.log("Mute");
          } else if (division === "hit") {
            sampler.triggerAttack(note);
            // console.log("Hit " + note);
          } else {
            sampler.triggerAttack(note);
            // console.log("Play " + note);
          }
        }
      } else {
        if (division === "down") {
          // downStrumAudioRef.current.currentTime = 0;
          // downStrumAudioRef.current.play();
          // playTone(440);
          // synth.triggerAttackRelease("440", 0.1);
          // playToneJsTone(440);
          sampler.triggerAttack("A1");
          // console.log("Play A1");
        } else if (division === "up") {
          // upStrumAudioRef.current.currentTime = 0;
          // upStrumAudioRef.current.play();
          // playTone(880);
          // synth.triggerAttackRelease("880", 0.1);
          // playToneJsTone(880);
          sampler.triggerAttack("A2");
          // console.log("Play A2");
        } else if (division === "mute") {
          // upStrumAudioRef.current.currentTime = 0;
          // upStrumAudioRef.current.play();
          // playTone(880);
          // synth.triggerAttackRelease("880", 0.1);
          // playToneJsTone(880);
          // sampler.releaseAll(Tone.now() + 0.01);
          if (divisionIdx % 2 === 0) {
            sampler.triggerAttack("A3");
          } else {
            sampler.triggerAttack("A4");
          }
          setTimeout(() => sampler.releaseAll(Tone.now() + 0.01), 100);
          // console.log("Mute");
        } else if (division === "hit") {
          // upStrumAudioRef.current.currentTime = 0;
          // upStrumAudioRef.current.play();
          // playTone(880);
          // synth.triggerAttackRelease("880", 0.1);
          // playToneJsTone(880);
          // sampler.triggerAttack("A1");
          sampler.triggerAttack("A5");
          setTimeout(() => sampler.releaseAll(Tone.now() + 0.01), 100);
          // console.log("Hit");
        }
      }
    }

    if (cutSound) {
      const timeUntilNextStrum = getNextActiveStrumTime(divisionIdx) - 50;
      const time = timeUntilNextStrum <= 100 ? 100 : timeUntilNextStrum;
      setTimeout(() => sampler.releaseAll(Tone.now() + 0.01), time);
    }
  };

  const initSampler = (urls = soundFiles["acoustic"]["a"]) => {
    const samplerObj = new Tone.Sampler({
      urls: {
        ...urls,
        ...{
          A3:
            sound === "acoustic"
              ? soundFiles["acoustic"]["muted"]["A1"]
              : soundFiles["electric"]["muted"]["A1"],
          A4:
            sound === "acoustic"
              ? soundFiles["acoustic"]["muted"]["A2"]
              : soundFiles["electric"]["muted"]["A2"],
          A5: sound === "acoustic" ? soundFiles["acoustic"]["hit"] : soundFiles["electric"]["hit"],
        },
        ...{
          C1: soundFiles["human"][1],
          C2: soundFiles["human"][2],
          C3: soundFiles["human"][3],
          C4: soundFiles["human"][4],
          C5: soundFiles["human"]["and"],
        },
      },
      baseUrl: `${CDN_URL}/images/components/strumming-machine`,
      onload: () => {
        Tone.start(); // Important with Tone.js and newer Chrome browsers.
        // console.log("Sampler loaded.");
        setSamplerLoaded(true);
      },
    }).toDestination();

    setSampler(samplerObj);
  };

  const getNextActiveStrumTime = (currentDivision) => {
    const interval = getInterval(tempo);
    let nextDivision = (currentDivision + 1) % 8; // Modulo 8 since there are 8 divisions
    let timeUntilNextStrum = interval; // At least one interval will pass

    while (selectedStrummingPattern[nextDivision.toString()] === null) {
      timeUntilNextStrum += interval;
      nextDivision = (nextDivision + 1) % 8;
    }

    return timeUntilNextStrum;
  };

  // const playStrummingPatternOld = () => {
  //   if (isPlaying) {
  //     stopStrummingPattern();
  //     return;
  //   }
  //
  //   if (!selectedStrummingPattern) return;
  //
  //   setIsPlaying(true);
  //
  //   const divisions = Object.entries(selectedStrummingPattern).filter(([key]) => !isNaN(key));
  //   const divisionCount = divisions.length;
  //   const interval = getInterval(tempo);
  //   let currentDivision = 0;
  //
  //   setActiveDivision(currentDivision);
  //   playStrum(currentDivision);
  //
  //   loop = setInterval(() => {
  //     currentDivision = (currentDivision + 1) % divisionCount;
  //     setActiveDivision(currentDivision);
  //     console.log("playStrummingPattern loop currentDivision: ", currentDivision);
  //     playStrum(currentDivision);
  //   }, interval);
  // };

  const playStrummingPattern = () => {
    if (isPlaying) {
      stopStrummingPattern();
      return;
    }

    if (!selectedStrummingPattern) return;

    setIsPlaying(true);

    const divisions = Object.entries(selectedStrummingPattern).filter(([key]) => !isNaN(key));
    const divisionCount = divisions.length;
    const interval = getInterval(tempo);
    let currentDivision = 0;
    let currentVoiceDivision = 0;

    const loop = () => {
      setActiveDivision(currentDivision);
      playStrum(currentDivision);

      if (metronome) {
        playMetronome(currentDivision);
      }

      currentDivision = (currentDivision + 1) % divisionCount;

      loopTimeoutId = setTimeout(loop, interval);
    };

    const voiceLoop = () => {
      playVoice(currentVoiceDivision);

      currentVoiceDivision = (currentVoiceDivision + 1) % divisionCount;

      // Calculate the delay for the next iteration and compensate the one after.
      const nextInterval =
        currentVoiceDivision === 6
          ? interval * 0.75
          : currentVoiceDivision === 7
          ? interval * 1.25
          : interval;

      voiceLoopTimeoutId = setTimeout(voiceLoop, nextInterval);
    };

    // Start the loop.
    loop();

    // Start the voice loop on a different loop,
    // because we calculate the interval differently, considering delay in samples.
    if (voiceCount) {
      voiceLoop();
    }
  };

  const stopStrummingPatternOld = () => {
    if (sampler) {
      sampler.releaseAll(Tone.now() + 0.01);
    }
    clearInterval(loop);
    setIsPlaying(false);
    setActiveDivision(-1);
  };

  const stopStrummingPattern = () => {
    if (sampler) {
      sampler.releaseAll(Tone.now() + 0.01);
    }
    clearTimeout(loopTimeoutId);
    clearTimeout(voiceLoopTimeoutId);
    setIsPlaying(false);
    setActiveDivision(-1);
  };

  const handleDivisionClick = (divisionIdx, strummingDirection) => {
    if (isPlaying) {
      stopStrummingPattern();
    }

    let newPattern;

    setSelectedStrummingPattern((prevPattern) => {
      newPattern = { ...prevPattern };
      if (newPattern[divisionIdx] === strummingDirection) {
        newPattern[divisionIdx] = null;
      } else {
        newPattern[divisionIdx] = strummingDirection;
      }

      for (let i = 0; i <= 7; i++) {
        if (newPattern[i] === undefined) {
          newPattern[i] = null;
        }
      }

      newPattern["id"] = -1;

      return newPattern;
    });
  };

  const renameSavedPattern = (pattern) => {
    const encodedPattern = encodePattern(pattern, savedPatternCount);
    encodedPattern["name"] = pattern.inputValue;
    const data = {
      user_item: {
        user_id: currentUser.data.id,
        itemable_type: "StrummingPattern",
        itemable_id: pattern.itemable_id,
        item_status: "saved",
        strumming_pattern_id: encodedPattern.id,
        data: { strumming_pattern: encodedPattern },
      },
    };

    renameStrummingPatternItem(data);
    pattern["name"] = pattern.inputValue;
    setSelectedStrummingPattern(pattern);
  };

  const toggleSavedPattern = (pattern) => {
    const encodedPattern = encodePattern(pattern, savedPatternCount);
    encodedPattern["name"] = pattern.inputValue;

    const isSaved =
      savedStrummingPatterns.length &&
      savedStrummingPatterns.find(
        (strummingPatterns) =>
          strummingPatterns.attributes.data.strumming_pattern.pattern ===
          getEncodedPatternString(pattern)
      );

    if (isSaved) {
      deleteStrummingPatternItem(savedPattern);
    } else {
      createStrummingPatternItem({
        user_item: {
          user_id: currentUser.data.id,
          itemable_type: "StrummingPattern",
          itemable_id: pattern.id <= 0 ? null : pattern.id,
          item_status: "saved",
          strumming_pattern_id: encodedPattern.id,
          data: { strumming_pattern: encodedPattern },
        },
      });
    }
  };

  const savePattern = (savedPattern, selectedStrummingPattern) => {
    if (savedPattern) {
      deleteStrummingPatternItem(savedPattern);
    } else {
      openDialog({
        message: `Save the pattern?`,
        confirmButtonText: "Confirm",
        data: selectedStrummingPattern,
        input: {
          type: "text",
          placeholder: "Pattern Name",
          value: ["blank", "unsaved"].includes(selectedStrummingPattern.name.toLowerCase())
            ? ""
            : selectedStrummingPattern.name,
        },
      });
    }
  };

  const copyPattern = (selectedStrummingPattern) => {
    openAlert({ message: "Link has been copied to your clipboard!", severity: "success" });
    navigator.clipboard.writeText(
      `${
        window.location.protocol + "//" + window.location.host
      }/strumming-machine/${getEncodedPatternString(selectedStrummingPattern)}`
    );
  };

  const resetPattern = () => {
    openAlert({ message: "Strumming pattern has been reset.", severity: "success" });
    setSelectedStrummingPattern(blankStrummingPattern);
  };

  const emptyStrummingPattern = Object.keys(selectedStrummingPattern)
    .filter((key) => Number.isInteger(+key) && +key >= 0 && +key <= 7)
    .every((key) => selectedStrummingPattern[key] === null);

  return (
    <div className="strumming-machine-app">
      {currentUser && (
        <LibraryToggle
          showLibrary={showLibrary}
          setShowLibrary={setShowLibrary}
          showLessons={showLessons}
          setShowLessons={setShowLessons}
          showInstructions={showInstructions}
          setShowInstructions={setShowInstructions}
          width={width}
        />
      )}
      {!currentUser ? (
        <Welcome token={token} />
      ) : showLibrary ? (
        <Library
          setShowLibrary={setShowLibrary}
          setSelectedStrummingPattern={setSelectedStrummingPattern}
          strummingPatterns={strummingPatterns}
          openAlert={openAlert}
          createStrummingPatternItem={createStrummingPatternItem}
          deleteStrummingPatternItem={deleteStrummingPatternItem}
          width={width}
          savedStrummingPatterns={savedStrummingPatterns}
          openDialog={openDialog}
          savePattern={savePattern}
          copyPattern={copyPattern}
        />
      ) : showLessons ? (
        <Lessons width={width} />
      ) : showInstructions ? (
        <Instructions />
      ) : (
        <>
          {selectedStrummingPattern && (
            <StrummingPatternDiagram
              selectedStrummingPattern={selectedStrummingPattern}
              activeDivision={activeDivision}
              onDivisionClick={handleDivisionClick}
              showControls={false}
              openDialog={openDialog}
              copyPattern={copyPattern}
              resetPattern={resetPattern}
              savedStrummingPatterns={savedStrummingPatterns}
              savePattern={savePattern}
              inLibrary={false}
            />
          )}
          <button
            className={`play-button button button--primary`}
            onClick={playStrummingPattern}
            disabled={emptyStrummingPattern || !samplerLoaded}
          >
            {isPlaying ? (
              <>
                <PauseIcon /> Stop
              </>
            ) : (
              <>
                <PlayIcon /> Play
              </>
            )}
          </button>
          <Settings
            timeSignature={timeSignature}
            setTimeSignature={setTimeSignature}
            tempo={tempo}
            setTempo={setTempo}
            sound={sound}
            setSound={setSound}
            metronome={metronome}
            setMetronome={setMetronome}
            soundMute={soundMute}
            setSoundMute={setSoundMute}
            chord={chord}
            setChord={setChord}
            voiceCount={voiceCount}
            setVoiceCount={setVoiceCount}
          />
        </>
      )}
      <Dialog
        onConfirm={(pattern) => {
          !showLibrary || !pattern.saved
            ? toggleSavedPattern(pattern)
            : renameSavedPattern(pattern);
        }}
      />
      <Snackbar />
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  token: state.session.token,
  savedStrummingPatterns: savedStrummingPatternsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  createStrummingPatternItem: (strummingPattern, status) =>
    dispatch(createStrummingPatternItem(strummingPattern, status)),
  deleteStrummingPatternItem: (strummingPatternId, status) =>
    dispatch(deleteStrummingPatternItem(strummingPatternId, status)),
  renameStrummingPatternItem: (strummingPatternId, status) =>
    dispatch(renameStrummingPatternItem(strummingPatternId, status)),
  openAlert: (alert) => dispatch(openAlert(alert)),
  openDialog: (text) => dispatch(openDialog(text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withBootstrapSize(StrummingMachineApp));
