import React from "react";

const strategies = {
  observer: "observer",
  listener: "listener",
};

export function withInnerWidth(ChildComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = { width: this.width };
      this.strategy =
        typeof ResizeObserver == "undefined" ? strategies.listener : strategies.observer;
      this.observer = null;
    }

    get width() {
      // return document.documentElement.clientWidth;
      return window.innerWidth && document.documentElement.clientWidth
        ? Math.min(window.innerWidth, document.documentElement.clientWidth)
        : document.documentElement.clientWidth ||
            window.innerWidth ||
            document.getElementsByTagName("body")[0].clientWidth;
    }

    componentDidMount() {
      if (this.strategy === strategies.listener) {
        window.addEventListener("resize", this.setWidth);
      } else if (this.strategy === strategies.observer) {
        this.observer = new ResizeObserver(this.setWidth);
        this.observer.observe(document.body);
      }
    }

    componentWillUnmount() {
      if (this.strategy === strategies.listener) {
        window.removeEventListener("resize", this.setWidth);
      } else if (this.strategy === strategies.observer) {
        this.observer.unobserve(document.body);
      }
    }

    componentDidUpdate() {
      this.setWidth(); // the if statement in setWidth will prevent an infinite loop
    }

    setWidth = () => {
      if (this.state.width !== this.width) {
        this.setState({ width: this.width });
      }
    };

    render() {
      return <ChildComponent {...this.props} width={this.state.width} />;
    }
  };
}
