import React from "react";
import PropTypes from "prop-types";
import { CDN_URL } from "../components/shared/CdnUrl";

const BpmIntroduction = (props) => {
  return (
    <div className="bpm__container">
      <div className="bpm__left">
        <div className="bpm__heading">
          <h1 className="bold uppercase">Tap Tempo Tool</h1>
          <h2 className="bold">Calculate a Song Tempo in BPM!</h2>
          <p>
            Working out the BPM (Beats Per Minute) of any song is super easy now that you found this
            page!
          </p>
        </div>

        <div className="bpm__steps">
          <ul>
            <li>
              <span className="step-number">1</span>{" "}
              <span className="step-desc">Hit the Start The Tempo Calculator button below</span>
            </li>
            <li>
              <span className="step-number">2</span>{" "}
              <span className="step-desc">
                Then simply tap along with the beat of the song.
                <br />
                On a mobile/tablet device, tap the orange button!
                <br />
                On a computer, click the orange button or use the spacebar on your keyboard.
              </span>
            </li>
            <li>
              <span className="step-number">3</span>{" "}
              <span className="step-desc">
                The tempo of the song will be shown on screen as you tap. <br />
                It will change if you speed up or slow down - so try to keep it steady!
              </span>
            </li>
            <li>
              <span className="step-number">4</span>
              <span className="step-desc">
                The tempo resets after 3 seconds. <br />
                To start again, just wait 3 seconds or hit the end button. <br />
                Do it a couple of times for full accuracy to remove human error ;)
              </span>
            </li>
          </ul>
        </div>

        <button
          className="button button--primary button--inline"
          onClick={props.toggleActiveSession}
        >
          Start Tempo Calculator
        </button>
      </div>

      <div className="bpm__right">
        <span> You might also like...</span>
        <div className="bpm__suggestions">
          <a href="/guitar-lessons">
            <img
              src={`${CDN_URL}/redesign/bpm-tool/lessons-tile.png`}
              alt="Learn to Play Guitar on JustinGuitar"
            />
          </a>
          <a href="/songs">
            <img
              src={`${CDN_URL}/redesign/bpm-tool/learn-songs.png`}
              alt="Learn Awesome Songs on JustinGuitar"
            />
          </a>
          <a href="/products/time-trainer-metronome">
            <img
              src={`${CDN_URL}/redesign/bpm-tool/app-tile.png`}
              alt="JustinGuitar Time Trainer App"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

BpmIntroduction.propTypes = {
  toggleActiveSession: PropTypes.func.isRequired,
};

export default BpmIntroduction;
