import { createSelector } from "@reduxjs/toolkit";
import { completedLessonIds } from "../../components/shared/session/sessionSelectors";

export const parentGroupSelector = (state) => state.parentGroup.parentGroup;
export const currentUserSelector = (state) => state.parentGroup.session.currentUser;
export const paywall = (state) => state.parentGroup.paywall;

const orderedModules = createSelector(parentGroupSelector, (parentGroup) =>
  parentGroup.included
    .filter((i) => i.type === "group")
    .sort((a, b) => {
      let aIndex = parentGroup.data.attributes.groupOrder.indexOf(parseInt(a.id));
      let bIndex = parentGroup.data.attributes.groupOrder.indexOf(parseInt(b.id));
      return aIndex < bIndex ? -1 : aIndex > bIndex ? 1 : 0;
    })
);

const userHasPurchase = createSelector(paywall, currentUserSelector, (paywall, currentUser) => {
  return (
    paywall &&
    currentUser &&
    currentUser.included.filter(
      (i) =>
        i.type === "userPurchase" &&
        i.attributes.purchaseReference === paywall.data.attributes.reference &&
        i.attributes.isActive === true
    ).length > 0
  );
});

const paywallPreviewModules = createSelector(paywall, orderedModules, (paywall, orderedModules) =>
  paywall
    ? orderedModules.slice(0, paywall.data.attributes.numberOfPreviewableChildren)
    : orderedModules
);

export const lockedModules = createSelector(
  paywall,
  orderedModules,
  userHasPurchase,
  (paywall, orderedModules, userHasPurchase) => {
    return !paywall || userHasPurchase
      ? null
      : orderedModules.slice(
          paywall.data.attributes.numberOfPreviewableChildren,
          orderedModules.length
        );
  }
);

export const unlockedModules = createSelector(
  parentGroupSelector,
  paywall,
  orderedModules,
  userHasPurchase,
  paywallPreviewModules,
  (parentGroup, paywall, orderedModules, userHasPurchase, paywallPreviewModules) => {
    return !paywall || userHasPurchase ? orderedModules : paywallPreviewModules;
  }
);

const completedUserItems = createSelector(
  currentUserSelector,
  orderedModules,
  (currentUserSelector, orderedModules) => {
    if (currentUserSelector) {
      const moduleIds = orderedModules.map((m) => parseInt(m.id));
      const items = currentUserSelector.included.filter(
        (i) =>
          i.type === "userItem" &&
          i.attributes.itemStatus === "completed" &&
          i.attributes.itemableType === "Group" &&
          moduleIds.includes(i.attributes.itemableId)
      );
      return items.length > 0 ? items : null;
    }
  }
);

export const moduleCompletions = createSelector(
  completedLessonIds,
  orderedModules,
  (completedLessonIds, orderedModules) => {
    if (completedLessonIds) {
      let percentageMap = {};
      orderedModules.forEach((mod) => {
        const lessonIds = mod.attributes.lessons.map((l) => l.id);
        // Filter unique lesson ids, as they can be stored in more than one user_item,
        // because lessons have been moved between groups, but user_items have not been updated with that.
        const completed = [
          ...new Set(completedLessonIds.filter((lessonId) => lessonIds.includes(lessonId))),
        ];
        percentageMap[mod.id] = {
          percentage: completed.length / lessonIds.length,
          nestedItems: completed,
        };
      });
      return percentageMap;
    }
  }
);

const GRADIENTS_BY_REFERENCE = {
  BG1: "grade1",
  BG2: "grade2",
  BG3: "grade3",
  IF4: "grade4",
  IF5: "grade5",
  INM: "grade6",
  ADV: "grade7",
};

export const heroGradient = createSelector(parentGroupSelector, (parentGroup) => {
  return GRADIENTS_BY_REFERENCE[parentGroup.data.attributes.reference];
});

export const swooshBackgroundImg = createSelector(parentGroupSelector, (parentGroup) => {
  const img = parentGroup.data.attributes.heroFullImage;
  // image can be an empty string saved from admin CMS - give null instead of ''
  return img && img.length > 0 ? img : null;
});
