import React from "react";
import { connect } from "react-redux";
import ColorChords from "./ColorChords";
import HighlightChord from "./HighlightChord";
import FontAdjustTab from "./FontAdjustTab";
import Typeface from "./Typeface";
import DarkMode from "./DarkMode";
import FontWeight from "./FontWeight";
import { resetAll } from "../../store/songStore";

const SheetMusicSubMenu = ({ darkModeEnabled, chordHighlighted, standalonePage, resetAll }) => {
  return (
    <div className="song-sheet__secondary">
      <ul>
        <span className="uppercase submenu-heading">Text</span>
        {/* {standalonePage && <FontAdjustTab />} */}
        <FontAdjustTab />
        <FontWeight />
        <Typeface />
        <DarkMode />
        <hr />
        <span className="uppercase submenu-heading">Chords</span>
        <HighlightChord />
        <ColorChords />
        <hr />
        <button className="reset-all-btn" onClick={() => resetAll()}>
          Reset All
        </button>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({
  chordHighlighted: state.entity.chordHighlighted,
  darkMode: state.entity.darkMode,
  standalonePage: state.entity.standalonePage,
});

const mapDispatchToProps = (dispatch) => ({
  resetAll: () => dispatch(resetAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SheetMusicSubMenu);
