import React from "react";
import CompetitionFormButton from "./CompetitionFormButton";
import { BUTTON_TEXT } from "./Competition";
// when using Swoosh...
// import Swoosh from "../../components/shared/Swoosh";
// import { BootstrapSize } from "../../components/shared/bootstrap-helpers";
// import { CDN_URL } from "../../components/shared/CdnUrl";
// const heroImages = {
//   [BootstrapSize.smDevice]: `${CDN_URL}/images/pages/app-competition/app-competition-hero-lg.jpg`,
//   [BootstrapSize.xsDevice]: `${CDN_URL}/images/pages/app-competition/app-competition-hero-sm.jpg`,
// };

const CompetitionHeader = ({ user, handleSubmission, hasSubmitted, isOver }) => {
  return (
    <div className="competition__hero">
      <h1 className="uppercase header1 white">Win a Free Subscription</h1>

      <p>Win a free subscription to the JustinGuitar Lessons & Songs App. 🎉</p>

      <p>
        The updated version of my Beginner App has everything you need to learn to play guitar. In
        your pocket!{" "}
      </p>
      {!isOver && (
        <CompetitionFormButton
          user={user}
          text={BUTTON_TEXT}
          handleSubmission={handleSubmission}
          hasSubmitted={hasSubmitted}
        />
      )}
    </div>
  );
};

export default CompetitionHeader;
