import React from "react";

export function withHeroMargin(ChildComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { heroOffset: 10 };
      this.heroRef = React.createRef();
    }

    setHeroTop = () => {
      let heroOffset = 10;
      if (this.props.width > 576 && this.heroRef && this.heroRef.current) {
        heroOffset = this.heroRef.current.offsetHeight * 0.1 * -1;
      }

      this.setState({ heroOffset });
    };

    componentDidMount() {
      this.setHeroTop();
      window.addEventListener("resize", this.setHeroTop);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.setHeroTop);
    }

    render() {
      const { heroOffset } = this.state;
      return <ChildComponent {...this.props} heroRef={this.heroRef} heroOffset={heroOffset} />;
    }
  };
}
