import React from "react";
import AboutSwoosh from "./AboutSwoosh";
import Teacher from "./Teacher";
import Musician from "./Musician";

export default class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "teacher",
    };
  }

  changePage = (pageName) => {
    if (pageName === "teacher" || pageName === "musician") {
      this.setState({ currentPage: pageName });
    }
  };

  render() {
    return (
      <div className="about">
        <AboutSwoosh changePage={this.changePage} currentPage={this.state.currentPage} />
        {this.state.currentPage === "musician" ? (
          <Musician />
        ) : (
          <Teacher publications={this.props.publications} />
        )}
      </div>
    );
  }
}
