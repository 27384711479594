import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  userNotesWithPracticeExcluded,
  songNotes,
  lessonNotes,
  moduleNotes,
} from "../../components/shared/session/sessionSelectors";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import { clearAlert, openAlert, openDialog } from "../../components/shared/ui/uiSlice";
import { addOrUpdateUserNote, deleteUserNote } from "../../components/shared/session/sessionSlice";
import Dialog from "../../components/shared/ui/Dialog";
import Snackbar from "../../components/shared/ui/Snackbar";
import { setActiveTab } from "../store/dashboardStore";
import { PromoItem } from "../../justins-corner/PromoItem";
import { withBootstrapSize } from "../../components/shared/WithBootstrapSize";

const BackToDashboard = ({ setDashboardTab, hasText }) => {
  return (
    <button className="back-btn" onClick={() => setDashboardTab("Dashboard")}>
      <i className="icon icon-arrow-left"></i> {hasText ? "Dashboard" : ""}
    </button>
  );
};

const AppleIcon = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.4298 31.5538C45.4036 27.0836 47.429 23.7145 51.519 21.2304C49.2315 17.9531 45.7707 16.1506 41.2087 15.8032C36.8892 15.4623 32.1634 18.3201 30.433 18.3201C28.6043 18.3201 24.4225 15.9212 21.1321 15.9212C14.3416 16.026 7.125 21.3352 7.125 32.1371C7.125 35.3292 7.70836 38.6261 8.87507 42.0214C10.4351 46.4916 16.0589 57.4443 21.9252 57.2673C24.9927 57.1952 27.1623 55.0912 31.154 55.0912C35.0277 55.0912 37.0334 57.2673 40.4549 57.2673C46.3737 57.1821 51.46 47.2257 52.9413 42.7424C45.0038 38.9998 45.4298 31.7832 45.4298 31.5538ZM38.541 11.5624C41.8641 7.61654 41.5626 4.02465 41.4643 2.7334C38.5279 2.90382 35.1326 4.73254 33.199 6.98075C31.0688 9.39283 29.8169 12.3751 30.0856 15.7376C33.258 15.9802 36.1551 14.3481 38.541 11.5624Z"
        fill="black"
      />
    </svg>
  );
};

const StarIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12651_72671)">
        <path
          d="M15.9993 23.027L24.2393 28.0003L22.0527 18.627L29.3327 12.3203L19.746 11.507L15.9993 2.66699L12.2527 11.507L2.66602 12.3203L9.94602 18.627L7.75935 28.0003L15.9993 23.027Z"
          fill="url(#paint0_linear_12651_72671)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_12651_72671"
          x1="2.66602"
          y1="2.66699"
          x2="21.8371"
          y2="32.7734"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9ED32" />
          <stop offset="1" stopColor="#FBB040" />
        </linearGradient>
        <clipPath id="clip0_12651_72671">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const HalfStarIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12651_72679)">
        <path
          d="M29.3327 12.3203L19.746 11.4937L15.9993 2.66699L12.2527 11.507L2.66602 12.3203L9.94602 18.627L7.75935 28.0003L15.9993 23.027L24.2393 28.0003L22.066 18.627L29.3327 12.3203ZM15.9993 20.5337V8.13366L18.2793 13.5203L24.1194 14.027L19.6927 17.867L21.026 23.5737L15.9993 20.5337Z"
          fill="url(#paint0_linear_12651_72679)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_12651_72679"
          x1="2.66602"
          y1="2.66699"
          x2="21.8371"
          y2="32.7734"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9ED32" />
          <stop offset="1" stopColor="#FBB040" />
        </linearGradient>
        <clipPath id="clip0_12651_72679">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const PlayIcon = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12651_72663)">
        <path
          d="M27.9382 28.8278L6.20508 51.6739C6.87913 54.1607 9.16683 55.9802 11.8835 55.9802C12.9661 55.9802 13.9873 55.6972 14.8657 55.1715L14.9269 55.1311L39.3972 41.1606L27.9382 28.8076V28.8278Z"
          fill="#EA4335"
        />
        <path
          d="M49.9557 24.9459H49.9353L39.3751 18.8604L27.4668 29.3534L39.4159 41.1807L49.9353 35.1761C51.7736 34.1854 53.0196 32.2647 53.0196 30.061C53.0196 27.8572 51.794 25.9366 49.9557 24.9661V24.9459Z"
          fill="#FBBC04"
        />
        <path
          d="M6.20426 8.32617C6.0817 8.8114 6 9.29662 6 9.82228V50.1971C6 50.7228 6.06128 51.208 6.20426 51.6932L28.6932 29.4335L6.20426 8.32617Z"
          fill="#4285F4"
        />
        <path
          d="M28.1016 30L39.3563 18.86L14.9065 4.82893C14.0282 4.30327 12.9865 4 11.8631 4C9.16683 4 6.85871 5.83981 6.20508 8.30638L28.1221 29.9798L28.1016 30Z"
          fill="#34A853"
        />
      </g>
      <defs>
        <clipPath id="clip0_12651_72663">
          <rect width="47" height="52" fill="white" transform="translate(6 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const AppTab = ({ setDashboardTab, bootstrapSize, width }) => {
  const shouldBeHorizontal = ["smDevice", "mdDevice", "lgDevice"].includes(bootstrapSize);

  return (
    <div className="dashboard-app">
      <div className="dashboard-app__content">
        <BackToDashboard setDashboardTab={setDashboardTab} hasText={true} />
        <h1 className="dashboard__heading smaller">
          Lessons & Songs App
          <BackToDashboard setDashboardTab={setDashboardTab} hasText={false} />
        </h1>
        <div className="content-container">
          <h2>Learn Through The Songs You Love! 🎸</h2>
          <div className="info">
            <p>
              The Beatles, Bob Marley, Jimmy Hendrix, Ed Sheeran, or Tracy Chapman - whatever your
              taste in music, you can easily learn how to play your favorite songs!
            </p>
            <p>My Songs & Lessons App is perfect to help you:</p>
            <ul>
              <li>✓ Optimize your learning journey (Grades 1, 2, and 3 of my Beginner Course)</li>
              <li>✓ Learn on the go with interactive exercises and backing tracks</li>
              <li>✓ Easy to use guitar tuner</li>
              <li>✓ Track your progress all the way</li>
            </ul>
          </div>
          <div className="ctas">
            <a
              href="https://go.onelink.me/3065804798/dashboard"
              target="_blank"
              className="button button--teal"
            >
              manage app subscription
            </a>
            <a
              href="https://go.onelink.me/3065804798/dashboard"
              target="_blank"
              className="button button--primary"
            >
              download the app
            </a>
          </div>
          <div className="ratings">
            <div className="apple">
              <AppleIcon />
              <div className="stars">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
              <p>4.9 • 14K ratings{width > 576 ? " " : <br />}(App Store)</p>
            </div>
            <div className="google">
              <PlayIcon />
              <div className="stars">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <HalfStarIcon />
              </div>
              <p>4.7 • 20K ratings{width > 576 ? " " : <br />}(Google Play)</p>
            </div>
          </div>
          <p className="account-info">
            📲{" "}
            <strong>
              My app is available for mobile devices and doesn't sync with the website.
            </strong>{" "}
            Please have separate accounts and subscriptions if you're using both platforms!
          </p>
          <div className="support">
            <h2>App Support & Feedback 💬</h2>
            <p>
              💡 <strong>Have questions or need help?</strong>
              {width <= 992 ? <br /> : " "}Contact{" "}
              <a href="mailto:justin.feedback@musopia.net" target="_blank">
                justin.feedback@musopia.net
              </a>{" "}
              for all your Lessons & Songs App inquiries or read the{" "}
              <a href="/faq/lessons-songs-app" className="info">
                FAQ
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <div className="dashboard-app__sidebar">
        <PromoItem
          orientation={shouldBeHorizontal ? "landscape" : "portrait"}
          target="dashboard_app"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userNotes: userNotesWithPracticeExcluded(state),
  songNotes: songNotes(state),
  lessonNotes: lessonNotes(state),
  moduleNotes: moduleNotes(state),
  user: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  openDialog: (text) => dispatch(openDialog(text)),
  openAlert: (alert) => dispatch(openAlert(alert)),
  clearAlert: () => dispatch(clearAlert()),
  addOrUpdateUserNote: (note) => dispatch(addOrUpdateUserNote(note)),
  deleteUserNote: (note) => dispatch(deleteUserNote(note)),
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
  setDashboardTab: (tab) => dispatch(setActiveTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withBootstrapSize(AppTab));
