import React, { useState } from "react";

const GUITAR_TYPES = {
  acoustic: "acoustic",
  electric: "electric",
};

const GuitarButton = ({ activeType, setActiveType }) => (
  <div className="guitar-type-buttons">
    <button
      className={activeType === GUITAR_TYPES.electric ? "active" : ""}
      onClick={() => setActiveType(GUITAR_TYPES.electric)}
    >
      Electric Guitars
    </button>
    <span className="spacer"></span>
    <button
      className={activeType === GUITAR_TYPES.acoustic ? "active" : ""}
      onClick={() => setActiveType(GUITAR_TYPES.acoustic)}
    >
      Acoustic Guitars
    </button>
  </div>
);

const ElectricGuitarDetails = ({ activeType }) => {
  if (activeType != GUITAR_TYPES.electric) return null;

  return (
    <>
      <p>
        We dedicate part of our profit to acquiring electric guitars and distributing them to
        students who need them the most!
      </p>

      <p>
        We prioritize buying second-hand electric guitars whenever we can because, sadly, many
        instruments are left unused. These are a great choice for this project because they're
        eco-friendly and affordable, allowing us to help more students. It's a win-win! Before
        delivering these guitars, we ensure they are clean, well set up, and have new strings for
        easy playability.
      </p>

      <p>
        However, we'll buy guitars from a local shop for winners outside the United Kingdom, where
        buying second-hand guitars isn't always possible due to the necessary set up prior to
        shipping.
      </p>
    </>
  );
};

const AcousticGuitarDetails = ({ activeType }) => {
  if (activeType != GUITAR_TYPES.acoustic) return null;

  return (
    <>
      <p>
        <a href="https://www.andertons.co.uk" target="_blank">
          Andertons
        </a>{" "}
        generously provides us with 100 new acoustic guitars annually, additionally covering
        shipping and warranty fees! We chose the EastCoast G1 model since it’s affordable and ideal
        for beginners. :)
      </p>
      <p>
        The G1 features a medium-bodied design and delivers a dynamic sound. This great instrument
        naturally produces bright, crisp tones. Artists like Ed Sheeran often use this guitar shape
        as it accompanies vocals nicely!
      </p>
      <p>
        For winners outside the United Kingdom, we'll purchase similar guitars from a local shop.
        This is made possible through part of the proceeds we receive from our donations and
        purchases.
      </p>
    </>
  );
};

const AboutTheGuitars = () => {
  const [activeType, setActiveType] = useState(GUITAR_TYPES.electric);
  return (
    <div className="section about-the-guitars">
      <h2 className="title">About the guitars</h2>
      <div className="content">
        <div className="description">
          <GuitarButton activeType={activeType} setActiveType={setActiveType} />

          <div className="text">
            <ElectricGuitarDetails activeType={activeType} />
            <AcousticGuitarDetails activeType={activeType} />
          </div>
        </div>
        <div className={`bg ${activeType}`}></div>
      </div>
    </div>
  );
};

export default AboutTheGuitars;
