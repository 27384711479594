import * as yup from "yup";

export interface WaitingListSignup {
  firstName: string;
  lastName: string;
  email: string;
}

export const waitingListSignupSchema = yup.object({
  firstName: yup.string().min(1).required(),
  lastName: yup.string().min(1).required(),
  email: yup.string().email().required(),
});
