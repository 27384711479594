import React from "react";

import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { jgDenimColors } from "../../../jg-material/theme/jg-denim-colors";
import { YourStayDetails } from "../interfaces";
import { UseFormik } from "../interfaces/formik-helpers";
import { RegisterFormPaper } from "../shared/RegisterFormPaper";
import { TransportMethod } from "../interfaces/enum/transport-method";

export const YourStayForm = ({ formik }: { formik: UseFormik<Partial<YourStayDetails>> }) => (
  <>
    <RegisterFormPaper>
      <Typography variant="h4" sx={{ mb: jgDenimTheme.spacing(2) }}>
        Are you coming with a partner?
      </Typography>

      <TextField
        name={"withPartner"}
        required
        value={formik.values.withPartner}
        error={formik.touched.withPartner && Boolean(formik.errors.withPartner)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        fullWidth={true}
        margin="dense"
        multiline
        label={"Partner Info"}
      />
    </RegisterFormPaper>

    <RegisterFormPaper>
      <Typography variant="h4" sx={{ mb: jgDenimTheme.spacing(2) }}>
        Dietary requirements or special requests
      </Typography>

      <Typography variant="body1">
        If you've got specific dietary needs, let us know and we'll make the hotel aware of your
        requirements, but it's best to speak to the restaurant staff when you arrive, too.
      </Typography>

      <TextField
        name={"specialRequests"}
        required
        value={formik.values.specialRequests}
        error={formik.touched.specialRequests && Boolean(formik.errors.specialRequests)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        fullWidth={true}
        margin="dense"
        multiline
        label={"Special Requests"}
      />
    </RegisterFormPaper>

    <RegisterFormPaper>
      <Typography variant="h4" sx={{ mb: jgDenimTheme.spacing(2) }}>
        Transport and arrival time
      </Typography>

      <Typography variant="body1">How will you reach us?</Typography>

      <FormControl
        margin="dense"
        fullWidth={true}
        error={formik.touched.transportType && Boolean(formik.errors.transportType)}
        required={true}
      >
        <InputLabel id="transportType">How will your reach us?</InputLabel>
        <Select
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          labelId="transportType"
          value={formik.values.transportType}
          name={"transportType"}
          id={"transportType"}
          label="How will your reach us?"
        >
          {Object.entries(TransportMethod).map(([value, label]) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography variant="body1" marginTop={3}>
        Add your estimated arrival time
        <Typography
          component="span"
          fontSize="smaller"
          variant="body1"
          color={jgDenimColors.denim.light}
        >
          (optional)
        </Typography>
      </Typography>

      <TextField
        name={"arrivalTime"}
        value={formik.values.arrivalTime}
        error={formik.touched.arrivalTime && Boolean(formik.errors.arrivalTime)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        fullWidth={true}
        margin="dense"
        label={"Arrival Time"}
      />
    </RegisterFormPaper>
  </>
);
