import React from "react";
import { connect } from "react-redux";
import Purchase from "./Purchases/Purchase";
import Loading from "../../components/shared/Loading";
import { CDN_URL } from "../../components/shared/CdnUrl";
import Subscription from "./Subscriptions/Subscription";
import Snackbar from "../../components/shared/ui/Snackbar";
import PmtEnrollment from "./PmtEnrollment";

const Purchases = ({ user, pageParts, isLoading, tabsRefactorEnabled }) => {
  // Taken from users_controller#purchases > @items == current_user.purchases_for_dashboard
  const { purchasesForDashboard, enrollments } = user.data.attributes;

  const { pmtSubscription, bluesImmersionSubscription, tabsSubscription } = user.data.attributes;

  return (
    <div className="account__purchases" style={{ position: "relative" }}>
      {isLoading ? <Loading /> : null}

      {purchasesForDashboard.length || enrollments.length || tabsSubscription || pmtSubscription ? (
        <div className="purchase-list">
          {tabsRefactorEnabled && tabsSubscription && (
            <Subscription subscription={tabsSubscription} />
          )}

          {pmtSubscription && <Subscription subscription={pmtSubscription} />}
          {bluesImmersionSubscription && <Subscription subscription={bluesImmersionSubscription} />}

          {purchasesForDashboard.map((purchase, idx) => (
            <Purchase purchase={purchase} key={idx} />
          ))}
          {!pmtSubscription && <PmtEnrollment enrollments={enrollments} />}
          <Snackbar />
        </div>
      ) : (
        <p className="bold">No purchases yet.</p>
      )}
      <div className="disclaimer disclaimer--purchases">
        <p>
          🚨{" "}
          <strong>
            Cancel subscriptions to any JustinGuitar Apps (iOS and Android) from your mobile decice:{" "}
            <a target="_blank" href="https://support.apple.com/en-gb/HT202039">
              cancel in The App Store
            </a>{" "}
            or{" "}
            <a
              target="_blank"
              href="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en"
            >
              cancel in Google Play
            </a>
            .
          </strong>
        </p>
        <p>
          ℹ️ Please note that the JustinGuitar Lessons & Songs App is only available for mobile
          devices and doesn't sync with this website. For App support and feedback, contact
          justin.feedback@musopia.net. If you need assistance accessing any other JustinGuitar
          product, please get in touch with us at hello@justinguitar.com.
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.ui.isLoading,
  tabsRefactorEnabled: state.entity.dashboard.tabsRefactorEnabled,
  ...ownProps,
});

export default connect(mapStateToProps)(Purchases);
