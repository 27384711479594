import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Box, Button, Checkbox, Grid, styled, TextField } from "@mui/material";
import { StripeInput } from "./StripeInput";
import { createDeposit } from "./deposit-api";
import Recaptcha from "react-recaptcha";
import loadScript from "../../../components/shared/loadScript";
import Snackbar from "../../../components/shared/Snackbar";
import Loading from "../../../components/shared/Loading";
import { RECAPTCHA_KEY, STRIPE_SALES_PUBLISHABLE_KEY } from "../../../payments/PaymentConstants";
import { useSelector } from "react-redux";
import { workshopSelectors } from "../store/workshops.slice";
import { TermsAndConditionsLink } from "../register/TermsAndConditionsLink";
import { workshopToPurchaseReference, workshopToRemainingFeeReference } from "./helpers";

// cancel global styles -- this can go away once we switch all stripe input to Mui
const StripeTextField = styled(TextField)(() => ({
  ".StripeElement": {
    borderRadius: 0,
    boxShadow: "none",
  },
}));

const _StripeForm = ({ onComplete, currentUser }: { onComplete: () => void; currentUser: any }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [customerName, setCustomerName] = React.useState<string | undefined>();
  const [recaptchaToken, setRecaptchaToken] = React.useState<string | undefined>();
  const [error, setError] = React.useState<boolean | string>(false);
  const [loading, setLoading] = React.useState(false);
  const [agreeToTC, setAgreeToTC] = React.useState(false);
  const workshop = useSelector(workshopSelectors.selectCurrentWorkshop);

  React.useEffect(() => {
    loadScript("https://www.google.com/recaptcha/api.js");
  }, []);

  React.useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 10000);
    }
  }, [error]);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (elements == null || stripe == null) {
      setError(true);
      return;
    }

    const card = elements.getElement(CardNumberElement);

    if (card == null) {
      setError(true);
      return;
    }

    const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        name: customerName,
      },
    });

    const { error: cardTokenError, token: cardToken } = await stripe.createToken(card);

    if (
      paymentError != null ||
      paymentMethod == null ||
      cardTokenError != null ||
      cardToken == null ||
      recaptchaToken == null
    ) {
      setError(true);
      return;
    }

    if (workshop) {
      const purchaseReference = workshopToPurchaseReference(workshop);
      const userPaidDeposit = (currentUser?.data.attributes.purchasesForDashboard || []).some(
        (purchase) => purchase.data.attributes.purchaseReference === purchaseReference
      );

      createDeposit(
        paymentMethod,
        cardToken,
        recaptchaToken,
        stripe,
        !userPaidDeposit
          ? workshopToPurchaseReference(workshop!)
          : workshopToRemainingFeeReference(workshop),
        workshop,
        userPaidDeposit
      )
        .then(() => {
          setLoading(false);
          onComplete();
        })
        .catch((e) => {
          setLoading(false);
          setError(e.message || true);
        });
    }
  };

  return (
    <div>
      {loading && <Loading />}
      <TextField
        label="Name on card"
        name="ccname"
        required
        fullWidth
        margin="dense"
        value={customerName}
        onChange={(e) => setCustomerName(e.target.value)}
      />
      <StripeTextField
        label="Card Number"
        name="ccnumber"
        required
        fullWidth
        margin="dense"
        InputProps={{
          inputComponent: StripeInput,
          inputProps: {
            component: CardNumberElement,
          },
        }}
        InputLabelProps={{ shrink: true }} // stripe puts example cc number as placeholder so we need to stay out of the way
      />

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <StripeTextField
            label="Expiration Date"
            name="ccexp"
            required
            fullWidth
            margin="dense"
            InputProps={{
              inputProps: {
                component: CardExpiryElement,
              },
              inputComponent: StripeInput,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <StripeTextField
            label="CVC"
            name="cvc"
            variant="outlined"
            required
            fullWidth
            margin="dense"
            InputProps={{
              inputProps: {
                component: CardCvcElement,
              },
              inputComponent: StripeInput,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Box
        display="flex"
        flexDirection={"column"}
        justifyContent="flex-end"
        alignItems={"flex-end"}
        my={2}
      >
        <Recaptcha
          render="explicit"
          sitekey={RECAPTCHA_KEY}
          onloadCallback={() => {
            /*
                no-op callback. why? due to a silly limitation of the react-recaptcha library,
                this cb must be defined for verifyCallback to work...
                https://github.com/appleboy/react-recaptcha/issues/242#issuecomment-540733254
              */
          }}
          verifyCallback={setRecaptchaToken}
        />
      </Box>
      <Box
        display="flex"
        // flexDirection={"column"}
        justifyContent="flex-end"
        alignItems={"center"}
        mt={2}
      >
        <TermsAndConditionsLink inPaymentForm />
        <Checkbox
          onChange={(e) => setAgreeToTC(!agreeToTC)}
          checked={agreeToTC}
          value={agreeToTC}
          color="success"
        />
        <Button onClick={handleSubmit} disabled={!agreeToTC}>
          Complete Booking
        </Button>
      </Box>

      {error && (
        <Snackbar
          message={`There was an issue processing your card${
            typeof error === "string" ? `: \n${error}` : "."
          }`}
          severity={"error"}
          durationMs={60_000}
        />
      )}
    </div>
  );
};

export const StripeForm = ({
  onComplete,
  currentUser,
}: {
  onComplete: () => void;
  currentUser: any;
}) => (
  <Elements stripe={loadStripe(STRIPE_SALES_PUBLISHABLE_KEY)}>
    <_StripeForm onComplete={onComplete} currentUser={currentUser} />
  </Elements>
);
