import React from "react";
import CtaButton from "./CtaButton";
import { OrangeLink } from "../../jg-material/theme/components/styled/Links";

const TeacherTrainingContent = () => {
  const redirectToTeachers = () => {
    window.location.href = "/teachers";
  };

  return (
    <div className="teacher-training__content">
      <h4 className="bold header4">Hey there, guitar friend!</h4>

      <p>
        Teaching guitar isn't just a job for me—it's my lifelong passion. With over 35 years of
        experience and 15 years of studying how people learn, I've got a ton of valuable insights to
        share with you.
      </p>
      <p>
        Join JustinGuitar Teacher Training to tap into everything I've learned since launching
        JustinGuitar.com. We'll learn from growing your teaching business to building strong
        connections with your students. By the end of the program, you'll feel confident in your
        teaching skills and access complete teaching materials, but also know how to create unique
        lesson plans. Plus, you might earn a JustinGuitar Certification and become an{" "}
        <OrangeLink onClick={redirectToTeachers}>Approved Teacher</OrangeLink>!
      </p>
      <p>
        But here's the deal: spots for this course will be limited. If you want to be the first to
        know when registration opens and get exclusive pricing, sign up below.
      </p>

      <CtaButton text="Claim Your Spot" />

      <p className="ps">
        *JustinGuitar Teacher Training is expected to take place in 2025 and beyond.{" "}
      </p>
    </div>
  );
};

export default TeacherTrainingContent;
