import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { ACOUSTIC_GUITARS_GIVEN_AWAY, ELECTRIC_GUITARS_GIVEN_AWAY } from "./consts";

const SECTIONS = {
  acoustic: "acoustic",
  electric: "electric",
};

const GIVEN_AWAY = {
  [SECTIONS.acoustic]: ACOUSTIC_GUITARS_GIVEN_AWAY,
  [SECTIONS.electric]: ELECTRIC_GUITARS_GIVEN_AWAY,
};

const SectionSelect = ({ activeSection, setActiveSection }) => (
  <Box className="guitar-type-buttons">
    <button
      className={activeSection === SECTIONS.electric ? "active" : ""}
      onClick={() => setActiveSection(SECTIONS.electric)}
    >
      Electric Guitars
    </button>
    <span className="spacer"></span>
    <button
      className={activeSection === SECTIONS.acoustic ? "active" : ""}
      onClick={() => setActiveSection(SECTIONS.acoustic)}
    >
      Acoustic Guitars
    </button>
  </Box>
);

const GuitarsGivenAway = () => {
  const [activeSection, setActiveSection] = useState(SECTIONS.electric);

  return (
    <Box textAlign={"center"} mb={5}>
      <Typography variant="h3" mb={5}>
        Guitars Donated So Far
      </Typography>

      <SectionSelect activeSection={activeSection} setActiveSection={setActiveSection} />
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", flexDirection: "column", maxHeight: "500px", overflowY: "scroll" }}
      >
        <Grid item>
          {GIVEN_AWAY[activeSection].map((entry, idx) => (
            <Typography
              variant="h8"
              key={`giveaway${idx}`}
              sx={{ fontWeight: "semibold", textTransform: "none" }}
            >
              {entry}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default GuitarsGivenAway;
