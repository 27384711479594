import React from "react";
import { PencilIcon, YouTubeIcon } from "../StrummingMachine/StrummingMachineApp/Icons";
import LazyLoad from "react-lazyload";
import LiteYoutube from "../../components/shared/LiteYoutube";
import { CurrentUserBadge } from "../../components/header/CurrentUserBadge";
import { getYoutubeId } from "./utils";
import { Tooltip } from "@mui/material";

const STATUSES = {
  inReview: "in_review",
  approved: "approved",
};

const PerformanceCard = ({ performance, setPerformanceToPlay, edit, currentUser }) => {
  const { songId, songTitle, status, thumbnails, videoUrl, userAttributes } =
    performance.attributes;
  return (
    <div className="performance-card">
      {status === STATUSES.inReview && <div className="pending-overlay"></div>}

      {status !== STATUSES.inReview && (
        <Tooltip arrow enterTouchDelay={0} title="View performance">
          <div className="play-overlay">
            <button onClick={() => setPerformanceToPlay(performance)}>
              <YouTubeIcon />
            </button>
          </div>
        </Tooltip>
      )}

      <div className="thumb">
        <LazyLoad width={240} height={134}>
          <LiteYoutube
            url={getYoutubeId(videoUrl)}
            thumbnailUrl={thumbnails && thumbnails.standard}
          />
        </LazyLoad>
      </div>
      <div className="info">
        <div>
          <div className="image">
            <CurrentUserBadge imgPath={userAttributes.imageUid} size="xsmall" showName={false} />
          </div>
          <span className="name">{userAttributes.username}</span>
          {status === STATUSES.inReview && <span className="status">pending</span>}
        </div>
        {currentUser &&
          (currentUser?.data?.attributes.id === userAttributes.id ||
            currentUser.id === userAttributes.id) &&
          edit && (
            <button className="edit" onClick={() => edit(performance)}>
              <PencilIcon />
            </button>
          )}
        {songTitle && (
          <a href={`/songs/${songId}`} className="song-title">
            {songTitle}
          </a>
        )}
      </div>
    </div>
  );
};

export default PerformanceCard;
