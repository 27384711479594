import ReactOnRails from "react-on-rails";
import { configureStore, createSlice, combineReducers } from "@reduxjs/toolkit";
import { ui } from "../../components/shared/ui/uiIndex";

function parentGroupStore(props, railsContext) {
  const initialState = {
    lockedModules: [],
    unlockedModules: [],
    currentUser: props.currentUser,
    parentGroup: props.parentGroup,
    releasedLessonIds: props.releasedLessonIds,
    paywall: props.paywall,
    token: props.session.token,
    session: props.session,
    promoItems: props.promoItems,
    enrolledCount: props.enrolledCount,
    faqs: props.faqs,
  };

  const parentGroupSlice = createSlice({
    name: "parentGroupStore",
    initialState,
  });

  const reducer = combineReducers({
    parentGroup: parentGroupSlice.reducer,
    ui: ui.reducer,
  });

  return configureStore({ reducer });
}

ReactOnRails.registerStore({
  parentGroupStore,
});
