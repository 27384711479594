import { CDN_URL } from "../../components/shared/CdnUrl";


export const TESTIMONIALS = [
    {
      name: "Tim",
      location: null,
      teacher: "Richard’s Student",
      body: `”Richard is amazingly knowledgeable about ‘all things guitar’, patient, and very observant. He has an excellent Zoom setup that enables him to display multiple different angles of his guitar and closeups as well. Richard takes a person-centered and holistic approach to his teaching, all wrapped up with a song-based methodology. I could not have asked for a better teacher!”`,
      headerImage: `${CDN_URL}/images/pages/teachers/testimonial-tim-v2.jpg`,
    },
    {
      name: "Nick",
      location: null,
      teacher: "Richard’s Student",
      body: `”Richard is patient, nonjudgmental, and a downright good teacher. He helps me out in a way I can understand. The lessons are tailored to my requirements, and he gives me input on what to learn. His feedback, both during and after lessons, is second to none. I have progressed under Richard in a way I could never do on my own. I cannot recommend Richard enough, and he is also a downright top bloke!”`,
      headerImage: `${CDN_URL}/images/pages/teachers/testimonial-nick-v2.jpg`,
    },
    {
      name: "Lian",
      location: null,
      teacher: "Richard’s Student",
      body: `”I am really pleased with my time with Richard so far. Having regular classes helps keep me motivated and stay on track, so when I miss a few days because life gets in the way, I do not drop practicing completely, as I did in the past. Richard can tailor our time together to my experience level and interests, and I have been making a lot of progress in a short time as a result. I also enjoy our sessions because Richard is very kind and respectful, communicates clearly, and has a lot of patience and a relaxing vibe.”`,
      headerImage: `${CDN_URL}/images/pages/teachers/lian.jpeg`,
    },
    {
      name: "Edward",
      location: null,
      teacher: "Jed’s Student",
      body: `”My lessons with Jed are going very well! He is professional and knowledgeable and answers all my questions. He is inspiring, and I could not be happier with his help! I'm enjoying learning to play with your online classes, and the combination of the two is no doubt a win, win for me!”`,
      headerImage: `${CDN_URL}/images/pages/teachers/edward.png`,
    },
    {
      name: "Kevin",
      location: null,
      teacher: "Richard’s Student",
      body: `”Richard is a wonderful guitar teacher with whom I've been fortunate enough to take lessons since early 2023. He is attentive to the things I want to learn and provides excellent feedback after each lesson. His setup for Zoom classes is seamless for delivery, especially great since I'm in Washington State and he is in England. As a kid, I dreaded weekly lessons. With Richard, I look forward to learning. A great addition to learning with Justin! Every day begins with coffee and the guitar now!”`,
      headerImage: `${CDN_URL}/images/pages/teachers/kevin.jpeg`,
      backgroundPosition: "top",
    },
    {
      name: "Tim",
      location: null,
      teacher: "Jed’s Student",
      body: `”Jed's approach to teaching guitar is just so clever; each lesson is so well-planned! Jed is really helping my understanding of the guitar rather than just playing by numbers like I used to do with tabs. I feel that I am learning how songs and solos are put together and understanding the importance of the different scales. Every lesson is fun and enjoyable as well as educational. I could not recommend Jed enough and Justin's site!”`,
      headerImage: `${CDN_URL}/images/pages/teachers/tim.jpeg`,
    },
    {
      name: "Mark",
      location: "US",
      teacher: "Jed’s Student",
      body: `”I started with Jed about a month ago. I am 64 and started my guitar journey with JustinGuitar during Covid. Jed is very organized, having created a practice routine for me, and has already made small adjustments to my technique, which are improving my skills tremendously. He has a great Zoom setup, with multiple cameras to demonstrate technique and to record key pieces of lessons! However, what I really like about Jed is his ability to make my journey fun and rewarding. He has the unique ability to push me while keeping me relaxed. He blends teacher, coach, and counselor into his approach. He is a pleasure to work with. I feel very fortunate to have found Jed!”`,
      headerImage: `${CDN_URL}/images/pages/teachers/mark.jpeg`,
    },
    {
      name: "Manuel & Jake",
      location: "Australia",
      teacher: "Jed’s Students",
      body: `“Through the use of specialist music microphones and multiple cameras, Jed is able to ensure sound quality is always perfect and shows close-up views of both left and right hands. All of this adds to make the online lessons far more convenient than traveling for in person lessons.”`,
      headerImage: `${CDN_URL}/images/pages/teachers/manuel.png`,
    },
    {
      name: "Gilles",
      location: "Spain",
      teacher: "Richard’s Student",
      body: `“It would have been impossible for me only 7 months ago to imagine that I would be playing fingerstyle guitar by the seaside! Richard’s approach perfectly complement JustinGuitar and have made my guitar journey very enjoyable. Never too late to start!”`,
      headerImage: `${CDN_URL}/images/pages/teachers/gilles.png`,
    },
    {
      name: "Arthur",
      location: null,
      teacher: "Lieven’s Student",
      body: `“With Lieven's help, I have learned to play a song that I have wanted to play for years, and in about 6 weeks. Without Justin and all he has brought to the beginners, and without Lieven and his guidance, I would still be floundering.”`,
      headerImage: `${CDN_URL}/images/pages/teachers/arthur.png`,
    },
    {
      name: "Mark",
      location: null,
      teacher: "Richard’s Student",
      body: `“I immediately clicked with Richard. He listens to where I want to go and provides drills and exercises to help me get there. He spots minor (and major) changes I can make that have had a dramatic impact on my ability and confidence.”`,
      headerImage: `${CDN_URL}/images/pages/teachers/mark.png`,
    },
    {
      name: "Ray",
      location: "UK",
      teacher: "Jed’s Student",
      body: `“My 16 year old daughter has been loving her online lessons with Jed. She's been progressing very quickly since starting the lessons and really enjoys them. It's a great way for her to learn - and I can pick up some tips along the way!”`,
      headerImage: `${CDN_URL}/images/pages/teachers/ray.png`,
    },
    {
      name: "Andrew",
      location: null,
      teacher: "Richard’s Student",
      body: `“Richard is well-prepared, thoughtful, provides practice routines and resources and shows concern in trying to meet my needs and musical interests.”`,
      headerImage: `${CDN_URL}/images/pages/teachers/andrew.png`,
    },
    {
      name: "Davy",
      location: "UK",
      teacher: "Lee's Student",
      body: `“Mr. Mead-Batten is everything you'd want in a teacher: patient, thoughtful, personable, knowledgeable, and on top of that, he's even been sending me personalized videos after each - his generous nature is my gain! I really hardly ever review anything, but I actually couldn't wait to leave this testimonial.”`,
      headerImage: `${CDN_URL}/images/pages/teachers/davy2.jpeg`,
    },
    {
      name: "Paul",
      location: "UK",
      teacher: "Lee’s Student",
      body: `“Over the past 10 years Lee has patiently and enthusiastically helped me develop and improve my playing technique. He has a seemingly endless repertoire of exercises and his lessons are packed with encouragement and good humor which has made them something I really look forward to each week.”`,
      headerImage: `${CDN_URL}/images/pages/teachers/paul.png`,
    },
    {
      name: "Barbara Gini",
      location: null,
      teacher: "Richard’s Student",
      body: `“Richard is an attentive, knowledgeable, and caring instructor, and I really enjoy taking classes with him! He paces the learning to suit me and I always feel more motivated to practice after our sessions. Having someone who is familiar with Justin’s teaching methodology and can supplement it is really helpful!”`,
      headerImage: `${CDN_URL}/images/pages/teachers/barbara2.png`,
    },
  ];
  