import React from "react";
import { connect } from "react-redux";
import { changeFontSize } from "../../store/songStore";

const DEFAULT_FONT_SIZE = 14;

const FontAdjustTab = ({ activeSheetMusicContent, standalonePage, fontSize, changeFontSize }) => {
  return (
    <li
      className={
        "submenu-item " + (activeSheetMusicContent === "Tabs" || !standalonePage ? "disabled" : "")
      }
    >
      <div className="change-option">
        <div className="change-option-label">
          <span>
            Font<sup>{fontSize}</sup>
          </span>
        </div>
        <div className="change-option-action">
          {/* <span className="smaller-font">A</span>
          <input
            value={fontSize}
            type="range"
            min={10}
            max={18}
            onChange={(e) => {
              changeFontSize(e.target.value);
            }}
          />
          <span className="larger-font">A</span> */}
          <span
            className="change-btn change-btn--minus"
            onClick={() => changeFontSize(fontSize - 1)}
          >
            <span>-</span>
          </span>
          <span
            className="change-btn change-btn--plus"
            onClick={() => changeFontSize(fontSize + 1)}
          >
            <span>+</span>
          </span>
        </div>
      </div>
    </li>
  );
};

const mapStateToProps = (state) => ({
  activeSheetMusicContent: state.entity.activeSheetMusicContent,
  standalonePage: state.entity.standalonePage,
  fontSize: state.entity.fontSize,
});

const mapDispatchToProps = (dispatch) => ({
  changeFontSize: (amount) => dispatch(changeFontSize(amount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FontAdjustTab);
