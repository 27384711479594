import React from "react";
import { ViewMoreButton } from "../ViewMoreButton";
import { ArtistThumbnail } from "./ArtistThumbnail";

const ArtistListing = ({ artists, hasMoreArtists, loadingMoreResults, fetchArtists }) => {
  return (
    <div>
      <div className="infinite-scroll">
        {artists.length === 0 && !loadingMoreResults ? (
          <div className="no-results">No results match your criteria</div>
        ) : (
          <div className="artists__listing">
            {artists.map((artist) => (
              <ArtistThumbnail artist={artist.attributes} key={artist.id} />
            ))}
          </div>
        )}

        <ViewMoreButton
          onViewMore={() => fetchArtists(false)}
          loadingMoreResults={loadingMoreResults}
          hasMore={hasMoreArtists}
        />
      </div>
    </div>
  );
};

export default ArtistListing;
