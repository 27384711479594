import React from "react";

const encodedUrl = (url) => {
  return url.replace(/\(/g, "%28").replace(/\)/g, "%29").replace(/\'/g, "%27").replace(/ /g, "%20");
};

const TabImage = ({ image }) => {
  return <img src={encodedUrl(image)} />;
};

export default TabImage;
