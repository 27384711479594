import React, { useState } from "react";
import { BootstrapSize, breakpoints } from "../../components/shared/bootstrap-helpers";

const data = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

const Receipts = ({ width }) => {
  const [showReceipt, setShowReceipt] = useState(false);

  return (
    <div className="account__receipts">
      {!showReceipt ? (
        <>
          <div className="tab-heading">
            <button className="donation-btn">See Recurring Donation</button>
            <button className="subscriptions-btn">See Recurring Subscriptions</button>
          </div>
          <div className="tab-content">
            {width > breakpoints[BootstrapSize.lgDevice] ? (
              <table className="table">
                <tr>
                  <th>Date</th>
                  <th>Product</th>
                  <th>Reference</th>
                  <th>Price</th>
                  <th></th>
                </tr>
                {data.map((item, idx) => (
                  <tr key={idx}>
                    <td>October 1, 2020</td>
                    <td>Music Theory - Lifetime Access</td>
                    <td>205-2587940-6807520</td>
                    <td>$99</td>
                    <td>
                      <button onClick={() => setShowReceipt(true)}>view</button>
                    </td>
                  </tr>
                ))}
              </table>
            ) : (
              <ul className="list">
                {data.map((item, idx) => (
                  <li>
                    <div>
                      <p>Music Theory - Lifetime Access</p>
                      <span>October 1, 2020</span>
                    </div>
                    <button onClick={() => setShowReceipt(true)}>
                      <i className="icon icon-caret-right"></i>
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </>
      ) : (
        <div className="tab-content receipt">
          <p className="receipt__number">Receipt No. 205-2587940-6807520</p>
          <div className="receipt__data">
            <div
              className="receipt__image"
              style={{ backgroundImage: "url(https://via.placeholder.com/100)" }}
            ></div>
            {width > breakpoints[BootstrapSize.xlDevice] ? (
              <table className="table">
                <tr>
                  <th>Date</th>
                  <th>Product</th>
                  <th>Payment method</th>
                  <th>Price</th>
                  <th>VAT (20%)</th>
                  <th>Total</th>
                </tr>
                <tr>
                  <td>10/01/20</td>
                  <td>Music Theory - Lifetime Access</td>
                  <td>•••• •••• •••• 1021</td>
                  <td>$82.5</td>
                  <td>$16.5</td>
                  <td>$99</td>
                </tr>
              </table>
            ) : (
              <div className="receipt__data__list">
                <dl>
                  <dt>Date</dt>
                  <dd>09/01/20</dd>
                  <dt>Product</dt>
                  <dd>Music Theory - Lifetime Access</dd>
                  <dt>Payment method</dt>
                  <dd>•••• •••• •••• 1021</dd>
                  <dt>Price</dt>
                  <dd>$82.5</dd>
                  <dt>VAT (20%)</dt>
                  <dd>$16.5</dd>
                  <dt>Total</dt>
                  <dd>$99</dd>
                </dl>
              </div>
            )}
          </div>
          <p className="receipt__terms">
            By purchasing a recurring donation, you authorize JustinGuitar.com to automatically
            charge you each month until you cancel. You can check your renewal date or cancel
            anytime via your Donations tab. No partial refunds. JustinGuitar.com terms apply.
            <br />
            <br />
            <a href="#">Terms & Conditions</a>
          </p>
        </div>
      )}
    </div>
  );
};

export default Receipts;
