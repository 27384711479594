import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material";
import { jgDenimTheme } from "../../jg-material/theme/jg-denim-theme";
import { Box, Paper, Container, Typography, Grid, Divider } from "@mui/material";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import Loading from "../../components/shared/Loading";
import CheckoutSuccessSwoosh from "./CheckoutSuccessSwoosh";
import CheckoutSuccessMessage from "./CheckoutSuccessMessage";
import CheckoutSuccessDetails from "./CheckoutSuccessDetails";
import CheckoutSuccessItems from "./CheckoutSuccessItems";

export const CHECKOUT_DATE_FORMAT = "MMMM Do YYYY";
const GENERIC_ERROR_MESSAGE =
  "We could not find this checkout session. Please contact our customer support at hello@justinguitar.com if you think there has been an error.";

const CheckoutSuccess = ({ currentUser }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lineItems, setLineItems] = useState([]);
  const [checkoutSession, setCheckoutSession] = useState(null);
  const [pricingPlans, setPricingPlans] = useState([]);

  useEffect(() => {
    setLoading(true);

    axiosWithCSRF()
      .get(window.location.href)
      .then((response) => {
        setLineItems(response.data.lineItems);
        setCheckoutSession(response.data.checkoutSession);
        setPricingPlans(response.data.pricingPlans);
      })
      .catch(() => {
        setError(GENERIC_ERROR_MESSAGE);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <ThemeProvider theme={jgDenimTheme}>
      <CheckoutSuccessSwoosh />

      {/* materialui <Container/> is different size from existing .container class */}
      <Box className="container">
        <Box>
          {!error && <CheckoutSuccessMessage currentUser={currentUser} />}
          <Divider cx={{ p: 3 }} />

          {loading ? (
            <Loading isContent={true} />
          ) : error ? (
            <Box p={10}>
              <Typography color={"red"}>{error}</Typography>
            </Box>
          ) : (
            <>
              <CheckoutSuccessDetails checkoutSession={checkoutSession} />

              <Divider cx={{ p: 3 }} />

              <CheckoutSuccessItems
                lineItems={lineItems}
                pricingPlans={pricingPlans}
                checkoutSession={checkoutSession}
              />
            </>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default (props) => <CheckoutSuccess {...props} />;
