import { Box, Typography } from "@mui/material";
import React from "react";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";
import { WorkshopEntity } from "../interfaces";

export const WorkshopPrice = ({ workshop }: { workshop: WorkshopEntity }) => (
  <Box mr={jgDenimTheme.spacing(2)}>
    <Typography variant="h4" textAlign="left" margin={jgDenimTheme.spacing(6, 0, 4, 0)}>
      Workshop & Accommodation Fees
    </Typography>
    {workshop.attributes.price.split("\n").map((p, idx) => (
      <Typography component="div" variant="body1" mb={jgDenimTheme.spacing(2)} key={idx}>
        <div
          dangerouslySetInnerHTML={{
            __html: p
              .replace("Workshop:", "<strong>$&</strong>")
              .replace("Accommodation & Meals:", "<strong>$&</strong>")
              .replace("Hotel Booking Window:", "<strong>$&</strong>")
              .replace("Carlo", "<a href='mailto:carlo@tpw.it'>$&</a>"),
          }}
        />
      </Typography>
    ))}
  </Box>
);
