import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosWithCSRF from "../../../shared/axiosWithCSRF";

import * as ui from "../slices/ui";

export const createVote = createAsyncThunk(
  "songs/createVote",
  (songRequest, { dispatch, getState }) => {
    const {
      session: { currentUser },
    } = getState();

    if (currentUser == null) {
      dispatch(
        ui.actions.openAlert({
          message: "Please log in before voting",
          severity: "warning",
        })
      );
      return Promise.reject();
    }

    return axiosWithCSRF()
      .post(`/song_requests/${songRequest.id}/vote.json`)
      .then(({ data }) => data)
      .catch(() =>
        dispatch(
          ui.actions.openAlert({
            message: "An error occurred, please try again later",
            severity: "error",
          })
        )
      );
  }
);
