import React from "react";

export default class AlbumCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAlbum: this.props.albums[0],
    };
  }

  currentIndex = () => {
    return this.props.albums.indexOf(this.state.activeAlbum);
  };

  nextAlbum = (currentIndex) => {
    if (currentIndex + 1 === this.props.albums.length) {
      return this.props.albums[0];
    } else {
      return this.props.albums[currentIndex + 1];
    }
  };

  previousAlbum = (currentIndex) => {
    if (currentIndex === 0) {
      return this.props.albums[this.props.albums.length - 1];
    } else {
      return this.props.albums[currentIndex - 1];
    }
  };

  album = (album, side, idx) => {
    return this.standardAlbum(album, side, idx);
  };

  standardAlbum = (album, side, idx) => {
    return (
      <div
        className={
          "about__body__content__albums__album" +
          (side === "right" ? " about__body__content__albums__album--active" : "")
        }
        key={idx}
        style={{ top: idx * 20 + "px" }}
      >
        <img src={album["imageSrc"]} alt={`${album["title"]} - ${album["subtitle"]}`} />
        <h3>{album["title"]}</h3>
        <p>{album["subtitle"]}</p>
      </div>
    );
  };

  albums = () => {
    let currentIndex = this.currentIndex();
    let albums = [
      { album: this.previousAlbum(currentIndex), side: "left" },
      { album: this.state.activeAlbum, side: "center" },
      { album: this.nextAlbum(currentIndex), side: "right" },
    ];
    return (
      <>
        {albums.map((album, idx) => {
          return this.album(album.album, album.side, idx);
        })}
      </>
    );
  };

  previous = () => {
    let previousAlbum = this.previousAlbum(this.currentIndex());
    this.setState({ activeAlbum: previousAlbum });
  };

  next = () => {
    let nextAlbum = this.nextAlbum(this.currentIndex());
    this.setState({ activeAlbum: nextAlbum });
  };

  render() {
    return (
      <>
        <i className="icon icon-angle-left" onClick={this.previous}></i>
        <i className="icon icon-angle-right" onClick={this.next}></i>
        {this.albums()}
      </>
    );
  }
}
