import React from "react";
import ReactPlayer from "react-player";

export default class DynamicIframe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      link: this.props.link,
      containerHeight: this.props.containerHeight,
      containerWidth: this.props.containerWidth,
      aspectRatio: this.props.aspectRatio || 16 / 9,
    };
  }

  componentDidMount() {
    this.setContainerDimensions();
    window.addEventListener("resize", this.updateContainerDimensions);
    this.updateContainerDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateContainerDimensions);
  }

  setContainerDimensions = () => {
    if (this.refs.iframe_size.offsetHeight || this.refs.iframe_size.offsetWidth) {
      this.setState({
        containerHeight: this.refs.iframe_size.offsetHeight,
        containerWidth: this.refs.iframe_size.offsetWidth,
      });
    }
  };

  updateContainerDimensions = () => {
    if (this.refs.iframe_size.offsetWidth) {
      this.setState({ containerWidth: this.refs.iframe_size.offsetWidth }, () => {
        this.setState({
          containerHeight: this.state.containerWidth / this.state.aspectRatio,
        });
      });
    }
  };

  donateFallbackScreen() {
    return (
      <div
        className="donate-video"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          background: "#F4F3F2",
          textAlign: "center",
        }}
      >
        <h1>Please donate if you dig the lesson!</h1>
        <p>
          This website is my full-time job. Please support 'free' lessons by making a donation -
          unless you already are - in which case THANK YOU! :)
        </p>
        <a className="donate-video__btn" href="/donate">
          DONATE NOW
        </a>
        <a href="/donate">Help me help you :)</a>
      </div>
    );
  }

  donateBox = () => {
    let { videoEndTile } = this.props;
    if (this.state.showDonate && videoEndTile) {
      const tile = {
        backgroundImage: `url(${videoEndTile.image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100%",
        width: "100%",
        position: "absolute",
        display: "block",
      };
      if (videoEndTile.link && videoEndTile.link.length > 0) {
        return <a href={videoEndTile.link} style={tile}></a>;
      } else {
        return <div style={tile}></div>;
      }
    } else if (this.state.showDonate) {
      return this.donateFallbackScreen();
    }
  };

  exitFullscreen() {
    if (document.fullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  triggerDonate = () => {
    if (this.props.link.includes("vimeo") || this.props.showDonateAfterVideo != true) {
      return;
    } else {
      this.setState({ showDonate: true }, () => {
        this.exitFullscreen();
        window.scrollTo(0, 0);
      });
    }
  };

  donateHTML = () => {
    // use below when deploying YT donate popup
    return (
      <div style={{ position: "relative" }} ref="iframe_size">
        {this.donateBox()}

        <div style={{ height: `${this.state.containerHeight}px`, width: "100%" }}>
          <ReactPlayer
            url={this.props.link}
            controls={true}
            onEnded={this.triggerDonate}
            width="100%"
            allowFullScreen={true}
            height="100%"
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <iframe
          ref="iframe_size"
          src={this.props.link}
          height={this.state.containerHeight}
          width="100%"
          allowFullScreen={true}
        />
      </div>
    );
  }
}
