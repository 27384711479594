import React from "react";
import { connect } from "react-redux";
import { setActiveTab } from "../store/dashboardStore";
import PropTypes from "prop-types";
import Tabs from "./Tabs";

const BackToDashboard = ({ setDashboardTab, hasText }) => {
  return (
    <button className="back-btn" onClick={() => setDashboardTab("Dashboard")}>
      <i className="icon icon-arrow-left"></i> {hasText ? "Dashboard" : ""}
    </button>
  );
};
const TabbedContent = ({
  title,
  activeTab,
  setActiveTab,
  setDashboardTab,
  tabOptions,
  tabsHidden,
  goToTab,
  children,
}) => {
  return (
    <div>
      <>
        <BackToDashboard setDashboardTab={setDashboardTab} hasText={true} />
        <h1 className="dashboard__heading">
          {title}
          <BackToDashboard setDashboardTab={setDashboardTab} hasText={false} />
        </h1>

        <div className="dashboard__content__tabs">
          {!tabsHidden && (
            <Tabs
              tabs={tabOptions || []}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              goToTab={goToTab}
              width={"change me"}
            />
          )}
          <div className={"tab-container " + (tabsHidden ? "tab-container--no-tabs" : "")}>
            {children}
          </div>
        </div>
      </>
    </div>
  );
};

TabbedContent.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  activeTab: PropTypes.string,
  tabOptions: PropTypes.array,
  setActiveTab: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentDashboardTab: state.entity.dashboard.activeTab,
});

const mapDispatchToProps = (dispatch) => ({
  setDashboardTab: (tab) => dispatch(setActiveTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabbedContent);
