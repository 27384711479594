import React from "react";
import { connect } from "react-redux";
import { orderedTabImages } from "../../store/songSelectors";
import { setSingleImage } from "../../store/songStore";
import TabImage from "./TabImage";

const TabAllImages = ({ orderedTabImages, setSingleImage }) => {
  return (
    <div>
      <div className="song-sheet__container">
        <div className="song-sheet__cover"></div>
        <div className="column song-sheet__images">
          {orderedTabImages.map((image) => {
            return (
              <div className="song-sheet__image" key={image.attributes.id}>
                <TabImage image={image.attributes.imageLink} />
              </div>
            );
          })}
        </div>
      </div>

      <div className="song-sheet__options">
        <span
          className="button button--blue button--inline"
          onClick={() => setSingleImage(orderedTabImages[0])}
        >
          see less
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orderedTabImages: orderedTabImages(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSingleImage: (img) => dispatch(setSingleImage(img)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabAllImages);
