import React from "react";
import axios from "axios";

export default class EditStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statIdToEdit: null,
      statValueToUpdate: null,
      isSubmitting: false,
    };
  }

  orderedItems = (items) => {
    let grouped = {};
    items.map((i) => {
      let d = new Date(i.date);
      d.setHours(0, 0, 0, 0);

      if (grouped[d]) {
        grouped[d].push(i);
      } else {
        grouped[d] = [].concat(i);
      }
    });
    let ordered = {};
    Object.keys(grouped)
      .sort((a, b) => {
        let aDate = Date.parse(a);
        let bDate = Date.parse(b);
        return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
      })
      .forEach((key) => {
        ordered[key] = grouped[key];
      });
    return ordered;
  };

  itemsHaveStatistic(items) {
    let hasItem = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].statistic != null && items[i].statistic != "") {
        hasItem = true;
        break;
      }
    }
    return hasItem;
  }

  statItemsList = (items) => {
    if (!items || items.length === 0 || this.itemsHaveStatistic(items) === false) {
      return this.noItems();
    }
    let ordered = this.orderedItems(items);
    if (ordered && Object.keys(ordered).length > 0) {
      return Object.keys(ordered).map((key) => {
        return this.statGroup(key, ordered[key]);
      });
    }
  };

  noItems() {
    return (
      <div className="edit-stats edit-stats--empty">
        <h5 style={{ textAlign: "center" }}>There are no stats for this time period.</h5>
      </div>
    );
  }

  statGroup = (statDate, items) => {
    let itemsWithStats = items.filter((i) => parseInt(i.statistic) > 0);
    if (itemsWithStats.length > 0) {
      return (
        <div className="edit-stats__group" key={statDate}>
          <div className="edit-stats__date">
            <span>{this.formatDate(statDate)}</span>
          </div>
          {this.statItems(items)}
        </div>
      );
    }
  };

  formatDate = (d) => {
    let dateToFormat = new Date(d);
    return dateToFormat.toLocaleDateString();
  };

  statItems = (items) => {
    return items.map((item) => {
      if (!item.statistic || item.statistic === "") {
        return;
      } else {
        return (
          <div className="edit-stats__item" key={item.id}>
            <p className="edit-stats__stat-name">Statistic: {item.statistic_name}</p>
            <p className="edit-stats__practice-name">Practice Item: {item.title}</p>
            {this.statItemField(item)}
            <span className="edit-stats__toggle" onClick={() => this.setStatIdToEdit(item)}>
              edit
            </span>
          </div>
        );
      }
    });
  };

  setStatIdToEdit = (item) => {
    this.setState({ statIdToEdit: item.id, statValueToUpdate: item.statistic });
  };

  clearStatIdToEdit = () => {
    this.setState({ statIdToEdit: null, inputError: false });
  };

  handleStatUpdate = () => {
    let { statIdToEdit, statValueToUpdate, inputError } = this.state;
    if (inputError === true) {
      return;
    } else {
      this.setState({ isSubmitting: true }, () => {
        axios
          .patch(`/practice_session_items/${statIdToEdit}`, {
            statistic: statValueToUpdate,
            authenticity_token: ReactOnRails.authenticityToken(),
          })
          .then((res) => {
            this.setState(
              { saveSuccess: "Successfully updated this item.", submittedId: statIdToEdit },
              () => {
                this.props.handleUpdatedStatItem(res.data.item);
                setTimeout(
                  () =>
                    this.setState({
                      saveSuccess: null,
                      statIdToEdit: null,
                      statValueToUpdate: null,
                      submittedId: null,
                      isSubmitting: false,
                    }),
                  2000
                );
              }
            );
          })
          .catch((err) => {
            this.setState(
              {
                saveError: "There was a problem updating this statistic.",
                submittedId: statIdToEdit,
              },
              () => {
                setTimeout(
                  () =>
                    this.setState({
                      saveError: null,
                      statIdToEdit: null,
                      statValueToUpdate: null,
                      submittedId: null,
                      isSubmitting: false,
                    }),
                  3000
                );
              }
            );
          });
      });
    }
  };

  handleStatDelete = () => {
    let { statIdToEdit } = this.state;
    if (confirm("Are you sure you want to delete this statistic? You cannot undo this.")) {
      this.setState({ isSubmitting: true }, () => {
        axios
          .delete(`/practice_session_items/${statIdToEdit}`, {
            headers: {
              "X-CSRF-Token": ReactOnRails.authenticityToken(),
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            this.setState(
              { saveSuccess: "Successfully deleted this item.", submittedId: statIdToEdit },
              () => {
                this.props.handleDeletedStatItem(res.data.item);
                setTimeout(() => {
                  this.props.handleDeletedStatItem(statIdToEdit);
                  this.setState({
                    saveSuccess: null,
                    statIdToEdit: null,
                    statValueToUpdate: null,
                    submittedId: null,
                    isSubmitting: false,
                  });
                }, 2000);
              }
            );
          })
          .catch((err) => {
            this.setState(
              {
                saveError: "There was a problem deleting this statistic.",
                submittedId: statIdToEdit,
              },
              () => {
                setTimeout(
                  () =>
                    this.setState({
                      saveError: null,
                      statIdToEdit: null,
                      statValueToUpdate: null,
                      submittedId: null,
                      isSubmitting: false,
                    }),
                  3000
                );
              }
            );
          });
      });
    }
  };

  saveResponse = (itemId) => {
    if (itemId != this.state.submittedId) {
      return;
    }
    if (this.state.saveError) {
      return <p className="has-errors">{this.state.saveError}</p>;
    } else if (this.state.saveSuccess) {
      return <p className="success">{this.state.saveSuccess}</p>;
    }
  };

  handleStatChange = (e) => {
    let newStat = e.target.value.trim();
    let inputError = true;
    if (newStat.length > 0 && isNaN(newStat) === false) {
      inputError = false;
    }
    this.setState({ inputError, statValueToUpdate: newStat });
  };

  validateNumberInput = () => {
    let { statValueToUpdate } = this.state;
    if (statValueToUpdate.length === 0 || isNaN(statValueToUpdate)) {
      this.setState({ inputError: true });
    } else if (this.state.inputError === true) {
      this.setState({ inputError: false });
    }
  };

  statItemField = (item) => {
    if (this.state.statIdToEdit === item.id) {
      return (
        <div className="edit-stats__form">
          {this.saveResponse(item.id)}

          <label>Update value:</label>
          <input
            type="number"
            className={this.state.inputError ? "input-with-errors" : ""}
            onChange={this.handleStatChange}
            onBlur={this.validateNumberInput}
            value={this.state.statValueToUpdate}
          ></input>
          {this.state.inputError && <p className="input-warning">Value must be a number.</p>}
          <div className="practice-buttons__container">
            <button
              className="practice__button practice__button--save"
              onClick={this.handleStatUpdate}
              disabled={this.state.isSubmitting || this.state.inputError}
            >
              save
            </button>
            <button
              className="practice__button practice__button--cancel"
              onClick={this.clearStatIdToEdit}
              disabled={this.state.isSubmitting || this.state.inputError}
            >
              cancel
            </button>
            <button
              className="practice__button practice__button--red"
              onClick={this.handleStatDelete}
              disabled={this.state.isSubmitting || this.state.inputError}
            >
              delete
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p className="edit-stats__stat">Recorded Value: {item.statistic}</p>
        </div>
      );
    }
  };

  render() {
    return <div className="edit-stats">{this.statItemsList(this.props.items)}</div>;
  }
}
