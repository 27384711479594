import React from "react";
import { connect } from "react-redux";
import Swoosh from "../components/shared/Swoosh";
import SongVideo from "./SongVideo";
import SongHeading from "./SongHeading";

const SongSwoosh = ({ currentUser, toggleMobileContextMenuVisibility }) => {
  return (
    <div className="song-swoosh">
      <Swoosh color="#44546C">
        <div
          className={
            "song-swoosh__content container " +
            (currentUser ? "" : "song-swoosh__content--login-prompt")
          }
        >
          <SongVideo />
          <SongHeading toggleMobileContextMenuVisibility={toggleMobileContextMenuVisibility} />
        </div>
      </Swoosh>
    </div>
  );
};

const mapStateToProps = ({ session: { currentUser } }) => ({ currentUser });
export default connect(mapStateToProps)(SongSwoosh);
