import React from "react";
import PropTypes from "prop-types";
import { CDN_URL } from "./CdnUrl";

const DEFAULT_ONELINK_URL = "https://go.onelink.me/3065804798/35a32b8f";

export default class AppIcons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (window.innerWidth < 576) {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        this.setState({ appBadge: "Play Store" });
      } else if (/iPhone/.test(userAgent) && !window.MSStream) {
        this.setState({ appBadge: "App Store" });
      }
    }
  }

  logEventAndGoToOnelink = (appOrPlayStore, url) => {
    const { analyticsEventPageName, analyticsEventValue } = this.props;
    if ("ga" in window) {
      let tracker = ga.getAll()[0];
      if (tracker) {
        tracker.send(
          "event",
          `go-to-${appOrPlayStore}-store`,
          "Click",
          `${analyticsEventPageName}-${analyticsEventValue}`
        );
      }
    }

    window.location.href = url;
  };

  render() {
    const { appBadge } = this.state;

    return (
      <div className="app-store-buttons">
        {appBadge == null || appBadge === "App Store" ? (
          <AppStoreIcon logEventAndGoToOnelink={this.logEventAndGoToOnelink} {...this.props} />
        ) : null}
        {appBadge == null || appBadge === "Play Store" ? (
          <PlayStoreIcon logEventAndGoToOnelink={this.logEventAndGoToOnelink} {...this.props} />
        ) : null}
      </div>
    );
  }
}

export const AppStoreIcon = ({ dark, black, appStoreLink, onelinkUrl, logEventAndGoToOnelink }) => {
  const imgSrc = `${CDN_URL}/images/banners/apple-store-badge.svg`;
  const href =
    appStoreLink != null && appStoreLink.length
      ? appStoreLink
      : onelinkUrl != null && onelinkUrl.length
      ? onelinkUrl
      : DEFAULT_ONELINK_URL;
  return (
    <a
      className="clickable"
      onClick={() => {
        if (typeof logEventAndGoToOnelink === "function") {
          logEventAndGoToOnelink("app", href);
        } else {
          window.location.href = href;
        }
      }}
      target="_blank"
    >
      <img src={imgSrc} alt="JustinGuitar Lessons & Songs App on the App Store" />
    </a>
  );
};

export const PlayStoreIcon = ({
  dark,
  black,
  googlePlayLink,
  onelinkUrl,
  logEventAndGoToOnelink,
}) => {
  const imgSrc = `${CDN_URL}/images/banners/google-play-badge.svg`;

  const href =
    googlePlayLink != null && googlePlayLink.length
      ? googlePlayLink
      : onelinkUrl != null && onelinkUrl.length
      ? onelinkUrl
      : DEFAULT_ONELINK_URL;
  return (
    <a
      className="clickable"
      onClick={() => {
        if (typeof logEventAndGoToOnelink === "function") {
          logEventAndGoToOnelink("play", href);
        } else {
          window.location.href = href;
        }
      }}
      target="_blank"
    >
      <img src={imgSrc} alt="JustinGuitar Lessons & Songs App on the Google Play Store" />
    </a>
  );
};

AppIcons.propTypes = {
  dark: PropTypes.bool,
  black: PropTypes.bool,
  appStoreLink: PropTypes.string,
  googlePlayLink: PropTypes.string,
  onelinkUrl: PropTypes.string,
};

AppStoreIcon.propTypes = {
  dark: PropTypes.bool,
  black: PropTypes.bool,
  appStoreLink: PropTypes.string,
  onelinkUrl: PropTypes.string,
};

PlayStoreIcon.propTypes = {
  dark: PropTypes.bool,
  black: PropTypes.bool,
  googlePlayLink: PropTypes.string,
  onelinkUrl: PropTypes.string,
};
