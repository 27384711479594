import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import LoginForm from "../components/LoginForm";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100%",
  },
};

export default class UserItemDummyButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clickedText: props.clickedText,
      extraText: props.extraText,
      loginVisible: false,
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    if (typeof window !== "undefined") {
      Modal.setAppElement("body");
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  showLogin() {
    this.setState({ clickedText: true });
    setTimeout(() => this.setState({ clickedText: false }), 3000);
  }

  displayText(itemStatus) {
    if (itemStatus === "saved" && this.state.clickedText === false) {
      return (
        <div>
          <span className="glyphicon glyphicon-heart-empty"></span>
          <p className="header5 save-item__button"> Save for Later</p>
        </div>
      );
    } else if (itemStatus === "progress" && this.state.clickedText === false) {
      return (
        <div>
          <span className="glyphicon glyphicon-time"></span>
          <p className="header5 save-item__button"> In Progress</p>
        </div>
      );
    } else if (itemStatus === "completed" && this.state.clickedText === false) {
      return (
        <div>
          <span className="icon icon-check"></span>
          <p className="header5 save-item__button"> Mark as Complete</p>
        </div>
      );
    }
  }

  openLogin = () => {
    this.setState({ loginVisible: true });
  };

  render() {
    return (
      <div className="needs-login__inner">
        {this.state.clickedText === false && (
          <button onClick={() => this.showLogin()}>
            {this.displayText(this.props.itemStatus)}
          </button>
        )}

        {this.state.clickedText === true && (
          <div className="user-login-prompt">
            <p>
              You must be <a onClick={() => this.openModal()}>logged in</a> {this.state.extraText}
            </p>
          </div>
        )}

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Log In"
        >
          <button className="close-modal__button" onClick={this.closeModal}>
            <p>X</p>
          </button>
          <LoginForm />
        </Modal>
      </div>
    );
  }
}
