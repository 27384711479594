import React from "react";
import { UnfoldMore } from "@mui/icons-material";
import { fontWeights } from "../../typography";

export const SelectIconComponent = () => (
  <UnfoldMore
    sx={{
      fontWeight: fontWeights.bold,
      fontSize: "16px",
      marginRight: "10px",
      cursor: "pointer",
    }}
  />
);
