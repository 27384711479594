import React from "react";
import "../../components/shared/polyfills/replaceAll.js";

export const ChordContentGroup = ({ chordGroup }) => {
  return (
    <div
      className="chords__content__chord-group"
      id={chordGroup.name.toLowerCase().replaceAll(" ", "-")}
    >
      <h2>{`${chordGroup.name} chords`}</h2>
      <div className="chords__content__chord-group__chords">
        {chordGroup.chords.map((chord, idx) => (
          <button key={idx} onClick={() => (window.location.href = `/chords/${chord.slug}`)}>
            {chord.title}
          </button>
        ))}
      </div>
    </div>
  );
};
