import React, { useState } from "react";
import { PurchaseTilesByReference } from "../dashboard/AccountTab/Purchases/PurchaseTilesByReference";

const ProductList = ({ products }) => {
  return (
    <div className="products-listing__list">
      {products.map((product, idx) => {
        const { image, title, link, hoverImage, reference } = product.data.attributes;
        const imgSrc = PurchaseTilesByReference.hasOwnProperty(reference)
          ? PurchaseTilesByReference[reference]
          : image;
        return (
          <a
            className="products-listing__product-card"
            href={link.replace("products", "store")}
            key={idx}
          >
            <div
              className={`products-listing__product-image${
                imgSrc && !imgSrc.includes("/books/") ? " custom" : ""
              }`}
            >
              <img
                src={imgSrc}
                alt={title}
                onMouseEnter={(e) => (e.currentTarget.src = hoverImage ? hoverImage : imgSrc)}
                onMouseLeave={(e) => (e.currentTarget.src = imgSrc)}
              />
            </div>
            <div className="products-listing__product-title">{title}</div>
          </a>
        );
      })}
    </div>
  );
};

export default ProductList;
