import React, { Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PASSWORD_CHANGE_SCHEMA, PASSWORD_FIELDS } from "./consts";
import { useEffect } from "react";
import { Box, Button, FormHelperText, IconButton, InputAdornment } from "@mui/material";
import Loading from "../../shared/Loading";
import { SquareTextField } from "../../../jg-material/theme/components/styled/SquareTextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axiosWithCSRF from "../../shared/axiosWithCSRF";

const ChangePassword = ({ sectionHeader }) => {
  const [resetToken, setResetToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const token = params.get("reset_password_token");
    setResetToken(token);
  }, []);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(PASSWORD_CHANGE_SCHEMA),
    mode: "onTouched",
  });

  const onSubmit = (data) => {
    setLoading(true);
    axiosWithCSRF()
      .patch("/users/password", {
        user: { reset_password_token: resetToken, password: data.password },
      })
      .then(() => {
        window.location.href = "/";
      })
      .catch((error) => {
        const defaultError = "There was a problem resetting your password. Please try again later.";
        setError("root.general", {
          type: "manual",
          message: error?.response?.data?.error || defaultError,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box>
      {sectionHeader("Change Your Password")}

      <form onSubmit={handleSubmit(onSubmit)}>
        {errors.root?.general && (
          <FormHelperText sx={{ fontSize: "12px" }} error={true}>
            {errors.root.general.message}
          </FormHelperText>
        )}

        {loading && <Loading />}

        {PASSWORD_FIELDS.map((pwField) => (
          <Fragment key={pwField.name}>
            <Controller
              name={pwField.name}
              control={control}
              render={({ field }) => {
                return (
                  <SquareTextField
                    {...field}
                    margin="normal"
                    size="medium"
                    className={field?.value?.length > 0 ? "Mui-filled" : ""}
                    required
                    fullWidth
                    label={pwField.placeholder}
                    type={showPassword ? "text" : "password"}
                    error={errors[field.name] ? true : false}
                    helperText={errors[field.name]?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((show) => !show)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                );
              }}
            />
          </Fragment>
        ))}

        <Button
          fullWidth
          type="submit"
          className="button button--primary"
          variant="contained"
          sx={{ mt: 3, mb: 2, boxShadow: "none" }}
          disabled={!isValid || loading}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default ChangePassword;
