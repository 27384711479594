import React, { useCallback, useState } from "react";
import Swoosh from "../../components/shared/Swoosh";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import Loading from "../../components/shared/Loading";
import OnboardingOptions from "./OnboardingOptions";
import TypeformEmbed from "./TypeformEmbed";

export const TYPEFORM_HEIGHT = "500px";

export const ONBOARDING_STATUSES = {
  needsOnboarding: "needs_onboarding",
  pausedOnboarding: "paused_onboarding",
  dismissedOnboarding: "dismissed_onboarding",
  completedOnboarding: "completed_onboarding",
};

const Onboarding = ({ currentUser }) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const memoizedHandleOnSubmit = useCallback(() => {
    handleOnSubmit();
  }, []);

  const updateOnboarding = (status) => {
    return axiosWithCSRF().put("/onboarding", { status: status });
  };

  const handleOnSubmit = () => {
    updateOnboarding(ONBOARDING_STATUSES.completedOnboarding).then(() => {
      setSubmitted(true);
    });
  };

  return (
    <>
      <Swoosh gradient="nightSky">
        {loading && <Loading />}

        <div className="container" style={{ minHeight: TYPEFORM_HEIGHT }}>
          <TypeformEmbed currentUser={currentUser} onSubmit={memoizedHandleOnSubmit} />

          {!submitted && <OnboardingOptions currentUser={currentUser} setLoading={setLoading} />}
        </div>
      </Swoosh>
    </>
  );
};

export default (props) => <Onboarding {...props} />;
