import React, { useEffect, useState } from "react";
import axiosWithCSRF from "../components/shared/axiosWithCSRF";

import { connect } from "react-redux";
import { openAlert } from "../components/shared/ui/uiSlice";
import Loading from "../components/shared/Loading";

const CreateDiscourseComment = ({
  currentUser,
  setShowCommenting,
  topicId,
  openAlert,
  setIsLoading,
}) => {
  const [discourseComment, setDiscourseComment] = useState("");

  function submitComment() {
    setIsLoading(true);
    axiosWithCSRF()
      .post("/create-discourse-post", {
        raw: discourseComment,
        topic_id: topicId,
        user_id: currentUser?.data?.id,
      })
      .then(() => {
        // give the comment time to appear in discourse thread before reloading the iframe.
        setTimeout(() => {
          setIsLoading(false);
          openAlert({ message: "Added your comment!", severity: "success" });
          setShowCommenting(false);
        }, 1000);
      })
      .catch(() => {
        setIsLoading(false);
        openAlert({ message: "There was a problem saving your comment", severity: "error" });
      });
  }

  return (
    <div className="discourse__commenting">
      <textarea
        placeholder="Start typing your comment here! Minimum of 10 characters."
        value={discourseComment}
        onChange={(e) => setDiscourseComment(e.target.value)}
      ></textarea>
      <div className="discourse__btns">
        <button
          className="button button--white button--inline"
          onClick={() => setShowCommenting(false)}
        >
          cancel
        </button>
        <button
          className="button button--blue button--inline"
          disabled={discourseComment.length < 10 === true}
          onClick={submitComment}
        >
          submit
        </button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openAlert: (text) => dispatch(openAlert(text)),
});

export default connect(null, mapDispatchToProps)(CreateDiscourseComment);
