import React from "react";
import { connect } from "react-redux";
import { hasChords } from "../../store/songSelectors";
import { toggleField, closeSubMenu } from "../../store/songStore";
import TabsChordsToggle from "./TabsChordsToggle";
import TransposeTab from "./TransposeTab";
import SubMenuTab from "./SubMenuTab";
import SubMenu from "../submenu/SheetMusicSubMenu";
import AutoscrollTab from "./AutoscrollTab";
import { withInnerWidth } from "../../../components/shared";
import { BootstrapSize, breakpoints } from "../../../components/shared/bootstrap-helpers";

class SheetMusicHeader extends React.Component {
  constructor(props) {
    super(props);
    this.menuRef = React.createRef();
    this.additionalMenuRef = React.createRef();
    this.subMenu = React.createRef();
    this.state = {
      seeMore: false,
      showDisplayNotice: localStorage.getItem("showDisplayNotice") !== "0",
    };
  }

  componentDidMount() {
    window.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("scroll", this.isSticky);
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("scroll", this.isSticky);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.width !== this.props.width) {
      this.props.closeSubMenu();

      if (nextProps.width >= breakpoints[BootstrapSize.lgDevice] && this.state.seeMore) {
        this.setState({ seeMore: false });
      }
    }
  }

  handleClickOutside = (e) => {
    if (
      this.props.subMenuOpen &&
      this.subMenu &&
      !this.subMenu.current.contains(e.target) &&
      ((!this.state.seeMore && this.menuRef && !this.menuRef.current.contains(e.target)) ||
        (this.state.seeMore &&
          this.additionalMenuRef &&
          !this.additionalMenuRef.current.contains(e.target)))
    ) {
      this.props.toggleField("subMenuOpen");
    }
  };

  isSticky = () => {
    const content = document.querySelector(".song-sheet");
    const header = document.querySelector(".song-sheet__header");

    if (content.getBoundingClientRect().top + 50 <= 0) {
      header.classList.add("fixed");
      document.querySelector(".song-sheet").style.paddingTop = `${
        header.getBoundingClientRect().height + 10
      }px`;
    } else {
      header.classList.remove("fixed");
      document.querySelector(".song-sheet").style.paddingTop = null;
    }
  };

  closeDisplayNotice = () => {
    this.setState({ showDisplayNotice: false });
    localStorage.setItem("showDisplayNotice", "0");
  }

  render() {
    const { sheetMusic, standalonePage, activeContent, hasChords, subMenuOpen } = this.props;
    return (
      <>
        <div className="song-sheet__header">
          <ul className="main-menu" ref={this.menuRef}>
            <li className="without-bg">
              <TabsChordsToggle />
            </li>

            {!standalonePage && (
              <li className="new-window">
                <a href={`/sheet-music/${sheetMusic.data.id}`} target="_blank">
                  New Window
                </a>
              </li>
            )}

            {hasChords && (
              <>
                <TransposeTab />
                <SubMenuTab />
                <AutoscrollTab />
              </>
            )}
          </ul>
          {this.state.seeMore && (
            <ul className="additional-menu" ref={this.additionalMenuRef}>
              {hasChords && (
                <>
                  <TransposeTab />
                  <SubMenuTab />
                </>
              )}
            </ul>
          )}
          <button
            className="toggle-more-btn"
            onClick={() => this.setState({ seeMore: !this.state.seeMore })}
          >
            {this.state.seeMore ? (
              <>
                less<span className="glyphicon glyphicon-chevron-up"></span>
              </>
            ) : (
              <>
                more<span className="glyphicon glyphicon-chevron-down"></span>
              </>
            )}
          </button>
          <div
            className="submenu-outer"
            style={{
              marginTop: !this.state.seeMore ? "60px" : "140px",
              left:
                this.props.width >= 650
                  ? !this.state.seeMore
                    ? this.menuRef &&
                      this.menuRef.current &&
                      this.menuRef.current.querySelector(".clickable.more-opts")
                      ? `${
                          this.menuRef.current
                            .querySelector(".clickable.more-opts")
                            .getBoundingClientRect().left
                        }px`
                      : null
                    : this.additionalMenuRef &&
                      this.additionalMenuRef.current &&
                      this.additionalMenuRef.current.querySelector(".clickable.more-opts")
                    ? `${
                        this.additionalMenuRef.current
                          .querySelector(".clickable.more-opts")
                          .getBoundingClientRect().left
                      }px`
                    : null
                  : null,
              right: this.props.width < 650 ? "20px" : null,
            }}
          >
            {/* Needed for handle click outside. The cover, which prevents copy paste, has z-index of 100000*/}
            <div style={{ position: "relative", zIndex: "200000" }} ref={this.subMenu}>
              {subMenuOpen && <SubMenu />}
            </div>
          </div>
        </div>
        {this.props.width <= 576 && this.state.showDisplayNotice && (
          <div className="display-notice">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => this.closeDisplayNotice()}>
              <g clipPath="url(#clip0_13270_77006)">
                <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#694B32"/>
              </g>
              <defs>
                <clipPath id="clip0_13270_77006">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <span className="title">Display Problems?</span>
            <br /> We apologize if the display is not optimal for the screen size of your device. We
            are working to improve your experience. In the meantime, we recommend using a device
            with a larger screen size.
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sheetMusic: state.entity.sheetMusic,
  currentUser: state.session.currentUser,
  subMenuOpen: state.entity.subMenuOpen,
  hasChords: hasChords(state),
  standalonePage: state.entity.standalonePage,
});

const mapDispatchToProps = (dispatch) => ({
  toggleField: (field) => dispatch(toggleField(field)),
  closeSubMenu: () => dispatch(closeSubMenu()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withInnerWidth(SheetMusicHeader));
