import { createSelector } from "@reduxjs/toolkit";
import "../../components/shared/polyfills/flat.js";

export const groupSelector = (state) => state.entity.group;
export const userSelector = (state) => state.session.currentUser;
export const paywallSelector = (state) => state.entity.paywall;

export const lessonsWithinGroupSelector = createSelector(groupSelector, (group) => {
  return group.included.filter((i) => i.type === "lesson");
});

export const orderedLessons = createSelector(
  groupSelector,
  lessonsWithinGroupSelector,
  (group, lessons) => {
    const { lessonOrder } = group.data.attributes;
    if (!lessonOrder || lessonOrder.length === 0) {
      return lessons;
    } else {
      const ordered = lessonOrder.map((lessonId) => {
        return lessons.filter((l) => parseInt(l.id) === lessonId)[0];
      });

      return ordered.filter((i) => i != null);
    }
  }
);

const youtubeDurationToSeconds = (duration) => {
  const split = duration ? duration.split(":") : [];
  if (split.length === 3) {
    return parseInt(split[0]) * 3600 + parseInt(split[1]) * 60 + parseInt(split[1]);
  } else if (split.length > 0) {
    return parseInt(split[0]) * 60 + parseInt(split[1]);
  }
};

const formatYoutubeDuration = (seconds) => {
  if (seconds < 60 && seconds > 0) {
    return `${seconds} seconds`;
  } else if (seconds < 3600) {
    let mins = Math.floor(seconds / 60);
    let secs = seconds - mins * 60;
    return secs === 0 ? `${mins} mins` : `${mins} mins and ${secs} sec`;
  } else {
    let hours = Math.floor(seconds / 3600);
    let mins = Math.floor((seconds - hours * 3600) / 60);
    let hourText = hours === 1 ? "hour" : "hours";
    let minText = mins === 1 ? "min" : "mins";
    return `${hours} ${hourText} ${mins} ${minText}`;
  }
};

export const totalVideoDurationSelector = createSelector(lessonsWithinGroupSelector, (lessons) => {
  const totalSeconds = lessons.reduce(
    (a, b) => a + (youtubeDurationToSeconds(b.attributes.youtubeDuration) || 0),
    0
  );
  return formatYoutubeDuration(totalSeconds);
});

export const firstLessonInGroupSelector = createSelector(
  groupSelector,
  lessonsWithinGroupSelector,
  (group, lessons) => {
    const { lessonOrder } = group.data.attributes;
    if (lessonOrder.length > 0) {
      let index = 0;
      let found;
      do {
        found = lessons.filter((l) => parseInt(l.id) === lessonOrder[index])[0];
        index += 1;
      } while (!found && index < group.data.attributes.lessonOrder.length);
      {
      }

      return found;
    } else {
      return lessons[0];
    }
  }
);

export const note = createSelector(userSelector, groupSelector, (user, group) => {
  if (user) {
    const groupId = group.data.attributes.id;
    return user.included.filter(
      (i) =>
        i.type === "userNote" &&
        i.attributes.noteableType === "Group" &&
        i.attributes.noteableId === groupId
    )[0];
  }
});

export const completedLessonIds = createSelector(userSelector, groupSelector, (user, group) => {
  if (user) {
    const completed = user.included.filter(
      (i) =>
        i.type === "userItem" &&
        i.attributes.itemableType === "Group" &&
        i.attributes.itemStatus === "completed"
    );
    return completed.map((c) => c && c.attributes.nestedIds).flat();
  }
});
