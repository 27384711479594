import React from "react";
import { LIST_STYLES } from "../consts";

export const ListingStyle = ({ listingStyle, setListingStyle }) => {
  return (
    <div className="listing-style">
      {Object.keys(LIST_STYLES).map((style) => (
        <span
          className={`listing-style__selection ${
            listingStyle === style ? "listing-style__selection--selected" : ""
          }`}
          onClick={() => setListingStyle(style)}
          key={style}
        >
          <i className={`icon icon-${style}`} />
        </span>
      ))}
    </div>
  );
};
