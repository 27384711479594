import React from "react";
import PropTypes from "prop-types";
import SongsGridItem from "../SongsGridItem";

const LatestSongs = ({ hasActiveTabsSubscription, songs, width }) => {
  const songDisplayCount =
    width >= 1200 ? 5 : width >= 992 ? 4 : width >= 768 ? 3 : width >= 576 ? 2 : 1;

  return (
    <>
      <div className="latest-releases">
        <h2
          style={{ paddingTop: hasActiveTabsSubscription ? (width > 576 ? "90px" : "80px") : null }}
        >
          latest releases
        </h2>
        <div className="latest-releases__song-grid">
          {songs.slice(0, songDisplayCount).map((song, idx) => (
            <SongsGridItem key={idx} song={song} />
          ))}
        </div>
        <div className="latest-releases__nav">
          <a href="/songs" className="button button--primary">
            search all songs
          </a>
        </div>
      </div>
    </>
  );
};

LatestSongs.propTypes = {
  hasActiveTabsSubscription: PropTypes.bool.isRequired,
  songs: PropTypes.arrayOf(PropTypes.object).isRequired,
  width: PropTypes.number.isRequired,
};

export default LatestSongs;
