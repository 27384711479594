import React from "react";

export const ArrowUpEmpty = () => (
  <svg width="21" height="64" viewBox="0 0 21 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.09431 19.4254L8.09431 62.5381L12.7068 62.5381L12.7068 19.4254L18.1879 19.4254L10.4005 3.49121L2.61313 19.4254L8.09431 19.4254ZM0.498339 20.7457L6.77402 20.7457L6.77401 63.8584L14.0271 63.8584L14.0271 20.7457L20.3027 20.7457L10.4005 0.484313L0.498339 20.7457Z"
      fill="#B8BEC7"
    />
  </svg>
);

export const ArrowDownEmpty = () => (
  <svg width="20" height="64" viewBox="0 0 20 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2353 44.9173L12.2353 1.80467L7.62283 1.80467L7.62283 44.9173H2.14165L9.92906 60.8516L17.7165 44.9173H12.2353ZM19.8313 43.597H13.5556L13.5556 0.484375L6.30254 0.484375L6.30254 43.597H0.0268555L9.92906 63.8585L19.8313 43.597Z"
      fill="#B8BEC7"
    />
  </svg>
);

export const ArrowUpFilled = () => (
  <svg width="20" height="64" viewBox="0 0 20 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.44393 20.7457L0.168253 20.7457L10.0705 0.484313L19.9727 20.7457L13.697 20.7457L13.697 63.8584L6.44393 63.8584L6.44393 20.7457Z"
      fill="#1D3446"
    />
  </svg>
);

export const ArrowDownFilled = () => (
  <svg width="21" height="64" viewBox="0 0 21 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8857 43.597H20.1613L10.2591 63.8585L0.356934 43.597H6.63261V0.484375H13.8857V43.597Z"
      fill="#1D3446"
    />
  </svg>
);

export const PlayIcon = () => (
  <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0V14L11 7L0 0Z" fill="white" />
  </svg>
);

export const PauseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="5" width="14" height="14" fill="white" />
  </svg>
);

export const PlayCircleIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12847_75211)">
      <path
        d="M10 16.5L16 12L10 7.5V16.5ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
        fill="#FD6A02"
      />
    </g>
    <defs>
      <clipPath id="clip0_12847_75211">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CourseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12905_75380)">
      <path
        d="M4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6ZM20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM12 14.5V5.5L18 10L12 14.5Z"
        fill="#FD6A02"
      />
    </g>
    <defs>
      <clipPath id="clip0_12905_75380">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const LinkIcon = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.7172 1.28836C15.8835 0.454638 14.7846 0 13.6099 0C12.4351 0 11.3362 0.454773 10.5025 1.28836L8.47502 3.2969C8.07706 3.69486 8.07706 4.31999 8.47502 4.69904C8.87299 5.09701 9.49812 5.09701 9.87717 4.69904L11.8856 2.69064C12.795 1.7812 14.3867 1.7812 15.2772 2.69064C16.2056 3.6191 16.2056 5.15385 15.2772 6.08224L11.9614 9.45489C11.0519 10.3643 9.46023 10.3643 8.56976 9.45489C8.1718 9.05693 7.54667 9.05693 7.16761 9.45489C6.76965 9.85286 6.76965 10.478 7.16761 10.857C8.00134 11.6908 9.10029 12.1454 10.275 12.1454C11.4497 12.1454 12.5487 11.6906 13.3824 10.857L16.7361 7.5033C17.5698 6.66958 18.0245 5.57063 18.0245 4.39592C18.0245 3.22107 17.5508 2.12212 16.7172 1.28839L16.7172 1.28836Z"
      fill="#1D3446"
    />
    <path
      d="M8.11435 13.2824L6.10595 15.3098C5.17749 16.2383 3.64275 16.2383 2.71435 15.3098C1.78589 14.3814 1.78589 12.8466 2.71435 11.9182L6.06809 8.56448C6.99655 7.63602 8.53129 7.63602 9.45969 8.56448C9.85765 8.96244 10.4828 8.96244 10.8618 8.56448C11.2598 8.16651 11.2598 7.54138 10.8618 7.16233C9.13758 5.43808 6.35234 5.43808 4.64706 7.16233L1.29319 10.4971C-0.431064 12.2213 -0.431064 15.0066 1.29319 16.7118C2.14579 17.5644 3.28271 18.0002 4.40058 18.0002C5.51844 18.0002 6.65536 17.5644 7.50796 16.7118L9.51636 14.7034C9.91433 14.3055 9.91433 13.6803 9.51636 13.3013C9.13758 12.9035 8.51228 12.9035 8.11432 13.2824L8.11435 13.2824Z"
      fill="#1D3446"
    />
  </svg>
);

export const MinusIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12846_74221)">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H7V11H17V13Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12846_74221">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const PlusIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12846_74220)">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12846_74220">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const FiltersIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 6H12V0H14V2H18V4H14V6ZM0 4V2H10V4H0ZM0 16V14H6V16H0ZM10 18V16H18V14H10V12H8V18H10ZM4 8V6H6V12H4V10H0V8H4ZM18 10V8H8V10H18Z"
      fill="white"
    />
  </svg>
);

export const Play2Icon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12884_75772)">
      <path
        d="M10 16.5L16 12L10 7.5V16.5ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12884_75772">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const DotsIcon = () => (
  <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4C3.1 4 4 3.1 4 2C4 0.900002 3.1 0 2 0C0.9 0 0 0.900002 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14Z"
      fill="#1D3446"
    />
  </svg>
);

export const PencilIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.16406 17.2496V20.9996H6.91406L17.9741 9.93957L14.2241 6.18957L3.16406 17.2496ZM20.8741 7.03957C21.2641 6.64957 21.2641 6.01957 20.8741 5.62957L18.5341 3.28957C18.1441 2.89957 17.5141 2.89957 17.1241 3.28957L15.2941 5.11957L19.0441 8.86957L20.8741 7.03957Z"
      fill="#1D3446"
    />
  </svg>
);

export const TrashIcon = ({ color = "#1D3446" }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.16406 19C6.16406 20.1 7.06406 21 8.16406 21H16.1641C17.2641 21 18.1641 20.1 18.1641 19V7H6.16406V19ZM19.1641 4H15.6641L14.6641 3H9.66406L8.66406 4H5.16406V6H19.1641V4Z"
      fill={color}
    />
  </svg>
);

export const ResetIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.34828 2.35C3.79828 0.9 5.78828 0 7.99828 0C12.4183 0 15.9883 3.58 15.9883 8C15.9883 12.42 12.4183 16 7.99828 16C4.26828 16 1.15828 13.45 0.268282 10H2.34828C3.16828 12.33 5.38828 14 7.99828 14C11.3083 14 13.9983 11.31 13.9983 8C13.9983 4.69 11.3083 2 7.99828 2C6.33828 2 4.85828 2.69 3.77828 3.78L6.99828 7H-0.00171852V0L2.34828 2.35Z"
      fill="#323232"
    />
  </svg>
);

export const YouTubeIcon = () => (
  <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12998_77297)">
      <path
        d="M66.6446 17.5259C66.2601 16.0792 65.5025 14.7589 64.4474 13.6971C63.3924 12.6353 62.0769 11.8692 60.6328 11.4754C55.3164 10.0459 34.0664 10.0459 34.0664 10.0459C34.0664 10.0459 12.8164 10.0459 7.50004 11.4754C6.05587 11.8692 4.74044 12.6353 3.68539 13.6971C2.63034 14.7589 1.87267 16.0792 1.48822 17.5259C0.0664064 22.8654 0.0664062 34.0004 0.0664062 34.0004C0.0664062 34.0004 0.0664064 45.1354 1.48822 50.475C1.87267 51.9216 2.63034 53.242 3.68539 54.3038C4.74044 55.3656 6.05587 56.1317 7.50004 56.5254C12.8164 57.955 34.0664 57.955 34.0664 57.955C34.0664 57.955 55.3164 57.955 60.6328 56.5254C62.0769 56.1317 63.3924 55.3656 64.4474 54.3038C65.5025 53.242 66.2601 51.9216 66.6446 50.475C68.0664 45.1354 68.0664 34.0004 68.0664 34.0004C68.0664 34.0004 68.0664 22.8654 66.6446 17.5259Z"
        fill="#FF0302"
      />
      <path d="M27.1133 44.1109V23.8887L44.886 33.9998L27.1133 44.1109Z" fill="#FEFEFE" />
    </g>
    <defs>
      <clipPath id="clip0_12998_77297">
        <rect width="68" height="68" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
