import React from "react";
import PropTypes from "prop-types";

const EnrollmentSuccess = ({ displayable }) => {
  if (!displayable) return null;

  return (
    <div className="section" id="plans">
      <h2 className="title">thank you for enrolling!</h2>
      <div className="payment-success">
        <p>You have successfully enrolled into the Practical Music Theory course.</p>

        <div style={{ display: "flex" }}>
          <a href="/classes/practical-music-theory" className="button button--primary">
            go to course
          </a>
        </div>
      </div>
    </div>
  );
};

export default EnrollmentSuccess;

EnrollmentSuccess.propTypes = {
  displayable: PropTypes.bool,
};
