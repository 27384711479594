import { fileTypes } from "../shared/FileIcon";

export const filterTypes = {
  Instrument: "Instrument",
  ["Difficulty Level"]: "Difficulty Level",
  Chords: "Chords",
  Tags: "Tags",
  ["Books & Apps"]: "Books & Apps",
  Genre: "Genre",
  Feature: "Feature",
  "Time Signature": "Time Signature",
};

export const filtersByType = {
  [filterTypes.Instrument]: ["Guitar", "Ukulele"],
  [filterTypes["Difficulty Level"]]: [
    "Beginner Grade 1",
    "Beginner Grade 2",
    "Beginner Grade 3",
    "Intermediate Grade 4",
    "Intermediate Grade 5",
    "Intermediate Grade 6",
    "Advanced Grade 7",
    "Advanced Grade 8",
    "Advanced Grade 9",
  ],
  [filterTypes.Chords]: ["A", "Am", "C", "D", "Dm", "E", "Em", "F", "Fmaj7", "G"],
  [filterTypes.Tags]: [
    "Just 2 Chords",
    "Just 3 Chords",
    "Just 4 Chords",
    "Acoustic",
    "Fingerstyle",
    "Solo",
    "Riff",
    "Power Chords",
    "Open Tuning",
    "Drop D",
    "Double Drop D",
    "Open D or E",
    "Open G or A",
    "Campfire Classic",
    "Christmas Songs",
  ],
  [filterTypes["Books & Apps"]]: [
    "JustinGuitar Lessons & Songs App",
    "Beginner Songbook 1",
    "Beginner Songbook 2",
    "Rock Songbook",
    "Pop Songbook",
    "Acoustic Songbook",
    "Vintage Songbook",
    "Christmas Songbook",
    "Aussie Songbook",
    "Blues Lead Solos",
  ],
  [filterTypes.Genre]: [
    "Alternative",
    "Blues",
    "Christmas",
    "Country",
    "Folk",
    "Funk",
    "Grunge",
    "Indie",
    "Jazz",
    "Metal",
    "Pop",
    "Punk",
    "Reggae",
    "Rock",
    "Rock 'n' Roll",
    "Soul",
  ],
  [filterTypes.Feature]: ["JustinGuitarTABS", "JustinGuitarCHORDS"],
  [filterTypes["Time Signature"]]: ["6/8"],
};

export const filters = [
  {
    type: filterTypes.Instrument,
    options: filtersByType[filterTypes.Instrument],
  },
  {
    type: filterTypes["Difficulty Level"],
    options: filtersByType[filterTypes["Difficulty Level"]],
  },
  {
    type: filterTypes.Chords,
    info: "All chords in the song need to be selected before the song shows in the results.",
    options: filtersByType[filterTypes.Chords],
  },
  {
    type: filterTypes.Tags,
    options: filtersByType[filterTypes.Tags],
  },
  {
    type: filterTypes["Books & Apps"],
    options: filtersByType[filterTypes["Books & Apps"]],
  },
  {
    type: filterTypes.Genre,
    options: filtersByType[filterTypes.Genre],
  },
  { type: filterTypes.Feature, options: filtersByType[filterTypes.Feature] },
  {
    type: filterTypes["Time Signature"],
    options: filtersByType[filterTypes["Time Signature"]],
  },
];

export const filterTypeToQueryValue = {
  [filterTypes.Genre]: "genre",
  [filterTypes.Instrument]: "instrument",
  [filterTypes["Difficulty Level"]]: "grade",
  [filterTypes["Books & Apps"]]: "product",
  [filterTypes.Chords]: "chords",
  [filterTypes.Tags]: "tags",
  [filterTypes.Feature]: "feature",
  [filterTypes["Time Signature"]]: "signature",
};

export const filterOptionToQueryValue = {
  Guitar: "guitar",
  Ukulele: "ukulele",
  ["Beginner Grade 1"]: "white",
  ["Beginner Grade 2"]: "yellow",
  ["Beginner Grade 3"]: "orange",
  ["Intermediate Grade 4"]: "green",
  ["Intermediate Grade 5"]: "blue",
  ["Intermediate Grade 6"]: "purple",
  ["Advanced Grade 7"]: "red",
  ["Advanced Grade 8"]: "brown",
  ["Advanced Grade 9"]: "",
  Alternative: "alternative",
  Blues: "blues",
  Christmas: "christmas",
  Country: "country",
  Folk: "folk",
  Funk: "funk",
  Grunge: "grunge",
  Indie: "indie",
  Jazz: "jazz",
  Metal: "metal",
  Pop: "pop",
  Punk: "punk",
  Reggae: "reggae",
  Rock: "rock",
  ["Rock 'n' Roll"]: "rock 'n' roll",
  Soul: "soul",
  ["JustinGuitar Lessons & Songs App"]: "Beginner Guitar App",
  ["Beginner Songbook 1"]: "Beginner Songbook 1",
  ["Beginner Songbook 2"]: "Beginner Songbook 2",
  ["Rock Songbook"]: "Rock Songbook",
  ["Pop Songbook"]: "Pop Songbook",
  ["Acoustic Songbook"]: "Acoustic Songbook",
  ["Vintage Songbook"]: "Vintage Songbook",
  ["Christmas Songbook"]: "Christmas Songbook",
  ["Aussie Songbook"]: "Aussie Songbook",
  ["Blues Lead Solos"]: "Blues Lead Solos",
  A: "A",
  Am: "Am",
  C: "C",
  D: "D",
  Dm: "Dm",
  E: "E",
  Em: "Em",
  F: "F",
  Fmaj7: "Fmaj7",
  G: "G",
  ["Christmas Songs"]: "Christmas Songs",
  Acoustic: "Acoustic",
  Fingerstyle: "Fingerstyle",
  ["Campfire Classic"]: "Campfire Classic",
  ["Power Chords"]: "Power Chords",
  ["Open Tuning"]: "Open Tuning",
  ["Just 2 Chords"]: "Just 2 Chords",
  ["Just 3 Chords"]: "Just 3 Chords",
  ["Just 4 Chords"]: "Just 4 Chords",
  Solo: "Solo",
  Riff: "Riff",
  ["Drop D"]: "Drop D",
  ["Double Drop D"]: "Double Drop D",
  ["Open D or E"]: "Open D or E",
  ["Open G or A"]: "Open G or A",
  ["Mega Popular"]: "Mega Popular",
  JustinGuitarTABS: "JustinGuitarTABS",
  JustinGuitarCHORDS: "JustinGuitarCHORDS",
  ["6/8"]: "6/8",
};
