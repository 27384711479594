export function getArtistNameFromQueryString() {
  const artistName = decodeURIComponent(
    window.location.search.replace(
      new RegExp(
        "^(?:.*[&\\?]" +
          encodeURIComponent("artist").replace(/[\.\+\*]/g, "\\$&") +
          "(?:\\=([^&]*))?)?.*$",
        "i"
      ),
      "$1"
    )
  );

  return artistName && artistName.length ? artistName : undefined;
}

export function updateQueryString(key, value) {
  let url = window.location.href;

  const re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi");

  if (re.test(url)) {
    if (typeof value !== "undefined" && value !== null) {
      url = url.replace(re, "$1" + key + "=" + value + "$2$3");
    } else {
      const hash = url.split("#");
      url = hash[0].replace(re, "$1$3").replace(/(&|\?)$/, "");
      if (typeof hash[1] !== "undefined" && hash[1] !== null) {
        url += "#" + hash[1];
      }
    }
  } else {
    if (typeof value !== "undefined" && value !== null) {
      const separator = url.indexOf("?") !== -1 ? "&" : "?";
      const hash = url.split("#");
      url = hash[0] + separator + key + "=" + value;
      if (typeof hash[1] !== "undefined" && hash[1] !== null) {
        url += "#" + hash[1];
      }
    }
  }

  // update query params without reload
  window.history.pushState({ path: url }, "", url);
}
