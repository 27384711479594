import React from 'react';
import { CloseIcon } from './icon.components';

export const ArtistCard = ({ artist, setActiveArtistTab, setShowDetails, width }) => {
    return (
      <div className="card">
        {!artist.showDetails ? (
          <div className="front" style={{ backgroundImage: `url(${artist.largeImage})` }}>
            <img src={artist.nameLogo} alt={artist.name} />
            <button className="button button--primary" onClick={() => setShowDetails(artist, true)}>
              view artist info
            </button>
            <p>{artist.description}</p>
          </div>
        ) : (
          <div className="details">
            <button onClick={(e) => setShowDetails(artist, false)}>
              <CloseIcon />
            </button>
            <div className="header">
              <img src={artist.nameLogo} alt={artist.name} />
            </div>
            <div className="tabs">
              <div className="nav">
                <button
                  className={artist.activeTab == "about" ? "active" : undefined}
                  onClick={() => setActiveArtistTab(artist, "about")}
                >
                  About
                </button>
                <button
                  className={artist.activeTab == "qualifications" ? "active" : undefined}
                  onClick={() => setActiveArtistTab(artist, "qualifications")}
                >
                  Qualifications
                </button>
                <button
                  className={artist.activeTab == "specialty" ? "active" : undefined}
                  onClick={() => setActiveArtistTab(artist, "specialty")}
                >
                  Specialty
                </button>
                <button
                  className={artist.activeTab == "lessonDetails" ? "active" : undefined}
                  onClick={() => setActiveArtistTab(artist, "lessonDetails")}
                >
                  {`Lesson${width > 768 ? " Details" : ""}`}
                </button>
              </div>
              <div className="content">
                <p dangerouslySetInnerHTML={{ __html: artist.content[artist.activeTab] }}></p>
                {artist.activeTab == "lessonDetails" && (
                  <p className="info">
                    Please note that JustinGuitar doesn't take any commission from the teachers.
                  </p>
                )}
              </div>
            </div>
            <div className="footer">
              <p>Use the info below to reach out</p>
              <div className="contacts">
                {artist.contacts.map((contact, idx) =>
                  typeof contact === "object" && contact !== null ? (
                    <a key={idx} href={`${contact.url}`} target="_blank">
                      {contact.name}
                    </a>
                  ) : contact.startsWith("+") || contact.match(/^\d/) ? (
                    <a
                      key={idx}
                      href="#"
                      data-country={contact.split(" ")[0]}
                      data-number={contact.substring(contact.indexOf(" "))}
                      className="phone"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href =
                          "tel:" +
                          e.currentTarget.dataset.country +
                          (e.currentTarget.dataset.number?.replace(/[ )(]/g, "") || '');
                        return;
                      }}
                    ></a>
                  ) : contact.includes("@") ? (
                    <a
                      key={idx}
                      href="#"
                      data-name={contact.split("@")[0]}
                      data-domain={contact.split("@")[1]}
                      className="email"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href =
                          "mailto:" +
                          e.currentTarget.dataset.name +
                          "@" +
                          e.currentTarget.dataset.domain;
                        return;
                      }}
                    ></a>
                  ) : (
                    <a key={idx} href={`https://${contact}`} target="_blank">
                      {contact}
                    </a>
                  )
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  