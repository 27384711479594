import React, { useState, useEffect } from "react";
import ReactOnRails from "react-on-rails";
import { Provider } from "react-redux";
import "./store/strummingMachineStore";
import { withBootstrapSize } from "../../components/shared/WithBootstrapSize";
import "../../components/shared/polyfills/replaceAll.js";
import StrummingMachineApp from "./StrummingMachineApp";
import { CDN_URL } from "../../components/shared/CdnUrl";

const StrummingMachine = ({ strummingPatterns, width }) => {
  const store = ReactOnRails.getStore("strummingMachineStore");

  return (
    <Provider store={store}>
      <div className="strumming-machine container">
        {!store.getState().session.currentUser ? (
          <h1>
            <img
              src={`${CDN_URL}/images/components/strumming-machine/strumming-machine-logo-default${
                width <= 768 ? "-sm" : ""
              }.png`}
              alt="Strumming Machine"
            />
          </h1>
        ) : (
          <h1 style={{ marginBottom: "30px" }}>
            <img
              src={`${CDN_URL}/images/components/strumming-machine/strumming-machine-logo-main-v3.png`}
              alt="Strumming Machine"
              style={{ maxWidth: "600px", width: "100%" }}
            />
          </h1>
        )}
        <StrummingMachineApp strummingPatternData={strummingPatterns} />
      </div>
    </Provider>
  );
};

export default withBootstrapSize(StrummingMachine);
