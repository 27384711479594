import { createSlice } from "@reduxjs/toolkit";
import { CurrentUser } from "../../../../../interfaces/current-user";

export interface SessionState {
  currentUser?: CurrentUser | null;
  userIsRegisteredForWorkshop?: boolean; // todo: how can we handle page-dependent session info?
  userCanPayDeposit?: boolean; // todo: how can we handle page-dependent session info?
}

export const initialSessionState: SessionState = {};

export const sessionSlice = createSlice({
  name: "session",
  initialState: initialSessionState,
  reducers: {
    setCurrentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    clearCurrentUser: (state) => {
      state.currentUser = null;
    },
  },
});
