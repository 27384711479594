import React, { useEffect, useState, useRef } from "react";
import { ContentCarousel } from "../components/ContentCarousel";
import { FacebookLogo, IGLogo, TikTokLogo, YouTubeIcon } from "./consts";
import { formatNumberShort } from "../utilities/misc";
import Loading from "../components/shared/Loading";
import axiosWithCSRF from "../components/shared/axiosWithCSRF";

const CONTENT_TYPES = {
  lessons: "Lessons",
  songs: "Song Tutorials",
  playground: "Playground",
};

const API_STATS_BY_NAME = {
  youtubeMain: "youtube_subscribers",
  youtubeSongs: "youtube_song_subscribers",
  instagram: "ig_followers",
  tiktok: "tiktok_followers",
  facebook: "facebook_likes",
};

const SocialBanner = ({ activeGroup, apiStats, width }) => {
  const statisticFor = (name) => {
    return apiStats.find((stat) => stat.title === name)?.statistic;
  };

  if (activeGroup === CONTENT_TYPES.lessons) {
    return (
      <div className="yt-subscribers">
        <a href="https://www.youtube.com/@justinguitar" target="_blank">
          <YouTubeIcon />
          <strong>
            {formatNumberShort(statisticFor(API_STATS_BY_NAME.youtubeMain))} Subscribers
          </strong>
          {width > 768 && " on My YouTube Main Channel"}
        </a>
      </div>
    );
  } else if (activeGroup === CONTENT_TYPES.songs) {
    return (
      <div className="yt-subscribers">
        <a href="https://www.youtube.com/@JustinGuitarSongs" target="_blank">
          <YouTubeIcon />
          <strong>
            {formatNumberShort(statisticFor(API_STATS_BY_NAME.youtubeSongs))} Subscribers
          </strong>
          {width > 768 && " on My YouTube Song Channel"}
        </a>
      </div>
    );
  } else if (activeGroup === CONTENT_TYPES.playground) {
    return (
      <div className="yt-subscribers">
        <a href="https://www.instagram.com/justinguitar" target="_blank">
          <IGLogo />
          <strong>
            {formatNumberShort(statisticFor(API_STATS_BY_NAME.instagram))}{" "}
            {width > 768 && " Followers"}
          </strong>
        </a>
        <a href="https://www.tiktok.com/@justinguitarofficial" target="_blank">
          <TikTokLogo />
          <strong>
            {formatNumberShort(statisticFor(API_STATS_BY_NAME.tiktok))}{" "}
            {width > 768 && " Followers"}
          </strong>
        </a>
        <a href="https://www.facebook.com/justinguitarofficial" target="_blank">
          <FacebookLogo />
          <strong>
            {formatNumberShort(statisticFor(API_STATS_BY_NAME.facebook))}{" "}
            {width > 768 && " Followers"}
          </strong>
        </a>
      </div>
    );
  }
};

const NewContent = ({ lastFiveLessons, width, apiStats }) => {
  const [loading, setLoading] = useState(false);

  const draggingRef = useRef(false);

  const handleMouseDown = () => {
    draggingRef.current = false;
  };

  const handleMouseMove = () => {
    draggingRef.current = true;
  };

  const handleMouseUp = (lesson) => {
    if (!draggingRef.current) {
      window.location.href = routeToLesson(lesson);
    }
  };

  const [activeGroup, setActiveGroup] = useState(CONTENT_TYPES.lessons);
  const [lessons, setLessons] = useState(lastFiveLessons);

  const routeToLesson = (lesson) => {
    if ([CONTENT_TYPES.lessons, CONTENT_TYPES.playground].includes(activeGroup)) {
      return `/guitar-lessons/${lesson.slug}`;
    } else {
      return `/songs/${lesson.slug}`;
    }
  };

  useEffect(() => {
    if (activeGroup === CONTENT_TYPES.lessons) {
      setLessons(lastFiveLessons);
      return;
    }

    setLoading(true);
    const route =
      activeGroup === CONTENT_TYPES.songs
        ? "/last-five-songs-for-homepage.json"
        : "/last-five-playground-lessons-for-homepage.json";

    axiosWithCSRF()
      .get(route)
      .then((response) => {
        setLessons(response.data.items);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [activeGroup]);

  return (
    <div className="new-content">
      <h2>+ New FREE LESSONS added every WEEK.</h2>
      <div className="new-content-nav">
        {Object.keys(CONTENT_TYPES).map((key) => (
          <button
            key={key}
            className={activeGroup === CONTENT_TYPES[key] ? "active" : ""}
            onClick={() => setActiveGroup(CONTENT_TYPES[key])}
          >
            {CONTENT_TYPES[key]}
          </button>
        ))}
      </div>

      <div className="yt-container">
        {loading ? (
          <div>
            <Loading isContent={true} />
          </div>
        ) : (
          <ContentCarousel settings={{ slidesToShow: 1, dots: true, initialSlide: 0 }}>
            {lessons.map((lesson, idx) => (
              <div
                key={idx}
                className="yt-video"
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={() => handleMouseUp(lesson)}
              >
                <div
                  className="yt-video-thumb"
                  style={
                    lesson.thumbnails && lesson.thumbnails.standard
                      ? { backgroundImage: `url(${lesson.thumbnails.standard})` }
                      : {}
                  }
                ></div>
              </div>
            ))}
          </ContentCarousel>
        )}
      </div>

      <SocialBanner activeGroup={activeGroup} apiStats={apiStats} width={width} />

      <a href="/guitar-lessons" className="button button--primary">
        view all lessons
      </a>
    </div>
  );
};

export default NewContent;
