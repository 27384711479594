import React from "react";
import { connect } from "react-redux";
import { savedSongItem } from "../../components/shared/session/sessionSelectors";
import { createSongItem, deleteSongItem } from "../../components/shared/session/sessionEffects";

const SaveSong = (props) => {
  const songParams = {
    user_item: {
      user_id: props.currentUser.data.id,
      itemable_type: "Song",
      itemable_id: props.song.data.id,
      item_status: "saved",
    },
  };

  return (
    <div
      className={
        "song__action-btn clickable " + (props.savedSongItem ? "song__action-btn--saved" : "")
      }
      onClick={
        props.savedSongItem
          ? () => props.deleteSong(props.savedSongItem)
          : () => props.saveSong(songParams)
      }
    >
      <i className={`icon icon-heart${props.savedSongItem ? "" : "-outline"}`} />
      {`${props.savedSongItem ? "Saved" : "Save"}`} to favorites
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  song: state.entity.song,
  savedSongItem: savedSongItem(state),
});

const mapDispatchToProps = (dispatch) => ({
  saveSong: (item) => dispatch(createSongItem(item)),
  deleteSong: (item) => dispatch(deleteSongItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveSong);
