import React from "react";
import "../../components/shared/polyfills/replaceAll.js";

export const NOTES = ["A", "A#", "B", "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#"];
export const SHARP_NOTES = ["A#", "B", "B#", "C#", "D", "D#", "E", "E#", "F#", "G", "G#", "A"];
export const FLAT_NOTES = ["Ab", "A", "Bb", "Cb", "C", "Db", "D", "Eb", "Fb", "F", "Gb", "G"];
const NOTE_MAP_BY_INDEX = {
  1: "0",
  2: "2",
  3: "4",
  4: "5",
  5: "7",
  6: "9",
  7: "11",
  8: "0",
};

function selectNoteCollection(numberOfNote) {
  if (numberOfNote.split("").length === 1) {
    return NOTES;
  } else if (numberOfNote.split("")[0] === "s") {
    return SHARP_NOTES;
  } else if (numberOfNote.split("")[0] === "b") {
    return FLAT_NOTES;
  } else {
    return NOTES;
  }
}

function numberOfNoteFormatted(note) {
  let splitNote = note.split("");
  return splitNote[splitNote.length - 1];
}

function calculateNote(match, musicKey) {
  let numberOfNote = match.split("-")[0];
  let modifier = match.split("-")[1] || "";
  modifier = modifier.replace("\\", "/");
  let noteCollection = selectNoteCollection(numberOfNote);
  let note = numberOfNoteFormatted(numberOfNote);
  const startingIndex = NOTES.indexOf(musicKey);
  let endingIndex = startingIndex + parseInt(NOTE_MAP_BY_INDEX[note]);
  if (endingIndex < 0) {
    return `${noteCollection[noteCollection.length + endingIndex]}${modifier}`;
  } else if (endingIndex >= noteCollection.length) {
    return `${noteCollection[endingIndex - noteCollection.length]}${modifier}`;
  } else {
    return `${noteCollection[endingIndex]}${modifier}`;
  }
}

function calculateSplitNote(match, musicKey) {
  if (match.includes("-") && match.indexOf("-") < match.indexOf("\\")) {
    // here the slash note should preserve the number.
    // e.g, Gsus2/4  should be [1-sus2/4]
    return calculateNote(match, musicKey);
  } else {
    let notes = match.split("/").filter((n) => n && n != "");
    if (notes.length > 1) {
      let calculated = notes.map((n) => calculateNote(n, musicKey));
      let finalized = calculated.filter((n) => n && n != "" && n != "undefined");
      return finalized.length > 1 ? finalized.join("/") : finalized[0];
    } else {
      return calculateNote(notes[0], musicKey);
    }
  }
}

function handleIndividualWord(word, match, defaultKey) {
  let note = match[1].includes("/")
    ? calculateSplitNote(match[1], defaultKey)
    : calculateNote(match[1], defaultKey);
  let content;
  if (word === match[0]) {
    content = `<span style="position:relative;" class="chord__${note} stacked-chord"><span style="position:absolute;top: ${topPosition()};">\
      ${note ? note : "?"}</span>&nbsp;</span>`;
  } else {
    content = word.replace(
      match[0],
      `<span style="position:relative;" class="chord__${note} stacked-chord"><span style="position:absolute;top: ${topPosition()};">\
      ${note ? note : "?"}</span></span>`
    );
    content = content.replace("&nbsp;", "");
  }
  return content;
}

function barLineHtml(word) {
  let barLine = `<span style="position:relative;" class="chord__bar">\
    <span style="position:absolute;top: ${topBarLinePosition()};">|</span></span>`;
  return word.replace("[|]", barLine);
}

function topBarLinePosition() {
  return window.innerWidth < 576 ? "-30px" : "-30px";
}

function topPosition() {
  return window.innerWidth < 576 ? "-30px" : "-30px";
}

function handleMultipleBrackets(word, defaultKey) {
  let split = word.split("][");
  let individualBrackets = [];
  split.map((w) => {
    if (w.includes("]")) {
      individualBrackets.push(`[${w}`);
    } else if (w.includes("[")) {
      individualBrackets.push(`${w}]`);
    } else {
      individualBrackets.push(`[${w}]`);
    }
  });
  return individualBrackets.map((snippet) => findMatchAndFormat(snippet, defaultKey)).join("");
}

function findMatchAndFormat(word, defaultKey) {
  let match = word.match(/\[((.)+)\]/);
  if (match && match[0] === "[|]") {
    return barLineHtml(word);
  } else if (match) {
    return handleIndividualWord(word, match, defaultKey);
  }
}

function handleSpecialContent(word) {
  let content = word.match(/\%([^)]+)\%/)[0];
  let htmlContent = `<span style="position: relative;">\
      <span style="position:absolute;top: ${topPosition()};">${content.replaceAll("%", "")}</span> \
    </span>`;
  return word.replace(content, htmlContent);
}

export const formattedSheetMusic = (sheetMusic, defaultKey, fontSize = "14px") => {
  let splitByParagraph = sheetMusic.replace("&nbsp;", " ").split("<p>");
  let formatted = [];
  splitByParagraph.map((p) => {
    let split = p.split(" ");
    split.map((word) => {
      if (word.includes("[") && word.includes("]")) {
        let content = word;
        if (word.includes("][")) {
          content = handleMultipleBrackets(word, defaultKey);
        } else {
          content = findMatchAndFormat(word, defaultKey);
        }
        formatted.push(content);
      } else if (word.match(/\%([^)]+)\%/)) {
        formatted.push(handleSpecialContent(word));
      } else {
        formatted.push(`<span>${word}</span>`);
      }
    });
  });

  let final = formatted
    .join(" ")
    .replace(
      /<h3>/g,
      '</div></div><div class="sheet-section"><div class="sheet-section__left"><h3>'
    )
    .replace(/<\/h3>/g, '</h3></div><div class="sheet-section__right">');

  return (
    <div className="song-sheet__container">
      <div className="song-sheet__cover"></div>
      <div className="song-sheet__html" dangerouslySetInnerHTML={{ __html: final }}></div>
    </div>
  );
};
