import React from "react";
import Slick from "react-slick";

export default class UniqueFeatures extends React.Component {
  slickSettings() {
    return {
      dots: true,
      dotsClass: "unique-features--options",
      arrows: false,
      autoplay: false,
      customPaging: (idx) => <div>{this.props.pages[idx].title}</div>,
    };
  }

  render() {
    return (
      <Slick {...this.slickSettings()}>
        {this.props.pages.map(({ title, imageUrl }) => (
          <img src={imageUrl} alt="" key={title} />
        ))}
      </Slick>
    );
  }
}
