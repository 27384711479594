import React, { useState } from "react";
import { CDN_URL, DRAGONFLY_PATH } from "../components/shared/CdnUrl";
import ProductList from "./ProductList";
import { AppStoreIcon, PlayStoreIcon } from "../components/shared/AppIcons";
import { productTypeToQueryString } from "./productTypes";
import { productTypeHeaderInfo } from "./ProductsIndexHeader";

const Songlist = ({ songlist }) => {
  const [songlistOpen, setSonglistOpen] = useState(false);
  const truncated = songlist.split("<br />").slice(0, 4).join("<br /> ");

  return (
    <div>
      <span className="bold product__subheading">Songlist</span>
      <div
        className="songlist"
        dangerouslySetInnerHTML={{ __html: songlistOpen ? songlist : truncated }}
      />
      <span className="preview-btn" onClick={() => setSonglistOpen(!songlistOpen)}>
        {songlistOpen ? "- see less" : "+ see more"}
      </span>
    </div>
  );
};

const ProductDetail = ({ product, relatedProducts }) => {
  const { typeOf, title, image, description, suppliers, appStoreLink, googlePlayLink, songlist } =
    product.data.attributes;
  return (
    <div className="product-detail container">
      {/* <div className="breadcrumb swoosh-breadcrumb">
        <a href="/products">Products</a>
        <span className="divider"> {">"}</span>
        <a href={`/products?type=${productTypeToQueryString(typeOf)}`}>
          {productTypeHeaderInfo[typeOf]["title"]}
        </a>
        <span className="divider"> {">"}</span>
        <span>{title}</span>
      </div> */}

      <div className="product-detail__top">
        <div className="product-detail__image">
          <img src={image} alt={title} />
        </div>
        <div className="product-detail__description">
          <h2>{title}</h2>

          {description != null && description.length > 0 ? (
            <>
              <div className="bold product__subheading">Overview</div>
              <div dangerouslySetInnerHTML={{ __html: description }}></div>
            </>
          ) : null}

          {songlist && songlist.length > 0 ? <Songlist songlist={songlist} /> : null}

          {Array.isArray(suppliers) && suppliers.length ? (
            <>
              <div className="bold product__subheading">Buying Options</div>
              {suppliers.map((supplier) => (
                <a
                  className="button button--blue button--inline supplier-link"
                  target="_blank"
                  href={supplier.externalUrl}
                >
                  Purchase from {supplier.supplierName}
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.5897e-07 6.75L9.1275 6.75L4.935 10.9425L6 12L12 6L6 -5.24537e-07L4.9425 1.0575L9.1275 5.25L5.90104e-07 5.25L4.5897e-07 6.75Z"
                      fill="white"
                    />
                  </svg>
                </a>
              ))}
            </>
          ) : null}

          {appStoreLink || googlePlayLink ? (
            <div className="app-store-buttons">
              {appStoreLink && <AppStoreIcon dark appStoreLink={appStoreLink} />}
              {googlePlayLink && <PlayStoreIcon dark googlePlayLink={googlePlayLink} />}
              {/* <AppIcons dark appStoreLink={appStoreLink} googlePlayLink={googlePlayLink} /> */}
            </div>
          ) : null}
        </div>
      </div>

      {Array.isArray(relatedProducts) && relatedProducts.length && (
        <div className="related-products">
          <h2 className="uppercase">You Might Also Like</h2>
          <ProductList products={relatedProducts} />
        </div>
      )}
    </div>
  );
};

export default ProductDetail;
