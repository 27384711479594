import { createSelector } from "@reduxjs/toolkit";
import "../polyfills/flat.js";

const selectUser = (state) => {
  if (state.parentGroup) return state.parentGroup?.session?.currentUser;

  return state.session ? state.session.currentUser : null;
};
export const selectSong = (state) => state.entity.song;

export const savedSongItem = createSelector(selectUser, selectSong, (user, song) => {
  const songId = song.data.id;
  return (
    user &&
    user.included.filter(
      (i) =>
        i &&
        i.type === "userItem" &&
        i.attributes.itemableId === parseInt(songId) &&
        i.attributes.itemableType === "Song"
    )[0]
  );
});

const songPracticeItemId = createSelector(selectSong, (song) => {
  const item = song.included.filter((s) => s.type === "practiceItem")[0];
  return item && item.id;
});

export const savedSongPracticeItem = createSelector(
  selectUser,
  songPracticeItemId,
  (user, practiceId) => {
    return (
      user &&
      user.included.filter(
        (i) =>
          i &&
          i.type === "userPracticeItem" &&
          parseInt(i.attributes.practiceItemId) === parseInt(practiceId)
      )[0]
    );
  }
);

export const userPracticeItems = createSelector(selectUser, (user) => {
  return user && user.included && user.included.filter((i) => i.type === "userPracticeItem");
});

export const userPracticeRoutines = createSelector(selectUser, (user) => {
  return user && user.included && user.included.filter((i) => i.type === "userPracticeRoutine");
});

const userGroupAndLessonItems = createSelector(selectUser, (user) => {
  if (user?.included) {
    return user.included.filter(
      (i) => i.type === "userItem" && ["Group", "Lesson"].includes(i.attributes.itemableType)
    );
  }
});

const userItemLessonIdsByStatusFactory = (status) => {
  return createSelector(userGroupAndLessonItems, (items) => {
    if (items) {
      const userItemsByStatus = items.filter((i) => i.attributes.itemStatus === status);
      const itemIds = userItemsByStatus.map((i) => {
        return i.attributes.itemableType === "Group"
          ? i.attributes.nestedIds
          : i.attributes.itemableId;
      });
      return itemIds.flat();
    }
  });
};

export const savedLessonIds = createSelector(
  userItemLessonIdsByStatusFactory("saved"),
  (items) => items
);

export const completedLessonIds = createSelector(
  userItemLessonIdsByStatusFactory("completed"),
  (items) => items
);

export const userNotes = createSelector(selectUser, (user) => {
  if (user) {
    return user.included.filter((i) => i.type === "userNote");
  }
});

export const userSongs = createSelector(selectUser, (user) => {
  // check if the item has a title to make sure the associated song exists
  if (user) {
    return user.included.filter(
      (i) =>
        i.type === "userItem" && i.attributes.itemableType === "Song" && i.attributes.itemableTitle
    );
  }
});

export const savedUserSongs = createSelector(userSongs, (userSongs) => {
  return userSongs.filter((s) => !s.attributes.itemStatus || s.attributes.itemStatus === "saved");
});

export const completedUserSongs = createSelector(userSongs, (userSongs) => {
  return userSongs.filter((s) => s.attributes.itemStatus === "completed");
});

export const userNotesWithPracticeExcluded = createSelector(userNotes, (notes) => {
  if (notes) {
    return notes.filter((n) => n.attributes.noteableType !== "UserPracticeItem");
  }
});

export const userSettingEnabled = createSelector(
  [selectUser, (user, settingName) => settingName],
  (user, settingName) => {
    if (user) {
      return user.data.attributes.settings.includes(settingName);
    }
  }
);

export const userFavorites = createSelector(selectUser, (user) => {
  if (user) {
    const favorites = user.included.filter(
      (i) => i.type === "userItem" && i.attributes.itemableType !== "StrummingPattern"
    );

    favorites.forEach((favorite) => {
      if (
        favorite.attributes.itemableType === "Group" &&
        favorite.attributes.itemStatus === "saved" &&
        favorite.attributes.lessonsWithinModule
      ) {
        favorite.attributes.lessonsWithinModule.forEach((lesson) => {
          favorites.push({
            id: favorite.id,
            type: "userItem",
            attributes: {
              createdAt: favorite.attributes.createdAt,
              itemStatus: favorite.attributes.itemStatus,
              itemableId: lesson.id,
              itemableTitle: lesson.title,
              itemableType: "Lesson",
              itemableUrl: lesson.url,
              itemableGrade: lesson.grade,
              userId: favorite.attributes.userId,
              lessonId: lesson.id,
              youtubeDuration: lesson.youtubeDuration,
              thumbnails: lesson.thumbnails,
              vimeoThumbnail: lesson.vimeoThumbnail,
            },
          });
        });
      }
    });

    return favorites.filter((n) => n.attributes.itemableType !== "Group");
  }
});

export const userCompletedGroups = createSelector(selectUser, (user) => {
  if (user) {
    return user.included.filter(
      (i) =>
        i.type === "userItem" &&
        i.attributes.itemableType === "Group" &&
        i.attributes.itemStatus === "completed"
    );
  }
});

const userNotesByTypeFactory = (noteType) => {
  return createSelector(userNotes, (notes) => {
    if (notes) {
      return notes.filter((n) => n.attributes.noteableType === noteType);
    }
  });
};

const userFavoritesByTypeFactory = (favoriteType) => {
  return createSelector(userFavorites, (favorites) => {
    if (favorites) {
      return favorites.filter((n) => n.attributes.itemableType === favoriteType);
    }
  });
};

export const songNotes = createSelector(userNotesByTypeFactory("Song"), (notes) => notes);
export const lessonNotes = createSelector(userNotesByTypeFactory("Lesson"), (notes) => notes);
export const moduleNotes = createSelector(userNotesByTypeFactory("Group"), (notes) => notes);

export const songFavorites = createSelector(
  userFavoritesByTypeFactory("Song"),
  (favorites) => favorites
);
export const chordFavorites = createSelector(
  userFavoritesByTypeFactory("Chord"),
  (favorites) => favorites
);
export const lessonFavorites = createSelector(
  userFavoritesByTypeFactory("Lesson"),
  (favorites) => favorites
);
export const strummingPatternFavorites = createSelector(
  userFavoritesByTypeFactory("StrummingPattern"),
  (favorites) => favorites
);
