import React, { useEffect } from "react";
import { PLAN_TYPES } from "../../subscriptions/consts";
import Plan, { PlanDetails } from "./Plan";

const ExistingPlan = ({ plan }) => {
  return (
    <button className={"plan existing"} onClick={null} style={{ cursor: "default" }}>
      <div className="badge">Your current plan</div>
      <PlanDetails plan={plan} existing={true} />
    </button>
  );
};

const UpgradePlans = ({
  currentUser,
  activePlan,
  setActivePlan,
  pricingPlans,
  createCheckoutSession,
}) => {
  const currentPlan = currentUser.data.attributes.pmtSubscription.included.find(
    (t) => t.type === "pricingPlan"
  );

  const { planType, intervalInMonths } = currentPlan.attributes;
  const yearlyPlan = pricingPlans.data.find((plan) => plan.attributes.intervalInMonths === 12);
  const lifetimePlan = pricingPlans.data.find(
    (plan) => plan.attributes.planType === PLAN_TYPES.lifetime
  );

  useEffect(() => {
    setActivePlan(lifetimePlan);
  }, []);

  const plansToOffer = intervalInMonths === 1 ? [yearlyPlan, lifetimePlan] : [lifetimePlan];

  if (planType === PLAN_TYPES.lifetime) return null;

  return (
    <>
      <h2 className="title">Course plans - upgrade deal</h2>
      <div className="plans">
        <ExistingPlan plan={currentPlan} />
        {plansToOffer.map((plan) => (
          <Plan
            key={plan.id}
            plan={plan}
            activePlan={activePlan}
            currentUser={currentUser}
            setActivePlan={setActivePlan}
            upgrade={true}
          />
        ))}

        <div className="upgrade__button">
          <button className="button button--primary" onClick={createCheckoutSession}>
            upgrade now
          </button>
        </div>
      </div>
    </>
  );
};
export default UpgradePlans;
