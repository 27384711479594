import React from "react";
import Slide from "../components/Slide";

export default class Slider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlideIndex: 0,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.productToShow(), 6000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  productToShow = () => {
    let totalSlides = this.props.products.length - 1;
    let nextSlide;
    if (this.state.currentSlideIndex < totalSlides) {
      nextSlide = this.state.currentSlideIndex + 1;
    } else {
      nextSlide = 0;
    }
    this.setState({ currentSlideIndex: nextSlide, appIconsOpen: false });
  };

  handleSlideChange = (index) => {
    this.setState({ currentSlideIndex: index, appIconsOpen: false });
    clearInterval(this.interval);
    this.interval = setInterval(() => this.productToShow(), 6000);
  };

  openIcons = () => {
    this.setState({ appIconsOpen: true });
  };

  render() {
    return (
      <div>
        {/*
          {this.props.products.map((product) =>
            <Slide image={product.image_url} key={product.id} />
          )}
          */}
        {this.props.products.map((product) => (
          <Slide
            product={product}
            appIconsOpen={this.state.appIconsOpen}
            openIcons={this.openIcons}
            key={product.id}
            productIndex={this.props.products.indexOf(product)}
            currentProductIndex={this.state.currentSlideIndex}
          />
        ))}
        <div className="product-slider-dots">
          {this.props.products.map((product, index) => (
            <span
              className={
                "icon icon-circle " + (this.state.currentSlideIndex === index ? "active-dot" : "")
              }
              key={product.id}
              onClick={() => this.handleSlideChange(index)}
            ></span>
          ))}
        </div>
      </div>
    );
  }
}
