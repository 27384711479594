import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_KEY } from "./new-payments/consts";

const InvisibleRecaptcha = ({ shouldExecute, shouldReset, onSuccess }) => {
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (!shouldExecute) return null;

    executeRecaptcha();
  }, [shouldExecute]);

  useEffect(() => {
    if (!shouldReset) return null;

    resetRecaptcha();
  }, [shouldReset]);

  useEffect(() => {
    if (!recaptchaToken) return null;

    onSuccess(recaptchaToken);
  }, [recaptchaToken]);

  const resetRecaptcha = () => {
    setRecaptchaToken(null);
    const recaptchaInstance = recaptchaRef.current;
    recaptchaInstance.reset();
  };

  const executeRecaptcha = () => {
    recaptchaRef.current.execute();
  };

  return (
    <ReCAPTCHA
      size="invisible"
      sitekey={RECAPTCHA_KEY}
      onChange={(token) => setRecaptchaToken(token)}
      ref={recaptchaRef}
    />
  );
};

export default InvisibleRecaptcha;
