import React from "react";
import PropTypes from "prop-types";
import CheckEmailPrompt from "./CheckEmailPrompt";
import AccountVerified from "./AccountVerified";
import RegistrationForm from "./RegistrationForm";

export default class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recaptchaToken: null,
      validUsernameOptions: [],
    };
  }

  render() {
    if (this.props.responseSuccess) {
      return <AccountVerified />;
    } else if (this.props.submitSuccess) {
      return (
        <CheckEmailPrompt
          firstSentence="Registration successful."
          secondSentence="Please check your email to verify your account. Here are quick links to common email services."
          hasReminder={true}
        />
      );
    } else {
      return <RegistrationForm {...this.props} />;
    }
  }
}

Register.propTypes = {};
