import ReactOnRails from "react-on-rails";
import { configureStore, createSlice, combineReducers } from "@reduxjs/toolkit";
import { ui } from "../../components/shared/ui/uiIndex";
import { session } from "../../components/shared/session/sessionIndex";
import { normalize } from "normalizr";
import { quizPropsJson } from "./quizSchema";
import { quizResponseSlice } from "./quizResponseSlice";

const initialState = {};

const initialSessionState = (props) => {
  return {
    session: { currentUser: props.currentUser },
  };
};

const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(logSheetMusicView.fulfilled, (state, { payload }) => {
    //   state.sheetMusicViewLogged = true;
    // });
  },
});

export const { addOrOverwriteEntities } = quizSlice.actions;

const reducer = combineReducers({
  entities: quizSlice.reducer,
  ui: ui.reducer,
  session: session.reducer,
  quizResponse: quizResponseSlice.reducer,
});

const createQuizStore = (preloadedState) =>
  configureStore({
    reducer,
    preloadedState,
  });

ReactOnRails.registerStore({
  quizStore: (props, railsContext) => {
    const { entities } = normalize(props, quizPropsJson);

    return createQuizStore({
      entities,
      ui: {
        ...ui.initialState,
        ...props.ui,
        currentEntityId: props.quiz.data.attributes.slug,
      },
      session: {
        ...initialSessionState(props),
        ...props.session,
      },
    });
  },
});
