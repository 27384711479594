import React from "react";
import ReactOnRails from "react-on-rails";
import { Provider } from "react-redux";
import "./store/childrensSongsStore";
import ChildrensSongsRoot from "./ChildrensSongsRoot";

const ChildrensSongs = () => {
  const store = ReactOnRails.getStore("childrensSongsStore");

  return (
    <Provider store={store}>
      <ChildrensSongsRoot />
    </Provider>
  );
};

export default ChildrensSongs;
