import React from "react";
import ReactOnRails from "react-on-rails";
import { Provider } from "react-redux";
import DashboardTabsHome from "./home/DashboardTabsHome";
import DashboardContentHome from "./home/DashboardContentHome";

require("./store/dashboardStore");

export default function () {
  const store = ReactOnRails.getStore("dashboardStore");

  return (
    <Provider store={store}>
      <div className="dashboard">
        <div className="dashboard__inset container">
          <DashboardTabsHome />
          <DashboardContentHome />
        </div>
      </div>
    </Provider>
  );
}
