import React from "react";
import PropTypes from "prop-types";

const NoResults = ({ query }) => (
  <div className="no-results">
    <h3>No Results Found.</h3>
    <p>
      There weren't any lessons, modules, or songs matching <span className="bold">{query}</span>
    </p>
  </div>
);

NoResults.propTypes = {
  query: PropTypes.string.isRequired,
};

export default NoResults;
