import React from 'react';
import { LocationIcon } from './icon.components';


export const TeacherCard = ({ teacher, setActiveTab, width }) => {
    return (
      <div className="card">
        <div className="header">
          <img src={teacher.smallImage} alt={teacher.name} />
          <p className="name">{teacher.name}</p>
          <p className="location">
            <LocationIcon />
            {teacher.location}
          </p>
        </div>
        <div className="tabs">
          <div className="nav">
            <button
              className={teacher.activeTab == "about" ? "active" : undefined}
              onClick={() => setActiveTab(teacher, "about")}
            >
              About
            </button>
            <button
              className={teacher.activeTab == "qualifications" ? "active" : undefined}
              onClick={() => setActiveTab(teacher, "qualifications")}
            >
              Qualifications
            </button>
            <button
              className={teacher.activeTab == "specialty" ? "active" : undefined}
              onClick={() => setActiveTab(teacher, "specialty")}
            >
              Specialty
            </button>
            <button
              className={teacher.activeTab == "lessonDetails" ? "active" : undefined}
              onClick={() => setActiveTab(teacher, "lessonDetails")}
            >
              {`Lesson${width > 768 ? " Details" : ""}`}
            </button>
          </div>
          <div className="content">
            <p dangerouslySetInnerHTML={{ __html: teacher.content[teacher.activeTab] }}></p>
            {teacher.activeTab == "lessonDetails" && (
              <p className="info">
                Please note that JustinGuitar doesn't take any commission from the teachers.
              </p>
            )}
          </div>
        </div>
        <div className="footer">
          <p>Use the info below to reach out</p>
          <div className="contacts">
            {teacher.contacts.map((contact, idx) =>
              contact.startsWith("+") || contact.match(/^\d/) ? (
                <a
                  key={idx}
                  href="#"
                  data-country={contact.split(" ")[0]}
                  data-number={contact.substring(contact.indexOf(" "))}
                  className="phone"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "tel:" +
                      e.currentTarget.dataset.country +
                      (e.currentTarget.dataset.number?.replace(/[ )(]/g, "") || '');
                    return;
                  }}
                ></a>
              ) : contact.includes("@") ? (
                <a
                  key={idx}
                  href="#"
                  data-name={contact.split("@")[0]}
                  data-domain={contact.split("@")[1]}
                  className="email"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "mailto:" + e.currentTarget.dataset.name + "@" + e.currentTarget.dataset.domain;
                    return;
                  }}
                ></a>
              ) : (
                <a key={idx} href={`https://${contact}`} target="_blank">
                  {contact}
                </a>
              )
            )}
          </div>
        </div>
      </div>
    );
  };
  