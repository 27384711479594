import React from "react";
import axios from "axios";
import PracticeIcons from "../../PracticeIcons";

export default class EditRoutine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: {},
      routineTitle: "",
      itemSortChanged: false,
      activeItemSearchTerm: "",
      searchItems: [],
    };
  }

  componentDidMount() {
    let unselectedItems = [];
    let selectedIds = this.props.routine.items.map((i) => i.id);
    let selectedItems = this.props.routine.items;

    this.props.practiceItems.forEach((item) => {
      if (selectedIds.includes(item.id) === false && item.status === "active") {
        unselectedItems.push(item);
      }
    });

    unselectedItems = this.alphabeticallySortItems(unselectedItems);
    this.setState({ routineTitle: this.props.routine.title, selectedItems, unselectedItems });
  }

  routineHasItem = (item) => {
    return this.props.routine.items.filter((i) => i.id === item.id).length > 0 ? true : false;
  };

  handleItemToggle = (item) => {
    if (this.state.isSumitting === true) {
      return;
    } else {
      let unselectedItems = [...this.state.unselectedItems];
      let selectedItems = [...this.state.selectedItems];
      if (selectedItems.map((i) => i.id).includes(item.id)) {
        let matchedItem = selectedItems.filter((i) => i.id === item.id)[0];
        unselectedItems.push(item);
        selectedItems.splice(selectedItems.indexOf(matchedItem), 1);
      } else {
        let matchedItem = unselectedItems.filter((i) => i.id === item.id)[0];
        selectedItems.push(item);
        unselectedItems.splice(unselectedItems.indexOf(matchedItem), 1);
      }
      unselectedItems = this.alphabeticallySortItems(unselectedItems);
      this.setState({ selectedItems, unselectedItems });
    }
  };

  checkboxDisplay = (item) => {
    if (this.isChecked(item)) {
      return (
        <span
          dangerouslySetInnerHTML={{ __html: PracticeIcons.checkedCheckboxIcon() }}
          onClick={() => this.handleItemToggle(item)}
        />
      );
    } else {
      return (
        <span
          dangerouslySetInnerHTML={{ __html: PracticeIcons.uncheckedCheckboxIcon() }}
          onClick={() => this.handleItemToggle(item)}
        />
      );
    }
  };

  isChecked = (item) => {
    return this.state.selectedItems.map((i) => i.id).includes(item.id);
  };

  newTitle = () => {
    if (this.props.routine.title != this.state.routineTitle) {
      return this.state.routineTitle;
    } else {
      return null;
    }
  };

  submitForm = () => {
    this.setState({ isSubmitting: true });
    let { selectedItems, routineTitle } = this.state;
    let itemIds = selectedItems.map((i) => i.id);

    axios
      .patch(`/user_practice_routines/change_routine_items/${this.props.routine.id}`, {
        user_practice_routine: {
          title: routineTitle,
          item_ids_to_add: itemIds,
        },
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((response) => {
        this.props.updateRoutineItems(this.props.routine.id, response.data.items);
        this.props.updateRoutineTitle(this.props.routine.id, routineTitle);
        this.setState({ isSumitting: false, itemSortChanged: false });
      })
      .catch((err) => {
        let error = "There was a problem updating this routine.";
        if (err.response && err.response.data && err.response.data.message) {
          error = err.response.data.message;
        }
        this.props.updateRoutineItems(null, null, error);
        this.setState({ isSumitting: false, itemSortChanged: false });
      });
  };

  handleRoutineNameChange = (e) => {
    this.setState({ routineTitle: e.target.value });
  };

  hasItems = () => {
    return this.props.practiceItems.filter((i) => i.status === "active").length > 0 ? true : false;
  };

  selectAllItems = () => {
    let { practiceItems } = this.props;
    let selectedItems = practiceItems.filter((i) => i.status === "active");
    this.setState({ selectedItems, unselectedItems: [] });
  };

  deselectAllItems = () => {
    let unselectedItems = this.props.practiceItems.filter((i) => i.status === "active");
    this.setState({ selectedItems: [], unselectedItems });
  };

  alphabeticallySortItems = (collection) => {
    return collection.sort((a, b) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  moveItem = (direction, item) => {
    this.setState({ itemSortChanged: true }, () => {
      let selectedItems = [...this.state.selectedItems];
      let itemFromProps = selectedItems.filter((i) => i.id === item.id)[0];
      let itemIndex = selectedItems.indexOf(itemFromProps);
      let itemToMove = selectedItems[itemIndex];
      let toIndex = direction === "up" ? itemIndex - 1 : itemIndex + 1;
      if (toIndex === selectedItems.length) {
        toIndex = 0;
      } else if (toIndex < 0) {
        toIndex = selectedItems.length - 1;
      }
      selectedItems.splice(itemIndex, 1);
      selectedItems.splice(toIndex, 0, itemToMove);
      this.setState({ selectedItems });
    });
  };

  toggleShowAllItems = () => {
    this.setState({ showAllItems: !this.state.showAllItems });
  };

  handleItems = (sortChanged) => {
    let itemAmount = this.props.practiceItems.filter((i) => i.status === "active").length;

    if (this.state.showAllItems === true) {
      return (
        <div>
          {this.itemsList(this.state.selectedItems, true, sortChanged)}
          {this.itemsList(this.state.unselectedItems)}
          {this.itemSearch()}
          {itemAmount > 5 && (
            <span className="show-items__toggle" onClick={this.toggleShowAllItems}>
              Show less
            </span>
          )}
        </div>
      );
    } else {
      return this.truncatedItemList(sortChanged);
    }
  };

  truncatedItemList = (sortChanged) => {
    let { selectedItems, unselectedItems } = this.state;
    let itemTotal = this.props.practiceItems.filter((i) => i.status === "active").length;
    if (selectedItems && selectedItems.length >= 5) {
      let items = [...selectedItems].slice(0, 5);
      return (
        <div>
          {this.itemsList(items, true, sortChanged)}
          {this.itemSearch()}
          {itemTotal > 5 && (
            <span
              className="show-items__toggle"
              onClick={this.toggleShowAllItems}
            >{`Show all ${itemTotal} items`}</span>
          )}
        </div>
      );
    } else if (selectedItems && unselectedItems) {
      let unselectedNumber = 5 - selectedItems.length;
      let unselectedToShow = [...unselectedItems].slice(0, unselectedNumber);
      return (
        <div>
          {this.itemsList(selectedItems, true, sortChanged)}
          {this.itemsList(unselectedToShow)}
          {this.itemSearch()}
          {itemTotal > 5 && (
            <span
              className="show-items__toggle"
              onClick={this.toggleShowAllItems}
            >{`Show all ${itemTotal} items`}</span>
          )}
        </div>
      );
    }
  };

  itemSearch = () => {
    return (
      <div className="routine-edit__search">
        <span className="glyphicon glyphicon-search"></span>
        <input
          placeholder="search items"
          value={this.state.activeItemSearchTerm}
          onChange={this.searchActiveItems}
        ></input>
        <div className="routine-edit__results">{this.searchResults()}</div>
      </div>
    );
  };

  searchActiveItems = (event) => {
    this.setState({ activeItemSearchTerm: event.target.value }, () => {
      let items = this.props.practiceItems.filter(
        (i) =>
          i.status === "active" &&
          i.title.toLowerCase().includes(this.state.activeItemSearchTerm.toLowerCase())
      );
      this.setState({ searchItems: items });
    });
  };

  clearSearch = () => {
    this.setState({ activeItemSearchTerm: "", searchItems: [] });
  };

  searchResults = () => {
    let { activeItemSearchTerm, searchItems } = this.state;
    if (activeItemSearchTerm && activeItemSearchTerm.length > 2) {
      if (searchItems.length > 0) {
        return (
          <div>
            <h4>Search Results</h4>
            <button type="button" onClick={this.clearSearch} className="routine-edit__clear-search">
              clear
            </button>
            {this.state.searchItems.map((item) => {
              return this.searchResultItem(item);
            })}
          </div>
        );
      } else {
        return <p>no results</p>;
      }
    }
  };

  searchResultItem = (item) => {
    return (
      <div key={`search-${item.id}`} className="routine-edit__item">
        <div>
          {this.checkboxDisplay(item)}
          <span className="routine-edit__text" onClick={() => this.handleItemToggle(item)}>
            {item.title}
          </span>
        </div>
      </div>
    );
  };

  itemsList = (collection, needsSorting = false, sortChanged = false) => {
    let items;
    if (needsSorting === true && sortChanged === true) {
      items = this.determineSortedItems(collection);
    } else {
      items = collection;
    }

    if (items) {
      return items.map((item) => {
        return this.routineItem(item);
      });
    }
  };

  determineSortedItems = (collection) => {
    let items;
    if (this.state.itemSortChanged === false) {
      items = this.props.routine.items;
      items.sort((a, b) => {
        return a.position < a.position ? -1 : 1;
      });
    } else {
      items = collection;
    }

    return items;
  };

  routineItem = (item) => {
    return (
      <div key={item.id} className="routine-edit__item">
        <div>
          {this.checkboxDisplay(item)}
          <span className="routine-edit__text" onClick={() => this.handleItemToggle(item)}>
            {item.title}
          </span>
        </div>

        {this.isChecked(item) && (
          <div className="routine-move__item">
            <i className="icon icon-angle-up" onClick={() => this.moveItem("up", item)}></i>
            <i className="icon icon-angle-down" onClick={() => this.moveItem("down", item)}></i>
          </div>
        )}
      </div>
    );
  };

  render() {
    if (this.hasItems()) {
      return (
        <div className="routine-edit">
          <div className="routine-edit__title">
            <span className="icon icon-pencil-outline"></span>
            <input
              value={this.state.routineTitle}
              type="text"
              disabled={this.state.isSubmitting === true}
              onChange={this.handleRoutineNameChange}
            />
          </div>

          <div className="routine-edit__select-row">
            <div className="routine-edit__select-option">
              <span
                onClick={this.selectAllItems}
                dangerouslySetInnerHTML={{ __html: PracticeIcons.selectAllIcon() }}
              />
              <span className="select-label" onClick={this.selectAllItems}>
                Select all items{" "}
              </span>
            </div>

            <div className="routine-edit__select-option">
              <span
                dangerouslySetInnerHTML={{ __html: PracticeIcons.deselectAllIcon() }}
                onClick={this.deselectAllItems}
              />
              <span className="select-label" onClick={this.deselectAllItems}>
                Deselect all items
              </span>
            </div>
          </div>

          <div className="routine-edit__items-container">
            {this.handleItems(this.state.itemSortChanged)}
          </div>

          <div className="practice-buttons__container">
            <button
              className="practice__button practice__button--save"
              onClick={this.submitForm}
              disabled={this.state.isSubmitting === true}
            >
              save
            </button>
            <button
              className="practice__button practice__button--cancel"
              onClick={this.props.closeForm}
              disabled={this.state.isSubmitting === true}
            >
              cancel
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p>
            There are no active practice items to add. Please create one in the 'Practice Items' tab
          </p>
        </div>
      );
    }
  }
}
