import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import LoginPrompt from "../../auth/LoginPrompt";
import YoutubeChapters from "./YoutubeChapters";
import { LightTooltip } from "../ui/Tooltips";
import { withInnerWidth } from "../WithInnerWidth";
import QuizTile from "./QuizTile";

const AUTOPLAY_START = 5;

const NextVideoTile = ({
  nextVideo,
  videoRemaining,
  refuseAutoplay,
  markAsCompleteAndGoToNextVideo,
}) => {
  if (nextVideo && videoRemaining && videoRemaining < AUTOPLAY_START) {
    return (
      <div className="autoplay__tile flex">
        <div className="flex">
          <div>
            <img src={nextVideo.image}></img>
          </div>
          <div>
            {videoRemaining >= 0 && (
              <span className="autoplay__next">
                Next lesson in <span className="time-remaining">{videoRemaining}</span>
              </span>
            )}
            <a
              className="autoplay__link"
              onClick={() => markAsCompleteAndGoToNextVideo(nextVideo.link)}
            >
              {nextVideo.title}
            </a>

            <div className="autoplay__options">
              <button
                onClick={refuseAutoplay}
                className="button button--inline button--transparent"
              >
                Cancel
              </button>
              <button
                className="button button--inline button--primary"
                onClick={() => markAsCompleteAndGoToNextVideo(nextVideo.link)}
              >
                Play Now
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const DisplayEndTile = ({
  nextVideo,
  goToNextVideo,
  cancel,
  nextModule,
  currentUser,
  quiz = null,
}) => {
  if (nextModule) {
    return (
      <div className="autoplay__ended autoplay__next-module">
        <h3>You've reached the end of this module!</h3>
        {!quiz ? (
          <a href="/practice" className="button button--primary">
            Start Practicing
          </a>
        ) : (
          <a href={`/quizzes/${quiz.slug}`} className="button button--primary">
            Take the Quiz
          </a>
        )}
        <span
          className="button button--white clickable"
          onClick={() => goToNextVideo(`/modules/${nextModule.slug}`)}
        >
          Continue To Next Module
        </span>
      </div>
    );
  } else if (nextVideo && nextVideo.link) {
    return (
      <div className="autoplay__ended">
        <button onClick={() => goToNextVideo(nextVideo.link)} className="button button--primary">
          Go to Next Lesson
        </button>
        <span className="autoplay__cancel clickable" onClick={cancel}>
          Cancel
        </span>
      </div>
    );
  } else {
    return null;
  }
};

class AutoplayVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { displayEndTile: false };
    this.videoRef = React.createRef();
  }

  checkProgress(progress) {
    let { videoDuration, refuseAutoplay } = this.state;
    if (!videoDuration || refuseAutoplay) {
      return;
    }

    let videoRemaining = Math.floor(videoDuration - progress.playedSeconds);
    this.setState({ videoRemaining });
  }

  setDuration(duration) {
    this.setState({ videoDuration: duration });
  }

  exitFullScreen() {
    if (!document.fullscreen) {
      return;
    }

    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }

  markAsCompleteAndGoToNextVideo = (url = null) => {
    const { nextVideo, hasAlreadyCompletedItem, currentUser, nextModule, quiz, quizAttempt } =
      this.props;

    this.exitFullScreen();

    if (
      currentUser &&
      hasAlreadyCompletedItem != true &&
      currentUser.data.attributes.settings.includes("autocomplete")
    ) {
      this.props.markAsComplete();
    }
    if (url) {
      setTimeout(() => this.goToNextVideo(url), 800);
    } else if (
      ((nextVideo && nextVideo.link) || (quiz && nextModule)) &&
      !this.state.refuseAutoplay &&
      (!currentUser || currentUser.data.attributes.settings.includes("autoplay"))
    ) {
      // give some time to see the 'marked as complete message before navigating away'
      setTimeout(
        () =>
          quiz && nextModule
            ? this.goToNextVideo(`/quizzes/${quiz.slug}${quizAttempt == null ? "" : "/results"}`)
            : this.goToNextVideo(nextVideo.link),
        800
      );
    } else if ((nextVideo && nextVideo.link) || nextModule) {
      this.setState({ displayEndTile: true });
    }
  };

  goToNextVideo = (link) => {
    window.location.href = link;
  };

  goToChapter = (timestamp) => {
    if (this.videoRef.current) {
      this.videoRef.current.seekTo(parseFloat(timestamp));
      this.videoRef.current.playing = true;
    }
  };

  videoEmbedUrl = (video) => {
    return video.includes("vimeo")
      ? video
      : `https://www.youtube.com/embed/${video}?modestbranding=1&autohide=1&showinfo=0&rel=0`;
  };

  render() {
    const {
      video,
      hasMaxResImage,
      nextVideo,
      nextModule,
      currentUser,
      youtubeChapters,
      userHasCompletedItem,
      toggleCompleted,
      quiz = null,
      quizAttempt = null,
    } = this.props;

    return (
      <div style={{ position: "relative", width: "100%" }}>
        <div className="react-player-wrapper">
          <ReactPlayer
            url={this.videoEmbedUrl(video)}
            controls={true}
            width="100%"
            allowFullScreen={true}
            height="100%"
            onDuration={(duration) => this.setDuration(duration)}
            onProgress={(progress) => this.checkProgress(progress)}
            ref={this.videoRef}
            onEnded={this.markAsCompleteAndGoToNextVideo}
            className="react-player"
          />
          {(!this.props.currentUser ||
            this.props.currentUser.data.attributes.settings.includes("autoplay")) && (
            <NextVideoTile
              refuseAutoplay={() => this.setState({ refuseAutoplay: true, videoRemaining: null })}
              videoRemaining={this.state.videoRemaining}
              markAsCompleteAndGoToNextVideo={this.markAsCompleteAndGoToNextVideo}
              {...this.props}
            />
          )}

          {this.state.displayEndTile && (
            <DisplayEndTile
              cancel={() => this.setState({ displayEndTile: false })}
              goToNextVideo={this.goToNextVideo}
              nextVideo={nextVideo}
              nextModule={nextModule}
              currentUser={currentUser}
              quiz={quiz}
              quizAttempt={quizAttempt}
            />
          )}

          {nextModule && quiz && (
            <QuizTile
              cancel={() => this.setState({ displayEndTile: false })}
              goToNextVideo={this.goToNextVideo}
              nextModule={nextModule}
              currentUser={currentUser}
              quiz={quiz}
              quizAttempt={quizAttempt}
              refuseAutoplay={() => this.setState({ refuseAutoplay: true, videoRemaining: null })}
              videoRemaining={this.state.videoRemaining}
              markAsCompleteAndGoToNextVideo={this.markAsCompleteAndGoToNextVideo}
              {...this.props}
            />
          )}
        </div>

        <div className="youtube-chapters-wrapper">
          {youtubeChapters && (
            <YoutubeChapters youtubeChapters={youtubeChapters} goToChapter={this.goToChapter} />
          )}
          <div className="lesson-complete-and-info">
            <button
              className={"lesson-complete-btn " + (userHasCompletedItem() ? "completed" : "")}
              onClick={() => toggleCompleted()}
            >
              <i
                className={`icon ${
                  userHasCompletedItem() ? "icon-check_circle_outline" : "icon-check-circle-outline"
                }`}
              />
              {`${
                userHasCompletedItem()
                  ? this.props.width <= 992
                    ? "Completed"
                    : "Lesson completed"
                  : this.props.width <= 992
                  ? "Complete"
                  : "Mark lesson as complete"
              }`}
            </button>
            <LightTooltip
              arrow
              enterTouchDelay={0}
              placement="right"
              title="You can change the video autocompletion settings in your Dashboard. Click Account and then Preferences."
            >
              <i className="icon icon-info"></i>
            </LightTooltip>
          </div>
        </div>
      </div>
    );
  }
}

AutoplayVideo.propTypes = {
  video: PropTypes.string.isRequired,
  hasMaxResImage: PropTypes.bool,
  nextVideo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }),
  currentUser: PropTypes.object,
  hasAlreadyCompletedItem: PropTypes.bool,
  markAsComplete: PropTypes.func.isRequired,
};

export default withInnerWidth(AutoplayVideo);
