import React, { useState } from "react";
import { connect } from "react-redux";
import { setActiveTab } from "./store/dashboardStore";
import { withBootstrapSize } from "../components/shared/WithBootstrapSize";

const TABS = [
  { name: "Dashboard", icon: "home", nestedTabs: ["latest"] },
  { name: "Practice", icon: "practice-note" },
  { name: "Favorites", icon: "heart" },
  { name: "Songbook", icon: "record" },
  { name: "Notes", icon: "pencil-right" },
  { name: "Journey", icon: "hat" },
  { name: "App", icon: "phone" },
  { name: "Account", icon: "gears" },
];

const DashboardTabs = ({ activeTab, setActiveTab, user, width }) => {
  return (
    <div
      className="dashboard__tabs"
      style={{ display: activeTab.toLowerCase() == "dashboard" ? "flex" : null }}
    >
      {TABS.map((tab) => {
        return (
          <button
            key={tab["name"]}
            className={
              "tab " +
              (activeTab.toLowerCase() === tab["name"].toLowerCase() ||
              (tab.nestedTabs && tab.nestedTabs.includes(activeTab.toLowerCase()))
                ? "active"
                : "")
            }
            onClick={() => {
              setActiveTab(tab["name"].toLowerCase());
              window.history.pushState(
                {},
                "",
                `/users/${user.data.attributes.slug}${
                  tab["name"].toLowerCase() === "dashboard" ? "" : `/${tab["name"]}`.toLowerCase()
                }`
              );
            }}
          >
            {tab["icon"] === "phone" && width <= 768 ? (
              <i className="icon">
                <svg
                  width="12"
                  height="18"
                  viewBox="0 0 12 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 0C0.895431 0 0 0.89543 0 2V16C0 17.1046 0.895431 18 2 18H10C11.1046 18 12 17.1046 12 16V2C12 0.895431 11.1046 0 10 0H2ZM2 2H10V14H2V2ZM7 16C7 16.5523 6.55228 17 6 17C5.44772 17 5 16.5523 5 16C5 15.4477 5.44772 15 6 15C6.55228 15 7 15.4477 7 16Z"
                    fill="#406E8E"
                  />
                </svg>
              </i>
            ) : (
              <i className={`icon icon-${tab["icon"]}`}></i>
            )}
            {tab["name"]}
          </button>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeTab: state.entity.dashboard.activeTab,
  user: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withBootstrapSize(DashboardTabs));
