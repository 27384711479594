import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosWithCSRF from "../../../shared/axiosWithCSRF";

import * as slices from "../slices";

// TODO: the below is shared with advanced search store

export const createUserSongItem = createAsyncThunk(
  "songs/createUserSongItem",
  (userSongItem, { dispatch }) =>
    axiosWithCSRF()
      .post("/user_items/song_item.json", userSongItem)
      .then((res) => {
        dispatch(slices.ui.actions.openAlert({ message: "Saved Song!", severity: "success" }));
        return { ...userSongItem, id: res.data.userItem.data.id };
      })
      .catch(() => {
        dispatch(slices.ui.actions.openAlert({ message: "An Error Occurred", severity: "error" }));
      })
);

export const deleteUserSongItem = createAsyncThunk(
  "songs/deleteUserSongItem",
  (userItem, { dispatch }) =>
    axiosWithCSRF()
      .delete(`/user_items/${userItem.id}.json`)
      .then(() => {
        dispatch(slices.ui.actions.openAlert({ message: "Unsaved Song!", severity: "success" }));
        return userItem;
      })
      .catch(() => {
        dispatch(slices.ui.actions.openAlert({ message: "An Error Occurred", severity: "error" }));
      })
);

export const createUserPracticeItem = createAsyncThunk(
  "songs/createUserPracticeItem",
  (songId, { dispatch }) =>
    axiosWithCSRF()
      .post(`/songs/${songId}/user_practice_item.json`)
      .then((res) => {
        dispatch(
          slices.ui.actions.openAlert({ message: "Saved Practice Item!", severity: "success" })
        );
        return { [songId]: res.data };
      })
      .catch(() => {
        dispatch(slices.ui.actions.openAlert({ message: "An Error Occurred", severity: "error" }));
      })
);

export const deleteUserPracticeItem = createAsyncThunk(
  "songs/deleteUserPracticeItem",
  (songId, { dispatch }) =>
    axiosWithCSRF()
      .delete(`/songs/${songId}/user_practice_item.json`)
      .then(() => {
        dispatch(
          slices.ui.actions.openAlert({ message: "Deleted Practice Item!", severity: "success" })
        );
        return songId;
      })
      .catch(() => {
        dispatch(slices.ui.actions.openAlert({ message: "An Error Occurred", severity: "error" }));
      })
);
