import React from "react";
import { Box, Button } from "@mui/material";
import { CANCEL_URL } from "./consts";
import { createSalesCheckoutSession } from "../../subscriptions/utils";

const BuyCourseButton = ({ activePlan, currentUser, discount, setLoading, setLoginOpen }) => {
  const handleSubmit = () => {
    if (currentUser) {
      setLoading(true);
      createSalesCheckoutSession(discount?.id, [activePlan.id], CANCEL_URL)
        .then((response) => {
          window.location = response.data.url;
        })
        .catch(() => {
          alert(
            "There was a problem processing your request. Please contact hello@justinguitar.com for assistance."
          );
          setLoading(false);
        });
    } else {
      setLoginOpen(true);
    }
  };

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} mt={2} mb={4}>
      <Button className="button button--primary" onClick={handleSubmit} sx={{ boxShadow: "none" }}>
        Buy Now
      </Button>
    </Box>
  );
};

export default BuyCourseButton;
