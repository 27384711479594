export const groupBy = (xs, key) =>
  xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

export const formatTime = (numSecs) => {
  let secNum = parseInt(numSecs, 10);
  let hours = Math.floor(secNum / 3600).toString();
  let minutes = Math.floor((secNum - hours * 3600) / 60)
    .toString()
    .padStart(2, "0");

  return `${hours != "0" ? `${hours} hour${hours != 1 ? "s" : ""} ` : ""}${minutes} min`;
};

export const formatNumber = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const snakeToCamelCaseString = (str) => {
  return str.replace(/_([a-z])/g, (g) => {
    return g[1].toUpperCase();
  });
};

export const snakeToCamelCaseObject = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[snakeToCamelCaseString(key)] = obj[key];
    return acc;
  }, {});
};

export const formatNumberShort = (num) => {
  const suffixes = ["", "K", "M", "B", "T"]; // Add more suffixes as needed
  const numLength = num.toString().length;
  const suffixIndex = Math.floor((numLength - 1) / 3);

  let formattedNumber = (num / Math.pow(1000, suffixIndex)).toFixed(2);

  // Remove trailing zeroes and decimal point if not needed
  formattedNumber = formattedNumber.replace(/\.?0+$/, "");

  return formattedNumber + suffixes[suffixIndex];
};
