import React from "react";
import axiosWithCSRF from "../../../shared/axiosWithCSRF";

export default class DeleteItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  deleteItem = (item) => {
    if (confirm("Are you sure you want to delete this item? This action cannot be undone.")) {
      axiosWithCSRF()
        .delete(`/user_practice_items/destroy_archived_item/${item.id}`)
        .then((res) => {
          this.props.handleDeletedItem(item);
        })
        .catch((error) => {
          this.props.handleDeletedItem(null);
        });
    }
  };

  render() {
    let { item } = this.props;
    return (
      <div className="practice-buttons__container practice-buttons__container--with-margin">
        <button
          className="practice__button practice__button--red"
          onClick={() => this.deleteItem(item)}
        >
          delete item
        </button>
        <button
          className="practice__button practice__button--cancel"
          onClick={this.props.closeForm}
        >
          cancel
        </button>
      </div>
    );
  }
}
