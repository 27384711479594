import React from "react";
import ConditionalWrapper from "../components/ConditionalWrapper";
import Slider from "react-slick";
import { CDN_URL } from "../components/shared/CdnUrl";
import { withInnerWidth } from "../components/shared";

const products = [
  {
    image: "Ad-Donation.png",
    url: "/donate",
    alt: "Donate to JustinGuitar",
  },
  {
    image: "Ad-Tshirt-DaVinci.png",
    url: "https://merch.justinguitar.com/collections/all?filter.p.product_type=Tshirt",
    alt: "Guitar Tee Shirts",
  },
  {
    image: "Ad-Tshirt-DaVinci-1.png",
    url: "https://merch.justinguitar.com/collections/all?filter.p.product_type=Mug",
    alt: "Guitar Mugs",
  },
  {
    image: "Ad-TABS.png",
    url: "/products?type=tabs",
    alt: "TABS",
  },
];

const books = [
  {
    id: 121,
    image: "Ad-ACOUSTIC-Songbook.png",
    imageAlt: "Ad-ACOUSTIC-Songbook-1.png",
    url: "/products/acoustic-songbook",
    alt: "Acoustic Songbook",
  },
  {
    id: 113,
    image: "Ad-AUSSIE-Songbook.png",
    imageAlt: "Ad-AUSSIE-Songbook-1.png",
    url: "/products/aussie-songbook",
    alt: "Aussie Songbook",
  },
  {
    id: 118,
    image: "Ad-BEG1-Songbook.png",
    imageAlt: "Ad-BEG1-Songbook-1.png",
    url: "/products/beginner-songbook-1",
    alt: "Beginner Songbook 1",
  },
  {
    id: 110,
    image: "Ad-BEG2-Songbook.png",
    imageAlt: "Ad-BEG2-Songbook-1.png",
    url: "/products/beginner-songbook-2",
    alt: "Beginner Songbook 2",
  },
  {
    id: 114,
    image: "Ad-BLUES-Songbook.png",
    imageAlt: "Ad-BLUES-Songbook-1.png",
    url: "/products/blues-lead-solos",
    alt: "Blues Lead Solos",
  },
  {
    id: 119,
    image: "Ad-CHRISTMAS-Songbook.png",
    imageAlt: "Ad-CHRISTMAS-Songbook-1.png",
    url: "/products/christmas-songbook",
    alt: "Christmas Songbook",
  },
  {
    id: 124,
    image: "Ad-NOTE-Songbook.png",
    imageAlt: "Ad-NOTE-Songbook-1.png",
    url: "/products/note-reading-for-guitar",
    alt: "Note Reading For Guitar",
  },
  {
    id: 116,
    image: "Ad-POP-Songbook.png",
    imageAlt: "Ad-POP-Songbook-1.png",
    url: "/products/pop-songbook",
    alt: "Pop Songbook",
  },
  {
    id: 117,
    image: "Ad-ROCK-Songbook.png",
    imageAlt: "Ad-ROCK-Songbook-1.png",
    url: "/products/rock-songbook",
    alt: "Rock Songbook",
  },
  {
    id: 111,
    image: "Ad-UKULELE-Songbook.png",
    imageAlt: "Ad-UKULELE-Songbook-1.png",
    url: "/products/ukulele-songbook",
    alt: "Ukulele Songbook",
  },
  {
    id: 112,
    image: "Ad-VINTAGE-Songbook.png",
    imageAlt: "Ad-VINTAGE-Songbook-1.png",
    url: "/products/vintage-songbook",
    alt: "Vintage Songbook",
  },
  {
    id: null,
    image: "Ad-Children-Songbook.png",
    imageAlt: "Ad-Children-Songbook-1.png",
    url: "/products/101-childrens-songs",
    alt: "Children's Songs On Ukulele & Guitar",
  },
];

const SongProducts = ({ song, width }) => {
  const associatedBookIds = song.data.attributes.appearsInProducts.filter((n) => n);
  const randomProduct = products[Math.floor(Math.random() * products.length)];
  const randomBook = books[Math.floor(Math.random() * books.length)];
  const associatedBooks = []; //books.filter((e) => associatedBookIds.includes(e.id));

  let randomAssociatedBook = null;
  if (associatedBooks.length) {
    randomAssociatedBook = associatedBooks[Math.floor(Math.random() * associatedBooks.length)];
  }

  return (
    <div className={`song__products${width > 992 ? " flex" : ""}`}>
      <ConditionalWrapper
        condition={width <= 992}
        wrapper={(children) => (
          <Slider
            {...{
              dots: true,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            }}
          >
            {children}
          </Slider>
        )}
      >
        <a href="https://justinguitarapp.onelink.me/3065804798/b9b7b85d">
          <img
            src={`${CDN_URL}/images/products/redesign/lessons-and-songs-app.png`}
            alt="JustinGuitar Lessons & Songs App"
          />
        </a>
        {randomAssociatedBook ? (
          <a href={randomAssociatedBook.url}>
            <img
              src={`${CDN_URL}/images/products/books/banners/x2/${randomAssociatedBook.image}`}
              alt={randomAssociatedBook.alt}
            />
          </a>
        ) : (
          <a href={randomBook.url}>
            <img
              src={`${CDN_URL}/images/products/books/banners/x2/${randomBook.imageAlt}`}
              alt={randomBook.alt}
            />
          </a>
        )}
        <a href={randomProduct.url}>
          <img
            src={`${CDN_URL}/images/products/banners/x2/${randomProduct.image}`}
            alt={randomProduct.alt}
          />
        </a>
      </ConditionalWrapper>
    </div>
  );
};

export default withInnerWidth(SongProducts);
