import React from "react";

const SheetMusicPaymentButton = ({ reference }) => {
  return (
    <div className="sheet-music__payment">
      <p>
        Want the chord/lyric and TAB for this song? Click the link below to get details and
        subscribe to JustinGuitarTABS :)
      </p>
      <a className="button button--primary button--inline" href={`/store/tabs?ref=${reference}`}>
        Subscribe Now
      </a>
    </div>
  );
};

export default SheetMusicPaymentButton;
