import ReactOnRails from "react-on-rails";
import About from "../bundles/JustinGuitar/pages/About";
import AddToPracticeRoutine from "../bundles/JustinGuitar/components/AddToPracticeRoutine";
import Ads from "../bundles/JustinGuitar/components/Ads";
import AdvancedSearch from "../bundles/JustinGuitar/components/advanced-search/AdvancedSearch";
import AllProductsContainer from "../bundles/JustinGuitar/components/AllProductsContainer";
import AppBanner from "../bundles/JustinGuitar/banners/index";
import AppIcons from "../bundles/JustinGuitar/components/shared/AppIcons";
import Artist from "../bundles/JustinGuitar/artists/index";
import Banner from "../bundles/JustinGuitar/components/Banner";
import BeginnerApp from "../bundles/JustinGuitar/pages/BeginnerApp";
import BluesImmersion from "../bundles/JustinGuitar/pages/BluesImmersion";
import BpmTool from "../bundles/JustinGuitar/bpm/BpmTool";
import CallToAction from "../bundles/JustinGuitar/home/CallToAction";
import CancelDonation from "../bundles/JustinGuitar/components/CancelDonation";
import CancelSubscription from "../bundles/JustinGuitar/components/CancelSubscription";
import Checkout from "../bundles/JustinGuitar/components/Checkout";
import CheckoutForm from "../bundles/JustinGuitar/components/CheckoutForm";
import CheckoutSuccess from "../bundles/JustinGuitar/subscriptions/success";
import ChildrensSongs from "../bundles/JustinGuitar/pages/ChildrensSongs";
import Chords from "../bundles/JustinGuitar/pages/Chords";
import Competition from "../bundles/JustinGuitar/pages/Competition/Competition";
import Contact from "../bundles/JustinGuitar/pages/Contact";
import CookieConsent from "../bundles/JustinGuitar/cookie_consent/CookieConsent";
import Dashboard from "../bundles/JustinGuitar/dashboard/Dashboard";
import DashboardHome from "../bundles/JustinGuitar/dashboard/DashboardHome";
import DashboardCardContainer from "../bundles/JustinGuitar/components/DashboardCardContainer";
import DashboardCardItem from "../bundles/JustinGuitar/components/DashboardCardItem";
import DashboardCardList from "../bundles/JustinGuitar/components/DashboardCardList";
import DeleteUserItem from "../bundles/JustinGuitar/components/DeleteUserItem";
import DiscourseLogin from "../bundles/JustinGuitar/discourse/Login";
import Donation from "../bundles/JustinGuitar/donations/Donation";
import DonationUses from "../bundles/JustinGuitar/donations/DonationUses";
import DynamicIframe from "../bundles/JustinGuitar/components/DynamicIframe";
import Ebooks from "../bundles/JustinGuitar/pages/Ebooks";
import Endorsements from "../bundles/JustinGuitar/home/Endorsements";
import FAQ from "../bundles/JustinGuitar/pages/FAQ";
import FileDownload from "../bundles/JustinGuitar/components/FileDownload";
import FilterItems from "../bundles/JustinGuitar/components/FilterItems";
import FilterLessonItems from "../bundles/JustinGuitar/components/FilterLessonItems";
import Footer from "../bundles/JustinGuitar/components/Footer";
import Group from "../bundles/JustinGuitar/group/Group";
import GroupPaywall from "../bundles/JustinGuitar/group/GroupPaywall";
import GroupPaywalledOnParentGroup from "../bundles/JustinGuitar/group/paywalled/GroupPaywalledOnParentGroup";
import Header from "../bundles/JustinGuitar/components/header/Header";
import Home from "../bundles/JustinGuitar/home/index";
import HomeHero from "../bundles/JustinGuitar/home/HomeHero";
import HomepageTop from "../bundles/JustinGuitar/components/HomepageTop";
import Lesson from "../bundles/JustinGuitar/lesson/Lesson";
import LessonContentContainer from "../bundles/JustinGuitar/components/LessonContentContainer";
import LessonContentTabOptions from "../bundles/JustinGuitar/components/LessonContentTabOptions";
import LessonList from "../bundles/JustinGuitar/components/LessonList";
import LessonListContainer from "../bundles/JustinGuitar/components/LessonListContainer";
import LessonListItem from "../bundles/JustinGuitar/components/LessonListItem";
import LessonsOverview from "../bundles/JustinGuitar/lessons/LessonsOverview";
import Links from "../bundles/JustinGuitar/pages/Links/index";
import LiteYoutube from "../bundles/JustinGuitar/components/shared/LiteYoutube";
import LiveEvents from "../bundles/JustinGuitar/pages/LiveEvents";
import Login from "../bundles/JustinGuitar/components/auth/Login";
import LoginForm from "../bundles/JustinGuitar/components/LoginForm";
import Metronome from "../bundles/JustinGuitar/components/practice/PracticeTab/Play/Metronome";
import ModuleListItem from "../bundles/JustinGuitar/components/ModuleListItem";
import MusicTheoryCourse from "../bundles/JustinGuitar/pages/MusicTheoryCourse";
import NewOrExistingRoutine from "../bundles/JustinGuitar/components/practice/General/NewOrExistingRoutine";
import Onboarding from "../bundles/JustinGuitar/pages/Onboarding";
import SongsIndexContainer from "../bundles/JustinGuitar/components/songs-index/SongsIndexContainer";
import Notification from "../bundles/JustinGuitar/notification/Notification";
import ParentGroup from "../bundles/JustinGuitar/parent_group/ParentGroup";
import PasswordAndConfirmationManagement from "../bundles/JustinGuitar/components/auth/PasswordAndConfirmationManagement";
import PasswordReset from "../bundles/JustinGuitar/components/auth/PasswordReset";
import Playground from "../bundles/JustinGuitar/pages/Playground";
import PlayinItForward from "../bundles/JustinGuitar/pages/PlayinItForward";
import Podcast from "../bundles/JustinGuitar/components/Podcast";
import Practice from "../bundles/JustinGuitar/components/practice/Practice";
import PrivateOneOnOneLessons from "../bundles/JustinGuitar/pages/PrivateOneOnOneLessons";
import ProductAppButtons from "../bundles/JustinGuitar/components/ProductAppButtons";
import ProductBundles from "../bundles/JustinGuitar/pages/ProductBundles";
import ProductDetail from "../bundles/JustinGuitar/products/ProductDetail";
import ProductsIndex from "../bundles/JustinGuitar/products/ProductsIndex";
import PromoItemPreview from "../bundles/JustinGuitar/pages/PromoItemPreview/index";
import Quiz from "../bundles/JustinGuitar/quiz/Quiz";
import Register from "../bundles/JustinGuitar/components/auth/Register";
import Search from "../bundles/JustinGuitar/components/header/Search";
import SearchItems from "../bundles/JustinGuitar/components/SearchItems";
import SheetMusicStandalonePage from "../bundles/JustinGuitar/song/sheetmusic/SheetMusicStandalonePage";
import SiteMap from "../bundles/JustinGuitar/site_map/SiteMap";
import Slide from "../bundles/JustinGuitar/components/Slide";
import Slider from "../bundles/JustinGuitar/components/Slider";
import Slideshow from "../bundles/JustinGuitar/home/Slideshow";
import Song from "../bundles/JustinGuitar/song/Song";
import SongList from "../bundles/JustinGuitar/components/SongList";
import SongListItem from "../bundles/JustinGuitar/components/SongListItem";
import SongsIndexPage from "../bundles/JustinGuitar/components/songs/SongsIndexPage";
import SortableList from "../bundles/JustinGuitar/components/SortableList";
import Store from "../bundles/JustinGuitar/pages/Store";
import StripeContainer from "../bundles/JustinGuitar/components/StripeContainer";
import StripeOptions from "../bundles/JustinGuitar/components/StripeOptions";
import StrummingMachine from "../bundles/JustinGuitar/pages/StrummingMachine";
import StrummingSos from "../bundles/JustinGuitar/pages/StrummingSos";
import SupportTeam from "../bundles/JustinGuitar/pages/SupportTeam";
import Tabbed from "../bundles/JustinGuitar/components/Tabbed";
import TabbedTab from "../bundles/JustinGuitar/components/TabbedTab";
import TabbedTabContainer from "../bundles/JustinGuitar/components/TabbedTabContainer";
import TabbedTextContainer from "../bundles/JustinGuitar/components/TabbedTextContainer";
import TabbedVideoContainer from "../bundles/JustinGuitar/components/TabbedVideoContainer";
import TabsShow from "../bundles/JustinGuitar/products/tabs";
import Teachers from "../bundles/JustinGuitar/pages/Teachers";
import TeacherTraining from "../bundles/JustinGuitar/pages/TeacherTraining/index";
import TermsAndConditions from "../bundles/JustinGuitar/pages/TermsAndConditions";
import UniqueFeatures from "../bundles/JustinGuitar/components/UniqueFeatures";
import UserItemButton from "../bundles/JustinGuitar/components/UserItemButton";
import UserItemContainer from "../bundles/JustinGuitar/components/UserItemContainer";
import UserItemDummyButton from "../bundles/JustinGuitar/components/UserItemDummyButton";
import UserNote from "../bundles/JustinGuitar/components/shared/UserNote";
import UserPerformances from "../bundles/JustinGuitar/pages/UserPerformances";
import UserSession from "../bundles/JustinGuitar/components/users/sessions";
import WelcomeVideoButton from "../bundles/JustinGuitar/home/WelcomeVideoButton";
import WhyDonate from "../bundles/JustinGuitar/donations/WhyDonate";
import { WorkshopsIndexPage } from "../bundles/JustinGuitar/pages/Workshops/index/WorkshopsIndexPage";
import { WorkshopShowPage } from "../bundles/JustinGuitar/pages/Workshops/show/WorkshopShowPage";
import { WorkshopRegisterPage } from "../bundles/JustinGuitar/pages/Workshops/register/WorkshopRegisterPage";
import { WorkshopGalleryPage } from "../bundles/JustinGuitar/pages/Workshops/gallery/WorkshopGalleryPage";
import { WorkshopDepositPage } from "../bundles/JustinGuitar/pages/Workshops/deposit/WorkshopDepositPage";

// This is how react_on_rails can see the JustinGuitar Component in the browser.
ReactOnRails.register({
  About,
  AddToPracticeRoutine,
  Ads,
  AdvancedSearch,
  AllProductsContainer,
  AppBanner,
  AppIcons,
  Artist,
  Banner,
  BeginnerApp,
  BluesImmersion,
  BpmTool,
  CallToAction,
  CancelDonation,
  CancelSubscription,
  Checkout,
  CheckoutForm,
  CheckoutSuccess,
  ChildrensSongs,
  Chords,
  Competition,
  Contact,
  CookieConsent,
  Dashboard,
  DashboardCardContainer,
  DashboardCardItem,
  DashboardCardList,
  DashboardHome,
  DeleteUserItem,
  DiscourseLogin,
  Donation,
  DonationUses,
  DynamicIframe,
  Ebooks,
  Endorsements,
  FAQ,
  FileDownload,
  FilterItems,
  Footer,
  Group,
  GroupPaywall,
  GroupPaywalledOnParentGroup,
  Header,
  Home,
  HomeHero,
  HomepageTop,
  Lesson,
  LessonContentContainer,
  LessonContentTabOptions,
  LessonList,
  LessonListContainer,
  LessonListItem,
  LessonsOverview,
  Links,
  LiteYoutube,
  LiveEvents,
  Login,
  LoginForm,
  Metronome,
  ModuleListItem,
  MusicTheoryCourse,
  NewOrExistingRoutine,
  Onboarding,
  SongsIndexContainer,
  Notification,
  ParentGroup,
  PasswordAndConfirmationManagement,
  PasswordReset,
  Playground,
  PlayinItForward,
  Practice,
  PrivateOneOnOneLessons,
  Podcast,
  ProductAppButtons,
  ProductBundles,
  ProductDetail,
  ProductsIndex,
  PromoItemPreview,
  Quiz,
  Register,
  Search,
  SearchItems,
  SheetMusicStandalonePage,
  SiteMap,
  Slide,
  Slider,
  Slideshow,
  Song,
  SongList,
  SongListItem,
  SongsIndexPage,
  SortableList,
  Store,
  StripeContainer,
  StripeOptions,
  StrummingMachine,
  StrummingSos,
  SupportTeam,
  Tabbed,
  TabbedTab,
  TabbedTabContainer,
  TabbedTextContainer,
  TabbedVideoContainer,
  TabsShow,
  Teachers,
  TeacherTraining,
  TermsAndConditions,
  UniqueFeatures,
  UserItemButton,
  UserItemContainer,
  UserItemDummyButton,
  UserPerformances,
  UserSession,
  WelcomeVideoButton,
  WhyDonate,
  WorkshopsIndexPage,
  WorkshopShowPage,
  WorkshopRegisterPage,
  WorkshopGalleryPage,
  WorkshopDepositPage,
});
