import React from "react";
import { connect } from "react-redux";
import { fetchSearchResults, setVisibleType } from "./store";
import { searchResultsTypes, searchResultsTypeToDisplayName } from "../shared/search";
import CollapsingOptionList from "../shared/CollapsingOptionList";
import { clearResults } from "./store/searchActions";

const filters = [
  { value: "All", label: "All" },
  ...Object.keys(searchResultsTypes).map((type) => ({
    value: type,
    label: searchResultsTypeToDisplayName[type],
  })),
];

const ResultTypeFilterList = ({ visibleType, toggleTypeVisibility }) => (
  <CollapsingOptionList
    defaultSelection={filters[0].value}
    onToggle={toggleTypeVisibility}
    selected={visibleType}
    options={filters}
    classPrefix={"advanced-search"}
  />
);

export default connect(
  ({ visibleType, results }) => ({
    visibleType,
    results,
  }),
  (dispatch, { visibleType }) => ({
    toggleTypeVisibility(type) {
      const sanitizedType = type === visibleType || type === "All" ? null : type;

      dispatch(setVisibleType(sanitizedType));
      if (sanitizedType == null) {
        dispatch(clearResults());
        dispatch(fetchSearchResults());
      }
    },
  })
)(ResultTypeFilterList);
