import React from "react";
import LoginPrompt from "../../components/auth/LoginPrompt";
import { GroupPaywall } from "../../group/paywalled/GroupPaywalledOnParentGroup";
import AutoplayVideo from "../../components/shared/video/AutoplayVideo";
import { isSaved, nextLesson } from "../lessonPageUtils";
import VideoPlaceholder from "./VideoPlaceholder";

const Login = () => {
  return (
    <div className="flex column needs-login">
      <h2>You must log in to view this lesson</h2>
      <LoginPrompt token={ReactOnRails.authenticityToken()} />
    </div>
  );
};

const Autoplay = ({ currentUser, lesson, isCompleted, toggleCompleted, videoRef, quizAttempt }) => {
  const { video, youtubeChapters, nextModule, hasMaxResImage, lessonsWithinModule, quiz } =
    lesson.data.attributes;
  return (
    <div className="video-swoosh__video" ref={videoRef}>
      <AutoplayVideo
        video={video}
        youtubeChapters={youtubeChapters}
        hasMaxResImage={hasMaxResImage}
        nextVideo={nextLesson(lessonsWithinModule, lesson)}
        nextModule={nextModule}
        currentUser={currentUser}
        quiz={quiz}
        quizAttempt={quizAttempt}
        hasAlreadyCompletedItem={isCompleted}
        markAsComplete={() => toggleCompleted(false, lesson.data.id)}
        userHasCompletedItem={() => {
          return isCompleted;
        }}
        toggleCompleted={() => toggleCompleted(isCompleted, lesson.data.id)}
      />
    </div>
  );
};

const Paywall = ({ videoRef, paywall }) => {
  return (
    <div className="video-swoosh__video paywalled-group" ref={videoRef}>
      <GroupPaywall paywall={paywall} />
    </div>
  );
};

const LessonVideoBlock = ({
  currentUser,
  lesson,
  paywall,
  completedLessonIds,
  toggleCompleted,
  videoRef,
  quizAttempt,
  toggleLoginModal,
  viewResources,
}) => {
  const { signedInOnly, video, image, quiz, nextModule } = lesson.data.attributes;
  const hasResources = lesson.data.relationships?.downloadables?.data?.length > 0;
  const isCompleted = completedLessonIds.includes(parseInt(lesson.data.id));

  if (signedInOnly && !currentUser) {
    return <Login />;
  } else if (paywall) {
    return <Paywall videoRef={videoRef} paywall={paywall} />;
  } else if (video?.length > 0) {
    return (
      <Autoplay
        isCompleted={isCompleted}
        currentUser={currentUser}
        lesson={lesson}
        toggleCompleted={toggleCompleted}
        videoRef={videoRef}
        quizAttempt={quizAttempt}
      />
    );
  } else {
    return (
      <VideoPlaceholder
        videoRef={videoRef}
        image={image}
        quiz={quiz}
        nextModule={nextModule}
        quizAttempt={quizAttempt}
        currentUser={currentUser}
        toggleLoginModal={toggleLoginModal}
        hasResources={hasResources}
        viewResources={viewResources}
      />
    );
  }
};

export default LessonVideoBlock;
