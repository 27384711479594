import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import onClickOutside from "react-onclickoutside";

import {
  closeSearch,
  closeFilters,
  setQuery,
  fetchSearchResults,
  selectAdvancedSearchUrl,
} from "./store";
import SearchResults from "./SearchResults";
import StaticLinks from "./StaticLinks";
import SearchFilters from "./SearchFilters";
import { PreventScroll } from "../../shared";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withBootstrapSize } from "../../shared/WithBootstrapSize";
import { BootstrapSize, breakpoints } from "../../shared/bootstrap-helpers";

const ANIMATION_DURATION_MS = 200;

export class SearchHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
  }

  componentDidMount() {
    this.setState({ open: true });
  }

  closeOutermostOverlay = () => !this.props.filtersOpen && this.animateCloseSearch();

  handleClickOutside = this.closeOutermostOverlay;

  animateCloseSearch = () => {
    this.setState({ open: false }, () => {
      setTimeout(() => {
        this.props.closeSearch();
      }, ANIMATION_DURATION_MS);
    });
  };

  // TODO: is this used? redesign/header doesn't seem to use it
  sendToGa = () => {
    const { query } = this.props;
    if ("ga" in window) {
      let tracker = ga.getAll()[0];
      if (tracker) {
        tracker.send("pageview", `search?q=${query.split(" ").join("+")}`);
      }
    }
  };

  viewAllResults = () => {
    const { query, showResults } = this.props;

    if (!showResults) {
      return null;
    }

    return (
      <div className="search__all" onClick={this.goToAdvancedSearch}>
        <span>View All Results for '{query}'</span>
      </div>
    );
  };

  searchList = () => {
    return (
      <CSSTransition in={this.state.open} timeout={ANIMATION_DURATION_MS} classNames="search__list">
        <div className="search__list open">
          <div style={{ position: "relative" }}>
            {this.viewAllResults()}

            <SearchResults />

            <div className="search__bottom">
              <StaticLinks />
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.goToAdvancedSearch();
    }
  };

  goToAdvancedSearch = () => {
    window.location.href = this.props.advancedSearchUrl;
  };

  render() {
    return (
      <div className="search">
        <CSSTransition
          in={this.state.open}
          classNames="search-overlay"
          timeout={ANIMATION_DURATION_MS}
        >
          <div className="search-overlay" onClick={this.closeOutermostOverlay} />
        </CSSTransition>
        <div className="search__form container">
          <div className="search__group">
            <button
              className="search__submit btn btn--search"
              onClick={() => this.props.fetchSearchResults()}
            >
              <span className="icon icon-search-thin" />
            </button>
            <button className="btn btn--back" onClick={this.animateCloseSearch}>
              <span className="icon icon-arrow-left" />
            </button>
            <input
              autoFocus
              autoComplete="off"
              type="text"
              placeholder="Search for courses, songs..."
              value={this.props.query}
              onChange={(event) => this.props.setQuery(event.target.value)}
              onKeyDown={this.handleKeyDown}
            />
            {this.props.query.length > 0 && (
              <button className="btn btn--clear" onClick={() => this.props.setQuery("")}>
                <span>clear</span>
              </button>
            )}

            <button className="btn btn--close" onClick={this.animateCloseSearch}>
              <span className="icon icon-cross" />
            </button>
            <SearchFilters />
          </div>
        </div>
        {this.searchList()}
      </div>
    );
  }
}

SearchHeader.propTypes = {
  closeSearch: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { query, results, loading, filtersOpen, searchOpen } = state;
  return {
    query,
    results,
    loading,
    filtersOpen,
    searchOpen,
    showResults:
      query != null && query.length > 1 && results != null && Object.keys(results).length > 0,
    advancedSearchUrl: selectAdvancedSearchUrl(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeSearch: () => dispatch(closeSearch()),
  closeFilters: () => dispatch(closeFilters()),
  fetchSearchResults: () => dispatch(fetchSearchResults()),
  setQuery: (query) => {
    dispatch(setQuery(query));
    dispatch(fetchSearchResults());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  onClickOutside(
    withBootstrapSize(({ width, searchOpen, ...props }) => {
      return (
        <TransitionGroup component={null}>
          {searchOpen ? (
            <CSSTransition
              key="search"
              in={searchOpen}
              timeout={200}
              classNames="header-search-switch-animation"
            >
              <PreventScroll
                shouldPreventScroll={() => width <= breakpoints[BootstrapSize.lgDevice]}
              >
                <SearchHeader {...props} />
              </PreventScroll>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      );
    })
  )
);
