import React from "react";
import axiosWithCSRF from "../shared/axiosWithCSRF";
import { Field } from "formik";
import Loading from "../shared/Loading";

export default class UsernameSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validUsernameOptions: [],
    };
  }

  eligibleToCheckUsername = (username, props) => {
    if (
      !props.errors.username &&
      username != this.state.takenUsername &&
      username.length &&
      this.state.validUsernameOptions.includes(username) === false
    ) {
      return true;
    }
  };

  checkUsername = (username, props) => {
    if (this.eligibleToCheckUsername(username, props)) {
      this.setState({ usernameLoading: true }, () => {
        axiosWithCSRF()
          .post("/users/check_username_availability", {
            username: username,
            authenticity_token: this.props.token,
          })
          .then((response) => {
            if (response.data.existing === true) {
              this.setState({ takenUsername: username, usernameLoading: false }, () => {
                this.props.setUsernameError();
                props.setErrors({ username: "Username is taken." });
              });
            } else {
              let validUsernameOptions = [...this.state.validUsernameOptions];
              validUsernameOptions.push(username);
              this.setState(
                { takenUsername: null, usernameLoading: false, validUsernameOptions },
                () => {
                  this.props.removeUsernameError();
                }
              );
            }
          })
          .catch((error) => {
            this.setState({ usernameLoading: false });
          });
      });
    } else if (this.state.validUsernameOptions.includes(username)) {
      this.setState({ takenUsername: null }, () => {
        this.props.removeUsernameError();
      });
    }
  };

  render() {
    let { formikProps, usernameError } = this.props;
    let { errors, touched, values } = formikProps;
    let { usernameLoading } = this.state;
    return (
      <div>
        <Field
          name="username"
          onBlur={(e) => {
            this.checkUsername(e.target.value, formikProps);
            formikProps.handleBlur(e);
          }}
          type="text"
          className={
            (values["username"].length > 0 ? "populated" : "") +
            ((errors["username"] && touched["username"] && !usernameLoading) ||
            (!usernameLoading && usernameError)
              ? " has-error"
              : "")
          }
        />

        {touched["username"] && !errors["username"] && !usernameError && !usernameLoading && (
          <i className="auth__response-icon icon icon-check"></i>
        )}

        {usernameLoading && <Loading isInput={true} />}

        <span className="auth__label">Username</span>

        {(!usernameLoading && errors["username"] && touched["username"]) ||
        (!usernameLoading && usernameError) ? (
          <>
            <i className="auth__response-icon icon icon-cancel-circle"></i>
            <div className="error-message">{errors["username"]}</div>
          </>
        ) : null}
      </div>
    );
  }
}
