import React from "react";
import axios from "axios";

export default class NewRoutine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routineTitle: "",
    };
  }

  createRoutine = () => {
    axios
      .post("/user_practice_routines", {
        title: this.state.routineTitle,
        is_active: true,
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((response) => {
        this.props.routineCreated(response.data.routine);
      })
      .catch((error) => {
        let errorResponse = error.response.data
          ? error.response.data.message
          : "There was a problem creating your routine.";
        this.setState({ hasErrors: errorResponse });
        setTimeout(() => this.setState({ hasErrors: null }), 2000);
      });
  };

  updateRoutineTitle = (event) => {
    this.setState({ routineTitle: event.target.value });
  };

  render() {
    if (this.state.hasErrors) {
      return (
        <div className="create-routine__container">
          <p className="has-errors">{this.state.hasErrors}</p>
        </div>
      );
    } else {
      return (
        <div className="dashboard__content__tabs">
          <div className="tab-container">
            <div className="first__routine__outer">
              <p className="bold text-center">
                Get started by creating your first practice routine below!
              </p>
              <div className="first__routine__inner">
                <div className="new-routine__title">
                  <div className="new-routine__title__inner">
                    <span className="icon icon-pencil-outline"></span>
                    <input
                      type="text"
                      value={this.state.routineTitle}
                      onChange={this.updateRoutineTitle}
                    />
                  </div>
                  <span className="routine__form-field__label">Title</span>
                </div>
              </div>

              <button
                type="button"
                className="practice__button practice__button--save"
                onClick={this.createRoutine}
                disabled={this.state.routineTitle.length > 1 ? false : true}
              >
                Create Routine
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}
