import React from "react";
import PropTypes from "prop-types";
import { FORM_ID } from "./Competition";
import LoginModal from "../../components/auth/LoginModal";
import { SliderButton } from "@typeform/embed-react";

export default class CompetitionFormButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginOpen: false,
    };
  }

  toggleLoginOpen = () => {
    this.setState({ loginOpen: !this.state.loginOpen });
  };

  render() {
    const { loginOpen } = this.state;
    const { user, text, hasSubmitted } = this.props;
    if (hasSubmitted) {
      return (
        <button className="button button--inline button--primary" disabled>
          {" "}
          You've Entered!
        </button>
      );
    } else if (user) {
      return (
        <>
          <SliderButton
            className="button button--primary button--inline"
            id={FORM_ID}
            hidden={{ email: user.email }}
            onSubmit={(event) => this.props.handleSubmission(event)}
          >
            {text ? text : "click here"}
          </SliderButton>
          {/*  <a
          className="button button--primary button--inline"
          data-tf-slider={FORM_ID}
          data-tf-hidden={`email=${user.email}`}
          data-tf-on-submit="submitAppCompetition"
        >
          {text ? text : "click here"}
        </a>*/}
        </>
      );
    } else {
      return (
        <>
          <LoginModal isOpen={loginOpen} toggleModal={this.toggleLoginOpen} />

          <button onClick={this.toggleLoginOpen} className="button button--primary button--inline">
            {text ? text : "click here"}
          </button>
        </>
      );
    }
  }
}

CompetitionFormButton.propTypes = {
  handleSubmission: PropTypes.func.isRequired,
};
