import React from "react";
import { Provider } from "react-redux";
import ReactOnRails from "react-on-rails";

import QueryBar from "./QueryBar";
import ResultTypeFilter from "./ResultTypeFilter";
import SearchResults from "./SearchResults";
import SearchFiltersList from "./SearchFiltersList";
import SearchFilters from "./SearchFilters";
import { withBootstrapSize } from "../shared/WithBootstrapSize";
import { BootstrapSize, breakpoints } from "../shared/bootstrap-helpers";

class AdvancedSearch extends React.Component {
  xsThroughMdDeviceArrangement() {
    return (
      <>
        <div className="filter-options" style={{ display: "flex" }}>
          <ResultTypeFilter />
          <SearchFilters />
        </div>
        <div className="advanced-search__main">
          <SearchResults />
        </div>
      </>
    );
  }

  lgAndXlArrangement() {
    return (
      <>
        <ResultTypeFilter />
        <div className="advanced-search__main">
          <div className="col-xs-4">
            <SearchFiltersList />
          </div>
          <div className="col-xs-8">
            <SearchResults />
          </div>
        </div>
      </>
    );
  }

  render() {
    const { width } = this.props;
    return (
      <div className="page-section clear-swoosh">
        <div className="page-section__content">
          <div className="advanced-search">
            <QueryBar />
            <div className="container">
              {width < breakpoints[BootstrapSize.lgDevice]
                ? this.xsThroughMdDeviceArrangement()
                : this.lgAndXlArrangement()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const AdvancedSearchContainer = withBootstrapSize(AdvancedSearch);

export default (props) => {
  const store = ReactOnRails.getStore("advancedSearchStore");
  return (
    <Provider store={store}>
      <AdvancedSearchContainer {...props} />
    </Provider>
  );
};
