import React from "react";
import { CDN_URL } from "../../components/shared/CdnUrl";
import { openDialog } from "../../components/shared/ui/uiSlice";
import { connect } from "react-redux";
import { getLessonThumb } from "../../utilities/thumb";
import StrummingPatternDiagram from "../../pages/StrummingMachine/StrummingMachineApp/StrummingPatternDiagram";
import { decodePattern } from "../../pages/StrummingMachine/StrummingMachineApp/lib";

const FavoriteCard = ({ favorite, chords, openDialog, user, openAlert }) => {
  const {
    youtubeDuration,
    thumbnails,
    itemableId,
    itemableType,
    itemableTitle,
    itemableGrade,
    itemableUrl,
    vimeoThumbnail,
  } = favorite.attributes;

  let chord = null;
  let backgroundImage = null;
  let strummingPattern = null;

  if (favorite.attributes.itemableType === "Chord") {
    chord = chords.find((chord) => chord.id === itemableId);

    backgroundImage =
      user.data.attributes.settings.includes("left-hand") && chord.image_left_hand
        ? `${CDN_URL}/images/pages/chords/chord-diagrams/${chord.image_left_hand}`
        : `${CDN_URL}/images/pages/chords/chord-diagrams/${chord.image_right_hand}`;
  } else {
    backgroundImage = getLessonThumb(favorite.attributes);
  }

  if (favorite.attributes.itemableType === "StrummingPattern") {
    strummingPattern = favorite;
  }

  return (
    <div
      className={`dashboard__favorite-card${
        chord ? " chord" : strummingPattern ? " strumming-pattern" : ""
      }`}
      onClick={() => {
        if (chord) {
          window.location.href = `/chords/${chord.slug}`;
        }
      }}
    >
      {!strummingPattern ? (
        <>
          <div className="favorite-thumb" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                openDialog({
                  message: `Are you sure you want to remove this ${itemableType.toLowerCase()}?`,
                  confirmButtonText: "Remove",
                  data: favorite,
                });
              }}
            >
              <i className="icon icon-heart"></i>
            </button>
          </div>
          {itemableUrl && <a href={itemableUrl}>{itemableTitle}</a>}
          <div className="favorite-info">
            {youtubeDuration && youtubeDuration.length > 0 && (
              <p className="favorite-info__duration">
                <i className="icon icon-clock2"></i>
                {youtubeDuration}
              </p>
            )}
            {(itemableGrade === 0 || itemableGrade) && (
              <p className={`favorite-info__grade tile tile--grade-${itemableGrade}`}>
                {itemableGrade === 0 ? "all grades" : `Grade ${itemableGrade}`}
              </p>
            )}
          </div>
        </>
      ) : (
        <StrummingPatternDiagram
          selectedStrummingPattern={decodePattern(
            strummingPattern.attributes.data.strumming_pattern
          )}
          onStrummingPatternSelect={() => {
            window.location.href = `/strumming-machine/${strummingPattern.attributes.data.strumming_pattern.pattern}`;
          }}
          saved
          deleteStrummingPattern={() => {
            openDialog({
              message: `Are you sure you want to remove this ${
                itemableType.toLowerCase() !== "strummingpattern"
                  ? itemableType.toLowerCase()
                  : "strumming pattern"
              }?`,
              confirmButtonText: "Remove",
              data: strummingPattern.attributes.data.strumming_pattern,
            });
          }}
          openAlert={openAlert}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  chords: state.entity.dashboard.chords,
});

const mapDispatchToProps = (dispatch) => ({
  openDialog: (text) => dispatch(openDialog(text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteCard);
