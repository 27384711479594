import React from "react";
import ContactSwoosh from "./ContactSwoosh";
import { CDN_URL } from "../../components/shared/CdnUrl";
import ContactForm from "../../components/shared/ContactForm";
import { withInnerWidth } from "../../components/shared";

const Contact = ({ width, recaptchaKey }) => {
  return (
    <div className="contact" style={{ position: "relative" }}>
      <ContactSwoosh />
      <div className="contact__form">
        <ContactForm recaptchaKey={recaptchaKey} />
      </div>
      <div className="contact__body container">
        <div className="contact__help">
          <h2>why not browse our faqs</h2>
          <img src={`${CDN_URL}/images/pages/contact/help.png`} alt="help" />
          <p>
            If you struggle with products or your account, our FAQ is up to date and full with
            answers to most questions you could have. If you can’t find the answer you’re looking
            for, use the form on this page.
          </p>
          <div>
            <a className="button button--primary button--inline" href="/faq">
              Go to FAQ
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withInnerWidth(Contact);
