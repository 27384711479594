import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  listing: false,
  latestReleases: false,
  artists: false,
  songRequestsListing: false,
  currentUserSongRequestsListing: false,
};

export const { actions, reducer } = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setListingLoading(state, { payload }) {
      state.listing = payload;
    },
    setLatestReleasesLoading(state, { payload }) {
      state.latestReleases = payload;
    },
    setArtistsLoading(state, { payload }) {
      state.artists = payload;
    },
    setSongRequestsListingLoading(state, { payload }) {
      state.songRequestsListing = payload;
    },
    setCurrentUserSongRequestsListingLoading(state, { payload }) {
      state.currentUserSongRequestsListing = payload;
    },
    setSongRequestFormLoading(state, { payload }) {
      state.songRequestFormLoading = payload;
    },
    setAll(state, { payload }) {
      return Object.keys(state).reduce(
        (accum, key) => ({
          ...accum,
          [key]: payload,
        }),
        {}
      );
    },
  },
});
