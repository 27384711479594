import { Components } from "@mui/material";

export const paper: Components["MuiPaper"] = {
  styleOverrides: {
    root: {},
    rounded: {
      borderRadius: "10px",
    },
  },
  defaultProps: {
    elevation: 0,
  },
};
