import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import { workshopGalleryImages, workshopImageBaseUrl } from "./constants";
import { jgDenimTheme } from "../../../jg-material/theme/jg-denim-theme";

export const WorkshopImagesCarousel = ({ workshop }: { workshop: string | null }) => {
  const [currIdx, setCurrIdx] = React.useState(0);
  const isSmall = useMediaQuery(jgDenimTheme.breakpoints.down("sm"));

  if (workshop == null) {
    return null;
  }

  const { workshopTitle, imagePaths } = workshopGalleryImages[workshop]!;

  return (
    <Box>
      <Typography variant="h4" color="white" textAlign="center" mb={jgDenimTheme.spacing(2)}>
        {workshopTitle}
      </Typography>

      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Box width={"90vw"} margin={"0 auto"}>
        <Slider
          {...slickSettings}
          infinite={true /*https://github.com/kenwheeler/slick/issues/940*/}
          initialSlide={currIdx}
          afterChange={setCurrIdx}
        >
          {imagePaths.map((path) => (
            <div>
              <Box display="flex" justifyContent="center" mx={isSmall ? 4 : 0}>
                <img
                  style={{ maxWidth: "100%" }}
                  key={path}
                  src={`${workshopImageBaseUrl}${workshop}/${path}`}
                />
              </Box>
            </div>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

const slickSettings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};
