import React from "react";
import { artistSortByLabels, sortByOptions } from "../consts";
import { withInnerWidth } from "../../shared";
import { breakpoints, BootstrapSize } from "../../shared/bootstrap-helpers";
const ArtistSort = ({ width, sortBy, setSortBy, hasQuery }) => {
  const justifyContent = width < breakpoints[BootstrapSize.lgDevice] ? "flex-start" : "flex-end";
  return (
    <div
      className="songs-index__sort-by"
      style={{ justifyContent: justifyContent, display: "flex" }}
    >
      <span>Sort By: </span>
      <select
        style={{ paddingLeft: "5px" }}
        value={sortBy || ""}
        onChange={(e) => setSortBy(e.target.value)}
      >
        {Object.entries(artistSortByLabels).map(([value, label]) => (
          <option
            value={value}
            key={value}
            disabled={
              (value === sortByOptions.relevance && !hasQuery) ||
              (value != sortByOptions.relevance && hasQuery)
            }
          >
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default withInnerWidth(ArtistSort);
