import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import { responseSelector } from "./quizSelectors";

export const submitQuiz = createAsyncThunk("quiz/submit", ({ quiz }, { dispatch, getState }) => {
  const state = getState();
  const response = responseSelector(state);
  const questionOptionIds = Object.values(response).reduce(
    (accum, optionIds) => [...accum, ...optionIds],
    []
  );

  axiosWithCSRF()
    .post(`/quizzes/${quiz.attributes.slug}/submit`, { question_option_ids: questionOptionIds })
    .then(() => (window.location.href = `/quizzes/${quiz.attributes.slug}/results`));
});
