import { Box, Typography } from "@mui/material";
import { RegisterFormPaper } from "../shared/RegisterFormPaper";
import React from "react";
import { StripeForm } from "./StripeForm";
import { CDN_URL } from "../../../components/shared/CdnUrl";

export const PayDeposit = ({
  onComplete,
  currentUser,
  activeStep,
}: {
  onComplete: () => void;
  currentUser: any;
  activeStep: Number;
}) => (
  <>
    <RegisterFormPaper>
      <Typography variant="h4" mb={4}>
        {activeStep == 0 ? "Deposit payment" : "Remaining fee payment"}
      </Typography>
      <StripeForm onComplete={onComplete} currentUser={currentUser} />
    </RegisterFormPaper>
    <Box mt={3} className="stripe-secure">
      <i className="icon icon-lock" />
      <span>Secure payment with</span>
      <Box
        ml={1}
        maxHeight={"25px"}
        component={"img"}
        src={`${CDN_URL}/redesign/2020/donate/stripe.svg`}
        alt="stripe logo"
      />
    </Box>
  </>
);
