import React from "react";
import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import CompetitionHeader from "./CompetitionHeader";
import CompetitionContent from "./CompetitionContent";
import TermsAndConditions from "./TermsAndConditions";
import CompetitionWinners from "./CompetitionWinners";

export const FORM_ID = "g0TDKxQi";
export const BUTTON_TEXT = "Enter the Draw";
import { Sidetab } from "@typeform/embed-react";

const COMPETITION_ID = 1;

export default class Competition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formOpen: false,
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);
  }

  handleSubmission = (event) => {
    setTimeout(() => {
      const body = document.body;
      body.style.overflow = "scroll";
      this.setState({ hasSubmitted: true }, () => {
        axiosWithCSRF().post("/competition_entries", {
          competition_entry: {
            competition_id: COMPETITION_ID,
          },
        });
      });
    }, 2000);
  };

  render() {
    const hasSubmitted = this.props.hasSubmitted || this.state.hasSubmitted;
    const { formOpen } = this.state;
    const { user, winners } = this.props;
    const hasWinners = winners && winners.length > 0;
    return (
      <div className="competition container ">
        <CompetitionHeader
          handleSubmission={this.handleSubmission}
          hasSubmitted={hasSubmitted}
          user={user}
          isOver={hasWinners}
        />
        {hasWinners ? (
          <CompetitionWinners winners={winners} />
        ) : (
          <CompetitionContent
            handleSubmission={this.handleSubmission}
            hasSubmitted={hasSubmitted}
            user={user}
          />
        )}
        <TermsAndConditions hasSubmitted={hasSubmitted} user={user} />
      </div>
    );
  }
}
