import axiosWithCSRF from "../../components/shared/axiosWithCSRF";
import { normalize } from "normalizr";
import debounce from "lodash.debounce";
import { songbookIndexJson, songbookJson, songOptionsJson } from "./SongbookSchema";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ui from "../../components/songs/store/slices/ui";
import { setLoading } from "../../components/shared/ui/uiSlice";
import {
  appendArtists,
  appendSongbooks,
  appendSongbookSongs,
  appendSongs,
  removeSongbook,
  replaceSongbookSongsForSongbook,
  setArtists,
  setRenamingSongbookId,
  setSongbook,
  setSongbooks,
  setSongbookSongs,
  setSongbookTabPanel,
  setSongOptions,
  setSongOptionsLoading,
  setSongOptionsTotalCount,
  setSongs,
  songbookTabPanel,
} from "../store/dashboardStore";
import {
  selectCurrentSongbook,
  selectOrderedSongsForSongbook,
  selectSongOptionsFuse,
  selectSongOptionsMeta,
  selectSongOptionsQuery,
  selectSongsArray,
} from "../store/dashboardSelectors";
import { artistsJson } from "../../components/songs/store/songSchema";
import * as slices from "../../components/songs/store/slices";

const handleError = (dispatch) => (e) => {
  dispatch(
    ui.actions.openAlert({
      message: "An error occurred, please try again later",
      severity: "error",
    })
  );
};

export const fetchSongbooks = createAsyncThunk("dashboard/fetchSongbooks", (_, { dispatch }) => {
  return axiosWithCSRF()
    .get("/songbooks")
    .then(({ data }) => data)
    .then((data) => normalize(data, songbookIndexJson))
    .then(({ entities: { songs, artists, songbooks, songbookSongs } }) => {
      dispatch(setSongs(songs || {}));
      dispatch(setArtists(artists || {}));
      dispatch(setSongbooks(songbooks || {}));
      dispatch(setSongbookSongs(songbookSongs || {}));
    })
    .catch(() => {
      handleError(dispatch);
    });
});

export const updateSongbook = createAsyncThunk(
  "dashboard/updateSongbook",
  ({ songbook, replaceSongs = false }, { dispatch }) => {
    dispatch(setLoading());
    return axiosWithCSRF()
      .put(`/songbooks/${songbook.id}?${replaceSongs ? "replace_songs=true" : ""}`, {
        songbook,
      })
      .then((res) => normalize(res.data, songbookJson))
      .then(({ entities, result }) => {
        dispatch(appendSongbooks(entities.songbooks));
        dispatch(appendSongs(entities.songs));
        dispatch(replaceSongbookSongsForSongbook(entities.songbookSongs));
        // extract original songbook for use later in promise chain
        return entities.songbooks[result.data];
      })
      .then((songbook) => {
        dispatch(
          ui.actions.openAlert({
            message: `Successfully updated ${songbook.attributes.title}`,
            severity: "success",
          })
        );
        return songbook;
      })
      .catch((e) => {
        handleError(dispatch);
      })
      .finally(() => {
        dispatch(setLoading());
      });
  }
);

export const createSongbook = createAsyncThunk(
  "dashboard/createSongbook",
  (songbook, { dispatch }) => {
    const newSongbook = songbook == null ? { title: "New Songbook" } : songbook;

    return axiosWithCSRF()
      .post("/songbooks", { songbook: newSongbook })
      .then((res) => res.data.data)
      .then((songbook) => {
        dispatch(setSongbook(songbook));
        dispatch(setRenamingSongbookId(songbook.id));
        return songbook;
      })
      .then((songbook) => {
        dispatch(
          ui.actions.openAlert({
            message: `Successfully created ${songbook.attributes.title}`,
            severity: "",
          })
        );
        return songbook;
      })
      .catch((e) => {
        handleError(dispatch);
      });
  }
);

export const deleteSongbook = createAsyncThunk(
  "dashboard/deleteSongbook",
  (songbook, { dispatch }) => {
    return axiosWithCSRF()
      .delete(`/songbooks/${songbook.id}`)
      .then(() => {
        dispatch(removeSongbook(songbook)); // closed over, don't need the arg from the axios response
      })
      .then(() => {
        dispatch(
          ui.actions.openAlert({
            message: `Successfully deleted ${songbook.attributes.title}`,
            severity: "",
          })
        );
        return songbook;
      })
      .catch((e) => {
        handleError(dispatch);
      });
  }
);

export const fetchSongOptions = createAsyncThunk(
  "dashboard/fetchSongOptions",
  (_, { dispatch }) => {
    dispatch(setSongOptionsLoading(true));
    return axiosWithCSRF()
      .get(`/songbooks/song_options`)
      .then((res) => normalize(res.data, artistsJson)) // using Song.cached_songs_for_index, which actually returns artists
      .then(({ entities: { songs, artists } }) => {
        dispatch(setSongs(songs));
        dispatch(setArtists(artists));
        dispatch(querySongOptions());
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        dispatch(setSongOptionsLoading(false));
      });
  }
);

const debouncedQuerySongOptions = debounce(({ dispatch, getState }) => {
  dispatch(setSongOptionsLoading(true));

  setTimeout(() => {
    const state = getState();
    const songs = selectSongsArray(state);
    const songsFuse = selectSongOptionsFuse(state);
    const query = selectSongOptionsQuery(state);
    const { page, perPage } = selectSongOptionsMeta(state);
    const currentSongbook = selectCurrentSongbook(state);
    const currentSongbookSongs = new Set(
      selectOrderedSongsForSongbook(state)(currentSongbook).map(({ id }) => id)
    );
    const filteredSongs = applyQuery(songs, songsFuse, query);
    const availableSongs = filteredSongs
      .filter((song) => !currentSongbookSongs.has(song.id))
      .sort((a, b) => (a.attributes.title > b.attributes.title ? 1 : -1));
    const pagedSongs = availableSongs.slice(0, page * perPage);
    const totalCount = availableSongs.length;
    dispatch(setSongOptions(pagedSongs.map(({ id }) => id)));
    dispatch(setSongOptionsTotalCount(totalCount));
    dispatch(setSongOptionsLoading(false));
  }, 1);
}, 300);

export const querySongOptions = createAsyncThunk("dashboard/querySongOptions", (_, thunkApi) => {
  debouncedQuerySongOptions(thunkApi);
});

const applyQuery = (songs, songsFuse, query) =>
  query == null || query.length === 0 ? songs : songsFuse.search(query).map(({ item }) => item);

export const deleteSongbookSong = createAsyncThunk(
  "dashboard/deleteSongbookSong",
  ({ songbook, song }, { dispatch }) => {
    return axiosWithCSRF()
      .post(`/songbooks/${songbook.id}/actions/remove_song`, { song })
      .then((res) => res.data.data)
      .then((songbook) => {
        dispatch(setSongbook(songbook));
      })
      .then(() => {
        dispatch(
          ui.actions.openAlert({
            message: `Successfully removed ${song.attributes.title} from  ${songbook.attributes.title}`,
            severity: "success",
          })
        );
      })
      .catch((e) => {
        handleError(dispatch);
      });
  }
);
