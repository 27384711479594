import PropTypes from "prop-types";
import React from "react";
import AppConfig from "../../../packs/AppConfig.js";

export default class FilterItems extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    filterBy: PropTypes.array.isRequired,
    onFiltersChanged: PropTypes.func.isRequired,
    initialLevel: PropTypes.array,
  };

  constructor(props, _railsContext) {
    super(props);

    this.state = {
      items: props.items,
      filterBy: props.filterBy,
      initialLevel: props.initialLevel,
      filterCount: props.filterCount,
      songCount: props.songCount,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ filterCount: nextProps.filterCount });
  }

  // Toggle filter group filter is filterBy item and filterGroup is array ["White", "Yellow", "Orange"]
  toggleFilterGroup = (filter, filterGroup) => {
    let filterBy = this.state.filterBy;

    // If existing filters already contain the filterGroup items
    // Remove them from filters...
    if (this.arrayContainsOther(filter.filters, filterGroup)) {
      filter.filters = filter.filters.filter((item) => {
        return !filterGroup.includes(item);
      });
      // ..otherwise append filters with the ones that aren't there yet
    } else {
      filterGroup.map((item) => {
        let newFilters = filter.filters;

        if (!filter.filters.includes(item)) {
          newFilters.push(item);
        }

        filter.filters = newFilters;
      });
    }

    filterBy = this.setFilterToFilterBy(filter, filterBy);

    // Apply filters to items
    this.applyFilters(filterBy);
  };

  toggleFilter = (filter, item) => {
    let filterBy = this.state.filterBy;

    // Check if filter is already in the filters list
    let index = -1;
    for (let i = 0; i < filter.filters.length; i++) {
      if (filter.filters[i] === filter.value(item)) {
        index = i;
      }
    }
    // Delete if it is, otherwise add filter
    if (index > -1 && filter.filters.length) {
      filter.filters.splice(index, 1);
    } else {
      filter.filters.push(filter.value(item));
    }

    filterBy = this.setFilterToFilterBy(filter, filterBy);

    // Apply filters to items
    this.applyFilters(filterBy);
  };

  // Find and set the filterByItem to filterBy and return the new one.
  setFilterToFilterBy = (filter, filterBy) => {
    for (let i = 0; i < filterBy.length; i++) {
      if (filterBy[i].name === filter.name) {
        filterBy[i] = filter;
      }
    }

    return filterBy;
  };

  // Filter items by filter. Return filtered set.
  filterItems = (items, filter) => {
    let filtered = items;

    // Filter items if filters are present, otherwise
    // just return the original set
    if (filter.filters && filter.filters.length) {
      filtered = items.filter((item) => {
        return filter.includeItem(item);
      });
    }

    return filtered;
  };

  // Apply filters to items.
  applyFilters = (filterBy) => {
    let items = this.props.allSongs ? this.props.allSongs : this.state.items;

    filterBy.map((filter) => {
      items = this.filterItems(items, filter);
    });

    this.setState({ filterBy: filterBy });
    this.props.onFiltersChanged(items);
  };

  // Reset a given filter
  resetFilter = (filter) => {
    let filterBy = this.state.filterBy;

    filter.filters = [];

    // Update filterBy
    filterBy = this.setFilterToFilterBy(filter, filterBy);

    this.applyFilters(filterBy);
  };

  // Clear all filters
  clearAll = () => {
    let filterBy = this.state.filterBy;

    for (let i = 0; i < filterBy.length; i++) {
      filterBy[i].filters = []; // Reset
    }

    this.applyFilters(filterBy);

    // Clear the search field
    document.getElementById("item-search-field").value = "";
  };

  // Toggle filter list visibility
  toggleList = (filter) => {
    let filterBy = this.state.filterBy;

    // togglelistVisible
    filter.listVisible = !filter.listVisible;

    // Update filterBy
    filterBy = this.setFilterToFilterBy(filter, filterBy);

    this.setState({ filterBy: filterBy });
  };

  // When search field is manipulated
  searchChanged = (event) => {
    let searchStr = event.target.value.toLowerCase();
    let filterBy = this.state.filterBy;

    // Special case, only one search string at a time
    filterBy[0].filters = [searchStr];

    this.setState({ filterBy: filterBy });
    this.applyFilters(this.state.filterBy);
  };

  // Check if all of the other matches the array
  arrayContainsOther = (array, other) => {
    // Other has more items
    if (other.length > array.length) {
      return false;
    }
    // If any of the other items doesn't match array items, false
    for (let i = 0; i < other.length; i++) {
      if (array.includes(other[i]) === false) {
        return false;
      }
    }

    return true;
  };

  chordsTooltip() {
    return (
      <i
        className="icon icon-info"
        data-toggle="tooltip"
        data-placement="top"
        title="All chords in the song need to be selected before the song shows in the results."
      ></i>
    );
  }

  itemCount = (filter) => {
    if (filter.grades) {
      let total = this.state.filterCount["levels"][filter.id];
      return total ? total : 0;
    } else if (filter.belt) {
      let total = this.state.filterCount["grades"][filter.id];
      return total ? total : 0;
    } else if (filter.type_of == "tag") {
      let total = this.state.filterCount["tags"][filter.id];
      return total ? total : 0;
    } else if (filter.type_of == "appears_in") {
      let total = this.state.filterCount["appears_in"][filter.id];
      return total ? total : 0;
    } else if (filter.type_of == "genre") {
      let total = this.state.filterCount["genres"][filter.id];
      return total ? total : 0;
    } else if (filter.type_of == "chord") {
      // let total = this.state.filterCount['chords'][filter.id]
      // return total ? total : 0
      return "";
    }
  };

  render() {
    return (
      <div className="filters">
        <div className="filters__header clearfix">
          <div className="filters__header-title page-subheading">
            Filter {this.state.songCount} songs
          </div>
          <div className="filters__header-clear" onClick={this.clearAll}>
            {AppConfig.data.filterItems.clearAll}
          </div>
        </div>

        <div className="filters__group">
          <div className="form-group">
            <input
              className=""
              id="item-search-field"
              placeholder={AppConfig.data.filterItems.searchPlaceholder}
              onChange={this.searchChanged}
            />
            <button type="submit" className="dynamic-search__submit btn btn--search">
              <span className="glyphicon glyphicon-search"></span>
            </button>
          </div>
        </div>

        {/* Search filter is a special case, handled elsewhere */}
        {this.state.filterBy.map(
          (filter) =>
            filter.name !== "search" && (
              <div className="filters__group" key={"filters-group-" + filter.name}>
                <div className="filters__title-row clearfix">
                  <div
                    className="filters__title page-subheading"
                    onClick={() => this.toggleList(filter)}
                  >
                    <span
                      className={
                        "glyphicon " +
                        (filter.listVisible
                          ? "glyphicon-triangle-top"
                          : "glyphicon-triangle-bottom")
                      }
                    ></span>
                    <span>
                      {filter.title}
                      {filter.title === "Easy Chords" && this.chordsTooltip()}
                    </span>
                    {filter.subtitle && (
                      <span className="filters__subtitle">{filter.subtitle}</span>
                    )}
                  </div>
                  <div className="filters__reset-filter" onClick={() => this.resetFilter(filter)}>
                    {AppConfig.data.filterItems.clear}
                  </div>
                </div>
                <div
                  className={"filters__list " + (filter.listVisible ? "" : "hidden")}
                  id={"filters-list-" + filter.name}
                >
                  {filter.name !== "level" &&
                    filter.filterItems &&
                    filter.filterItems.map((item) => (
                      <div
                        className={
                          "filters__item " +
                          (filter.filters.includes(filter.value(item)) ? "active" : "")
                        }
                        key={"filter-item-" + filter.value(item)}
                        onClick={() => this.toggleFilter(filter, item)}
                      >
                        <span className="filters__item-check glyphicon glyphicon-ok"></span>
                        <span className="filters__item-title">{filter.value(item)}</span>
                        <span className="filters__nestedItem-count">{this.itemCount(item)}</span>
                      </div>
                    ))}
                  {filter.name === "level" &&
                    filter.filterItems &&
                    filter.filterItems.map((item) => (
                      <div
                        className="filters__sub-group"
                        key={"filter-sub-group-" + item.title.toLowerCase()}
                      >
                        <div
                          className={
                            "filters__item " +
                            (this.arrayContainsOther(
                              filter.filters,
                              item.grades.map((grade) => {
                                return grade.belt;
                              })
                            )
                              ? "active"
                              : "")
                          }
                          onClick={() =>
                            this.toggleFilterGroup(
                              filter,
                              item.grades.map((grade) => {
                                return grade.belt;
                              })
                            )
                          }
                        >
                          <span className="filters__item-check glyphicon glyphicon-ok"></span>
                          <span className="filters__item-title">{item.title}</span>
                          <span className="filters__nestedItem-count">{this.itemCount(item)}</span>
                        </div>
                        {item.grades.map((grade) => (
                          <div
                            className={
                              "filters__item filters__item--nested " +
                              (filter.filters.includes(filter.value(grade)) ? "active" : "")
                            }
                            key={"filter-item-" + filter.value(grade)}
                            onClick={() => this.toggleFilter(filter, grade)}
                          >
                            <span className="filters__item-check glyphicon glyphicon-ok"></span>
                            <span className="filters__item-title">{filter.value(grade)}</span>
                            <span className="filters__nestedItem-count">
                              {this.itemCount(grade)}
                            </span>
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
            )
        )}
      </div>
    );
  }
}
