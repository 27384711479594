import React from "react";

export const VerifiedIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46 24L41.12 18.42L41.8 11.04L34.58 9.4L30.8 3L24 5.92L17.2 3L13.42 9.38L6.2 11L6.88 18.4L2 24L6.88 29.58L6.2 36.98L13.42 38.62L17.2 45L24 42.06L30.8 44.98L34.58 38.6L41.8 36.96L41.12 29.58L46 24ZM20.18 33.44L12.58 25.82L15.54 22.86L20.18 27.52L31.88 15.78L34.84 18.74L20.18 33.44Z"
      fill="#1D3446"
    />
  </svg>
);

export const MusicNoteIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10402_65434)">
        <path
          d="M12 3V13.55C11.41 13.21 10.73 13 10 13C7.79 13 6 14.79 6 17C6 19.21 7.79 21 10 21C12.21 21 14 19.21 14 17V7H18V3H12Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10402_65434">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ThumbsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10402_65451)">
        <path
          d="M2 20H4C4.55 20 5 19.55 5 19V10C5 9.45 4.55 9 4 9H2V20ZM21.83 12.88C21.94 12.63 22 12.36 22 12.08V11C22 9.9 21.1 9 20 9H14.5L15.42 4.35C15.47 4.13 15.44 3.89 15.34 3.69C15.11 3.24 14.82 2.83 14.46 2.47L14 2L7.59 8.41C7.21 8.79 7 9.3 7 9.83V17.67C7 18.95 8.05 20 9.34 20H17.45C18.15 20 18.81 19.63 19.17 19.03L21.83 12.88Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10402_65451">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SnowflakeIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10402_65437)">
        <path
          d="M22 11H17.83L21.07 7.76L19.66 6.34L15 11H13V9L17.66 4.34L16.24 2.93L13 6.17V2H11V6.17L7.76 2.93L6.34 4.34L11 9V11H9L4.34 6.34L2.93 7.76L6.17 11H2V13H6.17L2.93 16.24L4.34 17.66L9 13H11V15L6.34 19.66L7.76 21.07L11 17.83V22H13V17.83L16.24 21.07L17.66 19.66L13 15V13H15L19.66 17.66L21.07 16.24L17.83 13H22V11Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10402_65437">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DialogIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10402_65448)">
        <path
          d="M20 2H4C2.9 2 2.01 2.9 2.01 4L2 22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM13 14H11V10H13V14ZM13 8H11V6H13V8Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10402_65448">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
