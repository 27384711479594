import React from "react";
import {
  selectOrderedSongsForSongbook,
  selectSongbooksArray,
  selectSongbooksDictionary,
} from "../store/dashboardSelectors";
import { useDispatch, useSelector } from "react-redux";
import { storeNewSongbookSongs } from "./utils";
import { deleteSongbookSong } from "./songbookApi";

export default function ({ songbook, song, action, close }) {
  const songId = song.id;
  const songbooksDictionary = useSelector(selectSongbooksDictionary);
  const songbooks = useSelector(selectSongbooksArray);

  const selectableSongbooks = songbooks.filter((_songbook) =>
    _songbook.relationships.songs.data.every((song) => song.id !== songId)
  );

  const [selectedSongbooks, setSelectedSongbooks] = React.useState(new Set());

  const toggleSelected = (songbookId) => {
    const currentSongbooks = Array.from(selectedSongbooks);

    if (selectedSongbooks.has(songbookId)) {
      setSelectedSongbooks(new Set(currentSongbooks.filter((id) => id !== songbookId)));
    } else {
      setSelectedSongbooks(new Set([...currentSongbooks, songbookId]));
    }
  };

  const getSongbookCurrentSongs = useSelector(selectOrderedSongsForSongbook);
  const dispatch = useDispatch();
  const submit = (e) => {
    selectedSongbooks.forEach((songbookId) => {
      const _songbook = songbooksDictionary[songbookId];
      const songbookCurrentSongs = getSongbookCurrentSongs(_songbook);
      storeNewSongbookSongs(_songbook, songbookCurrentSongs, [songId], dispatch);
    });

    if (action === "move") {
      dispatch(deleteSongbookSong({ songbook, song }));
    }

    close(e);
  };

  return (
    <div className="songbook-destination-form" onClick={(e) => e.stopPropagation()}>
      <div className="songbook-destination-form__list">
        <div className="songbook-destination-form__header">
          <span>{action === "move" ? "Move to..." : "Copy to..."}</span>
          <i onClick={close} className="icon icon-cancel" />
        </div>

        {selectableSongbooks.map((songbook) => {
          const selected = selectedSongbooks.has(songbook.id);

          return (
            <div
              onClick={() => toggleSelected(songbook.id)}
              className={`songbook-destination-form__option ${
                selected ? "songbook-option--selected" : ""
              }`}
            >
              <div>
                <i className={`icon icon-checkbox-${selected ? "checked" : "unchecked"}`} />
              </div>
              <div>{songbook.attributes.title}</div>
            </div>
          );
        })}
      </div>

      {selectedSongbooks.size > 0 && (
        <div className="songbook-destination-form__footer">
          <div onClick={submit}>{action === "move" ? "Move" : "Copy"} to Selected Lists</div>
        </div>
      )}
    </div>
  );
}
