import { CDN_URL } from "../components/shared/CdnUrl";
import { USER_ITEM_STATUSES } from "./swoosh/consts";

function imageFormattedWithCdnUrl(image) {
  if (image && image.includes(CDN_URL)) {
    return image;
  } else if (image && image.length > 0) {
    return `${CDN_URL}${image}`;
  }
}

function nextLessonThumb(nextLesson) {
  if (nextLesson.image && nextLesson.image.length > 0) {
    return imageFormattedWithCdnUrl(nextLesson.image);
  } else if (nextLesson.thumbnails && Object.keys(nextLesson.thumbnails).length > 0) {
    return nextLesson.thumbnails["medium"];
  } else if (nextLesson.vimeoThumbnail && nextLesson.vimeoThumbnail.length > 0) {
    return nextLesson.vimeoThumbnail;
  }
}

export function nextLesson(lessonsWithinModule, currentLesson) {
  const lessonWithinList = lessonsWithinModule.find(
    (lesson) => lesson.id === parseInt(currentLesson.data.id)
  );
  const currentIndex = lessonsWithinModule.indexOf(lessonWithinList);
  const nextLesson =
    currentIndex + 1 < lessonsWithinModule.length ? lessonsWithinModule[currentIndex + 1] : null;

  if (nextLesson) {
    return {
      title: nextLesson.title,
      link: `/guitar-lessons/${nextLesson.slug}`,
      image: nextLessonThumb(nextLesson),
    };
  }
}

function itemsForThisLesson(userItems, lesson) {
  if (!userItems || userItems.length === 0) return [];

  const lessonId = lesson.data?.id || lesson.id;

  return userItems.filter((item) => item.attributes.nestedIds.includes(parseInt(lessonId)));
}

export function isSaved(userItems, lesson) {
  if (!userItems || userItems.length === 0) return false;

  const savedItem = itemsForThisLesson(userItems, lesson).find(
    (item) => item.attributes.itemStatus === USER_ITEM_STATUSES.saved
  );

  return savedItem ? true : false;
}

export function isCompleted(userItems, lesson) {
  if (!userItems || userItems.length === 0) return false;

  const completedItem = itemsForThisLesson(userItems, lesson).find(
    (item) => item.attributes.itemStatus === USER_ITEM_STATUSES.completed
  );

  return completedItem ? true : false;
}
